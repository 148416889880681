angular.module('mobilezuz').directive('spBarcode',function(){
    return{
        restrict: 'E',
        template: '<img src="" id="barcode">',
        scope: {
            text: '=',
            format: '@',
            height: '=',
            width: '=',
        },
        link: function(scope, element){
            var canvas = document.createElement('canvas');
            JsBarcode(canvas, scope.text, {
                format: scope.format || 'CODE128',
                height: scope.height || 60,
                width: scope.width || 1.5
            });
            element[0].children[0].src = canvas.toDataURL('image/png');
        }
    };
});
