(function (angular) {
angular.module('mobilezuz')
    .constant('LINK_LOCATIONS', {
        BOTTOM: 0,
        TOP: 1
    })
    .controller('AppHeader', [
        '$q', '$window', 'Cart', 'mDesign', '$timeout', 'Api', '$rootScope', '$scope', '$state', '$element', '$filter', '$location',
        'User', 'Util', 'Config','TrackingTransparency', 'Retailer', 'SpDeliveryAreasService', 'ChooseAreaDialog', 'HubService', '$mdSidenav',
        '$mdToast', 'LANGUAGES', 'LINK_LOCATIONS', 'PAGE_ACCESS', 'WEB_PAGE_LOCATIONS', 'RETAILER_STATUSES', 'SP_SERVICES', 'ORGANIZATION_TYPES', 'Api', 'MOBILE_SERVICE_ID','$sce',
        function ($q, $window, cart, mDesign, $timeout, api, $rootScope, $scope, $state, $element, $filter, $location,
                    User, Util, Config, TrackingTransparency, Retailer, SpDeliveryAreasService, ChooseAreaDialog, HubService, $mdSidenav,
                    $mdToast, LANGUAGES, LINK_LOCATIONS, PAGE_ACCESS, WEB_PAGE_LOCATIONS, RETAILER_STATUSES, SP_SERVICES, ORGANIZATION_TYPES, Api, MOBILE_SERVICE_ID, $sce ) {
            var _timeoutToggleSideNav,
                _timeoutOpenMenuPages,
                _listeners = [],
                dialogOpen = false,
                _headerElement = angular.element(document.getElementById('header'));

            $scope.areas = [];
            $scope.buildSideNav = false;
            $scope.changeLanguageMenu = {
                show: false
            };

            $scope.getTrustedSvg = function(svgString) {
                return $sce.trustAsHtml(svgString);
            };

            var currentVersion
            var platformId = !!window.cordova && (window.cordova.platformId == 'android' ? 1 : (window.cordova.platformId == 'ios' ? 2 : null))
            
            if (platformId) {
                document.addEventListener('deviceready', function() {
                    cordova.getAppVersion.getVersionNumber().then(function(version) {
                        currentVersion = version
                    });
                }, false);
            }

            var checkForceUpdate;


            function _convertToHtmlToCheckEmpty(text){
                var aux = document.createElement('div');
                aux.innerHTML = text; //parses the html
                return  aux.innerText.trim();
            }
            function showAppVersionUpdateDialog(checkForceUpdate){
                var defaultAppVersionUpdatePopupSettings = {
                    title: {
                        en: 'Update Required',
                        he: 'קיים עדכון אפליקציה',
                        ru: 'Требуется обновление',
                        es: 'Actualización necesaria',
                        ar: 'يُرجى تحديث التطبيق',
                        fr: 'Mise à jour requise.'
                    },
                    text: {
                        en: 'Hey there! It\'s time to update your app for a better experience. Get the latest version now to enjoy new features, faster performance, and increased security.',
                        he: 'מומלץ להתקין את העדכון החדש לשיפור הביצועים, האבטחה וחוויית הקנייה באפליקציה.',
                        ru: 'Привет! Пора обновить ваше приложение, чтобы оно работало еще лучше. Установите последнюю версию и наслаждайтесь новыми функциями, более быстрой работой и повышенной безопасностью.',
                        es: '¡Hola! Debes actualizar tu aplicación para que podamos brindarte una mejor experiencia. Descarga la versión más reciente para disfrutar de nuevas funciones, un rendimiento más rápido y mayor seguridad.',
                        ar: 'مرحبًا بكم! حان الوقت لتحديث تطبيقك للحصول على تجربة أفضل. احصل على أحدث إصدار الآن للاستمتاع بالميزات الجديدة والأداء الأسرع والأمان المعزّز.',
                        fr: 'Salut! Il est temps de mettre à jour votre application pour une meilleure expérience. Obtenez la dernière version maintenant pour bénéficier de nouvelles fonctionnalités, de meilleures performances et d\'une plus grande sécurité.'
                    },
                    allowUpdateBtn: {
                        en: 'Update Now',
                        he: 'עדכן כעת'
                    },
                };
                Retailer.getRetailerSettings().then(function(retailerSettings) {
                    Retailer.getAppVersionUpdatePopupSettings().then(function(data){
                        $rootScope.AppVersionUpdatePopup = data.AppVersionUpdatePopup;
                        return data.AppVersionUpdatePopup;
                    })
                    .catch(function(){
                        return defaultAppVersionUpdatePopupSettings;
                    })
                    .then(function(AppVersionUpdatePopupSettings){
                        mDesign.dialog({
                            focusOnOpen: false,
                            clickOutsideToClose: false,
                            templateUrl: 'views/templates/app-version-update-dialog.html',
                            controller: ['$scope', function ($scope) {
                                    $scope.update = update;
                                    $scope.title = (AppVersionUpdatePopupSettings && AppVersionUpdatePopupSettings.title && AppVersionUpdatePopupSettings.title[Config.language.culture]) || defaultAppVersionUpdatePopupSettings.title[Config.language.culture];
                                    $scope.text = (AppVersionUpdatePopupSettings && AppVersionUpdatePopupSettings.text && AppVersionUpdatePopupSettings.text[Config.language.culture]) || defaultAppVersionUpdatePopupSettings.text[Config.language.culture];
                                    $scope.allowUpdateButtonText = (AppVersionUpdatePopupSettings && AppVersionUpdatePopupSettings.allowButtonText && AppVersionUpdatePopupSettings.allowButtonText[Config.language.culture]) || defaultAppVersionUpdatePopupSettings.allowUpdateBtn[Config.language.culture];
    
                                    function update() {
                                    if(window.cordova && cordova.plugins && cordova.plugins.diagnostic){
                                        if (window.cordova.platformId === 'ios') {
                                            window.open('https://itunes.apple.com/us/app/apple-store/id' + retailerSettings.settings.appleAppId)
                                        }
                                        else if (window.cordova.platformId === 'android') { 
                                            cordova.InAppBrowser.open('https://play.google.com/store/apps/details?id=' + retailerSettings.settings.androidBundleId, '_system', 'location=yes');
                                        }
                                    }
                                    window.location.reload()
                                    }
                            }]
                        });
                    });
                });
            }
            
            _listeners.push($scope.$watch(function() {
                return Config.language.culture;
            }, function(newCulture, oldCulture) {
                $scope.language = newCulture;
            }));

            setUser();

            function setUser() {
                User.getUserSettingsMultiLoyalty().then(function(data) {
                    $scope.user = data;
                    userData = data;
                    var loyaltyClub = data.loyaltyClubs.find(function(item) {
                        if (!Config || !Config.retailer || !Config.retailer.loyaltyClubDriver || !Config.retailer.loyaltyClubDriver.clientConfig || !Config.retailer.loyaltyClubDriver.clientConfig.loyaltyClubs || !Config.retailer.loyaltyClubDriver.loyaltyClubIds) {
                            return;
                        }
                        return item.loyaltyClubDriverId === Config.retailer.loyaltyClubDriver.loyaltyClubDriverId && Config.retailer.loyaltyClubDriver.loyaltyClubIds.includes(item.loyaltyClubId) && Config.retailer.loyaltyClubDriver.clientConfig.loyaltyClubs[item.loyaltyClubId].pointsToCashback === true;
                    })
                    if (loyaltyClub) {
                        Util.getUserCashbackPoints(loyaltyClub.id).then(function (response) {
                            $scope.userPoints = response.points || 0;
                        });
                    }
                    setMenuConfig(userData);
                });
            }
            
            function setMenuConfig(userData) {
                if (userData && userData.loyaltyClubs.length > 0) {
                    $scope.loyaltyClub = userData.loyaltyClubs.find(function(item) {
                        if (!Config || !Config.retailer || !Config.retailer.loyaltyClubDriver || !Config.retailer.loyaltyClubDriver.clientConfig || !Config.retailer.loyaltyClubDriver.clientConfig.loyaltyClubs || !Config.retailer.loyaltyClubDriver.loyaltyClubIds) {
                            return;
                        }
                        return item.loyaltyClubDriverId === Config.retailer.loyaltyClubDriver.loyaltyClubDriverId && Config.retailer.loyaltyClubDriver.loyaltyClubIds.includes(item.loyaltyClubId) && Config.retailer.loyaltyClubDriver.clientConfig.loyaltyClubs[item.loyaltyClubId].pointsToCashback === true;
                    })    
                }
                if ($scope.loyaltyClub) {
                    var loyaltyClub = Config.retailer.loyaltyClubDrivers.find(function(item) {
                        return item.loyaltyClubDriverId === $scope.loyaltyClub.loyaltyClubDriverId
                    })
                    if (loyaltyClub && loyaltyClub.clientConfig && loyaltyClub.clientConfig.loyaltyClubs) { 
                        $scope.clientConfigMenu = loyaltyClub.clientConfig.loyaltyClubs[$scope.loyaltyClub.loyaltyClubId].loyaltyPages.menu
                    }
                }
            }


            User.getUserSettings().then(function(data) {
                $scope.userAccumulatedPoints = data.points
            }) 

            $rootScope.$watch('title', function (newVal) {
                $scope.title = newVal;
                $scope.areas = [];
            });
            $scope.logout = User.logout;
            $scope.isUserVerified = User.isVerified;

            $timeout(function () {
                $scope.buildSideNav = true;
            }, 2000);

            $scope.isLoggedIn = function () {
                return !!User.getUserLoginData();
            };

            $scope.historyBack = function ($event) {
                $event && $event.preventDefault();
                $window.onBackButton();
                //history.back();
            };

            $scope.backToHub = function() {
                return HubService.backToHub(Config.hubRetailer.domainName);
            };

            //$scope.getIsDisabledClearCart = function(){
            //    return $rootScope.disableClearCart;
            //}
            //
            //
            //$scope.$watch(function() {
            //    return $rootScope.disableClearCart;
            //}, function() {
            //    setTimeout(function(){
            //        $scope.disableClearCart = $rootScope.disableClearCart;
            //        $scope.$apply();
            //    },0)
            //
            //}, true);
            $window.$mdSidenav = $mdSidenav;
            $window.addEventListener('popstate', function (event) {
                if ($mdSidenav('sidenav').isOpen()) {
                    $mdSidenav('sidenav').close();
                }
            });
            $scope.toggleSideNav = function (isOpen, $event) {
                $event && $event.preventDefault();
                $event && $event.stopPropagation();
                if (_timeoutToggleSideNav) {
                    $timeout.cancel(_timeoutToggleSideNav);
                }

                //== Update customer credits on menu open
                if(!isOpen && $rootScope.isCreditCustomer) {
                    _setUserSettings(true);
                }
                $scope.sideNavIsOpen = !isOpen;
                _timeoutToggleSideNav = $timeout(function () {
                    if (isOpen) {
                        $mdSidenav('sidenav').close();
                        document.removeEventListener('focusin', _sidenavTrapFocus);
                    } else {
                        $scope.changeLanguageMenu.show = false;
                        $mdSidenav('sidenav').open();
                        document.addEventListener('focusin', _sidenavTrapFocus);
                    }
                }, 300);
            };
            function _sidenavTrapFocus(event) {
                if (dialogOpen) {
                    return;
                }

                var sidenav = document.getElementById('header_sidenav');
                if (!sidenav.contains(event.target)) {
                    // If focus goes outside the sidenav, bring it back
                    var sidenavCloseButton = document.querySelector('#header_sidenav .close-sidenav');
                    sidenavCloseButton && sidenavCloseButton.focus();
                }
            }

            $scope.goToLoginDialog = function() {
                $mdSidenav('sidenav').close();
                Util.goToLoginDialog();
            };

            $scope.openMenuPages = function (stateName, stateParams) {
                if (_timeoutOpenMenuPages) {
                    $timeout.cancel(_timeoutOpenMenuPages);
                }
                _timeoutOpenMenuPages = $timeout(function () {
                    $mdSidenav('sidenav').close();
                    $state.go(stateName, stateParams);
                }, 300);
            };

            $scope.getUserData = function () {
                return User.getUserLoginData();
            };

            function _setUserSettings(withoutCache) {
                return User.getUserSettings(withoutCache).then(function(resp) {
                    $scope.userSettings = resp;
                });
            }
            function _onUserLogin() {
                _setUserSettings(true).then(function(){
                    _showAfterLoginPopup();
                });
            }

            function _showAfterLoginPopup() {
                var isOrganization = $scope.userSettings.organization && $scope.userSettings.organization.id,
                    isInactive = isOrganization && !$scope.userSettings.organization.isActive,
                    isMissingBranches = isOrganization && $scope.userSettings.organization.organizationType === ORGANIZATION_TYPES.OBLIGO && (!$scope.userSettings.organization.branches || $scope.userSettings.organization.branches.length === 0),
                    messageType = 0;

                if(isOrganization && (isInactive || isMissingBranches)) {
                    messageType = isInactive ? 1 : 2;
                } else if(!isOrganization && $rootScope.config.retailer.status === RETAILER_STATUSES.ORGANIZATION_ONLY) {
                    messageType = 3;
                }

                if( messageType ) {
                    return _showOrganizationInactivePopup(messageType);
                } else if($rootScope.isCreditCustomer) {
                    return _showCreditCustomerPopup();
                }
            }

            function _showCreditCustomerPopup() {
                var userSettings = $scope.userSettings;
                return mDesign.dialog({
                    controller: ['$scope', 'mDesign', function ($scope, mDesign) {
                        $scope.hide = function () {
                            mDesign.hide();
                        };
                        $scope.username = userSettings.firstName + ' ' + userSettings.lastName;
                        $scope.organizationName = userSettings.organization && userSettings.organization.name || '';
                    }],
                    templateUrl: 'views/templates/credit-customer-welcome.html'
                });
            }

            function _showOrganizationInactivePopup(messageType) {
                var userSettings = $scope.userSettings;
                return mDesign.dialog({
                    controller: ['$scope', 'mDesign', function ($scope, mDesign) {
                        $scope.hide = function () {
                            mDesign.hide();
                        };
                        $scope.messageType = messageType;
                        $scope.username = userSettings.firstName + ' ' + userSettings.lastName;
                        $scope.organizationName = userSettings.organization && userSettings.organization.name || '';
                    }],
                    templateUrl: 'views/templates/organization-inactive.html'
                }).then(function() {
                    User.logout();
                });
            }

            function _cleanUser() {
                $scope.userSettings = undefined;
            }

            if (!!User.getUserLoginData()) {
                _setUserSettings();
            }

            function _setRetailerData(withoutCache) {
                return User.getRetailerData(withoutCache).then(function (retailerData) {
                    $scope.loyaltyClubDrivers = retailerData.loyaltyClubDrivers;
                    $scope.isLoyaltyExtendedRegisterActive = retailerData.loyaltyClubDrivers.length === 1 &&  retailerData.loyaltyClubDrivers[0].clientConfig &&  retailerData.loyaltyClubDrivers[0].clientConfig.extendedLoyaltyClub;
                    retailerData.branches.forEach(function (branch) {
                        (branch.areas || []).forEach(function (area) {
                            $scope.areas.push(area);

                        });
                    });
                    $scope.retailerData = retailerData;
                });
            }

            _setRetailerData();
            $rootScope.$on('login', _onUserLogin);
            $rootScope.$on('loyaltyLogin', function() {
                $q.all([_setUserSettings(true), _setRetailerData(true)])
                    .then(function() {
                        $scope.$root.$emit('loyaltyLoginActionsFinished');
                    });
            });

            $rootScope.$on('cart.lines.add.click', function () {
                if($rootScope.noAvailableDeliverySlots) {
                    Util.showDeliveryNoAvailableSlotsPopup();
                }
            });

            $scope.clearSearch = function ($event) {
                $timeout(function () {
                    $scope.searchQuery = '';
                    $scope.autocompleteList = [];
                    if ($event) {
                        $event.preventDefault();
                        $event.stopPropagation();
                        $event.stopImmediatePropagation();
                    }
                }, 100);
            };

            $scope.getAutoCompleteOptions = function () {
                if (!$scope.searchQuery) {
                    return [];
                }

                return api.request({
                    url: '/v2/retailers/:rid/branches/:bid/products/suggest',
                    method: 'GET',
                    params: {
                        size: 6,
                        query: $scope.searchQuery
                    }
                }).then(function (data) {
                    return data.options;
                });
            };

            
            function getAutocompleteSearchText() {
                var data = Config.retailer.settings.searchText;
                if (data) {
                    var searchTextSetting = data;
                    if (Object.keys(searchTextSetting).length > 0) {
                        var searchTextJSON = JSON.parse(searchTextSetting);
                        var translations = {};
                        Object.keys(LANGUAGES).forEach(function(culture) {
                            translations[culture] = searchTextJSON[LANGUAGES[culture]];
                        })
                        $rootScope.searchText = translations;
                    }
                }
            }

            var stateBeforeSearch;
            $scope.searchByQuery = function ($event) {
                if($event && $event.option && $event.option.value) {
                    $scope.searchQuery = $event.option.value;
                }
                if ($scope.searchQuery && $scope.searchQuery.trim() !== '') {
                    var searchParams = {
                        query: $scope.searchQuery,
                        filters: undefined,
                        sort: undefined
                    };
                    if ($event && $event.filter) {
                        searchParams.filter = $event.filter.id || $event.filter;
                    }
                    $state.go('app.search', searchParams);
                } else if (!!stateBeforeSearch && $state.is('app.search')) {
                    $state.go(stateBeforeSearch.name, stateBeforeSearch.params);
                }

                _blurInput();
            };

            function _blurInput() {
                var _parentElement = $element[0].parentNode || $element[0].parentElement,
                    _inputElement = _parentElement.querySelector('input[type = "search"]');

                setTimeout(function() {
                    _inputElement.blur();
                }, 100);

                // if ($window.cordova && $window.cordova.plugins) {
                //      $window.cordova.plugins.Keyboard.close();
                // }
            }

            $rootScope.$on('$stateChangeStart', function (event, newState, newParams, oldState, oldParams) {
                if (!newState || !newState.data || newState.data.bodyClass == 'search') {
                    return;
                }

                stateBeforeSearch = oldState && oldState.name ? {name: oldState.name, params: oldParams} : null;
                $scope.clearSearch();
            });

            if (window.cordova && window.cordova.platform === 'ios') {
                $scope.handleKeypress = function(event) {
                    if (event.keyCode === 13) {
                        cordova.plugins.Keyboard.close();
                    }
                    };
            }

            $rootScope.$on('$locationChangeStart', function (event, to, params) {
                if (!!Config.branch && (!!Config.branchAreaName || Config.chooseAreaEvent !== SP_SERVICES.CHOOSE_AREA_EVENT.ENTER_SITE)) {
                    return;
                }

                if (Config.chooseAreaEvent === undefined || Config.chooseAreaEvent !== SP_SERVICES.CHOOSE_AREA_EVENT.ENTER_SITE) {
                    return;
                }

                var disablePopupOnCurrentRoute = Util.checkPopupDisabledUrls($location.path(), Config.retailer.settings.chooseAreaPopupDisabledUrls);
                if (!disablePopupOnCurrentRoute) {
                    if (document.querySelector('md-dialog-content') != null) {
                        return;
                    }

                    return ChooseAreaDialog.show(false);
                }
            });

            //$scope.cleanCart = function () {
            //    mDesign.confirm('Are you sure?', function (confirm) {
            //        confirm && $rootScope.$emit('cleanCart');
            //    });
            //};

            function showMultiDomainsOnCartActivity(defer) {
                var listeners = [];
                defer = defer || $q.defer();

                function onCartActivity() {
                    if (Util.fetchingUserArea) {
                        var watcher = $rootScope.$on('user.fetchArea.finish', function() {
                            watcher();
                            onCartActivity();
                        });
                        return;
                    }

                    angular.forEach(listeners, function(listener) {
                        listener();
                    });
                    $timeout(function () {
                        // When has edit order it will set the edit order branch and area.
                        if ((Config.branchAreaId && Config.branch && Config.branchAreaName || cart.editOrderId)) {
                            return defer.resolve();
                        }

                        ChooseAreaDialog.show(true).then(function() {
                            if (!Config.branchAreaName) {
                                cart.clear();
                                return showMultiDomainsOnCartActivity(defer);
                            }

                            defer.resolve();
                        }).catch(function() {
                            if (!Config.branchAreaName) {
                                cart.clear();
                                return showMultiDomainsOnCartActivity(defer);
                            }
                            defer.reject();
                        });
                    });
                }

                listeners.push($rootScope.$on('cart.lines.add', function (event, data) {
                    if (data && data.isSecondTime) {
                        if (!Config.branchAreaName) {
                            cart.clear();
                        }
                        return;
                    }
                    onCartActivity();
                }));

                return defer.promise;
            }

            var configuration,
                hideOnOpenSiteText = false;
            $q.all({
                configuration: Retailer.getRetailerSettings(),
                init: Config.waitForInit()
            }).then(function(results) {
                configuration = results.configuration;
                getAutocompleteSearchText();
                $scope.mobileNavbarConfig = configuration && configuration.mobileNavbarSettings ? JSON.parse(configuration.mobileNavbarSettings) : {}
                $scope.isEnableNewNavBar = $scope.mobileNavbarConfig && $scope.mobileNavbarConfig.isEnable ? $scope.mobileNavbarConfig.isEnable.appHeader : false;

                if ($scope.isEnableNewNavBar) {
                  _headerElement.addClass('header-new-navbar');
                }
                var checkForceUpdatePromise = !!platformId && Api.request({
                    method: 'GET',
                    url: '/v2/services/' + MOBILE_SERVICE_ID + '/platforms/' + platformId + '/retailers/' + Config.retailerId + '/app-version/check-force-update',
                    params: { 
                        currentVersion: currentVersion,
                        appleAppId: configuration.settings.appleAppId,
                        spVersion: window.sp.version
                    }
                }) || Promise.resolve(false);

                checkForceUpdatePromise.then(function(res) {
                    checkForceUpdate = res
                }).then(function() {
                    if (checkForceUpdate) {
                        return showAppVersionUpdateDialog(checkForceUpdate)
                    }
                    $scope.shopTitle = (configuration.settings.shopTitle && JSON.parse(configuration.settings.shopTitle)) || configuration.title;
                    $scope.showMoreLinks = false;
                    $scope.primaryLinks = [];
                    angular.forEach(configuration.toolbar, function (link) {
                        if (link.location !== LINK_LOCATIONS.TOP) {
                            $scope.showMoreLinks = true;
                            return;
                        }
    
                        $scope.primaryLinks.push({
                            href: link.embedded ? $state.href('app.links', {linkId: link.id}) : link.url,
                            text: link.name,
                            target: link.embedded ? undefined : '_blank',
                            image: link.imageUrl,
                            url: link.url,
                            isInternal: Util.isInternalUrl(link.url)
                        });
                    });
    
                    angular.forEach(configuration.publishedWebPages, function (webPage) {
                        if (webPage.locationInWebsite !== WEB_PAGE_LOCATIONS.HEADER) {
                            $scope.showMoreLinks = true;
                            return;
                        }
    
                        $scope.primaryLinks.push({
                            href: $state.href('app.webPage', {id: webPage.id}),
                            text: webPage.name,
                            image: webPage.iconUrl
                        });
                    });
    
                    $scope.enablePersonalizedCoupons = configuration.settings.enablePersonalizedCoupons;
                    $scope.discountClubActive = Config.retailer.settings.discountClubActive === 'true';
    
                    setNumberOfCoupons();
    
                    if (!Config.multiRetailers.is && !Config.branchAreaName && Config.chooseAreaEvent === SP_SERVICES.CHOOSE_AREA_EVENT.CART_ACTIVITY) {
                        showMultiDomainsOnCartActivity();
                    }
    
                    if (!!Config.branch && !Config.multiRetailers.is && (!!Config.branchAreaName || Config.chooseAreaEvent !== SP_SERVICES.CHOOSE_AREA_EVENT.ENTER_SITE)) {
                        return;
                    }
    
                    var disablePopupOnCurrentRoute = Util.checkPopupDisabledUrls($location.path(), Config.retailer.settings.chooseAreaPopupDisabledUrls);
                    var area = {};
                    if (disablePopupOnCurrentRoute && Config.chooseAreaEvent === SP_SERVICES.CHOOSE_AREA_EVENT.ENTER_SITE) {
                        Config.branch = Config.retailer.branches[0];
                        Config.branchAreaName = Config.branch.areas[0].name;
                        area = Config.branch.areas[0];
                        area.retailer = Config.retailer;
                        area.branch = Config.branch;
                        area.deliveryTypeId = SP_SERVICES.DELIVERY_TYPES.PICKUP
                        area.path = $location.path();
                        $rootScope.disablePopupOnCurrentRoute = true;
                        Config.setLocalStorageItem('mobileZuzBranchId', area.branch.id);
                        Config.setLocalStorageItem('mobileZuzBranchAreaId', area.id);
                    }
    
                    return ChooseAreaDialog.show(false, null, null, null, area);
                })
            }).then(function() {
                return TrackingTransparency.requestTrackingPermission()
            }).then(function() {
                $rootScope.status = configuration.status;
                var search = $location.search();

                if (configuration && !configuration.status) {
                    var text = (configuration.settings.closedSiteText && configuration.settings.closedSiteText.trim()) ? configuration.settings.closedSiteText : $filter('translate')('The site is undergoing maintenance');
                    return mDesign.dialog({
                        template: '<md-dialog class="closed-site-container"><md-dialog-content class="closeSite">' +  text + '</md-dialog-content></md-dialog>',
                        clickOutsideToClose: false
                    });
                }
                if (configuration && configuration.status === RETAILER_STATUSES.LP_ONLY) {
                    window.location.replace(window.location.origin + '/lp/loyalty' + window.location.search)
                    return;
                }
                if (configuration && configuration.settings && configuration.status === RETAILER_STATUSES.DISABLED) {
                    var closedSiteText = configuration.settings.closedSiteText || '<p>{{"We are sorry but this service is no longer available" | translate}}</p>';
                    return mDesign.dialog({
                        template: '<md-dialog class="disabled-site-container"><md-dialog-content class="closeSite">' + closedSiteText + '</md-dialog-content></md-dialog>',
                        clickOutsideToClose: false
                    });
                }

                if (configuration && configuration.settings && !$scope.isLoggedIn() &&
                    (configuration.status === RETAILER_STATUSES.REGISTERED_ONLY || configuration.status === RETAILER_STATUSES.ORGANIZATION_ONLY)) {
                    angular.forEach($state.get(), function(state) {
                        state.data = state.data || {};
                        // ignore user confirmation pages, validation is being done inside the controller
                        if (state.data.stateAccess !== PAGE_ACCESS.NOT_LOGGED_IN && state.name.indexOf('app.user-confirmation') === -1) {
                            state.data.stateAccess = PAGE_ACCESS.LOGGED_IN;
                        }
                    });

                    if (!User.getUserLoginData() && $state.$current.self.name !== 'app.forgottenPassword' && !search.invitedUser && !search.emailVerification && !search.userConfirmationToken) {
                        return Util.goToLoginDialog().then(function() {
                            // after an organization user is logging in the website is refreshed,
                            // this is preventing the popup to be displayed twice (after login and after refresh)
                            hideOnOpenSiteText = configuration.status === RETAILER_STATUSES.ORGANIZATION_ONLY;
                        });
                    }
                }
            }).then(function() {
                if (hideOnOpenSiteText || configuration.status !== RETAILER_STATUSES.ACTIVE &&
                    configuration.status !== RETAILER_STATUSES.REGISTERED_ONLY &&
                    configuration.status !== RETAILER_STATUSES.ORGANIZATION_ONLY) {
                    return configuration;
                }

                return _showOnOpenSiteAlertText(configuration);
            }).then(function() {
                if ($scope.isLoggedIn()) {
                    return _setUserSettings().then(function () {
                        return _showAfterLoginPopup();
                    });
                }
            }).finally(function() {
                $rootScope.intiDialogClosed = true;
                $rootScope.$emit('initDialog.closed');
            });

            function _showOnOpenSiteAlertText(configuration) {
                var search = $location.search();
                if(configuration && configuration.settings &&  configuration.settings.onOpenSiteAlertText &&
                    _convertToHtmlToCheckEmpty(configuration.settings.onOpenSiteAlertText.trim()) &&
                    configuration.settings.onOpenSiteAlertText.trim() &&
                    !Config.preventDialogsOnLoad &&
                    $state.$current.self.name !== 'app.forgottenPassword' && !search.invitedUser) {

                    var modifiedOnOpenSiteAlertText = configuration.settings.onOpenSiteAlertText;

                    if (!!window.cordova && window.cordova.platformId == 'android') {
                        var parser = new DOMParser();
                        var doc = parser.parseFromString(modifiedOnOpenSiteAlertText, 'text/html');
                        var anchorTags = doc.querySelectorAll("a[href$='.pdf']");

                        anchorTags.forEach(function(anchorTag) {
                            var originalHref = anchorTag.getAttribute('href');
                            var newHref = 'https://docs.google.com/viewer?url=' + encodeURIComponent(originalHref);
                            anchorTag.setAttribute('href', newHref);
                        });
                        modifiedOnOpenSiteAlertText = doc.documentElement.outerHTML;
                    }
                    
                    return mDesign.dialog({
                        controller: ['$scope', 'mDesign', function ($scope, mDesign) {
                            $scope.hide = function () {
                                mDesign.hide();
                            };
                        }],
                        template: '<md-dialog class="on-open-site-alert-container"><md-dialog-content class="onOpenSiteAlertText">' + modifiedOnOpenSiteAlertText + '</md-dialog-content><md-dialog-actions layout="row"><md-button ng-click="hide()" class="md-primary">{{\'OK\' | translate}}</md-button></div></md-dialog>'
                    });
                }
            }

            $rootScope.isSsoLogin = !!($window.location.search.includes('token=') && $window.location.search.includes('loginOrRegister=1'));

            $rootScope.$on('login', setNumberOfCoupons);
            $rootScope.$on('logout', setNumberOfCoupons);
            $rootScope.$on('logout', _cleanUser);
            $rootScope.$on('numberOfCouponsChanged', function (event, numberOfCoupons) {
                $scope.numberOfCoupons = numberOfCoupons;
            });
            $rootScope.$on('mDesign.shown', function () {
                dialogOpen = true;
            });
            $rootScope.$on('mDesign.closed', function () {
                dialogOpen = false;
            });

            function setNumberOfCoupons() {
                if ($scope.isLoggedIn() && $scope.enablePersonalizedCoupons) {
                    api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/branches/:bid/users/:uid/coupons',
                        params: {extended: true, countonly: true}
                    }).then(function (couponsData) {
                        var coupons = couponsData.coupons || couponsData;
                        var showReminderCoupon = null;

                        Util.setGlobalCouponRedemptions(couponsData.coupons);

                        if (coupons && coupons.length) {
                            angular.forEach(coupons, function (coupon) {
                                if (coupon.showReminder) {
                                    showReminderCoupon = coupon;                                    
                                }
                            });
                        }

                        // only need to show 1 coupon to avoid repeating popups
                        if(showReminderCoupon) {
                            Util.showCouponReminderDialog(showReminderCoupon);
                        }

                        $scope.numberOfCoupons = coupons.length;
                        $rootScope.$emit('numberOfCouponsRoot', coupons.length)

                        $rootScope.userCouponsById = Util.convertArrayToObject(coupons, 'id');
                        $rootScope.couponsWithPoints = coupons.filter(function(coupon) {
                            return coupon.pointrequirement;
                        }).map(function(coupon) {
                            return coupon.id;
                        });
                    });
                } else {
                    delete $scope.numberOfCoupons;
                }
            }

            $rootScope.goToInternalURL = function (url) {
                $location.url(url);
                $scope.toggleSideNav(true);
            };
            
            Util.destroyListeners($scope, _listeners);
        }]);
})(angular);
    
    
