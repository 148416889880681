(function (angular) {
    angular.module('mobilezuz').directive('spFileInput', [
        function () {
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    spModel: '=',
                    placeholder: '@'
                },
                template: '<span class="sp-file-input" ng-class="{\'has-value\': !!spModel}">' +
                '   <span class="title">{{spModel.name || placeholder || (\'Choose file\' | translate)}}</span>' +
                '   <span class="input">' +
                '       <input type="file"/>' +
                '   </span>' +
                '   <span class="remove" ng-click="spModel = null">x</span>' +
                '</span>',
                controller: ['$scope', '$element', function ($scope, $element) {
                    var $inputElement = angular.element($element[0].querySelector('input'));
                    $inputElement.bind('change', function (event) {
                        $scope.spModel = $inputElement[0].files[0];
                        $scope.$applyAsync();
                    });
                }]
            };
        }]);
})(angular);