angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.cart.checkout.before', {
        url: '/before',
        data: {
            validation: ['$rootScope', '$q', 'Cart', function($rootScope, $q, Cart) {
                var defer = $q.defer();

                function validate() {
                    if (Cart.serverCartId && Cart.total.actualLines) {
                        return defer.resolve();
                    }

                    return defer.reject('app.cart');
                }

                if (Cart.sendingSucceeded) {
                    validate();
                } else {
                    var listener = $rootScope.$on('cart.update.complete', function() {
                        listener();
                        validate();
                    });
                }

                return defer.promise;
            }]
        },
        views: {
            'checkoutView': {
                templateUrl: 'views/checkout/before.html',
                controller: 'CheckoutBeforeCtrl as beforeCtrl'
            }
        }
    });
}]).controller('CheckoutBeforeCtrl', ['$rootScope', 'Config', 'SpDeliveryAreasService', function($rootScope, Config, SpDeliveryAreasService) {
    delete Config.checkoutData;

    var beforeCtrl = this;

    beforeCtrl.getBranchLocation = getBranchLocation;
    beforeCtrl.branchArea = Config.getBranchArea();
    beforeCtrl.changeBranchArea = changeBranchArea;

    function getBranchLocation(area) {
        return SpDeliveryAreasService.getAreaAddressText(area, true, Config.branch);
    }

    function changeBranchArea() {
        var newDeliveryTypeId = beforeCtrl.branchArea.deliveryTypeId === $rootScope.SP_SERVICES.DELIVERY_TYPES.DELIVERY ? $rootScope.SP_SERVICES.DELIVERY_TYPES.PICKUP :  $rootScope.SP_SERVICES.DELIVERY_TYPES.DELIVERY;
        var newArea = !!Config.branch && (Config.branch.areas || []).find(function (area) {
            return area.deliveryTypeId === newDeliveryTypeId;
        });

        if (newArea) {
            Config.changeBranch(Config.branch.id, newArea.id, {});
        }
    }
}]);