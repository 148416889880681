(function (angular, app) {
    'use strict';

    app.service('DeliveryItemsLimitDialog', ['mDesign', function(mDesign) {
        this.show = show;

        function show() {
            return mDesign.dialog({
                controller: 'DeliveryItemsLimitCtrl as deliveryItemsLimitCtrl',
                templateUrl: 'views/templates/delivery-items-limit.html',
                clickOutsideToClose: true,
                escapeToClose: false,
                styleClass: 'delivery-items-limit-dialog',
                resolve: {
                    areas: [
                        'SpDeliveryAreasService', 'SP_SERVICES',
                        function(SpDeliveryAreasService, SP_SERVICES) {
                            var typedAreaText = localStorage.getItem('mobileZuzTypedAreaText');
                            if (!typedAreaText) {
                                return [];
                            }

                            return SpDeliveryAreasService.getChooseAreaMode().then(function(mode) {
                                if (mode === SP_SERVICES.CHOOSE_AREA_MODE.CHOOSE_RETAILER) {
                                    return SpDeliveryAreasService.getAreas();
                                }

                                return SpDeliveryAreasService.filterDeliveryAreas(mode, typedAreaText).then(function(resp) {
                                    return resp.areas;
                                });
                            });
                        }
                    ]
                }
            });
        }
    }]);

    app.controller('DeliveryItemsLimitCtrl', [
        '$q', 'mDesign', 'ChooseAreaDialog', 'areas',
        function ($q, mDesign, ChooseAreaDialog, areas) {
            var deliveryItemsLimitCtrl = this;

            deliveryItemsLimitCtrl.hideDialog = mDesign.hide;
            deliveryItemsLimitCtrl.changeDeliveryType = changeDeliveryType;
            deliveryItemsLimitCtrl.showChangeDeliveryMethod = areas.length > 1;
            deliveryItemsLimitCtrl.deliveryMethods = _getAvailableDeliveryMethods();

            function _getAvailableDeliveryMethods() {
                var keys = {},
                    values = [];
                angular.forEach(areas, function(area) {
                    if (keys[area.deliveryTypeId]) {
                        return;
                    }

                    keys[area.deliveryTypeId] = true;
                    values.push(area.deliveryTypeId);
                });
                return values;
            }

            function changeDeliveryType() {
                return $q.resolve(mDesign.hide()).then(function() {
                    return ChooseAreaDialog.show(true, false, localStorage.getItem('mobileZuzTypedAreaText'));
                });
            }
        }]);
})(angular, angular.module('mobilezuz'));