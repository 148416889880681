(function (angular, app) {
    'use strict';

    app.service('PermanentViewFiltersDialog', ['mDesign', function(mDesign) {
        this.show = show;

        function show() {
            return mDesign.dialog({
                templateUrl: 'views/templates/permanent-view-filters/index.html',
                controller: 'PermanentViewFiltersCtrl as permanentViewFiltersCtrl',
                resolve: {
                    PermanentFiltersGroups: ['PermanentFiltersGroups', function (PermanentFiltersGroups) {
                        return PermanentFiltersGroups.create();
                    }]
                }
            });
        }
    }]);

    app.run(['$rootScope', 'PermanentViewFiltersDialog', function ($rootScope, PermanentViewFiltersDialog) {
        $rootScope.PermanentViewFiltersDialog = PermanentViewFiltersDialog;
    }]);

    app.controller('PermanentViewFiltersCtrl', [
        '$q', '$timeout', 'Api', 'Retailer', 'SpProductTags', 'mDesign', 'User','Config', 'PermanentFilters', 'PermanentFiltersGroups',
        function($q, $timeout, Api, Retailer, SpProductTags, mDesign,User,Config, PermanentFilters, PermanentFiltersGroups) {
            var permanentViewFiltersCtrl = this,
                _totalGroups,
                _loadPromise;

            permanentViewFiltersCtrl.groups = [];
            permanentViewFiltersCtrl.loadGroups = loadGroups;
            permanentViewFiltersCtrl.clearAll = clearAll;
            permanentViewFiltersCtrl.save = save;
            permanentViewFiltersCtrl.close = mDesign.hide;
            permanentViewFiltersCtrl.selectedProductTags = PermanentFilters.values[PermanentFilters.TYPES.PRODUCT_TAGS].reduce(function(obj, value) {
                obj[value] = true;
                return obj;
            }, {});

            Retailer.getRetailerSettings().then(function (configuration) {
                permanentViewFiltersCtrl.retailer = configuration;
            });

            loadGroups();

            function loadGroups() {
                if (_loadPromise || _totalGroups !== undefined && permanentViewFiltersCtrl.groups.length >= _totalGroups) {
                    return;
                }

                _loadPromise = PermanentFiltersGroups.get(permanentViewFiltersCtrl.groups.length, 3).then(function(result) {
                    _totalGroups = result.total;
                    var allowedUsers = Config.retailer.settings.eBTAllowedUsers ? JSON.parse(Config.retailer.settings.eBTAllowedUsers) : [];
                    if (allowedUsers.length && !allowedUsers.includes(User.settings.id)) {
                      angular.forEach(result.groups, function(group){
                        group.productTags = group.productTags && group.productTags.filter(function (tag){
                          return (tag.productTagId !== 2560 && tag.productTagId !== 5364)})
                      });
                    }
                    else if (allowedUsers.length && allowedUsers.includes(User.settings.id)) {
                      angular.forEach(result.groups, function(group){
                        group.productTags = group.productTags && group.productTags.filter(function (tag){ return (tag.productTagId !== 5364)})
                      });
                    }

                    Array.prototype.push.apply(permanentViewFiltersCtrl.groups, result.groups);
                }).finally(function() {
                    _loadPromise = undefined;
                });
            }

            function clearAll() {
                angular.forEach(permanentViewFiltersCtrl.selectedProductTags, function(val, productTagId) {
                    delete permanentViewFiltersCtrl.selectedProductTags[productTagId];
                });

                return save();
            }

            function save() {
                var productTagIds = [];
                angular.forEach(permanentViewFiltersCtrl.selectedProductTags, function(val, productTagId) {
                    if (val) {
                        productTagIds.push(Number(productTagId));
                    }
                });

                permanentViewFiltersCtrl.saving = true;
                return $q.all([
                    PermanentFilters.addProductTagsFilters(productTagIds, true),
                    $timeout(function(){}, 1000)
                ]).then(function() {
                    permanentViewFiltersCtrl.saved = true;

                    if (!productTagIds.length) {
                        mDesign.hide();
                    }
                }).finally(function() {
                    permanentViewFiltersCtrl.saving = false;
                });
            }
        }
    ]);
})(angular, angular.module('mobilezuz'));
