angular.module('mobilezuz').controller('DeliveryArea', ['$scope', '$mdToast', 'mDesign',
    function ($scope, $mdToast, mDesign) {
        $scope.closeToast = function () {
            $mdToast.hide();
        };

        $scope.openAreasDialog = function ($event) {
            $event && $event.stopPropagation();

            mDesign.dialog({
                controller: 'DeliveryAreaAlert',
                templateUrl: 'views/templates/delivery-area-alert.html'
            });
        };
    }]);