angular.module('mobilezuz').directive('disableEnter', ['$timeout', 'Util', function ($timeout, Util) {
    return {
        restrict: 'A',
        link: function ($scope, element) {
            element.bind('keypress', function (event) {
                if (event && event.which === 13) {
                    event.preventDefault();
                    return false;
                }
            });

            Util.destroyListeners($scope, function () {
                element.unbind('keypress');
            });
        }
    };
}]);