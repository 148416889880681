angular.module('mobilezuz').controller('InnerPaymentDialogCtrl', [
    '$scope', 'Config', 'mDesign', 'PaymentsService', 'options',
    function ($scope, Config, mDesign, PaymentsService, options) {
        var innerPaymentCtrl = this;

        innerPaymentCtrl.paymentMethodId = options.paymentMethodId;
        innerPaymentCtrl.isAddCard = options.isAddCard;
        innerPaymentCtrl.getCardDetails = getCardDetails;
        innerPaymentCtrl.cancel = cancel;

        function getCardDetails() {
            return PaymentsService.getGiftCardBalance(options.paymentMethodId ,innerPaymentCtrl.cardNum)
                .then(function (result) {
                    mDesign.hide(result);
                }).catch(function (err) {
                    mDesign.hide({error: err});
                });
        }

        function cancel() {
            mDesign.hide();
        }
    }]);
