angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.retailer', {
        url: '/retailer',
        data: {
            stateAccess: PAGE_ACCESS.ALLOW,
            bodyClass: 'about',
            metaTags: {
                title: 'Retailer Details'
            }
        },
        views: {
            '@': {
                template: '<div class="about"></div>'
            }
        }
    });
}]);