angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.myShoppingLists', {
        url: '/shop-lists',
        data: {
            stateAccess: PAGE_ACCESS.LOGGED_IN,
            bodyClass: 'my-shopping-lists',
            metaTags: {
                title: 'Shopping lists'
            }
        },
        views: {
            '@': {
                templateUrl: 'views/my-shopping-lists.html',
                controller: 'MyShoppingListsCtrl'
            }
        }
    });

}]).controller('MyShoppingListsCtrl', ['$scope', 'ShoppingList', 'Cart', 'mDesign', "$timeout",
    function ($scope, ShoppingList, cart, mDesign, $timeout) {
        $scope.order = 'name';
        $scope.newListName = '';

        var _timeoutEditShopList,
            _timeoutNewListName;

        function _loadShoppingList() {
            ShoppingList.request('GET').then(function (respond) {
                $scope.myShoppingLists = respond.shopLists;
            });
        }
        _loadShoppingList();

        $scope.deleteShopList = function (shopList) {
            ShoppingList.request('DELETE', false, shopList.id).then(function (respond) {
                _loadShoppingList();
            });
        };

        $scope.editShopList = function (shopList) {
            shopList.newName = shopList.name;
            shopList.isEditing = true;
            if (_timeoutEditShopList) {
                $timeout.cancel(_timeoutEditShopList);
            }
            _timeoutEditShopList = $timeout(function () {
                var element = document.querySelector('input#list_new_name');
                element.focus();
                element.select();
            }, 200);
        };

        $scope.saveNewName = function (shopList) {
            if (shopList.newName) {
                ShoppingList.request('PUT', shopList.newName, shopList.id).then(function (respond) {
                    shopList.name = shopList.newName;
                });
            } else {
                mDesign.alert('Invalid name');
            }
            shopList.isEditing = false;
        };

        $scope.createNewList = function () {
            mDesign.dialog({
                focusOnOpen: true,
                clickOutsideToClose: true,
                templateUrl: 'views/templates/new-shopping-list-dialog.html',
                controller: ['$scope', function ($scope) {

                    $scope.save = save;
                    $scope.cancel = cancel;
                    $scope.newListName = '';
                    $scope.isAdd = true;

                    function cancel(){
                        mDesign.hide();
                    }

                    function save() {
                        saveShoppingList($scope.newListName, $scope.isAdd);
                        mDesign.hide();
                    }

                }]
            });
        }
        function _sendAddShopList(isAdd) {
            ShoppingList.request('POST', $scope.newListName, null, !!isAdd).then(function() {
                mDesign.alert('New shopping list was created');
                $scope.newListName = '';
                _loadShoppingList();
            });
        }

        function saveShoppingList (newListName,isAdd) {
            $scope.newListName = newListName
            if (window.cordova && window.cordova.plugins) {
                window.cordova.plugins.Keyboard.close();
            }
            $timeout(function () {
                if ($scope.newListName.length) {
                    if (cart.total.lines) {
                        _sendAddShopList(isAdd);
                    } else {
                        _sendAddShopList();
                    }
                } else {
                    mDesign.alert('Please enter list name');
                    _timeoutNewListName && $timeout.cancel(_timeoutNewListName);
                    _timeoutNewListName = $timeout(function () {
                        document.querySelector('#new-list-name').focus();
                    }, 2000);
                }
            }, 800);
        }
    }]);