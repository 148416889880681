(function (angular) {
    angular.module('mobilezuz')
        .filter('creditCardName', ['Config', function (config) {
            return function (input) {
                var language = config.language;
                var creditCardTextReplacement = config.retailer.settings.creditCardTextReplacement;
                if(creditCardTextReplacement && creditCardTextReplacement[language.id]) {
                    return input.replace(/Credit Cards?|credit cards|кредитную карту|Кредитные карты|כרטיס אשראי|כרטיסי אשראי|כרטיסי האשראי|Cartes de crédit|carte de crédit|tarjeta de credito|Tarjetas de crédito|بطاقات الائتمان|بطاقة الائتمان/gi, function(matched){
                        if(matched === 'credit cards') {
                            return creditCardTextReplacement[language.id].toLowerCase();
                        } else {
                            return creditCardTextReplacement[language.id];
                        }
                    });
                }
                return input
            };
        }]);
})(angular);
