angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.specials.special', {
        url: '/:ssid',
        data: {
            stateAccess: PAGE_ACCESS.ALLOW,
            bodyClass: 'single-sale',
            metaTags: {
                title: 'Special products'
            }
        },
        views: {
            '@': {
                templateUrl: 'views/special-products.html',
                controller: 'SpecialProductsCtrl'
            }
        }
    });
}]).controller('SpecialProductsCtrl', ['$rootScope', '$scope', '$stateParams', 'Api', 'Util', 'Cart', 'Offers',
    function ($rootScope,$scope, $stateParams, api, util, cart, offers) {
        var _listeners = [],
            isRequesting = false,
            isEmptyRequest = false;

        $scope.products = [];
        $scope.total = 0;

        function _getSaleProductsChunk(isInit) {
            if (isRequesting || (!isInit && $scope.total == $scope.products.length)) return;

            isRequesting = true;
            return offers.getOffer($stateParams.ssid, {
                from: $scope.products.length,
                size: 20
            }, {
                loadingElement: document.querySelector('.loading-progress-wrapper')
            }).then(function (resp) {
                Array.prototype.push.apply($scope.products, cart.getProducts(resp.products.products));
                $scope.total = resp.products.total;
                $scope.saleTitle = $rootScope.config.retailer.settings.isNewPromotionDesignEnabled === 'true' ? resp.special.names[$rootScope.config.language.id].promotionTag : resp.special.description;
            }).finally(function() {
                isRequesting = false;
            });
        }

        _getSaleProductsChunk(true);

        function _syncProductWithCart() {
            $scope.products = cart.getProducts($scope.products);
        }

        _listeners.push($scope.$root.$on('cart.lines.add', _syncProductWithCart));
        _listeners.push($scope.$root.$on('cart.lines.remove', _syncProductWithCart));
        _listeners.push($scope.$on('vertical-scroll-end', function () {
            _getSaleProductsChunk();
        }));

        util.destroyListeners($scope, _listeners);
    }]);
