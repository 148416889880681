(function (angular) {
    angular.module('mobilezuz').config(['SpDialogUrlManagerProvider', 'SP_URL_DIALOG_QUERY_PARAMS', function(SpDialogUrlManagerProvider, SP_URL_DIALOG_QUERY_PARAMS) {
        SpDialogUrlManagerProvider.dialog({
            queryParams: SP_URL_DIALOG_QUERY_PARAMS.PRODUCT,
            canShow: ['paramValue', function(paramValue) {
                return !isNaN(paramValue);
            }],
            paramChangedEventName: 'dialogProduct.change',
            paramResolve: ['$q', 'Api', 'paramValue', 'paramName', 'savedParamData', 'Util', function($q, Api, paramValue, paramName, savedParamData, Util) {
                paramValue = Number(paramValue);

                //to support backward compatibility
                if (paramName === 'product') {
                    if (savedParamData && savedParamData.id === paramValue) {
                        return savedParamData;
                    }

                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/branches/:bid/products/' + paramValue
                    }, {
                        fireAndForgot: true
                    });
                }

                if (savedParamData && savedParamData.productId === paramValue) {
                    Util.updateMetaByProduct(savedParamData);
                    return savedParamData;
                }

                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/branches/:bid/products',
                    params: {
                        catalogProductId: paramValue
                    }
                }, {
                    fireAndForgot: true
                }).then(function(result) {
                    if (result.total === 1) {
                        return result.products[0];
                    } else {
                        return $q.reject(new Error('Product not found'));
                    }
                }).then(function(product) {
                    Util.updateMetaByProduct(product);
                    return product;
                });
            }],
            show: ['mDesign', 'value', 'Util', function(mDesign, value, Util) {
                return mDesign.dialog({
                    multiple: true,
                    fullscreen: true,
                    templateUrl: 'views/single-product.html',
                    controller: 'SingleProductCtrl',
                    locals: {
                        product: value
                    },
                    //mDesign param
                    dontPreventRouteChange: true
                }).then(function () {
                    Util.updateMetaByProduct();
                });
            }]
        });
    }]);

    angular.module('mobilezuz').controller('SingleProductCtrl', [
        '$scope', '$timeout', '$state', '$stateParams', 'DataLayer', 'Api', 'Cart', 'Util', 'Config', 'mDesign', '$window',
        'ShoppingList', 'SpDialogUrlManager', 'product', '$rootScope', '$compile', '$filter', 'Offers', 'Retailer',
        'User', 'EnLanguage', 'SpProductTags', 'PRODUCT_TAG_TYPES', 'SUPERVISION_TAGS', 'IMAGE_TYPES', '$sce', 'AdobeAnalytics',
        function ($scope, $timeout, $state, $stateParams, DataLayer, api, cart, Util, Config, mDesign,$window,
                  ShoppingList, SpDialogUrlManager, product, $rootScope, $compile, $filter, offers, retailer,
                  User, EnLanguage, SpProductTags, PRODUCT_TAG_TYPES, SUPERVISION_TAGS, IMAGE_TYPES, $sce, AdobeAnalytics) {
            $scope.menuProduct = $stateParams.menuProduct === 'true';
                    
            var _timeoutNewListName,
                _timeoutNewListNameFocus,
                _listeners = [],
                _retailerSettings;

            var _translateFilter = $filter('translate'),
                _currencyFilter = $filter('currency'),
                _regularPriceFilter = $filter('regularPrice'),
                _productImagesFilter = $filter('productImages'),
                _nameFilter = $filter('name');

            retailer.getRetailerSettings().then(function (response) {
                _retailerSettings = response;
                $scope.showSaleExperations = response.showSpecialsValidity;
                _setSimilarProducts();
            });

            function _init() {
                if (!product) {
                    return _noProductFound();
                }

                $scope.nutritionFacts = {};
                _setNutritionFacts();

                $scope.product = cart.getProduct(product);

                if($scope.product.branch && $scope.product.branch.isOutOfStock) {
                    User.injectSuggestionsForProducts([product]);
                }

                DataLayer.push(DataLayer.EVENTS.SELECT_ITEM, {products: [product]});
                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Open Product Page'}});

                AdobeAnalytics.newPageEvent();

                _initOriginCountry();
                _initImages();
                _setCategoryType();
                _initSupervisions();
                _initProductOffers();
                _initProductTags();
                _setPricingMethods();
                _setSimilarProducts();
                _setDisplayLocationComponent();
                _initPeriousAndNextButtons();
                _setProductPageMessages();
                _setAttributeData();
                _setProductProperties();

                DataLayer.push(DataLayer.EVENTS.VIEW_ITEM, {products: [product]});
                $rootScope.$emit('productPage.open', product, product.itemsData);
            }

            _init();

            function _setCategoryType() {
                Util.getCategoryActualTypeByProductData($scope.product).then(function(categoryType) {
                    $scope.product.categoryType = categoryType;
                });
            }

            function _setDisplayLocationComponent() {
                $scope.showInStoreProductLocation = false;
                if( !(product.branch && product.branch.aisleName) ) {
                    return;
                }

                if(! (Config.retailer && Config.retailer.settings && Config.retailer.settings.showInStoreProductLocation) ) {
                    return;
                }
                $scope.showInStoreProductLocation = Config.retailer.settings.showInStoreProductLocation === 'true';
            }

            function _initProductOffers() {
                $scope.productOffers = [];
                if ($scope.product.branch.specials) {
                    for (var i = 0; i < $scope.product.branch.specials.length; i++) {
                        if (Config.showCouponsSpecials || !$scope.product.branch.specials[i].isCoupon) {
                            $scope.productOffers.push($scope.product.branch.specials[i]);
                        }
                    }
                }
            }

            function _initProductTags() {
                $scope.productTags = [];
                if ($scope.product.productTagsData) {
                    angular.forEach($scope.product.productTagsData, function(productTag) {
                        if (SpProductTags.isProductTagRelationActive(productTag) && productTag.typeId !== PRODUCT_TAG_TYPES.LANDING_PAGE) {
                            $scope.productTags.push(productTag);
                        }
                    });
                }
            }

            function _setProductProperties() {
                if ($scope.product.productProperties && $scope.product.productProperties.length) {
                    $scope.productProperty = $scope.product.productProperties[0];

                    var line = getLine();
                    if (line && line.productPropertyValue && line.productPropertyValue.id) {
                        var selectedPropertyValue = _getPropertyValue(line.productPropertyValue.id);
                        if (selectedPropertyValue.id) {
                            $scope.selectedPropertyValueId = selectedPropertyValue.id;
                        }
                    } else {
                        $scope.selectedPropertyValueId = null;
                    }
                }
            }

            function _setSimilarProducts() {
                if (!_retailerSettings) return;

                var requestParams = {
                    method: 'GET',
                    params: {
                        from: 0,
                        size: 15,
                        filters: {
                            must: {
                                term: {
                                    'branch.isActive': true,
                                    'branch.isVisible': true
                                }
                            },
                            mustNot: {
                                term: {id: $scope.product.id}
                            }
                        }
                    }
                };

                if (Config.retailer.settings.showUnavailableProducts !== 'true') {
                    requestParams.params.filters.mustNot.term["branch.regularPrice"] = 0;
                    requestParams.params.filters.mustNot.term["branch.isOutOfStock"] = true;
                }
                else {
                    if (Config.retailer.settings.isOutOfStockDuration === 'true') {
                        requestParams.params.filters.bool = {
                            should: [
                                {
                                    bool: {
                                        must_not: {
                                            exists: {
                                                field: "branch.outOfStockShowUntilDate"
                                            }
                                        }
                                    }
                                },
                                {
                                    bool: {
                                        must: [
                                            {
                                                range: {
                                                    "branch.outOfStockShowUntilDate": {
                                                        gt: "now"
                                                    }
                                                }
                                            },
                                            {
                                                term: {
                                                    "branch.isOutOfStock": true
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    bool: {
                                        must: [
                                            {
                                                term: {
                                                    "branch.isOutOfStock": false
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                }

                if (_retailerSettings.productPageCarouselContent === 2 && product.branch.specials && product.branch.specials.length && !product.branch.specials[0].hasOneProduct) { // show special products
                    requestParams.url = '/v2/retailers/:rid/branches/:bid/specials/' + product.branch.specials[0].id;
                    $scope.carouselTitle = $rootScope.config.retailer.settings.isNewPromotionDesignEnabled === 'true' ? product.branch.specials[0].names[$rootScope.config.language.id].promotionTag : product.branch.specials[0].description;
                } else {
                    requestParams.url = '/v2/retailers/:rid/branches/:bid/products';
                    requestParams.params.filters.must.term['family.id'] = $scope.product.family.id;
                }

                api.request(requestParams).then(function (similarProducts) {
                    if (similarProducts.products.products) similarProducts = similarProducts.products; // special products support
                    if ($scope.menuProduct) {
                        similarProducts.products.forEach(function (prod) {
                            prod.image = {
                                url: 'https://d226b0iufwcjmj.cloudfront.net/global/mobile/images/icons/icon-restaurant-menu-item.png'
                            };
                        });
                    }

                    $scope.similarProducts = similarProducts.products;
                    $scope.similarProductsTotal = similarProducts.total;

                    User.injectSuggestionsForProducts($scope.similarProducts);

                });
            }

            function _noProductFound() {
                mDesign.alert('Product not found', function () {
                    $state.go('app.home');
                });
            }

            function _setNutritionFacts() {
                if (!product.nutritionFacts)return;

                angular.forEach(product.nutritionFacts, function (productNutritionFact) {
                    productNutritionFact.value = productNutritionFact.value ? productNutritionFact.value : 0;
                    $scope.nutritionFacts[productNutritionFact.nutritionFact.name[0].toLowerCase() + productNutritionFact.nutritionFact.name.substring(1)] = productNutritionFact;
                });
                if ($scope.nutritionFacts.totalFat)$scope.nutritionFacts.totalFat.dailyVal = $scope.nutritionFacts.totalFat.value ? (($scope.nutritionFacts.totalFat.value / 65) * 100).toFixed() : 0;
                if ($scope.nutritionFacts.saturatedFat)$scope.nutritionFacts.saturatedFat.dailyVal = $scope.nutritionFacts.saturatedFat.value ? (($scope.nutritionFacts.saturatedFat.value / 20) * 100).toFixed() : 0;
                if ($scope.nutritionFacts.transFat)$scope.nutritionFacts.transFat.dailyVal = $scope.nutritionFacts.transFat.value ? (($scope.nutritionFacts.transFat.value / 20) * 100).toFixed() : 0;
                if ($scope.nutritionFacts.cholesterol) $scope.nutritionFacts.cholesterol.dailyVal = $scope.nutritionFacts.cholesterol.value ? (($scope.nutritionFacts.cholesterol.value / 300) * 100).toFixed() : 0;
                if ($scope.nutritionFacts.sodium)$scope.nutritionFacts.sodium.dailyVal = $scope.nutritionFacts.sodium.value ? (($scope.nutritionFacts.sodium.value / 2400) * 100).toFixed() : 0;
                if ($scope.nutritionFacts.totalCarbohydrates)$scope.nutritionFacts.totalCarbohydrates.dailyVal = $scope.nutritionFacts.totalCarbohydrates.value ? (($scope.nutritionFacts.totalCarbohydrates.value / 300) * 100).toFixed() : 0;
                if ($scope.nutritionFacts.dietaryFiber)$scope.nutritionFacts.dietaryFiber.dailyVal = $scope.nutritionFacts.dietaryFiber.value ? (($scope.nutritionFacts.dietaryFiber.value / 25) * 100).toFixed() : 0;
            }

            function _initOriginCountry() {
                try {
                    if (Config.retailer.settings.agProductsOriginLegalText && typeof Config.retailer.settings.agProductsOriginLegalText === 'string') {
                        $scope.agProductsOriginLegalText = JSON.parse(Config.retailer.settings.agProductsOriginLegalText);
                    }

                    if (Config.retailer.settings.isAgProductsOriginFromPOSActive && typeof Config.retailer.settings.isAgProductsOriginFromPOSActive === 'string') {
                        $scope.isAgProductsOriginFromPOSActive = JSON.parse(Config.retailer.settings.isAgProductsOriginFromPOSActive);
                    }

                    if (Config.retailer.settings.isAgProductsOriginLegalTextActive && typeof Config.retailer.settings.isAgProductsOriginLegalTextActive === 'string') {
                        $scope.isAgProductsOriginLegalTextActive = JSON.parse(Config.retailer.settings.isAgProductsOriginLegalTextActive);
                    }
                } catch (e) {
                    console.log(e);
                }
            }

            function _initImages() {
                $scope.images = _productImagesFilter($scope.product);
                if ($scope.images && $scope.images.length > 0) {
                    $scope.images = $scope.images.sort(function (a, b) {
                        var aSort = a.isDefault && 0 || a.sort !== undefined && a.sort+1 || (a.isDefault ? 0: 100),
                            bSort =  a.isDefault && 0 || b.sort !== undefined && b.sort+1  || (b.isDefault ? 0: 100);
                        var s = aSort - bSort;
                        if(s === 0){
                            s = a.id - b.id;
                        }
                        return s;
                    });
                }
                angular.forEach($scope.images, function(image) {
                    if (image.typeId === IMAGE_TYPES.IMAGE360) {
                        $scope.has360Image = true;
                    }
                });
            }

            function _initSupervisions() {
                var supervisionMap = {};
                angular.forEach(SUPERVISION_TAGS, function(stringValue, identifier) {
                    supervisionMap[identifier] = [];
                });

                angular.forEach($scope.product.supervisions, function(supervision) {
                    angular.forEach(supervision.tags, function(tag) {
                        supervisionMap[tag].push(supervision.names);
                    });
                });

                $scope.product.supervisionArray = [];
                $scope.selectedShopList = null;
                angular.forEach(supervisionMap, function(value, key) {
                    if (value && value.length) {
                        $scope.hasSupervisions = true;
                    }

                    $scope.product.supervisionArray.push({
                        id: key,
                        values: value
                    });
                });
            }

            $scope.getLine = getLine;

            function _setPricingMethods() {
                $scope.pricingMethod = 1;
                $scope.methods = [];
                if ($scope.product.branch) {
                    if ($scope.product.branch.case && $scope.product.branch.regularPrice != $scope.product.branch.case.price || !$scope.product.branch.case) {
                        $scope.methods.push({
                            val: 1,
                            text: _translateFilter('Single')
                        });
                    }
                    if ($scope.product.branch.packQuantity) {
                        if (!$scope.product.isCaseMode) {
                            $scope.pricingMethod = 2;
                            $scope.isPack = true;
                        }
                        $scope.methods.push({
                            val: 2,
                            text: $scope.product.branch.packQuantity + ' / ' + _currencyFilter(($scope.product.branch.packPrice ? $scope.product.branch.packPrice : _regularPriceFilter($scope.product) * $scope.product.branch.packQuantity))
                        });
                    }
                    if ($scope.product.branch.case && !!$scope.product.branch.case.price) {
                        if($scope.product.isCaseMode) {
                            $scope.pricingMethod = 3;
                        }
                        $scope.methods.push({
                            val: 3,
                            text: _translateFilter('Case')
                        });
                    }
                }
            }

            function _setProductPageMessages() {
                if( !(Config && Config.retailer && Config.retailer.productPageMessages) ) {
                    return {};
                }

                var response = {};

                //== Run for all messages and fill data for each message's language
                angular.forEach(Config.retailer.productPageMessages, function(message) {
                    response[message.languageId] = message;
                });
                $scope.productPageMessage = response[$scope.$root.config.language.id];
            }


            function _setAttributeData() {

                $scope.attributesList = [];  

                if (product.data) {
                    var displayedAttributesList = _nameFilter(product.data, 'attributesList');
                    if (displayedAttributesList) {
                        $scope.attributesList = JSON.parse(displayedAttributesList);
                    }
                }
            }


            $scope.toggle = toggle;
            function toggle(val, $event) {
                $scope.pricingMethod = val;
                if (val === 1) {
                    $scope.isPack = false;
                    $scope.product.isCaseMode = false;
                }
                if (val === 2) {
                    $scope.isPack = true;
                    $scope.product.isCaseMode = false;
                }
                if (val === 3) {
                    $scope.isPack = false;
                    $scope.product.isCaseMode = true;
                }
                $event && $event.stopPropagation();
            }

            //== show HTML from backend's CKEditor
            $scope.trustedHtml = function (text) {
                return $sce.trustAsHtml(text);
            };
            

            //load full product info for "View similar items" section

            $scope.addToCart = function (event, selectedPropertyValueId) {
                if ($scope.productProperty && $scope.productProperty.id && !$scope.selectedPropertyValue) {
                    $scope.showProductPropertyError = true;
                    return;
                }

                event && event.preventDefault();
                var line = { isCase: $scope.product.isCaseMode, product: $scope.product, quantity: $scope.isPack ? $scope.product.branch.packQuantity : null };
                if ($scope.selectedPropertyValue && $scope.product.productProperties) {
                    line.productPropertyValue = $scope.selectedPropertyValue;
                }
                var isLimitPassed = cart.checkHeavyLimitBeforeAddLine(line);
                if (isLimitPassed === false) {
                    if (cart.lines && Object.keys(cart.lines).length === 0 &&
                        $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                        cart.addDeliveryFeeLineIfNeeded();
                    }
                    cart.addLine(line, line.product && line.product.soldBy, {source: 'ProductPage'});
                }

                $timeout(function () {
                    var $focusElement = angular.element(document.querySelector('#line_quantity_' + product.id + ' .product-action.action-increase'));
                    $focusElement && $focusElement.focus();
                });
            };

            function _getPropertyValue(valueId) {
                if (!valueId) return;

                var selectedValue = $scope.productProperty.values.find(function (value) {
                   return value.id === valueId;
                });

                selectedValue.propertyNames = $scope.productProperty.names;
                return selectedValue;
            }

            $scope.openAddToShoplist = function () {
                var mustLoginMessage = function () {
                    mDesign.confirm({
                        content: 'You must login first',
                        ok: 'connect',
                        cancel: 'cancel'
                    }, function (isTrue) {
                        if (!isTrue) {
                            return;
                        }

                        var retStateParams = JSON.stringify($state.params);
                        SpDialogUrlManager.backClose().then(function() {
                            return Util.goToLoginDialog(null, null, null, retStateParams);
                        });
                    });
                };

                if ($scope.productProperty && $scope.productProperty.id && !$scope.selectedPropertyValue) {
                    $scope.showProductPropertyError = true;
                    $scope.shoplistsOpen = false;
                    return;
                }

                try {
                    var userLoginData = User.getUserLoginData();
                    if (!userLoginData || !userLoginData.uid) {
                        return mustLoginMessage();
                    }
                } catch (e) {
                    return mustLoginMessage();
                }

                $scope.shoplistsOpen = true;
                var url = '/v1/retailers/:rid/users/:uid/shopListsWithItems/' + $scope.product.id;
                var params = {};
                if($scope.product.isCaseMode){
                    params.iscase = $scope.product.isCaseMode;
                }
                api.request({
                    method: 'GET',
                    url: url,
                    cache: false,
                    params: params
                }).then(function (data) {
                    if (Object.keys(data).length) {
                        $scope.savedToShopList = data.formattedNameList;
                        $scope.shopLists = data.dontContainProductList;
                    } else {
                        $scope.shoplistsOpen = false;
                        mDesign.alert('Not found shop lists');
                    }
                });
            };

            $scope.closeAddToShoplist = function () {
                $scope.shoplistsOpen = false;
            };

            var quantity = 1;
            if ($scope.product.singleLine && $scope.product.singleLine.quantity) {
                quantity = $scope.product.singleLine.quantity;
            }

            $scope.addToShoplist = function () {
                $scope.selectedShopList = $scope.shopLists.find(function callbackFn(shopList) {
                    return shopList.Id === parseInt($scope.shoplistId);
                });
                DataLayer.push(DataLayer.EVENTS.ADD_TO_WISH_LIST, {wishListItem: $scope.product});
                api.request({
                    method: 'POST',
                    url: 'retailers/:rid/users/:uid/shopLists/' + $scope.shoplistId + '/products/' + $scope.product.id,
                    data: {
                        quantity: $scope.product.singleLine && $scope.product.singleLine.quantity || quantity,
                        productPropertyValueId: $scope.selectedPropertyValue && $scope.selectedPropertyValue.id || null,
                        comments: $scope.product.singleLine && $scope.product.singleLine.comments || '',
                        isCase: $scope.product.isCaseMode || null


                    }

                }).then(function () {
                    $rootScope.$emit('cart.lines.added.to.mylist', [$scope.product.id]);
                    mDesign.alert('Product added to shop list');

                    if ($scope.savedToShopList) {
                        $scope.savedToShopList = $scope.savedToShopList + ', ' + $scope.selectedShopList.Name;
                    } else $scope.savedToShopList = $scope.selectedShopList.Name;

                    $scope.shopLists = $scope.shopLists.filter(function(item) {
                        return item.Id !== $scope.selectedShopList.Id
                    });
                }).catch(function (error) {
                    if(error.status === 409) {
                        $scope.savedToShopList = true;
                    }
                }).finally(function () {
                    $scope.shoplistsOpen = false;
                });
            };
            $scope.openAddNewList = function () {
                $scope.newListOpen = true;
            };
            $scope.newListName = '';
            $scope.saveShoppingList = function () {
                if (window.cordova && window.cordova.plugins) {
                    window.cordova.plugins.Keyboard.close();
                }
                if (_timeoutNewListName) {
                    $timeout.cancel(_timeoutNewListName);
                }
                _timeoutNewListName = $timeout(function () {
                    var quantity = 1
                    if($scope.product.singleLine && $scope.product.singleLine.quantity) {
                        quantity = $scope.product.singleLine.quantity
                    }
                    if ($scope.newListName.length) {
                        ShoppingList.request('POST', $scope.newListName, null).then(function (respond) {
                            $scope.listId = respond.id;
                        }).then(function () {
                            return api.request({
                                method: 'POST',
                                url: 'retailers/:rid/users/:uid/shopLists/' + $scope.listId + '/products/' + $scope.product.id,
                                data: {quantity: quantity, productPropertyValueId: ($scope.selectedPropertyValue && $scope.selectedPropertyValue.id || null)}
                            });
                        }).then(function () {
                            $rootScope.$emit('cart.lines.added.to.mylist', [$scope.product.id]);
                            mDesign.alert('Product added to shop list');
                        }).finally(function () {
                            $scope.newListOpen = false;
                            $scope.shoplistsOpen = false;
                            $scope.newListName = '';
                        });
                    } else {
                        mDesign.alert('Please enter list name');
                        if (_timeoutNewListNameFocus) {
                            $timeout.cancel(_timeoutNewListNameFocus);
                        }
                        _timeoutNewListNameFocus = $timeout(function () {
                            angular.element(document.querySelector('#new-list-name')).focus();
                        }, 2000);
                    }
                }, 800);
            };

            $scope.comment = function ($event, product) {
                $event && $event.preventDefault(); // to stop href of parent event from firing (default browser event)
                // and to stop the browser from automatically setting the md-dialog cancel in focus
                mDesign.dialog({
                    multiple: true,
                    focusOnOpen: false,
                    controller: 'CartLineComment',
                    templateUrl: 'views/templates/cart-line-comment.html',
                    resolve: {
                        line: [function () {
                            return cart.getProductLine(product);
                        }]
                    }
                });
            };

            $scope.isTwoModes = function (product) {
                if (product.branch.case && product.branch.case.price) {
                    if (product.branch.case.price == product.branch.regularPrice) {
                        product.isCaseMode = true;
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            };

            $scope.offerInfo = function (id) {
                $state.go('app.specials.special', {ssid: id});
            };

            $scope.prtoductTabs = {
                SIMILAR_PRODUCT: 1,
                PRODUCT_NUTRITION_FACT: 2,
                PRODUCT_DESCRIPTION: 3
            };

            $scope.selectedTab = $scope.prtoductTabs.SIMILAR_PRODUCT;

            $scope.pickTab = pickTab;

            function pickTab(nextTab) {
                $scope.selectedTab = nextTab;
            }

            function getLine() {
                return cart.getProductLine($scope.product);
            }

            function _syncProductWithCart() {
                $scope.product = cart.getProduct($scope.product);
            }

            function _changeProduct(event, newProduct) {
                product = newProduct;
                delete $scope.similarProducts;
                delete $scope.similarProductsTotal;
                _init();
            }

            $scope.imagesDialog = function($event, is360) {
                SpDialogUrlManager.setOverlayDialog(mDesign.dialog({
                    multiple: true,
                    controller: 'ProductImagesDialogCtrl as productImagesDialogCtrl',
                    templateUrl: 'views/product-images.html',
                    locals: { images: angular.copy($scope.images), is360Mode: is360 },
                    targetEvent: $event
                }));
            };

            function _initPeriousAndNextButtons() {
                $scope.showPreviousNextProductLink = $scope.product && $scope.product.itemsData && $scope.product.itemsData.total > 1;
            }

            $scope.previousProduct = previousProduct;
            function previousProduct() {
                $scope.productProperty = null;
                if($scope.product.itemsData) {
                    var previousProduct = $scope.product.itemsData.previous($scope.product.itemsData.realIndex);
                    if(previousProduct && previousProduct.productId) {
                        Util.goToProductDialog(previousProduct);
                    }
                }
            }

            $scope.nextProduct = nextProduct;
            function nextProduct() {
                $scope.productProperty = null;
                if($scope.product.itemsData) {
                    var nextProduct = $scope.product.itemsData.next($scope.product.itemsData.realIndex);
                    if(nextProduct && nextProduct.productId) {
                        Util.goToProductDialog(nextProduct);
                    }
                }
            }

            $scope.onPropertyValueChanged = onPropertyValueChanged;
            function onPropertyValueChanged(selectedPropertyValueId) {
                $scope.showProductPropertyError = null;

                $scope.selectedPropertyValue = _getPropertyValue(selectedPropertyValueId);
                var line = getLine();
                if (!selectedPropertyValueId || !line) {
                    return;
                }

                line.productPropertyValue = $scope.selectedPropertyValue;
                line.productPropertyValueId = selectedPropertyValueId;

                cart.productPropertyValueChanged(line)
            }

            _listeners.push($scope.$root.$on('cart.lines.add', _syncProductWithCart));
            _listeners.push($scope.$root.$on('cart.lines.remove', _syncProductWithCart));
            _listeners.push($scope.$root.$on('dialogProduct.change', _changeProduct));

            Util.destroyListeners($scope, _listeners);
            
            var windowElement = angular.element($window);

            function _hideProductPropertySelectBox(e){
                var selectBox = document.querySelector('.md-select-menu-container');
                if(!e.target.closest('.md-select-menu-container') && !e.target.closest('.select-property-value')){
                    if(selectBox)
                    selectBox.style.display = 'none';
                }
            }

            windowElement.bind('touchmove',_hideProductPropertySelectBox);
            windowElement.bind('click',_hideProductPropertySelectBox);
            windowElement.bind('mousewheel',_hideProductPropertySelectBox);

            $scope.$on('$destroy', function() {
                windowElement.unbind('scroll',_hideProductPropertySelectBox);
                windowElement.unbind('click',_hideProductPropertySelectBox);
                windowElement.unbind('mousewheel',_hideProductPropertySelectBox);
            });
            
        }]);
})(angular);
