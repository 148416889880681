angular.module('mobilezuz').directive('passwordEye', [function () {
    return {
        restrict: 'E',
        scope: {
            value: '<'
        },
        replace: true,
        template: '<div class="eye-content ng-hide">' +
                  ' <span class="eye-icon" tabindex="0" aria-label="{{\'show password\' | translate}}"></span>' +
                  '</div>',
        link: function ($scope, $element) {
            var startEventName = 'mousedown',
                endEventName = 'mouseup',
                startEnterKeyEvent = 'keydown',
                endEnterKeyEvent = 'keyup';

            if ('ontouchstart' in window) {
                startEventName = 'touchstart';
                endEventName = 'touchend';
            }

            function _bindStart() {
                $element.bind(startEventName, _onStart);
                $element.bind(startEnterKeyEvent, _onStart);
            }

            function _unbindStart() {
                $element.unbind(startEventName, _onStart);
                $element.unbind(startEnterKeyEvent, _onStart);
            }

            function _bindEnd() {
                $element.bind(endEventName, _onEnd);
                $element.bind(endEnterKeyEvent, _onEnd);
            }

            function _unbindEnd() {
                $element.unbind(endEventName, _onEnd);
                $element.unbind(endEnterKeyEvent, _onEnd);
            }

            function _findMdInputContainerParent() {
                var parentRow = $element;
                while (parentRow.length && parentRow[0].tagName.toLowerCase() !== 'md-input-container') {
                    parentRow = angular.element(parentRow[0].parentElement || parentRow[0].parentNode);
                }
                return parentRow;
            }

            function _onStart() {
                var parentRow = _findMdInputContainerParent(),
                    inputPassword = angular.element(parentRow[0].querySelector('input[type="password"]')),
                    inputText = angular.element(parentRow[0].querySelector('input.replacement'));
                if (!inputText.length) {
                    inputText = angular.element(inputPassword).clone();
                    inputText.attr('type', 'text').addClass('replacement');
                    inputPassword.after(inputText);
                }
                inputText.val(inputPassword.val());
                parentRow.addClass('show-password');

                _unbindStart();
                _bindEnd();
            }

            function _onEnd() {
                var parentRow = _findMdInputContainerParent();
                parentRow.removeClass('show-password');

                _unbindEnd();
                _bindStart();
            }

            _bindStart();

            // Just show password eye when input password have value
            setTimeout(function(){
                var parentRow = _findMdInputContainerParent(),
                inputPassword = angular.element(parentRow[0].querySelector('input[type="password"]'));
                
                if(inputPassword.val()){
                    $element.removeClass('ng-hide');
                }
                inputPassword.on('keyup', function(){
                    var val = inputPassword.val();
                    if(!val){
                        $element.addClass('ng-hide');
                    }
                    else{
                        // $element.addClass('ng-show');
                        $element.removeClass('ng-hide');
                    }
                });                
            },500);

        }
    };
}]);