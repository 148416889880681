angular.module('mobilezuz').controller('LimitHeavyPackageDialog', ['$scope', 'mDesign', 'maxQuantity', 'displayName', 'iconUrl',
    function ($scope, mDesign, maxQuantity, displayName, iconUrl) {
        $scope.dialog = mDesign;
        $scope.maxQuantity = maxQuantity;
        $scope.displayName = displayName;
        $scope.iconUrl = iconUrl;

        $scope.hide = function () {
            mDesign.cancel();
        };
    }
]);