(function(angular) {
    var NOT_CONNECTED_ERROR = new Error('Not connected');

    // for testing in browser
    // window.facebookConnectPlugin = {
    //     login: function(fields, callback, errorCallback) {
    //         setTimeout(function() {
    //             callback({
    //                 authResponse: 'test auth response'
    //             });
    //         }, 1000);
    //     },
    //     api: function(path, something, callback) {
    //         setTimeout(function() {
    //             callback({
    //                 email: 'test@email.com',
    //                 id: 1
    //             });
    //         }, 1000);
    //     },
    //     getLoginStatus: function(callback, bool) {
    //         setTimeout(function() {
    //             callback({
    //                 status: 'connected',
    //                 authResponse: {
    //                     accessToken: 'test-token'
    //                 }
    //             });
    //         }, 1000);
    //     }
    // };

    function srv($q, Api, User, mDesign) {
        var self = this;

        self.login = login;

        function login() {
            return _facebookLogin().then(function(user) {
                if (user.authResponse) {
                    return _facebookGetMe();
                } else {
                    throw new Error('No auth response');
                }
            }).then(function(me) {
                return _facebookGetAccessToken().then(function(accessToken) {
                    return {
                        accessToken: accessToken,
                        me: me
                    };
                }).catch(function(err) {
                    if (err === NOT_CONNECTED_ERROR) {
                        return login();
                    }

                    throw err;
                });
            }).then(function(facebookLoginResults) {
                return _loginWithFacebookResults(facebookLoginResults.accessToken, facebookLoginResults.me);
            }).catch(function(err) {
                if (err) {
                    var message = err.errorMessage || err.message;
                    mDesign.alert('Facebook Login failed' + (message ? ' - ' + message : '') + (err.errorCode ? ' - ' + err.errorCode : ''));
                }

                throw err;
            });
        }

        function _facebookLogin() {
            return new $q(function(resolve, reject) {
                facebookConnectPlugin.login(['email'], function (user) {
                    resolve(user);
                }, function (err) {
                    if (Number(err.errorCode) === 4201) {
                        return reject();
                    }

                    reject(err);
                });
            });
        }

        function _facebookGetMe() {
            return new $q(function(resolve, reject) {
                facebookConnectPlugin.api('/me', null, function (me) {
                    resolve(me);
                });
            });
        }

        function _facebookGetAccessToken() {
            return new $q(function(resolve, reject) {
                facebookConnectPlugin.getLoginStatus(function (response) {
                    if (response.status === 'connected') {
                        resolve(response.authResponse.accessToken);
                    } else {
                        reject(NOT_CONNECTED_ERROR);
                    }
                }, true);
            });
        }

        function _loginWithFacebookResults(accessToken, me) {
            return Api.request({
                method: 'POST',
                url: 'retailers/:rid/sessions/facebook',
                data: {
                    access_token: accessToken
                }
            }, {
                hideError: true
            }).then(function (respond) {
                if (respond && respond.token) {
                    User.setUserLoginData({
                        uid: respond.userId,
                        email: me.email,
                        token: respond.token,
                        organizationId: respond.organizationId,
                        organizationTypeId: respond.organizationTypeId,
                        organizationRoleId: respond.organizationRoleId,
                        organizationBranches: respond.organizationBranches
                    }, true);
                } else {
                    throw new Error('Unknown facebook login error');
                }
            }).catch(function(data) {
                throw new Error(data.response.error);
            });
        }
    }

    angular.module('mobilezuz')
        .service('Facebook', ['$q', 'Api', 'User', 'mDesign', srv]);
})(angular);
