(function (angular) {
    "use strict";

    angular.module('mobilezuz').directive('spIframe', [
        function () {
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    src: '@'
                },
                template: '<iframe ng-src="{{$root.util.trustSrc(src)}}" frameborder="0" allowfullscreen></iframe>'
            };
        }]);
})(angular);