(function (angular) {
    angular.module('mobilezuz')
        .directive('subHeader', [function() {
            return {
                restrict: 'A',
                transclude: true,
                template: '' +
                    '<div ng-transclude></div>',
                link: function ($scope, $element, attrs) {
                    var $scrollableContent = angular.element(document.querySelector('#main > .content')),
                        $parent = $element.parent(),
                        $transcludeElement = angular.element($element[0].querySelector(':scope > [ng-transclude]')),
                        _toolBarContent = $scrollableContent[0].querySelector('[tool-bar] > .tool-bar-content'),
                        _trackElement = attrs.subHeader ? $transcludeElement[0].querySelector(attrs.subHeader) : $transcludeElement[0],
                        _activeTopPosition, _activeBottomPosition,
                        _isActive = false;

                    $scope.$on('toolBar.change', _toolBarChange);
                    $scrollableContent.bind('scroll', _setIsActive);
                    $scope.$on('$destroy', function() {
                        $scrollableContent.unbind('scroll', _setIsActive);
                    });

                    function _setIsActive() {
                        var scrollTop = $scrollableContent[0].scrollTop;

                        if (!_isActive) {
                            _activeTopPosition = scrollTop -
                                $scrollableContent[0].getBoundingClientRect().top +
                                _trackElement.getBoundingClientRect().top;

                            _activeBottomPosition = _activeTopPosition + $parent.prop('offsetHeight');
                        }

                        var toolBarHeight = _toolBarContent.offsetHeight;
                        var newIsActive = scrollTop > (_activeTopPosition - toolBarHeight) &&
                            scrollTop < (_activeBottomPosition - toolBarHeight);
                        if (newIsActive !== _isActive) {
                            _isActive = newIsActive;

                            if (_isActive) {
                                $element.css('height', $element.prop('offsetHeight') + 'px');
                                $transcludeElement.css('top', toolBarHeight + 'px');
                                $element.addClass('active');
                            } else {
                                $element.removeClass('active');
                                $transcludeElement.css('top', '');
                                $element.css('height', '');
                            }
                        }
                    }

                    function _toolBarChange() {
                        if (_isActive) {
                            $transcludeElement.css('top', _toolBarContent.offsetHeight + 'px');
                        }

                        _setIsActive();
                    }
                }
            };
        }]);
})(angular);