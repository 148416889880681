(function(angular) {
	angular.module('mobilezuz')
		.config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
			$stateProvider.state('app.toolbar', {
				url: '/toolbar',
				data: {
					stateAccess: PAGE_ACCESS.ALLOW,
					bodyClass: 'toolbar'
				},
				views: {
					'@': {
						templateUrl: 'views/toolbar.html',
						controller: 'ToolbarCtrl as toolbarCtrl'
					}
				}
			});
		}]).controller('ToolbarCtrl', ['$scope', '$rootScope', '$state', 'Retailer', 'LINK_LOCATIONS', 'WEB_PAGE_LOCATIONS', 'Util', '$location', function($scope, $rootScope, $state, Retailer, LINK_LOCATIONS, WEB_PAGE_LOCATIONS, Util, $location) {
		$scope.title = $rootScope.title = 'More Links';
		Retailer.getRetailerSettings().then(function(configuration) {
			$scope.secondaryLinks = [];
			$scope.goToInternalURL = _goToInternalURL;
			angular.forEach(configuration.toolbar, function(link) {
				if (link.location === LINK_LOCATIONS.BOTTOM) {
					$scope.secondaryLinks.push({
						href: link.embedded ? $state.href('app.links', {linkId: link.id}) : link.url,
						text: link.name,
						target: link.embedded ? undefined : '_blank',
						image: link.imageUrl,
						url: link.url,
						isInternal: Util.isInternalUrl(link.url)
					});
				}
			});

			angular.forEach(configuration.publishedWebPages, function(webPage) {
				if (webPage.locationInWebsite === WEB_PAGE_LOCATIONS.FOOTER) {
					$scope.secondaryLinks.push({
						href: $state.href('app.webPage', {id: webPage.id}),
						text: webPage.name,
						image: webPage.iconUrl
					});
				}
			});

			if (configuration.settings.aboutUsText) {
				$scope.secondaryLinks.push({
					href: $state.href('app.about'),
					text: 'About Us'
				});
			}

			if (configuration.settings.agreementText) {
				$scope.secondaryLinks.push({
					href: $state.href('app.terms-and-conditions'),
					text: 'Terms & Conditions'
				});
			}

			if (configuration.settings.privacyText) {
				$scope.secondaryLinks.push({
					href: $state.href('app.policies'),
					text: 'Privacy Policy'
				});
			}

			if (configuration.settings.contactUsText) {
				$scope.secondaryLinks.push({
					href:  $state.href('app.retailer.contact-details'),
					text: 'Contact Details'
				});
			}

			function _goToInternalURL(url) {
				return $location.url(url);
			}
		});
	}]);
})(angular);