(function (angular, app) {
    "use strict";

    //no need for this directive when in web browser
    if (window.cordova === undefined) {
        return;
    }

    angular.module('mobilezuz')
        .directive('a', ['$window', 'Util', function ($window, Util) {
            return {
                restrict: 'E',
                scope: {
                    href: '@',
                    target: '@',
                    cordovaTarget: '@'
                },
                link: function($scope, $element) {
                    $element.bind('click', function(event) {
                        var target = $scope.cordovaTarget || $scope.target;
                        if ($scope.href && (target === '_blank' || target === '_system')) {
                            $window.open($scope.href, target);
                            event.preventDefault();
                            return false;
                        }
                    });
                }
            };
        }]);
})(angular);
