// create angular module
var spGoogleMapsApp = angular.module('spGoogleMaps', []);
(function(spGoogleMapsApp, angular) {
	'use strict';
	angular.module('spGoogleMaps')
		.constant('GOOGLE_AUTO_COMPLETE_TYPES', {
			/**
			 businesses
			 */
			ESTABLISHMENT: 'establishment',

			/**
			 * addresses
			 */
			GEOCODE: 'geocode',

			/**
			 * precise addresses
			 */
			ADDRESS: 'address',

			/**
			 * administrative regions
			 */
			REGIONS: '(regions)',

			/**
			 * localities
			 */
			CITIES: '(cities)'
		})
		.constant('GOOGLE_ADDRESS_TYPES', {
			/**
			 * indicates a precise street address.
			 */
			STREET_ADDRESS: 'street_address',

			/**
			 * indicates a named route (such as "US 101").
			 */
			ROUTE: 'route',

			/**
			 * indicates a major intersection, usually of two major roads.
			 */
			INTERSECTION: 'intersection',

			/**
			 * indicates a political entity.
			 * Usually, this type indicates a polygon of some civil administration.
			 */
			POLITICAL: 'political',

			/**
			 * indicates the national political entity, and is typically the highest order type returned by the Geocoder.
			 */
			COUNTRY: 'country',

			/**
			 * indicates a first-order civil entity below the country level.
			 * Within the United States, these administrative levels are states.
			 * Not all nations exhibit these administrative levels.
			 * In most cases, administrative_area_level_1 short names will closely match ISO 3166-2 subdivisions and other widely circulated lists;
			 * however this is not guaranteed as our geocoding results are based on a variety of signals and location data.
			 */
			ADMINISTRATIVE_AREA_LEVEL_1: 'administrative_area_level_1',

			/**
			 * indicates a second-order civil entity below the country level.
			 * Within the United States, these administrative levels are counties.
			 * Not all nations exhibit these administrative levels.
			 */
			ADMINISTRATIVE_AREA_LEVEL_2: 'administrative_area_level_2',

			/**
			 * indicates a third-order civil entity below the country level.
			 * This type indicates a minor civil division.
			 * Not all nations exhibit these administrative levels.
			 */
			ADMINISTRATIVE_AREA_LEVEL_3: 'administrative_area_level_3',

			/**
			 * indicates a fourth-order civil entity below the country level.
			 * This type indicates a minor civil division.
			 * Not all nations exhibit these administrative levels.
			 */
			ADMINISTRATIVE_AREA_LEVEL_4: 'administrative_area_level_4',

			/**
			 * indicates a fifth-order civil entity below the country level.
			 * This type indicates a minor civil division.
			 * Not all nations exhibit these administrative levels.
			 */
			ADMINISTRATIVE_AREA_LEVEL_5: 'administrative_area_level_5',

			/**
			 * indicates a commonly-used alternative name for the entity.
			 */
			COLLOQUIAL_AREA: 'colloquial_area',

			/**
			 * indicates an incorporated city or town political entity.
			 */
			LOCALITY: 'locality',

			/**
			 * indicates a grouping of geographic areas, such as locality and sublocality, used for mailing addresses in some countries.
			 */
			POSTAL_TOWN: 'postal_town',

			/**
			 * indicates a specific type of Japanese locality, to facilitate distinction between multiple locality components within a Japanese address.
			 */
			WARD: 'ward',

			/**
			 * indicates a first-order civil entity below a locality.
			 * For some locations may receive one of the additional types: sublocality_level_1 to sublocality_level_5.
			 * Each sublocality level is a civil entity.
			 * Larger numbers indicate a smaller geographic area.
			 */
			SUBLOCALITY: 'sublocality',

			/**
			 * indicates a named neighborhood
			 */
			NEIGHBORHOOD: 'neighborhood',

			/**
			 * indicates a named location, usually a building or collection of buildings with a common name
			 */
			PREMISE: 'premise',

			/**
			 * indicates a first-order entity below a named location, usually a singular building within a collection of buildings with a common name
			 */
			SUBPREMISE: 'subpremise',

			/**
			 * indicates a postal code as used to address postal mail within the country.
			 */
			POSTAL_CODE: 'postal_code',

			/**
			 * indicates a prominent natural feature.
			 */
			NATURAL_FEATURE: 'natural_feature',

			/**
			 * indicates an airport.
			 */
			AIRPORT: 'airport',

			/**
			 * indicates a named park.
			 */
			PARK: 'park',

			/**
			 * indicates a named point of interest.
			 * Typically, these "POI"s are prominent local entities that don't easily fit in another category, such as "Empire State Building" or "Statue of Liberty."
			 */
			POINT_OF_INTEREST: 'point_of_interest'
		})
		.constant('LANGUAGE_IDS', {
			HE: 1,
			EN: 2,
			ES: 3,
			RU: 4,
            FR: 5,
            AR: 6
		})
		.constant('GOOGLE_LANGUAGE_CODES', {
			1: 'iw',
			2: 'en',
			3: 'es',
			4: 'ru',
            5: 'fr',
            6: 'ar'
		})
	;
})(spGoogleMapsApp, angular);

(function(spGoogleMapsApp, angular) {
    spGoogleMapsApp.directive('spGoogleMaps', ['$q', '$rootScope', 'spGoogleMapsUtil', 'spGoogleMaps', function($q, $rootScope, spGoogleMapsUtil, spGoogleMaps) {
        return {
            restrict: 'E',
            replace: true,
            template: '' +
                '<div class="sp-google-maps-wrapper">' +
                '<div class="sp-google-maps"></div>' +
                '</div>',
            scope: {
                options: '=?',
                model: '=?',
                enterCityText: '@?',
                enterCountryCodeText: '@?',
                defaultCountryCodeText: '@?',
                addPolygonText: '@?',
                addPolygonTitle: '@?'
            },
            controller: ['$scope', '$element', '$http', function($scope, $element, $http) {
                var internalModel = {},
                    _map,
                    _marker,
                    _listeners = [];

                spGoogleMaps.onload(function() {
                    $scope.$watch('model', _initMap);
                });

                function _initMap() {
                    if (_listeners.length) {
                        for (var i = 0; i < _listeners.length; i++) {
                            google.maps.event.clearListeners(_map, _listeners[i]);
                        }
                    }

                    setModel();

                    $scope.options = $scope.options || {};
                    var options = $scope.options.google || {};
                    options.zoom = $scope.options.zoom || options.zoom || 7;
                    options.zoomListener = $scope.options.zoomListener || false;
                    options.center = _getInitCenter();
                    options.showMarker = $scope.options.showMarker;
                    options.icon = $scope.options.icon;
                    options.title = $scope.options.title;
                    options.storeLogo = $scope.options.storeLogo;
                    options.setBounds = $scope.options.setBounds;
                    options.polygonColor = $scope.options.polygonColor;
                    options.polygonHighlightColor = $scope.options.polygonHighlightColor;

                    _map = new google.maps.Map($element[0].children[0], options);

                    spGoogleMapsUtil._bounds = new google.maps.LatLngBounds();

                    _setDrawingManager();
                    _setPolygons();
                    _handleCurrentPolygon();
                    _handleSearchCity();

                    if (options.zoomListener) {
                        $scope.$watch('options.zoom', _changeZoom);
                    }

                    $scope.$watch('options.center', _changeCenter);
                }

                function _changeZoom() {
                    _map.setZoom($scope.options.zoom);
                }

                function _changeCenter() {
                    if (angular.isString($scope.options.center)) {
                        _setStringCenter();
                    } else if (_isSetCenter()) {
                        _map.setCenter(_getInitCenter());
                    }
                }

                function _setSearchBox() {
                    if (!$scope.options.searchBox) return;

                    var input = angular.element($scope.options.searchBox);
                    if (!(input instanceof HTMLElement)) {
                        input = angular.element(document.createElement('input')).addClass('search-box').attr({
                            'type': 'text',
                            'placeholder': 'Search'
                        });
                    }
                    var searchBox = new google.maps.places.SearchBox(input[0]);
                    _map.controls[google.maps.ControlPosition.TOP_LEFT].push(input[0]);

                    // Bias the SearchBox results towards current map's viewport.
                    _map.addListener('bounds_changed', function() {
                        searchBox.setBounds(_map.getBounds());
                    });
                    _listeners.push('bounds_changed');

                    // Listen for the event fired when the user selects a prediction and retrieve
                    // more details for that place.
                    searchBox.addListener('places_changed', function() {
                        var places = searchBox.getPlaces();

                        if (places.length == 0) return;

                        // For each place, get the icon, name and location.
                        var bounds = new google.maps.LatLngBounds();
                        angular.forEach(places, function(place) {
                            if (place.geometry.viewport) {
                                // Only geocodes have viewport.
                                bounds.union(place.geometry.viewport);
                            } else {
                                bounds.extend(place.geometry.location);
                            }
                        });
                        _map.fitBounds(bounds);
                    });
                    _listeners.push('places_changed');
                }

                /*function _getDatabasePolygons() {
                    return spGoogleMapsUtil.googlePolygonToDatabasePolygon(internalModel.polygons);
                }*/

                function setModel() {
                    $scope.model = $scope.model || {};
                    internalModel.polygons = $scope.model.polygons = $scope.model.polygons || [];
                    /*internalModel.getDatabasePolygons = $scope.model.getDatabasePolygons = _getDatabasePolygons;
                     internalModel.removePolygon = $scope.model.removePolygon = removePolygon;*/
                }

                function _getInitCenter() {
                    if (!$scope.options.center || angular.isString($scope.options.center)) {
                        return new google.maps.LatLng(0, 0);
                    }

                    if ($scope.options.center instanceof google.maps.LatLng) {
                        return $scope.options.center;
                    }

                    return new google.maps.LatLng($scope.options.center.lat, $scope.options.center.lng);
                }

                function _setStringCenter() {
                    if (!$scope.options.center || !angular.isString($scope.options.center)) {
                        return;
                    }

                    spGoogleMapsUtil.positionByAddress($scope.options.center).then(function(position) {
                        if ($scope.options.showMarker) {
                            _addMarker(position);
                        }

                        if (_isSetCenter()) {
                            _map.setCenter(position);
                        }
                    }).catch(function() {
                        console.warn('Could not get lat lng for \'' + $scope.options.center + '\'');
                    });
                }

                function _isSetCenter() {
                    return !$scope.options.setBounds || internalModel.polygons.length === 0;
                }

                var _editPolygons = false;

                function _setDrawingManager() {
                    if (!$scope.options.drawing) return;

                    var drawingManager = new google.maps.drawing.DrawingManager(),
                        options = {
                            drawingControl: true,
                            drawingControlOptions: {
                                drawingModes: []
                            }
                        };

                    if ($scope.options.drawing.polygons) {
                        _editPolygons = true;
                        var polygonsConfig = $scope.options.drawing.polygons;
                        if (polygonsConfig.value) {
                            console.warn('options.drawing.polygons.value is deprecated api, use model.polyongs instead');
                            internalModel.polygons = $scope.model.polygons = polygonsConfig.value;
                        }

                        options.drawingControlOptions.drawingModes.push(google.maps.drawing.OverlayType.POLYGON);
                        google.maps.event.addListener(drawingManager, 'polygoncomplete', function(polygon) {
                            _addPolygon(polygon);
                            if (polygonsConfig.globalEvents && polygonsConfig.globalEvents.complete) {
                                polygonsConfig.globalEvents.complete(polygon);
                            }
                        });
                    }

                    drawingManager.setOptions(options);
                    drawingManager.setMap(_map);

                    var listener = _map.addListener('idle', function() {
                        listener.remove();
                        _setSearchBox();
                        if (!drawingManager || !drawingManager.gm_accessors_ || !drawingManager.gm_accessors_.snappingCallback) return;
                        var drawingControlsWrapper;
                        angular.forEach(drawingManager.gm_accessors_.snappingCallback, function(holder) {
                            if (!holder.j) return;
                            drawingControlsWrapper = angular.element(holder.j);
                        });
                        if (drawingControlsWrapper) {
                            drawingControlsWrapper.addClass('drawing-controls');
                        }
                    });
                    _listeners.push('idle');
                }

                function _addMarker(pos) {
                    _marker = new google.maps.Marker({
                        position: pos,
                        map: _map,
                        title: $scope.options.title || '',
                        icon: $scope.options.icon || ''
                    });

                    if ($scope.options.storeLogo) {
                        var infowindow = new google.maps.InfoWindow({
                            content: '<img class="info-logo" src=' + $scope.options.storeLogo + '>'
                        });
                        infowindow.open(_map, _marker);
                    }
                }

                function _setPolygons() {
                    if (internalModel.polygons) {
                        angular.forEach(internalModel.polygons, function(polygon) {
                            polygon._spGoogleMapsId = _drawPolygon(polygon)._spGoogleMapsId;
                        });
                        if ($scope.options.setBounds && internalModel.polygons.length != 0) _map.fitBounds(spGoogleMapsUtil._bounds);
                    }
                }

                var _$controlDiv, _$messageDiv;

                function _handleCurrentPolygon() {
                    if (!_editPolygons) return;

                    _map.addListener('click', _dimPolygon);
                    var $controlButton = angular.element(document.createElement('button')).text('Delete').bind('click', _removeCurrentPolygon).css({
                            'background-color': '#ffffff',
                            'border': 'none',
                            'border-radius': '3px',
                            'box-shadow': '0 2px 6px rgba(0, 0, 0, 0.3)'
                        }),
                        $controlDiv = _$controlDiv = angular.element(document.createElement('div')).addClass('delete-polygon-control').append($controlButton).css('display', 'none');
                    _map.controls[google.maps.ControlPosition.TOP_CENTER].push($controlDiv[0]);
                }

                function _handleSearchCity() {
                    if (!_editPolygons) return;

                    //add search option
                    var inputCityElement = document.createElement('input');
                    inputCityElement.setAttribute('type', 'text');
                    inputCityElement.setAttribute('id', 'cityToSearch');
                    inputCityElement.setAttribute('placeholder', $scope.enterCityText || 'Enter city name');
                    var inputCountryElement = document.createElement('input');
                    inputCountryElement.setAttribute('type', 'text');
                    inputCountryElement.setAttribute('id', 'countryToSearch');
                    inputCountryElement.setAttribute('value', $scope.defaultCountryCodeText);
                    inputCountryElement.setAttribute('placeholder', $scope.enterCountryCodeText || 'Enter country code');
                    var $title = angular.element(document.createElement('div')).addClass('add-polygon-title').text($scope.addPolygonTitle || 'Add city polygon'),
                        $searchCityInput = angular.element(inputCityElement),
                        $searchCountryCodeInput = angular.element(inputCountryElement),
                        $searchButton = angular.element(document.createElement('button')).text($scope.addPolygonText || 'Add polygon').bind('click', _searchCity),
                        $searchDiv = angular.element(document.createElement('div')).addClass('add-polygon')
                            .append($title).append($searchCityInput).append($searchCountryCodeInput).append($searchButton);

                    //add to map
                    _map.controls[google.maps.ControlPosition.LEFT_CENTER].push($searchDiv[0]);

                    //add message option
                    var $messageDiv = _$messageDiv = angular.element(document.createElement('div')).addClass('message').css('display', 'none');
                    _map.controls[google.maps.ControlPosition.TOP_CENTER].push($messageDiv[0]);

                    //configure jsonp callback
                    window.openStreetMap_jsonp_callback = function(results) {

                        if (results && results[0]) {
							var result;
							for (var i = 0; i < results.length; i++) {
								if (!results[i].geojson || !results[i].geojson.type || (results[i].geojson.type != 'MultiPolygon' && results[i].geojson.type !='Polygon')) {
									continue;
								} else {
									result = results[i];
									break;
								}
							}
                            switch (result && result.geojson ? result.geojson.type : null) {
                                case 'MultiPolygon':
                                    var polygons = result.geojson.coordinates.map(function(polygon) {
                                        return polygon.map(function(coordinate) {
                                            return coordinate.map(function(point) {
                                                return point.reverse();
                                            });
                                        });
                                    });
                                    for (var i = 0; i < polygons.length; i++) {
                                        _addPolygon(polygons[i][0], true, true);
                                    }
                                    _showMessage(result.display_name + ' added to map', 'success');
                                    _cleanInput();
                                    break;
                                case 'Polygon':
                                    var polygon = result.geojson.coordinates.map(function(coordinate) {
                                        return coordinate.map(function(point) {
                                            return point.reverse();
                                        });
                                    })[0];
                                    _addPolygon(polygon, true, true);
                                    _showMessage(result.display_name + ' added to map', 'success');
                                    _cleanInput();
                                    break;

                                default:
                                    _showMessage('Polygon not found', 'error');
                            }
                        } else {
                            _showMessage('Polygon not found', 'error');
                        }
                    }

                }

                function _showMessage(message, cssClass) {
                    _$messageDiv.text(message).addClass(cssClass).css('display', 'block');
                    setTimeout(function() {
                        _$messageDiv.removeClass(cssClass).css('display', 'none');
                    }, 5000);
                }


                function _cleanInput() {
                    document.getElementById('cityToSearch').value = null;
                }

                function _searchCity() {
                    var cityToSearch = document.getElementById('cityToSearch').value;
                    var countryToSearch = document.getElementById('countryToSearch').value;
                    if (!cityToSearch) {
                        return;
                    }
                    var openStreetMapUrl = 'https://nominatim.openstreetmap.org/search?city=' + encodeURI(cityToSearch) + '&countrycodes=' + encodeURI(countryToSearch) + '&format=json&polygon_geojson=true&limit=3&json_callback=openStreetMap_jsonp_callback';

                    $http.jsonp(openStreetMapUrl).then(function(response) {
                    }, function(err) {
                        //console.log(err)
                    });
                }

                var _currentPolygon = null;

                function _highlightPolygon(polygon) {
                    _dimPolygon();
                    _currentPolygon = polygon;
                    _currentPolygon.setOptions({
                        strokeColor: $scope.options.polygonHighlightColor || '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 1,
                        fillColor: $scope.options.polygonHighlightColor || '#FF0000',
                        fillOpacity: 0.35
                    });
                    _$controlDiv.css('display', 'block');
                }

                function _dimPolygon() {
                    if (!_currentPolygon) return;

                    _$controlDiv.css('display', 'none');
                    _setDefaultsToPolygon(_currentPolygon);
                    _currentPolygon = null;
                }

                function _setDefaultsToPolygon(polygon) {
                    polygon.setOptions({
                        strokeColor: $scope.options.polygonColor || '#000000',
                        strokeOpacity: 0.8,
                        strokeWeight: 1,
                        fillColor: $scope.options.polygonColor || '#000000',
                        fillOpacity: 0.35
                    });
                }

                function _drawPolygon(polygon) {
                    if (!(polygon instanceof google.maps.Polygon)) {
                        return _drawPolygon(spGoogleMapsUtil.databasePolygonsToGooglePolygons([polygon])[0]);
                    }

                    if ($scope.options.drawing && $scope.options.drawing.polygons) {
                        if ($scope.options.drawing.polygons.events) {
                            angular.forEach($scope.options.drawing.polygons.events, function(value, key) {
                                polygon.addListener(key, value);
                            });
                        }

                        if ($scope.options.drawing.polygons.defaultOptions) {
                            polygon.setOptions($scope.options.drawing.polygons.defaultOptions);
                        }
                    }

                    polygon._spGoogleMapsId = spGoogleMapsUtil.generatePolygonId();
                    if (_editPolygons) {
                        polygon.addListener('click', function() {
                            _highlightPolygon(this);
                        });
                    }
                    polygon.setMap(_map);
                    _setDefaultsToPolygon(polygon);
                    return polygon;
                }

                function _addPolygon(polygon, showMessageOnFail, goToPolygon) {
                    if (!(polygon instanceof google.maps.Polygon)) {
                        return _addPolygon(spGoogleMapsUtil.databasePolygonsToGooglePolygons([polygon])[0], showMessageOnFail, goToPolygon);
                    }
                    var internalPolygon = spGoogleMapsUtil.googlePolygonToDatabasePolygon(polygon);

                    var newPolygon = JSON.stringify(internalPolygon),
                        allPolygons = JSON.stringify(internalModel.polygons);
                    if (allPolygons.indexOf(newPolygon) > -1) {
                        if (showMessageOnFail) {
                            _showMessage('Polygon already added', 'error');
                        }
                        _cleanInput();
                        throw new Error('Polygon already added')
                    }

                    _drawPolygon(polygon);

                    internalPolygon._spGoogleMapsId = polygon._spGoogleMapsId;
                    internalModel.polygons.push(internalPolygon);

                    if (goToPolygon) {
                        _map.setCenter(new google.maps.LatLng(internalPolygon[0][0], internalPolygon[0][1]));
                    }

                }

                function _removeCurrentPolygon() {
                    if (!_currentPolygon) return;
                    _$controlDiv.css('display', 'none');
                    _currentPolygon.setMap(null);

                    for (var i = 0; i < internalModel.polygons.length; i++) {
                        if (internalModel.polygons[i]._spGoogleMapsId != _currentPolygon._spGoogleMapsId) continue;

                        internalModel.polygons.splice(i, 1);
                        break;
                    }
                }
            }]
        };
    }]);
})(spGoogleMapsApp, angular);
(function(spGoogleMapsApp, angular) {
	spGoogleMapsApp.directive('spGoogleMapsEmbed', [function() {
		return {
			restrict: 'E',
			replace: true,
			template: function(element, attrs) {
				return '' +
					'<div class="sp-google-maps-wrapper" id="{{spGoogleMapsEmbedCtrl}}">' +
					'<iframe height="100%" width="100%" ng-src="{{spGoogleMapsEmbedCtrl.trustSrc(spGoogleMapsEmbedCtrl.url)}}" allowfullscreen></iframe>' +
					'</div>'
			},
			scope: {
				options: '=?',
			},
			controllerAs: 'spGoogleMapsEmbedCtrl',
			controller: ['$scope', '$sce', '$element', '$attrs', 'LANGUAGE_IDS', 'GOOGLE_LANGUAGE_CODES',
				function($scope, $sce, $element, $attrs, LANGUAGE_IDS, GOOGLE_LANGUAGE_CODES) {
					var spGoogleMapsEmbedCtrl = this;
					spGoogleMapsEmbedCtrl.options = $scope.options || {};
					spGoogleMapsEmbedCtrl.mode = $scope.options.mode || 'place'; //options: 'place, search'
					spGoogleMapsEmbedCtrl.language = GOOGLE_LANGUAGE_CODES[$scope.options.languageId || LANGUAGE_IDS.EN];


					spGoogleMapsEmbedCtrl.url = 'https://www.google.com/maps/embed/v1/' + spGoogleMapsEmbedCtrl.mode +
						'?key=AIzaSyBY2VE7c29hE1NG9OhwFzSWTZtjmmYjpBM' +
						($scope.options.center ? '&q=' + $scope.options.center : '').replace(/\s/g, '+') + //handle backward
						($scope.options.q ? '&q=' + $scope.options.q : '').replace(/\s/g, '+') +
						($scope.options.q ? '&q=' + $scope.options.q : '').replace(/\s/g, '+') +
						(spGoogleMapsEmbedCtrl.language ? '&language=' + spGoogleMapsEmbedCtrl.language : '') +
						('&zoom=' + $scope.options.zoom || 7);

					spGoogleMapsEmbedCtrl.trustSrc = function(src) {
						return $sce.trustAsResourceUrl(src);
					}
				}]
		};
	}]);
})(spGoogleMapsApp, angular);
(function(spGoogleMapsApp) {
    'use strict';

    spGoogleMapsApp.service('spGoogleMaps', ['$q','LANGUAGE_IDS','GOOGLE_LANGUAGE_CODES', function($q, LANGUAGE_IDS, GOOGLE_LANGUAGE_CODES) {
        var self = this,
            _loadListeners = [],
            _googleLoaded = false,
            _languageIdDefer = $q.defer(),
            _dataDefer = $q.defer(),
            _data;

        self.init = init;
        self.onload = onload;
        self.getLanguageId = getLanguageId;
        self.getData = getData;

        function init(languageId, countriesIso) {
            _dataDefer.resolve({
                languageId: languageId || LANGUAGE_IDS.EN,
                countriesIso: countriesIso || null
            });

            // load google maps script
            window.spGoogleMaps = window.spGoogleMaps || {};
            window.spGoogleMaps._onload = _onActualGoogleLoad;

            var googleApiKey;
            //var userAgent = navigator.userAgent || navigator.vendor || window.opera,
            if (window.cordova) {
                /*
                 todo: change it before july 2017 (till than there is no need  for a key)
                 according to:
                 https://code.google.com/p/gmaps-api-issues/issues/detail?id=9996
                 https://code.google.com/p/gmaps-api-issues/issues/detail?id=9991
                 */
                googleApiKey = '';
            } else {
                googleApiKey = '&key=AIzaSyBY2VE7c29hE1NG9OhwFzSWTZtjmmYjpBM';
            }

            var src = 'https://maps.googleapis.com/maps/api/js?libraries=places,drawing,geometry' + googleApiKey +
                '&callback=window.spGoogleMaps._onload&language=' + GOOGLE_LANGUAGE_CODES[languageId || LANGUAGE_IDS.EN];

            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = src;
            script.async = 'async';
            script.defer = 'defer';

            var headElement = document.getElementsByTagName('head')[0] || document.body.parentNode.appendChild(document.createElement('head'));
            headElement.appendChild(script);
        }

        /**
         * Returns a promise of the current language id
         * @public
         *
         * @returns {Promise<number>}
         */
        function getLanguageId() {
            if (_data.languageId) {
                return $q.resolve(_data.languageId);
            }

            return _languageIdDefer.promise;
        }

        /**
         *
         * @return {Promise<void>|Promise<any>}
         */
        function getData() {
            if (_data) {
                return $q.resolve(_data);
            }

            return _dataDefer.promise;
        }

        /**
         * Run listeners on google api load callback is called
         * @private
         */
        function _onActualGoogleLoad() {
            _googleLoaded = true;
            angular.forEach(_loadListeners, function(listener) {
                listener();
            });
        }

        /**
         * Call function if google maps load or on google maps load
         * @public
         *
         * @param {Function} callback\
         */
        function onload(callback) {
            if (!_googleLoaded) {
                return _loadListeners.push(function () {
                    callback();
                });
            }

            setTimeout(callback);
        }
    }]);
})(angular.module('spGoogleMaps'));
(function (spGoogleMapsApp, angular) {
    spGoogleMapsApp.service('spGoogleMapsUtil', ['$q', 'Api', 'spGoogleMaps', 'GOOGLE_AUTO_COMPLETE_TYPES', 'GOOGLE_ADDRESS_TYPES',
        function ($q, Api, spGoogleMaps, GOOGLE_AUTO_COMPLETE_TYPES, GOOGLE_ADDRESS_TYPES) {
        var self = this,
            NOT_ALLOWED_GOOGLE_ADDRESS_TYPES = [
                GOOGLE_ADDRESS_TYPES.INTERSECTION,
                GOOGLE_ADDRESS_TYPES.COUNTRY,
                GOOGLE_ADDRESS_TYPES.PARK,
                GOOGLE_ADDRESS_TYPES.ADMINISTRATIVE_AREA_LEVEL_1,
                GOOGLE_ADDRESS_TYPES.ADMINISTRATIVE_AREA_LEVEL_2
            ];

        /**
         * Bounds
         * @private
         * @type {google array of Bounds}
         */
        self._bounds;

        /**
         * Polygon id counter
         *
         * @private
         * @type {Number}
         */
        self._polygonIdCounter = 1;

        self.generatePolygonId = generatePolygonId;
        self.positionByAddress = positionByAddress;
        self.databasePolygonsToGooglePolygons = databasePolygonsToGooglePolygons;
        self.googlePolygonToDatabasePolygon = googlePolygonToDatabasePolygon;
        self.googlePolygonPaths = googlePolygonPaths;
        self.placesAutocomplete = placesAutocomplete;

        function generatePolygonId () {
            return self._polygonIdCounter++;
        }

        /**
         * Get position by address
         * @public
         *
         * @param {String} address
         *
         * @return {Promise<{lat: number, lng: number}>}
         */
        function positionByAddress(address) {
            return spGoogleMaps.getData().then(function(data) {
                return Api.request({
                    method: 'GET',
                    url: '/v2/google-maps/address',
                    params: {
                        address: address,
                        languageId: data.languageId
                    }
                })
            }).then(function(resp) {
                return resp.results[0].geometry.location;
            });
        }

        /**
         * Database polygons to google polygons
         *
         * @param {Array} polygons
         * @param {Array} polygons[]
         * @param {Array} polygons[][]
         * @param {Number} polygons[][][0] - lat
         * @param {Number} polygons[][][1] - lng
         *
         * @return {Array<google.maps.Polygon>}
         */
        function databasePolygonsToGooglePolygons(polygons) {
            var ret = [], latlng;

            angular.forEach(polygons, function (polygon) {
                var paths = [];

                angular.forEach(polygon.data || polygon, function (path) {
                    latlng = new google.maps.LatLng(path[0], path[1]);
                    self._bounds.extend(latlng);
                    paths.push(latlng);
                });

                var googlePolygon = new google.maps.Polygon({
                    paths: paths
                });
                ret.push(googlePolygon);
            });

            return ret;
        }

        /**
         * Google polygons to database polygons
         *
         * @param {Array} polygons
         * @param {google.maps.Polygon} polygons[]
         * @param {Function} polygons[].lat
         * @param {Function} polygons[].lng
         *
         * @return {Array<Object>}
         */
        function googlePolygonToDatabasePolygon (polygons) {
            var isArray = angular.isArray(polygons),
                ret = [];
            polygons = isArray ? polygons : [polygons];

            angular.forEach(polygons, function (polygon) {
                ret.push(self.googlePolygonPaths(polygon));
            });

            return isArray ? ret : ret[0];
        }

        /**
         * Get paths by google polygon
         * @public
         *
         * @param {google.maps.Polygon} polygon
         *
         * @return {Array}
         */
        function googlePolygonPaths (polygon) {
            var paths = [];
            angular.forEach(polygon.getPath().getArray(), function (path) {
                paths.push([path.lat(), path.lng()]);
            });
            return paths;
        }

        function placesAutocomplete(query, types, placeId, zipCode, validZipCode, languageId) {
            if (!query) {
                return $q.resolve([]);
            }

            return spGoogleMaps.getData().then(function(data) {
                return Api.request({
                    method: 'GET',
                    url: '/v2/google-maps/auto-complete',
                    params: {
                        address: query,
                        languageId: languageId || data.languageId,
                        types: types,
                        countriesIso: data.countriesIso,
                        placeId: placeId, 
                        zipCode: zipCode,
                        validZipCode: validZipCode
                    }
                })
            }).then(function(resp) {
                var newPlaces = [];

                //for some reason google returns countries and states in the auto complete results (even though we sent GEOCODE type only)
                //the only current solution is to filter the results locally here:
                angular.forEach(resp.results || [], function (place) {
                    var isMethodNotAllowed = false;
                    angular.forEach(place.types, function (type) {
                        if (NOT_ALLOWED_GOOGLE_ADDRESS_TYPES.includes(type)) {
                            isMethodNotAllowed = true;
                        }
                    });

                    if (!isMethodNotAllowed) {
                        newPlaces.push(place);
                    }
                });

                return newPlaces;
            });
        }
    }]);
})(spGoogleMapsApp, angular);
