angular.module('mobilezuz').controller('ShoppingListComment', ['$scope', 'mDesign', 'listId', 'item', 'ShoppingList',
    function ($scope, mDesign, listId, item, shoppingList) {
        $scope.listId = listId;
        $scope.item = item;
        $scope.comments = item.comments;
        $scope.update = updateComment;
        $scope.cancel = cancel;

        function updateComment() {
            item.comments = $scope.comments;
            shoppingList.patchShopListItems($scope.listId, [item]);
            mDesign.hide(item.comments);
        }

        function cancel() {
            mDesign.cancel();
        }
    }]);