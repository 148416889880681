(function (angular) {
    angular.module('mobilezuz').config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('app.cart.order-update.payment', {
            url: '/payment',
            views: {
                updateOrder: {
                    templateUrl: 'views/order/update/payment.html',
                    controller: 'UpdateOrderPaymentCtrl as updateOrderPaymentCtrl'
                }
            }
        });

        $stateProvider.state('app.user-confirmation.update.payment', {
            url: '/payment',
            views: {
                updateOrder: {
                    templateUrl: 'views/order/update/payment.html',
                    controller: 'UpdateOrderPaymentCtrl as updateOrderPaymentCtrl'
                }
            }
        });
    }]).controller('UpdateOrderPaymentCtrl', [
        '$scope', '$state', '$stateParams', '$location', '$q', '$timeout', 'mDesign', 'Util', 'Loading', 'Retailer',
        'User', 'order', 'updateType', 'UPDATE_ORDER_PAYMENT_TYPE', 'Cart', 'Orders',
        function ($scope, $state, $stateParams, $location, $q, $timeout, mDesign, Util, Loading, Retailer,
                  User, order, updateType, UPDATE_ORDER_PAYMENT_TYPE, Cart, Orders) {
            var updateOrderPaymentCtrl = this,
                updateOrderCtrl = $scope.updateOrderCtrl;

            updateOrderCtrl.paymentDetails = {};
            updateOrderCtrl.ebtCalculatedAmounts = {};
            updateOrderCtrl.ebtCalculatedAmounts = {};
            updateOrderCtrl.hasLoyaltyCreditCards = false;
            updateOrderCtrl.orderInEdit = Orders.orderInEdit

            updateOrderCtrl.forward = forward;
            updateOrderCtrl.back = updateType.type === UPDATE_ORDER_PAYMENT_TYPE.EDIT_ORDER_UPDATE ? back : undefined;

            updateOrderCtrl.remainingEbtPayment = {
                snap : 0,
                cash : 0
            };
            updateOrderCtrl.useEbtBalance = {
                useSnap: true,
                disableSnap: true,
                useCash: true,
                disableCash: false
            };
            _setRemainingPayment()

            Retailer.getRetailerSettings().then(function (settings) {
                updateOrderPaymentCtrl.settings = settings;
            });

            if (updateType.type === UPDATE_ORDER_PAYMENT_TYPE.USER_CONFIRMATION_UPDATE) {
                _userConfirmationSignInFlow();
            } else {
                _setUser();
            }

            function back() {
                $state.go(updateType.exitState);
            }

            function forward() {
                $state.go(updateType.stateBase + '.summary');
            }

            function _setRemainingPayment() {
                var remainingPayment = Cart.getRemainingPayment(order)
                if (remainingPayment) {
                    var remainingEbtPayment = remainingPayment.ebt;
                    updateOrderCtrl.remainingEbtPayment = remainingEbtPayment;
                    var isSnapRefund = remainingEbtPayment.snap < -1;
                    var isCashRefund = remainingEbtPayment.cash < -1;
                    updateOrderPaymentCtrl.useEbtBalance = {
                        useCash: isCashRefund,
                        disableCash: isCashRefund,
                        useSnap: isSnapRefund,
                        disableSnap: isSnapRefund,
                    }
                }
            }

            function _userConfirmationSignInFlow() {
                var userLoginData = User.getUserLoginData();
                if (userLoginData) {
                    if (userLoginData.uid !== order.userId) {
                        Loading.counter(null, 1);
                        // wait for state transitions to finish before reloading
                        _waitForStateTransition().then(function () {
                            Util.clearLocalStorage();
                            Util.reload();
                        });
                        return;
                    }

                    _setUser();
                    return;
                }

                return $q.resolve().then(function() {
                    var search = $location.search();
                    // login dialog is already open
                    if (search.loginOrRegister) {
                        return true;
                    }

                    return mDesign.dialog({
                        focusOnOpen: false,
                        clickOutsideToClose: false,
                        templateUrl: 'views/order/update/pre-sign-in-dialog.html',
                        controller: ['$scope', function($scope) {
                            $scope.closeDialog = mDesign.hide;
                        }]
                    });
                }).then(function(proceed) {
                    if (proceed) {
                        return _login();
                    }

                    Loading.counter(null, 1);
                    location.href = '/collection-confirmation?orderId=' + $stateParams.orderId + '&userConfirmationToken=' + $stateParams.userConfirmationToken;
                });
            }

            function _login() {
                return Util.goToLoginDialog(null, null, null, null, true).then(function() {
                    return _userConfirmationSignInFlow();
                });
            }

            function _waitForStateTransition() {
                if (!$state.transition) {
                    return $timeout(angular.noop, 200);
                }

                return $state.transition.then(function() {
                    return _waitForStateTransition();
                }, function() {
                    return _waitForStateTransition();
                });
            }

            function _setUser() {
                User.getUserSettings().then(function (user) {
                    if (!user) {
                        return;
                    }

                    updateOrderPaymentCtrl.user = user;
                });
            }
        }]);
})(angular);
