(function (angular, Fraction) {
angular.module('mobilezuz').config([
    '$stateProvider', 'SpDialogUrlManagerProvider', 'SP_URL_DIALOG_QUERY_PARAMS', 'SP_URL_DIALOG_DATA_QUERY_PARAMS',
    function ($stateProvider, SpDialogUrlManagerProvider, SP_URL_DIALOG_QUERY_PARAMS, SP_URL_DIALOG_DATA_QUERY_PARAMS) {
        $stateProvider.state('app.singleRecipe', {
            url: '/recipes/:id',
            views: {
                '@': {
                    template: 'Redirecting...',
                    controller: ['$state', '$stateParams', '$timeout', 'Util', function($state, $stateParams, $timeout, Util) {
                        var recipeId = $stateParams.id;

                        $timeout(function() {
                            return $state.go('app.home', {}, {inherit: true});
                        }, 500).then(function() {
                            $timeout(function() {
                                return Util.goToRecipeDialog(recipeId);
                            }, 500);
                        });
                    }]
                }
            }
        });

        SpDialogUrlManagerProvider.dialog({
            queryParams: SP_URL_DIALOG_QUERY_PARAMS.RECIPE,
            dataQueryParams: SP_URL_DIALOG_DATA_QUERY_PARAMS.RECIPE,
            canShow: ['SpRecipeService', 'paramValue', function (SpRecipeService, paramValue) {
                return SpRecipeService.isValidRecipeQuery(decodeURIComponent(paramValue));
            }],
            paramResolve: ['SpRecipeService', 'paramValue', function (SpRecipeService, paramValue) {
                   return SpRecipeService.parseRecipeFromQuery(decodeURIComponent(paramValue));
            }],
            show: ['mDesign', 'value', function(mDesign, value) {
                return mDesign.dialog({
                    multiple: true,
                    fullscreen: true,
                    templateUrl: 'views/single-recipe.html',
                    controller: 'SingleRecipeCtrl',
                    locals: {
                        recipe: value
                    },
                    //mDesign param
                    dontPreventRouteChange: true
                });
            }]
        });
    }
]).controller('SingleRecipeCtrl', [
    '$rootScope', '$http', '$templateCache', '$timeout', '$compile', '$q', '$scope', '$window', '$stateParams', '$location', 'recipe', 'Cart', 'SpDialogUrlManager', 'Util', 'ADVERTISEMENT_CONVERSION_TYPE',
    function ($rootScope, $http, $templateCache, $timeout, $compile, $q, $scope, $window, $stateParams, $location, recipe, cart, SpDialogUrlManager, util, ADVERTISEMENT_CONVERSION_TYPE) {

        $scope.recipe = recipe;
        $scope.saveRecipe = saveRecipe;
        $scope.printRecipe = printRecipe;
        $scope.backClose = SpDialogUrlManager.backClose;
        $scope.showPrint = $rootScope.bodyClass.osType !== 'ios' && !window.cordova;
        $scope.isDevice = window.cordova;
        $scope.events = $stateParams.events && JSON.parse($stateParams.events);
        $location.search('events', null);

        var productIngredients = [],
            _listeners = [],
            recipeTemplate = 'views/templates/print-recipe.html',
            pdfToPrint,
            injectedContent,
            showProductsOnly = $stateParams.showProductsOnly === 'true' || $stateParams.showProductsOnly === '1';

        $scope.showDetailsSection = !showProductsOnly && !!recipe.description;
        angular.forEach(recipe.groups, function (group) {
            if (!showProductsOnly && group.steps) {
                $scope.showDetailsSection = true;
            }

            angular.forEach(group.ingredients, function (ingredient) {
                if (!showProductsOnly && ingredient.name) {
                    $scope.showDetailsSection = true;
                }

                if (ingredient.products && ingredient.products.length) {
                    var products = [];
                    angular.forEach(ingredient.products, function(productItem) {
                        productItem.conversionCount = ingredient.conversionCount;
                        productItem.product.conversionCount = ingredient.conversionCount;
                        products.push(cart.getProduct(productItem.product));
                    });

                    productIngredients.push({
                        quantity: ingredient.quantity,
                        unitOfMeasureNames: ingredient.unitOfMeasureNames,
                        products: products
                    });
                }
                ingredient.wholeNumber = parseInt(ingredient.quantity);
                var decimal = ingredient.quantity - Math.floor(ingredient.quantity);
                var fraction = new Fraction(decimal);
                ingredient.numerator = fraction.n;
                ingredient.denominator = fraction.d;
            });
        });
        $scope.productIngredients = productIngredients;

        function saveRecipe() {
            _getSaveLinkElement().download = 'recipe-download.png';
        }

        function printRecipe() {
            if (window.cordova && window.cordova.plugins && window.cordova.plugins.printer) {
                window.cordova.plugins.printer.print(injectedContent, 'Document.html');
            } else {
                $http.get(recipeTemplate, { cache: $templateCache })
                    .then(function(template) {
                        var compiled = $compile(template.data)($scope);
                        $timeout(function () {
                            var content = compiled[0].innerHTML,
                                popupWin = window.open('', '_blank', 'width=300,height=300');
                            popupWin.document.open();
                            popupWin.document.write('<!DOCTYPE html><html><body onload="window.print()">' + content + '</body></html>');
                            popupWin.document.close();
                        },0,false);
                    });
            }
        }

        function _initRecipeToSave() {
            $http.get(recipeTemplate, {cache: $templateCache})
                .then(function (template) {
                    var compiled = $compile(template.data)($scope);
                    $timeout(function () {
                        _initPictureToSave(compiled);
                    }, 0, false);
                });
        }
        _initRecipeToSave();

        function _initPictureToSave(compiled) {
            var content = compiled[0].innerHTML,
                promises = [];

            var injectedContent = '<!DOCTYPE html><html><head></head><body dir="' + 'ltr' + '"><div class="pdf-wrapper"><style>.pdf-wrapper {width: 21cm; }</style>'+ content +'</body></html>',
                newIframe = document.createElement('iframe');
            newIframe.id = 'pdfIframe';
            newIframe.width = '0';
            newIframe.height = '0';
            newIframe.src = 'about:blank';
            window.document.body.appendChild(newIframe);
            newIframe.contentWindow.document.open('text/html', 'replace');
            newIframe.contentWindow.document.write(injectedContent);
            newIframe.contentWindow.document.close();

            var newDiv = newIframe.contentWindow.document.querySelector('div'),
                imageArray = newIframe.contentWindow.document.querySelectorAll('img');

            angular.forEach(imageArray, function(image) {
                promises.push(_getDataUri(image.src));
            });

            $q.all(promises)
                .then(function(results) {
                    angular.forEach(imageArray, function(image) {
                        angular.forEach(results, function(result) {
                            if (result[image.src]) {
                                image.src = result[image.src];
                            }
                        });
                    });

                    html2canvas(newDiv).then(function (canvas) {
                        var data = canvas.toDataURL();
                        pdfToPrint = {
                            content: [{
                                image: data,
                                width: 500
                            }]
                        };

                        var saveLinkElement = _getSaveLinkElement();
                        if (saveLinkElement) {
                            saveLinkElement.href = data;
                        }
                        newIframe.parentNode.removeChild(newIframe);
                    });
                });
        }

        function _getDataUri(url) {
            var deferred = $q.defer(),
                image = new Image(),
                uriObj = {};
            image.setAttribute('crossOrigin', 'anonymous');
            image.src = url;

            image.onload = function () {
                var canvas = document.createElement('canvas');
                canvas.width = this.naturalWidth;
                canvas.height = this.naturalHeight;

                canvas.getContext('2d').drawImage(this, 0, 0);

                uriObj[url] = canvas.toDataURL();

                deferred.resolve(uriObj);
            };
            return deferred.promise;
        }

        function _getSaveLinkElement() {
            return $window.document.querySelector('.save-link');
        }

        function initFacebook(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.5";
            fjs.parentNode.insertBefore(js, fjs);
        }
        initFacebook(document, 'script', 'facebook-jssdk');

        _listeners.push($scope.$root.$on('cart.lines.add', function (event, data) {
            if ($scope.events) {
                util.firePromotionEvent($scope.events[ADVERTISEMENT_CONVERSION_TYPE.ADD_TO_CART]);
                if (data.lines && data.lines.length && data.lines[0].product.conversionCount) {
                    data.lines[0].adConversionUrl = $scope.events[ADVERTISEMENT_CONVERSION_TYPE.CHARGE];
                }
            }
        }));

        util.destroyListeners($scope, _listeners);


    }]);
})(angular, Fraction);
