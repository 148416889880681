(function(angular) {
	'use strict';

	var BRANCH_ID_LOCAL_STORAGE_KEY = 'mobileZuzBranchId',
		AREA_NAME_LOCAL_STORAGE_KEY = 'mobileZuzArea',
		AREA_ID_LOCAL_STORAGE_KEY = 'mobileZuzBranchAreaId',
		IS_AREA_CHOSEN_LOCAL_STORAGE_KEY = 'mobileZuzIsAreaChosen',
		LANGUAGE_STORAGE_KEY = 'mobileZuzLanguageId',
		APPROVED_COOKIES_STORAGE_KEY = 'approvedCookies';

	function ConfigService($rootScope, $q, $window, $location, $timeout, $injector, Retailer, SpDeliveryAreasService,
						   SP_SERVICES, HeLanguage, EnLanguage, EsLanguage, RuLanguage, FrLanguage, ArLanguage,
						   MOBILE_SERVICE_ID, Api) {
		var self = this,
			locationSearch = $location.search(),
			_isLoginAsBranch = false,
			_branchArea,
			User,
			Util,
			Tree,
			_initiated = false;

		// set the config from the window
		// this will be set from the server in web browsers and from the scripts/config.js file in a cordova app
		Object.assign(self, window.sp.mobileData);

		self.waitForInit = waitForInit;
		self.setBranch = setBranch;
		self.waitForBranchId = waitForBranchId;
		self.setBranchArea = setBranchArea;
		self.setIsChosenArea = setIsChosenArea;
		self.setLocalStorageItem = setLocalStorageItem;
		self.setLanguage = setLanguage;
		self.getBranchArea = getBranchArea;
        self.changeBranch = changeBranch;
		self.setApprovedCookies = setApprovedCookies;
		self.retailerHeavyTagLimitations = [];
		self.mapOfProductTagProducts = {};
		self.mapOfRetailerProductTagProducts = {};
		self.arrayOfProductTagProducts = [];
		self.arrayOfRetailerProductTagProducts = [];

		self.languages = [EnLanguage, EsLanguage, HeLanguage, RuLanguage, FrLanguage, ArLanguage];

		self.loginAsData = {};
		if (locationSearch.branchId) {
			self.loginAsData.branchId = Number(locationSearch.branchId);
		}
		if (locationSearch.token && window.location.pathname !== '/') {
			self.loginAsData.token = locationSearch.token;
		} else if (locationSearch.token && (!locationSearch.id || !locationSearch.loginOrRegister)) {
			self.loginAsData.token = locationSearch.token
			removeAutomaticLoginQueryParams();
		}
		if (locationSearch.userId) {
			self.loginAsData.userId = Number(locationSearch.userId);
		}
		if (locationSearch.loyaltyClubCardId) {
			self.loginAsData.loyaltyClubCardId = locationSearch.loyaltyClubCardId;
		}
		var organization;
		if (locationSearch.organization) {
			try {
				organization = JSON.parse(locationSearch.organization);
			} catch(err) {}
		}
		if (organization) {
			self.loginAsData.organization = organization;
		}

		self.isPrerender = navigator.userAgent.includes('SPPrerenderService;');
        self.preventDialogsOnLoad = _getPreventDialogsOnLoad();

		// to change key from 'mobileZuzZipCode' to 'mobileZuzArea'
		var zipCode = localStorage.getItem('mobileZuzZipCode');
		if (zipCode) {
			localStorage.removeItem('mobileZuzZipCode');
			localStorage.setItem(AREA_NAME_LOCAL_STORAGE_KEY, zipCode);
		}

		var languageId = localStorage.getItem(LANGUAGE_STORAGE_KEY);
		if (languageId === undefined || languageId === null || isNaN(languageId)) {
			languageId = self.retailerLanguageId;
		} else {
			languageId = Number(languageId);
		}

		angular.forEach(self.languages, function(language) {
			if (language.id === languageId) {
				setLanguage(language);
			}
			if (language.id === self.retailerLanguageId) {
				self.retailerLanguage = language;
			}
		});

		self.approvedCookies =  JSON.parse(localStorage.getItem(APPROVED_COOKIES_STORAGE_KEY));

		// Use a timeout to prevent a circular dependency with the Api service who uses the Config
		$timeout(function() {
			return Retailer.getRetailerSettings();
		}).then(function(retailer) {
			_init(retailer);
		});

		/**
		 * Removes token, id, loginOrRegister from query params and adds homepage as previous window state
		 * @public
		 *
		 * @returns {void}
		 */
		function removeAutomaticLoginQueryParams() {
			$location.search('token', null);
			$location.search('id', null);
			$location.search('loginOrRegister', null);
			window.history.replaceState({}, "", $location.$$url);
			window.history.pushState({}, "", $location.$$url);
		}

		/**
		 * Wait for the initiation of the retailer configurations
		 * @public
		 *
		 * @returns {Promise}
		 */
		function waitForInit() {
			return new $q(function(resolve) {
				if (_initiated) {
					return resolve(true);
				}

				var listener = $rootScope.$on('config.initiated', function() {
					listener();
					resolve(true);
				});
			});
		}

		function _setContactPhone() {
			self.retailer.contactPhone = self.retailer.contactPhone || (self.branch && self.branch.phone);
		}

		/**
		 * Init the retailer config details
		 * @private
		 *
		 * @param {object} retailer
		 */
		function _init(retailer) {
			self.retailer = retailer;
			self.defaultWeightUnitNames = retailer.defaultWeightUnitNames;

			self.multiRetailers.retailers = self.multiRetailers.retailers || [retailer];

			self.contactUsPageDesign = {
				isUseContactPage: self.retailer && self.retailer.settings.isUseContactPage === 'true',
				content: self.retailer && self.retailer.contactUsPageDesign,
			};
			self.retailer.enablePersonalReplacement = self.retailer && self.retailer.settings.enablePersonalReplacement === 'true';


			self.retailerLanguages = [];
			angular.forEach(self.languages, function(language) {
				angular.forEach(retailer.languages, function(retailerLanguage) {
					if (language.id === retailerLanguage.languageId) {
						self.retailerLanguages.push(language);
					}
				});
			});

			if(self.retailer && self.retailer.settings) {
				if(self.retailer.settings.activeLanguages) {
					var activeLanguages = JSON.parse(self.retailer.settings.activeLanguages);
					var defaultLanguages = [EnLanguage, EsLanguage, HeLanguage, RuLanguage, FrLanguage, ArLanguage];
					var temp = [];
					if(activeLanguages && activeLanguages.length) {
						activeLanguages.forEach(function(localeId) {
							defaultLanguages.forEach(function(locale) {
								if(localeId === locale.id) {
									temp.push(locale);
								}
							})
						})
					}
					self.retailerLanguages = temp.slice();
				}
			}

			_setHomePageVideos(self.retailer.homePageVideos);
			_parseAreaAvailabilitySettings(self.retailer.advancedAreaAvailabilitySettings);

			SpDeliveryAreasService.setRetailerConfigurations(retailer);
			SpDeliveryAreasService.setMultiRetailers(self.multiRetailers.retailers);
			$timeout(function() {
				SpDeliveryAreasService.setOrganizationBranches((_getUser().getUserLoginData() || {}).organizationBranches || null);
				_initHeavyTagLimitations();
				_initTree();
			});
			setIsChosenArea(localStorage.getItem(IS_AREA_CHOSEN_LOCAL_STORAGE_KEY));

			var savedBranchAreaId = localStorage.getItem(AREA_ID_LOCAL_STORAGE_KEY) && Number(localStorage.getItem(AREA_ID_LOCAL_STORAGE_KEY));
			$q.all({
				isZipCodeArea: SpDeliveryAreasService.isZipCodeArea(),
				chooseAreaEvent: SpDeliveryAreasService.getChooseAreaEvent(),
				initBranchAndArea: SpDeliveryAreasService.initBranchAndArea({
					branchId: Number($window.localStorage.getItem(BRANCH_ID_LOCAL_STORAGE_KEY)),
					branchAreaId: savedBranchAreaId,
					branchAreaText: localStorage.getItem(AREA_NAME_LOCAL_STORAGE_KEY),
					queryBranchId: self.loginAsData.branchId,
					isPrerender: self.isPrerender
				})
			}).then(function(results) {
				self.isZipCodeArea = results.isZipCodeArea;
				self.chooseAreaEvent = results.chooseAreaEvent;
				_isLoginAsBranch = results.initBranchAndArea.isQueryBranch;

				setBranch(results.initBranchAndArea.branch);
				if (results.initBranchAndArea.branch) {
					setBranchArea(results.initBranchAndArea.branchAreaId, results.initBranchAndArea.branchAreaText,
						savedBranchAreaId && savedBranchAreaId === results.initBranchAndArea.branchAreaId);
				}
			}).finally(function() {
				_initiated = true;
				$rootScope.$emit('config.initiated');
			});
		}

		function _getPreventDialogsOnLoad() {
			var localStorageValue = !!localStorage.getItem('preventDialogsOnLoad');
			if (localStorageValue) {
				localStorage.removeItem('preventDialogsOnLoad');
				return localStorageValue;
			}

			// to prevent dialogs on user confirmation update route
			if (locationSearch.userConfirmationToken) {
				return true;
			}

			return self.isPrerender;
		}

		function _setHomePageVideos(videos) {
			var filteredVideos = (videos || []).filter(function(video) {
				return video.serviceId === MOBILE_SERVICE_ID;
			});

			if (filteredVideos.length) {
				self.retailer.homePageActiveVideo = filteredVideos[0];
			}
		}

		function _parseAreaAvailabilitySettings(settings) {
			var PREMIUM_UPDATE_TIME_SLOT_ID = 27;
			var isEnablePremium = self.retailer.premiumFeaturesEnabled.includes(
				PREMIUM_UPDATE_TIME_SLOT_ID
			);

			self.advancedAreaAvailabilityEnabled = isEnablePremium && !!(self.retailer.settings.advancedAreaAvailabilityEnabled === 'true');
			if (!self.languages || !self.advancedAreaAvailabilityEnabled) {
				return;
			}

			self.advancedAreaAvailabilitySettings = {};

			self.storeIconUrl = '';
			self.deliveryImageUrl = ''
			self.resultsImageUrl = '';

			angular.forEach(self.languages, function(language) {
				self.advancedAreaAvailabilitySettings[language.id] = [];

				var data = {
					retailerId: self.retailer.id,
					languageId: language.id,
					scenarioId: 0,
					deliveryTabName: '',
					pickupTabName: '',
					firstDefaultTab: 0,
					tabsAndButtonsTextColor: '',
					tabsAndButtonsBgColor: '',
					popupTitle: {},
					popupSubTitle: {},
					popupSecondSubTitle: {},
					deliverySubTitle: {},
					searchMessageMode: 0,
					resultsPopupTitle: {},
					resultsPopupSubTitle: {},
					continueShoppingTextButton: {}
				};

				if (settings && settings.length) {
					angular.forEach(settings, function(item) {
						if (item.languageId === language.id && item.id) {
							item.popupTitle =  item.popupTitle && JSON.parse(item.popupTitle) || {};
							item.popupSubTitle =  item.popupSubTitle && JSON.parse(item.popupSubTitle) || {};
							item.popupSecondSubTitle =  item.popupSecondSubTitle && JSON.parse(item.popupSecondSubTitle) || {};
							item.deliverySubTitle =  item.deliverySubTitle && JSON.parse(item.deliverySubTitle) || {};
							item.resultsPopupTitle =  item.resultsPopupTitle && JSON.parse(item.resultsPopupTitle) || {};
							item.resultsPopupSubTitle =  item.resultsPopupSubTitle && JSON.parse(item.resultsPopupSubTitle) || {};
							item.continueShoppingTextButton =  item.continueShoppingTextButton && JSON.parse(item.continueShoppingTextButton) || {};
							item.deliveryImage =  item.deliveryImage && JSON.parse(item.deliveryImage) || {};
							item.resultsImage =  item.resultsImage && JSON.parse(item.resultsImage) || {};
							item.contactDetails =  item.contactDetails && JSON.parse(item.contactDetails) || {};
							data = item;
						}
					});
				}
				self.advancedAreaAvailabilitySettings[language.id] = data;
			});
		}


		/**
		 * Set the branch, save it to local storage and emit its event
		 * @public
		 *              *
		 * @param {Object} branch
		 */
		function setBranch(branch) {
			if (self.branch === branch || self.branch && branch && self.branch.id === branch.id) {
				return;
			}

			self.branch = branch;
			setLocalStorageItem(BRANCH_ID_LOCAL_STORAGE_KEY, branch.id);
			_setContactPhone();
			$rootScope.$emit('config.branch.set', branch);
		}

		/**
		 * Return a promise that will return the branch id when it has a value
		 * @public
		 *
		 * @returns {Promise}
		 */
		function waitForBranchId() {
			return new $q(function(resolve) {
				if (self.branch && self.branch.id) {
					return resolve(self.branch.id);
				}

				var listener = $rootScope.$on('config.branch.set', function(event, data) {
					if (data && data.id) {
						listener();
						resolve(data.id);
					}
				});
			});
		}

		/**
		 * Set the config branch area id and name and save to local storage
		 * @public
		 *
		 * @param {number|null} id
		 * @param {string|null} name
		 * @param {boolean} [doNotRestIsChosenArea]
		 */
		function setBranchArea(id, name, doNotRestIsChosenArea) {
			if (!self.branch) {
				throw new Error('Please wait for the config to be initiated before setting an area (using waitForInit)');
			}

			var area = id && self.branch.areas.find(function(area) {
				return area.id === id;
			});
			if (!area) {
				id = undefined;
				name = undefined;
			}

			var prevAreaId = self.branchAreaId;
			self.branchAreaId = id ? Number(id) : id;
			self.branchAreaName = name;

			if (prevAreaId !== self.branchAreaId) {
				$rootScope.$emit('config.branchAreaId.set');

				if (!doNotRestIsChosenArea) {
					setIsChosenArea(false);
				}
			}
			// setLocalStorageItem(AREA_NAME_LOCAL_STORAGE_KEY, name);
			// setLocalStorageItem(AREA_ID_LOCAL_STORAGE_KEY, id);
		}

		function setIsChosenArea(is) {
			self.isChosenArea = !!is;

			setLocalStorageItem(IS_AREA_CHOSEN_LOCAL_STORAGE_KEY, self.isChosenArea);
		}

		function setLocalStorageItem(key, value) {
			if (_isLoginAsBranch) {
				return;
			}

			if (value) {
				localStorage.setItem(key, value);
			} else {
				localStorage.removeItem(key);
			}
		}

		function getBranchArea() {
			if (_branchArea && _branchArea.id === self.branchAreaId) {
				return _branchArea;
			}

			if (!self.branch) {
				throw new Error('Please wait for the config to be initiated (using waitForInit)');
			}

			 _branchArea = self.branch.areas.find(function(area) {
				return area.id === parseInt(self.branchAreaId);
			});
			return _branchArea;
		}

		function setLanguage(language, isNeedCleanQueryParameter) {
			self.language = language;
			localStorage.setItem(LANGUAGE_STORAGE_KEY, language.id);
			$rootScope.$emit('config.language.set', language);
			SpDeliveryAreasService.setLanguageId(language.id);

			var langQueryName = 'lang';
			if (isNeedCleanQueryParameter && $location.search()[langQueryName]) {
				$location.search(langQueryName, null);
			}
		}

		function setApprovedCookies(approvedCookies) {
			self.approvedCookies = approvedCookies;
			localStorage.setItem(APPROVED_COOKIES_STORAGE_KEY, JSON.stringify(approvedCookies));
		}

        /**
         * Check order's Branch data and if differs from current (config) we switching Branch
         * @public
         *
         * @param {number} branchId
         * @param {number} branchAreaId
		 * @param {Object} options
		 * @param {boolean} [options.forceBranchChange]
		 * @param {string} [options.typedArea]
		 * @param {number} [options.newRetailerId]
         *
         * @returns {Promise}
         */
        function changeBranch(branchId, branchAreaId, options) {
			options = options || {};

            var currentBranchId = self.branch && self.branch.id ? self.branch.id : 0,
                currentBranchAreaId = self.branchAreaId ? self.branchAreaId : 0,
                newBranchId = branchId,
                newBranchAreaId = branchAreaId;

            //== check we have a new branch data to switch to
            if (!newBranchId || !newBranchAreaId) {
                return $q.resolve(false);
            }

            //= check if current branch and area are different from those where order was made
            if (currentBranchId === newBranchId && currentBranchAreaId === newBranchAreaId) {
                return $q.resolve(false);
            }

            var branch = _getBranchByIdFromConfig(newBranchId, options.newRetailerId);
            if (!branch) {
                return $q.resolve(false);
            }

            var area = _getAreaByIdFromBranch(branch, newBranchAreaId);

			// when force branch change, find the default area when area does not exist
			if (options.forceBranchChange && !area) {
				// when the branch is not actually changing, try to stay on the same area
				if (currentBranchId === newBranchId) {
					area = _getAreaByIdFromBranch(branch, self.branchAreaId)
				}

				area = area || _getDefaultAreaFromBranch(branch);

				if (area) {
					newBranchAreaId = area.id;
				}
			}

            if (!area) {
                return $q.resolve(false);
            }

            return SpDeliveryAreasService.getChooseAreaMode(false).then(function(chooseAreaMode) {
                var areaName = area.name;
                if (area.deliveryTypeId !== SP_SERVICES.DELIVERY_TYPES.PICKUP && chooseAreaMode === SP_SERVICES.CHOOSE_AREA_MODE.TYPE_AREA && area.names && area.names.length) {
                    areaName = options.typedArea || area.names[0].name;
                }

                //== set a Global config data - those will set a requered branch after reload
                self.setBranch(branch);
                self.setBranchArea(newBranchAreaId, areaName);
                self.setIsChosenArea(true);

                //== reload only if branch is changed, not when area changed
                if (currentBranchId !== newBranchId) {
					if (!options.newRetailerId || (self.retailer && options.newRetailerId === self.retailer.id)) {
						//== set this to true for skipping different popups on page reload
						localStorage.setItem('preventDialogsOnLoad', 'true');
					}

                    //== reload after $timeout for the config watchers to finish
                    $timeout(function () {
						_getUtil().reload();
                    });
                    return true;
                }
                return false;
            });
        }

        /**
         * Get Branch object by Branch Id from the config object
         * @private
         *
         * @param {Number} branchId
         * @param {Number} retailerId
         *
         * @returns {Object} area
         */
        function _getBranchByIdFromConfig(branchId, retailerId){
            var retailer = _getRetailerByIdFromConfig(retailerId);

            if (retailer && retailer.branches){
                for (var i = 0; i < retailer.branches.length; i++) {
                    if (retailer.branches[i].id === branchId){
						if (!retailer.branches[i].isDisabled && retailer.branches[i].isOnline &&
							(retailer.id !== self.retailer.id || _getUser().isOrganizationBranch(retailer.branches[i].id))) {
							return retailer.branches[i];
						} else {
							return;
						}
                    }
                }
            }
        }

        /**
         * Get Retailer object - for Multi Retailer we need to get new retailer
         * @private
         *
         * @param {Number} retailerId
         *
         * @returns {Object} Retailer
         */
        function _getRetailerByIdFromConfig(retailerId){
            var currentRetailerId = self.retailer ? self.retailer.id : 0;

            //== if new Retailer Id not provided or it's the same - return current retailer from config
            if (!retailerId || currentRetailerId === retailerId){
                return self.retailer;
            }

            //== get a list of all retailers if Multi retailer
            var retailers = self.multiRetailers && self.multiRetailers.retailers ? self.multiRetailers.retailers : self.retailers;

            if (retailers) {
                for (var i = 0; i < retailers.length; i++) {
                    if (retailers[i].id === retailerId) {
                        return retailers[i];
                    }
                }
            }

            return self.retailer;
        }

        /**
         * Get Area object by Area Id from the branch object
         * @private
         *
         * @param {Object} branch
         * @param {Number} orderBranchAreaId
         *
         * @returns {Object} area
         */
        function _getAreaByIdFromBranch(branch, orderBranchAreaId) {
			return (branch && branch.areas || []).find(function(area) {
				return area.id === orderBranchAreaId;
			});
        }

		/**
		 * Get the default area object from the branch object
		 * @private
		 *
		 * @param {Object} branch
		 *
		 * @returns {Object} area
		 */
		function _getDefaultAreaFromBranch(branch) {
			var areas = branch && branch.areas || [];

			var withNames = areas.find(function(area) {
				return area.names && area.names.length;
			});

			return withNames || areas[0];
		}

		/**
		 * Returns the user service, to prevent circular dependencies
		 * @private
		 *
		 * @return {Object}
		 */
		function _getUser() {
			return User = User || $injector.get('User');
		}

		/**
		 * Returns the util service, to prevent circular dependencies
		 * @private
		 *
		 * @return {Object}
		 */
		function _getUtil() {
			return Util = Util || $injector.get('Util');
		}

		function _initHeavyTagLimitations () {
			return getHeavyTagLimitations().then(function (quantityLimitations) {
				self.retailerHeavyTagLimitations = quantityLimitations.heavyPackageLimits;
				self.mapOfProductTagProducts = quantityLimitations.mapOfProductTagProducts;
				self.mapOfRetailerProductTagProducts = quantityLimitations.mapOfRetailerProductTagProducts;
				self.arrayOfProductTagProducts = quantityLimitations.mapOfProductTagProducts ? Object.values(quantityLimitations.mapOfProductTagProducts).flat() : [];
				self.arrayOfRetailerProductTagProducts = quantityLimitations.mapOfRetailerProductTagProducts ? Object.values(quantityLimitations.mapOfRetailerProductTagProducts).flat() : [];
			});
		}

		// in mobile web the tree is already available through window.sp.mobileData.tree which is injected in web-data.ejs.
		// in mobile app we request the tree through the Tree service
		function _initTree () {
			if (!self.tree) {
				Tree = Tree || $injector.get('Tree');
				return Tree.getTree().then(function (tree) {
					self.tree = tree;
				});
			}
		}

		function getHeavyTagLimitations () {
			return Api.request({
				method: 'GET',
				url: '/v2/retailers/:rid/limit-configurations/limitations'
			});
		}
	}

	angular.module('mobilezuz').service('Config', [
		'$rootScope', '$q', '$window', '$location', '$timeout', '$injector', 'Retailer', 'SpDeliveryAreasService',
		'SP_SERVICES', 'HeLanguage', 'EnLanguage', 'EsLanguage', 'RuLanguage', 'FrLanguage', 'ArLanguage', 'MOBILE_SERVICE_ID',
		'Api', ConfigService
	]);
})(angular);
