(function () {
    'use strict';

    angular.module('mobilezuz')
        .directive('doSubmit', ['$compile', function ($compile) {
            return {
                restrict: 'A',
                compile: function (element) {
                    var parentForm = element.parents('form:first'),
                        ngTapFunction = 'submitMyForm' + (parentForm.attr('id') || '');
                    if (cordova.platformId == 'ios') {
                        element.attr('ng-click', ngTapFunction + '()');
                    } else {
                        element.attr('type', 'submit');
                    }
                    element.removeAttr('do-submit');
                    return {
                        post: function ($scope) {
                            $compile(element)($scope);
                            if (cordova.platformId == 'ios') {
                                $scope[ngTapFunction] = function () {
                                    parentForm.submit();
                                };
                            }
                        }
                    };
                }
            };
        }]);
})();