angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.retailer.information', {
        url: '/information',
        data: {
            stateAccess: PAGE_ACCESS.ALLOW,
            bodyClass: 'about-branches',
            metaTags: {
                title: 'Branches'
            }
        },
        views: {
            '@': {
                templateUrl: 'views/information.html',
                controller: 'InformationCtrl'
            }
        }
    });
}]).controller('InformationCtrl', ['$scope', '$rootScope', 'User', 'BranchDistance',
    function ($scope, $rootScope, User, BranchDistance) {
        $scope.title = $rootScope.title = 'Store Info';

        User.getRetailerData().then(function (data) {
            $scope.branches = data.branches;
            $rootScope.title = $scope.branches.length > 1 ? 'Stores Info' : $rootScope.title;

            BranchDistance.calcBranchesDistance($scope.branches);

            // $scope.branches.forEach(function (branch) {
            //     BranchDistance.calcDistance(branch).then(function (route) {
            //         if (!route) {
            //             return;
            //         }
            //
            //         var split = route.legs[0].distance.text.split(' ');
            //         branch.distanceText = split[1];
            //         branch.distanceNum = split[0];
            //         branch.distanceValue = route.legs[0].distance.value;
            //     }, function (err) {});
            // });

        });
    }]);