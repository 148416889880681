(function (angular) {
    angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
        $stateProvider.state('app.shipment-tracking', {
            url: '/shipment-tracking?{orderId}',
            data: {
                stateAccess: PAGE_ACCESS.ALLOW,
                bodyClass: 'shipmentTracking',
                metaTags: {
                    title: 'Shipment Tracking'
                }
            },
            views: {
                '@': {
                    templateUrl: 'views/shipmentTracking.html',
                    controller: 'ShipmentTrackingCtrl as shipmentTrackingCtrl',
                }
            }
        });

    }]).controller('ShipmentTrackingCtrl', [
        '$scope', '$rootScope', '$window', '$stateParams', '$q', '$interval', 'Orders',
        function ($scope, $rootScope, $window, $stateParams, $q, $interval, Orders) {
            var shipmentTrackingCtrl = this;
            $rootScope.header = false;
            $rootScope.footer = false;
            shipmentTrackingCtrl.orderId = Number($stateParams.orderId);

            init();
            function init() {
               callPopUpDeliveryOrderStatus(shipmentTrackingCtrl.orderId);
               callOrderDetails(shipmentTrackingCtrl.orderId);
            }
            function callPopUpDeliveryOrderStatus(orderId){
                var deferred = $q.defer();

                Orders.getOrderBoxesStatuses(orderId).then(function (resultBoxesStatuses) {

                    if (resultBoxesStatuses) {
                        deferred.resolve(resultBoxesStatuses);
                        shipmentTrackingCtrl.boxesStatuses = resultBoxesStatuses;
                    }
                    else {
                        deferred.reject();
                    }
                });
                return deferred.promise;

            }

            function callOrderDetails(orderId){
                var deferred = $q.defer();

                Orders.getOrderDeliveryStatus(orderId).then(function (resultOrder) {

                    if (resultOrder !== null) {
                        deferred.resolve(resultOrder);
                        shipmentTrackingCtrl.user = resultOrder;
                    }
                    else {

                        deferred.reject();
                        $window.location.href = '/'
                    }
                });
                return deferred.promise;
            }

            shipmentTrackingCtrl.interval = $interval(function() {
                callPopUpDeliveryOrderStatus(shipmentTrackingCtrl.orderId);
            }, 30000);
        }]);
})(angular);
