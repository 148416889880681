(function (angular, app) {
  "use strict";

  app.controller("IrrelevantSalesCtrl", [
    "$scope",
    "$rootScope",
    "$filter",
    "Util",
    "Orders",
    "Cart",
    controller,
  ]);

  function controller($scope, $rootScope, $filter, Util, Orders, Cart) {
    var irreSaCtrl = this;
    var updateOrderV2Ctrl = $scope.updateOrderV2Ctrl;
    var _translate = $filter("translate");

    var backButtons = {
      changeSlot: {
        text: "irre_sales_btn_change_slot",
        onClick: onClickChangeDeliveryDate,
      },

      cancelUpdate: {
        text: "irre_sales_btn_cancel_update",
        onClick: onClickCancelUpdate,
      },
    };

    /** @type {Record<SP_SERVICES.CART_LINE_TYPES, boolean>} */
    irreSaCtrl.lineTypes = {};

    /** @type {ExpiredInfoItem[]} */
    irreSaCtrl.displayLines = [];
    irreSaCtrl.totalPriceUpdate = 0;
    irreSaCtrl.data = { analyticsLabel: "" };
    irreSaCtrl.isHaveExpireInfo = false;
    irreSaCtrl.backBtn = backButtons.cancelUpdate;
    irreSaCtrl.isUpdating = false;

    angular.extend(irreSaCtrl, {
      onClickUpdate: onClickUpdate,
    });

    // ------- Function -------

    _init();

    function _init() {
      if (!updateOrderV2Ctrl.irreSalesData) {
        return;
      }
      _initIrreSaleDisplayLines(
        updateOrderV2Ctrl.irreSalesData.expiredInfo,
        updateOrderV2Ctrl.irreSalesData.lineTypes,
        updateOrderV2Ctrl.irreSalesData.skipDialogSpecial
      );
      _initBackButton(updateOrderV2Ctrl.irreSalesData.expiredInfo);
    }

    /**
     * @param {ExpiredInfo} expiredInfo
     * @param {*[]} outOfStockLines
     */
    function _initBackButton(expiredInfo) {
      var backBtnType = Util.checkIsCalculateCheckoutTime() ? "cancelUpdate" : "changeSlot";

      if (!irreSaCtrl.isHaveExpireInfo && expiredInfo.outOfStock.items.length) {
        backBtnType = "cancelUpdate";
      }

      var transDeliveryType = _translate(
        "delivery_type_" + Orders.orderInEdit.branchDeliveryTypeId
      );

      var transBackBtnText = _translate(backButtons[backBtnType].text).replace(
        "{delivery method}",
        transDeliveryType
      );

      irreSaCtrl.backBtn = {
        onClick: backButtons[backBtnType].onClick,
        text: transBackBtnText,
      };
    }

    /**
     * @param {'sellDates' | 'special' | 'coupon' | 'outOfStock'} saleType
     * @return {'oos' | 'selldate' | 'checkout' | 'collect' | ''}
     */
    function _getSaleTypeTransKey(saleType) {
      switch (saleType) {
        case "outOfStock":
        case "sellDates":
          return "oos";

        case "coupon":
        case "special":
          return Util.checkIsCalculateCheckoutTime() ? "checkout" : "collect";

        default:
          return "";
      }
    }

    /**
     * Example result:
     *
     * - irre_sales_collect_one
     *
     * - irre_sales_checkout_many
     *
     * - irre_sales_oos_many
     *
     * @param {'sellDates' | 'special' | 'coupon' | 'outOfStock'} saleType
     * @param {number} count
     * @param {'singular' | 'plural'} type
     * @returns {string}
     */
    function _constructTitleV2(saleType, count) {
      var amountField = count === 1 ? "one" : "many";
      var saleTypeKey = count === 1 ? saleType : saleType + "s";

      var title = "irre_sales_" + _getSaleTypeTransKey(saleType) + "_" + amountField;

      var translatedType = _translate(saleTypeKey) ? _translate(saleTypeKey).toLowerCase() : "";

      var transTitle = _translate(title)
        .replace("{saleType}", translatedType)
        .replace("{count}", count);

      return transTitle;
    }

    /**
     * @param {CartLines} cartLines
     * @return {number}
     */
    function _getPriceOfIrreSales(cartLines, skipDialogSpecial) {
      if (!cartLines || !cartLines.length) {
        return 0;
      }

      var needSubtractPrice = 0;

      cartLines.forEach(function (line) {
        var finalPrice = skipDialogSpecial
          ? line.finalPriceForView
          : line.finalPriceForViewPotential || 0;

        needSubtractPrice += Number(finalPrice || 0);
      });

      return needSubtractPrice;
    }

    /**
     * @param {ExpiredInfo} expiredInfo // type def refer to cart.js
     * @param {Record<SP_SERVICES.CART_LINE_TYPES, boolean>} lineTypes
     * @param {boolean} skipDialogSpecial
     */
    function _initIrreSaleDisplayLines(expiredInfo, lineTypes, skipDialogSpecial) {
      // set price at start then subtract invalid items later
      irreSaCtrl.totalPriceUpdate = skipDialogSpecial
        ? $rootScope.cart.total.finalPriceForView
        : $rootScope.cart.total.finalPriceForViewPotential;

      var displayLines = [];

      Object.keys(expiredInfo).forEach(function (key) {
        var obj = expiredInfo[key] || {};

        if (!obj.items || !obj.items.length) {
          return;
        }

        if (key !== "outOfStock") {
          irreSaCtrl.isHaveExpireInfo = true;
        }

        var count = obj.items.length;
        obj.mainTitle = _constructTitleV2(key, count);
        displayLines.push(obj);

        // subtract prices that will be removed after clicking update
        if (key === "sellDates") {
          irreSaCtrl.totalPriceUpdate -= _getPriceOfIrreSales(obj.items, skipDialogSpecial);
        }
      });

      irreSaCtrl.displayLines = handleDisplayLines(displayLines);
      irreSaCtrl.data = { analyticsLabel: "" };
      irreSaCtrl.lineTypes = lineTypes;
    }

    /**
     * Combine items from out of stock with sell date to show as 1 section
     * @param {ExpiredInfoItem[]} displayLines
     * @return {ExpiredInfoItem[]}
     */
    function handleDisplayLines(displayLines) {
      /** @type {ExpiredInfoItem} */
      var sellDateOutOfStockLine = null;

      /** @type {ExpiredInfoItem[]} */
      var newDisplayLines = [];

      displayLines.forEach(function (line) {
        if (["outOfStock", "sellDates"].includes(line.type)) {
          if (sellDateOutOfStockLine) {
            sellDateOutOfStockLine.items = sellDateOutOfStockLine.items.concat(line.items);
          } else {
            // avoid chagne original data
            sellDateOutOfStockLine = angular.copy(line);
            sellDateOutOfStockLine.type = "sellDateOutOfStock";
          }

          return;
        }

        newDisplayLines.push(line);
      });

      if (sellDateOutOfStockLine) {
        sellDateOutOfStockLine.mainTitle = _constructTitleV2(
          "outOfStock",
          sellDateOutOfStockLine.items.length
        );
        newDisplayLines.unshift(sellDateOutOfStockLine);
      }

      return newDisplayLines;
    }

    function onClickCancelUpdate() {
      updateOrderV2Ctrl.close();
    }

    function onClickChangeDeliveryDate() {
      updateOrderV2Ctrl.back();
    }

    /**
     * @returns {Promise<void>}
     */
    function _removeInvalidLines() {
      var needRemoveLines = [];
      var outOfStockItems = updateOrderV2Ctrl.irreSalesData.expiredInfo.outOfStock.items;
      var expiredSellDatesItems = updateOrderV2Ctrl.irreSalesData.expiredInfo.sellDates.items;

      if (outOfStockItems.length) {
        needRemoveLines = needRemoveLines.concat(outOfStockItems);
      }

      if (expiredSellDatesItems.length) {
        needRemoveLines = needRemoveLines.concat(expiredSellDatesItems);
      }

      return Cart.removeLines(needRemoveLines, true, true);
    }

    function onClickUpdate() {
      irreSaCtrl.isUpdating = true;

      _removeInvalidLines()
        .then(function () {
          return updateOrderV2Ctrl.updateOrder();
        })
        .finally(function () {
          irreSaCtrl.isUpdating = false;
        });
    }
  }
})(angular, angular.module("mobilezuz"));
