angular.module("mobilezuz").config(["$stateProvider", config]);

var ctrlName = "coMapCtrl";

function config($stateProvider) {
  // KEEP STATE NAME SAME LEVEL WITH CHECKOUT PROCESS
  // app.cart.checkout.process.address.map will lead to bug can't redirect

  $stateProvider.state("app.cart.checkout.process.map", {
    url: "/map?from&defaultLocation",

    checkoutViewOriginalIndex: 1.5,
    // same with address to show same step in UI
    checkoutViewIndex: 1.5,
    views: {
      checkoutProcess: {
        templateUrl: "views/checkout/process/map.html",
        // reuse googleMapDialogCtrl name because reuse template
        controller: "CheckoutMapCtrl as " + ctrlName,
      },
    },
  });
}

/**
 * @typedef {Object} AddressDetails
 * @property {number} lat
 * @property {number} lng
 * @property {string} city
 * @property {string} text1
 * @property {string} country
 * @property {string} placeId
 */

angular
  .module("mobilezuz")
  .controller("CheckoutMapCtrl", [
    "GMap",
    "LocationService",
    "$timeout",
    "$state",
    "$stateParams",
    "$scope",
    "$rootScope",
    "Util",
    "Config",
    "AddressService",
    controller,
  ]);

function controller(
  GMap,
  LocationService,
  $timeout,
  $state,
  $stateParams,
  $scope,
  $rootScope,
  Util,
  Config,
  AddressService
) {
  var ctrl = this;
  var checkoutProcessCtrl = $scope.checkoutProcessCtrl;

  angular.extend(ctrl, {
    isHideSaveBtn: true,
    isShowAutoComplete: false,
    markerPopup: null,
    addressDetails: {
      lat: 0,
      lng: 0,
      city: "",
      text1: "",
      country: "",
      placeId: "",
      hasHouseNumberAndRoute: true
    },
    formattedAddress: "",

    infoWindow: null,
    searchValue: "",
    map: null,
    mapErrorMessage: "",

    handleCloseDialog: nextView,
    getAutoCompleteAddressOptions: getAutoCompleteAddressOptions,
    onChooseSearchOption: onChooseSearchOption,
  });

  angular.extend(checkoutProcessCtrl, {
    nextView: nextView,
    previousView: previousView,
  });

  //================== FUNCTIONS  ==================//

  _init();

  function _init() {
    // map is render BELOW webview, so should hide/transparent all UI
    // https://github.com/mapsplugin/cordova-plugin-googlemaps?tab=readme-ov-file#how-does-this-plugin-work-android-ios
    $rootScope.$emit("cart.hideCartPage");
    $rootScope.$emit("googleMap.opening");

    LocationService.getUserLocation().then(function (userLocation) {
      if (userLocation) {
        return userLocation;
      }
      
      if($stateParams.defaultLocation){
        return GMap.reverseGeocode({ address: $stateParams.defaultLocation}).then(function (res){
          if(!res) return;

          return {
            lat: res.position.lat,
            lng: res.position.lng
          }
        }).catch(function () { return; });
      }

      return;
    }).then(function (location) {
      if (location) {
        return location;
      }
      
      return LocationService.getBranchLocation().then(function (branchLocation) {
        if (branchLocation.lat && branchLocation.lng) {
          return branchLocation;
        }
        return;
      });
    }).then(function (location) {
      var _defaultLocation = LocationService.getDefaultLocation()

      initGoogleMapMobile(location || _defaultLocation);
    }).catch(function (error) {
      ctrl.mapErrorMessage = error.message;
    });
  }

  /**
   *
   * @param {MGeocode} geocode
   */
  function setAddress(geocode) {
    // need $timeout so adrs can be shown on UI
    $timeout(function () {
      var extractedInfo = GMap.extractInfoFromAddressComponents(geocode.address_components);
      ctrl.formattedAddress = geocode.formatted_address;
      var text1Obj = Util.constructAdrsText1(extractedInfo.countryCode, extractedInfo.houseNumber, extractedInfo.route, extractedInfo.city);
      angular.extend(ctrl.addressDetails, {
        lng: geocode.geometry.location.lng,
        lat: geocode.geometry.location.lat,
        placeId: geocode.place_id,
        city: extractedInfo.city,
        text1: text1Obj.value,
        country: extractedInfo.country,
        countryCode: extractedInfo.countryCode,
        zipCode: extractedInfo.zipCode,
        state: extractedInfo.state,
        hasHouseNumberAndRoute: text1Obj.hasHouseNumberAndRoute
      });
    }, 0);
  }

  /**
   *
   * @param {MGeocodePosition} latLng
   */
  function initDefaultAddress(latLng) {
    return AddressService.geocodeByGgMapApi({
      lat: latLng.lat,
      lng: latLng.lng,
      languageId: Config.language.id
    }).then(function (res) {
        return setAddress(res.results[0]);
      })
      .catch(function (err) {
        console.error(err);
      });
  }

  /**
   * @param {*} map
   * @param {MGeocodePosition} latLng
   */
  function initMarker(map, latLng, infoWindow) {
    var marker = GMap.initMarker(map, latLng);
    GMap.moveMapMoveMarkerListener(map, marker, setAddress);

    GMap.clickMarkerTogglePopupListener({
      marker: marker,
      infoWindow: infoWindow,
    });
  }

  /**
   *
   * @param {*} map
   * @param {MGeocodePosition} latLng
   */
  function onMapReady(map, latLng) {
    ctrl.map = map;
    ctrl.infoWindow = GMap.initInfoWindow();

    initMarker(map, latLng, ctrl.infoWindow);
    // MUST init default after init marker -> marker can show address
    initDefaultAddress(latLng);
  }

  /**
   *
   * @param {MGeocodePosition} latLng
   */
  function initGoogleMapMobile(latLng) {
    var mapRef = document.getElementById("map");
    var options = {
      camera: {
        target: latLng,
        zoom: 17,
      },
    };

    GMap.initMap(mapRef, options)
      .then(function (map) {
        onMapReady(map, latLng);
      })
      .catch(function (err) {
        console.error(err);
      });
  }

  /**
   * @param {Geocode[]} geocodes
   * @returns {{city: string, text1: string, hasHouseNumberAndRoute: boolean}}
   */
  function reverseFullAddressFromGeocodes(geocodes) {
    var geo = geocodes.results && geocodes.results[0];

    if (!geo) {
      return null;
    }

    var extractedInfo = GMap.extractInfoFromAddressComponents(geo.address_components);
    var text1Obj = Util.constructAdrsText1(extractedInfo.countryCode, extractedInfo.houseNumber, extractedInfo.route, extractedInfo.city)

    return {
      city: extractedInfo.city,
      text1: text1Obj.value,
      hasHouseNumberAndRoute: text1Obj.hasHouseNumberAndRoute
    };
  }

  function returnToAddressScreen() {
    $state.go($stateParams.from, { addressDetails: JSON.stringify(ctrl.addressDetails) });
  }

  // ECOM-5552 [AutoComplete] - Chosen address from google map is written backwards. not work well on native ios app.
  function handleIosReverseText() {
    if (
      !window.cordova ||
      window.cordova.platformId !== "ios"/* ||
      ctrl.addressDetails.countryCode !== "IL"*/
    ) {
      return Promise.resolve();
    }

    return AddressService.geocodeByGgMapApi({
      lat: ctrl.addressDetails.lat,
      lng: ctrl.addressDetails.lng,
      languageId: Config.language.id,
    }).then(function (geocodes) {
      var newAdrsDetails = reverseFullAddressFromGeocodes(geocodes);

      if (newAdrsDetails) {
        ctrl.addressDetails.text1 = newAdrsDetails.text1;
        ctrl.addressDetails.city = newAdrsDetails.city;
        ctrl.addressDetails.hasHouseNumberAndRoute = newAdrsDetails.hasHouseNumberAndRoute;
      }
    });
  }

  /**
   *
   * @param {AddressDetails} addressDetails
   */
  function nextView() {
    if (!ctrl.addressDetails) {
      return;
    }

    handleIosReverseText().then(function () {
      returnToAddressScreen();
    });
  }

  function previousView() {
    return checkoutProcessCtrl.goToState("app.cart.checkout.process.address");
  }

  function getAutoCompleteAddressOptions() {
    if (!ctrl.searchValue) {
      return Promise.resolve([]);
    }

    return GMap.geocoding(ctrl.searchValue, Config.language.id)
      .then(function (geocodes) {
        return geocodes;
      })
      .catch(function () {
        return [];
      });
  }

  function onChooseSearchOption($event) {
    var selectedGeocode = $event && $event.option;

    if (!selectedGeocode) {
      return;
    }

    if (ctrl.map) {
      GMap.moveCamera(ctrl.map, selectedGeocode.position);
    }

    $timeout(function () {
      document.activeElement.blur();
    }, 200);
  }

  //================== SCOPE LISTENER  ==================//

  $scope.$watch(ctrlName + ".formattedAddress", function () {
    GMap.setContentInfoWindow(ctrl.infoWindow, GMap.formatPopupContent(ctrl.formattedAddress));
  });

  $scope.$on("$destroy", function() {
    $rootScope.$emit("googleMap.closing");
});
}
