(function (angular) {
    angular.module('mobilezuz').directive('spFocusManagement', [
        '$timeout', '$compile', 'Util',
        function ($timeout, $compile, Util) {
        return {
            restrict: 'A',
            scope: {
                previousElementSelector: '@',
                nextElementSelector: '@'
            },
            link: function ($scope, $element) {
                var focusableElements,
                    previousElement,
                    nextElement,
                    firstFocusTrapElement,
                    lastFocusTrapElement;

                $timeout(function () {
                    _bindPreviousElementEvent();
                    _bindNextElementEvent();

                    firstFocusTrapElement = angular.element('<div class="sp-focus-trap first" tabindex="0"></div>').bind('focus', _onFirstFocusTrapFocus);
                    lastFocusTrapElement = angular.element('<div class="sp-focus-trap last" tabindex="0"></div>').bind('focus', _onLastFocusTrapFocus);

                    $element.prepend($compile(firstFocusTrapElement)($scope)[0]);
                    $element.append($compile(lastFocusTrapElement)($scope)[0]);
                }, 100);

                function _onFirstFocusTrapFocus() {
                    if (!previousElement || !previousElement.length) {
                        _bindPreviousElementEvent();
                    }
                    previousElement && previousElement[0].focus();
                }

                function _onLastFocusTrapFocus() {
                    if (!nextElement || !nextElement.length) {
                        _bindNextElementEvent();
                    }

                    nextElement && nextElement[0] && nextElement[0].focus();
                }

                function _onPreviousElementKeyDown(event) {
                    _getFocusableElements();
                    if (event.which === 9 && !event.shiftKey) {
                        focusableElements[0].focus();
                    }
                }

                function _bindPreviousElementEvent() {
                    previousElement = $scope.previousElementSelector && angular.element(document.querySelector($scope.previousElementSelector));
                    previousElement && previousElement.bind('keydown', _onPreviousElementKeyDown);
                }

                function _onNextElementKeyDown(event) {
                    _getFocusableElements();
                    if (event.which === 9 && event.shiftKey) {
                        focusableElements[focusableElements.length -1].focus();
                    }
                }

                function _bindNextElementEvent() {
                    nextElement = $scope.nextElementSelector && angular.element(document.querySelector($scope.nextElementSelector));
                    nextElement && nextElement.bind('keydown', _onNextElementKeyDown);
                }

                function _getFocusableElements() {
                    if (!focusableElements)  {
                        focusableElements = $element[0].querySelectorAll('button, [ng-click], [href], input, select, textarea');
                    }
                }

                Util.currentScopeListener($scope, $scope.$watch('nextElementSelector', function(newVal, oldVal) {
                    if (oldVal === newVal) return;
                    _bindNextElementEvent()
                }));

                Util.currentScopeListener($scope, $scope.$watch('previousElementSelector', function(newVal, oldVal) {
                    if (oldVal === newVal) return;
                    _bindPreviousElementEvent()
                }));

                Util.currentScopeListener($scope, function() {
                    firstFocusTrapElement.unbind('focus', _onFirstFocusTrapFocus);
                    lastFocusTrapElement.unbind('focus', _onLastFocusTrapFocus);
                    previousElement && previousElement.unbind('keydown', _onPreviousElementKeyDown);
                    nextElement.unbind('keydown', _onNextElementKeyDown);
                });
            }
        };
    }]);
})(angular);