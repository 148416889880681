(function(angular) {
    angular.module('mobilezuz').directive('productList', ['$rootScope', 'Products', 'Cart', 'Util', function($rootScope, Products, Cart, Util) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'views/templates/product-list.html',
            scope: {
                products: '=',
                orderBy: '=',
                groupBy: '=?',
                limitTo: '=?',
                mode: '@', // landscape/portrait - defaults as portrait (undefined is rendered as portrait)
                addToCartQuantityRecommendation: '@?',
                inViewWatcherShown: '<',
                categoryType: '<?',
                boostProductTags: '<?',
                isSpecialsDialog: '=?',
                isFromRecommendationPopup: '=?',
                source: '@'
            },
            link: function ($scope) {
                var _listeners = [],
                    _groupsMap = {};

                $scope.groups = [];
                $scope.stringify = JSON.stringify;

                $scope.$watch('products', _setProductsContext);

                _listeners.push($scope.$root.$on('products.added', function(event, data) {
                    _groupChunk(data.chunk.products);
                }));

                $scope.$on('$destroy', function() {
                    angular.forEach(_listeners, function(listener) {
                        listener();
                    });
                });

                function _setProductsContext() {
                    $scope.productsContext = Products.productsEditContext($scope.products);

                    if($scope.isFromRecommendationPopup) {
                        var cartLines = Cart.getLines();

                        angular.forEach($scope.products, function(product) {
                            if(product._suggestions && product._suggestions.length) {
                                angular.forEach(product._suggestions, function(suggestion) {
                                    angular.forEach(cartLines, function(line) {
                                        if(line.product.id === suggestion.id) {
                                            Object.assign(suggestion, line.product)
                                        }
                                    })
                                })
                            }
                        })

                        $scope.global = {
                            productsContext: Products.productsEditContext($scope.products)
                        }
                    }
                }

                function _groupChunk(chunk) {
                    if (!$scope.groupBy || !$scope.groupBy.by) return;

                    angular.forEach(chunk, function(item) {
                        var byVal = $scope.$eval($scope.groupBy.by, item),
                            group = _groupsMap[byVal];
                        if (!group) {
                            group = {
                                value: $scope.$eval($scope.groupBy.value, item),
                                items: []
                            };
                            _groupsMap[byVal] = group;
                            $scope.groups.push(group);
                        }
                        group.items.push(item);
                    });
                }
            }
        };
    }]);
})(angular);