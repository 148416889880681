(function () {
    "use strict";

    /**
     * shows an alternative img on image load error
     */
    angular.module('mobilezuz')
        .directive('errSrc', function () {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    element.bind('error', function () {
                        attrs.$set('src', attrs.errSrc);
                    });
                }
            };
        });

})();