angular.module("mobilezuz").service("gMapAbstractService", [function(){
  
  var self = this;

  angular.extend(self, {
    initMap: initMap,
    initMarker: initMarker,
    initInfoWindow: initInfoWindow,
    reverseGeocode: reverseGeocode,
    clickMarkerTogglePopupListener: clickMarkerTogglePopupListener,
    formatPopupContent: formatPopupContent,
    setContentInfoWindow: setContentInfoWindow,
    getCoordinateFromGeocode: getCoordinateFromGeocode
  });

  /**
   * @param {Node} elementRef
   * @param {*} mapOptions
   * @returns {Promise<Map>}
   */
  function initMap(elementRef, mapOptions) {}

  /**
   * @abstract
   * @param {*} map
   * @param {GeoLocation} latLng
   * @return marker
   */
  function initMarker(map, latLng) {}

  /**
   * @abstract
   * @param {*} latLng
   * @return {Promise<Geocode | MGeocode>}
   */
  function reverseGeocode(options) {}

  /**
   * @abstract
   * @param {string} html
   * @returns infoWindow
   */
  function initInfoWindow(html) {}

  /**
   * @abstract
   * @param {{
   * map: any
   * marker: any
   * infoWindow: any
   * }} param0
   */
  function clickMarkerTogglePopupListener(params0) {}

  /**
   * @param {string} formattedAddress
   * @returns {string}
   */
  function formatPopupContent(formattedAddress) {
    var POPUP_MARKER_UI =
      '<div style="text-align: left;"><img src="https://d226b0iufwcjmj.cloudfront.net/global/mobile/images/icons/small-gg-map-marker.svg" alt="Small Google Map Marker"><p>{formattedAddress}</p></div>';

    return POPUP_MARKER_UI.replace("{formattedAddress}", formattedAddress);
  }

  /**
   * @param {*} infoWindow
   * @param {string} html
   * @returns
   */
  function setContentInfoWindow(infoWindow, html) {
    if (!infoWindow) {
      return;
    }

    infoWindow.setContent(html);
  }

    /**
     * get position when use geocode on web or mobile app
     * @param {Geocode || MGeocode} geocode
     * @return {Geolocation}
     */
      function getCoordinateFromGeocode(geocode){
        if(!geocode){
          return null
        }
  
        return  geocode.position || {
          lat: geocode.geometry.location.lat(),
          lng: geocode.geometry.location.lng()
        }
      }
}]);
