(function (angular, app) {
	'use strict';

	app.service('SeniorCitizenVerificationDialog', ['mDesign', 'Config', 'User', function(mDesign, Config, User) {
		this.show = show;

		function show() {
			var userLoginData = User.getUserLoginData();

			return Config.waitForInit().then(function() {
				if (!Config.retailer.isSeniorCitizenActive || !userLoginData) {
					return;
				}

				return mDesign.dialog({
					controller: 'SeniorCitizenVerificationCtrl as seniorCitizenVerificationCtrl',
					templateUrl: 'views/templates/senior-citizen-verification.html',
					clickOutsideToClose: true,
					escapeToClose: false,
					resolve: {
						userData: function() {
							return User.getUserSettings();
						}
					}
				});
			});
		}
	}]);

	app.controller('SeniorCitizenVerificationCtrl', [
		'$q', 'mDesign', 'Api', 'User', 'userData',
		function($q, mDesign, Api, User, userData) {
			var seniorCitizenVerificationCtrl = this;

			seniorCitizenVerificationCtrl.initializedSubmit = false;
			seniorCitizenVerificationCtrl.userData = userData;

			seniorCitizenVerificationCtrl.hideDialog = mDesign.hide;
			seniorCitizenVerificationCtrl.verify = verify;

			function verify(form) {
				seniorCitizenVerificationCtrl.initializedSubmit = true;

				if (form.$invalid) {
					return;
				}

				seniorCitizenVerificationCtrl.processing = true;

				return (/*$q.resolve() || */Api.request({
					method: 'POST',
					url: '/v2/retailers/:rid/users/:uid/_verify-senior-citizen',
					data: {
						idNumber: seniorCitizenVerificationCtrl.seniorCitizenId
					}
				})).then(function() {
					return User.getUserSettings(true);
				}).then(function(userData) {
					seniorCitizenVerificationCtrl.userData = userData;
					/*seniorCitizenVerificationCtrl.userData.isSeniorCitizen = true;*/
				}).finally(function() {
					seniorCitizenVerificationCtrl.processing = false;
				});
			}
		}
	]);
})(angular, angular.module('mobilezuz'));