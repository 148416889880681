(function (angular) {
angular.module('mobilezuz')
    .directive('toolBar', ['$rootScope', '$q','User','Config', '$timeout', '$stateParams', function($rootScope, $q,User,Config, $timeout, $stateParams) {
        return {
            restrict: 'A',
            transclude: true,
            templateUrl: 'views/templates/tool-bar.drv.html',
            scope: {},
            bindToController: {
                backgroundImage: '@?',
                filters: '<spFilters',
                spFiltersChange: '&?',
                sorterItems: '<?spSorterItems',
                sortBy: '=?spSortBy',
                spSortByChange: '&?',
                export: '=?',
                onSearchProducts: '&?'
            },
            controllerAs: 'toolBarCtrl',
            controller: ['$scope', '$element', function($scope, $element) {
                var toolBarCtrl = this,
                    _isToolBarFixed = false,
                    $scrollableContent = angular.element(document.querySelector('#main > .content')),
                    _contentElement = $element[0].querySelector('.tool-bar-content'),
                    _fixedContentDefaultPosition,
                    _filtersIndexes = {},
                    _exports = {};

                toolBarCtrl.changeSorter = changeSorter;
                toolBarCtrl.changeFilter = changeFilter;
                toolBarCtrl.searchProductsChanged = searchProductsChanged;
                toolBarCtrl.clearFilters = clearFilters;
                _exports.clearFilters = clearAllFilters;
                toolBarCtrl.hideInnerFilterField = !!($stateParams.query && $stateParams.query.length);

                _countToolItems();
                
                $scrollableContent.bind('scroll', _setToolBarFixed);
                $scope.$on('$destroy', function() {
                    $scrollableContent.unbind('scroll', _setToolBarFixed);
                });

                $scope.$watch('export', function() {
                    toolBarCtrl.export = _exports;
                });

                $scope.$watch('toolBarCtrl.filters.length', function() {
                    _initFilters();
                });

                $scope.$watch('toolBarCtrl.sorterItems.length', function() {
                    _countToolItems();
                });

                _setBrandSearchTerm();

                function _setBrandSearchTerm() {
                    if($stateParams.filters && $stateParams.filters.queryInBrand) {
                        $scope.searchProductsModel = $stateParams.filters.queryInBrand;
                    }
                }
                
                function _setToolBarFixed() {
                    var scrollTop = $scrollableContent.prop('scrollTop');

                    // recalculate the static position as long as it is static
                    if (!_isToolBarFixed) {
                        _fixedContentDefaultPosition = scrollTop -
                            $scrollableContent[0].getBoundingClientRect().top +
                            _contentElement.getBoundingClientRect().top;
                    }

                    var _newIsFixed = scrollTop > _fixedContentDefaultPosition;
                    if (_newIsFixed !== _isToolBarFixed) {
                        _isToolBarFixed = _newIsFixed;
                        if (_isToolBarFixed) {
                            $element.css('min-height', $element[0].offsetHeight + 'px');
                            $element.addClass('active');
                        } else {
                            $element.css('min-height', '');
                            $element.removeClass('active');
                        }
                        _emitToolBarChange();
                    }
                }

                function _emitToolBarChange() {
                    $rootScope.$broadcast('toolBar.change');
                }

                /**
                 * Set sort by on sorter option change
                 * @public
                 *
                 * @param {Object} sorterItem
                 * @param {boolean} isDesc
                 */
                function changeSorter(sorterItem, isDesc) {
                    if (toolBarCtrl.sortBy && toolBarCtrl.sortBy.name === sorterItem.name && toolBarCtrl.sortBy.isDesc === isDesc) {
                        delete toolBarCtrl.sortBy.name;
                        delete toolBarCtrl.sortBy.isDesc;
                    } else {
                        toolBarCtrl.sortBy = toolBarCtrl.sortBy || {};
                        toolBarCtrl.sortBy.name = sorterItem.name;
                        toolBarCtrl.sortBy.isDesc = isDesc;
                    }

                    _fireEvent(toolBarCtrl.spSortByChange, { sortBy: toolBarCtrl.sortBy });
                }

                /**
                 * Fire the given event action with a timeout (to allow changes to bind outwards)
                 * @private
                 *
                 * @param {function} action
                 * @param {*} data
                 */
                function _fireEvent(action, data) {
                    if (!action) {
                        return;
                    }

                    return $timeout(function() {
                        _emitToolBarChange();
                        return action(data);
                    }, 100);
                }

                /**
                 * Set selected filters on filter option change
                 * @public
                 *
                 * @param {MobileFilterTool} filter
                 * @param {MobileFiltersItem} filterItem
                 */
                function changeFilter(filter, filterItem) {
                    filterItem._selected = !filterItem._selected;

                    //get current selected values for the given filter or create a new array fot it
                    var filterSelectedValues = filter.selectedItems || [];

                    //if the filter item becomes deselected remove its value from the selected filters
                    if (!filterItem._selected) {
                        for (var i = filterSelectedValues.length - 1; i >= 0; i--) {
                            if (filterSelectedValues[i].value === filterItem.value) {
                                //this removes the value at the given index
                                filterSelectedValues.splice(i, 1);
                                break;
                            }
                        }
                    }
                    //if the filter item becomes selected add its value to the selected filters
                    else if (filterItem._selected) {
                        filterSelectedValues.push(filterItem);
                    }

                    //if the given filter has selected values make sure it's added to the filters on $scope
                    if (filterSelectedValues.length) {
                        filter.selectedItems = filterSelectedValues;
                    }
                    //if the given filter does not have selected values make sure it's removed from the filters on $scope
                    else {
                        delete filter.selectedItems;
                    }

                    _fireEvent(toolBarCtrl.spFiltersChange, {filters: [filter]});
                    _countSelectedFilters();
                }

                /**
                 * Clear all the selected filters values
                 * @public
                 *
                 * @param {MobileFilterTool} filter
                 */
                function clearFilters(filter) {
                    //if the current filter has no selected values continue to next filter
                    if (!filter.selectedItems || !filter.selectedItems.length) {
                        return;
                    }

                    filter.selectedItems = [];
                    angular.forEach(filter.items, function(filterItem) {
                        filterItem._selected = false;
                    });

                    _fireEvent(toolBarCtrl.spFiltersChange, {filters: [filter]});

                    _countSelectedFilters();
                }

                /**
                 * Clear all the selected filters values of all filters
                 * @public
                 */
                function clearAllFilters() {
                    angular.forEach(toolBarCtrl.filters, function(filter) {
                        clearFilters(filter);
                    });
                }

                /**
                 * Search product by query
                 * @param model
                 * @public
                 */
                function searchProductsChanged(model) {
                    if (!toolBarCtrl.onSearchProducts) return;

                    toolBarCtrl.onSearchProducts({$model: model});
                }

                /**
                 * Init the selected filters options by the filters on $scope
                 * @private
                 */
                function _initFilters() {
                    angular.forEach(toolBarCtrl.filters, function(filter) {
                        //make items an array and keep the promise aside - for ng-repeat to not fail
                        filter.items = filter.items || [];

                        //wait for the items if it is a promise
                        $q.all([filter.itemsPromise || filter.items, filter.initPromise]).then(function(results) {
                            filter.items = results[0];

                            var allowedUsers = Config.retailer.settings.eBTAllowedUsers ? JSON.parse(Config.retailer.settings.eBTAllowedUsers) : [];
                            if (allowedUsers.length && !allowedUsers.includes(User.settings.id)){
                              if(results.length && results[0])
                              filter.items = results[0].filter(function (tag) {
                               if (tag.value !== 2560 && tag.value !== 5364){
                                 return tag
                               }
                             });
                            }
                            else if (allowedUsers.length) {
                              if(results.length && results[0])
                              filter.items = results[0].filter(function (tag) {
                                if (tag.value!== 5364){
                                  return tag
                                }
                             });
                            }
                            _countToolItems();

                            filter.resetValues = function(notify) {
                                //collect selected values in an object with the values as keys
                                var selectedValuesObj = {};
                                angular.forEach(filter.selectedItems, function(item) {
                                    selectedValuesObj[item.value] = true;
                                });

                                //set _selected property into the filter items by the collected values obj
                                var existSelectedValues = [];
                                angular.forEach(filter.items, function(filterItem) {
                                    filterItem._selected = !!selectedValuesObj[filterItem.value];
                                    if (filterItem._selected) {
                                        existSelectedValues.push(filterItem);
                                    }
                                });
                                // reset the selected values only to the ones exist in the items
                                filter.selectedItems = existSelectedValues;

                                _countSelectedFilters();

                                if (notify) {
                                    return _fireEvent(toolBarCtrl.spFiltersChange, {filters: [filter]});
                                }
                            };

                            //if the filter has no selected values continue to the next filter
                            if (filter.selectedItems && filter.selectedItems.length) {
                                filter.resetValues();
                            }
                        });
                    });
                }

                /**
                 * Set length into $scope.filters which is the count of all the selected filters
                 * @private
                 */
                function _countSelectedFilters() {
                    toolBarCtrl.selectedFiltersLength = 0;
                    angular.forEach(toolBarCtrl.filters, function(filter) {
                        toolBarCtrl.selectedFiltersLength += (filter.selectedItems || []).length;
                    });

                    _exports.selectedFiltersLength = toolBarCtrl.selectedFiltersLength;
                }

                /**
                 * Set length into $scope.filters which is the count of all the selected filters
                 * @private
                 */
                function _countToolItems() {
                    toolBarCtrl.toolLength = (toolBarCtrl.sorterItems || []).length;
                    angular.forEach(toolBarCtrl.filters, function(filter) {
                        toolBarCtrl.toolLength += (filter.items || []).length;
                    });
                }
            }]
        };
    }]);
})(angular);
