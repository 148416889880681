angular.module('mobilezuz').directive('focus', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function ($scope, $element, attrs) {
            $timeout(function () {
                $element.focus();
                if (window.cordova && window.cordova.plugins) {
                    window.cordova.plugins.Keyboard.show();
                }
            }, Number(attrs.focus) || 0);
            $element.bind('blur', hideKeyboard);

            function hideKeyboard() {
                if (window.cordova && window.cordova.plugins) {
                    window.cordova.plugins.Keyboard.close();
                    $element.unbind('blur', hideKeyboard);
                }
            }
        }
    };
}]);