(function (angular, app) {
    'use strict';

    app.service('ExternalRegistrationIFrameDialog', ['mDesign', function (mDesign) {
        this.show = show;
        this.hide = hide;

        function show(url, data) {
            return mDesign.dialog({
                templateUrl: 'views/templates/external-registration-iframe-dialog.html',
                controller: 'ExternalRegistrationIFrameCtrl as externalRegistrationIFrameCtrl',
                multiple: true,
                clickOutsideToClose: false,
                locals: {
                    url: url,
                    data: data
                }
            });
        }

        function hide() {
            mDesign.cancel();
        }
    }]);

    app.directive('iframeOnload', [function() {
        return {
            scope: {
                callBack: '&iframeOnload'
            },
            link: function(scope, element) {
                element.on('load', function(){
                    return scope.callBack();
                })
            }
        }
    }]);

    app.run(['$rootScope', 'ExternalRegistrationIFrameDialog', function ($rootScope, ExternalRegistrationIFrameDialog) {
        $rootScope.ExternalRegistrationIFrameDialog = ExternalRegistrationIFrameDialog;
    }]);

    app.controller('ExternalRegistrationIFrameCtrl', ['$rootScope', '$sce', 'User', 'url', 'data',
        function ($rootScope, $sce, User, iframeUrl, data) {
            var externalRegistrationIFrameCtrl = this;
            angular.extend(externalRegistrationIFrameCtrl, {
                iframeUrl: $sce.trustAsResourceUrl(iframeUrl),
                iframeLoaded: iframeLoaded,
                closeDialog: closeDialog
            });
            window.addEventListener("message", function(e) {
                if (e.data === 'ready') {
                    $rootScope.iframeIsReady = true;
                    iframeLoaded();
                } else if (e.data.isSuccess && e.data.data && e.data.data.foreignId && e.data.data.token) {
                    var url = '';
                    if (window.location.search.indexOf('?') > -1) {
                        url = window.location.href + '&loginOrRegister=1&id=' + e.data.data.foreignId + '&token=' + e.data.data.token;
                    } else {
                        url = window.location.href + '?loginOrRegister=1&id=' + e.data.data.foreignId + '&token=' + e.data.data.token;
                    }
                    if (url.includes('cart')) {
                        url = url.replace('cart', '');
                        url = url + '&externalReturn=app.cart';
                    }
                    window.location.href = url;
                }
                if (!e.data.isSuccess && e.data.isAbortedByUser) {
                    closeDialog();
                }
            });

            function iframeLoaded() {
                document.getElementById('external_login').contentWindow.postMessage(data, iframeUrl);
            }

            function closeDialog() {
                $rootScope.ExternalRegistrationIFrameDialog.hide();
            }
        }]);
})(angular, angular.module('mobilezuz'));
