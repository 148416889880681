'use strict';

angular.module('mobilezuz')
    .service('DialogAvailability', ['$q','$interval', '$timeout', '$rootScope', 'CordovaService', DialogRequest])

function DialogRequest($q, $interval, $timeout, $rootScope, CordovaService) {

    var _HANDLER_DELAY = 1000;

    var PERMISSIONS = {
        NOTIFICATIONS: 'NOTIFICATIONS',
        LOCATION: 'LOCATION',
        CAMERA: 'CAMERA',
        TRACKING: 'TRACKING'
    }

    var self = this,
        _dialogQueue = [],
        _dialogAvailable = null,
        _initDefer = $q.defer(),
        _initiated = _initDefer.promise,
        _dialogRequest = null;

    var _modes = {
        cordova: requestDialogCordova,
        web: requestDialogWeb
    }

    var _handlers = {
        NOTIFICATIONS: {handler: _notificationHandler, count: 30},
        LOCATION: {handler: _locationHandler, count: 10},
        CAMERA: {handler: _cameraHandler, count: 10},
        TRACKING: {handler: _trackingHandler, count: 30}
    }

    self.requestDialog = requestDialog;
    self.PERMISSIONS = PERMISSIONS;

    window.cordova ? CordovaService.ready.then(_init) : _init()

    function _init() {
        if (window.cordova && cordova.plugins && cordova.plugins.diagnostic && cordova.plugins.idfa)
        {
            _dialogRequest = _modes.cordova;
        }
        else {
            _dialogRequest = _modes.web;
        }

        _initDefer.resolve();
        _dialogAvailable = true;
    }

    function requestDialog(permission) {
        return _initiated.then(function () {
            return _dialogRequest(permission);
        });
    }

    function requestDialogCordova(permission) {
        var dialogRequest = $q.defer();
        dialogRequest.promise
            .then(function() {
                var dialogFree = $q.defer();

                var stopInterval = $interval(_permissionDialogHandler, _HANDLER_DELAY, _handlers[permission].count, false, permission);
                stopInterval
                    .then(function() {
                        _dialogFreeEvent(permission);
                    })
                    .catch(function() {
                        stopInterval = null;
                    });

                var cancelEvent = $rootScope.$on('dialogAvailability.' + permission, function() {
                    $interval.cancel(stopInterval);
                    cancelEvent();
                    dialogFree.resolve();
                });

                return dialogFree.promise;
            })
            .then(function() {
                return $timeout(function() {}, 500);
            })
            .then(_done);

        _dialogQueue.push(dialogRequest);
        _onQueue();
        return dialogRequest.promise;
    }

    function requestDialogWeb(permission) {
        $q.resolve()
    }

    function _onQueue() {
        if (_dialogAvailable && _dialogQueue.length > 0) {
            _dialogAvailable = false;
            _dialogQueue[0].resolve();
        }
    }

    function _done() {
        _dialogQueue.shift();
        _dialogAvailable = true;
        _onQueue();
    }

    function _permissionDialogHandler(permission) {
        _handlers[permission].handler()
            .then(function(permissionStatus) {
                if (permissionStatus) {
                    _dialogFreeEvent(permission);
                }
            });
    }

    function _notificationHandler() {
        return $q(function(resolve) {
            cordova.plugins.diagnostic.getRemoteNotificationsAuthorizationStatus(function(status) {
                _isPermissionRequested(status) ? resolve(true) : resolve (false)
            });
        });
    }

    function _locationHandler() {
        return $q(function(resolve) {
            cordova.plugins.diagnostic.getLocationAuthorizationStatus(function(status) {
                _isPermissionRequested(status) ? resolve(true) : resolve (false)
            });
        });
    }

    function _cameraHandler() {
        return $q(function(resolve) {
            cordova.plugins.diagnostic.getCameraAuthorizationStatus(function(status) {
                _isPermissionRequested(status) ? resolve(true) : resolve (false)
            });
        });
    }

    function _trackingHandler() {
        var idfaPlugin = cordova.plugins.idfa;
        return idfaPlugin.getInfo()
            .then(function (info) {
                return info.trackingPermission !== idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED ? true : false;
            });
    }

    function _isPermissionRequested(status) {
        return status !== cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED
    }

    function _dialogFreeEvent(permission) {
        $rootScope.$emit('dialogAvailability.' + permission);
    }
}