'use strict';

angular.module('mobilezuz')
    .controller('AccountDeletionCtrl', ['$q', '$timeout', 'mDesign', 'Retailer', 'User', 'SpCaptcha', 'AccountDeletionSrv', AccountDeletionCtrl])

function AccountDeletionCtrl($q, $timeout, mDesign, Retailer, User, SpCaptcha, AccountDeletionSrv) {
    var MAX_RETRIES = 5;
    var RESET_RETRIES = 1000 * 60 // one minute
    var accountDeletionCtrl = this;
    var _currentState = null;
    var _currentInitStep = null;
    var _captchaHash = '';
    var _retries = 0;

    accountDeletionCtrl.CONFIRM_PATTERN = 'CONFIRM';
    accountDeletionCtrl.isError = false;
    accountDeletionCtrl.errorMessage = '';
    accountDeletionCtrl.isInProgress = true;
    accountDeletionCtrl.isRequestsLimit = false;
    accountDeletionCtrl.showCaptcha = true;
    accountDeletionCtrl.captchaIsInvalid = false;
    accountDeletionCtrl.mfaCode = '';
    accountDeletionCtrl.mfaCodeLength = null;
    accountDeletionCtrl.confirmInput = '';
    accountDeletionCtrl.userEmail = '';
    accountDeletionCtrl.customerServicePhone = '';
    accountDeletionCtrl.numOfDaysUntilDeletion = null;

    accountDeletionCtrl.next = next;
    accountDeletionCtrl.getCurrentState = getCurrentState;
    accountDeletionCtrl.getCurrentStepTemplate = getCurrentStepTemplate;
    accountDeletionCtrl.sendMfaCode = sendMfaCode;
    accountDeletionCtrl.closeDialog = closeDialog;

    var _states = {
        initProcess: 'initProcess',
        error: 'error',
        alreadyDeleted: 'alreadyDeleted',
        notification: 'notification'
    };

    var _initSteps = {
        captcha: {name: 'captcha', templateUrl: 'views/templates/account-deletion/account-deletion-captcha.html'},
        authentication: {name: 'authentication', templateUrl: 'views/templates/account-deletion/account-deletion-authentication.html'},
        confirmation: {name: 'confirmation', templateUrl: 'views/templates/account-deletion/account-deletion-confirmation.html'}
    }

    _initCtrl()

    function _initCtrl() {
        $q.all({
            user: User.getUserSettings(),
            retailer: Retailer.getRetailerSettings(),
        }).then(function (results) {
            accountDeletionCtrl.userEmail = results.user.email || '';
            accountDeletionCtrl.customerServicePhone = results.retailer.contactPhone || '';
            return AccountDeletionSrv.isAccountDeleted();
        }).then(function (results) {
            accountDeletionCtrl.mfaCodeLength = results.codeLength;
            accountDeletionCtrl.numOfDaysUntilDeletion = results.numOfDaysUntilDeletion;
            if (results.status) {
                _stateTransition(_states.alreadyDeleted, null);
            }
            else {
                _stateTransition(_states.initProcess, _initSteps.captcha);
            }
        }).catch(function (err) {
            _setError(true);
            _stateTransition(_states.error, null);
        }).finally(function () {
            _setProgress(false);
        });
    }

    function next() {
        _setProgress(true);
        if (_currentState == _states.initProcess && _currentInitStep.name == _initSteps.captcha.name) {
            _handleCaptchaStep();
        }
        else if (_currentState == _states.initProcess && _currentInitStep.name == _initSteps.authentication.name) {
            _handleAuthenticationStep();
        }
        else if (_currentState == _states.initProcess && _currentInitStep.name == _initSteps.confirmation.name) {
            _handleConfirmationStep();
        }
    }

    function getCurrentState() {
        return _currentState;
    }

    function getCurrentStepTemplate() {
        return _currentInitStep ? _currentInitStep.templateUrl : '';
    }

    function sendMfaCode() {
        _setProgress(true);
        _setRetries();

        if (accountDeletionCtrl.isRequestsLimit) {
            _setProgress(false);
            return;
        }

        AccountDeletionSrv.sendMfaCode()
            .catch(function() {
                _setError(true);
                _stateTransition(_states.error, null);
            })
            .finally(function() {
                _setProgress(false);
            });
    }

    function closeDialog() {
        mDesign.hide()
    }

    function _stateTransition(state, step) {
        _currentState = state;
        _currentInitStep = step;
    }

    function _setProgress(isInProgress) {
        accountDeletionCtrl.isInProgress = isInProgress;
    }

    function _setError(isError) {
        accountDeletionCtrl.isError = isError;
    }


    function _setRetries() {
        if (_retries < MAX_RETRIES) {
            _retries++;
        }
        else {
            _setError(false);
            accountDeletionCtrl.isRequestsLimit = true;
            $timeout(function() {
                _retries = 0;
                accountDeletionCtrl.isRequestsLimit = false;
            }, RESET_RETRIES);
        }
    }

    function _handleCaptchaStep() {
        _captchaHash = SpCaptcha.getLastCaptchaVerifyHash()
        AccountDeletionSrv.createSession(_captchaHash)
            .then(AccountDeletionSrv.sendMfaCode)
            .then(function() {
                _stateTransition(_states.initProcess, _initSteps.authentication);
            })
            .catch(function () {
                _setError(true);
                _stateTransition(_states.error, null);
            })
            .finally(function () {
                _setProgress(false);
            });
    }

    function _handleAuthenticationStep() {
        AccountDeletionSrv.validateMfaCode(accountDeletionCtrl.mfaCode)
            .then(function() {
                _setError(false);
                _stateTransition(_states.initProcess, _initSteps.confirmation);
            })
            .catch(function(err) {
                _setError(true);

                if (err.status == 500 || err.response.sessionExpired) {
                    _stateTransition(_states.error, null);
                }
                else {
                    _setRetries();
                    accountDeletionCtrl.mfaCode = '';
                    accountDeletionCtrl.errorMessage = err.response.message;
                }
            })
            .finally(function() {
                _setProgress(false);
            });
    }

    function _handleConfirmationStep() {
        AccountDeletionSrv.requestAccountDeletion(accountDeletionCtrl.mfaCode)
            .then(function () {
                _stateTransition(_states.notification, null);
            })
            .catch(function () {
                _setError(true);
                _stateTransition(_states.error, null);
            })
            .finally(function () {
                _setProgress(false);
            });
    }
}