angular.module('spImageZoom', []);
(function (angular, app) {
    'use strict';

    app.constant('IMAGE_ZOOM_MODES', {
        NONE: 1,
        MAGNIFYING_GLASS: 2,
        ZOOM_CONTROLS: 3
    });
    app.constant('ZOOM_CONTROLS_ZOOM_OPTIONS', [1, 3, 6, 9]);

    app.run(['$rootScope', 'IMAGE_ZOOM_MODES', function($rootScope, IMAGE_ZOOM_MODES) {
        $rootScope.spImageZoom = $rootScope.spImageZoom || {};
        $rootScope.spImageZoom.IMAGE_ZOOM_MODES = IMAGE_ZOOM_MODES;
    }]);
})(angular, angular.module('spImageZoom'));

(function (angular, app) {
    'use strict';

    // save this amount of history, to not add too much memory
    // and still prevent trying to load an image too many times (usually recent images will get loaded again)
    var SAVED_LENGTH = 100;

    /**
     * Will try to load an image in the background and returns whether could be loaded or not
     * Will save a cache of recent history to make next attempts sync
     */
    function srv() {
        var self = this,
            _cache = [];

        self.load = load;

        function load(url, callback) {
            var imageStatus = _cache.find(function(i) {
                return i.url === url;
            });
            if (imageStatus) {
                _handleCacheImage(imageStatus, callback);
            } else {
                _loadImage(url, callback);
            }
        }

        function _handleCacheImage(imageStatus, callback) {
            if (imageStatus.isLoading) {
                imageStatus.callbacks.push(callback);
            } else {
                _callCallbacks(imageStatus, [function(value) {
                    // return true for 'isCache', to identify sync and async calls
                    callback(value, true);
                }]);
            }
        }

        function _loadImage(url, callback) {
            var imageStatus = {
                url: url,
                callbacks: [callback],
                isLoading: true
            };
            _pushCache(imageStatus);

            var image = new Image();
            image.onload = function() {
                imageStatus.isLoading = false;
                imageStatus.loaded = true;
                _callCallbacks(imageStatus);
            };
            image.onerror = function() {
                imageStatus.isLoading = false;
                imageStatus.loaded = false;
                _callCallbacks(imageStatus);
            };
            image.src = url;
        }

        function _pushCache(imageStatus) {
            _cache.unshift(imageStatus);
            _cache.splice(SAVED_LENGTH);
        }

        function _callCallbacks(imageStatus, callbacks) {
            callbacks = callbacks || imageStatus.callbacks;

            angular.forEach(callbacks, function(callback) {
                callback(imageStatus.loaded);
            });

            // these callbacks are not longer needed, they were called
            delete imageStatus.callbacks;
        }
    }

    /**
     * Register to Module
     */
    angular.module('spImageZoom').service('SpImagesLoader', [srv]);
})(angular);

(function (angular, app) {
    'use strict';

    var DEFAULT_ZOOM = 2;

    function MagnifyingGlass($scope, $element, options) {
        options = options || {};

        var zoom = Number(options.zoom) || DEFAULT_ZOOM,
            $largeImageElement = angular.element('<div class="large-image" style="background-image: url(' + options.imageUrl + ')"></div>'),
            modeClass = 'magnifying-glass-mode';

        $element.addClass(modeClass);
        $element.append($largeImageElement);

        $element.on('mousemove', _mouseMove);
        $element.on('mouseleave', _mouseLeave);

        this.destroy = destroy;

        function destroy() {
            $element.off('mousemove', _mouseMove);
            $element.off('mouseleave', _mouseLeave);
            $element.removeClass(modeClass);
            $largeImageElement.remove();
        }

        function _mouseMove(event) {
            var $smallImage = $element[0].getElementsByClassName('image')[0],
                elementOffset = _getElementOffset($element),
                scrolledElement = elementOffset.hasFixed && _getScrolledElement(),
                pointX = event.pageX + elementOffset.scrollLeft - elementOffset.left - $smallImage.offsetLeft - (scrolledElement ? scrolledElement.scrollLeft : 0),
                pointY = event.pageY + elementOffset.scrollTop - elementOffset.top - $smallImage.offsetTop - (scrolledElement ? scrolledElement.scrollTop : 0);

            if (pointX < $smallImage.offsetWidth && pointY < $smallImage.offsetHeight && pointX > 0 && pointY > 0) {
                $largeImageElement.addClass('shown');
                var positionX = Math.round(pointX * zoom - $largeImageElement[0].offsetWidth / 2) * -1,
                    positionY = Math.round(pointY * zoom - $largeImageElement[0].offsetHeight / 2) * -1,
                    left = pointX + $smallImage.offsetLeft - $largeImageElement[0].offsetWidth / 2,
                    top = pointY + $smallImage.offsetTop - $largeImageElement[0].offsetHeight / 2;
                $largeImageElement.css({
                    'left': left + 'px',
                    'top': top + 'px',
                    'background-position': positionX + 'px ' + positionY + 'px',
                    'background-size': ($smallImage.offsetWidth * zoom) + 'px ' + ($smallImage.offsetHeight * zoom) + 'px'
                });
            } else {
                $largeImageElement.removeClass('shown');
            }
        }

        function _mouseLeave() {
            $largeImageElement.removeClass('shown');
        }
    }

    /**
     * Gets whether the element has fixed position or not
     * @param {HTMLElement} element
     * @returns {boolean}
     * @private
     */
    function _getIsFixedElement(element) {
        var position = '';
        if (window.getComputedStyle && angular.isFunction(window.getComputedStyle)) {
            position = window.getComputedStyle(element).getPropertyValue('position');
        } else if (element.currentStyle && element.currentStyle['position']) {
            position = element.currentStyle['position'];
        }
        return position === 'fixed';
    }

    /**
     * Gets data about the offset of an element
     * @param {HTMLElement} elem
     * @returns {{top: number, left: number, scrollTop: number, scrollLeft: number, hasFixed: boolean}}
     * @private
     */
    function _getElementOffset(elem) {
        elem = angular.element(elem)[0];
        var top = 0,
            left = 0,
            scrollTop = 0,
            scrollLeft = 0,
            hasFixed = false;
        while (elem) {
            hasFixed = hasFixed || _getIsFixedElement(elem);
            top += elem.offsetTop;
            left += elem.offsetLeft;
            if (!hasFixed) {
                scrollTop += elem.scrollTop;
                scrollLeft += elem.scrollLeft;
            }

            elem = elem.offsetParent;
        }
        return {top: top, left: left, scrollTop: scrollTop, scrollLeft: scrollLeft, hasFixed: hasFixed};
    }

    /**
     * Gets the element that has the main scroll event and data
     * @returns {HTMLDocument|HTMLElement}
     * @private
     */
    function _getScrolledElement() {
        var elem = document;
        if (elem.documentElement && elem.documentElement.scrollTop) {
            elem = elem.documentElement;
        } else {
            elem = elem.body || elem;
        }
        return elem;
    }

    app.constant('MagnifyingGlass', MagnifyingGlass);
})(angular, angular.module('spImageZoom'));

(function (angular, app) {
    'use strict';

    /**
     * Image zoom directive Link
     * @name ImageZoomLink
     */
    function drvLink($scope, $element, MagnifyingGlass, ZoomControls, SpImagesLoader, IMAGE_ZOOM_MODES, ZOOM_CONTROLS_ZOOM_OPTIONS) {
        var TypesByMode = {};
        TypesByMode[IMAGE_ZOOM_MODES.MAGNIFYING_GLASS] = MagnifyingGlass;
        TypesByMode[IMAGE_ZOOM_MODES.ZOOM_CONTROLS] = ZoomControls;

        var instance;

        $scope.$watch(function() {
            return _getMode() + '-' + $scope.imageUrl + '-' + $scope.zoomedImageUrl + '-' + JSON.stringify(_getZoom() || -1);
        }, _init);

        $scope.$on('$destroy', function() {
            _destroyInstance();
        });

        function _init() {
            _destroyInstance();
            _loadZoomedImage();

            var Type = TypesByMode[_getMode()];
            if (Type) {
                instance = new Type($scope, $element, {
                    imageUrl: $scope.imageUrl,
                    zoomedImageUrl: $scope.zoomedImageUrl,
                    zoom: _getZoom(),
                    SpImagesLoader: SpImagesLoader
                });
            }
        }

        function _getMode() {
            return $scope.mode || IMAGE_ZOOM_MODES.MAGNIFYING_GLASS;
        }

        function _getZoom() {
            var mode = _getMode();
            if (mode === IMAGE_ZOOM_MODES.MAGNIFYING_GLASS) {
                return $scope.glassZoom;
            } else if (mode === IMAGE_ZOOM_MODES.ZOOM_CONTROLS) {
                return $scope.controlsZoom || ZOOM_CONTROLS_ZOOM_OPTIONS;
            }
        }

        function _destroyInstance() {
            if (instance && instance.destroy) {
                instance.destroy();
                instance = undefined;
            }
        }

        function _loadZoomedImage() {
            if ($scope.zoomedImageUrl) {
                SpImagesLoader.load($scope.zoomedImageUrl, function() {});
            }
        }
    }

    /**
     * Register to Module
     */
    app.directive('spImageZoom', [
        'MagnifyingGlass', 'ZoomControls', 'SpImagesLoader', 'IMAGE_ZOOM_MODES', 'ZOOM_CONTROLS_ZOOM_OPTIONS',
        function(MagnifyingGlass, ZoomControls, SpImagesLoader, IMAGE_ZOOM_MODES, ZOOM_CONTROLS_ZOOM_OPTIONS) {
            return {
                restrict: 'A',
                template: '' +
                    '<span class="image-to-middle"></span>' +
                    // calcImageUrl can be calculated and set on the zoom type (i.e. magnifying glass and zoom controls)
                    '<img alt="{{alt}}" class="image" ng-src="{{calcImageUrl || imageUrl}}"/>',
                scope: {
                    imageUrl: '@spImageZoom',
                    zoomedImageUrl: '@zoomedImage',
                    alt: '@?',
                    glassZoom: '<?',
                    controlsZoom: '<?',
                    mode: '<?'
                },
                link: function($scope, $element) {
                  return drvLink($scope, $element, MagnifyingGlass, ZoomControls, SpImagesLoader, IMAGE_ZOOM_MODES, ZOOM_CONTROLS_ZOOM_OPTIONS);
                }
            }
        }
    ]);
})(angular, angular.module('spImageZoom'));

(function (angular, app) {
    'use strict';

    var DRAG_AREA = '' +
        '<div class="drag-area"></div>';

    var CONTROL_BUTTONS = '' +
        '<div class="control-buttons">' +
        '<button class="zoom-in no-design" type="button" aria-label="Zoom In"></button>' +
        '<button class="zoom-out no-design" type="button" aria-label="Zoom Out"></button>' +
        '</div>';

    function ZoomControls($scope, $element, options) {
        options = options || {};

        var $document = angular.element(document),
            $dragAreaElement = angular.element(DRAG_AREA),
            $navigationControlsElement = angular.element(CONTROL_BUTTONS),
            _currentZoomIndex,
            _currentZoomValue,
            _translateLeft = 0,
            _translateTop = 0,
            _currentMovePoint,
            modeClass = 'zoom-controls-mode',
            isTouch = false;

        this.destroy = destroy;

        $element.addClass(modeClass);
        $element.append($dragAreaElement);
        $element.append($navigationControlsElement);

        $dragAreaElement.on('mousedown', _mouseDown);
        $dragAreaElement.on('touchstart', _touchStart);

        var $zoomInButton = angular.element($navigationControlsElement[0].getElementsByClassName('zoom-in')),
            $zoomOutButton = angular.element($navigationControlsElement[0].getElementsByClassName('zoom-out'));
        $zoomInButton.on('mousedown', _zoomInMouse);
        $zoomInButton.on('touchstart', _zoomInTouch);
        $zoomInButton.on('keydown', _enterKeyDownListener(_zoomIn));
        $zoomOutButton.on('mousedown', _zoomOutMouse);
        $zoomOutButton.on('touchstart', _zoomOutTouch);
        $zoomOutButton.on('keydown', _enterKeyDownListener(_zoomOut));

        var zoomOptions = [],
            defaultIndex;
        angular.forEach(options.zoom, function(zoomOption, index) {
            if (defaultIndex === undefined && zoomOption && zoomOption.isDefault) {
                defaultIndex = index;
            }

            zoomOptions.push(zoomOption.value || zoomOption);
        });
        _setCurrentZoom(defaultIndex || 0);

        function destroy() {
            delete $scope.calcImageUrl;
            $element.removeClass(modeClass);
            $dragAreaElement.remove();
            $navigationControlsElement.remove();
            _endMove();

            _getImageElement().css({ '-webkit-transform': '', '-ms-transform': '', 'transform': '' });
        }

        function _mouseDown(event) {
            if(!isTouch) {
                _startMoving(event.pageX, event.pageY);
            }
        }

        function _touchStart(event) {
            isTouch = true;
            _startMoving(event.touches[0].pageX, event.touches[0].pageY);
        }

        function _startMoving(startX, startY) {
            _currentMovePoint = {
                x: startX,
                y: startY
            };

            if(isTouch) {
                $document[0].addEventListener('touchmove', _touchMove, { passive: false }); //== have to send passive: false to use preventDefault
                $document.on('touchend', _endMove);
            } else {
                $document.on('mousemove', _mouseMove);
                $document.on('mouseup', _endMove);
            }
        }

        function _mouseMove(event) {
            event.preventDefault();
            _onMove(event.pageX, event.pageY);
        }

        function _touchMove(event) {
            event.preventDefault();
            _onMove(event.touches[0].pageX, event.touches[0].pageY);
        }

        function _onMove(moveX, moveY) {
            _move(
                (moveX - _currentMovePoint.x) / _currentZoomValue,
                (moveY - _currentMovePoint.y) / _currentZoomValue
            );

            _currentMovePoint.x = moveX;
            _currentMovePoint.y = moveY;
        }

        function _endMove() {
            $document.off('mousemove', _mouseMove);
            $document[0].removeEventListener('touchmove', _touchMove);
            $document.off('mouseup', _endMove);
            $document.off('touchend', _endMove);
        }

        function _zoomInTouch(event) {
            isTouch = true;
            _zoomIn(event);
        }

        function _zoomInMouse(event) {
            if (!isTouch) {
                _zoomIn(event);
            }
        }

        function _zoomIn(event) {
            event.stopPropagation();

            _setCurrentZoom(_currentZoomIndex + 1);
            $scope.$apply();
        }

        function _zoomOutTouch(event) {
            isTouch = true;
            _zoomOut(event);
        }

        function _zoomOutMouse(event) {
            if (!isTouch) {
                _zoomOut(event);
            }
        }

        function _zoomOut(event) {
            event.stopPropagation();

            _setCurrentZoom(_currentZoomIndex - 1);
            $scope.$apply();
        }

        function _enterKeyDownListener(listener) {
            return function(event) {
                if ((event.which || event.keyCode) === 13) {
                    return listener(event);
                }
            }
        }

        function _setButtonDisabled(buttonElement, isDisabled) {
            if (isDisabled) {
                buttonElement.addClass('disabled');
            } else {
                buttonElement.removeClass('disabled');
            }
        }

        function _setCssValue() {
            var $image = _getImageElement(),
                cssValue = 'scale(' + _currentZoomValue + ') translate(' + _translateLeft + 'px, ' + _translateTop + 'px)';

            $image.css({
                '-webkit-transform': cssValue,
                '-ms-transform': cssValue,
                'transform': cssValue
            });
        }

        function _move(addLeft, addTop) {
            var $image = _getImageElement(),
                actualWidth = $image.prop('offsetWidth') * _currentZoomValue,
                actualHeight = $image.prop('offsetHeight') * _currentZoomValue,
                maxLeft = Math.max(0, (actualWidth - $element.prop('offsetWidth')) / 2 / _currentZoomValue),
                maxTop = Math.max(0, (actualHeight - $element.prop('offsetHeight')) / 2 / _currentZoomValue);

            _translateLeft += addLeft;
            _translateLeft = Math.min(_translateLeft, maxLeft);
            _translateLeft = Math.max(_translateLeft, maxLeft * -1);

            _translateTop += addTop;
            _translateTop = Math.min(_translateTop, maxTop);
            _translateTop = Math.max(_translateTop, maxTop * -1);

            _setCssValue();
        }

        function _getImageElement() {
            return angular.element($element[0].getElementsByClassName('image'));
        }

        function _setCurrentZoom(newIndex) {
            var maxIndex = zoomOptions.length - 1,
                minIndex = 0;

            newIndex = Math.min(maxIndex, newIndex);
            newIndex = Math.max(minIndex, newIndex);

            if (newIndex === _currentZoomIndex) {
                return;
            }

            _currentZoomIndex = newIndex;

            _setButtonDisabled($zoomInButton, _currentZoomIndex === maxIndex);
            _setButtonDisabled($zoomOutButton, _currentZoomIndex === minIndex);

            _currentZoomValue = zoomOptions[_currentZoomIndex];
            if (typeof _currentZoomValue === 'function') {
                _currentZoomValue = _currentZoomValue();
            }

            _setZoomedImageUrl();
            _move(0, 0);
        }

        function _setZoomedImageUrl() {
            if (!options.zoomedImageUrl) {
                return;
            }

            options.SpImagesLoader.load(options.zoomedImageUrl, function(isLoaded, isCache) {
                if (!isLoaded) {
                    return;
                }

                // if (_currentZoomValue > 1) {
                    $scope.calcImageUrl = options.zoomedImageUrl;
                // } else {
                //     delete $scope.calcImageUrl;
                // }

                // apply changes when not a sync call
                if (!isCache) {
                    $scope.$apply();
                }
            });
        }
    }

    app.constant('ZoomControls', ZoomControls);
})(angular, angular.module('spImageZoom'));