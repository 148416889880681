angular.module('mobilezuz')
    .service('CordovaService', ['$q', '$window', '$timeout', function( $q, $window, $timeout) {
        var d = $q.defer(),
            resolved = false; // prevents duplicate resolve on setTimeout

        this.ready = d.promise;

        document.addEventListener('deviceready', function() {
            resolved = true;
            d.resolve($window.cordova);
        });

        // Check to make sure we didn't miss the // event (just in case)
        $timeout(function() {
            if (!resolved && $window.cordova) {
                d.resolve($window.cordova);
            }
        }, 3000);
    }]);