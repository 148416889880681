(function (angular, app) {
    'use strict';

    app.service('LastMinuteRecommendationsDialog', ['mDesign', function(mDesign) {
        this.show = show;
        this.hide = hide;

        function show(lastMinuteRecommendations, goToCheckoutCallback) {
            return mDesign.dialog({
                templateUrl: 'views/templates/last-minute-recommendations.html',
                controller: 'LastMinuteRecommendationsCtrl as lastMinuteRecommendationsCtrl',
                multiple: true,
                clickOutsideToClose: false,
                locals: {
                    lastMinuteRecommendations: lastMinuteRecommendations,
                    goToCheckoutCallback: goToCheckoutCallback
                }
            });
        }

        function hide() {
            mDesign.hide();
        }
    }]);

    app.run(['$rootScope', 'LastMinuteRecommendationsDialog', function ($rootScope, LastMinuteRecommendationsDialog) {
        $rootScope.LastMinuteRecommendationsDialog = LastMinuteRecommendationsDialog;
    }]);

    app.controller('LastMinuteRecommendationsCtrl', ['$scope', '$rootScope', '$state', '$anchorScroll', 'Config', 'User', 'CouponDetailsDialog', 'SpecialDetailsDialog', 'lastMinuteRecommendations', 'goToCheckoutCallback', 'Util', 'Cart',
        function ($scope, $rootScope, $state, $anchorScroll, Config, User, CouponDetailsDialog, SpecialDetailsDialog, lastMinuteRecommendations, goToCheckoutCallback, Util, Cart) {
            var lastMinuteRecommendationsCtrl = this,
                LOYALTY_PREMIUM_PACKAGE = 16;

            lastMinuteRecommendations.settings.sections.forEach(function(section) {
                section.start = section.sectionType === $rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.TIP_DONATION || section.sectionType === $rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.COUPONS ? 2 : 3;
            });
            angular.extend(lastMinuteRecommendationsCtrl, {
                LMRSettings: lastMinuteRecommendations.settings,
                LMRData: _getLastMinuteRecommendationsData(),
                goToSection: $anchorScroll,
                branchArea: Config.getBranchArea(),
                toggleView: toggleView,
                goToSpecial: goToSpecial,
                goToCheckout: goToCheckout,
                closeDialog: closeDialog
            });

            User.getUserSettings().then(function(user) {
                lastMinuteRecommendationsCtrl.user = user;
            });

            $scope.goToCoupon = function(coupon, terms) {
                CouponDetailsDialog.show(coupon, terms);
            }

            $scope.isEmptyObjectWithDelivery = function () {
               return Util.isEmptyObjectWithDelivery($rootScope.cart.lines);
            }
            lastMinuteRecommendationsCtrl.isLoyaltyPackageEnabled = Config.retailer.premiumFeaturesEnabled.includes(LOYALTY_PREMIUM_PACKAGE);
            lastMinuteRecommendationsCtrl.userCashbackLoyalty = Util.findUserCashbackLoyaltyClub()
            if(lastMinuteRecommendationsCtrl.userCashbackLoyalty) {
                _setCashbackLoyaltyClub(lastMinuteRecommendationsCtrl.userCashbackLoyalty.loyaltyClubId);
            }

            if(lastMinuteRecommendationsCtrl.activeCashbackLoyalty) {
                Util.getUserCashbackPoints(lastMinuteRecommendationsCtrl.userCashbackLoyalty.id).then(function (response) {
                    lastMinuteRecommendationsCtrl.userCashbackLoyalty.points = response.points || 0;
                });
            }

            function toggleView($event, product) {
                product.editState = !product.editState;
                $event && $event.preventDefault();
                lastMinuteRecommendations.data[$rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.PRODUCTS_COMPLEMENTARY].forEach(function (prod) {
                    if (prod.editState && prod !== product) {
                        prod.editState = false;
                    }
                });
            }

            function goToSpecial(offer) {
                if (offer.item) {
                    Util.goToProductDialog(offer.item);
                } else {
                    if (Config.retailer.settings.isNewPromotionDesignEnabled === 'true') {
                        SpecialDetailsDialog.show(offer.id);
                    } else {
                        $state.go('app.specials.special', {ssid: offer.id});
                    }
                }
            }

            function goToCheckout(event) {
                lastMinuteRecommendationsCtrl.closeDialog();
                goToCheckoutCallback(event, true);
            }

            function closeDialog() {
                $rootScope.LastMinuteRecommendationsDialog.hide();
            }

            function _setCashbackLoyaltyClub(loyaltyClubId) {
                angular.forEach(Config.retailer.loyaltyClubDrivers, function (loyaltyClubDriver) {
                    if(loyaltyClubDriver.clientConfig.loyaltyClubs && loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId] &&
                        loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId].pointsToCashback) {
                        lastMinuteRecommendationsCtrl.activeCashbackLoyalty = loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId];
                    }
                });
            }

            function _getLastMinuteRecommendationsData() {
                _setSoldBy(lastMinuteRecommendations);
                var branchArea = Config.getBranchArea();
                var tipDonationProducts = lastMinuteRecommendations.data[$rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.TIP_DONATION];
                var allowedDeliveryTypes = [$rootScope.SP_SERVICES.DELIVERY_TYPES.DELIVERY, $rootScope.SP_SERVICES.DELIVERY_TYPES.EXPRESS_DELIVERY];

                if (branchArea && !allowedDeliveryTypes.includes(branchArea.deliveryTypeId) && lastMinuteRecommendations.settings.config.hideTipsCarouselOnPickUp === 'true' && tipDonationProducts && tipDonationProducts.length > 0) {
                    var productsToFilter = new Set(tipDonationProducts.map(function(product) {
                        return product.id
                    }));
                    var sectionsToCheck = [$rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.PRODUCTS_COMPLEMENTARY, $rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.OFFERS, $rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.PRODUCTS_USUALLY].filter(function(section) {
                        return lastMinuteRecommendations.data.hasOwnProperty(section)
                    });

                    sectionsToCheck.forEach(function(section) {
                        lastMinuteRecommendations.data[section] = lastMinuteRecommendations.data[section].filter(function(product) {
                            return !productsToFilter.has(product.id)
                        });
                    });
                }

                Object.values(lastMinuteRecommendations.data).forEach(function(section) {
                    User.injectSuggestionsForProducts(section);
                })

                return lastMinuteRecommendations.data;
            }

            function _setSoldBy(lastMinuteRecommendations) {
                angular.forEach(lastMinuteRecommendations.data , function(items) {
                    angular.forEach(items, function (item) {
                        item.soldBy = null;
                        if (Util.isUnitsWeighable(item)) {
                            var cartLine = _getLine(item);
                            if (!cartLine) {
                                var soldBy;
                                if (!!item.productDisplayModeId) {
                                    switch (item.productDisplayModeId) {
                                        case $rootScope.PRODUCT_DISPLAY.UNIT.id:
                                            soldBy = $rootScope.PRODUCT_DISPLAY.UNIT.name;
                                            break;
                                        case $rootScope.PRODUCT_DISPLAY.WEIGHT.id:
                                            soldBy = $rootScope.PRODUCT_DISPLAY.WEIGHT.name;
                                            break;
                                        default:
                                            soldBy = item.isWeighable ? (item.weight ? $rootScope.PRODUCT_DISPLAY.UNIT.name : $rootScope.PRODUCT_DISPLAY.WEIGHT.name) : null;
                                    } 
                                } else {
                                    soldBy = item.isWeighable ? (item.weight ? $rootScope.PRODUCT_DISPLAY.UNIT.name : $rootScope.PRODUCT_DISPLAY.WEIGHT.name) : null;
                                }
                                item.soldBy = soldBy;
                            } else {
                                item.soldBy = cartLine.soldBy;
                            }
                        }
                    })
                });
            }

            function _getLine(product) {
                var cartLines = Cart.getLines(),
                    cartLine;
                if (!cartLines) return;
                angular.forEach(cartLines, function (line) {
                    if (line.product && line.product.id === product.id) {
                        cartLine = line;
                    }
                })
                return cartLine;
            }
        }]);
})(angular, angular.module('mobilezuz'));
