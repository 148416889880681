(function(angular) {
	var STATE_NAME = 'app.cart.checkout.process.times';

	var DAY_IN_MS = 1000 * 60 * 60 * 24;

	angular.module('mobilezuz').config(['$stateProvider', function($stateProvider) {
		$stateProvider.state(STATE_NAME, {
			url: '/times',
			checkoutViewOriginalIndex: 0,
			checkoutViewIndex: 0,
			views: {
				'checkoutProcess': {
					templateUrl: 'views/checkout/process/times.html',
					controller: 'CheckoutTimesCtrl as checkoutTimesCtrl'
				}
			}
		});
	}]).controller('CheckoutTimesCtrl', [
		'$scope', '$rootScope', '$timeout', '$q', '$state', '$filter', 'Config', 'Retailer', 'User', 'SpDeliveryTimesService',
		'branchArea', 'Cart', 'Util', 'mDesign', 'SpProductTags', 'ShippingTermsPopup', 'SeniorCitizenVerificationDialog',
		'DELIVERY_TIMES_TYPES', 'DELIVERY_CHOICES', 'CART_LINE_TYPES', 'PRODUCT_TAG_TYPES', 'SP_SERVICES', 'Util', 'DataLayer',
		function($scope, $rootScope, $timeout, $q, $state, $filter, Config, Retailer, User, SpDeliveryTimesService,
				 branchArea, Cart, Util, mDesign, SpProductTags, ShippingTermsPopup, SeniorCitizenVerificationDialog,
				 DELIVERY_TIMES_TYPES, DELIVERY_CHOICES, CART_LINE_TYPES, PRODUCT_TAG_TYPES, SP_SERVICES, util, DataLayer) {
			var checkoutProcessCtrl = $scope.checkoutProcessCtrl,
				checkoutTimesCtrl = this,
				_translate = $filter('translate'),
				_listeners = [];

			checkoutProcessCtrl.timesDetails = checkoutProcessCtrl.timesDetails || {};

			if (checkoutProcessCtrl.isAddressFirst && !checkoutProcessCtrl.addressDetails) {
				return checkoutProcessCtrl.goToState('app.cart.checkout.process.address', 500);
			}

			checkoutTimesCtrl.changeTimeChoice = changeTimeChoice;
			checkoutTimesCtrl.chooseDeliveryTime = chooseDeliveryTime;
			checkoutTimesCtrl.getDeliveryTimes = getDeliveryTimes;
			checkoutTimesCtrl.branch = Config.branch;
			checkoutProcessCtrl.nextView = nextView;
			checkoutProcessCtrl.previousView = previousView;
			checkoutProcessCtrl.validateView = validateView;
			checkoutTimesCtrl.changeAsap = changeAsap;
			checkoutTimesCtrl.onDisabledOptionSelected = onDisabledOptionSelected;
			checkoutTimesCtrl.onBranchAreaChange = onBranchAreaChange;
			checkoutTimesCtrl.isSpecialShown = isSpecialShown;
			checkoutTimesCtrl.showSeniorCitizen = showSeniorCitizen;

			Config.checkoutData.state = STATE_NAME;
			Config.checkoutData.timesDetails = checkoutProcessCtrl.timesDetails;

			ShippingTermsPopup.showWithRedirect('app.cart');

			_initializeTimeChoice();

			if (checkoutProcessCtrl.isDelivery) {
				_setIsSeniorCitizen();
			}

			function nextView() {
				checkoutProcessCtrl.addDeliveryProduct();
				if (!checkoutProcessCtrl.isAddressFirst) {
					return checkoutProcessCtrl.goToState('app.cart.checkout.process.address');
				}

				checkoutProcessCtrl.goToState('app.cart.checkout.process.' + (checkoutProcessCtrl.isPaymentLast() ? 'summary' : 'payment'));
			}

			function previousView() {
				if (!checkoutProcessCtrl.isAddressFirst) return;

				checkoutProcessCtrl.goToState('app.cart.checkout.process.address');
			}

			function chooseDeliveryTime(dTime) {
				checkoutTimesCtrl.collapseTimes = !checkoutTimesCtrl.collapseTimes;

				checkoutProcessCtrl.timesDetails.deliveryTime = dTime;
			}

			function _initializeTimeChoice() {
				checkoutProcessCtrl.timesDetails.branchArea = checkoutProcessCtrl.timesDetails.branchArea || branchArea;
				if (checkoutProcessCtrl.areas && checkoutProcessCtrl.areas.length && checkoutProcessCtrl.areas.length > 1) {
					_setBranchAreaFromAreasList();
				}

				onBranchAreaChange();
			}

			function _setBranchAreaFromAreasList() {
				var currentAreaInList = checkoutProcessCtrl.areas.find(function(areaWrapper) {
					return checkoutProcessCtrl.timesDetails.branchArea === areaWrapper;
				});
				if (currentAreaInList) {
					return;
				}

				var listArea = checkoutProcessCtrl.areas.find(function(areaWrapper) {
					return checkoutProcessCtrl.timesDetails.branchArea.area.id === areaWrapper.area.id;
				});

				var days,
					expressDeliveryTime,
					withinDaysDeliveryTime;
				if (checkoutProcessCtrl.timesDetails.deliveryChoice === DELIVERY_CHOICES.SHIPPING) {
					if (listArea.times[DELIVERY_CHOICES.SHIPPING]) {
						days = listArea.times[DELIVERY_CHOICES.SHIPPING];
					}
				} else {
					if (listArea.times[DELIVERY_CHOICES.LOCAL_DELIVERY][DELIVERY_TIMES_TYPES.REGULAR]) {
						days = listArea.times[DELIVERY_CHOICES.LOCAL_DELIVERY][DELIVERY_TIMES_TYPES.REGULAR];
					}
					if (listArea.times[DELIVERY_CHOICES.LOCAL_DELIVERY][DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_HOURS]) {
						expressDeliveryTime = listArea.times[DELIVERY_CHOICES.LOCAL_DELIVERY][DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_HOURS];
					}
					if (listArea.times[DELIVERY_CHOICES.LOCAL_DELIVERY][DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_DAYS]) {
						withinDaysDeliveryTime = listArea.times[DELIVERY_CHOICES.LOCAL_DELIVERY][DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_DAYS];
					}
				}

				var foundDeliveryDay,
					foundDeliveryTime;
				angular.forEach(days || [], function(day) {
					if (checkoutProcessCtrl.timesDetails.deliveryChoice === DELIVERY_CHOICES.SHIPPING) {
						if (checkoutProcessCtrl.timesDetails.deliveryDay && day.date === checkoutProcessCtrl.timesDetails.deliveryDay.date) {
							foundDeliveryDay = day;
						}
					} else if (checkoutProcessCtrl.timesDetails.deliveryTime) {
						if (day.isToday && expressDeliveryTime && checkoutProcessCtrl.timesDetails.deliveryTime.id === expressDeliveryTime.id) {
							foundDeliveryTime = expressDeliveryTime;
							foundDeliveryDay = day;
							return;
						}

						if (day.isToday && withinDaysDeliveryTime && checkoutProcessCtrl.timesDetails.deliveryTime.id === withinDaysDeliveryTime.id) {
							foundDeliveryTime = withinDaysDeliveryTime;
							foundDeliveryDay = day;
							return;
						}

						angular.forEach(day.times, function(deliveryTime) {
							if (deliveryTime.id === checkoutProcessCtrl.timesDetails.deliveryTime.id) {
								foundDeliveryTime = deliveryTime;
								foundDeliveryDay = day;
							}
						});
					}
				});

				checkoutProcessCtrl.timesDetails.branchArea = listArea;
				checkoutProcessCtrl.timesDetails.deliveryDay = foundDeliveryDay;
				checkoutProcessCtrl.timesDetails.deliveryTime = foundDeliveryTime;
			}

			function onBranchAreaChange() {
				if (!checkoutProcessCtrl.timesDetails.branchArea || !checkoutProcessCtrl.timesDetails.branchArea.times ||
					!checkoutProcessCtrl.timesDetails.branchArea.times[DELIVERY_CHOICES.LOCAL_DELIVERY] &&
					!checkoutProcessCtrl.timesDetails.branchArea.times[DELIVERY_CHOICES.SHIPPING]) {
					return checkoutTimesCtrl.timesSlots = SpDeliveryTimesService.timesObject()[DELIVERY_TIMES_TYPES.REGULAR];
				}

				if (checkoutProcessCtrl.timesDetails.branchArea.times[DELIVERY_CHOICES.LOCAL_DELIVERY] && checkoutProcessCtrl.timesDetails.branchArea.times[DELIVERY_CHOICES.SHIPPING]) {
					checkoutProcessCtrl.timesDetails.dualMode = true;
				}

				checkoutProcessCtrl.timesDetails.deliveryChoice = checkoutProcessCtrl.timesDetails.deliveryChoice || (checkoutProcessCtrl.timesDetails.branchArea.times[DELIVERY_CHOICES.LOCAL_DELIVERY] ? DELIVERY_CHOICES.LOCAL_DELIVERY : DELIVERY_CHOICES.SHIPPING);
				if (checkoutProcessCtrl.timesDetails.branchArea.times[checkoutProcessCtrl.timesDetails.deliveryChoice]) {
					changeTimeChoice();
				} else {
					checkoutTimesCtrl.timesSlots = SpDeliveryTimesService.timesObject()[DELIVERY_TIMES_TYPES.REGULAR];
				}
				Config.setBranchArea(checkoutProcessCtrl.timesDetails.branchArea.area.id, checkoutProcessCtrl.timesDetails.branchArea.area.name);
			}

			function isSpecialShown(special) {
				if (Config.showCouponsSpecials) {
					return true;
				}

				return !special.isCoupon;
			}

			function _setExpressTimeDisabled(expressTime) {
				var expressLimits = SpDeliveryTimesService.getExpressTimeProductTagLimits(
					Config.retailer.isExpressDeliveryByTag, Config.retailer.expressDeliveryProductsLimit);

				expressTime.disabled = !expressLimits.isEnabled || !expressLimits.isUnderQuantityLimit;

				expressTime.customData = expressTime.customData || {};
				expressTime.customData.exceededLimit = !expressLimits.isUnderQuantityLimit;
				expressTime.customData.hasNonExpressProducts = !expressLimits.isEnabled;
				expressTime.customData.productsLimit = Config.retailer.expressDeliveryProductsLimit;
			}

			function changeTimeChoice() {
				if (!checkoutProcessCtrl.timesDetails.branchArea.times[DELIVERY_CHOICES.SHIPPING] && !checkoutProcessCtrl.timesDetails.branchArea.times[DELIVERY_CHOICES.LOCAL_DELIVERY]) {
					return;
				}

				if (checkoutProcessCtrl.timesDetails.deliveryChoice === DELIVERY_CHOICES.SHIPPING) {
					checkoutTimesCtrl.timesSlots = checkoutProcessCtrl.timesDetails.branchArea.times[DELIVERY_CHOICES.SHIPPING];
					return;
				}

				SpDeliveryTimesService.isDeliveryWithinDaysOnly({
					deliveryTimesObj: checkoutProcessCtrl.timesDetails.branchArea.times[DELIVERY_CHOICES.LOCAL_DELIVERY],
					areaId: checkoutProcessCtrl.timesDetails.branchArea.area.id,
					retailerId: Config.retailer.id,
					isDeliveryWithinDaysByTag: Config.retailer.isDeliveryWithinDaysByTag
				}).then(function(isDeliveryWithinDaysOnly) {
					checkoutTimesCtrl.timesSlots = checkoutProcessCtrl.timesDetails.branchArea.times[DELIVERY_CHOICES.LOCAL_DELIVERY][DELIVERY_TIMES_TYPES.REGULAR];
					angular.forEach(checkoutTimesCtrl.timesSlots, function(slot) {
						slot.times.sort(function(time1, time2) {
							return new Date(time1.from) - new Date(time2.from);
						});
					});

					checkoutTimesCtrl.isDeliveryWithinDaysOnly = isDeliveryWithinDaysOnly;
					checkoutTimesCtrl.timesSlots[0].times = checkoutTimesCtrl.timesSlots[0].times || [];

					var withinDaysDelivery = checkoutProcessCtrl.timesDetails.branchArea.times[DELIVERY_CHOICES.LOCAL_DELIVERY][DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_DAYS];
					if (withinDaysDelivery) {
						var listWithinDaysDelivery = checkoutTimesCtrl.timesSlots[0].times.find(function(time) {
							return time.type === DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_DAYS;
						});
						if (!listWithinDaysDelivery) {
							checkoutTimesCtrl.timesSlots[0].times.unshift(withinDaysDelivery);
						}

						if (checkoutTimesCtrl.isDeliveryWithinDaysOnly) {
							checkoutProcessCtrl.timesDetails.deliveryDay = checkoutTimesCtrl.timesSlots[0];
							checkoutProcessCtrl.timesDetails.deliveryTime = listWithinDaysDelivery || withinDaysDelivery;
						}
					}

					var withinHoursDelivery = checkoutProcessCtrl.timesDetails.branchArea.times[DELIVERY_CHOICES.LOCAL_DELIVERY][DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_HOURS];
					if (withinHoursDelivery) {
						var listWithinHoursDelivery = checkoutTimesCtrl.timesSlots[0].times.find(function(time) {
							return time.type === DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_HOURS;
						});
						if (!listWithinHoursDelivery) {
							_setExpressTimeDisabled(withinHoursDelivery);
							checkoutTimesCtrl.timesSlots[0].times.unshift(withinHoursDelivery);
						} else {
							_setExpressTimeDisabled(listWithinHoursDelivery);
						}
					}
				});
			}

			function getDeliveryTimes(options) {
				options = options || {};
				return Retailer.getRetailerSettings()
					.then(function(retailer) {
						if (options.scroll) {
							if (checkoutProcessCtrl.checkingAddressInProgress) {
								return $q.reject();
							}

							if (retailer.deliveryCustomerDisplayDays === 0 || retailer.deliveryCustomerDisplayDays === null) {
								return $q.reject();
							}

							var today = SpDeliveryTimesService.now();
							today.setUTCHours(0, 0, 0, 0);

							if (checkoutTimesCtrl.timesSlots && checkoutTimesCtrl.timesSlots.length) {
								options.startDate = new Date(new Date(checkoutTimesCtrl.timesSlots[checkoutTimesCtrl.timesSlots.length - 1].date).getTime() + DAY_IN_MS);
							} else {
								options.startDate = new Date(today);
							}

							if (options.startDate >= (today.getTime() + (DAY_IN_MS * retailer.deliveryCustomerDisplayDays))) {
								return $q.reject();
							}
						}

						return checkoutProcessCtrl.getDeliveryTimes(undefined, options)
							.then(function(response) {
								var times = response.area.timesObject.times;
								if (response.area && angular.isArray(response.area.areas) && response.area.areas.length > 1) {
									var selectedPickupArea;
									angular.forEach(response.area.areas, function(area) {
										if (area.id === checkoutProcessCtrl.timesDetails.branchArea.area.id) {
											selectedPickupArea = area;
										}
									});
									if (selectedPickupArea) {
										times = selectedPickupArea.times;
									}
								}
								Array.prototype.push.apply(checkoutTimesCtrl.timesSlots, times.localDelivery[DELIVERY_TIMES_TYPES.REGULAR]);
							});
					});
			}

			function validateView() {
				if (checkoutProcessCtrl.timesDetails.branchArea.area.deliveryProviderId === SP_SERVICES.DELIVERY_PROVIDERS.MANUAL &&
					checkoutProcessCtrl.timesDetails.branchArea.area.providerExtraData.showShippingInputAtCheckout &&
					checkoutProcessCtrl.timesDetails.branchArea.area.providerExtraData.isShippingInputRequired &&
					!checkoutProcessCtrl.timesDetails.shippingCarrierCode) {
					return {
						msg: _translate('required error')
							.replace('{{name}}', checkoutProcessCtrl.timesDetails.branchArea.area.providerExtraData.shippingInputTitle)
					};
				}

				if (!!checkoutProcessCtrl.timesDetails.deliveryTime || checkoutProcessCtrl.timesDetails.deliveryChoice === DELIVERY_CHOICES.SHIPPING) {
					return;
				}

				return { msg: 'Sorry, no available ' + (checkoutProcessCtrl.isDelivery ? 'delivery' : 'pickup') + ' time slots' };
			}

			function changeAsap(asap) {
				checkoutProcessCtrl.timesDetails.asap = asap;
			}

			function onDisabledOptionSelected(time) {
				if (time.type !== DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_HOURS) {
					return;
				}

				if (time.customData.exceededLimit) {
					return _showExpressErrorAlert('' +
						'{{\'Express delivery is limited to\' | translate}} ' +
						time.customData.productsLimit +
						' {{\'items. To enable this service, remove items from your cart\' | translate}}.'
					);
				}

				if (time.customData.hasNonExpressProducts) {
					return _showExpressErrorAlert('{{\'This service is available for Express items only. Removing Non Express items from your cart will enable using this option\' | translate}}.');
				}
			}

			function _showExpressErrorAlert(message) {
				return mDesign.dialog({
					focusOnOpen: false,
					clickOutsideToClose: true,
					template: '' +
						'<md-dialog-content class="express-products-error-dialog">' +
						'<svg focusable="false" class="product-express-delivery-icon">' +
						'<use xlink:href="#sp-express-delivery"></use>' +
						'</svg>' +
						'<div class="express-products-error-dialog-message">' + message + '</div>' +
						'</md-dialog-content>' +
						'<md-dialog-actions>' +
						'<md-button class="md-primary" ng-click="hide()">{{\'OK\' | translate}}</md-button>' +
						'</md-dialog-actions>',
					controller: ['$scope', function($scope) {
						$scope.hide = mDesign.hide;
					}]
				});
			}

			function showSeniorCitizen() {
				return SeniorCitizenVerificationDialog.show().then(function() {
					return _setIsSeniorCitizen();
				}).then(function() {
					if (!checkoutTimesCtrl.isSeniorCitizen) {
						return;
					}

					return checkoutProcessCtrl.getDeliveryTimes(undefined, {
						externalPlaceId: checkoutProcessCtrl.addressDetails.externalPlaceId,
						geoCoordinates: checkoutProcessCtrl.addressDetails.geoCoordinates
					}).then(function() {
						_initializeTimeChoice();
					});
				});
			}

			function _setIsSeniorCitizen() {
				return User.getUserSettings().then(function(userData) {
					checkoutTimesCtrl.isSeniorCitizen = userData.isSeniorCitizen;
				});
			}

			function _sendDataLayerEvent() {
				var cartLineProducts = [];
				angular.forEach(Cart.lines, function (line) {
					if(line && line.product && line.product.id) {
						line.product.quantity = line.quantity;
						cartLineProducts.push(line.product);
					}
				});
				DataLayer.push(DataLayer.EVENTS.CHECKOUT, {products: cartLineProducts, data: {step: 3, option: 'time slot'}});
			}

			_listeners.push($rootScope.$on('cart.update.complete', _sendDataLayerEvent));

			util.destroyListeners($scope, _listeners);

			Cart.save();
		}]);
})(angular);
