angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.specialDiscount', {
        url: '/special-discount',
        data: {
            stateAccess: PAGE_ACCESS.NOT_ORGANIZATION_MEMBER,
            bodyClass: 'special-discount',
            metaTags: {
                title: 'Employee Discount'
            }
        },
        views: {
            '@': {
                templateUrl: 'views/special-discount.html',
                controller: 'SpecialDiscountCtrl',
                controllerAs: 'specialDiscountCtrl',
                resolve: {
                    User: ['User', function (user) {
                        return user.getUserSettings(true);
                    }]
                }
            }
        }
    });
}]).controller('SpecialDiscountCtrl', ['$scope', '$rootScope', '$state', '$filter', 'User', 'Util', 'Config', 'Api', 'Retailer',
    function ($scope, $rootScope, $state, $filter, User, Util, Config, Api, Retailer) {
        var specialDiscountCtrl = this;

        //== check if Special Discounts active for this Retailer
        if(!(Config.retailer.settings.discountClubActive)) {
            return $state.go('app.home');
        }

        specialDiscountCtrl.received = false;
        specialDiscountCtrl.error = '';
        specialDiscountCtrl.userData = {};
        specialDiscountCtrl.connectData = {};
        specialDiscountCtrl.discountClubCardId = User.discountClubCardId;
        specialDiscountCtrl.backgroundImage = Retailer.backgroundImage;
        specialDiscountCtrl.connect = connect;


        _init();

        /**
         * Init all
         * @private
         */
        function _init() {
            _getUserData(specialDiscountCtrl.discountClubCardId);
        }

        /**
         * On connect form submit button press - Send connect request to server
         * @public
         */
        function connect() {
            if (specialDiscountCtrl.connectData.personalId) {
                Api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/users/:uid/special-discount/connect-user',
                    params: {
                        personalId: specialDiscountCtrl.connectData.personalId,
                    }
                }).then(function (response) {
                    if (response.cardId) {
                        specialDiscountCtrl.userData = response;
                        specialDiscountCtrl.discountClubCardId = response.cardId;
                    } else {
                        specialDiscountCtrl.error = response.error ? $filter('translate')(response.error) : $filter('translate')('Employee discount account not found');
                    }
                });
            }
        }

        /**
         * Get updated user data including obligo from server
         * @private
         *
         * @param {Number} cardId
         */
        function _getUserData(cardId) {
            if (!cardId) {
                //== User not connected to MultiPass
                specialDiscountCtrl.received = true;
            } else {

                Api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/users/:uid/special-discount/get-user'
                }).then(function (response) {
                    specialDiscountCtrl.received = true;
                    if (response && response.cardId) {
                        specialDiscountCtrl.userData = response;
                        specialDiscountCtrl.discountClubCardId = response.cardId;
                    } else {
                        specialDiscountCtrl.discountClubCardId = 0;
                        specialDiscountCtrl.error = response && response.error ? $filter('translate')(response.error) : $filter('translate')('Employee discount account not found');
                    }
                });
            }
        }
}]);