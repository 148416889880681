angular.module('mobilezuz').factory('User', [
    '$q', '$location', 'Api', '$state', '$window', 'CordovaService', '$rootScope', '$injector', 'Config', 'mDesign',
    'Notifications', 'SEARCH_SORT_BOOST_TYPES', 'USER_VERIFICATION_STATUS', 'ORGANIZATION_TYPES',
    'POLICY_ENFORCEMENT_MODES', 'USER_ERRORS', 'BRANCH_DELIVERY_TYPES',
    function($q, $location, api, $state, $window, CordovaService, $rootScope, $injector, Config, mDesign,
             Notifications, SEARCH_SORT_BOOST_TYPES, USER_VERIFICATION_STATUS, ORGANIZATION_TYPES,
             POLICY_ENFORCEMENT_MODES, USER_ERRORS, BRANCH_DELIVERY_TYPES) {
        var user = {},
            userSettingsPromise,
            Util,
            retailerData;

        var multiLoyaltyUserPromise = null

        var isTriggeredMultiLoyalty = false

        user.settings = {}; // keep one reference of the user settings

        /** @type {boolean} */
        var isRetailerReplacementsEnabled = null;

        user.login = login;
        user.loginWithToken = loginWithToken;
        user.generateOtpCode = generateOtpCode;
        user.getTypeOfOTPFromCredentials = getTypeOfOTPFromCredentials
        user.logout = logout;
        user.setUserLoginData = setUserLoginData;
        user.getUserLoginData = getUserLoginData;
        user.getUserSettings = getUserSettings;
        user.getRetailerData = getRetailerData;
        user.getUserTickets = getUserTickets;
        user.createUserTicketComment = createUserTicketComment;
        user.uploadTicketAttachment = uploadTicketAttachment;
        user.getPurchasesSortBoost = getPurchasesSortBoost;
        user.isVerified = isVerified;
        user.isOrganizationBranch = isOrganizationBranch;
        user.resendActivationLink = resendActivationLink;
        user.getAuthorizationToken = getAuthorizationToken;
        user.setUserArea = setUserArea;
        user.setNewPassword = setNewPassword;
        user.getReplacementSuggestions = getReplacementSuggestions;
        user.updateReplacementSuggestions = updateReplacementSuggestions;
        user.injectSuggestionsForProducts = injectSuggestionsForProducts;
        user.getData = getUserSettings;
        user.setIsTriggerMultiLoyalty = setIsTriggerMultiLoyalty;
        user.getUserSettingsMultiLoyalty = getUserSettingsMultiLoyalty
        user.updateDeliveryLineAnonymous = updateDeliveryLineAnonymous;

        _init();

        function _init() {
          Config.waitForInit().then(function () {
            isRetailerReplacementsEnabled = Config.retailer.settings.enablePersonalReplacement;
          });
        }

        function updateReplacementSuggestions(selections) {
            var userLoginData = getUserLoginData(),
                userId = userLoginData && userLoginData.uid;

            return Config.waitForBranchId().then(function(branchId) {
                if (!branchId || !userId || !selections || !selections.length) {
                    return;
                }

                return api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/users/:uid/replacement-suggestions',
                    params: {
                        branchId: branchId,
                        userId: userId
                    },
                    data: {
                        selections: selections
                    }
                }, {
                    fireAndForgot: true
                });
            });
        }

        function getReplacementSuggestions(productIds, excludeIds) {
            var userLoginData = getUserLoginData(),
                userId = userLoginData && userLoginData.uid;

            return Config.waitForBranchId().then(function(branchId) {
                if (!branchId || !userId || !productIds || !productIds.length) {
                    return;
                }

                return api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/users/:uid/replacement-suggestions/getReplacements',
                    params: {
                        branchId: branchId
                    },
                    data: {
                        productIds: productIds,
                        excludeIds: excludeIds
                    }
                }, {
                    fireAndForgot: true
                });
            });
        }

        function injectSuggestionsForProducts(products) {
            if (isRetailerReplacementsEnabled) {
                var oosProducts = [];// out of stock products
                var productIdMap = {};
                
                products.forEach(function(product) {
                    if (product && product.branch && product.branch.isOutOfStock) {
                        oosProducts.push(product)
                    }
                });
    
                if(oosProducts && oosProducts.length > 0){
                    getReplacementSuggestions(oosProducts.map(function(product) {
                        // this is currently creating display issues, so disable for now
                        // line.product._suggestions = [{}];
                        productIdMap[product.id] = product;
                        return product.id;
                    }), []).then(function(data) {
                        angular.forEach(data, function(productSuggestion) {
                            var product = productIdMap[productSuggestion.id];
                            if (product && productSuggestion.suggestions && productSuggestion.suggestions.length) {
                                product.showReplacementLabel = true
                                product._suggestions = productSuggestion.suggestions;
                           }
                        });
                    })
                }
            }
        }

        function getAuthorizationToken(){
            var userLoginData = getUserLoginData();
            if (!userLoginData || !userLoginData.token){
                return
            }
            return 'Bearer ' + userLoginData.token;
        }

        function generateOtpCode(otpCredentials, typeOfOtp, recaptchaHash) {
            var defer = $q.defer();

            api.request({
                method: 'POST',
                url: '/retailers/:rid/sessions/generate-otp-code',
                data: {
                    otpCredentials: otpCredentials,
                    typeOfOtp: typeOfOtp,
                    recaptchaHash: recaptchaHash
                }
            }).then(function (resp) {
                defer.resolve({});
            }, function (err) {
                defer.reject(err);
            });

            return defer.promise;
        }

        function getTypeOfOTPFromCredentials(otpCredentials) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(otpCredentials) ? $rootScope.LOGIN_WITH_OTP_OPTIONS.EMAIL : $rootScope.LOGIN_WITH_OTP_OPTIONS.PHONE_NUMBER;
        }

        function loginWithToken(userId, token, otpCredentials, otpType, recaptchaHash, email) {
            return api.request({
                method: 'POST',
                url: '/retailers/:rid/sessions/token',
                data: {
                    userId: userId,
                    email: email,
                    token: token,
                    otpCredentials: otpCredentials,
                    otpType: otpType,
                    recaptchaHash: recaptchaHash
                }
            }).then(function (respond) {
                return userLoggedInSuccessfully(respond, respond.email);
            });
        }

        function login(email, pass, recaptchaHash) {
            return api.request({
                method: 'POST',
                url: 'retailers/:rid/sessions',
                data: angular.extend({
                    username: email,
                    password: pass,
                    recaptchaHash: recaptchaHash
                }, Notifications.data.token && {
                    service: Notifications.data.service,
                    appToken: Notifications.data.token
                })
            }, {
                hideError: true
            }).then(function (respond) {
                return userLoggedInSuccessfully(respond, email);
            }).catch(function (data) {
                var errorMessage = errorMessage = data.response && data.response.error || ('Unknown error (' + data.statusCode + ')');
                mDesign.alert(errorMessage);
                throw data;
            });
        }

        function userLoggedInSuccessfully(respond, username) {
            if (!respond.token) {
                throw respond;
            }

            userSettingsPromise = undefined;
            angular.copy({}, user.settings); // reset the user settings reference

            setUserLoginData({
                uid: respond.userId,
                email: username,
                token: respond.token,
                organizationId: respond.organizationId,
                organizationTypeId: respond.organizationTypeId,
                organizationBranches: respond.organizationBranches,
                organizationRoleId: respond.organizationRoleId,
                loyaltyClubCardId: respond.loyaltyClubCardId
            }, true);

            if (!respond.isPolicyApproved && Config.retailer.policyEnforcementModeId === POLICY_ENFORCEMENT_MODES.SHOW) {
                _showApprovalDialog(respond.userId, false);
            }

            return respond;
        }

        function logout() {
            var userLoginData = getUserLoginData();

            if (userLoginData) {
                api.request({
                    method: 'DELETE',
                    url: 'retailers/:rid/sessions/session'
                }).then(function (respond) {
                    userSettingsPromise = undefined;
                    multiLoyaltyUserPromise = undefined;

                    angular.copy({}, user.settings); // empty the user settings reference
                    $rootScope.isCreditCustomer = false;

                    setUserLoginData(null);
                    $window.localStorage.removeItem('userLoginData');
                    $window.localStorage.removeItem('deliveryArea');
                    $window.localStorage.removeItem('ageRestriction');
                    if (respond.success) {
                        $state.go('app.home');
                    }
                    delete Config.checkoutData;
                    $rootScope.$emit('logout');
                });
            }

            if ($window.facebookConnectPlugin) {
                facebookConnectPlugin.logout();
            }
        }

        function getUserLoginData() {
            if (Config.loginAsData.token) {
                return {
                    token: Config.loginAsData.token,
                    uid: Config.loginAsData.userId,
                    branchKeyOrganization: Config.loginAsData.organization && Config.loginAsData.organization.id,
                    organizationRoleId: Config.loginAsData.organization && Config.loginAsData.organization.roleId,
                    organizationTypeId: Config.loginAsData.organization && Config.loginAsData.organization.organizationTypeId,
                    organizationBranches: Config.loginAsData.organization && Config.loginAsData.organization.branches && Config.loginAsData.organization.branches.map(function(branch) {
                        return branch.id;
                    }),
                    loyaltyClubCardId: Config.loginAsData.loyaltyClubCardId
                };
            }

            var jsonData = $window.localStorage.getItem('userLoginData');
            if (jsonData) {
                return JSON.parse(jsonData);
            }
        }

        /**
         * @param {boolean} isTrigger 
         */
        function setIsTriggerMultiLoyalty(isTrigger){
            isTriggeredMultiLoyalty = isTrigger
        }

        function setUserLoginData(loginData, isLogin) {
            if (isLogin && (!loginData.uid || !loginData.token)) {
                throw new Error('No token or user id to save');
            }

            var nextLoginData = loginData && {
                uid: loginData.uid,
                email: loginData.email || '',
                token: loginData.token
            };

            if (loginData && loginData.organizationId && loginData.organizationTypeId !== ORGANIZATION_TYPES.SIMPLE) {
                nextLoginData.branchKeyOrganization = loginData.organizationId;
                nextLoginData.organizationRoleId = loginData.organizationRoleId;
                nextLoginData.organizationTypeId = loginData.organizationTypeId;
                if (loginData.organizationBranches) {
                    nextLoginData.organizationBranches = loginData.organizationBranches.map(function(branch) {
                        return branch.id;
                    });
                }
            }

            if (loginData && loginData.loyaltyClubCardId) {
                nextLoginData.loyaltyClubCardId = loginData.loyaltyClubCardId;
            }

            var currentLoginData = getUserLoginData() || {},
                shouldReload = false;

            
            nextLoginData = nextLoginData || {}
            var isChangeBranchKey = nextLoginData.branchKeyOrganization !== currentLoginData.branchKeyOrganization
            var isChangeOrgRoleId = nextLoginData.organizationRoleId !== currentLoginData.organizationRoleId
            var isChangeBranchs = !_compareOrganizationBranches(nextLoginData.organizationBranches, currentLoginData.organizationBranches)

            // ECOM-3375 Multi loyalty exclude registration club -> condition always true -> endless reload
            var isChangeLoyaltyCardId = !isTriggeredMultiLoyalty && nextLoginData.loyaltyClubCardId !== currentLoginData.loyaltyClubCardId

            if (isChangeBranchKey || isChangeOrgRoleId || isChangeBranchs || isChangeLoyaltyCardId) {
                shouldReload = true;
            }

            // when loginAs and the reason for reload is the loyaltyClubCardId, set the loginAs loyalty card
            // to prevent endless reloads
            if ((nextLoginData || {}).loyaltyClubCardId !== currentLoginData.loyaltyClubCardId && Config.loginAsData.token) {
                mDesign.ignoreNextUrlChange();
                $location.search('loyaltyClubCardId', (nextLoginData || {}).loyaltyClubCardId || null);
            }

            if (nextLoginData && Object.keys(nextLoginData).length > 0) {
                $window.localStorage.setItem('userLoginData', JSON.stringify(nextLoginData));
            } else {
                $window.localStorage.removeItem('userLoginData');
            }

            if (isLogin) {
                userSettingsPromise = null;
                multiLoyaltyUserPromise = null
                $rootScope.$emit('login');
            }

            if (shouldReload) {
                // timeout the reload, it seems to be blocked when the login dialog closes
                setTimeout(function() {
                    _getUtil().reload();
                }, 50);

                // force the reload, it seems to be blocked when the login dialog closes
                setInterval(function() {
                    _getUtil().reload();
                }, 3000);
            }
        }

        /**
         * To avoid circular dependency
         * @private
         *
         * @return {Promise}
         */
        function _getUtil() {
            return Util = Util || $injector.get('Util');
        }

        function _compareOrganizationBranches(branchesA, branchesB) {
            if ((branchesA || []).length !== (branchesB || []).length) {
                return false;
            }

            var branchesAMap = {};
            angular.forEach(branchesA || [], function(branchId) {
                branchesAMap[branchId] = true;
            });
            for (var i = 0; i < (branchesB || []).length; i++) {
                // when a branch id in B does not exist in A, it differs
                if (!branchesAMap[branchesB[i]]) {
                    return false;
                }
            }

            return true;
        }

        /**
         * @typedef {Object} GetUsersParams
         * @property {boolean} isNotIncludeRegistrationClub
         */

        /**
         * @param {*} branchId 
         * @param {GetUsersParams} extraParams 
         */
        function buildGetUserSettingsParams(branchId, extraParams){
            var params = {}
            
            if(branchId){
                params.branchId = branchId
            }

            angular.extend(params, extraParams)

            params.refreshObligo = !!$rootScope.refreshObligo

            return params
        }

        /**
         * @param {boolean} withoutCache 
         * @param {GetUsersParams} extraParams
         * @returns 
         */
        function getUserSettings(withoutCache, extraParams) {
            if (userSettingsPromise && !withoutCache) {
                return userSettingsPromise;
            }

            return userSettingsPromise = Config.waitForBranchId().then(function(branchId) {
                if (!getUserLoginData()) {
                    return $q.reject(USER_ERRORS.NOT_LOGGED_IN);
                }

                var params = buildGetUserSettingsParams(branchId, extraParams)

                return api.request({
                    method: 'GET',
                    url: 'retailers/:rid/users/:uid',
                    cache: false,
                    params: params,
                    headers: {
                        'api-version': '2.0'
                    }
                }).then(function(response) {
                    angular.copy(response, user.settings); // copy the response into the user settings reference
                    $rootScope.refreshObligo = false;

                    //== prevent displaying null null
                    user.settings.firstName = user.settings.firstName || '';
                    user.settings.lastName = user.settings.lastName || '';

                    $rootScope.isCreditCustomer = user.settings.isCreditCustomer || false;
                    $rootScope.creditCustomerRemainingSum = $rootScope.isCreditCustomer && user.settings.creditCustomerRemainingSum;

                    _setOrganizationUserLoginData();

                    if (Config.retailer.policyEnforcementModeId === POLICY_ENFORCEMENT_MODES.FORCE && !user.settings.policyApprovalTime) {
                        _showApprovalDialog(user.settings.id, true);
                    }

                    // ECOM-10578 avoid showing ChooseAreaDialog when login/checkout
                    if(!Config.branchAreaId || !Config.branchAreaName) {
                        Config.branchAreaId = user.settings.areaId;
                        Config.branchAreaName = user.settings.areaName;
                    }

                    return user.settings;
                }).catch(function(err) {
					if (err.response && (err.response.code === "accountNotVerified" || err.response.code === "userVerificationExpired")) {
						user.logout();
					}

					return user.settings;
                });
            });
        }

        function getUserSettingsMultiLoyalty(){
            if(multiLoyaltyUserPromise) {
                return multiLoyaltyUserPromise
            }

            return Config.waitForBranchId().then(function(branchId) {
                if(!getUserLoginData()) {
                    return $q.reject(USER_ERRORS.NOT_LOGGED_IN);
                }
    
                var params = buildGetUserSettingsParams(branchId, {isNotIncludeRegistrationClub: true})
    
                return multiLoyaltyUserPromise = api.request({
                    method: 'GET',
                    url: 'retailers/:rid/users/:uid',
                    params: params,
                    cache: false,
                    headers: {
                        'api-version': '2.0'
                    }
                }).then(function(res) {
                    multiLoyaltyUserCache = res
                    return res
                })
            })
        }

        /**
         * Set new user login data by the new user settings
         * @private
         */
        function _setOrganizationUserLoginData() {
            var loginData = getUserLoginData();
            var newLoginData = {
                uid: loginData.uid,
                email: loginData.email,
                token: loginData.token,
            };

            if (user.settings.organization) {
                newLoginData.organizationId = user.settings.organization.id;
                newLoginData.organizationTypeId = user.settings.organization.organizationType;
                newLoginData.organizationRoleId = user.settings.organization.roleId;
                newLoginData.organizationBranches = user.settings.organization.branches;
            }

            var loyaltyClubCardId = user.settings.loyaltyClubCardId || (user.settings.loyaltyClubs && user.settings.loyaltyClubs.length && user.settings.loyaltyClubs[0].loyaltyCardId);
            if (loyaltyClubCardId) {
                newLoginData.loyaltyClubCardId = loyaltyClubCardId;
            }
            setUserLoginData(newLoginData);
        }

        function getRetailerData(withoutCache) {
            var defer = $q.defer();

            if (!withoutCache && retailerData) {
                defer.resolve(retailerData);
            } else {
                api.request({
                    method: 'GET',
                    url: 'retailers/:rid'
                }).then(function (respond) {
                    retailerData = respond;
                    defer.resolve(respond);
                });
            }

            return defer.promise;
        }

        function getUserTickets(getLastData) {
            return api.request({
                method: 'GET',
                url: '/retailers/:rid/users/tickets/customer',
                params: {
                    getLastData: getLastData,
                }
            }).then(function (data) {
                return data.tickets;
            });
        }

        function createUserTicketComment(comment) {
            return api.request({
                method: 'POST',
                url: '/retailers/:rid/users/tickets/customer',
                data: comment
            }).then(function (data) {
                return data;
            });
        }

        function uploadTicketAttachment(attachment) {
            if (!attachment) {
                return $q.resolve();
            }

            var data = new FormData();
            data.append('attachment', attachment);

            return api.request({
                method: 'POST',
                url: '/retailers/:rid/tickets/comments/attachments/_upload',
                data: data,
                headers: {
                    'Content-Type': undefined
                }
            });
        }

        function getPurchasesSortBoost() {
            return Config.waitForInit().then(function() {
                if (!Config.retailer.isUserPurchasesPersonalizationActive) {
                    return;
                }

                var userLoginData = getUserLoginData();
                if (userLoginData && userLoginData.uid) {
                    return {
                        sortType: SEARCH_SORT_BOOST_TYPES.USER_LAST_PURCHASED_PRODUCTS,
                        topPriority: JSON.stringify([{id: userLoginData.uid}])
                    };
                }
            });
        }

        function isVerified() {
            return !Config.retailer || Config.retailer.settings.isUserVerificationActive !== 'true' ||
                (user.settings.verificationStatusId && 
                user.settings.verificationStatusId !== USER_VERIFICATION_STATUS.NOT_VERIFIED &&
                user.settings.verificationStatusId !== USER_VERIFICATION_STATUS.SENT);
        }

        /**
         * Returns whether the given branch id is allowed by the user organization
         * @public
         *
         * @param {number} branchId
         *
         * @return {boolean}
         */
        function isOrganizationBranch(branchId) {
            var loginData = getUserLoginData(),
                organizationBranches = (loginData || {}).organizationBranches;
            if (!organizationBranches) {
                return true;
            }

            return !!organizationBranches.find(function(orgBranchId) {
                return orgBranchId === branchId;
            });
        }

        /**
         * Resend activation link to user's email
         * @public
         *
         * @param {String} email
         *
         * @return {boolean}
         */
        function resendActivationLink(email) {
            return api.request({
                method: 'POST',
                url: '/v2/retailers/:rid/users/verification/resend-activation-link',
                data: { email: email }
            });
        }


		/**
		 * Show approval dialog
		 * @private
		 *
		 * @param {Number} userId
		 * @param {Boolean} isForce
		 *
		 * @return {boolean}
		 */
        function _showApprovalDialog(userId, isForce) {
            mDesign.dialog({
                templateUrl: 'views/templates/policy-approval.html',
                clickOutsideToClose: false,
                escapeToClose: !isForce,
                multiple: false,
                controller: ['$rootScope', '$scope', function($rootScope, $scope) {
                    var policyApprovalCtrl = this;
                    policyApprovalCtrl.allowClosing = !isForce;
                    policyApprovalCtrl.showError = false;
                    policyApprovalCtrl.cancel = cancel;
                    policyApprovalCtrl.submit = submit;

                    function submit() {
                        if (!policyApprovalCtrl.policyApproval) {
                            policyApprovalCtrl.showError = true;
                            return;
                        }
                        return api.request({
                            method: 'PATCH',
                            url: '/retailers/:rid/users/' + userId,
                            data: {policyApproval: true}
                        }).then(function() {
                            mDesign.hide();
                        });
                    }

                    function cancel() {
                        mDesign.hide();
                    }
                }],
                controllerAs: 'policyApprovalCtrl'
            });
		}

        /**
         * @typedef {Object} AreaInput
         * @property {number} deliveryTypeId
         * @property {number} id
         * @property {number=} name
         * @property {number=} branchId
         * @property {{ id: number }} branch
         */

        /**
         * @param {AreaInput} area
         * @param {number[]} deliveryTypeIds
         */
        function updateDeliveryLineAnonymous(area, deliveryTypeIds) {
          var cartId = Number(localStorage.getItem("serverCartId"));

          if (!area || !area.id || !deliveryTypeIds || !deliveryTypeIds.length || !cartId) {
            return Promise.resolve(true);
          }

          var body = {
            areaId: area.id,
            deliveryTypeIds: deliveryTypeIds,
          };

          return api.request({
            method: "POST",
            url: "/v2/retailers/:rid/branches/:bid/carts/" + cartId + "/delivery-line",
            data: body,
          });
        }

        /**
         * Set user area
         * @public
         * @param {AreaInput} area
         * @param {number=} userId 
         *
         * @return {error: boolean}
         */
        function setUserArea(area, userId) {
            var deliveryTypeIds = [area.deliveryTypeId];
            // ECOM-2695: Must send both area delivery type and "retailer delivery type" (I guess) to GET LIST AREAS FROM CACHE in server => send DELIVERY (1), EXPRESS_DELIVERY (5)
            if (area.deliveryTypeId === BRANCH_DELIVERY_TYPES.DELIVERY) {
                deliveryTypeIds.push(BRANCH_DELIVERY_TYPES.EXPRESS_DELIVERY);
            }

            if(!userId){
                return updateDeliveryLineAnonymous(area, deliveryTypeIds);
            }

            return api.request({
                method: 'POST',
                url: '/v2/retailers/:rid/users/:uid/set-area',
                data: {
                    userId: userId,
                    areaId: area.id,
                    areaName: area.name,
                    branchId: area.branchId || (area.branch && area.branch.id) || null,
                    deliveryTypeId: deliveryTypeIds,
                    cartId: localStorage.getItem('serverCartId') ? +localStorage.getItem('serverCartId') : null,
                    includeDeliveryFeeInCart: Config.retailer.settings.includeDeliveryFeeInCart === 'true',
                }
            });
        }

        function setNewPassword(email, resetCode, password, user) {
            return api.request({
                method: 'POST',
                url: '/retailers/:rid/users/_setNewPassword',
                data: Object.assign({}, user, {
                    email: email,
                    resetCode: resetCode,
                    password: password
                })
            });
        }

        return user;
    }]);