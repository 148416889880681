angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.productTagProducts', {
        url: '/product-tags/:productTagId/products?{events:string}',
        reloadOnSearch: false,
        data: {
            stateAccess: PAGE_ACCESS.ALLOW,
            bodyClass: 'product-tag-products',
            metaTags: {
                title: 'Product tag products'
            }
        },
        resolve: {
            productTag: ['$q', '$state', '$stateParams', '$location', 'Api',  function ($q, $state, $stateParams, $location, Api) {
                return $q.resolve().then(function() {
                    var productTagId =  Number($stateParams.productTagId);
                    if (!productTagId) {
                        return;
                    }

                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/product-tags/' + productTagId
                    });
                }).then(function (productTag) {
                    if (!productTag) {
                        throw new Error('Page not found');
                    }

                    return productTag;
                }).catch(function(err) {
                    $state.go('app.home', $location.search());
                    return $q.reject(err);
                });
            }],
            productTagBanner: ['$q', '$stateParams', '$state', '$location', 'Api', '$sce', function ($q, $stateParams, $state, $location, Api, $sce) {
                return $q.resolve().then(function() {
                    var productTagId = Number($stateParams.productTagId);

                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/landing-page-banner/' + productTagId,
                    });
                }).then(function (results) {
                    var returnedResults;
                    angular.forEach(results, function (value, key) {
                        if (results[key].isMobile == 1 && results[key].isActive){
                            value.imageUrl = $sce.trustAsResourceUrl(value.imageUrl)
                            returnedResults = value
                        }
                    });
                    return returnedResults;
                }).catch(function(err) {
                    $state.go('app.home', $location.search());
                    return $q.reject(err);
                });
            }]
        },
        views: {
            '@': {
                templateUrl: 'views/product-tag-products.html',
                controller: 'ProductTagProductsCtrl as productTagProductsCtrl'
            }
        }
    });
}]).controller('ProductTagProductsCtrl', [
    '$scope', '$rootScope', '$stateParams', '$location', 'Config', 'Api', 'Util', 'Cart', 'Bumpers',
    'Products', 'productTag', 'ADVERTISEMENT_CONVERSION_TYPE', 'productTagBanner', '$state',
    function ($scope, $rootScope, $stateParams, $location, Config, Api, util, cart, bumpers,
              Products, productTag, ADVERTISEMENT_CONVERSION_TYPE, productTagBanner, $state) {
        var productTagProductsCtrl = this,
            isParentTag = !!productTag.retailerId && productTag.retailerId !== Config.retailerId,
            _listeners = [],
            isRequesting = false,
            isEmptyRequest = false;
        productTagProductsCtrl.products = [];
        productTagProductsCtrl.specials = [];
        productTagProductsCtrl.total = 0;
        productTagProductsCtrl.currentSpecialsFrom = 0;
        productTagProductsCtrl.productTag = productTag;
        productTagProductsCtrl.productTagBanner = productTagBanner;
        productTagProductsCtrl.showSaleExperations = Config.retailer.showSpecialsValidity;

        _getSpecials();

        if ($stateParams.events) {
            try {
                productTagProductsCtrl.events = JSON.parse($stateParams.events);
            } catch(e) {}

            util.waitForInitDialog().then(function() {
                $location.search('events', null);
            });
        }

        function _getProductsChunk(isInit) {
            if (isRequesting || (!isInit && productTagProductsCtrl.total === productTagProductsCtrl.products.length)) return;

            isRequesting = true;

            var term = {
                'branch.isActive': true,
                'branch.isVisible': true
            };
            term[(isParentTag ? 'parentProductTags' : 'productTags')] = productTag.id;
            var sortValue = 'tagById.' + productTag.id + '.sort';
            var sortObject = {};
            sortObject[sortValue] = 'asc';
            return Products.prepareSearchParams({
                from: productTagProductsCtrl.products.length,
                size: 20,
                filters: {
                    must: {
                        term: term,
                        exists: ['family.id', 'family.categoriesPaths.id']
                    }
                }
            }).then(function(params) {
                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/branches/:bid/products',
                    params: params
                }, {
                    loadingElement: document.querySelector('.loading-progress-wrapper')
                });
            }).then(function (resp) {
                // Array.prototype.push.apply(productTagProductsCtrl.products, cart.getProducts(resp.products));
                productTagProductsCtrl.total = resp.total;

                if (resp.products.length) {
                    var productAndBumpersArray = cart.getProducts(resp.products);

                    $rootScope.$emit('products.added', {chunk: resp});

                    bumpers.handleProductsBumpers(productTagProductsCtrl.products, productAndBumpersArray);

                    productTagProductsCtrl.products.push.apply(productTagProductsCtrl.products, productAndBumpersArray);
                }
            }).finally(function () {
                isRequesting = false;
            });
        }

        _getProductsChunk(true);

        function _syncProductWithCart() {
            productTagProductsCtrl.products = cart.getProducts(productTagProductsCtrl.products);
        }

        function _getSpecials() {
            if (productTagProductsCtrl.productTag.specialsCount > 0){
                var specialsESFilters = {
                    must: {
                        lessThan: {
                            startDate: new Date()
                        },
                        greaterThan: {
                            endDate: new Date()
                        },
                        term: {
                            displayOnMobile: true,
                            productTags: [productTagProductsCtrl.productTag.id]
                        }
                    }
                };
                var params = {
                    from: productTagProductsCtrl.currentSpecialsFrom,
                    size: 20,
                    filters: angular.copy(specialsESFilters)
                };
                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/branches/:bid/specials',
                    params:params
                }).then(function (response) {
                    angular.forEach(response.specials , function (special){
                        productTagProductsCtrl.specials.push(special)
                    })
                    productTagProductsCtrl.currentSpecialsFrom += productTagProductsCtrl.specials.length
                });
            }
        }

        productTagProductsCtrl.goToOffer = function goToOffer($event, offer, itemsData) {
            if (offer.item) {
                Util.goToProductDialog(offer.item, null, null, false, itemsData);
            } else {
                $state.go('app.specials.special', {ssid: offer.id});
            }
        }

        _listeners.push($scope.$root.$on('cart.lines.add', function (event, data) {
            if (productTagProductsCtrl.products.length && productTagProductsCtrl.events) {
                util.firePromotionEvent(productTagProductsCtrl.events[ADVERTISEMENT_CONVERSION_TYPE.ADD_TO_CART]);
                if (data.lines && data.lines.length) {
                    data.lines[0].adConversionUrl = productTagProductsCtrl.events[ADVERTISEMENT_CONVERSION_TYPE.CHARGE];
                }
            }
            _syncProductWithCart();
        }));
        _listeners.push($scope.$root.$on('cart.lines.remove', _syncProductWithCart));
        _listeners.push($scope.$on('vertical-scroll-end', function () {
            _getProductsChunk();
            _getSpecials()
        }));

        util.destroyListeners($scope, _listeners);
    }]);
