(function (angular) {
    'use strict';

    var DEFAULT_SEARCH_PARAMS = {
        filters: {
            must: {
                exists: ['family.id', 'family.categoriesPaths.id', 'branch.regularPrice'],
                term: {
                    'branch.isActive': true,
                    'branch.isVisible': true
                }
            },
            mustNot: {
                term: {
                    'branch.regularPrice': 0
                }
            }
        }
    };

    angular.module('mobilezuz').directive('spSearchAutocomplete', ['$location', '$rootScope', '$timeout', '$q', '$state',
        'Util', 'Config', 'User', 'Api', 'Cart', 'Products', 'Util',
        function($location, $rootScope, $timeout, $q, $state, util, Config, User, api, Cart, Products, Util) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'views/templates/sp-search-autocomplete.html',
            scope: {
                model: '=spModel',
                threshold: '=?'
            },
            controllerAs: 'searchAutoCompleteCtrl',
            controller: ['$scope', '$element', '$attrs', 'FiltersManager', function($scope, $element, $attrs, FiltersManager) {
                var searchAutoCompleteCtrl = this,
                    _parentElement = angular.element($element[0].parentNode || $element[0].parentElement),
                    _body = document.querySelector('body'),
                    _inputElement = angular.element(null),
                    _inputNgModelController,
                    _reloadAutoComplete,
                    _showOptionsWatcher,
                    _chosenOption,
                    _headerElement = angular.element(document.getElementById('header'));
                
                searchAutoCompleteCtrl.shown = false;
                searchAutoCompleteCtrl.elementId = 'sp_search_autocomplete';
                searchAutoCompleteCtrl.search = search;
                searchAutoCompleteCtrl.searchByFilters = searchByFilters;
                searchAutoCompleteCtrl.setShown = setShown;

                var _inputAttrs = {
                    'role': 'combobox',
                    'aria-autocomplete': 'list',
                    'aria-expanded': 'false',
                    'aria-haspopup': 'listbox',
                    'aria-owns': searchAutoCompleteCtrl.elementId
                };

                if (angular.isUndefined($scope.threshold)) {
                    $scope.threshold = 300;
                }

                _parentElement.addClass('auto-complete-wrapper');

                function getOptions() {
                    var params = angular.copy(DEFAULT_SEARCH_PARAMS);
                    params.from = 0;
                    params.size = 10;
                    params.query = $scope.model;
                    params.languageId = Config.language.id;
                    params.isSearch = true;
                    params.userId = User.data && User.data.id

                    if (Config.retailer.settings.showUnavailableProducts !== 'true') {
                        params.filters.mustNot.term['branch.isOutOfStock'] = true;
                    }
                    else {
                        if (Config.retailer.settings.isOutOfStockDuration === 'true') {
                            params.filters.bool = {
                                "should": [
                                    {
                                        "bool": {
                                            "must_not": {
                                                "exists": {
                                                    "field": "branch.outOfStockShowUntilDate"
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "bool": {
                                            "must": [
                                                {
                                                "range": {
                                                    "branch.outOfStockShowUntilDate": {
                                                    "gt": "now"
                                                    }
                                                }
                                                },
                                                {
                                                "term": {
                                                    "branch.isOutOfStock": true
                                                }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "bool": {
                                            "must": [
                                                {
                                                "term": {
                                                    "branch.isOutOfStock": false
                                                }
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        }
                    }
					FiltersManager.parsePermanentProductTags(params, false);

                    _chosenOption = $scope.model;
                    return api.request({
                        url: '/v2/retailers/:rid/branches/:bid/products/autocomplete',
                        method: 'GET',
                        params: params
                    }).then(function (data) {
                        searchAutoCompleteCtrl.isSearchResult = $scope.model && $scope.model.length > 2;
                        searchAutoCompleteCtrl.title = data.title && data.title[params.languageId];
                        searchAutoCompleteCtrl.customerType = data.customerType;
                        searchAutoCompleteCtrl.rules = data.suggestions && data.suggestions.rules;
                        searchAutoCompleteCtrl.products = _productsMapping(data.suggestions && data.suggestions.suggestProducts);
                        searchAutoCompleteCtrl.highlight = null;
                        searchAutoCompleteCtrl.brandsHighlight = null;

                        User.injectSuggestionsForProducts(searchAutoCompleteCtrl.products);

                        util.setLiveAlert('Found X results for your search', searchAutoCompleteCtrl.products.length);

                        if (searchAutoCompleteCtrl.isSearchResult) {
                            searchAutoCompleteCtrl.isBrandSuggestionsActive = data.isBrandSuggestionsActive;
                            searchAutoCompleteCtrl.brandSuggestionsTitle = data.brandSuggestionsTitle && data.brandSuggestionsTitle[params.languageId];
                            searchAutoCompleteCtrl.highlight = [];
                            searchAutoCompleteCtrl.brandsHighlight = [];

                            if (data.suggestions && data.suggestions.suggestFilters) {
                                if (data.suggestions.suggestFilters.categories && data.suggestions.suggestFilters.categories.length) {
                                    Array.prototype.push.apply(searchAutoCompleteCtrl.highlight, data.suggestions.suggestFilters.categories);
                                }

                                if (data.suggestions.suggestFilters.brands && data.suggestions.suggestFilters.brands.length) {
                                    Array.prototype.push.apply(searchAutoCompleteCtrl.brandsHighlight, data.suggestions.suggestFilters.brands);
                                }
                            }
                        }
                    });
                }

                function _getCartLinesArray() {
                    var allLines = [];
                    angular.forEach(Cart.lines, function(line) {
                        allLines.push(line);
                    });
                    return allLines;
                }

                function _productsMapping(suggestProducts) {
                    var products;
                    if (suggestProducts.products) {
                        products = suggestProducts.products;
                    } else {
                        products = (suggestProducts.items || []).map(function (item) {
                            return item && item.product || item;
                        });
                    }

                    return Cart.getProducts(products);
                }

                function searchByFilters(key, value, name) {
                    searchAutoCompleteCtrl.filters = {};
                    if (key && value) {
                        searchAutoCompleteCtrl.filters[key] = value;
                    }
                    if(key === 'brand' && value && value[0]) {
                        searchAutoCompleteCtrl.filters = searchAutoCompleteCtrl.filters || {};
                        searchAutoCompleteCtrl.filters.queryInBrand = $scope.model;
                    }

                    search('', name);
                }

                function search(item, title) {
                    var filter,
                        query = $scope.model;
                    if (item === 'specials') {
                        filter = 'specials';
                    } else if(searchAutoCompleteCtrl.filters && searchAutoCompleteCtrl.filters.brand) { // Search by brand - remove search term
                        query = title ? '' : item || $scope.model;
                    }

                    searchAutoCompleteCtrl.postChooseOptionClick = true;

                    $state.go('app.search', {
                        query: query,
                        filter: filter,
                        filters: searchAutoCompleteCtrl.filters || {},
                        sort: undefined,
                        title: title || undefined
                    });

                    searchAutoCompleteCtrl.filters = {};
                }

                function _reloadOptions() {
                    if (_reloadAutoComplete) {
                        $timeout.cancel(_reloadAutoComplete);
                    }
                    searchAutoCompleteCtrl.postChooseOptionClick = false;
                    _chosenOption = null;

                    _reloadAutoComplete = $timeout(function() {
                        $q.resolve().then(function() {
                            return getOptions();
                        }).catch(function(err) {
                        });
                    }, $scope.threshold);
                }

                function _isElementFixed(element) {
                    element = angular.element(element)[0];
                    var res = '';
                    if (window.getComputedStyle && angular.isFunction(window.getComputedStyle)) {
                        res = window.getComputedStyle(element).getPropertyValue('position');
                    } else if (element.currentStyle && element.currentStyle.position) {
                        res = element.currentStyle.position;
                    }
                    return res === 'fixed';
                }

                function _callSetIsOutOfScreen() {
                    $timeout(_setIsOutOfScreen, 0);
                    $timeout(_setIsOutOfScreen, 500);
                }

                function _setIsOutOfScreen() {
                    var currentElement = _parentElement[0],
                        top = 0;
                    do {
                        top += currentElement.offsetTop;
                        currentElement = currentElement.offsetParent;
                    } while (!!currentElement && !_isElementFixed(currentElement));

                    var bottom = top + _parentElement[0].offsetHeight + $element.prop('offsetHeight');

                    if (bottom > _body.offsetHeight) {
                        $element.addClass('out-of-screen');
                    } else {
                        $element.removeClass('out-of-screen');
                    }
                }

                function _apply() {
                    $scope.$applyAsync();
                }

                function _inputKeyDown(event) {
                    if (event.which === 13) {
                        event.preventDefault();
                        event.stopImmediatePropagation();
                        search();
                        return _apply();
                    }

                    if (event.which === 9 && !event.shiftKey) {
                        var focusableElements = $element[0].querySelectorAll('button, [ng-click], [href], input, select, textarea');
                        focusableElements[0].focus();
                        event.preventDefault();
                        event.stopImmediatePropagation();
                    }
                }

                function _autocompleteElementFocusin() {
                    searchAutoCompleteCtrl.autocompleteElementFocus = true;
                }

                function _inputFocus() {
                    searchAutoCompleteCtrl.inFocus = true;
                    _reloadOptions();
                    _apply();
                    _callSetIsOutOfScreen();
                }

                function _inputBlur() {
                    $timeout(function() {
                        searchAutoCompleteCtrl.inFocus = false;
                    });
                }

                function _bindInput() {
                    $element.bind('focusin', _autocompleteElementFocusin);
                    _inputElement.bind('keydown', _inputKeyDown);
                    _inputElement.bind('focus', _inputFocus);
                    _inputElement.bind('blur', _inputBlur);

                    angular.forEach(_inputAttrs, function(value, key) {
                        _setInputAttr(key, value);
                    });
                }

                function _setInputAttr(key, value) {
                    _inputElement && _inputElement[0].setAttribute(key, value);
                }

                var watcher = $scope.$watch(function() {
                    return _parentElement[0].querySelector('input[ng-model="' + $attrs.spModel + '"]');
                }, function(newValue) {
                    if (!newValue) return;

                    watcher();
                    _inputElement = angular.element(newValue);
                    _inputNgModelController = _inputElement.data('$ngModelController');
                    _bindInput();
                });

                $scope.$watch('searchAutoCompleteCtrl.products', _setProductsContext);
                function _setProductsContext() {
                    if (searchAutoCompleteCtrl.products && searchAutoCompleteCtrl.products.length) {
                        searchAutoCompleteCtrl.productsContext = Products.productsEditContext(searchAutoCompleteCtrl.products);
                    }
                }

                $scope.$watch('model', function(newVal) {
                    if (_chosenOption === newVal) {
                        return;
                    }

                    _reloadOptions();
                });

                var _reloadListener;

                $scope.$on('$destroy', function() {
                    _reloadListener && _reloadListener();
                });

                _showOptionsWatcher = '(searchAutoCompleteCtrl.inFocus || searchAutoCompleteCtrl.autocompleteElementFocus) && !searchAutoCompleteCtrl.postChooseOptionClick';

                $scope.$watch(_showOptionsWatcher, function(newVal) {
                    setShown(!!newVal);
                });

                function setShown(value) {
                    var prevValue = searchAutoCompleteCtrl.shown;
                    searchAutoCompleteCtrl.shown = value;
                    $timeout(function () {
                        $rootScope.isSearchAutocompleteShown = value;
                    });

                    _setInputAttr('aria-expanded', searchAutoCompleteCtrl.shown);
                    if (searchAutoCompleteCtrl.shown) {
                        _parentElement.addClass('auto-complete-options-shown');
                        _headerElement.addClass('auto-complete-shown');
                    } else {
                        _parentElement.removeClass('auto-complete-options-shown');
                        _headerElement.removeClass('auto-complete-shown');
                    }
                }

                function _onResize() {
                    _callSetIsOutOfScreen();
                }

                function _checkFocusElement(event) {
                    if (!searchAutoCompleteCtrl.autocompleteElementFocus) return;

                    var eventTarget = event && (event.target || event.srcElement || event.currentTarget);
                    searchAutoCompleteCtrl.autocompleteElementFocus = eventTarget && !!eventTarget.closest('.sp-search-autocomplete');
                }

                document.addEventListener('focusin', _checkFocusElement);
                angular.element(window).bind('resize', _onResize);


                function _checkClickOutSearch(event){
                    if(!searchAutoCompleteCtrl.autocompleteElementFocus && !searchAutoCompleteCtrl.shown){
                        return;
                    }
                    var eventTarget = event && (event.target || event.srcElement || event.currentTarget);
                    var clickOut = !(eventTarget && !!eventTarget.closest('.sp-search-autocomplete'));
                    if(clickOut){
                        searchAutoCompleteCtrl.autocompleteElementFocus = false;
                    }
                }
                angular.element(window).bind('click', _checkClickOutSearch)
                util.destroyListeners($scope, function() {
                    setShown(false);
                    angular.element(_inputElement).unbind('keydown', _inputKeyDown);
                    angular.element(_inputElement).unbind('focusin', _inputFocus);
                    angular.element(_inputElement).unbind('blur', _inputBlur);
                    angular.element($element).unbind('focusin', _inputBlur);
                    angular.element(window).unbind('resize', _onResize);
                    angular.element(window).unbind('click', _checkClickOutSearch);
                });
            }]
        };
    }]);
})(angular);