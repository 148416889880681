angular.module('spApi', []);
;
(function (angular) {
    angular.module('spApi').config(['$provide', function ($provide) {
        /**
         * When request come back with status in the array, the api will call the callback
         * @public
         *
         * @type {{
                settings: {
                    [name]: {
                        statuses: Array,
                        callback: Function|Array,
                        limit: [Number]
                    }
                },
                statuses: {
                    [statusNumber]: [String] name
                }
            }}
         */
        var retry = { settings: {}, statuses: {} };

        var provider = $provide.service('Api', ['$q', '$http', '$controller', '$rootScope', 'Loading', function ($q, $http, $controller, $rootScope, loading) {
            var self = this;

            /**
             * @private
             * @type {Number}
             */
            var _nextInnerId = 0;

            /**
             * @private
             * @type {Array}
             */
            var _queue = [];

            /**
             * @private
             * @type {Number}
             */
            var _runningRequests = 0;

            /**
             * @private
             * @return {Number} unique id
             */
            function _getInnerId() {
                return _nextInnerId++;
            }

            /**
             * set loading counter by apiOptions
             * @private
             *
             * @param {Object} apiOptions
             * @param {Number} toAdd
             */
            function setLoadingCounter(apiOptions, toAdd) {
                if (apiOptions.fireAndForgot) return;

                return loading.counter(apiOptions.loadingElement.element, toAdd, {activeClass: apiOptions.loadingElement.activeClass, path: apiOptions._path});
            }

            /**
             * contains request apiOptions by id
             * @private
             *
             * @type {Object}
             * @example {
             *  'requestId': apiOptions
             * }
             */
            var _requests = {};

            /**
             * send request to API rest
             *
             * @param {Object} httpOptions
             * @param {Object} [apiOptions]
             * @param {Boolean=false} [apiOptions.fireAndForgot]
             * @param {String} [apiOptions.id]
             * @param {Element|Object} [apiOptions.loadingElement]
             * @param {Boolean=false} [apiOptions.doNotRetry]
             * @param {Boolean} [apiOptions.httpMethodOverride]
             *
             * @return {Promise}
             */
            self.request = function (httpOptions, apiOptions) {
                var defer = $q.defer();

                apiOptions = apiOptions || {};
                if (apiOptions.id) {
                    if (_requests[apiOptions.id]) {
                        self.abort(apiOptions.id);
                    }

                    apiOptions._id = _getInnerId();
                    _requests[apiOptions.id] = apiOptions;
                }

                apiOptions.loadingElement = apiOptions.loadingElement || {};
                var element = angular.element(apiOptions.loadingElement)[0] instanceof Element ? angular.element(apiOptions.loadingElement) : apiOptions.loadingElement.element;
                apiOptions.loadingElement = {element: element, activeClass: apiOptions.loadingElement.activeClass};

                httpOptions.method = httpOptions.method || 'GET';
                httpOptions.params = httpOptions.params || {};
                if (provider.httpMethodOverride && (apiOptions.httpMethodOverride === undefined || apiOptions.httpMethodOverride)) {
                    var method = httpOptions.method.toUpperCase();
                    if (method != 'POST' && method != 'GET') {
                        httpOptions.headers = httpOptions.headers || {};
                        httpOptions.headers['X-HTTP-Method-Override'] = method;
                        httpOptions.method = 'POST';
                    }
                }

                var retried = 0;

                function retryCallback(isRetry) {
                    if (isRetry) {
                        return sendRequest();
                    }

                    delete this.callback;
                    return defer.reject(this);
                }

                function sendRequest() {
                    if (_isRequestAborted(apiOptions)) return rejectAbortedRequest(defer, apiOptions);

                    _runningRequests++;
                    apiOptions._path = setLoadingCounter(apiOptions, 1);

                    // finally doesn't get this parameters
                    var data = {};
                    $http(httpOptions)
                        .then(function (resp) {
                            if (_isRequestAborted(apiOptions)) return rejectAbortedRequest(defer, apiOptions, resp);

                            data = _requestData(resp, httpOptions, apiOptions);

                            defer.resolve(resp.data);
                        })
                        .catch(function (resp) {
                            if (_isRequestAborted(apiOptions)) return rejectAbortedRequest(defer, apiOptions, resp);

                            data = _requestData(resp, httpOptions, apiOptions);

                            var retryName = retry.statuses[resp.status];
                            if (retryName && (!retry.settings[retryName].limit || retry.settings[retryName].limit > retried)) {
                                if (!apiOptions.doNotRetry) {
                                    retried++;
                                    data.callback = retryCallback.bind(data);
                                    return $controller(retry.settings[retryName].callback, data);
                                }

                                data.retryName = retryName;
                            }

                            if (!apiOptions.fireAndForgot) {
                                $rootScope.$emit('spApi.error', data);
                            }

                            defer.reject(data);
                        })
                        .finally(function () {
                            _runningRequests--;
                            if (_queue.length) {
                                _queue.splice(0, 1)[0]();
                            }

                            if (_isRequestAborted(apiOptions)) return;

                            delete _requests[apiOptions.id];

                            setLoadingCounter(apiOptions, -1);

                            $rootScope.$emit('spApi.response', data);
                        });
                }

                $controller(provider.setParameters || angular.noop, {
                    httpOptions: httpOptions,
                    apiOptions: apiOptions,
                    callback: function () {
                        if (_runningRequests >= provider.maxConcurrentRequests) {
                            return _queue.push(sendRequest);
                        }

                        sendRequest();
                    }
                });

                return defer.promise;
            };

            /**
             * abort request by id
             * @public
             *
             * @param {String} id
             */
            self.abort = function (id) {
                if (!_requests[id]) return new Error("request doesn't exist");

                setLoadingCounter(_requests[id], -1);
                delete _requests[id];
            };

            /**
             * get is request aborted
             * @private
             */
            function _isRequestAborted(apiOptions) {
                return apiOptions.id && (!_requests[apiOptions.id] || _requests[apiOptions.id]._id != apiOptions._id);
            }

            /**
             * rejects aborted request
             * @private
             */
            function rejectAbortedRequest(defer, apiOptions, response) {
                defer.reject({
                    aborted: true,
                    apiOptions: apiOptions,
                    response: response
                });
            }

            /**
             * Return request data object by response
             * @private
             *
             * @param {Object} response
             * @param {Object} httpOptions
             * @param {Object} apiOptions
             *
             * @returns {Object}
             */
            function _requestData(response, httpOptions, apiOptions) {
                return angular.extend({
                    httpOptions: httpOptions,
                    apiOptions: apiOptions,
                    response: response.data,
                    //Add workaround because of our services still expect
                    //To get response.statusCode error And the server returns response.status
                    statusCode: response.status
                }, response);
            }
        }]);

        /**
         * When api.request find parameters in the url (like ":param") call to this function
         *
         * @type {Array|Null} (like angularJs controller)
         * @example
         * ['httpOptions', 'apiOptions', 'callback', function (httpOptions, apiOptions, callback) {
         *     callback();
         * });
         */
        provider.setParameters = null;

        /**
         * Change all request method except GET to POST and add X-HTTP-Method-Override header with original method
         * @type {Boolean|Null}
         */
        provider.httpMethodOverride = null;

        /**
         * Set max concurrent requests
         * @type {Number}
         */
        provider.maxConcurrentRequests = 8;

        /**
         * Should the sp-api log not found responses to raven
         * @type {Boolean}
         */
        provider.logNotFoundRequests = false;

        /**
         * Add a retry name to the retry settings
         * @param {string} name
         * @param {object} options
         * @param {number|Array} options.status
         * @param {function} options.callback
         * @param {number} [options.limit]
         */
        function addRetry(name, options) {
            if (!name || !options || angular.isUndefined(options.status) || angular.isUndefined(options.callback)) return;
            retry.settings[name] = options;
            var statuses = angular.isArray(options.status) ? options.status : [options.status];
            angular.forEach(statuses, function(status) {
                retry.statuses[status] = name;
            });
        }

        provider.addRetry = addRetry;
    }]);

    'use strict';
})(angular);
;
(function(angular) {
    'use strict';

    angular.module('spApi').config(['$provide', function ($provide) {
        var provider = $provide.service('Loading', ['$q', '$location', '$rootScope', '$compile', function ($q, $location, $rootScope, $compile) {
            var self = this;

            /**
             * @private
             * counter per loading element and location
             *
             * @example
             * {
             *   'sp-api-1': {
             *     '/path': 2,
             *     '/otherPath': 1
             *   },
             *   'sp-api-2': {
             *     '/path': 1
             *   }
             * }
             */
            self._requestCounter = {};

            /**
             * set counter by element and path
             *
             * @param {HTMLElement} element
             * @param {Number} toAdd
             * @param {Object} options
             * @param {String} [options.path=$location.path() || '/']
             * @param {String} [options.activeClass]
             *
             * @return {String} path
             */
            self.counter = function (element, toAdd, options) {
                options = options || {};
                var isDefaultElement = !element;
                element = self._getElement(element);

                var id = self._idByElement(element);

                var path = (typeof options.path === 'string' ? options.path :  $location.path() || '/');

                if (!self._requestCounter[id]) {
                    self._requestCounter[id] = {};
                }

                if (!self._requestCounter[id][path]) {
                    self._requestCounter[id][path] = toAdd;
                } else {
                    self._requestCounter[id][path] += toAdd;
                }

                if (self._requestCounter[id][path]) {
                    setTimeout(function() {
                        if (!self._requestCounter[id][path]) return;

                        _toggleElement(element, isDefaultElement, true, options);
                    }, provider.showLoadingAfter);
                } else {
                    setTimeout(function() {
                        if (self._requestCounter[id][path]) return;

                        _toggleElement(element, isDefaultElement, false, options);
                    }, provider.hideLoadingAfter);

                }

                return path;
            };

            /**
             * Toggle loading element on or off
             * @private
             *
             * @param {Element} element
             * @param {boolean} isDefaultElement
             * @param {boolean} isOn
             * @param {Object} options
             * @param {string} [options.activeClass]
             *
             * @returns {void}
             */
            function _toggleElement(element, isDefaultElement, isOn, options) {
                var className = isDefaultElement ? provider.activeClass : options.activeClass;
                if (className) {
                    isOn ? element.addClass(className) : element.removeClass(className);
                } else {
                    element.css('display', isOn ? 'block' : 'none');
                }

                if (isDefaultElement) {
                    var body = angular.element(document.body || document.querySelector('body'));
                    isOn ? body.addClass('has-loading') : body.removeClass('has-loading');
                }
            }

            /**
             * set count by element and path
             *
             * @param {Element} element
             * @param {String=$location.path()} [path]
             *
             * @return {Number} count
             */
            self.getCount = function (element, path) {
                element = self._getElement(element);

                var id = self._idByElement(element);

                path = path || $location.path();

                var count = 0;
                if (self._requestCounter[id] && self._requestCounter[id][path]) {
                    count = self._requestCounter[id][path];
                }
                return count;
            };

            /**
             * @private
             * get element or default elem,element
             *
             * @return {Element}
             */
            self._getElement = function (element) {
                return (element ? angular.element(element) : null) || self._getDefaultElement();
            };

            /**
             * angular element of _defaultElement (created on the first time we need this)
             * @type {HTMLElement}
             * @private
             */
            self._defaultElement = null;

            /**
             * @private
             * return (create if need) default element
             *
             * @return {Element}
             */
            self._getDefaultElement = function () {
                if (self._defaultElement) {
                    return self._defaultElement;
                }

                self._defaultElement = angular.element(provider.loadingTemplate);
                _toggleElement(self._defaultElement, true, false, {});
                angular.element(document.body).append(self._defaultElement);
                $compile(self._defaultElement)($rootScope);

                return self._defaultElement;
            };

            /**
             * @private
             * number for _generateId
             * @type {Number}
             */
            self._generateIdNumber = 1;

            /**
             * @private
             * return unique id ('sp-api-{{Number}}')
             *
             * @return {String} id
             */
            self._generateId = function () {
                return 'sp-api-' + (self._generateIdNumber++);
            };

            /**
             * @private
             * get id (sp-api id) by element
             *
             * @param {Element} element
             *
             * @return {String} id
             */
            self._idByElement = function (element) {
                var spApiId = element.prop('sp-api-id');
                if (spApiId) {
                    return spApiId;
                }

                var id = self._generateId();
                element.prop('sp-api-id', id);
                return id;
            };
        }]);

        /**
         * default loading html template
         * @type {String}
         */
        provider.loadingTemplate = new Error('Loading.loadingTemplate must overwrite');

        /**
         * default active class
         * @type {string}
         */
        provider.activeClass = null;

        /**
         * show loading element after X ms
         * @type {Number}
         */
        provider.showLoadingAfter = null;

        /**
         * show loading element after X ms
         * @type {Number}
         */
        provider.hideLoadingAfter = null;
    }]);
})(angular);
