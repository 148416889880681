(function (angular) {
    var STATE_NAME = 'app.cart.checkout.process.payment';

    angular.module('mobilezuz').config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state(STATE_NAME, {
            url: '/payment',
            checkoutViewOriginalIndex: 2,
            checkoutViewIndex: 2,
            views: {
                'checkoutProcess': {
                    templateUrl: 'views/checkout/process/payment.html',
                    controller: 'CheckoutPaymentCtrl as checkoutPaymentCtrl'
                }
            }
        });
    }]).controller('CheckoutPaymentCtrl', [
        '$scope', '$rootScope', '$filter', 'Config', 'PaymentsService', 'Retailer', 'User', 'Cart', 'Util', 'ShippingTermsPopup', 'SP_PAYMENTS', 'DataLayer',
        function ($scope, $rootScope, $filter, Config, PaymentsService, Retailer, User, Cart, util, ShippingTermsPopup, SP_PAYMENTS, DataLayer) {
            var checkoutProcessCtrl = $scope.checkoutProcessCtrl,
                checkoutPaymentCtrl = this,
                translateFilter = $filter('translate'),
                _listeners = [];
            checkoutPaymentCtrl.checkoutProcessCtrl = checkoutProcessCtrl;
            checkoutPaymentCtrl.safeFallBackEBTCalculatedAmounts= $rootScope.safeFallBackEBTCalculatedAmounts;
            checkoutProcessCtrl.paymentDetails = checkoutProcessCtrl.paymentDetails || {};

            if (checkoutProcessCtrl.isAddressFirst && !checkoutProcessCtrl.timesDetails) {
                return checkoutProcessCtrl.goToState('app.cart.checkout.process.times', 500);
            }

            if (!checkoutProcessCtrl.isAddressFirst && !checkoutProcessCtrl.addressDetails) {
                return checkoutProcessCtrl.goToState('app.cart.checkout.process.address', 500);
            }

            checkoutProcessCtrl.nextView = nextView;
            checkoutProcessCtrl.previousView = previousView;
            checkoutProcessCtrl.validateView = validateView;

            Config.checkoutData.state = STATE_NAME;
            Config.checkoutData.paymentDetails = checkoutProcessCtrl.paymentDetails;

            ShippingTermsPopup.showWithRedirect('app.cart');

            Retailer.getRetailerSettings().then(function (settings) {
                checkoutPaymentCtrl.settings = settings;
                var ebtBranchSettings = checkoutPaymentCtrl.settings.settings.eBTBranchSettings ? JSON.parse(checkoutPaymentCtrl.settings.settings.eBTBranchSettings) : {};
                ebtBranchSettings.branchId = Config.branch.id;
                $rootScope.ebtBranchSettings = ebtBranchSettings
            });

            User.getUserSettings().then(function (user) {
                if (!user) {
                    return;
                }

                checkoutPaymentCtrl.user = user;
                checkoutProcessCtrl.nextButtonDisabled = checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.ORGANIZATION && !user.organization.isActive;
            });

            function nextView() {
                if (checkoutProcessCtrl.isPaymentLast()) {
                    return;
                }

                checkoutProcessCtrl.goToState('app.cart.checkout.process.summary');
            }

            function previousView() {
                if (checkoutProcessCtrl.isPaymentLast()) {
                    return checkoutProcessCtrl.goToState('app.cart.checkout.process.summary');
                }

                checkoutProcessCtrl.goToState(checkoutProcessCtrl.isAddressFirst ? 'app.cart.checkout.process.times' : 'app.cart.checkout.process.address');
            }

            function validateView() {
                var res;
                if (!checkoutProcessCtrl.paymentDetails.paymentMethod) {
                    res = {msg: 'Payment method is required'};
                }

                if (checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT) {
                    if (!checkoutProcessCtrl.paymentDetails.ebtCard) {
                        res = {msg: translateFilter('EBT_card_is_required')};
                    }

                    if (!checkoutProcessCtrl.paymentDetails.creditCard) {
                        res = {msg: 'Credit card is required'};
                    }

                    var $ebtForm = angular.element(document.querySelector('[name="ebtForm"]')),
                        ebtFormData = $ebtForm.data('$formController');

                    if(ebtFormData){
                        // make sure the amounts are synced
                        // todo: onEBTAmountChange();

                        if (ebtFormData.ebtFSAmount && ebtFormData.ebtFSAmount.$invalid) {
                            res = { selector: angular.element(document.querySelector('#ebtFSAmount')) };
                        } else if (ebtFormData.ebtCBAmount  && ebtFormData.ebtCBAmount.$invalid) {
                            res = { selector: angular.element(document.querySelector('#ebtCBAmount')) };
                        } else if ( checkoutProcessCtrl.ebtCalculatedAmounts && !checkoutProcessCtrl.ebtCalculatedAmounts.total.amount && !$rootScope.safeFallBackEBTCalculatedAmounts.total) {
                            res = { selector: angular.element(document.querySelector('#ebtFSAmount')), msg: 'Please enter EBT SNAP or EBT Cash amount' };
                        } else if (checkoutProcessCtrl.paymentDetails.isCvvShownForCheckout && (!checkoutProcessCtrl.paymentDetails.cvv || !checkoutProcessCtrl.paymentDetails.cvv.trim())) {
                          res = {selector: angular.element(document.querySelector('#cvv'))};
                      }
                    }

                }

                if (checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.TAV_PLUS || checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.TAV_PLUS_LIGHT) {
                    if (!checkoutProcessCtrl.paymentDetails.tavPlusCard) {
                        res = {msg: translateFilter('tav_plus_card_is_required')};
                    }

                    if (!checkoutProcessCtrl.paymentDetails.creditCard) {
                        res = {msg: 'Credit card is required'};
                    }

                    var $tavPlusForm = angular.element(document.querySelector('[name="tavPlusForm"]')),
                        tavFormData = $tavPlusForm.data('$formController');

                    if(tavFormData){
                        if (tavFormData.tavPlusAmount.$invalid) {
                            res = { selector: angular.element(document.querySelector('#tavPlusAmount')) };
                        }
                    }

                }

                if (checkoutProcessCtrl.isRememberCreditCardsMode && !res &&
                    (checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD ||
                    checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT ||
                        checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.TAV_PLUS ||
                      checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.TAV_PLUS_LIGHT)) {
                    if (!checkoutProcessCtrl.paymentDetails.creditCard) {
                        res = {msg: 'Credit card is required'};
                    }
                    else if (!checkoutProcessCtrl.paymentDetails.creditCard.isActive) {
                        res = {msg: translateFilter('Please activate credit card {xxxx} in order to continue').replace('{xxxx}', checkoutProcessCtrl.paymentDetails.creditCard.lastFourDigits)};
                    }
                    else if (checkoutProcessCtrl.paymentDetails.isCvvShownForCheckout &&
                        (!checkoutProcessCtrl.paymentDetails.cvv || !checkoutProcessCtrl.paymentDetails.cvv.trim())) {
                        res = {selector: angular.element(document.querySelector('#cvv'))};
                    }
                    else if (checkoutProcessCtrl.paymentDetails.isCvvShownForCheckout && (isNaN(checkoutProcessCtrl.paymentDetails.cvv) || checkoutProcessCtrl.paymentDetails.cvv.length < 3 || checkoutProcessCtrl.paymentDetails.cvv.length > 4)) {
                        res = {selector: angular.element(document.querySelector('#cvv'))};
                    }
                }
                if (checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.STORE_CREDIT) {
                    if (!checkoutProcessCtrl.paymentDetails.storeCredit || !checkoutProcessCtrl.paymentDetails.storeCredit.trim()) {
                        res = {selector: angular.element(document.querySelector('#store_credit'))};
                    }
                }

                if (checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.PAYPAL && !checkoutProcessCtrl.paymentDetails.paypalAccount) {
                    res = {msg: 'No PayPal account'};
                }


                if (res) {
                    checkoutPaymentCtrl.showErrors = true;
                    return res;
                }
            }

            function _sendDataLayerEvent() {
                var cartLineProducts = [];
                angular.forEach(Cart.lines, function (line) {
                    if(line && line.product && line.product.id) {
                        line.product.quantity = line.quantity;
                        cartLineProducts.push(line.product);
                    }
                });
                DataLayer.push(DataLayer.EVENTS.CHECKOUT, {products: cartLineProducts, data: {step: 4, option: 'payment details'}});
            }

            _listeners.push($rootScope.$on('cart.update.complete', _sendDataLayerEvent));

            util.destroyListeners($scope, _listeners);

        }]);
})(angular);
