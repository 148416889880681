(function (angular) {
    'use strict';

    var COUNTER = 0;

    angular.module('mobilezuz').directive('spAutocompleteAddressAndMap', [
        '$rootScope', '$timeout', '$q', 'Util',
        function ($rootScope, $timeout, $q, util) {
            return {
                restrict: 'E',
                replace: true,
                templateUrl: 'views/templates/sp-autocomplete-address-and-map.html',
                scope: {
                    model: '=spModel',
                    optionModel: '=?spOptionModel',
                    getOptions: '&spGetOptions',
                    choose: '&spChoose',
                    openGoogleMap: '&spOpenGoogleMap',
                    threshold: '=?',
                    hideIfEmpty: '=?',
                    filters: '&?spFilters',
                    filtersTemplateUrl: '@?spFiltersTemplateUrl',
                    value: '@?spValue',
                    identifier: '@?spIdentifier',
                    requireMatch: '<?spRequireMatch',
                    reloadOptionsOn: '@?spReloadOptionsOn',
                    showOnFocus: '<?spShowOnFocus',
                    ariaLabel: '@?spAriaLabel',
                    onShow: '&?',
                    onHide: '&?',
                },
                controllerAs: 'autocompleteAddressAndMapCtrl',
                controller: [
                    '$scope', '$element', '$attrs', 'Config',
                    function ($scope, $element, $attrs, config) {
                        var autocompleteAddressAndMapCtrl = this,
                            _parentElement = angular.element($element[0].parentNode || $element[0].parentElement),
                            _body = document.querySelector('body'),
                            _inputElement = angular.element(null),
                            _inputNgModelController,
                            _reloadAutoComplete,
                            _showOptionsWatcher,
                            _chosenOption,
                            _reloadListener;

                        var _inputAttrs = {
                            'role': 'combobox',
                            'aria-autocomplete': 'list',
                            'aria-expanded': 'false',
                            'aria-haspopup': 'listbox',
                            'aria-owns': autocompleteAddressAndMapCtrl.elementId
                        };

                        var watcher = $scope.$watch(function () {
                            return _parentElement[0].querySelector('input[ng-model="' + $attrs.spModel + '"]');
                        }, function (newValue) {
                            if (!newValue) return;

                            watcher();
                            _inputElement = angular.element(newValue);
                            _inputNgModelController = _inputElement.data('$ngModelController');
                            _bindInput();
                        });

                        autocompleteAddressAndMapCtrl.shown = false;
                        autocompleteAddressAndMapCtrl.elementId = 'sp_autocomplete_' + COUNTER++;
                        autocompleteAddressAndMapCtrl.isHoverOnBtnMap = false;
                        autocompleteAddressAndMapCtrl.isGoogleMapDropPinAllow = config.retailer.settings.isGoogleMapDropPinAllow;

                        autocompleteAddressAndMapCtrl.chooseOption = chooseOption;
                        autocompleteAddressAndMapCtrl.changeQuery = changeQuery;
                        autocompleteAddressAndMapCtrl.openGoogleMap = openGoogleMap;

                        _init();

                        function chooseOption(index) {
                            autocompleteAddressAndMapCtrl.chooseOptionClick = true;
                            _setRequireMatchValidation(true);
                            _chosenOption = $scope.optionModel = null;

                            var option, filter;
                            if (index > -1) {
                                option = autocompleteAddressAndMapCtrl.options[index];
                            } else if (autocompleteAddressAndMapCtrl.options.length === 1) {
                                index = 0;
                                option = autocompleteAddressAndMapCtrl.options[0];
                            }

                            if (option) {
                                $scope.model = option.value;
                                _chosenOption = $scope.optionModel = option;
                                filter = option.filter;
                                autocompleteAddressAndMapCtrl.currentIndex = index;
                                _setInputAttr('aria-activedescendant', (autocompleteAddressAndMapCtrl.elementId + '_option_' + index));
                            }

                            $timeout(function () {
                                $scope.choose(option && { $event: { filter: filter, option: option, index: index } });
                                _setOptions();
                                _inputElement && _inputElement[0].focus();
                                $timeout(function () {
                                    autocompleteAddressAndMapCtrl.chooseOptionClick = false;
                                    autocompleteAddressAndMapCtrl.postChooseOptionClick = true;
                                }, 0);
                            }, 100);
                        }

                        function changeQuery(event, index) {
                            var option = autocompleteAddressAndMapCtrl.options[index];
                            $scope.model = option.value;

                            event.stopPropagation();
                            event.preventDefault();
                            event.stopImmediatePropagation();

                            _reloadOptions();
                        }

                        function openGoogleMap() {
                            $scope.openGoogleMap();
                        }

                        function _init() {
                            if (angular.isUndefined($scope.threshold)) {
                                $scope.threshold = 300;
                            }
                            _parentElement.addClass('auto-complete-wrapper');
                            _showOptionsWatcher = '(!hideIfEmpty || model.length) && autocompleteAddressAndMapCtrl.inFocus && !autocompleteAddressAndMapCtrl.postChooseOptionClick';
                            angular.element(window).bind('resize', _onResize);
                        }

                        function _reloadOptions() {
                            if (_reloadAutoComplete) {
                                $timeout.cancel(_reloadAutoComplete);
                            }
                            _setRequireMatchValidation(true);
                            autocompleteAddressAndMapCtrl.postChooseOptionClick = false;

                            var currentChosenOption = _chosenOption;
                            _chosenOption = null;

                            _reloadAutoComplete = $timeout(function () {
                                var filtersDetails = {
                                    model: $scope.model,
                                    filters: $scope.filters && $scope.filters()
                                };
                                if (filtersDetails.filters && !angular.isArray(filtersDetails.filters)) {
                                    filtersDetails.filters = [filtersDetails.filters];
                                }

                                $q.resolve().then(function () {
                                    return $scope.getOptions();
                                }).then(function (options) {
                                    _setOptions(options || [], filtersDetails, currentChosenOption);
                                }).catch(function () {
                                    _setOptions([], filtersDetails, currentChosenOption);
                                });
                            }, $scope.threshold);
                        }

                        function _setOptions(options, filtersDetails, prevChosenOption) {
                            if (options) {
                                var newOptions = [];
                                if (filtersDetails.filters && filtersDetails.filters.length && filtersDetails.model && $scope.filtersTemplateUrl) {
                                    angular.forEach(filtersDetails.filters, function (filter) {
                                        newOptions.push({
                                            value: filtersDetails.model,
                                            filter: filter
                                        });
                                    });
                                }
                                var matchingOptions = [];
                                angular.forEach(options, function (option) {
                                    var newOption = _parseOption(option);
                                    newOptions.push(newOption);
                                    if (newOption.value === $scope.model) {
                                        matchingOptions.push(newOption);
                                    }
                                });
                                if (matchingOptions.length === 1) {
                                    _chosenOption = matchingOptions[0];
                                } else if (prevChosenOption) {
                                    // retain chosen option when has more than one new options and identifier matches
                                    var option = matchingOptions.find(function (option) {
                                        return option.identifier === prevChosenOption.identifier;
                                    });
                                    if (option) {
                                        _chosenOption = option;
                                    }
                                }
                                $scope.optionModel = _chosenOption;
                                autocompleteAddressAndMapCtrl.options = newOptions;
                            } else {
                                autocompleteAddressAndMapCtrl.options = [];
                            }

                            _setRequireMatchValidation(!$scope.model || !!_chosenOption);
                            autocompleteAddressAndMapCtrl.currentIndex = -1;
                            _inputElement && _inputElement[0].removeAttribute('aria-activedescendant');
                            $rootScope.$emit('autoComplete.set');

                            _callSetIsOutOfScreen();
                        }

                        function _parseOption(option) {
                            if (!option) {
                                return option;
                            }

                            var value = $scope.$eval($scope.value || 'option', { option: option });
                            return angular.extend({}, option, {
                                value: value,
                                identifier: $scope.identifier && $scope.$eval($scope.identifier, { option: option }) || value
                            });
                        }

                        function _setRequireMatchValidation(isValid) {
                            if ($scope.requireMatch && _inputNgModelController) {
                                _inputNgModelController.$setValidity('spAutoCompleteRequireMatch', isValid);
                            }
                        }

                        function _isElementFixed(element) {
                            element = angular.element(element)[0];
                            var res = '';
                            if (window.getComputedStyle && angular.isFunction(window.getComputedStyle)) {
                                res = window.getComputedStyle(element).getPropertyValue('position');
                            } else if (element.currentStyle && element.currentStyle.position) {
                                res = element.currentStyle.position;
                            }
                            return res === 'fixed';
                        }

                        function _callSetIsOutOfScreen() {
                            $timeout(_setIsOutOfScreen, 0);
                            $timeout(_setIsOutOfScreen, 500);
                        }

                        function _setIsOutOfScreen() {
                            var currentElement = _parentElement[0],
                                top = 0;
                            do {
                                top += currentElement.offsetTop;
                                currentElement = currentElement.offsetParent;
                            } while (!!currentElement && !_isElementFixed(currentElement));

                            var bottom = top + _parentElement[0].offsetHeight + $element.prop('offsetHeight');

                            if (bottom > _body.offsetHeight) {
                                $element.addClass('out-of-screen');
                            } else {
                                $element.removeClass('out-of-screen');
                            }
                        }

                        function _apply() {
                            $scope.$applyAsync();
                        }

                        function _inputKeyDown(event) {
                            if (event.which === 13) {
                                event.preventDefault();
                                event.stopImmediatePropagation();
                                chooseOption(autocompleteAddressAndMapCtrl.currentIndex);
                                return _apply();
                            }

                            var toAdd = 0;
                            switch (event.which) {
                                case 40:
                                    toAdd = 1;
                                    break;
                                case 38:
                                    toAdd = -1;
                                    break;
                            }
                            if (toAdd) {
                                autocompleteAddressAndMapCtrl.currentIndex += toAdd;
                                if (autocompleteAddressAndMapCtrl.currentIndex < -1) {
                                    autocompleteAddressAndMapCtrl.currentIndex = autocompleteAddressAndMapCtrl.options.length - 1;
                                }
                                if (!autocompleteAddressAndMapCtrl.options || autocompleteAddressAndMapCtrl.currentIndex >= autocompleteAddressAndMapCtrl.options.length) {
                                    autocompleteAddressAndMapCtrl.currentIndex = -1;
                                }

                                _setInputAttr('aria-activedescendant', autocompleteAddressAndMapCtrl.elementId + '_option_' + autocompleteAddressAndMapCtrl.currentIndex);
                                event.preventDefault();
                                event.stopImmediatePropagation();
                                _apply();
                            }
                        }

                        function _inputFocus() {
                            autocompleteAddressAndMapCtrl.inFocus = (autocompleteAddressAndMapCtrl.inFocus || 0) + 1;
                            if (!autocompleteAddressAndMapCtrl.options || $scope.showOnFocus && !autocompleteAddressAndMapCtrl.options.length && $scope.model) {
                                _reloadOptions();
                            }
                            _apply();
                            _callSetIsOutOfScreen();
                        }

                        function _inputBlur() {
                            // ignore blur when no focus is active
                            if (!autocompleteAddressAndMapCtrl.inFocus || autocompleteAddressAndMapCtrl.inFocus < 0) {
                                return;
                            }

                            $timeout(function () {
                                if (autocompleteAddressAndMapCtrl.inFocus && autocompleteAddressAndMapCtrl.inFocus > 0) {
                                    autocompleteAddressAndMapCtrl.inFocus--;
                                }
                            }, 200);
                        }

                        function _bindInput() {
                            _inputElement.bind('keydown', _inputKeyDown);
                            _inputElement.bind('focus', _inputFocus);
                            _inputElement.bind('blur', _inputBlur);

                            angular.forEach(_inputAttrs, function (value, key) {
                                _setInputAttr(key, value);
                            });
                        }

                        function _setInputAttr(key, value) {
                            _inputElement && _inputElement[0].setAttribute(key, value);
                        }

                        function _setShown(value) {
                            var prevValue = autocompleteAddressAndMapCtrl.shown;
                            autocompleteAddressAndMapCtrl.shown = value;
                            _setInputAttr('aria-expanded', autocompleteAddressAndMapCtrl.shown);
                            if (autocompleteAddressAndMapCtrl.shown) {
                                _parentElement.addClass('auto-complete-options-shown');
                            } else {
                                _parentElement.removeClass('auto-complete-options-shown');
                            }

                            if (prevValue !== autocompleteAddressAndMapCtrl.shown) {
                                if (autocompleteAddressAndMapCtrl.shown && $scope.onShow) {
                                    $scope.onShow();
                                }
                                if (!autocompleteAddressAndMapCtrl.shown && $scope.onHide) {
                                    $scope.onHide();
                                }
                            }
                        }

                        function _onResize() {
                            _callSetIsOutOfScreen();
                        }

                        $scope.$watch('model', function (newVal) {
                            if (_chosenOption && _chosenOption.value === newVal) {
                                return;
                            }

                            _reloadOptions();
                        });

                        $scope.$watch('optionModel', function (newVal) {
                            if (_chosenOption !== newVal) {
                                _chosenOption = $scope.optionModel = _parseOption(newVal);
                                _setRequireMatchValidation(!$scope.model || !!_chosenOption);
                            }
                        });

                        $scope.$watch('reloadOptionsOn', function (newVal) {
                            _reloadListener && _reloadListener();

                            if (newVal) {
                                _reloadListener = $rootScope.$on(newVal, function () {
                                    _reloadOptions();
                                });
                            }
                        });

                        $scope.$watch('requireMatch', function () {
                            if (!$scope.requireMatch && _inputNgModelController) {
                                _inputNgModelController.$setValidity('spAutoCompleteRequireMatch', true);
                            }
                        });

                        $scope.$on('$destroy', function () {
                            _reloadListener && _reloadListener();
                        });

                        $scope.$watch(_showOptionsWatcher, function (newVal) {
                            _setShown(!!newVal);
                        });

                        util.destroyListeners($scope, function () {
                            _setShown(false);
                            angular.element(_inputElement).unbind('keydown', _inputKeyDown);
                            angular.element(_inputElement).unbind('focus', _inputFocus);
                            angular.element(_inputElement).unbind('blur', _inputBlur);
                            angular.element(window).unbind('resize', _onResize);
                        });
                    }
                ]
            };
        }]);
})(angular);
