(function(angular) {
    // deep-link-storage-explanation
    // cordova-plugin-customurlscheme fires the last event again when page is reloaded
    // thus when reloading, the first event should be ignored
    var DEEP_LINK_RELOAD_KEY = 'mobileZuzDeepLinkReload';

    var watcher = new DeepLinkWatcher();

    function DeepLinkWatcher() {
        if (window.handleOpenURL) {
            throw new Error('window.handleOpenURL is already implemented');
        }

        var self = this,
            _unhandledEvents = [],
            _listener;

        self.listen = listen;
        // cordova-plugin-customurlscheme plugin
        window.handleOpenURL = handleOpenURL;

        function listen(listener) {
            if (_listener) {
                throw new Error('Cannot listen more than once');
            }

            _listener = listener;
            angular.forEach(_unhandledEvents, function(url) {
                handleOpenURL(url);
            });
            _unhandledEvents = undefined;
        }

        function handleOpenURL(url) {
            if (!_listener) {
                _unhandledEvents.push(url);
            }

            _listener(url);
        }
    }


    angular.module('mobilezuz').service('DeepLinkHandler', [
        '$timeout', '$window', '$location', 'Config', 'CordovaService',
        function($timeout, $window, $location, Config, CordovaService) {
            var self = this,
                appScheme = _getAppScheme(),
                isInReloadState = !!$window.localStorage.getItem(DEEP_LINK_RELOAD_KEY); // search deep-link-storage-explanation

            self.init = init;
            self.setReloadState = setReloadState;

            function init() {
                // when the app has no url scheme, do nothing
                if (!appScheme) {
                    return;
                }

                watcher.listen(_onDeepLink);

                // clear the reload state
                // search deep-link-storage-explanation
                CordovaService.ready.then(function() {
                    $timeout(function() {
                        $window.localStorage.removeItem(DEEP_LINK_RELOAD_KEY);
                        isInReloadState = false;
                    }, 100);
                });
            }

            function setReloadState() {
                // when the app has no url scheme, do nothing
                if (!appScheme) {
                    return;
                }

                $window.localStorage.setItem(DEEP_LINK_RELOAD_KEY, '1');
            }

            function _onDeepLink(url) {
                // search deep-link-storage-explanation
                if (isInReloadState) {
                    return;
                }

                if (!url.startsWith(appScheme)) {
                    return;
                }

                // $timeout to enter angular digest
                $timeout(function() {
                    var innerUrl = url.replace(appScheme, '');
                    $location.url(innerUrl);
                }, 0)
            }

            function _getAppScheme() {
                if (!Config.appId) {
                    return;
                }

                return Config.appId.replace(/\./g, '').toLowerCase() + '://';
            }
        }
    ]);
})(angular);
