(function (angular, app) {
    "use strict";
    var TEMPLATE_ROUTE = "views/update-order-v2";

    /**
     * @typedef {Object} TabType
     * @property {string} name - The name of the tab
     * @property {string} templateUrl - The URL of the template for this tab
     */

    /** @typedef {'LAUNCH_EDIT' | 'TIMESLOT' | 'FINISH'} TabName - The name of the tab */

    var TABS = {
        LAUNCH_EDIT: {
            name: "LAUNCH_EDIT",
            templateUrl: TEMPLATE_ROUTE + "/launch-edit-order.html",
        },

        TIMESLOT: {
            name: "TIMESLOT",
            templateUrl: TEMPLATE_ROUTE + "/edit-time-slot.html",
        },

        IRRE_SALES: {
            name: "IRRE_SALES",
            templateUrl: TEMPLATE_ROUTE + "/irrelevant-sales.html",
        },

        FINISH: {
            name: "FINISH",
            templateUrl: TEMPLATE_ROUTE + "/finish.html",
        },
    };

    /**
     * Config
     **/
    function config(
        SpDialogUrlManagerProvider,
        SP_URL_DIALOG_QUERY_PARAMS,
        SP_URL_DIALOG_DATA_QUERY_PARAMS
    ) {
        SpDialogUrlManagerProvider.dialog({
            queryParams: SP_URL_DIALOG_QUERY_PARAMS.UPDATE_ORDER_V2,
            dataQueryParams: SP_URL_DIALOG_DATA_QUERY_PARAMS.UPDATE_ORDER_V2,
            canShow: [
                "User",
                function (User) {
                    // check can show edit flow order
                    return User.getUserLoginData();
                },
            ],
            show: [
                "mDesign", "SpDialogUrlManager", "Config", "value", "params",
                function (mDesign, SpDialogUrlManager, Config, value, params) {
                    var templateUrl = "views/update-order-v2/index.html";
                    var styleClass = "update-order-v2";
                    var config = {
                        templateUrl: templateUrl,
                        controller: "UpdateOrderV2Ctrl as updateOrderV2Ctrl",
                        styleClass: styleClass,
                        disableClosing: false,
                        ariaLabelledby: "update-order-title",
                        hide: SpDialogUrlManager.backClose,
                        clickOutsideToClose: false,
                        resolve: {
                            step: function () { return params.step },
                            orderId: function () { return params.orderId },
                            isCancelOnClose: function () { return params.isCancelOnClose }
                        }

                    }
                    return mDesign.dialog(config);
                }
            ]
        })
    }

    /**
     * Controller 
     **/
    function ctrl($rootScope, $scope, SpDialogUrlManager, Orders, SP_SERVICES, Cart, Util,
        UpdateOrderV2Service, Config, step, orderId, isCancelOnClose) {

        var updateOrderV2Ctrl = this;
        isCancelOnClose = (String(isCancelOnClose).toLowerCase() == 'true'); // parse to boolean

        // properties
        updateOrderV2Ctrl.tabsIndex = TABS
        updateOrderV2Ctrl.orderId = orderId;
        updateOrderV2Ctrl.isCancelOnClose = isCancelOnClose;
        updateOrderV2Ctrl.delivery = {};
        updateOrderV2Ctrl.area = {};
        updateOrderV2Ctrl.loading = false;
        updateOrderV2Ctrl.isChangeDeliveryTime = false;
        /** @type {IrreSalesDataType} */
        updateOrderV2Ctrl.irreSalesData = Cart.irreSalesData || {
            lineTypes: null,
            expiredInfo: [],
            irrelevantSalesLines: [],
            skipDialogSpecial: false,
            outOfStockLines: []
        }

        updateOrderV2Ctrl.waitForSaveCart = false;

        // function
        updateOrderV2Ctrl.next = next;
        updateOrderV2Ctrl.close = close;
        updateOrderV2Ctrl.back = back;
        updateOrderV2Ctrl.updateOrder = updateOrder;
        updateOrderV2Ctrl.goToHomePage = goToHomePage;
        updateOrderV2Ctrl.enableForceShowLoadingDialog = enableForceShowLoadingDialog;
        updateOrderV2Ctrl.disableForceShowLoadingDialog = disableForceShowLoadingDialog;

        // ----------------------------------------------------
        _init();

        function _init() {
            _setInitTab();
        }

        function _setInitTab() {
            var firstStep = TABS[step] || TABS.LAUNCH_EDIT;

            if (firstStep.name === TABS.IRRE_SALES.name && !updateOrderV2Ctrl.irreSalesData.lineTypes) {
                close();
            }

            setCurrentTab(firstStep.name);
        }

        function setCurrentTab(tabName) {
            if (!tabName) {
                return;
            }

            var cStep = TABS[tabName.toUpperCase()];
            if (!cStep) {
                return;
            }

            updateOrderV2Ctrl.previousTab = updateOrderV2Ctrl.currentTab || null;
            updateOrderV2Ctrl.currentTab = TABS[tabName];
        }

        function next() {
            switch (updateOrderV2Ctrl.currentTab.name) {
                case TABS.LAUNCH_EDIT.name:
                    updateOrderV2Ctrl.isChangeDeliveryTime = true;
                    goToEditTimeSlot();
                    break;

                case TABS.TIMESLOT.name:
                    checkUpdateOrder();
                    break;
            }
        }

        function back() {
            if (updateOrderV2Ctrl.currentTab.name) {
                return setCurrentTab(TABS.TIMESLOT.name);
            }
        }

        function goToEditTimeSlot() {
            Orders.enableEditOrder(updateOrderV2Ctrl.orderId).then(function () {
                SpDialogUrlManager.setDialogParams({
                    updateOrderV2: '1',
                    step: TABS.TIMESLOT.name,
                    orderId: orderId,
                    isCancelOnClose: isCancelOnClose
                });
                setCurrentTab(TABS.TIMESLOT.name);
            });
        }

        /**
         * @param {Object} options
         * @param {boolean} options.shouldKeepEditMode
         * @returns 
         */
        function close(options) {
            Cart.setIrreSalesData(null);

            options = options || {};

            var isKeepEditMode =
                typeof options.shouldKeepEditMode === "boolean"
                    ? options.shouldKeepEditMode
                    : !isCancelOnClose;

            if (isKeepEditMode) {
                return SpDialogUrlManager.backClose();
            }

            Orders.cancelEdit()
                .then(function () {
                    Cart.clear();
                })
                .catch(function () {
                    Orders.orderInEdit = null;
                    Cart.clear(true);
                });

            return SpDialogUrlManager.backClose();
        }

        /**
         * @return {Promise<any>}
         */
        function checkUpdateOrder() {
          updateOrderV2Ctrl.loading = true;
          addDeliveryProduct();

          if (updateOrderV2Ctrl.waitForSaveCart) {
            var removeCartUpdateListener = $rootScope.$on("cart.update.complete", function () {
              removeCartUpdateListener();
              handleCheckUpdateOrder();
            });
          } else {
            handleCheckUpdateOrder();
          }
        }

        /**
		 * @return {Promise<void>}
		 */
		function handleCheckUpdateOrder() {
            var curOrder = {
                id: Orders.orderInEdit.id,
                timePlaced: Orders.orderInEdit.timePlaced,
              };

			return Cart.checkIrrelevantSales(updateOrderV2Ctrl.delivery.time, curOrder).then(function (irrelevantSales) {
                if (!irrelevantSales) {
                    return updateOrder();
                }

                updateOrderV2Ctrl.loading = false;
                updateOrderV2Ctrl.irreSalesData = irrelevantSales;
                setCurrentTab(TABS.IRRE_SALES.name);
            });
		}

        /**
         * @returns {Promise<void>}
         */
        function updateOrder() {
            if (
                updateOrderV2Ctrl.delivery &&
                updateOrderV2Ctrl.delivery.time &&
                updateOrderV2Ctrl.delivery.time.id
            ) {
                var timeSlotData = JSON.stringify({
                    newAreaDeliveryTimeId: updateOrderV2Ctrl.delivery.time.id,
                    newDate: _resetUTCDate(updateOrderV2Ctrl.delivery.time.newFrom),
                    shippingTimeTo: updateOrderV2Ctrl.delivery.time.newTo,
					shippingTimeFrom: updateOrderV2Ctrl.delivery.time.newFrom,
					shippingTimeFromDisplay: updateOrderV2Ctrl.delivery.time.newFromDisplay
                });

                localStorage.setItem(
                    "updateTimeSlot-orderId-" + Orders.orderInEdit.id + "-cartId-" + Cart.serverCartId,
                    timeSlotData
                );
            }

            var editOrderOptions = {
                editTimeSlot: true,
                skipV1IrrelevantSales: true,
                isChangeDeliveryTime:updateOrderV2Ctrl.isChangeDeliveryTime
            }
            
            updateOrderV2Ctrl.loading = true;
            return UpdateOrderV2Service.editOrder(editOrderOptions);
            // wait for cvv popup, additional payment, ebt,.. -> keep edit on until all finished
            // close({ shouldKeepEditMode: true });
        }

        /**
         * Remove old delivery and service fee lines from cart and add new delivery and service fee lines if applicable.
         * @private
         */
        function addDeliveryProduct() {
            // remove old delivery & service fee lines
            angular.forEach(Cart.lines, function (line) {
                if (line.type == SP_SERVICES.CART_LINE_TYPES.DELIVERY || line.type == SP_SERVICES.CART_LINE_TYPES.SERVICE_FEE) {
                    updateOrderV2Ctrl.waitForSaveCart = true;
                    Cart.removeLine(line, true);
                }
            });

            // handle service fee
            if (Config.retailer.serviceFeeProduct) {
                updateOrderV2Ctrl.waitForSaveCart = true;
                Cart.addLine({
                    quantity: 1,
                    type: SP_SERVICES.CART_LINE_TYPES.SERVICE_FEE,
                    product: {
                        id: Config.retailer.serviceFeeProduct.id
                    }
                });
            }

            // handle delivery
            var deliveryProductLine = _getDeliveryProductLine();
            if (deliveryProductLine) {
                updateOrderV2Ctrl.waitForSaveCart = true;
                Cart.addLine(deliveryProductLine);
            }
        }

        /**
        * get the delivery product line based on if delivery time slot has it , else do default
        * @returns {object}
        * @private
        */
        function _getDeliveryProductLine() {
            var deliveryProductId = 0;
            if (updateOrderV2Ctrl.delivery && updateOrderV2Ctrl.delivery.time && updateOrderV2Ctrl.delivery.time.deliveryTimeProductId) {
                deliveryProductId = updateOrderV2Ctrl.delivery.time.deliveryTimeProductId;
            }

            if (!deliveryProductId) {
                deliveryProductId = updateOrderV2Ctrl.delivery.area && updateOrderV2Ctrl.delivery.area.deliveryProductId;
            }

            if (!deliveryProductId) return;

            return {
                quantity: 1,
                deliveryProduct: true,
                type: SP_SERVICES.CART_LINE_TYPES.DELIVERY,
                product: {
                    id: deliveryProductId
                }
            };
        }

        /**
         * Reset the given Date object to midnight of the same day in UTC timezone
         * @param {Date} date - the Date object to reset
         * @returns {Date} - the reset Date object
         * @private
         */
        function _resetUTCDate(date) {
            date = new Date(date);
            date.setUTCHours(0, 0, 0, 0);
            return date;
        }

        function goToHomePage() {
            UpdateOrderV2Service.goToHomePage();
        }

        function goToTab(tabName) {
            if (!tabName) {
                throw new Error("Tab Name invalid", tabName);
            }

            Util.openEditOrderDialog(tabName, isCancelOnClose, orderId);
            setCurrentTab(tabName);
        }

        function enableForceShowLoadingDialog() {
            var loadingElement = angular.element(document.querySelector('.loader'));
            if (loadingElement && !loadingElement.hasClass('force-show')) {
                loadingElement.addClass('force-show');
            }
        }

        function disableForceShowLoadingDialog() {
            var loadingElement = angular.element(document.querySelector('.loader'));
            if (loadingElement && loadingElement.hasClass('force-show')) {
                loadingElement.removeClass('force-show');
            }
        }

        Util.currentScopeListener($scope, $rootScope.$on('order.update.timeslot.close', function (event, data) {
            updateOrderV2Ctrl.loading = false;
            close({ shouldKeepEditMode: true });
        }));

        Util.currentScopeListener($scope, $rootScope.$on('order.update.timeslot.finish', function (event, data) {
            updateOrderV2Ctrl.loading = false;
            goToTab(TABS.FINISH.name);
        }));

        Util.currentScopeListener($scope, $rootScope.$on('order.update.timeslot.stop-loading', function (event, data) {
            updateOrderV2Ctrl.loading = false;
        }));
    }
    angular.module('mobilezuz')
        .config([
            "SpDialogUrlManagerProvider",
            "SP_URL_DIALOG_QUERY_PARAMS",
            "SP_URL_DIALOG_DATA_QUERY_PARAMS",
            config
        ])
        .controller("UpdateOrderV2Ctrl", ['$rootScope', '$scope', 'SpDialogUrlManager', 'Orders', 'SP_SERVICES', 'Cart', 'Util',
            'UpdateOrderV2Service', 'Config', 'step', 'orderId', 'isCancelOnClose',
            ctrl
        ]);

})(angular);