(function (angular) {
    angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
        $stateProvider.state('app.myCoupons', {
            url: '/coupons?{filters:json}',
            data: {
                stateAccess: PAGE_ACCESS.LOGGED_IN,
                bodyClass: 'sales',
                metaTags: {
                    title: 'My coupons'
                }
            },
            resolve: {
                CouponsData: ['Api', function (api) {
                    return api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/branches/:bid/users/:uid/coupons',
                        params: { extended: true, countonly: false }
                    });
                }]
            },
            views: {
                '@': {
                    templateUrl: 'views/my-coupons.html',
                    controller: 'MyCouponsCtrl as myCoupons'
                }
            }
        });
    }]).run(['$rootScope', 'Cart', 'CouponDetailsDialog', 'SP_SERVICES', function ($rootScope, Cart, CouponDetailsDialog, SP_SERVICES) {
        $rootScope.$on('cart.update.complete', function (event, requestDirtyLines) {
            if (Cart.specialReminders.onCoupons && !angular.element(document.getElementsByClassName('coupon-details-dialog')[0]).length) {
                angular.forEach(Object.keys(requestDirtyLines), function (lineKey) {
                    var line = Cart.lines[lineKey];
                    if (!line || line.removed || line.type !== SP_SERVICES.CART_LINE_TYPES.COUPON) {
                        return;
                    }

                    var specialId = line.product && (
                            (line.product.special && line.product.special.id) || // from my-coupons page
                            (line.product.branch && line.product.branch.specials && line.product.branch.specials[0].id)
                        ),
                        specialReminder = Cart.specialReminders.onCoupons[specialId];
                    if (specialId && specialReminder && specialReminder.showProductsFrom && !specialReminder.awarded) {
                        CouponDetailsDialog.show(line.product);
                    }
                });
            }
        });
    }]).controller('MyCouponsCtrl', ['$scope', '$rootScope', '$stateParams', 'CouponsData', 'FiltersManager', 'CouponDetailsDialog', 'Api', 'Util', 'Cart', 'Config', 
            function ($scope, $rootScope, $stateParams, CouponsData, FiltersManager, CouponDetailsDialog, Api, Util, Cart, Config) {
                var myCoupons = this;

        if (CouponsData) {
            var totalClipped = 0;

            if (Cart.specialReminders.onCoupons) {
                var cartCoupons = Cart.specialReminders.onCoupons;
                for (var coupon in cartCoupons) {
                    if (!cartCoupons[coupon].awarded) {
                        totalClipped++;
                    }
                }
                if (!totalClipped) {
                    if (CouponsData.tags && CouponsData.tags.length > 0) {
                        CouponsData.tags = CouponsData.tags.filter(function (tag) {
                            return tag.id !== "5";
                        })
                    }
                } else {
                    if (CouponsData.tags && CouponsData.tags.length > 0) {
                        CouponsData.tags.forEach(function (tag) {
                            if (tag.id === "5") {
                                tag.count = totalClipped
                            }
                        })
                    }
                }
            } else {
                if (CouponsData.tags && CouponsData.tags.length > 0) {
                    CouponsData.tags = CouponsData.tags.filter(function (tag) {
                        return tag.id !== "5";
                    })
                }
            }
        }
        myCoupons.sideMenuFilters = [
            FiltersManager.getTypesFilter(Promise.resolve(CouponsData), 'type.id'),
            FiltersManager.getCategoriesFilter(Promise.resolve(CouponsData), 'category.id')
        ];
        
        myCoupons.sideMenuFilters[0].notInclusive = true;
        myCoupons.sideMenuFilters[1].notInclusive = true;
        myCoupons.isClickedSearchBtn = false;

        FiltersManager.setFiltersFromUrl(myCoupons.sideMenuFilters, $stateParams.filters).then(function () {
            _getCouponsData();
        });        

        $scope.goToCoupon = function(coupon, terms) {
            CouponDetailsDialog.show(coupon, terms);
        }

        myCoupons.onFiltersChange = function() {
            FiltersManager.setFiltersToUrl(myCoupons.sideMenuFilters);
            _getCouponsData();
        }
                
        myCoupons.addCouponToCart = function(couponCode, source) {
            Cart.addCoupon(couponCode, source).then(function() {
                myCoupons.couponCode = "";
            });
        }

        myCoupons.clearSearch = function() {
            myCoupons.couponFilter = "";
            if(myCoupons.isClickedSearchBtn) {
                myCoupons.isClickedSearchBtn = false;
                _getCouponsData(true);
            }
        }

        myCoupons.searchCoupon = function(couponFilter) {
            myCoupons.couponFilter = couponFilter;
            myCoupons.isClickedSearchBtn = true;
            _getCouponsData(true);
        }

        myCoupons.onSearchInputChange = function() {
            if(!myCoupons.isClickedSearchBtn) {
                // nothing searched yet - nothing to clear
                return;
            }
            if(myCoupons.couponFilter) {
                // if text is filled - user need to press Search button
                return;
            }
            myCoupons.isClickedSearchBtn = false;
            _getCouponsData(true);
        }

        Util.currentScopeListener($scope, $rootScope.$on('userBalancePoints', function (event, points) {
            myCoupons.points = points;
        }));

        Util.currentScopeListener($scope, $rootScope.$on('reloadUserBalancePoints', function () {
            _getCouponsDataPointsOnly().then(function(data) {
                myCoupons.points = data.points;

                _resetFilters(data);
            });
        }));

        Util.currentScopeListener($scope, $rootScope.$on('reloadCoupons', function (event, ev1) {
            _resetFilters(CouponsData);
        }));
                
        $rootScope.$on('cart.lines.add', function (event, ev1) {
            if (ev1 && ev1.lines && ev1.lines.length > 0 && ev1.lines[0].type == 3) {
                $rootScope.couponReceived = true;
            }
        });

        $rootScope.$on('cart.lines.remove', function (event, ev1) {
            if (ev1 && ev1.lines && ev1.lines.length > 0 && ev1.lines[0].type == 3) {
                $rootScope.couponReceived = true;
            }
        });

        $rootScope.$on('cart.update.complete', function (event, ev1) {
            if ($rootScope.couponReceived) {
                $rootScope.$emit('reloadUserBalancePoints');
            }
            $rootScope.couponReceived = false;
        });
                
        $rootScope.$on('cart.update.complete', function (event, ev1) {
            if (CouponsData) {
                var couponTagType = "Clipped";
                var coupons = CouponsData.coupons;
                if (Cart.specialReminders.onCoupons) {
                    var cartCoupons = Object.values(Cart.specialReminders.onCoupons),
                        cartCouponLocalIds = {};
                    for (var i = 0; i < cartCoupons.length; i++) { 
                        if (!cartCoupons[i].awarded) { 
                            cartCouponLocalIds[cartCoupons[i].localId] = {awarded: cartCoupons[i].awarded}
                        }

                    }
                    for (var cindex = 0; cindex < coupons.length; cindex++ ) {
                        var foundInAwarded = false;
                        if (cartCouponLocalIds[coupons[cindex].special.localId]) {
                            foundInAwarded = true;
                        }
                        var tags = coupons[cindex].tags || [];
                        if (foundInAwarded) {
                            if (!tags.includes(couponTagType)) {
                                tags.push(couponTagType);
                            }
                        } else {
                            if (tags.includes(couponTagType)) {
                                tags.splice(tags.indexOf(couponTagType), 1);
                            }
                        }
                    }
                } else {
                    // No coupons in specialReminders, clear 'Clipped' tag from all
                    for (var cindex = 0; cindex < coupons.length; cindex++ ) {
                        var tags = coupons[cindex].tags || [];
                        if (tags.includes(couponTagType)) {
                            tags.splice(tags.indexOf(couponTagType), 1);
                        }
                    }
                    if (CouponsData.tags && CouponsData.tags.length > 0) {
                        CouponsData.tags = CouponsData.tags.filter(function (couponTag) {
                            return couponTag.id !== "5";
                        })
                    }
                }

                var totalClipped = 0;
                for (var cindex = 0; cindex < coupons.length; cindex++ ) {
                    var tags = coupons[cindex].tags || [];
                    if (tags.includes(couponTagType)) {
                        totalClipped++;
                    }
                }

                var items = myCoupons.sideMenuFilters[0].items;
                if (totalClipped > 0) {
                    var found = false;
                    for (var index = 0; index < items.length; index++) {
                        if (items[index].value == "5") {
                            CouponsData.tags.forEach(function (tag) {
                                if (tag.id === '5') {
                                    tag.count = totalClipped;
                                }
                            })
                            items[index].count = totalClipped;
                            found = true;
                        }
                    }
                    if (!found) {
                        var names = {};
                        var name = 'Clipped but not in cart';
                        angular.forEach(Object.values(Config.languages), function (language) {
                            names[language.id] = language.translations[name.toLowerCase()] || name;
                        });
                        var newItem = {
                            value: "5",
                            names: names,
                            title: "names | name",
                            count: totalClipped
                        };
                        items.push(newItem);
                        CouponsData.tags.push({ id: "5", count: totalClipped, name: "Clipped but not in cart" })
                        $rootScope.$emit('reloadCoupons');
                    }
                } else {
                    // Remove it
                    var foundInFilters = false;
                    for (var index = 0; index < items.length; index++) {
                        if (items[index].value == "5") {
                            items.splice(index, 1);
                            foundInFilters = true;
                        }
                    }

                    if (CouponsData.tags && CouponsData.tags.length > 0) {
                        CouponsData.tags = CouponsData.tags.filter(function (tag) {
                            return tag.id !== "5";
                        })
                        foundInFilters = true;
                    }
                    if (foundInFilters) {
                        $rootScope.$emit('reloadCoupons');
                    }
                }
            }
        });
                
        function _getCouponsData(fireEvent) {
            $scope.params = { extended: true, countonly: false, searchFilter: myCoupons.couponFilter };
            angular.forEach(myCoupons.sideMenuFilters, function(filter) {
                filter.parse($scope.params);
            });

            myCoupons.loadingCoupons = true;
            Api.request({
                method: 'GET',
                url: '/v2/retailers/:rid/branches/:bid/users/:uid/coupons',
                params: $scope.params
            }).then(function (couponsData) {
                myCoupons.coupons = couponsData.coupons;
                myCoupons.loadingCoupons = false;

                Util.setGlobalCouponRedemptions(couponsData.coupons);

                if (!myCoupons.couponFilter || myCoupons.couponFilter === "") {
                    $rootScope.$emit('numberOfCouponsChanged', couponsData.totalCoupons);
                }
                myCoupons.points = couponsData.points;

                if (fireEvent) {
                    myCoupons.sideMenuFilters[0].items = FiltersManager.getTypesFilterPlain(couponsData);
                    myCoupons.sideMenuFilters[1].items = FiltersManager.getCategoriesFilterPlain(couponsData);

                    _resetFilters(couponsData);
                }
            });
        }

        function _getCouponsDataPointsOnly() {
            return Api.request({
                method: 'GET',
                url: '/v2/retailers/:rid/branches/:bid/users/:uid/coupons',
                params: { extended: true, countonly: false }
            }).then(function (data) {
                return data;
            });
        }

        function _resetFilters(data) {
            var removedTypeFilterIds = myCoupons.sideMenuFilters[0].items.filter(function(item) {
                return !data.tags.map(function(tag) {
                    return tag.id;
                }).includes(item.value);
            }).map(function(item) {
                return item.value;
            });
            if (removedTypeFilterIds && removedTypeFilterIds.length) {
                removedTypeFilterIds.forEach(function(id) {
                    myCoupons.sideMenuFilters[0].items.splice(myCoupons.sideMenuFilters[0].items.findIndex(function(item) {
                        return item.value === id;
                    }), 1);
                    if (myCoupons.sideMenuFilters[0].selectedItems) {
                        myCoupons.sideMenuFilters[0].selectedItems.splice(myCoupons.sideMenuFilters[0].items.findIndex(function(item) {
                            return item.value === id;
                        }), 1);
                    }
                    if (!myCoupons.sideMenuFilters[0].selectedItems) {
                        delete myCoupons.sideMenuFilters[0].selectedItems;
                    }
                });
            } else {
                myCoupons.sideMenuFilters[0].items.forEach(function(item) {
                    item.count = data.tags.find(function(tag) {
                        return tag.id === item.value;
                    }).count;
                });
            }
            myCoupons.onFiltersChange();
        }
    }]);
})(angular);
