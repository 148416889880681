angular.module('mobilezuz').factory('BranchDistance', [
	'$rootScope', '$q', '$filter', 'spGoogleMapsUtil', '$timeout', 'DialogAvailability',
	function($rootScope, $q, $filter, spGoogleMapsUtil, $timeout, DialogAvailability) {
		var calculator = {},
			branchesLocations = [],
			lastPosition = {},
			calculationsInProgress = false;

		$rootScope.branchesDistances = {};

		calculator.getLocationByAddress = function(addr) {
			return spGoogleMapsUtil.positionByAddress(addr).then(function(location) {
				return {
					latitude: location.lat,
					longitude: location.lng
				};
			});
		};

		calculator.getBranchLocation = function(branch) {
			if (branch.lat && branch.lon) {
				return $q.resolve({latitude: branch.lat, longitude: branch.lon});
			} else {
				var stringBuilder = [];
				if (branch.location) {
					stringBuilder.push(branch.location);
				}
				if (branch.city) {
					stringBuilder.push(branch.city);
				}
				return calculator.getLocationByAddress(stringBuilder.join(', '));
			}
		};


		/**
		 * Show branch distance from location
		 * @public
		 *
		 * @param {Array<object>} branches
		 *
		 * @returns {!ManagedPromise<T>}
		 */
		calculator.calcBranchesDistance = function(branches) {
			var defer = $q.defer();

			//== check if there is at least one branch
			if (!branches || branches.length < 1) {
				defer.reject($filter('translate')('No branch'));
			}

			//== get our current position
			DialogAvailability.requestDialog(DialogAvailability.PERMISSIONS.LOCATION)
				.then(function() {
					navigator.geolocation.getCurrentPosition(function(currentPosition) {

						//== if there is a calculations job in progress
						if (calculationsInProgress) {
							return defer.resolve();
						}

						//== if our device position found and there is no calculations job in progress
						if (currentPosition) {

							//== if position doesn't changed from the last time we checked there is no reason to calculate again
							if (lastPosition.coords && currentPosition.coords.latitude == lastPosition.coords.latitude) {
								return defer.resolve();
							}

							//== Save current location
							lastPosition = currentPosition;

							calculationsInProgress = true;
							var promises = [];

							//== creating promises array - each promise fill a single branch location
							branches.forEach(function(branch) {
								promises.push(calculator.getSingleBranchLocation(branch).then(function(branchLocation) {
									branchesLocations.push({
										branch: branch,
										location: branchLocation
									});
								}));
							});

							//== run all promises and after all done we need to start calculate distances
							$q.all(promises).then(function() {
								calculator.calcDistanceByBranchesArray(currentPosition);
							});

							defer.resolve();
						} else {
							defer.reject($filter('translate')('Could not find your location'));
						}

					}, function() {
						defer.reject($filter('translate')('Could not find your location'));
					});
				});
		};


		/**
		 * Get single branch location by it's address
		 * @public
		 *
		 * @param {object} branch
		 *
		 * @returns {!ManagedPromise<T>}
		 */
		calculator.getSingleBranchLocation = function(branch) {
			var defer = $q.defer();

			calculator.getBranchLocation(branch).then(function(branchLocation) {
				defer.resolve(branchLocation);
			});

			return defer.promise;
		};


		/**
		 * Calculate distance for each brunch in the list of branches
		 * @public
		 *
		 * @param {object} currentPosition
		 *
		 */
		calculator.calcDistanceByBranchesArray = function(currentPosition) {

			if (!branchesLocations.length) {
				calculationsInProgress = false;
				return;
			}

			//== get the first branch it the list
			var branchLocation = branchesLocations[0];

			//== this will calculate distance to this branch, on success we remove this branch from the list
			return calculator.calcSingleBranchDistance(branchLocation, currentPosition).then(function() {
				branchesLocations.splice(0, 1);
				calculator.calcDistanceByBranchesArray(currentPosition);
			}).catch(function(errStatus) {
				//== if we got query limitation response - we starting timer that will continue calculations
				if (errStatus == 'OVER_QUERY_LIMIT') {
					$timeout(function() {
						calculator.calcDistanceByBranchesArray(currentPosition);
					}, 600);
				}
			});
		};


		/**
		 * calculate distance from branch's location to our current position
		 * @public
		 *
		 * @param {object} branchLocation
		 * @param {object} currentPosition
		 *
		 * @returns {Promise<*>}
		 */
		calculator.calcSingleBranchDistance = function(branchLocation, currentPosition) {
			return _getGoogle().then(function(google) {
				var defer = $q.defer(),
					request = {
						origin: new google.maps.LatLng(currentPosition.coords.latitude, currentPosition.coords.longitude),
						destination: new google.maps.LatLng(branchLocation.location.latitude, branchLocation.location.longitude),
						travelMode: google.maps.TravelMode.DRIVING
					},
					directionsService = new google.maps.DirectionsService();

				directionsService.route(request, function(directionsResponse, status) {
					if (status == google.maps.DirectionsStatus.OK) {
						calculator.convertRouteToDistance(branchLocation, directionsResponse);
						defer.resolve();
					} else {
						defer.reject(status);
					}
				});

				return defer.promise;
			});
		};

		/**
		 * Insert distance from branch's location to our current position text to branch object
		 * @public
		 *
		 * @param {object} branchLocation
		 * @param {object} directionsResponse
		 */
		calculator.convertRouteToDistance = function(branchLocation, directionsResponse) {

			//== get the first route from Google Directions Service response
			var route = directionsResponse && directionsResponse.routes && directionsResponse.routes[0] ? directionsResponse.routes[0] : null;

			if (!route || !route.legs || !route.legs[0] || !route.legs[0].distance || !route.legs[0].distance.text) {
				return '';
			}

			//== branchLocation is an initial object so this will show the distance and text in HTML
			var split = route.legs[0].distance.text.split(' ');
			branchLocation.branch.distanceText = split[1];
			branchLocation.branch.distanceNum = split[0];
			branchLocation.branch.distanceValue = route.legs[0].distance.value;
		};

		function _getGoogle(index) {
			index = index || 0;

			if (google) {
				return $q.resolve(google);
			}

			if (index >= 3) {
				return $q.reject('Google wasn\'t found');
			}

			return $timeout(function() {
				return _getGoogle(index + 1);
			}, 300);
		}

		return calculator;
	}
]);