(function(angular) {
	angular.module('mobilezuz').service('ArLanguage', function() {
		this.id = 6;
		this.culture = 'ar';
		this.name = 'العربية';
		this.direction = 'rtl';
		this.translations = {
			'en': 'ar',
			'my': 'الخاص بي',
			'me': 'أنا',
			'in {name} dep.': 'في القسم {name}',
			'in specials': 'في العروض الخاصة',
			'special products': 'منتجات خاصة',
			'shopping lists': 'قوائم التسوق',
			'shopping list': 'قائمة التسوق',
			'shop': 'تسوق',
			'shop ': 'تسوق',
			'by departments': 'حسب الأقسام',
			'email': 'البريد الإلكتروني',
			'email_error': 'يُرجى إدخال عنوان بريد إلكتروني صالح',
			'password': 'كلمة المرور',
			'log in': 'تسجيل الدخول',
			'log in with facebook': 'تسجيل الدخول بواسطة الفيسبوك',
			'password recovery message was sent to your email': 'تم إرسال رسالة استرداد كلمة المرور إلى بريدك الإلكتروني',
			'home': 'الصفحة الرئيسية',
			'departments': 'الأقسام',
			'scanner': 'ماسح ضوئي',
			'all products': 'جميع المنتجات',
			'orders': 'الطلبات',
			'sales': 'المبيعات',
			'specials': 'العروض الخاصة',
			'sale': 'تخفيض',
			'edit': 'تحرير',
			'search': 'بحث',
			'total': 'الإجمالي',
			'checkout': 'إنهاء الشراء',
			'forgot your password?': 'هل نسيت كلمة مرورك',
			'my shopping lists': 'قوائم التسوق الخاصة بي',
			'remove': 'إزالة',
			'add list': 'إضافة قائمة',
			'name': 'الاسم',
			'creationdate': 'تاريخ الإنشاء',
			'itemscount': 'عدد العناصر',
			'shopping list name': 'اسم قائمة التسوق',
			'sorting by': 'فرز حسب',
			'add': 'إضافة',
			'add_product': 'إضافة',
			'close': 'إغلاق',
			'clean cart': 'تنظيف العربة',
			'cart': 'العربة',
			'recipient\'s name': 'اسم المستلم',
			'email address': 'عنوان البريد الإلكتروني',
			'the offer is valid from': 'العرض صالح من',
			'until': 'حتى',
			'terms and conditions': 'الشروط والأحكام',
			'this purchase order is an offer by the company identified on the face of this purchase order (the \"buyer\") for the purchase of the goods (the \"goods or services (the “services”) specified\")': 'طلب الشراء هذا هو عرض من الشركة موضح على الجزء الأمامي من الطلب (\"المشتري"\) لشراء البضائع (\"البضائع أو الخدمات  ("الخدمات") المحددة\"',
			'pay with': 'دفع بواسطة',
			'card number': 'رقم البطاقة',
			'delivery address': 'عنوان التوصيل',
			'add card number': 'إضافة رقم البطاقة',
			'country': 'البلد',
			'zip code': 'الرمز البريدي',
			'zipcode': 'الرمز البريدي',
			'entrance': 'المدخل',
			'city': 'المدينة',
			'street': 'الشارع',
			'house number': 'رقم المنزل',
			'floor': 'الطابق',
			'apartment': 'الشقة',
			'available days': 'الأيام المتوفرة',
			'entry: spautocompleterequirematch error': 'مدخل غير صالح',
			'house_entry_none': 'بدون',
			'house_entry_a': 'أ',
			'house_entry_b': 'ب',
			'house_entry_c': 'ت',
			'house_entry_d': 'ث',
			'house_entry_e': 'ج',
			'house_entry_f': 'ح',
			'house_entry_g': 'خ',
			'house_entry_h': 'د',
			'house_entry_i': 'ذ',
			'house_entry_j': 'ر',
			'personal info': 'المعلومات الشخصية',
			'first name': 'الاسم الأول',
			'last name': 'اسم العائلة',
			'full name': 'الاسم الكامل',
			'phone number': 'رقم الهاتف',
			'delivery time': 'وقت التسليم',
			'day': 'يوم',
			'time': 'الوقت',
			'notes': 'ملاحظات',
			'add instructions': 'إضافة تعليمات',
			'summary': 'الملخص',
			'products': 'المنتجات',
			'delivery charge': 'رسوم التوصيل',
			'ok': 'موافق',
			'cancel': 'إلغاء',
			'recent': 'الأخيرة',
			'purchases': 'المشتريات',
			'please enter street': 'يُرجى إدخال الشارع',
			'please enter email': 'يُرجى إدخال البريد الإلكتروني',
			'please enter password': 'يُرجى إدخال كلمة المرور',
			'please enter first name': 'يُرجى إدخال الاسم الأول',
			'please enter last name': 'يُرجى إدخال اسم العائلة',
			'please enter your email address': 'يُرجى إدخال عنوان بريدك الإلكتروني',
			'the shopping list was successfully added to the cart': 'تمت إضافة قائمة التسوق إلى العربة بنجاح',
			'callcustomer': 'اتصل بي',
			'creditcard': 'بطاقة ائتمان',
			'storecredit': 'ائتمان على الملف',
			'between': 'بين',
			'sunday': 'الأحد',
			'monday': 'الاثنين',
			'tuesday': 'الثلاثاء',
			'wednesday': 'الأربعاء',
			'thursday': 'الخميس',
			'friday': 'الجمعة',
			'saturday': 'السبت',
			'today': 'اليوم',
			'your order has been received': 'تم استلام طلبك',
			//'your order is in process and will be sent to you in the next hours. order id': 'جارٍ معالجة طلبك وسيتم إرساله لك في الساعات القادمة. معرف الطلب',
			'register': 'تسجيل',
			'creation date': 'تاريخ الإنشاء',
			'items count': 'عدد العناصر',
			'shop by': 'تسوق بواسطة',
			'my orders history': 'تاريخ طلباتي',
			'orders history': 'تاريخ الطلبات',
			'previous orders': 'الطلبات السابقة',
			'total (estimated)': 'المجموع (تقديري)',
			'call customer': 'اتصل بالزبون',
			'order time': 'وقت الطلب',
			'add to shopping list': 'أضف إلى قائمة التسوق',
			'received_items': 'تم استلامه',
			'replaced with':'تم استبداله ب',
			'new product': 'منتج جديد',
			'credit card charge' : 'رسم بطاقة الائتمان',
			'view details' : 'عرض التفاصيل',
			'order details' : 'تفاصيل الطلب',
			'view more': 'عرض المزيد',
			'view less' : 'عرض أقل',
			'credit card': 'بطاقة الائتمان',
			'payment information': 'معلومات الدفع',
			'payment method ending with' : 'طريقة الدفع تنتهي ب',
			'account': 'الحساب',
			'retailer details': 'تفاصيل المتجر',
			'more links': 'مزيد من الروابط',
			'link': 'الرابط',
			'change language': 'تغيير اللغة',
			'languages': 'اللغات',
			'help': 'مساعدة',
			'settings': 'الإعدادات',
			'personal details': 'التفاصيل الشخصية',
			'birth date': 'تاريخ الميلاد',
			'allow send promotions': 'السماح بإرسال العروض الترويجية',
			'gender': 'النوع',
			'male': 'ذكر',
			'female': 'أنثى',
			'prefer_not_to_say': 'أفضّل عدم القول',
			'save': 'حفظ',
			'print': 'طباعة',
			'addresses': 'العناوين',
			'logout': 'تسجيل الخروج',
			'info': 'معلومات',
			'policy': 'السياسة',
			'contact details': 'تفاصيل الاتصال',
			'continue without membership': 'متابعة بدون عضوية',
			'not a member yet? join now!': 'لست عضوًا بعد؟ انضموا الآن!',
			'my cart': 'عربتي',
			'quantity': 'الكمية',
			'price': 'السعر',
			'items': 'عناصر',
			'check out': 'خروج',
			'enter cvv': 'CVV',
			'add card': 'إضافة بطاقة',
			'remove card': 'قم بإزالة البطاقة',
			'thank you for your purchase': 'شكرًا لك على الشراء',
			'loyalty club member': 'عضو في النادي',
			'in this purchase': 'في عملية الشراء هذه',
			'save_1': 'حفظ',
			'continue without loyalty': 'متابعة بدون عضوية',
			'the delivery time is no longer available': 'وقت التوصيل لم يعد متاحًا',
			'finish': 'إنهاء',
			'gift total': 'إجمالي الهدايا',
			'purchase type': 'نوع الشراء',
			'end date': 'تاريخ الانتهاء',
			'gift type': 'نوع الهدية',
			'confirm password': 'تأكيد كلمة المرور',
			'please let me know about promotions': 'أبلغني من فضلك بالعروض الترويجية',
			'more': 'مزيد',
			'from my list': 'من قائمتي',
			'import to cart': 'استيراد إلى العربة',
			'import products from cart?': 'هل تريد استيراد المنتجات من العربة؟',
			'nutrition facts': 'الحقائق الغذائية',
			'details': 'التفاصيل',
			'add to cart': 'إضافة إلى العربة',
			'view similar items': 'عرض العناصر المشابهة',
			'settings was successfully changed!': 'تم تغيير الإعدادات بنجاح',
			'sorry. we couldn\'t find this product': 'عذرًا، لا يمكنكم العثور على هذا المنتج',
			'two passwords must match': 'يجب أن تكون كلمتان المرور متطابقتين',
			'sign up completed': 'اكتمل تسجيل الاشتراك',
			'product not found': 'المنتج غير موجود',
			'please enter your password': 'يُرجى إدخال كلمة المرور الخاصة بك',
			'my shopping list': 'قائمة التويق الخاصة بي',
			'house no.': 'رقم المنزل',
			'from my cart': 'من عربتي',
			'est.': 'حوالي',
			'case': 'عبوة',
			'in store location': 'في مكان التخزين',
			'units': 'وحدات',
			'unit': 'الوحدة',
			'new shopping list was created': 'تم إنشاء قائمة تسوق جديدة',
			'please enter list name': 'يُرجى إدخال اسم القائمة',
			'there are no products in cart': 'لا توجد أي منتجات في العربة',
			'single': 'واحد',
			'pack': 'عبوة',
			'pck': 'عبوة',
			'cell phone': 'الهاتف المحمول',
			'home phone': 'هاتف المنزل',
			'work phone': 'هاتف العمل',
			'error': 'خطأ',
			'are you sure?': 'هل أنت متأكد؟',
			'phones': 'الهواتف',
			'address': 'العنوان',
			'opening hours': 'ساعات العمل',
			'user not found': 'Invalid Username or Password',
			'the email address doesn\'t have an associated account. please try again': 'لم يتم تسجيل حساب بعنوان البريد الإلكتروني يُرجى المحاولة مرة أخرى',
			'please enter your email address to proceed': 'يُرجى إدخال عنوان بريدك الإلكتروني للمتابعة',
			'loyalty user not found': 'تفاصيل تسجيل الدخول المتعلقة بالولاء غير صالحة',
			'branches': 'الفروع',
			'login': 'تسجيل الدخول',
			'status': 'الحالة',
			'time placed': 'الوقت المحدد',
			'i agree to receive promotions': 'أوافق على استلام العروض الترويجية',
			'add to list': 'إضافة إلى القائمة',
			'unauthorized action': 'إجراء غير مصرح به',
			'first name is required': 'الاسم الأول مطلوب',
			'last name is required': 'اسم العائلة مطلوب',
			'email is required': 'البريد الإلكتروني مطلوب',
			'email is not valid': 'البريد الإلكتروني غير صالح',
			'password is required': 'كلمة المرور مطلوبة',
			'phone number is required': 'رقم الهاتف مطلوب',
			'pickup location is required': 'موقع الاستلام مطلوب',
			'pickup day is required': 'يوم الاستلام مطلوب',
			'pickup hour is required': 'ساعة الاستلام مطلوب',
			'payment method is required': 'طريقة الدفع مطلوبة',
			'credit card is required': 'بطاقة الائتمان مطلوبة',
			'delivery day is required': 'يوم التوصيل مطلوب',
			'password should be longer than 6 characters': 'يجب أن تكون كلمة المرور أكثر من  أحرف',
			'new order': 'طلب جديد',
			'charged': 'تم احتساب رومه',
			'delivered': 'تم توصيله',
			'play': 'تشغيل',
			'cancelled': 'تم إلغاؤه',
			'on hold': 'معلق',
			'processing': 'جارٍ المعاجة',
			'waiting for confirmation': 'في انتظار التأكيد',
			'collected': 'تم جمعه',
			'registered': 'مسجل',
			'delete': 'حذف',
			'you must login first': 'يجب عليك تسجيل الدخول أولًا',
			'not found shop lists': 'لم يتم العثور على قوائم تسوق',
			'product added to shop list': 'تمت إضافة المنتج إلى قائمة التسوق',
			'yes': 'نعم',
			'no': 'لا',
			'brand': 'العلامة التجارية',
			'new name': 'اسم جديد',
			'invalid name': 'اسم غير صالح',
			'could not find your location': 'لم يتم العثور على موقعك',
			'new list': 'قائمة جديدة',
			'+add to list': '+إضافة إلى القائمة',
			'your cart is still empty': 'لا تزال عربتك فارغة',
			'in all departments': 'في جميع الأقسام',
			'this is a pick and go order': 'هذا طلب اختر وتابع',
			'start shopping': 'بدء التسوق',
			'your order has been collected': 'تم جمع طلبك',
			'the order was canceled': 'تم إلغاء الطلب',
			'a message has been sent to the number on your order, kindly confirm the replacements and we will complete your order': 'أُرسلت رسالة إلى الرقم المسجل به طلبك، من فضلك أكِّد الاستبدالات وسنكمل طلبك',
			'the order is currently being collected by our trained shoppers': 'يجمع متسوقونا المدربون الطلب حاليًا',
			'your order is currently being collected by our trained shoppers': 'يجمع متسوقونا المدربون طلبك حاليًا',
			'your order has been received but not charged': 'تم استلام طلبك لكن لم تُدفع رسومه',
			'your credit card has been charged for your order': 'خُصمت رسوم طلبك من بطاقة الائتمان الخاصة بك',
			'your order has been delivered and is on its way to you': 'تم توصيل طلبك وهو في طريقه إليك',
			'the order was cancelled for various reasons and is not being handled': 'أُلغ الطلب لأسباب عدة ولا تتم معالجته',
			'order could not be completed, customer intervention is required': 'تعذّر إكمال الطلب، تدخل العميل مطلوب',
			'the order is currently being processed': 'الطلب قيد المعالجة حاليًا',
			'the items have been collected but not yet registered in the pos': 'جُمعت العناصر لكن لم يتم تسجيلها في الصندوق حتى الآن',
			'the products were collected and scanned, but the transaction was yet to be completed': 'جُمعت المنتجات ومُسحت ضوئيًا، لكن لم تكتمل المعاملة بعد',
			'product already exists': 'المنتج موجود بالفعل',
			'approve & register': 'الموافقة والتسجيل',
			'not approve': 'عدم الموافقة',
			'{hubname}\'s privacy policy.': 'سياسة خصوصية {hubname} ',
			'i agree to receive promotional content & offers from {company_name} and {hubname}': 'أوافق على استلام المحتوى والعروض الترويجية من {company_name} و{hubname}',
			'i agree to the': 'أوافق على',
			'terms & conditions': 'الشروط والأحكام',
			'loyalty Club terms & conditions': 'شروط وأحكام نادي الولاء',
			'policy_end': '',
			'privacy policy': 'سياسة الخصوصية',
			'about us': 'معلومات عنا',
			//'add comment': 'إضافة تعليق',
			'track my order': 'متابعة طلبي',
			'order id:': 'معرف الطلب:',
			'no internet connection': 'لا يوجد اتصال بالإنترنت',
			'please enable internet connection and retry': 'يُرجى تفعيل الاتصال بالإنترنت وإعادة المحاولة',
			'retry': 'إعادة المحاولة',
			'no branch': 'لا توجد فروع',
			'lists': 'القوائم',
			'street is required': 'الشارع مطلوب',
			'house number is required': 'رقم المنزل مطلوب',
			'country is required': 'الدولة مطلوبة',
			'cvv is required': 'cvv مطلوب',
			'connect': 'اتصال',
			'connect_loyalty': 'اتصال',
			'not all of the products given where found': 'لم يتم العثور على جميع المنتجات',
			'not all of the given products were found': 'لم يتم العثور على جميع المنتجات المحددة',
			'you have some missing products in your cart': 'بعض المنتجات ليست موجودة في عربتك',
			'select method': 'حدد طريقة',
			'call me when there is missing item': 'اتصل بي عندما يكون ثمة عنصر مفقود',
			'this email already exist': 'هذا البريد الإلكتروني موجود بالفعل',
			'this email already exists': 'هذا البريد الإلكتروني موجود بالفعل',
			'unable to create user': 'تعذر إنشاء المستخدم',
			'km': 'كم',
			'scanning failed: ': 'فشلت عملية المسح:',
			'scan barcode': 'مسح الرمز الشريطي',
			'please point your camera at the barcode.': 'يُرجى تركيز كاميرتك على الرمز الشريطي',
			'i confirm that i have read the': 'أؤكد أنني قرأت',
			'we are not able to add the following products to your current order': 'لا يمكنني إضافة المنتجات التالية إلى طلبك الحالي',
			'terms': 'الشروط',
			'payment by': 'الدفع عن طريق',
			'phone': 'الهاتف',
			'payment': 'الدفع',
			'shipping address': 'عنوان الشحن',
			'payment method': 'طريقة الشحن',
			'you save': 'لقد حفظت',
			'supervised': 'تحت إشراف',
			'floor /\ apt': 'الطابق/الشقة',
			'open': 'فتح',
			'per lb': 'لكل رطل',
			'lb': 'رطل',
			'to see if we deliver to your area,': 'لمعرفة ما إذا كان لدينا خدمة توصيل إلى منطقتك أم لا,',
			'click here': 'انقر هنا',
			'we\'re available in': 'تتوفر خدماتنا في',
			'checking delivery areas': 'فحص مناطق التوصيل',
			'loyalty club': 'نادي الولاء',
			'have a club member?': 'ألديك عضو في النادي؟',
			'join our members club': 'انضم إلى نادي عملائنا',
			'member id': 'معرف العضو',
			'club id': 'معرف النادي',
			'connect to your club account': 'الاتصال بحسابك في النادي',
			'choose': 'اختيار',
			'choose your delivery area': 'اختر منطقة التوصيل',
			'delivery area': 'منطقة التوصيل',
			'enter zip code': 'أدخل الرمز البريدي',
			'mobile phone': 'الهاتف المحمول',
			'personal id': 'المعرف الشخصي',
			'loyalty card id': 'مُعرف بطاقة الولاء',
			'update club details': 'تحديث تفاصيل النادي',
			'how do you want to get your shopping?': 'ما الطريقة التي ترغب في استلام مشترياتك بها؟',
			'home delivery': 'التوصيل إلى المنزل',
			'pickup': 'الاستلام',
			'pick and go': 'اختر وتابع',
			'pick & go': 'اختر وتابع',
			'shipping details': 'تفاصيل الشحن',
			'mobile': 'الهاتف المحمول',
			'next': 'التالي',
			'choose delivery time': 'اختر وقت التوصيل',
			'shipping method & time': 'طريقة ووقت الشحن',
			'localdelivery': 'التوصيل المحلي',
			'send the delivery sooner, if possible': 'أرسل خدمة التوصيل قريبًا، إن أمكن',
			'sooner if possible': 'قريبًا إن أمكن',
			'allow substitutions?': 'هل تسمح باستبدال المنتج؟',
			'select card': 'حدد البطاقة',
			'your order will be delivered': 'سيتم توصيل طلبك',
			'you saved': 'حفظت',
			'subtotal': 'المجموع الفرعي',
			'tax': 'ض.ق.م.',
			'estimated': 'المُقدر',
			'only': 'فقط',
			'contact details:': 'تفاصيل الاتصال',
			'place order': 'تقديم الطلب',
			'place order using masterpass': 'تقديم الطلب باستخدام MasterPass',
			'sending your order...': 'جارٍ إرسال طلبك...',
			'please check your email for order details. thank you!': 'يُرجى فحص بريدك الإلكتروني للاطلاع على تفاصيل الطلب شكرًا لك!',
			'back to home screen': 'العودة إلى الشاشة الرئيسية',
			'back to top': 'العودة إلى الأعلى',
			'questions? get in touch': 'ألديك أي استفسارات؟ تواصل معنا',
			'current location:': 'الموقع الحالي:',
			'pickup location': 'موقع الاستلام',
			'click to choose pickup location': 'انقر لاختيار موقع الاستلام',
			'pickup time': 'وقت الاستلام',
			'pickandgo time': 'وقت التسليم والذهاب',
			'contact & payment details': 'تفاصيل الاتصال والدفع',
			'your order will be ready': 'سيكون طلبك جاهزًا',
			'shipping': 'الشحن',
			'delivery': 'التوصيل',
			'call me': 'اتصل بي',
			'delivery_type_1': 'التوصيل',
			'delivery_type_2': 'الاستلام',
			'delivery_type_3': 'الاستلام والذهاب',
			'delivery_type_5': 'Express Delivery',
			'area_type_1': 'التوصيل',
			'area_type_2': 'التوصيل',
			'area_type_3': 'التوصيل',
			'area_type_4': 'التوصيل',
			'area_type_5': 'الاستلام',
			'sun': 'الأحد',
			'mon': 'الاثنين',
			'tue': 'الثلاثاء',
			'wed': 'الأربعاء',
			'thu': 'الخميس',
			'fri': 'الجمعة',
			'sat': 'السبت',
			'the address you provided is not served at this time. please enter another address or choose pickup': 'لا تتوفر خدماتنا في العنوان الذي أدخلته في الوقت الحالي يُرجى إدخال عنوان آخر أو اختيار الاستلام',
			'we\'re  sorry, but the address you provided is not served at this time': 'نعتذر لك، لكن لا تتوفر خدماتنا الآن في العنوان الذي أدخلته',
			'change to pickup': 'غيِّر إلى استلام',
			'can\'t find a product ?': 'هل لايمكنك العثور على منتج؟',
			'can\'t find a product?': 'هل لايمكنك العثور على منتج؟',
			'if we are unfamiliar with the product or don\'t have it in stock, we will give you a call.': 'إذا لم نكن على دراية بالمنتج أو لم يتواجد لدينا في المخزون، فسنتصل بك.',
			'product name': 'اسم المنتج',
			'select category': 'حدد فئة',
			'clear cart': 'إخلاء العربة',
			'menu': 'القائمة',
			'customer id': 'مُعرِّف العميل',
			'congradulations for joining the customers club': 'تهانينا على الانضمام إلى نادي العملاء',
			'your club member number': 'رقم عضويتك في النادي',
			'loyalty member': 'عضو الولاء',
			'you have connected successfully': 'نجح اتصالك',
			'pay attention': 'انتبه',
			'do you want to continue': 'هل ترغب في المتابعة',
			'continue without club discounts': 'متابعة دون الحصول على خصومات عضوية النادي',
			'return and pay with loyalty credit card': 'العودة والدفع باستخدام بطاقة ائتمان نادي الولاء',
			'paying with different payment method will discard {clubname} club discounts': 'سيلغي الدفع بطريقة دفع أخرى الحصول على خصومات عضوية نادي {clubname}',
			'join to club': 'انضم إلى النادي',
			'connect to club': 'اتصل بالنادي',
			'join': 'انضم',
			'all the fields is required': 'جميع الحقول مطلوبة',
			'did you mean?': 'هل تعني؟',
			'callcustomer_description': 'سنتصل بك لنؤكد السداد',
			'creditcard_description': 'اختر بطاقة ائتمان أو أضفها',
			'pay_creditcard_description': 'ادفع بواسطة بطاقة الائتمان',
			'storecredit_description': 'ادفع بواسطة بطاقة ائتمانك على معرف هوية الملف',
			'paypal_description': 'PayPal ادفع بواسطة',
			'icreditmasterpass_description': 'ادفع بواسطة حسابك MasterPass',
			'bit_description': 'ادفع بواسطة Bit',
			'ebt_description': 'EBT payment',
			'select:': 'تحديد:',
			'select': 'تحديد',
			'edit comment': 'تحرير التعليق',
			'add comment': 'إضافة التعليق',
			'add a comment': 'إضافة تعليق',
			'write a comment': 'كتابة تعليق',
			'type your comment here': 'اكتب تعليقك هنا',
			'address 1': 'شارع+رقم البيت',
			'to join': 'للانضمام',
			'join now': 'انضم الآن',
			'register now to join our membership club': 'سجِّل الآن للانضمام إلى نادي العملاء',
			'reset code': 'إعادة ضبط الرمز',
			'reset password': 'أعد ضبط كلمة المرور',
			'password reset': 'إعادة ضبط كلمة المرور',
			'a password recovery code has been sent to your email': 'تم إرسال رمز استرجاع كلمة المرور إلى بريدك الإلكتروني',
			'please check your inbox and follow the instructions': 'يرجى التحقق من صندوق الوارد الخاص بك واتباع التعليمات',
			'if you haven\'t received an email, please check if you mistyped your email address or contact customer service': 'إذا لم تتلقَ رسالة على البريد الإلكتروني، فيرجى التحقق مما إذا كنت قد أخطأت في كتابة عنوان بريدك الإلكتروني أو اتصل بخدمة العملاء على الرقم ',
			'est. weight': 'الوزن المقدر',
			'please confirm that you accept the terms and conditions for using this service': 'يُرجى التأكد من أنك توافق على الشروط والأحكام لاستخدام هذه الخدمة',
			'explanation': 'توضيح',
			'invalid cvv': 'cvv غير صالح',
			'choose card': 'اختر بطاقة',
			'invalid phone number': 'رقم هاتف غير صالح',
			'invalid city': 'مدينة غير صالحة',
			'invalid address': 'عنوان غير صالح',
			'invalid street': 'شارع غير صالح',
			'invalid address 1': 'عنوان 1 غير صالح',
			'invalid zip code': 'رمز بريدي غير صالح',
			'products are no longer available': 'لم تعد المنتجات متوفرة',
			'payments number': 'رقم المدفوعات',
			'online grocery service': 'خدمة البقالة عبر الإنترنت',
			'download': 'تنزيل',
			'get<br>the app': 'احصل على<br>التطبيق',
			'branch not found': 'نأسف، العنوان الذي أدخلته غير مشمول في الخدمة في الوقت الخالي',
			'missing premise or street number': 'فقدان رقم الشارع أو المبنى',
			'estimated tax': 'الضريبة المقدرة',
			'estimated total': 'المجموع المقدر',
			'contact us': 'اتصل بنا',
			'how can we help you?': 'كيف يمكننا مساعدتك؟',
			'how can we help you today?': 'كيف يمكننا مساعدتك اليوم؟',
			'submit': 'إرسال',
			'invalid message': 'رسالة غير صالحة',
			'invalid email': 'بريد إلكتروني غير صالح',
			'your message was sent successfully': 'تم إرسال رسالتك بنجاح',
			'we are sorry but this service is no longer available': 'عذرًا، لكن لم تعد هذه الخدمة متوفرة',
			'continue with this cart': 'متابعة باستخدام هذه العربة',
			'merge carts': 'دمج العربات',
			'would you like to merge the two carts or continue using this cart': 'هل ترغب في دمج العربتين أو المتابعة باستخدام هذه العربة',
			'somewhere else': 'في مكان آخر',
			'you have an open cart with': 'لديك عربة مفتوحة مع',
			'powered by': 'مُشغّل بواسطة',
			'day0': 'الأحد',
			'day1': 'الإثنين',
			'day2': 'الثلاثاء',
			'day3': 'الأربعاء',
			'day4': 'الخميس',
			'day5': 'الجمعة',
			'day6': 'السبت',
			'to ': 'إلى',
			'your order total {{areaname}}does not meet the {{minimum | currency}} minimum order total': 'لا يتوافق مجموع طلبك {{areaname}} مع الحد الأدنى لمجموع طلب {{minimum | currency}}',
			'please add to your cart items worth {{difference | currency}} to proceed to checkout': 'يُرجى إضافة قيمة عناصر عربتك {{difference | currency}} للمتابعة إلى إتمام العملية',
			'not including items on sale': 'باستثناء العناصر المعروضة للبيع',
			'please add additional products to the cart to proceed': 'يُرجى إضافة منتجات إضافية إلى العربة للمتابعة',

			'substitute_preference_1_label': 'استبدال',
			'substitute_preference_1_description': 'سنستبدل المنتج المفقود دون الاتصال بك',
			'substitute_preference_2_label': 'اتصل بي',
			'substitute_preference_2_description': 'سنتصل بك قبل استبدال المنتج المفقود',
			'substitute_preference_3_label': 'لا تستبدل',
			'substitute_preference_3_description': 'لن نسستبدل المنتج المفقود',
			'substitute_preference_4_label': 'أرسل رسالة SMS',
			'substitute_preference_4_description': 'سنرسل إليك رسالة نصية قبل استبدال المنتج المفقود',
			'substitute_preference_5_label': 'Send WhatsApp',
			'substitute_preference_5_description': 'We will send you a text message in WhatsApp before we replace a missing product',
			'substitute_preference__label': 'لمنع الخطأ',
			'substitute_preference__description': 'لمنع الخطأ',
      'leave_by_the_door':
        "يُرجى ترك الطلب خارج الباب إذا لم يكن هناك أحد في المنزل",
			'sorry, currently we don\'t deliver to this address.': 'عذرًا، لا يمكننا توصيل المنتج إلى هذا العنوان في الوقت الحالي',
			'sorry, currently we don\'t deliver to this address, for further inquiries call to customer service.': 'عذرًا، لا يمكننا توصيل المنتج إلى هذا العنوان في الوقت الحالي، لمزيد من الاستفسارات، اتصل بخدمة العملاء.',
			'sorry, branch change required, this address could only be delivered from branch': 'عذرًا، تغيير الفرع مطلوب، لا يمكن التوصيل إلى هذا العنوان إلا من الفرع فقط',
			'please enter a more accurate address to continue': 'يُرجى إدخال عنوان أكثر دقة للمتابعة',
			'switch branch': 'تبديل الفرع',
			'try another address or choose pickup option': 'جرب عنوانًا آخر أو اختر خيار التسليم',
			'delivery fee': 'رسوم التوصيل',
			'pickup fee': 'رسوم الاستلام',
			'service fee': 'رسوم الخدمة',
			'add a comment here:': 'إضافة تعليق هنا:',
			'at': 'في',
			'next delivery': 'عملية التوصيل القادمة',
			'next pickup': 'عملية الاستلام القادمة',
			'get it within': 'سيتم الشحن في غضون',
			'the following products were not saved correctly due to an error': 'لم يتم حفظ المنتجات التالية بطريقة صحيحة بسبب خطأ ما',
			'hrs': 'ساعات',
			'days': 'أيام',
			'business days': 'أيام العمل',
			'delivery within': 'التوصيل في غضون',
			'within': 'في غضون',
			'pickup within': 'الاستلام في غضون',
			'coupon number': 'رقم القسيمة',
			'coupon': 'القسيمة',
			'please enter quantity': 'يُرجى إدخال الكمية',
			'please enter a name with less than 200 characters': 'يُرجى إدخال اسمًا مكونًا من عدد أحرف أقل من 200 حرف',
			'we didn\'t recognize the address you entered': 'لم نتمكن من التعرف على العنوان الذي أدخلته',
			'continue anyway': 'المتابعة على أي حال',
			'more details': 'مزيد من التفاصيل',
			'product description': 'وصف المنتج',
			'ingredients': 'المكونات',
			'more information': 'مزيد من المعلومات',
			'directions': 'تعليمات',
			'serving size': 'حجم الوجبة',
			'servings per container': 'حجم الوجبة في كل عبوة',
			'amount per serving': 'القيمة لكل وجبة',
			'calories': 'السعرات الحرارية (طاقة)',
			'calories from fat': 'السعرات الحرارية من الدهون',
			'daily value': 'القيمة اليومية',
			'total fat': 'إجمالي الدهون',
			'saturated fat': 'الدهون المشبعة',
			'trans fat': 'الدهون المتحولة',
			'cholesterol': 'الكوليسترول',
			'sodium': 'الصوديوم',
			'total carbohydrate': 'إجمالي الكربوهيدرات',
			'dietary fiber': 'الألياف الغذائية',
			'sugars': 'السكريات',
			'protein': 'البروتين',
			'vitamin a': 'فيتامين a',
			'vitamin c': 'فيتامين c',
			'calcium': 'الكالسيوم',
			'iron': 'الحديد',
			'g': 'غ',
			'monounsaturated fat': 'الأحماض الدهنية الأحادية غير المشبعة',
			'polyunsaturated fat': 'الأحماض الدهنية المتعددة غير المشبعة',
			'percent daily': 'تستند القيم اليومية بالنسبة المئوية إلى حمية غذائية قيمتها 2.000 سُعر حراري. يمكن أن تكون القيمة اليومية لك أعلى أو أقل حسب السُعرات الحرارية المطلوبة.',
			'welcome': 'مرحبًا',
			'let\'s make sure we\'re available in your area': 'لنتأكد من أننا متاحين في منطقتك',
			'area': 'المنطقة',
			'check': 'فحص',
			'continue shopping': 'متابعة التسوق',
			'change area': 'تغيير المنطقة',
			'quantity limitation': 'حدود الكمية',
			'is limited to ': 'تقتصر على',
			'items only': 'العناصر فقط',
			'similar items': 'العناصر المشابهة',
			'nutrition': 'التغذية',
			'description': 'الوصف',
			'and': 'و',
			'and the': 'و',
			'currently we do not deliver to your area': 'نحن لا نجري عمليات توصيل إلى منطقتك حاليًا',
			'ticket_status_1': 'جديد',
			'ticket_status_2': 'معالجة',
			'ticket_status_3': 'تم التنفيذ',
			'no previous inquiries': 'لا توجد استفسارات سابقة',
			'join membership': 'انضم إلى أعضائنا',
			'join club': 'انضم إلى النادي',
			'view club member details': 'عرض تفاصيل أعضاء النادي',
			'thank you for signing up to {0} app': 'نشكرك لتسجيل الاشتراك في تطبيق {0}',
			'join our loyalty club to benefit from exclusive promotions': 'انضم إلى نادي الولاء للاستفادة من العروض الترويجية الحصرية',
			'connect to our loyalty club to benefit from exclusive promotions': 'اتصل بنادي الولاء للاستفادة من العروض الترويجية الحصرية',
			'membership number': 'رقم العضوية',
			'membership savings': 'وفورات العضوية',
			'could save': 'يمكن توفير',
			'customer service': 'خدمة العملاء',
			'expiration date': 'تاريخ انتهاء الصلاحية',
			'comments': 'التعليقات',
			'send': 'إرسال',
			'credit cards': 'بطاقات الائتمان',
			'drag files or browse...': 'اسحب الملفات أو انقر على استعراض...',
			'you are now connected to your membership club account': 'أنت الآن متصل بحسابك في النادي',
			'remove credit card info': 'إزالة معلومات بطاقة الائتمان',
			'are you sure you want to remove credit card information': 'هل أنت متأكد أنك تريد إزالة معلومات بطاقة الائتمان',
			'type in new request': 'كتابة طلب جديد',
			'by': 'حسب',
			'from': 'من',
			'read more': 'قراءة المزيد',
			'read less': 'قراءة أقل',
			'recipe': 'الوصفة',
			'video': 'فيديو',
			'min': 'دقيقة',
			'invalid coupon code': 'رمز قسيمة غير صحيح',
			'order status': 'حالة الطلب',
			'view order': 'عرض الطلب',
			'track order no.': 'تتبع رقم الطلب',
			'forgot something': 'هل نسيت شيئًا ما',
			'update order': 'تحديث الطلب',
			'update order failed': 'فشل تحديث الطلب',
			'order received': 'تم استلام الطلب',
			'in process': 'جارِ المعالجة',
			'ready': 'جاهز',
			'received': 'تم استلامه',
			'need help': 'تحتاج إلى مساعدة',
			'edit order': 'تعديل الطلب',
			'edit your order': 'تعديل طلبك',
			'your order': 'طلبك',
			'has been updated': 'تم تحديثه',
			'are you sure you want to cancel your order update and return to your original order': 'هل أنت متأكد أنك تريد إلغاء تحديث طلبك والعودة إلى طلبك الأصلي',
			'do you want to remove / add items to your existing order': 'هل تريد إزالة / إضافة عناصر إلى طلبك الحالي',
			'note': 'ملاحظة',
			'prices and specials will change according to the update date': 'تختلف الأسعار والعروض الخاصة حسب تاريخ التحديث',
			'please check your email for order details': 'يُرجى التحقق من بريدك الإلكتروني لمعرفة تفاصيل طلبك',
			'thank you': 'شكرًا لك',
			'we have started collecting your original order.': 'بدأنا في جمع طلبك الأصلي',
			'we have finished collecting your original order.': 'انتهينا من جمع طلبك الأصلي',
			'this is a canceled order.': 'هذا الطلب ملغي',
			'your order cannot be changed at this time': 'لم يعد من الممكن تعديل طلبك',
			'your order can no longer be changed': 'لم يعد من الممكن تعديل طلبك',
			'cancel update': 'إلغاء التحديث',
			'canceling': 'جارِ الإلغاء',
			'update': 'نحديث',
			'replace shopping cart': 'استبدال عربة التسوق',
			'are you sure you want to replace your shopping cart with the order from': 'هل أنت متأكد أنك تريد استبدال عربة التسوق الخاصة بك بالطلب من',
			'replace': 'استبدال',
			'replace_2': 'استبدال',
			'you can still edit your order': 'لا يزال بإمكانك تعديل طلبك',
			'your original order is': 'طلبك الأصلي',
			'cancel order update': 'إلغاء تحديث الطلب',
			'in process ': 'طلبكم الأصلي',
			'your cart is empty': 'عربتك خالية',
			'continue as new cart': 'متابعة بعربة جديدة',
			'you can move the selected items into a new order': 'يمكنك نقل العناصر المحددة إلى طلبك الجديد',
			'my coupons': 'قسائمي',
			'clip & add': 'إضافة',
			'clip': 'إضافة',
			'the site is undergoing maintenance': 'الموقع تحت الصيانة',
			'promotion details': 'Details',
			'hide details': 'إخفاء التفاصيل',
			'show details': 'عرض التفاصيل',
			'the following products are not active, and were removed from the cart': 'المنتجات التالية غير نشطة، وتمت إزالتها من العربة',
			'once we complete picking your order, we adjust your final charge based on weight adjustments, substitutions & out of stock items': 'بمجرد الانتهاء من استلام طلبك، نقوم بتعديل الرسوم النهائية بناءً على تعديلات الوزن وعمليات الاستبدال و العناصر غير المتوفرة',
			'due to changes in address details': 'بسبب تغييرات في تفاصيل العنوان',
			'please select your delivery time again': 'يُرجى تحديد وقت التوصيل مرة أخرى',
			'order': 'الطلب',
			'order id': 'مُعرِّف الطلب',
			'pack items': 'عناصر معبأة',
			'how do you want to get': 'الطريقة التي ترغب في الحصول بها على منتجك',
			'cost': 'التكلفة',
			'express': 'سريع',
			'no_deliveries_first_row': 'نعتذر، حاليًا غير متاح',
			'no_deliveries_second_row': 'التسليمات في هذا اليوم',
			'no_pickup_first_row': 'نأسف، حاليًا غير متاح',
			'no_pickup_second_row': 'استلم في هذا التاريخ',
			'add your comment here': 'أضف تعليقك هنا',
			'substitutions': 'استبدال',
			'before_checkout_delivery_title_1': 'اختر المرغوب',
			'before_checkout_delivery_title_2': 'وقت التسليم',
			'before_checkout_pickup_title_1': 'استلم طلبك',
			'before_checkout_pickup_title_2': 'من المتجر',
			'your credit cards': 'بطاقة الائتمان الخاصة بك',
			'add another card': 'إضافة بطاقة أخرى',
			'order update': 'تحديث الطلب',
			'updated order value': 'قيمة الطلب التي تم تحديثها',
			'(estimated)': ' (المُقدَّر)',
			'in order to complete the order update, please choose the payment method': 'لاستكمال تحديثك للطلب، يُرجى اختيار طريقة السداد',
			'confirm update': 'برجاء تأكيد التحديث',
			'add your credit/debit card': 'أضف بطاقات الائتمان / الخصم الخاصة بك',
			'use my delivery address as my billing address*': 'اجعل عنوان التوصيل الخاص بي هو عنوان السداد الخاص بي*',
			'*by checking this option you approve transfer of your address data to the payment provider': 'عند تحديدكم لهذا الخيار، فإنّكم توافقون على نقل بيانات عنوانكم إلى مزود خدمة الدفع',
			'add another account': 'إضافة حساب آخر',
			'please enter a name': 'يُرجى إدخال اسم',
			'please enter a mobile phone': 'يُرجى إدخال رقم الهاتف المحمول',
			'please enter an address': 'يُرجى إدخال عنوان',
			'please enter house number': 'يُرجى إدخال رقم المنزل',
			'please enter city': 'يُرجى إدخال المدينة',
			'please enter a correct phone number': 'يُرجى إدخال رقم هاتف صحيح',
			'is an anti-fraud security feature to help verify that you are in possession of your credit card': 'هو رقم للمساعدة على التحقق من حيازتك لبطاقة الائتمان الخاصة بك',
			'the three-digit cvv number is printed on the signature panel on the back of the card immediately after the card\'s account number': 'رقم cvv المكون من ثلاثة أرقام مطبوع على لوحة التوقيع على الجزء الخلفي من البطاقة بعد رقم حساب البطاقة مباشرة',
			'enter your credit on file id': 'أدخل بطاقة ائتمانك في معرف الملف',
			'checkout_title': 'السداد مع الخروج',
			'cart no.': 'رقم العربة',
			'pick_and_go_title': 'استلم واذهب',
			'no need to wait in line': 'لا داعي للانتظار في الطابور',
			'we will take care of your': 'سنهتم بطلبك',
			'cart and deliver': 'العربة والتسليم',
			'to begin, pick a': 'للبدء، اختر',
			'barcode card': 'بطاقة الباركود',
			'start pick & go': 'بدء اختر وتابع',
			'are you sure you want to stop the': 'هل أنت متأكد من أنك تريد إيقاف',
			'pick & go service': 'خدمة اختر وتابع',
			'stop pick & go': 'إيقاف اختر وتابع',
			'please enter your credit on file id': 'يُرجى إدخال بطاقة ائتمانك في معرف الملف',
			'substitutions choice': 'اختيار الاستبدالات',
			'home page': 'الصفحة الرئيسية',
			'thank you for': 'شكرًا لك على',
			'shopping at {retailername}': 'التسوق في {retailerName}',
			'an order summary email was sent to your inbox': 'يُرجى فحص بريدك الإلكتروني للاطلاع على تفاصيل الطلب',
			'forgot': 'نسيت',
			'something': 'شيء ما',
			'minutes': 'دقائق',
			'1 hours delivery': 'التوصيل في غضون ساعة',
			'2 hours delivery': 'التوصيل في غضون ساعتين',
			'{hours} hours delivery': 'التوصيل في غضون {hours} ساعة',
			'{minutes} minutes delivery': 'التوصيل في غضون {minutes} دقائق',
			'1 hour pickup': 'الاستلام في غضون ساعة واحدة',
			'2 hour pickup': 'الاستلام في غضون اعتين',
			'{hours} hour pickup': 'الاستلام في غضون {hours} ساعة',
			'pickup time and address': 'وقت وعنوان الاستلام',
			'pickup details': 'تفاصيل الاستلام',
			'your order will be ready for pickup': 'سيكون طلبك جاهزًا للاستلام',
			'loading': 'جارٍ التحميل',
			'paypal accounts': 'حسابات paypal',
			'create new order': 'إنشاء طلب جديد',
			'create new shopping list': 'إنشاء قائمة تسوق جديدة',
			'add cart products': 'أضف منتجات إلى العربة',
			'create': 'إنشاء',
			'no shopping list created yet': 'لم يتم إنشاء أي قائمة تسوق حتى الآن',
			'remove format': 'إزالة التنسيق',
			'since your address was not detected': 'بسبب عدم العثور على عنوانك',
			'please re-select a new delivery time': 'يُرجى إعادة تحديد وقت توصيل جديد',
			'select delivery time': 'حدد وقت التوصيل',
			'please try again': 'يُرجى إعادة المحاولة',
			'add a credit card': 'إضافة بطاقة ائتمان',
			'add paypal account': 'إضافة حساب PayPal',
			'it seems you are editing your order on a different device.': 'يبدو أنك تقوم بتعديل طلبك من جهاز مختلف.',
			'note! it seems you have finished editing your order on a different device/browser.': 'ملاحظة! يبدو أنك انتهيت من تعديل طلبك من جهاز / متصفح مختلف.',
			'it seems you have finished your order on a different device.': 'يبدو أنك انتهيت من طلبك من جهاز مختلف',
			'wee are currently updating this device too': 'نحدِّث هذا الجهاز أيضًا في الوقت الحالي',
			'sorry, no available delivery time slots': 'عذرًا، لا تتوفر أي فترة زمنية للتوصيل',
			'sorry, no available pickup time slots': 'عذرًا، لا تتوفر أي فترة زمنية للاستلام',
			'today\'s special deals': 'صفقات اليوم الخاصة',
			'loyalty membership expired': 'انتهت صلاحية عضوية الولاء',
			'hello': 'مرحبًا',
			'free': 'مجانًا',
			'your membership has expired': 'لقد انتهت عضويتك',
			'your club membership has expired on': 'لقد انتهت عضويتك في النادي في',
			'renew you membership and enjoy exclusive benefits and discounts': 'جدد عضويتك واستمتع بالمزايا والخصومات الحصرية',
			'enter a valid number': 'أدخل رقمًا صحيحًا',
			'no thanks, continue shopping': 'لا، شكرًا تابع التسوق',
			'renew membership': 'جدد العضوية في النادي',
			'your membership has been renewed successfully': 'تم تجديد عضويتك بنجاح',
			'you can enjoy from the club benefits in this order': 'يمكنك الاستمتاع بمزايا النادي في هذا الطلب',
			'loyalty_mode_1_label': 'العضو الحالي',
			'loyalty_mode_2_label': 'عضو جديد',
			'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/club-icon.png': 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/club-icon-hebrew.png',
			'please fill in membership number': 'يُرجى كتابة رقم العضوية',
			'{retailername} membership offers exclusive': 'تمنحك العضوية في نادي {retailername}عروض حصرية',
			'promotions in-store and online': 'عروض ترويجية حصرية في المتجر وعلى الإنترنت',
			'hi': 'مرحبًا',
			'the items in your shopping cart will be cleared if you choose to edit your previous order': 'ستُمسح العناصر الموجودة في عربة التسوق إذا اخترت تعديل طلبك السابق',
			'edit order & clear cart': 'تعديل الطلب وإخلاء العربة',
			'it seems you are editing your order on a different device/browser.': 'يبدو أنك تقوم بتعديل طلبك من جهاز / متصفح مختلف.',
			'it seems you have finished editing your order on a different device/browser.': 'يبدو أنك انتهيت من تعديل طلبك من جهاز / متصفح مختلف.',
			'do you want to add the product to this new order or to the order that is being updated?': 'هل تريد إضافة منتج إلى هذا الطلب الجديد أو إلى الطلب الذي يتم تحديثه؟',
			'note! you in the middle of editing order': 'ملاحظة! أنت في منتصف عملية تعديل الطلب',
			'you in the middle of editing order. changing store is impossible': 'أنت في منتصف عملية تعديل الطلب. لا يمكن تغيير المتجر',
			'add to new order': 'أضف إلى طلب جديد',
			'add to updated order': 'أضف إلى طلب محدث',
			'we are not able to register your membership club account': 'لم نتمكن من تسجيل عضويتك في النادي',
			'please make sure your details are correct or try again later': 'يُرجى التأكد من أن معلوماتك صحيحة أو أعد المحاولة لاحقًا',
			'https://d226b0iufwcjmj.cloudfront.net/global/mobile/images/backgrounds/loyalty-titles.png': 'https://d226b0iufwcjmj.cloudfront.net/global/mobile/images/backgrounds/loyalty-titles.png',
			'special_exp': 'ينتهي في',
			'special_exp_new': 'ينتهي في',
			'ends': 'ينتهي',
			'special_exp': 'ينتهي بتاريخ',
			'forpackquantity': 'من أجل',
			'verify_1': 'يُرجى التحقق',
			'verify_2': 'عمرك',
			'verify_3': 'يجب ألا يقل عمرك عن',
			'verify_4': 'للشراء',
			'verify_5': 'هذا العنصر',
			'verify_6': 'أنا',
			'verify_7': 'أكبر',
			'verify_8': 'من',
			'verify_9': 'أصغر',
			'years': 'سنوات',
			'sorry': 'نعتذر',
			'you cannot purchase': 'لا يمكنك الشراء',
			'this item': 'هذا العنصر',
			'please enter a valid email address': 'يُرجى إدخال عنوان بريد إلكتروني صالح',
			'password must include at least 6 characters': 'يجب أن تحتوي كلمة المرور على 6 أحرف على الأقل',
			'password should not be longer than 30 characters': 'يجب ألا تزيد كلمة المرور عن 30 حرفًا',
			'password must contain at least one numeric digit and one character': 'يجب أن تحتوي كلمة المرور على رقم واحد ورمز واحد',

			'let\'s start': 'دعنا نبدأ',
			'adding some items': 'إضافة بعض العناصر',

			'please notice': 'يُرجى الانتباه',
			'the expiration of these': 'انتهاء صلاحية هذه',
			'sales will not be valid for this chosen time': 'لن تكون المبيعات صالحة في هذا الوقت المحدد',
			'the special displayed below, will expire on the date you are supposed to receive your order': 'ستنتهي صلاحية العرض الخاص المبين أدناه في التاريخ الذي يُفترض فيه أن تستلم طلبك',
			'these items will be charged at their regular price': 'سيتم احتساب هذه العناصر بسعرها العادي',
			'this product will be charged the regular price, promotion will not be applied': 'سيتم احتساب هذا المنتج بالسعر العادي، ولن يتم تطبيق العرض الترويجي',
			'price of updated cart (est.)': 'سعر العربة المحدثة (تقديري)',

			'general specials': 'العروض الخاصة العامة',
			'loyalty specials': 'عروض الولاء الخاصة',
			'category': 'الفئة',
			'categories': 'الفئات',
			'special type': 'نوع العرض الخاص',
			'clear all': 'مسح الكل',
			'filter by': 'تصفية حسب',
			'sort by': 'فرز حسب',
			'type': 'النوع',
			'our top picks': 'أفضل المنتجات',
			'membership deals': 'صفقات العضوية',
			'it\'s easy!': 'إنه سهل',
			'please sign in to continue...': 'يُرجى تسجيل الدخول للمتابعة...',
			'e-mail': 'البريد الإلكتروني',
			'sign in': 'تسجيل الدخول',
			'club member name': 'اسم عضو النادي',
			'{clubname} special deals': 'الصفقات الخاصة لـ {clubname}',
			'membership_club': 'نادي',
			'join our membership and enjoy exclusive benefits and promotions': 'انضم لنادينا واستمتع بالمزايا والعروض الحصرية',
			'identify': 'الهوية',
			'join our membership club': 'انضم إلى النادي',
			'join the club': 'انضم إلى النادي',
			'connect to club': 'اتصل بالنادي',
			'activate': 'تنشيط',
			'please activate credit card {xxxx} in order to continue': 'يُرجى تنشيط بطاقة الائتمان {xxxx} للمتابعة',
			'membership_title_register': 'انضم',
			'membership_title_identify': 'تعريف',
			'membership_title_{clubname}': '{clubname}',
			'membership_{clubname}_title': '{clubname}',
			'to complete your purchase, join/connect to {clubname} membership account': 'لإتمام عملية الشراء، انضم إلى / سجل الدخول بحساب العضوية في {clubname}',
			'in order to complete your purchase, you must connect to the loyalty club': 'لإتمام عملية الشراء، يجب عليك تسجيل الدخول بحساب في نادي العملاء',
			'stores info': 'معلومات المتاجر',
			'store info': 'معلومات المتجر',
			'pickup address': 'عنوان الاستلام',
			'select address': 'تحديد عنوان',
			'buy': 'شراء',
			'please enter e-mail': 'يُرجى إدخال البريد الإلكتروني',
			'menu_stores_info': 'تفاصيل المتاجر',
			'menu_store_info': 'تفاصيل المتجر',
			'your order is being edited by another device': 'تم تعديل طلبك من جهاز آخر',
			'organization': 'المؤسسة',
			'organization_description': 'السداد بالبطاقة الائتمانية',
			'enter order confirmation no.': 'أدخل رقم تأكيد الطلب',

			'icreditmasterpass': 'MasterPass',

			'friend\'s email': 'البريد الإلكتروني للصديق',
			'or share your promo code': 'أو قدم رمز العرض الخاص بك',
			'congratulations': 'تهانينا',
			'shop now': 'تسوق الآن',
			'copy': 'نسخ',
			'no, thanks': 'لا، شكرًا',
			'got it': 'ابدأ الشراء',
			'invitation sent successfully': 'تم إرسال الدعوة بنجاح',
			'promo code unidentified': 'لم يتم التعرف على رمز العرض الترويجي',
			'enter your promo code': 'أدخل رمز العرض',
			'there are no coupons currently available': 'لا توجد أي قسائم متوفرة في الوقت الحالي',

			'express delivery is allowed only with products that contain express delivery tag': 'التوصيل السريع غير مسموح به إلا مع المنتجات التي تحتوي على بطاقة التوصيل السريع',
			'express delivery products limit exceeded': 'تم تجاوز حد منتجات التوصيل السريع',
			'this service is available for express items only. removing non express items from your cart will enable using this option': 'هذه الخدمة غير متاحة إلا للعناصر التي تحتوي على بطاقة توصيل سريع فقط. ستسمح إزالة العناصر التي لا تحتوي على بطاقة التوصيل السريع باستخدام هذا الخيار',
			'express delivery is limited to': 'يقتصر التسليم السريع على',
			'items. to enable this service, remove items from your cart': 'عناصر. لتمكين هذه الخدمة، قم بإزالة العناصر من عربتك',

			'buy_x_for_y': 'Buy {{purchaseTotal}}',

			/*pele card errors*/
			'permitted transaction.': 'معاملة مصرح بها.',
			'the card is blocked, confiscate it.': 'البطاقة محظورة، سنقوم بمصادرتها.',
			'the card is stolen, confiscate it.': 'البطاقة مسروقة، سنقوم بمصادرتها.',
			'contact the credit company.': 'اتصل بشركة الائتمان.',
			'refusal by credit company.': 'الرفض من جانب شركة الائتمان.',
			'the card is forged, confiscate it.': 'البطاقة مزورة، سنقوم بمصادرتها.',
			'incorrect cvv/id.': 'رقم بطاقة هوية /رقم ال cvv غير صحيح.',
			'an error occurred while building access key for blocked card files.': 'وقع خطأ ما أثناء إنشاء مفتاح وصول لملفات البطاقة المحظورة.',
			'no communication. please try again or contact system administration': ' لا يوجد اتصال، يُرجى المحاولة مرة أخرى أو الاتصال بإدارة النظام.',
			'the program was stopped by user`s command (esc) or com port can\'t be open (windows)': 'تم إيقاف البرنامج بأمر المشغل (ESC) أو COM PORT لا يمكن فتح (WINDOWS).',
			'the acquirer is not authorized for foreign currency transactions': 'غير مصرح للمشتري بإجراء معاملات صرف عملات أجنبية.',
			'this card is not permitted for foreign currency transactions': 'غير مصرح لهذه البطاقة بإجراء معاملات صرف عملات أجنبية.',
			'this card is not supported.': 'هذه البطاقة غير مدعومة.',
			'track 2 (magnetic) does not match the typed data.': 'لا يوجد تطابق بين المسار 2 (المغناطيسي) والبيانات المدخلة.',
			'additional required data was entered/not entered as opposed to terminal settings (z field).': 'تم إدخال / لم يتم إدخال البيانات الإضافية المطلوبة على عكس إعدادات الجهاز (حقل Z).',
			'last 4 digits were not entered (w field).': 'لم يتم إدخال آخر 4 أرقام (حقل w).',
			'entry in int_in file is shorter than 16 characters.': 'الإدخال في المجلد int_in أقصر من 16 حرفًا.',
			'the input file (int_in) does not exist.': 'ملف الإدخال (IN_INT) غير موجود.',
			'blocked cards file (neg) does not exist or has not been updated, transmit or request authorization for each transaction.': 'ملف البطاقة المحظورة (NEG) غير موجود أو لم يتم تحديثه، أرسل أو اطلب تصريحًا للمعاملة.',
			'one of the parameter files/vectors does not exist.': 'أحد ملفات المعلمات / المتجهات غير موجود.',
			'date file (data) does not exist.': 'ملف البيانات (DATA) غير موجود.',
			'format file (start) does not exist.': 'ملف التنسيق (START) غير موجود.',
			'the difference in days in the blocked cards input is too large, transmit or request authorization for each transaction.': 'الفرق في الأيام في مدخلات البطاقات المحظورة كبير جدًا، أرسل أو اطلب تصريحًا للمعاملة.',
			'the difference in generations in the blocked cards input is too large, transmit or request authorization for each transaction.': 'الفرق في الأجيال في مدخلات البطاقات المحظورة كبير جدًا، أرسل أو اطلب تصريحًا للمعاملة.',
			'when the magnetic strip is not completely entered, define the transaction as a telephone number or signature only.': 'عند عدم إدخال الشريط المغناطيسي بالكامل، حدد المعاملة كرقم هاتف أو توقيع فقط.',
			'the central terminal number was not entered into the defined main supplier terminal.': 'لم يتم إدخال رقم الجهاز المركزي في جهاز المورد الرئيسي.',
			'the beneficiary number was not entered into the defined main beneficiary terminal.': 'لم يتم إدخال رقم المستفيد في الجهاز المحدد للمستفيد الرئيسي.',
			'the supplier/beneficiary number was entered, however the terminal was not updated as the main supplier/beneficiary.': 'تم إدخال رقم المورد / المستفيد، ولكن لم يتم تحديث الجهاز كمورد / مستفيد رئيسي.',
			'the beneficiary number was entered, however the terminal was updated as the main supplier': 'تم إدخال رقم المستفيد، ولكن لم يتم تحديث الجهاز كمورد رئيسي.',
			'old transactions, transmit or request authorization for each transaction.': 'معاملات قديمة، أرسل أو اطلب تصريحًا للمعاملة.',
			'defective card.': 'رقم بطاقة ائتمانية غير صالح. يُرجى المحاولة مجددًا.',
			'this card is not permitted for this terminal or is not authorized for this type of transaction.': 'هذه البطاقة غير مصرح باستخدامها في هذا الجهاز أو غير مصرح لها بإجراء هذا النوع من المعاملات.',
			'this card is not permitted for this transaction or type of credit.': 'هذه البطاقة غير مصرح لها بإجراء هذه المعاملة مع هذا النوع من الائتمان.',
			'expired card.': 'بطاقة منتهية الصلاحية.',
			'expired token': 'رمز منتهي الصلاحية',
			'installment error, the amount of transactions needs to be equal to: first installment plus fixed installments times number of installments.': 'خطأ في الدفع، يجب أن يكون مبلغ المعاملات مساويًا للدفعة الأولى زائد الدفعات الثابتة مضروبةً في عدد المدفوعات.',
			'unable to execute a debit transaction that is higher than the credit card`s ceiling.': 'لا يمكن إجراء معاملة خصم أعلى من سقف بطاقة الائتمان.',
			'incorrect control number.': 'رقم تحكم غير صحيح.',
			'the beneficiary and supplier numbers were entered, however the terminal is defined as main.': 'تم إدخال أرقام المورد والمستفيد، لكن تم تحديد الجهاز كجهاز رئيي.',
			'the transaction`s amount exceeds the ceiling when the input file contains j1, j2 or j3 (contact prohibited).': 'يتجاوز مبلغ المعاملة الحد الأقصى عندما يحتوي ملف الإدخال على j1 أو j2 أو j3 (الاتصال محظور).',
			'the card is blocked for the supplier where input file contains j1, j2 or j3 (contact prohibited).': 'يتم حظر البطاقة للمورد عندما يحتوي ملف المدخلات على j1 أو j2 أو j3 (الاتصال محظور).',
			'random where input file contains j1 (contact prohibited).': 'يكون عشوائيًا عندما يحتوي ملف المدخلات على j1 (الاتصال محظور).',
			'the terminal is prohibited from requesting authorization without transaction (j5).': 'الجهاز ممنوع من طلب تصريح بدون معاملة (j5).',
			'the terminal is prohibited for supplier-initiated authorization request (j6).': 'الجهاز ممنوع من طلب تصريح من المورد (j6).',
			'the terminal must request authorization where the input file contains j1, j2 or j3 (contact prohibited).': 'يجب أن يطلب الجهاز تصريحًا عندما يحتوي ملف المدخلات على j1 أو j2 أو j3 (الاتصال محظور).',
			'secret code must be entered where input file contains j1, j2 or j3 (contact prohibited).': 'يجب إدخال الرمز السري عندما يحتوي ملف المدخلات على j1 أو j2 أو j3 (الاتصال محظور).',
			'incorrect vehicle number.': 'رقم المركبة غير صحيح.',
			'the number of the distance meter was not entered.': 'لم يتم إدخال رقم عداد المسافات.',
			'the terminal is not defined as gas station (petrol card or incorrect transaction code was used).': 'لم يتم تحديد الجهاز على أنه محطة غاز (تم استخدام بطاقة وقود أو رمز معاملة غير صحيح).',
			'an id number is required (for israeli cards only) but was not entered.': 'لم يتم إدخال رقم بطاقة الهوية.',
			'cvv is required but was not entered.': 'لم يتم إدخال cvv',
			'cvv and id number are required (for israeli cards only) but were not entered.': 'لم يتم إدخال cvv ورقم بطاقة الهوية.',
			'abs attachment was not found at the beginning of the input data in memory.': 'لم يتم العثور على مرفق ABS في بداية بيانات المدخلات في الذاكرة.',
			'the card number was either not found or found twice.': 'رقم البطاقة إما لم يتم العثور عليه أو تم العثور عليه مرتين.',
			'incorrect transaction type.': 'نوع المعاملة غير صحيح.',
			'incorrect transaction code.': 'رمز المعاملة غير صحيح.',
			'incorrect credit type.': 'نوع الائتمان غير صحيح.',
			'incorrect currency.': 'عملة غير صحيحة.',
			'the first installment and/or fixed payment are for non-installment type of credit.': 'هناك دفعة أولى و/أو دفعة ثابتة لنوع ائتمان غير مقسط.',
			'number of installments exist for the type of credit that does not require this.': 'يوجد عدد من الأقساط لنوع الائتمان الذي لا يتطلب ذلك.',
			'linkage to dollar or index is possible only for installment credit.': 'لا يمكن ربط الدولار أو المؤشر بائتمان مختلف عن الدفعات فقط.',
			'the magnetic strip is too short.': 'طول الشريط المغناطيسي قصير جدًا.',
			'the pin code device is not defined.': 'لم يتم تعريف جهاز لإدخال رمز التعريف الشخصي.',
			'must enter the pin code number.': 'يجب إدخال رمز التعريف الشخصي.',
			'smart card reader not available - use the magnetic reader.': 'قارئ البطاقات الذكية غير متاح - استخدم القارئ المغناطيسي.',
			'must use the smart card reader.': 'يجب استخدام قارئ البطاقات الذكية.',
			'denied - locked card.': 'عملية مرفوضة - البطاقة مقفلة.',
			'denied - smart card reader action didn\'t end in the correct time.': 'عملية مرفوضة - لم ينته إجراء قارئ البطاقات الذكية في الوقت المناسب.',
			'denied - data from smart card reader not defined in system.': 'عملية مرفوضة - لم يتم تعريف قارئ البطاقات الذكية في النظام.',
			'incorrect pin code.': 'رمز التعريف الشخصي غير صحيح.',
			'currency does not exist in vector 59.': 'العملة غير موجودة في المتجه 59.',
			'the club code entered does not match the credit type.': 'رمز النادي الذي تم إدخاله لا يتطابق مع نوع الائتمان.',
			'cannot cancel charge transaction. please create a credit transaction.': 'لا يمكن إلغاء معاملة الرسوم. يُرجى إنشاء معاملة ائتمان.',
			'unable to read/write/open the tran file.': 'لا يمكن قراءة / كتابة / فتح ملف TRAN.',
			'no authorization from credit company for clearance.': 'لا يوجد تصريح من شركة الائتمان للتسوية.',
			'the terminal is not permitted to send queries for immediate debit cards.': 'غير مصرح للجهاز بإرسال استفسارات بشأن الخصم الفوري.',
			'the transaction amount is too large, divide it into a number of transactions.': 'قيمة المعاملة كبيرة جدًا، قسّمها على عدد من المعاملات.',
			'the terminal is not authorized to execute forced transactions.': 'غير مصرح للجهاز بتنفيذ معاملات قسرية.',
			'the terminal is not authorized for cards with service code 587.': 'غير مصرح للجهاز بالتعامل مع بطاقات برمز خدمة 587.',
			'the terminal is not authorized for immediate debit cards.': 'غير مصرح للجهاز بالتعامل مع بطاقات الخصم الفوري.',
			'the terminal is not authorized for installment transactions.': 'غير مصرح للجهاز بتنفيذ معاملات دفع بأقساط.',
			'the terminal is authorized for installment transactions only, not telephone/signature.': 'غير مصرح للجهاز بتنفيذ معاملات هاتفية / توقيع إلا بأقساط فقط.',
			'the terminal is not authorized for telephone transactions.': 'غير مصرح للجهاز بتنفيذ معاملات هاتفية.',
			'the terminal is not authorized for signature-only transactions.': 'غير مصرح للجهاز بتنفيذ معاملات التوقيع فقط".',
			'the terminal is not authorized for foreign currency transactions, or transaction is not authorized.': 'غير مصرح للجهاز بتنفيذ معاملات بعملات أجنبية أو غير مصرح بالمعاملة.',
			'the terminal is not authorized for club transactions.': 'غير مصرح للجهاز بتنفيذ معاملات النادي.',
			'the terminal is not authorized for star/point/mile transactions.': 'غير مصرح للجهاز بتنفيذ معاملات نجوم / نقاط / أميال.',
			'the terminal is not authorized for isracredit credit.': 'غير مصرح للجهاز بالتعامل مع بطاقات إيسراكريديت.',
			'the terminal is not authorized for amex credit.': 'غير مصرح للجهاز بالتعامل مع بطاقات أميكس.',
			'the terminal is not authorized for dollar linkage.': 'غير مصرح للجهاز بربط الدولار.',
			'the terminal is not authorized for index linkage.': 'غير مصرح للجهاز بربط المؤشرات.',
			'the terminal is not authorized for index linkage with foreign cards.': 'غير مصرح للجهاز بربط المؤشرات ببطاقات أجنبية.',
			'the terminal is not authorized for star/point/mile transactions for this type of credit.': 'غير مصرح للجهاز بتنفيذ معاملات نجوم / نقاط / أميال لهذا النوع من الائتمان.',
			'the terminal is not authorized for isra 36 credit.': 'غير مصرح للجهاز بالتعامل مع بطاقات إيسرا 36.',
			'the terminal is not authorized for amex 36 credit.': 'غير مصرح للجهاز بالتعامل مع بطاقات أميكس 36.',
			'the terminal is not authorized for this club code.': 'غير مصرح للجهاز بالتعامل مع رمز النادي هذ.',
			'the terminal is not authorized for immediate debit transactions (except for immediate debit cards).': 'غير مصرح للجهاز بتنفيذ معاملات الخصم الفوري (باستثناء بطاقات الخصم الفوري).',
			'the terminal is not authorized to accept visa card staring with 3.': 'غير مصرح للجهاز بقبول بطاقات فيزا تبدأ برقم 3.',
			'the terminal is not authorized to execute credit transactions above the ceiling.': 'غير مصرح للجهاز بتنفيذ معاملات ائتمان أعلى من الحد الأقصى.',
			'the card is not permitted to execute club transactions.': 'غير مصرح للبطاقة بتنفيذ معاملات النادي.',
			'the card is not permitted to execute star/point/mile transactions.': 'غير مصرح للبطاقة بتنفيذ معاملات النجوم / النقاط / الأميال.',
			'the card is not permitted to execute dollar transactions (regular or telephone).': 'غير مصرح للبطاقة بتنفيذ معاملات الدولار (العادية أو الهاتفية).',
			'the card is not valid according to isracard`s list of valid cards.': 'بطاقة غير صالحة وفقًا لقائمة إيسراكارد للبطاقات الصالحة.',
			'defective card according to system definitions (isracard vector1), error in the number of figures on the card.': 'بطاقة معيبة وفقًا لتعريفات النظام (VECTOR1 إيسراكارد)، خطأ في عدد الخانات في البطاقة.',
			'the card is not permitted to execute dollar transactions according to system definitions (isracard vector1).': 'غير مصرح للبطاقة بتنفيذ معاملات بالدولار وفقًا لتعريفات النظام (إVECTOR1 إيسراكارد).',
			'the card belongs to a group that is not permitted to execute transactions according to system definitions (visa vector 20).': 'تنتمي البطاقة لمجموعة غير مصرح لها بتنفيذ معاملات وفقًا لتعريفات النظام (VECTOR20 فيزا).',
			'the card`s prefix (7 figures) is invalid according to system definitions (diners vector21).': 'بادئة البطاقة (7 أرقام) غير صالحة وفقًا لتعريفات النظام (21VECTOR التابعة لداينرز).',
			'the card is not permitted to carry out installment transactions according to isracard`s list of valid cards.': 'غير مصرح للبطاقة بتنفيذ معاملات قسط وفقًا لقائمة إيسراكارد للبطاقات الصالحة.',
			'the number of installments is too large according to isracard`s list of valid cards.': 'عدد المدفوعات طويل جدًا وفقًا لقائمة إيسراكارد للبطاقات الصالحة.',
			'visa and diners cards are not permitted for club installment transactions.': 'غير مصرح لبطاقات فيزا وداينرز بتنفيذ معلومات مدفوعات النادي.',
			'series of cards are not valid according to system definition (isracard vector5).': 'سلسلة البطاقات غير صالحة وفقًا لتعريف النظام (VECTOR5 إيسراكارد).',
			'invalid service code according to system definitions (isracard vector6).': 'رمز الخدمة غير صالح وفقًا لتعريفات النظام (VECTOR6 إيسراكارد).',
			'the card`s prefix (2 figures) is invalid according to system definitions (isracard vector7).': 'بادئة البطاقة (رقمان) غير صالحة وفقًا لتعريفات النظام (VECTOR6 إيسراكارد).',
			'invalid service code according to system definitions (visa vector12).': 'رمز الخدمة غير صالح وفقًا لتعريفات النظام (VECTOR12 فيزا).',
			'invalid service code according to system definitions (visa vector13).': 'رمز الخدمة غير صالح وفقًا لتعريفات النظام (VECTOR13 فيزا).',
			'immediate debit card is prohibited for executing credit transaction.': 'بطاقات الخصم الفوري ممنوعة من تنفيذ معاملات ائتمانية.',
			'the card is not permitted to execute installment transactions according to alpha vector no. 31.': 'غير مصرح للبطاقة بتنفيذ معاملات دفع وفقًا لألفا فيكتور 31.',
			'the card is not permitted for telephone and signature-only transactions according to alpha vector no. 31.': 'غير مصرح للبطاقة بتنفيذ المعاملات الهاتفية ومعاملات التوقيع فقط وفقًا لألفا فيكتور 31.',
			'the card is not permitted for telephone transactions according to alpha vector no. 31.': 'غير مصرح للبطاقة بتنفيذ معاملات هاتفية وفقًا لألفا فيكتور 31.',
			'credit is not approved for immediate debit cards.': 'غير مصرح بالائتمان لبطاقات الخصم الفوري.',
			'credit is not approved for foreign cards.': 'غير مصرح بالائتمان للبطاقات الأجنبية.',
			'incorrect club code.': 'رمز النادي غير صحيح.',
			'the card is not permitted to execute flexible credit transactions (adif/30+) according to system definitions (diners vector21).': 'غير مصرح للبطاقة بتنفيذ معاملات ائتمانية مرنة (أديف / 30+) وفقًا لتعريفات النظام (21VECTOR التابعة لداينرز).",.',
			'the card is not permitted to execute immediate debit transactions according to system definitions (diners vector21).': 'غير مصرح للبطاقة بتنفيذ معاملات الخصم الفوري وفقًا لتعريفات النظام (21VECTOR التابعة لداينرز).',
			'the payment amount is too low for credit transactions.': 'المبلغ المطلوب دفعه صغير جدًا للمعاملات الائتمانية.',
			'incorrect number of installments for credit transaction.': 'رقم غير صحيح لمدفوعات المعاملات الائتمانية.',
			'zero ceiling for this type of card for regular credit or credit transaction.': 'الحد الأقصى 0 لهذا النوع من البطاقات في الائتمان العادي أو المعاملات الائتمانية.',
			'zero ceiling for this type of card for immediate debit credit transaction.': ' الحد الأقصى 0 لهذا النوع من البطاقات في معاملات بطاقات الخصم الفوري.',
			'zero ceiling for this type of card for immediate debit in dollars.': ' الحد الأقصى 0 لهذا النوع من البطاقات في الخصم الفوري بالدولار.',
			'zero ceiling for this type of card for telephone transaction.': 'الحد الأقصى 0 لهذا النوع من البطاقات في المعاملات الهاتفية',
			'zero ceiling for this type of card for credit transaction.': 'الحد الأقصى 0 لهذا النوع من البطاقات في المعاملات الائتمانية',
			'zero ceiling for this type of card for installment transaction.': 'الحد الأقصى 0 لهذا النوع من البطاقات في معاملات الدفع.',
			'american express card issued abroad not permitted for instalments transaction.': 'غير مصرح لبطاقات أمريكان إكسبريس الصادرة في الخارج بتنفيذ معاملات دفع.',
			'jcb cards are only permitted to carry out regular credit transactions.': 'غير مصرح لبطاقات JCB إلا بتنفيذ المعاملات الائتمانية العادية.',
			'the amount in stars/points/miles is higher than the transaction amount.': 'القيمة بالنجوم / النقاط / الأميال أكبر من قيمة المعاملة.',
			'the club card is not within terminal range.': 'بطاقة النادي ليست في حدود نطاق الجهاز.',
			'star/point/mile transactions cannot be executed.': 'لا يمكن تنفيذ معاملات النجوم / النقاط / الأميال بالدولارات.',
			'dollar transactions cannot be executed for this type of card.': 'لا يمكن تنفيذ المعاملات بالدولار لهذا النوع من البطاقات.',
			'credit transactions cannot be executed with other than regular credit.': 'لا يمكن تنفيذ معاملات ائتمانية بائتمان غير عادي',
			'amount of discount on stars/points/miles is higher than the permitted.': 'قيمة الخصم بالنجوم / النقاط / الأميال أكبر من القيمة المصرح بها.',
			'forced transactions cannot be executed with credit/immediate debit card.': 'لا يمكن تنفيذ معاملات قسرية ببطاقة ائتمان / خصم فوري.',
			'the previous transaction cannot be cancelled (credit transaction or card number are not identical).': 'لا يمكن إلغاء المعاملة السابقة (المعاملة الائتمانية أو رقم البطاقة غير مطابقين).',
			'double transaction.': 'معاملة مزدوجة.',
			'the terminal is not permitted for index linkage of this type of credit.': 'غير مصرح للجهاز بربط المؤشرات لهذا النوع من الائتمان.',
			'the terminal is not permitted for dollar linkage of this type of credit.': 'غير مصرح للجهاز بربط الدولار لهذا النوع من الائتمان.',
			'the card is invalid according to system definitions (isracard vector1).': 'البطاقة غير صالحة وفقًا لتعريفات النظام (إيسراكارد فيكتور 1).',
			'unable to execute the self-service transaction if the gas station does not have self service.': 'لا يمكن تنفيذ معاملة \"خدمة ذاتية\" إذا لم يكن لدى محطة الوقود خدمة ذاتية\".',
			'credit transactions are forbidden with stars/points/miles.': 'المعاملات الائتمانية ممنوعة بالنجوم / النقاط / الأميال.',
			'dollar credit transactions are forbidden on tourist cards.': 'المعاملات الائتمانية بالدولار ممنوعة بالبطاقات السياحية.',
			'phone transactions are not permitted on club cards.': 'لا يمكن إجراء معاملات هاتفية ببطاقات النادي.',
			'application error.': 'خطأ في التطبيق.',
			'transaction amount missing or zero.': 'مبلغ المعاملة مفقود أو صفر.',
			'no communication to pelecard.': 'لا يوجد اتصال لبطاقة بيلي كارد.',
			'doubled transaction.': 'معاملة مزدوجة.',
			'terminal number does not exist.': 'رقم الجهاز غير موجود.',
			'terminal executes broadcast and/or updating data. please try again later.': 'الجهاز ينفذ إرسال و/أو تحديث بيانات. يُرجى إعادة المحاولة لاحقًا.',
			'user name and/or password not correct. please call support team.': 'اسم المستخدم و/أو كلمة المرور غير صحيحة. يُرجى الاتصال بفريق الدعم.',
			'user password has expired. please contact support team.': 'انتهت صلاحية كلمة مرور المستخدم. يُرجى الاتصال بفريق الدعم.',
			'please enter confirm password': 'يُرجى إدخال تأكيد كلمة المرور',
			'the passwords must match': 'يجب أن تتطابق كلمات المرور',
			'locked user. please contact support team.': 'مستخدم محظور. يُرجى الاتصال بفريق الدعم.',
			'blocked terminal. please contact account team.': 'جهاز محظور. يُرجى الاتصال بفريق الحسابات.',
			'token number abnormal.': 'رقم الرمز غير صحيح.',
			'user is not authorized in this terminal.': 'غير مصرح للمستخدم باستخدام هذا الجهاز.',
			'validity structure invalid. use mmyy structure only.': 'هيكل الصلاحية غير صحيح. استخدم هيكل MMYY فقط.',
			'ssl verifying access is blocked. please contact support team.': 'تم حظر التحقق من البطاقة الأمنية. يُرجى الاتصال بفريق الدعم.',
			'data not exist.': 'لا توجد بيانات.',
			'general error. please contact support team.': 'خطأ عام. يُرجى الاتصال بفريق الدعم.',
			'necessary values are blocked/wrong.': 'القيم الضرورية مفقودة / غير صحيحة.',
			'general error. repeat action.': 'خطأ عام. أعد المحاولة.',
			'necessary values missing to complete installments transaction': 'القيم الضرورية مفقودة لإتمام معاملة الدفع.',
			/*end pele card errors*/

			'removed': 'تمت الإزالة',

			'type in zip code': 'اكتب الرمز البريدي',
			'type in city': 'اكتب المدينة',
			'type your address': 'اكتب عنوانك',
			'or choose branch for pickup': 'أو اختر فرع للاستلام',
			'pickup the order from one of the branches at your time': 'استلم طلبك من أحد فروعنا في الوقت الذي يناسبك',
			'we serve your area': 'نقدم خدماتنا في منطقتك',
			'pickup service only': 'خدمة الاستلام فقط',
			'<< back': '<< عودة',
			'limited to {deliveryitemslimit} products': 'يقتصر على {deliveryitemslimit} من المنتجات',
			'limited to products marked with "{tagname}" tag only': 'يقتصر على المنتجات الموضوع عليها علامة "{tagname}" فقط',
			'has no items limit': 'ليس لديها حد للعناصر',
			'choose the pickup location': 'اختر موقع الاستلام',
			'products limit': 'حد المنتجات',
			'this delivery has a limit of {deliveryitemslimit} products': 'هذا التوصيل لديه حد يبلغ {deliveryitemslimit} من المنتجات',
			'if you wish to add another product, remove one from the cart first': 'إذا كنت تريد إضافة منتج آخر، قم بإزالة منتج من العربة أولًا',
			'continue': 'متابعة',
			'or change the delivery method': 'أو قم بتغيير طريقة التوصيل',
			'change delivery method': 'تغيير طريقة التوصيل',
			'are you sure you want to change the delivery type': 'هل أنت متأكد أنك تريد تغيير نوع التوصيل',
			'changes in delivery times may apply': 'قد يتم إجراء تغييرات على أوقات التوصيل',
			'changes in inventory between the stores may apply': 'قد يكون اختلاف بالمخزون بين المتاجر',
			'yes, change delivery method': 'نعم، قم بتغيير طريقة التوصيل',
			'no, stay in current delivery': 'لا، تابع في طريقة التوصيل الحالية',
			'unfortunately we didn\'t find any stores in this area': 'للأسف لم نجد أي متاجر في هذه المنطقة',
			'try a different area name': 'جرّب اسم منطقة أخرى',
			'please type a valid address': 'يُرجى إدخال عنوان صحيح',
			'please add street and house number': 'يُرجى إضافة الشارع ورقم المنزل',
			'delivery method in this area': 'طريقة التوصيل في هذه المنطقة',
			'apologies': 'اعتذارات',
			'sorry, we do not deliver to your area': 'معذرة، حاليًا نحن لا نقوم بعمليات توصيل إلى منطقتك',
			'please type in your email and we will let you know when we expand to your location': 'يُرجى ترك بريدك الإلكتروني وسنخبرك عندما نتوسع ونصل إلى منطقتك',
			'try a different address': 'جرّب عنوان مختلف',
			'try a different city': 'جرّب مدينة مختلفة',
			'try a different zip code': 'جرّب رمز بريدي مختلف',
			'keep in touch': 'ابقوا على اتصال',
			'your chosen delivery type is': 'نوع التوصيل المحدد هو',
			'change to ': 'تغيير إلى',
			'branch': 'الفرع',
			'from branch': 'من الفرع',
			'delivery products limit exceeded': 'تم تجاوز حد منتجات التوصيل',
			'see you soon': 'نراك قريبًا',
			'the order will wait for you at the store': 'سيكون الطلب في انتظارك في المتجر',
			'nagish_close_component': 'إخفاء',
			'chametz': 'منتجات الخميرة',
			'passoverkosher': 'طعام الكوشر لعيد الفصح',
			'passoverkoshertemporarily': 'طعام الكوشر المؤقت لعيد الفصح',
			'passoverkitniyotkosher': 'طعام الكوشر لعيد الفصح (البقول)',
			'passoverkitniyotkoshertemporarily': 'طعام الكوشر المؤقت لعيد الفصح (البقول)',
			'meat': 'اللحوم  في عيد الفصح',
			'milky': 'الألبان في عيد الفطر',
			'pareve': 'أكلات البرف في عيد الفصح',
			'israelmilk': 'منتجات الألبان',
			'israelcooking': 'الطهي الإسرائيلي',
			'sabbathobservingfactory': 'مطعم متابعة جودة أطعمة يوم السبت',
			'noshviitconcern': 'لا توجد مخاوف بشأن شيمتا',
			'noorlaconcern': 'لا توجد مخاوف بشأن أورالا',
			'notevelconcern': 'لا توجد مخاوف بشأن السفر',
			'noovernightconcern': 'لا توجد مخاوف ليلًا',
			'nogeniza': 'لا جينيزا',
			'recipes and allergies': 'الوصفات والمواد المسببة للحساسية',
			'list of ingredients': 'قائمة المواد المسببة للحساسية',
			'allergies contained': 'المنتج يحتوي على مواد مسببة للحساسية',
			'possible allergies': 'المواد المسببة للحساسية التي قد تكون موجودة',
			'about the product': 'عن المنتج',
			'extra info and instructions': 'المزيد من المعلومات والتعليمات',
			'supervisions': 'الإشراف',
			'usage warnings': 'تحذيرات الاستخدام',
			'storage instructions': 'تعليمات التخزين',
			'usage instructions': 'تعليمات تحضير واستخدام المنتج',
			'fat percentage': 'نسبة الدهون',
			'cream percentage': 'نسبة الكريم',
			'fruit percentage': 'نسبة الفاكهة',
			'alcohol percentage': 'نسبة الكحول',
			'ph': 'الرقم الهيدروجيني (pH)',
			'country of origin': 'بلد المنشأ',
			'date taken into effect': 'تاريخ النفاذ',
			'height': 'الارتفاع',
			'depth': 'العمق',
			'width': 'العرض',
			'net width': 'العرض الصافي',
			'gross width': 'العرض الإجمالي',
			'general information': 'معلومات عامة',
			'nutrition values': 'القيمة الغذائية',
			'the waiting period will not exceed two hours': 'التسليم في غضون ساعتين',
			'at_hour': 'في',
			'special_reminder_bubble_title': 'حفظ!',
			'special_reminder_awarded_bubble_title': 'لقد تم الحفظ!',
			'special_reminder_button_title': 'انقر واحفظ!!',
			'add products to earn this promotion': 'أضف منتجات للحصول على هذا العرض الترويجي',
			'done': 'تم',
			'select your benefit': 'حدد الميزة',
			'show more specials': 'عرض المزيد من العروض الخاصة',
			'show less specials': 'عرض عروض خاصة أقل',
			'coupon_requirements_error': 'لم تستوفي المتطلبات',
			'add products': 'إضافة منتجات',
			'remaining credit': 'الرصيد المتبقي',
			'budget': 'الميزانية',
			'cart amount higher': 'مبلغ العربة أكبر',
			'than your credit': 'من رصيدك',
			'edit cart': 'تعديل العربة',
			'cart total': 'الإجمالي في العربة',
			'to complete the purchase, remove products from the cart valued at': 'لإتمام عملية الشراء، قم بإزالة منتجات من العربة بقيمة',
			'this user is not set as credit customer': 'لم يتم تحديد هذا المستخدم على أنه عميل ائتمان',
			'cart amount higher than your credit. to proceed please remove at least': 'مبلغ العربة أكبر من رصيدك. للمتابعة، يُرجى إزالة ما لا يقل عن',
			'please verify that you are not a robot': 'يُرجى تأكيد أنك لست روبوت',
			'all conditions must be marked in order to complete the order': 'يجب وضع علامة على جميع الشروط لإتمام الطلب',
			'similar products': 'منتجات مماثلة',
			'additional special\'s variety': 'مجموعة متنوعة من العروض الخاصة',
			'select from variety': 'اختر من بين مجموعة متنوعة',
			'product info disclaimer': 'يمكن ألا تكون معلومات المنتج أو التغليف الظاهرة غير موجودة أو كاملة. يُرجى الرجوع دومًا إلى المنتج الفعلي للحصول على أدق المعلومات والتحذيرات. لمزيد من المعلومات، اتصل ببائع التجزئة أو المُصنع.',
			'employee discount': 'خصم الموظف',
			'card id': 'رقم البطاقة',
			'discount': 'الخصم',
			'remaining obligo': 'الميزانية المتبقية',
			'monthly obligo': 'الميزانية الشهرية',
			'club name': 'اسم النادي',
			'obligo type': 'نوع الميزانية',
			'monthly discount obligo': 'ميزانية الخصم الشهري',
			'cardid: required error': 'رقم البطاقة: خطأ عدم الإدخال',
			'pin: required error': 'يُرجى إدخال رقم Pin',
			'employee discount card holder?': 'هل تحمل بطاقة خصم موظف؟',
			'employee discount card data': 'بيانات بطاقة خصم موظف',
			'employee discount account not found': 'لم يتم العثور على حساب خصم موظف',
			'estimated employee savings': 'التوفيرات المقدرة للموظف',
			'unlimited': 'غير محدود',
			'getting data...': 'جارِ الحصول على البيانات...',
			'store is filtered': 'تمت فلترة المتجر',
			'what\'s your lifestyle?': 'ما هو نمط حياتك؟',
			'filter entire store': 'فلترة المتجر بأكمله',
			'filter the entire store according to your preferred lifestyle or diet!': 'فلترة المتجر بأكمله حسب نمط حياتك المفضل أو نظامك الغذائي!',
			'filtering store': 'فلترة المتجر',
			'the entire store is now filtered by': 'تمت فلترة المتجر بأكمله حسب',
			'clear all filters': 'امسحوا جميع الفلاتر',
			'back to filters': 'العودة إلى الفلاتر',
			'all filters': 'جميع الفلاتر',
			'filters': 'أيقونة فلاتر',
			'show more': 'عرض المزيد',
			'show less': 'تقليص',
			'permanent_filters_legal_text': 'عند استخدام خيارات الفلترة المتنوعة المقدمة على الموقع، لا تقدم الشركة ضمانًا بعدم وجود أي خلل و/أو أخطاء على الموقع، بما في ذلك أداء الفلترة المحددة. لا تتعهد الشركة بأن تصنيف المنتجات سيكون دقيقًا ويستثني المنتجات التي لا تلبي المعايير كما لا تلبي جميع المنتجات الموجودة المعايير. تقع مسؤولية استخدام المعلومات التي تم الحصول عليها على عاتقك فقط، ويشمل ذلك أي ضرر ينجم عن استخدام المعلومات أو الاستناد عليها. يجب اختبار كل منتج ومكوناته على حدة. ستتحمل أنت مسؤولية التحقق من أي معلومات مقارنة ببيانات المُصنع لهذا المنتج، وفي حالة وجود أي تعارض بين المعلومات المقدمة على الموقع والمقدمة من المُصنع، فيجب اتباع المعلومات المقدمة من المُصنع. لا يشكل ما سبق أي مسؤولية، سواءً كانت أضرار مباشرة أو غير مباشرة، على عاتق الشركة فيما يتعلق بالمعلومات الموضحة من المُصنع لأي منتج..',
			'we couldn\'t find any products': 'لم نتمكن من العثور على أي منتجات',
			'we couldn\'t find any specials': 'لم نتمكن من العثور على أي عروض خاصة',
			'try editing your filters': 'جرّب تعديل فلاترك',
			'clear_all_filters_2': 'مسح جميع الفلاتر',
			'or': 'أو',
			'edit global filters': 'تعديل الفلاتر العامة',

			'verify account': 'تأكيد الحساب',
			'you are now registered': 'تم التسجيل بنجاح',
			'please verify your account': 'يُرجى تأكيد حسابك',
			'there was a problem verifying your account': 'حدثت مشكلة أثناء التحقق من حسابك',
			'please contact customer service': 'يُرجى الاتصال من خدمة العملاء',
			'before your first purchase, a verification code will be sent to your mobile phone': 'قبل أول عملية شراء لك، سيتم إرسال رمز تحقق إلى هاتفك المحمول',
			'please enter your phone number': 'يُرجى إدخال رقم هاتفك',
			'send sms': 'أرسل رسالة sms',
			'enter the code that was sent to your phone': 'أدخل الرمز الذي تم إرساله إلى هاتفك',
			'change number': 'تغيير الرقم',
			'verify': 'تحقق',
			'i didn\'t receive the code (resend)': 'لم أستلم الرمز (أعد الإرسال)',
			'the code is invalid or expired': 'الرمز غير صحيح أو منتهي الصلاحية',
			'your account is verified': 'تم التحقق من حسابك',
			'invalid verification code': 'رمز التحقق غير صحيح',

			'loyalty club details': 'تفاصيل حساب نادي الولاء',
			'id': 'رقم المعرف',
			'loyalty terms': 'شروط نادي الولاء',
			'please enter id': 'يُرجى إدخال رقم التعريف',
			'invalid mobile phone': 'رقم هاتف غير صالح',
			'please confirm that you accept the terms': 'يُرجى التأكيد أنك توافق على الشروط',
			'member number': 'رقم العضو',
			'you have successfully registered': 'لقد قمت بالتسجيل بنجاح',
			'for the customer club': 'في نادي العملاء',
			'for the customer club and the website': 'في نادي العملاء والموقع الإلكتروني',
			'website registration': 'التسجيل في الموقع الإلكتروني',
			'please enter a 9-digit number': 'يُرجى إدخال الرقم المكون من 9 أرقام',
			'only digits should be typed': 'يجب كتابة الأرقام فقط',
			'please let me know about promotions on site and club': 'يُرجى إخباري بالعروض الترويجية على الموقع والنادي',
			'organization management': 'إدارة المؤسسة',
			'payment by organization credit': 'الدفع عن طريق رصيد المؤسسة',
			'sorry, your organization account is inactive': 'معذرة، حساب مؤسستك غير نشط',
			'sorry, there are no branches assigned to this organization': 'معذرة، لا توجد فروع مرتبطة بهذه المؤسسة',
			'please contact your organization admin': 'يُرجى الاتصال بمشرف مؤسستك',
			'credit cards can not be added. you are assigned to an organization': 'لا يمكن إضافة بطاقات ائتمان. لقد تم ربطك بمؤسسة',

			'cancel order': 'إلغاء الطلب',
			'are you sure you want to cancel this order': 'هل أنت متأكد أنك تريد إلغاء هذا الطلب',
			'order #': 'الطلب رقم',
			'keep the order': 'احتفظ بالطلب',
			'your order was cancelled': 'تم إلغاء طلبك',
			'this order will not be charged': 'لن يتم تحصيل رسوم على هذا الطلب',
			'your payment method will not be charged': 'لن يتم تحصيل رسوم من طريقة الدفع الخاصة بك',
			'a confirmation email was sent to {email}': 'تم إرسال رسالة تأكيد عبر البريد الإلكتروني {email}',
			'no available deliveries in the upcoming days': 'لا توجد عمليات توصيل متاحة في الأيام القادمة',
			'required error': '{{name}} مطلوب',
			'no data on this order': 'لا توجد بيانات لهذا الطلب',
			'payment failed': 'فشل الدفع',
			'back to payment': 'عودة إلى الدفع',
			'remove item from cart': 'إزالة العنصر من العربة',
			'to the selected delivery area we only send products marked with "{tagname}" tag': 'لا نرسل سوى المنتجات الموضوع عليها علامة "{tagname}" فقط إلى منطقة التوصيل المحددة',
			'you either remove the product from the cart or change the delivery area': 'يمكنك إما إزالة المنتج من العربة أو تغيير منطقة التوصيل',
			'or change the delivery area': 'أو تغيير منطقة التوصيل',
			'we will contact you in order to schedule the delivery hours': 'سنتصل بك لتحديد موعد التوصيل',
			'delivery price': 'سعر التوصيل',
			'ordered': 'تم الطلب',
			'address_warning_enter_house_num': 'يُرجى إدخال رقم منزلك',
			'so we can assign you to the right branch': 'لكي نتمكن من ربطك بالفرع المناسب',
			'items to cart': 'العناصر إلى السلة',

			'your products from a website': 'منتجاتك من موقع إلكتروني',
			'inventory availability may vary according to the delivery area': 'قد يختلف توافر المخزون حسب منطقة التوصيل',
			'please note - we could not identify {number}': 'برجاء العلم - لم نتمكن من تحديد {number}',
			'more products': 'المزيد من المنتجات',
			'more product': 'منتج آخر',
			'from the {name} website': 'من الموقع الإلكتروني {name}',
			'out of stock': 'نفد من المخزون',
			'added to cart': 'تمت الإضافة إلى العربة',
			'finish & go to cart': 'الانتهاء والانتقال إلى العربة',
			'the site is active only for organization users': 'الموقع غير نشط إلا لمستخدمي المؤسسة',
			'please login': 'يُرجى تسجيل الدخول',
			'please login or register': 'يُرجى تسجيل الدخول أو التسجيل',
			'sorry, this website is for organization users only': 'معذرة، هذا الموقع الإلكتروني مخصص لمستخدمي المؤسسة فقط',
			'you user is not assigned to an organization': 'المستخدم الخاص بك غير مرتبط بمؤسسة',
			'sorry, you can not register with this email address': 'معذرة، لا يمكنك التسجيل بعنوان البريد الإلكتروني هذا',
			'your account has not yet been verified via email - please verify by clicking on the link sent to your email inbox': 'لم يتم التحقق من حسابك عبر البريد الإلكتروني حتى الآن - يُرجى التحقق من خلال النقر على الرابط المرسل إلى بريدك الإلكتروني',
			'send again': 'أعد الإرسال',
			'didn\'t receive an email': 'لم أستلم رسالة بريد إلكتروني',
			'user email address not found': 'لم يتم العثور على عنوان البريد الإلكتروني',
			'a verification email will be sent to your email box. the link is valid for {link_ttl} minutes': 'سيتم إرسال رسالة تحقق إلى بريدك الإلكتروني. سيكون الرابط صالحًا لمدة {link_ttl} دقائق',
			'your account requires activation, email with activation link have been sent to your email. the link is valid for {link_ttl} minutes': 'يجب تنشيط حسابك، لقد تم إرسال رسالة تحتوي على رابط التنشيط إلى بريدك الإلكتروني. سيكون الرابط صالحًا لمدة {link_ttl} دقائق',
            'your account requires activation.': 'يجب تنشيط حسابك.',
            'email with activation link have been sent to your email.': 'لقد تم إرسال رسالة تحتوي على رابط التنشيط إلى بريدك الإلكتروني.',
            'the link is valid for {link_ttl} minutes': 'سيكون الرابط صالحًا لمدة {link_ttl} دقائق',
			'activation code expired': 'انتهت صلاحية رمز التنشيط',
			'website login': 'تسجيل الدخول على الموقع الإلكتروني',
			'send a new link': 'أرسل رابطًا جديدًا',
			'sorry, this link has expired': 'معذرة، انتهت صلاحية هذا الرابط',
			'a new link can be sent': 'يمكن إرسال رابط جديد',
			'you have successfully registered for the site': 'لقد قمت بالتسجيل بنجاح في هذا الموقع',
			'this account has already been verified': 'تم التحقق بالفعل من هذا الحساب',
			'you may enter the trade site': 'يمكنك الدخول إلى الموقع التجاري',
			'this account is an organizational account. you may need to verify it similarly from time to time': 'هذا الحساب هو حساب مؤسسة. قد تحتاج إلى التحقق من ذلك بطريقة مماثلة من وقت لآخر',
			'please verify your identity': 'يُرجى التحقق من هويتك',
			'to verify your identity, please enter a phone number': 'للتحقق من هويتك، يُرجى إدخال رقم هاتف',
			'continue to registration': 'متابعة التسجيل',
			'phone number does not match': 'رقم الهاتف غير مطابق',
			'user invitation not found': 'لم يتم العثور على دعوة المستخدم',
			'user already registered': 'المستخدم مسجل بالفعل',
			'phone number hint': 'ملاحظة بخصوص رقم الهاتف',
			'account not verified': 'لم يتم التحقق من الحساب',
			'sorry, there was an authentication error': 'معذرة، هناك خطأ في المصادقة',
			'please try again or contact the person in charge of the organization': 'يُرجى إعادة المحاولة أو الاتصال بالشخص المسؤول عن المؤسسة',
			'this account is an organizational account. you account verification has expired': 'هذا الحساب هو حساب مؤسسة. انتهت صلاحية التحقق من حسابك',
			'account verification succeed': 'تم التحقق من الحساب بنجاح',
			'apartment, suite, building, floor, etc.': 'شقة، جناح، مبنى، طابق وما إلى ذلك',

			'this user may already be on the system': 'قد يكون هذا المستخدم موجودًا بالفعل على النظام',
			'please try to login': 'يُرجى محاولة تسجيل الدخول',
			'delivery_type_1_times': 'مواعيد التوصيل',
			'delivery_type_2_times': 'مواعيد الاستلام الذاتي',
			'wrong captcha token': 'رمز captcha غير صحيح',
			'wrong captcha host': 'مضيفf captcha غير صحيح',

			'user products list not found': 'لم يتم للعثور على قائمة منتجات المستخدم',
			'user products list is disabled for this retailer': 'قائمة منتجات المستخدم معطلة لتاجر التجزئة هذا',
			'my lists': 'قائمتي',
			'my products': 'منتجاتي',
			'my products list': 'قائمة منتجاتي',
			'products i usually purchase': 'المنتجات التي أشتريها عادة',
			'your time is precious': 'وقتك ثمين',
			'we have prepared a list of your personal products': 'جهّزنا قائمة بمنتجاتك الشخصية',
			'we have prepared a list of products you usually purchase': 'جهّزنا قائمة بمنتجاتك الشخصية',
			'add products from the list': 'أضف منتجات من ألقائمة',
			'In cart': 'في عربة التسوق',

			'delivery precedence for senior citizens!': 'أسبقية التسليم لكبار السن!',
			'we keep delivery slots for senior citizens to allow high availability in this challenging time': 'نخصص إمكانيات توصيل لكبار السن للسماح بتوفير إمكانية كبيرة لإتاحة المنتجات في هذا الوقت الصعب',
			'enter your id number': 'أردخل رقم التعريف الخاص بك',
			'used for verification only, we arn\'t saving this number': 'لا يُستخدم إلا للتحقق من الصحة فقط، ولا نحفظ هذا الرقم',
			'another time, continue shopping': 'أكمل التسوق في وقت آخر',
			'subject to delivery slots availability out of senior citizens allocation': 'وفقًا لتوافر أوقات تسليم غير مخصصة لكبار السن',
			'the verification was successful': 'نجح التحقق من الصحة',
			'your account is now tagged as "senior citizen"': 'تم تمييز حسابك على أنك "شخص كبير في السن"',
			'please enter your id': 'يُرجى إدخال رقمك التعريفي',
			'invalid id': 'معرف غير صالح',
			'i am a senior citizen': 'أنا شخص كبير في السن',
			'may grant you priority delivery slots': 'يمكن أن نمنحك أولوية في إمكانية التوصيل',

			'we use cookies to improve your experience': 'نستخدم ملفات تعريف الارتباط لتحسين تجربتك',
			'we use cookies or other trackers to facilitate the use of the site, improve the performance and security of the site, personalize the content offered, including personalized advertising content, and compile traffic and navigation statistics. some cookies, other than functional and security cookies, require your consent to be placed.': ' نستخدم ملفات تعريف الارتباط أو غيرها من أدوات التعقب لتسهيل استخدامك لهذا الموقع الإلكتروني وتحسين أدائه وأمانه، ولتخصيص المحتوى المعروض، وهذا يشمل تخصيص محتوى الإعلانات وجمع إحصائيات استخدام الموقع والتنقل عليه. تتطلب بعض ملفات تعريف الارتباط موافقتك على تثبيتها، خلافًا لملفات تعريف الارتباط الوظيفية والمتعلقة بالأمان.',
			'accept': 'اقبل',
			'accept all cookies': 'قبول جميع ملفات تعريف الارتباط',
			'setup my cookies': 'إعداد ملفات تعريف الارتباط',
			'managing cookies': 'إدارة ملفات تعريف الارتباط',
			'these cookies allow you to benefit from the services offered by the site, to optimize its use and personalization (in particular personalization of offers and advertisements) depending on your profile.': 'تسمح لك هذه الملفات بالاستفادة من الخدمات التي يقدمها الموقع الإلكتروني، وتحسين استخدامه وتخصيصه (لا سيما تخصيص العروض والإعلانات) بناء على إعدادات ملفك الشخصي.',
			'marketing': 'التسويق',
			'marketing cookies are used to improve the relevance of the ads you see and to make sure that you do not see the same ad multiple times.': 'تُستخدَم ملفات تعريف ارتباط التسويق لتحسين الإعلانات التي تراها وتضمن ألا ترى الإعلان أكثر من مرة.',
			'additional features': 'الخصائص الإضافية',
			'these cookies make it possible to activate additional features on the site, for example to facilitate your purchases.': 'تتيح هذه الملفات تفعيل الخصائص الإضافية على الموقع الإلكتروني، ومنها تسهيل عمليات الشراء على سبيل المثال.',
			'audience measurement': 'حصر الزائرين',
			'these cookies allow us to obtain navigation statistics on our site. we use this data to identify possible malfunctions of the site, to improve the site, for marketing purposes, and in order to personalize your experience on the site.': 'تتيح لنا هذه الملفات جمع إحصائيات التنقل على موقعنا الإلكتروني. نستخدم هذه البيانات لتحديد أي أعطال بالموقع الإلكتروني، ولتحسين الموقع، ولأغراض التسويق، ولتخصيص تجربتك على الموقع الإلكتروني.',
			'google analytics': 'تحليلات جوجل',
			'these cookies allow us to obtain statistics on the number of visits to our site (e.g., number of visits, most viewed pages, etc.). we use this data to understand our users better, check the performance of our content, get insights, and identify possible malfunctions of the site to make improvements.': 'تسمح لنا هذه الملفات بجمع الإحصائيات المتعلقة بعدد الزيارات في الموقع الإلكتروني (مثل عدد الزيارات، وأكثر الصفحات التي يزورها المستخدمون، إلخ). نستخدم هذه البيانات في تحسين فهمنا للمستخدمين، والتحقق من أداء المحتوى، واستجماع الأفكار، وتحديد الأعطال المحتملة بالموقع الإلكترونية لإدخال التحسينات عليه.',
			'functional (not configurable)': 'ملفات تعريف الارتباط الوظيفية (لا يمكن تكوينها)',
			'these cookies allow you to remember your choices and preferences on your account and to provide features that facilitate the use of the site. they are essential for the proper functioning of the site and are strictly necessary for the provision of a service that you have expressly requested.': 'تسمح هذه الملفات بأن يتذكر الموقع الإلكتروني اختياراتك وتفضيلاتك في حسابك وتقديم الخصائص التي تسهّل استخدام الموقع الإلكتروني. وهي ضرورية لعمل الموقع على النحو الصحيح وضرورية جدًا لتقديم الخدمات التي طلبتَها صراحة.',
			'time slot unavailable': 'الفترة الزمنية غير متاحة',
			'we\'re sorry, this time slot is no longer available. please select a different time': 'معذرةً ، هذه الفترة الزمنية لم تعد متوفرة. يرجى اختيار وقت مختلف.',
			'return to schedule': 'العودة إلى الجدول الزمني',
			'sitemap': 'خريطة الموقع',
			'pages': 'الصفحات',
			'the {product_count} products listed below will be': ' المدرجة أدناه  {product_count} المنتجات',
			'removed from your cart': 'تم إزالته من عربة التسوق الخاصة بك',
			'out of stock products': 'منتجات غير متوفرة في المخزون',
			'the next items will be removed from your cart': 'سيتم إزالة العناصر القادمة من عربة التسوق',
			'back to cart': 'العودة إلى عربة التسوق',
			'confirm': 'أكد',
			'expired':'منتهية الصلاحية',
			'{product_count} out of stock products will not be supplied': ' منتجات غير متوفر بالمخزون {product_count} لن يتم توفير ',
			'{coupons_count} expired coupons cannot be redeemed': 'قسائم منتهية الصلاحية لا يمكن استعمالها {coupons_count}',
			'products marked with': 'المنتجات المميزة ب',
			'limited quantity': 'كمية محدودة',
			'tag are limited to': 'العلامة تقتصر على',
			'items only.': 'العناصر فقط.',
			'you_can_not_add_more_than': 'You can not add to the shopping cart more than',
			'items_marked_with_symbol': 'items marked with this symbol',
			'are you sure you want to remove all products from your shopping cart?': 'هل أنت متأكد أنك تريد إزالة جميع المنتجات من عربة التسوق الخاصة بك؟',
			'yes, clear cart': 'نعم ، امسح السلة',
			'clear cart?': 'إخلاء العربة',
			'registration attempt has been blocked, please contact support': 'تم حظر محاولة التسجيل ، يرجى الاتصال بالدعم',
			'account has been suspended, please contact support': 'تم تعليق الحساب ، يرجى الاتصال بالدعم',
			'delivery-fee-explain-default': ' .ةيفاضإ تاموصخ يأ وأ ميلستلا تقو/ةقطنم ببسب تارييغتلل عضخي دقو ،جورخلا ليجست دنع يئاهنلا ليصوتلا موسر غلبم ثيدحت عقوتملا نم',
			'delivery_type_1_fee': 'موسر مالتسالل ءاقتلالا',
			'delivery_type_2_fee': 'موسر ميلستلا',
			'includes_delivery_type_1_fee': 'موسر مالتسالل ءاقتلالا لماش',
			'includes_delivery_type_2_fee': 'موسر ميلستلا لماش',
			'not_includes_delivery_fee': '(غير شامل رسوم التوصيل)',
			'valid until': 'صالح حتى',
			'proceed to checkout': 'المتابعة لإتمام العملية',

			'add coupon': 'إضافة قسيمة شراء',
			'your points': 'نقاطك',
			'new': 'جديد',
			'buy with points': 'شراء بالنقاط',
			'almost expired': 'أوشكت نهاية الصلاحية',
			'coupon clipped': 'تم قص الكوبون',
			'add product': 'أضف منتج',
			'product in cart': 'المنتج في السلة',
			'clip coupon': 'قص الكوبون',
			'clipped externally': 'المستلَم من جهة خارجية',
			'personal coupons': 'القسائم الشخصية',
			'may interest you': 'ربما يعجبكم أيضا',
			'clip for {x} points': 'إضافه مقابل {x} نقطة/نقاط',
			'not enough points': 'نقاط غير كافية',
			'coupon expires today': 'تنتهي صلاحية القسيمة اليوم',
			'coupon expires in {x} days': 'تنتهي صلاحية القسيمة في {x} يوم/أيام',
			'clip for': 'إضافة مقابل',
			'valid': 'صالحة',
			'can be redeemed once': 'يمكن استبدالها مرة واحدة',
			'can be redeemed {x} times': 'يمكن استبدالها {x} مرات',

			'last_minute_recommendations_headline': 'لدينا توصيات مختارة خصيصًا لكِ.',
			'last_minute_recommendations_title_coupons': 'قسائم شراء ستوفر لكِ المال',
			'last_minute_recommendations_title_products_usually': 'ربما تكوني قد نسيتي هذه المنتجات؟',
			'last_minute_recommendations_title_products_complementary': 'ربما يعجبكِ أيضا...',
			'last_minute_recommendations_title_specials_offers': 'عروض خاصة لا تفوتيها',
			'last_minute_recommendations_title_products_offers': 'لا يمكنك تفويت هذه العروض الخاصة',

			'account_deletion_common_customer_support_1': 'للحصول على الدعم يمكنكم الاتصال بخدمة العملاء:',
			'account_deletion_common_customer_support_2': 'يُرجى المحاولة مرة أخرى لاحقًا أو يمكنكم الاتصال بخدمة العملاء:',
			'account_deletion_account_title': 'حذف الحساب',
			'account_deletion_init_title': 'حذف حسابكم',
			'account_deletion_recaptcha_description': 'يتطلّب الأمر منكم التحقق باستخدام بضع خطوات بسيطة لحذف حسابكم.',
			'account_deletion_authentication_description': 'سيتم إرسال بريد إلكتروني لكم به رمز التحقّق إلى {userEmail}',
			'account_deletion_authentication_help_spam': 'ربما يجب عليكم التحقق من مجلد البريد العشوائي الخاص بكم',
			'account_deletion_authentication_help_code_1': 'لم يصلكم الرمز بعد؟',
			'account_deletion_authentication_help_code_2': 'إرسال مرة أخرى',
			'account_deletion_authentication_help_error_try_again_1': 'يُرجى المحاولة مرة أخرى أو',
			'account_deletion_authentication_help_error_try_again_2': 'إعادة إرسال رمز جديد',
			'account_deletion_authentication_help_error_max_tries': 'لقد تجاوزتم عدد المحاولات الممكنة، يُرجى المحاولة مرة أخرى في وقتٍ لاحق.',
			'Expired code': 'رمز منتهي الصلاحية',
			'Invalid code': 'Iالرمز غير صحيح',
			'account_deletion_confirmation_description_1': 'أنت على وشك',
			'account_deletion_confirmation_description_2': 'حذف حسابكم بشكل نهائي',
			'account_deletion_confirmation_description_3': 'سيتم حذف جميع بيانات حسابكم والطلبات المسقبلية',
			'account_deletion_confirmation_description_4': 'لا يمكن التراجع عن هذا الإجراء ',
			'account_deletion_confirmation_help_input_1': 'بكتابة كلمة "CONFIRM"، فإنّكم توافقون على حذف حسابكم ',
			'account_deletion_confirmation_help_input_2': 'اكتبوا  كلمة "CONFIRM" لتأكيد ذلك',
			'account_deletion_already_deleted_title': 'لا يمكنني المتابعة',
			'account_deletion_already_deleted_description': 'Tلقد تم حذف هذا الحساب وجاري معالجة ذلك',
			'account_deletion_notification_title': 'سيتم حذف حسابكم في غضون {numOfDaysUntilDeletion} أيام',
			'account_deletion_notification_description': 'أي طلبات يتم طلبها للتسليم أو يتم اختيارها بعد هذا الوقت لن يتم توفيرها',
			'account_deletion_error_title': 'حدث خطأ ما في النظام',
			'account_deletion_error_description': 'لا يمكن المتابعة بسبب وجود خطأ ما في النظام',

			'please type in your zip code': 'يُرجى كتابة الرمز البريدي الخاص بك',
			'find address': 'البحث عن العنوان',
			'you cannot edit this field before entering a zip code for your address': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
			'please choose your address': 'يُرجى اختيار العنوان الخاص بك',
			'you will be able to edit the address details': 'ستتمكّن من تعديل تفاصيل العنوان ',
			'you cannot edit this field': 'لا يمكنك تعديل هذا الحقل',
			'not editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
			'not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
			'not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
			'zipcode error': 'لا يمكنك تعديل هذا الحقل',
			'there are no available items for this promotion': 'لاتوجدعناصرمتاحةلهذاالعرضالترويجي',
			'proceed to checkout': 'المتابعة لإتمام العملية',

			'add_units_coupon': 'أضف {remaining} المزيد من المنتجات للاستفادة من التخفيض',
			'add_currency_coupon': 'أضف {remaining} المزيد من المنتجات للاستفادة من التخفيض',
			'add_measure_coupon': 'أضف {remaining} {unit} المزيد من المنتجات للاستفادة من التخفيض',
			'add_units_cart_coupon': 'أضف {remaining} من المنتجات',
			'add_unit_cart_coupon': 'أضف منتجًا {remaining}',
			'add_currency_cart_coupon': 'أضف {remaining}',
			'add_measure_cart_coupon': 'أضف {remaining} {unit}',
			'add_units_special_purchase': 'أضف {remaining} آخر',
			'buy_units_total': 'اشْتَرِ {total} من المنتجات',
			'buy_unit_total': 'اشْتَرِ {total} من المنتجات',
			'buy_currency_total': 'اشْتَرِ بـ {total} شيكل',
			'buy_measure_total': 'اشْتَرِ {total} {unit}',

			'suggestions_dialog_header_1': 'توصية للبدائل',
			'suggestions_dialog_header_2': 'يُرجى اختيار بديل من مجموعة منتجاتنا المماثلة',
			'suggestions_dialog_header_3': 'غير متوفر في المخزون',
			'suggestions_dialog_header_4': 'توصية للبدائل:',
			'ok, continue' : 'حسنًا، استمرار',
            'change chosen time': 'تغيير تاريخ التسليم',
			'hover description for cvv': 'رقم التحقّق من البطاقة (CVV) الموجود على بطاقة الائتمان أو بطاقة الخصم المباشر الخاصة بك، وهو عبارة عن رقم مكون من 3 أرقام على بطاقة الائتمان وبطاقة الخصم فيزا وماستر كارد وديسكفر، بينما يوجد رمز رقمي مكون من 4 أرقام على بطاقة الائتمان أو الخصم التي تحمل علامة أمريكان إكسبريس.',
			'enter cvv number': '',

			'the coupon displayed below, will not be valid for this chosen time': 'يرجى ملاحظة أن القسيمة المعروضة أدناه لن تكون صالحة لهذا الوقت المختار',
			'the expiration of these {coupon_number} coupons will not be valid for this chosen time': 'برجاء ملاحظة أن تاريخ إنتهاء صلاحية هذه القسائم الأربعة لن يكون صالحًا في هذا الوقت المحدّد.',
			'the coupon displayed below, will not be valid for this chosen time': 'القسيمة المعروضة أدناه ، لن تكون صالحة لهذا الوقت المختار',
			'these {coupon_number} coupons will not be valid for this chosen time': 'هاتان القسيمتان غير صالحتين في هذا الوقت المحدّد.',
			'price does not include bottle deposit': 'السعر لا يشمل رسم الإيداع النقدي مقابل زجاجة المشروبات المعبأة',
			'price includes bottle deposit': 'السعر يشمل رسم الإيداع النقدي مقابل زجاجة المشروبات المعبأة',
			'balance to be paid': 'الرصيد الواجب دفعه',
			'please enter the card number to check the balance': 'الرجاء إدخال رقم البطاقة للتحقق من الرصيد',
			'redeem': 'السترداد',
			'please save the voucher or gift card until you receive your order and are charged': 'يرجى حفظ القسيمة أو بطاقة الهدايا حتى تتلقى طلبك وتحصيل الرسوم',
			'choose gift card/s': '',
			'add gift card': '',
			'redeem gift card & discount cards': 'لاسترداد بطاقة هدايا',
			'for gift card and discount cards': '',
			'gift_card_error_message': 'لم نتمكن من معالجة بطاقة الهدايا الخاصة بك، يرجى المحاولة مرة أخرى أو تجربة بطاقة أخرى',
			'wrong_gift_card_amount_error': 'القيمة تتجاوز قيمة سلة التسوق',
			'amount_exceeds_balance': 'المبلغ يتجاوز الرصيد',
			"sorry, currently we don't deliver to this address for further inquiries call to the customer service.": 'عذرا ، حاليا لا نقوم بالتوصيل إلى هذا العنوان لمزيد من الاستفسارات اتصل بخدمة العملاء',
			'january': 'يناير',
			'february': 'شهر فبراير',
			'march': 'يمشي',
			'april': 'أبريل',
			'may': 'يمكن',
			'june': 'يونيو',
			'july': 'يوليو',
			'august': 'أغسطس',
			'september': 'سبتمبر',
			'october': 'اكتوبر',
			'november': 'شهر نوفمبر',
			'december': 'ديسمبر',
			'redeemed': 'مسترد',
			'points gained': 'النقاط المكتسبة',
			'source': 'المصدر',
			'when': 'التاريخ',
			'gg_map_dialog_title': 'اختر عنوانًا من الخريطة',
			'gg_map_dialog_hint': 'إنّتا نواجه مشكلة في تحديد موقعك بدقة. يُرجى التأكّد من وضع الدبوس بدقة كبيرة. سيساعدنا هذا في تحديد عنوانك بدقة',
			'gg_map_alert_need_location': 'يرجى تشغيل الموقع لاستخدام خرائط Google',
			'gg_map_alert_error': 'هناك بعض المشاكل عند عرض خرائط Google',
			'gg_map_alert_unsupport': 'الجغرافية غير مدعومة من قبل هذا المتصفح',
			'autocomplete_adrs_didnt_find': 'لم تجد عنوانك؟',
			'autocomplete_adrs_choose_location': 'اختر موقعك على الخريطة',
			'autocomplete_adrs_city_not_found': 'عذرًا، لم يتم العثور على مدينتك. يُرجى تحديد عنوان من اقتراحات الإكمال التلقائي أو تثبيت عنوانك باستخدام خيار خرائط جوجل.',
			'autocomplete_adrs_adrs_not_found': 'عذرًا، لم يتم العثور على عنوانك. يُرجى تحديد عنوان من اقتراحات الإكمال التلقائي أو تثبيت عنوانك باستخدام خيار خرائط جوجل.',
			'sorry, your city was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'عذرًا، لم يتم العثور على مدينتك. يُرجى تحديد عنوان من اقتراحات الإكمال التلقائي أو تثبيت عنوانك باستخدام خيار خرائط جوجل.',
			'sorry, your address was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'عذرًا، لم يتم العثور على عنوانك. يُرجى تحديد عنوان من اقتراحات الإكمال التلقائي أو تثبيت عنوانك باستخدام خيار خرائط جوجل.',
			'sorry, your zip code was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'عذرًا، لم يتم العثور على الرمز البريدي الخاص بك، لذا يُرجى تحديد عنوان من اقتراحات الإكمال التلقائي أو تثبيت عنوانك باستخدام خيار خرائط جوجل.',
			'countryInput: required error': 'الرجاء إدخال بلدك',
			'country of country_of_origin_title': 'بلد المنشأ',
			'origin_country_legal_text': 'يُرجى العلم أنه على الرغم من أن بلد المنشأ المذكور دقيق اعتبارًا من تاريخ الطلب، إلا أن بلد المنشأ الفعلي للمنتج الذي تم تسليمه قد يختلف بناءً على المخزون المتوفر في نقطة الاستلام أثناء التسليم',
			'after package deduction': 'بعد خصم تكاليف التعبئة',
			'is this address correct? you can now edit and refine it': 'هل هذا العنوان صحيح؟ يمكنك تعديله وتحسينه الآن',
			'edit your address': 'اكتب عنوانك',
			'contact_us': 'اتصل بنا',
			'how_can_we_help_you_today': 'كيف يمكننا مساعدتك اليوم؟',
			'leave_us_a_message': 'اترك لنا رسالة، وسنتواصل معك قريبًا',
			'fullname': 'الاسم الكامل',
			'phone': 'رقم الهاتف',
			'email': 'البريد الإلكتروني',
			'message': 'كيف يمكننا مساعدتك؟',
			'send': 'إرسال',
			'your_message_has_been_sent': 'تم إرسال رسالتك',
			'thank_you_for_contact_us': 'أشكركم على الاتصال بنا',
			'searchtext_storename_placeholder': '{اسم المتجر}',
			'customer support phone number': 'رقم هاتف دعم العملاء',
			'please wait, we are uploading the products': 'يُرجى الانتظار، نحن نقوم بتحميل المنتجات.',
			'complete_and_proceed_to_checkout': 'أكمل وانتقل إلى الدفع',
			'please notice, the following products are not available on this chosen time.': 'يُرجى ملاحظة أن المنتجات التالية غير متوفرة في الوقت الذي تم تحديده.',
			'the following products are not available on this chosen time.': 'أن المنتجات التالية غير متوفرة في الوقت الذي تم تحديده.',
			'if you continue, they will be removed from your cart.': 'إذا فمت بالمتابعة، سيتم إزالتها من سلة التسوق الخاصة بك.',
			'result for “{title}”': 'النتيجة لـ “{title}”',
			'find a product': 'ابحث عن منتج',
			'results by brand:': 'النتائج حسب العلامة التجارية:',
			'please enter cvv to continue': 'الرجاء إدخال CVV للمتابعة',
			'checkout is opened on another screen': 'الدفع مفتوح على شاشة أخرى',
			'loading coupons, please wait...': 'جاري تحميل القسائم، يُرجى الانتظار...',
			'please wait, we are loading the products': 'يُرجى الانتظار، نحن نقوم بتحميل المنتجات.',
			'receipt': 'إيصال',
			'api_error_message': 'لقد حدث خطأ ما{error_message} (خطأ {error_code}). يُرجى المحاولة مرة أخرى بعد بضع دقائق، وإذا تكررت المشكلة فاتصل بخدمة العملاء',
			'payment error': 'خطأ الدفع',
			'error received': 'الخطأ المستلم',
			'please check your credit card details or contact your credit card provider': 'برجاء مراجعة تفاصيل بطاق  الائتمان الخاصة بك أو اتصل بمقدِّم بطاقة الائتمان',
			'address_form_field_label_city': 'المدينة',
            'address_form_field_label_address': 'العنوان',
            'address_form_field_label_zip_code': 'الرمز البريدي',
            'address_form_field_label_postal_code': 'الرمز البريدي',
            'address_form_field_label_entrance': 'المدخل',
            'address_form_field_label_apartment_number': 'رقم الشقة',
            'address_form_field_label_building_code': 'رمز المبنى',
            'address_form_field_label_state': 'الولاية',
            'address_form_field_label_comments': 'التعليقات',
            'address_form_field_label_apartment_suite_unit': 'شقة / جناح / وحدة',
            'address_form_field_label_providence': 'التدابير',
            'address_form_field_label_more_details': 'المزيد من التفاصيل',
			'address_form_field_label_floor': 'طابق',
			'address_form_field_label_house_number': 'رقم المنزل',
			'address_form_field_label_street': 'الشارع',

			'address_form_field_error_city': 'الرجاء إدخال مدينة صالحة',
			'address_form_field_error_address': 'الرجاء إدخال عنوان صالح',
			'address_form_field_error_zip_code': 'الرجاء إدخال الرمز البريدي الصحيح',
			'address_form_field_error_postal_code': 'الرجاء إدخال الرمز البريدي الصحيح',
			'address_form_field_error_entrance': 'الرجاء إدخال مدخل صالح',
			'address_form_field_error_apartment_number': 'الرجاء إدخال رقم شقة صالح',
			'address_form_field_error_building_code': 'الرجاء إدخال كود البناء الصحيح',
			'address_form_field_error_state': 'الرجاء إدخال ولاية صالحة',
			'address_form_field_error_comments': 'يرجى إدخال تعليق',
			'address_form_field_error_apartment_suite_unit': 'الرجاء إدخال شقة/جناح/وحدة صالحة',
			'address_form_field_error_providence': 'الرجاء إدخال منطقة صالحة',
			'address_form_field_error_more_details': 'الرجاء إدخال المزيد من التفاصيل',
			'address_form_field_error_floor': 'الرجاء إدخال طابق صالح',
                
			'address_drop_pin_message_1': 'تم تثبيت الموقع بنجاح. عدل إذا لزم الأمر.',
			'address_drop_pin_message_cancel': 'إلغاء العنوان المثبت',

			'autocomplete_city_not_found_non_droppin': 'عذرًا، لم يتم العثور على مدينتك، لذا يُرجى تحديد عنوان من القائمة أو الاتصال بالدعم للحصول على المساعدة.',
			'autocomplete_address_not_found_non_droppin': 'عذرًا، لم يتم العثور على عنوانك، لذا يُرجى تحديد عنوان من القائمة أو الاتصال بالدعم للحصول على المساعدة.',
			'autocomplete_zip_code_not_found_non_droppin': 'عذرًا، لم يتم العثور على الرمز البريدي الخاص بك، لذا يُرجى تحديد عنوان من القائمة أو الاتصال بالدعم للحصول على المساعدة.',

            'pickup time:': 'وقت الجلب:',
            'pickup address:': 'عنوان الجلب:',
            'delivery time:': 'وقت التوصيل:',
            'delivery address:': 'عنوان التسليم:',
            'on_day': '',
            'wish_to_change_delivery': 'هل ترغب في تغيير وقت التسليم؟',
            'wish_to_change_pickup': 'هل ترغب في تغيير وقت الجلب؟',
            'select date & time': 'تحديد التاريخ والوقت',
            'confirm update': 'برجاء تأكيد التحديث',
            'save & change delivery time': 'حفظ وتغيير وقت التسليم',
            'save & change pickup time': 'حفظ وتغيير وقت الجلب',
            'back to home page': 'العودة إلى الصفحة الرئيسية',
            'your order has been updated': 'لقد تم تحديث طلبك',

            'checkout_finish_last_update_time_delivery': 'يمكنك تحديث وقت تسليم طلبك حتى',
            'checkout_finish_last_update_time_pickup': 'يمكنك تحديث وقت جلب طلبك حتى',
            'forgot_sth_want_to_change_delivery': 'هل تريد تغيير تاريخ الاستلام؟',
            'forgot_sth_want_to_change_pickup': 'هل تريد تغيير تاريخ الجلب؟',

            'update_order_popup_title_def': 'يمكنك تحديث عناصر طلبك أو وقت {delivery method}',
            'update_order_popup_order_item_def': 'تحديث عناصر الطلب',
            'update_order_popup_delivery_time_def': 'تحديث وقت الاستلام',
            'update_order_popup_pickup_time_def': 'تحديث وقت الجلب',
            'update_order_popup_cancel_def': 'هل تريد إلغاء طلبك؟ انقر هنا لإلغائه {cancel order}',
            'update_order_popup_lastest_time_def': 'يمكنك تحديث وقت طلبك {delivery method} حتى {time calculate}',
            'update_order_popup_err_cant_update_time': 'تحديث وقت {delivery method} الخاص بك غير متاح',

            'irre_sales_collect_one': 'لن يكون {saleType} هذا صالحًا لهذا الوقت المحدّث وسيتم تحصيله بسعره العادي',
            'irre_sales_collect_many': 'لن تكون {count} {saleType} هذه صالحة لهذا الوقت المحدّث وسيتم تحصيلها بسعرها العادي',
            'irre_sales_checkout_one': 'عند تحديث هذا الطلب، لن يكون {saleType} التالي صالحًا بعد الآن، وسيتم احتساب ثمن المنتج بسعره العادي',
            'irre_sales_checkout_many': 'عند تحديث هذا الطلب، لن يكون {count} {saleType} التالية صالحة بعد الآن، وسيتم احتساب ثمن العناصر بسعرها العادي',
            'irre_sales_oos_one': 'ستتم إزالة المنتجات غير المتوفرة حاليًا من سلة التسوق الخاصة بك.',
            'irre_sales_oos_many': 'ستتم إزالة المنتجات غير المتوفرة حاليًا من سلة التسوق الخاصة بك.',
            'irre_sales_btn_continue': 'حسنًا، التحديث',
            'irre_sales_btn_change_slot': 'حدّث وقت {delivery method} ',
            'irre_sales_btn_cancel_update': 'إلغاء التحديث',
            'thank you for your order!': 'شكرًا لك على طلبك!',
			'delivery_disclaimer_default_text':'يمكن اختيار نوافذ التسليم فقط أثناء الدفع',

			"add your credit card": "أضف بطاقتك الإئتمانية",
			"payment methods": "طرق الدفع",
			"your credit cards": "بطاقتك الإئتمانية",
			"add a credit card": "أضف بطاقة الإئتمانية",
			"gift cards": "بطاقات الهدايا",
			"add gift cards": "أضف بطاقات الهدايا",
			"choose gift card/s": "اختر بطاقة/بطاقات الهدايا",
			"please enter the card number to check balance": "يُرجى إدخال رقم البطاقة للتحقق من الرصيد",
			"{gift card name} details": "تفاصيل {Gift card name}",
			"on the next screen, you can select your payment amount": "في الشاشة التالية، يمكنك تحديد مبلغ الدفع الخاص بك",
			"balance:": "الرصيد:",
			"please enter amount to pay": "يُرجى إدخال المبلغ المطلوب دفعه",
			"amount exceeds balance": "المبلغ يتجاوز الرصيد",
			"please save the voucher or gift card until you receive your order and are charged.": "يُرجى حفظ القسيمة أو بطاقة الهدايا حتى تتلقى طلبك وتحصيل الرسوم.",
			"remove card": "إزالة البطاقة",
			"see more": "مشاهدة المزيد",
			"show less": "إظهار أقل",
			"amount exceeds shopping cart value": "القيمة تتجاوز قيمة سلة التسوق",
			'user not connected to organization': 'المستخدم غير متصل بالمؤسسة',
			'amount to pay': 'المبلغ المطلوب دفعه',
			'balance': 'الرصيد',
			'reset': 'إعادة ضبط',

			'default_first_time_popup_title': 'مرحبًا بك!',
			'default_first_time_popup_sub_title': 'كيف ترغب في استلام طلبك؟',
			'default_first_time_popup_sub_title_pickup': 'اختر متجرًا قريبًا للاستلام المريح!',
			'default_first_time_popup_sub_title_delivery': 'أدخِل عنوانك لرؤية خيارات التسليم المتاحة بالقرب منك',
			'default_returning_popup_title': 'تغيير المنطقة',
			'default_returning_popup_sub_title': 'كيف ترغب في استلام طلبك؟',
			'default_returning_popup_sub_title_pickup': 'حدّد موقع الاستلام الجديد',
			'default_returning_popup_sub_title_delivery': 'ابحث عن خيارات توصيل جديدة',

			'default_one_store_popup_title': 'أخبار رائعة!',
			'default_one_store_popup_sub_title': 'يمكننا التوصيل إلى منطقتك!',
			'default_one_store_popup_continue_button_text': 'الاستمرار في التسوق',

			'default_multiple_store_popup_title': 'أنت محظوظ!',
			'default_multiple_store_popup_sub_title': 'لدينا العديد من المتاجر التي تخدم منطقتك',

			'default_no_store_popup_title': 'عذرًا',
			'default_no_store_popup_sub_title': 'لا نقوم حاليًا بالتوصيل إلى منطقتك. اترك لنا بريدك الإلكتروني، وسنخبرك عندما نتوسع.',

			'delivery_input_zip_code_placeholder': 'الرمز البريدي (مثال: 75011)',
			'back_btn_text': 'رجوع',
			'delivery_input_address_placeholder': 'يُرجى إدخال عنوان التسليم',
		};
	});
})(angular);
