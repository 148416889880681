(function (angular) {
    angular.module('mobilezuz')
        .filter('arrayInLength', function () {
            return function (input) {
                var numInput = Number(input);
                if (angular.isNumber(numInput)) {
                    var res = [];
                    for (var i = 0; i < numInput; i++) {
                        res.push(i + 1);
                    }
                    return res;
                } else {
                    return input;
                }
            };
        });
})(angular);