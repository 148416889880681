angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.sales.sale', {
        url: '/:ssid',
        data: {
            stateAccess: PAGE_ACCESS.ALLOW,
            metaTags: {
                title: 'Sale'
            }
        },
        views: {
            '@': {
                templateUrl: '',
                controller: 'SingleSaleCtrl'
            }
        }
    });
}]).controller('SingleSaleCtrl', ['$scope', '$state', '$stateParams',
    function ($scope, $state, $stateParams) {
        $state.go('app.specials.special', {ssid: $stateParams.ssid});
    }]);