(function (angular) {
    angular.module('mobilezuz').service('Quagga', ['$rootScope', '$q', function ($rootScope, $q) {
        var self = this;

        self.scan = scan;

        var initOptions = {
            inputStream: {
                name: "Live",
                type: "LiveStream",
                constraints: {
                    width: 640,
                    height: 480,
                    facingMode: "environment"
                }
            },
            locator: {
                patchSize: "medium",
                halfSample: true
            },
            numOfWorkers: 4,
            decoder: {
                // all options  ['upc_reader', 'upc_e_reader', 'ean_reader', 'ean_8_reader', 'code_128_reader', 'code_39_reader', 'code_39_vin_reader', 'codabar_reader', 'i2of5_reader']
                readers: ['upc_reader', 'upc_e_reader', 'ean_reader', 'ean_8_reader']
            },
            locate: true
        };

        var initiated = false,
            processing = false,
            scanDefer;

        function init() {
            var defer = $q.defer();
            //if (initiated) {
            // defer.resolve();
            // } else {
            //setTimeout(function(){
            Quagga.init(initOptions, function (err) {
                if (err) {
                    return defer.reject(err);
                }
                initiated = true;
                defer.resolve();
                //Quagga.registerResultCollector(resultCollector);
                //App.attachListeners();
            });
            //},500);
            //  }
            return defer.promise;
        }

        function _unbindBackBtn() {
            angular.element(document).unbind('backbutton', _onBackBtn);
        }

        function _bindBackBtn() {
            _unbindBackBtn();

            angular.element(document).bind('backbutton', _onBackBtn);
        }

        function _onBackBtn(event) {
            if (processing) {
                stop({cancelled: true, event: event});
            }
        }

        function _unbindTouch() {
            angular.element(document.querySelector('body')).unbind('touchstart', _onTouch);
        }

        function _bindTouch() {
            _unbindTouch();

            angular.element(document.querySelector('body')).bind('touchstart', _onTouch);
        }

        function _onTouch(event) {
            var target = angular.element(event.target),
                isCanvas = target[0].tagName === 'CANVAS' && target.hasClass('drawingBuffer');
            if (processing && !isCanvas) {
                stop({cancelled: true, event: event});
            }
        }

        function bindStopEvent() {
            _bindTouch();
            _bindBackBtn();
        }

        function unbindStopEvent() {
            _unbindTouch();
            _unbindBackBtn();
        }

        function scan() {
            if (!processing) {
                return init().then(function () {
                    processing = true;
                    bindStopEvent();
                    Quagga.start();

                    $rootScope.showQuaggaScanner = true;
                    //$rootScope.$digest();
                    scanDefer = $q.defer();
                    return scanDefer.promise;
                });
            }
        }

        function stop(err, result) {
            $rootScope.showQuaggaScanner = false;
            if (err) {
                scanDefer.reject(err);
            } else if (result) {
                scanDefer.resolve(result.codeResult.code);
            }
            scanDefer = null;
            Quagga.stop();
            unbindStopEvent();
            processing = false;
        }

        Quagga.onProcessed(function (result) {
            var drawingCtx = Quagga.canvas.ctx.overlay,
                drawingCanvas = Quagga.canvas.dom.overlay;

            drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
            if (result) {
                if (result.boxes) {
                    result.boxes.filter(function (box) {
                        return box !== result.box;
                    }).forEach(function (box) {
                        Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "green", lineWidth: 2});
                    });
                }

                if (result.box) {
                    Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "#00F", lineWidth: 2});
                }

                if (result.codeResult && result.codeResult.code) {
                    Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3});
                }
            }
        });

        Quagga.onDetected(function (result) {
            stop(null, result);
        });
    }]);
})(angular);

