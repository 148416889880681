angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.specialCategories', {
        url: '/special/categories',
        data: {
            stateAccess: PAGE_ACCESS.ALLOW,
            bodyClass: 'sales-categories',
            metaTags: {
                title: 'Specials categories'
            }
        },
        views: {
            '@': {
                templateUrl: 'views/special-categories.html',
                controller: 'SpecialCategoriesCtrl'
            }
        }
    });

}]).controller('SpecialCategoriesCtrl', [
    '$scope', '$state', 'Offers',
    function ($scope, $state, Offers) {
        function _getSalesCategories() {
            Offers.getSpecialCategories()
                .then(function (specialCategories) {
                    $scope.categories = specialCategories;
                });
        }

        _getSalesCategories();

        $scope.setCategoryInfo = function (categoryId) {
            $state.go('app.specials.categories', angular.extend($state.params, {categoryId: categoryId}));
        };
    }
]);
