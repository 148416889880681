(function (angular) {
  'use strict';

  /**
   * @typedef {Object} MapLocation
   *
   * @property {number} lat
   * @property {number} lng
   */

  angular.module('mobilezuz').service('LocationService', 
  ['$window', '$rootScope', '$filter', 'GMap',
  function ($window, $rootScope, $filter, GMap) {
    var self = this,
      _translate = $filter('translate')    

    angular.extend(self, {
      getUserDefaultLocation: getUserDefaultLocation,
      getUserLocation: getUserLocation,
      getBranchLocation: getBranchLocation,
      getDefaultLocation: getDefaultLocation,
    });

    /**
     * Get user's location first. If not then get selected branch's location
     * @returns {Promise<MapLocation>}
     */
    function getUserDefaultLocation() {
      return getUserLocation().then(function (userLocation) {
        if (userLocation) {
          return userLocation;
        }

        return getBranchLocation().then(function (branchLocation) {
            return branchLocation || getDefaultLocation();
        });
      });
    }

    /**
     * @returns {lat: string, lng: string}
     */
    function getDefaultLocation(){
      return { lat: 0, lng: 0 };
    }

    /**
     * @returns {Promise<GeolocationPosition>}
     */
    function getGeoCurPosition() {
      return new Promise(function (resolve, reject) {
        $window.navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    }

    /**
     * @returns {Promise<MapLocation>}
     */
    function getUserLocation() {
      if (!$window.navigator.geolocation) {
        throw new Error(_translate("gg_map_alert_unsupport"));
      }

      return getGeoCurPosition().then(function (location) {
        return {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        };
      }).catch(function (error) {
        var ERROR_USER_DENIED_LOCATION_CODE = 1;
        if (error.code !== ERROR_USER_DENIED_LOCATION_CODE) {
          throw new Error(_translate("gg_map_alert_error"));
        }
      });
    }

    /**
     * @param {string} address 
     * @returns {Promise<MapLocation>}
     */
    function getBranchLatLngByCity(address) {
      return new Promise(function(resolve){
        if(!address){
          resolve(null)
        }

        GMap.geocoding(address)
          .then(function (geocodes) {
            if(!geocodes || geocodes.length === 0){
              resolve(null)
            }

            resolve(GMap.getCoordinateFromGeocode(geocodes[0]));
          })
          .catch(function (err) {
            console.error(err);

            reject(null);
          });
        })
    }

    /**
     * Get all branch's location info
     * @returns {Promise<MapLocation>}
     */
    function getBranchLocation() {
      var curBranch = $rootScope.config.branch;

      if (curBranch.lat && curBranch.lon) {
        // lat lon are saved as string
        return Promise.resolve({
          lat: +curBranch.lat,
          lng: +curBranch.lon,
        });
      }

      return getBranchLatLngByCity(curBranch.city);
    }
  }]);
})(angular);
