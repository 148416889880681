(function (angular) {
    "use strict";
    angular.module('spUnits', []);
})(angular);

(function (angular) {
    'use strict';

    angular.module('spUnits')
        .constant('SP_UNITS_TRANSLATE', {
            en: {
                PER: 'per '
            },
            he: {
                PER: 'ל-'
            },
            ru: {
                PER: 'за '
            },
            es: {
                PER: 'por '
            },
			fr: {
				PER: 'le '
			},
			ar: {
				PER: 'لكل '
			}
        })
        .service('spUnitsConfig', ['SP_UNITS', 'SP_UNITS_TRANSLATE', function (SP_UNITS, SP_UNITS_TRANSLATE) {
            var self = this;

            /**
             * @type {String}
             * @private
             */
            self._defaultCurrency = '';

            /**
             * Set default currency
             * @public
             *
             * @param {String} currency
             */
            self.setCurrency = function (currency) {
                self._defaultCurrency = currency;
            };

            /**
             * Get currency
             * @public
             *
             * @return {String} currency
             */
            self.getCurrency = function () {
                return self._defaultCurrency;
            };

            /**
             * @type {Object}
             * @private
             */
            self._group;

            /**
             * Set units group
             * @public
             *
             * @param {String} group
             */
            self.setGroup = function (group) {
                self._group = {
                    name: group,
                    units: SP_UNITS.GROUPS[group]
                };
            };

            /**
             * Set units group
             * @public
             *
             * @param {Object} massUnitNames
             * @param {string} fallbackGroup
             */
            self.setGroupByMassUnit = function (massUnitNames, fallbackGroup) {
                var group;
                angular.forEach(massUnitNames, function(name, languageId) {
                    if (!group && SP_UNITS.BY_NAME[name.toLowerCase()]) {
                        group = SP_UNITS.BY_NAME[name.toLowerCase()].groupName;
                    }
                });

                self.setGroup(group || fallbackGroup);
            };

            /**
             * Get units group
             * @public
             */
            self.getGroup = function () {
                return self._group;
            };

            /**
             * @type {String}
             * @private
             */
            self._defaultLanguage = 'en';

            /**
             * Set default language of unit names
             * @public
             *
             * @param {String} language
             */
            self.setDefaultLanguage = function (language) {
                self._defaultLanguage = language;
            };

            /**
             * Get default language of unit names
             * @public
             *
             * @return {String} language
             */
            self.getDefaultLanguage = function () {
                return self._defaultLanguage;
            };

            /**
             * Translate to _defaultLanguage or to en
             * @public
             *
             * @param {String} key
             *
             * @return {String}
             */
            self.translate = function (key) {
                var def = SP_UNITS_TRANSLATE[self._defaultLanguage];
                return def && def[key] ? def[key] : SP_UNITS_TRANSLATE.en[key];
            };

            /**
             * @type {Object}
             * @private
             */
            self._normalizerOverrides = {};

            /**
             * Set normalizer overrides for different unit types
             * @public
             * 
             * @param {Object} normalizerOverrides
             */
            self.setNormalizerOverrides = function(normalizerOverrides) {
              angular.forEach(normalizerOverrides, function (normlalizerValue, unitType) {
                self._normalizerOverrides[unitType] = normlalizerValue;
              });
            };

            /**
             * Get normalizer override for specific unit type
             * @public
             * 
             * @param {string} unitType
             *
             * @return {number|undefined}
             */
            self.getNormalizerOverride = function(unitType) {
              return self._normalizerOverrides[unitType];
            };
        }]);
})(angular);

(function (angular) {
    'use strict';

    function getName(names) {
        return Array.isArray(names) ? names[0] : names;
    }

    angular.module('spUnits')
        .filter('spUnits', ['spUnits', 'spUnitsConfig', function (spUnits, spUnitsConfig) {
            /**
             * @param {Number} weight
             * @param {String} from
             * @param {Object} [options]
             * @param {String} [options.to]
             * @param {String} [options.language]
             * @param {Boolean} [options.convertInGroup]
             * @param {String} [options.separator]
             *
             * @returns {String}
             */
            function spUnitsFilter(weight, from, options) {
                if (!weight) {
                    return '';
                } else if (!from) {
                    return _weightAccuracy(weight);
                }

                options = options || {};

                var convert = spUnits.convert(weight, from, options);
                return _weightAccuracy(convert.value, convert.accuracy) + (options.separator || ' ') + getName(convert.names[options.language] || convert.names[spUnitsConfig.getDefaultLanguage()] || convert.names.en);
            }

            /**
             * Return weight string with accuracy
             * @private
             *
             * @param {Number} weight
             * @param {Number=100} [accuracy]
             *
             * @returns {String}
             */
            function _weightAccuracy(weight, accuracy) {
                accuracy = accuracy || 100;

                var totalWeight = (weight * accuracy) / accuracy;
                var totalWeightSplit = totalWeight.toString().split('.');

                if(totalWeightSplit.length === 1 || totalWeightSplit[1].length <= 2) {
                    return totalWeight.toString();
                }

                return totalWeight.toFixed(2);
            }

            spUnitsFilter.$stateful = true;
            return spUnitsFilter;
        }])
		.filter('spUnitsPrice', ['spUnits', 'spUnitsConfig', '$filter', function(spUnits, spUnitsConfig, $filter) {
			function spUnitsPriceFilter(price, weight, unitName, language, quantityNormalizer, lengthNormalizer) {
				if (!price || !weight || !unitName) return '';

                var convert = spUnits.normalizePrice(price, weight, unitName, Number(quantityNormalizer)),
                    isSingle = convert && convert.normalizer === 1,
                    isFrUnit = (language === 'fr' &&  unitName === 'unité'),
                    fixedUnitPrice = '',
                    per = spUnitsConfig.translate('PER'),
                    divider = ' ';

                if (!convert) {
                    return '';
                }

                // Override length to 1 meter according to retailer settings
                if (lengthNormalizer === "1" && convert.normalizer === 100 && convert.names.en.includes('meter')) {
                    convert.normalizer = 1;
                    convert.price = convert.price/100;
                }

                if(isSingle) {
                    convert.normalizer = '';

                    if(language === 'he') {
                        per = 'ל';
                        divider = '';
                    }

                    if(isFrUnit) {
                        per = 'l\''
                        divider = '';
                    }
                }

                fixedUnitPrice = (convert.price ? $filter('currency')(convert.price) : '') + ' ' + per +
                    convert.normalizer + divider + getName(convert.names[language] || convert.names[spUnitsConfig.getDefaultLanguage()] || convert.names.en);

                if(isFrUnit && !isSingle) {
                    fixedUnitPrice = (convert.price ? $filter('currency')(convert.price) : '') + ' ' +
                        convert.normalizer + ' l\'' + getName(convert.names[language] || convert.names[spUnitsConfig.getDefaultLanguage()] || convert.names.en);
                }

                return fixedUnitPrice;
			}

			spUnitsPriceFilter.$stateful = true;
			return spUnitsPriceFilter;
		}])
        .filter('spDynamicUnitsPrice', ['spUnits', 'spUnitsConfig', '$filter', function(spUnits, spUnitsConfig, $filter) {
            function spDynamicUnitsPriceFilter(priceNormalization, weight) {
                if (!priceNormalization || !weight) return '';

                var per = spUnitsConfig.translate('PER');

                return  $filter('currency')(priceNormalization) + ' ' + per + weight;
            }

            spDynamicUnitsPriceFilter.$stateful = true;
            return spDynamicUnitsPriceFilter;
        }]);
})(angular);

(function (angular) {
    'use strict';

    angular.module('spUnits')
        .service('spUnits', ['SP_UNITS', 'spUnitsConfig', 'spUnitsCoreConverter', function (SP_UNITS, spUnitsConfig, spUnitsCoreConverter) {
            var self = this;

            self.convert = convert;
            self.normalizePrice = normalizePrice;

            /**
             * Convert unit to group unit
             * @public
             *
             * @param {number} value
             * @param {string|Object} from
             * @param {Object} [options]
             * @param {string|Object} [options.to]
             * @param {Boolean=false} [options.convertInGroup]
             * @param {number} [options.minUnitSize=1]
             *
             * @return { { names: Object, value: Number, accuracy: Number } }
             */
            function convert (value, from, options) {
                options = options || {};

                return spUnitsCoreConverter.convert(value, from, {
                    toUnit: options.to,
                    toGroup: (spUnitsConfig.getGroup() || {}).name,
                    convertInGroup: options.convertInGroup,
                    minUnitSize: options.minUnitSize,
                    defaultLanguage: spUnitsConfig.getDefaultLanguage()
                });
            }

            /**
             * Normalize the price to standard unit
             * @public
             *
             * @param {Number} price
             * @param {Number} weight
             * @param {String} unitName
             * @param {number} normalizer
             * 
             * @return { Null | { names: Object, normalizer: Number, price: Number } }
             */
            function normalizePrice(price, weight, unitName, normalizer) {
                var unit = SP_UNITS.BY_NAME[unitName.toLowerCase()];
                if (!unit) {
                    return null;
                }

                var group = unit.universal ? { name: unit.groupName } : spUnitsConfig.getGroup();
                if (!group || !SP_UNITS.NORMALIZERS[group.name] || !SP_UNITS.NORMALIZERS[group.name][unit.type]) {
                    return null;
                }

                var viewNormalyzerConversion,
                    normalizerUnit = SP_UNITS.NORMALIZERS[group.name][unit.type],
                    normalizerValue = spUnitsConfig.getNormalizerOverride(unit.type) || normalizerUnit.normalizer,
                    normalizerWeightUnit = self.convert(weight, unit, { to: normalizerUnit })

                if (normalizerUnit.universal && normalizerWeightUnit.value <= normalizerValue) {
                    return null;
                }

                if(normalizerUnit.type !== 'unit'){
                    normalizer = null;
                }

                if (normalizerValue !== normalizerUnit.normalizer) {
                    viewNormalyzerConversion = self.convert(normalizerValue, normalizerUnit, {convertInGroup: true});
                }

                var names = angular.extend({}, (viewNormalyzerConversion || normalizerUnit).names),
                  viewNormalizerUnit = SP_UNITS.BY_NAME[Object.values(names)[0][0].toLowerCase()];
                if (viewNormalizerUnit === unit) {
                    var language = SP_UNITS.LANGUAGE_BY_NAME[unitName.toLowerCase()];
                    names[language] = [unitName].concat(names[language]);
                }

                return {
                    names: names,
                    normalizer: normalizer || (viewNormalyzerConversion && viewNormalyzerConversion.value) || normalizerValue,
                    price: price / (normalizerWeightUnit.value / (normalizer || normalizerValue))
                };
            }
        }]);
})(angular);

(function (angular) {
    'use strict';

    var require;
    require=(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var UNITS = {
  "unit000": {
    "type": "mass",
    "size": 0.001,
    "names": {
      "en": ["mg", "milligram"],
      "he": ["מ\"ג", "מיליגרם"],
      "ru": ["мг.", "миллиграмм"],
      "es": ["mg", "miligramo"],
      "fr": ["mg", "milligramme"],
      "ar": ["مللي جرام"]
    },
    "accuracy": 10,
    "groupName": "eu"
  },
  "unit001": {
    "type": "mass",
    "size": 1,
    "names": {
      "en": ["gram", "gr", "g"],
      "he": ["גרם", "ג"],
      "ru": ["гр.", "грамм"],
      "es": ["g", "gramo"],
      "fr": ["gramme", "g", "gramme"],
      "ar": ["جرام"]
    },
    "accuracy": 10,
    "normalizer": 100,
    "groupName": "eu"
  },
  "unit002": {
    "type": "mass",
    "size": 1000,
    "names": {
      "en": ["kg", "kg", "kilogram"],
      "he": ["ק\"ג", "קילוגרם"],
      "ru": ["кг.", "килограмм"],
      "es": ["kg", "kilo", "kilogramo"],
      "fr": ["kilo", "kilo", "kilogramme"],
      "ar": ["كيلو جرام"]
    },
    "groupName": "eu"
  },
  "unit010": {
    "type": "volume",
    "size": 1,
    "names": {
      "en": ["ml", "milliliter"],
      "he": ["מ\"ל", "מיליליטר"],
      "ru": ["мл.", "миллилитр"],
      "es": ["ml", "mililitro"],
      "fr": ["ml", "millilitre"],
      "ar": ["مل"]
    },
    "accuracy": 10,
    "normalizer": 100,
    "groupName": "eu"
  },
  "unit011": {
    "type": "volume",
    "size": 1000,
    "names": {
      "en": ["liter", "ltr"],
      "he": ["ליטר", "ל"],
      "ru": ["литр", "л."],
      "es": ["litro", "l"],
      "fr": ["litre", "litre"],
      "ar": ["لتر"]
    },
    "groupName": "eu"
  },
  "unit020": {
    "type": "length",
    "size": 1,
    "names": {
      "en": ["cm", "centimeter"],
      "he": ["ס\"מ", "סנטימטר"],
      "ru": ["см.", "сантиметр"],
      "es": ["cm", "centímetro"],
      "fr": ["cm", "centimètre"],
      "ar": ["سم"]
    },
    "accuracy": 100,
    "groupName": "eu"
  },
  "unit021": {
    "type": "length",
    "size": 100,
    "names": {
      "en": ["m", "meter"],
      "he": ["מטר"],
      "ru": ["м.", "метр"],
      "es": ["m", "metro"],
      "fr": ["m", "mètre"],
      "ar": ["متر"]
    },
    "accuracy": 100,
    "normalizer": 100,
    "groupName": "eu"
  },
  "unit030": {
    "type": "area",
    "size": 1,
    "names": {
      "en": ["sq cm", "sq centimeter", "square centimeter"],
      "he": ["סמ\"ר", "סנטימטר רבוע", "סנטימטר מרובע"]
    },
    "accuracy": 100,
    "groupName": "eu"
  },
  "unit031": {
    "type": "area",
    "size": 10000,
    "names": {
      "en": ["sq m", "sq meter", "square meter"],
      "he": ["מ\"ר", "מטר רבוע", "מטר מרובע"]
    },
    "accuracy": 100,
    "groupName": "eu"
  },
  "unit100": {
    "type": "mass",
    "size": 28.349523125,
    "names": {
      "en": ["oz"],
      "he": ["אונקיה"],
      "ru": ["унция"],
      "es": ["oz", "onza"],
      "fr": ["oz", "once"],
      "ar": ["أونصة"]
    },
    "accuracy": 100,
    "groupName": "us"
  },
  "unit101": {
    "type": "mass",
    "size": 453.59237,
    "names": {
      "en": ["lb", "pound"],
      "he": ["פאונד"],
      "ru": ["фунт"],
      "es": ["lb", "libra"],
      "fr": ["lb", "livre"],
      "ar": ["رطل"]
    },
    "groupName": "us"
  },
  "unit110": {
    "type": "volume",
    "size": 29.5735,
    "names": {
      "en": ["fl oz", "floz"],
      "he": ["אונקיית נוזל"],
      "ru": ["жидкая унция"],
      "es": ["fl oz", "onza fluida"],
      "fr": ["fl oz", "once liquide"],
      "ar": ["أونصة سائلة"]
    },
    "accuracy": 100,
    "groupName": "us"
  },
  "unit111": {
    "type": "volume",
    "size": 3785.411784001,
    "names": {
      "en": ["gal", "gallon"],
      "he": ["גלון"],
      "ru": ["галлон"],
      "es": ["gal", "galón"],
      "fr": ["gallon"],
      "ar": ["جالون"]
    },
    "groupName": "us"
  },
  "unit120": {
    "type": "length",
    "size": 2.54,
    "names": {
      "en": ["in", "inch"],
      "he": ["אינטש"],
      "ru": ["дюйм"],
      "fr": ["pouce"],
      "ar": ["بوصة"]
    },
    "accuracy": 100,
    "groupName": "us"
  },
  "unit121": {
    "type": "length",
    "size": 30.48,
    "names": {
      "en": ["ft", "feet"],
      "he": ["רגל"],
      "ru": ["фут"],
      "fr": ["pieds"],
      "ar": ["قدم"]
    },
    "accuracy": 100,
    "groupName": "us"
  },
  "unit130": {
    "type": "area",
    "size": 6.4516,
    "names": {
      "en": ["sq in", "sq inch", "square inch"],
      "he": ["אינטש רבוע"],
      "fr": ["pouce carré"],
      "ar": ["بوصة مربعة"]
    },
    "accuracy": 100,
    "groupName": "us"
  },
  "unit131": {
    "type": "area",
    "size": 929.03,
    "names": {
      "en": ["sq ft", "sq feet", "square feet"],
      "he": ["רגל רבוע"],
      "fr": ["pieds carrés"],
      "ar": ["قدم مربع"]
    },
    "accuracy": 100,
    "groupName": "us"
  },
  "unit200": {
    "type": "unit",
    "size": 1,
    "names": {
      "en": ["unit", "units", "item", "items", "ct", "count"],
      "he": ["יח'", "יח", "יחידה", "יחידות"],
      "ru": ["Ед. изм"],
      "es": ["unidad"],
      "fr": ["unité", "unités"],
      "ar": ["عنصر"]
    },
    "normalizer": 10,
    "universal": true,
    "groupName": "universal"
  }
};
module.exports = {
  GROUPS: {
    "eu": {
      "mass": {
        convert: true,
        units: [UNITS.unit000, UNITS.unit001, UNITS.unit002]
      },
      "volume": {
        convert: true,
        units: [UNITS.unit010, UNITS.unit011]
      },
      "length": {
        convert: true,
        units: [UNITS.unit020, UNITS.unit021]
      },
      "area": {
        convert: true,
        units: [UNITS.unit030, UNITS.unit031]
      }
    },
    "us": {
      "mass": {
        convert: true,
        units: [UNITS.unit100, UNITS.unit101]
      },
      "volume": {
        convert: false,
        units: [UNITS.unit110, UNITS.unit111]
      },
      "length": {
        convert: true,
        units: [UNITS.unit120, UNITS.unit121]
      },
      "area": {
        convert: true,
        units: [UNITS.unit130, UNITS.unit131]
      }
    },
    "universal": {
      "unit": {
        convert: false,
        units: [UNITS.unit200]
      }
    }
  },
  BY_NAME: {
    "mg": UNITS.unit000,
    "milligram": UNITS.unit000,
    "מ\"ג": UNITS.unit000,
    "מיליגרם": UNITS.unit000,
    "мг.": UNITS.unit000,
    "миллиграмм": UNITS.unit000,
    "miligramo": UNITS.unit000,
    "milligramme": UNITS.unit000,
    "مللي جرام": UNITS.unit000,
    "gram": UNITS.unit001,
    "gr": UNITS.unit001,
    "g": UNITS.unit001,
    "גרם": UNITS.unit001,
    "ג": UNITS.unit001,
    "гр.": UNITS.unit001,
    "грамм": UNITS.unit001,
    "gramo": UNITS.unit001,
    "gramme": UNITS.unit001,
    "جرام": UNITS.unit001,
    "kg": UNITS.unit002,
    "kilogram": UNITS.unit002,
    "ק\"ג": UNITS.unit002,
    "קילוגרם": UNITS.unit002,
    "кг.": UNITS.unit002,
    "килограмм": UNITS.unit002,
    "kilo": UNITS.unit002,
    "kilogramo": UNITS.unit002,
    "kilogramme": UNITS.unit002,
    "كيلو جرام": UNITS.unit002,
    "ml": UNITS.unit010,
    "milliliter": UNITS.unit010,
    "מ\"ל": UNITS.unit010,
    "מיליליטר": UNITS.unit010,
    "мл.": UNITS.unit010,
    "миллилитр": UNITS.unit010,
    "mililitro": UNITS.unit010,
    "millilitre": UNITS.unit010,
    "مل": UNITS.unit010,
    "liter": UNITS.unit011,
    "ltr": UNITS.unit011,
    "ליטר": UNITS.unit011,
    "ל": UNITS.unit011,
    "литр": UNITS.unit011,
    "л.": UNITS.unit011,
    "litro": UNITS.unit011,
    "l": UNITS.unit011,
    "litre": UNITS.unit011,
    "لتر": UNITS.unit011,
    "cm": UNITS.unit020,
    "centimeter": UNITS.unit020,
    "ס\"מ": UNITS.unit020,
    "סנטימטר": UNITS.unit020,
    "см.": UNITS.unit020,
    "сантиметр": UNITS.unit020,
    "centímetro": UNITS.unit020,
    "centimètre": UNITS.unit020,
    "سم": UNITS.unit020,
    "m": UNITS.unit021,
    "meter": UNITS.unit021,
    "מטר": UNITS.unit021,
    "м.": UNITS.unit021,
    "метр": UNITS.unit021,
    "metro": UNITS.unit021,
    "mètre": UNITS.unit021,
    "متر": UNITS.unit021,
    "sq cm": UNITS.unit030,
    "sq centimeter": UNITS.unit030,
    "square centimeter": UNITS.unit030,
    "סמ\"ר": UNITS.unit030,
    "סנטימטר רבוע": UNITS.unit030,
    "סנטימטר מרובע": UNITS.unit030,
    "sq m": UNITS.unit031,
    "sq meter": UNITS.unit031,
    "square meter": UNITS.unit031,
    "מ\"ר": UNITS.unit031,
    "מטר רבוע": UNITS.unit031,
    "מטר מרובע": UNITS.unit031,
    "oz": UNITS.unit100,
    "אונקיה": UNITS.unit100,
    "унция": UNITS.unit100,
    "onza": UNITS.unit100,
    "once": UNITS.unit100,
    "أونصة": UNITS.unit100,
    "lb": UNITS.unit101,
    "pound": UNITS.unit101,
    "פאונד": UNITS.unit101,
    "фунт": UNITS.unit101,
    "libra": UNITS.unit101,
    "livre": UNITS.unit101,
    "رطل": UNITS.unit101,
    "fl oz": UNITS.unit110,
    "floz": UNITS.unit110,
    "אונקיית נוזל": UNITS.unit110,
    "жидкая унция": UNITS.unit110,
    "onza fluida": UNITS.unit110,
    "once liquide": UNITS.unit110,
    "أونصة سائلة": UNITS.unit110,
    "gal": UNITS.unit111,
    "gallon": UNITS.unit111,
    "גלון": UNITS.unit111,
    "галлон": UNITS.unit111,
    "galón": UNITS.unit111,
    "جالون": UNITS.unit111,
    "in": UNITS.unit120,
    "inch": UNITS.unit120,
    "אינטש": UNITS.unit120,
    "дюйм": UNITS.unit120,
    "pouce": UNITS.unit120,
    "بوصة": UNITS.unit120,
    "ft": UNITS.unit121,
    "feet": UNITS.unit121,
    "רגל": UNITS.unit121,
    "фут": UNITS.unit121,
    "pieds": UNITS.unit121,
    "قدم": UNITS.unit121,
    "sq in": UNITS.unit130,
    "sq inch": UNITS.unit130,
    "square inch": UNITS.unit130,
    "אינטש רבוע": UNITS.unit130,
    "pouce carré": UNITS.unit130,
    "بوصة مربعة": UNITS.unit130,
    "sq ft": UNITS.unit131,
    "sq feet": UNITS.unit131,
    "square feet": UNITS.unit131,
    "רגל רבוע": UNITS.unit131,
    "pieds carrés": UNITS.unit131,
    "قدم مربع": UNITS.unit131,
    "unit": UNITS.unit200,
    "units": UNITS.unit200,
    "item": UNITS.unit200,
    "items": UNITS.unit200,
    "ct": UNITS.unit200,
    "count": UNITS.unit200,
    "יח'": UNITS.unit200,
    "יח": UNITS.unit200,
    "יחידה": UNITS.unit200,
    "יחידות": UNITS.unit200,
    "Ед. изм": UNITS.unit200,
    "unidad": UNITS.unit200,
    "unité": UNITS.unit200,
    "unités": UNITS.unit200,
    "عنصر": UNITS.unit200
  },
  NORMALIZERS: {
    "eu": {
      "mass": UNITS.unit001,
      "volume": UNITS.unit010,
      "length": UNITS.unit021
    },
    "universal": {
      "unit": UNITS.unit200
    }
  },
  LANGUAGE_BY_NAME: {
    "mg": "en",
    "milligram": "en",
    "מ\"ג": "he",
    "מיליגרם": "he",
    "мг.": "ru",
    "миллиграмм": "ru",
    "miligramo": "es",
    "milligramme": "fr",
    "مللي جرام": "ar",
    "gram": "en",
    "gr": "en",
    "g": "en",
    "גרם": "he",
    "ג": "he",
    "гр.": "ru",
    "грамм": "ru",
    "gramo": "es",
    "gramme": "fr",
    "جرام": "ar",
    "kg": "en",
    "kilogram": "en",
    "ק\"ג": "he",
    "קילוגרם": "he",
    "кг.": "ru",
    "килограмм": "ru",
    "kilo": "es",
    "kilogramo": "es",
    "kilogramme": "fr",
    "كيلو جرام": "ar",
    "ml": "en",
    "milliliter": "en",
    "מ\"ל": "he",
    "מיליליטר": "he",
    "мл.": "ru",
    "миллилитр": "ru",
    "mililitro": "es",
    "millilitre": "fr",
    "مل": "ar",
    "liter": "en",
    "ltr": "en",
    "ליטר": "he",
    "ל": "he",
    "литр": "ru",
    "л.": "ru",
    "litro": "es",
    "l": "es",
    "litre": "fr",
    "لتر": "ar",
    "cm": "en",
    "centimeter": "en",
    "ס\"מ": "he",
    "סנטימטר": "he",
    "см.": "ru",
    "сантиметр": "ru",
    "centímetro": "es",
    "centimètre": "fr",
    "سم": "ar",
    "m": "en",
    "meter": "en",
    "מטר": "he",
    "м.": "ru",
    "метр": "ru",
    "metro": "es",
    "mètre": "fr",
    "متر": "ar",
    "sq cm": "en",
    "sq centimeter": "en",
    "square centimeter": "en",
    "סמ\"ר": "he",
    "סנטימטר רבוע": "he",
    "סנטימטר מרובע": "he",
    "sq m": "en",
    "sq meter": "en",
    "square meter": "en",
    "מ\"ר": "he",
    "מטר רבוע": "he",
    "מטר מרובע": "he",
    "oz": "en",
    "אונקיה": "he",
    "унция": "ru",
    "onza": "es",
    "once": "fr",
    "أونصة": "ar",
    "lb": "en",
    "pound": "en",
    "פאונד": "he",
    "фунт": "ru",
    "libra": "es",
    "livre": "fr",
    "رطل": "ar",
    "fl oz": "en",
    "floz": "en",
    "אונקיית נוזל": "he",
    "жидкая унция": "ru",
    "onza fluida": "es",
    "once liquide": "fr",
    "أونصة سائلة": "ar",
    "gal": "en",
    "gallon": "en",
    "גלון": "he",
    "галлон": "ru",
    "galón": "es",
    "جالون": "ar",
    "in": "en",
    "inch": "en",
    "אינטש": "he",
    "дюйм": "ru",
    "pouce": "fr",
    "بوصة": "ar",
    "ft": "en",
    "feet": "en",
    "רגל": "he",
    "фут": "ru",
    "pieds": "fr",
    "قدم": "ar",
    "sq in": "en",
    "sq inch": "en",
    "square inch": "en",
    "אינטש רבוע": "he",
    "pouce carré": "fr",
    "بوصة مربعة": "ar",
    "sq ft": "en",
    "sq feet": "en",
    "square feet": "en",
    "רגל רבוע": "he",
    "pieds carrés": "fr",
    "قدم مربع": "ar",
    "unit": "en",
    "units": "en",
    "item": "en",
    "items": "en",
    "ct": "en",
    "count": "en",
    "יח'": "he",
    "יח": "he",
    "יחידה": "he",
    "יחידות": "he",
    "Ед. изм": "ru",
    "unidad": "es",
    "unité": "fr",
    "unités": "fr",
    "عنصر": "ar"
  }
};

},{}],"Converter":[function(require,module,exports){
'use strict';

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var DEFAULT_LANGUAGE = 'en',
    UNITS = require('./generated/units');

module.exports = {
  UNITS: UNITS,
  convert: convert
};
/**
 * Convert unit to group unit
 * @public
 *
 * @param {number} value
 * @param {string|Object} from
 * @param {Object} [options]
 * @param {string} [options.toGroup]
 * @param {string} [options.toUnit] - will override toGroup when the unit is found
 * @param {boolean} [options.convertInGroup=false]
 * @param {number} [options.minUnitSize=1]
 * @param {number} [options.defaultLanguage=1]
 *
 * @return { { names: Object, value: Number, accuracy: Number } }
 */

function convert(value, from, options) {
  options = options || {};

  var fromUnit = _unitByName(from);

  if (!fromUnit) {
    return {
      names: _defineProperty({}, options.defaultLanguage || DEFAULT_LANGUAGE, from),
      value: value,
      accuracy: 100
    };
  }

  var toUnit; // when has to unit, set the to group by it

  if (options.toUnit) {
    toUnit = _unitByName(options.toUnit);

    if (toUnit) {
      options.toGroup = toUnit.groupName;
    }
  } // when no toGroup, set it to the same as the from


  if (!options.toGroup) {
    options.toGroup = fromUnit.groupName;
  }

  var fromUnitRet = {
    names: _objectSpread({}, fromUnit.names),
    value: value,
    accuracy: fromUnit.accuracy
  };

  if (typeof from === 'string') {
    var language = UNITS.LANGUAGE_BY_NAME[from.toLowerCase()];
    fromUnitRet.names[language] = [from].concat(_toConsumableArray(fromUnit.names[language]));
  }

  if (!toUnit && !options.convertInGroup && fromUnit.groupName === options.toGroup || !UNITS.GROUPS[options.toGroup][fromUnit.type] || !UNITS.GROUPS[options.toGroup][fromUnit.type].convert) {
    return fromUnitRet;
  }

  toUnit = toUnit || _getUnitByGroupAndValue(UNITS.GROUPS[options.toGroup][fromUnit.type].units, value * fromUnit.size, options.minUnitSize);

  if (!toUnit) {
    return fromUnitRet;
  }

  if (toUnit.type !== fromUnit.type) {
    throw new Error("Cannot parse from '".concat(fromUnit.type, "' to '").concat(toUnit.type, "' type"));
  }

  return {
    names: toUnit.names,
    value: value * fromUnit.size / toUnit.size,
    accuracy: toUnit.accuracy === undefined ? 100 : toUnit.accuracy
  };
}
/**
 * Get unit by name
 * @private
 *
 * @param {String|Object|Undefined} name
 *
 * @returns {Object|Undefined}
 */


function _unitByName(name) {
  if (!name || name && typeof name !== 'string') {
    return name;
  }

  return UNITS.BY_NAME[(name || '').toLowerCase()];
}
/**
 * Get unit by group value
 * @private
 *
 * @param {Object} group
 * @param {Number} value
 * @param {Number=1} [minUnitSize]
 *
 * @return {Object} unit
 */


function _getUnitByGroupAndValue(group, value, minUnitSize) {
  if (group.length === 1) {
    return group[0];
  }

  minUnitSize = minUnitSize || 1;

  for (var i = 1; i < group.length; i++) {
    if (value / group[i].size < minUnitSize) {
      return group[i - 1];
    }
  }

  return group[group.length - 1];
}

},{"./generated/units":1}]},{},[]);
;

    var coreConverter = require('Converter');

    angular.module('spUnits')
        .constant('SP_UNITS', coreConverter.UNITS)
        .service('spUnitsCoreConverter', [function() {
            var self = this;

            self.convert = coreConverter.convert;
        }]);
})(angular);
