angular.module('spVerticalValueSlider', []).directive('spVerticalValueSlider', ['$parse', '$timeout', function ($parse, $timeout) {
    return {
        restrict: 'EA',
        link: function ($scope, $element, attrs) {
            $element.css('overflow', 'hidden');

            var $lastElement,
                toRemove = [],
                animationsQueue = [],
                ANIMATION_TIME = 200,
                _keyUpModelTimeout;

            function _cancelKeyUpModelTimeout() {
                if (!_keyUpModelTimeout) return;

                $timeout.cancel(_keyUpModelTimeout);
                _keyUpModelTimeout = null;
            }

            function _getAriaLabel() {
                var text = {
                    'he': 'כמות',
                    'en': 'quantity'
                }
            
                var lang = $scope.$eval(attrs.spLang);
                return text[lang] || text['en'];
            }

            function animate() {
                _cancelKeyUpModelTimeout();
                var tagName = attrs.spTagName || 'input',
                    currentValue = animationsQueue[0],
                    animationTime = ANIMATION_TIME / animationsQueue.length;
                tagName = tagName.toLowerCase();
                var $newElement = angular.element(document.createElement(tagName)).css({
                    'display': 'block',
                    'height': '100%',
                    'width': '100%',
                    'box-sizing': 'border-box',
                    'transition': 'margin ' + animationTime + 'ms linear'
                });
                tagName == 'input' && $newElement.prop({
                    type: 'number',
                    step: 'any',
                    ariaLabel: _getAriaLabel(),
                    disabled: $scope.$eval(attrs.spDisabled)
                });

                if (tagName == 'input') {
                    $newElement.bind('keypress', function(event){
                        if (!attrs.spIncludeRegex) return;

                        var regex = new RegExp(attrs.spIncludeRegex);
                        return regex.test(String.fromCharCode(event.which));
                    }).bind('keyup', function (event) {
                        var num = Number($newElement.val());
                        if (!isNaN(num)) {
                            _cancelKeyUpModelTimeout();
                            $parse(attrs.spModel).assign($scope, num);
                            if (attrs.spChange) {
                                $scope.$eval(attrs.spChange, {
                                    $event: event
                                });
                            }
                            $scope.$applyAsync();
                            _keyUpModelTimeout = $timeout(function() {
                                var modelVal = $scope.$eval(attrs.spModel);
                                if (num == modelVal) return;
                                $lastElement.val(modelVal);
                            });
                        }
                    }).val(currentValue.newVal);

                    if (attrs.spFocus) {
                        $newElement.bind('focus', function (event) {
                            $scope.$eval(attrs.spFocus, {
                                $event: event
                            });
                            $scope.$applyAsync();
                        });
                    }
                    if (attrs.spBlur) {
                        $newElement.bind('blur', function (event) {
                            $scope.$eval(attrs.spBlur, {
                                $event: event
                            });
                            $scope.$applyAsync();
                        });
                    }
                } else {
                    $newElement.text(currentValue.newVal);
                }

                if ($lastElement) {
                    toRemove.push($lastElement);

                    if (currentValue.newVal > (currentValue.oldVal || 0)) {
                        $element.append($newElement);
                        $lastElement.css({
                            'margin-top': '-' + $lastElement[0].offsetHeight + 'px'
                        });
                    } else {
                        $newElement.css({
                            'margin-top': '-' + $lastElement[0].offsetHeight + 'px'
                        });
                        $element.prepend($newElement);
                        setTimeout(function () {
                            $newElement.css({
                                'margin-top': ''
                            });
                        }, 20);
                    }

                    setTimeout(function () {
                        animationsQueue.splice(0, 1);
                        if (animationsQueue.length) {
                            animate();
                        }
                        if (toRemove.length) {
                            toRemove[0].remove();
                            toRemove.splice(0, 1);
                        }
                    }, animationTime);
                } else {
                    $element.prepend($newElement);
                    animationsQueue.splice(0, 1);
                }

                $lastElement = $newElement;
            }

            $scope.$watch(attrs.spModel, function (newVal, oldVal) {
                if ($lastElement && $lastElement[0] == document.activeElement) {
                    newVal.toString() !== $lastElement.val() && $lastElement.val(newVal);
                    return;
                }

                animationsQueue.push({
                    newVal: newVal,
                    oldVal: oldVal
                });

                if (animationsQueue.length == 1) {
                    animate();
                }
            });
        }
    };
}]);