(function (angular) {
    var isNoDeliveryForTodayShown = false;

    angular.module('mobilezuz').directive('nextDelivery', [
        '$q', '$timeout', 'User', 'Config', 'Orders', 'SpDeliveryTimesService', 'Retailer', 'Cart', 'Util',
        'SpDeliveryAreasService', 'DELIVERY_TIMES_TYPES', 'DELIVERY_TYPES', 'SP_SERVICES', 'RETAILER_STATUSES', 'mDesign',
        function ($q, $timeout, user, Config, Orders, spDeliveryTimesService, Retailer, Cart, Util,
                  SpDeliveryAreasService, DELIVERY_TIMES_TYPES, DELIVERY_TYPES, SP_SERVICES, RETAILER_STATUSES, mDesign) {
            return {
                templateUrl: 'views/templates/next-delivery.html',
                replace: true,
                controller: ['$scope', '$rootScope', function($scope, $rootScope) {
                    var _listeners = [];

                    $scope.toggleMoreDetails = toggleMoreDetails;
                    $scope.deliveryNoAvailableSlotsPopup = deliveryNoAvailableSlotsPopup;
                    $scope.setCurrentMoreSlots = setCurrentMoreSlots;
                    $scope.ordersService = Orders;
                    $scope.nextDelivery = {};
                    $rootScope.noAvailableDeliverySlots = $rootScope.noAvailableDeliverySlots || false;
                    $scope.deliveryDisclaimerPopUpMessage = {};
                    $scope.isEnableDeliveryDisclaimerPopUp = false;
                    $scope.deliveryDisclamerPopupColor = {};
                    function _setNextDelivery() {
                        Util.fetchingUserArea = true;
                        Config.waitForInit().then(function() {
                            $scope.languageId = Config.language.id;
                            $scope.deliveryDisclaimerPopUpMessage = JSON.parse(Config.retailer.settings.deliveryDisclaimerPopUpMessage || '{}');
                            $scope.isEnableDeliveryDisclaimerPopUp = Config.retailer.settings.isEnableDeliveryDisclaimerPopUp === 'true'; 
                            $scope.deliveryDisclamerPopupColor = JSON.parse(Config.retailer.settings.deliveryDisclamerPopupColor || '{}');
                            return spDeliveryTimesService.getNextDeliveryTimes($scope.nextDelivery, _getUserAddress, Config.getBranchArea(), Cart.serverCartId);
                        }).then(function(result) {
                            Config.deliveryAreaPrice = result.area.deliveryAreaPrice;
                            SpDeliveryAreasService.getChooseAreaEvent().then(function(areaEvent){
                                var area = result.area;
                                if (areaEvent === SP_SERVICES.CHOOSE_AREA_EVENT.NONE) {
                                    var branchAreaId = Number(localStorage.getItem('mobileZuzBranchAreaId'));
                                    Config.setBranchArea(branchAreaId > 0 ? branchAreaId : Number(area.id), area.name);
                                }
                            })

                            if (!result.nextTime || (!result.nextTime.isToday && result.nextTime.type !== DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_HOURS)) {
                                _showNoDeliveriesTodayPopup();
                            }

                            $rootScope.noAvailableDeliverySlots = result.noAvailableDeliverySlots;
                        })
                        .catch(function() {
                            if ($scope.nextDelivery.times) {
                                $scope.nextDelivery.times.splice(0);
                            }
                        })
                        .finally(function() {
                            Util.fetchingUserArea = false;
                            $rootScope.$emit('user.fetchArea.finish');
                            $rootScope.$emit('nextDelivery.toggle');
                            if ($scope.nextDelivery.moreSlots && Object.keys($scope.nextDelivery.moreSlots).length > 0) {
                                setCurrentMoreSlots($scope.nextDelivery.moreSlots[$scope.nextDelivery.currentNextDeliveryTypeId], $scope.nextDelivery.currentNextDeliveryTypeId);
                            }
                        })
                    }

                    function _getUserAddress() {
                        if (!user.getUserLoginData()) {
                            return $q.reject('not logged in');
                        }

                        return user.getUserSettings().then(function(data) {
                            var address = data.addresses[0];
                            if (!address || address.city === null) {
                                return $q.reject('user has no address');
                            }

                            return {
                                address: {
                                    text1: address.text1,
                                    street: address.street,
                                    houseNumber: address.houseNumber,
                                    entry: address.entry,
                                    city: address.city,
                                    zipCode: address.zipCode
                                },
                                languageId: Config.language.id
                            };
                        });
                    }

                    _setNextDelivery();

                    function _clickOnBodyToCloseMoreDetails(event) {
                        var target = angular.element(event.target || event.srcElement || event.path[0]);
                        while (target.length && !target.hasClass('next-delivery-wrapper')) {
                            target = angular.element(target.prop('parentNode') || target.prop('parentElement'));
                        }
                        if (target.hasClass('next-delivery-wrapper')) {
                            return;
                        }

                        toggleMoreDetails();
                        $scope.$applyAsync();
                    }

                    function setCurrentMoreSlots(areasSlots, deliveryTypeId) {
                        spDeliveryTimesService.setNextDeliveryCurrentSlots($scope.nextDelivery, areasSlots, deliveryTypeId);
                        $scope.selectedDeliveryType = deliveryTypeId;
                    }

                    function toggleMoreDetails() {
                        if (!$scope.nextDelivery || $scope.nextDelivery && !$scope.nextDelivery.times.length) return;

                        $scope.showMoreDetails = !$scope.showMoreDetails;
                        if ($scope.showMoreDetails) {
                            angular.element(document.body || document.querySelector('body')).bind('touchstart', _clickOnBodyToCloseMoreDetails);
                        } else {
                            angular.element(document.body || document.querySelector('body')).unbind('touchstart', _clickOnBodyToCloseMoreDetails);
                        }
                    }

                    function _showNoDeliveriesTodayPopup() {
                         var isLoggedIn = !!user.getUserLoginData() || false;
                         if (!isNoDeliveryForTodayShown && Config && Config.retailer && Config.retailer.settings &&
                             (Config.retailer.status === RETAILER_STATUSES.ACTIVE || isLoggedIn) &&
                             Config.retailer.settings.noDeliveriesTodayText && Config.retailer.settings.noDeliveriesTodayText.trim() &&
                             !Config.preventDialogsOnLoad) {
                            isNoDeliveryForTodayShown = true;
                            return mDesign.dialog({
                                controller: ['$scope', 'mDesign', function ($scope, mDesign) {
                                    $scope.hide = function () {
                                        mDesign.hide();
                                    };
                                }],
                                template: '<md-dialog class="on-no-deliveries-today-container"><md-dialog-content class="noDeliveriesTodayText">' + Config.retailer.settings.noDeliveriesTodayText + '</md-dialog-content><md-dialog-actions layout="row"><md-button ng-click="hide()" class="md-primary">{{\'OK\' | translate}}</md-button></div></md-dialog>'
                            });
                         } else {
                             return $q.resolve();
                         }
                    }

                    function deliveryNoAvailableSlotsPopup() {
                        Util.showDeliveryNoAvailableSlotsPopup(true);
                    }

                    _listeners.push($rootScope.$on('config.branchAreaId.set', function() {
                        _setNextDelivery();
                    }));
                    _listeners.push($rootScope.$on('login', function() {
                        _setNextDelivery();
                    }));

                    _listeners.push($scope.$on('$destroy', function() {
                        angular.forEach(_listeners, function(listener) {
                            listener();
                        });
                    }));
                }]
            };
        }
    ]);
})(angular);