(function (angular, app) {
    'use strict';

    app.service('FullCreditCardChargeConfirmationDialog', ['mDesign', function(mDesign) {
        this.show = show;
        this.hide = hide;

        function show() {
            return mDesign.dialog({
                focusOnOpen: true,
                disableClosing: true,
                templateUrl: 'views/order/update/full-credit-card-charge-confirmation-dialog.html',
                controller: 'FullCreditCardChargeConfirmationDialogCtrl as creditCardConfirmationDialogCtrl',
            })
        }

        function hide(data) {
            return mDesign.hide(data);
        }
    }]);

    app.run(['$rootScope', 'FullCreditCardChargeConfirmationDialog', function ($rootScope, FullCreditCardChargeConfirmationDialog) {
        $rootScope.FullCreditCardChargeConfirmationDialog = FullCreditCardChargeConfirmationDialog;
    }]);


    app.controller('FullCreditCardChargeConfirmationDialogCtrl', ['FullCreditCardChargeConfirmationDialog',
        function (fullCreditCardChargeConfirmationDialog) {
            var creditCardConfirmationDialogCtrl = this;
            creditCardConfirmationDialogCtrl.onClickConfirmButton = onClickConfirmButton;

            function onClickConfirmButton(isChargeConfirmed) {
                fullCreditCardChargeConfirmationDialog.hide({ isChargeConfirmed: isChargeConfirmed });
            }
    }]);
})(angular, angular.module('mobilezuz'));