angular.module('mobilezuz').controller('missingProduct', [
    '$scope', 'Config', 'mDesign', 'tree', 'Cart', 'query',
    function ($scope, Config, mDesign, tree, cart, query) {
        $scope.categories = tree.categories;
        $scope.dialog = mDesign;

        $scope.pseudoLine = {
            quantity: '',
            isPseudo: true,
            product: {
                name: query
            }
        };

        $scope.showAlert = function (error, callback) {
            mDesign.alert(error, callback);
        };

        $scope.addPseudoProduct = function () {
            $scope.showError = false;
            if (!$scope.pseudoLine.quantity || $scope.pseudoLine.quantity < 0 || !$scope.pseudoLine.product.name || $scope.pseudoLine.product.name.length > 200) {
                $scope.showError = true;
                return;
            }
			$scope.pseudoLine.product.name = $scope.pseudoLine.product.name.replace(/[<>=]/g, '');

            $scope.pseudoLine.product.id = $scope.pseudoLine.product.name;
            $scope.pseudoLine.product.family = {
                categories: [$scope.pseudoLine.product.category]
            };
            delete $scope.pseudoLine.product.category;

            $scope.pseudoLine.product.names = {};
            $scope.pseudoLine.product.names[Config.language.id] = {
                short: $scope.pseudoLine.product.name,
                long: $scope.pseudoLine.product.name
            };

            cart.addLine($scope.pseudoLine, null, {source: 'Replacements Pop Up'});

            var cartLine = cart.lines[cart.keyByLine($scope.pseudoLine)];
            if (cartLine && cartLine != $scope.pseudoLine) {
                cartLine.comments = $scope.pseudoLine.comments;
                cartLine.quantity = $scope.pseudoLine.quantity;
                cartLine.product = $scope.pseudoLine.product;
            }
            mDesign.hide();
        };
    }]);