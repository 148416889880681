angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.loyaltyClub', {
        url: '/loyalty-club?{showRegistrationForm},{showLookupForm}',
        data: {
            stateAccess: PAGE_ACCESS.NOT_ORGANIZATION_MEMBER,
            bodyClass: 'loyalty-club',
            metaTags: {
                title: 'Loyalty club'
            }
        },
        resolve: {
            retailerSettings: ['Retailer', function (retailer) {
                return retailer.getRetailerSettings();
            }]
        },
        views: {
            '@': {
                templateUrl: 'views/loyalty-club.html',
                controller: 'LoyaltyClubCtrl as loyaltyCtrl'
            }
        }
    });
}]).controller('LoyaltyClubCtrl', [
    '$rootScope','$scope', '$state', '$q', '$timeout', '$window', '$location', '$filter', 'Api', 'Config', 'User',
    'Util', 'Retailer', 'Cart', 'Offers', 'mDesign', 'PaymentsService', 'retailerSettings', 'SpCaptcha',
    'CART_LINE_TYPES', 'LOYALTY_CLUB_DRIVERS', 'DataLayer',
    function ($rootScope, $scope, $state, $q, $timeout, $window, $location, $filter, Api, Config, User,
              Util, Retailer, Cart, Offers, mDesign, PaymentsService, retailerSettings, SpCaptcha,
              CART_LINE_TYPES, LOYALTY_CLUB_DRIVERS, DataLayer) {
        var loyaltyCtrl = this,
            _existLoyaltyClubId,
            _timer,
            _translate = $filter('translate'),
            _listeners = []
            LOYALTY_URL = '/retailers/:rid/users/:uid/loyalty';

        loyaltyCtrl.retailerSettings = retailerSettings;
        loyaltyCtrl.backgroundImage = Retailer.backgroundImage;
        loyaltyCtrl.validateLoyaltyClubId = validateLoyaltyClubId;
        loyaltyCtrl.connectMembership = connectMembership;
        loyaltyCtrl.toggleTitleImage = toggleTitleImage;
        loyaltyCtrl.openDialer = openDialer;
        loyaltyCtrl.showRegistrationForm = showRegistrationForm;
        loyaltyCtrl.goToLogin = goToLogin;
        loyaltyCtrl.historyBack = historyBack;
        loyaltyCtrl.loggedIn = !!User.getUserLoginData();
        loyaltyCtrl.retailer = retailerSettings;
        loyaltyCtrl.retailerPhoneNumber = retailerSettings.contactPhone || (Config.branch && Config.branch.phone);
        loyaltyCtrl.isRegistrationFormShown = true || $state.params.showRegistrationForm || !$state.params.showLookupForm;
        loyaltyCtrl.isIdentificationFormShown = $state.params.showLookupForm;
        loyaltyCtrl.captchaIsInvalid = false;
        loyaltyCtrl.showCaptcha = true;
        loyaltyCtrl.goTo = goTo;
        loyaltyCtrl.getAreaStyle = getAreaStyle;
        loyaltyCtrl.initDriver = initDriver;

        _init();

        function _init() {
            loyaltyCtrl.loyaltyClubDriver = null;
            loyaltyCtrl.lookupData = null;
            loyaltyCtrl.registerData = null;
            loyaltyCtrl.LOYALTY_MODES = {LOOKUP: 1, REGISTER: 2};
            loyaltyCtrl.loyaltyMode = null;
            loyaltyCtrl.loyaltyChanged = null;
            loyaltyCtrl.loyaltyClubExpired = null;
            loyaltyCtrl.clientConfig =null;
            loyaltyCtrl.lookupFields = null;
            loyaltyCtrl.registerFields = null;
            loyaltyCtrl.alertRegisterIfCartAboveValue = null;
            loyaltyCtrl.userLoyaltyClub = null;
            loyaltyCtrl.loyaltyClubCardId = null;
            loyaltyCtrl.loyaltyClubExpiration = null;
            loyaltyCtrl.loyaltyClubExpired = null;

            if (loyaltyCtrl.loggedIn) {
                User.getUserSettings().then(function (resp) {
                    loyaltyCtrl.userData = resp;

                    loyaltyCtrl.userClubsByDriversObject = {};
                    angular.forEach((loyaltyCtrl.userData.loyaltyClubs || []), function (loyaltyClub) {
                        loyaltyClub.isExpired = !!loyaltyClub.loyaltyClubExpiration && new Date(loyaltyClub.loyaltyClubExpiration) < new Date();
                        loyaltyCtrl.userClubsByDriversObject[loyaltyClub.loyaltyClubDriverId] = loyaltyClub;
                    });

                    if (loyaltyCtrl.retailer.loyaltyClubDrivers.length > 1) {
                        loyaltyCtrl.isMultiLoyalty = true;
                    } else {
                        initDriver(loyaltyCtrl.retailer.loyaltyClubDrivers[0]);
                    }
                });
            }
        }

        function initDriver(driver) {
            //$window.history.pushState(null, '', '?loyaltyClubDriverId=' + driver.loyaltyClubDriverId);
            if (loyaltyCtrl.isMultiLoyalty) {
                $rootScope.preventHistoryBack = true;
            }
            return Api.request({
                method: 'GET',
                url: 'retailers/:rid/loyalty/fields',
                params: {
                    retailerLoyaltyClubDriverId: driver.id
                }
            }).then(function (fields) {
                loyaltyCtrl.loyaltyClubDriver = driver;
                loyaltyCtrl.lookupData = {};
                loyaltyCtrl.registerData = {};
                loyaltyCtrl.LOYALTY_MODES = {LOOKUP: 1, REGISTER: 2};
                loyaltyCtrl.loyaltyMode = loyaltyCtrl.LOYALTY_MODES.REGISTER;
                loyaltyCtrl.loyaltyChanged = false;
                loyaltyCtrl.loyaltyClubExpired = false;
                loyaltyCtrl.clientConfig = driver.clientConfig;
                loyaltyCtrl.lookupFields = fields.lookup;
                loyaltyCtrl.registerFields = fields.register;
                loyaltyCtrl.barcodeWithCheck = '';
                if (loyaltyCtrl.clientConfig.alertRegisterIfCartAbove && loyaltyCtrl.clientConfig.alertRegisterIfCartAbove < Cart.total.priceForView) {
                    loyaltyCtrl.alertRegisterIfCartAboveValue = Cart.total.simulateClubsGiftsForView;
                }
                if (loyaltyCtrl.clientConfig) {
                    loyaltyCtrl.joinClubActive = loyaltyCtrl.clientConfig.joinClubActive;
                    loyaltyCtrl.connectClubActive = loyaltyCtrl.clientConfig.connectClubActive;
                }
                _setLoyaltyInfo();

                fillUserDataToLoyaltyRegistrationForm(loyaltyCtrl.userData);

                $timeout(function() {
                    if (loyaltyCtrl.loyaltyClubDriver.clientConfig.addBarcodeOnLoyaltyMobile) {
                        var barcode = loyaltyCtrl.userClubsByDriversObject[loyaltyCtrl.loyaltyClubDriver.loyaltyClubDriverId].loyaltyCardId;
                        loyaltyCtrl.barcodeWithCheck = barcode + generateGS1CheckDigit(barcode);
                        JsBarcode("#barcode_" + loyaltyCtrl.loyaltyClubDriver.loyaltyClubDriverId, loyaltyCtrl.barcodeWithCheck, {
                            displayValue: false,
                            format: "UPC",
                            flat: true
                        });
                    }
                }, 0);

            });

        }

        function generateGS1CheckDigit(input) {
            var array = input.split('').reverse();
            var total = 0;
            var i = 1;
            array.forEach(function(number){
                number = parseInt(number);
                if (i % 2 === 0) {
                    total = total + number;
                } else {
                    total = total + (number * 3);
                }
                i++;
            });
            return (Math.ceil(total / 10) * 10) - total;
        }

        function _setLoyaltyInfo() {
            if (loyaltyCtrl.loggedIn && loyaltyCtrl.loyaltyClubDriver) {
                if ((loyaltyCtrl.loyaltyClubDriver.clientConfig.url &&  loyaltyCtrl.loyaltyClubDriver.clientConfig.url.registrationUrl) &&
                    loyaltyCtrl.loyaltyClubDriver.loyaltyClubDriverId === LOYALTY_CLUB_DRIVERS.CLUB_365) {
                    var userLoyalty = loyaltyCtrl.userData.loyaltyClubs.find(function (loyaltyClub) {
                        return loyaltyClub.loyatlyClubDriverId === LOYALTY_CLUB_DRIVERS.CLUB_365;
                    });

                    if (!!userLoyalty && !userLoyalty.loyaltyVerificationStatus) {
                        _checkLoyaltyStatus();
                    }
                }

                if ($state.params.showRegistrationForm) {
                    showRegistrationForm();
                }

                loyaltyCtrl.userLoyaltyClub = loyaltyCtrl.userClubsByDriversObject[loyaltyCtrl.loyaltyClubDriver.loyaltyClubDriverId];
                var loyaltyClubCardId = loyaltyCtrl.userLoyaltyClub && loyaltyCtrl.userLoyaltyClub.loyaltyCardId;

                if (!loyaltyClubCardId) {
                    loyaltyCtrl.loyaltyChanged = true;
                    return;
                }

                _existLoyaltyClubId = loyaltyClubCardId;
                loyaltyCtrl.loyaltyClubCardId = loyaltyClubCardId;
                loyaltyCtrl.loyaltyClubExpiration = loyaltyCtrl.userLoyaltyClub.loyaltyClubExpiration;
                loyaltyCtrl.loyaltyClubExpired = loyaltyCtrl.loyaltyClubExpiration && new Date(loyaltyCtrl.loyaltyClubExpiration) < new Date();
                if (loyaltyCtrl.lookupFields.length === 1) {
                    return loyaltyCtrl.lookupData[loyaltyCtrl.lookupFields[0].key] = _existLoyaltyClubId;
                }

                //todo: find out how to know which field is the loyalty number
                loyaltyCtrl.lookupData.customerId = _existLoyaltyClubId;
            }
        }

        $rootScope.$on('loyaltyLoginActionsFinished', function() {
            _setLoyaltyInfo();
        });

        function validateLoyaltyClubId() {
            loyaltyCtrl.loyaltyChanged = (loyaltyCtrl.lookupFields.length == 1 ? loyaltyCtrl.lookupData[loyaltyCtrl.lookupFields[0].key] : loyaltyCtrl.lookupData.customerId) != _existLoyaltyClubId;
        }

        function showRegistrationForm() {
            if (!loyaltyCtrl.loggedIn) {
                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Show loyalty club when need to register or login'}});

                goToLogin();
            } else {
                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Show loyalty club'}});
                loyaltyCtrl.isRegistrationFormShown = true;
                fillUserDataToLoyaltyRegistrationForm(loyaltyCtrl.userData);
            }
        }

        function fillUserDataToLoyaltyRegistrationForm(userData) {
            if (!userData || !userData.id || !loyaltyCtrl.registerFields) {
                return;
            }

            angular.forEach(loyaltyCtrl.registerFields, function (registerField) {
                switch(registerField.key) {
                    case 'Email':
                        if(userData.email) loyaltyCtrl.registerData['Email'] = userData.email;
                        break;
                    case 'FirstName':
                        if(userData.firstName) loyaltyCtrl.registerData['FirstName'] = userData.firstName;
                        break;
                    case 'LastName':
                        if(userData.lastName) loyaltyCtrl.registerData['LastName'] = userData.lastName;
                        break;
                    case 'City':
                        if(userData.addresses[0] && userData.addresses[0].city) loyaltyCtrl.registerData['City'] = userData.addresses[0].city;
                        break;
                    case 'BirthDate':
                        if(userData.dateOfBirth) loyaltyCtrl.registerData['BirthDate'] = new Date(userData.dateOfBirth);
                        break;
                    case 'Gender':
                        if(userData.gender) loyaltyCtrl.registerData['Gender'] = userData.gender === 1 ? 'male' : 'female';
                        break;
                }
            });
        }

        function goToLogin() {
            return Util.goToLoginDialog('Please sign in to continue...', 'It\'s easy!', 'app.loyaltyClub');
        }

        function connectMembership(loyaltyForm) {
            loyaltyCtrl.inProgress = true;
            return $q.resolve().then(function() {

                if (loyaltyCtrl.loyaltyClubDriver &&
                    loyaltyCtrl.loyaltyClubDriver.loyaltyClubDriverId === LOYALTY_CLUB_DRIVERS.MAX) {
                    return _showCreditCardLoyalty();
                }
                if (loyaltyCtrl.loyaltyClubDriver.isLookupOnly || loyaltyCtrl.loyaltyMode === loyaltyCtrl.LOYALTY_MODES.LOOKUP) {
                    loyaltyCtrl.connectClubActive = true;
                    return _lookupMembership(loyaltyForm);
                } else if (loyaltyCtrl.loyaltyMode === loyaltyCtrl.LOYALTY_MODES.REGISTER) {
                    return _registerMembership(loyaltyForm);
                }
            }).finally(function() {
                loyaltyCtrl.inProgress = false;
            });
        }

        function _registerMembership(loyaltyForm) {
            DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Register club membership'}});
            if (loyaltyCtrl.loyaltyClubDriver &&
                loyaltyCtrl.loyaltyClubDriver.clientConfig &&
                loyaltyCtrl.loyaltyClubDriver.clientConfig.url &&
                loyaltyCtrl.loyaltyClubDriver.clientConfig.url.registrationUrl) {
                return mDesign.dialog({
                    templateUrl: 'views/templates/loyalty-iframe.html',
                    controllerAs: 'loyaltyIframeCtrl',
                    controller: ['$scope', '$sce', '$mdDialog', function ($scope, $sce, $mdDialog) {
                        $scope.src = $sce.trustAsResourceUrl(loyaltyCtrl.loyaltyClubDriver.clientConfig.url.registrationUrl + loyaltyCtrl.userData.id);
                        $scope.hide = $mdDialog.hide;
                    }],
                    clickOutsideToClose: false
                }).then(function () {
                    return _checkLoyaltyStatus();
                }).then(function (user) {
                    var userLoyalty = loyaltyCtrl.userData.loyaltyClubs.find(function (loyaltyClub) {
                        return loyaltyClub.loyatlyClubDriverId === loyaltyCtrl.loyaltyClubDriver.loyatlyClubDriverId;
                    });
                    if (userLoyalty && userLoyalty.loyaltyVerificationStatus) {
                        $rootScope.$emit('loyaltyClubRegistration', user.id);
                        return mDesign.alert(loyaltyCtrl.loyaltyClubDriver.clientConfig.text.registrationCompletedSuccessfully);
                    }
                });
            }

            if (loyaltyForm.$invalid) {
                return mDesign.alert('' +
                    '<div>{{\'We are not able to register your membership club account\' | translate}}</div>' +
                    '<div>{{\'Please make sure your details are correct or try again later\' | translate}}</div>');
            }

            loyaltyCtrl.registerData.branchId = Config.branch.id;
            loyaltyCtrl.registerData.retailerLoyaltyClubDriverId = loyaltyCtrl.loyaltyClubDriver.id;

            if (loyaltyCtrl.loyaltyClubDriver &&
                loyaltyCtrl.loyaltyClubDriver.clientConfig &&
                loyaltyCtrl.loyaltyClubDriver.clientConfig.product &&
                loyaltyCtrl.loyaltyClubDriver.clientConfig.product.registerRetailerProductId) {
                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/loyalty',
                    params: {
                        isDefault: true,
                        start: 0,
                        max: 1,
                        orderBy: 'Id'
                    }
                }, {
                    withoutToast: true
                }).then(function (result) {
                    var loyaltyId;
                    if (result.clubs && result.clubs.length) {
                        loyaltyId = result.clubs[0].id;
                    }

                    if (!loyaltyId) {
                        return $q.reject('No default loyalty club found');
                    }

                    Cart.addLine({
                        product: {
                            id: loyaltyCtrl.loyaltyClubDriver.clientConfig.product.registerRetailerProductId
                        },
                        quantity: 1,
                        type: CART_LINE_TYPES.REGISTER_LOYALTY,
                        metaData: JSON.stringify({
                            club: loyaltyId,
                            registerData: loyaltyCtrl.registerData
                        })
                    }, null, {source: 'Loyalty Club'});
                    $rootScope.$emit('loyaltyClubRegistration', loyaltyCtrl.userData.id);
                    return mDesign.alert('Congradulations for joining the customers club').then(function () {
                        $state.go('app.home');
                        $scope.$root.$emit('loyaltyLogin');
                    });
                }).catch(function (resp) {
                    mDesign.alert('Loyalty ' + resp && resp.response && resp.response.error || resp);
                });
            }

            // first make sure a cart exists
            return Cart.createCart().then(function() {
                return Api.request({
                    method: 'POST',
                    url: LOYALTY_URL,
                    data: {
                        registerData: loyaltyCtrl.registerData,
                        cartId: Cart.serverCartId
                    }
                }, {
                    hideError: true
                });
            }).then(function (res) {
                if (!res.loyaltyId) {
                    return $q.reject('Failed to register to loyalty club');
                }

                User.getUserSettings(true);
                return mDesign.dialog({
                    templateUrl: 'views/templates/loyalty-congradulations.html',
                    controllerAs: 'loyaltyCongradulationsCtrl',
                    controller: ['$mdDialog', function ($mdDialog) {
                        var loyaltyCongradulationsCtrl = this;

                        loyaltyCongradulationsCtrl.customerName = loyaltyCtrl.userData.firstName + ' ' + loyaltyCtrl.userData.lastName;
                        loyaltyCongradulationsCtrl.loyaltyId = res.loyaltyId;

                        loyaltyCongradulationsCtrl.hide = $mdDialog.hide;
                    }],
                    clickOutsideToClose: true
                }).then(function(goHome) {
                    if (goHome) {
                        $state.go('app.home');
                    }
                    $scope.$root.$emit('loyaltyLogin');
                });
            }).catch(function (resp) {
                if (resp.response && resp.response.errors) {
                    mDesign.alert('Loyalty register failed : ' + resp.response.errors[0].message);
                } else {
                    mDesign.alert('Loyalty ' + resp && resp.response && resp.response.error || resp);
                }
            });
        }

        function _showCreditCardLoyalty() {
            if (loyaltyCtrl.loyaltyClubDriver &&
                loyaltyCtrl.loyaltyClubDriver.loyaltyClubDriverId === LOYALTY_CLUB_DRIVERS.MAX) {
                return User.getUserSettings(true).then(function(userData){
                    return userData.creditCards.length;
                }).then(function(numberOfCreditCards) {
                    return PaymentsService.addCreditCard({ isLoyalty: true })
                        .then(function () {
                            return User.getUserSettings(true);
                        }).then(function (newUserData) {
                            if (newUserData.creditCards.length > numberOfCreditCards) {
                                var message = loyaltyCtrl.loyaltyClubDriver.clientConfig &&
                                    loyaltyCtrl.loyaltyClubDriver.clientConfig.text &&
                                    loyaltyCtrl.loyaltyClubDriver.clientConfig.text.postActivationLabel || '';
                                return mDesign.alert(message);
                            } else {
                                return $q.reject();
                            }
                        });
                });
            }
        }

        function _checkLoyaltyStatus() {
            if (!loyaltyCtrl.loyaltyClubDriver || loyaltyCtrl.loyaltyClubDriver.loyaltyClubDriverId !== LOYALTY_CLUB_DRIVERS.CLUB_365) {
                return User.getUserSettings(true);
            }

            // first make sure a cart exists
            // TODO: support multi loyalty drivers
            return Cart.createCart().then(function() {
                return Api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/users/:uid/loyalty',
                    data: {
                        actionType: 1,
                        cartId: Cart.serverCartId
                    }
                });
            }).then(function () {
                return User.getUserSettings(true);
            });
        }

        function _lookupMembership(loyaltyForm) {
            var recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();
            if (!recaptchaHash) {
                loyaltyCtrl.captchaIsInvalid = true;
                return;
            }
            DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Lookup club membership'}});

            if (loyaltyForm.$invalid) {
                return mDesign.alert('Please fill in membership number');
            }

            _existLoyaltyClubId = loyaltyCtrl.lookupFields.length == 1 ? loyaltyCtrl.lookupData[loyaltyCtrl.lookupFields[0].key] : loyaltyCtrl.lookupData.customerId;
            validateLoyaltyClubId();
            loyaltyCtrl.lookupData.retailerLoyaltyClubDriverId = loyaltyCtrl.loyaltyClubDriver.id;

            return Api.request({
                method: 'PUT',
                url: LOYALTY_URL,
                data: loyaltyCtrl.lookupData,
                params: {
                    branchId: Config.branch.id,
                    recaptchaHash: recaptchaHash
                }
            }, {
                withoutToast: true
            }).then(function () {
                User.getUserSettings(true);
                var connectedSuccessfullyMessage = loyaltyCtrl.loyaltyClubDriver.clientConfig &&
                    loyaltyCtrl.loyaltyClubDriver.clientConfig.text &&
                    loyaltyCtrl.loyaltyClubDriver.clientConfig.text.connectedSuccessfully;
                return mDesign.dialog({
                    controller: ['$scope', 'mDesign', function ($scope, mDesign) {
                        $scope.continue = function () {
                            $state.go('app.home');
                            $scope.$root.$emit('loyaltyLogin');
                            mDesign.hide();
                        };

                        $scope.backToLoyalties = function () {
                            _init();
                            $scope.$root.$emit('loyaltyLogin');
                            mDesign.hide();
                        };
                    }],
                    template: '<md-dialog class="after-register"><md-dialog-content class="after-register-content"><h1>{{\'after_register_title\' | translate}}!</h1><div>{{\'You can now enjoy the club specials\' | translate}}</div></md-dialog-content>' +
                        '<md-dialog-actions layout="column"><md-button ng-click="backToLoyalties()" class="no-design">{{\'BACK TO LOYALTY CLUBS\' | translate}}</md-button>' +
                        '<md-button ng-click="continue()" class="md-primary">{{\'to_home_page\' | translate}}</md-button></md-dialog-actions></md-dialog>'
                });

            });
        }

        function _getElementTop(element) {
            var top = 0;
            do {
                top += element.offsetTop;
                element = element.offsetParent;
            } while (element);
            return top;
        }

        function toggleTitleImage() {
            _timer = $timeout(function () {
                var mainScrollElement = document.querySelector('#main[ui-view]'),
                    loyaltyClubForm = document.getElementById('loyaltyForm'),
                    buttonsWrapper = loyaltyClubForm.querySelector(':scope .button-wrapper'),
                    buttonsTop = _getElementTop(buttonsWrapper) + buttonsWrapper.offsetHeight - _getElementTop(loyaltyClubForm);

                if (buttonsTop > mainScrollElement.offsetHeight) {
                    angular.element(loyaltyClubForm.querySelector(':scope > .title')).css('display', 'none');
                } else {
                    angular.element(loyaltyClubForm.querySelector(':scope > .title')).css('display', '');
                }
            }, 100);
        }

        function openDialer() {
            window.location.href = 'tel:' + loyaltyCtrl.retailerPhoneNumber;
        }

        Util.destroyListeners($scope, function () {
            _timer && $timeout.cancel(_timer);
        });

        function historyBack($event) {
            $event && $event.preventDefault();
            $window.onBackButton();
        }

        function goTo(href) {
            Util.goToBannerHref(href);
        }

        function getAreaStyle(area, bannerQuerySelector) {
            $timeout(function () {
                var bannerElement = document.querySelector(bannerQuerySelector),
                bannerWidth = bannerElement && bannerElement.width,
                bannerHeight = bannerElement && bannerElement.height;

                return area.style = {
                    top: ((area.y/(area.originalHeight/bannerHeight)) + 'px'),
                    left: ((area.x/(area.originalWidth/bannerWidth)) + 'px'),
                    width: ((area.width/(area.originalWidth/bannerWidth)) + 'px'),
                    height: ((area.height/(area.originalHeight/bannerHeight)) + 'px')
                }
            },1000);
        }

        function resetDrivers() {
            loyaltyCtrl.loyaltyClubDriver = loyaltyCtrl.userLoyaltyClub = loyaltyCtrl.loyaltyClubCardId = loyaltyCtrl.loyaltyClubExpiration = null;
        }

        // util.destroyListeners($scope, $rootScope.$on('editOrder.polling', function (event, data) {
        //     _editOrderDialog(data);
        // }));

        /**
         * ECOM-5550
         * @param {*} _ 
         * @param {{captchaId: string, widgetId: number, action: string}} data 
         */
        function _setCaptchaValid(_, data){
          // need timeout so form can validate captcha
          $timeout(function () {
            if (!loyaltyForm) {
              return;
            }

            // only works if set value from loyaltyForm
            loyaltyForm[2].value = data.action === "success" ? "true" : "";
          });
        }

        _listeners.push($rootScope.$on('spCaptcha.event', _setCaptchaValid))

        $rootScope.$on('historyBack', function () {
            if ($rootScope.preventHistoryBack) {
                if (loyaltyCtrl.isMultiLoyalty && !loyaltyCtrl.loyaltyClubDriver || !loyaltyCtrl.isMultiLoyalty) {
                    $rootScope.preventHistoryBack = false;
                } else {
                    resetDrivers();
                    _init();
                }
            }
        });

        Util.destroyListeners($rootScope, _listeners)
    }]);
