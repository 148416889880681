(function(angular) {
    var STORAGE_CHECKOUT_DATA_KEY = 'mobileZuzExternalCheckoutData';

    angular.module('mobilezuz').service('ExternalCheckout', [
        '$rootScope', '$window', '$document', '$timeout', '$state', 'Api', 'Config',
        function($rootScope, $window, $document, $timeout, $state, Api, Config) {
            var self = this,
                windowFocusListener = new WindowFocusListener($window, $document, $timeout);

            self.STORAGE_KEY = STORAGE_CHECKOUT_DATA_KEY;
            self.getStorage = getStorage;
            self.setStorage = setStorage;
            self.init = init;
            self.isInExternalCheckoutFinishPage = isInExternalCheckoutFinishPage;
            self.getCheckoutData = getCheckoutData;
            self.deleteCheckoutData = deleteCheckoutData;
            self.watchCheckoutData = watchCheckoutData;
            self.stopWatchingCheckoutData = stopWatchingCheckoutData;

            self.exitCheckoutProgress = exitCheckoutProgress;

            function getStorage() {
                return localStorage.getItem(STORAGE_CHECKOUT_DATA_KEY);
            }

            function setStorage(value) {
                if (value === null) {
                    localStorage.removeItem(STORAGE_CHECKOUT_DATA_KEY);
                } else {
                    localStorage.setItem(STORAGE_CHECKOUT_DATA_KEY, value);
                }
            }

            function init() {
                windowFocusListener.subscribe(_checkCheckoutData);

                var storageCheckoutDataKey = getStorage();
                if (!storageCheckoutDataKey) {
                    return;
                }

                watchCheckoutData();
                _checkCheckoutData();
            }

            function isInExternalCheckoutFinishPage() {
                return new Promise(function(resolve) {
                    if ($state.current && $state.current.name) {
                        return resolve();
                    }

                    var listener = $rootScope.$on('$stateChangeSuccess', function(event, toState) {
                        listener();
                        resolve();
                    });
                }).then(function() {
                    return $state.current && $state.current.name === 'app.externalCheckoutFinish';
                });
            }

            /**
             * @typedef {Object} ExternalCheckoutData
             *
             * @property {Object} [request]
             * @property {string} [request.externalPaymentId]
             * @property {Object} request.options - checkout options in the 'rest'
             * @property {Object} [response]
             * @property {string} [response.externalPaymentId]
             * @property {number} [response.orderId]
             * @property {Object} [response.error]
             * @property {string} [response.error.error]
             * @property {number|string} [response.error.code]
             */

            /**
             * Returns the checkout data by the given key or the storage key
             * @public
             *
             * @param {string} [key]
             *
             * @return {Promise<ExternalCheckoutData|void>}
             */
            function getCheckoutData(key) {
                if (!key) {
                    var storageCheckoutDataKey = getStorage();
                    if (!storageCheckoutDataKey) {
                        return;
                    }

                    key = storageCheckoutDataKey;
                }

                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/external-payment-data-cache/' + key
                }, {
                    hideError: true
                }).then(function(resp) {
                    return {
                        externalPaymentKey: key,
                        checkoutData: resp
                    };
                }).catch(function(err) {
                    if (err.statusCode === 404) {
                        return { externalPaymentKey: key };
                    }

                    throw err;
                });
            }

            function deleteCheckoutData(externalPaymentKey) {
                if (!externalPaymentKey) {
                    throw new Error('Checkout key must be provided');
                }

                return Api.request({
                    method: 'DELETE',
                    url: '/v2/retailers/:rid/external-payment-data-cache/' + externalPaymentKey
                }).then(function() {
                    _clearData(externalPaymentKey);
                });
            }

            function watchCheckoutData() {
                windowFocusListener.bind();
            }

            function stopWatchingCheckoutData() {
                windowFocusListener.unbind();
            }

            function exitCheckoutProgress(externalPaymentKey) {
                _clearData(externalPaymentKey);
                _goHome();
            }

            function _clearData(externalPaymentKey) {
                var storageCheckoutDataKey = getStorage();
                if (storageCheckoutDataKey === externalPaymentKey) {
                    setStorage(null);
                    stopWatchingCheckoutData();
                }
            }

            function _goHome() {
                delete Config.checkoutData;
                $state.go('app.home');
            }

            function _checkCheckoutData() {
                var storageCheckoutDataKey = getStorage();
                if (!storageCheckoutDataKey) {
                    stopWatchingCheckoutData();
                    _existCheckoutState();
                    return;
                }

                return isInExternalCheckoutFinishPage().then(function(is) {
                    // do not check while showing the external checkout finish page
                    if (is) {
                        return;
                    }

                    return getCheckoutData(storageCheckoutDataKey).then(function(data) {
                        // when the checkout data key has no request nor response data, remove it and stop watching
                        if (!data.checkoutData) {
                            setStorage(null);
                            stopWatchingCheckoutData();
                            _existCheckoutState();
                            return;
                        }

                        // when the checkout data has a request but not a response,
                        // keep watching for the response but do nothing
                        if (!data.checkoutData.response) {
                            return;
                        }

                        // when a response exists, go to the externalCheckoutFinish page to show it
                        $state.go('app.externalCheckoutFinish');
                    });
                });
            }

            function _existCheckoutState() {
                // when unbinding and the user is on the checkout page, go home, the payment was finished or timed out
                if ($state.current && $state.current.name.startsWith('app.cart.checkout')) {
                    _goHome();
                }
            }
        }
    ]);

    function WindowFocusListener($window, $document, $timeout) {
        var self = this,
            _subscribers = [],
            _windowElement = angular.element(window),
            _currentWindowState,
            _intervalId;

        self.bind = bind;
        self.unbind = unbind;
        self.subscribe = subscribe;

        function bind() {
            if (_intervalId) {
                return;
            }

            _windowElement.bind('blur', _onWindowBlur);
            _windowElement.bind('focus', _onWindowFocus);
            if ($window.cordova) {
                $document.bind('resume', _notifySubscribers);
            }
            _intervalId = setInterval(_notifySubscribers, 1000 * 60 * 5 /*every five minutes*/);
        }

        function unbind() {
            if (_intervalId === undefined) {
                return;
            }

            _windowElement.unbind('blur', _onWindowBlur);
            _windowElement.unbind('focus', _onWindowFocus);
            if ($window.cordova) {
                $document.unbind('resume', _notifySubscribers);
            }
            clearInterval(_intervalId);
            _intervalId = _currentWindowState = undefined;
        }

        function subscribe(subscriber) {
            _subscribers.push(subscriber);
        }

        function _onWindowBlur() {
            _currentWindowState = 'blur';
        }

        function _onWindowFocus() {
            if (_currentWindowState === 'focus') {
                return;
            }

            _currentWindowState = 'focus';
            _notifySubscribers();
        }

        function _notifySubscribers() {
            angular.forEach(_subscribers, function(subscriber) {
                // using $timeout to angular digest
                $timeout(function() {
                    subscriber()
                });
            });
        }
    }
})(angular);
