angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.search', {
        url: '/search/:query?{filter}&{filters:json}&{title}',
        data: {
            stateAccess: PAGE_ACCESS.ALLOW,
            bodyClass: 'search',
            metaTags: {
                title: 'Search'
            }
        },
        views: {
            '@': {
                templateUrl: 'views/search.html',
                controller: 'SearchCtrl'
            }
        }
    });
}]).controller('SearchCtrl', [
    '$rootScope', '$scope', '$stateParams', 'Config', 'Api', 'Cart', 'mDesign', 'Util', 'Bumpers', 'User', 'FiltersManager', 'SEARCH_SORT_BOOST_TYPES', 'DataLayer',
    function ($rootScope, $scope, $stateParams, Config, api, cart, mDesign, Util, bumpers, User, FiltersManager, SEARCH_SORT_BOOST_TYPES, DataLayer) {
        var _listeners = [],
            _isRequesting = false,
            _isEmptyRequest = false,
            _currentFrom = 0, // do not use the products.length directly, to avoid bumpers
            _resetProductsQueue = new Util.SingleOperationQueue();

        if($stateParams.filters && $stateParams.filters.queryInBrand && !$stateParams.query) {
            $stateParams.query = $stateParams.filters.queryInBrand;
        }

        $scope.query = $stateParams.query;
        $scope.title = $stateParams.query || $stateParams.title
        $scope.products = [];
        $scope.noProducts = false;
        $scope.resetProducts = resetProducts;
        $scope.removeKeyboardFocus = removeKeyboardFocus;
        $scope.missingProductPopup = missingProductPopup;
        $scope.onSearchProducts = onSearchProducts;
        $scope.boostProductTags = [];

        var params = {
            query: $scope.query,
            languageId: Config.language.id,
            filters: {
                must: {
                    exists: ['family.id', 'family.categoriesPaths.id', 'branch.regularPrice'],
                    term: {
                        'branch.isActive': true,
                        'branch.isVisible': true
                    }
                },
                mustNot: {
                    term: {
                        'branch.regularPrice': 0
                    }
                }
            }
        };
        if (Config.retailer.settings.showUnavailableProducts !== 'true') {
            params.filters.mustNot.term['branch.isOutOfStock'] = true;
        }
        else {
            if (Config.retailer.settings.isOutOfStockDuration === 'true') {
                params.filtersbool = {
                    "should": [
                        {
                            "bool": {
                                "must_not": {
                                    "exists": {
                                        "field": "branch.outOfStockShowUntilDate"
                                    }
                                }
                            }
                        },
                        {
                            "bool": {
                                "must": [
                                    {
                                    "range": {
                                        "branch.outOfStockShowUntilDate": {
                                        "gt": "now"
                                        }
                                    }
                                    },
                                    {
                                    "term": {
                                        "branch.isOutOfStock": true
                                    }
                                    }
                                ]
                            }
                        },
                        {
                            "bool": {
                                "must": [
                                    {
                                    "term": {
                                        "branch.isOutOfStock": false
                                    }
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        }

        if ($stateParams.filter) {
            if ($stateParams.filter === 'specials') {
                params.filters.must.exists.push('branch.specials');
            } else if ($stateParams.filter !== 'all') {
                params.filters.must.term['family.categoriesPaths.id'] = $stateParams.filter;
            }
        }

        //init products filters
        //must be here after initializing params
        var filtersPromise = api.request({
            url: '/v2/retailers/:rid/branches/:bid/products/filters',
            params: params
        }, {
            fireAndForgot: true
        });

        var _productTagsFilter = FiltersManager.getProductTagsFilter(filtersPromise, $scope, false);
        $scope.filters = [
            _productTagsFilter,
            FiltersManager.getCategoriesFilter(filtersPromise),
            FiltersManager.getBrandsFilter(filtersPromise)
        ];

        FiltersManager.setFiltersFromUrl($scope.filters, $stateParams.filters).then(function() {
            return _getProducts();
        });

        function _getProducts() {
            _isRequesting = true;

            var requestParams = Object.assign({
                from: _currentFrom,
                size: 20
            }, angular.copy(params));

            angular.forEach($scope.filters, function(filter) {
                filter.parse(requestParams);
            });

            $scope.boostProductTags = (_productTagsFilter.selectedItems || []).map(function(item) {
                return item.value;
            });

            return _addSearchSortBoost(requestParams).then(function() {
                return api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/branches/:bid/products',
                    params: requestParams
                }, {
                    loadingElement: document.querySelector('.loading-progress-wrapper')
                });
            }).then(function (response) {
                $scope.noProducts = !response.total;

                //== Send to Data Layer only for search and not for the scroll action
                if(!_currentFrom) {
                    DataLayer.push(DataLayer.EVENTS.SEARCH, {data: {query: $scope.query, results: response.total}});
                }

                _currentFrom += response.products.length;

                if (response.products.length) {
                    var productAndBumpersArray = cart.getProducts(response.products);
                    bumpers.handleProductsBumpers($scope.products, productAndBumpersArray);

                    User.injectSuggestionsForProducts(productAndBumpersArray);

                    $scope.products.push.apply($scope.products, productAndBumpersArray);
                } else {
                    _isEmptyRequest = true;
                }
                _sendSearchRequestToExposeBox($stateParams.query,response.products);
            }).finally(function() {
                _isRequesting = false;
            });
        }

        function _sendSearchRequestToExposeBox(query, lines){
            var products =[];
            var categories =[];
            for (var i = 0; i < lines.length && i < 6; i++) {
                products.push(lines[i].id);
            }

            $rootScope.$emit('productSearch', query, products, _prepareCategoriesForSearchRequestToExposeBox());
        }

        function _prepareCategoriesForSearchRequestToExposeBox(){
            var productTags = [];
            angular.forEach($scope.filters[0].selectedItems, function(filter) {
                var productFilter = FiltersManager.getProductTagName(filter.value, Config.language.id);
                if (productFilter){
                    productTags.push(productFilter);
                }
            });

            var categoryFilters =[];
            angular.forEach($scope.filters[1].selectedItems, function(filter) {
                if (filter.names){
                    categoryFilters.push(filter.names[Config.language.id]);
                }
            });

            var brandFilters =[];
            angular.forEach($scope.filters[2].selectedItems, function(filter) {
                if (filter.names && filter.names[Config.language.id]){
                    brandFilters.push(filter.names[Config.language.id]);
                }
                else{
                    var keysArray = filter.names ? Object.keys(filter.names) : null;
                    if (keysArray && keysArray.length > 0){
                        brandFilters.push(filter.names[keysArray[0]]);
                    }
                }

            });
            return {productTags: productTags, categoryFilters: categoryFilters, brandFilters: brandFilters};

        }

        function _addSearchSortBoost(params) {
            return User.getPurchasesSortBoost().then(function(userBoost) {
                var sortBoosts = [];

                if (userBoost) {
                    sortBoosts.push(userBoost);
                }

                var productTagsSortBoost = FiltersManager.getProductTagsSortBoost(_productTagsFilter);
                if (productTagsSortBoost) {
                    sortBoosts.push(productTagsSortBoost);
                }

                if (sortBoosts.length) {
                    params.sortBoosts = sortBoosts;
                }
            });
        }

        /**
         * Reset the products with new filters
         * @public
         */
        function resetProducts() {
            FiltersManager.setFiltersToUrl($scope.filters);

            return _resetProductsQueue.do(function() {
                // hide no products message
                $scope.noProducts = false;
                //empty the products array
                _currentFrom = 0;
                $scope.products.splice(0);
                //reset is empty to allow getting products again
                _isEmptyRequest = false;

                return _getProducts();
            });
        }

        function removeKeyboardFocus() {
            if (document.querySelector('#search input') === document.activeElement) {
                document.activeElement.blur();
            }
        }

        function missingProductPopup() {
            mDesign.dialog({
                controller: 'missingProduct',
                templateUrl: 'views/templates/missing-product.html',
                resolve: {
                    tree: ['Tree', function (tree) {
                        return tree.getTree();
                    }],
                    query: [function () {
                        return $scope.query;
                    }]
                }
            });
        }

        function onSearchProducts(query) {
            if ((!query || query.length < 3) && ! $scope.query) {
                return;
            }

            $scope.query = !query || query.length < 3 ? '' : query;
            params.query = $scope.query;
            resetProducts();
        }

        _listeners.push($scope.$on('vertical-scroll-end', function () {
            if (!_isRequesting && !_isEmptyRequest) {
                _getProducts();
            }
        }));

        function _syncProductWithCart() {
            $scope.products = cart.getProducts($scope.products);
        }

        _listeners.push($scope.$root.$on('cart.lines.add', _syncProductWithCart));
        _listeners.push($scope.$root.$on('cart.lines.remove', _syncProductWithCart));

        Util.destroyListeners($scope, _listeners);
    }]);
