angular.module('mobilezuz').service('Tree', [
	'$rootScope', '$q', '$timeout', 'Config', 'Api', 'User',
	function ($rootScope, $q, $timeout, Config, Api, User) {
		var self = this,
			_treePromise;

		self.getTree = getTree;

		/**
		 * Gets the retailer's tree
		 * @returns {Promise}
		 */
		function getTree(withoutCache) {
			if (!_treePromise || withoutCache) {
				_treePromise = $q.all([
					_getTree(),
					Config.waitForBranchId()
				]).then(function (results) {
					_removeInactiveCategories(results[0]);
					return results[0];
				});
			}

			return _treePromise;
		}

		function _getTree() {
			if (Config.tree) {
				return $q.resolve(Config.tree);
			}

			return Api.request({
				method: 'GET',
				url: 'v2/retailers/:rid/tree'
			}, {
				fireAndForgot: true
			});
		}

		function _removeInactiveCategories(tree) {
			var loginData = User.getUserLoginData() || {},
				organizationId = loginData.branchKeyOrganization,
				branchIdKey = Config.branch.id + (organizationId ? '_' + organizationId : '');
			for (var i = tree.categories.length - 1; i >= 0; i--) {
				var level1 = tree.categories[i];
				if (!_isCategoryVisible(level1, branchIdKey, organizationId)) {
					tree.categories.splice(i, 1);
					continue;
				}

				for (var ii = level1.subCategories.length - 1; ii >= 0; ii--) {
					var level2 = level1.subCategories[ii];
					if (!_isCategoryVisible(level2, branchIdKey, organizationId)) {
						level1.subCategories.splice(ii, 1);
						continue;
					}

					for (var iii = level2.subCategories.length - 1; iii >= 0; iii--) {
						var level3 = level2.subCategories[iii];
						if (!_isCategoryVisible(level3, branchIdKey, organizationId)) {
							level2.subCategories.splice(iii, 1);
						}
					}
				}
			}
		}

		function _isCategoryVisible(category, branchIdKey, organizationId) {
			var isVisible = !!category.branches[branchIdKey] && !!category.branches[branchIdKey].isVisible;
			if (!isVisible) {
				return false;
			}

			if (category.organizations && organizationId) {
				var categoryOrg = category.organizations.find(function(org) {
					return org.id === organizationId;
				});

				if (categoryOrg && !categoryOrg.isVisible) {
					return false;
				}
			}

			return true;
		}
	}]);