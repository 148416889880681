angular.module('mobilezuz').controller('AgeRestrictionDialog', ['$scope', 'mDesign', 'ageLimit', '$timeout',
    function ($scope, mDesign, ageLimit, $timeout) {
        $scope.dialog = mDesign;
        $scope.ageLimit = ageLimit;

        $scope.cantPurchaseTrue = function () {
            $scope.cantPurchase = true;
            $timeout(function () {
                mDesign.cancel();
            }, 3000);

        };
    }]);