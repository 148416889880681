angular.module('mobilezuz').controller('SinglePromotion', ['$scope', '$q', 'Config', 'Api', 'mDesign', 'User', 'promotion',
    function ($scope, $q, Config, Api, mDesign, user, promotion) {

        var singlePromotion = this;
        singlePromotion.promotion = promotion;

        singlePromotion.cancel = cancel;
        singlePromotion.sendEmail = sendEmail;

        function _init() {
            if (singlePromotion.promotion.isReference) {
                return user.getUserSettings()
                    .then(function (user) {
                        singlePromotion.promoCode = singlePromotion.promotion.id + '-' + user.id;
                    });
            }
        }

        _init();

        function sendEmail() {
            if (singlePromotion.sending || singlePromotion.sentSuccessfully) return $q.resolve();

            singlePromotion.sending = true;
            var baseUrl = 'https://' + Config.retailer.domain + '/';

            return Api.request({
                method: 'POST',
                url: '/v2/retailers/:rid/users/:uid/promotions/' + singlePromotion.promotion.id,
                data: {to: singlePromotion.email, baseUrl: baseUrl}
            }).then(function () {
                singlePromotion.sentSuccessfully = true;
            }).finally(function () {
                singlePromotion.sending = false;
            });
        }

        function cancel() {
            mDesign.hide();
        }
    }]);