angular.module('mobilezuz')
    .directive('ribbon', [
        function () {
            return {
                restrict: 'E',
                templateUrl: 'views/templates/ribbon.html',
                replace: true,
                scope: {
                    imagePath: '=',
                    ribbonName: '='
                }
            };
        }]);