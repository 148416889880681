(function (angular) {

    angular.module('mobilezuz')
        .config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
            $stateProvider.state('app.singleOrder', {
                url: '/orders/:oid',
                data: {
                    stateAccess: PAGE_ACCESS.LOGGED_IN,
                    name: 'single-order'
                },
                views: {
                    '@': {
                        templateUrl: 'views/single-order.html',
                        controller: 'SingleOrderItemCtrl as singleOrderItemCtrl',
                        resolve: {
                            order: ['$stateParams', 'Api', 'Config', 'User', function ($stateParams, api, Config, User) {
                                return api.request({
                                    method: 'GET',
                                    url: '/v2/retailers/:rid/branches/:bid/users/:uid/orders/' + $stateParams.oid
                                }).then(function(order) {
                                    var products = order.lines.map(function(line) {
                                        return line.product
                                    })

                                    User.injectSuggestionsForProducts(products);

                                    return order
                                });
                            }]
                        }
                    }
                }
            });
        }])
        .controller('SingleOrderItemCtrl', ['$timeout', '$state', '$scope', 'order', '$rootScope', 'Cart', 'Util', 'mDesign', 'CART_LINE_TYPES', 'Orders', 'SP_SERVICES', 'Util',
            function ($timeout, $state, $scope, order, $rootScope, cart, util, mDesign, CART_LINE_TYPES, Orders, SP_SERVICES, Util) {
                var singleOrderItemCtrl = this,
                    _listeners = [];

                var categories = {};

                $scope.orderStatus = Orders.orderStatus;
                $scope.order = angular.copy(order);
                singleOrderItemCtrl.order = angular.copy(order);
                singleOrderItemCtrl.items = order.lines;
                singleOrderItemCtrl.selectedLines = 0;
                singleOrderItemCtrl.importOrderToCart = importOrderToCart;
                singleOrderItemCtrl.plusQuantity = plusQuantity;
                singleOrderItemCtrl.minusQuantity = minusQuantity;
                singleOrderItemCtrl.selectItem = selectItem;
                singleOrderItemCtrl.setProduct = setProduct;
                singleOrderItemCtrl.openedProduct = null;

                //== If Cart did'n finished an API call we subscribing to event
                if(!cart.sendingSucceeded) {
                    _listeners.push($rootScope.$on('cart.update.complete', function () {
                        _init();
                    }));
                } else {
                    _init();
                }

                function _init() {
                    _setSoldBy();
                    _setItemsToCategories();
                    cart.setCartLineReplacements(singleOrderItemCtrl.items);
                }

                function _setItemsToCategories() {
                    if (!singleOrderItemCtrl.order.lines || !singleOrderItemCtrl.order.lines || !Array.isArray(singleOrderItemCtrl.order.lines)) {
                        singleOrderItemCtrl.items = [];
                        return;
                    }

                    // group lines by categories
                    angular.forEach(order.lines, function (orderCartLine) {
                        orderCartLine.isProductOutOfStock = util.isProductOutOfStock(orderCartLine);
                        orderCartLine.isNeedToShowOutOfStockLabel = util.isNeedToShowOutOfStockLabel(orderCartLine);
                        if (orderCartLine.type !== CART_LINE_TYPES.MARKETING) {
                            if (!categories[orderCartLine.categoryName]) {
                                categories[orderCartLine.categoryName] = [];
                            }
                            categories[orderCartLine.categoryName].push(orderCartLine);
                            $scope.order.lines.push(orderCartLine);
                        }
                    });

                    $scope.linesByCategories = categories;

                    var cartProducts = _getCartProductsQuantity();
                    singleOrderItemCtrl.items = [];
                    angular.forEach($scope.order.lines, function(line) {
                        if (!line.product || (!line.isPseudo && (!line.product.family ||
                            !line.product.family.categoriesPaths || !line.product.family.categoriesPaths.length))) {
                            return;
                        }

                        line.inCart = !!cartProducts[line.product.id];
                        line.inCartQuantity = cartProducts[line.product.id] || 0;

                        // set weighable units for suitable products

                        if (line.product.isWeighable && line.product.weight && !line.weightQuantity) {
                            line.weightQuantity = line.quantity;
                            if (!line.product.soldBy || line.product.soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name) {
                                line.quantity = Math.round(line.weightQuantity / line.product.weight);
                            }
                        }

                        line.quantity = _fixQuantity(line.quantity);

                        singleOrderItemCtrl.items.push(line);
                    });
                }

                function _getCartProductsQuantity() {
                    var cartProducts = cart.getLines(),
                        products = {};

                    if(cartProducts && Array.isArray(cartProducts) && cartProducts.length) {
                        angular.forEach(cartProducts, function (cartLine) {
                            if(cartLine && cartLine.product && cartLine.product.id) {
                                products[cartLine.product.id] = !cartLine.removed && cartLine.quantity || 0;
                            }
                        });
                    }

                    return products;
                }

                function importOrderToCart() {
                    var linesToAdd = [],
                        linesWithoutPropertySelected = [];

                    angular.forEach(order.lines, function(item) {
                        if(item.product && item.product.productProperties && item.product.productProperties.length && !item.productPropertyValueId && item.actionsChecked) {
                            linesWithoutPropertySelected.push(item.product.names[2].short);
                        }

                        if (item.quantity && item.actionsChecked) {
                            linesToAdd.push({
                                isCase: item.isCase,
                                product: item.product,
                                quantity: item.quantity,
                                soldBy: item.soldBy,
                                productPropertyValueId: item.productPropertyValueId || null,
                                comments: item.comments
                            });
                        }
                    });

                    if ( !(linesToAdd && linesToAdd.length) ) {
                        return mDesign.alert('{{\'No products were selected\' | translate}}');
                    }

                    if(linesWithoutPropertySelected.length) {
                        return mDesign.alert('{{\'Product properties need to be selected for\' | translate}}' + ': ' + linesWithoutPropertySelected.join(', '));
                    }

                    var isLimitPassed = cart.checkHeavyLimitBeforeAddLines(linesToAdd);
                    if (isLimitPassed === false) {
                        if (cart.lines && Object.keys(cart.lines).length === 0 &&
                            $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                            cart.addDeliveryFeeLineIfNeeded();
                        }
                        //== Go to cart after products added
                        cart.addLines(linesToAdd, SP_SERVICES.SOURCES.ORDER).then(function(){
                            $state.go('app.cart');
                        });
                    }
                }

                function _getQuantityInterval(item) {
                    return item.soldBy === $rootScope.PRODUCT_DISPLAY.WEIGHT.name || (item.isWeighable && !item.weight) ?  (item.unitResolution || 0.5) : 1;
                }

                function plusQuantity(item, $event) {
                    $event.preventCart();
                    item.quantity += _getQuantityInterval(item.product);
                    item.quantity = _fixQuantity(item.quantity);
                }

                function minusQuantity(item, $event) {
                    $event.preventCart();
                    item.quantity -= _getQuantityInterval(item.product);
                    item.quantity = _fixQuantity(item.quantity);
                    if (item.quantity <= 0) {
                        item.quantity = 0;
                    }
                }

                function _fixQuantity(quantity) {
                    return Number(Number(quantity).toFixed(2));
                }

                function selectItem(item) {
                    singleOrderItemCtrl.selectedLines += item.actionsChecked ? -1 : 1;
                    singleOrderItemCtrl.selectedLines = singleOrderItemCtrl.selectedLines < 0 ? 0 : singleOrderItemCtrl.selectedLines;

                    item.actionsChecked = !item.actionsChecked;
                }

                function updateAfterCloseProduct () {
                    if (singleOrderItemCtrl.openedProduct) {
                        var item = cart.getProduct(singleOrderItemCtrl.openedProduct);
                        if (item) {
                            for (var i = 0; i < singleOrderItemCtrl.items.length; i++) {
                                if (singleOrderItemCtrl.items[i].productId === item.id && item.singleLine) {
                                    singleOrderItemCtrl.items[i].quantity = item.singleLine.quantity;
                                    singleOrderItemCtrl.openedProduct = null;
                                }
                            }
                        }
                    }
                }

                function setProduct (item) {
                    if (item && item.product) {
                        singleOrderItemCtrl.openedProduct = item.product;
                    }
                    return true;
                }

                function _setSoldBy() {
                    angular.forEach(order.lines, function (item) {
                        item.soldBy = null;
                        if(!item.product){
                            return;
                        }
                        item.product.soldBy = null;
                        if (item.product && util.isUnitsWeighable(item.product)) {
                            var cartLine = _getLine(item.product);
                            if (!cartLine) {
                                item.soldBy = !!item.weighableProductUnits && item.weighableProductUnits > 0 ? 'Unit' : 'Weight';
                            } else {
                                item.soldBy = cartLine.soldBy;

                                /* if need to re-calculate the quantity from unit to weight when switching to weight, uncomment this code */
                                // if (item.soldBy === $rootScope.PRODUCT_DISPLAY.WEIGHT.name && !item.weighableProductUnits) {
                                //     item.quantity = +(item.quantity * item.product.weight).toFixed(2);
                                // }
                                
                                if(item.soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name && item.weighableProductUnits) {
                                    item.quantity = Math.ceil(item.quantity);
                                }
                            }
                            item.product.soldBy = item.soldBy;
                        }
                    });
                }

                function _getLine(product) {
                    var cartLines = cart.getLines(),
                        cartLine;
                    if (!cartLines) return;
                    angular.forEach(cartLines, function (line) {
                        if (line.product && line.product.id === product.id) {
                            cartLine = line;
                        }
                    })
                    return cartLine;
                }

                _listeners.push($scope.$root.$on('closeProductPage', updateAfterCloseProduct));
                
                util.destroyListeners($scope, _listeners);

            }]);
})(angular);