angular.module('mobilezuz').controller('AddGiftCardCtrl', [
    '$rootScope', '$scope', '$filter', 'Config', 'Cart', "User", 'mDesign', 'PaymentsService', 'paymentCtrl', 'options', "Util", 'SP_PAYMENTS','$document',
    function ($rootScope, $scope, $filter, Config, Cart, user, mDesign, PaymentsService, paymentCtrl, options, Util, SP_PAYMENTS, $document) {
        var  _listeners = [];
        var addGiftCardCtrl = this,
        sortType = {
            'ASC': 0,
            'DESC': 1
        },
            _roundCurrencyFilter = $filter('roundCurrency');
        addGiftCardCtrl.giftCards = options.giftCards || [];
        addGiftCardCtrl.redeemedGiftCards = angular.copy(options.redeemedGiftCards) || {};
        addGiftCardCtrl.mainPaymentMethod = options.mainPaymentMethod;
        addGiftCardCtrl.giftCardsSeeMore = false;
        addGiftCardCtrl.compiledUserAddress = Util.compiledUserAddress(paymentCtrl.addressDetails);
        addGiftCardCtrl.maxAmount = options.maxAmount || null;
        addGiftCardCtrl.giftCardsData = options.giftCardsData || {};
        addGiftCardCtrl.removedGiftCards = [];
        addGiftCardCtrl.giftCardsSettings = Config.retailer.settings.giftCardsSettings ? JSON.parse(Config.retailer.settings.giftCardsSettings) : {};
        addGiftCardCtrl.selectedGiftCardData = options.selectedGiftCardData || {};
        addGiftCardCtrl.total = Cart.total.finalPriceWithTax + Cart.total.serviceFee.finalPriceWithTax + Cart.total.deliveryCost.finalPriceWithTax;
        addGiftCardCtrl.giftCardPopupSettings = Config.retailer.settings.checkoutDisplaySettings ? JSON.parse(Config.retailer.settings.checkoutDisplaySettings) : {};
        addGiftCardCtrl.giftCardInputRegExp = new RegExp(/^[0-9.-]*$/);
        addGiftCardCtrl.showMoreLessToggle = showMoreLessToggle;
        addGiftCardCtrl.toggleSelectedGiftCard = toggleSelectedGiftCard;
        addGiftCardCtrl.showAddCardDialog = showAddCardDialog;
        addGiftCardCtrl.checkGiftCard = checkGiftCard;
        addGiftCardCtrl.redeemGiftCard = redeemGiftCard;
        addGiftCardCtrl.removeCard = removeCard;
        addGiftCardCtrl.isDisableRedeemBtn = isDisableRedeemBtn;
        addGiftCardCtrl.checkAmount = checkAmount;
        addGiftCardCtrl.checkMaxGiftCardAmount = checkMaxGiftCardAmount;
        addGiftCardCtrl.resetCardDetails = resetCardDetails;
        addGiftCardCtrl.isGiftCardNotEligible = isGiftCardNotEligible;
        addGiftCardCtrl.next = next;
        addGiftCardCtrl.close = close;
        addGiftCardCtrl.isRedeemedGiftCardsExist = isRedeemedGiftCardsExist;
        addGiftCardCtrl.openPreview = openPreview;
        addGiftCardCtrl.closePreview = closePreview;
        addGiftCardCtrl.showMoreLessToggleNew = showMoreLessToggleNew;
        addGiftCardCtrl.previewImage = {};
        addGiftCardCtrl.legalText = {};
        addGiftCardCtrl.giftCardDisplay = Config.retailer.settings.giftCardDisplay ? JSON.parse(Config.retailer.settings.giftCardDisplay) : {}
        // If Max Gift Card is enabled, it should not be placed in the first order of the gift card array
        _reorderGiftCards(addGiftCardCtrl.giftCards, SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD);
        addGiftCardCtrl.selectedGiftCard = options.selectedGiftCard || null;

        _listeners.push($document.on('click', function (event) {
            if (Object.keys(addGiftCardCtrl.previewImage).length > 0 && !event.target.closest('.gift-card-preview') && !event.target.closest('.btn-open-preview')) {
                closePreview();
            }
        }));

        _init();

        function _init() {
            if($rootScope.isUsedCashbackPoints) {
                addGiftCardCtrl.total -= options.availableUserCashback;
            }

            _setGiftCardsData();
            _calculateBalanceToBePayed();
            _prepareLegalText();
        }

        function _prepareLegalText(){

            Object.keys(addGiftCardCtrl.giftCardDisplay).forEach(function (lang){
                addGiftCardCtrl.legalText[lang] = {};
                Object.keys(addGiftCardCtrl.giftCardDisplay[lang].config).forEach(function (paymentId){
                    addGiftCardCtrl.legalText[lang][paymentId] = {
                        isShowMore: false,
                        isEnableShowMore: (addGiftCardCtrl.giftCardDisplay[$rootScope.config.language.culture].config[paymentId].legalText || '').length > 69,
                        short: _cutText(addGiftCardCtrl.giftCardDisplay[$rootScope.config.language.culture].config[paymentId].legalText || '', 70),
                        long: addGiftCardCtrl.giftCardDisplay[$rootScope.config.language.culture].config[paymentId].legalText || ''
                    };
                })
            })
        }

        function showMoreLessToggleNew(paymentId) {
            addGiftCardCtrl.legalText[$rootScope.config.language.culture][paymentId].isShowMore = !addGiftCardCtrl.legalText[$rootScope.config.language.culture][paymentId].isShowMore;
        }

        function showMoreLessToggle() {
            addGiftCardCtrl.giftCardsSeeMore = !addGiftCardCtrl.giftCardsSeeMore;
        }

        function openPreview(methodId) {
            if (addGiftCardCtrl.giftCardDisplay[$rootScope.config.language.culture] && addGiftCardCtrl.giftCardDisplay[$rootScope.config.language.culture].config[methodId]) {
                addGiftCardCtrl.previewImage[methodId] = {
                    isEnable:addGiftCardCtrl.giftCardDisplay[$rootScope.config.language.culture].config[methodId].showTooltip,
                    text:addGiftCardCtrl.giftCardDisplay[$rootScope.config.language.culture].config[methodId].tooltipText,
                    image: addGiftCardCtrl.giftCardDisplay[$rootScope.config.language.culture].config[methodId].giftCardEntryInstructionsTooltipImg,
                }
            }

        }

        function closePreview() {
            addGiftCardCtrl.previewImage = {};
        }

        function toggleSelectedGiftCard(giftCardId) {
            if(addGiftCardCtrl.selectedGiftCard === giftCardId) {
                addGiftCardCtrl.selectedGiftCard = null;
            } else {
                addGiftCardCtrl.selectedGiftCard = giftCardId;
                var foundGiftCard = findGiftCard(giftCardId);
                addGiftCardCtrl.selectedGiftCardData = foundGiftCard;
                if (giftCardId === SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD) {
                    showAddCardDialog(giftCardId);
                }
            }
        }

        function showAddCardDialog(giftCardId) {
            if (addGiftCardCtrl.giftCardsData[giftCardId] && addGiftCardCtrl.giftCardsData[giftCardId].token || addGiftCardCtrl.redeemedGiftCards[giftCardId] && addGiftCardCtrl.redeemedGiftCards[giftCardId].token) {
                addGiftCardCtrl.giftCardsSeeMore = false;
                return;
            }

            // Open iframe when the max gift card has not been added
            // mDesign.hide(addGiftCardCtrl.redeemedGiftCards);
            if (Config.retailer.settings.useDeliveryAddressAsBilling && addGiftCardCtrl.compiledUserAddress) {
                return mDesign.dialog({
                    templateUrl: 'views/templates/use-delivery-address-as-billing.html',
                    controller: ['$scope', function ($dialogScope) {
                        var dialogCtrl = this,
                        userAddress = paymentCtrl.addressDetails || user.addresses[0];
                        var userCountryName = userAddress && userAddress.country;
                        if (userCountryName) {
                            userAddress.countryCode = Util.getCountryCode(userCountryName);
                        }
                        dialogCtrl.sendUserAddress = false;
                        dialogCtrl.compiledUserAddress = addGiftCardCtrl.compiledUserAddress;
                        dialogCtrl.cancel = cancel;
                        $dialogScope.continue = function () {
                            mDesign.hide();
                            return setMaxGiftCard(giftCardId, {
                                sendUserAddress: dialogCtrl.sendUserAddress,
                                address: userAddress.text1 ? userAddress.text1 : userAddress.text2 ? userAddress.text2 : '',
                                city: userAddress.city ? userAddress.city : '',
                                country: userAddress.country ? userAddress.country : '',
                                countryCode: userAddress.countryCode ? userAddress.countryCode : '',
                            })
                        };
                        function cancel() {
                            mDesign.hide();
                        };
                    }],
                    controllerAs: 'dialogCtrl',
                    styleClass: 'clear-cart-popup'
                });
            } else {
                return setMaxGiftCard(giftCardId, {})
            }
        }
        
        function checkGiftCard(paymentMethodId) {
            addGiftCardCtrl.giftCardsData[paymentMethodId].errorMessage = '';
            var cardNumber = addGiftCardCtrl.giftCardsData[paymentMethodId].number;
            var cvv = addGiftCardCtrl.giftCardsData[paymentMethodId].cvv;
            var giftCard = findGiftCard(paymentMethodId);
            var finalizedNumber = giftCard.isCvvRequired && paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.NOFSHONIT ? cardNumber + cvv : cardNumber;

            return PaymentsService.getGiftCardBalance(paymentMethodId, finalizedNumber)
                .then(function (result) {
                    addGiftCardCtrl.giftCardsData[paymentMethodId].balance = _roundCurrencyFilter(result.balance);
                    addGiftCardCtrl.giftCardsData[paymentMethodId].token = result.token;
                    addGiftCardCtrl.giftCardsData[paymentMethodId].lastFourDigits = result.lastFourDigits;
                    addGiftCardCtrl.giftCardsData[paymentMethodId].amount = _roundCurrencyFilter(addGiftCardCtrl.giftCardsData[paymentMethodId].balance < addGiftCardCtrl.balanceToBePayed ? addGiftCardCtrl.giftCardsData[paymentMethodId].balance : addGiftCardCtrl.balanceToBePayed);
                    addGiftCardCtrl.maxAmount = addGiftCardCtrl.giftCardsData[paymentMethodId].amount;

                }).catch(function (err) {
                    addGiftCardCtrl.giftCardsData[paymentMethodId].errorMessage = 'gift_card_error_message';
                });
        }

        function setMaxGiftCard(paymentMethodId, additionalData) {
            return PaymentsService.addMaxGiftCard(additionalData)
                .then(function (result) {
                    if (result && angular.isObject(result) && result.paymentData && angular.isObject(result.paymentData)) {
                        // addGiftCardCtrl.giftCardsData[paymentMethodId].balance = null;
                        var balanceToPay = _roundCurrencyFilter(addGiftCardCtrl.balanceToBePayed);
                        addGiftCardCtrl.giftCardsData[paymentMethodId].token = result.paymentData.token;
                        addGiftCardCtrl.giftCardsData[paymentMethodId].number = result.paymentData.cardNumber;
                        addGiftCardCtrl.giftCardsData[paymentMethodId].lastFourDigits = result.paymentData.lastFourDigits;
                        addGiftCardCtrl.giftCardsData[paymentMethodId].queryTransId = result.paymentData.queryTransId;
                        addGiftCardCtrl.giftCardsData[paymentMethodId].amount = balanceToPay;
                        addGiftCardCtrl.giftCardsData[paymentMethodId].isCvvRequired = false;
                        addGiftCardCtrl.maxAmount = addGiftCardCtrl.balanceToBePayed.toFixed(2);
                        if (result.paymentData.balance) {
                            addGiftCardCtrl.giftCardsData[paymentMethodId].balance = _roundCurrencyFilter(result.paymentData.balance);
                            addGiftCardCtrl.giftCardsData[paymentMethodId].amount = addGiftCardCtrl.giftCardsData[paymentMethodId].balance;
                            if (balanceToPay < addGiftCardCtrl.giftCardsData[paymentMethodId].amount) {
                                addGiftCardCtrl.giftCardsData[paymentMethodId].amount = balanceToPay;
                            }
                        }
                    } else {
                        console.log('Cannot open gift card dialog');
                    }
                })
                .catch(function (error) {
                    addGiftCardCtrl.giftCardsData[paymentMethodId].errorMessage = 'gift_card_error_message';
                });
        }

        function redeemGiftCard(paymentMethodId) {
            if (paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD) {
                if (!addGiftCardCtrl.giftCardsData[paymentMethodId].cvv) {
                    addGiftCardCtrl.selectedGiftCardData.isCvvRequired = true;
                    return;
                } else {
                    addGiftCardCtrl.selectedGiftCardData.isCvvRequired = false;
                }
            }

            if(addGiftCardCtrl.removedGiftCards[paymentMethodId]) {
                delete addGiftCardCtrl.removedGiftCards[paymentMethodId];
            }

            addGiftCardCtrl.redeemedGiftCards[paymentMethodId] = {
                methodId: paymentMethodId,
                amount: addGiftCardCtrl.giftCardsData[paymentMethodId].amount,
                cardNumber: addGiftCardCtrl.giftCardsData[paymentMethodId].number,
                token: addGiftCardCtrl.giftCardsData[paymentMethodId].token,
                lastFourDigits: addGiftCardCtrl.giftCardsData[paymentMethodId].lastFourDigits,
                cvv: addGiftCardCtrl.giftCardsData[paymentMethodId].cvv,
                queryTransId: addGiftCardCtrl.giftCardsData[paymentMethodId].queryTransId,
                paymentsNumber: 1
            }

            addGiftCardCtrl.balanceToBePayed = addGiftCardCtrl.balanceToBePayed.toFixed(2);
            addGiftCardCtrl.balanceToBePayed -= addGiftCardCtrl.giftCardsData[paymentMethodId].amount;
            addGiftCardCtrl.giftCardsData[paymentMethodId] = null;
        }

        function removeCard(paymentMethodId) {
            addGiftCardCtrl.removedGiftCards[paymentMethodId] = addGiftCardCtrl.redeemedGiftCards[paymentMethodId];
            delete addGiftCardCtrl.redeemedGiftCards[paymentMethodId];
            if (paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD) {
                addGiftCardCtrl.selectedGiftCard = null
                addGiftCardCtrl.giftCardsData[paymentMethodId] = {}
            }
            _calculateBalanceToBePayed();
        }

        function resetCardDetails(paymentMethodId) {
            addGiftCardCtrl.wrongAmountError = '';
            addGiftCardCtrl.giftCardsData[paymentMethodId] = {};
            if (paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD) {
                addGiftCardCtrl.selectedGiftCard = null
            }
        }

        function checkAmount(paymentMethodId) {
            addGiftCardCtrl.wrongAmountError = '';

            if(addGiftCardCtrl.giftCardsData[paymentMethodId].balance && addGiftCardCtrl.giftCardsData[paymentMethodId].amount > addGiftCardCtrl.giftCardsData[paymentMethodId].balance) {
                addGiftCardCtrl.wrongAmountError = 'amount_exceeds_balance';
                return;
            }

            if(addGiftCardCtrl.giftCardsData[paymentMethodId].amount > addGiftCardCtrl.maxAmount) {
                addGiftCardCtrl.wrongAmountError = 'wrong_gift_card_amount_error';
                return;
            }
        }

        function checkMaxGiftCardAmount(paymentMethodId) {
            addGiftCardCtrl.wrongAmountError = '';

            if(addGiftCardCtrl.giftCardsData[paymentMethodId].balance && addGiftCardCtrl.giftCardsData[paymentMethodId].amount > addGiftCardCtrl.giftCardsData[paymentMethodId].balance) {
                addGiftCardCtrl.wrongAmountError = 'amount_exceeds_balance';
                return;
            }

            if(addGiftCardCtrl.giftCardsData[paymentMethodId].amount > addGiftCardCtrl.balanceToBePayed) {
                addGiftCardCtrl.wrongAmountError = 'wrong_gift_card_amount_error';
            }
        }

        function isDisableRedeemBtn(paymentMethodId) {
            if (
              paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD ||
              paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.NOFSHONIT
            ) {
                return !addGiftCardCtrl.giftCardsData[paymentMethodId].cvv || addGiftCardCtrl.giftCardsData[paymentMethodId].cvv.length === 0 || !addGiftCardCtrl.giftCardsData[paymentMethodId].amount || !addGiftCardCtrl.giftCardsData[paymentMethodId].balance || addGiftCardCtrl.giftCardsData[paymentMethodId].amount < 0 || addGiftCardCtrl.giftCardsData[paymentMethodId].amount > _roundCurrencyFilter(addGiftCardCtrl.maxAmount) || addGiftCardCtrl.giftCardsData[paymentMethodId].amount > addGiftCardCtrl.giftCardsData[paymentMethodId].balance;
            }
            return !addGiftCardCtrl.giftCardsData[paymentMethodId].amount || !addGiftCardCtrl.giftCardsData[paymentMethodId].balance || addGiftCardCtrl.giftCardsData[paymentMethodId].amount < 0 || addGiftCardCtrl.giftCardsData[paymentMethodId].amount > _roundCurrencyFilter(addGiftCardCtrl.maxAmount) || addGiftCardCtrl.giftCardsData[paymentMethodId].amount > addGiftCardCtrl.giftCardsData[paymentMethodId].balance;
        }

        function isGiftCardNotEligible(paymentMethodId) {
            return (paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.TAV_PLUS || paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.TAV_PLUS_LIGHT) && addGiftCardCtrl.mainPaymentMethod !== SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD &&
                addGiftCardCtrl.selectedGiftCard === paymentMethodId;
        }

        function close () {
            var redeemedGiftCards = Object.values(addGiftCardCtrl.redeemedGiftCards);
            if (!redeemedGiftCards || !redeemedGiftCards.length) {
                mDesign.hide({});
                return;
            }

            if(addGiftCardCtrl.removedGiftCards) {
                angular.extend(addGiftCardCtrl.redeemedGiftCards, addGiftCardCtrl.removedGiftCards);
            }
            _calculateTotalGiftCard();
            mDesign.hide();
        }

        function next() {
            _calculateTotalGiftCard();
            mDesign.hide(addGiftCardCtrl.redeemedGiftCards);
        }

        function _calculateTotalGiftCard() {
            // For Max Gift Card, the dialog is forced to close leading to the missing total giftCard data in summaryCtr, so we need to manually set the totalGiftCard before closing
            if (addGiftCardCtrl.redeemedGiftCards[SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD]) {
                paymentCtrl.totalPriceForPointsCalculation += paymentCtrl.paymentDetails.totalGiftCards || 0;
                paymentCtrl.paymentDetails.totalGiftCards = 0;
                paymentCtrl.paymentDetails.giftCards = addGiftCardCtrl.redeemedGiftCards;
                paymentCtrl.paymentDetails.redeemedGiftCardIds = Object.keys(paymentCtrl.paymentDetails.giftCards);
                angular.forEach(paymentCtrl.paymentDetails.redeemedGiftCardIds, function (giftCardId) {
                    paymentCtrl.paymentDetails.totalGiftCards += paymentCtrl.paymentDetails.giftCards[giftCardId].amount;
                });

                if(paymentCtrl.paymentDetails.userCashbackLoyalty) {
                    paymentCtrl.totalPriceForPointsCalculation -= paymentCtrl.paymentDetails.totalGiftCards || 0;
                    paymentCtrl.paymentDetails.userCashbackLoyalty.availableUserCashback  -= paymentCtrl.paymentDetails.totalGiftCards || 0;
                    _calculateUserPoints();
                }
            }
        }

        function _calculateUserPoints() {
            if(paymentCtrl.totalPriceForPointsCalculation > 0) {
                var points = paymentCtrl.totalPriceForPointsCalculation / paymentCtrl.paymentDetails.activeCashbackLoyalty.moneyToPointRatio;
                paymentCtrl.paymentDetails.currentOrderPoints = Number(points.toFixed(1));
            } else {
                paymentCtrl.paymentDetails.currentOrderPoints = null;
            }
        }

        function _setGiftCardsData() {
            angular.forEach(addGiftCardCtrl.giftCards, function (giftCard) {
                if (!addGiftCardCtrl.giftCardsData[giftCard.paymentMethodId]) {
                    addGiftCardCtrl.giftCardsData[giftCard.paymentMethodId] = {
                        number: null,
                        balance: null
                    };
                }
            });
        }

        function _calculateBalanceToBePayed() {
            addGiftCardCtrl.balanceToBePayed = addGiftCardCtrl.total;
            var keys = Object.keys(addGiftCardCtrl.redeemedGiftCards);
            angular.forEach(keys, function (giftCardId) {
                addGiftCardCtrl.balanceToBePayed -= addGiftCardCtrl.redeemedGiftCards[giftCardId] ? addGiftCardCtrl.redeemedGiftCards[giftCardId].amount : 0;
            });
        }

        function findGiftCard(giftCardId) {
            var index = addGiftCardCtrl.giftCards.findIndex(function (giftCard) { 
                return giftCard.paymentMethodId === giftCardId; 
            });
            return index > -1 ? addGiftCardCtrl.giftCards[index] : {};
        }

        function _reorderGiftCards(giftCards, paymentMethodId) {
            if (giftCards.length > 0) {
                var index = giftCards.findIndex(function (giftCard) {
                    return giftCard.paymentMethodId === paymentMethodId;
                })

                if (index >= 0) {
                    giftCards.push(giftCards.splice(index, 1)[0]);
                }
            }
        }

        function isRedeemedGiftCardsExist() {
            var redeemedGiftCardsValues = Object.values(addGiftCardCtrl.redeemedGiftCards);
            var isRedeemedGiftCardsHaveAmount = redeemedGiftCardsValues.some(function (giftCard) { return giftCard && giftCard.amount > 0 });
            return isRedeemedGiftCardsHaveAmount;                 
        }

        function _cutText(text, length) {
            if (text.length > length) {
                return text.substring(0, length) + '...';
            }
            return text;
        }

        Util.destroyListeners($scope, _listeners, function () {
            //console.log('Cart destroyListeners')

        });
    }]);
