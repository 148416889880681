(function (angular) {
    "use strict";

    angular.module("mobilezuz").directive("spInputDynamicDimension", function ($timeout, $rootScope) {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attrs) {
                var _TYPE = {
                    LABEL: 'label',
                    ERROR: 'error'
                };

                _init();

                function _init() {
                    // Set up a MutationObserver to detect changes in the label or error
                    var _parent;
                    if ($attrs.spInputDynamicDimensionParentSelector) {
                        _parent = _findClosestParent($element, $attrs.spInputDynamicDimensionParentSelector);
                    } else {
                        _parent = $element.parent()[0];
                    }

                    if (_parent) {
                        var _label = _parent.querySelector('label');
                        var _error = _parent.querySelector('[ng-messages]');

                        if (_label) {
                            _label.style.whiteSpace = 'normal';
                            
                            // Use ResizeObserver for label
                            _observeSize(_label, function () {
                                updateDimension(_label, _TYPE.LABEL, _parent);
                            });

                            var labelObserver = new MutationObserver(function () {
                                updateDimension(_label, _TYPE.LABEL, _parent);
                            });
                            _observe(labelObserver, _label);
                        }

                        if (_error) {
                            _makeAbsoluteBelow(_error);
                            
                            var errorObserver = new MutationObserver(function () {
                                updateDimension(_error, _TYPE.ERROR, _parent);
                            });
                            _observe(errorObserver, _error);
                        }
                    }
                }

                function _observe(_observer, _element) {
                    // Observe changes to child nodes and character data
                    _observer.observe(_element, {
                        childList: true, // For changes in text nodes
                        characterData: true, // For direct text changes
                        subtree: true, // To observe text within child elements
                        attributes: true, // Observe attribute changes
                        attributeFilter: ['style', 'class'], // Only observe the style attribute
                    });

                    // Cleanup observer on scope destroy
                    $scope.$on('$destroy', function () {
                        _observer.disconnect();
                    });
                }

                // Function to observe size changes
                function _observeSize(element, callback) {
                    var resizeObserver = new ResizeObserver(callback);
                    resizeObserver.observe(element);

                    // Cleanup observer on scope destroy
                    $scope.$on('$destroy', function () {
                        resizeObserver.disconnect();
                    });
                }

                // Function to update padding
                function updateDimension(_element, _type, _parent) {
                    switch (_type) {
                        case _TYPE.LABEL: {
                            var _eHeight = _element.offsetHeight;
                            _parent.style.marginTop = _eHeight + 'px';
                            break;
                        }
                        case _TYPE.ERROR: {
                            var _eHeight = _element.offsetHeight;
                            _parent.style.marginBottom = _eHeight + 'px';
                            break;
                        }
                    }
                }

                function _makeAbsoluteBelow(_element) {
                    _element.style.position = 'absolute';
                    _element.style.top = '100%';
                    _element.style.left = '0';
                    _element.style.width = '100%';
                    _element.style.zIndex = '1';
                }

                function _findClosestParent(element, selector) {
                    var _element = angular.element(element);
                    while (_element.length) {
                        if (_element[0].matches(selector)) {
                            return _element[0];
                        }
                        _element = _element.parent();
                    }
                    return null;
                }
            }
        };
    });
})(angular);
