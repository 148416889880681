angular.module('mobilezuz')
    .directive('spPrice', ['$filter', 'Config', 'spUnits', 'Util', function ($filter, Config, spUnits, Util) {
        var _nameFilter = $filter('name');

        // check currency for default
        var _isIsraeliRetailer = Config.currencySymbol === '₪',
            _configInitPromise = Config.waitForInit();
        _configInitPromise.then(function () {
            // when a country iso exists, use it instead
            if (Config.retailer.countryIso) {
                _isIsraeliRetailer = Config.retailer.countryIso === 'IL';
            }

            _configInitPromise = undefined;
        });

        return {
            restrict: 'E',
            replace: true,
            scope: {
                salePrice: '<',
                price: '<',
                showMeasure: '=?',
                packQuantity: '=?',
                product: '<?',
                showNormalizePrice: '<?',
                showDisclaimerOnCartPages: '=?',
                showUnits: '=?'
            },
            controller: ['$scope', '$filter', function ($scope, $filter) {
                $scope.defaultWeightUnitNames = Config.defaultWeightUnitNames;
                $scope._roundCurrencyFilter = $filter('roundCurrency');
                $scope.unitOfMeasureNames = $scope.product && !$scope.product.isWeighable && $scope.product.unitOfMeasure && $scope.product.unitOfMeasure.names;
                $scope.culture = Config.language.culture;
                $scope.quantityNormalizer = Config.retailer.settings.displayedPriceMeasureNormalizer || null;
                $scope.lengthNormalizer = Config.retailer.settings.measureMetersForLengthUnit || null;
                $scope.packPrice = $scope.product && $scope.product.branch && $scope.product.branch.packPrice;
                $scope.disclaimerOnCartPages = Config.retailer.settings.disclaimerOnCartPages;
                $scope.languageId = Config.language.id;
                if ($scope.product && $scope.product.isWeighable) {
                    $scope.unitOfMeasureNames = $scope.defaultWeightUnitNames;
                }
                $scope.isFlexibleUnitNormalization = Config.retailer.settings.isFlexibleUnitNormalization === 'true';
                $scope.showDisclaimer = showDisclaimer;
                
                var filteredUnitName = _nameFilter($scope.unitOfMeasureNames) || '';
                var normalizedPrice = spUnits.normalizePrice($scope.price, 1, filteredUnitName);

                if (normalizedPrice && $scope.product && $scope.product.isWeighable && ($scope.product.unitResolution || 1) <= 0.1) {
                    $scope.defaultWeightUnitNames = normalizedPrice.normalizer + ' ' + _nameFilter(normalizedPrice.names);

                    if ($scope.salePrice) {
                        $scope.salePrice /= $scope.price / normalizedPrice.price;
                    }

                    $scope.price = normalizedPrice.price;
                }

                _setPriceToNormalize();

                function showDisclaimer(evt, productId) {
                    Util.showBottleDepositDisclaimer(evt, productId)
                }

                function _setPriceToNormalize() {
                    $scope.priceToNormalize = $scope.price;

                    if (_configInitPromise) {
                        _configInitPromise.then(_setPriceToNormalize);
                        return;
                    }

                    // by law in israel, the normalized price must be calculated by the original price
                    if ($scope.salePrice && !_isIsraeliRetailer) {
                        $scope.priceToNormalize = $scope.salePrice;
                    }
                }

                if (Config.retailer.settings.showPriceWithoutBottleDeposit &&
                    $scope.product &&
                    $scope.product.branch &&
                    $scope.product.branch.linkedProductPrice &&
                    !$scope.showDisclaimerOnCartPages) {
                    var priceDecimal = ($scope.price % 1) != 0 ? $scope.price.toString().split(".")[1].length : 0;
                    var numberOfItems = $scope.product.isCaseMode && $scope.product.branch.case && $scope.product.branch.case.items || 1;
                    var linkedPrice = $scope.product.branch.linkedProductPrice;
                    $scope.priceToNormalize = ($scope.priceToNormalize - (numberOfItems * linkedPrice)).toFixed(priceDecimal);
                    $scope.showDisclaimer = true;
                    $scope.packPrice = null;
                }

            }],
            template: '' +
                '<p itemprop="offers" itemtype="https://schema.org/Offer" itemscope class="sp-price">' +
                '<span class="prices"> <span class="price sale-price" ng-if="!!salePrice" aria-label="{{\'sale price\' | translate}}"><span class="sr-only-element">{{\'Sale price\' | translate}}</span>{{salePrice | currency}}<span>{{!showMeasure ? \'\' : \'&nbsp;/&nbsp;\' + (defaultWeightUnitNames | name)}}</span></span>' +
                '<span class="price" ng-class="{\'with-sale-price\': !!salePrice}" aria-label="{{\'sale price\' | translate}}"><span class="sr-only-element">{{\'Regular price\' | translate}}</span>{{packQuantity ? (packQuantity + \' \' + (\'forPackQuantity\' | translate) + \' \') : \'\'}}{{packQuantity && packPrice ? (packPrice | currency) : ((price * (packQuantity ? packQuantity : 1)) | currency)}}<span>{{!showMeasure ? \'\' : \'&nbsp;/&nbsp;\' + (defaultWeightUnitNames | name)}}</span><span ng-if="showUnits">&nbsp;/{{\'unit\' | translate}}</span></span></span>' +
                '<span class="normalize-price" ng-if="isFlexibleUnitNormalization && !!product.unitOfNormalization" aria-label="{{\'normalize price\' | translate}}">' +
                '{{product.unitPrice | spDynamicUnitsPrice: product.unitOfNormalization}}' +
                '</span>' +
                '<span class="normalize-price" aria-label="{{\'normalize price\' | translate}}" ng-if="$root.config.retailer.shouldNormalizePrice && showNormalizePrice && !showMeasure && !!product.weight && (!isFlexibleUnitNormalization || isFlexibleUnitNormalization && !product.unitOfNormalization)">' +
                '{{((salePrice && regularPrice != salePrice) ? salePrice : priceToNormalize) | spUnitsPrice:(product.numberOfItems || 1) * product.weight * (product.isCaseMode && product.branch.case.items || 1):(unitOfMeasureNames | name):($root.config.language.culture):(quantityNormalizer):(lengthNormalizer)}}' +
                '</span>' +
                '<span style="margin-left:auto;" ng-if="showDisclaimerOnCartPages && product && product.branch && product.branch.linkedProductPrice" id="{{\'bottle_disclaimer_\' + product.id}}" ng-click="showDisclaimer($event, product.id)" class="bottle-deposit-disclaimer">' +
                '<img style="width:20px; height:20px" className="bottle-deposit-disclaimer-img" ng-src="{{$root.IMAGES_PATHS.INFO_ICON_GREEN}}"/>' +
                '<span class="bottle-deposit-disclaimer-content">' +
                '<span class="text">{{disclaimerOnCartPages[languageId] || (\'Price includes bottle deposit\' | translate)}}</span>' +
                '<span class="arrow"></span>' +
                '</span>' +
                '</span>' +
                '<meta itemprop="price" ng-if="!!salePrice" content="{{_roundCurrencyFilter(salePrice)}}" />' +
                '<meta itemprop="{{!!salePrice ? \'\' : \'price\'}}" content="{{_roundCurrencyFilter(price * (packQuantity ? packQuantity : 1))}}" />' +
                '<meta itemprop="priceCurrency" content="{{::$root.ANALYTICS_CURRENCY[$root.config.currencySymbol]}}" />' +
                '</p>'
        };
    }]);
