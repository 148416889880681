(function(angular) {
    'use strict';

    angular.module('mobilezuz').controller('ProductImagesDialogCtrl', [
        '$filter', 'mDesign', 'images', 'is360Mode', 'IMAGE_TYPES',
        function ($filter,  mDesign, images, is360Mode, IMAGE_TYPES) {
            var productImagesDialogCtrl = this,
                _imageFilter = $filter('image');

            productImagesDialogCtrl.images = images;
            productImagesDialogCtrl.shownImage = images[0];
            productImagesDialogCtrl.closeDialog = mDesign.hide;

            angular.forEach(productImagesDialogCtrl.images, function(image) {
                if (image.typeId === IMAGE_TYPES.IMAGE) {
                    _loadImage(_imageFilter(image.url, 'large'));
                }
                if (is360Mode && image.typeId === IMAGE_TYPES.IMAGE360) {
                    productImagesDialogCtrl.shownImage = image;
                }
            });

            function _loadImage(url, callback) {
                var image = new Image();
                image.onload = callback;
                image.src = url;
            }
        }
    ]);
})(angular);