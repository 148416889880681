(function (angular, app) {
    'use strict';

    app.component('noResultsMessage', {
        controllerAs: 'noResultsMessageCtrl',
        bindings: {
            message: '@',
            selectedFiltersLength: '<?',
            clearFilters: '&?'
        },
        templateUrl: 'views/templates/no-results-message.html'
    });
})(angular, angular.module('mobilezuz'));