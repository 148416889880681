angular.module('mobilezuz')
    .config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
        $stateProvider.state('app.retailer.sitemap', {
            url: '/sitemap',
            data: {
                stateAccess: PAGE_ACCESS.ALLOW,
                bodyClass: 'sitemap',
                metaTags: {
                    title: 'Sitemap'
                }
            },
            resolve: {
                tree: ['$q', '$timeout', 'Tree', function ($q, $timeout, tree) {
                    return $timeout(function () {
                        return tree.getTree();
                    }, 10).then(function (data) {
                        return JSON.parse(JSON.stringify(data));
                    });
                }]
            },
            views: {
                '@': {
                    templateUrl: 'views/sitemap.html',
                    controller: 'SitemapCtrl',
                    controllerAs: 'sitemapCtrl'
                }
            }
        });
    }])
    .controller('SitemapCtrl', ['$rootScope', '$scope', 'Config', 'Retailer', 'User', 'Api', 'mDesign', 'SpCaptcha', 'DataLayer', 'tree', '$state', 'Offers', 'Util', '$location',
        function ($rootScope, $scope, Config, Retailer, User, api, mDesign, SpCaptcha, DataLayer, tree, $state, Offers, Util, $location) {
            var sitemapCtrl = this,
                userId;

            $rootScope.title = 'Sitemap';

            sitemapCtrl.categories = tree.categories;
            sitemapCtrl.goToCategoryProducts = goToCategoryProducts;
            sitemapCtrl.goToLinkPage = goToLinkPage;
            sitemapCtrl.goToState = goToState;
            sitemapCtrl.customPages = [];
            sitemapCtrl.specials = null;
            sitemapCtrl.retailerSettings = Config.retailer.settings;
            sitemapCtrl.enablePersonalizedCoupons = Config.retailer.settings.enablePersonalizedCoupons;
            sitemapCtrl.discountClubActive = Config.retailer.settings.discountClubActive;
            sitemapCtrl.branches = Config.retailer.settings.branches;
            sitemapCtrl.loyaltyClubDriver =  Config.retailer.loyaltyClubDriver;
            sitemapCtrl.isLoyaltyExtendedRegisterActive = sitemapCtrl.loyaltyClubDriver && sitemapCtrl.loyaltyClubDriver.clientConfig && sitemapCtrl.loyaltyClubDriver.clientConfig.extendedLoyaltyClub;

            _setCustomPages();
            _setUserSettings();
            _setSpecials();

            function _setCustomPages() {
                angular.forEach(Config.retailer.toolbar, function (link) {
                    sitemapCtrl.customPages.push({
                        text: link.name,
                        state: 'app.links',
                        stateId: link.id,
                        url: link.url
                    });
                });
    
                angular.forEach(Config.retailer.publishedWebPages, function (webPage) {
                    sitemapCtrl.customPages.push({
                        text: webPage.name,
                        state: 'app.webPage',
                        stateId: webPage.id
                    });
                });
            }

            function _setUserSettings() {
                return User.getUserSettings(true).then(function(resp) {
                    $scope.userSettings = resp;
                });
            }
            function _setSpecials() {
                Offers.getFirstSpecials().then(function(specials) {
                    sitemapCtrl.specials = specials;
                });
            }

            $scope.isLoggedIn = function () {
                return !!User.getUserLoginData();
            };

            function goToCategoryProducts(category) {
                return $state.go('app.categories.categoryProducts', {categoryId: category.id, level1: null, level2: null});
            }
            function goToLinkPage(link) {
                if (link.url && Util.isInternalUrl(link.url)) {
                    return $location.url(link.url);
                }

                return $state.go(link.state, {id: link.stateId});
            }

            function goToState(state) {
                return $state.go(state);
            }
        }]);