angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.ordersHistory', {
        url: '/orders-history',
        data: {
            stateAccess: PAGE_ACCESS.LOGGED_IN,
            bodyClass: 'orders-history',
            metaTags: {
                title: 'Orders history'
            }
        },
        resolve: {
            orders: ['Api', function (api) {
                return api.request({
                    method: 'GET',
                    url: 'retailers/:rid/users/:uid/orders',
                    params: {start: 0, max: 20}
                }, {
                    fireAndForgot: true
                });
            }]
        },
        views: {
            '@': {
                templateUrl: 'views/orders-history.html',
                controller: 'OrdersHistoryCtrl'
            }
        }
    });

}]).controller('OrdersHistoryCtrl', [
    '$scope', '$rootScope', 'Orders', 'orders', 'mDesign', 'Util', 'ORDER_STATUSES', 'ORDER_STATUS_STAGES', 'Api',
    function ($scope, $rootScope, Orders, orders, mDesign, Util, ORDER_STATUSES, ORDER_STATUS_STAGES, api) {
        $scope.orders = orders;
        $scope.openOrderDialog = openOrderDialog;
        $scope.cancelOrder = cancelOrder;
        $scope.editOrder = editOrder;
        $scope.isOrderEditable = isOrderEditable;
        $scope.orderStatus = Orders.orderStatus;
        var _isRequesting = false,
            _currentFrom = orders.length,
            listeners = [];

        listeners.push($scope.$on('vertical-scroll-end', function () {
            if (!_isRequesting) {
                _getOrders();
            }
        }));

        listeners.push($rootScope.$on('order.update.timeslot.refresh', function (event, data) {
            if(data.order){
                angular.forEach(orders, function (listOrder) {
                    if (data.order.id === listOrder.id) {
                        listOrder.shippingTimeFrom = data.order.shippingTimeFrom;
                        listOrder.shippingTimeFromDisplay = data.order.shippingTimeFromDisplay;
                        listOrder.shippingTimeTo = data.order.shippingTimeTo;
                        listOrder.totalAmount = data.order.totalAmount;
                        _setShownAmount($scope.orders)
                    }
                });
            }
        }));

        _setShownAmount($scope.orders)

        function openOrderDialog(statusId, event) {
            event.stopPropagation();

            mDesign.alert({
                title: $scope.orderStatus[statusId].title,
                content: $scope.orderStatus[statusId].description
            });
            return false;
        }

        function isOrderEditable(order) {
            return Util.isOrderEditable(order);
        }

        function editOrder(order) {
            Orders.checkIfUserCanUpdateOrder(order.id).then(function(response){
                if(response && response.isCustomerEditBlocked){
                    var  contentHeader = 'We have started collecting your original order.',
                        contentTitle = 'Your order cannot be changed at this time'
                        contentFooter = '';


                    return mDesign.dialog({
                        focusOnOpen: false,
                        clickOutsideToClose: false,
                        templateUrl: 'views/templates/order-status-dialog.html',
                        controller: ['$scope', function ($scope) {
                            $scope.contentHeader = contentHeader;
                            $scope.contentTitle = contentTitle;
                            $scope.contentFooter = contentFooter;
                            $scope.hide = mDesign.hide;
                        }]
                    });
                }
                else{
                    Orders.editOrderDialog(order);
                }
            })

        }

        function _setShownAmount(orders) {
            angular.forEach(orders, function (order) {
                if (Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.READY) || Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.FINISH)) {
                    order.shownAmount = order.overallCharged || order.amountCharged || 0;
                } else {
                    order.shownAmount = order.totalAmount - (order.ebtSnapTaxSaved || 0) + (order.ebtWeightDeposit || 0) || 0;
                    
                }
            });
        }

        function _getOrders() {
            _isRequesting = true;

            var requestParams = {
                start: _currentFrom,
                max: 20,
            };

            return api.request({
                method: 'GET',
                url: 'retailers/:rid/users/:uid/orders',
                params: requestParams
            }, {
                fireAndForgot: true
            }).then(function (orders){
                _currentFrom += orders.length;
                _setShownAmount(orders)
                angular.forEach(orders,function (order){
                    $scope.orders.push(order)
                });
            }).finally(function (){
                _isRequesting = false
            })
        }


        function cancelOrder(order, event) {
            event.stopPropagation();

            Orders.cancelOrder(order);

            return false;
        }

        Util.destroyListeners($scope, listeners);

        Util.destroyListeners($scope, [$rootScope.$on('orders.cancelOrder', function (event, order) {
            angular.forEach(orders, function(listOrder) {
                if (order.id === listOrder.id) {
                    listOrder.statusId = ORDER_STATUSES.CANCELLED;
                }
            });
        })]);
    }
]);
