(function(angular) {
	var httpProvider;

	angular
		.module('mobilezuz', [
			'ngAnimate',
			// 'ngTouch',
			'ui.router',
			'ngSanitize',
			'ngMaterial',
			'ngMessages',
			'ngCookies',
			'ngAria',
			'spVerticalValueSlider',
			'spApi',
			'spFilters',
			'spUnits',
			'spServices',
			'spPayments',
			'spInViewWatcher',
			'sp-autocomplete',
			'spTemplates',
			'spSvgTemplates',
			'spGoogleMaps',
			'spNotifications',
			'spHtmlComponentsRenderer',
			'duScroll',
			'spDialogUrlManager',
			'spProductTags',
			'spVirtualRepeat',
			'spCaptcha',
			'spImageZoom',
			'sp360',
			'spHomePageComponents',
			'spItemsGrid',
			'spSeparateInputValue'
		])
		.config([
			'$compileProvider', '$httpProvider', '$qProvider', '$stateProvider', '$locationProvider', '$urlRouterProvider',
			'$provide', '$mdThemingProvider', '$mdGestureProvider', 'ApiProvider', 'LoadingProvider', 'SpDialogUrlManagerProvider',
			'SpDeliveryTimesServiceProvider', 'PermanentFiltersProvider', 'PaymentsServiceProvider', '$ariaProvider',
			'MOBILE_SERVICE_ID', 'SP_URL_DIALOG_QUERY_PARAMS', 'SP_URL_DIALOG_DATA_QUERY_PARAMS', 'URL_DIALOG_DATA_QUERY_PARAMS',
			function($compileProvider, $httpProvider, $qProvider, $stateProvider, $locationProvider, $urlRouterProvider,
					 $provide, $mdThemingProvider, $mdGestureProvider, apiProvider, loadingProvider, SpDialogUrlManagerProvider,
					 spDeliveryTimesServiceProvider, PermanentFiltersProvider, PaymentsServiceProvider, $ariaProvider,
					 MOBILE_SERVICE_ID, SP_URL_DIALOG_QUERY_PARAMS, SP_URL_DIALOG_DATA_QUERY_PARAMS, URL_DIALOG_DATA_QUERY_PARAMS) {
				httpProvider = $httpProvider;

				$ariaProvider.config({
					ariaInvalid: false
				});

				SpDialogUrlManagerProvider.closeDialog = ['mDesign', function(mDesign) {
					return mDesign.hide();
				}];

				PermanentFiltersProvider.localStorage = ['$window', function($window) {
					return {
						getItem: function(key) {
							var val = $window.localStorage.getItem('mobileZuz-' + key);
							if (val) {
								return JSON.parse(val);
							}
						},
						setItem: function(key, value) {
							return $window.localStorage.setItem('mobileZuz-' + key, JSON.stringify(value));
						}
					};
				}];

				spDeliveryTimesServiceProvider.days = 6;
				spDeliveryTimesServiceProvider.getLocals = ['Config', function(Config) {
					return {
						timeZoneOffset: Config.timeZoneOffset,
						languageId: Config.language.id
					};
				}];

				$httpProvider.useApplyAsync(true);

				// $qProvider.errorOnUnhandledRejections(false);

				angular.filterCollection = function(items, func) {
					if (func && angular.isFunction(func) && items && (angular.isArray(items) || angular.isElement(items) || angular.isObject(items))) {
						if (angular.isElement(items)) {
							items = angular.element(items);
						}
						var isArray = angular.isArray(items) || angular.isElement(items),
							res = isArray ? [] : {};
						angular.forEach(items, function(value, key) {
							if (func(value, key)) {
								if (isArray) {
									res.push(value);
								} else {
									res[key] = value;
								}
							}
						});
						return res;
					} else {
						return items;
					}
				};

				loadingProvider.showLoadingAfter = 0;
				loadingProvider.loadingTemplate = '<div class="loader"><md-progress-circular class="md-accent" md-mode="indeterminate"></md-progress-circular></div>';
				loadingProvider.activeClass = 'shown';

				function _joinUrl(base, path) {
					if (base.indexOf('http') !== -1 && path.indexOf(base) !== -1) {
						return path;
					}

					var baseHasSlash = base[base.length - 1] === '/',
						pathHasSlash = path[0] === '/';

					if (baseHasSlash !== pathHasSlash) {
						return base + path;
					}
					if (baseHasSlash && pathHasSlash) {
						return base + path.substring(1);
					}
					return base + '/' + path;
				}

				apiProvider.setParameters = [
					'$rootScope', '$q', 'httpOptions', 'callback', 'User', 'Config', 'Retailer', 'CordovaService',
					function($rootScope, $q, httpOptions, callback, User, Config, Retailer, CordovaService) {
						httpOptions.headers = httpOptions.headers || {};
						if (httpOptions.method) {
							var method = httpOptions.method.toUpperCase();
							if (method !== 'POST' && method !== 'GET') {
								httpOptions.headers['X-HTTP-Method-Override'] = method;
								httpOptions.method = 'POST';
							}
						}

						var promises = {};
						if ((!Config.branch || !Config.branch.id) && httpOptions.url.indexOf(':bid') > -1) {
							promises.branch = Config.waitForBranchId();
						}
						if (httpOptions.url.indexOf('http://') !== 0 && httpOptions.url.indexOf('https://') !== 0) {
							promises.host = Retailer.getRetailerMainDomain();
						}
						// when in a cordova app, wait for device ready
						if (Config.appId) {
							promises.deviceReady = CordovaService.ready;
						}
						$q.all(promises).then(function(results) {
							httpOptions.url = httpOptions.url.replace(/:rid/, Config.retailerId);

							if (Config.branch && Config.branch.id) {
								httpOptions.url = httpOptions.url.replace(/:bid/g, Config.branch.id);
							}

							if (results.host) {
								httpOptions.url = _joinUrl(results.host, httpOptions.url);
							}

							var userLoginData = User.getUserLoginData();
							if (userLoginData) {
								httpOptions.url = httpOptions.url.replace(/:uid/, userLoginData.uid);
								httpOptions.params = httpOptions.params || {};
								httpOptions.headers.Authorization =  User.getAuthorizationToken()
							}

							httpOptions.params.appId = MOBILE_SERVICE_ID;
							callback();
						});
					}
				];

				apiProvider.retry = {
					statuses: [0, -1],
					callback: ['mDesign', 'callback', function(mDesign, callback) {
						mDesign.alert('No internet connection').then(callback);
					}]
				};

				function lightColor(color_1, weight) {
					function d2h(d) {
						return d.toString(16);
					}

					function h2d(h) {
						return parseInt(h, 16);
					}

					weight = Number(weight) || 50;
					color_1 = color_1[0] == '#' ? color_1.substring(1) : color_1;

					var color_2 = 'ffffff',
						color = '#';

					for (var i = 0; i <= 5; i += 2) {
						var v1 = h2d(color_1.substr(i, 2)),
							v2 = h2d(color_2.substr(i, 2)),
							val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));

						while (val.length < 2) {
							val = '0' + val;
						}

						color += val;
					}

					return color;
				}

				window.tempConfig = angular.copy(window.defaultThemeConfig);
				if (window.sp.mobileData.siteMainColor) {
					window.tempConfig.color = '#' + window.sp.mobileData.siteMainColor;
				}

				window.spBuildCss(window.themeCss, window.tempConfig, document.querySelector('#theme_css'));

				var retailerColor = window.tempConfig.color.substring(1, window.tempConfig.color.length - 1);
				$mdThemingProvider.definePalette('retailerColorPalette', {
					'50': lightColor(retailerColor, 10),
					'100': lightColor(retailerColor, 20),
					'200': lightColor(retailerColor, 30),
					'300': lightColor(retailerColor, 40),
					'400': lightColor(retailerColor, 50),
					'500': lightColor(retailerColor, 60),
					'600': lightColor(retailerColor, 70),
					'700': lightColor(retailerColor, 80),
					'800': lightColor(retailerColor, 90),
					'900': lightColor(retailerColor, 100),
					'A100': lightColor(retailerColor, 25),
					'A200': lightColor(retailerColor, 50),
					'A400': lightColor(retailerColor, 75),
					'A700': lightColor(retailerColor, 95),
					'contrastDefaultColor': 'light',

					'contrastDarkColors': ['50', '100',
						'200', '300', '400', 'A100'],
					'contrastLightColors': undefined
				});

				$mdThemingProvider.theme('default').primaryPalette('retailerColorPalette');

				$mdGestureProvider.skipClickHijack();

				$compileProvider.debugInfoEnabled(true);
				$compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|app|file|mailto|maps|tel|geo):/);
				$compileProvider.imgSrcSanitizationWhitelist(/^\s*(local|https?|app|file|blob|):/);

				//// decorate $exceptionHandler to call window.onerror
				// $provide.decorator('$exceptionHandler', ['$delegate', function ($delegate) {
				//
				//     return function(exception, cause){
				//
				//         // try to retrieve the source line and column
				//         var splitSource = [],
				//             firstLine = exception.stack && exception.stack.split('\n')[1]; // the first line is the exception message
				//
				//         if (firstLine) {
				//             var splitLine = firstLine.split('/'),
				//                 source = splitLine[splitLine.length-1];
				//
				//             if (source) {
				//                 splitSource = source.split(':');  // e.g. 'at http://localhost:3000/frontend/services/analytics.js:72:17'
				//             }
				//         }
				//
				//         window.dispatchEvent(new (window.ErrorEvent || window.Event)('error', {
				//             error: exception,
				//             message: exception.message,
				//             filename: splitSource[0],
				//             lineno: splitSource[1],
				//             colno: splitSource[2]
				//         }));
				//
				//         $delegate(exception, cause);
				//     };
				// }]);

				var stateUrl = '?{domain},{version},{mobileAppId},{branchId},{token},{userId},{organization},{retailerId},{inviteId},{promotionId},{events},{hubChildrenSearchText}',
					dialogParams = [];

				stateUrl += ',{hubId:int}'; // for backwards in old hub mobile apps

				if(window.cordova === undefined && window.sp.mobileData && window.sp.mobileData.retailer && window.sp.mobileData.retailer.settings) {
                    var shouldUseMultiLanguageURLPath = window.sp.mobileData.retailer.settings.shouldUseMultiLanguageURLPath === 'true';
                    if(shouldUseMultiLanguageURLPath){
                        var langPrefixURLReg =  /^\/(he|en|es|fr|ru|ar)\//;
                        if(langPrefixURLReg.test(location.pathname)){
                            stateUrl = '/:lang' + stateUrl
                        }

                    }
                    // if(shouldUseMultiLanguageURLPath === 'true') stateUrl = '/:lang' + stateUrl;
                }

				angular.forEach([SP_URL_DIALOG_QUERY_PARAMS, SP_URL_DIALOG_DATA_QUERY_PARAMS, URL_DIALOG_DATA_QUERY_PARAMS], function (paramsObject) {
					angular.forEach(paramsObject, function (params) {
						Array.prototype.push.apply(dialogParams, params);
					});
				});
				if (dialogParams.length) {
					stateUrl += ',{' + dialogParams.join('},{') + '}';
				}
				$stateProvider.state('app', {
					abstract: true,
					url: stateUrl,
					resolve: {
						languageConfig: ['$stateParams', '$rootScope', 'Config', 'SpDeliveryAreasService', function($stateParams, $rootScope, Config, SpDeliveryAreasService) {
							// only run on web because native dont need multi lang url
							if(window.cordova === undefined && window.sp.mobileData.retailer.settings.shouldUseMultiLanguageURLPath === 'true') {
								if($stateParams.lang) {
									Config.languages.forEach(function(locale) {
										if(locale.culture === $stateParams.lang) {
											$rootScope.config.language = locale;
											SpDeliveryAreasService.setLanguageCookie($stateParams.lang);
										}
									})
								}
								else {
                                    var langPrefixURLReg =  /^\/(he|en|es|fr|ru|ar)\//;
                                    if(!langPrefixURLReg.test(location.pathname)) {
                                        var retailerCulture = window.sp.mobileData && window.sp.mobileData.retailer && window.sp.mobileData && window.sp.mobileData.retailer.culture;

                                        if( !$rootScope.config.language || $rootScope.config.language.culture != retailerCulture ) {
                                        	Config.languages.forEach(function(locale) {
												if(locale.culture === retailerCulture) {
													$rootScope.config.language = locale;
												}
											});
                                        }
                                    }
                                }
							}
						}]
					},
					reloadOnSearch: false
				});

				$urlRouterProvider
				// backward compatibility
					.when('/sales', '/specials')
					.when('/about/branches', '/retailer/information')
					.when('/about/branches/:id', '/retailer/information/:id')
					.when('/my-shopping-lists', '/shop-lists')
					.when('/my-shopping-lists/:id', '/shop-lists/:id')
					.when('/about/:page', '/retailer/:page')

					.when('/retailer/info', '/about')
					.when('/retailer/policy', '/terms-and-conditions')
					.when('/retailer/contacts', '/retailer/contact-details')

					// frontend compatibility
					.when('/retailer/times', '/retailer/information')
					.when('/user-edit/contact-us', '/retailer/contact-us')
					.when('/user-edit/premium-loyalty-club?loyaltyClubDriverId', 'premium-loyalty-club?loyaltyClubDriverId')

					// TODO temp redirect from the old path, remove that once there are no longer banners and links pointing to it
					.when('/tags/:productTagId/products' + stateUrl, '/product-tags/:productTagId/products' + stateUrl)
					
					// otherwise go to home page
					.otherwise('/');

				if (window.cordova === undefined || (window.location.protocol.startsWith("http") && window.location.hostname !== 'mobilezuz')) {
					$locationProvider.html5Mode({
						enabled: true,
						requireBase: false
					});
				} else {
					$locationProvider.html5Mode(false);
				}

				PaymentsServiceProvider.getConfig = [
					'$q', 'Config', 'Util', 'User', 'Cart', 'Retailer', 'CREDIT_CARD_MODES',
					function($q, Config, Util, User, Cart, Retailer, CREDIT_CARD_MODES) {
						return $q.all({
							domain: Retailer.getRetailerMainDomain(),
							init: Config.waitForInit()
						}).then(function(results) {
							var userLoginData = User.getUserLoginData();

							return {
								retailerId: Config.retailerId,
								branchId: Config.branch && Config.branch.id,
								creditCardWindowType: Config.branch && Config.branch.creditCardWindowType || Config.retailer.creditCardWindowType,
								creditCardSupportsExternalPaymentFlow: Config.branch &&
									Config.branch.creditCardSupportsExternalPaymentFlow || Config.retailer.creditCardSupportsExternalPaymentFlow,
								isRememberCreditCards: Util.getBranchSettings(Config.retailer).creditCardMode === CREDIT_CARD_MODES.REMEMBER_CARDS,
								cartId: Cart.serverCartId,
								userId: userLoginData && userLoginData.uid,
								token: userLoginData && userLoginData.token,
								domain: results.domain,
								languageId: Config.language.id

							};
						});
					}
				];

				PaymentsServiceProvider.showIFrameDialog = [
					'mDesign', 'Config', 'options', 'finishEventPromise',
					function(mDesign, Config, options, finishEventPromise) {
						finishEventPromise.then(function(close) {
							if (close !== false) {
								mDesign.hide();
							}
						});

						return mDesign.dialog({
							focusOnOpen: false,
							clickOutsideToClose: true,
							templateUrl: 'views/templates/payment-iframe-dialog.html',
							controller: 'PaymentIFrameDialogCtrl as paymentIFrameCtrl',
							locals: { options: options }
						});
					}
				];

				PaymentsServiceProvider.showAddGiftCardDialog = [
					'mDesign', 'Config', 'options',
					function(mDesign, config, options) {
						return mDesign.dialog({
							focusOnOpen: false,
							clickOutsideToClose: true,
							templateUrl: 'views/templates/inner-payment-dialog.html',
							controller: 'InnerPaymentDialogCtrl as innerPaymentCtrl',
							locals: { options: options }
						});
					}
				];

				PaymentsServiceProvider.showErrorDialog = ['Util', 'error', function(util, error) {
					return util.showPaymentErrorDialog({error: error});
				}];
			}])
		.run(['$q', '$window', '$rootScope', '$state', '$location', '$filter', '$compile', '$document', '$timeout',
			'$injector', 'PermanentFilters', 'SpCartService', 'HubService', 'CordovaService', 'mDesign', 'Util',
			'Config', 'User', 'Retailer', 'spFilters', 'spUnitsConfig', 'Notifications', 'Api', 'spGoogleMaps',
			'SpProductTags', 'SpRecipeService', 'UserVerificationDialog', 'ExternalCheckout', 'Cart', 'DeepLinkHandler',
			'Orders', 'PAGE_ACCESS', 'IMAGES_PATHS', 'SP_UNITS', 'STATE_NAMES', 'MOBILE_SERVICE_ID',
			'ORGANIZATIONS_ROLES', 'ORGANIZATION_TYPES', 'CART_LINE_TYPES', 'DEBUGGING_PROVIDERS',
			'AdobeAnalytics', 'DialogAvailability', 'NagishLi', 'spMixPanel',
			function($q, $window, $rootScope, $state, $location, $filter, $compile, $document, $timeout,
					 $injector, PermanentFilters, SpCartService, HubService, CordovaService, mDesign, Util,
					 Config, User, Retailer, spFilters, spUnitsConfig, Notifications, api, spGoogleMaps,
					 SpProductTags, SpRecipeService, UserVerificationDialog, ExternalCheckout, Cart, DeepLinkHandler,
					 OrdersService, PAGE_ACCESS, IMAGES_PATHS, SP_UNITS, STATE_NAMES, MOBILE_SERVICE_ID,
					 ORGANIZATIONS_ROLES, ORGANIZATION_TYPES, CART_LINE_TYPES, DEBUGGING_PROVIDERS,
					 AdobeAnalytics, DialogAvailability, NagishLi, spMixPanel) {

				SpProductTags.setRetailerId(Config.retailerId);
				$rootScope.PermanentFilters = PermanentFilters;
				$rootScope.header = true;
				$rootScope.footer = true;
				$rootScope.disablePopupOnCurrentRoute = false;
				var langQueryName = 'lang';
				if ($location.search()[langQueryName]) {
					angular.forEach(Config.languages, function(language) {
						if (language.culture === $location.search()[langQueryName]) {
							Config.setLanguage(language);
						}
					});
				}

				var currentVersion
				var checkForceUpdate

				var platformId = !!window.cordova && (window.cordova.platformId == 'android' ? 1 : (window.cordova.platformId == 'ios' ? 2 : null))
				
				if (platformId) {
					document.addEventListener('deviceready', function() {
						var versionPs = [cordova.getAppVersion.getVersionNumber()];
						//android
						if(platformId == 1){
							var androidAppUpdateInfoPromise = new Promise(function(resolve, reject){
								window.plugins.InAppUpdate.check(function(success){
									resolve(success);
								}, function(error){
									reject(error);
								});
							});
							versionPs.push(androidAppUpdateInfoPromise)
						}
					    Promise.all(versionPs).then(function(versions) {
					    	var version = versions[0],
					    		androidAppUpdateInfo = versions[1];
					        currentVersion = version;
					        
					        // Once the version number is obtained, proceed with the next steps
					        return Retailer.getRetailerSettings().then(function(configuration) {
						        return api.request({
						            method: 'GET',
						            url: '/v2/services/' + MOBILE_SERVICE_ID + '/platforms/' + platformId + '/retailers/' + Config.retailerId + '/app-version/check-force-update',
						            params: { 
						                currentVersion: currentVersion,
						                appleAppId: configuration.settings.appleAppId,
						                spVersion: window.sp.version,
						                androidUpdateAvailability: androidAppUpdateInfo && androidAppUpdateInfo.updateAvailability || null
						            }
						        }).then(function(res) {
							        checkForceUpdate = res;
							        if (checkForceUpdate) {
							            return showAppVersionUpdateDialog(checkForceUpdate);
							        }
							    });
						    })
					    })
					}, false);
				}		

				function showAppVersionUpdateDialog(checkForceUpdate){
					var defaultAppVersionUpdatePopupSettings = {
						title: {
							en: 'Update Required',
							he: 'קיים עדכון אפליקציה',
							ru: 'Требуется обновление',
							es: 'Actualización necesaria',
							ar: 'يُرجى تحديث التطبيق',
							fr: 'Mise à jour requise.'
						},
						text: {
							en: 'Hey there! It\'s time to update your app for a better experience. Get the latest version now to enjoy new features, faster performance, and increased security.',
							he: 'מומלץ להתקין את העדכון החדש לשיפור הביצועים, האבטחה וחוויית הקנייה באפליקציה.',
							ru: 'Привет! Пора обновить ваше приложение, чтобы оно работало еще лучше. Установите последнюю версию и наслаждайтесь новыми функциями, более быстрой работой и повышенной безопасностью.',
							es: '¡Hola! Debes actualizar tu aplicación para que podamos brindarte una mejor experiencia. Descarga la versión más reciente para disfrutar de nuevas funciones, un rendimiento más rápido y mayor seguridad.',
							ar: 'مرحبًا بكم! حان الوقت لتحديث تطبيقك للحصول على تجربة أفضل. احصل على أحدث إصدار الآن للاستمتاع بالميزات الجديدة والأداء الأسرع والأمان المعزّز.',
							fr: 'Salut! Il est temps de mettre à jour votre application pour une meilleure expérience. Obtenez la dernière version maintenant pour bénéficier de nouvelles fonctionnalités, de meilleures performances et d\'une plus grande sécurité.'
						},
						allowUpdateBtn: {
							en: 'Update Now',
							he: 'עדכן כעת'
						},
					};
					Retailer.getRetailerSettings().then(function(retailerSettings) {
						Retailer.getAppVersionUpdatePopupSettings().then(function(data){
							$rootScope.AppVersionUpdatePopup = data.AppVersionUpdatePopup;
							return data.AppVersionUpdatePopup;
						})
						.catch(function(){
							return defaultAppVersionUpdatePopupSettings;
						})
						.then(function(AppVersionUpdatePopupSettings){
							mDesign.dialog({
								focusOnOpen: false,
								clickOutsideToClose: false,
								templateUrl: 'views/templates/app-version-update-dialog.html',
								controller: ['$scope', function ($scope) {
									$scope.update = update;
									$scope.title = (AppVersionUpdatePopupSettings && AppVersionUpdatePopupSettings.title && AppVersionUpdatePopupSettings.title[Config.language.culture]) || defaultAppVersionUpdatePopupSettings.title[Config.language.culture];
									$scope.text = (AppVersionUpdatePopupSettings && AppVersionUpdatePopupSettings.text && AppVersionUpdatePopupSettings.text[Config.language.culture]) || defaultAppVersionUpdatePopupSettings.text[Config.language.culture];
									$scope.allowUpdateButtonText = (AppVersionUpdatePopupSettings && AppVersionUpdatePopupSettings.allowButtonText && AppVersionUpdatePopupSettings.allowButtonText[Config.language.culture]) || defaultAppVersionUpdatePopupSettings.allowUpdateBtn[Config.language.culture];
		
									function update() {
										if(window.cordova && window.cordova.plugins && window.cordova.plugins.diagnostic){
											if (window.cordova.platformId === 'ios') {
												window.open('https://itunes.apple.com/us/app/apple-store/id' + retailerSettings.settings.appleAppId)
											}
											else if (window.cordova.platformId === 'android') { 
												cordova.InAppBrowser.open('https://play.google.com/store/apps/details?id=' + retailerSettings.settings.androidBundleId, '_system', 'location=yes');
											}
										}
										window.location.reload()
									}
								}]
							});
						});
					});
				}

				NagishLi.init();

				var searchParams = $location.search();

				if ($window.MobileAccessibility){
					MobileAccessibility.usePreferredTextZoom(false);
				}

				Util.getField(httpProvider, ['defaults', 'headers', 'common'], function(headerMetaData) {
					Util.getField(sp, ['build', 'git_commit'], function(commit) {
						headerMetaData['x-client-commit'] = commit.substring(0, 7);
					});

					Util.getField(sp, ['build', 'release'], function(version) {
						headerMetaData['x-client-version'] = version;
					});
				});

				if (!!$location.search().retailerId) {
					var searchRetailerId = Number($location.search().retailerId),
						multiRetailers = Config.multiRetailers,
						foundRetailer = multiRetailers && multiRetailers.retailers.filter(function(retailer) {
							return retailer.id === searchRetailerId;
						});
					if (multiRetailers && multiRetailers.retailers.length && Config.retailerId !== searchRetailerId && foundRetailer && foundRetailer.length) {
						Config.retailerId = searchRetailerId;
						return api.request({
							method: 'PUT',
							url: '/frontend/' + searchRetailerId
						}).then(function() {
							Util.clearLocalStorage();
							Util.reload();
						});
					}
				}

				$rootScope.hasCordova = !!window.cordova;

				spFilters.missingImage = IMAGES_PATHS.MISSING_ICON;

				spUnitsConfig.setCurrency(Config.currencySymbol);

				Config.waitForInit().then(function() {
					spUnitsConfig.setGroupByMassUnit(Config.defaultWeightUnitNames, Config.currencySymbol === '$' ? 'us' : 'eu');
					spUnitsConfig.setNormalizerOverrides({mass: Config.retailer.displayedPriceMeasureUnit});
					if ($rootScope.hasCordova) {
						_injectHotjarScript(Config.retailer, MOBILE_SERVICE_ID, DEBUGGING_PROVIDERS);
					}
				});

				_setPackagesLanguage();
				$rootScope.$on('config.language.set', _setPackagesLanguage);
				if( window.cordova === undefined ) {
					$rootScope.$on('config.language.set', _setLocaleParam);
				}

				$rootScope.config = Config;
				$rootScope.isUs = Config.currencySymbol === '$';
				$rootScope.bodyClass = {};
				$rootScope.preventHistoryBack = false;


				function _setPackagesLanguage() {
					spFilters.name.language = {
						id: Config.language.id,
						culture: Config.language.culture
					};

          			spFilters.name.retailerLanguage = Config.retailerLanguage;

					spUnitsConfig.setDefaultLanguage(Config.language.culture);

					Config.waitForInit().then(function() {
						_setNavBarConfig();
						var countries = Config.retailer.settings.countriesIsoSearch ? JSON.parse(Config.retailer.settings.countriesIsoSearch) : [];
						var	countriesIso = [];
						angular.forEach(countries, function(country) {
							countriesIso.push(country.isoCode);
						});
						spGoogleMaps.init(Config.language.id, countriesIso);

						OrdersService.watchEditOrderId();
					});
				}

				function _setNavBarConfig(){
					var mobileNavbarConfig = Config.retailer.mobileNavbarSettings ? JSON.parse(Config.retailer.mobileNavbarSettings) : {};
					var isEnableNewNavBarHeader = mobileNavbarConfig && mobileNavbarConfig.isEnable ? mobileNavbarConfig.isEnable.appHeader : false;
					if(isEnableNewNavBarHeader) {
						$rootScope.bodyClass.newNavBarHeader = 'new-navbar-header';
					}
				}

				function _setLocaleParam(_, locale) {
					var shouldUseMultiLanguageURLPath = window.sp.mobileData.retailer.settings.shouldUseMultiLanguageURLPath;
                    if(shouldUseMultiLanguageURLPath === 'true'){
                        var langPrefixURLReg =  /^\/(he|en|es|fr|ru|ar)\//;
                        if(langPrefixURLReg.test(location.pathname)){
                            if(locale && locale.culture) {
								if($state.current.name) {
									$state.go($state.current.name, {
										lang: locale.culture
									}, {
										notify: false,
										reload: false
									})
								}
							}
                        }
                        else{
                            return api.request({
                                method: 'PUT',
                                url: '/frontend/lang/' + locale.culture
                            }).then(function () {
                                location.reload();
                            });
                        }
                        $rootScope.metaTags.canonical = (Config.retailer.domain && $location.$$path) ? 'https://' + Config.retailer.domain + '/' + locale.culture + '/' +$location.$$path.replace(langPrefixURLReg, '') : '';
                    }
				}

				// Enable/ disable Mix Pannel when cookies for google analytics is set
				$rootScope.$on('util.cookieWall.event', function (event, value) { 
					spMixPanel.init();
				})

				//Add OS type class
				if (Util.isIos()) {
					$rootScope.bodyClass.osType = 'ios';
				}

				function _validateRoute(event, route, params) {
					function _prevent() {
						if (event && route.name !== 'app.home') {
						 	event.preventDefault();
						}

						return $q.resolve().then(function() {
							if ($rootScope.intiDialogClosed) {
								return $q.resolve();
							}

							var defer = $q.defer(),
								listener = $rootScope.$on('initDialog.closed', function() {
									listener();
									defer.resolve();
								});
							return defer.promise;
						}).then(function() {
							if (!$state.current || !$state.current.name) {
								return $state.go('app.home');
							}
						}).then(function () {
							//timeout to make sure the browser history changed
							return $timeout(function() {}, 200);
						});
					}

					if (route) {
						var userLoginData = User.getUserLoginData(),
							stateAccess = route.data && route.data.stateAccess;

						//User already logged in$stateParams
						if (stateAccess === PAGE_ACCESS.NOT_LOGGED_IN && userLoginData) {
							_prevent();
							return;
						}

						// require login first
						if (!userLoginData && (stateAccess === PAGE_ACCESS.USER_VERIFIED ||
							stateAccess === PAGE_ACCESS.ORGANIZATION_ADMIN || stateAccess === PAGE_ACCESS.LOGGED_IN ||
							stateAccess === PAGE_ACCESS.NOT_ORGANIZATION_MEMBER)) {
							_prevent().then(function() {
								return Util.goToLoginDialog(null, null, route.name, JSON.stringify(angular.extend({}, params, {
									loginDialogRetState: undefined,
									loginDialogRetStateParams: undefined
								})));
							});
							return;
						}

						if (stateAccess === PAGE_ACCESS.USER_VERIFIED) {
							var hasNoRetailer = !Config.retailer,
								userHasNoData = hasNoRetailer || Config.retailer.settings.isUserVerificationActive && !User.settings.id;
							if (userLoginData && (userHasNoData || !User.isVerified())) {
								_prevent().then(function() {
									var promise;
									if (hasNoRetailer) {
										promise = Config.waitForInit();
									}
									if (userHasNoData) {
										promise = User.getUserSettings();
									}

									return (promise || UserVerificationDialog.show()).then(function() {
										return !!promise || User.isVerified();
									});
								}).then(function(doContinue) {
									if (doContinue) {
										$state.go(route, params);
									}
								});
								return;
							}

							if (Config.retailer.loyaltyClubDriver && Config.retailer.loyaltyClubDriver.isActive &&
								Config.retailer.loyaltyClubDriver.clientConfig.isRegisterToClubObligation &&
								!userLoginData.loyaltyClubCardId && !Object.values(Cart.lines).find(function (line) {
									return line.type === CART_LINE_TYPES.REGISTER_LOYALTY;
								})) {
								_prevent().then(function() {
									$state.go(Config.retailer.loyaltyClubDriver.clientConfig.extendedLoyaltyClub ? 'app.extendedLoyaltyClub' : 'app.loyaltyClub');
								});

								return;
							}
						}

						if (stateAccess === PAGE_ACCESS.ORGANIZATION_ADMIN &&
							userLoginData && userLoginData.organizationRoleId !== ORGANIZATIONS_ROLES.ADMIN) {
							_prevent();
							return;
						}

						if (stateAccess === PAGE_ACCESS.NOT_ORGANIZATION_MEMBER &&
							userLoginData && userLoginData.organizationTypeId === ORGANIZATION_TYPES.OBLIGO) {
							_prevent();
							return;
						}

						if (route.data && route.data.validation) {
							$q.resolve().then(function() {
								return $injector.invoke(route.data.validation);
							}).catch(function(redirectTo) {
								$state.go(redirectTo);
							});
						}
					}
				}

				//Check user access level
				$rootScope.$on('$stateChangeStart', function(event, toRoute, params) {
					$rootScope.title = '';
					_validateRoute(event, toRoute, params);
				});

				$rootScope.$on('logout', function() {
					_validateRoute(null, $state.current, $state.params);
				});

				function isPreventBack() {
					var sidenva = $injector.get('$mdSidenav')('sidenav');
					if (sidenva.isOpen()) {
						sidenva.close();
						return true;
					}

					if ($window.onBackButtonBefore && !$window.onBackButtonBefore()) {
						return true;
					}

					if ($rootScope.isSearchAutocompleteShown) {
						return true;
					}

					return angular.isFunction($rootScope.preventHistoryBack) ? $rootScope.preventHistoryBack() !== false : !!$rootScope.preventHistoryBack;
				}

				// Polyfill for history.state in older webkit engines (http://stackoverflow.com/a/17234954/593425)
				if ($window.history && !$window.history.hasOwnProperty('state')) {
					(function(pushState, replaceState) {
						// history.state is always initialised to null
						$window.history.state = null;

						$window.history.pushState = function(state) {
							pushState.apply($window.history, arguments);

							$window.history.state = state;
						};
						$window.history.replaceState = function(state) {
							replaceState.apply(window.history, arguments);

							$window.history.state = state;
						};

						$window.addEventListener('popstate', function(e) {
							$window.history.state = e.state;
						}, true);

					})($window.history.pushState, $window.history.replaceState);
				}

				var stateCount = 0, currentState = 0;

				function onStateChange() {
					//not working in app only in browser
					if (window.cordova !== undefined) {
						return;
					}

					setTimeout(function() {
						try {
							if (!$window.history.state) {
								$window.history.replaceState({statePosition: stateCount++}, null, $window.location.pathname + $window.location.search + $window.location.hash);
								currentState = $window.history.state.statePosition;
							} else if (!('statePosition' in $window.history.state)) {
								$window.history.replaceState(angular.merge({}, $window.history.state, {
									statePosition: stateCount++
								}), null, $window.location.pathname + $window.location.search + $window.location.hash);
								currentState = $window.history.state.statePosition;
							} else {
								stateCount = $window.history.state.statePosition >= stateCount ? $window.history.state.statePosition + 1 : stateCount;
								if ($window.history.state.statePosition < currentState) {
									//back event
									if (isPreventBack()) {
										window.history.go(1);
									}
								}
								currentState = window.history.state.statePosition;
							}
						} catch (e) {
						}
					});
				}

				Retailer.getRetailerSettings().then(function(data) {
					$rootScope.retailerName = data.settings.appName || data.title || data.name;
					$rootScope.metaTitle = data.metaTitle;
					$rootScope.defaultDescription = data.metaDescription;
					$rootScope.defaultKeywords = data.keywords;
					$rootScope.disclaimer = data.settings.disclaimer;
					$rootScope.coupons = data.allowCoupons;
					$rootScope.notIncludeSpecials = data.notIncludeSpecials;
					$rootScope.giftCard = data.allowGiftCardsInCheckout;
					$rootScope.retailerPhone = data.contactPhone;
					$rootScope.retailerLogo = data.mobileLogoUrl || data.logoUrl;
					$rootScope.logoAccessibilityText = data.settings.mobileLogoAccessibilityText;

					spFilters.unitNames.defaultNames = data.defaultWeightUnitNames;
					spFilters.retailer.isRegularPriceWithTax = data.isRegularPriceWithTax;
					spFilters.retailer.includeTaxInPrice = data.includeTaxInPrice;
					spFilters.retailer.currencySymbol = data.currencySymbol;
					spFilters.retailer.currencyMinorUnit = data.currencyMinorUnit;
					spFilters.retailer.priceRoundModeId = data.priceRoundModeId;

					$rootScope.defaultWeighableUnit = SP_UNITS.BY_NAME[data.defaultWeightUnitNames[Config.retailerLanguageId].toLowerCase()];

					if (data.settings.isNewPromotionDesignEnabled === 'true') {
						$rootScope.$on('$locationChangeStart', _hidePromotionTooltip);
						$rootScope.$on('$stateChangeStart', _hidePromotionTooltip);

						function _hidePromotionTooltip() {
							$rootScope.promotionTooltip = null;
						}
					}

					try {
						if (data.settings.specialNameReplacement && typeof data.settings.specialNameReplacement === 'string') {
							data.settings.specialNameReplacement = JSON.parse(data.settings.specialNameReplacement);
						}

						if (data.settings.creditCardTextReplacement && typeof data.settings.creditCardTextReplacement === 'string') {
							data.settings.creditCardTextReplacement = JSON.parse(data.settings.creditCardTextReplacement);
						}

						if (data.settings.specificAddressDetails && typeof data.settings.specificAddressDetails === 'string') {
							data.settings.specificAddressDetails = JSON.parse(data.settings.specificAddressDetails);
						}

						if (data.settings.joinConnectTextReplacement && typeof data.settings.joinConnectTextReplacement === 'string') {
							data.settings.joinConnectTextReplacement = JSON.parse(data.settings.joinConnectTextReplacement);
						}

						if (data.settings.cvvTextReplacement && typeof data.settings.cvvTextReplacement === 'string') {
							data.settings.cvvTextReplacement = JSON.parse(data.settings.cvvTextReplacement);
						}

						if (data.settings.cvvDescriptionTextReplacement && typeof data.settings.cvvDescriptionTextReplacement === 'string') {
							data.settings.cvvDescriptionTextReplacement = JSON.parse(data.settings.cvvDescriptionTextReplacement);
						}

						if (data.settings.disclaimerOnProductPages && typeof data.settings.disclaimerOnProductPages === 'string') {
							data.settings.disclaimerOnProductPages = JSON.parse(data.settings.disclaimerOnProductPages);
						}

						if (data.settings.disclaimerOnCartPages && typeof data.settings.disclaimerOnCartPages === 'string') {
							data.settings.disclaimerOnCartPages = JSON.parse(data.settings.disclaimerOnCartPages);
						}

						
						if (data.settings.changeTimeSlot && typeof data.settings.changeTimeSlot === 'string') {
							data.settings.changeTimeSlot = JSON.parse(data.settings.changeTimeSlot);
						}
					} catch (e) {
						console.log(e);
					}

					data.settings.useDeliveryAddressAsBilling = data.settings.useDeliveryAddressAsBilling === 'true';
					data.settings.addSpecificAddressDetails = data.settings.addSpecificAddressDetails === 'true';
					data.settings.enableDefaultCountry = data.settings.enableDefaultCountry === 'true';
					data.settings.autocompleteAddressField = data.settings.autocompleteAddressField === 'true';
					data.settings.showPriceWithoutBottleDeposit = data.settings.showPriceWithoutBottleDeposit === 'true';
					data.settings.showCouponsSpecials = data.settings.showCouponsSpecials === 'true';
					data.settings.enablePersonalReplacement = data.settings.enablePersonalReplacement === 'true';
				});

				function _getMetaDataPromise(state, key) {
					return $q.resolve().then(function() {
						var val = state.data.metaTags && state.data.metaTags[key] ? state.data.metaTags[key] : '';
						if (angular.isArray(val) || angular.isFunction(val)) {
							val = $injector.invoke(val);
						}
						return val;
					});
				}

				function _setMetaTags(state) {
					$q.all([
						Retailer.getRetailerSettings(),
						_getMetaDataPromise(state, 'title'),
						_getMetaDataPromise(state, 'description'),
						_getMetaDataPromise(state, 'keywords')
					]).then(function(results) {
						$rootScope.metaTags = $rootScope.metaTags || {};
						$rootScope.metaTags.currentDescription = results[2];
						$rootScope.metaTags.currentKeywords = results[3];
						$rootScope.metaTags.canonical = (Config.retailer.domain && $location.$$path) ? 'https://' + Config.retailer.domain + $location.$$path : '';

						if(window.cordova === undefined && results[0] && results[0].settings && results[0].settings.shouldUseMultiLanguageURLPath === 'true') {
							var langPrefixURLReg =  /^\/(he|en|es|fr|ru|ar)\//;
							if(!langPrefixURLReg.test(location.pathname)){
	                            var defaultCulture = Config.retailer.culture;
	                            $rootScope.metaTags.canonical = (Config.retailer.domain && $location.$$path) ? 'https://' + Config.retailer.domain + '/' +defaultCulture+ $location.$$path : '';
	                        }
							var segments = $location.$$path.split('/').filter(Boolean);
							if(segments && segments.length) {
								var urlLocale = segments[0];

								$rootScope.metaTags.alternateUrls = Config.languages.filter(function(lang) {
									return lang.culture !== urlLocale
								}).map(function(locale) {
									segments[0] = locale.culture
									return {
										url: 'https://' + Config.retailer.domain + '/' + segments.join('/'),
										culture: locale.culture,

									}
								})
							}
						}
						
						var retailerTitle = results[0].metaTitle || results[0].title || results[0].settings.appName || results[0].name,
							stateTitle = results[1],
							oldValue = $rootScope.metaTags.currentTitle;

						$rootScope.metaTags.currentTitle = ($filter('translate')(stateTitle)) + (stateTitle ? ' | ' : '') + retailerTitle;
						$rootScope.$emit('metaTags.currentTitle.change', {
							value: $rootScope.metaTags.currentTitle,
							oldValue: oldValue
						});
					});
				}

				//Add page class to body tag & meta tags to head
				$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState) {
					onStateChange();
					toState.data = toState.data || {};
					$rootScope.bodyClass.currentPage = toState.data.bodyClass;
					_setMetaTags(toState);

					$timeout(function () {
						AdobeAnalytics.newPageEvent();
						if (fromState && (!toState || !toState.name || !toState.name.includes("app.cart.checkout"))) {
							document.querySelector('#main').focus();
						}
					}, 500);
				});

				$rootScope.$on('$viewContentLoaded', function() {
					$timeout(function() {
						var main = document.getElementById('main'),
							lastScrollPos = 0;
						// $rootScope.disableClearCart = true;
						// $timeout(function () {
						//     $rootScope.disableClearCart = false;
						// }, 3000);
						if (main) {
							main.onscroll = function() {
								main = document.getElementById('main');
								if (lastScrollPos < main.scrollTop) {
									if ((main.scrollHeight - (main.scrollTop + main.offsetHeight)) < 500) {
										$rootScope.$broadcast('vertical-scroll-end');
									}
								}

								lastScrollPos = main.scrollTop;
							};
						}
					}, 0);
				});

				function noInternetConnections() {
					if ($window.navigator.connection && $window.navigator.connection.type === 'none') {
						$window.navigator.notification.alert(
							$filter('translate')('No Internet connection'),
							noInternetConnections,
							$filter('translate')('Please enable Internet connection and retry'),
							$filter('translate')('Retry')
						);
					} else {
						Util.reload();
					}
				}

				$window.onBackButton = function(event) {
                    $rootScope.$emit('historyBack');
                    setTimeout(function() {
						event && event.preventDefault();

						if ($window.cordova !== undefined && (!$window.history.length || (angular.element(document.body).hasClass('home') && !mDesign.isCurrentUrlADialog()))) {
							return setTimeout(function() { //Exit app if there is nothing more in history or it's on home page without dialog on top
								$window.navigator.app.exitApp();
							}, 1000);
						}

						if (!isPreventBack()) {
							$window.history.back();
						}
					}, 0);
				};

				CordovaService.ready.then(function() {
					setTimeout(function() {
						try {
							navigator.splashscreen.hide();
						} catch (e) {
							console.error(e);
						}
						try {
							cordova.exec(null, null, 'SplashScreen', 'hide', []);
						} catch (e) {
							console.error(e);
						}
					}, 500);
					$rootScope.hasCordova = !!window.cordova;

					if (window.plugins && window.plugins.appsFlyer && Config.appsFlyerId) {
						var options = {
							devKey: Config.appsFlyerId  // AppsFlyer devKey
						};
						$q.resolve().then(function() {
							if (Util.isIos()) {
								return Retailer.getRetailerSettings().then(function(details) {
									options.appId = details.settings.appleAppId;  // ios app id in app store
									options.waitForATTUserAuthorization = 10;
								});
							}
						}).then(function() {
							window.plugins.appsFlyer.initSdk(options);
						});
					}

					function _goToStateAfterDialogCloses(toStateName, toStateParams) {
						if (mDesign.activeDefer) {
							var listener = $rootScope.$on('mDesign.closed', function() {
								$state.go(toStateName, toStateParams);
								listener();
							});
						} else {
							$state.go(toStateName, toStateParams);
						}
					}

					/*
					 Custom backbutton actions for Android
					 */
					document.addEventListener('backbutton', window.onBackButton, false);

					//Internet off-line event
					document.addEventListener('offline', function() {
						noInternetConnections();
					}, false);

					document.addEventListener('DOMContentLoaded', function() {
						document.body.style.height = screen.availHeight + 'px';
					});

					// Set cordova-plugin-apprate
					if (!Config.hubRetailer || !Config.hubRetailer.id) {
                        Retailer.getRetailerSettings().then(function(details) {
                            AppRate.setPreferences({
								usesUntilPrompt: 3,
								promptAgainForEachNewVersion: false,
								simpleMode: true,
								reviewType: {
									ios: 'AppStoreReview',
									android: 'InAppBrowser'
								},
								storeAppURL: {
									ios: details.settings.appleAppId,
									android: 'market://details?id=' + details.settings.androidBundleId,
								}
                            });

							AppRate.promptForRating(false); // only after usesUntilPrompt
                        });
					}

					$window.open = window.open = $window.cordova.InAppBrowser.open;
				});

				/*Android mobile web fix for over scroll when keyboard shown*/
				var keyboardShown,
					lastResizeScreenHeight = $window.screen.height,
					lastResizeInnerHeight = $window.innerHeight;
				window.addEventListener('resize', _onResize);

				function _onResize() {
					//if the resize didn't happen because of rotation and is android web (not crodova app)
					if (lastResizeScreenHeight === $window.screen.height && !$window.cordova && !(/iphone|ipad|ipod/.test(navigator.userAgent))) {
						//if the keyboard is already open
						//and the body's height is higher than before (if the height got shorter it isn't closing the keyboard)
						//- it means that the keyboard was closed (probably)
						if (keyboardShown && lastResizeInnerHeight < $window.innerHeight) {
							$rootScope.$emit('keyboard.close');
						}
						//if the keyboard is not open
						//and the body's height is shorter than before (if the height got higher it isn't opening the keyboard)
						//and there is an input or a textarea in focus
						//- it means that the keyboard was opened
						else if (!keyboardShown && lastResizeInnerHeight > $window.innerHeight &&
							($window.document.activeElement.tagName.toLowerCase() === 'input' ||
								$window.document.activeElement.tagName.toLowerCase() === 'textarea')
						) {
							$rootScope.$emit('keyboard.open');
						}
					}

					lastResizeScreenHeight = $window.screen.height;
					lastResizeInnerHeight = $window.innerHeight;
				}

				$rootScope.$on('keyboard.open', onKeyboardShow);
				$rootScope.$on('keyboard.close', onKeyboardHide);

				function onKeyboardShow() {
					keyboardShown = true;

					angular.element(document.querySelector('body')).addClass('keyboard-shown');
					setTimeout(function() {
						var activeElement = $window.document.activeElement;
						if (!activeElement) return;

						var tagName = activeElement.tagName.toLowerCase();
						if (tagName !== 'input' && tagName !== 'textarea') return;

						var container = activeElement,
							top = activeElement.offsetTop,
							lastOffset;
						while (container && !_isScrollable(container)) {
							if (container.offsetParent && container.offsetParent === container.parentElement) {
								lastOffset = container.offsetParent;
								top += lastOffset.offsetTop;
							}

							container = container.parentElement;
						}

						if (container) {
							angular.element(container).scrollTop(top - container.clientHeight / 2, 100);
						}
					}, 100);
				}

				function _isScrollable(element) {
					var overflowY = window.getComputedStyle(element).overflowY;
					return overflowY === 'auto' || overflowY === 'scroll';
				}

				function onKeyboardHide() {
					keyboardShown = false;
					angular.element(document.querySelector('body')).removeClass('keyboard-shown');
				}

				$window.addEventListener('message', function(event) {
					if (event.data.name) {
						$rootScope.$emit(event.data.name, event.data);
					} else {
						$rootScope.$emit('message', event.data);
					}
					$rootScope.$evalAsync();

				}, false);

                SpRecipeService.initRecipeListener();

				$rootScope.$on('spApi.error', function(event, data) {
					if (data.apiOptions.hideError) {
						return;
					}

					// Special handeling
					if(data.response && data.response.code === 'deliveryTimeNotAvailable') {
						return;
					}

					//== we don't want to display application errors when there is an organization without branches
					var userLoginData = User.getUserLoginData();
					if (userLoginData && userLoginData.branchKeyOrganization && (userLoginData.organizationBranches || []).length === 0) {
						return;
					}

					if ($rootScope.isSSOLoginInProgress) {
						$rootScope.isSSOLoginInProgress = false;
						return;
					}

					if (data.response && data.response.errors) {
						var errorMsg = [];
						angular.forEach(data.response.errors, function(error, key) {
							//in case got an array of errors with msg
							if (error.msg) {
								return errorMsg.push(error.msg);
							}

							//in case it has no msg and it is not a json schema error (not an array)
							//json schema format: error: {body|params|...: [{property: 'name', messages: ['message']}]}
							if (!angular.isArray(error)) {
								return errorMsg.push(key + ' - ' + JSON.stringify(error));
							}

							//in case it is an array - which means it is probably a json schema errors
							angular.forEach(error, function(jsonSchemaError) {
								//in case it is an array but the array's inner object is not a json schema object
								if (!jsonSchemaError.property || !jsonSchemaError.messages) {
									return errorMsg.push(JSON.stringify(jsonSchemaError));
								}

								//in case it is a json schema object
								errorMsg.push(jsonSchemaError.property + ' - ' + jsonSchemaError.messages.join(', '));
							});
						});
						mDesign.alert(errorMsg.join('\n'));
						return;
					}

					if (data.statusCode === 401 || data.response && data.response.error && data.response.error === 'Token not active') {
						User.setUserLoginData(null);
					} else if (data.response && (data.response.error === 'Missing premise or street number' || data.response.error === 'Branch not found')) {
						return;
					}

					if (data.response && data.response.missingProducts && data.response.missingProducts.length) {
						mDesign.alert(data.response.error || ('Unknown error (' + data.statusCode + ')'), function() {
							var err = $filter('translate')('You have some missing products in your cart') + ': ',
								productsText = '';

							data.response.missingProducts.forEach(function(prod) {
								var toAdd = '',
									namesKeys = prod.names ? Object.keys(prod.names) : null;
								if (namesKeys && namesKeys.length) {
									toAdd += prod.names[Config.language.id] ? prod.names[Config.language.id].short : prod.names[namesKeys[0]].short;
								} else {
									toAdd += prod.title;
								}
								productsText += productsText ? ', ' + toAdd : toAdd;
							});
							mDesign.alert(err + productsText);
						});
					} else if (data.response && data.response.code === 'loginAccountNotActivated') {
                        mDesign.dialog({
                            templateUrl: 'views/templates/activation-email-resend-dialog.html',
                            controller: ['$rootScope', '$scope', function($rootScope, $scope) {
                                $scope.message = data.response.error;
                                $scope.hide = mDesign.hide;
                                $scope.resend = _resendActivationLink;
                            }]
                        });
                    } else if (data.response && data.response.code === 'registrationAccountNotActivated' && Config.retailer && Config.retailer.settings) {
						mDesign.dialog({
							templateUrl: 'views/templates/account-not-activated-dialog.html',
							controller: ['$rootScope', '$scope', function($rootScope, $scope) {
								$scope.hide = mDesign.hide;
								$scope.resend = _resendActivationLink;
								$scope.userEmailVerificationExpirationMinutes = Config.retailer.settings.userEmailVerificationExpirationMinutes;
							}]
						});
                    } else if (data.response && data.response.error === 'migratedUser' && Config.retailer.settings.isUserMigrationEnabled === 'true') {
                        Util.goToUserMigrationDialog($rootScope.emailForReActivation);
                    } else {
						//== Display error popup on all regular API errors
						if (!data.httpOptions.url.includes('/generate-otp-code') && !data.httpOptions.url.includes('/sessions/token')) {
							if (!data.response || (!data.response.error && !!data.response.errorCode)) {
								data.response = data.response || {};
								data.response.errorCode = 'ST276'; // General error
							}

							var errorMessage = 'api_error_message',
								error;

							if (data.response && data.response.errorCode && angular.isString(data.response.errorCode)) {
								if (data.response.informative) {
									var errorOriginalMessage = $filter('translate')(data.response.error);
									error = ($filter('translate')(errorMessage)).replace('{error_code}', data.response.errorCode).replace('{error_message}', ' - ' + errorOriginalMessage);
								} else {
									error = ($filter('translate')(errorMessage)).replace('{error_code}', data.response.errorCode).replace('{error_message}', '');
								}
							} else {
								error = data.response && data.response.error || ('Unknown error (' + data.statusCode + ')');
							}

							mDesign.alert(error);
						}
					}
				});

				function _resendActivationLink() {
					User.resendActivationLink($rootScope.emailForReActivation).then(function() {
						mDesign.hide();
						mDesign.alert($filter('translate')('A verification email will be sent to your email box. The link is valid for {link_ttl} minutes').replace('{link_ttl}', Config.retailer.settings.userEmailVerificationExpirationMinutes));
					}).catch(function() {
						mDesign.hide();
						//== Alert will be displayed by automatically by http response handle
					});
				}

				$rootScope.$on('spApi.response', function(event, data) {
					if (data.statusCode != 401 || data.response && data.response.error && data.response.error !== 'Token not active') {
						return;
					}
					Util.clearAllStorage(1000);
				});

				if (!Config.hubRetailer || !Config.hubRetailer.id) {
					DialogAvailability.requestDialog(DialogAvailability.PERMISSIONS.NOTIFICATIONS)
						.then(function() {
							return Retailer.getRetailerSettings();
						}).then(function(retailerData) {
							Notifications.init({
								serviceId: MOBILE_SERVICE_ID,
								senderId: '1022690898150',
								firebaseApiKey: 'AIzaSyBkR5w1OppZwWNjkRF_LVwOZuQV59q-Hf0',
								firebaseServiceWorkerUrl: '/firebase-service-workers/3.9.js', //it's a file not url
								androidIconColor: retailerData.androidNotificationIconColor || retailerData.settings.siteColor && ('#' + retailerData.settings.siteColor),
							});
						});
				}
				if (!!searchParams.inviteId && !!searchParams.promotionId) {
					$window.localStorage.setItem('inviteId', searchParams.inviteId);
					$window.localStorage.setItem('promotionId', searchParams.promotionId);

					$location.search('inviteId', null);
					$location.search('promotionId', null);

					Retailer.getRetailerSettings().then(function(configuration) {
						mDesign.dialog({
							dontPreventRouteChange: true,
							templateUrl: 'views/templates/promotion.html',
							controller: ['$scope', function($scope) {
								$scope.hide = mDesign.hide;
								$scope.promotion = configuration.promotion;
							}]
						});
					});
				}

				Notifications.setRetailerId(Config.retailerId);
				_listenNotification($rootScope,$state);
				// if (!e.foreground && e.additionalData.specialId) {
				//     _goToStateAfterDialogCloses('app.specials.special', {ssid: e.additionalData.specialId});
				// }
				// if (!e.foreground && e.additionalData.orderId) {
				//     _goToStateAfterDialogCloses('app.ordersHistory.order', {oid: e.additionalData.orderId});
				// }

				if (!!searchParams.resetPasswordCode && !!searchParams.email) {
					delete searchParams.loginOrRegister; // SP-2430
					$state.go('app.forgottenPassword', searchParams);
				}

				if (Config.hubRetailer && searchParams.hubId && Number(searchParams.hubId) === Config.hubRetailer.id) {
					// boolean to hide the hub header, backwards for old hub mobile apps
					$rootScope.backwardsHubId = true;

					HubService.getData(Config.hubRetailer.id).then(function(hubData) {
						if (window.top === window.self) {
							// TODO for general backward purposes, until both mobile and hub frontend are released
							Retailer.getRetailerSettings().then(function(details) {
								window.location.href = 'https://' + hubData.domainName + '/' + details.id +
									'/' + details.name + ';path=' + encodeURIComponent(window.location.pathname) +
									encodeURIComponent(window.location.search);
							});
						} else {
							// for hub old mobile app backward, leave after release
							$rootScope.$on('metaTags.currentTitle.change', function(event, data) {
								_postMessage({
									name: 'retailerTitleChange',
									newValue: data.value,
									oldValue: data.oldValue
								}, hubData.domainName);
							});

							$rootScope.$on('$stateChangeSuccess', function() {
								_postMessage({
									name: 'retailerStateChange',
									isHome: $state.includes(STATE_NAMES.HOME)
								}, hubData.domainName);
							});

							$rootScope.$on('$locationChangeSuccess', function(event, toUrl, fromUrl) {
								_postMessage({
									name: 'retailerPathChange',
									toUrl: toUrl,
									fromUrl: fromUrl
								}, hubData.domainName);
							});
						}
					});
				}

				_handleLoyaltyMember()

			function _handleLoyaltyMember(){
				if (Config.retailer) {
					if (!!User.getUserLoginData()) {
						$rootScope.refreshObligo = true;
						User.getUserSettings().then(function (userSettings) {
							if (Config.retailer.settings.isExternalLoyaltyClubRegistrationEnabled === 'true' && !userSettings.foreignId) {
								Util.goToLoyaltyIFrameDialog(Config.retailer.settings.externalLoyaltyClubRegistrationUrl, userSettings);
							} else if (Config.retailer.loyaltyClubDriver.isActive) {
								if (userSettings.loyaltyClubs && userSettings.loyaltyClubs.length) {
									Util.syncExpiredLoyaltyUser(userSettings).then(function (result) {
										if(result && result.isExpired) {
											return
										}

										Util.loyaltyClubAutoRenew(userSettings);
									})
								} else {
									Util.loyaltyClubAutoConnect();

									if (Config.retailer.loyaltyClubDriver.clientConfig.isRegisterToClubObligation) {
										$state.go(Config.retailer.loyaltyClubDriver.clientConfig.extendedLoyaltyClub ? 'app.extendedLoyaltyClub' : 'app.loyaltyClub', {showRegistrationForm: true});
									}
								}
							}
						});
					}
				}
			}

				var migration = ($window.localStorage.getItem('migration') && JSON.parse(localStorage.getItem('migration'))) || {};
				if (!User.getUserLoginData() && Config.retailer && Config.retailer.settings.isUserMigrationEnabled === 'true') { 
					if (!migration.hide && (!migration.initialTime || (new Date(migration.initialTime).getTime() + 300000) < new Date().getTime())) {
					    $timeout(function () {
                            Util.goToUserMigrationDialog();
                        }, 500);
                    }
				}

				Config.waitForInit().then(function(){
					Config.retailer.loyaltyClubs = {};
					angular.forEach((Config.retailer.loyaltyClubDrivers || []), function (driver) {
						driver.clientConfig.pointsDecimalRound = driver.clientConfig.pointsDecimalRound || 1;
						if(driver.isActive){
							for(var key in driver.clientConfig.loyaltyClubs){
								Config.retailer.loyaltyClubs[key] = driver.clientConfig.loyaltyClubs[key];
							  }
						}
					});
				})

				// when no version is set into the sp object, it is a local serve or a cordova app build
				if (window.sp.version) {
					_watchVersion(api, Util, $document, MOBILE_SERVICE_ID);
				}

				ExternalCheckout.init();
				DeepLinkHandler.init();

				$rootScope.$on('login', function() {
					_handleLoyaltyMember();
				});
			}]);

	function _watchVersion(api, Util, $document, MOBILE_SERVICE_ID) {
		var windowElement = angular.element(window);

		// compare version when window gets shown, in case interval was missed
		var vendorPrefix,
			isVisible = false;
		// Determine if a vendor prefix is required to utilize the Page Visibility API
		if ('hidden' in $document) {
			vendorPrefix = '';
		} else {
			angular.forEach(['moz', 'webkit', 'ms'], function (prefix) {
				if ((prefix + 'Hidden') in $document[0]) {
					vendorPrefix = prefix;
				}
			});
		}
		if (vendorPrefix !== undefined) {
			$document[0].addEventListener(vendorPrefix + 'visibilitychange', function() {
				if (this[vendorPrefix ? vendorPrefix + 'Hidden' : 'hidden']) {
					isVisible = false;
				} else {
					_compareVersion();
					isVisible = true;
				}
			});
		}

		// compare version when window gets in focus, in case interval was missed
		var isWindowBlur = false;
		windowElement.on('blur', function() { isWindowBlur = true; });
		windowElement.on('focus', function() {
			if (isWindowBlur && !isVisible) {
				_compareVersion();
			}

			isWindowBlur = false;
		});

		var compareInterval = 1000 * 60 * 60  * 6/*6 hours*/;

		// compare version every interval
		setInterval(_compareVersion, compareInterval);

		function _getNextVersionCompare() {
			return new Date(new Date().getTime() + compareInterval);
		}

		var nextVersionCompare = _getNextVersionCompare();
		function _compareVersion() {
			var now = new Date();
			// do not compare while compared in the last interval, to prevent endless reloads when cache did not reset
			if (now < nextVersionCompare) {
				return;
			}

			nextVersionCompare = now;
			api.request({
				method: 'GET',
				url: '/v2/services/' + MOBILE_SERVICE_ID + '/versions/active'
			}).then(function(resp) {
				if (resp.version !== window.sp.version) {
					window.sp.version = resp.version;
					Util.reload();
				}
			});
		}
	}

	function _postMessage(data, allowedDomain) {
		// has port only when debugging
		if (window.location.port) {
			window.parent.postMessage(data, window.location.origin);
		} else {
			window.parent.postMessage(data, 'https://' + allowedDomain);
		}
	}

	function _injectHotjarScript(retailerSettings, MOBILE_SERVICE_ID, DEBUGGING_PROVIDERS) {
		if (retailerSettings.debuggingProviders &&
				retailerSettings.debuggingProviders[DEBUGGING_PROVIDERS.HOTJAR] &&
				retailerSettings.debuggingProviders[DEBUGGING_PROVIDERS.HOTJAR][MOBILE_SERVICE_ID] &&
				retailerSettings.debuggingProviders[DEBUGGING_PROVIDERS.HOTJAR][MOBILE_SERVICE_ID].isActive &&
				retailerSettings.debuggingProviders[DEBUGGING_PROVIDERS.HOTJAR][MOBILE_SERVICE_ID].extraData) {
			var scriptTag = document.createElement('script');
			scriptTag.text = '(function(h,o,t,j,a,r){' +
				'h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};' +
				'h._hjSettings={hjid: ' + retailerSettings.debuggingProviders[DEBUGGING_PROVIDERS.HOTJAR][MOBILE_SERVICE_ID].extraData + ',hjsv:6};' +
				'a=o.getElementsByTagName(\'head\')[0];' +
				'r=o.createElement(\'script\');r.async=1;' +
				'r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;' +
				'a.appendChild(r);' +
				'})(window,document,\'https://static.hotjar.com/c/hotjar-\',\'.js?sv=\')';
			document.head.append(scriptTag);
		}
	}

	function _listenNotification($rootScope,$state){
		var MODULE_NAME = 'spNotifications',
			NOTIFICATION_ACCEPTED_EVENT = MODULE_NAME + '.notificationAccepted';
		$rootScope.$on(NOTIFICATION_ACCEPTED_EVENT,function(event, data){
			if (data && data.additionalData && data.additionalData.specialId && !data.foreground) {
				$state.go('app.specials.special', {ssid: data.additionalData.specialId});
			}
		})
	}
})(angular);
