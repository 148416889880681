(function (angular) {
  "use strict";

  angular
    .module("mobilezuz")
    .controller("LaunchEditOrderCtrl", [
      "$scope",
      "Config",
      "$rootScope",
      "Util",
      "$filter",
      "$sce",
      "Orders",
      "BRANCH_DELIVERY_TYPES",
      controller,
    ]);
  function controller(
    $scope,
    Config,
    $rootScope,
    Util,
    $filter,
    $sce,
    Orders,
    BRANCH_DELIVERY_TYPES
  ) {
    var ctrl = this;
    var dateFilter = $filter("date");
    var _translate = $filter("translate");
    var updateOrderV2Ctrl = $scope.updateOrderV2Ctrl;

    /** @type {OrderUpdatePopupDisplay} */
    ctrl.popup = null;
    ctrl.isUpdateSlotDisabled = false;

    $scope.lang = $rootScope.config.language.culture;

    angular.extend(ctrl, {
      onClickCancel: onClickCancel,
      onClickUpdateTimeslot: onClickUpdateTimeslot,
      onClickUpdateOrderItems: onClickUpdateOrderItems,
    });

    // ----------------------------------

    _init();

    function _init() {
      Orders.getOrderDetails(updateOrderV2Ctrl.orderId).then(initData);
      _bindWindowClickCancelEditPopup();
    }

    /**
     * @param {Order} order
     */
    function initData(order) {
      ctrl.isUpdateSlotDisabled = !Util.checkCanUpdateTimeSlot(order);

      ctrl.popup = initOrderUpdatePopup(Config.retailer.settings, order);
    }

    /**
     * @param {Order} order
     * @return {KeyValParam[]}
     */
    function getAllTextParam(order) {
      var lastTimeUpdate = Util.calculateLastUpdateOrderDateTime(order.shippingTimeFrom);

      var allParams = [
        {
          key: "{delivery method}",
          value: _translate("delivery_type_" + order.branchDeliveryTypeId).toLowerCase(),
        },

        {
          key: "{time calculate}",
          value: Util.getTranslatedFullDateTime(lastTimeUpdate),
        },
      ];

      return allParams;
    }

    /**
     * Open in dialog -> assume can't change language half-way through
     *
     * @param {RetailerSettings} retailerSettings
     * @param {Order} order
     * @return {OrderUpdatePopupDisplay}
     */
    function initOrderUpdatePopup(retailerSettings, order) {
      /** @type {OrderUpdatePopup} */
      var updateOrderPopup = angular.copy(retailerSettings.changeTimeSlot.orderUpdatePopup);
      var allParams = getAllTextParam(order);

      var updateSlotBtn =
        order.branchDeliveryTypeId === BRANCH_DELIVERY_TYPES.DELIVERY
          ? updateOrderPopup.updateDeliveryBtn
          : updateOrderPopup.updatePickupBtn;

      var deliveryType =
        order.branchDeliveryTypeId === BRANCH_DELIVERY_TYPES.DELIVERY ? "delivery" : "pickup";

      var updateSlotDefKey = "update_order_popup_" + deliveryType + "_time_def";

      if (!updateOrderPopup.icon) {
        updateOrderPopup.icon = "https://d226b0iufwcjmj.cloudfront.net/retailers/1214/retailer-change-time-slot/1728033043299-0.685248695047215.png";
      }

      if (!updateOrderPopup.updateOrderBtn.icon) {
        updateOrderPopup.updateOrderBtn.icon = "https://d226b0iufwcjmj.cloudfront.net/backend/icons/change-delivery-window/bottle.svg";
      }

      if (!updateSlotBtn.icon) {
        updateSlotBtn.icon = order.branchDeliveryTypeId === BRANCH_DELIVERY_TYPES.DELIVERY ? "https://d226b0iufwcjmj.cloudfront.net/backend/icons/change-delivery-window/delivery-times.svg" : "https://d226b0iufwcjmj.cloudfront.net/backend/icons/change-delivery-window/vector.svg";
      }

      /** @type {OrderUpdatePopupDisplay} */
      var popupDisplay = {
        icon: updateOrderPopup.icon,

        title: Util.getTextByLangAndReplaceParams(
          updateOrderPopup.title,
          "update_order_popup_title_def",
          allParams
        ),

        latestTimeToUpdateText: Util.getTextByLangAndReplaceParams(
          updateOrderPopup.latestTimeToUpdateText,
          "update_order_popup_lastest_time_def",
          allParams
        ),

        cancelOrderText: initCancelOrderText(updateOrderPopup.cancelOrderText),

        updateOrderBtn: initConfigButtonDisplay(
          updateOrderPopup.updateOrderBtn,
          "update_order_popup_order_item_def"
        ),

        updateSlotBtn: initConfigButtonDisplay(
          updateSlotBtn,
          updateSlotDefKey,
          allParams
        ),

        errorMessage: Util.getTextByLangAndReplaceParams(
          {},
          "update_order_popup_err_cant_update_time",
          allParams
        ),
      };

      return popupDisplay;
    }

    /**
     * @param {ConfigButton} configButton
     * @param {string} defTxtKey
     * @param {KeyValParam[]=} keyValParams
     * @return {ConfigButtonDisplay}
     */
    function initConfigButtonDisplay(configButton, defTxtKey, keyValParams) {
      var text = Util.getTextByLangAndReplaceParams(configButton.text, defTxtKey, keyValParams);

      return {
        text: text,
        icon: configButton.icon,
      };
    }

    /**
     * @param {ConfigText} text
     * @return {string}
     */
    function initCancelOrderText(text) {
      var params = [
        {
          key: "{cancel order}",
          value: [
            '<button class="cancel-btn no-design" onclick="bindWindowClickCancelEditPopup()">',
            _translate("cancel order"),
            "</button>",
          ].join(""),
        },
      ];

      var newText = Util.getTextByLangAndReplaceParams(
        text,
        "update_order_popup_cancel_def",
        params
      );

      return $sce.trustAsHtml(newText);
    }

    function onClickCancel() {
      updateOrderV2Ctrl.close({ shouldKeepEditMode: true });
      return Orders.getOrderDetails(updateOrderV2Ctrl.orderId).then(function (order) {
        return Orders.cancelOrder(order);
      });
    }

    function onClickUpdateOrderItems() {
      updateOrderV2Ctrl.close({ shouldKeepEditMode: true });
      Orders.enableEditOrder(updateOrderV2Ctrl.orderId);
    }

    function onClickUpdateTimeslot() {
      updateOrderV2Ctrl.enableForceShowLoadingDialog();
      updateOrderV2Ctrl.next();
    }

    function _bindWindowClickCancelEditPopup() {
      window.bindWindowClickCancelEditPopup = function () {
        onClickCancel();
      };
    }
  }
})(angular);
