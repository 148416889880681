(function (angular) {
    angular.module('mobilezuz')
        .service('mDesign', ['$rootScope', '$mdDialog', '$filter', '$q', '$timeout', '$interpolate', '$location', 'SP_URL_DIALOG_QUERY_PARAMS',
            function ($rootScope, $mdDialog, $filter, $q, $timeout, $interpolate, $location, SP_URL_DIALOG_QUERY_PARAMS) {
                var self = this,
                    dialogQueue = [],
                    _shownDialogs = [],
                    _ignoreNextUrlChange = 0;

                self.activeDialog = null;

                self.cancel = $mdDialog.cancel;
                self.hide = $mdDialog.hide;
                self.dialog = showDialog;
                self.alert = showAlert;
                self.confirm = showConfirm;
                self.isCurrentUrlADialog = isCurrentUrlADialog;
                self.ignoreNextUrlChange = ignoreNextUrlChange;

                function showDialog(options, defer) {
                    if (!!self.activeDialog) {
                        if (_isDialogMultiple(self.activeDialog.options) && _isDialogMultiple(options) === undefined) {
                            if (angular.isFunction(options.multiple)) {
                                options.multiple(true);
                            } else {
                                options.multiple = true;
                            }
                        }

                        if (!_isDialogMultiple(options)) {
                            var inQueueDefer = $q.defer();
                            dialogQueue.push({defer: inQueueDefer, options: options});
                            return inQueueDefer.promise;
                        }
                    }

                    defer = defer || $q.defer();
                    self.activeDialog = {options: options, defer: defer};

                    if (!options.dontPreventRouteChange) {
                        var listener = $rootScope.$on('$locationChangeStart', function (event, toUrl, fromUrl) {
                            //for some reason the event is sometimes fired even when the url did not change
                            //it caused the dialog to close for no reason

                            //.split('#')[0] - remove anchors as well - for check (don't close if anchors are different)
                            if (toUrl.split('#')[0] === fromUrl.split('#')[0]) {
                                return;
                            }

                            if (_ignoreNextUrlChange > 0) {
                                _ignoreNextUrlChange--;
                                return;
                            }

                            // product, recipe or login dialogs can be opened above others
                            if (_isUrlDialog(toUrl) || _isUrlDialog(fromUrl)) {
                                return;
                            }

                            event.preventDefault();

                            if (!options.clickOutsideToClose) {
                                return;
                            }

                            _unbindListener(listener);
                            $mdDialog.cancel(new Error('Canceled due to location change'));
                        });
                    }

                    if (angular.isUndefined(options.clickOutsideToClose)) {
                        options.clickOutsideToClose = true;
                    }

                    _shownDialogs.push(self.activeDialog);
                    $rootScope.$emit('mDesign.shown');
                    $mdDialog.show(options).then(function (res) {
                        _unbindListener(listener);
                        defer.resolve(res);
                    }, function (err) {
                        _unbindListener(listener);
                        defer.reject(err);
                    }).finally(function () {
                        self.activeDialog = null;
                        _shownDialogs.splice(_shownDialogs.length - 1, 1);

                        if (_shownDialogs.length) {
                            self.activeDialog = _shownDialogs[_shownDialogs.length - 1];
                        } else if (dialogQueue.length > 0) {
                            return $timeout(function() {
                                showDialog(dialogQueue[0].options, dialogQueue[0].defer);
                                dialogQueue.splice(0, 1);
                            });
                        }

                        $rootScope.$emit('mDesign.closed');
                    });

                    return self.activeDialog.defer.promise;
                }

                function isCurrentUrlADialog() {
                    return  _isUrlDialog($location.url());
                }

                function ignoreNextUrlChange() {
                    _ignoreNextUrlChange++;
                }

                function _isUrlDialog(url) {
                    var search = url.split('?')[1];
                    if(!search) {
                        return false;
                    }

                    return !!Object.keys(SP_URL_DIALOG_QUERY_PARAMS).find(function(key) {
                        return SP_URL_DIALOG_QUERY_PARAMS[key].find(function(paramName) {
                            return search.indexOf(paramName) > -1;
                        });
                    });
                }

                function showAlert(options, _event, callback) {
                    if (!callback && angular.isFunction(_event)) {
                        callback = _event;
                        _event = null;
                    }

                    if (angular.isString(options)) {
                        options = {content: options};
                    }

                    var confirmation = $filter('translate')(options.buttonText || 'OK');

                    options.content = $filter('translate')(options.content);

                    var alert = $mdDialog.alert().htmlContent($interpolate(options.content)($rootScope)).ok(confirmation).clickOutsideToClose(true);
                    if (_event && _event.target && _event.target instanceof Node) {
                        alert.targetEvent(_event);
                    }
                    if (options.title) {
                        alert.title($filter('translate')(options.title));
                    }

                    return showDialog(alert).finally(callback);
                }

                function showConfirm(options, _event, callback) {
                    if (!callback && angular.isFunction(_event)) {
                        callback = _event;
                        _event = null;
                    }

                    if (typeof options == 'string') {
                        options = {
                            content: options
                        };
                    }

                    options.content = $filter('translate')(options.content);

                    var confirmation = $filter('translate')(options.ok || 'OK'),
                        cancel = $filter('translate')(options.cancel || 'Cancel');

                    var confirm = $mdDialog.confirm().htmlContent(options.content).ok(confirmation).cancel(cancel);
                    if (_event && _event.target && _event.target instanceof Node) {
                        confirm.targetEvent(_event);
                    }

                    showDialog(confirm).then(function () {
                        callback(true);
                    }, function () {
                        callback(false);
                    });
                }

                function _unbindListener(listener) {
                    if (listener) {
                        listener();
                        listener = null;
                    }
                }

                function _isDialogMultiple(dialogOptions) {
                    if(dialogOptions.multiple === undefined) {
                        return undefined;
                    }
                    return dialogOptions.multiple === true || angular.isFunction(dialogOptions.multiple) && dialogOptions._options.multiple;
                }

                //self.bind('clickoutside', function(){
                //    self.hide();
                //});
                //self.clickOutsideToClose = $mdDialog.clickOutsideToClose(true)
                //self.clickOutsideToClose(true);
            }]);
})(angular);