(function (angular) {
    'use script';

    angular.module('spDialogUrlManager', []);
})(angular);(function (angular) {
    'use script';

    angular.module('spDialogUrlManager')
        .constant('SP_URL_DIALOG_QUERY_PARAMS', {
            LOGIN: ['loginOrRegister'],
            PRODUCT: ['catalogProduct', 'product'],
            RECIPE: ['recipe'],
            SPECIAL: ['special'],
            LOYALTY: ['registerLoyalty'],
            EXTERNAL_CART: ['externalCart'],
            EMAIL_VERIFICATION: ['emailVerification'],
            INVITED_USER: ['invitedUser'],
            UPDATE_ORDER_V2: ['updateOrderV2']
        })
        .constant('SP_URL_DIALOG_DATA_QUERY_PARAMS', {
            RECIPE: ['showProductsOnly'],
            EXTERNAL_CART: ['externalName'],
            EMAIL_VERIFICATION: ['code'],
            SPECIAL: ['showFrom'],
            UPDATE_ORDER_V2: ['step', 'orderId', 'isCancelOnClose']
        });
})(angular);
(function (angular) {
    'use script';

    var DIALOG_DID_NOT_OPEN = new Error('Didn\'t open');

    /**
     * @typedef {object} DialogUrlConfig
     *
     * @property {Array.<string>} queryParams - the params that will open the dialog
     * @property {Array.<string>} [dataQueryParams] - will only be detected as not reload params (will not cause the dialog to open)
     * @property {function|Array<string|function>} [canShow] -
     *  Will be provided with the param name and value as injectables by the names 'paramName' and 'paramValue'.
     *  Should validate the param or any other validation to determine whether the dialog can be shown or not.
     *  Returns a boolean|Promise<boolean>.
     * @property {function|Array<string|function>} [paramResolve] -
     *  Will be provided with the param name and value and the saved data of the param name
     *  as injectables by the names 'paramName', 'paramValue' and 'savedParamData'.
     *  Resolves the param into the form it should be sent into the dialog.
     *  This exists mainly for changes in the param value when the dialog is already open.
     *  Returns a Promise<*>
     * @property {string} paramChangedEventName - a name of the event which will be emitted on the root scope
     *  once the query param has changed and the dialog is already open.
     * @property {function|Array<string|function>} show -
     *  Will be provided with the resolved param or its original value when it has no resolver
     *  as an injectable by the name 'value'.
     *  Opens the dialog.
     *  Returns a Promise that will be resolved once the dialog will be closed.
     * @property {function|Array<string|function>} hide -
     *  Hides the dialog (will be called instead of the default closeDialog on the provider).
     *  Returns a Promise.
     */

    angular.module('spDialogUrlManager').provider('SpDialogUrlManager', [SpDialogUrlManagerProvider]);

    function SpDialogUrlManagerProvider() {
        var dialogs = [],
            service,
            provider = this;

        provider.dialog = addDialogConfig;
        provider.closeDialog = defaultCloseDialog;

        /**
         * Add a dialog config to the list
         * @public
         *
         * @param {DialogUrlConfig} dialogConfig
         */
        function addDialogConfig(dialogConfig) {
            dialogs.push(dialogConfig);
        }

        /**
         * Default close dialog error
         * @public
         */
        function defaultCloseDialog() {
            throw new Error('spDialogUrlManager Error: I don\'t know how to close your dialog.\n' +
                'Please override the SpDialogUrlManagerProvider.closeDialog with an angular injectable function.\n' +
                'You can also define a \'hide\' function on the dialog config.');
        }

        provider.$get = [
            '$rootScope', '$location', '$q', '$injector', '$timeout', '$state',
            function($rootScope, $location, $q, $injector, $timeout, $state) {
                if (!service) {
                    service = new DialogUrlManagerService($rootScope, $location, $q, $injector, $timeout, $state, provider, dialogs);
                }

                return service;
            }
        ];
    }

    /**
     *
     * Dialog manager service
     * @constructor
     *
     * @param $rootScope
     * @param $location
     * @param $q
     * @param $injector
     * @param $timeout
     * @param $state
     * @param {SpDialogUrlManagerProvider} provider
     * @param {Array<DialogUrlConfig>} dialogs
     */
    function DialogUrlManagerService($rootScope, $location, $q, $injector, $timeout, $state, provider, dialogs) {
        var self = this,
            _overlayingDialogs = [],
            _savedData = {},
            _queryParamsMap = {},
            _dataQueryParamsMap = {},
            _lastStatePosition = 0,
            _currentStatePosition = 0,
            _openDialog;

        self.saveData = saveData;
        self.backClose = backClose;
        self.setDialogParams = setDialogParams;
        self.setOverlayDialog = setOverlayDialog;

        _start();

        /**
         * Save data (like) into the dialogs
         * @public
         *
         * @param {string} paramName
         * @param {*} data
         */
        function saveData(paramName, data) {
            _savedData[paramName] = data;
        }

        /**
         * Go back in history to before the dialog was opened
         * @public
         *
         * @returns {Promise}
         */
        function backClose() {
            var dialogOpenStatePosition = _getDialogStatePosition();
            if (_isNan(dialogOpenStatePosition) || dialogOpenStatePosition < 0 ||
                !window.history.state || _isNan(window.history.state.spDialogUrlManagerDialogStatePosition) ||
                window.history.state.spDialogUrlManagerDialogStatePosition < dialogOpenStatePosition) {
                return $state.go('.', _emptyDialogParams(), {notify: false})
                    // add .then and .catch which are not really doing anything
                    // it seems like $state.go does not actually performs without it
                    .then(function(result) { return result; })
                    .catch(function(err) { throw err; });
            }

            _getDialogStatePosition(null);
            history.go(dialogOpenStatePosition - window.history.state.spDialogUrlManagerDialogStatePosition - 1);

            return $timeout(function() {}, 500);
        }

        /**
         * Returns whether the given value is not a number
         * @private
         *
         * @param {*} value
         *
         * @returns {boolean}
         */
        function _isNan(value) {
            return isNaN(value) || value === null;
        }

        /**
         * Add dialog params into a state without reloading
         * @public
         *
         * @param {object} paramsObj
         *
         * @returns {Promise}
         */
        function setDialogParams(paramsObj) {
            return _waitForStateChange().then(function() {
                return $state.go('.', angular.merge(_emptyDialogParams(), paramsObj), {
                    inherit: true,
                    notify: false
                });
            }).then(function() {
                if (_openDialog && _isParamInParamsObj(_openDialog.queryParam, paramsObj)) {
                    return _openDialog.dialogPromise;
                }

                return _listenForRouteOpen(paramsObj);
            });
        }

        /**
         * Add overlaying dialog to be closed first
         * @public
         *
         * @param {Promise} dialogPromise
         */
        function setOverlayDialog(dialogPromise) {
            _overlayingDialogs.push(dialogPromise);

            dialogPromise.finally(function() {
                _overlayingDialogs.splice(_overlayingDialogs.indexOf(dialogPromise), 1);
            });
        }

        /**
         * Returns a promise that will be resolved at the next $stateChangeSuccess event
         * @private
         *
         * @returns {Promise}
         */
        function _waitForStateChange() {
            if ($state.current && $state.current.name) {
                return $q.resolve();
            }

            return new $q(function(resolve) {
                var listener = $rootScope.$on('$stateChangeSuccess', function () {
                    listener();
                    resolve();
                });
            });
        }

        /**
         * Listen for the opening of a dialog from one of the params given in the paramsObj
         * And return the promise of that dialog
         * @private
         *
         * @param {object} paramsObj
         *
         * @returns {Promise}
         */
        function _listenForRouteOpen(paramsObj) {
            return new $q(function(resolve, reject) {
                var listener = $rootScope.$on('dialogUrlManager.routeChanged', function(event, routeData) {
                    if (!routeData.newParam) {
                        return;
                    }

                    listener();

                    if (_isParamInParamsObj(routeData.newParam, paramsObj)) {
                        resolve(routeData.dialogPromise);
                    } else {
                        reject(DIALOG_DID_NOT_OPEN)
                    }
                });
            });
        }

        /**
         * Returns whether a query param is in a map of query params
         * @private
         *
         * @param {object} queryParam
         * @param {string} queryParam.paramName
         * @param {string} queryParam.paramValue
         * @param {object} paramsObj
         *
         * @returns {boolean}
         */
        function _isParamInParamsObj(queryParam, paramsObj) {
            var paramNames = Object.keys(paramsObj);
            for (var i = 0; i < paramNames.length; i++) {
                var paramName = paramNames[i],
                    paramValue = paramsObj[paramName] && paramsObj[paramName].toString();
                if (paramName === queryParam.paramName && paramValue === queryParam.paramValue) {
                    return true;
                }
            }
            return false;
        }

        /**
         * Get the dialog params empty to close all other dialogs
         * @private
         */
        function _emptyDialogParams() {
            var params = {};

            angular.forEach(dialogs, function(dialogConfig) {
                angular.forEach(dialogConfig.queryParams, function(paramName) {
                    params[paramName] = null;
                });

                angular.forEach(dialogConfig.dataQueryParams || [], function(paramName) {
                    params[paramName] = null;
                });
            });

            return params;
        }

        /**
         * Get the hub data
         * @public
         */
        function _start() {
            _prepareQueryParamNamesMap();

            _preventPageReRenderListener();
            _listenForDialogChanges();
        }

        /**
         * Open or close dialogs when dialogs query params changed in the url
         * @private
         */
        function _listenForDialogChanges() {
            var startEventPromises = [];
            $rootScope.$on('$locationChangeStart', function(event, toUrl, fromUrl) {
                var searchParams = _parseUrlQueryParams(toUrl),
                    matchingDialog = _findDialogByParams(searchParams),
                    closePromise = _handleClosingDialog(matchingDialog, event);

                if (!event.defaultPrevented) {
                    startEventPromises.push(closePromise.then(function() {
                        return matchingDialog;
                    }));
                }
            });

            var notFirst = false;
            $rootScope.$on('$locationChangeSuccess', function(event, toUrl, fromUrl) {
                var startPromise = startEventPromises.shift();

                if (notFirst && toUrl === fromUrl) {
                    return event.preventDefault();
                }
                notFirst = true;

                try {
                    if (!window.history.state) {
                        window.history.replaceState({spDialogUrlManagerDialogStatePosition: _lastStatePosition++}, null, toUrl);
                        _currentStatePosition = window.history.state.spDialogUrlManagerDialogStatePosition;
                    } else if (!('spDialogUrlManagerDialogStatePosition' in window.history.state)) {
                        window.history.replaceState(angular.merge({}, window.history.state, {
                            spDialogUrlManagerDialogStatePosition: _lastStatePosition++
                        }), null, toUrl);
                        _currentStatePosition = window.history.state.spDialogUrlManagerDialogStatePosition;
                    } else {
                        _currentStatePosition = window.history.state.spDialogUrlManagerDialogStatePosition;
                        _lastStatePosition = _currentStatePosition + 1;
                    }
                } catch (e) {}

                var prevParam = _openDialog && angular.copy(_openDialog.queryParam),
                    dialogStatePosition = _currentStatePosition;

                // if the dialog opens on the first location change, get the its saved state position
                if (toUrl === fromUrl) {
                    dialogStatePosition = _getDialogStatePosition();
                }

                startPromise.then(function(matchingDialog) {
                    if (matchingDialog) {
                        return _handleDialogRouting(matchingDialog, dialogStatePosition);
                    }
                }).finally(function() {
                    var currentParam = _openDialog && angular.copy(_openDialog.queryParam);

                    if ((!prevParam) !== (!currentParam) ||
                        (currentParam && currentParam.paramName) !== (prevParam && prevParam.paramName) ||
                        (currentParam && currentParam.paramValue) !== (prevParam && prevParam.paramValue)) {
                        $rootScope.$emit('dialogUrlManager.routeChanged', {
                            oldParam: prevParam,
                            newParam: currentParam,
                            dialogPromise: _openDialog && _openDialog.dialogPromise
                        });
                    }
                });
            });


            // not always $locationChangeSuccess will go after $locationChangeStart. So need force remove startEventPromises
            $rootScope.$on('dialogUrlManager.shiftEventPromise', function() {
                startEventPromises.shift();
            });
        }

        /**
         * Returns a key value object of the search params from the given url
         * @private
         *
         * @param {string} url
         *
         * @return {Object}
         */
        function _parseUrlQueryParams(url) {
            if (!url || url.indexOf('?') === -1) {
                return {};
            }

            var searchParams = url.split('?')[1].split('#')[0],
                map = {};
            angular.forEach(searchParams.split('&'), function(param) {
                var paramSplit = param.split('=');
                map[paramSplit[0]] = paramSplit[1];
            });
            return map;
        }

        /**
         * Returns or sets the dialog open state position
         * @private
         *
         * @param {number|null} value
         *
         * @returns {number}
         */
        function _getDialogStatePosition(value) {
            if (!angular.isUndefined(value)) {
                if (value !== null) {
                    return sessionStorage.setItem('spDialogUrlManagerDialogOpenStatePosition', value);
                }

                return sessionStorage.removeItem('spDialogUrlManagerDialogOpenStatePosition');
            }

            return Number(sessionStorage.getItem('spDialogUrlManagerDialogOpenStatePosition')) || null;
        }

        /**
         * Calls the defined 'hide' function on the dialog config or the default close dialog function.
         * @private
         *
         * @param {object} dialog
         * @param {boolean} [dialog.closing]
         * @param {Promise} dialog.dialogPromise
         * @param {DialogUrlConfig} dialog.dialogConfig
         *
         * @returns {Promise}
         */
        function _closeDialog(dialog) {
            return new $q(function(resolve) {
                dialog.dialogPromise.finally(function() {
                    resolve();
                });

                if (!dialog.closing) {
                    dialog.closing = true;

                    if (!dialog.dialogConfig.hide) {
                        return $injector.invoke(provider.closeDialog);
                    }

                    $injector.invoke(dialog.dialogConfig.hide);
                }
            });
        }

        /**
         * @typedef {Object} MatchingDialogData
         *
         * @property {DialogUrlConfig} dialogConfig
         * @property {Object} params
         * @property {DialogQueryParam} matchingParam
         */

        /**
         * Find the matching dialog by the url params
         * @private
         *
         * @param {Object} queryParams
         *
         * @return {MatchingDialogData}
         */
        function _findDialogByParams(queryParams) {
            var matchingDialog;
            angular.forEach(dialogs, function(dialogConfig) {
                var queryParamData = _getDialogQueryParamValue(dialogConfig, queryParams);
                // if this dialog has a matching query params, it means that it should be open
                if (queryParamData) {
                    matchingDialog = {
                        dialogConfig: dialogConfig,
                        params: _getAllDialogParams(dialogConfig, queryParams),
                        matchingParam: queryParamData
                    };
                }
            });
            return matchingDialog;
        }

        /**
         * Collect all the query params related to the given dialog config
         * @private
         *
         * @param {DialogUrlConfig} dialogConfig
         * @param {Object} queryParams
         *
         * @returns {Object}
         */
        function _getAllDialogParams(dialogConfig, queryParams) {
            var res = {};

            angular.forEach([dialogConfig.queryParams, dialogConfig.dataQueryParams], function(paramsList) {
                angular.forEach(paramsList || [], function(paramName) {
                    if (queryParams.hasOwnProperty(paramName)) {
                        res[paramName] = queryParams[paramName];
                    }
                });
            });

            return res;
        }

        /**
         * Close the current open dialog
         * @private
         *
         * @param {MatchingDialogData} matchingDialog
         * @param {Event} event
         *
         * @return {Promise<void>}
         */
        function _handleClosingDialog(matchingDialog, event) {
            // when there is not open dialog or the open dialog is the one that should be open, do nothing
            if (!_openDialog || (!_openDialog.closing && matchingDialog && _openDialog.dialogConfig === matchingDialog.dialogConfig)) {
                return $q.resolve();
            }

            if (_overlayingDialogs.length) {
                event.preventDefault();
                return $injector.invoke(provider.closeDialog);
            }

            return _closeDialog(_openDialog);
        }

        /**
         * Show or hide dialog when its query params changed
         * @private
         *
         * @param {MatchingDialogData} matchingDialog
         * @param {number} statePosition
         *
         * @returns {Promise<void>}
         */
        function _handleDialogRouting(matchingDialog, statePosition) {
            return $q.resolve().then(function() {
                return _resolveValue(matchingDialog.dialogConfig, matchingDialog.matchingParam.paramName, matchingDialog.matchingParam.paramValue);
            }).then(function(resolvedValue) {
                // if there is already an open dialog, and it is this one, emit an event of value changed
                if (_openDialog && _openDialog.dialogConfig === matchingDialog.dialogConfig) {
                    $rootScope.$emit(matchingDialog.dialogConfig.paramChangedEventName, resolvedValue);
                    _openDialog.queryParam = matchingDialog.matchingParam;
                    return;
                }

                _getDialogStatePosition(statePosition);
                var promise = $injector.invoke(matchingDialog.dialogConfig.show, null, angular.merge({
                    value: resolvedValue
                }, matchingDialog.matchingParam, {
                    params: matchingDialog.params
                }));

                _openDialog = {
                    queryParam: matchingDialog.matchingParam,
                    dialogConfig: matchingDialog.dialogConfig,
                    dialogPromise: promise
                };

                promise.finally(function() {
                    if (_openDialog.dialogPromise === promise) {
                        _openDialog = null;
                    }
                });
            });
        }

        /**
         * @typedef {Object} DialogQueryParam
         *
         * @property {string} paramName
         * @property {string} paramValue
         */

        /**
         * Scan the query params for values of the given dialog config
         * @private
         *
         * @param {DialogUrlConfig} dialogConfig
         * @param {object} queryParams
         *
         * @returns {DialogQueryParam}
         */
        function _getDialogQueryParamValue(dialogConfig, queryParams) {
            for (var i = 0; i < dialogConfig.queryParams.length; i++) {
                var param = {
                    paramName: dialogConfig.queryParams[i],
                    paramValue: queryParams[dialogConfig.queryParams[i]]
                };

                if (queryParams.hasOwnProperty(param.paramName)) {
                    if (dialogConfig.canShow && !$injector.invoke(dialogConfig.canShow, null, param)) {
                        _waitForStateChange().then(function() {
                            return backClose();
                        });
                        return;
                    }

                    return param;
                }
            }
        }

        /**'
         * Resolves the search query param into the expected data in the dialog
         * @private
         *
         * @param {DialogUrlConfig} dialogConfig
         * @param {string} paramName
         * @param {string} paramValue
         *
         * @returns {Promise.<*>}
         */
        function _resolveValue(dialogConfig, paramName, paramValue) {
            return new $q.resolve().then(function() {
                if (!dialogConfig.paramResolve) {
                    return paramValue;
                }

                return $injector.invoke(dialogConfig.paramResolve, undefined, {
                    paramName: paramName,
                    paramValue: paramValue,
                    savedParamData: _savedData[paramName]
                });
            });
        }

        /**
         * Prepare a map of the query params of the dialogs
         * The map is an object with the param name as the key and dialog configurations as the value
         * @private
         */
        function _prepareQueryParamNamesMap() {
            angular.forEach(dialogs, function(dialogConfig) {
                angular.forEach(dialogConfig.queryParams, function(paramName) {
                    _queryParamsMap[paramName] = dialogConfig;
                });

                angular.forEach(dialogConfig.dataQueryParams || [], function(paramName) {
                    _dataQueryParamsMap[paramName] = dialogConfig;
                });
            });
        }

        /**
         * Prevent the page from being re rendered when only dialog params changed
         * @private
         */
        function _preventPageReRenderListener() {
            $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
                if (toState === fromState && !_hasNoDialogParams(fromParams, toParams) && !_hasNoDialogParams(toParams, fromParams)) {
                    event.preventDefault();
                    $state.go(toState.name, toParams, {notify: false, relative: toState});
                }
            });
        }

        /**
         * Compare two state params for changes that are not related to the dialogs
         * @private
         *
         * @param {object} fromParams
         * @param {object} toParams
         *
         * @returns {boolean}
         */
        function _hasNoDialogParams(fromParams, toParams) {
            for (var paramName in fromParams) {
                if (!fromParams.hasOwnProperty(paramName)) {
                    continue;
                }

                if (!_queryParamsMap[paramName] && !_dataQueryParamsMap[paramName] && toParams[paramName] !== fromParams[paramName]) {
                    return true;
                }
            }
        }
    }
})(angular);