(function (angular) {

    angular.module('mobilezuz').service('EmailVerificationService', ['Api', function(api) {
        this.doEmailVerification = doEmailVerification;

        function doEmailVerification(code) {
            //== Try to verify user by code
            return api.request({
                method: 'POST',
                url: '/v2/retailers/:rid/users/verification/user-email-verification',
                data: {
                    code: code
                }
            }, {
                hideError: true
            }).then(function(response) {
                return {
                    success: true,
                    response: response
                }
            }).catch(function(err) {
                return {
                    success: false,
                    error: err,
                    email: (err.response || {}).email || ''
                }
            });
        }
    }]);


    angular.module('mobilezuz').config([
        'SpDialogUrlManagerProvider', 'SP_URL_DIALOG_QUERY_PARAMS', 'SP_URL_DIALOG_DATA_QUERY_PARAMS',
        function (SpDialogUrlManagerProvider, SP_URL_DIALOG_QUERY_PARAMS, SP_URL_DIALOG_DATA_QUERY_PARAMS) {
            SpDialogUrlManagerProvider.dialog({
                queryParams: SP_URL_DIALOG_QUERY_PARAMS.EMAIL_VERIFICATION,
                dataQueryParams: SP_URL_DIALOG_DATA_QUERY_PARAMS.EMAIL_VERIFICATION,
                canShow: ['paramValue', function (paramValue) {
                    return !!paramValue;
                }],
                paramResolve: ['paramValue', 'EmailVerificationService', function (paramValue, EmailVerificationService) {
                    return EmailVerificationService.doEmailVerification(paramValue);
                }],
                show: ['mDesign', 'SpDialogUrlManager', 'value', function (mDesign, SpDialogUrlManager, value) {
                    return mDesign.dialog({
                        multiple: true,
                        fullscreen: true,
                        templateUrl: 'views/email-verification.html',
                        controller: 'EmailVerificationCtrl as emailVerificationCtrl',
                        locals: {
                            verificationResponse: value
                        },
                        dontPreventRouteChange: true
                    });
                }]
            });
        }
    ]);


    angular.module('mobilezuz').controller('EmailVerificationCtrl', ['$timeout', '$location', 'Util', 'User', 'verificationResponse', 'SpDialogUrlManager',
        function ($timeout, $location, util, user, verificationResponse, SpDialogUrlManager) {
            var emailVerificationCtrl = this;

            emailVerificationCtrl.title = '';
            emailVerificationCtrl.body = '';
            emailVerificationCtrl.goToLoginEnabled = false;
            emailVerificationCtrl.closeEnabled = false;
            emailVerificationCtrl.resendActivationEnabled = false;

            emailVerificationCtrl.goToLogin = goToLogin;
            emailVerificationCtrl.close = close;
            emailVerificationCtrl.resendActivation = resendActivation;

            init();

            function init() {
                //== Received OK response
                if(verificationResponse.success) {
                    emailVerificationCtrl.goToLoginEnabled = true;
                    emailVerificationCtrl.title = verificationResponse.response.isEmailVerifiedInPast ? 'Account verification succeed' : 'You have successfully registered for the site';
                    if(verificationResponse.response.isThisUserVerificationTimeLimited) {
                        emailVerificationCtrl.body = 'This account is an organizational account. You may need to verify it similarly from time to time';
                    }
                } else {
                    //== Error response received
                    if(verificationResponse.error.response.code === 'userActivationCodeExpired') {
                        emailVerificationCtrl.resendActivationEnabled = true;
                        emailVerificationCtrl.title = 'Sorry, this link has expired';
                        emailVerificationCtrl.body = 'A new link can be sent';
                    } else if(verificationResponse.error.response.code === 'invalidVerificationCode') {
                        emailVerificationCtrl.title = 'This user may already be on the system';
                        emailVerificationCtrl.body = 'Please try to login';
                        emailVerificationCtrl.goToLoginEnabled = true;
                    } else {
                        emailVerificationCtrl.closeEnabled = true;
                        emailVerificationCtrl.title = (verificationResponse.error.response || {}).error || 'Error';
                        emailVerificationCtrl.body = '';
                    }
                }
            }

            function close() {
                return SpDialogUrlManager.backClose();
            }

            function goToLogin() {
                return close().then(function() {
                    $timeout(function() {
                        return util.goToLoginDialog();
                    }, 0);
                });
            }

            function resendActivation() {
                emailVerificationCtrl.goToLoginEnabled = false;
                emailVerificationCtrl.resendActivationEnabled = false;
                emailVerificationCtrl.closeEnabled = true;

                user.resendActivationLink(verificationResponse.email).then(function () {
                    emailVerificationCtrl.title = 'Sent successfully';
                    emailVerificationCtrl.body = 'A verification email will be sent to your email box. The link is valid for {link_ttl} minutes';
                }).catch(function(err) {
                    emailVerificationCtrl.title = 'Error';
                    emailVerificationCtrl.body = (err.response || {}).error;
                });
            }
        }]);
})(angular);