(function(angular) {
	angular.module('mobilezuz').service('Retailer', ['$window', '$timeout', '$q', 'Api', 'MOBILE_SERVICE_ID', 'API_BASE_URL',
		function($window, $timeout, $q, api, MOBILE_SERVICE_ID, API_BASE_URL) {
			var self = this;

			self.getRetailerSettings = getRetailerSettings;
			self.getCameraPermissionPopupSettings = getCameraPermissionPopupSettings;
			self.getAppVersionUpdatePopupSettings = getAppVersionUpdatePopupSettings;
			self.getRetailerMainDomain = getRetailerMainDomain;

			var _configurationPromise = null;
			var _cameraPermissionPromise = null;
			var _appVersionUpdatePromise = null;

			/**
			 * Get the retailer's configuration
			 * @returns {promise}
			 */
			function getRetailerSettings() {
				if (!_configurationPromise) {
					_configurationPromise = _getRetailerSettings()
						.then(function(configuration) {
							var mobileHomePageImages = angular.filterCollection(configuration.homePageImages, function(image) {
								return image.serviceId == MOBILE_SERVICE_ID;
							});

							if (mobileHomePageImages.length) {
								var imageIndex = Number($window.localStorage.getItem('mobileZuzHomePageImageIndex'));
								imageIndex = angular.isNumber(imageIndex) && !isNaN(imageIndex) ? (imageIndex + 1) % mobileHomePageImages.length : 0;
								$window.localStorage.setItem('mobileZuzHomePageImageIndex', imageIndex);
								self.backgroundImage = mobileHomePageImages[imageIndex].url;
							}
                            if (!$window.sp.mobileData.retailer) {
                                $timeout(function() {
                                    _configurationPromise = null;
                                }, 60 * 1000 * 30); // remove cache after 30 min
                            }
							return configuration;
						});
				}

				return _configurationPromise;
			}

			function _getRetailerSettings() {
				if ($window.sp.mobileData.retailer) {
					return $q.resolve($window.sp.mobileData.retailer);
				}

				return api.request({
					method: 'GET',
					url: 'retailers/:rid/configuration?isDoEbt=1'
				});
			}


			/**
			 * Get the retailer's camera permission popup settings
			 * @returns {promise}
			 */
			function getCameraPermissionPopupSettings(){
				if(!_cameraPermissionPromise){
					_cameraPermissionPromise = api.request({
						method: 'GET',
						url: 'v2/retailers/:rid/configurations/cameraPermissionPopup'
					}).then(function(cameraPermissionPopup){
						return cameraPermissionPopup;
					})
				}
				return _cameraPermissionPromise;
			}

			/**
			 * Get the retailer's app version popup settings
			 * @returns {promise}
			 */
			function getAppVersionUpdatePopupSettings(){
				if(!_appVersionUpdatePromise){
					_appVersionUpdatePromise = api.request({
						method: 'GET',
						url: 'v2/retailers/:rid/configurations/appVersionUpdatePopup'
					}).then(function(appVersionUpdatePopup){
						return appVersionUpdatePopup;
					})
				}
				return _appVersionUpdatePromise;
			}

			var _mainDomainPromise = null;

			/**
			 * Get the retailer's main domain
			 * @returns {Promise<string>}
			 */
			function getRetailerMainDomain() {
				if (_mainDomainPromise) {
					return _mainDomainPromise;
				}

				if ($window.sp.mobileData.apiUrl) {
					return _mainDomainPromise = $q.resolve($window.sp.mobileData.apiUrl);
				}

				return _mainDomainPromise = api.request({
					method: 'GET',
					url: API_BASE_URL + 'v2/retailers/:rid/domains/main'
				},{
					fireAndForgot: true
				}).then(function(domain) {
					var url = domain && domain.url && ('https://' + domain.url) || API_BASE_URL;
					if (url.slice(-1) !== '/') {
						url += '/';
					}
					return url;
				}).then(function(url) {
					if (url === API_BASE_URL) { //do not need to check again
						return url;
					}

					return api.request({ // check ping
						method: 'GET',
						url: url + 'ping'
					},{
						fireAndForgot: true
					}).then(function(res) {
						if (res === 'pong') { //good
							return url;
						} else { //use default
							return API_BASE_URL;
						}
					});
				}).catch(function(err) {
					console.error('Cannot get retailer main domain', err);
					return API_BASE_URL;
				});
			}
		}]);
})(angular);
