angular.module('mobilezuz').service('AddressService', [
  'Api', 'SP_SERVICES', 'SpDeliveryAreasService', 'FILTER_ADDRESS_MODE', '$q',
  function (Api, SP_SERVICES, SpDeliveryAreasService, FILTER_ADDRESS_MODE, $q) {
    var self = this;
    angular.extend(self, {
      getInternalCities: getInternalCities,
      getAddressesFromGoogleMapApi: getAddressesFromGoogleMapApi,
      isEnabledAddressSetting: isEnabledAddressSetting,
      getAddressFormSettings: getAddressFormSettings,
      getAddressAutoCompleteOptions: getAddressAutoCompleteOptions,
      getCitiesFromGoogleMapApi: getCitiesFromGoogleMapApi,
      getZipCodeFromGoogleMapApi: getZipCodeFromGoogleMapApi,
      geocodeByGgMapApi: geocodeByGgMapApi
    });

    /**
      * Get all interal cities which come from Cities table in the database
      * @param {string} cityQuery
      * @returns Promise<any[]>
      */
    function getInternalCities(cityQuery) {
      return Api.request({
        method: 'GET',
        url: '/v2/addresses/cities',
        params: {
          term: cityQuery,
          size: 8,
        },
      });
    }

    /**
     * Get full addresses from Google Map API
     * @param {string} addressQuery 
     * @returns Promise<any[]>
     */
    function getAddressesFromGoogleMapApi(addressQuery, placeId, zipCode, validZipCode, languageId) {
      var GG_ADDRESS_MODE = SP_SERVICES.CHOOSE_AREA_MODE.TYPE_GOOGLE_ADDRESS;
      return SpDeliveryAreasService.autoCompleteDeliveryAreasWithFullData(GG_ADDRESS_MODE, addressQuery, placeId, zipCode, validZipCode, languageId);
    }

    /**
     * Get address form settings
     * @returns Promise<any[]>
     */
    function getAddressFormSettings() {
      return Api.request({
        method: 'GET',
        url: '/v2/retailers/:rid/address-setting/address-form-settings',
      });
    }

    /**
     * Get address form settings
     * @returns Promise<any[]>
     */
    var _isEnabledAddressSettingCache;
    function isEnabledAddressSetting() {
      if(_isEnabledAddressSettingCache) return $q.resolve(_isEnabledAddressSettingCache);

      return Api.request({
        method: 'GET',
        url: '/v2/retailers/:rid/address-setting/is-enabled-address-setting',
      }).then(function (res) {
        _isEnabledAddressSettingCache = res;
        return res;
      });
    }

    /**
     * @param {{
     * address: string
     * languageId: number
     * lat: number
     * lng: number
     * }} params
     * @returns {Promise<Geocode[]>}
     */
    function geocodeByGgMapApi(params) {
      return Api.request(
        {
          method: "GET",
          url: "/v2/google-maps/address",
          params: params
        },
        {
          hideError: true
        }
      ).catch(function () {
        return { results: [] };
      });
    }

    function getAddressAutoCompleteOptions(addressQuery, dataOptions) {
      if (!addressQuery) {
        return $q.resolve([]);
      }

      // Filter address by city/ zip code
      if (dataOptions.addressFilteringMode) {
        return _getAddressUsingFilter(addressQuery, dataOptions);
      }

      // Call google api
      return getAddressesFromGoogleMapApi(addressQuery).then(function (resp) {
        var addresses = [];
        if (resp && resp.places && resp.places.length) {
          angular.forEach(resp.places, function (place) {
            var CITY_INDEX = 0;
            var isCity = _isCity(place);
            if (!isCity) {
              var item = {
                name: place.description, // For displaying options to distinguish
                component: {
                  text1: place.structured_formatting.main_text,
                  city: place.structured_formatting.secondary_text.split(', ')[CITY_INDEX],
                  externalPlaceId: place.place_id,
                },
                placeTypes: place.types
              }
              item.mainValue = item.component.text1 + ', ' + item.component.city;
              addresses.push(item);
            }
          });
        }
        return addresses;
      });
    }

    function _getAddressUsingFilter(addressQuery, dataOptions) {
      if (dataOptions.addressFilteringMode === FILTER_ADDRESS_MODE.CITY) {
        return _filterByCity(addressQuery, dataOptions);
      }
      if (dataOptions.addressFilteringMode === FILTER_ADDRESS_MODE.ZIP_CODE) {
        return _filterByZipCode(addressQuery, dataOptions);
      }
    }

    function _isCity(place) {
      return ['locality', 'sublocality', 'neighborhood'].some(function (type) {
        return place.types.includes(type)
      });
    }

    function _filterByCity(addressQuery, dataOptions) {
      var placeId = dataOptions.placeId;
      return getAddressesFromGoogleMapApi(addressQuery, placeId, null, null, dataOptions.languageId).then(function (resp) {
        var city = dataOptions.city;
        resp.places = _refineAddresses(resp, city);
        var addresses = [];
        if (resp && resp.places && resp.places.length) {
          angular.forEach(resp.places, function (place) {
            var CITY_INDEX = 0;
            var isCity = _isCity(place);
            if (!isCity) {
              var item = {
                name: place.description, // For displaying options to distinguish
                component: {
                  text1: place.structured_formatting.main_text,
                  city: place.structured_formatting.secondary_text.split(', ')[CITY_INDEX],
                  externalPlaceId: place.place_id,
                },
                placeTypes: place.types
              }
              item.mainValue = item.component.text1 + ', ' + item.component.city;
              addresses.push(item);
            }
          });
        }
        return addresses;
      });
    }

    function _filterByZipCode(addressQuery, dataOptions) {
      var zipCode = dataOptions.zipCode;
      if (!zipCode) return;
      return getAddressesFromGoogleMapApi(addressQuery, null, zipCode, null, dataOptions.languageId).then(function (resp) {
        var addresses = [];
        if (resp && resp.places && resp.places.length) {
          angular.forEach(resp.places, function (place) {
            var CITY_INDEX = 0;
            var isCity = _isCity(place);
            if (!isCity) {
              var item = {
                name: place.description, // For displaying options to distinguish
                component: {
                  text1: place.structured_formatting.main_text,
                  city: place.structured_formatting.secondary_text.split(', ')[CITY_INDEX],
                  externalPlaceId: place.place_id,
                },
                placeTypes: place.types
              }
              item.mainValue = item.component.text1 + ', ' + item.component.city;
              addresses.push(item);
            }
          });
        }
        return addresses;
      });
    }

    function _refineAddresses(addresses, query) {
      if (!addresses.places.length) {
        return;
      }
      return addresses.places.filter(function (place) {
        return place.terms.some(function (term) {
          return term.value.toLowerCase().includes(query.toLowerCase());
        })
      })
    }

    function getCitiesFromGoogleMapApi(cityQuery) {
      return getAddressesFromGoogleMapApi(cityQuery).then(function (res) {
        var cities = [];
        if (res && res.places && res.places.length) {
          angular.forEach(res.places, function (place) {
            var isCity = _isCity(place);
            if (isCity) {
              cities.push({
                name: place.description,
                component: {
                  city: place.structured_formatting.main_text,
                },
                placeTypes: place.types,
                mainValue: place.structured_formatting.main_text,
                placeId: place.place_id
              });
            }
          })
        }
        return { cities: cities };
      });
    }

    function _isZipCode(place) {
      return ["postal_code"].some(function (type) {
        return place.types.includes(type)
      });
    }

    function getZipCodeFromGoogleMapApi(zipCode) {
      return getAddressesFromGoogleMapApi(zipCode, '', zipCode, true).then(function (res) {
        var zipCodes = [];
        if (res && res.places && res.places.length) {
          angular.forEach(res.places, function (place) {
            var isZipCode = _isZipCode(place);
            if (isZipCode) {
              zipCodes.push({
                name: place.description,
                component: {
                  zipCode: place.structured_formatting.main_text,
                },
                placeTypes: place.types, // Just for syncing with getAddressAutoCompleteOptions func
                mainValue: place.structured_formatting.main_text,
                placeId: place.place_id
              })
            }
          })
        }
        return { zipCodes: zipCodes }
      })
    }

  }]);




