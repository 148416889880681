(function(angular) {
	'use strict';

	var MODULE_NAME = 'spNotifications',
		NOTIFICATION_ACCEPTED_EVENT = MODULE_NAME + '.notificationAccepted',
		TOKEN_CHANGED_EVENT = MODULE_NAME + '.tokenChanged';

	/**
	 * @typedef {Object} NotificationsData
	 * @property {String} [service]
	 * @property {String} [token]
	 */

	/**
	 * @typedef {Object} NotificationsConfig
	 * @property {Number} serviceId
	 * @property {String} senderId
	 * @property {String} firebaseApiKey
	 * @property {String} firebaseServiceWorkerUrl
	 * @property {String} [androidIconColor]
	 * @property {String} [firebaseVersion='3.9.0']
	 */

	angular.module(MODULE_NAME, [])
		.service('Notifications', ['$window', '$timeout', '$rootScope', '$q', 'Api', function($window, $timeout, $rootScope, $q, Api) {
			var self = this;

			/**
			 * @type {NotificationsData}
			 */
			self.data = {};

			self.init = init;
			self.setRetailerId = setRetailerId;

			/**
			 * Init
			 * @public
			 *
			 * @param {NotificationsConfig} config
			 */
			function init(config) {
				self.config = config;
				self.config.firebaseVersion = self.config.firebaseVersion || '3.9.0';

				return _getCordova().then(function(cordova) {
					switch (cordova && cordova.platformId) {
						case 'android':
							self.data.service = 'FCM';
							_nativeNotifications();
							break;

						case 'ios':
							self.data.service = 'APN';
							_nativeNotifications();
							break;

						default:
							self.data.service = 'FCM';
							_firebaseNotifications();
					}
				});
			}

			function _getCordova() {
				return new $q(function(resolve) {
					if ($window.cordova) {
						return resolve($window.cordova);
					}

					//listen to deviceready event - to support cordova being injected by the top window when in iframe
					document.addEventListener('deviceready', function() {
						resolve($window.cordova);
					});

					//For a browser that has no cordova and will never have - resolve on load
					//When readyState is complete it means that:
					//The document and all sub-resources have finished loading. The state indicates that the load event is about to fire.
					if (document.readyState === 'complete') {
						//Set timeout to make sure the load event happened
						$timeout(function() {
							resolve($window.cordova);
						}, 1000)
					} else {
						$window.addEventListener('load', function() {
							//set timeout to allow the device ready event to be called before resolving the promise
							$timeout(function() {
								resolve($window.cordova);
							});
						});
					}
				});
			}

			/**
			 * Set Retailer Id
			 * @public
			 *
			 * @param {Number} retailerId
			 */
			function setRetailerId(retailerId) {
				self.retailerId = retailerId;
				_sendToken();
			}

			/**
			 * Set Token
			 * @private
			 *
			 * @param {String} token
			 */
			function _setToken(token) {
				self.data.token = token;
				$rootScope.$emit(TOKEN_CHANGED_EVENT);

				_sendToken();
			}

			function _sendToken() {
				if (!self.retailerId || !self.data.token) {
					return;
				}

				return Api.request({
					method: 'POST',
					url: '/retailers/' + self.retailerId + '/apptokens',
					data: {
						serviceId: self.config.serviceId,
						service: self.data.service,
						appToken: self.data.token
					}
				}, {
					fireAndForgot: true
				});
			}

			/**
			 * Native Notifications
			 * @private
			 */
			function _nativeNotifications() {
				_getPushNotificationsPlugin().then(function(pushNotificationPlugin) {
					var pushNotifications = pushNotificationPlugin.init({
						android: {
							iconColor: self.config.androidIconColor
						},
						ios: {
							sound: true
						}
					});

					pushNotifications.on('registration', function(data) {
						_setToken(data.registrationId);
					});

					pushNotifications.on('notification', function(data) {
						$rootScope.$emit(NOTIFICATION_ACCEPTED_EVENT, _normalizeData(data));
					});
				});
			}

			function _getPushNotificationsPlugin() {
				return new $q(function(resolve) {
					if ($window.PushNotification) {
						return resolve($window.PushNotification);
					}

					document.addEventListener('deviceready', function() {
						resolve($window.PushNotification);
					});
				});
			}

			/**
			 * Firebase Notifications
			 * @private
			 */
			function _firebaseNotifications() {
				_loadScript('https://www.gstatic.com/firebasejs/' + self.config.firebaseVersion + '/firebase-app.js').then(function() {
					return _loadScript('https://www.gstatic.com/firebasejs/' + self.config.firebaseVersion + '/firebase-messaging.js');
				}).then(function() {
					$window.firebase.initializeApp({
						apiKey: self.config.firebaseApiKey,
						messagingSenderId: self.config.senderId
					});

					var messaging = firebase.messaging();

					navigator.serviceWorker.register(self.config.firebaseServiceWorkerUrl).then(function(registration) {
						messaging.useServiceWorker(registration);

						return messaging.requestPermission();
					}).then(function() {
						messaging.getToken().then(function(token) {
							_setToken(token);
						});

						messaging.onMessage(function(message) {
							$rootScope.$emit(NOTIFICATION_ACCEPTED_EVENT, _normalizeData(message.data));
						});
					});
				});
			}

			/**
			 * Load Script
			 * @private
			 *
			 * @param {String} src
			 *
			 * @returns {Promise}
			 */
			function _loadScript(src) {
				var defer = $q.defer();

				var script = document.createElement('script');
				script.setAttribute('src', src);
				script.addEventListener('load', function() {
					defer.resolve();
				});
				script.addEventListener('error', function(err) {
					defer.reject(err);
				});
				document.head.appendChild(script);

				return defer.promise;
			}

			/**
			 * Normalize Data
			 * @private
			 *
			 * @param {Object} data
			 * @private
			 */
			function _normalizeData(data) {
				angular.extend(data, data.additionalData);
				return data;
			}
		}]);
})(angular);
