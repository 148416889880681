angular.module('mobilezuz')
    .directive('cartLine', ['$state', 'Cart', 'mDesign', 'CART_LINE_TYPES',
        function ($state, cart, mDesign, CART_LINE_TYPES) {
            return {
                restrict: 'E',
                templateUrl: 'views/templates/cart-line.html',
                replace: true,
                scope: {
                    line: '=',
                    index: '='
                },
                controller: ['$scope', 'Util', function ($scope, Util) {
                    $scope.comment = showCommentDialog;
                    $scope.goToProduct = goToProduct;

                    function goToProduct(line) {
                        if (line.isPseudo || line.type == CART_LINE_TYPES.REGISTER_LOYALTY) {
                            return;
                        }

                        Util.goToProductDialog(line.product, null, null, false, { index: index, source: 'Cart' });
                    }

                    function showCommentDialog(event) {
                        if (event) {
                            event.stopPropagation(); // to stop view product page function in father (product-image) event
                            event.preventDefault(); // to stop the browser from automatically placing the focus on the md-dialog (on mobile device)
                        }

                        mDesign.dialog({
                            focusOnOpen: false,
                            controller: 'CartLineComment',
                            templateUrl: 'views/templates/cart-line-comment.html',
                            resolve: {
                                line: [function () {
                                    return $scope.line;
                                }]
                            },
                            onComplete: function() {
                                var textarea = angular.element(document.querySelector('#comment-area'));
                                textarea.focus();
                            }
                        });
                    }
                }]
            };
        }]);