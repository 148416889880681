'use strict';

angular.module('mobilezuz')
    .service('AccountDeletionSrv', ['Api', 'Config', AccountDeletionSrv])
    .service('AccountDeletionDialogSrv', ['mDesign', AccountDeletionDialogSrv])

function AccountDeletionDialogSrv(mDesign) {
    this.show = show;

    function show() {
        return mDesign.dialog({
            templateUrl: 'views/templates/account-deletion/account-deletion.html',
            focusOnOpen: false,
            clickOutsideToClose: true,
            controllerAs: 'accountDeletionCtrl',
            controller: 'AccountDeletionCtrl'
        });
    }
}

function AccountDeletionSrv(Api, Config) {
    var BASE_URL = '/v2/retailers/:rid/users/:uid/account-deletion/'
    var accountDeletionSrv = this;

    accountDeletionSrv.isAccountDeleted = isAccountDeleted;
    accountDeletionSrv.createSession = createSession;
    accountDeletionSrv.sendMfaCode = sendMfaCode;
    accountDeletionSrv.validateMfaCode = validateMfaCode;
    accountDeletionSrv.requestAccountDeletion = requestAccountDeletion;

    function isAccountDeleted() {
        return Api.request({
            method: 'GET',
            url: BASE_URL + 'is-account-deleted/',
        }, {hideError: true});
    }

    function createSession(captchaHash) {
        return Api.request({
            method: 'POST',
            url: BASE_URL + 'create-session/',
            params: {
                recaptchaHash: captchaHash
            }}, {hideError: true});
    }

    function sendMfaCode() {
        return Api.request({
            method: 'GET',
            url: BASE_URL + 'send-mfa-code/',
            params: {
                language: Config.language.culture
            }}, {hideError: true});
    }

    function validateMfaCode(mfaCode) {
        return Api.request({
            method: 'GET',
            url: BASE_URL + 'validate-mfa-code/',
            params: {
                mfaCode: mfaCode
            }}, {hideError: true});
    }

    function requestAccountDeletion(mfaCode) {
        return Api.request({
            method: 'GET',
            url: BASE_URL + 'delete-account/',
            params: {
                mfaCode: mfaCode
            }}, {hideError: true});
    }
}