angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.pickAngGo', {
        url: '/pick-and-go',
        data: {
            stateAccess: PAGE_ACCESS.LOGGED_IN,
            bodyClass: 'pick-and-go',
            metaTags: {
                title: 'Pick And Go'
            }
        },
        views: {
            '@': {
                templateUrl: 'views/pick-and-go.html',
                controller: 'PickAndGoCtrl as pickAndGoCtrl'
            }
        }
    });
}]).controller('PickAndGoCtrl', [
    '$scope', '$state', 'mDesign', 'Orders', 'Cart', 'Api', 'BarcodeScaner', 'CHECKOUT_METHODS',
    function($scope, $state, mDesign, Orders, Cart, Api, BarcodeScaner, CHECKOUT_METHODS) {

        var pickAndGoCtrl = this;

        pickAndGoCtrl.open = open;
        pickAndGoCtrl.close = close;

        function open () {
            // to simulate scan:

            // pickAndGoCtrl.barcode = '1234';
            // Cart.clear(true);
            // Orders.barcode = pickAndGoCtrl.barcode;
            // return $state.go('app.cart.checkout.process', {method: CHECKOUT_METHODS.VALUES.PICK_AND_GO});

            BarcodeScaner.scan().then(function (barcode) {
                if (barcode) {
                    pickAndGoCtrl.barcode = barcode.toString();
                     Api.request({
                         method: 'GET',
                         url: 'retailers/:rid/cartbarcodes/' + pickAndGoCtrl.barcode
                     }).then(function (res) {
                         if (res.barcode && res.barcode.length && res.barcode[0].barcode == pickAndGoCtrl.barcode) {
                            Cart.clear(true);
                            Orders.barcode = pickAndGoCtrl.barcode;

                            // mDesign.alert('Your cart number is: ' + pickAndGoCtrl.barcode, function () {
                                 return $state.go('app.cart.checkout.process', {method: CHECKOUT_METHODS.VALUES.PICK_AND_GO});
                            // });
                         } else {
                             _scanErrorDialogShow();
                        }
                     });
                  }else{
                      _scanErrorDialogShow();
                  }
             });
        }

        function close() {
            return mDesign.dialog({
                focusOnOpen: false,
                clickOutsideToClose: false,
                templateUrl: 'views/templates/pickAndGo-close-dialog.html',
                controller: ['$scope', function ($scope) {
                    $scope.close = close;
                    $scope.cancel = cancel;

                    function close() {
                        mDesign.hide(true);
                    }

                    function cancel() {
                        mDesign.hide();
                    }
                }]
            }).then(function (toClose) {
                if (!toClose) return;

                $state.go('app.home');
            });
        }

        function _scanErrorDialogShow(){
            return mDesign.dialog({
                focusOnOpen: false,
                clickOutsideToClose: false,
                templateUrl: 'views/templates/scan-cart-barcode.html',
                controller: ['$scope', function ($scope) {
                    $scope.rescan = rescan;
                    $scope.cancel = cancel;

                    function rescan() {
                        mDesign.hide();
                        open();
                    }

                    function cancel() {
                        mDesign.hide();
                        return $state.go('app.home');
                    }
                }]
            });
        }
    }
]);