angular.module('mobilezuz').directive('spLineQuantity', ['$rootScope', 'Cart', 'Util', 'spUnits', 'SpCartService', 'spUnitsConfig', function ($rootScope, cart, util, spUnits, spCartService, spUnitsConfig) {
    return {
        restrict: 'E',
        replace: true,
        template: '' +
            '<div class="sp-line-quantity {{mode}}" id="line_quantity_{{line.product.id}}" ng-class="{' +
                '\'weighable\': line.product.soldBy === $root.PRODUCT_DISPLAY.WEIGHT.name || (line.product.isWeighable && !line.isCase && !line.product.weight), ' +
                '\'removed\': line.removed && !line.isProductOutOfStock,' +
                '\'show-trash-icon\': quantity <= intervalInUnit || line.removed || (line.isProductOutOfStock && isInCart) || ($root.util.isEnableSuggestion(line) && $root.util.isShowOutOfStock(line))' +
            '}">' +
                '<md-button class="product-action action-increase" ng-class="{\'disabled-out-of-stock\': (line.isProductOutOfStock && isInCart) || ($root.util.isEnableSuggestion(line) && $root.util.isShowOutOfStock(line))}" ng-click="line.removed || line.isProductOutOfStock ? addLineFromRemovedProduct(line) : plusQuantity()" aria-label="{{\'Increase the amount\' | translate}}" ng-disabled="(line.isProductOutOfStock && isInCart) || ($root.util.isEnableSuggestion(line) && $root.util.isShowOutOfStock(line))">' +
                    '<svg class="plus-icon" focusable="false"><use xlink:href="#sp-icons-quantity-plus"></use></svg>' +
                    '<svg class="add-line-icon" focusable="false"><use xlink:href="#sp-icons-cart"></use></svg>' +
                '</md-button>' +
                '<div class="input-wrapper" ng-class="{\'disabled-out-of-stock\': (line.isProductOutOfStock && isInCart) || ($root.util.isEnableSuggestion(line) && $root.util.isShowOutOfStock(line))}">' +
                    '<sp-vertical-value-slider ng-class="{\'smaller-width\': unit}" class="product-action" sp-model="quantity" sp-change="quantityChanged()" id="quantity-input-{{line.id}}" sp-lang="$root.config.language.culture"' +
                        ' sp-include-regex="{{line.product.soldBy === $root.PRODUCT_DISPLAY.WEIGHT.name || line.product.isWeighable && !line.product.weight && \'[0-9\\.]\' || \'[0-9]\' }}" sp-focus="selectContent($event);" sp-blur="onBlur();" sp-disabled="{{(line.isProductOutOfStock && isInCart) || ($root.util.isEnableSuggestion(line) && $root.util.isShowOutOfStock(line))}}"></sp-vertical-value-slider>' +
                    '<span class="unit"><span aria-hidden="true">{{unit.names | name}}</span>' +
                    '<span class="sr-only-element">{{unit.names[$root.config.language.culture][unit.names[$root.config.language.culture].length-1]}}</span></span>'+
                '</div>' +
                '<md-button ng-class="{\'out-of-stock-trash-icon\': (line.isProductOutOfStock && isInCart) || ($root.util.isEnableSuggestion(line) && $root.util.isShowOutOfStock(line))}" class="product-action" ng-click="line.removed ? $root.cart.removeLine(line) : minusQuantity(line)" aria-label="{{\'Reduce the amount\' | translate}}">' +
                    '<svg class="minus-icon" focusable="false"><use xlink:href="#sp-icons-quantity-minus"></use></svg>' +
                    '<svg class="trash-icon" focusable="false"><use xlink:href="#sp-icons-trash"></use></svg>' +
                '</md-button>' +
            '</div>',
        scope: {
            line: '=',
            isPack: '=?',
            onPlus: '&?',
            onMinus: '&?',
            onChange: '&?',
            mode: '@', // landscape/portrait
            allowTemporaryDeletion: '=?',
            isInCart: '@',
            source: '@'
        },
        link: function ($scope) {
            var _listeners = [];

            _init();

            $scope.grayBtnsDesigns = {white: true};

            $scope.plusQuantity = plusQuantity;
            $scope.minusQuantity = minusQuantity;
            $scope.quantityChanged = quantityChanged;
            $scope.onBlur = onBlur;
            $scope.selectContent = selectContent;
            $scope.addLineFromRemovedProduct = addLineFromRemovedProduct;
            var quantityChangedTimer;
            var quantityChangeTimeout = 200;
            var ACCURACY = 100;

            function plusQuantity() {
                if (_activateWithPreventCart($scope.onPlus)) return;
                var oldQuantity = $scope.line.quantity;
                var quantity = 1 * _getUnitInterval();
                var cartLineId = $scope.line.id;
                var productId = $scope.line.product.productId;
                var newQuantity = Math.round((quantity + $scope.quantity) * ACCURACY) / ACCURACY;
                var weightableQuantity;
                if (util.productSoldBy($scope.line.product, $scope.line.soldBy) === $rootScope.PRODUCT_DISPLAY.WEIGHT.name || ($scope.line.product.isWeighable && !$scope.line.product.weight)) {
                    weightableQuantity = $scope.unit ? spUnits.convert($scope.quantity, $scope.unit, {
                        to: $rootScope.defaultWeighableUnit
                    }).value : $scope.quantity;
                }
                var isLimitPassed = cart.checkHeavyLimitBeforeAddQuantity(cartLineId, productId, weightableQuantity || quantity);
                if (isLimitPassed === false) {
                    if (cart.lines && Object.keys(cart.lines).length === 0 &&
                        $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                        cart.addDeliveryFeeLineIfNeeded();
                    }
                    $scope.quantity = newQuantity;
                    quantityChanged('increase');
                } else {
                    $scope.quantity = oldQuantity;
                    $scope.line.quantity = oldQuantity;
                }
            }

            function minusQuantity(line) {
                if ((line.isProductOutOfStock && $scope.isInCart) || $rootScope.util.isEnableSuggestion(line) && $rootScope.util.isShowOutOfStock(line)) {
                    cart.removeLine(line, true);
                }
                if (_activateWithPreventCart($scope.onMinus)) return;
                var quantity = 1 * _getUnitInterval();
                var newQuantity = Math.round(( $scope.quantity - quantity) * ACCURACY) / ACCURACY;
                $scope.quantity = newQuantity;
                quantityChanged('decrease');

            }

            function quantityChanged(direction) {
                if(quantityChangedTimer){
                    clearTimeout(quantityChangedTimer);
                }
                //added timeout to prevent multiple calls to server during the client types the amount
                quantityChangedTimer = setTimeout(function() {callQuantityChanged(direction)}, quantityChangeTimeout);
                setTimeout(function() {$rootScope.$emit('cart.change')}, quantityChangeTimeout + 100);
            }

            function callQuantityChanged(direction) {
                var soldBy = util.productSoldBy($scope.line.product, $scope.line.soldBy);
                var oldQuantity = $scope.line.quantity;
                var quantity;
                var ACCURACY = 100; // use to prevent caculating directly on decimal value
                var intervalInUnit = _getUnitInterval();

                if ((util.isUnitsWeighable($scope.line.product) && soldBy === $rootScope.PRODUCT_DISPLAY.WEIGHT.name) || (!soldBy && util.isWeightQuantity($scope.line.product))) {
                    // check if new weight quantity have invalid decimal value
                    if(Math.round($scope.quantity * ACCURACY) % Math.round(intervalInUnit * ACCURACY) != 0) {
                        $scope.quantity = oldQuantity;
                        return;
                    }

                    quantity = $scope.unit ? spUnits.convert($scope.quantity, $scope.unit, {
                        to: $rootScope.defaultWeighableUnit
                    }).value : $scope.quantity;
                } else {
                     // check if new unit quantity have decimal value
                    if(($scope.quantity % 1) > 0) {
                        $scope.quantity = oldQuantity;
                        return;
                    }
                    
                    quantity = $scope.quantity;
                }

                var cartLineId = $scope.line.id;
                var productId = $scope.line.product.productId;
                if (direction === 'decrease') {
                    $scope.line.quantity = quantity;
                    $rootScope.$emit('cart.lines.quantityChanged.click', {lines: [$scope.line], direction: direction}, {source: $scope.source});
                    _callOnChangeAndCart();
                } else {
                    var result = cart.checkHeavyLimitBeforeAddQuantity(cartLineId, productId, quantity);
                    if (result === false) {
                        if (cart.lines && Object.keys(cart.lines).length === 0 &&
                            $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                            cart.addDeliveryFeeLineIfNeeded();
                        }
                        $scope.line.quantity = quantity;
                        $rootScope.$emit('cart.lines.quantityChanged.click', {lines: [$scope.line], direction: direction}, {source: $scope.source});
                        _callOnChangeAndCart();
                    } else {
                        $scope.quantity = oldQuantity;
                        $scope.line.quantity = oldQuantity;
                    }
                }

                if ($scope.orginalUnit && $scope.orginalUnit.type === 'mass' && $scope.orginalUnit.size === 1) {
                    var resizedUnit = _resizeUnit();
                    if(resizedUnit.size !== $scope.unit.size){
                        _init();
                    }
                }
            }

            function addLineFromRemovedProduct (line) {
                var quantity = 1;
                var cartLineId = line.id;
                var productId = line.product.productId;
                var result = cart.checkHeavyLimitBeforeAddQuantity(cartLineId, productId, quantity);
                if (result === false) {
                    if (cart.lines && Object.keys(cart.lines).length === 0 &&
                        $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                        cart.addDeliveryFeeLineIfNeeded();
                    }
                    cart.addLine(line, line.product && line.product.soldBy);
                } else {
                    line.quantity = 0;
                }
            }

            function onBlur() {
                if ($scope.quantity) return;

                _callOnChangeAndCart();
            }

            function selectContent(event) {
                event.target.select();
            }

            function _callOnChangeAndCart() {
                if (_activateWithPreventCart($scope.onChange)) return;

                if (!$scope.line.quantity) return cart.removeLine($scope.line, !$scope.allowTemporaryDeletion);


                var currentQuantitiy = $scope.line.quantity;
                cart.quantityChanged($scope.line, null, $scope.line.soldBy);
                if (currentQuantitiy != $scope.line.quantity) {
                    $scope.quantity = $scope.line.quantity;
                }
            }

            function _getUnitInterval() {
                return (util.productSoldBy($scope.line.product, $scope.line.soldBy) === $rootScope.PRODUCT_DISPLAY.WEIGHT.name || ($scope.line.product.isWeighable && !$scope.line.product.weight) ? $scope.intervalInUnit : 1) * ($scope.isPack ? $scope.line.product.branch.packQuantity : 1);
            }

            function _resizeUnit(){
                var units = spUnitsConfig.getGroup().units.mass.units;
                var minUnitSize = 1;
                var kgUnitSize = 1000;
                
                for (var i = 0; i < units.length; i++) {
                    if ($scope.line.quantity * kgUnitSize / units[i].size < minUnitSize) {
                       return units[i - 1];
                    }
                }

                return units[units.length - 1];
            }

            function _init() {
                $scope.unit = !$scope.line.isCase ? util.getProductUnit($scope.line.product, $scope.line.soldBy) : null;
                $scope.orginalUnit = $scope.orginalUnit || $scope.unit;
                if ($scope.orginalUnit && $scope.orginalUnit.type === 'mass' && $scope.orginalUnit.size === 1) {
                    var resizeUnit = _resizeUnit();
                    $scope.unit = resizeUnit; 
                }
                $scope.interval = spCartService.quantityInterval($scope.line, $scope.line.soldBy);
                $scope.intervalInUnit = parseFloat(($scope.unit ? spUnits.convert($scope.interval, $rootScope.defaultWeighableUnit, {
                    to: $scope.unit
                }).value : $scope.interval).toFixed(2));

                _setQuantity();
            }

            function _setQuantity() {
                var soldBy = util.productSoldBy($scope.line.product, $scope.line.soldBy);
                if ($scope.isInCart && util.isProductOutOfStock($scope.line)) {
                    $scope.quantity = 0;
                    return;
                }
                if ((util.isUnitsWeighable($scope.line.product) && soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name) || (!soldBy && !$scope.line.product.isWeighable)) {
                    $scope.line.quantity = parseInt($scope.line.quantity);
                    $scope.quantity = $scope.line.quantity;
                    return;
                }

                $scope.quantity = parseFloat(spUnits.convert($scope.line.quantity, $rootScope.defaultWeighableUnit, {
                    to: $scope.unit
                }).value.toFixed(2));
            }

            function _activateWithPreventCart(action) {
                var prevent = false;
                action && action({
                    $event: {
                        preventCart: function() {
                            prevent = true;
                        }
                    }
                });
                return prevent;
            }

            _listeners.push($scope.$watch('line', _init));
            _listeners.push($scope.$watch('line.soldBy', function (newSoldBy, oldSoldBy) {
                newSoldBy = newSoldBy || null;
                oldSoldBy = oldSoldBy || null;

                if (newSoldBy === oldSoldBy) return;
                _init();
                _callOnChangeAndCart();
            }));
            _listeners.push($scope.$watch('line.quantity', _setQuantity));

            util.destroyListeners($scope, _listeners);
        }
    };
}]);
