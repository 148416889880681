angular.module('mobilezuz')
    .directive('rowActionsSwipe', [function () {
        return {
            transclude: true,
            templateUrl: 'views/templates/row-actions-swipe.html',
            restrict: 'A',
            scope: {
                onRemove: '&?',
                onEdit: '&?'
            },
            link: function ($scope) {
                $scope.clickOnRemove = clickOnRemove;
                $scope.clickOnEdit = clickOnEdit;
                $scope.onSwipe = swipe;

                var isHasDelete = !!$scope.onRemove,
                    isHasEdit = !!$scope.onEdit;

                $scope.isDeleteActive = $scope.isEditActive = false;

                function swipe(dir) {
                    if (dir === 'right') {
                        $scope.isEditActive = isHasEdit && (!isHasDelete || !$scope.isDeleteActive);
                        $scope.isDeleteActive = false;
                    } else if (dir === 'left') {
                        $scope.isDeleteActive = isHasDelete && (!isHasEdit || !$scope.isEditActive);
                        $scope.isEditActive = false;
                    }
                }

                function clickOnRemove() {
                    $scope.onRemove && $scope.onRemove();
                }

                function clickOnEdit() {
                    $scope.onSwipe('left');
                    $scope.onEdit && $scope.onEdit();
                }
            }
        };
    }]);