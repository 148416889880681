'use strict';

angular.module('mobilezuz')
    .service('NagishLi', ['$window', '$rootScope', 'Config', '$compile', '$filter', NagishLi])

function NagishLi($window, $rootScope, Config, $compile, $filter) {
    this.init = init;

    function _initNagishLi() {
        if (!$window.$NagishLi)
        {
            var node = document.createElement('script');
            node.src = 'https://d226b0iufwcjmj.cloudfront.net/nagishli/nagishli.js?v=2'
            node.type = 'text/javascript';
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }

    function _setNagishLiPosition() {
        if ($window.moveNagishLi) {
            $window.moveNagishLi($window.nl_pos.toUpperCase());
        } else {
            setTimeout(_setNagishLiPosition, 500);
        }
    }

    function _addNagishCloseElem(nagishMenu) {
        //== Create a new element to inject to the Nagishli Menu
        var newNagishMenuElem = angular.element('<li class="close_nagish" ng-bind=":: \'nagish_close_component\' | translate"></li>');
        //== This element on Click will hide all Component
        newNagishMenuElem.bind('touchend', function() {

            //== We need to click to the icon to close the Component, the state is stored for refresh
            document.getElementById('NagishLiLogo').click();

            setTimeout(function() {
                //== Hide the Componentt
                angular.element(document.getElementById('NagishLiTag')).css('display', 'none');
            }, 500);
        });
        //== Appending the new menu item to the menu
        nagishMenu.appendChild(newNagishMenuElem[0]);

        //== we need to compile to see the text
        $compile(newNagishMenuElem)(angular.element(document.body).scope());
    }
    
    function _addAria() {
        var nagishLiBarStrip = document.querySelector('nagishli#NagishLiTag > div#NagishLiBar > div#NagishLiBarStrip');
        if (!!nagishLiBarStrip) {
            nagishLiBarStrip.setAttribute('role', 'button');
            nagishLiBarStrip.setAttribute('aria-label', $filter('translate')('Accessibility component'));
            nagishLiBarStrip.setAttribute('aria-expanded', nagishLiBarStrip.classList.contains('open'));

            var nagishLiBarStripEl = angular.element(nagishLiBarStrip);
            nagishLiBarStripEl.bind('click', function() {
                if (nagishLiBarStripEl.attr('aria-expanded') === 'false') {
                    nagishLiBarStripEl.attr('aria-expanded', 'true');
                } else {
                    nagishLiBarStripEl.attr('aria-expanded', 'false');
                }
            });
        }

        var listItemEl = document.querySelectorAll('#NagishLiMenus > li.dropdown');
        if(!!listItemEl) {
            listItemEl.forEach(function(element, index) {
                element.setAttribute('uniq-id', index)
                var hasAlt = element.hasAttribute('alt');
                if (hasAlt) {
                    element.setAttribute('alt', '');
                }
                element.setAttribute('role', 'menuitem');
                var dropdownTitle = element.getElementsByClassName("dropdown-toggle")[0].getElementsByClassName("dropdown-title")[0];
                var dropdownTitleId = 'nagishLi_' + encodeURIComponent(dropdownTitle.innerHTML);
                dropdownTitle.setAttribute('id', dropdownTitleId);
                element.setAttribute('aria-labelledby', dropdownTitleId);
                element.setAttribute('aria-haspopup', 'true');
                element.setAttribute('aria-expanded',  !!element.querySelector('.dropdown-menu.show'));
    
                var angularEl = angular.element(element);
                angularEl.bind('click', function() {
                    if (!!element.querySelector('.dropdown-menu.show')) {
                        angularEl.attr('aria-expanded', 'true');
                    } else {
                        angularEl.attr('aria-expanded', 'false');
                    }
                    var currentElementIndex = angularEl.attr('uniq-id');
                    listItemEl.forEach(function(otherElement) {
                        var angularOtherEl = angular.element(otherElement);
                        if (angularOtherEl.attr('uniq-id') !== currentElementIndex) {
                            angularOtherEl.attr('aria-expanded', 'false');
                        }
                    })
                });
            });
        }
        
        if(document.querySelector('#NLZoomIn')) {
            document.querySelector('#NLZoomIn').setAttribute('role', 'button');
            document.querySelector('#NLZoomIn').setAttribute('aria-label', $filter('translate')('Increase font size'));
        }
        if(document.querySelector('#NLZoomOut')) {
            document.querySelector('#NLZoomOut').setAttribute('role', 'button');
            document.querySelector('#NLZoomOut').setAttribute('aria-label', $filter('translate')('Decrease font size'));
        }
    }

    function _setupNagishElem(nagishInnerCntr) {
        var nagishMenu = document.getElementById('NagishLiMenus');
        nagishInnerCntr = nagishInnerCntr || 0;

        //== check if an element exists, if not we will retry 5 times with 2 seconds timer
        if (nagishMenu) {
            // This button will always be first in the tabbable order.
            nagishMenu.setAttribute('tabindex', 0);
            
            // Add close element control to NagishLi menu
            _addNagishCloseElem(nagishMenu);
            
            // Add aria tags and roles for list menu items
            _addAria();

        } else if (nagishInnerCntr < 5) {
            setTimeout(function() {
                //== Retrying the process for 5 times if the Component is not loaded yet
                _setupNagishElem(nagishInnerCntr + 1);
            }, 500);
        }
    }

    function init() {
        Config.waitForInit().then(function () {
            if (!Config.retailer.isAccessibilityOn) {
                return;
            }

            $window.nl_lang = Config.language.culture === 'he' ? Config.language.culture : 'en'; // nagishli only supports he & en
            $window.nl_pos = 'b' + Config.language.direction[0];
            $window.nl_dir = 'https://d226b0iufwcjmj.cloudfront.net/nagishli/nl-files/'

            _initNagishLi();
            _setupNagishElem();
            _setNagishLiPosition($window.nl_lang);

            $rootScope.$on('config.language.set', function (event, language) {
                $window.nl_pos = 'b' + language.direction[0];
                _setNagishLiPosition();
            });
        });
    }
}