(function (angular) {
    angular.module('mobilezuz')
        .filter('cartLineQuantity', ['$filter', function ($filter) {
            var _productWeightFilter = $filter('productWeight');

            /**
             * @param {Object} line
             */
            return function (line, actualQuantity, soldBy) {
                if (line.isProductOutOfStock) {
                    return 0;
                }
                if (line.isPseudo) {
                    return line.quantity || 0;
                }
                return line.isCase || !line.product.isWeighable || (line.product.isWeighable && line.product.weight && (!soldBy || soldBy === 'Unit')) ? (actualQuantity || line.quantity) : _productWeightFilter(line.product, line.isCase, null, actualQuantity || line.quantity, soldBy);
            };
        }]);
})(angular);