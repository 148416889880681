(function (angular) {

    angular.module('mobilezuz')
        .config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
            $stateProvider.state('app.smartList', {
                url: '/smart-list',
                data: {
                    stateAccess: PAGE_ACCESS.LOGGED_IN,
                    name: 'smart-list'
                },
                metaTags: {
                    title: [ '$filter', function ($filter) {
                        return $filter('translate')('Products I usually purchase');
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'views/smart-list.html',
                        controller: 'SmartListCtrl as smartListCtrl',
                        resolve: {
                            smartList: ['Api', function (Api) {
                                return Api.request({
                                    method: 'GET',
                                    url: '/v2/retailers/:rid/branches/:bid/users/:uid/smart-list'
                                }).catch(function(err) {
                                    return {
                                        items: [],
                                        error: (err.response || {}).error
                                    };
                                });
                            }]
                        }
                    }
                }
            });
        }])
        .controller('SmartListCtrl', ['$timeout', '$state', '$scope', '$rootScope', '$filter', 'Cart', 'Util', 'smartList', 'mDesign', 'SP_SERVICES', 'Config',
            function ($timeout, $state, $scope, $rootScope, $filter, cart, util, smartList, mDesign, SP_SERVICES, Config) {
                var smartListCtrl = this,
                    _listeners = [],
                    _nameFilter = $filter('name');

                smartListCtrl.smartList = smartList;
                smartListCtrl.selectedLines = 0;
                smartListCtrl.importSmartListToCart = importSmartListToCart;
                smartListCtrl.plusQuantity = plusQuantity;
                smartListCtrl.minusQuantity = minusQuantity;
                smartListCtrl.selectItem = selectItem;
                smartListCtrl.sortByCategories = Config.retailer.settings.sortCartByCategories === 'true';
                smartListCtrl.sortedListByTree = [];
                smartListCtrl.isOutOfStock = isOutOfStock;
                smartListCtrl.isEnableSuggestion = isEnableSuggestion;
                smartListCtrl.showDisclaimer = showDisclaimer;

                //== If Cart did'n finished an API call we subscribing to event
                if(!cart.sendingSucceeded) {
                    _listeners.push($rootScope.$on('cart.update.complete', function () {
                        _init();
                    }));
                } else {
                    _init();
                }

                function _init() {
                    _setSoldBy();
                    _setItemsToCategories();
                    cart.setCartLineReplacements(smartListCtrl.items);
                    !$rootScope.$$phase && $scope.$apply();
                }

                function _setItemsToCategories() {
                    if (!smartListCtrl.smartList || !smartListCtrl.smartList.items || !Array.isArray(smartListCtrl.smartList.items)) {
                        smartListCtrl.items = [];
                        return;
                    }

                    var cartProducts = _getCartProductsQuantity();
                    smartListCtrl.items = [];
                    angular.forEach(smartListCtrl.smartList.items, function(line) {
                        if (!line.product || (!line.isPseudo && (!line.product.family ||
                            !line.product.family.categoriesPaths || !line.product.family.categoriesPaths.length))) {
                            return;
                        }

                        line.inCart = !!cartProducts[line.product.id];
                        line.inCartQuantity = cartProducts[line.product.id] || 0;

                        // set weighable units for suitable products
                        if (line.product.isWeighable && line.product.weight && !line.weightQuantity) {
                            line.weightQuantity = line.quantity;
                            if (!line.product.soldBy || line.product.soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name) {
                                line.quantity = Math.round(line.weightQuantity / line.product.weight);
                            }
                        }

                        line.quantity = _fixQuantity(line.quantity);
                        line.type = SP_SERVICES.CART_LINE_TYPES.PRODUCT;
                        line.isProductOutOfStock = util.isProductOutOfStock(line);
                        line.isNeedToShowOutOfStockLabel = util.isNeedToShowOutOfStockLabel(line);
                        smartListCtrl.items.push(line);

                        if (smartListCtrl.sortByCategories) {
                            smartListCtrl.sortedCartByCategories = cart.sortCartByCategories(smartListCtrl.items, []);
                            smartListCtrl.sortedListByTree = cart.sortByTree(smartListCtrl.sortedCartByCategories);
                        }
                    });
                }
                
                function _getCartProductsQuantity() {
                    var cartProducts = cart.getLines(),
                        products = {};

                    if(cartProducts && Array.isArray(cartProducts) && cartProducts.length) {
                        angular.forEach(cartProducts, function (cartLine) {
                            if(cartLine && cartLine.product && cartLine.product.id) {
                                products[cartLine.product.id] = !cartLine.removed && cartLine.quantity || 0;
                            }
                        });
                    }

                    return products;
                }


                function importSmartListToCart() {
                    var linesToAdd = [],
                        linesWithoutPropertySelected = [];

                    angular.forEach(smartListCtrl.items, function(item) {
                        if (item.product.productProperties && item.product.productProperties.length && !item.productPropertyValueId && item.actionsChecked) {
                            linesWithoutPropertySelected.push(_nameFilter(item.product.names).short);
                        }
                    });
                    angular.forEach(smartListCtrl.items, function(item) {
                        if (item.quantity && item.actionsChecked) {
                            linesToAdd.push({
                                isCase: item.isCase,
                                product: item.product,
                                quantity: item.quantity,
                                productPropertyValueId: item.productPropertyValueId || null,
                                comments: item.comments
                            });
                        }
                    });

                    if ( !(linesToAdd && linesToAdd.length) ) {
                        return mDesign.alert('{{\'No products were selected\' | translate}}');
                    }

                    if(linesWithoutPropertySelected.length) {
                        return mDesign.alert('{{\'Product properties need to be selected for\' | translate}}' + ': ' + linesWithoutPropertySelected.join(', '));
                    }

                    var isLimitPassed = cart.checkHeavyLimitBeforeAddLines(linesToAdd);
                    if (isLimitPassed === false) {
                        //== Go to cart after products added
                        if (cart.lines && Object.keys(cart.lines).length === 0 &&
                            $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                            cart.addDeliveryFeeLineIfNeeded();
                        }
                        cart.addLines(linesToAdd, SP_SERVICES.SOURCES.SMART_LIST).then(function(){
                            $state.go('app.cart');
                        });
                    }
                }

                function _getQuantityInterval(item) {
                    return item.soldBy === $rootScope.PRODUCT_DISPLAY.WEIGHT.name || (item.isWeighable && !item.weight) ? (item.weight || item.unitResolution ? item.weight || item.unitResolution : 0.5) : 1;
                }

                function plusQuantity(item, $event) {
                    $event.preventCart();
                    var isLimitPassed = cart.checkHeavyLimitBeforeAddLine(item);
                    if (isLimitPassed === false) {
                        if (cart.lines && Object.keys(cart.lines).length === 0 &&
                            $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                            cart.addDeliveryFeeLineIfNeeded();
                        }
                        item.quantity += _getQuantityInterval(item.product);
                        item.quantity = _fixQuantity(item.quantity);
                    }
                }

                function minusQuantity(item, $event) {
                    $event.preventCart();
                    item.quantity -= _getQuantityInterval(item.product);
                    item.quantity = _fixQuantity(item.quantity);
                    if (item.quantity <= 0) {
                        item.quantity = 0;
                    }
                }

                function _fixQuantity(quantity) {
                    return Number(Number(quantity).toFixed(2));
                }

                function selectItem(item) {
                    smartListCtrl.selectedLines += item.actionsChecked ? -1 : 1;
                    smartListCtrl.selectedLines = smartListCtrl.selectedLines < 0 ? 0 : smartListCtrl.selectedLines;

                    item.actionsChecked = !item.actionsChecked;
                }

                function isOutOfStock(item) {
                    return item.isProductOutOfStock || item.isNeedToShowOutOfStockLabel;
                }

                function isEnableSuggestion(item) {
                    var suggestions = item.product._suggestions;
                    return suggestions && suggestions.length && suggestions[0].id;
                }

                function showDisclaimer(evt, productId) {
                    util.showBottleDepositDisclaimer(evt, productId)
                }

                function _setSoldBy() {
                    angular.forEach(smartListCtrl.smartList.items, function (item) {
                        item.soldBy = null;
                        if(!item.product){
                            return;
                        }
                        item.product.soldBy = null;
                        if (item.product && util.isUnitsWeighable(item.product)) {
                            var cartLine = _getLine(item.product);
                            if (!cartLine) {
                                var soldBy;
                                if (!!item.product.productDisplayModeId) {
                                    switch (item.product.productDisplayModeId) {
                                        case $rootScope.PRODUCT_DISPLAY.UNIT.id:
                                            soldBy = $rootScope.PRODUCT_DISPLAY.UNIT.name;
                                            break;
                                        case $rootScope.PRODUCT_DISPLAY.WEIGHT.id:
                                            soldBy = $rootScope.PRODUCT_DISPLAY.WEIGHT.name;
                                            break;
                                        default:
                                            soldBy = item.product.isWeighable ? (item.product.weight ? $rootScope.PRODUCT_DISPLAY.UNIT.name : $rootScope.PRODUCT_DISPLAY.WEIGHT.name) : null;
                                    } 
                                } else {
                                    soldBy = item.product.isWeighable ? (item.product.weight ? $rootScope.PRODUCT_DISPLAY.UNIT.name : $rootScope.PRODUCT_DISPLAY.WEIGHT.name) : null;
                                }
                                item.soldBy = soldBy;
                            } else {
                                item.soldBy = cartLine.soldBy;
                            }
                            item.product.soldBy = item.soldBy;
                        }
                    });
                }

                function _getLine(product) {
                    var cartLines = cart.getLines(),
                        cartLine;
                    if (!cartLines) return;
                    angular.forEach(cartLines, function (line) {
                        if (line.product && line.product.id === product.id) {
                            cartLine = line;
                        }
                    })
                    return cartLine;
                }

                util.destroyListeners($scope, _listeners);

            }]);
})(angular);
