(function (angular) {
    angular.module('mobilezuz')
        .directive('spStickyActions', ['$window', '$rootScope', 'Util', function ($window, $rootScope, Util) {
            return {
                restrict: 'A',
                link: function ($scope, $element) {
                    var $scrollableElement,
                        $productElement,
                        productElementOffsetTop,
                        productElementBottomPadding,
                        actionsElementOffsetTop,
                        $nagishIconElement,
                        nagishIconDefaultMarginBottom,
                        nagishIconChangedMarginBottom = '90px',
                        viewportHeight = $window.innerHeight,
                        stickyClassName = 'sticky-actions',
                        previousState = false,
                        currentState = false,
                        _listeners = [];

                    _init();

                    function _checkIsSticky() {
                        previousState = currentState; //== save previous state before calculating new currentState
                        currentState = $scrollableElement[0].scrollTop + viewportHeight < actionsElementOffsetTop + $element[0].offsetHeight + productElementOffsetTop;
                        if(currentState !== previousState) {
                            _stickElement(currentState);
                            _moveNagishIcon(currentState);
                        }
                    }

                    function _stickElement(currentState) {
                        if(currentState) {
                            $productElement.addClass(stickyClassName);
                        } else {
                            $productElement.removeClass(stickyClassName);
                        }
                    }

                    function _moveNagishIcon(currentState) {
                        $nagishIconElement && $nagishIconElement.style.setProperty('margin-bottom', currentState ? nagishIconChangedMarginBottom : nagishIconDefaultMarginBottom, 'important');
                    }

                    function _setup() {
                        $scrollableElement = angular.element(document.querySelector('.product-dialog-content'));
                        $productElement = angular.element(document.querySelector('.product'));
                        productElementOffsetTop = $productElement[0].offsetTop;
                        actionsElementOffsetTop = $element[0].offsetTop;
                        productElementBottomPadding = parseInt( $window.getComputedStyle($productElement[0]).getPropertyValue('padding-bottom') );
                        $nagishIconElement = document.getElementById('NagishLiBar');
                        nagishIconDefaultMarginBottom = $nagishIconElement && $window.getComputedStyle($nagishIconElement).getPropertyValue('margin-bottom');
                        previousState = false;
                        currentState = false;
                    }

                    function _init() {
                        setTimeout(function () {
                            _setup();
                            _checkIsSticky();
                            $scrollableElement.unbind('scroll');
                            $scrollableElement.bind('scroll', _checkIsSticky);
                        }, 500);
                    }

                    //== when another product loaded we need to remove stickiness from element to calculate it's real position
                    function _onProductChange() {
                        _stickElement(false);
                        _init();
                    }

                    _listeners.push($rootScope.$on('dialogProduct.change', _onProductChange));
                    Util.destroyListeners($rootScope, _listeners);
                }
            };
        }]);
})(angular);