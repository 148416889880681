angular.module('mobilezuz').service('Loyalty', [
    'Config', 'Api',
    function(Config, Api) {
        var self = this;

        self.getLoyaltyDriver = getLoyaltyDriver;
        self.getSpecialsLoyalty = getSpecialsLoyalty;
        self.getLastTransactions = getLastTransactions;
        self.isCashbackLoyaltyClub = isCashbackLoyaltyClub;
        self.getRetailerClubConfig = getRetailerClubConfig;
        self.getRetailerClubDriver = getRetailerClubDriver;
        function getLoyaltyDriver(loyaltyClubDriverId) {
            return Config.retailer.loyaltyClubDrivers.find(function(item) {
                return item.loyaltyClubDriverId === loyaltyClubDriverId
            })
        }

        function isCashbackLoyaltyClub(loyaltyClubId) {
            return Config.retailer.loyaltyClubDrivers.find(function(loyaltyClubDriver) {
                return loyaltyClubDriver.clientConfig.loyaltyClubs && loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId] && loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId].pointsToCashback;
            });
        }

        function getRetailerClubDriver() {
            return !!Config.retailer.loyaltyClubDriver && Config.retailer.loyaltyClubDriver;
        }

        function getRetailerClubConfig(clubId) {
            var retailerClubDriver = getRetailerClubDriver(),
                retailerClub = null;
            if (retailerClubDriver.loyaltyClubIds.includes(clubId)) {
                angular.forEach(retailerClubDriver.clientConfig.loyaltyClubs, function (loyaltyClub, loyaltyClubId) {
                    if (+loyaltyClubId === clubId && loyaltyClub.pointsToCashback) {
                        retailerClub = loyaltyClub;
                    }
                })
                return retailerClub;
            }
        }

        function getSpecialsLoyalty(loyaltyClubIds, size) {
            return Api.request({
                method: 'GET',
                url: '/v2/retailers/:rid/branches/:bid/specials',
                params: {
                    size: size,
                    filters: {
                        "must": {
                            "term": {
                                "displayOnWeb":true,
                                "loyaltyClubsIds": loyaltyClubIds
                            },
                            "exists": ["loyaltyClubsIds"]
                        }
                    }
                }
            });
        }

        function getLastTransactions(loyaltyClubId){
            return Api.request({
                method: 'GET',
                url: '/v2/retailers/:rid/users/:uid/loyalty-clubs/' + loyaltyClubId + '/_lastTransactions'
            }).then(function(result){
                return result;
            });
        }
    }]);




