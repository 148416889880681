(function (angular, app) {
    'use strict';

    var TIMES_OPTIONS = {
        LOCAL_DELIVERY: 'Local Delivery',
        SHIPPING_SERVICE: 'Shipping'
    };

    var MINUTE_IN_MS = 1000 * 60,
        DAY_IN_MS = MINUTE_IN_MS * 60 * 24;

    angular.module('mobilezuz')
        .controller('EditOrderTimeslotCtrl', ['$rootScope', '$scope', 'SpDeliveryAreasService', 'Util', 'SpDeliveryTimesService',
            'Config', 'DELIVERY_TIMES_TYPES', '$q', 'SP_SERVICES', '$filter', 'Orders', 'Retailer', ctrl]);

    function ctrl($rootScope, $scope, SpDeliveryAreasService, util, SpDeliveryTimesService,
        config, DELIVERY_TIMES_TYPES, $q, SP_SERVICES, $filter, Orders, Retailer
    ) {
        var editOrderTimeslotCtrl = this,
            updateOrderV2Ctrl = $scope.updateOrderV2Ctrl,
            dateFilter = $filter('date'),
            _oldStartDate;

        //properties
        editOrderTimeslotCtrl.checkingInProgress = false;
        editOrderTimeslotCtrl.pickerIndex = 0;

        // function
        editOrderTimeslotCtrl.setDeliveryTimes = setDeliveryTimes;
        editOrderTimeslotCtrl.isAbleUpdate = isAbleUpdate;
        editOrderTimeslotCtrl.isSpecialShown = isSpecialShown;

        _init();

        // ----------------------------------------------------        
        function _init() {
            return _getOrderInEdit().then(function () {
                updateOrderV2Ctrl.disableForceShowLoadingDialog();
                _setOrder(editOrderTimeslotCtrl.order);  
            })
        }

        function _getOrderInEdit(){
            var orderPromise;
            if (!Orders.orderInEdit) {
                orderPromise = Orders.getOrderDetails(updateOrderV2Ctrl.orderId);
            } else {
                orderPromise = $q.resolve(Orders.orderInEdit);
            }
            return orderPromise.then(function (order) {
                editOrderTimeslotCtrl.order = order;
            });
        }

        /**
         * Set order data to edit order timeslot controller
         * @param {Order} order - order data
         * @private
         */
        function _setOrder(order) {
            editOrderTimeslotCtrl.timesSlots = [];
            editOrderTimeslotCtrl.areaId = order.branchAreaId;
            editOrderTimeslotCtrl.deliveryType = order.branchDeliveryTypeId;
            editOrderTimeslotCtrl.limitDate = util.calculatePostponeUpdateDateTime(order.timePlaced);

            // getting picking branch address
            SpDeliveryAreasService.getAreas().then(function (areas) {
                var area = areas.find(function (area) {
                    return area.id === editOrderTimeslotCtrl.areaId
                });

                editOrderTimeslotCtrl.pickingAddress = SpDeliveryAreasService.getAreaAddressText(area);
            });

            setDeliveryTimes(order);
        }

        /**
         * Set delivery times
         * @public
         *
         * @param {Object} [options={}] - options
         * @param {boolean} [options.scroll=false] - scroll to next week
         * @param {Date} [options.startDate] - start date
         *
         * @returns {Promise}
         */
        function setDeliveryTimes(options) {
            options = options || {};
            return Retailer.getRetailerSettings()
                .then(function (retailer) {
                    if (options.scroll) {
                        if (editOrderTimeslotCtrl.getDeliveryTimeInProgress) {
                            return $q.reject();
                        }

                        var today = SpDeliveryTimesService.now();
                        today.setUTCHours(0, 0, 0, 0);

                        if (editOrderTimeslotCtrl.timesSlots && editOrderTimeslotCtrl.timesSlots.length) {
                            options.startDate = new Date(new Date(editOrderTimeslotCtrl.timesSlots[editOrderTimeslotCtrl.timesSlots.length - 1].date).getTime() + DAY_IN_MS);
                        } else {
                            options.startDate = new Date(today);
                        }

                        if (options.startDate > editOrderTimeslotCtrl.limitDate) {
                            return $q.reject();
                        }
                    }

                    return getDeliveryTimes(undefined, options)
                        .then(function (response) {
                            updateOrderV2Ctrl.delivery.branchArea = response;
                            var times = response.area.timesObject.times;
                            if (response.area && angular.isArray(response.area.areas) && response.area.areas.length > 1) {
                                var selectedPickupArea;
                                angular.forEach(response.area.areas, function (area) {
                                    if (area.id === updateOrderV2Ctrl.delivery.branchArea.area.id) {
                                        selectedPickupArea = area;
                                    }
                                });
                                if (selectedPickupArea) {
                                    times = selectedPickupArea.times;
                                }
                            }
                            var timeSlots = limitDeliveryTimeSlots(times.localDelivery[DELIVERY_TIMES_TYPES.REGULAR], editOrderTimeslotCtrl.limitDate);
                            setDefaultDeliveryTime(timeSlots);
                            Array.prototype.push.apply(editOrderTimeslotCtrl.timesSlots, timeSlots);
                        });
                });
        }

        /**
         * Limit delivery time slots to dates that are less than or equal to the given limit date
         *
         * @param {Object[]} timeSlot - array of time slots
         * @param {Date} limitDate - limit date
         *
         * @returns {Object[]} - limited time slots
         */
        function limitDeliveryTimeSlots(timeSlot, limitDate) {
            var tempTimeSlots = [];
            angular.forEach(timeSlot, function (dayTimes) {
                if (new Date(dayTimes.date).getTime() > editOrderTimeslotCtrl.limitDate) {
                    return;
                }
                tempTimeSlots.push(dayTimes);
            });
            return tempTimeSlots;
        }


        /**
         * Set default delivery time for the order
         *
         * @param {Object[]} timeslot - array of time slots
         */
        function setDefaultDeliveryTime(timeslot) {
            var firstActiveDeliveryDay,
                firstActiveDeliveryTime;
            if (!updateOrderV2Ctrl.delivery.time) {
                angular.forEach(timeslot, function (date) {
                    angular.forEach(date.times, function (time) {
                        if (!firstActiveDeliveryTime) {
                            firstActiveDeliveryTime = time;
                            firstActiveDeliveryDay = date;
                        }

                        if (time.newFrom === editOrderTimeslotCtrl.order.shippingTimeFrom
                            && time.newTo === editOrderTimeslotCtrl.order.shippingTimeTo) {
                                updateOrderV2Ctrl.delivery.time = time;
                                updateOrderV2Ctrl.delivery.times = date;
                        }
                    });
                });

                if (timeslot.length && timeslot.length > 0 && !updateOrderV2Ctrl.delivery.time) {
                    updateOrderV2Ctrl.delivery.time = firstActiveDeliveryDay ? firstActiveDeliveryDay : timeslot[0];
                    updateOrderV2Ctrl.delivery.times = firstActiveDeliveryTime ? firstActiveDeliveryTime : timeslot[0];
                }
            }
        }

        /**
         * Get delivery times from server
         *
         * @param {Object} [defer] - deferred object
         * @param {Object} [options] - options object
         * @param {Boolean} [options.scroll] - if true then scroll to the top of the page
         * @param {Date} [options.startDate] - start date for delivery times
         * @returns {Promise} - promise with delivery times
         */
        function getDeliveryTimes(defer, options) {
            options = options || {};

            defer = defer || $q.defer();

            var params = {
                languageId: config.language.id,
                areaId: editOrderTimeslotCtrl.areaId,
                viewAllTimes: true
            };

            if (options.scroll && options.startDate) {
                if (editOrderTimeslotCtrl.getDeliveryTimeInProgress) {
                    return $q.reject();
                }
                params.startDate = options.startDate;
            }

            editOrderTimeslotCtrl.getDeliveryTimeInProgress = true;
            params.maxDisplayDays = editOrderTimeslotCtrl.limitDate;
            SpDeliveryTimesService.getTimes(editOrderTimeslotCtrl.deliveryType, params).then(function (response) {
                updateOrderV2Ctrl.area = response;
                return defer.resolve(response);
            }).catch(function (err) {
                defer.reject(err);
            }).finally(function () {
                editOrderTimeslotCtrl.getDeliveryTimeInProgress = false;
            });

            return defer.promise;
        }

        // ----------------------------------------------------

        /**
         * Check if delivery time was changed
         * @returns {Boolean} true if delivery time was changed, otherwise false
         */
        function isAbleUpdate() {
            return updateOrderV2Ctrl.delivery.time
                && updateOrderV2Ctrl.delivery.time.newFrom !== editOrderTimeslotCtrl.order.shippingTimeFrom
                && updateOrderV2Ctrl.delivery.time.newTo !== editOrderTimeslotCtrl.order.shippingTimeTo;
        }

        function isSpecialShown(special) {
            if (Config.showCouponsSpecials) {
                return true;
            }

            return !special.isCoupon;
        }
    };
})(angular);
