(function(angular) {
	angular.module('mobilezuz')
		.config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
			$stateProvider.state('app.policies', {
				url: '/policies',
				data: {
					stateAccess: PAGE_ACCESS.ALLOW,
					bodyClass: 'info-text',
					metaTags: {
						title: 'Privacy policy'
					}
				},
				views: {
					'@': {
						templateUrl: 'views/bind-html.html',
						controller: 'PolicyCtrl'
					}
				}
			});
		}]).controller('PolicyCtrl', ['$rootScope', '$scope', 'Retailer',
		function($rootScope, $scope, Retailer) {
			$rootScope.title = 'Privacy policy';
			Retailer.getRetailerSettings().then(function(response) {
				$scope.content = response.settings.privacyText;
			});
		}]);
})(angular);