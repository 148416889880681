(function (angular) {

    var POPUP_TIMEOUT_MINUTES = 5; //== time between displaying popup next time after terms where successfully confirmed

    angular.module('mobilezuz').service('ShippingTermsPopup', ['$q', '$rootScope', '$state', '$window', 'mDesign', 'Config', 'Api', 'SpCartService',
        function ($q, $rootScope, $state, $window, mDesign, Config, Api, SpCartService) {
            var self = this;

            angular.extend(self, {
                showPopup: showPopup,
                showWithRedirect: showWithRedirect,
                isShowPopup: isShowPopup
            });

            /**
             * Display Shipping Terms popup
             * @public
             *
             * @returns {Promise}
             */
            function showPopup() {

                if(!isShowPopup()) {
                    return $q.resolve(true);
                }

                var languageId = $rootScope.config.language.id,
                    shippingTerms = _initShippingTerms(languageId);

                //== If there is no at least one condition - proceed without displaying the Popup
                if(!shippingTerms.conditionsList[languageId] || shippingTerms.conditionsList[languageId].length === 0) {
                    return $q.resolve(true);
                }

                return mDesign.dialog({
                    controller: ['$scope', 'mDesign', function ($scope, mDesign) {
                        var shippingTermsController = this;

                        shippingTermsController.title = shippingTerms.popupTitle[languageId] && shippingTerms.popupTitle[languageId].title || '';
                        shippingTermsController.description = shippingTerms.popupDescription[languageId] && shippingTerms.popupDescription[languageId].title || '';
                        shippingTermsController.conditions = shippingTerms.conditionsList[languageId];
                        shippingTermsController.marked = [];
                        shippingTermsController.displayError = false;

                        $scope.check = function() {
                            //== get number of marked checkboxes
                            var checkArray = shippingTermsController.marked.filter(function (value) {
                                return !!value;
                            });

                            if(shippingTermsController.conditions && checkArray.length !== shippingTermsController.conditions.length) {
                                shippingTermsController.displayError = true;
                            } else {
                                //== save current state to DB
                                _sendConfirmToServer(shippingTerms, languageId).then(function() {
                                    //== hide popup and proceed
                                    mDesign.hide(true);
                                });

                            }
                        };

                        $scope.close = function() {
                            mDesign.hide(false);
                        };
                    }],
                    controllerAs: 'shippingTermsController',
                    templateUrl: 'views/partials/shipping-terms-popup.html',
                    clickOutsideToClose: false
                }).then(function(confirmedAllOptions) {
                    if(confirmedAllOptions) {
                        $window.localStorage.setItem('ShippingTermConfirmTime', new Date().getTime());
                    }
                    return $q.resolve(confirmedAllOptions);
                });
            }

            /**
             * Display Shipping Terms popup and redirect if not confirmed
             * @public
             *
             * @param {String} [redirectTo]
             */
            function showWithRedirect(redirectTo) {
                showPopup().then(function(isProceed) {
                    if(!isProceed) {
                        $state.go(redirectTo || 'app.home');
                    }
                });
            }

            /**
             * Save user's confirm data to DB
             * @private
             *
             * @param {Object} shippingTerms
             * @param {Number} languageId
             *
             * @returns {Promise}
             */
            function _sendConfirmToServer(shippingTerms, languageId) {
                var data = {
                    cartId: SpCartService.serverCartId || 0,
                    languageId: languageId,
                    labels: []
                };

                if(shippingTerms.popupTitle[languageId] && shippingTerms.popupTitle[languageId].id) {
                    data.labels.push(shippingTerms.popupTitle[languageId].id);
                }

                if(shippingTerms.popupDescription[languageId] &&  shippingTerms.popupDescription[languageId].id) {
                    data.labels.push(shippingTerms.popupDescription[languageId].id);
                }

                if(shippingTerms.conditionsList[languageId] && shippingTerms.conditionsList[languageId].length) {
                    shippingTerms.conditionsList[languageId].forEach(function(condition) {
                        condition.id && data.labels.push(condition.id);
                    });
                }

                return Api.request({
                    method: 'PATCH',
                    url: '/v2/retailers/:rid/delivery',
                    params: data
                }, {
                    fireAndForgot: true
                });
            }

            /**
             * Check if popup should be displayed
             * @private
             *
             * @returns {Boolean}
             */
            function isShowPopup() {
                //== popup option disabled in Admin
                if(Config.retailer.settings.shippingTermsPopupEnable !== 'true') {
                    return false;
                }

                //== check last time this popup was successfully confirmed
                var confirmDate = $window.localStorage.getItem('ShippingTermConfirmTime');

                //== never confirmed
                if(!confirmDate) {
                    return true;
                }

                var nowDate = new Date().getTime();
                var timeout = 1000 * 60 * POPUP_TIMEOUT_MINUTES; //== one minute

                return nowDate - confirmDate >= timeout;
            }

            /**
             * Fill shippingTerms object with data we got from Retailer Settings
             * @private
             *
             * @param {Number} languageId
             *
             * @returns {Object}
             */
            function _initShippingTerms(languageId) {

                var terms = Config.retailer.shippingTermsAndConditions,
                    shippingTerms = {
                        popupTitle: {},
                        popupDescription: {},
                        conditionsList: {}
                    };

                if(terms && Array.isArray(terms)) {
                    for(var i = 0; i < terms.length; i++) {
                        var term = terms[i];
                        if(term.id) {
                            switch(term.typeId) {
                                case 1:
                                    shippingTerms.popupTitle[term.languageId] = {
                                        title: term.title,
                                        id: term.id
                                    };
                                    break;
                                case 2:
                                    shippingTerms.popupDescription[term.languageId] = {
                                        title: term.title,
                                        id: term.id
                                    };
                                    break;
                                case 3:
                                    shippingTerms.conditionsList[term.languageId] = shippingTerms.conditionsList[term.languageId] || [];
                                    shippingTerms.conditionsList[term.languageId].push({
                                        title: term.title,
                                        id: term.id
                                    });
                                    break;
                            }
                        }
                    }
                }

                _fillMissingLanguages(shippingTerms, languageId);

                return shippingTerms;
            }

            /**
             * Fill title, description and conditions if missing for current language - all missing languages will be filled from default or first existing language
             * @private
             *
             * @param {Object} shippingTerms
             * @param {Number} languageId
             */
            function _fillMissingLanguages(shippingTerms, languageId) {

                //== get Retailer's default language
                var defaultLanguageId = Config.retailer.languageId;

                //== copy object values to current language
                _setTermsByLanguage(shippingTerms.popupTitle, languageId, defaultLanguageId);
                _setTermsByLanguage(shippingTerms.popupDescription, languageId, defaultLanguageId);
                _setTermsByLanguage(shippingTerms.conditionsList, languageId, defaultLanguageId);
            }

            /**
             * Fill missing language for data object
             * @private
             *
             * @param {Object} shippingTermsObject
             * @param {Number} currentLanguageId
             * @param {Number} defaultLanguageId
             */
            function _setTermsByLanguage(shippingTermsObject, currentLanguageId, defaultLanguageId) {
                //== data already exists on current language
                if(!shippingTermsObject || shippingTermsObject[currentLanguageId]) {
                    return;
                }

                //== if data exists on default language - fill it from default language
                if(shippingTermsObject[defaultLanguageId]) {
                    shippingTermsObject[currentLanguageId] = shippingTermsObject[defaultLanguageId];
                    return;
                }

                //== let's check what language exist on current object and take the first one
                Object.keys(shippingTermsObject).forEach(function (lang) {
                    if(shippingTermsObject[lang]) {
                        shippingTermsObject[currentLanguageId] = shippingTermsObject[lang];
                        return;
                    }
                });
            }
        }]);

})(angular);