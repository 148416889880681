angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
	$stateProvider.state("app.forgottenPassword", {
		url: "/forgotten-password",
		data: {
			stateAccess: PAGE_ACCESS.NOT_LOGGED_IN,
			bodyClass: "forgotten-password",
			metaTags: {
				title: "Password reset",
			},
		},
		views: {
			"@": {
				templateProvider: [
					"Config",
					"$templateRequest",
					function (Config, $templateRequest) {
						var templateUrl = 'views/forgottenPassword.html'
						if(Config.retailer.loyaltyClubDriver && Config.retailer.loyaltyClubDriver.clientConfig && Config.retailer.loyaltyClubDriver.clientConfig.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty) {
							templateUrl = 'views/forgottenPasswordV2.html'
						}
						return $templateRequest(templateUrl)
					},
				],
				controller: "ForgottenPasswordCtrl as forgottenPasswordCtrl",
			},
		},
	});
}]).controller('ForgottenPasswordCtrl', ['$filter', '$rootScope', '$window', '$scope', '$state', '$stateParams', 'Api', 'User', 'mDesign',
	'SpCaptcha', 'Retailer', 'Config',
	function($filter, $rootScope, $window, $scope, $state, $stateParams, api, User, mDesign, SpCaptcha, Retailer, Config) {
		var forgottenPasswordCtrl = this;

		forgottenPasswordCtrl.resetCode = $stateParams.resetPasswordCode;
		forgottenPasswordCtrl.resetPassword = resetPassword;
		forgottenPasswordCtrl.sendEmail = sendEmail;
		forgottenPasswordCtrl.isSendEmail = false;
		forgottenPasswordCtrl.isShowCaptcha = true;
		forgottenPasswordCtrl.captchaIsInvalid = false;
		forgottenPasswordCtrl.email = $stateParams.email || null;

		if (forgottenPasswordCtrl.resetCode) {
			forgottenPasswordCtrl.isSendEmail = true;
		}

		Retailer.getRetailerSettings().then(function (configuration) {
			forgottenPasswordCtrl.backgroundImage = Retailer.backgroundImage;
		});

		_clearStateOnHold();

		function resetPassword() {
			if (!_validate()) {
				return;
			}

			if (forgottenPasswordCtrl.password !== forgottenPasswordCtrl.confirmPassword) {
				return mDesign.alert('The passwords must match');
			}

			return api.request({
				method: 'POST',
				url: 'retailers/:rid/users/_forgottenPassword',
				data: {
					email: forgottenPasswordCtrl.email,
					resetCode: forgottenPasswordCtrl.resetCode,
					password: forgottenPasswordCtrl.password
				}
			}).then(function() {
				return User.login(forgottenPasswordCtrl.email, forgottenPasswordCtrl.password);
			}).then(function() {
				return $state.go('app.home');
			});
		}

		function sendEmail() {
			if ($stateParams.resetPasswordCode || User.getUserLoginData()) {
				return;
			}
			if (!forgottenPasswordCtrl.email  || !forgottenPasswordCtrl.email.length) {
				mDesign.alert('Please enter your email address to proceed');
				throw new Error('Invalid email');
			}

			$rootScope.emailForReActivation = forgottenPasswordCtrl.email;

			var recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();

			if (!recaptchaHash) {
				forgottenPasswordCtrl.captchaIsInvalid = true;
				return;
			}
			return api.request({
				method: 'GET',
				url: 'retailers/:rid/users/_forgottenPassword',
				params: {
					email: forgottenPasswordCtrl.email,
					recaptchaHash: recaptchaHash
				}
			}, {
				fireAndForgot: true
			}).finally(function() {
				forgottenPasswordCtrl.isSendEmail = true;
				forgottenPasswordCtrl.isShowCaptcha = false;
				mDesign.dialog({
					focusOnOpen: false,
					clickOutsideToClose: true,
					template:
						'<md-dialog-content>' +
						'<div style="padding:2rem">' +
						'<p style="margin-bottom: 2rem;">{{\'Please check your inbox and follow the instructions\' | translate}}</p>' +
						'<small style="color:#E71B1B;font-size:0.8em;">' +
						'{{\'If you haven&#x5c;\'t received an email, please check if you mistyped your email address or contact customer service\' | translate}} ' +
						(Config.retailer.contactPhone || '') + '</small>' + '</div>' +
						'</md-dialog-content>' +
						'<md-dialog-actions>' +
						'<md-button class="md-primary" ng-click="hide()">{{\'OK\' | translate}}</md-button>' +
						'</md-dialog-actions>',
					controller: ['$scope', function ($scope) {
						$scope.hide = mDesign.hide;
					}]
				});
			});
		}

		function _validate() {
			if (forgottenPasswordCtrl.resetPasswordForm && forgottenPasswordCtrl.resetPasswordForm.$invalid) {
				var errorElem = forgottenPasswordCtrl.resetPasswordForm.$error[Object.keys(forgottenPasswordCtrl.resetPasswordForm.$error)[0]][0].$$element[0];
				if(errorElem.type === 'hidden') {
					_scrollDown('resetPasswordForm');
				} else {
					errorElem.scrollIntoView();
					errorElem.focus();
				}
				return false;
			}

			return true;
		}

		function _scrollDown(formName) {
			var formElement = document.getElementsByTagName(formName);
			if(formElement && formElement[0]){
				formElement[0].scrollTop = formElement[0].scrollHeight;
			}
		}

		function _clearStateOnHold() {
			var stateOnHold = localStorage.getItem('stateOnHold');
			if(stateOnHold) {
				localStorage.setItem('stateOnHold', '');
			}
		}

		$scope.historyBack = function ($event) {
			$event && $event.preventDefault();
			$window.onBackButton();
		};

	}]);
