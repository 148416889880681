angular.module('mobilezuz').directive('mainContent', ['$rootScope', function ($rootScope) {
    return {
        link: function($scope, $element) {
            var lastScrollPos;
            $element.bind('scroll', function () {
                if (lastScrollPos < $element[0].scrollTop) {
                    if (($element[0].scrollHeight - ($element[0].scrollTop + $element[0].offsetHeight)) < 200) {
                        $rootScope.$broadcast('vertical-scroll-end');
                    }
                }
                lastScrollPos = $element[0].scrollTop;
            });
        }
    };
}]);