(function(angular) {
    'use strict';

    angular.module('spPayments', ['spApi']);
})(angular);
(function (angular) {
    var CREDIT_CARD_WINDOW_TYPES = {
        IFRAME: 1,
        WINDOW: 2
    };

    var PAYMENT_TYPES = {
        CREDIT: 1,
        DEBIT: 2,
        EBT_SNAP: 3,
        EBT_CASH: 4
    };

    var PAYMENT_METHODS_NAMES = {
        CALL_CUSTOMER: 'CallCustomer',
        CREDIT_CARD: 'CreditCard',
        STORE_CREDIT: 'StoreCredit',
        PAYPAL: 'PayPal',
        ORGANIZATION: 'Organization',
        I_CREDIT_MASTER_PASS: 'ICreditMasterPass',
        CUSTOMER_CREDIT: 'CustomerCredit',
        BIT: 'Bit',
        EBT: 'EBT',
        TAV_PLUS: 'TavPlus',
        TAV_PLUS_LIGHT: 'TavPlusLight',
        LOYALTY_POINTS: 'LoyaltyPoints',
        TEN_BIS: 'TenBis',
        RAK_BESMACHOT: 'RakBesmachot',
        PRAXEL: 'PRAXEL',
        CIBUS: 'Cibus',
        HTZONE: 'HitechZone',
        MAX_GIFT_CARD: "MaxGiftCard",
        GOLD_NORTH: 'GoldNorth',
        DOLCE_VITA: 'DolceVita',
        ISTUDENT: 'Istudent',
        CAL: 'Cal',
        DIRECT_INSURANCE_CLUB: 'DirectInsuranceClub',
        YOURS_CLUB: 'YoursClub',
        PSAGOT: 'Psagot',
        YERUSHALMI: 'Yerushalmi',
        LEUMI_GOODIES: 'LeumiGoodies',
        PAIS_PLUS: 'PaisPlus',
        RAAYONIT: 'Raayonit',
        NOFSHONIT: 'Nofshonit'
    };
    var PAYMENT_METHODS_IDS = {
        CALL_CUSTOMER: 1,
        CREDIT_CARD: 2,
        STORE_CREDIT: 3,
        PAYPAL: 4,
        ORGANIZATION: 5,
        I_CREDIT_MASTER_PASS: 6,
        CUSTOMER_CREDIT: 7,
        BIT: 8,
        EBT: 9,
        TAV_PLUS: 10,
        LOYALTY_POINTS: 11,
        TEN_BIS: 12,
        RAK_BESMACHOT: 13,
        PRAXEL: 14,
        CIBUS: 15,
        HTZONE: 16,
        MAX_GIFT_CARD: 18,
        GOLD_NORTH: 19,
        DOLCE_VITA: 20,
        ISTUDENT: 21,
        CAL: 22,
        DIRECT_INSURANCE_CLUB: 23,
        YOURS_CLUB: 24,
        PSAGOT: 25,
        YERUSHALMI: 26,
        LEUMI_GOODIES: 27,
        PAIS_PLUS: 28,
        RAAYONIT: 29,
        NOFSHONIT: 30,
        TAV_PLUS_LIGHT: 31
    };
    var PAYMENT_METHODS_ID_BY_NAME = {},
        PAYMENT_METHODS_NAME_BY_ID = {};
    angular.forEach(PAYMENT_METHODS_IDS, function(id, key) {
        PAYMENT_METHODS_ID_BY_NAME[PAYMENT_METHODS_NAMES[key]] = id;
        PAYMENT_METHODS_NAME_BY_ID[id] = PAYMENT_METHODS_NAMES[key];
    });

    // Register service
    angular.module('spPayments').constant('SP_PAYMENTS', {
        CREDIT_CARD_WINDOW_TYPES: CREDIT_CARD_WINDOW_TYPES,
        PAYMENT_TYPES: PAYMENT_TYPES,
        PAYMENT_METHODS: {
            NAMES: PAYMENT_METHODS_NAMES,
            IDS: PAYMENT_METHODS_IDS,
            ID_BY_NAME: PAYMENT_METHODS_ID_BY_NAME,
            NAME_BY_ID: PAYMENT_METHODS_NAME_BY_ID
        }
    });

    // Add to root scope
    angular.module('spPayments').run(['$rootScope', 'SP_PAYMENTS', function($rootScope, SP_PAYMENTS) {
        $rootScope.SP_PAYMENTS = SP_PAYMENTS;
    }]);

})(angular);

(function (angular) {
    'use strict';

    var EBT_WEIGHT_DEBIT_PERCENT = 0.1;

    var provider;

    var serviceName = 'PaymentsService';

    function PaymentsService($filter, $window, $q, $injector, Api, SP_PAYMENTS) {
        var self = this,
            _roundCurrencyFilter;

        self.addCreditCard = addCreditCard;
        self.addMaxGiftCard = addMaxGiftCard;
        self.payWithCreditCard = payWithCreditCard;
        self.addEBTCard = addEBTCard;
        self.addGiftCard = addGiftCard;
        self.getPaymentUrl = getPaymentUrl;
        self.initEBTPayment = initEBTPayment;
        self.getEBTBalance = getEBTBalance;
        self.calculateEBTAmounts = calculateEBTAmounts;
        self.getGiftCardBalance = getGiftCardBalance;

        /**
         * Add a credit card
         * @public
         *
         * @param {AddCreditCardOptions} [options]
         *
         * @return {Promise<void>}
         */
        function addCreditCard(options) {
            return _addCard(SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD, options);
        }

        /**
         * Add a credit card
         * @public
         *
         * @param {AddCreditCardOptions} [options]
         *
         * @return {Promise<void>}
        */
        function addMaxGiftCard(options) {
            return _addCard(SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD, options);
        }
        
        /**
         * Pay with credit card
         * @public
         *
         * @param {InitPaymentOptions} [options]
         *
         * @return {Promise<PaymentEventData>}
         */
        function payWithCreditCard(options) {
            var config,
                openedWindow;
            return _getConfig(SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD).then(function(configResult) {
                config = configResult;

                if (_isExternalPayment(config) && (!$window.cordova || !$window.cordova.InAppBrowser) &&
                    config.creditCardWindowType === SP_PAYMENTS.CREDIT_CARD_WINDOW_TYPES.WINDOW) {
                    openedWindow = _openBlankWindow();
                }
                return _getPaymentUrl(SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD, config, options);
            }).then(function(url) {
                var eventPrefix = _isExternalPayment(config) ? 'external_' : '';
                if (config.creditCardWindowType === SP_PAYMENTS.CREDIT_CARD_WINDOW_TYPES.WINDOW) {
                    return _openWindow(url, eventPrefix + 'payment_window.finished', openedWindow);
                } else {
                    return _openIFrameDialog({
                        url: url,
                        methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD,
                        finishEvent: eventPrefix + 'payment_iframe.finished',
                        isAddCard: false
                    });
                }
            }).then(function (eventData) {
                return _handlePaymentEventData(eventData);
            }).catch(function(err) {
                if(openedWindow && err && err.response && err.response.code === 'deliveryTimeNotAvailable') {
                    try {
                        openedWindow.close();
                    } catch(closeErr) {
                        console.log('Window close error', closeErr)
                    }
                }

                throw err;
            });
        }

        function _openBlankWindow() {
            var left = ((screen || {}).width/2)-(600/2);
            var top = ((screen || {}).height/3)-(600/2);
            var openWindow = $window.open(null, null, 'height=600px,width=600px,top='+top+',left='+left);
            openWindow.document.write('Loading...')
            return openWindow;
        }

        /**
         * Add an ebt card
         * @public
         *
         * @return {Promise<void>}
         */
        function addEBTCard() {
            return _addCard(SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT);
        }

        /**
         * Add a gift card
         * @public
         *
         * @return {Promise<void>}
         */
        function addGiftCard(methodId, options) {
            return $q.resolve().then(function () {
                return $injector.invoke(provider.showAddGiftCardDialog, self, {
                    options: angular.extend({}, options.passThroughOptions, {
                        paymentMethodId: methodId,
                    })
                }).then(function (result) {
                    return result;
                }).catch(function (err) {
                    // ignore rejection with no error, like canceling a dialog
                    if (err) {
                        throw err;
                    }
                });
            });
        }

        /**
         * Returns the payment url
         * @private
         *
         * @param {number} methodId
         * @param {InitPaymentOptions} [options]
         *
         * @return {Promise<string>}
         */
        function getPaymentUrl(methodId, options) {
            return _getConfig(methodId).then(function (config) {
                return _getPaymentUrl(methodId, config, options);
            });
        }

        /**
         * @typedef {Object} InitPaymentOptions
         *
         * @property {number} [creditCardId]
         * @property {String} [cvv]
         *
         * @property {number} [cartId]
         * @property {number} [orderId]
         *
         * @property {boolean} [isPickup]
         * @property {Object} [address]
         * @property {string} [address.addressText]
         * @property {string} [address.city]
         * @property {string} [address.zipCode]
         * @property {Date|string} [deliveryDate]
         * @property {string} [action]
         * @property {object} [actionData]
         */

        /**
         * Open init ebt window and return the answer
         * @public
         *
         * @property {InitPaymentOptions} options
         *
         * @return {Promise<PaymentEventData>}
         */
        function initEBTPayment(options) {
            return getPaymentUrl(SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT, options).then(function (url) {
                return _openIFrameDialog({
                    url: url,
                    isAddCard: false,
                    finishEvent: 'ebt_payment.finished',
                    methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT,
                    passThroughOptions: options,
                });
            }).then(function (eventData) {
                return _handlePaymentEventData(eventData);
            });
        }

        /**
         * @typedef {Object} EBTBalanceResult
         *
         * @property {number} fsBalance
         * @property {number} cbBalance
         */

        /**
         * Get the ebt balance
         * @public
         *
         * @param {string} paymentToken
         *
         * @return {Promise<EBTBalanceResult>}
         */
        function getEBTBalance(paymentToken) {
            return _getConfig(SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT).then(function (config) {
                return Api.request({
                    method: 'POST',
                    url: '/retailers/' + config.retailerId + '/users/' + config.userId + '/_ebt-balance',
                    data: {
                        paymentToken: paymentToken
                    }
                });
            });
        }

        /**
         * @typedef {Object} EBTBalanceResult
         *
         * @property {number} fsBalance
         * @property {number} cbBalance
         */

        /**
         * Get the gift card balance
         * @public
         *
         * @param {number} methodId
         * @param {string} cardNum
         *
         * @return {Promise<GiftCardBalanceResult>}
         */
        function getGiftCardBalance(methodId, cardNum) {
            return _getConfig(methodId).then(function (config) {
                return Api.request({
                    method: 'GET',
                    url: '/retailers/' + config.retailerId + '/users/' + config.userId + '/_giftCard-balance',
                    params: {
                        cardNum: cardNum,
                        methodId: methodId
                    }
                }, {
                    hideError: true
                });
            });
        }

        /**
         * Handle payment event data error
         * @private
         *
         * @param {PaymentEventData} eventData
         *
         * @return {PaymentEventData}
         */
        function _handlePaymentEventData(eventData) {
            if (eventData && eventData.error) {
                $injector.invoke(provider.showErrorDialog, self, {error: eventData.error.error || eventData.error});
                throw eventData.error;
            }

            if (!eventData || (!eventData.paymentToken && !eventData.skip3DS && !eventData.actionResponse)) {
                throw new Error('canceled');
            }

            return eventData;
        }

        /**
         * @typedef {Object} AddCreditCardConfig
         *
         * @property {number} retailerId
         * @property {number} branchId
         * @property {number} creditCardWindowType
         * @property {boolean} creditCardSupportsExternalPaymentFlow
         * @property {boolean} isRememberCreditCards
         * @property {number} cartId
         * @property {number} userId
         * @property {string} token
         * @property {number} [languageId]
         * @property {string} [domain]
         */

        /**
         * Returns the config defined on the provider
         * @private
         *
         * @param {number} methodId
         *
         * @returns {Promise<AddCreditCardConfig>}
         */
        function _getConfig(methodId) {
            return $q.resolve().then(function () {
                return $injector.invoke(provider.getConfig, self);
            }).then(function (config) {
                if (methodId !== SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD) {
                    config.creditCardWindowType = SP_PAYMENTS.CREDIT_CARD_WINDOW_TYPES.IFRAME;
                }

                return config;
            });
        }

        /**
         * @typedef {Object} AddCreditCardOptions
         *
         * @property {boolean} [isLoyalty]
         * @property {number} [cartId]
         * @property {number} [orderId]
         * @property {boolean} [sendUserAddress]
         * @property {string} [address]
         * @property {string} [city]
         * @property {string} [country]
         * @property {string} [countryCode]
         */

        /**
         * Add a credit card
         * @private
         *
         * @param {number} methodId
         * @param {AddCreditCardOptions} [options]
         *
         * @return {Promise<void>}
         */
        function _addCard(methodId, options) {
            return _getConfig(methodId).then(function (config) {
                var url = _getAddCardUrl(config, methodId, options);

                if (config.creditCardWindowType === SP_PAYMENTS.CREDIT_CARD_WINDOW_TYPES.WINDOW) {
                    return _openAddCardWindow(url);
                } else {
                    return _openIFrameDialog({
                        url: url,
                        methodId: methodId,
                        finishEvent: 'add_card_iframe.finished',
                        isAddCard: true,
                        passThroughOptions: options
                    });
                }
            }).then(function (eventData) {
                if (eventData && eventData.err && 'error' in eventData.err) {
                    $injector.invoke(provider.showErrorDialog, self, {error: eventData.err.error});
                }

                return eventData;
            });
        }

        /**
         * Add a credit card using a new window
         * @private
         *
         * @param {string} url
         *
         * @return {Promise<AddCreditCardEventData>}
         */
        function _openAddCardWindow(url) {
            return _openWindow(url, 'add_card_window.finished');
        }

        /**
         * @typedef {Object} AddCreditCardEventData
         *
         * @property {Object} [err]
         * @property {string} [err.error]
         */

        /**
         * @typedef {Object} PaymentEventData
         *
         * @property {Object} [error]
         * @property {string} [error.error]
         * @property {string} [error.code]
         * @property {string} [paymentToken]
         * @property {string} [authNum]
         * @property {number} [paymentsNumber]
         * @property {boolean} [skip3DS]
         */

        /**
         * Open a window and listen for the given finish event
         * @private
         *
         * @param {string} url
         * @param {string} finishEvent
         * @param {Window} [existingWindow]
         *
         * @return {Promise<AddCreditCardEventData|PaymentEventData>}
         */
        function _openWindow(url, finishEvent, existingWindow) {
            if ($window.cordova && $window.cordova.InAppBrowser) {
                return _showCordovaWindow(url);
            }

            return _showWindow(url, finishEvent, existingWindow);
        }

        /**
         * Show default browser window
         * @private
         *
         * @param {string} url
         * @param {string} finishEvent
         * @param {Window} [existingWindow]
         *
         * @returns {Promise<AddCreditCardEventData|PaymentEventData>}
         */
        function _showWindow(url, finishEvent, existingWindow) {
            return new $q(function (resolve) {
                var resolved = false,
                    listener,
                    intervalId;

                function _resolve(resp) {
                    if (resolved) {
                        return;
                    }

                    resolved = true;
                    resolve(resp);
                    if (listener) {
                        listener();
                        listener = undefined;
                    }
                    if (intervalId) {
                        clearInterval(intervalId);
                        intervalId = undefined;
                    }
                }

                listener = _listenForMessage(finishEvent, _resolve);

                var openWindow = existingWindow || $window.open(url, null, 'height=600px,width=600px');
                if (existingWindow) {
                    existingWindow.location.href = url;
                }
                intervalId = setInterval(function () {
                    if (openWindow.closed) {
                        // timeout to make sure the event was called first
                        setTimeout(_resolve, 500);
                    }
                }, 500);
            });
        }

        /**
         * Listen for a message on the window element
         * @private
         *
         * @param {string} messageName
         * @param {function} callback
         *
         * @return {function}
         */
        function _listenForMessage(messageName, callback) {
            function _messageCallback(event) {
                if (event.data && event.data.name === messageName) {
                    callback(event.data);
                }
            }

            var windowElement = angular.element(window);
            windowElement.bind('message', _messageCallback);

            return function () {
                windowElement.unbind('message', _messageCallback);
            };
        }

        /**
         * Show cordova window
         * @private
         *
         * @param {string} url
         *
         * @returns {Promise<AddCreditCardEventData|PaymentEventData>}
         */
        function _showCordovaWindow(url) {
            var inAppBrowserRef = $window.cordova.InAppBrowser.open(url, '_blank');
            return new $q(function (resolve, reject) {
                var resolved = false;

                function _resolve(resp) {
                    if (resolved) {
                        return;
                    }

                    resolved = true;
                    resolve(resp);
                }

                inAppBrowserRef.addEventListener('loadstop', function () {
                    //in app browser that will try post message need to save the message data in window.sp.message
                    inAppBrowserRef.executeScript({code: 'sp.message'}, function (values) {
                        //once got a message data close the in app browser and post message
                        _resolve(values[0]);
                        inAppBrowserRef.close();
                    });
                });

                inAppBrowserRef.addEventListener('exit', function () {
                    // timeout to make sure the event was called first
                    setTimeout(_resolve, 500);
                });
            });
        }

        /**
         * Add a credit card using an iFrame dialog
         * @private
         *
         * @param {Object} options
         * @param {string} options.url
         * @param {number} options.methodId
         * @param {number} options.isAddCard
         * @param {string} options.finishEvent
         * @param {AddCreditCardOptions} [options.passThroughOptions]
         *
         * @return {Promise<AddCreditCardEventData>}
         */
        function _openIFrameDialog(options) {
            var _cardAddedDefer = $q.defer(),
                _listener = _listenForMessage(options.finishEvent, function (eventData) {
                    _cardAddedDefer.resolve(eventData);
                });

            return $q.resolve().then(function () {
                return $injector.invoke(provider.showIFrameDialog, self, {
                    options: angular.extend({}, options.passThroughOptions, {
                        url: options.url,
                        paymentMethodId: options.methodId,
                        isAddCard: options.isAddCard
                    }),
                    finishEventPromise: _cardAddedDefer.promise
                });
            }).then(function () {
                // make sure the promise is resolved
                _cardAddedDefer.resolve(false);

                return _cardAddedDefer.promise;
            }).catch(function (err) {
                // ignore rejection with no error, like canceling a dialog
                if (err) {
                    throw err;
                }
            }).finally(function () {
                _listener();
            });
        }

        /**
         * Returns the add credit card url
         * @private
         *
         * @param {AddCreditCardConfig} config
         * @param {number} methodId
         * @param {AddCreditCardOptions} [options]
         *
         * @return {string}
         */
        function _getAddCardUrl(config, methodId, options) {
            options = options || {};

            var command = 'initcardinfo';
            if (methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT) {
                command = '_init-ebt-card';
            }
            if (methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD) {
                command = 'initmaxgiftcard';
            }

            var url = [
                config.domain || '/',
                'retailers/' + config.retailerId + '/users/' + config.userId + '/' + command + '?token=' + config.token
            ];

            if (options.cartId || config.cartId) {
                url.push('&cartId=' + (options.cartId || config.cartId));
            }
            if (options.orderId) {
                url.push('&orderId=' + options.orderId);
            }
            if (config.branchId) {
                url.push('&branchId=' + config.branchId);
            }
            if (config.languageId) {
                url.push('&languageId=' + config.languageId);
            }
            if (options.sendUserAddress) {
                url.push('&addUserAddress=' + true);
            }

            if (options.address) {
                url.push('&address=' + options.address);
            }
            if (options.city) {
                url.push('&city=' + options.city);
            }
            if (options.country) {
                url.push('&country=' + options.country);
            }
            if (options.countryCode) {
                url.push('&countryCode=' + options.countryCode);
            }

            return url.join('');
        }

        /**
         * Returns the payment url
         * @private
         *
         * @param {number} methodId
         * @param {AddCreditCardConfig} config
         * @param {InitPaymentOptions} [options]
         *
         * @return {Promise<string>}
         */
        function _getPaymentUrl(methodId, config, options) {
            options = options || {};

            if (methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD && _isExternalPayment(config)) {
                return _initExternalPayment(config, options);
            }

            var command = 'initpayment';
            if (methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT) {
                command = '_init-ebt-payment';
            } else if (methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.PAYPAL) {
                command = 'initpaypal';
            } else if (methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.I_CREDIT_MASTER_PASS) {
                command = 'initICreditMasterPass';
            }

            var url = [
                config.domain || '/',
                'retailers/' + config.retailerId + '/users/' + config.userId + '/' + command + '?token=' + config.token
            ];
            if (options.cartId || config.cartId) {
                url.push('&cartId=' + (options.cartId || config.cartId));
            }
            if (options.orderId) {
                url.push('&orderId=' + options.orderId);
            }
            if (options.creditCardId) {
                url.push('&creditCardId=' + options.creditCardId);
            }
            if (options.cvv) {
                url.push('&cvv=' + options.cvv);
            }
            if (config.languageId) {
                url.push('&languageId=' + config.languageId);
            }
            if (options.address) {
                angular.forEach(['addressText', 'city', 'zipCode'], function (key) {
                    if (options.address[key]) {
                        url.push('&' + key + '=' + options.address[key]);
                    }
                });
            }
            if (options.isPickup) {
                url.push('&isPickup=1');
            }
            if (options.deliveryDate) {
                url.push(('&deliveryDate=' + options.deliveryDate));
            }
            return $q.resolve(url.join(''));
        }

        /**
         * Returns whether in external payment mode
         * @private
         *
         * @param {AddCreditCardConfig} config
         *
         * @returns {boolean}
         */
        function _isExternalPayment(config) {
            return config.creditCardSupportsExternalPaymentFlow && !config.isRememberCreditCards;
        }

        /**
         * @typedef {Object} EBTAmounts
         *
         * @property {number} fsAmount
         * @property {number} cbAmount
         */

        /**
         * @typedef {Object} CartEBTEligibleDataItem
         *
         * @property {number} eligibleWithTax
         * @property {number} eligibleWithoutTax
         * @property {number} depositWithTax
         * @property {number} depositWithoutTax
         * @property {number} totalWithTax
         * @property {number} totalWithoutTax
         */

        /**
         * @typedef {Object} CartEBTEligibleData
         *
         * @property {CartEBTEligibleDataItem} snap
         * @property {CartEBTEligibleDataItem} entireCart
         */

        /**
         * @typedef {Object} EBTCalculatedAmount
         *
         * @property {number} amount
         * @property {number} amountWithTax
         * @property {number} deposit
         * @property {number} depositWithTax
         * @property {number} exceeding
         * @property {number} taxSaved
         */

        /**
         * @typedef {Object} EBTCalculatedAmounts
         *
         * @property {EBTCalculatedAmount} fs
         * @property {EBTCalculatedAmount} cb
         * @property {EBTCalculatedAmount} total
         */

        /**
         * Calculates the ebt amount and deposit
         * @public
         *
         * @param {EBTAmounts} ebtAmountsObj
         * @param {CartEBTEligibleData} ebtEligibleData
         *        use sp-services cart service's getEBTEligible to extract this data from the cart
         *
         * @returns {EBTCalculatedAmounts}
         */
        function calculateEBTAmounts(ebtAmountsObj, ebtEligibleData) {
          var ebtActualTotal = {
            fs: { amount: 0, amountWithTax: 0, deposit: 0, depositWithTax: 0, exceeding: 0, taxSaved: 0 },
            cb: { amount: 0, amountWithTax: 0, deposit: 0, depositWithTax: 0, exceeding: 0, taxSaved: 0 },
            total: { amount: 0, amountWithTax: 0, deposit: 0, depositWithTax: 0, exceeding: 0, taxSaved: 0 }
          };

          if (!ebtAmountsObj) {
            return ebtActualTotal;
          }

          var fsAmount = ebtAmountsObj.fsAmount || 0,
            cbAmount = ebtAmountsObj.cbAmount || 0;

          // reset to 0 when minus
          if (fsAmount < 0) {
            ebtAmountsObj.fsAmount = fsAmount = 0;
          }
          if (cbAmount < 0) {
            ebtAmountsObj.cbAmount = cbAmount = 0;
          }

          // calculate the fs amount and deposit
          var fsTaxSaved = _roundDecimal( (fsAmount * ebtEligibleData.entireCart.immutableTaxRatio) - fsAmount);
            var fsAmountWithTax = fsAmount + fsTaxSaved;
          /*We want to cover the case with weightable products. Here we want to understand how much person paid. If he/she paying higher than SNAP amount without deposit
          / we want to provide correct TaxSaved amount by deducting deposit.
          */
          if (fsAmount > ebtEligibleData.snap.immutableWithoutTaxWithoutDeposit) {
            fsTaxSaved = _roundDecimal(ebtEligibleData.snap.immutableWithTaxWithoutDeposit - ebtEligibleData.snap.immutableWithoutTaxWithoutDeposit);
          }
          ebtActualTotal.fs.amount = fsAmount;
          ebtActualTotal.fs.taxSaved = fsTaxSaved;
          if (ebtEligibleData.snap.depositWithoutTax) {
            ebtActualTotal.fs.deposit = _extractEBTDeposit(ebtActualTotal.fs.amount, ebtEligibleData.snap.depositWithoutTax);
            ebtActualTotal.fs.depositWithTax = _extractEBTDeposit(fsAmountWithTax, ebtEligibleData.snap.depositWithTax);
            ebtActualTotal.fs.amount = _roundDecimal(ebtActualTotal.fs.amount - ebtActualTotal.fs.deposit);
          }
          ebtActualTotal.fs.amountWithTax = ebtActualTotal.fs.amount || 0;

            // calculate the cb amount and deposit
            // ebt cash should not reduce tax, so both amount and amountWithTax include tax
            ebtActualTotal.cb.amountWithTax = ebtActualTotal.cb.amount = cbAmount;
            // the remaining deposit for the ebt cash
            var cbDeposit = ebtEligibleData.entireCart.depositWithTax - (ebtActualTotal.fs.depositWithTax || 0);
            if (cbDeposit > 0) {
                ebtActualTotal.cb.depositWithTax = ebtActualTotal.cb.deposit = _extractEBTDeposit(ebtActualTotal.cb.amount, cbDeposit);
                ebtActualTotal.cb.amountWithTax = ebtActualTotal.cb.amount = _roundDecimal(ebtActualTotal.cb.amount - ebtActualTotal.cb.deposit);
            }

          // set the fs exceeding amount
          var fsExceedingWithTax = 0;
          if (fsAmount > ebtEligibleData.snap.totalWithoutTax) {
            ebtActualTotal.fs.exceeding = _roundDecimal(fsAmount - ebtEligibleData.snap.totalWithoutTax);
            fsExceedingWithTax = _roundDecimal(fsAmountWithTax - ebtEligibleData.snap.totalWithTax);
          }

          // set the cb exceeding amount
          var remainingCBEligible = ebtEligibleData.entireCart.totalWithTax - fsAmountWithTax + fsExceedingWithTax;
          if (cbAmount > remainingCBEligible) {
            ebtActualTotal.cb.exceeding = _roundDecimal(cbAmount - remainingCBEligible);
          }

          // sum the fs and cb amounts into the total
          angular.forEach(['amount', 'amountWithTax', 'deposit', 'depositWithTax', 'exceeding', 'taxSaved'], function (key) {
            ebtActualTotal.total[key] = ebtActualTotal.fs[key] + ebtActualTotal.cb[key];
          });

          return ebtActualTotal;
        }

        /**
         * Returns the external payment url
         * @private
         *
         * @param {AddCreditCardConfig} config
         * @param {InitPaymentOptions} [options]
         *
         * @return {Promise<string>}
         */

        function _initExternalPayment(config, options) {
            return Api.request({
                method: 'POST',
                url: '/retailers/' + config.retailerId + '/users/' + config.userId + '/_init-external-payment',
                data: angular.extend({
                    cartId: options.cartId,
                    orderId: options.orderId,
                    creditCardId: options.creditCardId,
                    cvv: options.cvv,
                    languageId: config.languageId,
                    isPickup: options.isPickup,
                    deliveryDate: options.deliveryDate,
                    action: options.action,
                    branchId: config.branchId
                }, options.actionData)
            }).then(function (result) {
                return result.url;
            });
        }

        /**
         * Returns the tax amount saved
         * @private
         *
         * @param {number} totalTax
         * @param {number} savePercent
         *
         * @return {number}
         */
        function _getTaxSaved(totalTax, savePercent) {
            // round the remainder if tax to be paid, as this should be the determining factor
            var taxRemainder = _roundDecimal(totalTax * (1 - savePercent));
            // then return the amount you saved
            return _roundDecimal(totalTax - taxRemainder);
        }

        /**
         * Extracts the amount of deposit out of the ebt amount
         * @private
         *
         * @param {number} amount
         * @param {number} maxDeposit
         *
         * @return {number}
         */
        function _extractEBTDeposit(amount, maxDeposit) {
            return _roundDecimal(Math.min(
                amount / (1 + EBT_WEIGHT_DEBIT_PERCENT) * EBT_WEIGHT_DEBIT_PERCENT,
                maxDeposit
            ));
        }

        /**
         * Round number to two decimal digits
         * @private
         *
         * @param {number} num
         *
         * @return {number}
         */
        function _roundDecimal(num) {
            if (!_roundCurrencyFilter) {
                try {
                    _roundCurrencyFilter = $filter('roundCurrency');
                } catch (err) {
                    console.warn('sp-payments: roundCurrency filter wasn\'t found, using default round');
                    _roundCurrencyFilter = function (amount) {
                        return Math.round(amount * 1000) / 1000;
                    };
                }
            }

            return _roundCurrencyFilter(num);
        }
    }

    angular.module('spPayments').config(['$provide', function ($provide) {
        provider = $provide.service(serviceName, [
            '$filter', '$window', '$q', '$injector', 'Api', 'SP_PAYMENTS',
            PaymentsService
        ]);

        provider.getConfig = getConfig;
        provider.showIFrameDialog = showIFrameDialog;
        provider.showErrorDialog = showErrorDialog;

        function getConfig() {
            throw new Error(serviceName + 'Provider.getConfig is not defined');
        }

        function showIFrameDialog() {
            throw new Error(serviceName + 'Provider.showIFrameDialog is not defined');
        }

        function showErrorDialog() {
            console.error(serviceName + 'Provider.showErrorDialog is not defined');
        }
    }]);
})(angular);
