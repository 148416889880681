(function(sp) {
    var ORDER_STATUSES = {
        NEW: 1,
        CHARGED: 2,
        DELIVERED: 3,
        CANCELLED: 4,
        SUSPENDED: 5,
        PROCESSING: 6,
        COLLECTED: 7,
        REGISTERED: 8,
        WAITING_FOR_CONFIRMATION: 117,
        USER_CONFIRMED: 118
    };

    var ORDER_STATUS_STAGES = {
        RECEIVED: {},
        IN_PROCESS: {},
        READY: {},
        FINISH: {},
        CANCELLED: {}
    };
    var languages = {
        he: 1,
        en: 2,
        es: 3,
        ru: 4,
        fr: 5,
        ar: 6,
    };
    ORDER_STATUS_STAGES.RECEIVED[ORDER_STATUSES.NEW] = 'NEW';
    ORDER_STATUS_STAGES.READY[ORDER_STATUSES.CHARGED] = 'CHARGED';
    ORDER_STATUS_STAGES.FINISH[ORDER_STATUSES.DELIVERED] = 'DELIVERED';
    ORDER_STATUS_STAGES.CANCELLED[ORDER_STATUSES.CANCELLED] = 'CANCELLED';
    ORDER_STATUS_STAGES.IN_PROCESS[ORDER_STATUSES.SUSPENDED] = 'SUSPENDED';
    ORDER_STATUS_STAGES.IN_PROCESS[ORDER_STATUSES.PROCESSING] = 'PROCESSING';
    ORDER_STATUS_STAGES.IN_PROCESS[ORDER_STATUSES.COLLECTED] = 'COLLECTED';
    ORDER_STATUS_STAGES.IN_PROCESS[ORDER_STATUSES.REGISTERED] = 'REGISTERED';
    ORDER_STATUS_STAGES.IN_PROCESS[ORDER_STATUSES.WAITING_FOR_CONFIRMATION] = 'WAITING_FOR_CONFIRMATION';
    ORDER_STATUS_STAGES.IN_PROCESS[ORDER_STATUSES.USER_CONFIRMED] = 'USER_CONFIRMED';

    var DELIVERY_TYPES = {
        DELIVERY: 1,
        PICKUP: 2,
        PICK_AND_GO: 3,
        SCAN_AND_GO: 4,
        EXPRESS_DELIVERY: 5
    };

    var CHECKOUT_METHODS_VALUES = {
        DELIVERY: 'delivery',
        PICKUP: 'pickup',
        PICK_AND_GO: 'pickAndGo'
    };

    var ORDER_DELIVERY_PROVIDER_STATUSES = {
        READY: 7,
        ON_THE_WAY: 8,
        DELIVERED: 9
    };

    var DELIVERY_PROVIDER_STATUS_ICONS = {};
        DELIVERY_PROVIDER_STATUS_ICONS[ORDER_DELIVERY_PROVIDER_STATUSES.READY] = {
               IMAGE: 'https://d226b0iufwcjmj.cloudfront.net/backend/icons/delivery-provider/ready-blue.svg',
                NAME: 'Ready'
        };
        DELIVERY_PROVIDER_STATUS_ICONS[ORDER_DELIVERY_PROVIDER_STATUSES.ON_THE_WAY] = {
            IMAGE: 'https://d226b0iufwcjmj.cloudfront.net/backend/icons/delivery-provider/on-the-way-blue.svg',
            NAME: 'On the way'
        };
        DELIVERY_PROVIDER_STATUS_ICONS[ORDER_DELIVERY_PROVIDER_STATUSES.DELIVERED] = {
            IMAGE: 'https://d226b0iufwcjmj.cloudfront.net/backend/icons/delivery-provider/delivered-blue.svg',
            NAME: 'Delivered'
        };

    var CHECKOUT_METHODS = {
        VALUES: CHECKOUT_METHODS_VALUES,
        BY_DELIVERY_TYPE: {}
    };
    CHECKOUT_METHODS.BY_DELIVERY_TYPE[DELIVERY_TYPES.DELIVERY] = CHECKOUT_METHODS_VALUES.DELIVERY;
    CHECKOUT_METHODS.BY_DELIVERY_TYPE[DELIVERY_TYPES.PICKUP] = CHECKOUT_METHODS_VALUES.PICKUP;
    CHECKOUT_METHODS.BY_DELIVERY_TYPE[DELIVERY_TYPES.PICK_AND_GO] = CHECKOUT_METHODS_VALUES.PICK_AND_GO;
    CHECKOUT_METHODS.BY_DELIVERY_TYPE[DELIVERY_TYPES.SCAN_AND_GO] = CHECKOUT_METHODS_VALUES.DELIVERY;
    CHECKOUT_METHODS.BY_DELIVERY_TYPE[DELIVERY_TYPES.EXPRESS_DELIVERY] = CHECKOUT_METHODS_VALUES.DELIVERY;

    angular.module('mobilezuz')
        .constant('MOBILE_SERVICE_ID', 3)
        .constant('PAGE_ACCESS', {
            LOGGED_IN: 2,
            USER_VERIFIED: 3,
            NOT_LOGGED_IN: 1,
            ALLOW: 0,
            ORGANIZATION_ADMIN: 4,
            NOT_ORGANIZATION_MEMBER: 5
        })
        .constant('LANGUAGES', languages)
        .constant('ORDER_DELIVERY_PROVIDER_STATUSES', ORDER_DELIVERY_PROVIDER_STATUSES)
        .constant('DELIVERY_PROVIDER_STATUS_ICONS', DELIVERY_PROVIDER_STATUS_ICONS)
        .constant('ORDER_STATUSES', ORDER_STATUSES)
        .constant('ORDER_STATUS_STAGES', ORDER_STATUS_STAGES)
        .constant('CATEGORIES_TYPES', {
            INHERIT: 1,
            REGULAR: 2,
            MENU: 3,
            LARGE_ITEMS: 4
        })
        .constant('CREDIT_CARD_MODES', {
            ONE_TIME: 'ONE_TIME',
            REMEMBER_CARDS: 'TOKEN'
        })
        .constant('CREDIT_CARD_3DS_MODES', {
            ON_ADD_CARD: 1,
            ON_PAYMENT: 2,
            ON_UPDATE: 3
        })
        .constant('CREDIT_CARD_TYPES', {
            REGULAR: 0,
            TOURIST_CARD: 1,
            DIRECT: 3,
            GIFT_CARD: 4
        })
        .constant('CREDIT_CARD_PROVIDERS', {
            MERCURY: 'Mercury',
            PELE_CARD: 'PeleCard',
            FIRST_DATA: 'FirstData',
            FIDELITY: 'Fidelity',
            AUTHORIZE_DOT_NET: 'AuthorizeDotNet',
            CREDIT_GUARD: 'CreditGuard',
            CREDIT_GUARD_3DS: 'CreditGuard3DS',
            CREDIT_GUARD_3DS_V2: 'CreditGuard3DSV2',
            STRIPE: 'Stripe',
            COMAX: 'Comax'
        })
        .constant('ANALYTICS_CURRENCY', {
            '$': 'USD',
            '₪': 'ILS',
            '€': 'EUR',
            'KWD': 'KWD',
            '£': 'GBP'
        })
        .constant('SUBSTITUTE_PREFERENCES', {
            ALLOW: 1,
            CALL_ME: 2,
            DO_NOT_ALLOW: 3,
            SEND_SMS: 4,
            WHATSAPP: 5
        })
        .constant('DELIVERY_TIMES_TYPES', {
            REGULAR: 1,
            DELIVERY_WITHIN_HOURS: 2,
            DELIVERY_WITHIN_DAYS: 3
        })
        .constant('DELIVERY_TYPES', DELIVERY_TYPES)
        .constant('TAG_DATE_CONFIGURATION_TYPE', {
            WEEK_DAYS: 1,
            DATE_RAGE: 2
        })
        .constant('CHECKOUT_METHODS', CHECKOUT_METHODS)
        .constant('ADVERTISEMENT_AD_TYPE', {
            BANNER: 1,
            VIDEO: 2,
            RECIPE: 3,
            PLASMA: 4,
            LANDING_PAGES: 5,
            CHECKOUT_SUGGESTIONS: 6
        })
        .constant('ADVERTISEMENT_GROUP_TYPE', {
            BANNERS: 1,
            PRODUCTS: 2,
            FILLERS: 3,
            PLASMA: 4
        })
        .constant('ADVERTISEMENT_CONVERSION_TYPE', {
            CHARGE: 2,
            ADD_TO_CART: 800
        })
        .constant('DELIVERY_CHOICES', {
            LOCAL_DELIVERY: 'localDelivery',
            SHIPPING: 'shipping'
        })
        .constant('BRANCH_AREA_TYPES', {
            LOCATIONS: 1,
            ZIP_CODES: 2,
            POLYGONS: 3,
            EVERYWHERE: 4,
            PICKUP: 5
        })
        .constant('CART_LINE_TYPES', {
            PRODUCT: 1,
            DELIVERY: 2,
            COUPON: 3,
            REGISTER_LOYALTY: 4,
            SERVICE_FEE: 5,
            MARKETING: 6
        })
        .constant('LOYALTY_CLUB_DRIVERS', {
            COMPANY: 1,
            RETALIX: 2,
            LOCAL_LOYALTY: 3,
            CLUB_365: 4,
            RETALIX_V2: 6,
            MAX: 7,
            COMAX: 8,
            SIMPLETEXT: 9,
            LOOPBACK: 10,
            ISRACARD: 11,
            BIRDZI: 12,
            EOECOLLEAGUE: 13,
            EOECOOP: 14,
            CHIPPER: 15,
            TIV_TAAM_LOYALTY: 16,
            NCR_MULTIPASS_CASHBACK: 17,
            ACCUMULATED_POINTS: 18
        })
        .constant('TICKET_STATUSES', {
            NEW: 1,
            HANDLING: 2,
            DONE: 3
        })
        .constant('MULTI_DOMAINS_PICKERS', {
            ZIP_CODE: 1,
            CHOOSE_RETAILER: 2,
            CHOOSE_AREA: 3
        })
        // TODO remove
        .constant('DELIVERY_AREA_METHODS_PRECISION', {
            PRECISE_LOCATION: 1,
            CITY: 2,
            CITY_STREET_NOT_REQUIRED: 3
        })
        .constant('CITY_TYPES', {
            ONE_ZIP_CODE: 1,
            MULTIPLE_ZIP_CODES: 2
        })
        .constant('HOUSE_ENTRIES', [
            'HOUSE_ENTRY_NONE',
            'HOUSE_ENTRY_A',
            'HOUSE_ENTRY_B',
            'HOUSE_ENTRY_C',
            'HOUSE_ENTRY_D',
            'HOUSE_ENTRY_E',
            'HOUSE_ENTRY_F',
            'HOUSE_ENTRY_G',
            'HOUSE_ENTRY_H',
            'HOUSE_ENTRY_I',
            'HOUSE_ENTRY_J'
        ])
        .constant('PROMOTION_TYPES', {
            BUY_X_GET_Y_IN_PROMOTION: 1,
            BUY_X_IN_Y_PROMOTION: 2,
            TOTAL_GET_Y_IN_PROMOTION: 3,
            BUY_X_GET_Y_DISCOUNT_PROMOTION: 4,
            BUY_X_GET_Y_FIXED_DISCOUNT_PROMOTION: 5,
            DISCOUNT_TOTAL_PROMOTION: 6,
            BUY_X_GET_FIXED_DISCOUNT_PROMOTION: 7,
            BUY_X_GET_DISCOUNT_PROMOTION: 8,
            FIXED_DISCOUNT_TOTAL_PROMOTION: 9,
            TOTAL_GET_Y_DISCOUNT_PROMOTION: 10
        })
        .constant('HOME_PAGE_CAROUSEL_DISPLAY_TYPES', {
            PROMOTIONS: 1,
            PRODUCTS: 2
        })
        .constant('BANNER_HEIGHT', {
            LARGE: 375,
            MEDIUM: 275,
            SMALL: 195
        })
        .constant('WEB_PAGE_LOCATIONS', {
            HEADER: 1,
            FOOTER: 2
        })
        .constant('CHARGE_SPECIALS_CALCULATION_TIME', {
            COLLECTION_TIME: null,
            CHECKOUT_POS: '2',
            CHECKOUT_SP: '3'
        })
        .constant('URL_DIALOG_DATA_QUERY_PARAMS', {
            LOGIN: ['loginDialogTitle', 'loginDialogSubTitle', 'loginDialogRetState', 'loginDialogRetStateParams', 'resetPasswordCode', 'email', 'registrationTab', 'registrationEmail', 'userConfirmationLogin']
        })
        .constant('RETAILER_STATUSES', {
            IN_MAINTENANCE: 0,
            REGISTERED_ONLY: 1,
            ACTIVE: 2,
            DISABLED: 3,
            ORGANIZATION_ONLY: 4,
            LP_ONLY: 5
        })
        .constant('STATE_NAMES', {
            HOME: 'app.home'
        })
        .constant('SUPERVISION_TAGS', {
            1: 'Chametz',
            2: 'PassoverKosher',
            3: 'PassoverKosherTemporarily',
            4: 'PassoverKitniyotKosher',
            5: 'PassoverKitniyotKosherTemporarily',
            6: 'Meat',
            7: 'Milky',
            8: 'Pareve',
            9: 'IsraelMilk',
            10: 'IsraelCooking',
            11: 'SabbathObservingFactory',
            12: 'NoShviitConcern',
            13: 'NoOrlaConcern',
            14: 'NoTevelConcern',
            15: 'NoOvernightConcern',
            16: 'NoGeniza'
        })
        .constant('SEARCH_SORT_BOOST_TYPES', {
            A_B: 1,
            BRAND: 2,
            SUB_SUB_CATEGORY: 3,
            SUPPLIER: 4,
            POS_SOLD_QUANTITY: 5,
            PRICE: 6,
            HAS_SPECIAL: 7,
            USER_LAST_PURCHASED_PRODUCTS: 8, // not a category sort type,
            PRODUCT_TAGS: 9 // not a category sort type
        })
        .constant('IMAGE_TYPES', {
            IMAGE: 1,
            IMAGE360: 2
        })
        .constant('PHONE_TYPES', {
            HOME: 1,
            WORK: 2,
            MOBILE: 3,
            VERIFY: 4
        })
        .constant('USER_VERIFICATION_STATUS', {
            NOT_VERIFIED: 1,
            SENT: 2,
            VERIFIED: 3
        })
        .constant('LINE_STATUS', {
            STATUS: -1,
            NEW: 1,
            DONE: 2,
            MISSING: 3,
            ON_HOLD: 4,
            REPLACED: 5,
            BUNDLED: 6,
            REFUND: 7
        })
        .constant('API_BASE_URL', 'https://mobile.' + sp.mobileData.companyDetails.apiRootDomain + '/')
        .constant('ORGANIZATIONS_ROLES', {
            USER: 1,
            ADMIN: 2
        })
        .constant('ORGANIZATION_TYPES', {
            SIMPLE: 1,
            OBLIGO: 2,
            NO_OBLIGO: 3
        })
        .constant('POLICY_ENFORCEMENT_MODES', {
            IGNORE: 1,
            SHOW: 2,
            FORCE: 3
        })
        .constant('DEBUGGING_PROVIDERS', {
            HOTJAR: 1
        })
        .constant('UPDATE_ORDER_PAYMENT_TYPE', {
            EDIT_ORDER_UPDATE: 1,
            USER_CONFIRMATION_UPDATE: 2
        })
        .constant('RECEIPT_TYPE_ID', {
            DIGITAL: 1,
            BOTH: 2,
            PHYSICAL: 3
        })
        .constant('DEFAULT_AFTER_FIRST_ORDER', {
            DEFAULT: 1,
            CUSTOMER_LAST_CHOICE: 2
        })
        .constant('LAST_MINUTE_RECOMMENDATIONS', {
            SECTIONS_TYPES: {
                OFFERS: 1,
                TIP_DONATION: 2,
                COUPONS: 3,
                PRODUCTS_USUALLY: 4,
                PRODUCTS_COMPLEMENTARY: 5
            },
            ITEMS_TYPES: {
                SPECIALS: 1,
                PRODUCTS: 2
            }
        })
        .constant('LOGIN_WITH_OTP_OPTIONS', {
            EMAIL: 1,
            PHONE_NUMBER: 2,
            VERIFIED_PHONE_CREDENTIAL: 100
        })
        .constant('LOGIN_OR_REGISTER_SCREENS', {
            DEFAULT: 'default',
            ENTER_REGISTRATION_FORM: 'enterRegistrationForm',
            SELECT_OTP_OPTION: 'selectOtpOption',
            ENTER_OTP_PHONE_OR_EMAIL: 'enterOtpPhoneOrEmail',
            ENTER_CODE_TO_VALIDATE: 'enterCodeToValidate',
            CHOOSE_UNIQUE_EMAIL: 'chooseUniqueEmail',
            OTP_ERROR: 'otpError',
            ENTER_COMPLETION_FORM: "enterCompletionForm",
            ENTER_FACEBOOK_REGISTRATION_FORM: 'enterFacebookRegistrationForm'
        })
        .constant('USER_TYPE_FOR_COMPLETION_FORM', {
            NEW_USER: 'newUser',
            SITE_USER_BUT_NOT_LOYALTY_MEMBER: 'siteUserButNotLoyaltyMember',
            LOYALTY_MEMBER_BUT_NOT_SITE_MEMBER: 'loyaltyMemberButNotSiteMember',
        })
        .constant('COUPON_MEANINGLESS_REMAINING_VALUES', [0.1, 0.01, 0.001])
        .constant('LOYALTY_PAGE_METHODS', {
/*
            LOYALTY_MEMBER: 1,
*/
            NON_LOYALTY_MEMBER: 0
        })
        .constant('USER_LOYALTY_TYPES', {
            ELIGIBLE_TO_REDEEM: 1,
            NOT_ELIGIBLE_TO_REDEEM: 2,
            NON_REGISTERED: 3,
            HAS_ZERO_POINT: 4
        })
        .constant('LOYALTY_PAGE_COMPONENT_TYPES', {
            BY_NAME: {
                MAIN_BANNER: 1,
                MARKETING_BANNER: 2,
                STORES_COMPONENT: 3,
                POINTS_TO_CASHBACK_CALCULATOR: 4,
                EXTERNAL_SERVICES_LIST: 5,
                POTENTIAL_PERSONAL_COUPONS: 6,
                LOYALTY_SPECIALS_CAROUSEL: 7,
                SIGN_IN_COMPONENT: 8,
                Q_AND_A_COMPONENT: 9,
                LEGAL_TEXT: 10,
                POINTS_ACCUMULATION_PROGRESS_BAR: 11,
                PERSONALIZED_COUPONS_CAROUSEL: 12,
                MEMBER_MAIN_BANNER: 13,
                ACCUMULATION_REDEMPTION: 14
            },
            BY_VALUE: {
                1: {
                    disabled: false,
                    isSortable: false,
                    title: 'Main Banner',
                    isMandatory: true,
                    templateUrl: 'views/templates/loyalty-page-design-components/main-banner.html'
                },
                2: {
                    disabled: false,
                    isSortable: true,
                    title: 'Marketing Banner',
                    isMandatory: true,
                    templateUrl: 'views/templates/loyalty-page-design-components/marketing-banner.html'
                },
                3: {
                    disabled: false,
                    isSortable: true,
                    title: 'Stores component',
                    isMandatory: true,
                    templateUrl: 'views/templates/loyalty-page-design-components/stores-component.html',
                    isGeneral: true
                },
                4: {
                    disabled: false,
                    isSortable: true,
                    title: 'Points to Cashback Calculator',
                    isMandatory: false,
                    templateUrl: 'views/templates/loyalty-page-design-components/points-to-cash-calculator.html'
                },
                5: {
                    disabled: false,
                    isSortable: true,
                    title: 'External Services List',
                    isMandatory: false,
                    templateUrl: 'views/templates/loyalty-page-design-components/external-services-list.html',
                    isGeneral: true
                },
                6: {
                    disabled: false,
                    isSortable: true,
                    title: 'Potential Personal Coupons - Ad image',
                    isMandatory: true,
                    templateUrl: 'views/templates/loyalty-page-design-components/potential-coupons-component.html',
                },
                7: {
                    disabled: false,
                    isSortable: true,
                    title: 'Loyalty specials carousel',
                    isMandatory: true,
                    templateUrl: 'views/templates/loyalty-page-design-components/loyalty-specials-carousel.html',
                    isGeneral: true
                },
                8: {
                    disabled: false,
                    isSortable: false,
                    title: 'Sign-in / Sign-up Component',
                    isMandatory: true,
                    templateUrl: 'views/templates/loyalty-page-design-components/sign-in-component.html',
                },
                9: {
                    disabled: false,
                    isSortable: false,
                    isGeneral: true,
                    title: 'Questions and Answers Component',
                    isMandatory: false,
                    templateUrl: 'views/templates/loyalty-page-design-components/q-and-a-component.html'
                },
                10: {
                    disabled: false,
                    isSortable: false,
                    isGeneral: true,
                    title: 'Legal Text',
                    isMandatory: false,
                    templateUrl: 'views/templates/loyalty-page-design-components/legal-text-component.html'
                },
                11: {
                    disabled: false,
                    isSortable: true,
                    title: 'Points Accumulation Progress Bar',
                    isMandatory: false,
                    templateUrl: 'views/templates/loyalty-page-design-components/points-accumulation-progress-bar.html'
                },
                12: {
                    disabled: false,
                    isSortable: true,
                    title: 'Personalized Coupons Component',
                    isMandatory: true,
                    templateUrl: 'views/templates/loyalty-page-design-components/personalized-coupons-component.html'
                },
                13: {
                    disabled: false,
                    isSortable: false,
                    title: 'Main Banner (web only)',
                    isMandatory: true,
                    templateUrl: 'views/templates/loyalty-page-design-components/member-main-banner.html'
                },
                14: {
                    disabled: false,
                    isSortable: true,
                    title: 'Accumulation And Redemption History',
                    isMandatory: true,
                    templateUrl: 'views/templates/loyalty-page-design-components/accumulation-redemption-history.html'
                }
            }
        })
        .constant('RETAILER_PROMOTION_EVENTS', {
            REGISTER: 1,
            CHECKOUT: 2,
            ARRIVAL: 3
        })
        .constant('USER_ERRORS', {
            NOT_LOGGED_IN: 'not logged in',
        }).constant('BRANCH_DELIVERY_TYPES', {
            DELIVERY: 1,
            PICKUP: 2,
            PICK_AND_GO: 3,
            SCAN_AND_GO: 4,
            EXPRESS_DELIVERY: 5
        })
        .constant("PRODUCT_DISPLAY", {
            WEIGHT: {
                id: 1,
                name: 'Weight'
            },
            UNIT: {
                id: 2,
                name: 'Unit'
            },
            WEIGHT_AND_UNIT: {
                id: 3,
                name: 'Weight and Unit'
            }
        })
        .constant('GOOGLE_MAP_ADDRESS_TYPES', {
            ROUTE: 'route',
            PREMISE: 'premise',
            COUNTRY: 'country',
            LOCALITY: 'locality',
            ZIP_CODE: 'postal_code',
            POSTAL_TOWN: 'postal_town',
            SUB_LOCALITY: 'sublocality',
            NEIGHBORHOOD: 'neighborhood',
            STREET_NUMBER: 'street_number',
            ESTABLISHMENT: 'establishment',
            ADMIN_AREA_LV1: 'administrative_area_level_1',
            ADMIN_AREA_LV2: 'administrative_area_level_2',
        })
        .constant('GOOGLE_MAP_MOBILE_EVENTS', {
            MAP_READY: "map_ready",
            MAP_CLICK: "map_click",
            MAP_LONG_CLICK: "map_long_click",
            POI_CLICK: "poi_click",
            MY_LOCATION_CLICK: "my_location_click",
            MY_LOCATION_BUTTON_CLICK: "my_location_button_click",
            INDOOR_BUILDING_FOCUSED: "indoor_building_focused",
            INDOOR_LEVEL_ACTIVATED: "indoor_level_activated",
            CAMERA_MOVE_START: "camera_move_start",
            CAMERA_MOVE: "camera_move",
            CAMERA_MOVE_END: "camera_move_end",
            CAMERA_TARGET_CHANGED: 'camera_target_changed',
            OVERLAY_CLICK: "overlay_click",
            POLYGON_CLICK: "polygon_click",
            POLYLINE_CLICK: "polyline_click",
            CIRCLE_CLICK: "circle_click",
            GROUND_OVERLAY_CLICK: "groundoverlay_click",
            INFO_CLICK: "info_click",
            INFO_LONG_CLICK: "info_long_click",
            INFO_CLOSE: "info_close",
            INFO_OPEN: "info_open",
            MARKER_CLICK: "marker_click",
            MARKER_DRAG: "marker_drag",
            MARKER_DRAG_START: "marker_drag_start",
            MARKER_DRAG_END: "marker_drag_end",
            MAP_DRAG: "map_drag",
            MAP_DRAG_START: "map_drag_start",
            MAP_DRAG_END: "map_drag_end",
            KML_CLICK: "kml_click",
            FUSION_TABLE_CLICK: "fusion_table_click",
            PANORAMA_READY: "panorama_ready",
            PANORAMA_CAMERA_CHANGE: "panorama_camera_change",
            PANORAMA_LOCATION_CHANGE: "panorama_location_change",
            PANORAMA_CLICK: "panorama_click",
        })
        .constant('FILTER_ADDRESS_MODE', {
            CITY: 1,
            ZIP_CODE: 2,
        })
        .constant('ORDER_UPDATE_POLICY', {
            ORDER_STATUS: 1,
            HOURS_BEFORE_TIMESLOT: 2,
        })
        .run(['$rootScope', 'IMAGES_PATHS', 'ORDER_STATUSES', 'ORDER_STATUS_STAGES', 'CREDIT_CARD_MODES', 'CREDIT_CARD_3DS_MODES',
            'CREDIT_CARD_PROVIDERS', 'ANALYTICS_CURRENCY', 'SUBSTITUTE_PREFERENCES', 'DELIVERY_TIMES_TYPES', 'DELIVERY_TYPES',
            'CHECKOUT_METHODS', 'BRANCH_AREA_TYPES', 'CART_LINE_TYPES', 'TICKET_STATUSES', 'MULTI_DOMAINS_PICKERS', 'CATEGORIES_TYPES',
            'MOBILE_SERVICE_ID', 'LANGUAGES', 'DELIVERY_CHOICES', 'CITY_TYPES', 'CREDIT_CARD_TYPES', 'USER_VERIFICATION_STATUS',
            'PROMOTION_TYPES', 'HOME_PAGE_CAROUSEL_DISPLAY_TYPES', 'BANNER_HEIGHT', 'WEB_PAGE_LOCATIONS', 'CHARGE_SPECIALS_CALCULATION_TIME',
            'RETAILER_STATUSES', 'ADVERTISEMENT_AD_TYPE', 'SUPERVISION_TAGS', 'IMAGE_TYPES', 'ORGANIZATIONS_ROLES', 'ORGANIZATION_TYPES',
            'POLICY_ENFORCEMENT_MODES', 'DEBUGGING_PROVIDERS','HOUSE_ENTRIES','LINE_STATUS', 'LOYALTY_CLUB_DRIVERS', 'RECEIPT_TYPE_ID',
            'DEFAULT_AFTER_FIRST_ORDER', 'LAST_MINUTE_RECOMMENDATIONS', 'ORDER_DELIVERY_PROVIDER_STATUSES', 'DELIVERY_PROVIDER_STATUS_ICONS',
            'LOGIN_WITH_OTP_OPTIONS', 'LOGIN_OR_REGISTER_SCREENS', 'USER_TYPE_FOR_COMPLETION_FORM', 'COUPON_MEANINGLESS_REMAINING_VALUES', 'LOYALTY_PAGE_METHODS',
            'LOYALTY_PAGE_COMPONENT_TYPES', 'USER_ERRORS', 'PRODUCT_DISPLAY', 'RETAILER_PROMOTION_EVENTS', 'BRANCH_DELIVERY_TYPES', 'USER_LOYALTY_TYPES', 'GOOGLE_MAP_ADDRESS_TYPES', 'GOOGLE_MAP_MOBILE_EVENTS', 'TAG_DATE_CONFIGURATION_TYPE', 'ORDER_UPDATE_POLICY', 'FILTER_ADDRESS_MODE',
            function ($rootScope, IMAGES_PATHS, ORDER_STATUSES, ORDER_STATUS_STAGES, CREDIT_CARD_MODES, CREDIT_CARD_3DS_MODES,
                      CREDIT_CARD_PROVIDERS, ANALYTICS_CURRENCY, SUBSTITUTE_PREFERENCES, DELIVERY_TIMES_TYPES, DELIVERY_TYPES,
                      CHECKOUT_METHODS, BRANCH_AREA_TYPES, CART_LINE_TYPES, TICKET_STATUSES, MULTI_DOMAINS_PICKERS, CATEGORIES_TYPES,
                      MOBILE_SERVICE_ID, LANGUAGES, DELIVERY_CHOICES, CITY_TYPES, CREDIT_CARD_TYPES, USER_VERIFICATION_STATUS,
                      PROMOTION_TYPES, HOME_PAGE_CAROUSEL_DISPLAY_TYPES, BANNER_HEIGHT, WEB_PAGE_LOCATIONS, CHARGE_SPECIALS_CALCULATION_TIME,
                      RETAILER_STATUSES, ADVERTISEMENT_AD_TYPE, SUPERVISION_TAGS, IMAGE_TYPES, ORGANIZATIONS_ROLES, ORGANIZATION_TYPES,
                      POLICY_ENFORCEMENT_MODES, DEBUGGING_PROVIDERS, HOUSE_ENTRIES, LINE_STATUS, LOYALTY_CLUB_DRIVERS, RECEIPT_TYPE_ID, 
                      DEFAULT_AFTER_FIRST_ORDER, LAST_MINUTE_RECOMMENDATIONS, ORDER_DELIVERY_PROVIDER_STATUSES, DELIVERY_PROVIDER_STATUS_ICONS,
                      LOGIN_WITH_OTP_OPTIONS, LOGIN_OR_REGISTER_SCREENS, USER_TYPE_FOR_COMPLETION_FORM, COUPON_MEANINGLESS_REMAINING_VALUES, LOYALTY_PAGE_METHODS,
                      LOYALTY_PAGE_COMPONENT_TYPES, USER_ERRORS, PRODUCT_DISPLAY, RETAILER_PROMOTION_EVENTS, BRANCH_DELIVERY_TYPES, USER_LOYALTY_TYPES,GOOGLE_MAP_ADDRESS_TYPES, GOOGLE_MAP_MOBILE_EVENTS, TAG_DATE_CONFIGURATION_TYPE, ORDER_UPDATE_POLICY, FILTER_ADDRESS_MODE) {
                $rootScope.IMAGES_PATHS = IMAGES_PATHS;
                $rootScope.ORDER_STATUSES = ORDER_STATUSES;
                $rootScope.ORDER_STATUS_STAGES = ORDER_STATUS_STAGES;
                $rootScope.CREDIT_CARD_MODES = CREDIT_CARD_MODES;
                $rootScope.CREDIT_CARD_3DS_MODES = CREDIT_CARD_3DS_MODES;
                $rootScope.CREDIT_CARD_PROVIDERS = CREDIT_CARD_PROVIDERS;
                $rootScope.ANALYTICS_CURRENCY = ANALYTICS_CURRENCY;
                $rootScope.SUBSTITUTE_PREFERENCES = SUBSTITUTE_PREFERENCES;
                $rootScope.DELIVERY_TIMES_TYPES = DELIVERY_TIMES_TYPES;
                $rootScope.DELIVERY_TYPES = DELIVERY_TYPES;
                $rootScope.CHECKOUT_METHODS = CHECKOUT_METHODS;
                $rootScope.BRANCH_AREA_TYPES = BRANCH_AREA_TYPES;
                $rootScope.CART_LINE_TYPES = CART_LINE_TYPES;
                $rootScope.TICKET_STATUSES = TICKET_STATUSES;
                $rootScope.MULTI_DOMAINS_PICKERS = MULTI_DOMAINS_PICKERS;
                $rootScope.CATEGORIES_TYPES = CATEGORIES_TYPES;
                $rootScope.MOBILE_SERVICE_ID = MOBILE_SERVICE_ID;
                $rootScope.LANGUAGES = LANGUAGES;
                $rootScope.DELIVERY_CHOICES = DELIVERY_CHOICES;
                $rootScope.CITY_TYPES = CITY_TYPES;
                $rootScope.CREDIT_CARD_TYPES = CREDIT_CARD_TYPES;
                $rootScope.PROMOTION_TYPES = PROMOTION_TYPES;
                $rootScope.HOME_PAGE_CAROUSEL_DISPLAY_TYPES = HOME_PAGE_CAROUSEL_DISPLAY_TYPES;
                $rootScope.BANNER_HEIGHT = BANNER_HEIGHT;
                $rootScope.WEB_PAGE_LOCATIONS = WEB_PAGE_LOCATIONS;
                $rootScope.CHARGE_SPECIALS_CALCULATION_TIME = CHARGE_SPECIALS_CALCULATION_TIME;
                $rootScope.RETAILER_STATUSES = RETAILER_STATUSES;
                $rootScope.ADVERTISEMENT_AD_TYPE = ADVERTISEMENT_AD_TYPE;
                $rootScope.SUPERVISION_TAGS = SUPERVISION_TAGS;
                $rootScope.IMAGE_TYPES = IMAGE_TYPES;
                $rootScope.USER_VERIFICATION_STATUS = USER_VERIFICATION_STATUS;
                $rootScope.ORGANIZATIONS_ROLES = ORGANIZATIONS_ROLES;
                $rootScope.ORGANIZATION_TYPES = ORGANIZATION_TYPES;
                $rootScope.POLICY_ENFORCEMENT_MODES = POLICY_ENFORCEMENT_MODES;
                $rootScope.DEBUGGING_PROVIDERS = DEBUGGING_PROVIDERS;
                $rootScope.HOUSE_ENTRIES = HOUSE_ENTRIES;
                $rootScope.LINE_STATUS = LINE_STATUS;
                $rootScope.LOYALTY_CLUB_DRIVERS = LOYALTY_CLUB_DRIVERS;
                $rootScope.RECEIPT_TYPE_ID = RECEIPT_TYPE_ID;
                $rootScope.DEFAULT_AFTER_FIRST_ORDER = DEFAULT_AFTER_FIRST_ORDER;
                $rootScope.LAST_MINUTE_RECOMMENDATIONS = LAST_MINUTE_RECOMMENDATIONS;
                $rootScope.ORDER_DELIVERY_PROVIDER_STATUSES = ORDER_DELIVERY_PROVIDER_STATUSES;
                $rootScope.DELIVERY_PROVIDER_STATUS_ICONS = DELIVERY_PROVIDER_STATUS_ICONS;
                $rootScope.LOGIN_WITH_OTP_OPTIONS = LOGIN_WITH_OTP_OPTIONS;
                $rootScope.LOGIN_OR_REGISTER_SCREENS = LOGIN_OR_REGISTER_SCREENS;
                $rootScope.USER_TYPE_FOR_COMPLETION_FORM = USER_TYPE_FOR_COMPLETION_FORM;
                $rootScope.COUPON_MEANINGLESS_REMAINING_VALUES = COUPON_MEANINGLESS_REMAINING_VALUES;
                $rootScope.LOYALTY_PAGE_METHODS = LOYALTY_PAGE_METHODS;
                $rootScope.LOYALTY_PAGE_COMPONENT_TYPES = LOYALTY_PAGE_COMPONENT_TYPES;
                $rootScope.RETAILER_PROMOTION_EVENTS = RETAILER_PROMOTION_EVENTS
                $rootScope.USER_ERRORS = USER_ERRORS;
                $rootScope.PRODUCT_DISPLAY = PRODUCT_DISPLAY;
                $rootScope.USER_LOYALTY_TYPES = USER_LOYALTY_TYPES;
                $rootScope.BRANCH_DELIVERY_TYPES = BRANCH_DELIVERY_TYPES;
                $rootScope.GOOGLE_MAP_ADDRESS_TYPES = GOOGLE_MAP_ADDRESS_TYPES;
                $rootScope.GOOGLE_MAP_MOBILE_EVENTS = GOOGLE_MAP_MOBILE_EVENTS;
                $rootScope.TAG_DATE_CONFIGURATION_TYPE = TAG_DATE_CONFIGURATION_TYPE;
                $rootScope.FILTER_ADDRESS_MODE = FILTER_ADDRESS_MODE;
                $rootScope.ORDER_UPDATE_POLICY = ORDER_UPDATE_POLICY;
            }]);
})(window.sp);
