angular.module('mobilezuz')
    .directive('couponCartLine', [function () {
        return {
            restrict: 'E',
            templateUrl: 'views/templates/coupon-cart-line.html',
            replace: true,
            scope: {
                line: '='
            },
            controller: ['$rootScope', 'CouponDetailsDialog', function ($rootScope, CouponDetailsDialog) {
                this.goToCoupon = function(coupon, showTerms) {
                    if (showTerms && (!$rootScope.userCouponsById || !$rootScope.userCouponsById[coupon.id] || !$rootScope.userCouponsById[coupon.id].description)) {
                        return;
                    }

                    if (!coupon.special) {
                        coupon.special = coupon.branch.specials.find(function(special) {
                            return special.isCoupon;
                        });
                    }

                    CouponDetailsDialog.show(coupon, showTerms);
                }
            }],
            controllerAs: 'couponCartLineCtrl'
        };
    }]);