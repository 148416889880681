angular.module('mobilezuz').service('Coupon', [
    'Api',
    function(Api) {
        var self = this;

        self.getNumberOfCoupons = getNumberOfCoupons;

        function getNumberOfCoupons() {
            return Api.request({
                method: "GET",
                url: "/v2/retailers/:rid/branches/:bid/users/:uid/coupons",
                params: { extended: true, countonly: true },
            })
        }
    }]);




