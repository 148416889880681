(function (angular) {
angular.module('mobilezuz')
    .controller('ChooseFromNumberOfAreas', ['$scope', 'mDesign', 'areas', function ($scope, mDesign, areas) {
        $scope.areas = areas;

        $scope.cancel = mDesign.cancel;
        $scope.choose = function (address) {
            var data = {};
            address.address_components.forEach(function (addressComponent) {
                angular.forEach(addressComponent.types, function(type) {
                    if ((!data.city && type === 'locality') || type === 'sublocality') {
                        data.city = addressComponent.long_name;
                    } else if (type === 'route') {
                        data.route = addressComponent.long_name;
                    } else if (type === 'street_number') {
                        data.number = addressComponent.long_name;
                    } else if (type === 'postal_code') {
                        data.zipCode = addressComponent.long_name;
                    }
                });
            });

            mDesign.hide({
                address: {
                    city: data.city,
                    text1: (data.number || '') + (data.number && data.route ? ' ' : '') + (data.route || ''),
                    zipCode: data.zipCode,
                    lat: address.geometry && address.geometry.location.lat,
                    lng: address.geometry && address.geometry.location.lng,
                    externalPlaceId: address.place_id,
                },
                geoCoordinates: address.geometry && address.geometry.location,
                externalPlaceId: address.place_id
            });
        };
    }]);
})(angular);