(function (angular, app) {
    'use strict';

    app.service('DeliveryWithinDaysWarningDialog', [
        '$injector', 'Config', 'mDesign', 'SpDeliveryAreasService', 'DELIVERY_TIMES_TYPES', 'PRODUCT_TAG_TYPES',
        function ($injector, Config, mDesign, SpDeliveryAreasService, DELIVERY_TIMES_TYPES, PRODUCT_TAG_TYPES) {
            var Cart;

            this.show = show;

            function show() {
                return Config.waitForInit().then(function() {
                    // when an area was not yet selected, do nothing
                    if (!Config.branchAreaId) {
                        return;
                    }

                    // when the feature is off, do nothing
                    if (!Config.retailer.isDeliveryWithinDaysByTag) {
                        return;
                    }

                    Cart = Cart || $injector.get('Cart');

                    // when there are no products in the cart, do nothing
                    if (!Cart.total || !Cart.total.crossProductTagTypes || !Cart.total.crossProductTagTypes.none) {
                        return;
                    }

                    // when the cart has a cross cart product tag type of delivery within days, do nothing
                    if (Cart.total.crossProductTagTypes[PRODUCT_TAG_TYPES.DELIVERY_WITHIN_DAYS]) {
                        return;
                    }

                    return SpDeliveryAreasService.isAreaDeliveryWithinDaysOnly(Config.branchAreaId, Config.retailer.id);
                }).then(function(isDeliveryWithinDaysOnly) {
                    // when the area has other delivery time types, show no warning
                    if (!isDeliveryWithinDaysOnly) {
                        return;
                    }

                    return mDesign.dialog({
                        controller: 'DeliveryWithinDaysWarningDialog as warningCtrl',
                        templateUrl: 'views/templates/delivery-within-days-product-warning.html',
                        clickOutsideToClose: false,
                        escapeToClose: false
                    });
                });
            }
        }
    ]);

    app.controller('DeliveryWithinDaysWarningDialog', [
        'mDesign', 'ChooseAreaDialog', 'Cart', 'SpProductTags', 'PRODUCT_TAG_TYPES',
        function (mDesign, ChooseAreaDialog, Cart, SpProductTags, PRODUCT_TAG_TYPES) {
            var warningCtrl = this;

            warningCtrl.removeLines = removeLines;
            warningCtrl.chooseArea = chooseArea;

            SpProductTags.getDeliveryWithinDaysTag().then(function(tag) {
                warningCtrl.deliveryWithinDaysTag = tag;
            });

            function removeLines() {
                angular.forEach(Cart.lines, function(line) {
                    if (!line.removed && (!line.productTagTypes ||
                        !line.productTagTypes[PRODUCT_TAG_TYPES.DELIVERY_WITHIN_DAYS])) {
                        Cart.removeLine(line);
                    }
                });

                mDesign.hide();
            }

            function chooseArea() {
                ChooseAreaDialog.show(false, true, null);

                mDesign.hide();
            }
        }]);
})(angular, angular.module('mobilezuz'));