angular.module('mobilezuz')
    .service('UpdateOrderV2Service', [
        '$q', '$rootScope', '$filter', 'Api', 'mDesign', '$state', 'Config', 'Cart', 'Util', 'User', 'Orders',
        'ORDER_STATUS_STAGES', 'SP_SERVICES', 'SP_PAYMENTS', 'PaymentsService', 'Retailer', 'ShippingTermsPopup','$timeout',
        function ($q, $rootScope, $filter, Api, mDesign, $state, Config, Cart, Util, User, Orders,
            ORDER_STATUS_STAGES, SP_SERVICES, SP_PAYMENTS, PaymentsService, Retailer, ShippingTermsPopup, $timeout) {
            var updateOrderV2Service = this;

            var ADD_EDIT_ORDER_LINES_STORAGE_KEY = 'mobileZuzAddEditOrderLines',
            userLoginData = null,
            _roundCurrencyFilter = $filter('roundCurrency');
            
            init();

            function init(){
                userLoginData = User.getUserLoginData();

                if (userLoginData) {
                    User.getUserSettings().then(function (resp) {
                        updateOrderV2Service.userData = resp;
                    });
                }
            }
            angular.extend(updateOrderV2Service, {
                editOrder: editOrder,
                goToHomePage: goToHomePage
            });

            /**
            * Edit order
            *
            * @param {Object} [options]
            * @param {boolean} [options.editTimeSlot] 
            * @param {boolean} [options.skipV1IrrelevantSales] 
            * @return {Promise}
            */
            function editOrder(options) {
                options = options || {};
                // need to improve and return error message
                // not just return empty 
                if (!Cart.serverCartId || !Cart.lines || updateOrderV2Service.disableUpdateOrder) {
                    return;
                }

                var isLimitPassed = Cart.checkHeavyLimitBeforeAddLines([]);
                if (isLimitPassed === false) {
                    updateOrderV2Service.disableUpdateOrder = true;

                    return Cart.checkUserCreditLimited(false, '').then(function (isLimited) {
                        if (isLimited) {
                            return $q.reject('userCreditLimited');
                        }
                        return $q.resolve();
                    }).then(function () {
                        if (options.skipV1IrrelevantSales) {
                            return $q.resolve();
                        }

                        var outOfStockLines = [];
                        angular.forEach(Cart.lines, function (line) {
                            if (!!line.deliveryProduct) {
                                return;
                            }
                            line.isProductOutOfStock = Util.isProductOutOfStock(line);
                            line.isNeedToShowOutOfStockLabel = Util.isNeedToShowOutOfStockLabel(line);

                            var isExistLine = outOfStockLines.find(function (l) {
                                return l.id == line.id;
                            });

                            if ((line.isProductOutOfStock || line.isCouponActive === false) && !isExistLine) {
                                outOfStockLines.push(line);
                            }
                        });

                        if (outOfStockLines && outOfStockLines.length) {
                            return _outOfStockDialog(outOfStockLines).then(function (result) {
                                return !result || !result.isConfirmed ? $q.reject('Out of stock not confirmed') : $q.resolve();
                            });
                        }
                        return $q.resolve();
                    }).then(function () {
                        return Retailer.getRetailerSettings();
                    }).then(function (retailerData) {
                        if (ShippingTermsPopup.isShowPopup()) {
                            return Util.closeUpdateDialog().then(function () {
                                return ShippingTermsPopup.showPopup();
                            }).then(function (isProceed) {
                                if (!isProceed) {
                                    return $q.reject('shippingTermsDeclined');
                                }
                                return retailerData;
                            })
                        } else{
                            return retailerData;
                        };
                    }).then(function (retailerData) {
                        updateOrderV2Service.cvvUpdateConfirm = retailerData.settings.cvvUpdateConfirm === 'true';

                        var deliveryMinimumCostByArea = _getAreaById(Config.branchAreaId);
                        deliveryMinimumCostByArea = deliveryMinimumCostByArea && deliveryMinimumCostByArea.deliveryMinimumCost;
                        var minimumOrderPrice = (!!updateOrderV2Service.userData.personalMinOrderLimit || updateOrderV2Service.userData.personalMinOrderLimit === 0) ?
                            updateOrderV2Service.userData.personalMinOrderLimit : (Number(deliveryMinimumCostByArea) || Number(retailerData.settings.minimumOrderPrice));

                        return Util.validateMinimumCost(minimumOrderPrice, retailerData.notIncludeSpecials,
                            deliveryMinimumCostByArea && Config.branchAreaName).then(function () {
                                return retailerData;
                            });
                    }).then(function (retailerData) {

                        return Orders.getOrderDetails(Orders.orderInEdit.id, {
                            fireAndForgot: true
                        }).then(function (order) {
                            if (options.skipV1IrrelevantSales) {
                                return $q.resolve({ order: order });
                            }

                            var irrelevantSalesLines = getIrrelevantSales();

                            function getIrrelevantSales() {
                                var irrelevantSalesLines = [];
                                if (order.shippingTimeTo) {
                                    var deliveryDay = new Date(order.shippingTimeTo.split('T')[0]);
                                    angular.forEach(Cart.lines, function (line) {

                                        if (!Util.isSellOnDate(line, new Date(deliveryDay))) {
                                            irrelevantSalesLines.push(line);
                                        }
                                    });
                                }
                                return irrelevantSalesLines;
                            }

                            if (irrelevantSalesLines.length > 0) {
                                return mDesign.dialog({
                                    clickOutsideToClose: false,
                                    controller: ['$scope', function ($scope) {
                                        $scope.irrelevantSellDates = irrelevantSalesLines;
                                        $scope.potentialCartTotal = order.totalAmount;
                                        $scope.close = mDesign.hide;
                                        $scope.listTemplateUrl = [
                                            "views/templates/irrelevant-sales/sell-date-dialog.html",
                                        ];
                                        $scope.displayChoosenDateButton = false;
                                        $scope.irrelevantSellDates.forEach(function (line) {
                                            var finalPrice = line.finalPriceForView || 0
                                            $scope.potentialCartTotal -= Number(finalPrice || 0);
                                        });
                                    }],
                                    templateUrl: 'views/templates/irrelevant-sales/index.html'
                                }).then(function (pass) {
                                    return $q.resolve({ order: order, pass: pass == null, irrelevantSalesLines: irrelevantSalesLines })
                                })
                            }
                            return $q.resolve({ order: order, pass: true, irrelevantSalesLines: irrelevantSalesLines })
                        }).then(function (rs) {
                            if (options.skipV1IrrelevantSales) {
                                return rs.order;
                            }

                            if (rs.pass) {
                                if (rs.irrelevantSalesLines.length === 0) {
                                    return rs.order;
                                }
                                return Cart.removeLines(rs.irrelevantSalesLines, undefined, true).then(function () {
                                    return rs.order;
                                })
                            }
                            return;
                        }).then(function (order) {
                            if (!order) {
                                return;
                            }

                            if (!Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.RECEIVED) || order.isCustomerEditBlocked) {
                                return Util.closeUpdateDialog().then(function(){
                                    _editOrderDialog(order);
                                });
                            }

                            var remainingCharge = _roundCurrencyFilter(Cart.total.finalPriceWithTax +
                                Cart.total.serviceFee.finalPriceWithTax + Cart.total.deliveryCost.finalPriceWithTax) -
                                order.initialCheckoutCharge;
                            var paymentOnUpdateOrderMinSum = !!retailerData.settings.paymentOnUpdateOrderMinSum && Number(retailerData.settings.paymentOnUpdateOrderMinSum);
                            if (retailerData.settings.paymentOnUpdateOrder === 'true' && remainingCharge > (paymentOnUpdateOrderMinSum || 0)) {
                                return Util.closeUpdateDialog().then(function(){
                                        _proceedToAdditionalPaymentDialog(remainingCharge);
                                    });
                            }

                            if (order.paymentData && order.paymentData.ebtPayment) {
                                return Util.closeUpdateDialog(2000).then(function(){
                                        $state.go('app.cart.order-update.ebt-payment');
                                    });
                            }
                            var shouldUse3DSOnUpdate = Config.retailer.settings.creditCard3DSModes && Config.retailer.settings.creditCard3DSModes.map(function (value) { return +value });
                            if (
                                updateOrderV2Service.cvvUpdateConfirm &&
                                !shouldUse3DSOnUpdate &&
                                order.paymentData &&
                                order.paymentData.mainPayment &&
                                order.paymentData.mainPayment.paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD
                            ) {

                                Util.closeUpdateDialog()
                                    .then(function(){ return openConfirmCVVPopup(); })
                                    .then(function () {
                                        var summaryData = updateOrderV2Service.summaryData;

                                        if (summaryData) {
                                            if (summaryData.proceedToCheckout && !summaryData.popupAgain) {
                                                options.showSummary = summaryData
                                                return _checkoutUpdate(options);
                                            }
                                            else if (!summaryData.proceedToCheckout && summaryData.summaryData.popupAgain) {
                                                openConfirmCVVPopup()
                                            }
                                        }

                                    })
                            }
                            else if (shouldUse3DSOnUpdate &&
                                updateOrderV2Service.cvvUpdateConfirm &&
                                order.paymentData &&
                                order.paymentData.mainPayment &&
                                order.paymentData.mainPayment.paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD) {
                                Util.closeUpdateDialog()
                                    .then(function(){ return openConfirmCVVPopup(); })
                                    .then(function () {
                                        var summaryData = updateOrderV2Service.summaryData;

                                        if (summaryData) {
                                            if (summaryData.proceedToCheckout && !summaryData.popupAgain) {
                                                PaymentsService.payWithCreditCard({
                                                    creditCardId: (summaryData.creditCard || {}).id,
                                                    cvv: summaryData.cvv,
                                                    action: 'checkout',
                                                }).then(function (paymentData) {
                                                    summaryData.cvvConfirmUpdate = true;
                                                    if (!paymentData) {
                                                        return
                                                    }
                                                    var payment = {
                                                        cvv: summaryData.cvv,
                                                        methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD,
                                                        typeId: SP_PAYMENTS.PAYMENT_TYPES.CREDIT,
                                                        token: paymentData.paymentToken,
                                                        authNum: paymentData.authNum,
                                                        preAuthWithStaticAmount: paymentData.preAuthWithStaticAmount,
                                                        preAuthStaticAmount: paymentData.preAuthStaticAmount,
                                                        creditCardId: summaryData.creditCard && summaryData.creditCard.id,
                                                        preAuthAmount: !isEmptyObjectWithDelivery($rootScope.cart.lines) ?
                                                            ($rootScope.cart.total.finalPriceForViewWithDeliveryFee || $rootScope.cart.total.finalPriceForView) :
                                                            ($rootScope.cart.total.finalPriceForView)
                                                    }
                                                    updateOrderV2Service.payment = payment;

                                                    options.showSummary = true
                                                    return _checkoutUpdate(options);
                                                }).catch(function (err) {
                                                    if (err.statusCode === 409 && err.response &&
                                                        err.response.error === 'Already checked out' && err.response.orderId) {
                                                        return { actionResponse: { id: err.response.orderId } };
                                                    } else {
                                                        throw err;
                                                    }
                                                });
                                            }
                                            else if (!summaryData.proceedToCheckout && summaryData.summaryData.popupAgain) {
                                                openConfirmCVVPopup()
                                            }
                                        }

                                    })

                            } else {
                                return _checkoutUpdate(options);
                            }
                        });
                    }).catch(function (err) {
                        if (err !== 'userCreditLimited') {
                            return $q.reject(err);
                        }
                    }).finally(function () {
                        updateOrderV2Service.disableUpdateOrder = false;
                        updateOrderV2Service.summaryData = null;
                    });
                }
            }

            function _checkoutUpdate(options) {
                options = options || {};
                var timeSlotData = Util.getNewTimeSlotData(Orders.orderInEdit.id, Cart.serverCartId);
                var localStorageTimeslotDataId = 'updateTimeSlot-orderId-' + Orders.orderInEdit.id + '-cartId-' + Cart.serverCartId;
                return Api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/branches/:bid/users/:uid/_checkoutUpdate',
                    data: {
                        newCartId: Cart.serverCartId,
                        orderId: Orders.orderInEdit.id,
                        skipActiveProductsValidation: true,
                        payment: updateOrderV2Service.payment,
                        newAreaDeliveryTimeId: timeSlotData && timeSlotData.newAreaDeliveryTimeId,
                        newDate: timeSlotData && timeSlotData.newDate,
                        isEnableUpdateTimeSlot: Util.checkIsPremiumEditOrderToggled(),
                        isChangeDeliveryTime: options.isChangeDeliveryTime || false
                    }
                }, {
                    hideError: true
                }).then(function () {
                    Orders.getOrderDetails(Orders.orderInEdit.id).then(function (order) {
                        $rootScope.$emit('order.update.timeslot.refresh', {
                            order: order
                        });
                    });
                    
                    //Google analytics listener.
                    // $rootScope.$emit('checkout', Orders.orderInEdit.id, angular.copy(Cart.total), angular.copy(Cart.lines));
                    var isUpdateDialogOpenning = !!document.querySelector('md-dialog.edit-order-v2');
                    if (timeSlotData) {
                        if (!isUpdateDialogOpenning) {
                            Util.openEditOrderDialog("FINISH", false, Orders.orderInEdit.id);
                        } else {
                            $rootScope.$emit('order.update.timeslot.finish');
                        }
                    } else if (isUpdateDialogOpenning) {
                        Util.closeUpdateDialog();
                    }

                    var order = Orders.orderInEdit;
                    updateOrderV2Service.updatedOrderId = Orders.orderInEdit.id;
                    updateOrderV2Service.updateOrder = true;
                    Orders.orderInEdit = null;

                    Cart.clear(true);

                    //== refreshing User's Credit data after order update
                    if ($rootScope.isCreditCustomer) {
                        User.getUserSettings(true);
                    }

                    $rootScope.$emit('update-order-v2-summary', {
                        showSummary: options.showSummary,
                        order: order
                    });

                }).catch(function (err) {
                    if (err.statusCode !== 400) {
                        Orders.cancelEdit().then(function () {
                            Cart.clear();
                        }).catch(function () {
                            Orders.orderInEdit = null;
                            Cart.clear(true);
                        });
                    }

                    if ((err.statusCode !== 300 && err.statusCode !== 401) && err.response && err.response.error) {
                        //== when Credit customer is limited we need to display a custom popup with remaining sum
                        if (err.response.code === 'creditCustomerLimited') {
                            return Cart.checkUserCreditLimited(true, '');
                        } else {
                            return Util.closeUpdateDialog().then(function () {
                                mDesign.alert('{{\'Update order failed\' | translate}}.<br/>{{\'' + err.response.error.replace(/'/g, '\\\'') + '\' | translate}}');
                            });
                        }
                    }

                    if (err.apiOptions) {
                        err.apiOptions.hideError = false;
                        $rootScope.$emit('spApi.error', err);
                    }
                }).finally(function () {
                    updateOrderV2Service.payment = null;
                    $rootScope.$emit('order.update.timeslot.stop-loading');
                    localStorage.removeItem(localStorageTimeslotDataId);
                });
            }

            function _getAreaById(areaId) {
                var areas = Config.branch.areas;
                for (var i = 0; i < areas.length; i++) {
                    if (areas[i].id == areaId) {
                        return areas[i];
                    }
                }
            }

            function _proceedToAdditionalPaymentDialog(remainingCharge) {
                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: false,
                    templateUrl: 'views/templates/additional-payment-dialog.html',
                    controller: ['$scope', function ($scope) {
                        $scope.remainingCharge = remainingCharge;
                        $scope.proceedToPayment = function () {
                            mDesign.hide(true);
                        }
                        $scope.backToCart = function () {
                            mDesign.hide(false);
                        }
                    }]
                }).then(function (proceeed) {
                    if (proceeed) {
                        $state.go('app.cart.order-update');
                    }
                });
            }

            function _outOfStockDialog(outOfStockLines) {
                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: false,
                    templateUrl: 'views/templates/out-of-stock-dialog.html',
                    controller: ['$scope', function ($scope) {
                        $scope.lines = outOfStockLines;
                        $scope.hide = mDesign.hide;
                        $scope.isSentData = false;
                        $scope.productsCount = 0;
                        $scope.couponsCount = 0;

                        angular.forEach($scope.lines, function (line) {
                            if (line.isProductOutOfStock) {
                                ++$scope.productsCount;
                            }
                            if (line.isCouponActive === false) {
                                ++$scope.couponsCount;
                            }
                        });


                        $scope.removeLines = function () {
                            var promises = [];
                            $scope.isSentData = true;
                            angular.forEach(outOfStockLines, function (line) {
                                if (line.isProductOutOfStock || line.isCouponActive === false) {
                                    promises.push(Cart.removeLine(line, true, true));
                                }
                            });
                            $q.all(promises).then(function () {
                                mDesign.hide({ isConfirmed: true });
                            });
                        };
                    }]
                });
            }

            function openConfirmCVVPopup() {
                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: false,
                    templateUrl: 'views/update-cvv-confirm-dialog.html',
                    controller: [function () {
                        var cvvConfirmCtrl = this;
                        User.getUserSettings(true).then(function (userData) {
                            if (userData.creditCards && cvvConfirmCtrl.creditCards.length !== userData.creditCards.length) {
                                cvvConfirmCtrl.creditCards = userData.creditCards || [];
                                cvvConfirmCtrl.summaryData.creditCard = _getOrderCreditCard(userData.creditCards, Orders.orderInEdit) || cvvConfirmCtrl.creditCards[0];
                            }
                        });
                        cvvConfirmCtrl.creditCards = updateOrderV2Service.userData.creditCards || [];
                        cvvConfirmCtrl.summaryData = {
                            paymentMethod: null,
                            storeCredit: '',
                            creditCard: {},
                            paymentsNumber: 1
                        }
                        cvvConfirmCtrl.showAddCardDialog = function () {
                            mDesign.hide();
                            updateOrderV2Service.compiledUserAddress = Util.compiledUserAddress();
                            cvvConfirmCtrl.summaryData.proceedToCheckout = false;
                            cvvConfirmCtrl.summaryData.popupAgain = true;
                            if (Config.retailer.settings.useDeliveryAddressAsBilling && updateOrderV2Service.compiledUserAddress) {
                                return mDesign.dialog({
                                    templateUrl: 'views/templates/use-delivery-address-as-billing.html',
                                    controller: ['$scope', function ($dialogScope) {
                                        var dialogCtrl = this,
                                            userAddress = User.settings.addresses && User.settings.addresses.length && User.settings.addresses[0];
                                        dialogCtrl.sendUserAddress = false;
                                        dialogCtrl.compiledUserAddress = updateOrderV2Service.compiledUserAddress;
                                        dialogCtrl.cancel = cancel;
                                        $dialogScope.continue = function () {
                                            mDesign.hide();
                                            return PaymentsService.addCreditCard({
                                                sendUserAddress: dialogCtrl.sendUserAddress,
                                                address: userAddress.text1 ? userAddress.text1 : userAddress.text2 ? userAddress.text2 : '',
                                                city: userAddress.city ? userAddress.city : '',
                                                country: userAddress.country ? userAddress.country : '',
                                                countryCode: userAddress.countryCode ? userAddress.countryCode : '',
                                            }).then(function () {
                                                setTimeout(function () {
                                                    User.getUserSettings(true)
                                                        .then(function (user) {
                                                            goToCheckout(false, false)
                                                        })
                                                }, 1000);
                                            });
                                        };
                                        function cancel() {
                                            mDesign.hide();
                                        }
                                    }],
                                    controllerAs: 'dialogCtrl',
                                    styleClass: 'clear-cart-popup'
                                });
                            } else {
                                return PaymentsService.addCreditCard().then(function () {
                                    updateOrderV2Service.openConfirmCVVPopup()
                                });
                            }
                        }
                        var setData = function () {
                            cvvConfirmCtrl.summaryData.creditCard = _getOrderCreditCard(cvvConfirmCtrl.creditCards, Orders.orderInEdit) || cvvConfirmCtrl.creditCards[0];
                            cvvConfirmCtrl.hasCard = true;
                        }
                        cvvConfirmCtrl.closeDialog = function () {
                            mDesign.hide();
                            cvvConfirmCtrl.summaryData.proceedToCheckout = false;
                            cvvConfirmCtrl.summaryData.popupAgain = false;
                            return cvvConfirmCtrl.summmaryData
                        }
                        cvvConfirmCtrl.chooseCreditCard = function (creditCard) {
                            cvvConfirmCtrl.summaryData.proceedToCheckout = true;
                            cvvConfirmCtrl.summaryData.popupAgain = false;
                            cvvConfirmCtrl.summaryData.creditCard = creditCard;
                        }
                        cvvConfirmCtrl.confirmUpdate = function () {
                            cvvConfirmCtrl.summaryData.proceedToCheckout = true;
                            cvvConfirmCtrl.summaryData.popupAgain = false;
                            updateOrderV2Service.summaryData = cvvConfirmCtrl.summaryData;
                            if (!cvvConfirmCtrl.summaryData.cvv || cvvConfirmCtrl.summaryData.cvv.length < 3) {
                                cvvConfirmCtrl.errorState = {
                                    cvv: 'CVV Should not be empty and should have at least 3 digits'
                                }
                                return
                            }
                            else {
                                return mDesign.hide();
                            }

                        }
                        setData()
                    }],
                    controllerAs: 'cvvConfirmCtrl',
                    styleClass: 'cvv-confirm-popup',
                })
            }

            function isEmptyObjectWithDelivery(data) {
                if (data) {
                    var keys = Object.keys(data);
                    if (keys.length === 1) {
                        return data[keys[0]].type === SP_SERVICES.CART_LINE_TYPES.DELIVERY;
                    }
                    return keys.length === 0;
                }
                return false;
            }

            function _getOrderCreditCard(userCards, order) {
                var userCard = null;
                if (userCards.length && order.paymentData && order.paymentData.mainPayment && order.paymentData.mainPayment.lastFourDigits) {
                    userCard = userCards.find(function (card) {
                        return card.lastFourDigits === order.paymentData.mainPayment.lastFourDigits;
                    })
                }
                return userCard ? userCard : userCards.length && userCards[0];
            }

            function _editOrderDialog(order) {
                if (!Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.RECEIVED) && Orders.orderInEdit) {
                    var contentHeader = '',
                        contentFooter = '',
                        contentTitle = '';
                    if (Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.IN_PROCESS) || order.isCustomerEditBlocked) {
                        contentHeader = 'We have started collecting your original order.';
                        contentTitle = 'Your order cannot be changed at this time';
                    } else if (Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.READY)) {
                        contentHeader = 'We have finished collecting your original order.';
                    } else if (Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.CANCELLED)) {
                        contentTitle = 'Your order can no longer be changed';
                        contentHeader = 'This is a canceled order.';
                    } else {
                        contentHeader = 'We have finished collecting your original order.';
                    }

                    return mDesign.dialog({
                        focusOnOpen: false,
                        clickOutsideToClose: false,
                        templateUrl: 'views/templates/order-status-dialog.html',
                        controller: ['$scope', function ($scope) {
                            $scope.contentHeader = contentHeader;
                            $scope.contentTitle = contentTitle;
                            $scope.contentFooter = contentFooter;
                            $scope.hide = mDesign.hide;
                        }]
                    }).then(function (clearCart) {
                        return Orders.cancelEdit().then(function () {
                            if (clearCart) {
                                Cart.clear();
                            }
                        });
                    });
                }
            }

            function goToHomePage(){
                Util.closeUpdateDialog(2000).then(function(){
                    $state.go('app.home');
                });
            }
        }]);


