(function (angular) {
    'use strict';

    angular.module('mobilezuz').directive('spOutOfView', [
        '$document',
        function ($document) {
            return {
                restrict: 'A',
                scope: {
                    spOutOfViewAction: '&',
                },
                link: function ($scope, $element) {
                    $scope.spOutOfViewMeasurements = $scope.spOutOfViewMeasurements || {};
                    var positionedElement = $element[0];

                    angular.element($document).bind('touchmove', function() {
                        var rect = positionedElement.getBoundingClientRect();

                        if (rect.top < 0 || rect.bottom > $document[0].body.clientHeight) {
                            $scope.spOutOfViewAction();
                            $scope.$applyAsync();
                        }
                    });
                }
            };
        }]);

})(angular);