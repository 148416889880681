angular.module('mobilezuz')
    .service('Orders', [
        '$q', '$rootScope', '$timeout', '$window', 'Api', 'mDesign', '$state', 'Config', 'Cart', 'Util', 'User',
        'ORDER_STATUS_STAGES', 'ORDER_STATUSES', 'SP_SERVICES', 'SpDialogUrlManager',
        function ($q, $rootScope, $timeout, $window, Api, mDesign, $state, Config, Cart, Util, User,
                  ORDER_STATUS_STAGES, ORDER_STATUSES, SP_SERVICES, SpDialogUrlManager) {
            var self = this;

            var ADD_EDIT_ORDER_LINES_STORAGE_KEY = 'mobileZuzAddEditOrderLines';

            angular.extend(self, {
                getOrders: getOrders,
                getLastOrder: getLastOrder,
                getOrderStatus: getOrderStatus,
                getOrderDetails: getOrderDetails,
                cancelOrder: cancelOrder,
                editOrderDialog: editOrderDialog,
                cancelEdit: cancelEdit,
                watchEditOrderId: watchEditOrderId,
                checkIfUserCanUpdateOrder: checkIfUserCanUpdateOrder,
                getOrderBoxesStatuses: getOrderBoxesStatuses,
                getOrderDeliveryStatus: getOrderDeliveryStatus,
                enableEditOrder: enableEditOrder,
                editOrderDialogV2: editOrderDialogV2,

                lastOrder: null,
                orderInEdit: null,
                barcode: null,
                orderStatus: {}
            });

            function checkIfUserCanUpdateOrder(orderId) {
                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/orders/' + orderId + '/isCustomerEditBlocked'
                });
            }

            function getOrders(from, size) {
                var params = {
                    from: from,
                    size: size,
                    orderBy: [{id: 'desc'}]
                };

                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/users/:uid/orders',
                    params: params
                }, {
                    fireAndForgot: true
                });
            }

            function getLastOrder() {
                return Api.request({
                    url: '/retailers/:rid/users/:uid/lastOrder'
                });
            }

            function getOrderStatus(orderId) {
                return Api.request({
                    method: 'GET',
                    url: '/retailers/:rid/users/:uid/orders/' + orderId + '/status'
                }, {
                    fireAndForgot: true
                });
            }

            function getOrderDetails(orderId, apiOptions) {
                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/branches/:bid/users/:uid/orders/' + orderId
                }, apiOptions);
            }

            function getOrderDeliveryStatus(orderId) {
                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/' +  Config.retailerId + '/delivery/order/' + orderId
                }, {
                    fireAndForgot: true
                });
            }

            function cancelOrder(order, apiOptions) {
                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: false,
                    templateUrl: 'views/templates/cancel-order.html',
                    controller: ['$scope', function ($scope) {
                        $scope.order = order;
                        $scope.user = User.getUserLoginData();
                        $scope.cancelOrder = cancelOrder;
                        $scope.closeDialog = mDesign.hide;

                        function cancelOrder() {

                            return Api.request({
                                method: 'POST',
                                url: '/v2/retailers/:rid/branches/:bid/users/:uid/orders/' + order.id + '/_cancel'
                            }, apiOptions).then(function(additionalData) {
                                $rootScope.$emit('orders.cancelOrder', order);
                                $rootScope.$emit('orderCanceled', order.id);
                                $scope.orderCancelled = true;
                                order.additionalData = additionalData;
                            });
                        }
                    }]
                });
            }

            function getOrderBoxesStatuses(orderId){
                var urlToGet = '/v2/retailers/' +  Config.retailerId +
                    '/delivery/order/' + orderId + '/status-order-boxes';

                return Api.request({
                    method: 'GET',
                    url: urlToGet,
                    headers: {
                        'api-version': '2.0'
                    }
                }).then(function (orderBoxes) {
                    return orderBoxes;
                }).catch(function (err) {
                    throw err;
                });
            }

            function editOrderDialog(order) {
                if(Util.checkIsPremiumEditOrderToggled()) {
                    return editOrderDialogV2(order.id);
                }
                var _templateUrl;
                if (Object.keys(Cart.lines).length === 0) {
                    _templateUrl = 'views/templates/edit-order-dialog.html';
                } else {
                    _templateUrl = 'views/templates/edit-order-with-cart-lines.html';
                }

                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: false,
                    templateUrl: _templateUrl,
                    controller: ['$scope', function ($scope) {
                        $scope.edit = edit;
                        $scope.cancel = cancel;

                        function edit() {
                            $scope.inProgress = true;
                            return Cart.createCart().then(function() {
                                return Api.request({
                                    method: 'PATCH',
                                    url: '/retailers/:rid/users/:uid/orders/' + order.id + '/reopen/' + Cart.serverCartId
                                });
                            }).then(function () {
                                return getOrderDetails(order.id, {fireAndForgot: true});
                            }).then(function(order) {
                                self.orderInEdit = order;
                                $rootScope.$emit('orderEdit', order);
                                return mDesign.hide().then(function() {
                                    return _setEditOrder(order, true);
                                });
                            }).catch(function(err) {
                                cancel();
                                throw err;
                            }).finally(function () {
                                $scope.inProgress = false;
                                Cart.init();
                            });
                        }

                        function cancel() {
                            mDesign.hide();
                        }
                    }]
                });
            }

            function cancelEdit() {
                if (!self.orderInEdit || !Cart.serverCartId) {
                    return $q.resolve();
                }

                return Api.request({
                    method: 'PATCH',
                    url: '/retailers/:rid/users/:uid/orders/' + self.orderInEdit.id + '/cancelEdit/' + Cart.serverCartId
                }).then(function () {
                    self.orderInEdit = null;
                });
            }

            function _setEditOrder(order, addLines) {
                return Config.changeBranch(order.branchId, order.branchAreaId, {
                    forceBranchChange: true
                }).then(function(isRefresh) {
                    if (addLines) {
                        if (isRefresh) {
                            $window.localStorage.setItem(ADD_EDIT_ORDER_LINES_STORAGE_KEY, 1);
                        } else {
                            return _copyOrderToNewCart(order);
                        }
                    }
                    return $q.resolve();
                }).then(function(isRefresh) {
                    $state.go('app.cart');
                    return isRefresh;
                });
            }

            function _copyOrderToNewCart(order) {
                angular.forEach(order.lines, function (line) {
                    if (!line.isPseudo) return;

                    var category = line.categoryId && {id: line.categoryId, names: {}};
                    if (category) {
                        category.names[Config.language.id] = line.categoryName;
                        var results = Util.getCategory(category.id);
                        if (results && results.length) {
                            category.names = results[0].names;
                        }
                    }

                    line.text = line.name;

                    line.product = {
                        id: line.name,
                        name: line.name,
                        categories: [category],
                        names: {}
                    };

                    line.product.names[Config.language.id] = {
                        short: line.name,
                        long: line.name
                    };
                });

                Cart.clear();

                var isLimitPassed = Cart.checkHeavyLimitBeforeAddLines(order.lines);
                if (isLimitPassed === false) {
                    if (Cart.lines && Object.keys(Cart.lines).length === 0 &&
                        $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                        Cart.addDeliveryFeeLineIfNeeded();
                    }
                    return Cart.addLines(Util.getActiveLines(order.lines, false, true, true, true), SP_SERVICES.SOURCES.ORDERS, !!self.orderInEdit);
                }
                return $q.resolve();
            }

            var _isCartAlreadyUpdated = false;

            $rootScope.$on('cart.update.complete', function () {
                _isCartAlreadyUpdated = true;
            });

            //this function must called from app.js
            function watchEditOrderId() {
                $rootScope.$on('cart.editOrderId.change', function() {
                    var userLoginData = User.getUserLoginData();
                    if (!userLoginData) return;

                    if (Cart && Cart.editOrderId !== (self.orderInEdit && self.orderInEdit.id)) {
                        if ($state.includes('app.cart.checkout')) {
                            return self.cancelEdit();
                        } else {
                            if (_isCartAlreadyUpdated) {
                                var contentHeader = 'It seems you are editing your order on a different device/browser.',
                                    contentTitle = 'Do you want to add the product to this new order or to the order that is being updated?';

                                return mDesign.dialog({
                                    focusOnOpen: false,
                                    clickOutsideToClose: false,
                                    templateUrl: 'views/templates/update-order-dialog.html',
                                    controller: ['$scope', function($scope) {
                                        $scope.contentHeader = contentHeader;
                                        $scope.contentTitle = contentTitle;
                                        $scope.addToNewOrder = addToNewOrder;
                                        $scope.updateOrder = updateOrder;

                                        function addToNewOrder() {
                                            mDesign.hide().then(function() {
                                                return self.cancelEdit();
                                            });
                                        }

                                        function updateOrder() {
                                            mDesign.hide().then(function() {
                                                return self.getOrderDetails(Cart.editOrderId, {fireAndForgot: true}).then(function(order) {
                                                    self.orderInEdit = order;
                                                    _setEditOrder(order, true);
                                                });
                                            });
                                        }
                                    }]
                                });
                            } else {
                                $q.resolve().then(function() {
                                    if (Config.preventDialogsOnLoad) {
                                        return;
                                    }

                                    return mDesign.alert('Note! You in the middle of editing order');
                                }).then(function() {
                                    return self.getOrderDetails(Cart.editOrderId, {fireAndForgot: true}).then(function(order) {
                                        if (!Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.RECEIVED)) {
                                            self.orderInEdit = order;
                                            cancelEdit();
                                            return mDesign.alert('Your order can no longer be changed');
                                        }

                                        self.orderInEdit = order;

                                        var isAddEditOrderLines = !!$window.localStorage.getItem(ADD_EDIT_ORDER_LINES_STORAGE_KEY);
                                        _setEditOrder(order, isAddEditOrderLines);
                                        if (isAddEditOrderLines) {
                                            $window.localStorage.removeItem(ADD_EDIT_ORDER_LINES_STORAGE_KEY);
                                        }
                                    });
                                });
                            }
                        }
                    } else if (!Cart.editOrderId && self.orderInEdit && self.orderInEdit.id) {
                        if (_isCartAlreadyUpdated) {
                            mDesign.alert('Note! It seems you have finished editing your order on a different device/browser.')
                                .then(function() {
                                    self.orderInEdit = null;
                                });
                        } else {
                            self.orderInEdit = null;
                        }
                    }
                });
            }

            function editOrderDialogV2(orderId){
                if(!orderId) {
                    throw new Error('Missing orderId');
                }
                // if(typeof isCancelOnClose !== 'boolean') {
                //     throw new Error('Missing isCancelOnClose')
                // }
                return SpDialogUrlManager.setDialogParams({
                    updateOrderV2: '1',
                    step: "LAUNCH_EDIT",
                    // isCancelOnClose: isCancelOnClose ? isCancelOnClose : false,
                    isCancelOnClose: true,
                    orderId: orderId
                });

            }

            function _userLogOut() {
                self.orderInEdit = null;
                self.lastOrder = null;
                self.orderInEdit = null;
                self.barcode = null;
            }

            /**
             * Enable edit order
             * @param {number} orderId
             * @return {Promise}
             */
            function enableEditOrder(orderId) {
                return Cart.createCart().then(function() {
                    return Api.request({
                        method: 'PATCH',
                        url: '/retailers/:rid/users/:uid/orders/' + orderId + '/reopen/' + Cart.serverCartId
                    });
                }).then(function () {
                    return getOrderDetails(orderId, {fireAndForgot: true});
                }).then(function(order) {
                    self.orderInEdit = order;
                    $rootScope.$emit('orderEdit', order);
                    return _setEditOrder(order, true);
                }).catch(function(err) {
                    cancelEdit();
                    throw err;
                }).finally(function () {
                    Cart.init();
                });
            }

            $rootScope.$on('logout', function () {
                _userLogOut();
            });

            self.orderStatus[ORDER_STATUSES.NEW] = {
                title: 'New order',
                description: 'Your order has been received'
            };
            self.orderStatus[ORDER_STATUSES.CHARGED] = {
                title: 'Charged',
                description: 'Your order has been collected'
            };
            self.orderStatus[ORDER_STATUSES.DELIVERED] = {
                title: 'Delivered',
                description: 'Your order has been collected'
            };
            self.orderStatus[ORDER_STATUSES.CANCELLED] = {
                title: 'Cancelled',
                description: 'The order was canceled'
            };
            self.orderStatus[ORDER_STATUSES.PROCESSING] = self.orderStatus[ORDER_STATUSES.SUSPENDED] = self.orderStatus[ORDER_STATUSES.USER_CONFIRMED] = {
                title: 'Processing',
                description: 'The order is currently being collected by our trained shoppers'
            };
            self.orderStatus[ORDER_STATUSES.COLLECTED] = {
                title: 'Collected',
                description: 'Your order is currently being collected by our trained shoppers'
            };
            self.orderStatus[ORDER_STATUSES.REGISTERED] = {
                title: 'Registered',
                description: 'Your order is currently being collected by our trained shoppers'
            };
            self.orderStatus[ORDER_STATUSES.WAITING_FOR_CONFIRMATION] = {
                title: 'Waiting for confirmation',
                description: 'A message has been sent to the number on your order, kindly confirm the replacements and we will complete your order'
            }
        }]);


