(function (angular) {
angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.ordersHistory.order', {
        url: '/:oid',
        data: {
            stateAccess: PAGE_ACCESS.LOGGED_IN,
            bodyClass: 'single-order-history',
            metaTags: {
                title: 'Order'
            }
        },
        // resolve: {
        //     order: ['$stateParams', 'Api', 'User', function ($stateParams, api, User) {
        //         return api.request({
        //             method: 'GET',
        //             url: '/v2/retailers/:rid/branches/:bid/users/:uid/orders/' + $stateParams.oid
        //         }).then(function(order) {
        //             var products = order.lines.map(function(line) { return line.product})
        //             User.injectSuggestionsForProducts(products);
        //             return order
        //         });
        //     }]
        // },
        views: {
            '@': {
                templateUrl: 'views/single-order-history.html',
                controller: 'SingleOrderHistoryCtrl'
            }
        }
    });

}]).controller('SingleOrderHistoryCtrl', [
    '$scope', '$rootScope', 'mDesign', 'Cart', 'Util', 'Orders', '$state', 'orders', 'CART_LINE_TYPES', 'ORDER_STATUSES', 'SP_SERVICES', 'LINE_STATUS', 'ShoppingList', '$filter', 'Config',
    '$stateParams', 'Api', 'User',
    function ($scope, $rootScope, mDesign, cart, Util, Orders, $state, orders, CART_LINE_TYPES, ORDER_STATUSES, SP_SERVICES, LINE_STATUS, ShoppingList, $filter, Config,
        $stateParams, api, User
    ) {
        var categories = {},
            _productWeightFilter = $filter('productWeight');

        $scope.importShoppingListToCart = importShoppingListToCart;
        $scope.importOrderToShoppingList = importOrderToShoppingList;
        $scope.editOrder = editOrder;
        $scope.isOrderEditable = isOrderEditable;
        $scope.openOrderDialog = openOrderDialog;
        $scope.cancelOrder = cancelOrder;
        $scope.togglePaymentInfo = togglePaymentInfo;
        $scope.isCartHasOriginalPrices = isCartHasOriginalPrices;
        $scope.showReceivedOrderData = showReceivedOrderData;
        $scope.unitOfMeasure = unitOfMeasure;

        _init();
        function _init(){
            _getOrderDetail().then(function(order) {
                $scope.order = angular.copy(order);
                $scope.itemsCount = 0;
                $scope.expandedPaymentInfo = false;
                $scope.ebtPayment = {};

                $scope.total = { price: 0 }; //reset values
                $scope.order.lines = [];
                var orderCartLineById = {};
                var substituteIds = [];
                var pseudoIdsArray = order.lines.filter(function (item) {
                    if (item.isPseudo) {
                        orderCartLineById[item.id] = item;
                    }
                    if (item.substituteId) {
                        substituteIds.push(item.substituteId);
                    }
                    return item.isPseudo;
                }).map(function (item) { return item.id; });

                _setSoldBy();

                angular.forEach(order.lines, function (orderCartLine, index) {
                    if (orderCartLine.type !== CART_LINE_TYPES.MARKETING) {
                        if (!pseudoIdsArray.includes(orderCartLine.id) || (orderCartLine.isPseudo && !substituteIds.includes(orderCartLine.id))) {
                            if (orderCartLine.substituteId && pseudoIdsArray.includes(orderCartLine.substituteId)) {
                                var pseudoLine = orderCartLineById[orderCartLine.substituteId];
                                orderCartLine.isProductOutOfStock = Util.isProductOutOfStock(pseudoLine);
                                orderCartLine.isNeedToShowOutOfStockLabel = Util.isNeedToShowOutOfStockLabel(pseudoLine);
                                if (!categories[orderCartLine.categoryName]) {
                                    categories[orderCartLine.categoryName] = [];
                                }
                                $scope.total.price += pseudoLine.quantity * pseudoLine.regularPrice;
                                categories[orderCartLine.categoryName].push(pseudoLine);
                                categories[orderCartLine.categoryName].sort(function (a, b) {
                                    return b.index - a.index
                                });
                                $scope.order.lines.push(pseudoLine);
                            }
                            orderCartLine.isProductOutOfStock = Util.isProductOutOfStock(orderCartLine);
                            orderCartLine.isNeedToShowOutOfStockLabel = Util.isNeedToShowOutOfStockLabel(orderCartLine);
                            if (!categories[orderCartLine.categoryName]) {
                                categories[orderCartLine.categoryName] = [];
                            }
                            $scope.total.price += orderCartLine.quantity * orderCartLine.regularPrice;
                            categories[orderCartLine.categoryName].push(orderCartLine);
                            categories[orderCartLine.categoryName].sort(function (a, b) {
                                return b.index - a.index
                            });
                            $scope.order.lines.push(orderCartLine);
                        }
                    }
                });

                cart.setCartLineReplacements($scope.order.lines);
                $scope.linesByCategories = categories;
                $scope.total.price = Math.round($scope.total.price * 100) / 100;

                $scope.itemsCount = $scope.order.lines.filter(function (item){
                    return item.type === SP_SERVICES.CART_LINE_TYPES.PRODUCT && (item.status !== LINE_STATUS.MISSING && !item.substitute)
                }).length;
        
                _setEBTPayment();

                _loadShoppingList();

                $scope.orderStatus = Orders.orderStatus;
            });
        }

        function _loadShoppingList() {
            ShoppingList.request('GET').then(function (respond) {
                $scope.myShoppingLists = respond.shopLists;
            });
        }
   
        function _getOrderDetail(){
            return api.request({
                method: 'GET',
                url: '/v2/retailers/:rid/branches/:bid/users/:uid/orders/' + $stateParams.oid
            }).then(function(order) {
                var products = order.lines.map(function(line) { return line.product})
                User.injectSuggestionsForProducts(products);
                return order
            });
        }


        function importOrderToShoppingList () {
            var linesWithoutPropertySelected = [];
            angular.forEach($scope.order.lines, function(line) {
                if (line.product && line.product.productProperties && line.product.productProperties.length && !line.productPropertyValueId) {
                    linesWithoutPropertySelected.push(line.product.names[2].short);
                }
            });

            if(linesWithoutPropertySelected.length) {
                return  mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: true,
                    locals: {
                        orderId: $scope.order.id,
                        linesWithoutPropertySelected: linesWithoutPropertySelected
                    },
                    templateUrl: 'views/templates/product-property-warning-dialog.html',
                    controller: ['$scope', 'orderId', 'linesWithoutPropertySelected', function ($scope, orderId, linesWithoutPropertySelected) {

                        $scope.cancel = cancel;
                        $scope.viewDetails = viewDetails;
                        $scope.linesWithoutPropertySelected = linesWithoutPropertySelected;

                        function cancel(){
                            mDesign.hide();
                        }

                        function viewDetails() {
                            $state.go('app.singleOrder', ({oid: orderId}))
                            mDesign.hide();
                        }
                    }]
                });
            }

            mDesign.dialog({
                focusOnOpen: false,
                clickOutsideToClose: true,
                templateUrl: 'views/templates/import-to-shopping-list-dialog.html',
                controller: ['$scope', function (innerScope) {

                    innerScope.save = save;
                    innerScope.cancel = cancel;
                    innerScope.toggleAddToExistingList = toggleAddToExistingList;
                    innerScope.chosenList = null;
                    innerScope.shoppingLists = $scope.myShoppingLists
                    innerScope.newListName = '';
                    innerScope.isCreateNewList = true;

                    function cancel(){
                        mDesign.hide();
                    }

                    function toggleAddToExistingList(){
                        innerScope.isCreateNewList = !innerScope.isCreateNewList;
                    }

                    function save() {
                        innerScope.isCreateNewList ? _createNewList(innerScope.newListName) :  _updateShoppingList(innerScope.chosenList)
                        mDesign.hide();
                    }
                }]
            });
        }

        function unitOfMeasure(line, actualQuantity, isIgnoreOutOfStock) {
            if (line.isProductOutOfStock && !isIgnoreOutOfStock) {
                return 0;
            }
            if (line.isPseudo) {
                return line.quantity || 0;
            }
            return line.isCase || !line.product.isWeighable || (line.product.isWeighable && line.product.weight) ? (actualQuantity || line.quantity) : _productWeightFilter(line.product, line.isCase, null, actualQuantity || line.quantity);
        }

        function _updateShoppingList(chosenList) {
            var linesToAdd = $scope.order.lines.filter(function (line) {
                return !line.substituteId && line.quantity > 0;
            })
            var lines = Util.getActiveLines(linesToAdd)
            ShoppingList.patchShopListItems(chosenList, lines).then(function () {
                mDesign.alert('Your shopping list was updated')
            })
        }

        function _setSoldBy() {
            var order = $scope.order;
            angular.forEach(order.lines, function (item) {
                item.soldBy = null;
                if(!item.product){
                    return;
                }
                item.product.soldBy = null;
                if (item.product && Util.isUnitsWeighable(item.product)) {
                    item.soldBy = !!item.weighableProductUnits && item.weighableProductUnits > 0 ? 'Unit' : 'Weight';
                    item.product.soldBy = item.soldBy;
                    if (item.soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name && item.weighableProductUnits) {

                        item.quantity = Util.roundNumber(item.quantity / item.product.weight);
                    }
                }
            });
        }

        function _setEBTPayment(){
            var order = $scope.order;
            if (order.paymentData.ebtSNAPPayment || order.paymentData.ebtCashPayment){
                var ebtPayment = { ebtCashPayment : 0, ebtSNAPPayment : 0 , ebtFinalizedAmount : 0 , ebtSNAPRefund : 0 , ebtCashRefund : 0 };
                var ebtSNAPPayment = order.paymentData.ebtSNAPPayment
                var ebtCashPayment = order.paymentData.ebtCashPayment
                ebtPayment = {
                    ebtSNAPPayment: ebtSNAPPayment ? (ebtSNAPPayment.preAuthAmount || ebtSNAPPayment.amountCharged) : 0,
                    ebtCashPayment: ebtCashPayment ? (ebtCashPayment.preAuthAmount || ebtCashPayment.amountCharged) : 0,
                }
                ebtPayment.ebtFinalizedAmount = (ebtSNAPPayment ? ebtSNAPPayment.amountCharged : 0) + (ebtCashPayment ? ebtCashPayment.amountCharged : 0)
                if (ebtSNAPPayment && ebtSNAPPayment.amountCharged !== null){
                    ebtPayment.ebtSNAPRefund = ebtSNAPPayment.preAuthAmount - ebtSNAPPayment.amountCharged
                }
                if (ebtCashPayment && ebtCashPayment.amountCharged !== null){
                    ebtPayment.ebtCashRefund = ebtCashPayment.preAuthAmount - ebtCashPayment.amountCharged
                }
                $scope.ebtPayment = ebtPayment;
            }
        }


        function _createNewList(newListName) {
            ShoppingList.request('POST', newListName, null, false)
                .then(function (response) {
                    return _updateShoppingList(response.id)
                });
        }

        function importShoppingListToCart() {
            var linesToAdd = [];
            angular.forEach($scope.order.lines, function(line) {
                if (!line.isProductOutOfStock) {
                    linesToAdd.push({
                        quantity: line.quantity,
                        isCase: line.isCase,
                        isPseudo: line.isPseudo,
                        comments: line.comments,
                        name: line.name,
                        categoryName: line.categoryName,
                        product: line.product,
                        type: line.type,
                        metaData: line.metaData
                    });
                }
            });
            var isLimitPassed = cart.checkHeavyLimitBeforeAddLines(linesToAdd);
            if (isLimitPassed === false) {
                if (cart.lines && Object.keys(cart.lines).length === 0 &&
                    $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                    cart.addDeliveryFeeLineIfNeeded();
                }
                cart.addLines(Util.getActiveLines(linesToAdd), SP_SERVICES.SOURCES.ORDER_HISTORY, false, $scope.order.cartId);
                $state.go('app.cart');
            }
        }

        function editOrder() {
            Orders.editOrderDialog($scope.order);
        }

        function isOrderEditable() {
            var order = $scope.order;
            return Util.isOrderEditable(order);
        }

        function openOrderDialog() {
            mDesign.alert({
                title: $scope.orderStatus[$scope.order.statusId].title,
                content: $scope.orderStatus[$scope.order.statusId].description
            });
        }
        function isCartHasOriginalPrices(){
            return $scope.order.lines.find(function (line){
                return line.originalPrice && line.originalTotalPrice
            })
        }


        function cancelOrder() {
            Orders.cancelOrder($scope.order);
        }

        function togglePaymentInfo() {
            $scope.expandedPaymentInfo = !$scope.expandedPaymentInfo;
        }

        function showReceivedOrderData(){
            return $scope.order && ($scope.order.statusId !== ORDER_STATUSES.NEW && $scope.order.statusId !== ORDER_STATUSES.CANCELLED &&  $scope.order.statusId !== ORDER_STATUSES.PROCESSING)
        }


        Util.currentScopeListener($scope, $rootScope.$on('order.update.timeslot.refresh', function (event, data) {
            _init();
        }));
    
        Util.destroyListeners($scope, [$rootScope.$on('orders.cancelOrder', function(event, order) {
            if ($scope.order.id === order.id) {
                $scope.order.statusId = ORDER_STATUSES.CANCELLED;
            }
            angular.forEach(orders, function(listOrder) {
                if (order.id === listOrder.id) {
                    listOrder.statusId = ORDER_STATUSES.CANCELLED;
                }
            });

            if(!$rootScope.$$phase) {
                $scope.$apply();
            }
        })]);
    }]);
})(angular);
