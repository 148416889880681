angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.premiumLoyaltyClub', {
        url: '/premium-loyalty-club?{showRegistrationForm},{showLookupForm},{loyaltyClubDriverId},{memberToken}',
        data: {
            bodyClass: 'premium-loyalty-club',
            metaTags: {
                title: 'Premium Loyalty club'
            }
        },
        resolve: {
            retailerSettings: ['Retailer', function (retailer) {
                return retailer.getRetailerSettings();
            }]
        },
        views: {
            '@': {
                templateUrl: 'views/premium-loyalty-club.html',
                controller: 'PremiumLoyaltyClubCtrl as premiumLoyaltyCtrl'
            }
        }
    });
}]).controller('PremiumLoyaltyClubCtrl', [
    '$rootScope','$scope', '$state', '$q', '$timeout', '$window', '$location', '$filter', 'Api', 'Config', 'User',
    'Util', 'Retailer', 'Cart', 'Offers', 'mDesign', 'PaymentsService', 'retailerSettings', 'SpCaptcha', 'CouponDetailsDialog',
    'CART_LINE_TYPES', 'LOYALTY_CLUB_DRIVERS', 'LOYALTY_PAGE_METHODS', 'LOYALTY_PAGE_COMPONENT_TYPES', 'Loyalty',
    function ($rootScope, $scope, $state, $q, $timeout, $window, $location, $filter, Api, Config, User,
              Util, Retailer, Cart, Offers, mDesign, PaymentsService, retailerSettings, SpCaptcha, CouponDetailsDialog,
              CART_LINE_TYPES, LOYALTY_CLUB_DRIVERS, LOYALTY_PAGE_METHODS, LOYALTY_PAGE_COMPONENT_TYPES, Loyalty) {
        var premiumLoyaltyCtrl = this,
            loyaltyClubDriverId = $state.params.loyaltyClubDriverId && Number($state.params.loyaltyClubDriverId),
            memberToken = $state.params.memberToken

        premiumLoyaltyCtrl.initPointsCalculator = initPointsCalculator;
        premiumLoyaltyCtrl.onSliderValueChange = onSliderValueChange;
        premiumLoyaltyCtrl.goToSignIn = goToSignIn;
        premiumLoyaltyCtrl.redeemPoints = redeemPoints;
        premiumLoyaltyCtrl.unredeemedPoints = unredeemedPoints;
        premiumLoyaltyCtrl.initGeneralComponent = initGeneralComponent;
        premiumLoyaltyCtrl.initExternalServicesComponent = initExternalServicesComponent;
        premiumLoyaltyCtrl.openExternalRedeemCodeDialog = openExternalRedeemCodeDialog;
        premiumLoyaltyCtrl.openExternalServicesListDialog = openExternalServicesListDialog;
        premiumLoyaltyCtrl.goToCoupon = goToCoupon;
        premiumLoyaltyCtrl.openAccumulationRedemptionDialog = openAccumulationRedemptionDialog;
        premiumLoyaltyCtrl.accumulationDefaultText =  {
            btnText: {
                he: 'היסטוריית צבירה ומימוש',
                en: 'Accumulation and redemption history',
                ru: 'История накопления и погашения',
                es: 'Historial de acumulación y redención',
                ar: 'تاريخ التراكم والاسترداد',
                fr: 'Historique de cumul et d\’utilisation'
            },
            popupTitleText: {
                he: 'היסטוריית צבירה ומימוש נקודות',
                en: 'Points accumulation and redemption history',
                ru: 'История накопления и погашения баллов',
                es: 'Historial de puntos acumulados y redimidos',
                ar: 'تراكم النقاط وتاريخ الاسترداد',
                fr: 'Historique de cumul et d\’utilisation des points'
            },
            markTitleText: {
                he: 'סך הנקודות הנוכחי שלכם הוא {Total Points Amount}',
                en: 'Your Current Point total is {Total Points Amount}',
                ru: 'Ваши баллы: {Total Points Amount}',
                es: 'Su puntaje actual total es {Total Points Amount}',
                ar: 'مجموع نقاطك الحالية هو {Total Points Amount}',
                fr: 'Total de points actuel est de {Total Points Amount}'
            },
            notAccumulateText: {
                he: 'נראה שעדיין לא צברתם נקודות, המשיכו בקנייה על מנת לצבור נקודות',
                en: 'Looks like you haven\'t earned any points yet, continue shopping to earn points',
                ru: 'Похоже, у вас пока что нет баллов: продолжайте покупки, чтобы получить баллы.',
                es: 'Al parecer usted no ha ganado puntos aún, continúe comprando para ganar puntos',
                ar: 'يبدو أنك لم تكسب أي نقاط بعد، تابع التسوق لكسب النقاط',
                fr: 'Il semblerait que vous n\'ayez pas encore gagné le moindre point, continuez à acheter pour gagner des points.'
            }
        }
        premiumLoyaltyCtrl.defaultTextColor = '#000000';

        _init();

        function _init() {
            Retailer.getRetailerSettings().then(function(response) {
                premiumLoyaltyCtrl.retailerSettings = response;

                premiumLoyaltyCtrl.loyaltyClubDriver = retailerSettings.loyaltyClubDrivers.find(function (driver) {
                    return driver.loyaltyClubDriverId === loyaltyClubDriverId;
                });

                premiumLoyaltyCtrl.loyaltyClubPointsName = premiumLoyaltyCtrl.retailerSettings && premiumLoyaltyCtrl.retailerSettings.settings.loyaltyClubPointsName || 'points';
                
                if (!!User.getUserLoginData()) {
                    return User.getUserSettingsMultiLoyalty().then(function (resp) {
                        premiumLoyaltyCtrl.userData = resp;
                        premiumLoyaltyCtrl.userLoyaltyClub = premiumLoyaltyCtrl.userData.loyaltyClubs.find(function (userLoyaltyClub) {
                            return userLoyaltyClub.loyaltyClubDriverId === loyaltyClubDriverId;
                        });

                        premiumLoyaltyCtrl.userType = !!premiumLoyaltyCtrl.userLoyaltyClub ? premiumLoyaltyCtrl.userLoyaltyClub.loyaltyClubId : LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER;

                        if (premiumLoyaltyCtrl.userType !== LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER) {
                            premiumLoyaltyCtrl.loyaltyClub = premiumLoyaltyCtrl.loyaltyClubDriver.clientConfig.loyaltyClubs[premiumLoyaltyCtrl.userType];
                        }

                        if (premiumLoyaltyCtrl.userType === LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER && !!memberToken) {
                            return _getMemberData({token: memberToken});
                        }
                    });
                } else if (!!memberToken) {
                    return _getMemberData({token: memberToken});
                } else {
                    premiumLoyaltyCtrl.userType = LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER;
                }
            }).then(function () {
                    if (!premiumLoyaltyCtrl.userLoyaltyClub) {
                        return;
                    }

                    return Util.getUserCashbackPoints(premiumLoyaltyCtrl.userLoyaltyClub.id, premiumLoyaltyCtrl.loyaltyClubDriver.clientConfig.pointsDecimalRound).then(function (response) {
                        premiumLoyaltyCtrl.userPoints = response.points;
                        premiumLoyaltyCtrl.userPointsToken = response.token;
                    });
            }).then(function () {
                if (premiumLoyaltyCtrl.userType === LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER) {
                    _setSignInComponent();
                }

                if (!premiumLoyaltyCtrl.userLoyaltyClub || !premiumLoyaltyCtrl.loyaltyClub) {
                    return;
                }

                _setAccumulationRedemptionComponent();

                premiumLoyaltyCtrl.loyaltyClub.minPointsNum = premiumLoyaltyCtrl.loyaltyClub.minPointsNum || 0;
                premiumLoyaltyCtrl.userEligibleToRedeem = !!premiumLoyaltyCtrl.userPoints && premiumLoyaltyCtrl.userPoints >= premiumLoyaltyCtrl.loyaltyClub.minPointsNum;
                premiumLoyaltyCtrl.minPointsToMoney = (premiumLoyaltyCtrl.loyaltyClub.minPointsNum * premiumLoyaltyCtrl.loyaltyClub.pointsToCashbackRatio).toFixed(0);
                premiumLoyaltyCtrl.userPointsToMoney = (premiumLoyaltyCtrl.userPoints * premiumLoyaltyCtrl.loyaltyClub.pointsToCashbackRatio).toFixed(1);
                premiumLoyaltyCtrl.monyeToRedeem = (premiumLoyaltyCtrl.loyaltyClub.minPointsNum - premiumLoyaltyCtrl.userPoints) * premiumLoyaltyCtrl.loyaltyClub.moneyToPointRatio;
                premiumLoyaltyCtrl.precentsPointsOutOfMin = premiumLoyaltyCtrl.loyaltyClub.isMinPointsRequired && premiumLoyaltyCtrl.userPoints &&
                premiumLoyaltyCtrl.userPoints < premiumLoyaltyCtrl.loyaltyClub.minPointsNum ? premiumLoyaltyCtrl.userPoints / premiumLoyaltyCtrl.loyaltyClub.minPointsNum * 100 : 0;
                premiumLoyaltyCtrl.isUsedPoints = premiumLoyaltyCtrl.loyaltyClubDriver && premiumLoyaltyCtrl.loyaltyClub.pointsToCashback && premiumLoyaltyCtrl.userLoyaltyClub && premiumLoyaltyCtrl.userLoyaltyClub.redeemedPointsAmount &&
                    (!premiumLoyaltyCtrl.loyaltyClub.isMinPointsRequired || (premiumLoyaltyCtrl.loyaltyClub.isMinPointsRequired &&
                        premiumLoyaltyCtrl.userEligibleToRedeem));

                if (premiumLoyaltyCtrl.isUsedPoints) {
                    _showAnimation();
                }

                if (premiumLoyaltyCtrl.userData) {
                    _getCoupons();
                }
            });
        }

        function _setAccumulationRedemptionComponent(){
            Loyalty.getLastTransactions(premiumLoyaltyCtrl.userLoyaltyClub.id).then(function(transactions){
                if(transactions && transactions.length) {
                    transactions.forEach(function(transaction){
                        var date = new Date(transaction.Date && transaction.Date.replace(/-/g, "/"));
                        transaction.Month = date.toLocaleString('en-US', { month: 'long' });
                        transaction.Year = date.getFullYear();
                        transaction.TotalPointsTotalLoad = transaction.TotalPointsTotalLoad && transaction.TotalPointsTotalLoad.toFixed(1);
                        transaction.TotalApprovedSum = transaction.TotalApprovedSum && transaction.TotalApprovedSum.toFixed(1);
                    })
                    premiumLoyaltyCtrl.dataTransactions = transactions;
                }
            })
            premiumLoyaltyCtrl.accumulationRedemptionComponent = premiumLoyaltyCtrl.loyaltyClubDriver.loyaltyPageDesign[premiumLoyaltyCtrl.userType].find(function(component){
                return component.type === LOYALTY_PAGE_COMPONENT_TYPES.BY_NAME.ACCUMULATION_REDEMPTION;
            });
        }

        function _getMemberData(params) {
            return Api.request({
                method: 'GET',
                url: '/v2/retailers/:rid/loyalty/drivers/' + premiumLoyaltyCtrl.loyaltyClubDriver.id + '/memberByToken',
                params: params
            }).then(function (resp) {
                premiumLoyaltyCtrl.userLoyaltyCardId = resp.loyaltyCardId;
                premiumLoyaltyCtrl.userPoints = resp.points;
                premiumLoyaltyCtrl.userPointsToken = resp.pointsToken;
                premiumLoyaltyCtrl.userType = resp.loyaltyClubId;
                premiumLoyaltyCtrl.loyaltyClub = premiumLoyaltyCtrl.loyaltyClubDriver.clientConfig.loyaltyClubs[resp.loyaltyClubId];
                premiumLoyaltyCtrl.isOneTimeUser = true;
            });
        }

        function _getMemberExternalRedeemCode() {
            return Api.request({
                method: 'GET',
                url: '/v2/retailers/:rid/loyalty/drivers/' + premiumLoyaltyCtrl.loyaltyClubDriver.id + '/externalRedeemCode',
                params: {
                    cardId: premiumLoyaltyCtrl.userLoyaltyCardId || premiumLoyaltyCtrl.userLoyaltyClub.loyaltyCardId
                }
            }).then(function (resp) {
                premiumLoyaltyCtrl.externalRedeemCode = resp;
            });
        }

        function _setSignInComponent() {
            premiumLoyaltyCtrl.signInComponent = premiumLoyaltyCtrl.loyaltyClubDriver.loyaltyPageDesign[premiumLoyaltyCtrl.userType].find(function (component) {
                return component.type === LOYALTY_PAGE_COMPONENT_TYPES.BY_NAME.SIGN_IN_COMPONENT;
            });

            premiumLoyaltyCtrl.signInComponentTypesObj = !premiumLoyaltyCtrl.signInComponent.content.isDuplicate ? {} : (premiumLoyaltyCtrl.signInComponent.content.duplicateAfter || []).reduce(function(obj, key) {
                obj[key] = key;
                return obj;
            }, {});
        }

        function initPointsCalculator(component) {
            premiumLoyaltyCtrl.sliderMinValue = component.content.minValue = (component.content.minValue || component.content.minValue === 0) ? component.content.minValue : 400;
            premiumLoyaltyCtrl.sliderMaxValue = component.content.maxValue = component.content.maxValue || 2500;
            component.content.sliderIncrements = component.content.sliderIncrements || 100;
            premiumLoyaltyCtrl.sliderValue = component.content.maxValue/2;
            var input = document.querySelector('.points-calculator .slider-container input[type=range]');
            var newColor = component.content.sliderColor; // this could be a passed in dynamic value, etc.
            var newBarColor = component.content.progressBarColor; // this could be a passed in dynamic value, etc.
            if (input) {
                input.style.setProperty('--sliderColor', newColor);
                input.style.setProperty('--sliderBackgroundColor', newBarColor);
                var value = (premiumLoyaltyCtrl.sliderValue-premiumLoyaltyCtrl.sliderMinValue)/(premiumLoyaltyCtrl.sliderMaxValue-premiumLoyaltyCtrl.sliderMinValue)*100 + '%';
                input.style.setProperty('--sliderPosition', value);

            }
            onSliderValueChange(component);
        }

        function onSliderValueChange(component) {
            var calculationClub = premiumLoyaltyCtrl.loyaltyClubDriver.clientConfig.loyaltyClubs[component.content.calculationClubId], 
                points = premiumLoyaltyCtrl.sliderValue / calculationClub.moneyToPointRatio,
                pointsToMoney = (premiumLoyaltyCtrl.sliderValue / calculationClub.moneyToPointRatio) * calculationClub.pointsToCashbackRatio;
            premiumLoyaltyCtrl.pointsDemo = _roundDecimal(points, premiumLoyaltyCtrl.loyaltyClubDriver.clientConfig.pointsDecimalRound);
            premiumLoyaltyCtrl.pointsToMoneyDemo = _roundDecimal(pointsToMoney, 2);
            premiumLoyaltyCtrl.yearlyProfitAmountDemo = _roundDecimal(premiumLoyaltyCtrl.pointsToMoneyDemo * 12, 2);

            var input = document.getElementById("range_input");
            var value = (premiumLoyaltyCtrl.sliderValue-premiumLoyaltyCtrl.sliderMinValue)/(premiumLoyaltyCtrl.sliderMaxValue-premiumLoyaltyCtrl.sliderMinValue)*100 + '%';
            input.style.setProperty('--sliderPosition', value);
        }

        function _roundDecimal(amount,decimalRoundNumber) {
            return Number.isInteger(amount) ? amount : amount.toFixed(decimalRoundNumber);
        }

        /**
         * @param {*} designComponent
         * @returns {string}
         */
        function getExternalLinkFromLoyaltyPageDesign(designComponent) {
          if (
            !designComponent ||
            !designComponent.content ||
            !designComponent.content.externalRegistration
          ) {
            return null;
          }

          var externalRegistration = designComponent.content.externalRegistration;

          if (!externalRegistration.isActive) {
            return null;
          }

          return externalRegistration.link;
        }

        function goToSignIn(component) {
            var externalLink = getExternalLinkFromLoyaltyPageDesign(component)

            if(premiumLoyaltyCtrl.userData && externalLink){
                return Util.goToBannerHref(externalLink)
            }

            if(!premiumLoyaltyCtrl.userData && Config.retailer.loyaltyClubDriver && Config.retailer.loyaltyClubDriver.clientConfig && Config.retailer.loyaltyClubDriver.clientConfig.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty) {
                return Util.goToLoginDialog()
            }

            if (premiumLoyaltyCtrl.loyaltyClubDriver.clientConfig.extendedLoyaltyClub) {
                return $state.go('app.extendedLoyaltyClub');
            } 
            
            return $state.go('app.loyaltyClub');
        }

        function redeemPoints() {
            return Cart.createCart().then(function() {
                return Api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/users/:uid/loyalty-clubs/' + premiumLoyaltyCtrl.userLoyaltyClub.id + '/insertUserRedemptionPoints',
                    data: {
                        redeemedPointsAmount: premiumLoyaltyCtrl.userPoints,
                        pointsToken: premiumLoyaltyCtrl.userPointsToken,
                        cartId: Cart.serverCartId
                    }
                }).then(function() {
                    premiumLoyaltyCtrl.redeemedPointsAmount = premiumLoyaltyCtrl.userPoints;
                    premiumLoyaltyCtrl.isUsedPoints = true;
                    _showAnimation();
                });
            })
        }


        function unredeemedPoints() {
            return Api.request({
                method: 'DELETE',
                url: '/v2/retailers/:rid/users/:uid/loyalty-clubs/'  + premiumLoyaltyCtrl.userLoyaltyClub.id + '/deleteUserRedemptionPoints/' + Cart.serverCartId,
            }).then(function() {
                premiumLoyaltyCtrl.isUsedPoints = false;
                premiumLoyaltyCtrl.showAnimation = false;
                delete premiumLoyaltyCtrl.redeemedPointsAmount;
            })
        }

        function initGeneralComponent(currentComponent) {
            if (premiumLoyaltyCtrl.userType !== LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER) {
                var generalComponent = premiumLoyaltyCtrl.loyaltyClubDriver.loyaltyPageDesign[LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER].find(function (component) {
                    return component.type === currentComponent.type;
                });

                currentComponent.content = generalComponent.content;
            }
        }

        function initExternalServicesComponent(currentComponent) {
            if (premiumLoyaltyCtrl.userType !== LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER) {
                var generalComponent = premiumLoyaltyCtrl.loyaltyClubDriver.loyaltyPageDesign[LOYALTY_PAGE_METHODS.NON_LOYALTY_MEMBER].find(function (component) {
                    return component.type === currentComponent.type;
                });

                currentComponent.content = angular.extend(currentComponent.content, generalComponent.content);
            }
        }

        function openExternalRedeemCodeDialog(component) {
            _getMemberExternalRedeemCode().then(function () {
                premiumLoyaltyCtrl.hideExternalCode = false;
                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: true,
                    templateUrl: 'views/templates/loyalty-page-design-components/external-redeem-code-dialog.html',
                    controller: ['$scope', function ($scope) {
                        $scope.backgroundImage = premiumLoyaltyCtrl.loyaltyClub.banners.mobileLpdExternalCodePopup;
                        $scope.content = component.content;
                        $scope.externalRedeemCode = premiumLoyaltyCtrl.externalRedeemCode;
                        $scope.hide = function () {
                            mDesign.hide();
                        }
                    }]
                }).then(function () {
                    premiumLoyaltyCtrl.hideExternalCode = false;
                });
            });
        }

        function openExternalServicesListDialog(component) {
            return mDesign.dialog({
                focusOnOpen: false,
                clickOutsideToClose: true,
                templateUrl: 'views/templates/loyalty-page-design-components/external-services-list-dialog.html',
                controller: ['$scope', function ($scope) {
                    $scope.html = component.content.externalServicesHtml;
                    $scope.hide = mDesign.hide;
                }]
            });
        }

        function _showAnimation() {
            premiumLoyaltyCtrl.showAnimation = true;
            $timeout(function () {
                premiumLoyaltyCtrl.showAnimation = false;
            }, 5000)
        }

        function _getCoupons() {
            return Api.request({
                method: 'GET',
                url: '/v2/retailers/:rid/branches/:bid/users/:uid/coupons',
                params: { extended: true, countonly: false }
            }, {
                fireAndForgot: true
            }).then(function (couponsData) {
                premiumLoyaltyCtrl.coupons = couponsData.coupons;
            });
        }

        function goToCoupon(coupon, terms) {
            CouponDetailsDialog.show(coupon, terms);
        }

        function openAccumulationRedemptionDialog(){
            return mDesign.dialog({
                focusOnOpen: false,
                clickOutsideToClose: true,
                templateUrl: 'views/templates/loyalty-page-design-components/accumulation-redemption-dialog.html',
                controller: ['$scope', function ($scope) {
                    $scope.cancel = cancel;
                    for (var lang in premiumLoyaltyCtrl.accumulationRedemptionComponent.content.markTitleText) {
                        if (premiumLoyaltyCtrl.accumulationRedemptionComponent.content.markTitleText.hasOwnProperty(lang)) {
                            premiumLoyaltyCtrl.accumulationRedemptionComponent.content.markTitleText[lang] = premiumLoyaltyCtrl.accumulationRedemptionComponent.content.markTitleText[lang].replace(/\{Total Points Amount\}/g, premiumLoyaltyCtrl.userPoints);
                        }
                    }
                    $scope.accumulationRedemptionComponent = premiumLoyaltyCtrl.accumulationRedemptionComponent.content;
                    $scope.dataTransactions = premiumLoyaltyCtrl.dataTransactions;
                    $scope.banners = premiumLoyaltyCtrl.loyaltyClub.banners;
                    $scope.accumulationDefaultText = premiumLoyaltyCtrl.accumulationDefaultText;

                    function cancel() {
                        mDesign.hide();
                    }
                }]
            });
        }
    }]);
