(function (angular) {
    angular.module('mobilezuz')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.cart.order-update.ebt-finish', {
                url: '/ebt-finish',
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return ($filter('translate')('Payment Finish Details'));
                    }]
                },
                views: {
                    updateOrder: {
                        templateUrl: 'views/order/update/ebt-finish.html',
                        controller: 'EBTFinishPaymentCtrl as ebtFinishPaymentCtrl',
                    }
                }
            });
        }])
        .controller('EBTFinishPaymentCtrl',
            ['$scope', '$state', '$rootScope', '$location', 'Util', 'Cart', 'SP_SERVICES', 'Config', 'LocalStorage',
                function ($scope, $state, $rootScope, $location, util, cart, SP_SERVICES, config, localStorage) {

                    var updateOrderCtrl = $scope.updateOrderCtrl;
                    updateOrderCtrl.back = null;
                    updateOrderCtrl.exit = null;
                    updateOrderCtrl.forward = null;
                    updateOrderCtrl.confirmButton = null;
                    updateOrderCtrl.withoutHeader = true;

                    var ebtFinishPaymentCtrl = this;
                    ebtFinishPaymentCtrl.toHomePage = toHomePage;

                    init();

                    // EXTERNAL FUNCTION

                    function init() {
                        if (!$rootScope.ebtBranchSettings && config.retailer.settings.eBTBranchSettings) {
                            $rootScope.ebtBranchSettings = JSON.parse(config.retailer.settings.eBTBranchSettings);
                            $rootScope.ebtBranchSettings.branchId = config.branch.id;
                        }

                        _initialFinalSummary();

                        $scope.$on('$destroy', _reset);
                    }

                    function toHomePage() {
                        _reset();

                        $state.go('app.home');
                    }

                    // if product for edit order button
                    function onEditOrder() {
                        util.editOrderWIthValidationIfOrderEditable(ebtFinishPaymentCtrl.orderId).then(function (editable) {
                            if (editable) {
                                toHomePage(true);
                            }
                        })
                    }

                    // INTERNAL FUNCTION
                    function _reset() {
                        updateOrderCtrl.withoutHeader = null;

                        cart.setTimeTravel();
                        cart.clear(true);
                        $rootScope.$emit('editOrder.updated');
                    }

                    /**
                     * @typedef {Object} DeliveryTimeRange
                     * @property {string} from - The starting time of delivery
                     * @property {string} to - The ending time of delivery
                     * @property {string} shippingTimeFromDisplay - Display format of shipping start time
                     */

                    /**
                     * Retrieves the delivery time range from localStorage or falls back to existing data
                     * @private
                     * @returns {DeliveryTimeRange} The delivery time range object or null if not available
                     */
                    function _getDeliveryTimeRange() {
                        var localStorageDeliveryTimeRange = util.getNewTimeSlotData(updateOrderCtrl.updatedOrderId, cart.serverCartId);

                        var deliveryTimeRange = localStorageDeliveryTimeRange ? angular.extend({},
                            localStorageDeliveryTimeRange,
                            {
                                from: localStorageDeliveryTimeRange.shippingTimeFrom,
                                to: localStorageDeliveryTimeRange.shippingTimeTo
                            }
                        ) : null;

                        if (!deliveryTimeRange || !deliveryTimeRange.from || !deliveryTimeRange.to) {
                            deliveryTimeRange = updateOrderCtrl.paymentsData.deliveryInfo.deliveryTimeRage;
                        }
                        return deliveryTimeRange;
                    }

                    function _initialFinalSummary() {

                        ebtFinishPaymentCtrl.orderId = updateOrderCtrl.updatedOrderId;
                        ebtFinishPaymentCtrl.deliveryType = updateOrderCtrl.paymentsData.deliveryInfo.deliveryMethod.label;
                        ebtFinishPaymentCtrl.deliveryTimes = _getDeliveryTimeRange();
                        ebtFinishPaymentCtrl.columns = [];
                        var ebtDividerTaxSaved = updateOrderCtrl.paymentsData.ebtDividerAmounts && updateOrderCtrl.paymentsData.ebtDividerAmounts.total ? updateOrderCtrl.paymentsData.ebtDividerAmounts.total.taxSaved : 0;
                        var generaColumn = [
                            [
                                _columnObj('Items', _countCartLinesUnitsItems()),
                                _columnObj('Sub total', _toFloatNum(updateOrderCtrl.paymentsData.summary.subTotal + (updateOrderCtrl.paymentsData.summary.ebtWeightDeposit || 0)), true),
                                _columnObj('Delivery fee', updateOrderCtrl.paymentsData.summary.deliveryFee, true),
                                _columnObj('Service fee', updateOrderCtrl.paymentsData.summary.serviceFee, true),
                                _columnObj('Tax', _toFloatNum(updateOrderCtrl.paymentsData.summary.finalTax - (ebtDividerTaxSaved || 0)), true),
                                _columnObj('Total', updateOrderCtrl.paymentsData.summary.finalTotal, true, ['bold', 'colored']),
                            ]
                        ];
                        var ebtColumn = [
                            [
                                _columnObj('EBT Card', ('**** ' + updateOrderCtrl.paymentsData.ebtCard.lastFourDigits)),
                            ],
                            [
                                _columnObj('EBT SNAP Payment', null, null, 'bold', true, true),
                                _columnObj('Previous Charge',
                                    (updateOrderCtrl.paymentsData.ebtInfo.snap.previousPayment && updateOrderCtrl.paymentsData.ebtInfo.snap.previousPayment.initialAmount ? _toFloatNum(updateOrderCtrl.paymentsData.ebtInfo.snap.previousPayment.initialAmount) : ''),
                                    true),
                                _columnObj(
                                    _getChargeType('snap'),
                                    _getChargeAmount('snap'),
                                    true,
                                    'colored',
                                    !_getChargeAmount('snap'),
                                    true),
                            ],
                            [
                                _columnObj(($rootScope.ebtBranchSettings && $rootScope.ebtBranchSettings.branchId && $rootScope.ebtBranchSettings[$rootScope.ebtBranchSettings.branchId].eBTCashAllowed ? 'EBT CASH Payment' : ''), null, null, 'bold', true, true),
                                _columnObj('Previous Charge',
                                    (updateOrderCtrl.paymentsData.ebtInfo.cash.previousPayment && updateOrderCtrl.paymentsData.ebtInfo.cash.previousPayment.initialAmount ? _toFloatNum(updateOrderCtrl.paymentsData.ebtInfo.cash.previousPayment.initialAmount) : ''),
                                    true),
                                _columnObj(
                                    ($rootScope.ebtBranchSettings[$rootScope.ebtBranchSettings.branchId].eBTCashAllowed ? _getChargeType('cash') : ''),
                                    _getChargeAmount('cash'),
                                    true,
                                    'colored',
                                    !_getChargeAmount('cash'),
                                    true),
                            ],
                            _balanceColumnsBuilder()
                        ];
                        var creditCardColumn = [
                            [
                                _columnObj('Credit card', ('**** ' + updateOrderCtrl.paymentsData.mainLastDigits)),
                                _columnObj('Credit Card Charge', updateOrderCtrl.paymentsData.summary.creditCardReminder, true, 'colored')
                            ]
                        ];

                        ebtFinishPaymentCtrl.columns = [generaColumn, ebtColumn, creditCardColumn];
                    }

                    function _columnObj(text, value, hasCurrency, classes, hideColon, withoutValue) {
                        return {
                            text: text,
                            hasCurrency: hasCurrency,
                            value: value,
                            classes: classes,
                            hideColon: hideColon,
                            withoutValue: withoutValue
                        }
                    }

                    function _getChargeType(type) {
                        if (updateOrderCtrl.finalUpdatePayments[type]) {
                            return updateOrderCtrl.finalUpdatePayments[type].chargeType === 'charge' ? 'Current Charge' : 'Refund';
                        }

                        return 'No Charged/Refund';
                    }

                    function _getChargeAmount(type) {
                        return updateOrderCtrl.finalUpdatePayments[type] && updateOrderCtrl.finalUpdatePayments[type].amount;
                    }

                    function _balanceColumnsBuilder() {
                        var balances = [];

                        if (updateOrderCtrl.paymentsData.ebtBalance) {
                            angular.forEach(updateOrderCtrl.paymentsData.ebtBalance, function (val, key) {
                                if(key.toLowerCase().indexOf('updated')> -1) {
                                    var text = 'Updated ' + ((key.toLowerCase().indexOf('fsbalance') > -1) ? 'SNAP' : 'CASH') + ' balance';

                                    balances.push(_columnObj(text, val, true));
                                }

                            });
                        }

                        return balances;
                    }

                    function _toFloatNum(v, decimalNum) {
                        return parseFloat(v.toFixed((decimalNum || 2)));
                    }

                    function _countCartLinesUnitsItems() {
                        return updateOrderCtrl.paymentsData.cartLines ? updateOrderCtrl.paymentsData.cartLines.reduce(function(sum, line) {
                            if (line.type === SP_SERVICES.CART_LINE_TYPES.PRODUCT) {
                                sum += line.quantity;
                            }

                            return _toFloatNum(sum);
                        }, 0) : 0;
                    }
                }]);
})(angular);