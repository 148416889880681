(function (angular) {
    var SPECIAL_TYPES = {
        REGULAR_SPECIALS: 'regular',
        LOYALTY_SPECIALS: 'loyalty'
    };

    var specialsData = {
        sales: [],
        scrollTop: undefined,
        total: undefined
    };

    angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
        $stateProvider.state('app.specials', {
            url: '/specials?{filters:json}',
            data: {
                stateAccess: PAGE_ACCESS.ALLOW,
                bodyClass: 'sales',
                metaTags: {
                    title: 'Specials'
                }
            },
            views: {
                '@': {
                    templateUrl: 'views/specials.html',
                    controller: 'SpecialsCtrl as specialsCtrl'
                }
            }
        });

        $stateProvider.state('app.specials.categories', {
            url: '/categories/:categoryId',
            data: {
                stateAccess: PAGE_ACCESS.ALLOW,
                bodyClass: 'sales',
                metaTags: {
                    title: 'Specials'
                }
            },
            views: {
                '@': {
                    templateUrl: 'views/specials.html',
                    controller: 'SpecialsCtrl as specialsCtrl'
                }
            }
        });

    }]).run(['$rootScope', '$state', function($rootScope, $state) {
        $rootScope.$on('$stateChangeSuccess', function(event, toState) {
            if (!$state.includes('app.specials')) {
                specialsData.sales = [];
                specialsData.scrollTop = specialsData.total = undefined;
            }
        });
    }]).controller('SpecialsCtrl', [
        '$scope', '$rootScope', '$state', '$stateParams', 'DataLayer', 'Cart', 'Util', 'Offers', 'Config', 'FiltersManager', 'SpecialDetailsDialog',
       function ($scope, $rootScope, $state, $stateParams, DataLayer, cart, Util, offers, Config, FiltersManager, SpecialDetailsDialog) {
            var specialsCtrl = this,
                _listeners = [],
                _resetSpecialsQueue = new Util.SingleOperationQueue(),
                _requestPromise,
                _mainContentElement = document.querySelector('#main > .content'),
                LOYALTY_SPECIAL_DRIVERS = {};

            $rootScope.title = 'Specials'

           if (specialsData.scrollTop) {
                setTimeout(function() {
                    _mainContentElement.scrollTop = specialsData.scrollTop;
                });
            }

            if ($stateParams.categoryId) {
                offers.getSpecialCategories(true)
                    .then(function (specialCategories) {
                        for (var i = 0 ; i < specialCategories.length ; i++) {
                            if (specialCategories[i].id == $stateParams.categoryId) {
                                $scope.specialCategoryName = specialCategories[i].name;
                            }
                        }
                    });
            }

            specialsCtrl.sales = specialsData.sales;
            specialsCtrl.goToOffer = goToOffer;
            specialsCtrl.toggleView = toggleView;
            specialsCtrl.onFiltersChange = onFiltersChange;
            specialsCtrl.noSpecials = false;
            specialsCtrl.boostProductTags = [];
            specialsCtrl.getLine = getLine;

            var filtersPromise = offers.getFilters();

            var _productTagsFilter = FiltersManager.getProductTagsFilter(filtersPromise, $scope, true);
            specialsCtrl.sideMenuFilters = [
                _productTagsFilter,
                FiltersManager.getCategoriesFilter(filtersPromise, 'branch.productsData.categories.id'),
                FiltersManager.getBrandsFilter(filtersPromise, 'branch.productsData.brands.id')
            ];

            Config.waitForInit().then(function() {
                specialsCtrl.showSaleExperations = Config.retailer.showSpecialsValidity;

                //add loyalty filter if the loyalty club is active
                var specialTypeItems = [{
                    value: SPECIAL_TYPES.REGULAR_SPECIALS,
                    title: '\'General Specials\' | translate'
                }];

                if (Config.retailer.loyaltyClubDrivers) {
                    angular.forEach(Config.retailer.loyaltyClubDrivers || [], function (driver) {
                        if (driver.loyaltyClubIds && driver.loyaltyClubIds.length) {
                            LOYALTY_SPECIAL_DRIVERS[driver.id] = driver.loyaltyClubIds;
                            specialTypeItems.push({
                                value: driver.id,
                                title: ('\'{driver_name} Specials\' | translate').replace('{driver_name}', driver.name)
                                /*name: {
                                    1: 'מבצעי ' + driver.name,
                                    2: driver.name + ' Specials',
                                    3: 'Promociones de ' + driver.name,
                                    4: 'Акции ' + driver.name,
                                    5: 'Promos Club',
                                    6: 'الصفقات الخاصة للنادي',
                                }*/
                            })
                        }
                    });
                }

                if (specialTypeItems.length > 1) {
                    //add the filter at the start of the array
                    specialsCtrl.sideMenuFilters.splice(0, 0, {
                        title: '\'Special type\' | translate',
                        name: 'type',
                        orderItemsBy: 'name | name',
                        items: specialTypeItems,
                        parse: _parseSpecialTypeFilter
                    });
                }

                return FiltersManager.setFiltersFromUrl(specialsCtrl.sideMenuFilters, $stateParams.filters);
            }).then(function() {
                if (specialsData.total === undefined) {
                    _getSales();
                }
            });

            function _getSales() {
                if (_requestPromise) {
                    return _requestPromise;
                } else if (specialsData.total === specialsCtrl.sales.length) {
                    return;
                }

                var params = {
                    size: 20,
                    from: specialsCtrl.sales.length
                };

                angular.forEach(specialsCtrl.sideMenuFilters, function(filter) {
                    filter.parse(params);
                });

                specialsCtrl.boostProductTags = (_productTagsFilter.selectedItems || []).map(function(item) {
                    return item.value;
                });

                if ($stateParams.categoryId) {
                    params.filters = params.filters || {};
                    params.filters.must = params.filters.must || {};
                    params.filters.must.term = params.filters.must.term || {};
                    params.filters.must.term.specialCategories = $stateParams.categoryId;
                }

                return _requestPromise = offers.getOffers(params, {
                    loadingElement: document.querySelector('.loading-progress-wrapper')
                }).then(function (resp) {
                    specialsData.total = resp.total;
                    specialsCtrl.noSpecials = !resp.total;
                    Array.prototype.push.apply(specialsCtrl.sales, resp.specials);
                    Util.setProductIndexPosition(resp.specials);
                    _syncSpecialsProductsWithCart();
                    DataLayer.push(DataLayer.EVENTS.VIEW_ITEM_LIST, {products: resp.specials, data: {productsStartingPosition: resp.total}});
                }).finally(function () {
                    _requestPromise = null;
                });
            }

            function _parseSpecialTypeFilter(params) {
                if (!this.selectedItems || this.selectedItems.length !== 1) {
                    return;
                }

                params.filters = params.filters || {};
                params.filters.must = params.filters.must || {};

                var loyaltyClubIds = [];
                var selectedItem = this.selectedItems[0];
                if (selectedItem.value === SPECIAL_TYPES.REGULAR_SPECIALS) {
                    params.filters.must.missing = ['loyaltyClubsIds'];
                } else if (!!LOYALTY_SPECIAL_DRIVERS[selectedItem.value]) {

                    Array.prototype.push.apply(loyaltyClubIds, LOYALTY_SPECIAL_DRIVERS[selectedItem.value]);
                    params.filters.must.exists = ['loyaltyClubsIds'];
                }

                if (loyaltyClubIds.length) {
                    params.filters.must.term = params.filters.must.term || {};
                    params.filters.must.term.loyaltyClubsIds = loyaltyClubIds;
                }
            }

            function _syncSpecialsProductsWithCart() {
                angular.forEach(specialsCtrl.sales, function (deal) {
                    if (!deal.item) return;

                    deal.item = cart.getProduct(deal.item);
                });
            }

            function onFiltersChange() {
                FiltersManager.setFiltersToUrl(specialsCtrl.sideMenuFilters);

                _resetSpecialsQueue.do(function() {
                    // hide no products message
                    specialsCtrl.noSpecials = false;
                    //empty the sales array
                    specialsCtrl.sales.splice(0);
                    specialsData.total = -1;

                    //get the specials
                    return _getSales();
                });
            }

            function goToOffer($event, offer, itemsData) {
                if (Util.isSingleItemSpecial(offer)) {
                    Util.goToProductDialog(offer.item, null, null, false, itemsData);
                } else {
                    if (Config.retailer.settings.isNewPromotionDesignEnabled === 'true') {
                        SpecialDetailsDialog.show(offer.id);
                    } else {
                        $state.go('app.specials.special', {ssid: offer.id});
                    }
                }
            }

            function toggleView($event, product) {
                product.editState = !product.editState;
                $event && $event.preventDefault();
                specialsCtrl.sales.forEach(function (prod) {
                    if (prod.editState && prod !== product) {
                        prod.editState = false;
                    }
                });
            }

            function _onScroll(event) {
                specialsData.scrollTop = _mainContentElement.scrollTop;
            }
           
            function getLine(product) {
                var cartLines = cart.getLines(),
                    cartLine;
                if (!cartLines) return;
                angular.forEach(cartLines, function (line) {
                    if (line.product.id === product.id) {
                        cartLine = line;
                    }
                })
                return cartLine;
            }

            $scope.$on('vertical-scroll-end', _getSales);
            _listeners.push($scope.$root.$on('cart.lines.add', _syncSpecialsProductsWithCart));
            _listeners.push($scope.$root.$on('cart.lines.remove', _syncSpecialsProductsWithCart));

            angular.element(_mainContentElement).bind('scroll', _onScroll);
            _listeners.push(function() {
                angular.element(_mainContentElement).unbind('scroll', _onScroll);
            });

            Util.destroyListeners($scope, _listeners);
        }]);
})(angular);
