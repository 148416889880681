(function (angular) {
    angular.module('mobilezuz')
        .config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
            $stateProvider.state('app.categories', {
                url: '/categories?level1,level2',
                reloadOnSearch: false,
                data: {
                    stateAccess: PAGE_ACCESS.ALLOW,
                    bodyClass: 'categories',
                    metaTags: {
                        title: 'Categories'
                    }
                },
                resolve: {
                    tree: ['$q', '$timeout', 'Tree', function ($q, $timeout, tree) {
                        return $timeout(function () {
                            return tree.getTree();
                        }, 10).then(function (data) {
                            return JSON.parse(JSON.stringify(data));
                        });
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'views/categories.html',
                        controller: 'CategoriesCtrl as categoriesCtrl'
                    }
                }
            });
        }])
        .controller('CategoriesCtrl', [
            '$scope', '$rootScope', '$state', '$timeout', '$location', 'Config', 'tree', 'IMAGES_PATHS','Util', 'DataLayer',
            function ($scope, $rootScope, $state, $timeout, $location, Config, tree, IMAGES_PATHS, util, DataLayer) {
                var categoriesCtrl = this;

                categoriesCtrl.tree = tree;
                categoriesCtrl.openCategories = {};

                categoriesCtrl.getName = getName;
                categoriesCtrl.getImageUrl = getImageUrl;
                categoriesCtrl.toggleCategory = toggleCategory;
                categoriesCtrl.getCategoryAttributeId = getCategoryAttributeId;

                $rootScope.title = 'All products';

                _setOpenCategoriesFromSearch();

                function getName(names) {
                    if (names[Config.language.id]) {
                        return names[Config.language.id];
                    }

                    var keys = Object.keys(names);
                    return keys && keys.length ? names[keys[0]] : '';
                }

                function getImageUrl(category) {
                    var url = category === categoriesCtrl.openCategories[category.level] ? category.iconSelectedImage : category.iconImage;

                    if (!url) {
                        return IMAGES_PATHS.MISSING_ICON;
                    } else if (url.indexOf('http') !== 0) {
                        return 'https://ecom.blob.core.windows.net' + category.iconImage;
                    }

                    return url;
                }

                function getCategoryAttributeId(id) {
                    return 'cat_' + id;
                }

                function toggleCategory(category, $event, $index) {
                    if ($event) {
                        $event.stopPropagation();
                    }

                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Menu', action: 'Click', label: category.names}});

                    var oldElement = categoriesCtrl.openCategories[category.level] && document.getElementById(getCategoryAttributeId(categoriesCtrl.openCategories[category.level].id)),
                        newValue = categoriesCtrl.openCategories[category.level] !== category ? category : null;

                    if (newValue && ($index || $index === 0)) {
                      var treeCategories = categoriesCtrl.tree.categories;

                      categoriesCtrl.nextElementId =
                        $index + 1 < treeCategories.length ? treeCategories[$index + 1].id : null;
                    }

                    util.getCategory(category.id).then(function(categories) {
                        if (categories) {
                            $rootScope.$emit('categoryPage',categories);
                        }
                    });

                    var isSingleCategory = false;
                    if (!category.subCategories || !category.subCategories.length) {
                        isSingleCategory = true;
                        return _goToCategoryProducts(category);
                    } else if (category.subCategories.length === 1) {
                        if ((!category.subCategories[0].subCategories || category.subCategories[0].subCategories.length === 1)) {
                            isSingleCategory = true;
                            return _goToCategoryProducts(category.subCategories[0]);
                        }

                        if (newValue) {
                            $timeout(function () {
                                toggleCategory(category.subCategories[0]);
                            }, 0);
                        }
                    }

                    _setCategoryAtLevel(newValue, category.level);

                    if (newValue && category.subCategories.length > 1 && categoriesCtrl.openCategories[category.level + 1]) {
                        _setCategoryAtLevel(null, category.level + 1);
                    }

                    if (category.level === 2) {
                        if (oldElement) {
                            oldElement.style.height = '0';
                        }

                        if (newValue) {
                            $timeout(function () {
                                var element = document.getElementById(getCategoryAttributeId(newValue.id));
                                element.style.height = (element.children[0].offsetHeight * newValue.subCategories.length) + 'px';
                            }, 0);

                        }
                    }

                    if (category.level === 1 && !isSingleCategory) {
                        $timeout( function () {
                            var $subCategoriesFirstButtonElement = angular.element(document.querySelector('#sub_categories .sub-categories-content > button'));
                            $subCategoriesFirstButtonElement && $subCategoriesFirstButtonElement.focus();
                        });
                    }
                }

                function _goToCategoryProducts(category) {
                    return $state.go('app.categories.categoryProducts', {categoryId: category.id, level1: null, level2: null});
                }

                function _setCategoryAtLevel(category, level) {
                    categoriesCtrl.openCategories[level] = category;

                    //set search params
                    var params = {};
                    params['level' + level] = category ? category.id : null;
                    $state.go('app.categories', params, {notify: false});
                }

                function _setOpenCategoriesFromSearch() {
                    var searchParams = $location.search();

                    if (searchParams.level1) {
                        var level1Id = Number(searchParams.level1);
                        if (!categoriesCtrl.openCategories[1] || categoriesCtrl.openCategories[1].id !== level1Id) {
                            toggleCategory(_findCategory(tree.categories, level1Id));
                        }

                        if (searchParams.level2) {
                            if (categoriesCtrl.openCategories[1].subCategories && categoriesCtrl.openCategories[1].subCategories.length > 1) {
                                var level2Id = Number(searchParams.level2);
                                if (!categoriesCtrl.openCategories[2] || categoriesCtrl.openCategories[2].id !== level2Id) {
                                    $timeout(function () {
                                        toggleCategory(_findCategory(categoriesCtrl.openCategories[1].subCategories, level2Id));
                                    }, 0);
                                }
                            }
                        } else if (categoriesCtrl.openCategories[2]) {
                            toggleCategory(categoriesCtrl.openCategories[2]);
                        }
                    } else if (categoriesCtrl.openCategories[1]) {
                        toggleCategory(categoriesCtrl.openCategories[1]);
                    }
                }

                function _findCategory(categories, id) {
                    for (var i = 0; i < categories.length; i++) {
                        if (categories[i].id === id) {
                            return categories[i];
                        }
                    }
                }

                $scope.$on('$locationChangeSuccess', _setOpenCategoriesFromSearch);
            }
        ]);
})(angular);
