(function(angular, app) {
    function config($stateProvider, SpDialogUrlManagerProvider, SP_URL_DIALOG_QUERY_PARAMS, URL_DIALOG_DATA_QUERY_PARAMS, PAGE_ACCESS) {
        $stateProvider.state('app.loginOrRegister', {
            url: '/login-or-register?{retState},{retStateParams},{title},{subTitle}',
            data: {
                stateAccess: PAGE_ACCESS.NOT_LOGGED_IN
            },
            views: {
                '@': {
                    template: 'Redirecting...',
                    controller: ['$state', '$stateParams', '$timeout', 'Util', function($state, $stateParams, $timeout, Util) {
                        var retState = $stateParams.retState,
                            retStateParams = $stateParams.retStateParams,
                            title = $stateParams.title,
                            subTitle = $stateParams.subTitle;

                        $timeout(function() {
                            return $state.go('app.home', {}, {inherit: true});
                        }, 500).then(function() {
                            $timeout(function() {
                                return Util.goToLoginDialog(title, subTitle, retState, retStateParams);
                            }, 500);
                        });
                    }]
                }
            }
        });

        SpDialogUrlManagerProvider.dialog({
            queryParams: SP_URL_DIALOG_QUERY_PARAMS.LOGIN,
            dataQueryParams: URL_DIALOG_DATA_QUERY_PARAMS.LOGIN,
            canShow: ['User', function (User) {
                return setTimeout(function () {
                    return !User.getUserLoginData();
                }, 100)
            }],
            show: ['mDesign', 'Config', function(mDesign, Config) {
                var templateUrl = 'views/login-or-register.html'
                var controller = 'LoginOrRegisterCtrl as loginOrRegisterCtrl'
                if(Config.retailer.loyaltyClubDriver && Config.retailer.loyaltyClubDriver.clientConfig && Config.retailer.loyaltyClubDriver.clientConfig.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty) {
                    templateUrl = 'views/login-or-register-v2.html'
                    controller = 'LoginOrRegisterCtrlV2 as loginOrRegisterCtrlV2'
                }
                return mDesign.dialog({
                    multiple: true,
                    fullscreen: true,
                    clickOutsideToClose: false,
                    templateUrl: templateUrl,
                    controller: controller,
                    resolve: {
                        retailerData: ['Retailer', function (Retailer) {
                            return Retailer.getRetailerSettings();
                        }]
                    },
                    //mDesign param
                    dontPreventRouteChange: true
                });
            }]
        });
    }

    var registerData;
    var LOGIN_TYPE = {
        default: 'default',
        otp: 'otp'
    }

    function ctrl($rootScope, $window, $state, $timeout, $q, $sce, Config, Facebook, Retailer, User,
                  Cart, Api, HubService, SpDialogUrlManager, UserVerificationDialog, mDesign, Util,
                  retailerData, $location, $filter, RETAILER_STATUSES, LOYALTY_CLUB_DRIVERS, SpCaptcha, DataLayer) {
        var loginOrRegisterCtrl = this,
            search = $location.search(),
            _translate = $filter('translate'),
            _name = $filter('name'),
            specialCharacterRegex = /^[^\uD83C-\uDBFF\uDC00-\uDFFF\uD800-\uDBFF][^\uDC00-\uDFFF]*$/;
        // can't use: /^[^\p{Emoji_Presentation}]*$/u because it is es6

        loginOrRegisterCtrl.otpCodeInputs = [];
        loginOrRegisterCtrl.showInvalidOrExpiredText = false;
        loginOrRegisterCtrl.showAccountSuspended  = false;
        loginOrRegisterCtrl.curLoginType = LOGIN_TYPE.default
        loginOrRegisterCtrl.LoginWithOtpOptions = $rootScope.LOGIN_WITH_OTP_OPTIONS;
        loginOrRegisterCtrl.LoginOrRegisterScreens = $rootScope.LOGIN_OR_REGISTER_SCREENS;
        loginOrRegisterCtrl.otpAttempts = 0;
        loginOrRegisterCtrl.queryParamDomain = search.domain;
        loginOrRegisterCtrl.title = getText('title', search.loginDialogTitle);
        loginOrRegisterCtrl.subTitle = search.loginDialogSubTitle;
        loginOrRegisterCtrl.isRegistrationTab = search.registrationTab !== undefined;
        loginOrRegisterCtrl.isUserConfirmationLogin = search.userConfirmationLogin !== undefined;
        loginOrRegisterCtrl.captchaIsInvalid = false;
        loginOrRegisterCtrl.otpOptionSelectedMapObject = {};
        loginOrRegisterCtrl.otpOptionSelectedMapObject[loginOrRegisterCtrl.LoginWithOtpOptions.PHONE_NUMBER] = {
            img: 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/otp-phone.png',
            enterOtpPhoneOrEmailTitle: 'Please enter your phone number',
            enterCodeToValidateTitle: 'We\'ve sent a Password to the mobile number',
            enterCodeToValidateSmallText: 'not your phone number?',
            otpErrorTitle: 'Sorry, we cannot proceed using this phone number'
        };
        loginOrRegisterCtrl.otpOptionSelectedMapObject[loginOrRegisterCtrl.LoginWithOtpOptions.EMAIL] = {
            img: 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/otp-email.png',
            enterOtpPhoneOrEmailTitle: 'Please enter your Email',
            enterCodeToValidateTitle: 'We\'ve sent a Password to your email address at',
            enterCodeToValidateSmallText: 'not your email?',
            otpErrorTitle: 'Sorry, we cannot proceed using this email'
        };
        loginOrRegisterCtrl.config = Config;
        if (loginOrRegisterCtrl.isUserConfirmationLogin) {
            loginOrRegisterCtrl.title = getText('title', 'Login to your account to complete the payment');
        }

        if (search.token && search.id) {
            localStorage.removeItem('userLoginData');
            $rootScope.isSSOLoginInProgress = true;
            User.loginWithToken(search.id, search.token).then(function () {});
            $location.search('token', null);
            $location.search('id', null);
            $location.search('loginOrRegister', null);
            $window.history.replaceState(null, null, $location.$$url);
            $window.history.pushState(null, null, $location.$$url);
            if(search.externalReturn && search.externalReturn === 'app.cart') {
                if(search.domain) {
                    return $state.go(search.externalReturn, {domain: search.domain});
                } else {
                    return $state.go(search.externalReturn);
                }
            }
        }

        loginOrRegisterCtrl.backClose = backClose;
        loginOrRegisterCtrl.submit = submit;
        loginOrRegisterCtrl.showCaptcha = true;
        loginOrRegisterCtrl.onTabChanged = onTabChanged;
        loginOrRegisterCtrl.showLoginWithOtp = showLoginWithOtp;
        loginOrRegisterCtrl.showEnterSmsOrMail = showEnterSmsOrMail;
        loginOrRegisterCtrl.sendOneTimeCode = sendOneTimeCode;
        loginOrRegisterCtrl.goToEnterOtp = goToEnterOtp;
        loginOrRegisterCtrl.validateOtpCode = validateOtpCode;
        loginOrRegisterCtrl.setFocus = setFocus;
        loginOrRegisterCtrl.handlePasteEvent  = handlePasteEvent;
        loginOrRegisterCtrl.backToLogin = backToLogin;
        loginOrRegisterCtrl.filterNonDigits = filterNonDigits;
        loginOrRegisterCtrl.filterNonDigitsOnChange = filterNonDigitsOnChange;
        loginOrRegisterCtrl.getValidLoginScreenBasedOnBackendConfiguration = getValidLoginScreenBasedOnBackendConfiguration;
        loginOrRegisterCtrl.changeView = changeView;
        loginOrRegisterCtrl.addEventListenersToOtpCodeInputsForBackspaceKey = addEventListenersToOtpCodeInputsForBackspaceKey;
        loginOrRegisterCtrl.addAutoFillForOTPFromSMSListener = addAutoFillForOTPFromSMSListener;
        loginOrRegisterCtrl.fillOtpCodeInputsFromStringCode = fillOtpCodeInputsFromStringCode;

        loginOrRegisterCtrl.user = {
            allowSendPromotions: !!Config.retailer.allowSendPromotionsDefault,
            policyApproval: !!Config.retailer.allowTermsAndConditionsDefault,
        };

        loginOrRegisterCtrl.retailerData = retailerData;
        loginOrRegisterCtrl.backgroundImage = Retailer.backgroundImage;

        SpCaptcha.countFormSubmits(loginOrRegisterCtrl);

        if (search.registrationEmail) {
            loginOrRegisterCtrl.user.email = search.registrationEmail || '';
        }

        getValidLoginScreenBasedOnBackendConfiguration();

        _initCtrl();

        //handle chrome autofill and add the missing class
        $timeout(function () {
            var $emailElem = angular.element(document.querySelector('input[type="email"]:-webkit-autofill'));
            if ($emailElem.length) {
                angular.element(document.querySelector('input[type="email"]:-webkit-autofill').parentNode.closest("md-input-container")).addClass('md-input-has-value')
                angular.element(document.querySelector('input[type="password"]:-webkit-autofill').parentNode.closest("md-input-container")).addClass('md-input-has-value')
            }

            if (loginOrRegisterCtrl.isRegistrationTab) {
                document.getElementById('register_tab').click();
            }
        }, 300);

        function changeView(nextView, isGoBack) {
            if (isGoBack) {
                var previousViewState = loginOrRegisterCtrl.previousViewState.pop();
                if (previousViewState.view === loginOrRegisterCtrl.currentView) {
                    loginOrRegisterCtrl.backClose();
                    return;
                }
                if (previousViewState.view === 'enterOtpPhoneOrEmail' && !previousViewState.otpPhoneOrEmail && (loginOrRegisterCtrl.config.retailer.settings.allowLoginBySmsVerification !== 'true' || loginOrRegisterCtrl.config.retailer.settings.allowLoginByEmailVerification !== 'true')) {
                    loginOrRegisterCtrl.backToLogin();
                    return;
                }
                loginOrRegisterCtrl.otpPhoneOrEmail = previousViewState.otpPhoneOrEmail;
                loginOrRegisterCtrl.currentView = previousViewState.view;
            } else {
                loginOrRegisterCtrl.previousViewState.push({view: loginOrRegisterCtrl.currentView, otpPhoneOrEmail: loginOrRegisterCtrl.otpPhoneOrEmail});
                loginOrRegisterCtrl.currentView = nextView;
            }
        }

        function getValidLoginScreenBasedOnBackendConfiguration() {
            if (loginOrRegisterCtrl.config.retailer.settings.isLoginViaOtpActive === 'true' && loginOrRegisterCtrl.config.retailer.settings.numberOfDigitsForOTP) {
                loginOrRegisterCtrl.numberOfDigitsForOTPCode = new Array(+loginOrRegisterCtrl.config.retailer.settings.numberOfDigitsForOTP);
            }

            if (loginOrRegisterCtrl.config.retailer.settings.mainLoginPage === 'otp-login' && loginOrRegisterCtrl.config.retailer.settings.isLoginViaOtpActive === 'true') {
                if (loginOrRegisterCtrl.config.retailer.settings.allowLoginByEmailVerification === 'true' && loginOrRegisterCtrl.config.retailer.settings.allowLoginBySmsVerification === 'true') {
                    loginOrRegisterCtrl.previousViewState = [{view: loginOrRegisterCtrl.LoginOrRegisterScreens.SELECT_OTP_OPTION, otpPhoneOrEmail: ''}];
                    loginOrRegisterCtrl.currentView = loginOrRegisterCtrl.LoginOrRegisterScreens.SELECT_OTP_OPTION;
                } else if (loginOrRegisterCtrl.config.retailer.settings.allowLoginByEmailVerification === 'true') {
                    loginOrRegisterCtrl.otpOptionSelected = loginOrRegisterCtrl.LoginWithOtpOptions.EMAIL;
                    loginOrRegisterCtrl.previousViewState = [{view: loginOrRegisterCtrl.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL, otpPhoneOrEmail: ''}];
                    loginOrRegisterCtrl.currentView = loginOrRegisterCtrl.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL;
                } else {
                    loginOrRegisterCtrl.otpOptionSelected = loginOrRegisterCtrl.LoginWithOtpOptions.PHONE_NUMBER;
                    loginOrRegisterCtrl.previousViewState = [{view: loginOrRegisterCtrl.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL, otpPhoneOrEmail: ''}];
                    loginOrRegisterCtrl.currentView = loginOrRegisterCtrl.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL;
                }

                loginOrRegisterCtrl.curLoginType = LOGIN_TYPE.otp
            } else {
                loginOrRegisterCtrl.previousViewState = [{view: loginOrRegisterCtrl.LoginOrRegisterScreens.DEFAULT, otpPhoneOrEmail: ''}];
                loginOrRegisterCtrl.currentView = loginOrRegisterCtrl.LoginOrRegisterScreens.DEFAULT;
                loginOrRegisterCtrl.curLoginType = LOGIN_TYPE.default
            }
        }

        function showLoginWithOtp() {
            var retailerSettings = loginOrRegisterCtrl.config.retailer.settings;
            var allowLoginByEmailVerification = JSON.parse(retailerSettings.allowLoginByEmailVerification);
            var allowLoginBySmsVerification = JSON.parse(retailerSettings.allowLoginBySmsVerification);
            
            loginOrRegisterCtrl.otpPhoneOrEmail = '';
            loginOrRegisterCtrl.curLoginType = LOGIN_TYPE.otp

            if (allowLoginByEmailVerification && allowLoginBySmsVerification) {
                loginOrRegisterCtrl.changeView(loginOrRegisterCtrl.LoginOrRegisterScreens.SELECT_OTP_OPTION);
            } else if (allowLoginByEmailVerification) {
                loginOrRegisterCtrl.otpOptionSelected = loginOrRegisterCtrl.LoginWithOtpOptions.EMAIL;
                loginOrRegisterCtrl.changeView(loginOrRegisterCtrl.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL);
            } else if (allowLoginBySmsVerification) {
                loginOrRegisterCtrl.otpOptionSelected = loginOrRegisterCtrl.LoginWithOtpOptions.PHONE_NUMBER;
                loginOrRegisterCtrl.changeView(loginOrRegisterCtrl.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL);
            }
        }

        function showEnterSmsOrMail(nextOtpScreenEnum) {
            loginOrRegisterCtrl.otpPhoneOrEmail = '';
            loginOrRegisterCtrl.otpOptionSelected = nextOtpScreenEnum;
            loginOrRegisterCtrl.changeView(loginOrRegisterCtrl.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL);
        }

        function sendOneTimeCode() {
            if (!loginOrRegisterCtrl.otpPhoneOrEmail) {
                return;
            } else {
                loginOrRegisterCtrl.otpPhoneOrEmail = loginOrRegisterCtrl.otpPhoneOrEmail.toString();
                loginOrRegisterCtrl.otpCodeInputs = [];

                User.generateOtpCode(loginOrRegisterCtrl.otpPhoneOrEmail, loginOrRegisterCtrl.otpOptionSelected)
                    .then(function() {
                        loginOrRegisterCtrl.showInvalidOrExpiredText = false;
                        loginOrRegisterCtrl.changeView(loginOrRegisterCtrl.LoginOrRegisterScreens.ENTER_CODE_TO_VALIDATE);
                        $timeout(function () {
                            loginOrRegisterCtrl.addEventListenersToOtpCodeInputsForBackspaceKey();
                            loginOrRegisterCtrl.addAutoFillForOTPFromSMSListener();
                        }, 500);
                    })
                    .catch(function (err) {
                        if (err && err.data && (err.data.error === 'User not found' || err.data.error === 'multipleUsersWithSamePhoneNumber')) {
                            loginOrRegisterCtrl.changeView(loginOrRegisterCtrl.LoginOrRegisterScreens.OTP_ERROR);
                        } else if (err && err.data.error.includes('Account has been suspended')) {
                            loginOrRegisterCtrl.showAccountSuspended = true;
                            loginOrRegisterCtrl.changeView(loginOrRegisterCtrl.LoginOrRegisterScreens.OTP_ERROR);
                        }
                    });
            }
        }

        function addAutoFillForOTPFromSMSListener() {
            if (window.OTPCredential) {
                var otpInputs = document.querySelectorAll('.otp-code-input .input');
                if (otpInputs && otpInputs.length) {
                    navigator.credentials.get({
                        otp: {transport: ['sms']}
                    }).then(function (otp) {
                        console.log('DEBUG - OTP code received - ' + otp.code);
                        loginOrRegisterCtrl.fillOtpCodeInputsFromStringCode(otp.code);
                    }).catch(function (err) {
                        console.log(err);
                    });
                }
            }
        }

        function fillOtpCodeInputsFromStringCode(stringOtpCode) {
            var otpCodeSplitted = stringOtpCode.split('');
            if (otpCodeSplitted.length === loginOrRegisterCtrl.numberOfDigitsForOTPCode.length) {
                for (var i = 0; i < otpCodeSplitted.length; i++) {
                    loginOrRegisterCtrl.otpCodeInputs[i] = otpCodeSplitted[i];
                }
                var validateOtpCodeForm = document.querySelector('form[name="enterCodeToValidate"]');
                loginOrRegisterCtrl.validateOtpCode(validateOtpCodeForm);
            } else {
                console.error('Length mismatch in otp code from sms and otp code inputs:' + stringOtpCode);
            }
        }

        function addEventListenersToOtpCodeInputsForBackspaceKey() {
            var otpCodeInputElements = document.querySelectorAll('.otp-code-input .input');
            otpCodeInputElements.forEach(function (inputElement, index) {
                inputElement.addEventListener('keydown', function (event) {
                    var key = event.keyCode || event.charCode;
                    var isEmpty = inputElement.value === ''
                    if (key === 8 && isEmpty) {
                        if (index > 0) {
                            otpCodeInputElements[index - 1].focus(); // move focus to previous input field
                        }
                    }
                });
            });
        }

        function goToEnterOtp() {
            loginOrRegisterCtrl.changeView(loginOrRegisterCtrl.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL);
            loginOrRegisterCtrl.otpPhoneOrEmail = '';
            loginOrRegisterCtrl.showAccountSuspended = false;
        }

        function validateOtpCode(form, event) {
            if (form.$invalid) {
                // util.setFirstErrorInput(event.target || event.srcElement || event.path[0]);
            } else {
                var otpCode = loginOrRegisterCtrl.otpCodeInputs.join('');
                User.loginWithToken(undefined, otpCode, loginOrRegisterCtrl.otpPhoneOrEmail, loginOrRegisterCtrl.otpOptionSelected)
                    .then(function () {
                        var retState = search.loginDialogRetState, // may be deleted in the "then"
                            retParams = {};
                        loginOrRegisterCtrl.otpAttempts = 0;
                        DataLayer.push(DataLayer.EVENTS.LOGIN);
                        loginOrRegisterCtrl.backClose();
                        if (retState) {
                            return $state.go(retState, retParams);
                        } else if (retParams) {
                            return $state.go('.', retParams, {reload: false});
                        }
                    }, function (err) {
                        if (err && err.data.error.includes('Account has been suspended')) {
                            loginOrRegisterCtrl.showAccountSuspended = true;
                            loginOrRegisterCtrl.changeView(loginOrRegisterCtrl.LoginOrRegisterScreens.OTP_ERROR);
                        }

                        loginOrRegisterCtrl.showInvalidOrExpiredText = true;
                        loginOrRegisterCtrl.otpAttempts += 1;
                        if (loginOrRegisterCtrl.otpAttempts === (+loginOrRegisterCtrl.config.retailer.settings.otpUserBlockAttempts || 3)) {
                            loginOrRegisterCtrl.getValidLoginScreenBasedOnBackendConfiguration();
                            loginOrRegisterCtrl.otpAttempts = 0;
                        }
                    });
            }
        }

        //  This function is used to not allow user input text characters. <input type="number"/> is not used because it can have leading zeros.
        function filterNonDigits(event) {
            var key = event.key;
            var isDigit = /\d/.test(key);
            var isSpecialKey = [
                'Backspace',
                'Delete',
                'Tab',
                'Escape',
                'Enter',
                'Home',
                'End',
                'ArrowLeft',
                'ArrowUp',
                'ArrowRight',
                'ArrowDown'
            ].includes(key);
            if (loginOrRegisterCtrl.otpOptionSelected === loginOrRegisterCtrl.LoginWithOtpOptions.PHONE_NUMBER && !isDigit && !isSpecialKey) {
                event.preventDefault();
            }
        }

        //  This function is used to cover when text is added with pasting.
        function filterNonDigitsOnChange() {
            if (loginOrRegisterCtrl.otpOptionSelected === loginOrRegisterCtrl.LoginWithOtpOptions.PHONE_NUMBER && loginOrRegisterCtrl.otpPhoneOrEmail) {
                loginOrRegisterCtrl.otpPhoneOrEmail = loginOrRegisterCtrl.otpPhoneOrEmail.replace(/\D/g, '');
            }
        }

        function setFocus(currentInputIndex) {
            if (currentInputIndex < (loginOrRegisterCtrl.numberOfDigitsForOTPCode.length - 1)) {
                $timeout(function () {
                    if (loginOrRegisterCtrl.otpCodeInputs[currentInputIndex]) {
                        document.querySelectorAll('.otp-code-input .input')[currentInputIndex + 1].focus();
                    }
                });
            }
        }

        function handlePasteEvent(event) {
            event.preventDefault();
            loginOrRegisterCtrl.fillOtpCodeInputsFromStringCode(event.clipboardData.getData('text'));
        }

        function backToLogin() {
            loginOrRegisterCtrl.changeView(loginOrRegisterCtrl.LoginOrRegisterScreens.DEFAULT);
            loginOrRegisterCtrl.isResetCodeError = false;
            loginOrRegisterCtrl.showAccountSuspended = false;
            loginOrRegisterCtrl.curLoginType = LOGIN_TYPE.default

            delete search.resetCode;
            delete search.email;
        }

        function _initCtrl() {
            return $q.resolve().then(function() {
                if (!Config.hubRetailer || !Config.hubRetailer.id) {
                    return;
                }

                return HubService.getData(Config.hubRetailer.id).then(function(hubData) {
                    loginOrRegisterCtrl.hubData = hubData;
                }).catch(function() {
                    //to ignore error and show regular register tabs
                });
            }).then(function() {
                loginOrRegisterCtrl.loginTabTitle = getText('loginTabTitle', 'Login');
                loginOrRegisterCtrl.registerTabTitle = getText('registerTabTitle', 'Register');
                loginOrRegisterCtrl.additionalText = getText('additionalText', '');
                loginOrRegisterCtrl.firstNamePlaceholder = getText('firstName', 'First Name');
                loginOrRegisterCtrl.lastNamePlaceholder = getText('lastName', 'Last Name');
                loginOrRegisterCtrl.phoneNumberPlaceholder = getText('phoneNumber', 'Phone Number');
                loginOrRegisterCtrl.termsAndConditionsText = getText('termsAndConditionsText', '');
                loginOrRegisterCtrl.promotionsText = getText('promotionsText', 'Please let me know about promotions');
                loginOrRegisterCtrl.registerButton = getText('registerButton', 'Register');

                loginOrRegisterCtrl.tabs = [_loginTab()];

                var canRegister = retailerData.status !== RETAILER_STATUSES.REGISTERED_ONLY && !loginOrRegisterCtrl.isUserConfirmationLogin;
                if (canRegister && retailerData.status === RETAILER_STATUSES.ORGANIZATION_ONLY) {
                    canRegister = (Config.retailer.settings.registrationDomainsEnabled || 'false') === 'true';
                }
                if (canRegister) {
                    loginOrRegisterCtrl.tabs.push(_registerTab(!!loginOrRegisterCtrl.hubData));
                }

                loginOrRegisterCtrl.tab = loginOrRegisterCtrl.tabs[0];

                $timeout(function() {
                    if (search.loginOrRegister === '2') {
                        loginOrRegisterCtrl.user = {};
                        loginOrRegisterCtrl.tab = loginOrRegisterCtrl.tabs[1];
                    }
                    // case reopen dialog, reset to login tab
                    else if(loginOrRegisterCtrl.tab === loginOrRegisterCtrl.tabs[1]){
                        loginOrRegisterCtrl.user = {};
                        loginOrRegisterCtrl.tab = loginOrRegisterCtrl.tabs[0];
                        loginOrRegisterCtrl.form.$submitted = false;
                    }
                })

                if (registerData && registerData.isActive) {
                    loginOrRegisterCtrl.tab = loginOrRegisterCtrl.tabs[1];
                    loginOrRegisterCtrl.user = registerData.user;
                }

                registerData = {user: loginOrRegisterCtrl.user, isActive: loginOrRegisterCtrl.tab.title === 'Register'};
            });
        }

        function getText(key, defaultValue) {
            return Config.retailer.isCustomRegistrationActive &&
                Config.retailer.customRegistrationData && Config.retailer.customRegistrationData[Config.language.id] &&
                Config.retailer.customRegistrationData[Config.language.id][key] ?
                Config.retailer.customRegistrationData[Config.language.id][key] : $filter('translate')(defaultValue);
        }

        function _loginTab(isHub) {
            var tab = {
                title: loginOrRegisterCtrl.loginTabTitle,
                id: 'login_tab',
                isRegisterTab: false,
                submit: function () {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Login to site'}});

                    var recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();
                    $rootScope.emailForReActivation = loginOrRegisterCtrl.user.email;
                    return User.login(loginOrRegisterCtrl.user.email, loginOrRegisterCtrl.user.password, recaptchaHash)
                },
                fields: [{
                    title: 'E-mail',
                    type: 'email',
                    model: 'email',
                    required: true
                }, {
                    title: 'Password',
                    type: 'password',
                    model: 'password',
                    required: true
                }],
                links: [],
                buttons: [{
                    title: 'Sign In',
                    type: 'submit'
                }]
            };

            // TODO support forgot password better, without redirecting, in user confirmation update
            if (!loginOrRegisterCtrl.isUserConfirmationLogin) {
                tab.links.push({
                    title: 'Forgot Your Password?',
                    uiSref: 'app.forgottenPassword({email: loginOrRegisterCtrl.user.email, domain: loginOrRegisterCtrl.queryParamDomain})',
                    ngClassFunc: disableForgotPasswordLink
                });
            }

            if (!isHub && $window.facebookConnectPlugin && retailerData.facebookAppId) {
                tab.buttons.push({
                    title: 'Login',
                    type: 'button',
                    styleClass: 'facebook',
                    icon: 'facebook',
                    operation: function () {
                        return submit(Facebook.login);
                    }
                });
            }

            return tab;
        }

        function disableForgotPasswordLink() {
            return loginOrRegisterCtrl.user.email ? '' : 'forgot-password-inactive';
        }

        function _scrollRegisterTabDown() {
            var registerDialog = document.getElementsByClassName("login-or-register-dialog");
            if(registerDialog && registerDialog[0]){
                var formElement = registerDialog[0].getElementsByTagName('form');
                if(formElement && formElement[0]){
                    formElement[0].scrollTop = formElement[0].scrollHeight;
                }
            }
        }

        function _registerTab(isHub) {
            var tab = {
                title: loginOrRegisterCtrl.registerTabTitle,
                id: 'register_tab',
                isRegisterTab: true,
                showHubPolicy: true,
                validation: function () {
                    if (!loginOrRegisterCtrl.user.policyApproval) {
                        mDesign.alert('Please confirm that you accept the terms and conditions for using this service').then(function () {
                            //== when user click on warning popup's OK button we scroll down to focus Terms checkbox on bottom
                            _scrollRegisterTabDown();
                        });
                        return false;
                    }

                    return true;
                },
                submit: function () {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Register to site'}});

                    var params = angular.copy(loginOrRegisterCtrl.user),
                        invitePromotion = {
                            inviteId: Number(localStorage.getItem('inviteId')),
                            promotionId: Number(localStorage.getItem('promotionId'))
                        };
                    if (invitePromotion.inviteId && invitePromotion.promotionId) {
                        params.invitePromotion = invitePromotion;
                    }

                    if($rootScope.externalUserCode) {
                        params.externalUserCode = $rootScope.externalUserCode;
                    }

                    params.cartId = Cart.serverCartId || undefined;

                    params.recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();
                    $rootScope.emailForReActivation = loginOrRegisterCtrl.user.email;

                    return Api.request({
                        method: 'POST',
                        url: 'retailers/:rid/users',
                        data: params
                    }).then(function(registerData){
                        DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Register successful'}});

                        return User.login(loginOrRegisterCtrl.user.email, loginOrRegisterCtrl.user.password, null).then(function() {
                            $rootScope.$emit('customerRegistration', {
                                id: registerData.userId,
                                firstName: loginOrRegisterCtrl.user.firstName,
                                lastName: loginOrRegisterCtrl.user.lastName,
                                email: loginOrRegisterCtrl.user.email,
                                allowSendPromotions: loginOrRegisterCtrl.user.allowSendPromotions});
                            localStorage.setItem('isFirstUserLogin', true);
                            DataLayer.push(DataLayer.EVENTS.SIGN_UP);
                            return registerData;
                        });
                    }).then(function(registerData) {
                        if (registerData.cartId) {
                            // wait for after the login, for the cart call to have a token
                            Cart.replaceCart(registerData.cartId, true);
                        }

                        return User.getUserSettings(true);
                    }).then(function() {
                        if (User.isVerified()) {
                            return true;
                        }

                        return UserVerificationDialog.show(true);
                    }).then(function(verificationSkipped) {
                        if (Config.retailer.settings.isExternalLoyaltyClubRegistrationEnabled === 'true' && !User.settings.foreignId) {
                            return Util.goToLoyaltyIFrameDialog(Config.retailer.settings.externalLoyaltyClubRegistrationUrl, User.settings);
                        }

                        if (retailerData.loyaltyClubDriver && retailerData.loyaltyClubDriver.isActive &&
                            (!search.loginDialogRetState || search.loginDialogRetState === 'app.loyaltyClub') &&
                            !retailerData.loyaltyClubDriver.clientConfig.isRegisterToClubObligation  &&
                            retailerData.loyaltyClubDriver.loyaltyClubDriverId !== LOYALTY_CLUB_DRIVERS.LOOPBACK) {
                                return mDesign.confirm({
                                    content: _translate('Thank you for signing up to {0} app').replace('{0}', $rootScope.retailerName) + '. ' +
                                        _translate((retailerData.loyaltyClubDriver.isLookupOnly ? 'Connect to' : 'Join') + ' our loyalty club to benefit from exclusive promotions') + '.',
                                    ok: retailerData.loyaltyClubDriver.isLookupOnly ? 'Connect' : 'Join Membership',
                                    cancel: 'cancel'
                                }, function (isTrue) {
                                    if (isTrue) {
                                        // $rootScope.$emit('loyaltyLoginActionsFinished');
                                    $state.go(retailerData.loyaltyClubDriver.clientConfig.extendedLoyaltyClub ? 'app.extendedLoyaltyClub' : 'app.loyaltyClub', {showRegistrationForm: true}, {reload: true});
                                    }
                                });
                        }

                        if (verificationSkipped) {
                            return mDesign.alert('Sign Up Completed');
                        }
                    });
                },
                fields: [{
                    title: loginOrRegisterCtrl.firstNamePlaceholder,
                    type: 'text',
                    model: 'firstName',
                    required: true,
                    match: {
                        regexp: specialCharacterRegex,
                        errorMessage: 'Please enter First name'
                    },
                    maxLength: 50,
                    maxLengthErrMessage: 'First Name should not be longer than 50 characters',
                }, {
                    title: loginOrRegisterCtrl.lastNamePlaceholder,
                    type: 'text',
                    model: 'lastName',
                    required: true,
                    match: {
                        regexp: specialCharacterRegex,
                        errorMessage: 'Please enter Last name'
                    },
                    maxLength: 50,
                    maxLengthErrMessage: 'Last Name should not be longer than 50 characters',
                }, loginOrRegisterCtrl.config.retailer.settings.isPhoneNumberMandatoryWhileRegistering === 'true' ? {
                    title: loginOrRegisterCtrl.phoneNumberPlaceholder,
                    type: 'text',
                    model: 'phoneNumber',
                    required: true,
                    match: {
                        regexp: /^(\+\d+|\d+)$/,
                        errorMessage: 'Enter valid Phone number'
                    },
                } : null, {
                    title: 'Email',
                    type: 'email',
                    model: 'email',
                    required: true
                }, {
                    title: 'Password',
                    type: 'password',
                    model: 'password',
                    required: true,
                    minLength: 6,
                    maxLength: 30,
                    maxLengthErrMessage: 'Password should not be longer than 30 characters',
                    match: {
                        regexp: /(?=.*[0-9])(?=.*[^0-9])/,
                        errorMessage: 'Password must contain at least one numeric digit and one character'
                    }
                }, {
                    title: 'Confirm Password',
                    type: 'password',
                    model: 'confirmPassword',
                    required: true
                }, {
                    titleGenerator: function() {
                        if (!isHub) {
                            if (loginOrRegisterCtrl.promotionsText) {
                                return loginOrRegisterCtrl.promotionsText;
                            } else {
                                return _translate('Please let me know about promotions');
                            }
                        }

                        if (loginOrRegisterCtrl.promotionsText) {
                            return loginOrRegisterCtrl.promotionsText;
                        } else {
                            return _translate('I agree to receive promotional content & offers from {company_name} and {hubname}')
                                .replace('{hubname}', loginOrRegisterCtrl.hubData.hubRegisterName)
                                .replace('{company_name}', _name(Config.retailer.companyDetails.names));
                        }
                    },
                    type: 'checkbox',
                    model: 'allowSendPromotions',
                    required: true
                }, {
                    titleGenerator: function() {
                        if (loginOrRegisterCtrl.termsAndConditionsText) {
                            return loginOrRegisterCtrl.termsAndConditionsText;
                        } else {
                            return $sce.getTrustedHtml(_translate('I agree to the') +
                                ' <a href="' + $state.href('app.terms-and-conditions', {loginOrRegister: null}) + '">' + _translate('Terms & Conditions') + '</a> ' +
                                _translate('and the') +
                                ' <a href="' + $state.href('app.policies', {loginOrRegister: null}) + '">' + _translate('Privacy Policy') + '</a> ' +
                                _translate('policy_end'));
                        }
                    },
                    type: 'checkbox',
                    model: 'policyApproval',
                    required: true
                }].filter(function (it) {
                    return !!it;
                }),
                buttons: []
            };

            if (isHub) {
                Array.prototype.push.apply(tab.buttons, [{
                    title: 'Next',
                    type: 'button',
                    show: function() {
                        return !!loginOrRegisterCtrl.hubData.hubRegisterPolicyText && !loginOrRegisterCtrl.showHubPolicy && loginOrRegisterCtrl.user.allowSendPromotions;
                    },
                    operation: function() {
                        if (!_validate()) {
                            return;
                        }

                        loginOrRegisterCtrl.showHubPolicy = true;
                    }
                }, {
                    title: 'Approve & Register',
                    type: 'submit',
                    show: function() {
                        return !loginOrRegisterCtrl.hubData.hubRegisterPolicyText || !loginOrRegisterCtrl.user.allowSendPromotions || loginOrRegisterCtrl.showHubPolicy;
                    }
                }, {
                    title: 'Not Approve',
                    type: 'button',
                    show: function() {
                        return loginOrRegisterCtrl.showHubPolicy && loginOrRegisterCtrl.user.allowSendPromotions;
                    },
                    operation: function() {
                        loginOrRegisterCtrl.showHubPolicy = false;
                    }
                }]);
            } else {
                tab.buttons.push({
                    title: loginOrRegisterCtrl.registerButton,
                    type: 'submit'
                });
            }

            return tab;
        }

        function _validate() {
            if (loginOrRegisterCtrl.form.$invalid) {
                var errorElem = loginOrRegisterCtrl.form.$error[Object.keys(loginOrRegisterCtrl.form.$error)[0]][0].$$element[0];
                if(errorElem.type === 'hidden') {
                    _scrollRegisterTabDown();
                } else {
                    errorElem.scrollIntoView();
                    errorElem.focus();
                }
                angular.forEach(loginOrRegisterCtrl.form.$error.required, function(field) {
                    if (field.$$attr.id === 'captcha_hidden_input') {
                        loginOrRegisterCtrl.captchaIsInvalid = true;
                    }
                });
                return false;
            } else if (loginOrRegisterCtrl.tab.validation && !loginOrRegisterCtrl.tab.validation()) {
                return false;
            }

            return true;
        }

        function onTabChanged() {
            if (loginOrRegisterCtrl.tab.isRegisterTab) {
              DataLayer.push(DataLayer.EVENTS.VIEW_SIGN_UP);
              changeView(loginOrRegisterCtrl.LoginOrRegisterScreens.DEFAULT);
            } else {
              if (loginOrRegisterCtrl.curLoginType === LOGIN_TYPE.otp) {
                showLoginWithOtp();
              } else {
                DataLayer.push(DataLayer.EVENTS.VIEW_LOGIN);
              }
            }

            loginOrRegisterCtrl.form.$submitted = false;
            registerData.isActive = loginOrRegisterCtrl.tab.title === 'Register';
        }

        function resetForm() {
            angular.forEach(loginOrRegisterCtrl.form, function(value) {
                if (typeof value === 'object' && value.hasOwnProperty('$modelValue')) {
                    // Clear the modal data bindings
                    value.$setViewValue(undefined);
                }
            });
        }

        function submit(func) {
            var promise;

            if (func) {
                promise = func();
            } else {
                if (!_validate()) {
                    return;
                }

                promise = loginOrRegisterCtrl.tab.submit();
            }

            return promise.then(function () {
                $timeout(function () {
                    Util.setLiveAlert(loginOrRegisterCtrl.tab.isRegisterTab ? 'registration completed successfully': 'you have logged in successfully');
                }, 500);
                resetForm();
                return SpDialogUrlManager.backClose();
            }).then(function () {
                var stateOnHold = localStorage.getItem('stateOnHold');
                if (stateOnHold) {
                    $state.go(stateOnHold);
                }
                return User.getUserSettings().then(function() {
                    if(!User.isVerified()) return UserVerificationDialog.show();
                });
            }).then(function (userData) {
                var retState = search.loginDialogRetState, // may be deleted in the "then"
                    retParams = {};
                // Check for selected areas on login
                if(!Config.branchAreaId && !Config.branchAreaName && userData && userData.areaId && userData.areaName) {
                    localStorage.setItem('mobileZuzBranchAreaId', userData.areaId);
                    Config.savedBranchAreaId = userData.areaId;
                    Config.branchAreaName = userData.areaName;
                }

                if(!loginOrRegisterCtrl.tab.isRegisterTab) {
                    if(!localStorage.getItem('isFirstUserLogin')){
                        $rootScope.$emit('loginForAnalyticServiceProvider');
                    }
                    //== login success
                    localStorage.setItem('isFirstUserLogin', false);

                    DataLayer.push(DataLayer.EVENTS.LOGIN);
                    SpCaptcha.formSubmitSuccess(loginOrRegisterCtrl);
                }

                if (search.loginDialogRetStateParams) {
                    try {
                        retParams = JSON.parse(search.loginDialogRetStateParams);
                    } catch(e) {}
                }

                if (retailerData.loyaltyClubDriver && retailerData.loyaltyClubDriver.isActive && retailerData.loyaltyClubDriver.clientConfig.isRegisterToClubObligation && !userData.loyaltyClubCardId) {
                    return $state.go(retailerData.loyaltyClubDriver.clientConfig.extendedLoyaltyClub ? 'app.extendedLoyaltyClub' : 'app.loyaltyClub', {showRegistrationForm: true});
                }

                if (retState) {
                    return $state.go(retState, retParams);
                } else if (retParams) {
                    return $state.go('.', retParams, { reload: false });
                }
            }).catch(function(err) {

                if(!loginOrRegisterCtrl.tab.isRegisterTab) {
                    //== login failed
                    DataLayer.push(DataLayer.EVENTS.ERROR_LOGIN, {data: {email: loginOrRegisterCtrl.user.email}});
                    SpCaptcha.formSubmitFailed(loginOrRegisterCtrl);
                } else {
                    //== register failed
                    DataLayer.push(DataLayer.EVENTS.ERROR_SIGN_UP, {data: {email: loginOrRegisterCtrl.user.email, error: err}});
                }

                loginOrRegisterCtrl.showHubPolicy = false;
                throw err;
            });
        }

        function backClose() {
            var stateOnHold = localStorage.getItem('stateOnHold');
            if(stateOnHold) {
                localStorage.setItem('stateOnHold', '');
            }

            return SpDialogUrlManager.backClose();
        }
    }

    angular.module('mobilezuz')
        .config(['$stateProvider', 'SpDialogUrlManagerProvider', 'SP_URL_DIALOG_QUERY_PARAMS', 'URL_DIALOG_DATA_QUERY_PARAMS', 'PAGE_ACCESS', config])
        .controller('LoginOrRegisterCtrl', ['$rootScope', '$window', '$state', '$timeout', '$q', '$sce', 'Config', 'Facebook',
            'Retailer', 'User', 'Cart', 'Api', 'HubService', 'SpDialogUrlManager', 'UserVerificationDialog', 'mDesign', 'Util',
            'retailerData', '$location', '$filter', 'RETAILER_STATUSES', 'LOYALTY_CLUB_DRIVERS', 'SpCaptcha', 'DataLayer', ctrl]);

    angular.module('mobilezuz')
        .filter('masked', function() {
        return function(string) {
            var firstThreeCharacters = string.slice(0, 3);
            var lastTwoCharacters = string.substr(string.length - 2, 2);
            var starts = '*'.repeat(string.length - 5);
            return firstThreeCharacters + starts + lastTwoCharacters;
        };
    })
})(angular);
