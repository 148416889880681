(function (angular) {
    var STATE_NAME = 'app.cart.checkout.process.summary';

    angular.module('mobilezuz').config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state(STATE_NAME, {
            url: '/summary',
            checkoutViewOriginalIndex: 3,
            checkoutViewIndex: 3,
            views: {
                'checkoutProcess': {
                    templateUrl: 'views/checkout/process/summary.html',
                    controller: 'CheckoutSummaryCtrl as checkoutSummaryCtrl'
                }
            }
        });
    }]).controller('CheckoutSummaryCtrl', ['$scope', '$rootScope', 'Config', 'Cart', 'ShippingTermsPopup', 'PaymentsService', 'SP_PAYMENTS', 'Util', 'DataLayer', 'SP_SERVICES',
        function ($scope, $rootScope, Config, Cart, ShippingTermsPopup, PaymentsService, SP_PAYMENTS, util, DataLayer, SP_SERVICES) {
            var checkoutProcessCtrl = $scope.checkoutProcessCtrl,
                checkoutCtrl = $scope.checkoutCtrl,
                checkoutSummaryCtrl = this,
                _listeners = [];

            if (!checkoutProcessCtrl.isPaymentLast() && !checkoutProcessCtrl.paymentDetails) {
                return checkoutProcessCtrl.goToState('app.cart.checkout.process.payment', 500);
            }

            if (checkoutProcessCtrl.isAddressFirst && (!checkoutProcessCtrl.timesDetails || !checkoutProcessCtrl.timesDetails.deliveryDay || !checkoutProcessCtrl.timesDetails.deliveryTime)) {
                return checkoutProcessCtrl.goToState('app.cart.checkout.process.times', 500);
            }

            if (!checkoutProcessCtrl.isAddressFirst && !checkoutProcessCtrl.addressDetails) {
                return checkoutProcessCtrl.goToState('app.cart.checkout.process.address', 500);
            }

            checkoutProcessCtrl.nextView = nextView;
            checkoutProcessCtrl.previousView = previousView;
            checkoutProcessCtrl.validateView = angular.noop;
            checkoutSummaryCtrl.deliveryDetailsNotEmpty = deliveryDetailsNotEmpty;

            Config.checkoutData.state = STATE_NAME;

            ShippingTermsPopup.showWithRedirect('app.cart');

            Cart.forceUpdate().then(function() {
                checkoutProcessCtrl.tipsWasRemoved = Cart.tipsWasRemoved;
                checkoutProcessCtrl.removeTipsOnPickUpText = Config.retailer.settings.removeTipsOnPickUpText ? JSON.parse(Config.retailer.settings.removeTipsOnPickUpText) : {};
            });
                // set the disable loyalty
            Cart.disableLoyalty(checkoutProcessCtrl.isNonLoyaltyCardWarning());

            if(checkoutProcessCtrl.paymentDetails){
                if (checkoutProcessCtrl.paymentDetails.paymentMethod) {
                    // recalculate cart for promotions by payment method
                    Cart.save({
                        paymentMethodId: SP_PAYMENTS.PAYMENT_METHODS.ID_BY_NAME[checkoutProcessCtrl.paymentDetails.paymentMethod],
                        source: SP_SERVICES.SOURCES.CHECKOUT_SUMMARY
                    });
                }

                var ebtEligibleData =  Cart.getEBTEligible();
                var ebt = ebtEligibleData;

                if (!ebt.entireCart) {
                    return
                }
                var taxRatio = ebt.snap.immutableWithTaxWithoutDeposit / ebt.snap.immutableWithoutTaxWithoutDeposit;

                if (!taxRatio) {
                    taxRatio = 1;
                }
                ebtEligibleData.entireCart.immutableTaxRatio = taxRatio;
                checkoutProcessCtrl.ebtCalculatedAmounts =  PaymentsService.calculateEBTAmounts(checkoutProcessCtrl.paymentDetails.ebt, ebtEligibleData);
            }

            function nextView() {
                if (!checkoutProcessCtrl.isPaymentLast()) {
                    return;
                }

                checkoutProcessCtrl.goToState('app.cart.checkout.process.payment');
            }

            function previousView() {
                if (!checkoutProcessCtrl.isPaymentLast()) {
                    return checkoutProcessCtrl.goToState('app.cart.checkout.process.payment');
                }

                checkoutProcessCtrl.goToState(checkoutProcessCtrl.isAddressFirst ? 'app.cart.checkout.process.times' : 'app.cart.checkout.process.address');
            }

            function deliveryDetailsNotEmpty() {
                return !!Object.keys(checkoutProcessCtrl.timesDetails.deliveryDay).length || !!Object.keys(checkoutProcessCtrl.timesDetails.deliveryTime).length;
            }

            function _sendDataLayerEvent() {
                var cartLineProducts = [];
                var cartLineCoupons = [];
                angular.forEach(Cart.lines, function (line) {
                    if(line && line.product && line.product.id) {
                        line.product.quantity = line.quantity;
                        cartLineProducts.push(line.product);

                        if(line.type === SP_SERVICES.CART_LINE_TYPES.COUPON) {
                            cartLineCoupons.push(line.product.localName || line.product.id);
                        }
                    }
                });
                DataLayer.push(DataLayer.EVENTS.CHECKOUT, {products: cartLineProducts, data: {step: 5, option: 'payment summary'}});


                checkoutCtrl.dataLayerCartProducts = angular.copy(cartLineProducts);
                checkoutCtrl.deliveryType = checkoutProcessCtrl.isDelivery ? 'Delivery' : 'Pickup';
                checkoutCtrl.taxAmount = parseFloat((Cart.total.tax).toFixed(2));
                checkoutCtrl.shippingCost = parseFloat((Cart.total.deliveryCost.finalPriceWithTax || Cart.total.deliveryCost.priceWithTax).toFixed(2));
                checkoutCtrl.grandTotal = parseFloat((Cart.total.finalPriceWithTax + Cart.total.serviceFee.finalPriceWithTax + Cart.total.deliveryCost.finalPriceWithTax).toFixed(2));
                checkoutCtrl.couponData = cartLineCoupons[0] || '';
            }

            _listeners.push($rootScope.$on('cart.update.complete', _sendDataLayerEvent));

            util.destroyListeners($scope, _listeners);

        }]);
})(angular);
