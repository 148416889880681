angular.module('mobilezuz').controller('LoyaltySpecialsListCtrl', [
    '$rootScope','$scope', 'Api', 'Config', 'Util', 'Retailer', 'Cart', 'Offers', 'SpecialDetailsDialog', 'Products',
    function ($rootScope, $scope, Api, Config, Util, Retailer, Cart, Offers, SpecialDetailsDialog, Products) {
        var loyaltySpecialsListCtrl = this;

        loyaltySpecialsListCtrl.sales = [];
        loyaltySpecialsListCtrl.goToOffer = goToOffer;
        loyaltySpecialsListCtrl.toggleView = toggleView;
        loyaltySpecialsListCtrl.getSales = getSales;
        loyaltySpecialsListCtrl.productsContext = Products.productsEditContext([])

        _getRetailerSettings();
        getSales();

        if ($scope.$parent.component) {
            loyaltySpecialsListCtrl.isPremiumLoyalty = true;
            loyaltySpecialsListCtrl.component = $scope.$parent.component;
        }

        function _getRetailerSettings() {
            Retailer.getRetailerSettings().then(function (setting) {
                loyaltySpecialsListCtrl.retailerSettings = setting;
            });
        }

        var _isRequesting = false,
            _total = 0;
        function getSales() {
            if (_isRequesting || _total === loyaltySpecialsListCtrl.sales.length) return;

            _isRequesting = true;

            return Offers.prepareSearchParams({
                size: 10,
                from: loyaltySpecialsListCtrl.sales.length,
                filters: {
                    must: {
                        exists: ['loyaltyClubsIds']
                    }
                }
            }).then(function(params) {
                return Offers.getOffers(params, {
                    loadingElement: document.querySelector('.loading-progress-wrapper')
                });
            }).then(function (resp) {
                _total = resp.total;
                angular.forEach(resp.specials, function (special) {
                    if (!special.item) return;

                    special.item = Cart.getProduct(special.item);
                });
                Array.prototype.push.apply(loyaltySpecialsListCtrl.sales, resp.specials);
            }).finally(function () {
                _isRequesting = false;
            });
        }

        function toggleView($event, product) {
            $event && $event.preventDefault();

            product.editState = !product.editState;
            loyaltySpecialsListCtrl.sales.forEach(function (prod) {
                if (prod.editState && prod !== product) {
                    prod.editState = false;
                }
            });
        }

        function goToOffer($event, offer) {
            $event && $event.stopPropagation();

            if(!offer){
                return;
            }

            // show single product dialog
            if (offer.item) {
                Util.goToProductDialog(offer.item, null, null, false, {source: 'Loyalty Club'});
            }

            // show multiple related products in special
            else {
                SpecialDetailsDialog.show(offer.id);
            }
        }

        $scope.$on('vertical-scroll-end', getSales);
    }
    ]);