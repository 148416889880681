angular.module('mobilezuz')
    .service('Offers', ['Config', 'Api', '$q', '$timeout', 'FiltersManager', function (Config, api, $q, $timeout, FiltersManager) {

        var self = this,
            firstSpecialsPromise;

        angular.extend(self, {
            getOffers: getOffers,
            getOffer: getOffer,
            getSpecialCategories: getSpecialCategories,
            getFilters: getFilters,
            getFirstSpecials: getFirstSpecials,
            prepareSearchParams: prepareSearchParams
        });

        function getFirstSpecials() {
            if (!firstSpecialsPromise) {
                firstSpecialsPromise = self.getOffers({
                    from: 0,
                    size: 1
                }).then(function (res) {
                    $timeout(function () {
                        firstSpecialsPromise = null;
                    }, 60 * 1000); // remove cache after 60 sec
                    return res.specials || [];
                });
            }

            return firstSpecialsPromise;
        }

        function getOffers(params, apiOptions) {
            var saleDate = new Date();
            var defaultParams = {
                filters: {
                    must: {
                        lessThan: {
                            startDate: saleDate
                        },
                        greaterThan: {
                            endDate: saleDate
                        },
                        term: {
                            displayOnWeb: true
                        }
                    }
                },
                sort: [{'priority': 'desc'}, {'sort': 'asc'}, {'id': 'desc'}]
            };

            if (!Config.showCouponsSpecials) {
                defaultParams.filters.must.term.isCoupon = false;
            }

            return api.request({
                method: 'GET',
                url: '/v2/retailers/:rid/branches/:bid/specials',
                params: angular.merge({}, defaultParams, params || {})
            }, apiOptions);
        }

        function getOffer(id, params, backgroundRequest) {
            return api.request({
                method: 'GET',
                url: '/v2/retailers/:rid/branches/:bid/specials/' + id,
                params: params
            }, backgroundRequest);
        }

        function getSpecialCategories(withCache) {
            if (withCache && self.specialCategories && self.specialCategories.length) {
                return $q.resolve(self.specialCategories);
            }
            return api.request({
                method: 'GET',
                url: '/v2/retailers/:rid/specials/categories'
            }).then(function (resp) {
                self.specialCategories = resp.specialCategories || [];
                return self.specialCategories;
            });
        }

        function getFilters(params) {
            var saleDate = new Date();
            var defaultParams = {
                filters: {
                    must: {
                        lessThan: {
                            startDate: saleDate
                        },
                        greaterThan: {
                            endDate: saleDate
                        },
                        term: {
                            displayOnWeb: true
                        }
                    }
                },
                sort: [{'priority': 'desc'}, {'id': 'desc'}]
            };
            if (!Config.showCouponsSpecials) {
                defaultParams.filters.must.term.isCoupon = false;
            }

            return api.request({
                url: '/v2/retailers/:rid/branches/:bid/specials/filters',
                params: angular.merge({}, defaultParams, params || {})
            }, {
                fireAndForgot: true
            });
        }

        function prepareSearchParams(params) {
            return FiltersManager.parsePermanentProductTags(params, true).then(function() {
                return params;
            });
        }
    }]);
