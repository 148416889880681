(function (angular) {
	var pathPrefix;

	angular.module('mobilezuz')
		.filter('innerUrl', ['$window', '$location', function($window, $location) {
			return function (url) {
				if (!$window.cordova || !url) {
					return url;
				}

				pathPrefix = pathPrefix || _getPathPrefix($location);
				var indexOfLastDot = url.lastIndexOf('.');
				var indexOfSlash = url.indexOf('/', indexOfLastDot)
				var indexOfQuestionMark = url.indexOf('?')
				var indexOfHashBang = url.indexOf('#!');

				//first section handles full urls
				//handle full url with / (i.e https://www.test.com/section?x=0)
				if (indexOfLastDot !== -1 && indexOfSlash !== -1) {
					return pathPrefix + url.substring(indexOfSlash);
				}
				//handle full url without / but with ? (i.e https://www.test.com?x=0)
				else if (indexOfLastDot !== -1 && indexOfQuestionMark !== -1) {
					return pathPrefix + '/' + url.substring(indexOfQuestionMark);
				}
				//handle full url without / and without ? (i.e https://www.test.com))
				else if (indexOfLastDot !== -1) {
					return pathPrefix;
				}
				// handle special case for `#!` prefix
				else if (indexOfHashBang === 0) {
					return pathPrefix + url.substring(2);
				}

				//second section handles relative urls
				//handle relative url that starts with / (i.e /sectionA/sectionB?x=0
				if (indexOfSlash === 0) {
					return pathPrefix + url;
				}
				//handle relative url that starts with ? (i.e ?x=0
				if (indexOfQuestionMark === 0) {
					return pathPrefix + '/' + url;
				}
				// return url to current page when no match found
				return pathPrefix + '/';
			};
		}]);

	function _getPathPrefix($location) {
		var absUrl = $location.absUrl(),
			url = $location.url();

		return absUrl.slice(0, absUrl.lastIndexOf(url));
	}
})(angular);