(function (angular) {
    angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
        $stateProvider.state('app.cart.order-update', {
            url: '/order-update',
            data: {
                stateAccess: PAGE_ACCESS.USER_VERIFIED,
                bodyClass: 'checkout',
                metaTags: {
                    title: 'Checkout'
                }
            },
            resolve: {
                order: ['Orders', function(Orders) {
                    return Orders.orderInEdit;
                }],
                updateType: ['UPDATE_ORDER_PAYMENT_TYPE', function(UPDATE_ORDER_PAYMENT_TYPE) {
                    return {
                        type: UPDATE_ORDER_PAYMENT_TYPE.EDIT_ORDER_UPDATE,
                        exitState: 'app.cart',
                        stateBase: 'app.cart.order-update'
                    };
                }]
            },
            views: {
                checkout: {
                    templateUrl: 'views/order/update/index.html',
                    controller: 'UpdateOrderCtrl as updateOrderCtrl'
                }
            }
        });

        // add a prent state to simulate the checkout_view ui-view for css to apply
        $stateProvider.state('app.user-confirmation', {
            url: '/user-confirmation',
            data: {
                // the login flow is a special one, the validation will be in the controller
                // stateAccess: PAGE_ACCESS.USER_VERIFIED,
                bodyClass: 'checkout',
                metaTags: {
                    title: 'Checkout'
                }
            },
            abstract: true,
            views: {
                '@': {
                    template: '<div id="checkout_view" ui-view="checkout" class="in-checkout"></div>'
                }
            }
        });


        $stateProvider.state('app.user-confirmation.update', {
            url: '/update/:orderId?{userConfirmationToken}',
            resolve: {
                order: ['$stateParams', 'Api', function($stateParams, Api) {
                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/orders/' + $stateParams.orderId,
                        params: {
                            userConfirmationToken: $stateParams.userConfirmationToken
                        }
                    }).catch(function() { });
                }],
                updateType: ['UPDATE_ORDER_PAYMENT_TYPE', function(UPDATE_ORDER_PAYMENT_TYPE) {
                    return {
                        type: UPDATE_ORDER_PAYMENT_TYPE.USER_CONFIRMATION_UPDATE,
                        exitState: 'app.home',
                        stateBase: 'app.user-confirmation.update'
                    };
                }]
            },
            views: {
                checkout: {
                    templateUrl: 'views/order/update/index.html',
                    controller: 'UpdateOrderCtrl as updateOrderCtrl'
                }
            }
        });
    }])
        .controller('UpdateOrderCtrl', [
            '$scope', '$rootScope', '$state', '$stateParams', '$q', 'mDesign', 'Util', 'Retailer', 'order', 'updateType',
            'CREDIT_CARD_MODES', 'CREDIT_CARD_3DS_MODES', 'SP_PAYMENTS', 'UPDATE_ORDER_PAYMENT_TYPE',
            function ($scope, $rootScope, $state, $stateParams, $q, mDesign, Util, Retailer, order, updateType,
                      CREDIT_CARD_MODES, CREDIT_CARD_3DS_MODES, SP_PAYMENTS, UPDATE_ORDER_PAYMENT_TYPE) {
                var updateOrderCtrl = this,
                    cartCtrl = $scope.cartCtrl;

                updateOrderCtrl.goToState = goToState;
                updateOrderCtrl.exit = updateType.type === UPDATE_ORDER_PAYMENT_TYPE.EDIT_ORDER_UPDATE ? exit : undefined;
                updateOrderCtrl.confirmButton = null;

                if (!order) {
                    goToState(updateType.exitState);
                    return;
                }

                if (updateType.type === UPDATE_ORDER_PAYMENT_TYPE.EDIT_ORDER_UPDATE) {
                    cartCtrl.showCheckoutView = true;
                }

				updateOrderCtrl.order = order;
                updateOrderCtrl.isRememberCreditCardsMode = true;

                Retailer.getRetailerSettings().then(function (retailerSettings) {
                    updateOrderCtrl.settings = retailerSettings;

                    var settings = Util.getBranchSettings(retailerSettings);
                    var hasCreditCardOption = (retailerSettings.checkoutPaymentMethods || []).some(function(paymentMethod) {
                        return paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD && paymentMethod.branchDeliveryTypeId === updateOrderCtrl.order.branchDeliveryTypeId;
                    });

                    updateOrderCtrl.isRememberCreditCardsMode = !hasCreditCardOption || settings.creditCardMode === CREDIT_CARD_MODES.REMEMBER_CARDS;

                    var creditCard3DSModesObject = {};
                    angular.forEach(settings && settings.creditCard3DSModes || [], function (modeId) {
                        creditCard3DSModesObject[modeId] = true;
                    });
                    updateOrderCtrl.isCreditCard3DSActive = updateOrderCtrl.isRememberCreditCardsMode &&
                        !!creditCard3DSModesObject[CREDIT_CARD_3DS_MODES.ON_PAYMENT];
                });

                if ($state.current.url.indexOf('/ebt-') === -1) {
                    goToState(updateType.stateBase + '.payment');
                }

                function exit() {
                    $state.go(updateType.exitState);
                }

                updateOrderCtrl.isLastView = function () {
                    return $state.includes(updateType.stateBase + '.summary');
                }

                updateOrderCtrl.setToolTip = function (name) {
                    updateOrderCtrl.shownToolTip = name || 'default';
                };

                updateOrderCtrl.clearToolTip = function (event) {
                    event && event.preventDefault();
                    delete updateOrderCtrl.shownToolTip;
                };

                /**
                 * Returns whether the submit button should redirect to a payment page
                 * @public
                 *
                 * @returns {boolean}
                 */
                updateOrderCtrl.isPaymentSubmitButton = function () {
                    return updateOrderCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.I_CREDIT_MASTER_PASS ||
                        updateOrderCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.BIT ||
                        (!updateOrderCtrl.isRememberCreditCardsMode && (
                            updateOrderCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD ||
                            updateOrderCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT
                        ));
                }

                function goToState(state) {
                    return $q.resolve().then(function() {
                        if ($rootScope.intiDialogClosed) {
                            return;
                        }

                        var defer = $q.defer(),
                            listener = $rootScope.$on('initDialog.closed', function() {
                                listener();
                                defer.resolve();
                            });
                        return defer.promise;
                    }).then(function() {
                        if (mDesign.activeDialog) {
                            mDesign.ignoreNextUrlChange();
                        }

                        $state.go(state);
                    });
                }
            }
        ])
})(angular);
