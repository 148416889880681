(function (angular) {
angular.module('mobilezuz')
    .directive('checkoutViewIndicator', ['$state', 'Util', function($state, Util) {
        return {
            restrict: 'E',
            scope: {
                state: '@?',
                index: '=?',
                activeIndex: '=',
                separator: '@?',
                action: '&?'
            },
            controller: ['$scope', function($scope) {
                $scope.goToState = goToState;

                function goToState() {
                    if (!$scope.state) return;

                    !!$scope.action && $scope.action();
                    $scope.$parent.checkoutProcessCtrl.goToState('app.cart.checkout.process.' + $scope.state);
                }

                function _setIndicatorIndex() {
                    $scope.indicatorIndex = angular.isDefined($scope.index) ? $scope.index : ($scope.state ? $state.get('app.cart.checkout.process.' + $scope.state).checkoutViewIndex : 0);
                }
                _setIndicatorIndex();

                Util.destroyListeners($scope, [$scope.$watch('state', _setIndicatorIndex)]);
            }],
            template: '' +
            '<div class="view-indicator" ng-class="{active: indicatorIndex == activeIndex, passed: activeIndex > indicatorIndex, separator: !!separator}" ng-click="goToState()">' +
            '   <span class="for-vertical-align"></span><!--' +
            '   --><span class="text">{{indicatorIndex+1}}</span><!--' +
            '   --><span class="passed-text"><img ng-src="{{::$root.IMAGES_PATHS.CHECKOUT_V_ICON}}" alt="v"/></span>' +
            '</div>'
        };
    }]);
})(angular);