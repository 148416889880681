(function (angular) {
angular.module('mobilezuz').controller('InstallAppCtrl', ['$scope', '$rootScope', '$window', 'Retailer', 'Util',
    function ($scope, $rootScope, $window, retailer, Util) {
        var _listeners = [];
        $scope.installMsgShown = $window.cordova || $window.localStorage.hasOwnProperty('installAppShown');

        if (!$scope.installMsgShown) {
            $scope.data = {};
            retailer.getRetailerSettings().then(function (details) {
                if (details.appIcon) {
                    $scope.data.logo = ((details.appIcon.indexOf('http://') > -1 || details.appIcon.indexOf('https://') > -1) ? '' : 'https://ecom.blob.core.windows.net/') + details.appIcon;
                } else {
                    $scope.data.logo = 'https://d226b0iufwcjmj.cloudfront.net/global/favicon.ico';
                }
                $scope.data.name = details.settings.appName;
                if ($scope.$root.bodyClass.osType === 'ios') {
                    $scope.data.downloadUrl = details.settings.appleAppId ? 'https://itunes.apple.com/us/app/apple-store/id' + details.settings.appleAppId + '?mt=8' : null;
                } else {
                    $scope.data.downloadUrl = details.settings.androidBundleId ? 'https://play.google.com/store/apps/details?id=' + details.settings.androidBundleId : null;
                }
                $scope.data.hideInstallAppBanner = details.settings.hideInstallAppBanner === 'true';
            });

            $scope.closeMsg = function () {
                $scope.data.downloadUrl = null;
                $window.localStorage.setItem('installAppShown', true);
            };

            _listeners.push($rootScope.$on('$stateChangeSuccess', function(event, toState) {
                if (toState.name !== 'app.home') {
                    $scope.data.downloadUrl = null;
                }
            }));
        }

        Util.destroyListeners($scope, _listeners);
    }]);
})(angular);
