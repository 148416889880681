(function (angular, app) {
    angular.module('mobilezuz').config([
        'SpDialogUrlManagerProvider', 'SP_URL_DIALOG_QUERY_PARAMS', 'SP_URL_DIALOG_DATA_QUERY_PARAMS',
        function(SpDialogUrlManagerProvider, SP_URL_DIALOG_QUERY_PARAMS, SP_URL_DIALOG_DATA_QUERY_PARAMS) {
            SpDialogUrlManagerProvider.dialog({
                queryParams: SP_URL_DIALOG_QUERY_PARAMS.SPECIAL,
                dataQueryParams: SP_URL_DIALOG_DATA_QUERY_PARAMS.SPECIAL,
                canShow: ['paramValue', function(paramValue) {
                    return !isNaN(paramValue);
                }],
                show: ['mDesign', 'SpDialogUrlManager', 'value', 'params', function(mDesign, SpDialogUrlManager, value, params) {
                    return mDesign.dialog({
                        controller: 'SpecialDetailsCtrl as specialDetailsCtrl',
                        templateUrl: 'views/templates/special-details.html',
                        clickOutsideToClose: true,
                        escapeToClose: false,
                        styleClass: 'special-details-dialog',
                        focusOnOpen: false,
                        locals: {
                            specialId: value,
                            showProductsFrom: params.showFrom
                        },
                        multiple: true
                    });
                }]
            });
        }
    ]);

    app.service('SpecialDetailsDialog', ['SpDialogUrlManager', function (SpDialogUrlManager) {
        this.show = show;

        /**
         * Show special details dialog
         * @public
         *
         * @param {number} specialId
         * @param {string} [showProductsFrom]
         */
        function show(specialId, showProductsFrom) {
            return SpDialogUrlManager.setDialogParams({
                special: specialId,
                showFrom: showProductsFrom
            });
        }
    }]);

    app.controller('SpecialDetailsCtrl', [
        '$rootScope', '$scope', '$window', '$timeout', '$interval', 'Api', 'Cart', 'Util', 'mDesign', 'SpDialogUrlManager', 'specialId', 'showProductsFrom', 'DataLayer',
        function ($rootScope, $scope, $window, $timeout, $interval, Api, Cart, Util, mDesign, SpDialogUrlManager, specialId, showProductsFrom, DataLayer) {
            DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Dialog', action: 'Open', label: 'Special Details Dialog'}});

            var specialDetailsCtrl = this,
                isCartUpdatedForFirstTime = false;

            specialDetailsCtrl.hide = SpDialogUrlManager.backClose;
            specialDetailsCtrl.products = {
                total: null,
                products: []
            };
            specialDetailsCtrl.gifts = {
                total: null,
                products: []
            };
            specialDetailsCtrl.itemsWrapperStyle = {};

            specialDetailsCtrl.togglePurchase = togglePurchase;
            specialDetailsCtrl.currentPurchase = 0;
            // get first chunk
            getProductsChunk();

            $timeout(_setFocusOnOpen);

            function _setFocusOnOpen() {
                var element = document.getElementById('special_details_dialog');
                element && element.focus();
            }

            function getProductsChunk() {
                if (specialDetailsCtrl.products.products.length === specialDetailsCtrl.products.total) {
                    return;
                }

                $scope.finishedLoading = false;
                if (!specialDetailsCtrl.products.currentRequestPromise) {
                    specialDetailsCtrl.products.currentRequestPromise = Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/branches/:bid/specials/' + specialId,
                        params: {
                            from: specialDetailsCtrl.products.products.length,
                            size: 20,
                            showProductsFrom: showProductsFrom
                        }
                    }).then(function (response) {
                        specialDetailsCtrl.data = response;
                        $scope.finishedLoading = true;
                        _setSpecialData();
                        _setMultiBasketsSpecialInfo();
                        _setSpecialInfo();
                        Util.setProductIndexPosition(response.products.products);
                        DataLayer.push(DataLayer.EVENTS.VIEW_PROMOTION, {promotion: {id: response.special.id, name: response.special.description, image: response.special.frontendImageUrl}, data: {type: null}});
                        DataLayer.push(DataLayer.EVENTS.VIEW_ITEM_LIST, {products: response.products.products});
                    }).finally(function () {
                        delete specialDetailsCtrl.products.currentRequestPromise;
                        if (specialDetailsCtrl.special.hasMultipleBuckets) {
                            _setItemsWrapperHeight();
                        }
                    });
                }

                return specialDetailsCtrl.products.currentRequestPromise;
            }

            function togglePurchase(index) {
                specialDetailsCtrl.currentPurchase = specialDetailsCtrl.currentPurchase === index ? null : index;
            }

            function _setSpecialData() {
                specialDetailsCtrl.special = specialDetailsCtrl.data.special;
                specialDetailsCtrl.products.total = specialDetailsCtrl.data.products.total;
                if (!specialDetailsCtrl.products.products) {
                    specialDetailsCtrl.products.products = [];
                }

                Array.prototype.push.apply(specialDetailsCtrl.products.products, Cart.getProducts(specialDetailsCtrl.data.products.products));
                if (specialDetailsCtrl.data.gifts) {
                    specialDetailsCtrl.gifts.total = specialDetailsCtrl.data.gifts.total;
                    specialDetailsCtrl.gifts.products = [];
                    Array.prototype.push.apply(specialDetailsCtrl.gifts.products, Cart.getProducts(specialDetailsCtrl.data.gifts.products));
                }
            }

            function _setSpecialInfo(skipRemindersValidation) {
                var specialProductIds = _getSpecialProductIds(specialDetailsCtrl.products.products);
                specialDetailsCtrl.quantity = 0;
                specialDetailsCtrl.total = 0;

                var lines = _getSpecialCartLines(specialProductIds);
                specialDetailsCtrl.showUnit = _showUnit(lines);
                var productsSameType = specialDetailsCtrl.showUnit || _productsSameType(lines);

                lines.forEach(function(line) {
                    if (specialProductIds.includes(line.product.id)) {
                        if (productsSameType) {
                            specialDetailsCtrl.quantity += line.quantity;
                        }
                        specialDetailsCtrl.total += line.totalPriceForView;
                    }
                });

                if (!skipRemindersValidation && Cart.specialReminders.inCart) {
                    var remainderInCart = Cart.specialReminders.inCart[specialDetailsCtrl.special.id];
                    var remainderOnProducts = Cart.specialReminders.onProducts[specialDetailsCtrl.special.id];
                    if((remainderInCart && !remainderInCart.isUsedRemainder && remainderInCart.awarded && remainderInCart.isBuyAndGetType) ||
                        (remainderOnProducts && !remainderOnProducts.isUsedRemainder && remainderOnProducts.awarded && remainderOnProducts.isBuyAndGetType)) {
                        specialDetailsCtrl.showGifts = true;
                    }
                }
            }

            function _setMultiBasketsSpecialInfo() {
                if (!specialDetailsCtrl.special.hasMultipleBuckets) {
                    return;
                }

                specialDetailsCtrl.quantity = 0;
                specialDetailsCtrl.total = 0;
                specialDetailsCtrl.isGiftAbsolute = false;
                specialDetailsCtrl.isTotalPurchaseEnoughForGift = false;

                (specialDetailsCtrl.special.levels || []).forEach(function (level) {
                    (level.gifts || []).forEach(function(gift){
                        specialDetailsCtrl.isGiftAbsolute = gift.type === 1 || specialDetailsCtrl.isGiftAbsolute;
                    });
                    (level.purchases || []).forEach(function (purchase, index) {
                        specialDetailsCtrl.specilaTotalItems += purchase.total;
                        if (purchase.esProducts && purchase.esProducts.length) {
                            var esProducts = purchase.esProducts;
                            purchase.esProducts = [];
                            Array.prototype.push.apply(purchase.esProducts, Cart.getProducts(esProducts));
                        } else {
                            specialDetailsCtrl.specialInactive = true;
                        }
                        var purchaseProductIds = _getSpecialProductIds(purchase.esProducts || []),
                            lines = _getSpecialCartLines(purchaseProductIds);

                        purchase.showUnit = _showUnit(lines);
                        var productsSameType = purchase.showUnit || _productsSameType(lines);
                        purchase.quantity = 0;
                        lines.forEach(function(line) {
                            if (purchaseProductIds.includes(line.product.id)) {
                                if (productsSameType) {
                                    purchase.quantity += line.quantity;
                                }
                                specialDetailsCtrl.total += line.totalPriceForView - (line.totalGiftsForView || 0);
                            }
                        });

                        purchase.remainder = (purchase.quantity >= 1 && purchase.quantity < purchase.total) ? purchase.total - purchase.quantity : null
                        specialDetailsCtrl.quantity += purchase.quantity;
                        specialDetailsCtrl.isTotalPurchaseEnoughForGift = (purchase.type === 1 && purchase.total <= specialDetailsCtrl.quantity) 
                                                                        || (purchase.type === 2 && purchase.total <= specialDetailsCtrl.total) || specialDetailsCtrl.isTotalPurchaseEnoughForGift;

                        if (specialDetailsCtrl.currentPurchase === index && !purchase.remainder && !!purchase.quantity) {
                            togglePurchase(level.purchases.length - 1 === index ? index : index + 1)
                        }

                    });
                });
            }

            function _showUnit(lines) {
                return lines.every(function(line) { return Cart.isUnitsWeighable(line); });
            }

            function _productsSameType(lines) {
                return lines.every(function(line) { return !line.product.isWeighable; });
            }

            function _getSpecialProductIds(products) {
                return products.map(function(product) {
                    return product.id;
                });
            }

            function _getSpecialCartLines(specialProductIds) {
                return Cart.getLines(specialDetailsCtrl.products.products).filter(function(line) {
                    return specialProductIds.includes(line.product.id);
                });
            }

            function _setItemsWrapperHeight() {
                $timeout(function () {
                    var header = document.querySelector('md-dialog.special-details-dialog > md-dialog-content > header.new-promotion'),
                        content = document.querySelector('md-dialog.special-details-dialog > md-dialog-content');

                    specialDetailsCtrl.itemsWrapperStyle.height = ((content && content.offsetHeight || 0) - ((header && header.offsetHeight || 0) + 20))  + 'px';
                    specialDetailsCtrl.itemsWrapperStyle.overflow = 'auto';
                }, 500);
            }

            $timeout(function () {
                var mdDialogContent = document.querySelector('md-dialog.special-details-dialog md-dialog-content'),
                    $mdDialogContent = angular.element(mdDialogContent);
                $mdDialogContent.bind('scroll', function (event) {
                    if (event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop < 200) {
                        getProductsChunk();
                    }
                });
            }, 0);

            Util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function () {
                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Add Items from Special Details Dialog'}});
            }));

            Util.currentScopeListener($scope, $rootScope.$on('cart.update.complete', function () {
                _setSpecialInfo(true);
                _setMultiBasketsSpecialInfo();

                if (!isCartUpdatedForFirstTime) {
                    isCartUpdatedForFirstTime = true;
                    $timeout(function () {
                        getProductsChunk();
                    }, 1000)
                }
            }));
        }
    ]);
})(angular, angular.module('mobilezuz'));
