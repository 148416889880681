angular.module('mobilezuz').directive("confirmPassword", function() {
    return {
        require: "ngModel",
        scope: {
            confirmPassword: "=confirmPassword"
        },
        link: function(scope, element, attributes, modelVal) {
            if(attributes.name === 'confirmPassword' || attributes.name === 'Confirm Password' || attributes.id === 'confirmPassword') {
                modelVal.$validators.equal = function(val) {
                    return scope.confirmPassword && val == scope.confirmPassword;
                };
    
                scope.$watch("confirmPassword", function() {
                    modelVal.$validate();
                });
            }
        }
    };
});