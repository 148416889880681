angular.module('mobilezuz').controller('DeliveryAreaAlert', ['$scope', 'mDesign', 'User', '$timeout', '$q', '$log', '$filter',
    function ($scope, mDesign, User, $timeout, $q, $log, $filter) {

        $scope.dialog = mDesign;
        $scope.selectedItem = null;
        $scope.searchText = null;
        $scope.autoFocus = true;
        $scope.noCache = true;
        $scope.querySearch = querySearch;
        $scope.searchTextChange = searchTextChange;
        $scope.selectedAreaChange = selectedAreaChange;

        $scope.areas = [];
        User.getRetailerData().then(function (retailerData) {
            retailerData.branches.forEach(function (branch) {
                (branch.areas || []).forEach(function (area) {
                    $scope.areas.push(area);
                });
            });
        });

        function querySearch(query) {
            var results = query ? $scope.areas.filter(_createFilterFor(query)) : $scope.areas,
                deferred = $q.defer();
            $timeout(function () {
                deferred.resolve(results);
            }, Math.random() * 1000, false);
            return deferred.promise;
        }

        function _createFilterFor(query) {
            var lowercaseQuery = $filter('lowercase')(query);
            return function filterFn(area) {
                return (area.name.indexOf(lowercaseQuery) === 0);
            };
        }

        function selectedAreaChange(area) {
            $scope.selectedArea = area;
        }

        function searchTextChange(text) {
            $log.log('Text changed to ' + text);
        }
    }]);