(function(angular) {
	'use strict';

	function config(spAIAnalyticServiceProvider, spMixPanelProvider) {
		spAIAnalyticServiceProvider.getLocals = ['Config', 'User', function (config, user) {
			var userLoginData = user.getUserLoginData();
			return {
				userId: (userLoginData) ? userLoginData.uid : null,
				username: (userLoginData) ? userLoginData.email : null,
				languageId: (config.retailer)? config.retailer.languageId : config.retailerLanguageId
			};

		}];

		spMixPanelProvider.getLocals = ['Config', 'User', 'LocalStorage', function (config, user, localStorageService) {
			var userLoginData = user.getUserLoginData();
			var APPROVED_COOKIES_STORAGE_KEY = 'approvedCookies';
			return {
				userId: (userLoginData) ? userLoginData.uid : null,
				username: (userLoginData) ? userLoginData.email : null,
				languageId: (config.retailer)? config.retailer.languageId : config.retailerLanguageId,
				mixPanelToken: config.retailer.settings.mixPanelProjectToken,
				approvedCookies: localStorageService.getItem(APPROVED_COOKIES_STORAGE_KEY),
				isCookieWallEnabled: config.retailer && config.retailer.isCookieWallEnabled
			};

		}];
	}

	function run($window, $q, $rootScope, Config, TrackingTransparency, spAIAnalyticService, AdobeAnalytics, spMixPanel) {
		$q.all({
			config: Config.waitForInit(),
			tracking: TrackingTransparency.waitForPermission()
		}).then(function(results) {
			if (TrackingTransparency.isTrackingAllowed) {
				_addExposeboxAnalytics();
				_addAdobeAnalytics();
				_addMixPanel();
			}

			return;
		});

		function _addExposeboxAnalytics() {
			//ToDo: should have a cookie to check upon
			spAIAnalyticService.init();
		}

		function _addAdobeAnalytics() {
			//ToDo: should have a cookie to check upon
			AdobeAnalytics.init();
		}

		function _addMixPanel() {
			//ToDo: should have a cookie to check upon
			spMixPanel.init();
		}
	}

	angular.module('mobilezuz')
		.config(['spAIAnalyticServiceProvider', 'spMixPanelProvider', config])
		.run(['$window', '$q', '$rootScope', 'Config', 'TrackingTransparency', 'spAIAnalyticService', 'AdobeAnalytics', 'spMixPanel', run])
})(angular);
