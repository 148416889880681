(function (angular) {
    'use strict';
    angular.module('mobilezuz').service('LocalStorage', ['$window',
        function ($window) {
            var self = this;

            angular.extend(self, {
                getItem: getItem,
                setItem: setItem
            });

            function getItem(key) {
                var data = $window.localStorage.getItem(key);
                try {
                    return JSON.parse(data);
                }
                catch(err) {
                    return data;
                }
            }

            function setItem(key, value) {
                $window.localStorage.setItem(key, value);
            }
        }]);

})(angular);
