angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.links', {
        url: '/links/:linkId?suffix',
        data: {
            stateAccess: PAGE_ACCESS.ALLOW,
            bodyClass: 'link'
        },
        views: {
            '@': {
                templateUrl: 'views/link.html',
                controller: 'LinksCtrl as linksCtrl'
            }
        }
    });

}]).controller('LinksCtrl', ['$sce', '$state', '$stateParams', 'Retailer', function ($sce, $state, $stateParams, Retailer) {
        var linksCtrl = this;

        Retailer.getRetailerSettings().then(function (configuration) {
            if ($stateParams.linkId) {
                angular.forEach(configuration.toolbar, function (link) {
                    if (Number($stateParams.linkId) === link.id) {
                        var url = link.url;
                        if (url[url.length - 1] === '/') {
                            url = url.substring(0, url.length - 1);
                        }

                        var suffix = $stateParams.suffix || '';
                        if (suffix && suffix[0] === '/') {
                            suffix = suffix.substring(1);
                        }

                        linksCtrl.src = $sce.trustAsResourceUrl(url + (suffix ? '/' + suffix : ''));

                        var extension = linksCtrl.src.toString().split('.').pop().split('?')[0];
                        linksCtrl.isImage = extension === 'png' || extension === 'jpg' || extension === 'jpeg';
                    }
                });
            }

            if (!linksCtrl.src) {
                $state.go('app.home');
                console.error('Redirected to home page, link ' + $stateParams.linkId + ' does not exist');
            }
        });
    }]);