(function (angular) {
    'use strict';

    angular.module('mobilezuz').directive('payment', [
        'User', 'Config', 'Cart', 'Api', 'PaymentsService', 'Util', 'CHECKOUT_METHODS', 'DELIVERY_TYPES', 'CREDIT_CARD_MODES', 'SP_PAYMENTS',
        'mDesign', '$sce', '$q', '$timeout', '$window', 'ORGANIZATION_TYPES', 'Orders', '$filter',
        function (User, Config, Cart, Api, PaymentsService, Util, CHECKOUT_METHODS, DELIVERY_TYPES, CREDIT_CARD_MODES, SP_PAYMENTS,
                  mDesign, $sce, $q, $timeout, $window, ORGANIZATION_TYPES, Orders, $filter) {
            return {
                restrict: 'E',
                replace: true,
                transclude: true,
                scope: {
                    user: '=',
                    settings: '=',
                    deliveryDate: '=',
                    deliveryTypeId: '=',
                    paymentDetails: '=',
                    addressDetails: '=',
                    ebtCalculatedAmounts: '=?',
                    hasLoyaltyCreditCards: '=',
                    listener: '@'
                },
                templateUrl: 'views/templates/payment.html',
                controller: function ($scope, $rootScope) {
                    var userLoginData = User.getUserLoginData(),
                        checkoutMethod = CHECKOUT_METHODS.BY_DELIVERY_TYPE[$scope.deliveryTypeId],
                        _listeners = [],
                        _roundCurrencyFilter = $filter('roundCurrency'),
                        LOYALTY_PREMIUM_PACKAGE = 16;

                    $scope.checkoutPaymentMethods = [];
                    $scope.primaryPayments = [];
                    $scope.secondaryPayments = [];
                    $scope.creditCards = [];
                    $scope.ebtCards = [];
                    $scope.paypalAccounts = [];
                    $scope.shownToolTip = undefined;
                    $scope.isPickAndGo = checkoutMethod === CHECKOUT_METHODS.VALUES.PICK_AND_GO;
                    $scope.isDelivery = checkoutMethod === CHECKOUT_METHODS.VALUES.DELIVERY || checkoutMethod === CHECKOUT_METHODS.VALUES.PICK_AND_GO;
                    $scope.orderInEdit = Orders.orderInEdit;
                    $scope.tavPlusSeeMore = false;
                    $scope.compiledUserAddress = Util.compiledUserAddress($scope.addressDetails);
                    $scope.creditCardDisplay = Config.retailer.settings.creditCardDisplay ? JSON.parse(Config.retailer.settings.creditCardDisplay) : {}
                    $scope.giftCardDisplay = Config.retailer.settings.giftCardDisplay ? JSON.parse(Config.retailer.settings.giftCardDisplay) : {}
                    $scope.removeGiftCard = removeGiftCard;
                    _setGiftCardPopupSettings();

                    $scope.requiredCvvGiftCards = [SP_PAYMENTS.PAYMENT_METHODS.IDS.NOFSHONIT];
                    $scope.paymentDetails.isCvvShownForCheckout = typeof(Config.retailer.settings.isCvvShownForCheckout) === 'undefined' || Config.retailer.settings.isCvvShownForCheckout === 'true';

                    $scope.paymentMethodChanged = function (method) {

                      if (method.name === 'CreditCard') {
                        $scope.paymentDetails.ebt = {}
                        $scope.cartEBTEligibleData = $scope.recalculate($scope.paymentDetails.ebt, $scope.cartEBTEligibleData)
                        if($scope.cartEBTEligibleData){
                            $scope.ebtCalculatedAmounts = PaymentsService.calculateEBTAmounts(
                            $scope.paymentDetails.ebt, $scope.cartEBTEligibleData);
                        }

                      }

                      if(method.isStandAlone && method.name !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT) {
                          _resetGiftCards();
                      }

                      $scope.paymentMethodModel = method;
                      $scope.paymentDetails.paymentMethod = $scope.paymentMethodModel.name;
                      if(!$scope.paymentDetails.paymentMethod.paymentMethodId) {
                          $scope.paymentDetails.paymentMethod.paymentMethodId = $scope.paymentMethodModel.paymentMethodId;
                      }

                      $scope.paymentDetails.isStandAlonePaymentMethod = method.isStandAlone;
                    }

                    function _resetGiftCards () {
                        $scope.paymentDetails.totalGiftCards = 0;
                        $scope.paymentDetails.giftCards = {};
                        $scope.paymentDetails.redeemedGiftCardIds = [];
                    }

                    $scope.onEBTAmountChange = function () {
                      $scope.cartEBTEligibleData = $scope.recalculate($scope.paymentDetails.ebt,$scope.cartEBTEligibleData)
                      $scope.ebtCalculatedAmounts = PaymentsService.calculateEBTAmounts(
                        $scope.paymentDetails.ebt, $scope.cartEBTEligibleData);
                      $rootScope.safeFallBackEBTCalculatedAmounts = $scope.ebtCalculatedAmounts;

                    }

                    $scope.createCashSnapTaxRatio = function () {
                      var ebt = $scope.cartEBTEligibleData;
                      if (!ebt.entireCart) {
                        return
                      }
                      var taxRatio = ebt.snap.immutableWithTaxWithoutDeposit / ebt.snap.immutableWithoutTaxWithoutDeposit;

                      if (!taxRatio) {
                        taxRatio = 1;
                      }
                      $scope.cartEBTEligibleData.entireCart.immutableTaxRatio = taxRatio;
                      return
                    }

                    $scope.recalculate = function(ebt, ebtEligibleData) {
                        if(ebtEligibleData){
                            var fsAmount = (ebt && ebt.fsAmount) ? ebt.fsAmount : 0,
                                cbAmount = (ebt && ebt.cbAmount) ? ebt.cbAmount : 0,
                                deltaFsLeft = _roundCurrencyFilter(cbAmount - (ebtEligibleData.cash.immutableWithTax - ebtEligibleData.snap.immutableWithoutTax));
                            var cashEligibleCalculation =   _roundCurrencyFilter(ebtEligibleData.cash.immutableWithTax -   (fsAmount *  ebtEligibleData.entireCart.immutableTaxRatio));
                            if (cashEligibleCalculation < 0){
                                cashEligibleCalculation = 0;
                            }
                            ebtEligibleData.cash.eligibleWithoutTax = ebtEligibleData.cash.totalWithoutTax =  _roundCurrencyFilter(ebtEligibleData.cash.immutableWithoutTax - fsAmount);
                            ebtEligibleData.cash.eligibleWithTax = ebtEligibleData.cash.totalWithTax =  cashEligibleCalculation;
                            var snapTotalEligible = _roundCurrencyFilter(ebtEligibleData.snap.immutableWithoutTax  -  deltaFsLeft)
                            if(deltaFsLeft > 0){
                                ebtEligibleData.snap.eligibleWithoutTax =
                                    ebtEligibleData.snap.totalWithoutTax = _roundCurrencyFilter(ebtEligibleData.snap.immutableWithoutTax -  deltaFsLeft);
                                ebtEligibleData.snap.totalWithTax = ebtEligibleData.snap.eligibleWithTax  = _roundCurrencyFilter(ebtEligibleData.snap.immutableWithTax - deltaFsLeft);
                            }
                            else {
                                ebtEligibleData.snap.eligibleWithoutTax = ebtEligibleData.snap.totalWithoutTax = ebtEligibleData.snap.immutableWithoutTax;
                                ebtEligibleData.snap.totalWithTax = ebtEligibleData.snap.eligibleWithTax =  ebtEligibleData.snap.immutableWithTax;
                            }
                            if(snapTotalEligible < 0){
                                ebtEligibleData.snap.eligibleWithoutTax = ebtEligibleData.snap.totalWithoutTax = ebtEligibleData.snap.totalWithTax = 0;
                            }
                        }

                      return ebtEligibleData
                    }

                    $scope.chooseCreditCard = function (creditCard) {
                        $scope.paymentDetails.creditCard = creditCard;
                        $scope.paymentDetails.cvv = "";
                    }

                    $scope.showWeightDebitDialog = function () {
                        return mDesign.dialog({
                            focusOnOpen: false,
                            clickOutsideToClose: false,
                            templateUrl: 'views/templates/ebt-weight-debit-disclaimer-dialog.html',
                            controller: ['$scope', function ($scope) {
                                $scope.hide = mDesign.hide;
                            }]
                        });
                    }

                    /**
                     * Add a new tav plus card
                     */
                    $scope.addTavPlusCard = function (tavPlusPaymentMethodId) {
                        if (tavPlusPaymentMethodId) {
                            return PaymentsService.addGiftCard(tavPlusPaymentMethodId, {})
                              .then(function (tavPlusCard) {
                                  if (!tavPlusCard) {
                                      return;
                                  }
                                  if (tavPlusCard.error || !tavPlusCard.token) {
                                      if (tavPlusCard.error && tavPlusCard.error.status === 403 && tavPlusCard.error.data && tavPlusCard.error.data.error === 'userIsBlocked') {
                                          return _showTavPlusErrorDialog('Multiple attempts', 'Due multiple unsuccessful attempts ,the process cannot be completed', 'OK');
                                      }

                                      return _showTavPlusErrorDialog('An error occurred while processing the voucher', 'Try again or check your voucher details', 'TRY AGAIN');
                                  }

                                  if (!tavPlusCard.balance || tavPlusCard.balance == 0) {
                                      return _showTavPlusErrorDialog('Your voucher has been fully utilized', 'Try another voucher number or choose a different payment method', 'OK');
                                  }
                                  $scope.paymentDetails.tavPlusCard = tavPlusCard;
                                  var total = Cart.total.finalPriceWithTax + Cart.total.serviceFee.finalPriceWithTax + Cart.total.deliveryCost.finalPriceWithTax,
                                    amount = _roundCurrencyFilter($scope.paymentDetails.tavPlusCard.balance < total ? $scope.paymentDetails.tavPlusCard.balance : total);
                                  $scope.paymentDetails.tavPlusCard.amount = amount;
                                  $scope.paymentDetails.tavPlusMaxAmount= amount;
                              }).catch(function (err) {
                                  return _showTavPlusErrorDialog('An error occurred while processing the voucher', 'Try again or check your voucher details', 'TRY AGAIN');
                              });
                        }

                    }

                    $scope.onTavPlusAmountChange = function () {
                        if ($scope.paymentDetails.tavPlusCard.amount) {
                            $scope.paymentDetails.tavPlusCard.amount = Number($scope.paymentDetails.tavPlusCard.amount.toFixed(2));
                        }
                    }

                    $scope.openTavPlusExampleDialog = function () {
                        return mDesign.dialog({
                            focusOnOpen: false,
                            clickOutsideToClose: true,
                            controller: ['$scope', function (scope) {}],
                            templateUrl: 'views/templates/tav-plus-example-dialog.html'
                        });
                    }

                    $scope.onTavPlusSeeMore = function () {
                        $scope.tavPlusSeeMore = true;
                    }

                    function _showTavPlusErrorDialog(title, desc, buttonText) {
                        return mDesign.dialog({
                            focusOnOpen: false,
                            clickOutsideToClose: true,
                            template: '<div class="tav-plus-error-dialog">' +
                                '<h4 class="title">{{title | translate}}</h4> ' +
                                '<div class="desc">{{desc | translate}}</div>' +
                                '<div class="contact-phone">{{(\'Customer service\' | translate) + \': \' + contactPhone}}</div>' +
                                '<button ng-if="buttonText" ng-click="hide()">{{buttonText | translate}}</button>' +
                                '</div>',
                            controller: ['$scope', function (scope) {
                                scope.title = title;
                                scope.desc = desc;
                                scope.contactPhone = Config.retailer.contactPhone;
                                scope.buttonText = buttonText;
                                scope.hide = mDesign.hide;
                            }]
                        });
                    }

                    /**
                     * Add a new EBT card
                     * @param {boolean} checkBalance - check the balance after adding the card
                     */
                    $scope.addEBTCard = function (checkBalance) {
                        return PaymentsService.addEBTCard().then(function () {
                            return User.getUserSettings(true);
                        }).then(function (user) {
                            if (user) {
                                $timeout(function () {
                                    _initEBTCards(user.ebtCards);
                                });
                            }
                        });
                    }

                    $scope.addNewCard = function () {
                        if(Config.retailer.settings.useDeliveryAddressAsBilling && $scope.compiledUserAddress) {
                            return mDesign.dialog({
                                templateUrl: 'views/templates/use-delivery-address-as-billing.html',
                                controller: ['$scope', function ($dialogScope) {
                                    var dialogCtrl = this,
                                    userAddress = $scope.addressDetails || $scope.user.addresses[0];
                                    var userCountryName = userAddress && userAddress.country;
                                    if (userCountryName) {
                                        userAddress.countryCode = Util.getCountryCode(userCountryName);
                                    }
                                    dialogCtrl.sendUserAddress = false;
                                    dialogCtrl.compiledUserAddress = $scope.compiledUserAddress;
                                    dialogCtrl.cancel = cancel;
                                    $dialogScope.continue = function () {
                                        mDesign.hide();
                                        return PaymentsService.addCreditCard({
                                            sendUserAddress: dialogCtrl.sendUserAddress,
                                            address: userAddress.text1 ? userAddress.text1 : userAddress.text2 ? userAddress.text2 : '',
                                            city: userAddress.city ? userAddress.city : '',
                                            country: userAddress.country ? userAddress.country : '',
                                            countryCode: userAddress.countryCode ? userAddress.countryCode : '',
                                        }).then(function() {
                                            setTimeout(function() {
                                                User.getUserSettings(true)
                                                    .then(function (user) {
                                                        _initCreditCards(user.creditCards);
                                                    })
                                            }, 1000);
                                        });
                                    };
                                    function cancel() {
                                        mDesign.hide();
                                    };
                                }],
                                controllerAs: 'dialogCtrl',
                                styleClass: 'clear-cart-popup'
                            });
                        } else {
                            PaymentsService.addCreditCard().then(function () {
                                return User.getUserSettings(true);
                            }).then(function (user) {
                                if (user) {
                                    $timeout(function () {
                                        _initCreditCards(user.creditCards);
                                    });
                                }
                            });
                        }
                    }

                    $scope.removeCard = function (cardId) {
                        if (!cardId) {
                            return;
                        }

                        return new $q(function (resolve, reject) {
                            mDesign.confirm({
                                content: 'Are you sure?',
                                ok: 'Remove card',
                                cancel: 'Cancel'
                            }, function (isTrue) {
                                if (isTrue) {
                                    resolve();
                                } else {
                                    reject('Canceled');
                                }
                            });
                        }).then(function () {
                            return Api.request({
                                method: 'DELETE',
                                url: 'retailers/:rid/users/:uid/removecard',
                                headers: {
                                    'content-type': 'application/json'
                                },
                                data: {
                                    cardId: cardId
                                }
                            });
                        }).then(function () {
                            return User.getUserSettings(true);
                        }).then(function (response) {
                            $timeout(function () {
                                _initCreditCards(response.creditCards);
                                _initEBTCards(response.ebtCards);
                            });
                        });
                    }

                    $scope.setToolTip = function (name) {
                        $scope.shownToolTip = name || 'default';
                    }

                    $scope.clearToolTip = function (event) {
                        event && event.preventDefault();
                        $scope.shownToolTip = undefined;
                    }

                    $scope.openPaypalWindow = function () {
                        PaymentsService.getPaymentUrl(SP_PAYMENTS.PAYMENT_METHODS.IDS.PAYPAL).then(function (url) {
                            Util.listenForInAppMessage($window.open(url, '_blank'));
                        });
                    }

                    $scope.addPromoCode = function () {
                        if (!$scope.promoCode) {
                            return;
                        }

                        return Api.request({
                            method: 'GET',
                            url: '/v2/retailers/' + Config.retailerId + '/promotions/_query',
                            params: {
                                promoCode: $scope.promoCode,
                                userId: userLoginData.uid
                            },
                        }, {
                            fireAndForgot: true
                        }).then(function (res) {
                            $timeout(function () {
                                $scope.promoCodeErr = undefined;
                                $scope.paymentDetails.invitePromotion = {};
                                if (res.promotion.names) {
                                    $scope.paymentDetails.invitePromotion.description = res.promotion.names;
                                }

                                $scope.paymentDetails.invitePromotion.promoCode = $scope.promoCode;
                                $scope.promoCode = undefined;
                                $scope.paymentDetails.invitePromotion.promotionId = res.promotion.promotionId;
                                $scope.paymentDetails.invitePromotion.inviteId = res.promotion.inviteId;
                            })
                        }).catch(function (err) {
                            $timeout(function () {
                                $scope.paymentDetails.invitePromotion = undefined;
                                $scope.promoCodeErr = err.data.error || 'Promo code unidentified';
                            });
                        });
                    }

                    /**
                     * INIT ALL BASIC PROPERTIES
                     */
                    _init();

                    function _init() {
                        if($scope.paymentDetails.totalGiftCards && ($rootScope.cart.total.finalPriceWithTax - $scope.paymentDetails.totalGiftCards) < 0) {
                            _resetGiftCards();
                        }

                        _initCreditCards($scope.user.creditCards);
                        _initEBTCards($scope.user.ebtCards);
                        _initPaypalAccounts($scope.user.paypalAccounts);

                        if($scope.paymentDetails.redeemedGiftCardIds && $scope.paymentDetails.redeemedGiftCardIds.length) {
                            $scope.giftCardsSelected = [];
                            angular.forEach($scope.paymentDetails.redeemedGiftCardIds, function (giftCardId) {
                                $scope.paymentDetails.totalGiftCards += $scope.paymentDetails.giftCards[giftCardId].amount;
                                var selectedGiftCard = angular.copy($scope.paymentDetails.giftCards[giftCardId]);
                                selectedGiftCard.name =Config.retailer.settings.giftCardNamesSettings[giftCardId][$rootScope.config.language.id];
                                selectedGiftCard.lastFourDigits = selectedGiftCard.cardNumber.slice(-4);
                                $scope.giftCardsSelected.push(selectedGiftCard);
                            });
                        }

                        // PaymentsService.getPaymentUrl(SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD, {
                        //     deliveryDate: $scope.deliveryDate
                        // }).then(function (paymentUrl) {
                        //     $timeout(function () {
                        //         $scope.paymentIFrameUrl = $sce.trustAsResourceUrl(paymentUrl);
                        //     });
                        // });

                        var branchDeliveryTypeIdMap = {};
                        angular.forEach(CHECKOUT_METHODS.BY_DELIVERY_TYPE, function (method, key) {
                            if (method === checkoutMethod) {
                                branchDeliveryTypeIdMap[key] = true;
                            }
                        });
                        var hasCreditCardOption = ($scope.settings.checkoutPaymentMethods || []).some(function (paymentMethod) {
                            return paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD && branchDeliveryTypeIdMap[paymentMethod.branchDeliveryTypeId];
                        });
                        var settings = Util.getBranchSettings($scope.settings);

                        $scope.hasPaypalOption = ($scope.settings.checkoutPaymentMethods || []).some(function (paymentMethod) {
                            return paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.PAYPAL && branchDeliveryTypeIdMap[paymentMethod.branchDeliveryTypeId];
                        });
                        $scope.isRememberCreditCardsMode = !hasCreditCardOption || settings.creditCardMode === CREDIT_CARD_MODES.REMEMBER_CARDS;
                        $scope.paymentDetails.storeCredit = $scope.paymentDetails.storeCredit || $scope.user.storeCreditAccountNumber;
                        $scope.paymentDetails.paypalAccount = $scope.paymentDetails.paypalAccount || $scope.user.paypalAccount;
                        $scope.userOrganization = $scope.user.organization;

                        _addPaypalListener();
                        if (!$scope.userOrganization || $scope.userOrganization.organizationType === ORGANIZATION_TYPES.NO_OBLIGO) {
                            $scope.promotion = $scope.settings.promotion;

                            $scope.checkoutPaymentMethods = _sortPaymentMethods($scope.settings.checkoutPaymentMethods);
                            $scope.secondaryPayments = [];
                            for (var i = 0; i < $scope.checkoutPaymentMethods.length; i++) {
                                var method = angular.copy($scope.checkoutPaymentMethods[i]);
                                if (!method.isPrimary) {
                                    if ($scope.requiredCvvGiftCards.includes(method.paymentMethodId)) {
                                        method.isCvvRequired = true;
                                    }
                                    $scope.secondaryPayments.push(method);
                                }  
                            }

                            $scope.primaryPayments = $scope.checkoutPaymentMethods.filter(function (method) {
                                return method.isPrimary;
                            });
                            $scope.paymentsNumber = settings.paymentsNumber;
                            $scope.paymentDetails.paymentMethod = $scope.paymentDetails.paymentMethod ||
                                ($scope.checkoutPaymentMethods.length && $scope.checkoutPaymentMethods[0].name);
                            $scope.paymentDetails.paymentsNumber = $scope.paymentDetails.paymentsNumber || 1;

                            $scope.paymentMethodModel = $scope.checkoutPaymentMethods.find(function (paymentMethod) {
                                return paymentMethod.name === $scope.paymentDetails.paymentMethod;
                            });

                            _setEBTEligible();
                            _setRemainingEbtPayment();
                            _doACheckForEbt()
                        } else {
                            $scope.checkoutPaymentMethods = [{name: SP_PAYMENTS.PAYMENT_METHODS.NAMES.ORGANIZATION}];
                            $scope.paymentMethodModel = $scope.checkoutPaymentMethods[0];
                            $scope.paymentDetails.paymentMethod = $scope.paymentMethodModel.name;
                        }

                        $scope.paymentDetails.isLoyaltyPackageEnabled = Config.retailer.premiumFeaturesEnabled.includes(LOYALTY_PREMIUM_PACKAGE);
                        $scope.paymentDetails.userCashbackLoyalty = Util.findUserCashbackLoyaltyClub()
                        if($scope.paymentDetails.userCashbackLoyalty) {
                            $scope.paymentDetails.isRedeemedNow = false;
                            _setCashbackLoyaltyClub($scope.paymentDetails.userCashbackLoyalty.loyaltyClubId);
                        }
                        if($scope.paymentDetails.activeCashbackLoyalty) {
                            Util.getUserCashbackPoints($scope.paymentDetails.userCashbackLoyalty.id, $scope.paymentDetails.activeCashbackLoyalty.pointsDecimalRound).then(function (response) {
                                $scope.paymentDetails.userCashbackLoyalty.points = response.points || 0;
                                $scope.paymentDetails.userCashbackLoyalty.pointsToken = response.token;


                                if(!$scope.paymentDetails.activeCashbackLoyalty.isMinPointsRequired || ($scope.paymentDetails.activeCashbackLoyalty.isMinPointsRequired && $scope.paymentDetails.userCashbackLoyalty.points &&
                                    $scope.paymentDetails.userCashbackLoyalty.points >= $scope.paymentDetails.activeCashbackLoyalty.minPointsNum)) {
                                    $scope.paymentDetails.userPointsToCashback = ($scope.paymentDetails.userCashbackLoyalty.points / $scope.paymentDetails.activeCashbackLoyalty.pointsToCashbackAmount) *
                                        $scope.paymentDetails.activeCashbackLoyalty.cashbackAmount;
                                }
                                $scope.paymentDetails.precentsPointsOutOfMin = $scope.paymentDetails.activeCashbackLoyalty.isMinPointsRequired && $scope.paymentDetails.userCashbackLoyalty.points &&
                                $scope.paymentDetails.userCashbackLoyalty.points < $scope.paymentDetails.activeCashbackLoyalty.minPointsNum ? $scope.paymentDetails.userCashbackLoyalty.points / $scope.paymentDetails.activeCashbackLoyalty.minPointsNum * 100 : 0;
                                $rootScope.isUsedCashbackPoints = $scope.paymentDetails.activeCashbackLoyalty && $scope.paymentDetails.activeCashbackLoyalty.pointsToCashback && $scope.paymentDetails.userCashbackLoyalty.redeemedPointsAmount &&
                                    $scope.paymentDetails.isLoyaltyPackageEnabled && (!$scope.paymentDetails.activeCashbackLoyalty.isMinPointsRequired ||
                                        ($scope.paymentDetails.activeCashbackLoyalty.isMinPointsRequired && $scope.paymentDetails.userCashbackLoyalty.points >= $scope.paymentDetails.activeCashbackLoyalty.minPointsNum));

                                _handlePointsCalculation()
                            });
                        }
                    }


                    function _handlePointsCalculation() {
                      if (!$scope.paymentDetails.activeCashbackLoyalty) {
                        return;
                      }

                      $scope.totalPriceForPointsCalculation =
                        $rootScope.cart.total.finalPriceWithTax;

                      if ($scope.paymentDetails.userPointsToCashback) {
                        $scope.paymentDetails.userCashbackLoyalty.availableUserCashback =
                          $scope.paymentDetails.userPointsToCashback >
                          $scope.totalPriceForPointsCalculation
                            ? $scope.totalPriceForPointsCalculation
                            : $scope.paymentDetails.userPointsToCashback;

                        if ($scope.paymentDetails.totalGiftCards) {
                          $scope.paymentDetails.userCashbackLoyalty.availableUserCashback -=
                            $scope.paymentDetails.totalGiftCards;
                        }
                      }

                      if ($rootScope.isUsedCashbackPoints) {
                        $scope.totalPriceForPointsCalculation -=
                          $scope.paymentDetails.userCashbackLoyalty.availableUserCashback || 0;
                      }

                      _calculateUserPoints();
                    }

                    function _doACheckForEbt() {
                      if($scope.cartEBTEligibleData && $scope.cartEBTEligibleData.cash && $scope.cartEBTEligibleData.snap && $scope.cartEBTEligibleData.cash.totalWithTax === 0 && $scope.cartEBTEligibleData.snap.totalWithTax === 0){
                        var filteredMethods = $scope.checkoutPaymentMethods.filter(function(method){ return method.name !== 'EBT'});
                        $scope.checkoutPaymentMethods = filteredMethods;
                      }
                      var allowedUsers = Config.retailer.settings.eBTAllowedUsers ? JSON.parse(Config.retailer.settings.eBTAllowedUsers) : [];
                      if(allowedUsers && allowedUsers.length && !allowedUsers.includes($scope.user.id)) {
                        var filteredMethods =  $scope.checkoutPaymentMethods.filter(function (method) { return method.name !== 'EBT' });
                        $scope.checkoutPaymentMethods = filteredMethods;
                      }
                      if (Config.retailer.settings.eBTBranchSettings) {
                        var settings = JSON.parse(Config.retailer.settings.eBTBranchSettings);
                        var shallWeKeepEBTOption = false;
                          if (settings[Config.branch.id]) {
                              Object.values(settings[Config.branch.id]).forEach(function (value) {
                                  if (typeof (value) === 'boolean' && value) {
                                      shallWeKeepEBTOption = true;
                                  }
                              });
                              if (!shallWeKeepEBTOption) {
                                  var filteredMethods = $scope.checkoutPaymentMethods.filter(function (method) {
                                      return method.name !== 'EBT'
                                  });
                                  $scope.checkoutPaymentMethods = filteredMethods;
                              }
                          }
                      }

                        // After switched to Primary and Secondary payment methods we need to exclude EBT from primaryPayments if not present in checkoutPaymentMethods
                        var isEbtVisible = false;
                        if($scope.checkoutPaymentMethods && Array.isArray($scope.checkoutPaymentMethods)) {
                          $scope.checkoutPaymentMethods.forEach(function (paymentMethod) {
                              if (paymentMethod.name === 'EBT') {
                                  isEbtVisible = true;
                              }
                          });
                        }

                        if(!isEbtVisible) {
                            $scope.primaryPayments = $scope.primaryPayments.filter(function (method) {
                                return method.name !== 'EBT';
                            });
                        }
                    }

                    function _setRemainingEbtPayment(){
                        var remainingEbtPayment = Cart.getRemainingPayment($scope.orderInEdit)
                        var isSnapRefund = remainingEbtPayment.snap < -1;
                        var isCashRefund = remainingEbtPayment.cash < -1;
                        $scope.useEbtBalance = {
                            useCash: isCashRefund,
                            disableCash: isCashRefund,
                            useSnap: isSnapRefund,
                            disableSnap: isSnapRefund,
                        }
                        $scope.remainingEbtPayment = remainingEbtPayment.ebt;
                    }

                    function _initCreditCards(userCreditCards) {
                        $scope.creditCards = userCreditCards || [];

                        var chosenCard;
                        if ($scope.paymentDetails.creditCard) {
                            chosenCard = $scope.creditCards.find(function (card) {
                                return $scope.paymentDetails.creditCard.id === card.id && card.isActive;
                            });
                        }
                        var defaultCard = $scope.creditCards.find(function (card) {
                            return card.isDefault && card.isActive;
                        });
                        var activeCard = $scope.creditCards.find(function (card) {
                            return card.isActive;
                        });
                        var loyaltyCreditCard = $scope.creditCards.find(function (card) {
                            return card.isLoyalty;
                        });

                        $scope.paymentDetails.creditCard = chosenCard || loyaltyCreditCard || defaultCard || activeCard;
                        $scope.hasLoyaltyCreditCards = !!loyaltyCreditCard;
                    }

                    function _initEBTCards(userEBTCards) {
                        $scope.ebtCards = userEBTCards || [];
                        $scope.paymentDetails.ebtCard = $scope.ebtCards[0];
                    }

                    function _initPaypalAccounts(userPaypalAccounts) {
                        $scope.paypalAccounts = angular.filterCollection(userPaypalAccounts || [], function (x) {
                            return x.billingAgreement || x.payerId === $scope.paymentDetails.paypalPayerId;
                        });

                        var defaultPaypalAccount = $scope.paypalAccounts.filter(function (paypalAccount) {
                            return paypalAccount.isDefault;
                        });
                        $scope.paymentDetails.paypalAccount = defaultPaypalAccount || $scope.paypalAccounts[0];
                    }

                    function _sortPaymentMethods(paymentMethods) {
                        if (!paymentMethods) {
                            return $scope.checkoutPaymentMethods || [];
                        }

                        var newArray = [],
                            creditCard,
                            ebt;

                        angular.forEach(paymentMethods, function (paymentMethod) {
                            if (paymentMethod.branchDeliveryTypeId !== $scope.deliveryTypeId) {
                                return;
                            }

                            if (paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD) {
                                creditCard = paymentMethod;
                            } else if (paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT) {
                                ebt = paymentMethod;
                            } else {
                                newArray.push(paymentMethod);
                            }
                        });

                        if (ebt) {
                            newArray.unshift(ebt);
                        }
                        if (creditCard) {
                            newArray.unshift(creditCard);
                        }

                        return newArray;
                    }

                    function _setEBTEligible() {
                        if (!$scope.checkoutPaymentMethods) {
                            return;
                        }

                        var ebtPaymentMethod = $scope.checkoutPaymentMethods.find(function (method) {
                            return method.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT;
                        });
                        if (!ebtPaymentMethod) {
                            return;
                        }

                        $scope.cartEBTEligibleData = Cart.getEBTEligible();
                        $scope.createCashSnapTaxRatio()
                        $scope.onEBTAmountChange();
                    }

                    function removeGiftCard(cardId, index) {
                        if($scope.paymentDetails.giftCards && $scope.paymentDetails.giftCards[cardId]) {
                            delete $scope.paymentDetails.giftCards[cardId];
                            $scope.giftCardsSelected.splice(index, 1);
                            $scope.paymentDetails.totalGiftCards = 0;
                            $scope.paymentDetails.redeemedGiftCardIds = Object.keys($scope.paymentDetails.giftCards);
                            angular.forEach($scope.paymentDetails.redeemedGiftCardIds, function (giftCardId) {
                                $scope.paymentDetails.totalGiftCards += $scope.paymentDetails.giftCards[giftCardId].amount;
                            });
                        }

                    }

                    function _getEBTTransaction() {
                        if (!$scope.paymentDetails.ebtCard) {
                            return $q.reject('no ebt card');
                        }

                        var address = {};
                        if ($scope.isDelivery) {
                            address.addressText = $scope.addressDetails.text1;
                            address.city = $scope.addressDetails.city;
                            address.zipCode = $scope.addressDetails.zipCode;
                        } else {
                            address.addressText = Config.branch.location;
                            address.city = Config.branch.city;
                            address.zipCode = Config.branch.zipCode;
                        }

                        return PaymentsService.initEBTPayment({
                            creditCardId: $scope.paymentDetails.ebtCard.id,
                            address: address,
                            isPickup: !$scope.isDelivery
                        }).then(function (resp) {
                            return resp.paymentToken;
                        });
                    }

                    function _addPaypalListener() {
                        if (!$scope.hasPaypalOption) {
                            return;
                        }

                        _listeners.push($rootScope.$on('paypal.finished', function (data, eventData) {
                            if (!eventData || eventData.error || !eventData.paymentToken) {
                                if (eventData && eventData.error) {
                                    mDesign.alert(eventData.error.error);
                                }
                                return;
                            }

                            $scope.paymentDetails.paymentToken = eventData.paymentToken;
                            $scope.paymentDetails.paypalPayerId = eventData.payerId;
                            User.getUserSettings(true).then(function (user) {
                                if (!user) {
                                    return;
                                }
                                $timeout(function () {
                                    _initPaypalAccounts(user.paypalAccounts);
                                });
                            });
                        }));
                    }

                    if ($scope.listener) {
                        _listeners.push($rootScope.$on($scope.listener, _setEBTEligible));
                    }

                    Util.destroyListeners($scope, _listeners);

                     $scope.redeemedNow = function() {
                         if($scope.totalPriceForPointsCalculation <= 0) {
                             return mDesign.alert({
                                 content: 'לא ניתן לממש את נקודות המועדון (קאשבק) אם הסכום לתשלום בכרטיס האשראי הוא 0'});
                         }

                        return Api.request({
                            method: 'POST',
                            url: '/v2/retailers/:rid/users/' + User.settings.id + '/loyalty-clubs/' + $scope.paymentDetails.userCashbackLoyalty.id + '/insertUserRedemptionPoints',
                            data: {
                                redeemedPointsAmount: $scope.paymentDetails.userCashbackLoyalty.availableUserCashback,
                                pointsToken: $scope.paymentDetails.userCashbackLoyalty.pointsToken,
                                cartId: Cart.serverCartId
                            }
                        }).then(function() {
                            $scope.paymentDetails.userCashbackLoyalty.redeemedPointsAmount = $scope.paymentDetails.userCashbackLoyalty.availableUserCashback;
                            $scope.paymentDetails.isRedeemedNow = true;
                            $scope.paymentDetails.showAnimation = true;
                            $rootScope.isUsedCashbackPoints = true;
                            $scope.totalPriceForPointsCalculation -= $scope.paymentDetails.userCashbackLoyalty.availableUserCashback;

                            _calculateUserPoints();
                            $timeout(function () {
                                $scope.paymentDetails.showAnimation = false;
                            }, 5000)
                        })
                    }

                    function _setCashbackLoyaltyClub(loyaltyClubId) {
                        angular.forEach(Config.retailer.loyaltyClubDrivers, function (loyaltyClubDriver) {
                            if(loyaltyClubDriver.clientConfig.loyaltyClubs && loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId] &&
                                loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId].pointsToCashback) {
                                $scope.paymentDetails.activeCashbackLoyalty = loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId];
                                $scope.paymentDetails.activeCashbackLoyalty.name = loyaltyClubDriver.name;
                                $rootScope.purchaseSummaryPage = $scope.paymentDetails.activeCashbackLoyalty.loyaltyPages.purchaseSummary;
                                $scope.paymentDetails.activeCashbackLoyalty.pointsDecimalRound = loyaltyClubDriver.clientConfig.pointsDecimalRound;
                            }
                        });
                    }

                    $scope.unredeemedPoints = function() {
                        return Api.request({
                            method: 'DELETE',
                            url: '/v2/retailers/:rid/users/' + User.settings.id + '/loyalty-clubs/' + $scope.paymentDetails.userCashbackLoyalty.id + '/deleteUserRedemptionPoints/' + Cart.serverCartId,
                        }).then(function() {
                            $scope.totalPriceForPointsCalculation += $scope.paymentDetails.userCashbackLoyalty.availableUserCashback;
                            _calculateUserPoints();

                            $scope.paymentDetails.isRedeemedNow = false;
                            $rootScope.isUsedCashbackPoints = false;
                            $scope.paymentDetails.showAnimation = false;
                            delete $scope.paymentDetails.userCashbackLoyalty.redeemedPointsAmount;
                        })
                    }

                    $scope.presentLoyaltyPointsComponent = function () {
                         return (!$scope.paymentMethodModel.isStandAlone && $scope.paymentMethodModel.name !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT
                             && $scope.paymentDetails.userCashbackLoyalty &&
                             $scope.paymentDetails.activeCashbackLoyalty && $scope.paymentDetails.activeCashbackLoyalty.pointsToCashback &&
                             $scope.paymentDetails.isLoyaltyPackageEnabled && $scope.paymentDetails.activeCashbackLoyalty.loyaltyPages.paymentDetailsPage.isActive &&
                             !!($scope.paymentDetails.activeCashbackLoyalty.isMinPointsRequired ||
                                 (!$scope.paymentDetails.activeCashbackLoyalty.isMinPointsRequired && $scope.paymentDetails.userCashbackLoyalty.points)))
                    }

                    $scope.openGiftCardsPopup = function (secondaryPayments) {
                        $scope.giftCards = secondaryPayments;

                        var options = {
                            giftCards: $scope.giftCards,
                            redeemedGiftCards: $scope.paymentDetails.giftCards,
                            mainPaymentMethod: $scope.paymentMethodModel.paymentMethodId,
                            availableUserCashback: $scope.paymentDetails.userCashbackLoyalty && $scope.paymentDetails.userCashbackLoyalty.availableUserCashback ? $scope.paymentDetails.userCashbackLoyalty.availableUserCashback : 0
                        }

                        return mDesign.dialog({
                            focusOnOpen: false,
                            clickOutsideToClose: false,
                            disableClosing: true,
                            multiple: true,
                            templateUrl: 'views/templates/add-gift-card.html',
                            controller: 'AddGiftCardCtrl as addGiftCardCtrl',
                            locals: { options: options },
                            resolve: {
                                paymentCtrl: [function () {
                                    return $scope
                                }]
                            }
                        }).then(function (result) {
                            if(angular.isObject(result) && Object.keys(result).length) {
                                $scope.totalPriceForPointsCalculation += $scope.paymentDetails.totalGiftCards || 0;
                                $scope.paymentDetails.totalGiftCards = 0;
                                $scope.paymentDetails.giftCards = result;
                                $scope.paymentDetails.redeemedGiftCardIds = Object.keys($scope.paymentDetails.giftCards);
                                $scope.giftCardsSelected = [];
                                angular.forEach($scope.paymentDetails.redeemedGiftCardIds, function (giftCardId) {
                                    $scope.paymentDetails.totalGiftCards += $scope.paymentDetails.giftCards[giftCardId].amount;
                                    var selectedGiftCard = angular.copy($scope.paymentDetails.giftCards[giftCardId]);
                                    selectedGiftCard.name =Config.retailer.settings.giftCardNamesSettings[giftCardId][$rootScope.config.language.id];
                                    selectedGiftCard.lastFourDigits = selectedGiftCard.cardNumber.slice(-4);
                                    $scope.giftCardsSelected.push(selectedGiftCard);
                                });

                                if($scope.paymentDetails.userCashbackLoyalty) {
                                    $scope.totalPriceForPointsCalculation -= $scope.paymentDetails.totalGiftCards || 0;
                                    $scope.paymentDetails.userCashbackLoyalty.availableUserCashback -= $scope.paymentDetails.totalGiftCards || 0;
                                    _calculateUserPoints();
                                }

                            }
                        });
                    }

                    function _calculateUserPoints() {
                        if($scope.totalPriceForPointsCalculation > 0) {
                            var points = $scope.totalPriceForPointsCalculation / $scope.paymentDetails.activeCashbackLoyalty.moneyToPointRatio;
                            $scope.paymentDetails.currentOrderPoints = Number(points.toFixed(1));
                        } else {
                            $scope.paymentDetails.currentOrderPoints = null;
                        }
                    }

                    function _setGiftCardPopupSettings() {
                        $scope.giftCardPopupSettings = Config.retailer.settings.checkoutDisplaySettings ? JSON.parse(Config.retailer.settings.checkoutDisplaySettings) : {};

                        if($scope.giftCardPopupSettings) {
                            var keys = Object.keys($scope.giftCardPopupSettings);

                            angular.forEach(keys, function (key) {
                                if($scope.giftCardPopupSettings[key].paymentLinkText) {
                                    $scope.giftCardPopupSettings[key].paymentLinkText = $scope.giftCardPopupSettings[key].paymentLinkText.split('{click here}');
                                }
                            });
                        }
                    }

                    // ECOM-9370 - sell date is removed after calculating -> need to recalculate again after loading cart
                    var removeCartTotalCalculatedListener = $rootScope.$on(
                      "cart.lines.quantityChanged",
                      function () {
                        removeCartTotalCalculatedListener();
                        
                        var removeCartUpdateListener = $rootScope.$on('cart.update.complete', function(){
                            removeCartUpdateListener();
                            _handlePointsCalculation();
                        })
                      }
                    );
                }
            };
        }]);
})(angular);
