'use strict';

angular.module('mobilezuz')
    .service('TrackingTransparency', ['$q', '$rootScope', 'Config','mDesign', 'DialogAvailability', TrackingPermission])

    function TrackingPermission($q, $rootScope, Config, mDesign, DialogAvailability) {
        var self = this,
            _initiated = false,
            _strategy = null;

        var PLATFORM_TYPE = {
            IOS_MOBILE_APP_LEGACY: 1,
            IOS_MOBILE_APP: 2,
            ANDROID_MOBILE_APP: 3,
            MOBILE_WEB: 4
        };

        var CORDOVA_PLATFORM = {
            IOS: 'ios',
            ANDROID: 'android'
        };

        // iOS 14+ support and require the usage of the ATT framework
        var IOS_VERSION = {
            APP_TRACKING_TRANSPARENCY_SUPPORTED: 14
        };

        // supported cookies are derived from the cookiewall view and not represent all the possible cookies.
        // in the future it's important to support all cookies dynamically, by registering them from outside, by the analytics service that require the permission, which may vary from retailer to retailer, and presenting them in the cookiewall view
        var SUPPORTED_COOKIES = {
            marketing: false,
            additionalFeatures: false,
            audienceMeasurement: false,
            googleAnalytics: false
        };

        // holds different approaches for dealing with tracking data permission, based on platform type and other settings.
        // the correct strategy is loaded in runtime while the service inits
        var strategy = {
            allowAll: {
                name: "allowAll",
                permission: _allowAllPermissionsStrategy,
                dialog: _allowAllDialogStrategy
            },
            cookieWall: {
                name: "cookieWall",
                permission: _cookieWallPermissionsStrategy,
                dialog: _cookieWallDialogStrategy
            },
            native: {
                name: "native",
                permission: _nativePermissionsStrategy,
                dialog: _nativeDialogStrategy
            }
        };

        // requestTrackingPermission - should be used where we want to popup the dialog
        // waitForPermission && isTrackingAllowed - should be used prior to initiating analytics
        // waitForPermission waits for the isTrackingAllowed field to be initiated to true or false, which could happen during init or by presenting a dialog to the user
        // after it resolves we can check the isTrackingAllowed for general tracking permission
        // each analytics service should check if it's allowed to run, by looking at Config.ApprovedCookies
        self.requestTrackingPermission = requestTrackingPermission;
        self.waitForPermission = waitForPermission;
        self.isTrackingAllowed = null;

        _init();

        // publicly exposed and used to popup a permission request dialog, when needed and handle tracking
        function requestTrackingPermission() {
            return _waitForInit()
                .then(_initDialog)
                .then(_setTracking)
                .catch(function () {
                    _setTracking({isTrackingAllowed: false});
                })
                .finally(_notifyTracking);
        }

        // publicly exposed and used to wait for the tracking status to be determined
        function waitForPermission() {
            return _waitForEvent(_isTrackingDefined(), 'tracking.permissionRequested');
        }

        // used when we have to wait for the service to finish initializing prior to proceeding
        function _waitForInit() {
            return _waitForEvent(_initiated, 'tracking.initiated');
        }

        // initiates the service
        function _init() {
            Config.waitForInit()
                .then(_setStrategy)
                .then(_initPermissions)
                .then(_setTracking)
                .catch(function () {
                    _setTracking({isTrackingAllowed: false});
                })
                .finally(function()  {
                    _notifyInit();
                    _notifyTracking();
                });
        }

        // based on platform type and retailer settings, the correct approach for dealing with tracking permission is set
        function _setStrategy() {
            var platform = _gePlatformType();
            if (platform === PLATFORM_TYPE.IOS_MOBILE_APP) {
                _strategy = strategy.native;
            }
            else if (platform === PLATFORM_TYPE.IOS_MOBILE_APP_LEGACY || Config.retailer.isCookieWallEnabled) {
                _strategy = strategy.cookieWall;
            }
            else {
                _strategy = strategy.allowAll;
            }
        }

        // inits the relevant permission setup based on the approach set on strategy
        function _initPermissions() {
            // deals with scenario where strategy is changed due to os upgrade on device or retailer settings changes
            if (Config.approvedCookies && Config.approvedCookies._usedStrategy !== _strategy.name) {
                Config.setApprovedCookies(null);
            }
            return _strategy.permission();
        }

        // inits the relevant dialog based on the approach set on strategy
        function _initDialog() {
            // presenting the dialog is only needed when tracking is not determined during setup
            if (_isTrackingDefined()) {
                return {isTrackingAllowed: self.isTrackingAllowed};
            }

            return DialogAvailability.requestDialog(DialogAvailability.PERMISSIONS.TRACKING)
                .then(_strategy.dialog)
        }

        // strategy for cases where we are not obligated to request permission to track.
        // if we determine cookies existence, we assume permission was asked before, otherwise we set all supported cookies to true and allow tracking
        function _allowAllPermissionsStrategy() {
            if (Config.approvedCookies) {
                return {isTrackingAllowed: true}
            }
            else {
                _setSupportedCookies({allowAll: true});
                return {isTrackingAllowed: true, cookies: SUPPORTED_COOKIES};
            }
        }

        // strategy for cases where user is running on iOS 14+
        // if tracking is not limited we allow to track and setup all supported cookies in case they dont present - such case could be when permission was denied in the past and later enabled in settings
        // if tracking is limited we check if we allow asking for permission or deny tracking
        function _nativePermissionsStrategy() {
            var idfaPlugin = cordova.plugins.idfa;
            return idfaPlugin.getInfo()
                .then(function (info) {
                    if (!info.trackingLimited && Config.approvedCookies) {
                        return {isTrackingAllowed: true};
                    }
                    else if (!info.trackingLimited) {
                        _setSupportedCookies({allowAll: true});
                        return {isTrackingAllowed: true, cookies: SUPPORTED_COOKIES};
                    }
                    else if (info.trackingPermission !== idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED) {
                        return {isTrackingAllowed: false};
                    }

                    return {isTrackingAllowed: null}
                });
        }

        // strategy for cases where we obligated to ask for permission, but not using iOS 14+
        // if we determine cookies existence, we assume permission was asked before, otherwise we set to null and let the relevant dialog strategy handle the setup or set to false in case dialog on startup is not allowed
        function _cookieWallPermissionsStrategy() {
            if (Config.approvedCookies) {
                return {isTrackingAllowed: true}
            }
            else if (Config.preventDialogsOnLoad) {
                return {isTrackingAllowed: false}
            }

            return {isTrackingAllowed: null}
        }

        // presenting dialog to user is not needed, tracking is set during init
        function _allowAllDialogStrategy() {
            return;
        }

        // native iOS dialog is presented, permission and cookies are set based on response
        function _nativeDialogStrategy() {
            var idfaPlugin = cordova.plugins.idfa;
            return idfaPlugin.requestPermission()
                .then(function (result) {
                    if (result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED) {
                        _setSupportedCookies({allowAll: true});
                        return {isTrackingAllowed: true, cookies: SUPPORTED_COOKIES};
                    }
                    else {
                        return {isTrackingAllowed: false};
                    }
                });
        }

        // custom dialog is presented, allowing some customization for different tracing - permission and cookies are set based on response
        function _cookieWallDialogStrategy() {
            var forceApproveDialog = false;
            return mDesign.dialog({
                templateUrl: 'views/templates/cookie-wall.html',
                clickOutsideToClose: false,
                escapeToClose: !forceApproveDialog,
                multiple: false,
                controller: ['$scope', function ($scope) {
                    var cookieWallCtrl = this;
                    cookieWallCtrl.isMainView = true;
                    cookieWallCtrl.allowClosing = !forceApproveDialog;
                    cookieWallCtrl.showError = false;
                    cookieWallCtrl.cancel = cancel;
                    cookieWallCtrl.accept = accept;
                    cookieWallCtrl.acceptAll = acceptAll;
                    cookieWallCtrl.configure = configure;
                    cookieWallCtrl.true = true;

                    _setSupportedCookies({googleAnalytics: true});
                    cookieWallCtrl.cookies = SUPPORTED_COOKIES

                    function accept() {
                        $rootScope.$emit('util.cookieWall.event', {approvedCookies: cookieWallCtrl.cookies});
                        cancel({ isTrackingAllowed: true, cookies: SUPPORTED_COOKIES });
                    }

                    function acceptAll() {
                        Object.keys(cookieWallCtrl.cookies).forEach(function (key) {
                            cookieWallCtrl.cookies[key] = true;
                        });
                        accept();
                    }

                    function configure() {
                        cookieWallCtrl.isMainView = false;
                    }

                    function cancel(results) {
                        return mDesign.hide(results || {isTrackingAllowed: false});
                    }
                }],
                controllerAs: 'cookieWallCtrl'
            });
        }

        function _waitForEvent(isResolved, eventName) {
            return new $q(function (resolve) {
                if (isResolved) {
                    return resolve(true);
                }

                var listener = $rootScope.$on(eventName, function () {
                    listener();
                    resolve(true);
                });
            });
        }

        // signals that tracking info is determined
        function _notifyTracking() {
            if (_isTrackingDefined()) {
                $rootScope.$emit('tracking.permissionRequested');
                $rootScope.$emit('tracking.permissions.updated');
            }
        }

        // sets the initiated field and signals init is complete
        function _notifyInit() {
            _initiated = true;
            $rootScope.$emit('tracking.initiated');
        }

        // returns the platform the user is currently running on to help determine the correct approach
        function _gePlatformType() {
            if (window.cordova && window.cordova.platformId === CORDOVA_PLATFORM.IOS && device.version && Number(device.version.split('.')[0]) >= IOS_VERSION.APP_TRACKING_TRANSPARENCY_SUPPORTED && window.cordova.plugins && cordova.plugins.idfa) {
                return PLATFORM_TYPE.IOS_MOBILE_APP;
            }
            else if (window.cordova && window.cordova.platformId === CORDOVA_PLATFORM.IOS) {
                return PLATFORM_TYPE.IOS_MOBILE_APP_LEGACY;
            }
            else if (window.cordova && window.cordova.platformId === CORDOVA_PLATFORM.ANDROID) {
                return PLATFORM_TYPE.ANDROID_MOBILE_APP;
            }
            else {
                return PLATFORM_TYPE.MOBILE_WEB;
            }
        }

        function _isTrackingDefined() {
            return self.isTrackingAllowed !== null;
        }

        // sets status for supported cookies
        function _setSupportedCookies(cookiesFilter) {
            Object.keys(SUPPORTED_COOKIES).forEach(function (key) {
                SUPPORTED_COOKIES[key] = cookiesFilter.allowAll ? true : !!cookiesFilter[key]
            });
        }

        // sets tracking and cookies
        function _setTracking(trackingInfo) {
            if (!trackingInfo) {
                return;
            }
            else if (trackingInfo.isTrackingAllowed && trackingInfo.cookies) {
                var approvedCookies = Object.keys(trackingInfo.cookies)
                    .filter(function(key) {
                        return trackingInfo.cookies[key];
                    })
                    .reduce(function(obj, key) {
                        obj[key] = trackingInfo.cookies[key];
                        return obj;
                    }, {});

                approvedCookies._time = new Date();
                approvedCookies._usedStrategy = _strategy.name;

                Config.setApprovedCookies(approvedCookies);
            }

            else if (trackingInfo.isTrackingAllowed === false) {
                Config.setApprovedCookies(null);
            }

            self.isTrackingAllowed = trackingInfo.isTrackingAllowed;
        }
    }
