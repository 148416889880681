(function(angular) {
	'use strict';

	angular.module('mobilezuz')
		.config(['$stateProvider', function($stateProvider) {
			$stateProvider.state('app.webPage', {
				url: '/web-pages/{id:int}',
				data: {
					name: 'web-page',
					metaTags: {
						title: ['$stateParams', 'Retailer', function($stateParams, Retailer) {
							return Retailer.getRetailerSettings().then(function(configuration) {
								if (!configuration.publishedWebPages) {
									return;
								}

								for (var i = 0; i < configuration.publishedWebPages.length; i++) {
									if (configuration.publishedWebPages[i].id === $stateParams.id) {
										return configuration.publishedWebPages[i].name;
									}
								}
							});
						}]
					},
				},
				views: {
					'@': {
						templateUrl: 'views/web-page.html',
						controller: 'WebPageCtrl as webPageCtrl',
						resolve: {
							webPage: ['$stateParams', 'Api', function($stateParams, api) {
								return api.request({
									method: 'GET',
									url: '/v2/retailers/:rid/web-pages/' + $stateParams.id
								});
							}]
						}
					}
				}
			});
		}])
		.controller('WebPageCtrl', ['$sce', 'spHtmlComponentsRenderer', 'webPage',
			function($sce, spHtmlComponentsRenderer, webPage) {
				var webPageCtrl = this;

				webPageCtrl.webPage = webPage;
				webPageCtrl.html = spHtmlComponentsRenderer.generateHtml(webPageCtrl.webPage.components);
			}
		]);
})(angular);
