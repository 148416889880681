(function (angular) {
    'use strict';
    angular.module('mobilezuz').directive('spSpecialDisclaimer', [
        '$rootScope', '$filter', 'Util', 'Config',
        function ($rootScope, $filter, util, config) {
            return {
                restrict: 'E',
                template: '<span><span sp-bind-html="disclaimerHtml"></span></span>',
                replace: true,
                scope: {
                    originalStartDate: '=?startDate',
                    originalEndDate: '=?endDate',
                    purchaseLimit: '=?'
                },
                controller: ['$scope', function($scope) {
                    util.currentScopeListener($scope, $scope.$watch('originalStartDate', function(newValue) {
                        $scope.startDate = newValue ? $filter('date')(newValue, $rootScope.isUs ? 'MM/dd/yyyy' : 'dd/MM/yyyy', 'UTC') : null;
                    }));
                    util.currentScopeListener($scope, $scope.$watch('originalEndDate', function(newValue) {
                        $scope.endDate = newValue ? $filter('date')(newValue, $rootScope.isUs ? 'MM/dd/yyyy' : 'dd/MM/yyyy', 'UTC') : null;
                    }));

                    $scope.disclaimerHtml = config.retailer.settings.disclaimer;
                }]
            };
        }]);

})(angular);

