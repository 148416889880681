(function (angular) {
    angular.module('mobilezuz').directive('productProperties', [function () {
        return {
            restrict: 'E',
            templateUrl: 'views/templates/product-properties.html',
            replace: true,
            scope: {
                item: '=',
            },
            controllerAs: 'productPropertiesCtrl',
            controller: ['$scope', 'Cart', function ($scope, Cart) {
                var productPropertiesCtrl = this;
                var item = $scope.item;
                productPropertiesCtrl.item = item;
                productPropertiesCtrl.showPropertyError = false;
                productPropertiesCtrl.onPropertyValueChanged = onPropertyValueChanged;

                _setProductProperties();

                function onPropertyValueChanged() {
                    productPropertiesCtrl.showPropertyError = null;

                    if (!productPropertiesCtrl.selectedPropertyValueId || !productPropertiesCtrl.item) {
                        return;
                    }
                    productPropertiesCtrl.item.productPropertyValueId = productPropertiesCtrl.selectedPropertyValueId;
                }

                function _setProductProperties() {
                    productPropertiesCtrl.productProperty = productPropertiesCtrl.item.product.productProperties[0];

                    if (productPropertiesCtrl.item.productPropertyValue && productPropertiesCtrl.item.productPropertyValue.id) {
                        productPropertiesCtrl.selectedPropertyValueId = productPropertiesCtrl.item.productPropertyValue.id;

                    }
                }
            }]
        };
    }]);
})(angular);