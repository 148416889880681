(function (angular) {

    angular.module('mobilezuz').service('ExternalUserVerificationService', ['Api', function(api) {
        this.doExternalUserVerification = doExternalUserVerification;
        this.checkEmail = checkEmail;

        function checkEmail(code) {
            //== Check external invited user
            return api.request({
                method: 'POST',
                url: '/v2/retailers/:rid/users/verification/external/user-check',
                data: {
                    code: code
                }
            }).then(function(response) {
                return {
                    success: true,
                    response: response,
                    code: code,
                    email: response.email || ''
                }
            }).catch(function(err) {
                return {
                    success: false,
                    code: code,
                    error:  err.response.errors ? err.response.errors[0] : err
                }
            });
        }

        function doExternalUserVerification(code, phone) {
            //== Try to verify external invited user
            return api.request({
                method: 'POST',
                url: '/v2/retailers/:rid/users/verification/external/user-verification',
                data: {
                    code: code,
                    phone: phone
                }
            }).then(function(response) {
                return {
                    success: true,
                    response: response
                }
            }).catch(function(err) {
                return {
                    success: false,
                    error: err.response.errors ? err.response.errors[0] : err
                }
            });
        }
    }]);


    angular.module('mobilezuz').config([
        'SpDialogUrlManagerProvider', 'SP_URL_DIALOG_QUERY_PARAMS', 'SP_URL_DIALOG_DATA_QUERY_PARAMS',
        function (SpDialogUrlManagerProvider, SP_URL_DIALOG_QUERY_PARAMS, SP_URL_DIALOG_DATA_QUERY_PARAMS) {
            SpDialogUrlManagerProvider.dialog({
                queryParams: SP_URL_DIALOG_QUERY_PARAMS.INVITED_USER,
                dataQueryParams: SP_URL_DIALOG_DATA_QUERY_PARAMS.EMAIL_VERIFICATION, //== This is the same 'code' parameter as in Email Verification
                canShow: ['paramValue', function (paramValue) {
                    return !!paramValue;
                }],
                paramResolve: ['paramValue', 'ExternalUserVerificationService', function (paramValue, ExternalUserVerificationService) {
                    return ExternalUserVerificationService.checkEmail(paramValue);
                }],
                show: ['mDesign', 'SpDialogUrlManager', 'value', function (mDesign, SpDialogUrlManager, value) {
                    return mDesign.dialog({
                        multiple: true,
                        fullscreen: true,
                        templateUrl: 'views/external-user-verification.html',
                        controller: 'ExternalUserVerificationCtrl as externalUserVerificationCtrl',
                        locals: {
                            checkEmailResponse: value
                        },
                        dontPreventRouteChange: true
                    });
                }]
            });
        }
    ]);


    angular.module('mobilezuz').controller('ExternalUserVerificationCtrl', ['$rootScope', '$timeout', 'Util', 'User', 'SpDialogUrlManager', 'checkEmailResponse', 'ExternalUserVerificationService',
        function ($rootScope, $timeout, Util, User, SpDialogUrlManager, checkEmailResponse, ExternalUserVerificationService) {
            var externalUserVerificationCtrl = this;

            externalUserVerificationCtrl.title = 'Please verify your identity';
            externalUserVerificationCtrl.body = 'To verify your identity, please enter a phone number';

            externalUserVerificationCtrl.submitEnabled = false;
            externalUserVerificationCtrl.closeEnabled = false;

            externalUserVerificationCtrl.email = checkEmailResponse.success && checkEmailResponse.email;
            externalUserVerificationCtrl.code = checkEmailResponse.code;
            externalUserVerificationCtrl.phone = '';
            externalUserVerificationCtrl.phoneHint = '';
            externalUserVerificationCtrl.phoneError = '';

            externalUserVerificationCtrl.submit = submit;
            externalUserVerificationCtrl.close = close;

            init();

            function init() {
                if(checkEmailResponse.success) {
                    externalUserVerificationCtrl.submitEnabled = true;
                    externalUserVerificationCtrl.phoneHint = checkEmailResponse.response.maskedPhone;
                } else {
                    _showFatalError(checkEmailResponse);
                }
            }

            function close() {
                return SpDialogUrlManager.backClose();
            }

            function _goToRegistration() {
                return close().then(function() {
                     $timeout(function() {
                         return SpDialogUrlManager.setDialogParams({
                             loginOrRegister: '1',
                             registrationTab: '1',
                             registrationEmail: externalUserVerificationCtrl.email
                         });
                     }, 0);
                });
            }

            function submit() {
                externalUserVerificationCtrl.phoneError = '';
                if( /^\(?\+?(\d[\s,\-,(,),,,]*){8,15}$/.test(externalUserVerificationCtrl.phone) === false) {
                    externalUserVerificationCtrl.phoneError = 'Invalid phone number';
                    return;
                }

                ExternalUserVerificationService.doExternalUserVerification(externalUserVerificationCtrl.code, externalUserVerificationCtrl.phone).then(function(response) {
                    if(response.success) {
                        externalUserVerificationCtrl.submitEnabled = true;
                        _setExternalUserData(externalUserVerificationCtrl.code);
                        _goToRegistration();
                    } else {
                        _showError(response);
                    }
                });
            }

            function _showError(response) {
                externalUserVerificationCtrl.phoneError = (response.error.response || {}).error || (response.error || '').msg;
            }

            function _showFatalError(response) {
                externalUserVerificationCtrl.title = (response.error.response || {}).error || (response.error || '').msg;
                externalUserVerificationCtrl.body = '';
                externalUserVerificationCtrl.submitEnabled = false;
                externalUserVerificationCtrl.closeEnabled = true;
            }

            function _setExternalUserData(code) {
                $rootScope.externalUserCode = code;
            }
        }]);
})(angular);