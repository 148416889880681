(function(angular) {
    angular.module('mobilezuz')
        .config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
            $stateProvider.state('app.loyaltyTerms', {
                url: '/loyalty-terms',
                data: {
                    stateAccess: PAGE_ACCESS.ALLOW,
                    bodyClass: 'info-text',
                    metaTags: {
                        title: 'loyalty terms'
                    }
                },
                views: {
                    '@': {
                        templateUrl: 'views/bind-html.html',
                        controller: 'LoyaltyTermsCtrl'
                    }
                }
            });
        }]).controller('LoyaltyTermsCtrl', ['$rootScope', '$scope', 'Retailer',
        function($rootScope, $scope, Retailer) {
            $rootScope.title = 'Loyalty Club Terms & conditions';
            Retailer.getRetailerSettings().then(function(response) {
                $scope.content = response.settings.loyaltyPrivacyText;
            });
        }]);
})(angular);