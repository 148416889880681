(function(angular) {
	angular.module('mobilezuz').service('FrLanguage', function() {
		this.id = 5;
		this.culture = 'fr';
		this.name = 'Français';
		this.direction = 'ltr';
		this.countriesTranslations = {
			FR: {
				'tax': 'TVA'
			}
		};
		this.translations = {
			//'en': 'fr',
			'my': 'Mon',
			'me': 'Moi',
			'in {name} dep.': 'dans le rayon {name}',
			'in specials': 'dans les promos',
			'special products': 'Produits en promo',
			'shopping lists': 'Listes de courses',
			'shopping list': 'Liste de courses',
			'shop': 'Achetez',
			'shop ': 'Produits ',
			'by departments': 'par rayon',
			'email': 'Email',
			'email_error': 'Veuillez saisir une une adresse email valide',
			'password': 'Mot de passe',
			'log in': 'Se connecter',
			'log in with facebook': 'Se connecter avec Facebook',
			'password recovery message was sent to your email': 'Message de récupération du mot de passe envoyé à votre email',
			'home': 'Accueil',
			'departments': 'les rayons',
			'scanner': 'Scan',
			'all products': 'Produits',
			'orders': 'A. récents',
			'sales': 'Promos',
			'specials': 'Promos',
			'sale': 'Promo',
			'sale!': 'Promo !',
			'edit': 'Modifier',
			'search': 'Recherche',
			'total': 'Total',
			'checkout': 'Passer commande',
			'forgot your password?': 'Mot de passe oublié ?',
			'my shopping lists': 'Mes listes de courses',
			'remove': 'Supprimer',
			'add list': 'Ajouter une liste',
			'name': 'Nom',
			'creationdate': 'Date de création',
			'itemscount': 'Nombre de produits',
			'shopping list name': 'Nom de la liste',
			'sorting by': 'Trier par',
			'add': 'Ajouter',
			'add_product': 'Ajouter',
			'close': 'Fermer',
			'clean cart': 'Vider le panier',
			'cart': 'Panier',
			'recipient\'s name': 'Nom du destinataire',
			'email address': 'Adresse Email',
			'the offer is valid from': 'La promo est valide du',
			'until': 'au',
			'terms and conditions': 'Conditions d’utilisation',
			'this purchase order is an offer by the company identified on the face of this purchase order (the \"buyer\") for the purchase of the goods (the \"goods or services (the “services”) specified\")': 'This purchase order is an offer by the company identified on the face of this purchase order (the \"Buyer\") for the purchase of the goods (the \"Goods or services (the “Services”) specified\")_h',
			'pay with': 'Payer avec',
			'card number': 'Num. de carte',
			'delivery address': 'Adresse de livraison',
			'add card number': 'Ajouter un num. de carte',
			'country': 'Pays',
			'zip code': 'Code postal',
			'zipcode': 'Code postal',
			'entrance': 'Entrée',
			'city': 'Ville',
			'street': 'Rue',
			'house number': 'Num. de la maison',
			'floor': 'Étage',
			'apartment': 'Appart.',
			'available days': 'Jours disponibles',
			'entry: spautocompleterequirematch error': 'Saisir une lettre (A-J)',
			'house_entry_none': 'Aucun',
			'house_entry_a': 'A',
			'house_entry_b': 'B',
			'house_entry_c': 'C',
			'house_entry_d': 'D',
			'house_entry_e': 'E',
			'house_entry_f': 'F',
			'house_entry_g': 'G',
			'house_entry_h': 'H',
			'house_entry_i': 'I',
			'house_entry_j': 'J',
			'personal info': 'Informations personnelles',
			'first name': 'Prénom',
			'last name': 'Nom de famille',
			'full name': 'Nom complet',
			'phone number': 'Num. de téléphone',
			'delivery time': 'Horaire de livraison',
			'day': 'Jour',
			'time': 'Horaire',
			'notes': 'Commentaires',
			'add instructions': 'Ajouter des instructions',
			'summary': 'Résumé',
			'products': 'Produits',
			'delivery charge': 'Frais de livraison',
			'ok': 'OK',
			'cancel': 'Annuler',
			'recent': 'Voir les',
			'purchases': 'achats récents',
			'please enter street': 'Saisissez une rue',
			'please enter email': 'Saisissez un email',
			'please enter password': 'Saisissez un mot de passe',
			'please enter first name': 'Saisissez un prénom',
			'please enter last name': 'Saisissez un nom de famille',
			'please enter your email address': 'Saisissez un email',
			'the shopping list was successfully added to the cart': 'La liste de courses a été ajoutée au panier',
			'callcustomer': 'Appelez-moi',
			'creditcard': 'Carte de crédit',
			'storecredit': 'Compte client',
			'between': 'entre',
			'sunday': 'Dimanche',
			'monday': 'Lundi',
			'tuesday': 'Mardi',
			'wednesday': 'Mercredi',
			'thursday': 'Jeudi',
			'friday': 'Vendredi',
			'saturday': 'Samedi',
			'today': 'Aujourd’hui',
			'your order has been received': 'Votre commande a bien été reçue',
			//'your order is in process and will be sent to you in the next hours. order id': 'Votre commande est en cours de préparation et vous sera envoyée dans quelques heures. ID de commande',
			'register': 'Inscription',
			'creation date': 'Date de création',
			'items count': 'Nombre d’articles',
			'shop by': 'Voir tous',
			'my orders history': 'Historique de mes commandes',
			'orders history': 'Historique des commandes',
			'previous orders': 'Mes commandes',
			'order time': 'Date de commande',
			'call customer': 'Appeler le client',
			'total (estimated)' : 'Total (estimé)',
			'out of stock': 'Rupture',
			'received_items': 'Reçue',
			'ordered': 'Commandé',
			'payment method ending with': 'Mode de paiement terminant avec',
			'credit card charge': 'Carte de crédit débitée',
			'new product': 'Nouveau produit',
			'view details': 'Voir les détails',
			'order details': 'Détails de la commande',
			'credit card': 'Carte de crédit',
			'view more': 'Afficher plus',
			'view less': 'Afficher moins',
			'account': 'Infos du compte',
			'retailer details': 'Détails du magasin',
			'more links': 'Plus de liens',
			'link': 'Lien',
			'change language': 'Changer de langue',
			'languages': 'Langues',
			'help': 'Aide',
			'settings': 'Paramètres',
			'personal details': 'Coordonnées',
			'birth date': 'Date de naissance',
			'allow send promotions': 'Je souhaite être informé des promotions',
			'gender': 'Sexe',
			'male': 'Homme',
			'female': 'Femme',
			'prefer not to say': 'Préfère ne pas répondre',
			'save': 'Enregistrer',
			'print': 'Imprimer',
			'share': 'Partager',
			'addresses': 'Adresses',
			'logout': 'Déconnexion',
			'info': 'Information',
			'policy': 'Politique',
			'contact details': 'Coordonnées de contact',
			'continue without membership': 'Continuer sans le club',
			'not a member yet? join now!': 'Pas encore membre du club ? Adhérez et économisez !',
			'my cart': 'Panier',
			'quantity': 'Quantité',
			'price': 'Prix',
			'items': 'Articles',
			'check out': 'Caisse',
			'enter cvv': 'CVV',
			'add card': 'Ajouter une carte',
			'remove card': 'Supprimer une carte',
			'thank you for your purchase': 'Merci d’avoir commandé chez',
			'loyalty club member': 'Adhérents au club',
			'in this purchase': 'Dès cet achat',
			'save_1': 'économisez',
			'continue without loyalty': 'Continuer sans le club',
			'the delivery time is no longer available': 'Horaire de livraison indisponible',
			'finish': 'Finir',
			'gift total': 'Valeur du cadeau',
			'purchase type': 'Conditions de la promo',
			'end date': 'Date de fin',
			'gift type': 'Type de promo',
			'confirm password': 'Confirmer le mot de passe',
			'please let me know about promotions': 'J\'accepte de recevoir des informations et offres par email et SMS (la réception peut être arrêtée à tout moment)',
			'more': 'Plus',
			'from my list': 'De la liste',
			'import to cart': 'Ajouter au panier',
			'import products from cart?': 'Importer les produits du panier ?',
			'nutrition facts': 'Valeurs nutritionnelles',
			'details': 'Détails',
			'add to cart': 'Ajouter au panier',
			'view similar items': 'Voir produits similaires',
			'settings was successfully changed!': 'Détails modifiés',
			'sorry. we couldn\'t find this product': 'Désolés, produit non trouvé',
			'two passwords must match': 'Mots de passe différents',
			'sign up completed': 'Vous êtes bien inscrit',
			'product not found': 'Produit non trouvé',
			'please enter your password': 'Saisissez un mot de passe',
			'my shopping list': 'Ma liste',
			'house no.': 'Num. de la maison',
			'from my cart': 'du panier',
			'est.': 'environ',
			'case': 'Caisse',
			'in store location': 'Emplacement dans le magasin',
			'units': 'Unités',
			'unit': 'Unité',
			'new shopping list was created': 'Nouvelle liste de courses créée',
			'please enter list name': 'Donnez un nom à la liste',
			'there are no products in cart': 'Il n’y a pas de produits dans la liste',
			'single': 'Unité',
			'pack': 'Pack',
			'pck': 'Pack',
			'cell phone': 'Téléphone mobile',
			'home phone': 'Téléphone du domicile',
			'work phone': 'Téléphone du travail',
			'error': 'Erreur',
			'are you sure?': 'Êtes vous sûr de vouloir vider votre panier ?',
			'phones': 'Téléphone',
			'address': 'Adresse',
			'opening hours': 'Heures d’ouverture',
			'user not found': 'Nom d\'utilisateur ou mot de passe erroné',
			'the email address doesn\'t have an associated account. please try again': 'Adresse email non associée à un utilisateur inscrit. Réessayez',
			'please enter your email address to proceed': 'Veuillez saisir votre adresse email pour continuer',
			'loyalty user not found': 'Identifiants d’adhérent au club erronés',
			'branches': 'Magasin',
			'login': 'Connexion utilisateur',
			'status': 'État',
			'time placed': 'Date de commande',
			'i agree to receive promotions': 'J\'accepte de recevoir les infos et promotions',
			'add to list': 'Ajouter à la liste',
			'unauthorized action': 'Action non autorisée',
			'first name is required': 'Prénom obligatoire',
			'last name is required': 'Nom de famille obligatoire',
			'email is required': 'Email obligatoire',
			'email is not valid': 'Email non valide',
			'password is required': 'Mot de passe obligatoire',
			'phone number is required': 'No de téléphone obligatoire',
			'pickup location is required': 'Choix du lieu de retrait obligatoire',
			'pickup day is required': 'Choix du jour de retrait obligatoire',
			'pickup hour is required': 'Choix de l’heure de retrait obligatoire',
			'payment method is required': 'Choix de la méthode de paiement obligatoire',
			'credit card is required': 'Veuillez ajouter une carte de crédit',
			'delivery day is required': 'Choix du jour de livraison obligatoire',
			'password should be longer than 6 characters': 'Le mot de passe doit contenir plus de 6 caractères',
			'new order': 'Nouvelle commande',
			'charged': 'Facturée',
			'delivered': 'Livrée',
			'play': 'Lire',
			'cancelled': 'Annulée',
			'on hold': 'En attente',
			'processing': 'En cours de préparation',
			'printed': 'Imprimée',
			'waiting for confirmation': 'En attente de confirmation',
			'collected': 'Préparée',
			'registered': 'En cours de préparation',
			'delete': 'Supprimer',
			'you must login first': 'Vous devez vous connecter à votre compte',
			'not found shop lists': 'Pas de liste de courses trouvée',
			'product added to shop list': 'Produit ajouté à la liste de courses',
			'yes': 'Oui',
			'no': 'Non',
			'brand': 'Marque',
			'new name': 'Nouveau nom',
			'invalid name': 'Nom non valide',
			'could not find your location': 'Votre emplacement n’a pas été trouvé',
			'new list': 'Créer liste',
			'+add to list': '+Ajouter',
			'your cart is still empty': 'Votre panier est encore vide',
			'in all departments': 'dans tous les rayons',
			'this is a pick and go order': 'Commande Pick & Go',
			'start shopping': 'Commencer les courses',
			'your order has been collected': 'Votre commande est maintenant prête.',
			'the order was canceled': 'La commande a été annulée.',
			'a message has been sent to the number on your order, kindly confirm the replacements and we will complete your order': 'Un SMS vous a été envoyé, veuillez s’il vous plaît valider les remplacements afin que nous puissions finaliser votre commande.',
			'the order is currently being collected by our trained shoppers': 'Votre commande est en cours de préparation.',
			'your order is currently being collected by our trained shoppers': 'Votre commande est en cours de préparation.',
			'your order has been received but not charged': 'Commande reçue dans le système, mais pas encore facturée',
			'your credit card has been charged for your order': 'Carte de crédit débitée du montant de l\'achat',
			'your order has been delivered and is on its way to you': 'Commande expédiée et en route vers chez vous',
			'the order was cancelled for various reasons and is not being handled': 'Commande annulée pour diverses raisons',
			'order could not be completed, customer intervention is required': 'Commande ne pouvant être complétée, intervention du client nécessaire',
			'the order is currently being processed': 'Commande en cours de préparation',
			'the items have been collected but not yet registered in the pos': 'Les produits ont été préparés, mais pas encore enregistrés en caisse',
			'the products were collected and scanned, but the transaction was yet to be completed': 'Produits préparés et scannés, mais pas encore payés',
			'product already exists': 'Le produit existe déjà dans la liste',
			'approve & register': 'J’approuve et termine l\'inscription',
			'not approve': 'Je n’approuve pas',
			'{hubname}\'s privacy policy.': 'la politique de confidentialité de {hubname}',
			'i agree to receive promotional content & offers from {company_name} and {hubname}': 'J\'accepte de recevoir du contenu promotionnel et des offres de la société {company_name}, qui gère le site du détaillant et de {hubname}',
			'i agree to the': 'Je confirme avoir lu et accepté les',
			'terms & conditions': 'Conditions du site',
			'loyalty Club terms & conditions': 'le règlement du club',
			'policy_end': '',
			'privacy policy': 'Politique de confidentialité',
			'about us': 'À propos de nous',
			//'add comment': 'Ajouter un commentaire sur un article',
			'track my order': 'Suivi de commande',
			'order id:': 'No de commande :',
			'no internet connection': 'Pas de connexion Internet',
			'please enable internet connection and retry': 'Activez la connexion Internet et réessayez',
			'retry': 'Réessayez',
			'no branch': 'Pas de magasin',
			'lists': 'Listes',
			'street is required': 'Rue obligatoire',
			'house number is required': 'No de maison obligatoire',
			'country is required': 'Pays obligatoire',
			'cvv is required': 'CVV obligatoire',
			'connect': 'Se connecter',
			'connect_loyalty': 'Se connecter au club',
			'not all of the products given where found': 'Certains produits n’ont pas été trouvés',
			'not all of the given products were found': 'Certains produits n’ont pas été trouvés',
			'you have some missing products in your cart': 'Plusieurs produits de votre panier sont manquants',
			'select method': 'Choisissez une méthode de paiement',
			'call me when there is missing item': 'Appelez-moi si il manque un produit',
			'this email already exist': 'Adresse email déjà existante',
			'this email already exists': 'Adresse email déjà existante',
			'unable to create user': 'Impossible de créer l\'utilisateur',
			'km': 'km',
			'scanning failed: ': 'Échec du scan: ',
			'scan barcode': 'Scanner un produit',
			'please point your camera at the barcode.': 'Pointez la caméra sur le code-barres',
			'i confirm that i have read the': 'Je confirme avoir lu',
			'we are not able to add the following products to your current order': 'Nous ne pouvons pas ajouter ces produits à votre commande',
			'terms': 'le règlement',
			'payment by': 'Paiement par',
			'phone': 'Téléphone',
			'payment': 'Paiement',
			'shipping address': 'Adresse de livraison',
			'payment method': 'Moyen de paiement',
			'you save': 'Vous économisez ',
			'supervised': 'supervisé',
			'floor /\ apt': 'Étage /\ appartement',
			'open': 'Ouvert',
			'per lb': 'par kg',
			'lb': 'kg',
			'to see if we deliver to your area,': 'Pour voir les secteurs de livraison,',
			'click here': 'Cliquez ici',
			'we\'re available in': 'Nous livrons à',
			'checking delivery areas': 'Vérification des secteurs de livraison,',
			'loyalty club': 'Club client',
			'have a club member?': 'Membre du club existant ?',
			'join our members club': 'Adhérez à notre club client',
			'join the club': 'Adhérer au club',
			'connect to club': 'Se connecter au club',
			'member id': 'No d’ID',
			'club id': 'Numéro de club',
			'connect to your club account': 'Connectez-vous à votre club',
			'choose': 'Sélectionner',
			'choose your delivery area': 'Choisissez votre secteur de livraison',
			'delivery area': 'Secteur de livraison',
			'enter zip code': 'Saisissez un code postal',
			'mobile phone': 'Téléphone mobile',
			'personal id': 'No d’ID',
			'loyalty card id': 'No de carte-fidélité',
			'update club details': 'Actualiser les détails du club',
			'how do you want to get your shopping?': 'Comment souhaitez-vous recevoir votre commande ?',
			'home delivery': 'Livraison',
			'pickup': 'Retrait en magasin',
			'pick and go': 'Pick & Go',
			'pick & go': 'Pick & Go',
			'shipping details': 'Détails de livraison',
			'mobile': 'Mobile',
			'next': 'Suivant',
			'choose delivery time': 'Choisir un horaire de livraison',
			'shipping method & time': 'Moyen et horaire de livraison',
			'localdelivery': 'Livraison locale',
			'send the delivery sooner, if possible': 'Envoyer plus tôt, si possible',
			'sooner if possible': 'Dès que possible',
			'allow substitutions?': 'Remplacement de produits manquants',
			'select card': 'Choisir une carte',
			'your order will be delivered': 'Votre commande sera livrée le :',
			'you saved': 'Vous économisez ',
			'subtotal': 'Sous-total',
			'tax': 'Taxe',
			'estimated': 'Estimé',
			'only': 'Seulement',
			'contact details:': 'Coordonnées de contact',
			'place order': 'Commander',
			'place order using masterpass': 'Commander avec MasterPass',
			'sending your order...': 'Transfert de votre commande au magasin...',
			'please check your email for order details. thank you!': 'Un email avec les détails de votre commande vous a été envoyé',
			'back to home screen': 'Retour à la page d’accueil',
			'back to top': 'Retour en haut',
			'questions? get in touch': 'Des questions ? Contactez-nous',
			'current location:': 'Emplacement actuel :',
			'pickup location': 'Lieu de retrait',
			'click to choose pickup location': 'Choisir un lieu de retrait',
			'pickup time': 'Horaire de retrait',
			'contact & payment details': 'Détails de contact et de paiement',
			'your order will be ready': 'Votre commande sera prête',
			'shipping': 'Livraison',
			'delivery': 'Livraison',
			'call me': 'Appelez-moi',
			'delivery_type_1': 'Livraison',
			'delivery_type_2': 'Retrait',
			'delivery_type_3': 'Pick & Go',
			'delivery_type_5': 'Livraison express',
			'area_type_1': 'Livraison',
			'area_type_2': 'Livraison',
			'area_type_3': 'Livraison',
			'area_type_4': 'Livraison',
			'area_type_5': 'Retrait magasin',
			'sun': 'dim',
			'mon': 'lun',
			'tue': 'mar',
			'wed': 'mer',
			'thu': 'jeu',
			'fri': 'ven',
			'sat': 'sam',
			'the address you provided is not served at this time. please enter another address or choose pickup': 'Nous ne livrons pas encore à l\'adresse saisie, sélectionnez l\'option retrait magasin ou entrez une autre adresse',
			'we\'re  sorry, but the address you provided is not served at this time': 'Désolés, nous ne livrons pas encore à l\'adresse saisie',
			'change to pickup': 'Passez au retrait magasin',
			'can\'t find a product ?': 'Pas trouvé  ?',
			'can\'t find a product?': 'Vous ne trouvez pas le produit cherché ?',
			'if we are unfamiliar with the product or don\'t have it in stock, we will give you a call.': 'Saisissez le nom du produit et si nous ne le trouvons pas, nous vous contacterons',
			'product name': 'Nom du produit',
			'select category': 'Sélectionner une catégorie',
			'clear cart': 'Vider le panier',
			'menu': 'Menu',
			'customer id': 'Numéro de club',
			'congradulations for joining the customers club': 'Félicitations pour votre adhésion au club client',
			'your club member number': 'Votre No d’adhérent',
			'loyalty member': 'Chers adhérents au club',
			'you have connected successfully': 'Vous êtes connecté',
			'pay attention': 'Veuillez noter',
			'do you want to continue': 'Voulez-vous continuer',
			'continue without club discounts': 'Continuer sans les avantages adhérents',
			'return and pay with loyalty credit card': 'Retourner et payer avec la carte de crédit-fidélité.',
			'paying with different payment method will discard {clubname} club discounts': 'Les avantages adhérents de {clubname} sont valables seulement en payant avec la carte de crédit-fidélité. Le paiement par un autre mode de paiement annulera les avantages reçus',
			'join to club': 'Adhésion au club',
			'connect to club': 'Connexion au club',
			'join': 'Adhérer',
			'all the fields is required': 'Tous les champs sont requis',
			'did you mean?': 'Vouliez-vous dire ?',
			'callcustomer_description': 'Nous vous appellerons pour le règlement',
			'creditcard_description': 'Choisissez ou ajoutez une carte de crédit',
			'pay_creditcard_description': 'Payer par carte de crédit',
			'storecredit_description': 'Payer avec un compte client',
			'paypal_description': 'Payer avec Paypal',
			'icreditmasterpass_description': 'Payer avec un compte MasterPass',
			'select:': 'Sélectionnez :',
			'select': 'Sélectionnez :',
			'edit comment': 'Modifier un commentaire',
			'add comment': 'Ajouter un commentaire',
			'add a comment': 'Ajouter un commentaire',
			'write a comment': 'Écrire un commentaire',
			'type your comment here': 'Saisir un commentaire ici',
			'address 1': 'Rue + numéro',
			'to join': 'Pour adhérer',
			'join now': 'Adhérez maintenant',
			'register now to join our membership club': 'Inscrivez-vous au site et continuez en adhérant au club',
			'reset code': 'Code de réinitialisation',
			'reset password': 'Réinitialiser le mot de passe',
			'password reset': 'Réinitialisation du mot de passe',
			'a password recovery code has been sent to your email': 'Code de réinitialisation envoyé sur votre email',
			'please check your inbox and follow the instructions': 'Veuillez consulter  votre boîte de réception et suivre les instructions',
            'if you haven\'t received an email, please check if you mistyped your email address or contact customer service': 'Si vous n\'avez pas reçu de courriel, veuillez vérifier si vous avez saisi correctement votre adresse courriel ou contactez le service client',
			'est. weight': 'Poids estimé',
			'please confirm that you accept the terms and conditions for using this service': 'Veuillez confirmer que vous acceptez les conditions d’utilisation de l’application',
			'explanation': 'Explication',
			'invalid cvv': 'cvv erroné',
			'choose card': 'Choisir une carte',
			'invalid phone number': 'No de téléphone non valide',
			'invalid city': 'Ville non valide',
			'invalid address': 'Adresse non valide',
			'invalid street': 'Rue non valide',
			'invalid address 1': 'Rue et numéro non valides',
			'invalid zip code': 'Code postal non valide',
			'products are no longer available': 'Produits en rupture de stock',
			'payments number': 'Nombre de mensualités',
			'online grocery service': 'Service d\'épicerie en ligne',
			'download': 'Lancer l’installation',
			'get<br>the app': 'Installer<br>l’application',
			'branch not found': 'Désolé, magasin non trouvé',
			'missing premise or street number': 'No de maison obligatoire',
			'estimated tax': 'Taxe estimée',
			'estimated total': 'Paiement estimé',
			'contact us': 'Contactez-nous',
			'how can we help you?': 'Comment pouvons-nous vous aider ?',
			'how can we help you today?': 'Comment pouvons-nous vous aider ?',
			'submit': 'Envoyer',
			'invalid message': 'Message non valide',
			'invalid email': 'Email non valide',
			'your message was sent successfully': 'Message bien envoyé !',
			'we are sorry but this service is no longer available': 'Le service souhaité n’existe plus',
			'continue with this cart': 'Continuer avec le panier actuel',
			'merge carts': 'Fusionner les paniers',
			'would you like to merge the two carts or continue using this cart': 'Fusionner les deux paniers ou continuer à utiliser ce panier ?',
			'somewhere else': 'Ailleurs',
			'you have an open cart with': 'Vous avez un panier ouvert avec',
			'powered by': 'Créé par',
			'day0': 'Dima.',
			'day1': 'Lundi',
			'day2': 'Mardi',
			'day3': 'Merc.',
			'day4': 'Jeudi',
			'day5': 'Vend.',
			'day6': 'Same.',
			'to ': 'Pour ',
			'your order total {{areaname}}does not meet the {{minimum | currency}} minimum order total': 'Votre commande {{areaName}} n’a pas encore atteint le minimum requis de {{minimum | currency}}',
			'please add to your cart items worth {{difference | currency}} to proceed to checkout': 'Ajoutez au panier des produits d’une valeur de {{difference | currency}} pour terminer l’achat',
			'not including items on sale': 'Produits en promo non inclus',
			'please add additional products to the cart to proceed': 'Ajoutez des produits au panier pour continuer',

			'substitute_preference_1_label': 'Remplacez',
			'substitute_preference_1_description': 'Nous les remplacerons sans vous appeler',
			'substitute_preference_2_label': 'Appelez-moi',
			'substitute_preference_2_description': 'Nous vous téléphonerons pour les remplacer',
			'substitute_preference_3_label': 'Ne rien faire',
			'substitute_preference_3_description': 'Nous ne les remplacerons pas',
			'substitute_preference_4_label': 'SMS',
			'substitute_preference_4_description': 'Nous vous enverrons un SMS pour les remplacer',
			'substitute_preference_5_label': 'WhatsApp',
			'substitute_preference_5_description': 'Nous vous enverrons un WhatsApp pour les remplacer',
			'substitute_preference__label': 'pour éviter une erreur',
			'substitute_preference__description': 'pour éviter une erreur',
      'leave_by_the_door': "Laisser la livraison devant la maison si personne n’est là",
			'sorry, currently we don\'t deliver to this address.': 'Désolés, pour l’instant, nous ne livrons pas dans le secteur indiqué',
			'sorry, currently we don\'t deliver to this address, for further inquiries call to customer service.': 'Désolés, nous ne livrons pas ce secteur actuellement. Pour plus de détails n’hésitez pas à nous contacter.',
			'sorry, branch change required, this address could only be delivered from branch': 'Désolés, changez de magasin. La livraison à cette adresse est possible seulement depuis un magasin',
			'please enter a more accurate address to continue': 'Saisissez une adresse plus précise pour continuer',
			'switch branch': 'Changer de magasin',
			'try another address or choose pickup option': 'Essayez une autre adresse ou choisissez l\'option de retrait en magasin',
			'delivery fee': 'Frais de livraison',
			'pickup fee': 'Frais de retrait',
			'service fee': 'Frais de service',
			'add a comment here:': 'Ajouter des commentaires ici',
			'at': 'à',
			'next delivery': 'Prochaine livraison',
			'next pickup': 'Prochain retrait',
			'get it within': 'sera envoyée d’ici',
			'the following products were not saved correctly due to an error': 'Les produits suivants n\'ont pas été enregistrés, à cause d’une erreur',
			'hrs': 'heures',
			'coupon number': 'Code coupon',
			'coupon': 'Coupon',
			'please enter quantity': 'Saisissez une quantité',
			'please enter a name with less than 200 characters': 'Le nom ne peut contenir plus de 200 caractères',
			'we didn\'t recognize the address you entered': 'Nous ne reconnaissons pas l’adresse saisie',
			'continue anyway': 'Continuer quand même',
			'more details': 'Plus de détails',
			'product description': 'Description du produit',
			'ingredients': 'Ingrédients',
			'more information': 'Plus d\'infos',
			'directions': 'Description',
			'serving size': 'Taille d’une portion',
			'servings per container': 'Contenu',
			'amount per serving': 'Poids unitaire',
			'calories': 'Calories (énergie)',
			'calories from fat': 'Calories provenant des lipides',
			'daily value': 'Valeur quotidienne',
			'total fat': 'Matières grasses',
			'saturated fat': 'dont acides gras saturés',
			'trans fat': 'dont acides gras trans',
			'cholesterol': 'Cholestérol',
			'sodium': 'Sodium',
			'total carbohydrate': 'Glucides',
			'dietary fiber': 'Fibres alimentaires',
			'sugars': 'Sucres',
			'protein': 'Protéines',
			'vitamin a': 'Vitamine A',
			'vitamin c': 'Vitamine C',
			'calcium': 'Calcium',
			'iron': 'Fer',
			'g': 'g',
			'monounsaturated fat': 'Acides gras monoinsaturés',
			'polyunsaturated fat': 'Acides gras polyinsaturés',
			'percent daily': 'Les pourcentages de la consommation quotidienne sont basés sur un régime quotidien de 2 000 calories. Votre valeur quotidienne peut être élevée ou basse selon votre apport calorique.',
			'welcome': 'Bienvenue',
			'let\'s make sure we\'re available in your area': 'Vérifions notre présence dans votre secteur',
			'area': 'Ville',
			'check': 'Vérifier',
			'continue shopping': 'Continuer vos courses',
			'change area': 'Changement de secteur',
			'quantity limitation': 'Limite de quantité',
			'is limited to ': 'limité à ',
			'items only': 'articles seulement',
			'similar items': 'Produits similaires',
			'nutrition': 'Valeurs nutritionnelles',
			'description': 'Description du produit',
			'and': 'et',
			'and the': 'et la',
			'currently we do not deliver to your area': 'Pour l’instant, nous ne livrons pas dans votre secteur',
			'ticket_status_1': 'NOUVEAU',
			'ticket_status_2': 'En traitement',
			'ticket_status_3': 'Fin',
			'no previous inquiries': 'Aucune demande enregistrée',
			'join membership': 'Adhésion au club',
			'join club': 'Adhésion au club',
			'view club member details': 'Voir les détails d’adhérent au club',
			'thank you for signing up to {0} app': 'Merci d’avoir adhéré à l’application de {0}',
			'join our loyalty club to benefit from exclusive promotions': 'Adhérez à notre club client et profitez de promos exclusives',
			'connect to our loyalty club to benefit from exclusive promotions': 'Connectez-vous à notre club client et profitez de promos exclusives',
			'membership number': 'Numéro de club',
			'membership savings': 'Économie Adhérent',
			'could save': 'Vous auriez pu économiser',
			'customer service': 'Service Client',
			'expiration date': 'Date d’expiration',
			'comments': 'Commentaires',
			'send': 'Envoyer',
			'credit cards': 'Cartes de crédit',
			'drag files or browse...': 'Faire glisser les fichiers ici ou cliquer pour télécharger...',
			'you are now connected to your membership club account': 'Vous êtes connecté au club client',
			'remove credit card info': 'Suppression des détails de la carte de crédit',
			'are you sure you want to remove credit card information': 'Voulez-vous vraiment supprimer les détails de votre carte de crédit',
			'type in new request': 'Saisissez ici une nouvelle demande',
			'by': 'par',
			'from': 'Source',
			'read more': 'Lire plus',
			'read less': 'Lire moins',
			'recipe': 'Recette',
			'video': 'Vidéo',
			'min': 'Minutes',
			'invalid coupon code': 'Code coupon absent du système',
			'order status': 'État de la commande',
			'view order': 'Voir la commande',
			'track order no.': 'Suivi de commande No',
			'forgot something': 'Avez-vous oublié quelque chose',
            'update order': 'Mise à jour de la commande',
			'order received': 'Commande reçue',
			'in process': 'En préparation',
			'ready': 'Prête',
			'received': 'Reçue',
			'need help': 'Besoin d’aide',
			'edit order': 'Oui modifier la commande',
			'edit your order': 'Modifier la commande',
			'your order': 'Votre commande',
			'has been updated': 'a été actualisée',
			'are you sure you want to cancel your order update and return to your original order': 'Voulez-vous annuler la modification de la commande et revenir à votre commande d\'origine',
			'do you want to remove / add items to your existing order': 'Voulez-vous supprimer/ajouter des produits à votre commande en cours ',
			'note': 'Veuillez noter',
			'prices and specials will change according to the update date': 'Les prix et promos peuvent changer selon la date de mise à jour',
			'please check your email for order details': 'Veuillez consulter votre e-mail pour obtenir les détails de votre commande',
			'thank you': 'Merci',
			'we have started collecting your original order.': 'Nous avons commencé à préparer votre commande',
			'we have finished collecting your original order.': 'Nous avons fini de préparer votre commande',
			'your order cannot be changed at this time': 'La commande ne peut plus être modifiée',
			'your order can no longer be changed': 'La commande ne peut plus être modifiée',
			'cancel update': 'Annuler la modification',
			'canceling': 'Annulation',
			'update': 'Mise à jour',
			'replace shopping cart': 'Remplacer les produits du panier',
			'are you sure you want to replace your shopping cart with the order from': 'Voulez-vous remplacer les produits du panier par ceux que vous avez commandés le',
			'replace': 'Remplacer',
			'replace_2': 'Remplacer',
			'you can still edit your order': 'Vous pouvez encore modifier votre commande',
			'your original order is': 'Retour à',
			'cancel order update': 'Annulation de la MAJ de la commande',
			'in process ': 'votre commande d’origine',
			'your cart is empty': 'Votre panier est vide',
			'continue as new cart': 'Créer un nouveau panier',
			'you can move the selected items into a new order': 'Vous pouvez transférer les produits choisis dans une nouvelle commande',
			'my coupons': 'Mes coupons',
			'clip & add': 'Sélectionner',
			'clip': 'Sélectionner',
			'the site is undergoing maintenance': 'Site en cours de maintenance',
			'promotion details': 'Détails',
			'hide details': 'Masquer les détails',
			'show details': 'Afficher les détails',
			'the following products are not active, and were removed from the cart': 'Les produits suivants ne sont plus en stock et ont été retirés du panier',
			'once we complete picking your order, we adjust your final charge based on weight adjustments, substitutions & out of stock items': 'Pendant la préparation de la commande, le prix finale peut changer en raison des produits vendus au poids, remplacés ou en rupture de stock',
			'due to changes in address details': 'Cette adresse ne correspond pas à l’horaire de livraison choisi',
			'please select your delivery time again': 'Choisissez un nouvel horaire de livraison',
			'order': 'Commande',
			'order id': 'Numéro de commande',
			'pack items': 'Produits en pack',
			'how do you want to get': 'Comment voulez-vous recevoir',
			'cost': 'Prix',
			'express': 'Express',
			'no_deliveries_first_row': 'Désolés, nous n’avons pas trouvé',
			'no_deliveries_second_row': 'd’horaires de livraison ce jour-là',
			'no_pickup_first_row': 'Désolés, nous n’avons pas trouvé',
			'no_pickup_second_row': 'd’horaires de retrait ce jour-là',
			'add your comment here': 'Ajouter un commentaire',
			'substitutions': 'Produits manquants',
			'before_checkout_delivery_title_1': 'Sélectionnez',
			'before_checkout_delivery_title_2': 'Horaire de livraison souhaité',
			'before_checkout_pickup_title_1': 'Retirez',
			'before_checkout_pickup_title_2': 'votre commande du magasin',
			'your credit cards': 'Vos cartes de crédit',
			'add another card': 'Ajouter une carte de crédit',
			'order update': 'Mise à jour de la commande',
			'updated order value': 'Valeur de la commande mise à jour',
			'(estimated)': '(estimative)',
			'in order to complete the order update, please choose the payment method': 'Afin de finaliser la mise à jour de la commande, veuillez choisir un mode de paiement',
			'confirm update': 'CONFIRMER LA MISE À JOUR',
			'add your credit/debit card': 'Ajoutez vos cartes de crédit/débit',
			'use my delivery address as my billing address*': 'Utiliser mon adresse de livraison comme adresse de facturation*',
			'*by checking this option you approve transfer of your address data to the payment provider': 'En cochant cette option, vous acceptez le transfert de vos données d\'adresse au prestataire de paiement',
			'add another account': 'Ajouter un compte',
			'please enter a name': 'Saisissez un nom',
			'please enter a mobile phone': 'Saisissez un téléphone mobile',
			'please enter an address': 'Saisissez rue et numéro',
			'please enter house number': 'Saisissez un numéro de maison',
			'please enter city': 'Saisissez une ville',
			'please enter a correct phone number': 'Saisissez un numéro téléphone valide',
			'is an anti-fraud security feature to help verify that you are in possession of your credit card': 'Le code CVV (Credit Validation Value) est utilisé pour vérifier que la carte est à vous',
			'the three-digit cvv number is printed on the signature panel on the back of the card immediately after the card\'s account number': 'Les trois chiffres CVV sont imprimés au dos de la carte sur la bande de signature',
			'enter your credit on file id': 'Saisissez votre numéro de compte',
			'checkout_title': 'Finalisation',
			'cart no.': 'Panier Num.',
			'pick_and_go_title': 'Pick & Go',
			'no need to wait in line': 'Pas besoin de faire la queue',
			'we will take care of your': 'Nous nous occuperons',
			'cart and deliver': 'de votre chariot et de votre livraison',
			'to begin, pick a': 'Pour commencer, prenez',
			'barcode card': 'une carte code-barres',
			'start pick & go': 'Commencez votre Pick & Go',
			'are you sure you want to stop the': 'Êtes-vous sûr de vouloir arrêter le',
			'pick & go service': 'service \'Pick & Go\'',
			'stop pick & go': 'Arrêter Pick & Go',
			'please enter your credit on file id': 'Saisissez votre numéro de compte',
			'substitutions choice': 'Remplacement de produits',
			'home page': 'Page d’accueil',
			'thank you for': 'Merci',
			'shopping at {retailername}': 'd’avoir commandé chez {retailerName}',
			'an order summary email was sent to your inbox': 'Un e-mail récapitulatif de votre commande a été envoyé à votre adresse e-mail',
			'forgot': 'Vous avez oublié',
			'something': 'quelque chose',
			'1 hours delivery': 'Livraison en 1 h',
			'2 hours delivery': 'Livraison en 2 h',
			'{hours} hours delivery': 'Livraison en {hours} h',
			'{minutes} minutes delivery': 'Livraison en {minutes} minutes',
			'1 hour pickup': 'Retrait en 1 h',
			'2 hour pickup': 'Retrait en 2 h',
			'{hours} hour pickup': 'Retrait en {hours} h',
			'hours': 'heures',
			'within': 'dans les',
			'minutes': 'minutes',
			'pickup time and address': 'Horaire et adresse de retrait',
			'pickup details': 'Infos de retrait',
			'your order will be ready for pickup': 'Votre commande sera prête à retirer',
			'loading': 'Chargement',
			'paypal accounts': 'Compte Paypal',
			'create new order': 'Créer une commande',
			'create new shopping list': 'Créer une nouvelle liste',
			'add cart products': 'Ajouter les articles du panier',
			'create': 'Créer',
			'no shopping list created yet': 'Aucune liste de courses n’a encore été créée',
			'remove format': 'Supprimer le format',
			'since your address was not detected': 'Votre adresse n’étant pas reconnue',
			'please re-select a new delivery time': 'choisissez un nouvel horaire de livraison',
			'select delivery time': 'Choisir un horaire de livraison',
			'please try again': 'Réessayer',
			'add a credit card': 'Ajouter une carte de crédit',
			'add paypal account': 'Ajouter un compte PayPal',
			'it seems you are editing your order on a different device.': 'Vous modifiez déjà votre commande sur un autre appareil/navigateur',
			'note! it seems you have finished editing your order on a different device/browser.': 'Attention, vous avez déjà fini de modifier la commande sur un autre appareil/navigateur',
			'it seems you have finished your order on a different device.': 'La modification de votre commande est déjà terminée',
			'wee are currently updating this device too': 'Nous actualisons maintenant ce périphérique',
			'sorry, no available delivery time slots': 'Désolés, il ne reste plus d’horaires de livraison libres',
			'sorry, no available pickup time slots': 'Désolés, il ne reste plus d’horaires de retrait libres',
			'today\'s special deals': 'Promos du jour',
			'loyalty membership expired': 'Votre adhésion au club est expirée',
			'hello': 'Bonjour',
			'free': 'Gratuit',
			'your membership has expired': 'Votre adhésion au club est expirée',
			'your club membership has expired on': 'Votre adhésion au club est expirée le',
			'renew you membership and enjoy exclusive benefits and discounts': 'Renouvelez votre adhésion au club et profitez des avantages et offres dès cet achat',
			'enter a different number': 'Entrer un autre numéro de club',
			'no thanks, continue shopping': 'Continuer sans les avantages adhérents',
			'renew membership': 'Renouvellement de l\'adhésion',
			'your membership has been renewed successfully': 'Votre adhésion au club a été renouvelée',
			'you can enjoy from the club benefits in this order': 'Vous pouvez profiter des avantages adhérents dès cette commande',
			'loyalty_mode_1_label': 'Membre existant',
			'loyalty_mode_2_label': 'Adhésion au club',
			'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/club-icon.png': 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/club-icon.png',
			'please fill in membership number': 'Saisissez votre numéro d’adhérent',
			'{retailername} membership offers exclusive': 'L’adhésion au club {retailername} vous fait bénéficier d’',
			'promotions in-store and online': 'avantages, remises et offres uniques',
			'hi': 'Bonjour',
			'the items in your shopping cart will be cleared if you choose to edit your previous order': 'Votre panier actuel sera effacé si vous choisissez de modifier votre précédente commande',
			'edit order & clear cart': 'Vider le panier et modifier la commande',
			'it seems you are editing your order on a different device/browser.': 'Vous passez une commande sur un autre appareil/navigateur',
			'it seems you have finished editing your order on a different device/browser.': 'Vous avez fini de modifier la commande sur un autre appareil/navigateur',
			'do you want to add the product to this new order or to the order that is being updated?': 'Voulez-vous ajouter le produit à cette nouvelle commande ou à la commande en cours de modification',
			'note! you in the middle of editing order': 'Attention, vous êtes en train de modifier une commande',
			'you in the middle of editing order. changing store is impossible': 'Vous êtes en pleine modification de commande. Impossible de changer de magasin',
			'add to new order': 'Ajouter à une nouvelle commande',
			'add to updated order': 'Ajouter à une commande en modification',
			'we are not able to register your membership club account': 'Impossible d’adhérer au club',
			'please make sure your details are correct or try again later': 'Vérifiez que les infos sont corrects ou réessayer plus tard',
			'https://d226b0iufwcjmj.cloudfront.net/global/mobile/images/backgrounds/loyalty-titles.png': 'https://d226b0iufwcjmj.cloudfront.net/mobile/images/backgrounds/loyalty-titles.png',
			'special_exp': 'Fin promo',
			'special_exp_new': 'Fin promo',
			'ends': 'Fin promo',
			'forpackquantity': 'à',
			'verify_1': 'Interdiction de vente',
			'verify_2': 'aux mineurs',
			'verify_3': 'La vente de ce produit aux moins de',
			'verify_4': 'est prohibée',
			'verify_5': '',
			'verify_6': '',
			'verify_7': 'J’ai',
			'verify_8': 'plus de',
			'verify_9': 'J’ai moins de',
			'years': 'ans',
			'sorry': 'Désolés',
			'you cannot purchase': 'Vous ne pouvez pas acheter',
			'this item': 'ce produit',
			'please enter a valid email address': 'Saisissez un email valide',
			'password must include at least 6 characters': 'Le mot de passe doit contenir au moins 6 caractères',
			'password should not be longer than 30 characters' : 'Le mot de passe peut contenir jusqu’à 30 caractères',
			'password must contain at least one numeric digit and one character': 'Le mot de passe doit contenir au moins un chiffre et une lettre',

			'let\'s start': 'Commençons',
			'adding some items': 'Ajoutez des produits',

			'please notice': 'Veuillez noter',
			'the expiration of these': 'La validité des',
			'sales will not be valid for this chosen time': 'réductions affichées expirera le jour de livraison de la commande',
			'the special displayed below, will expire on the date you are supposed to receive your order': 'La promo ci dessous expirera le jour de livraison de la commande',
			'these items will be charged at their regular price': 'Ces produits seront facturés au tarif sans réduction',
			'this product will be charged the regular price, promotion will not be applied': 'Ce produit sera facturé au tarif sans réduction',
			'price of updated cart (est.)': 'Prix du panier actualisé (estimé)',

			'general specials': 'Promos de la chaîne',
			'loyalty specials': 'Promos Club',
			'category': 'Catégorie',
			'categories': 'Catégories',
			'special type': 'Type de promo',
			'clear all': 'Tout effacer',
			'filter by': 'Filtrer par',
			'sort by': 'Trier par',
			'type': 'Type',
			'our top picks': 'Nos recommandations',
			'membership deals': 'Promos Club',
			'it\'s easy!': 'C\'est facile !',
			'please sign in to continue...': 'Connectez-vous au site pour continuer...',
			'e-mail': 'Email',
			'sign in': 'Connexion',
			'club member name': 'Nom de membre du club',
			'{clubname} special deals': 'Promos de {clubname}',
			'membership_club': 'Club',
			'join our membership and enjoy exclusive benefits and promotions': 'Adhérez au club client et bénéficiez d\'avantages uniques',
			'identify': 'Identification',
			'join our membership club': 'Adhérer au club client',
			'activate': 'Activer',
			'please activate credit card {xxxx} in order to continue': 'Activez la carte de crédit terminée par les chiffres {xxxx} pour continuer',
			'membership_title_register': 'Adhérez maintenant ',
			'membership_title_identify': 'Identifiez-vous',
			'membership_title_{clubname}': 'au club {clubname}',
			'membership_{clubname}_title': 'au club {clubname}',
			'to complete your purchase, join/connect to {clubname} membership account': 'Pour finaliser votre achat, inscrivez/connectez-vous au club client de {clubName}',
			'in order to complete your purchase, you must connect to the loyalty club': 'Pour finaliser votre achat, vous devez vous connecter au club client',
			'stores info': 'Détails des magasins',
			'store info': 'Détails du magasin',
			'pickup address': 'Adresse de retrait',
			'select address': 'Choisir une adresse',
			'buy': 'Achetez',
			'please enter e-mail': 'Saisissez un email',
			'menu_stores_info': 'Liste de magasins',
			'menu_store_info': 'Détails du magasin',
			'your order is being edited by another device': 'Votre commande est en train d\'être modifiée sur un autre appareil',
			'organization': 'Organisation',
			'organization_description': 'Paiement à crédit',
			'enter order confirmation no.': 'Saisissez le no de confirmation de commande',

			'icreditmasterpass': 'MasterPass',

			'friend\'s email': 'Email d’un/e amie/e',
			'or share your promo code': 'ou partagez le code promo',
			'congratulations': 'Spécialement pour vous',
			'shop now': 'Commencer à acheter',
			'copy': 'Copier',
			'no, thanks': 'Non, merci',
			'got it': 'Merci, c’est compris',
			'invitation sent successfully': 'L’invitation a été envoyée',
			'promo code unidentified': 'Code promo non trouvé',
			'enter your promo code': 'Saisir un code promo',
			'there are no coupons currently available': 'Aucun coupon disponible pour le moment',

			'express delivery is allowed only with products that contain express delivery tag': 'Livraison express possible seulement avec les produits marqués comme tels',
			'express delivery products limit exceeded': 'Quantité de produits supérieure à la limite de livraison express',
			'this service is available for express items only. removing non express items from your cart will enable using this option': 'Ce service n\'est disponible que pour les produits marqués en livraison express. Supprimer les produits non marqués permettra cette option',
			'express delivery is limited to': 'Livraison express limitée à',
			'items. to enable this service, remove items from your cart': 'articles seulement. Pour permettre cette option, supprimer des produits du papier',

			'buy_x_for_y': '{{purchaseTotal}} en promo',

			/* pele card errors*/
			'permitted transaction.': 'Transaction permise.',
			'the card is blocked, confiscate it.': 'Carte bloquée. Confisquez-la.',
			'the card is stolen, confiscate it.': 'Carte volée. Confisquez-la.',
			'contact the credit company.': 'Contacter la compagnie de crédit.',
			'refusal by credit company.': 'Refus de la compagnie de crédit. CVV non valide ou autre.',
			'the card is forged, confiscate it.': 'Carte fausse. Confisquez-la.',
			'incorrect cvv/id.': 'Le numéro d\'identité ou le CVV ne correspond pas au numéro de carte.' /*'La société de crédit doit être appelée.'*/,
			'an error occurred while building access key for blocked card files.': 'Impossible de créer une clé d\'accès au fichier des cartes bloquées.',
			'no communication. please try again or contact system administration': 'Impossible d\'appeler.',
			'the program was stopped by user`s command (esc) or com port can\'t be open (windows)': 'Logiciel arrêté sur ordre de l\'opérateur (ESC) ou PORT COM non ouvrable (WINDOWS).',
			'the acquirer is not authorized for foreign currency transactions': 'Débit en devises étrangères non autorisé pour l’acheteur.',
			'this card is not permitted for foreign currency transactions': 'Débit en devises étrangères non autorisé pour la carte.',
			'this card is not supported.': 'Carte non prise en charge.',
			'track 2 (magnetic) does not match the typed data.': 'Le numéro saisi et la bande magnétique ne correspondent pas.',
			'additional required data was entered/not entered as opposed to terminal settings (z field).': 'Les données supplémentaires ne sont pas/sont contraires aux paramètres du terminal.',
			'last 4 digits were not entered (w field).': '4 derniers chiffres non saisis.',
			'entry in int_in file is shorter than 16 characters.': 'Une entrée du fichier INT_IN a moins de 16 caractères.',
			'the input file (int_in) does not exist.': 'Le fichier d\'entrée (IN_INT) n\'existe pas.',
			'blocked cards file (neg) does not exist or has not been updated, transmit or request authorization for each transaction.': 'Le fichier des cartes bloquées (NEG) n\'existe pas ou est obsolète - Envoyez ou demandez une approbation pour chaque transaction.',
			'one of the parameter files/vectors does not exist.': 'L\'un des fichiers de paramètres ou de vecteurs n\'existe pas.',
			'date file (data) does not exist.': 'Le fichier de dates (DATA) n\'existe pas.',
			'format file (start) does not exist.': 'Le fichier de démarrage (START) n\'existe pas.',
			'the difference in days in the blocked cards input is too large, transmit or request authorization for each transaction.': 'L\'écart de jours dans l\'entrée des cartes bloquées est trop grand - Envoyez ou demandez une approbation pour chaque transaction.',
			'the difference in generations in the blocked cards input is too large, transmit or request authorization for each transaction.': 'L\'écart de générations dans l’entrée des cartes bloquées est trop grand - Effectuez une diffusion ou demandez l\'approbation pour chaque transaction.',
			'when the magnetic strip is not completely entered, define the transaction as a telephone number or signature only.': 'Lorsque la bande magnétique n\'est pas entièrement entrée, définissez la transaction comme téléphonique ou par signature uniquement.',
			'the central terminal number was not entered into the defined main supplier terminal.': 'Le numéro du terminal central n\'a pas été saisi dans le terminal défini pour le travail comme fournisseur principal.',
			'the beneficiary number was not entered into the defined main beneficiary terminal.': 'Le numéro de bénéficiaire n\'a pas été saisi dans le terminal défini pour le travail comme bénéficiaire principal.',
			'the supplier/beneficiary number was entered, however the terminal was not updated as the main supplier/beneficiary.': 'Terminal qui n’est pas défini comme fournisseur/bénéficiaire principal, mais un numéro de fournisseur/bénéficiaire a été saisi.',
			'the beneficiary number was entered, however the terminal was updated as the main supplier': 'Terminal défini comme fournisseur principal et un numéro de fournisseur a aussi été saisi.',
			'old transactions, transmit or request authorization for each transaction.': 'Transactions anciennes - Transmettez ou demandez une autorisation pour chaque transaction.',
			'defective card.': 'Erreur de saisie de la carte de crédit, réessayez.' /*'Carte non prise en charge.'*/,
			'this card is not permitted for this terminal or is not authorized for this type of transaction.': 'Cette carte n\'est pas autorisée sur ce terminal ou pour ce type de transaction.',
			'this card is not permitted for this transaction or type of credit.': 'Cette carte ne peut pas effectuer de transaction avec ce type de crédit.',
			'expired card.': 'Expirée.',
			'expired token': 'Lien expiré',
			'installment error, the amount of transactions needs to be equal to: first installment plus fixed installments times number of installments.': 'Erreur de paiement - Le montant de la transaction doit être égal au premier paiement + (paiement fixe X nombres de paiements).',
			'unable to execute a debit transaction that is higher than the credit card`s ceiling.': 'Impossible d\'exécuter une transaction de supérieure au plafond de la carte de débit.',
			'incorrect control number.': 'Erreur de saisie de la carte de crédit, réessayez.' /*'numéro de contrôle incorrect.'*/,
			'the beneficiary and supplier numbers were entered, however the terminal is defined as main.': 'Terminal défini comme bénéficiaire principal, un numéro de fournisseur a été saisi.',
			'the transaction`s amount exceeds the ceiling when the input file contains j1, j2 or j3 (contact prohibited).': 'Dépasse le plafond lorsque le fichier d\'entrée contient J1, J2 ou J3 (interdit de téléphoner).',
			'the card is blocked for the supplier where input file contains j1, j2 or j3 (contact prohibited).': 'Carte bloquée pour le fournisseur lorsque le fichier d\'entrée contient J1, J2 ou J3 (interdit de téléphoner).',
			'random where input file contains j1 (contact prohibited).': 'Aléatoire lorsque le fichier d\'entrée contient J1 (interdit de téléphoner).',
			'the terminal is prohibited from requesting authorization without transaction (j5).': 'Terminal non autorisé à demander une autorisation sans transaction (5J).',
			'the terminal is prohibited for supplier-initiated authorization request (j6).': 'Terminal non autorisé à demander une autorisation initiée par le commerce (6J).',
			'the terminal must request authorization where the input file contains j1, j2 or j3 (contact prohibited).': 'Terminal tenu de demander une autorisation lorsque le fichier d\'entrée contient J1, J2 ou J3 (interdit de téléphoner).',
			'secret code must be entered where input file contains j1, j2 or j3 (contact prohibited).': 'Le code secret doit être saisi lorsque le fichier d\'entrée contient J1, J2 ou J3 (interdit de téléphoner).',
			'incorrect vehicle number.': 'No de véhicule non valide',
			'the number of the distance meter was not entered.': 'Odomètre non saisi.',
			'the terminal is not defined as gas station (petrol card or incorrect transaction code was used).': 'Terminal non configuré comme une station-service (carte d’essence ou code de transaction utilisé non adapté).',
			'an id number is required (for israeli cards only) but was not entered.': 'Aucun numéro d’identité saisi',
			'cvv is required but was not entered.': 'Aucun CVV saisi',
			'cvv and id number are required (for israeli cards only) but were not entered.': 'Ni numéro d’identité ni CVV saisi',
			'abs attachment was not found at the beginning of the input data in memory.': 'Fichier joint ABS introuvable au début des données d’entrée en mémoire.',
			'the card number was either not found or found twice.': 'Numéro de carte introuvable ou trouvé deux fois.',
			'incorrect transaction type.': 'Type de transaction incorrect.',
			'incorrect transaction code.': 'Code de transaction incorrect.',
			'incorrect credit type.': 'Type de crédit incorrect.',
			'incorrect currency.': 'Devise incorrecte.',
			'the first installment and/or fixed payment are for non-installment type of credit.': 'Il y a un premier versement ou paiement fixe pour un type de crédit autre que les mensualités.',
			'number of installments exist for the type of credit that does not require this.': 'Il y a un paiement fixe pour un type de crédit qui n’en a pas besoin.',
			'linkage to dollar or index is possible only for installment credit.': 'Impossible d’indexer au dollar/coût de la vie pour un type de crédit autre que les mensualités.',
			'the magnetic strip is too short.': 'La bande magnétique est trop courte.',
			'the pin code device is not defined.': 'Aucun périphérique défini pour saisir un code secret.',
			'must enter the pin code number.': 'Un code secret doit être saisi.',
			'smart card reader not available - use the magnetic reader.': 'Lecteur de cartes à puce indisponible - Utilisez le lecteur magnétique',
			'must use the smart card reader.': 'Il est obligatoire d’utiliser le lecteur de cartes à puce',
			'denied - locked card.': 'Refus - Carte verrouillée',
			'denied - smart card reader action didn\'t end in the correct time.': 'Refus - l\'action avec le lecteur de cartes à puce ne s’est pas terminée à temps',
			'denied - data from smart card reader not defined in system.': 'Refus - les données obtenues avec le lecteur de cartes à puce ne sont pas définies dans le système',
			'incorrect pin code.': 'Code secret saisi erroné.',
			'currency does not exist in vector 59.': 'La devise n\'existe pas dans le vecteur 59.',
			'the club code entered does not match the credit type.': 'Le \"code du club\" entré ne correspond pas au type de crédit.',
			'cannot cancel charge transaction. please create a credit transaction.': 'Transaction d\'annulation interdite sur la carte. Effectuez une transaction de de crédit.',
			'unable to read/write/open the tran file.': 'Impossible de lire/écrire/ouvrir le fichier TRAN.',
			'no authorization from credit company for clearance.': 'La carte saisie n\'est pas prise en charge par l\'entreprise.' /*'Pas d’autorisation de débiter de la compagnie de crédit.'*/,
			'the terminal is not permitted to send queries for immediate debit cards.': 'Le terminal n\'est pas autorisé à demander un crédit de débit instantané.',
			'the transaction amount is too large, divide it into a number of transactions.': 'Montant de transaction trop grand - Divisez en plusieurs transactions.',
			'the terminal is not authorized to execute forced transactions.': 'Le terminal n\'est pas autorisé à effectuer des transactions forcées.',
			'the terminal is not authorized for cards with service code 587.': 'Le terminal n\'a pas d’autorisation pour une carte avec code de service 587.',
			'the terminal is not authorized for immediate debit cards.': 'Le terminal n\'a pas d’autorisation pour une carte de débit instantané.',
			'the terminal is not authorized for installment transactions.': 'Le terminal n\'a pas d’autorisation pour une transaction en plusieurs mensualités.',
			'the terminal is authorized for installment transactions only, not telephone/signature.': 'Le terminal n\'a pas d’autorisation pour une transaction par téléphone/signature seulement en plusieurs mensualités.',
			'the terminal is not authorized for telephone transactions.': 'Le terminal n\'a pas d’autorisation pour une transaction par téléphone.',
			'the terminal is not authorized for signature-only transactions.': 'Le terminal n\'a pas d’autorisation pour une transaction par téléphone/signature seulement.',
			'the terminal is not authorized for foreign currency transactions, or transaction is not authorized.': 'Le terminal n\'a pas d’autorisation pour une transaction en dollars.',
			'the terminal is not authorized for club transactions.': 'Le terminal n\'a pas d’autorisation pour une transaction de club.',
			'the terminal is not authorized for star/point/mile transactions.': 'Le terminal n\'a pas d’autorisation pour une transaction par étoiles/points/miles.',
			'the terminal is not authorized for amex credit.': 'Le terminal n\'a pas d’autorisation pour un crédit Amex.',
			'the terminal is not authorized for dollar linkage.': 'Le terminal n\'a pas d’autorisation pour indexer au dollar.',
			'the terminal is not authorized for index linkage.': 'Le terminal n\'a pas d’autorisation pour indexer au coût de la vie.',
			'the terminal is not authorized for index linkage with foreign cards.': 'Le terminal n\'a pas d’autorisation pour indexer au coût de la vie les cartes étrangères.',
			'the terminal is not authorized for star/point/mile transactions for this type of credit.': 'Le terminal n\'a pas d\'autorisation pour une transaction par étoiles/points/miles pour ce type de crédit.',
			'the terminal is not authorized for isra 36 credit.': 'Le terminal n\'a pas d’autorisation pour un crédit Isra 36.',
			'the terminal is not authorized for amex 36 credit.': 'Le terminal n\'a pas d’autorisation pour un crédit Amex 36.',
			'the terminal is not authorized for this club code.': 'Le terminal n\'a pas d’autorisation pour ce code de club.',
			'the terminal is not authorized for immediate debit transactions (except for immediate debit cards).': 'Le terminal n\'a pas d’autorisation pour une transaction de débit immédiat, sauf pour les cartes de débit immédiat.',
			'the terminal is not authorized to accept visa card staring with 3.': 'Le terminal n\'est pas autorisé à recevoir les cartes de crédit commençant par 3.',
			'the terminal is not authorized to execute credit transactions above the ceiling.': 'Le terminal n\'est pas autorisé à effectuer une transaction négative supérieure au plafond.',
			'the card is not permitted to execute club transactions.': 'Cette carte n\'est pas autorisée à effectuer des transactions de club.',
			'the card is not permitted to execute star/point/mile transactions.': 'Cette carte n\'est pas autorisée à effectuer des transactions par étoiles/points/miles.',
			'the card is not permitted to execute dollar transactions (regular or telephone).': 'Cette carte n\'est pas autorisée à effectuer des transactions en dollars (normales ou par téléphone).',
			'the card is not valid according to isracard`s list of valid cards.': 'Carte non valide d’après la liste des cartes valides d’Isracard.',
			'defective card according to system definitions (isracard vector1), error in the number of figures on the card.': 'Carte non valide d’après la configuration du système (VECTOR1 d\'Isracard) - Nombre de chiffres sur la carte incorrect.',
			'the card is not permitted to execute dollar transactions according to system definitions (isracard vector1).': 'Carte non autorisée à effectuer des transactions en dollars d’après la configuration du système (VECTOR1 d\'Isracard).',
			'the card belongs to a group that is not permitted to execute transactions according to system definitions (visa vector 20).': 'La carte appartient à un groupe de cartes non autorisées à effectuer des transactions d’après la configuration du système (VECTOR20 de Visa).',
			'the card`s prefix (7 figures) is invalid according to system definitions (diners vector21).': 'Le préfixe de la carte (7 chiffres) n\'est pas valide selon la configuration du système (VECTOR21 de Diners).',
			'the card is not permitted to carry out installment transactions according to isracard`s list of valid cards.': 'Carte non autorisée à effectuer des transactions en plusieurs mensualités d’après la liste des cartes valides d’Isracard.',
			'the number of installments is too large according to isracard`s list of valid cards.': 'Trop grand nombre de mensualités d’après la liste des cartes valides d’Isracard.',
			'visa and diners cards are not permitted for club installment transactions.': 'Les cartes Visa et Diners ne sont pas autorisées à effectuer des transactions de club en plusieurs mensualités.',
			'series of cards are not valid according to system definition (isracard vector5).': 'Série de cartes non valide selon la configuration du système (VECTOR5 d\'Isracard).',
			'invalid service code according to system definitions (isracard vector6).': 'Code de service non valide selon la configuration du système (VECTOR6 d\'Isracard).',
			'the card`s prefix (2 figures) is invalid according to system definitions (isracard vector7).': 'Le préfixe de la carte (2 chiffres) n\'est pas valide selon la configuration du système (VECTOR7 d’Isracard).',
			'invalid service code according to system definitions (visa vector12).': 'Code de service non valide selon la configuration du système (VECTOR12 de Visa).',
			'invalid service code according to system definitions (visa vector13).': 'Code de service non valide selon la configuration du système (VECTOR13 de Visa).',
			'immediate debit card is prohibited for executing credit transaction.': 'Une carte de débit immédiat n’est pas autorisée à effectuer une transaction de crédit.',
			'the card is not permitted to execute installment transactions according to alpha vector no. 31.': 'Carte non autorisée à effectuer des transactions en plusieurs mensualités selon Vector 31 de Leumi Card.',
			'the card is not permitted for telephone and signature-only transactions according to alpha vector no. 31.': 'Carte non autorisée à effectuer des transactions par téléphone et signature uniquement selon Vector 31 de Leumi Card.',
			'the card is not permitted for telephone transactions according to alpha vector no. 31.': 'Carte non autorisée à effectuer des transactions par téléphone selon Vector 31 de Leumi Card.',
			'credit is not approved for immediate debit cards.': 'Crédit non autorisé pour les cartes de débit immédiat.',
			'credit is not approved for foreign cards.': 'Crédit non autorisé pour les cartes étrangères.',
			'incorrect club code.': 'Code de club non valide.',
			'the card is not permitted to execute flexible credit transactions (adif/30+) according to system definitions (diners vector21).': 'Carte non autorisée à effectuer des transactions de crédit flexible (adif/30+) selon la configuration du système (VECTOR21 de Diners).',
			'the card is not permitted to execute immediate debit transactions according to system definitions (diners vector21).': 'Carte non autorisée à effectuer des transactions de débit immédiat selon la configuration du système (VECTOR21 de Diners).',
			'the payment amount is too low for credit transactions.': 'Montant du paiement trop faible pour une transaction de crédit.',
			'incorrect number of installments for credit transaction.': 'Nombre de mensualités erroné pour une transaction de crédit.',
			'zero ceiling for this type of card for regular credit or credit transaction.': 'Plafond 0 pour ce type de carte pour une transaction avec crédit normal ou crédit.',
			'zero ceiling for this type of card for immediate debit credit transaction.': 'Plafond 0 pour ce type de carte pour une transaction avec un crédit de débit immédiat.',
			'zero ceiling for this type of card for immediate debit in dollars.': 'Plafond 0 pour ce type de carte pour une transaction de débit immédiat en dollars.',
			'zero ceiling for this type of card for telephone transaction.': 'Plafond 0 pour ce type de carte pour une transaction par téléphone.',
			'zero ceiling for this type of card for credit transaction.': 'Plafond 0 pour ce type de carte pour une transaction négative.',
			'zero ceiling for this type of card for installment transaction.': 'Plafond 0 pour ce type de carte pour une transaction en plusieurs mensualités.',
			'american express card issued abroad not permitted for instalments transaction.': 'Une carte American Express émise à l\'étranger n’est pas autorisée à effectuer des transactions en plusieurs mensualités.',
			'jcb cards are only permitted to carry out regular credit transactions.': 'Les cartes JCB sont seulement autorisées à effectuer des transactions de crédit normal.',
			'the amount in stars/points/miles is higher than the transaction amount.': 'Le montant en étoiles/points/miles est supérieur au montant de la transaction.',
			'the club card is not within terminal range.': 'La carte club n\'est pas dans la plage du terminal.',
			'star/point/mile transactions cannot be executed.': 'Impossible d’effectuer des transactions par étoiles/points/miles en dollars.',
			'dollar transactions cannot be executed for this type of card.': 'Le terminal n’a pas d’autorisation pou une transaction en dollars avec ce type de crédit.',
			'credit transactions cannot be executed with other than regular credit.': 'Impossible d’effectuer transaction négative avec un crédit différent de la normale.',
			'amount of discount on stars/points/miles is higher than the permitted.': 'Montant de la réduction en étoiles/points/miles supérieur à ce qui est autorisé.',
			'forced transactions cannot be executed with credit/immediate debit card.': 'Impossible d\'exécuter une transaction forcée avec une carte de crédit/débit immédiat.',
			'the previous transaction cannot be cancelled (credit transaction or card number are not identical).': 'La transaction précédente ne peut pas être annulée (transaction négative ou numéro de carte différent).',
			'double transaction.': 'Transaction double.',
			'the terminal is not permitted for index linkage of this type of credit.': 'Le terminal n\'a pas d’autorisation d’indexation au coût de la vie pour ce crédit.',
			'the terminal is not permitted for dollar linkage of this type of credit.': 'Le terminal n\'a pas d’autorisation d’indexation au dollar pour ce crédit.',
			'the card is invalid according to system definitions (isracard vector1).': 'Carte non valide selon la configuration du système (Vector 1 d’Isracard).',
			'unable to execute the self-service transaction if the gas station does not have self service.': 'Stations-service Impossible d’exécuter en \"libre-service\" mais plutôt en \"libre-service\" dans les stations-service.',
			'credit transactions are forbidden with stars/points/miles.': 'Les étoiles/points/miles ne sont pas autorisés avec les transactions négatives',
			'dollar credit transactions are forbidden on tourist cards.': 'Les transactions négatives en dollars sont interdites sur les cartes-touriste.',
			'phone transactions are not permitted on club cards.': 'Les transactions par téléphones sont interdites avec les cartes-adhérent.',
			'application error.': 'Erreur d\'application.',
			'transaction amount missing or zero.': 'Montant de la transaction manquant ou nul.',
			'no communication to pelecard.': 'Aucune communication avec pelecard.',
			'doubled transaction.': 'Transaction doublée.',
			'terminal number does not exist.': 'Numéro de terminal inexistant.',
			'terminal executes broadcast and/or updating data. please try again later.': 'Le terminal exécute la diffusion ou la mise à jour de données, réessayez ultérieurement.',
			'user name and/or password not correct. please call support team.': 'Nom d\'utilisateur ou mot de passe incorrects, contactez l\'assistance.',
			'user password has expired. please contact support team.': 'Mot de passe de l\'utilisateur expiré, contactez l\'assistance.',
			'please enter confirm password': 'Confirmez le mot de passe',
			'the passwords must match': 'Les mots de passe doivent être identiques',
			'locked user. please contact support team.': 'Utilisateur verrouillé, contactez l\'assistance.',
			'blocked terminal. please contact account team.': 'Terminal bloqué, contactez la comptabilité.',
			'token number abnormal.': 'Numéro de token invalide.',
			'user is not authorized in this terminal.': 'Utilisateur non autorisé à effectuer des actions sur ce terminal.',
			'validity structure invalid. use mmyy structure only.': 'Structure de validité non valide, seule la structure MMAA doit être utilisée.',
			'ssl verifying access is blocked. please contact support team.': 'Accès au certificat SSL bloqué, contactez l\'assistance.',
			'data not exist.': 'Aucune donnée n\'existe.',
			'general error. please contact support team.': 'Erreur générale, contactez l\'équipe d\'assistance.',
			'necessary values are blocked/wrong.': 'Valeurs nécessaires manquantes/erronées.',
			'general error. repeat action.': 'Erreur générale, contactez l\'équipe d\'assistance.',
			'necessary values missing to complete installments transaction': 'Valeurs nécessaires manquantes pour une transaction en mensualités.',
			/*end pele card errors*/

			'removed': 'Supprimé',

			'type in zip code': 'Saisissez un code postal',
			'type in city': 'Saisissez une ville de livraison',
			'type your address': 'Saisissez une adresse',
			'or choose branch for pickup': 'ou sélectionnez le retrait magasin',
			'pickup the order from one of the branches at your time': 'Retirez la commande dans l’un de nos magasins à l’heure qui vous convient',
			'we serve your area': 'Nous desservons votre secteur',
			'<< back': '<< Retour',
			'limited to {deliveryitemslimit} products': 'Limité à {deliveryItemsLimit} produits',
			'has no items limit': 'Sans limite de produits',
			'choose the pickup location': 'Choisissez le point de retrait',
			'products limit': 'Limites de produits',
			'this delivery has a limit of {deliveryitemslimit} products': 'Cette livraison comporte une limite de {deliveryItemsLimit} produits',
			'if you wish to add another product, remove one from the cart first': 'Si vous souhaitez ajouter un produit de plus, retirez-en d\'abord un autre du panier',
			'continue': 'Continuer',
			'or change the delivery method': 'ou changez de méthode de livraison',
			'change delivery method': 'Changer de méthode de livraison',
			'are you sure you want to change the delivery type': 'Sûr de vouloir changer de méthode de livraison',
			'changes in delivery times may apply': 'Les horaires de livraison peuvent varier',
			'changes in inventory between the stores may apply': 'Les stocks peuvent varier d’un magasin à l’autre',
			'yes, change delivery method': 'Oui, changer de méthode de livraison',
			'no, stay in current delivery': 'Non, rester dans cette livraison',
			'unfortunately we didn\'t find any stores in this area': 'Désolés, nous n’avons pas trouvé de magasin dans ce secteur',
			'try a different area name': 'Essayez un autre secteur',
			'please type a valid address': 'Saisissez une adresse valide',
			'please add street and house number': 'Ajoutez une rue et un numéro',
			'delivery method in this area': 'Type de livraison dans ce secteur',
			'apologies': 'Toutes nos excuses',
			'sorry, we do not deliver to your area': 'Pour l’instant, nous ne livrons pas dans votre secteur',
			'please type in your email and we will let you know when we expand to your location': 'Laissez-nous votre email, nous vous ferons savoir dès que nous livrerons votre localisation',
			'try a different address': 'Essayez une autre adresse',
			'try a different city': 'Essayez une autre ville',
			'try a different zip code': 'Essayez un autre code postal',
			'keep in touch': 'Informez-moi',
			'your chosen delivery type is': 'Vous avez choisi de recevoir votre commande en',
			'change to ': 'Changer par ',
			'branch': 'Magasin',
			'from branch': 'du magasin',
			'delivery products limit exceeded': 'Limite de produits dépassée',
			'see you soon': 'A très vite ',
			'the order will wait for you at the store': 'La commande vous attendra prête et emballée',
			'nagish_close_component': 'Masquer',
			'chametz': 'Hametz',
			'passoverkosher': 'Casher LePessah toute l’année',
			'passoverkoshertemporarily': 'Casher LePessah temporaire',
			'passoverkitniyotkosher': 'Casher LePessah toute l’année pour les Okhlei Kitnyot',
			'passoverkitniyotkoshertemporarily': 'Kasher LePessah temporaire toute l’année pour les Okhlei Kitnyot',
			'meat': 'Kasher Viande',
			'milky': 'Kasher Lait',
			'pareve': 'Kasher Parvé',
			'israelmilk': 'Kasher Lait surveillé',
			'israelcooking': 'Bishoul Israël',
			'sabbathobservingfactory': 'Usine respectant Chabbat',
			'noshviitconcern': 'Sans risque de Cheviit',
			'noorlaconcern': 'Sans risque de Orla',
			'notevelconcern': 'Sans risque de Tével',
			'noovernightconcern': 'Sans risque de Leinat Layla',
			'nogeniza': 'N’a pas besoin de Gueniza',
			'recipes and allergies': 'Ingrédients et allergènes',
			'list of ingredients': 'Liste d’ingrédients',
			'allergies contained': 'Contient des allergènes',
			'possible allergies': 'Peut contenir des allergènes',
			'about the product': 'À propos du produit',
			'extra info and instructions': 'Infos additionnelles et instructions',
			'supervisions': 'Cacheroute',
			'usage warnings': 'Avertissements d’utilisation et sécurité',
			'storage instructions': 'Instructions de stockage',
			'usage instructions': 'Instructions de préparation et d’utilisation du produit',
			'fat percentage': 'Taux de graisse',
			'cream percentage': 'Taux de crème',
			'fruit percentage': 'Taux de fruit',
			'alcohol percentage': 'Taux d’alcool',
			'ph': 'Potentiel hydrogène (pH)',
			'country of origin': 'Pays de production',
			'date taken into effect': 'Date d\'entrée en vigueur',
			'height': 'Hauteur',
			'depth': 'Profondeur',
			'width': 'Largeur',
			'net width': 'Poids net',
			'gross width': 'Poids brut',
			'general information': 'Détails généraux',
			'nutrition values': 'Valeurs nutritionnelles',
			'the waiting period will not exceed two hours': 'Livraison dans les 2 heures après l’horaire choisi',
			'at_hour': 'À l’heure',
			'special_reminder_bubble_title': 'Économisez !',
			'special_reminder_awarded_bubble_title': 'Vous économisez !',
			'special_reminder_button_title': 'Cliquez et économisez !',
			'add products to earn this promotion': 'Ajoutez des produits pour profiter de la promo',
			'done': 'Fin',
			'select your benefit': 'Choisissez votre avantage',
			'show more specials': 'Voir plus de promos',
			'show less specials': 'Voir moins de promos',
			'coupon_requirements_error': 'Conditions du coupon non remplies',
			'add products': 'Ajoutez des produits',
			'remaining credit': 'Solde du budget',
			'budget': 'Budget',
			'cart amount higher': 'Montant du panier élevé',
			'than your credit': 'Du solde du budget il reste',
			'edit cart': 'Modifier le panier',
			'cart total': 'Total du panier',
			'to complete the purchase, remove products from the cart valued at': 'Pour terminer l\'achat, retirez des produits du panier d\'une valeur de',
			'this user is not set as credit customer': 'Client non défini comme client à crédit',
			'cart amount higher than your credit. to proceed please remove at least': 'Montant du panier supérieur au crédit du client. Pour terminer, vous devez retirer des produits pour au moins',
			'please verify that you are not a robot': 'Veuillez cochez Je ne suis pas un robot',
			'all conditions must be marked in order to complete the order': 'Toutes les conditions doivent être cochées afin de compléter la commande',
			'similar products': 'Produits similaires',
			'additional special\'s variety': 'Gamme de produits en promo',
			'select from variety': 'Sélectionner dans le choix de produits',
			'product info disclaimer': 'Toutes les informations relatives au produit y compris les photos sont indiquées sur le site seulement à titre indicatif et sont susceptibles de contenir certaines erreurs ou imprécisions.',
			'employee discount': 'Réduction d’employé',
			'card id': 'No de carte',
			'discount': 'Réduction',
			'remaining obligo': 'Solde du budget',
			'monthly obligo': 'Budget mensuel',
			'club name': 'Nom du club',
			'obligo type': 'Type de budget',
			'monthly discount obligo': 'Budget de réduction mensuel',
			'cardid: required error': 'Saisissez un numéro de carte',
			'pin: required error': 'Saisissez le code secret',
			'employee discount card holder?': 'Vous avez droit à une réduction d’employé ?',
			'employee discount card data': 'Détails du compte de réduction d’employé',
			'employee discount account not found': 'Compte de réduction d’employé non trouvé',
			'estimated employee savings': 'Réduction d’employé estimée',
			'unlimited': 'Illimité',
			'getting data...': 'Réception des données...',
			'store is filtered': 'Site filtré',
			'what\'s your lifestyle?': 'Quel est votre mode de vie ?',
			'filter entire store': 'Filtrer tout le site',
			'filter the entire store according to your preferred lifestyle or diet!': 'Filtrez tout le site selon votre mode de vie ou votre régime préféré !',
			'filtering store': 'Filtre site',
			'the entire store is now filtered by': 'Site entièrement filtré par',
			'clear all filters': 'Effacer tous les filtres',
			'back to filters': 'Retour aux filtres',
			'all filters': 'Tous les filtres',
			'filters': 'Filtres',
			'show more': 'Voir plus',
			'show less': 'Voir moins',
			'permanent_filters_legal_text': 'Lors de l\'utilisation des différentes options de filtrage proposées sur le site, la Société ne garantit pas l’absence de dysfonctionnement ou d’erreur sur le site et notamment lors du filtrage. La Société ne s\'engage pas à ce que la classification des produits en catégories soit exacte et exclut les produits ne satisfaisant pas les critères requis. Vous êtes seul responsable de l’utilisation des informations obtenues, notamment en matière de dommages causés en utilisant les informations ou en s’y fiant. Chaque produit doit être testé individuellement avec tous ses ingrédients, Vous êtes responsable de vérifier toutes les informations face aux données du fabricant pour ce produit. En cas de divergence entre les informations présentées sur le site et celles présentées par le fabricant, ces dernières prévalent. Ce qui précède ne saurait engager la responsabilité de la Société pour des dommages, indirects ou directs, liés aux informations affichées par le fabricant d\'un quelconque produit autre.',
			'we couldn\'t find any products': 'Aucun produit trouvé',
			'we couldn\'t find any specials': 'Aucune promo trouvée',
			'try editing your filters': 'Essayez de modifier les filtres',
			'clear_all_filters_2': 'effacez tous les filtres',
			'or': 'ou',
			'edit global filters': 'Modifications des filtres globaux',

			'verify account': 'Vérifier le compte',
			'you are now registered': 'Inscription réussie',
			'there was a problem verifying your account': 'Un problème est survenu lors de la vérification de votre compte',
			'please contact customer service': 'Veuillez contacter le service client',
			'please verify your account': 'Vérifiez votre compte',
			'before your first purchase, a verification code will be sent to your mobile phone': 'Avant votre premier achat, un code de vérification sera envoyé sur votre téléphone mobile',
			'please enter your phone number': 'Saisissez votre numéro de téléphone',
			'send sms': 'Envoyer sms',
			'enter the code that was sent to your phone': 'Saisissez le code de vérification envoyé sur votre téléphone mobile',
			'change number': 'Changer le numéro',
			'verify': 'Vérification',
			'i didn\'t receive the code (resend)': 'Je n’ai pas reçu de code de vérification (renvoyez-le)',
			'the code is invalid or expired': 'Code de vérification erroné ou expiré',
			'your account is verified': 'Processus de vérification mené à bien',
			'invalid verification code': 'Code de vérification erroné',

			'loyalty club details': 'Détails du compte adhérent',
			'id': 'Numéro d’identité',
			'loyalty terms': 'Le règlement du club',
			'please enter id': 'Saisissez un numéro d’identité',
			'invalid mobile phone': 'No de téléphone mobile non valide',
			'please confirm that you accept the terms': 'Veillez à confirmer les conditions d’utilisation de l’application',
			'member number': 'Numéro de club',
			'you have successfully registered': 'Vous êtes bien inscrit',
			'for the customer club': 'au club client',
			'for the customer club and the website': 'au club client et au site en ligne',
			'website registration': 'Inscription au site',
			'please enter a 9-digit number': 'Saisissez un numéro d\'identité de 9 chiffres',
			'only digits should be typed': 'Saisissez seulement des chiffres',
			'please let me know about promotions on site and club': 'Je consens à être informé par email',
			'organization management': 'Gestion d’organisation',
			'payment by organization credit': 'Facturation du budget institutionnel',
			'sorry, your organization account is inactive': 'Désolés, compte d’organisation inactif',
			'sorry, there are no branches assigned to this organization': 'Désolés, aucun magasin associé à cette organisation',
			'please contact your organization admin': 'Contactez le responsable de l’organisation',
			'credit cards can not be added. you are assigned to an organization': 'Impossible d’ajouter une carte de crédit. Vous êtes associé à une organisation',

			'cancel order': 'Annuler la commande',
			'are you sure you want to cancel this order': 'Êtes-vous sûr de vouloir annuler la commande',
			'order #': 'Numéro de commande',
			'keep the order': 'Garder la commande',
			'your order was cancelled': 'Commande annulée',
			'this order will not be charged': 'Cette commande ne sera pas facturée',
			'your payment method will not be charged':'Votre mode de paiement ne sera pas facturé',
			'a confirmation email was sent to {email}': 'Vous recevrez un email de confirmation à {email}',
			'no available deliveries in the upcoming days': 'Il n’y a plus de livraisons disponibles dans les prochains jours',
			'items to cart': 'Produits au panier',
			'this user may already be on the system': 'Cet utilisateur est déjà existant',
			'please try to login': 'ous pouvez essayer de vous connecter',
			'a verification email will be sent to your email box. the link is valid for {link_ttl} minutes': 'Un lien de vérification vous a été envoyé par mail. Le lien est valide {link_ttl} minutes',
			'send again': 'Envoyer à nouveau',
			'didn\'t receive an email': 'Email non reçu',
			'your account has not yet been verified via email - please verify by clicking on the link sent to your email inbox': 'Votre compte n\'a pas encore été vérifié par e-mail - veuillez le vérifier en cliquant sur le lien envoyé dans votre boîte de réception',
			'your account requires activation, email with activation link have been sent to your email. the link is valid for {link_ttl} minutes': 'Un lien de validation vous a été envoyé par email, veuillez cliquer dessus pour activer votre compte. le lien est valide pendant {link_ttl} minutes',
            'your account requires activation.': 'Votre compte doit être activé.',
            'email with activation link have been sent to your email.': 'Un lien d’activation vient d’être envoyé sur votre e-mail.',
            'the link is valid for {link_ttl} minutes': 'Le lien est valide durant {link_ttl} minutes',

			'website login': 'Me connecter',
			'send a new link': 'Nouveau lien',
			'sorry, this link has expired': 'Le lien a expiré',
			'a new link can be sent': 'Un nouveau lien peut-être envoyé.',
			'you have successfully registered for the site': 'Votre compte a bien été créé !',

			'delivery_type_1_times': 'Horaires de livraison',
			'delivery_type_2_times': 'En point retrait',

			'we use cookies to improve your experience': 'Nous utilisons les cookies pour améliorer votre expérience',
			'we use cookies or other trackers to facilitate the use of the site, improve the performance and security of the site, personalize the content offered, including personalized advertising content, and compile traffic and navigation statistics. some cookies, other than functional and security cookies, require your consent to be placed.': 'Nous utilisons des cookies ou autres pisteurs pour faciliter l’utilisation du site, améliorer les performances et la sécurité du site, personnaliser le contenu proposé, y compris un contenu publicitaire personnalisé et accumuler des statistiques sur le trafic et la navigation. Certains cookies, autres que des cookies de fonctionnement et de sécurité, exigent votre consentement pour être placés.',
			'accept': 'Accepter',
			'accept all cookies': 'Accepter tous les cookies',
			'setup my cookies': 'Configurer mes cookies',
			'managing cookies': 'Cookies de gestion',
			'these cookies allow you to benefit from the services offered by the site, to optimize its use and personalization (in particular personalization of offers and advertisements) depending on your profile.': 'Ces cookies vous permettent de bénéficier des services proposés par le site, d’optimiser son utilisation et sa personnalisation (en particulier la personnalisation d’offres et de publicités) en fonction de votre profil.',
			'marketing': 'Marketing',
			'marketing cookies are used to improve the relevance of the ads you see and to make sure that you do not see the same ad multiple times.': 'Les cookies de marketing sont utilisés pour améliorer la pertinence des annonces publicitaires que vous voyez et pour s’assurer que vous ne voyez pas la même publicité plusieurs fois.',
			'additional features': 'Autres fonctionnalités',
			'these cookies make it possible to activate additional features on the site, for example to facilitate your purchases.': 'Ces cookies permettent d\'activer d\'autres fonctionnalités sur le site, par exemple pour faciliter vos achats.',
			'audience measurement': 'Mesure d\'audience',
			'these cookies allow us to obtain navigation statistics on our site. we use this data to identify possible malfunctions of the site, to improve the site, for marketing purposes, and in order to personalize your experience on the site.': 'Ces cookies nous permettent d’obtenir des statistiques de navigation sur notre site. Nous utilisons ces données pour identifier des dysfonctionnements éventuels du site, à des fins de marketing et pour personnaliser votre expérience sur le site.',
			'google analytics': 'Google Analytics',
			'these cookies allow us to obtain statistics on the number of visits to our site (e.g., number of visits, most viewed pages, etc.). we use this data to understand our users better, check the performance of our content, get insights, and identify possible malfunctions of the site to make improvements.': 'Ces cookies nous permettent d’obtenir des statistiques sur le nombre de visites de notre site (par ex. nombre de visites, pages les plus consultées, etc.). Nous utilisons ces données pour mieux cerner nos utilisateurs, vérifier les performances de notre contenu, recueillir des informations et identifier des dysfonctionnements éventuels du site pour procéder à des améliorations.',
			'functional (not configurable)': 'Fonctionnels (non configurables)',
			'these cookies allow you to remember your choices and preferences on your account and to provide features that facilitate the use of the site. they are essential for the proper functioning of the site and are strictly necessary for the provision of a service that you have expressly requested.': 'Ces cookies vous permettent de vous souvenir de vos choix et préférences sur votre compte et de délivrer des fonctionnalités facilitant l’utilisation du site. Ils sont essentiels pour le bon fonctionnement du site et sont nécessaires uniquement pour vous délivrer un service que vous avez expressément demandé.',

			'user products list not found': 'La liste des produits utilisateur introuvable',
			'user products list is disabled for this retailer': 'La liste des produits utilisateur est désactivée pour ce détaillant',
			'my lists': 'Commandes',
			'my products': 'Mes produits',
			'my products list': 'Ma liste de produits',
			'products i usually purchase': 'Ma liste de produits',
			'your time is precious': 'Votre temps est précieux',
			'we have prepared a list of your personal products': 'Nous avons préparé une liste de vos produits habituels',
			'we have prepared a list of products you usually purchase': 'Nous avons préparé une liste de vos produits habituels',
			'add products from the list': 'Ajouter des produits de la liste',
			'no products were selected': 'Aucun produit choisi',
			'in cart': 'Dans le chariot',
			'pay with credit card': 'PAYER',
			'sitemap': 'Plan du site',
			'pages': 'Pages',
			'add to shopping list': 'Ajouter à une liste de course',
			'payment information': 'Infos paiement',
			'replaced with': 'Remplacement',
			'the {product_count} products listed below will be': 'Les {product_count} produits ci-dessous seront',
			'removed from your cart': 'Supprimée de votre panier',
			'out of stock products': 'Produits en rupture de stock',
			'the items below will be removed from your cart': 'Les éléments suivants seront supprimé de votre panier',
			'back to cart': 'Retour au panier',
			'confirm': 'Confirmer',
			'expired':'expiré',
			'{product_count} out of stock products will not be supplied': '{product_count} des produits en rupture de stock ne seront pas fournis',
			'{coupons_count} expired coupons cannot be redeemed': '{coupons_count} coupons expirés ne peuvent pas être utilisés',
			'products marked with': 'Produits marqués de',
			'limited quantity': 'Quantité limitée',
			'tag are limited to': 'balise sont limités à',
			'items only.': 'articles seulement.',
			'are you sure you want to remove all products from your shopping cart?': 'Voulez-vous vraiment supprimer tous les produits de votre panier?',
			'yes, clear cart': 'Oui, vider le panier',
			'clear cart?': 'Vider le panier?',
			'registration attempt has been blocked, please contact support': 'La tentative d\'enregistrement a été bloquée, veuillez contacter le support',
			'account has been suspended, please contact support': 'Compte suspendu, veuillez contacter l\'assistance',
			'delivery-fee-explain-default': 'Le montant final des frais de livraison est susceptible d’être mis à jour lors du paiement et d’être sujet à modification en raison de la zone/heure de livraison ou de toute remise supplémentaire. ',
			'delivery_type_1_fee': 'Frais livraison',
			'delivery_type_2_fee': 'Frais enlèvement',
			'includes_delivery_type_1_fee': 'Inclut les frais livraison',
			'includes_delivery_type_2_fee': 'Inclut les frais enlèvement',
			'not_includes_delivery_fee': '(n\'inclut pas les frais de livraison)',
			'valid until': 'Expiré le',
			'proceed to checkout': 'Passer commande',

			'add coupon': 'Ajouter coupon',
			'your points': 'Vos points',
			'new': 'Nouveau',
			'buy with points': 'Acheter avec des points',
			'almost expired': 'Presque Expiré',
			'coupon clipped': 'Coupon découpé',
			'add product': 'Ajouter un produit',
			'product in cart': 'Produit dans le panier',
			'clip coupon': 'Couper le coupon',
			'clipped externally': 'Clipsé en externe',
			'personal coupons': 'Coupons personnels',
			'may interest you': 'Susceptible de vous intéresser',
			'clip for {x} points': 'Epingler pour {x} points',
			'not enough points': 'Pas assez de points',
			'coupon expires today': 'Le coupon expire aujourd\'hui',
			'coupon expires in {x} days': 'Coupon expire dans {x} jours',
			'clip for': 'Epingler pour',
			'valid': 'Valable',
			'can be redeemed once': 'Peut être échangé une fois',
			'can be redeemed {x} times': 'Peut être échangé {x} fois',

			'last_minute_recommendations_headline': 'nous avons des recommandations sélectionnées spécialement pour vous.',
			'last_minute_recommendations_title_coupons': 'Des coupons qui peuvent vous faire économiser de l\'argent',
			'last_minute_recommendations_title_products_usually': 'Peut-être avez-vous oublié ces produits?',
			'last_minute_recommendations_title_products_complementary': 'Vous pourriez aussi aimer...',
			'last_minute_recommendations_title_specials_offers': 'Promotions à ne pas manquer',
			'last_minute_recommendations_title_products_offers': 'Vous ne voudrez pas manquer ces produits',

			'account_deletion_common_customer_support_1': 'Pour obtenir de l\'aide, appelez le service client:',
			'account_deletion_common_customer_support_2': 'Veuillez réessayer plus tard ou appelez le service client:',
			'account_deletion_account_title': 'Supprimer le compte',
			'account_deletion_init_title': 'Supprimer votre compte',
			'account_deletion_recaptcha_description': 'Une vérification en quelques étapes est requise pour supprimer votre compte.',
			'account_deletion_authentication_description': 'Un courriel avec un code de vérification a été envoyé à {userEmail}',
			'account_deletion_authentication_help_spam': 'Il vous faudra peut-être vérifier votre dossier de courrier indésirable',
			'account_deletion_authentication_help_code_1': 'Vous n\'avez pas reçu le code?',
			'account_deletion_authentication_help_code_2': 'renvoyez',
			'account_deletion_authentication_help_error_try_again_1': 'Veuillez réessayer ou',
			'account_deletion_authentication_help_error_try_again_2': 'renvoyez un nouveau code',
			'account_deletion_authentication_help_error_max_tries': 'Vous avez dépassé le nombre de tentatives possibles, veuillez réessayer plus tard.',
			'Expired code': 'Code expiré',
			'Invalid code': 'Code erroné',
			'account_deletion_confirmation_description_1': 'Vous êtes sur le point de',
			'account_deletion_confirmation_description_2': 'supprimer définitivement votre compte',
			'account_deletion_confirmation_description_3': 'toutes les données de votre compte et vos futures commandes seront supprimées',
			'account_deletion_confirmation_description_4': 'Cette action ne peut pas s’annuler',
			'account_deletion_confirmation_help_input_1': 'En tapant le mot "CONFIRM", vous approuverez la suppression de votre compte',
			'account_deletion_confirmation_help_input_2': 'Tapez "CONFIRM" pour vérifier',
			'account_deletion_already_deleted_title': 'Impossible de poursuivre',
			'account_deletion_already_deleted_description': 'Ce compte a été supprimé et est en cours de traitement',
			'account_deletion_notification_title': 'votre compte sera supprimé dans {numOfDaysUntilDeletion} jours',
			'account_deletion_notification_description': 'Toute commande passée pour être livrée ou enlevée après cette heure ne sera pas fournie',
			'account_deletion_error_title': 'Une erreur système s\'est produite',
			'account_deletion_error_description': 'Impossible de continuer en raison d\'une erreur système',
			'please type in your zip code': 'Veuillez saisir votre code postal',
			'find address': 'Trouver une adresse',
			'you cannot edit this field before entering a zip code for your address': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
			'please choose your address': 'Veuillez choisir votre adresse',
			'you will be able to edit the address details': 'Vous pourrez modifier les coordonnées',
			'you cannot edit this field': 'Vous ne pouvez pas modifier ce champ',
			'not editable error': 'Vous ne pouvez pas modifier ce champ avant d\'avoir saisi un code postal pour votre adresse',
			'not_editable_locked error': 'Pour modifier l\'adresse, veuillez choisir à nouveau dans la liste des codes postaux',
			'not_editable_locked_empty error': 'Veuillez saisir votre code postal et choisir votre adresse dans la liste',
			'zipcode error': 'Vous ne pouvez pas modifier ce champ',
			'there are no available items for this promotion': 'Il n\'y a pas d\'articles disponibles pour cette promotion',
			'proceed to checkout': 'Passer commande',

			'add_units_coupon': 'Ajouter {remaining} articles pour obtenir la rduction',
			'add_currency_coupon': 'Ajouter {remaining} articles pour obtenir la rduction',
			'add_measure_coupon': 'Ajouter {remaining} {unit} articles pour obtenir la rduction',
			'add_units_cart_coupon': 'Ajouter {remaining} articles',
			'add_unit_cart_coupon': 'Ajouter un article',
			'add_currency_cart_coupon': 'Ajouter {remaining}',
			'add_measure_cart_coupon': 'Ajouter {remaining} {unit}',
			'add_units_special_purchase': 'Ajouter {remaining} articles supplémentaires',
			'buy_units_total': 'Acheter {total} produits',
			'buy_unit_total': 'Acheter {total} produits',
			'buy_currency_total': 'Acheter pour {total}',
			'buy_measure_total': 'Acheter {total} {unit}',

			'suggestions_dialog_header_1': 'Recommandation relative aux remplacements',
			'suggestions_dialog_header_2': 'veuillez choisir un autre article parmi notre gamme de produits similaires',
			'suggestions_dialog_header_3': 'En rupture de stock',
			'suggestions_dialog_header_4': 'Recommandation relative aux remplacements',
            'ok, continue' : 'Ok, CONTINUER',
            'change chosen time': 'modifier la date de livraison',
			'hover description for cvv': 'Le numéro CVV (""Card Verification Value"") figurant sur les cartes de crédit ou de débit est un numéro à trois chiffres inscrit sur les cartes de crédit et de débit des marques VISA, Mastercard et Discover.  Les cartes de crédit ou de débit de la marque American Express comportent un code numérique à 4 chiffres.',
			'enter cvv number': '',

			'the coupon displayed below, will not be valid for this chosen time': 'veuillez noter que le coupon affiché ci-dessous ne sera pas valide pour cette période choisie',
			'the expiration of these {coupon_number} coupons will not be valid for this chosen time': 'Veuillez noter que ces {coupon_number} coupons expireront et ne pourront pas être utilisés',
			'these {coupon_number} coupons will not be valid for this chosen time': 'Ces{coupon_number} coupons ne seront pas valables pour la période choisie.',
			'the coupon displayed below, will not be valid for this chosen time': 'Le coupon affiché ci-dessous, ne sera pas valable pour cette période choisie',
			'price does not include bottle deposit': 'La consigne n\'est pas incluse dans le prix',
			'price includes bottle deposit': 'La consigne est incluse dans le prix',
			'balance to be paid': 'Solde à payer',
			'please enter the card number to check the balance': 'Veuillez entrer le numéro de la carte pour vérifier le solde',
			'redeem': 'Échanger',
			'please save the voucher or gift card until you receive your order and are charged': "Veuillez conserver le bon ou la carte cadeau jusqu'à ce que vous receviez votre commande et que celle-ci soit facturée",
			'choose gift card/s': '',
			'add gift card': '',
			'redeem gift card & discount cards': 'Pour échanger une CARTE CADEAU',
			'for gift card and discount cards': '',
			'gift_card_error_message': 'Impossible de traiter votre CARTE-CADEAU, veuillez réessayer ou essayer une autre carte',
			'wrong_gift_card_amount_error': 'Le montant dépasse la valeur du panier',
			'amount_exceeds_balance': 'Montant supérieur au solde',
			"sorry, currently we don't deliver to this address for further inquiries call to the customer service.": "Désolés, nous ne livrons pas à cette adresse actuellement. Pour plus d'informations, veuillez vous adresser au service à la clientèle.",
			'january': 'Janvier',
			'february': 'Février',
			'march': 'Mars',
			'april': 'Avril',
			'may': 'Peut',
			'june': 'Juin',
			'july': 'Juillet',
			'august': 'Août',
			'september': 'Septembre',
			'october': 'Octobre',
			'november': 'Novembre',
			'december': 'Décembre',
			'redeemed': 'utilisé',
			'points gained': 'Points gagnés',
			'source': 'Source',
			'when': 'Date',
			'gg_map_dialog_title': 'Choisissez une adresse',
			'gg_map_dialog_hint': 'Nous avons du mal à déterminer votre position exacte. Veillez à bien positionner la punaise avec une grande précision. Cela nous aidera à déterminer votre adresse exacte',
			'gg_map_alert_need_location': "Veuillez activer la localisation afin d'utiliser Google Maps.",
			'gg_map_alert_error': "Il y a quelques problèmes lors de l'affichage de Google Maps.",
			'gg_map_alert_unsupport': "La géolocalisation n'est pas prise en charge par ce navigateur.",
			'autocomplete_adrs_didnt_find': 'Vous n\'avez pas trouvé votre adresse?',
			'autocomplete_adrs_choose_location': 'Choisissez votre localisation sur la carte',
			'autocomplete_adrs_city_not_found': "Désolé, votre ville n'a pas été trouvée. Veuillez sélectionner une adresse parmi les suggestions de saisie automatique ou épinglez votre adresse à l'aide de l'option Google Maps.",
			'autocomplete_adrs_adrs_not_found': "Désolé, votre adresse n'a pas été trouvée. Veuillez sélectionner une adresse parmi les suggestions de saisie automatique ou épinglez votre adresse à l'aide de l'option Google Maps.",
			'sorry, your city was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': "Désolé, votre ville n'a pas été trouvée. Veuillez sélectionner une adresse parmi les suggestions de saisie automatique ou épinglez votre adresse à l'aide de l'option Google Maps.",
			'sorry, your address was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': "Désolé, votre adresse n'a pas été trouvée. Veuillez sélectionner une adresse parmi les suggestions de saisie automatique ou épinglez votre adresse à l'aide de l'option Google Maps.",
			'sorry, your zip code was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'Désolé, votre code ZIP est introuvable. Veuillez sélectionner une adresse à partir des suggestions de l\'auto-complétion ou épingler votre adresse en utilisant la fonctionnalité Google Maps.',
			'countryInput: required error': 'Veuillez entrer votre pays',
			'country_of_origin_title': 'Pays d’origine',
			'origin_country_legal_text': 'Veuillez noter que bien que le pays d\'origine indiqué soit exact au moment de la commande, le véritable pays d\'origine du produit livré peut varier en fonction du stock disponible au point de collecte lors de la livraison',
			'address_warning_enter_house_num': 'Veuillez entrer votre numéro de maison',
			'after package deduction': 'Après déduction du colis',
			'is this address correct? you can now edit and refine it': "Cette adresse est-elle correcte ? Vous pouvez la modifier et l'améliorer",
			'edit your address': "Modifier l'adresse",
			'contact_us': 'Nous contacter',
			'how_can_we_help_you_today': 'Que pouvons nous faire pour vous aujourd’hui?',
			'leave_us_a_message': 'Laissez-nous votre message et nous vous répondrons dans les plus brefs délais.',
			'fullname': 'Nom complet',
			'phone': 'Numéro de téléphone',
			'email': 'E-mail',
			'message': 'Que pouvons-nous faire pour vous ?',
			'send': 'Envoyer',
			'your_message_has_been_sent': 'Votre message a bien été envoyé.',
			'thank_you_for_contact_us': 'Gracias por contactarnos',
			'searchtext_storename_placeholder': '{Nom du magasin}',
			'customer support phone number': 'Numéro de téléphone du service clientèle',
			'please wait, we are uploading the products': 'Veuillez patienter pendant que nous chargeons les produits',
			'complete_and_proceed_to_checkout': 'Compléter et passer à la caisse',
			'please notice, the following products are not available on this chosen time.': 'Veuillez noter que les produits suivants ne sont pas disponibles à la date sélectionnée.',
			'the following products are not available on this chosen time.': 'Que les produits suivants ne sont pas disponibles à la date sélectionnée.',
			'if you continue, they will be removed from your cart.': 'Si vous cliquez sur le bouton "Continuer", ils seront retirés de votre panier.',
			'result for “{title}”': 'Résultat de “{title}”',
			'find a product': 'Trouver un produit',
			'results by brand:': 'Résultats selon la marque :',
			'please enter cvv to continue': 'Veuillez saisir le CVV pour continuer',
			'checkout is opened on another screen': 'La commande est ouverte sur un autre écran',
			'loading coupons, please wait...': 'Chargement des coupons, veuillez patienter...',
			'please wait, we are loading the products': 'Veuillez patienter pendant que nous chargeons les produits',
			'receipt': 'Reçu',
			'api_error_message': 'Une erreur est survenue{error_message} (erreur {error_code}). veuillez réessayer dans quelques minutes. Si le problème persiste, veuillez contacter le service clientèle.',
			'payment error': 'Erreur de paiement',
			'error received': 'Erreur détectée',
			'please check your credit card details or contact your credit card provider': 'Veuillez vérifier les détails de votre carte de crédit ou contacter votre banque.',
			'address_form_field_label_city': 'Ville',
            'address_form_field_label_address': 'Adresse',
            'address_form_field_label_zip_code': 'Code ZIP',
            'address_form_field_label_postal_code': 'Code Postal',
            'address_form_field_label_entrance': 'Entrée',
            'address_form_field_label_apartment_number': 'Numéro de chambre',
            'address_form_field_label_building_code': 'Code du bâtiment',
            'address_form_field_label_state': 'État',
            'address_form_field_label_comments': 'Commentaires',
            'address_form_field_label_apartment_suite_unit': 'Appartement / Suite / Unité',
            'address_form_field_label_providence': 'Providence',
            'address_form_field_label_more_details': 'Plus de détails',
			'address_form_field_label_floor': 'étage',
			'address_form_field_label_street': 'Rue',
			'address_form_field_label_house_number': 'Num. de la maison',

			'address_form_field_error_city': 'Veuillez entrer une ville valide',
			'address_form_field_error_address': 'Veuillez entrer une adresse valide',
			'address_form_field_error_zip_code': 'Veuillez entrer un code postal valide',
			'address_form_field_error_postal_code': 'Veuillez entrer un code postal valide',
			'address_form_field_error_entrance': 'Veuillez entrer une entrée valide',
			'address_form_field_error_apartment_number': 'Veuillez entrer un numéro d\'appartement valide',
			'address_form_field_error_building_code': 'Veuillez entrer un code de bâtiment valide',
			'address_form_field_error_state': 'Veuillez entrer un état valide',
			'address_form_field_error_comments': 'Veuillez saisir un commentaire',
			'address_form_field_error_apartment_suite_unit': 'Veuillez entrer un appartement/suite/unité valide',
			'address_form_field_error_providence': 'Veuillez entrer une province valide',
			'address_form_field_error_more_details': 'Veuillez entrer plus de détails',
			'address_form_field_error_floor': 'Veuillez entrer un étage valide',
                
			'address_drop_pin_message_1': 'Emplacement enregistré avec succès. Modifiez si nécessaire.',
			'address_drop_pin_message_cancel': 'Supprimer l\'adresse épinglée',

			'autocomplete_city_not_found_non_droppin': 'Désolé, votre ville est introuvable. Veuillez sélectionner une adresse dans la liste ou contacter le service d\'assistance pour obtenir de l\'aide.',
			'autocomplete_address_not_found_non_droppin': 'Désolé, votre adresse est introuvable. Veuillez sélectionner une adresse dans la liste ou contacter le service d\'assistance pour obtenir de l\'aide.',
			'autocomplete_zip_code_not_found_non_droppin': 'Désolé, votre code ZIP est introuvable. Veuillez sélectionner une adresse dans la liste ou contacter le service d\'assistance pour obtenir de l\'aide.',

            'pickup time:': 'Heure de retrait :',
            'pickup address:': 'Adresse de retrait :',
            'delivery time:': 'Délai de livraison :',
            'delivery address:': 'Adresse de livraison :',
            'on_day': '',
            'wish_to_change_delivery': 'Vous souhaitez modifier le délai de livraison ?',
            'wish_to_change_pickup': 'Vous souhaitez modifier le délai de livraison ?',
            'select date & time': 'Choisir la date et l\'heure',
            'confirm update': 'CONFIRMER LA MISE À JOUR',
            'save & change delivery time': 'Enregistrer et Changer la date de livraison',
            'save & change pickup time': 'Enregistrer et Changer l’heure de retrait',
            'back to home page': 'Retour à la page d\'accueil',
            'your order has been updated': 'Votre commande a été mise à jour',

            'checkout_finish_last_update_time_delivery': 'Vous pouvez mettre à jour le délai de livraison de votre commande jusqu\'à',
            'checkout_finish_last_update_time_pickup': 'Vous pouvez mettre à jour l’heure de retrait de votre commande jusqu\'à',
            'forgot_sth_want_to_change_delivery': 'Vous souhaitez modifier le délai de livraison?',
            'forgot_sth_want_to_change_pickup': 'Vous souhaitez modifier la date de retrait?',

            'update_order_popup_title_def': 'Vous pouvez mettre à jour les articles de votre commande ou la date de {delivery method}.',
            'update_order_popup_order_item_def': 'mettre à jour les articles de la commande',
            'update_order_popup_delivery_time_def': 'mettre à jour le délai de livraison',
            'update_order_popup_pickup_time_def': 'mettre à jour l\'heure de retrait',
            'update_order_popup_cancel_def': 'Envie d\'annuler votre commande ? Cliquez ici pour l\'annuler  {cancel order}',
            'update_order_popup_lastest_time_def': 'Vous pouvez mettre à jour votre commande {delivery method} jusqu\'à {time calculate}.',
            'update_order_popup_err_cant_update_time': 'La mise à jour de votre {delivery method} date n\'est pas disponible',

            'irre_sales_collect_one': 'Ce {saleType} ne sera pas valable pour cette période de mise à jour et sera facturé à son prix normal',
            'irre_sales_collect_many': 'Ces {count} {saleType} ne seront pas acceptés pour cette nouvelle période et seront facturés au tarif habituel',
            'irre_sales_checkout_one': 'En mettant à jour cette commande, le {saleType} suivant ne sera plus valide et l\'article sera facturé à son prix normal',
            'irre_sales_checkout_many': 'Lors de la mise à jour de cette commande, les {count} {saleType} suivants ne seront plus valables et les articles seront facturés à leur prix normal',
            'irre_sales_oos_one': 'Ces produits sont actuellement indisponibles et seront retirés de votre panier',
            'irre_sales_oos_many': 'Ces produits sont actuellement indisponibles et seront retirés de votre panier',
            'irre_sales_btn_continue': 'ok, mise à jour',
            'irre_sales_btn_change_slot': 'changer {delivery method} la date',
            'irre_sales_btn_cancel_update': 'Annuler la modification',
            'thank you for your order!': 'Merci de votre commande !',
			'delivery_disclaimer_default_text': "La fenêtre de livraison ne peut être sélectionnée qu'au moment du paiement.",

			"add your credit card": "Ajoutez votre carte de crédit",
			"payment methods": "Modes de paiement",
			"your credit cards": "Vos cartes de crédit",
			"add a credit card": "Ajouter une carte de crédit",
			"gift cards": "Cartes-cadeaux",
			"add gift cards": "Ajouter des cartes-cadeaux",
			"choose gift card/s": "Choisir une ou des CARTES-CADEAUX",
			"please enter the card number to check balance": "Veuillez saisir le numéro de la carte pour vérifier le solde",
			"{gift card name} details": "Détails de {nom de la carte cadeau}",
			"on the next screen, you can select your payment amount": "Sélectionnez le montant de votre paiement sur l'écran suivant.",
			"balance:": "Solde :",
			"please enter amount to pay": "Veuillez saisir le montant à payer",
			"amount exceeds balance": "Montant supérieur au solde",
			"please save the voucher or gift card until you receive your order and are charged.": "Veuillez conserver le bon ou la carte cadeau jusqu'à ce que vous receviez votre commande et que vous soyez débité.",
			"remove card": "Retirer la carte",
			"see more": "afficher plus",
			"show less": "Afficher moins",
			"amount exceeds shopping cart value": "Le montant dépasse la valeur du panier",
			'user not connected to organization': 'Utilisateur non connecté à une organisation',
			'amount to pay': 'Montant à payer',
			'balance': 'Solde',
			'reset': 'Réinitialiser',

			'default_first_time_popup_title': 'Bienvenue !',
			'default_first_time_popup_sub_title': 'Comment souhaitez-vous recevoir votre commande ?',
			'default_first_time_popup_sub_title_pickup': 'Choisissez un magasin proche de chez vous pour faciliter l\'enlèvement !',
			'default_first_time_popup_sub_title_delivery': 'Saisissez votre adresse pour afficher les options de livraison disponibles près de chez vous.',
			'default_returning_popup_title': 'Changer de zone',
			'default_returning_popup_sub_title': 'Comment souhaitez-vous recevoir votre commande ?',
			'default_returning_popup_sub_title_pickup': 'Sélectionnez un nouveau lieu pour l’enlèvement',
			'default_returning_popup_sub_title_delivery': 'Trouvez de nouvelles options de livraison',

			'default_one_store_popup_title': 'Excellente nouvelle !',
			'default_one_store_popup_sub_title': 'Nous pouvons vous livrer dans votre région !',
			'default_one_store_popup_continue_button_text': 'Poursuivre vos achats',

			'default_multiple_store_popup_title': 'Vous êtes chanceux !',
			'default_multiple_store_popup_sub_title': 'Nous avons plusieurs magasins qui désservent votre région',

			'default_no_store_popup_title': 'Nous sommes désolés',
			'default_no_store_popup_sub_title': 'Pour le moment, nous ne livrons pas dans votre région. Laissez-nous votre adresse électronique et nous vous informerons de toute nouvelle expansion de nos services.',

			'delivery_input_zip_code_placeholder': 'Code postal (ex : 75011)',
			'back_btn_text': 'Retour',
			'delivery_input_address_placeholder': 'Veuillez saisir une adresse de livraison',
		};
	});
})(angular);
