(function (angular, app) {
    'use strict';

    app.component('paymentMethodDescription', {
        controllerAs: 'descriptionCtrl',
        bindings: {
            paymentMethod: '<',
            isRememberCardsMode: '<'
        },
        templateUrl: 'views/templates/payment-method-description.html'
    });
})(angular, angular.module('mobilezuz'));