(function(angular) {
    'use strict';

    angular.module('mobilezuz')
        .service('Products', ['$window', 'Cart', 'FiltersManager', '$rootScope', 'SP_SERVICES', '$timeout', function($window, Cart, FiltersManager, $rootScope, SP_SERVICES, $timeout) {
            var self = this;

            self.productsEditContext = productsEditContext;
            self.prepareSearchParams = prepareSearchParams;

            function productsEditContext(products) {
                return new ProductsEditContext(products);
            }

            function prepareSearchParams(params) {
                return FiltersManager.parsePermanentProductTags(params, false).then(function() {
                    return params;
                });
            }

            function ProductsEditContext(products) {
                var self = this;

                self.toggleView = toggleView;
                self.addProduct = addProduct;
                self.changeIsCaseMode = changeIsCaseMode;
                self.getLine = getLine;
                self.isTwoModes = isTwoModes;

                function getLine(product) {
                    return Cart.getProductLine(product);
                }

                function changeIsCaseMode(product, val) {
                    product.isCaseMode = val;
                }

                function addProduct(product, itemsData) {
                    Cart.addProduct(product, itemsData);
                    product.editState = false;
                    toggleView(product, true);
                }

                function toggleView(product, changeFocus) {
                    $window.document.activeElement.blur();
                    product.editState = !product.editState;
                    if (products) {
                        products.forEach(function(prod) {
                            prod.editState && prod !== product && (prod.editState = false);
                        });
                    }

                    if (product.editState && changeFocus) {
                        $timeout(function () {
                            var $focusElement = angular.element(document.querySelector('#line_quantity_' + product.id + ' .product-action.action-increase'));
                            $focusElement && $focusElement.focus();
                        });
                    }
                }

                function isTwoModes(product) {
                    if (!product.isBumper && product.branch.case && product.branch.case.price) {
                        if (product.branch.case.price === product.branch.regularPrice) {
                            product.isCaseMode = true;
                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        return false;
                    }
                }
            }
        }]);
})(angular);
