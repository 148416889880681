(function (angular, app) {
    'use strict';

    app.service('UserVerificationDialog', ['mDesign', function(mDesign) {
        this.show = show;

        function show(isPostRegistration) {
            return mDesign.dialog({
                multiple: true,
                fullscreen: true,
                clickOutsideToClose: false,
                templateUrl: 'views/templates/user-verification.html',
                controller: 'UserVerificationCtrl as userVerificationCtrl',
                locals: {
                    isPostRegistration: !!isPostRegistration
                }
            });
        }
    }]);

    app.run(['$rootScope', 'UserVerificationDialog', function ($rootScope, UserVerificationDialog) {
        $rootScope.UserVerificationDialog = UserVerificationDialog;
    }]);

    app.controller('UserVerificationCtrl', [
        'User', 'Api', 'mDesign', 'Retailer', 'isPostRegistration', 'USER_VERIFICATION_STATUS', 'PHONE_TYPES',
        function(User, Api, mDesign, Retailer, isPostRegistration, USER_VERIFICATION_STATUS, PHONE_TYPES) {
            var userVerificationCtrl = this;

            userVerificationCtrl.send = send;
            userVerificationCtrl.verify = verify;
            userVerificationCtrl.close = mDesign.hide;

            userVerificationCtrl.isPostRegistration = isPostRegistration;
            userVerificationCtrl.backgroundImage = Retailer.backgroundImage;

            _init();

            function _init() {
                return User.getUserSettings(true).then(function(userData){
                    userVerificationCtrl.userData = userData;
                    userVerificationCtrl.isVerified = userData.verificationStatusId === USER_VERIFICATION_STATUS.VERIFIED;
                    userVerificationCtrl.codeSent = userData.verificationStatusId === USER_VERIFICATION_STATUS.SENT &&
                        userData.verificationCodeExpiration && (new Date(userData.verificationCodeExpiration)) > (new Date());

                    var mobilePhone = angular.filterCollection(userData.phones, function(phone) {
                        return phone.typeVal === PHONE_TYPES.MOBILE || phone.typeVal === PHONE_TYPES.VERIFY;
                    })[0];
                    if (mobilePhone) {
                        userVerificationCtrl.phoneNumber = mobilePhone.phoneNumber;
                    }
                });
            }

            function send(phoneNumber) {
                return _request({
                    url: '_send',
                    data: {
                        phoneNumber: phoneNumber
                    }
                }).then(function() {
                    userVerificationCtrl.verifyError = false;
                    userVerificationCtrl.uniqPhoneError = false;
                    userVerificationCtrl.codeSent = true;
                    userVerificationCtrl.code = '';
                }).catch(function(e) {
                    var errorName = e.data.error;
                    if (errorName === 'The given phone number already exists in the system') {
                        userVerificationCtrl.uniqPhoneError = true;
                    }
                });
            }

            function verify() {
                userVerificationCtrl.verifyError = false;

                return _request({
                    url: '_verify',
                    data: {
                        code: userVerificationCtrl.code
                    }
                }, {
                    hideError: true
                }).then(function() {
                    return _init();
                }).catch(function() {
                    userVerificationCtrl.verifyError = true;
                });
            }

            function _request(options, apiOptions) {
                userVerificationCtrl.inPrgress = true;

                options.url = '/v2/retailers/:rid/users/:uid/verification/' + options.url;
                options.method = 'POST';
                return Api.request(options, apiOptions).finally(function() {
                    userVerificationCtrl.inPrgress = false;
                });
            }
        }
    ]);
})(angular, angular.module('mobilezuz'));
