(function (angular) {
    angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
        $stateProvider.state('app.categories.categoryProducts', {
            url: '/:categoryId/products?{filters:json}&{sort:json}',
            reloadOnSearch: false,
            data: {
                stateAccess: PAGE_ACCESS.ALLOW,
                bodyClass: 'products',
                metaTags: {
                    title: ['$stateParams', 'Util', '$filter', function ($stateParams, Util, $filter) {
                        return Util.getCategory($stateParams.categoryId).then(function(categoriesInfo) {
                            return $filter('name')(categoriesInfo[0].names);
                        });
                    }],
                    description: ['$stateParams', 'Util', function ($stateParams, Util) {
                        return Util.getCategory($stateParams.categoryId).then(function(categoriesInfo) {
                            for (var i = 0; i < categoriesInfo.length; i++) {
                                if (!categoriesInfo[i].description) {
                                    continue;
                                }

                                return categoriesInfo[i].description;
                            }
                        });
                    }],
                    keywords: ['$stateParams', 'Util', function ($stateParams, Util) {
                        return Util.getCategory($stateParams.categoryId).then(function(categoriesInfo) {
                            for (var i = 0; i < categoriesInfo.length; i++) {
                                if (!categoriesInfo[i].keywords) {
                                    continue;
                                }

                                return categoriesInfo[i].keywords;
                            }
                        });
                    }]
                }
            },
            views: {
                '@': {
                    templateUrl: 'views/products.html',
                    controller: 'ProductsCtrl',
                    resolve: {
                        categoriesInfo: ['$stateParams', '$state', 'Util', function($stateParams, $state, Util) {
                            return Util.getCategory($stateParams.categoryId).then(function(categories) {
                                if(!categories) {
                                    $state.go('app.home');
                                    throw new Error('Category not found');
                                }
                                return categories;
                            });
                        }]
                    }
                }
            }
        });

    }]).controller('ProductsCtrl', [
        '$scope', '$rootScope', '$state', '$stateParams', '$location', '$timeout', 'Api', 'Util', 'Config', 'Cart',
        'User', 'FiltersManager', 'Bumpers', 'categoriesInfo',
        'CATEGORIES_TYPES', 'ADVERTISEMENT_CONVERSION_TYPE', 'SEARCH_SORT_BOOST_TYPES', 'DataLayer',
        function ($scope, $rootScope, $state, $stateParams, $location, $timeout, api, Util, Config, cart,
                  User, FiltersManager, bumpers, categoriesInfo,
                  CATEGORIES_TYPES, ADVERTISEMENT_CONVERSION_TYPE, SEARCH_SORT_BOOST_TYPES, DataLayer) {
            var _resetProductsQueue = new Util.SingleOperationQueue(),
                _isRequesting = false,
                _isEmptyRequest = false,
                _currentFrom = 0; // do not use the products.length directly, to avoid bumpers
            
            $scope.products = [];
            $scope.resetProducts = resetProducts;
            $scope.noProducts = false;
            $scope.boostProductTags = [];

            _setCategoryInfo();

            if ($stateParams.events) {
                try {
                    $scope.events = JSON.parse($stateParams.events);
                } catch(e) {}

                Util.waitForInitDialog().then(function() {
                    $location.search('events', null);
                });
            }

            $scope.groupBy = {
                titleStyleClass: 'family-group-title',
                by: 'family.id',
                value: 'family'
            };

            //init the brand and family filters
            var filtersPromise = api.request({
                url: '/v2/retailers/:rid/branches/:bid/categories/' + $stateParams.categoryId + '/products/filters'
            }, {
                fireAndForgot: true
            });

            var _productTagsFilter = FiltersManager.getProductTagsFilter(filtersPromise, $scope, false, true);
            $scope.filters = [
                _productTagsFilter,
                FiltersManager.getFamiliesFilter(filtersPromise),
                FiltersManager.getBrandsFilter(filtersPromise)
            ];

            Config.retailer.allowSupervisionFilter && $scope.filters.push(FiltersManager.getSupervisionsFilter(filtersPromise));

            $scope.sorterItems = [{
                title: '\'Price\' | translate',
                name: 'branch.regularPrice'
            }];

            if ($stateParams.sort) {
                $scope.orderProductsBy = $stateParams.sort;
            }

            FiltersManager.setFiltersFromUrl($scope.filters, $stateParams.filters).then(function() {
                return _getProducts();
            });

            function _getProducts() {
                _isRequesting = true;

                var requestParams = {
                    from: _currentFrom,
                    size: 20,
                    languageId: Config.language.id,
                    minScore: 0
                };

                if (Config.retailer.settings.showUnavailableProducts !== 'true') {
                    requestParams.filters = {
                        "mustNot": {
                            "term": {
                                "branch.isOutOfStock": true
                            }
                        }
                    }
                }
                else {
                    if (Config.retailer.settings.isOutOfStockDuration === 'true') {
                        requestParams.filters = {
                           "bool": {
                                "should": [
                                    {
                                        "bool": {
                                            "must_not": {
                                                "exists": {
                                                    "field": "branch.outOfStockShowUntilDate"
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "bool": {
                                            "must": [
                                                {
                                                "range": {
                                                    "branch.outOfStockShowUntilDate": {
                                                    "gt": "now"
                                                    }
                                                }
                                                },
                                                {
                                                "term": {
                                                    "branch.isOutOfStock": true
                                                }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "bool": {
                                            "must": [
                                                {
                                                "term": {
                                                    "branch.isOutOfStock": false
                                                }
                                                }
                                            ]
                                        }
                                    }
                                ]
                           } 
                        }
                    }
                }

                angular.forEach($scope.filters, function(filter) {
                    filter.parse(requestParams);
                });

                $scope.boostProductTags = (_productTagsFilter.selectedItems || []).map(function(item) {
                    return item.value;
                });

                return _getCategorySort().then(function(categorySort) {
                    var sort = [];
                    if ($scope.categoryInfo.categoryType === CATEGORIES_TYPES.MENU) {
                        sort.push({'family.id': 'asc'});
                        if (categorySort) {
                            requestParams.categorySort = categorySort;
                        } else {
                            sort.push({'localBarcode.raw': 'asc'});
                        }
                    } else if ($scope.orderProductsBy && $scope.orderProductsBy.name) {
                        var paramsSort = {};
                        paramsSort[$scope.orderProductsBy.name] = $scope.orderProductsBy.isDesc ? 'desc' : 'asc';
                        sort = [paramsSort];
                    } else {
                        requestParams.categorySort = categorySort;
                    }

                    if (sort.length) {
                        requestParams.sort = sort;
                    }

                    requestParams.names = [];
                    angular.forEach(categoriesInfo, function (category) {
                        angular.forEach(category.names, function (value) {
                            requestParams.names.push(value);
                        });
                    });

                    return api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/branches/:bid/categories/' + $stateParams.categoryId + '/products',
                        params: requestParams
                    }, {
                        loadingElement: document.querySelector('.loading-progress-wrapper')
                    });
                }).then(function (response) {
                    _currentFrom += response.products.length;
                    var currentProductsCount = $scope.products.length;

                    $scope.noProducts = !response.total;
                    if (response.products.length) {
                        var productAndBumpersArray = cart.getProducts(response.products);
                        Util.setProductIndexPosition(productAndBumpersArray);

                        $rootScope.$emit('products.added', {chunk: response});
                        _addProductItemsData(productAndBumpersArray, currentProductsCount);
                        bumpers.handleProductsBumpers($scope.products, productAndBumpersArray);

                        User.injectSuggestionsForProducts(productAndBumpersArray);

                        $scope.products.push.apply($scope.products, productAndBumpersArray);
                    } else {
                        _isEmptyRequest = true;
                    }
                    DataLayer.push(DataLayer.EVENTS.VIEW_ITEM_LIST, {products: response.products, data: {productsStartingPosition: currentProductsCount}});
                }).finally(function() {
                    _isRequesting = false;
                });
            }

            function _getCartLinesArray() {
                var allLines = [];
                angular.forEach(cart.lines, function(line) {
                    allLines.push(line);
                });
                return allLines;
            }

            function _addProductItemsData(scopeProducts, cnt) {
                if(scopeProducts && scopeProducts.length) {
                    for(var i = 0; i < scopeProducts.length; i++) {
                        if(scopeProducts[i].productId) {
                            scopeProducts[i].itemsData = {
                                next: _nextProduct,
                                previous: _previousProduct,
                                //siblings: [Items.items[index - 1], Items.items[index - 2], Items.items[index + 1], Items.items[index + 2]],
                                total: scopeProducts.length,
                                index: i,
                                realIndex: cnt + i
                            };
                        }
                    }
                }
            }

            function _nextProduct(index) {
                var productsOnly = _getProductsWithoutBumpers($scope.products);
                index = index || 0;

                if(productsOnly[index + 1] && productsOnly[index + 1].productId) {
                    return productsOnly[index + 1];
                }
                return null;
            }

            function _previousProduct(index) {
                var productsOnly = _getProductsWithoutBumpers($scope.products);
                index = index || 0;

                if(productsOnly[index - 1] && productsOnly[index - 1].productId) {
                    return productsOnly[index - 1];
                }
                return null;
            }

            function _getProductsWithoutBumpers(products) {
                var temp = [];
                if(products && products.length) {
                    for(var i = 0; i < products.length; i++) {
                        if(products[i].productId) {
                            temp.push(products[i]);
                        }
                    }
                }
                return temp;
            }

            function _setCategoryInfo() {
                if (categoriesInfo && categoriesInfo.length) {
                    var imgUrl,
                        categoryType;
                    angular.forEach(categoriesInfo, function (category) {
                        imgUrl = imgUrl || category.mobileImage || category.imageUrl;
                        categoryType = !categoryType || categoryType === CATEGORIES_TYPES.INHERIT ? category.type : categoryType;
                    });
                    if (imgUrl) {
                        imgUrl = imgUrl.replace('%20normal', '%20app').replace('_normal', '_app');
                        imgUrl = "https://" + imgUrl.split('//').pop();
                    }
                    if (!categoryType || categoryType === CATEGORIES_TYPES.INHERIT) {
                        categoryType = CATEGORIES_TYPES.REGULAR;
                    }

                    $scope.categoryInfo = {
                        image: imgUrl,
                        names: categoriesInfo[0].names,
                        description: categoriesInfo[0].categoryDescription,
                        categoryType: categoryType,
                        isCutDescription: true
                    };
                    $rootScope.currentCategory = categoriesInfo[0];
                }
            }

            function _getCategorySort() {
                var categorySorts = [];

                var categoryWithSort = categoriesInfo.find(function(node) {
                    return !!node.categorySort;
                });
                if (categoryWithSort && categoryWithSort.categorySort) {
                    angular.forEach(categoryWithSort.categorySort, function(categorySortItem) {
                        categorySorts.push({
                            sortType: categorySortItem.sortType,
                            topPriority: categorySortItem.topPriority && (angular.toJson(JSON.parse(categorySortItem.topPriority).map(function(x) {
								return {id: x.id};
							})))
                        });
                    });
                }

                return User.getPurchasesSortBoost().then(function(userBoost) {
                    if (userBoost) {
                        categorySorts.push(userBoost);
                    }

                    var productTagsSortBoost = FiltersManager.getProductTagsSortBoost(_productTagsFilter);
                    if (productTagsSortBoost) {
                        categorySorts.push(productTagsSortBoost);
                    }

                    if (categorySorts.length) {
                        return categorySorts;
                    }
                });
            }

            /**
             * Reset the products with new filters or sort by
             * @public
             */
            function resetProducts() {
                FiltersManager.setFiltersToUrl($scope.filters, $scope.orderProductsBy);

                return _resetProductsQueue.do(function() {
                    // hide no products message
                    $scope.noProducts = false;
                    //empty the products array
                    _currentFrom = 0;
                    $scope.products.splice(0);
                    //reset is empty to allow getting products again
                    _isEmptyRequest = false;

                    return _getProducts();
                });
            }

            var listeners = [];

            listeners.push($scope.$on('vertical-scroll-end', function () {
                if (!_isRequesting && !_isEmptyRequest) {
                    _getProducts();
                }
            }));

            function _syncProductWithCart () {
                $scope.products = cart.getProducts($scope.products);
            }

            listeners.push($scope.$root.$on('cart.lines.add', function(event, data){
                if ($scope.products.length && $scope.events) {
                    Util.firePromotionEvent($scope.events[ADVERTISEMENT_CONVERSION_TYPE.ADD_TO_CART]);
                    if (data.lines && data.lines.length) {
                        data.lines[0].adConversionUrl = $scope.events[ADVERTISEMENT_CONVERSION_TYPE.CHARGE];
                    }
                }
                _syncProductWithCart();
            }));
            listeners.push($scope.$root.$on('cart.lines.remove', _syncProductWithCart));
            listeners.push(function(){
                $rootScope.currentCategory = null;
            });

            Util.destroyListeners($scope, listeners);

            /*$scope.addProduct = function (product) {
             product.isAdded = true;
             $scope.changeQuantity(product, 'increment');
             };*/
        }
    ]);
})(angular);
