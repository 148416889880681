angular.module('mobilezuz')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.accessibilityStatement', {
                url: '/accessibility-statement',
                data: {
                    name: 'accessibility-statement'
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return $filter('translate')('Accessibility Statement');
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'views/accessibility-statement.html',
                        controller: 'AccessibilityStatementCtrl'
                    }
                }
            });
        }])
        .controller('AccessibilityStatementCtrl', ['$scope', 'Config', function ($scope, config) {
            $scope.accessibilityStatement = config.retailer.settings.accessibilityStatement;

            console.log('accessibilityStatement ', $scope.accessibilityStatement)
        }]);
