/**
 * @typedef {Object} AddressDetail
 * @property {number} placeId
 * @property {string} text1
 * @property {string} city
 * @property {string} country
 * @property {number} lat
 * @property {number} lng
 */

(function (angular) {
  angular.module("mobilezuz").controller("GoogleMapDialogCtrl",
    function (mDesign, $scope, LocationService, Util, GMap, defaultLocation, $timeout) {
      var googleMapDialogCtrl = this;
      var DEFAULT_ZOOM = 18

      angular.extend(googleMapDialogCtrl, {
        isShowAutoComplete: true,
        markerPopup: null,
        addressDetail: {
          lat: 0,
          lng: 0,
          city: '',
          text1: '',
          country: '',
          placeId: '',
          zipCode: '',
          hasHouseNumberAndRoute: true,
        },
        formattedAddress: "",
        mapErrorMessage: "",
        searchQuery: "",

        defaultLocation: defaultLocation,
        handleCloseDialog: handleCloseDialog,
      });

      _init();

      //================== PRIVATES FUNCTIONS  ==================//

      function _setAddress(geocode) {
        var extractedInfo = GMap.extractInfoFromAddressComponents(geocode.address_components);
        googleMapDialogCtrl.formattedAddress = geocode.formatted_address;
        var text1Obj = Util.constructAdrsText1(extractedInfo.countryCode, extractedInfo.houseNumber, extractedInfo.route, extractedInfo.city);
        angular.extend(googleMapDialogCtrl.addressDetail, {
          lng: geocode.geometry.location.lng(),
          lat: geocode.geometry.location.lat(),
          placeId: geocode.place_id,
          city: extractedInfo.city,
          text1: text1Obj.value,
          country: extractedInfo.country,
          countryCode: extractedInfo.countryCode,
          zipCode: extractedInfo.zipCode,
          state: extractedInfo.state,
          hasHouseNumberAndRoute: text1Obj.hasHouseNumberAndRoute
        });
        $scope.$applyAsync();
      }

      /**
       * @param {GeoLocation}
       * @returns {Promise<google.maps.Map>}
       */
      function _initMap(defaultLocation) {
        var mapRef = document.getElementById("map");
        var mapOptions = {
          zoom: DEFAULT_ZOOM,
          center: defaultLocation,
        };

        return GMap.initMap(mapRef, mapOptions)
      }

      /**
       * @param {GeoLocation} location refer service/google-map
       */
      function _setAddressFromLocation(location) {
        GMap.reverseGeocode({ location: location }).then(function (geocode) {
          _setAddress(geocode);
        });
      }

      /**
       * @param {google.maps.Map} map
       * @param {{lat: number, lng: number}} defaultLocation
       * @returns {google.maps.Marker}
       */
      function _initMarker(map, location) {
        var marker = GMap.initMarker(map, location);
        _addMarkerEventListener(map, marker);
        return marker;
      }

      /**
       * @param {google.maps.Map} map
       * @param {google.maps.Marker} marker
       */
      function _addMarkerEventListener(map, marker) {
        GMap.addDragMarkerEventLister(marker, _setAddress);
        GMap.addClickMapMoveMarkerEventListener(marker, map, _setAddress);
        googleMapDialogCtrl.markerPopup = GMap.initInfoWindow(null);

        GMap.clickMarkerTogglePopupListener({
          map: map,
          marker: marker,
          infoWindow: googleMapDialogCtrl.markerPopup,
        });
      }

      /**
       * @param {GeoLocation} location
       * @param {google.maps.Map} map
       */
      function _initAutoComplete(location, map, marker) {
        var inputRef = document.getElementById("pac-input");
        if (!inputRef) {
          return;
        }
        var autocomplete = GMap.initAutoComplete(inputRef, location, map);
        GMap.addAutocompleteChangeEventListener(autocomplete, map, marker, _setAddress);
      }

      function initGoogleMapWeb(defaultLocation) {
        _initMap(defaultLocation).then(function (map) {
          var marker = _initMarker(map, defaultLocation);
          _initAutoComplete(defaultLocation, map, marker);
        });
      }

      function _init() {
        _caluDialogHeight();

        LocationService.getUserLocation().then(function (userLocation) {
          if (userLocation) {
            return userLocation;
          }
          
          if(googleMapDialogCtrl.defaultLocation){
            return GMap.reverseGeocode({ address: googleMapDialogCtrl.defaultLocation}).then(function (res){
              if(!res) return;

              return {
                lat: res.geometry.location.lat(),
                lng: res.geometry.location.lng()
              }
            }).catch(function () { return; });
          }

          return;
        }).then(function (location) {
          if (location) {
            return location;
          }
          
          return LocationService.getBranchLocation().then(function (branchLocation) {
            if (branchLocation.lat && branchLocation.lng) {
              return branchLocation;
            }
            return;
          });
        }).then(function (location) {
          if (!location) {
            // close dialog so can see alert error
            return handleCloseDialog();
          }

          _setAddressFromLocation(location);
          initGoogleMapWeb(location);
        }).catch(function (error) {
          googleMapDialogCtrl.mapErrorMessage = error.message;
        });
      }

      function _caluDialogHeight(){
        $timeout(function() {
          var rootDialogElementHeight = document.querySelector('.md-dialog-container').offsetHeight;
          // var mdDialogMaxHeight = window.getComputedStyle(document.querySelector('md-dialog')).maxHeight;
  
          // 80% base on .md-dialog maxHeight;
          var _height = rootDialogElementHeight * (80/100);
          googleMapDialogCtrl.height = _height;
        }, 0);
      }

      //================== PUBLIC FUNCTIONS  ==================//
      function handleCloseDialog(data) {
        mDesign.hide(data);
      }

      //================== SCOPE LISTENER  ==================//
      $scope.$watch("googleMapDialogCtrl.formattedAddress", function () {
        GMap.setContentInfoWindow(
          googleMapDialogCtrl.markerPopup,
          GMap.formatPopupContent(googleMapDialogCtrl.formattedAddress)
        );
      });
    }
  );
})(angular);
