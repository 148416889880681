angular.module('mobilezuz')
    .config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
        $stateProvider.state('app.retailer.contactUs', {
            url: '/contact-us',
            data: {
                stateAccess: PAGE_ACCESS.ALLOW,
                bodyClass: 'contact-us',
                metaTags: {
                    title: 'Contact us'
                }
            },
            views: {
                '@': {
                    templateUrl: 'views/contact-us.html',
                    controller: 'ContactUsCtrl',
                    controllerAs: 'contactUsCtrl'
                }
            }
        });
    }])
    .constant('CONTACT_US_ENUM', {
        BACKGROUND:'background',
        MAIN_TEXT:'main-text',
        COMMUNICATIONS_TYPES: 'communications-types',
        LEAVE_A_MESSAGE: 'leave-a-message',
    })
    .controller('ContactUsCtrl', ['$rootScope', 'Config', 'Retailer', 'User', 'Api', 'mDesign', 'SpCaptcha', 'DataLayer', 'ChooseAreaDialog', 'CONTACT_US_ENUM', '$scope','$timeout',
        function ($rootScope, Config, Retailer, User, api, mDesign, SpCaptcha, DataLayer, ChooseAreaDialog, CONTACT_US_ENUM, $scope, $timeout) {
            var contactUsCtrl = this,
                userId;

            $scope.ENUM = CONTACT_US_ENUM;
            $scope.LANGUAGE = $rootScope.config.language.culture;
            contactUsCtrl.content = Config.retailer && Config.retailer.contactUsPageDesign ? Config.retailer.contactUsPageDesign  : {};
            contactUsCtrl.layout = contactUsCtrl.content && contactUsCtrl.content.layout && contactUsCtrl.content.layout.length ? contactUsCtrl.content.layout.filter(function(item){
                return item.able || item.type === CONTACT_US_ENUM.MAIN_TEXT
            }).map(function(item){
                return Object.assign(item, {
                    templateUrl: 'views/templates/contact-us/' + item.type + '.html'
                });
            }) : [];
            contactUsCtrl.isUseContactPage = Config.retailer && Config.retailer.settings && Config.retailer.settings.isUseContactPage && Config.retailer.settings.isUseContactPage === 'true';
            contactUsCtrl.isSent = false;

            DataLayer.push(DataLayer.EVENTS.VIEW_CONTACT_US);

            contactUsCtrl.showCaptcha = false;
            contactUsCtrl.captchaIsInvalid = false; 

            Retailer.getRetailerSettings().then(function (response) {
                contactUsCtrl.retailerPhoneNumber = response.contactPhone || (Config.branch && Config.branch.phone);
            });

            contactUsCtrl.openDialer = function () {
                window.location.href = 'tel:' + contactUsCtrl.retailerPhoneNumber;
            };

            User.getUserSettings().then(function (userData) {
                userId = userData.id;
                contactUsCtrl.fullName = (userData.firstName ? userData.firstName + ' ' : '') + (userData.lastName || '');
                contactUsCtrl.email = userData.email;
                contactUsCtrl.phone = userData.phones && userData.phones.length ? pickPhoneNumber(userData.phones) : '';

                // for contact us details in form
                contactUsCtrl.details = contactUsCtrl.details || {};
                contactUsCtrl.details.fullName = contactUsCtrl.fullName;
                contactUsCtrl.details.email = contactUsCtrl.email;
                contactUsCtrl.details.phone = contactUsCtrl.phone;
            }).catch(function() {
               // catch 'not logged in' event (do nothing)
            });


            $timeout(function() {
                contactUsCtrl.showCaptcha = true;
            }, 1000);


            function pickPhoneNumber(phones) {
                var mobileNumber = phones.filter(function (phone) {
                    return phone.typeVal === 3;
                });
                if (mobileNumber.length > 0) {
                    return mobileNumber[0].phoneNumber;
                } else {
                    return phones[0].phoneNumber;
                }
            }

            contactUsCtrl.sendData = function (form) {
                if (angular.isUndefined($rootScope.config.getBranchArea())){
                    return ChooseAreaDialog.show(false);
                }

                if(form.$invalid) {
                    angular.forEach(form.$error.required, function(field) {
                        if (field.$$attr.id === 'captcha_hidden_input') {
                            contactUsCtrl.captchaIsInvalid = true;
                        }
                    });
                    return;
                }
                var data = {
                    email: form.email.$viewValue,
                    fullName: form.fullName.$viewValue,
                    phone: form.phone.$viewValue,
                    branchId: Config.branch.id,
                    recaptchaHash: SpCaptcha.getLastCaptchaVerifyHash(),
                    message: form.message.$viewValue
                };
                if (userId) {
                    data.userId = userId;
                }
                api.request({
                    method: 'POST',
                    url: 'retailers/:rid/contactUs',
                    data: data
                }).then(function () {
                    $rootScope.$emit('contactUs', contactUsCtrl.email, contactUsCtrl.name);
                    contactUsCtrl.isSent = true;
                    DataLayer.push(DataLayer.EVENTS.CONTACT_US);
                    if (Config.retailer.settings && Config.retailer.settings.isUseContactPage !== 'true') {
                        return mDesign.alert('Your message was sent successfully')
                    }
                }).then(function() {
                    if (Config.retailer.settings && Config.retailer.settings.isUseContactPage !== 'true') {
                        window.history.go(-1);
                    }
                }).catch(function (err) {
                    DataLayer.push(DataLayer.EVENTS.ERROR_CONTACT_US, { data: { error: err } });
                });
            };
        }]);