(function (angular, app) {
    'use strict';

    app.service('ChooseAreaDialog', [
        '$rootScope', '$state', '$injector', '$q', '$timeout', '$location', 'Config', 'mDesign', 'ExternalCheckout', 'DeliveryWithinDaysWarningDialog',
        function($rootScope, $state, $injector, $q, $timeout, $location, Config, mDesign, ExternalCheckout, DeliveryWithinDaysWarningDialog) {
            var self = this,
                Cart,
                waitPromise,
                Config;

            self.show = show;
            self.isPresent = false;

            function show(allowClosing, doNotAllowChooseRetailers, areaName, openPickup, area) {
                Cart = Cart || $injector.get('Cart');
                Config = $injector.get('Config');

                var queryAreaName = null;
                var search = $location.search();

                if (search.hubChildrenSearchText) {
                    queryAreaName = search.hubChildrenSearchText;
                    $location.search('hubChildrenSearchText', null);
                }

                //== when cart is in Update Mode - branch switching is disabled - show popup
                if (Cart.editOrderId) {
                    return mDesign.alert('You in the middle of editing order. Changing store is impossible').then(function(){
                        throw 'Cannot change area when editing order';
                    });
                }
                if ($rootScope.bodyClass.currentPage === 'shipmentTracking') {
                    return;
                }
                // prevent double show
                if(self.isPresent){
                    return;
                }
                self.isPresent = true;

                var dialogTemplateUrl = Config.advancedAreaAvailabilityEnabled ? 'views/choose-area-advanced/main.html' : 'views/templates/choose-area.html';

                return _waitBeforeAreaDialog().then(function() {
                    return mDesign.dialog({
                        controller: 'ChooseAreaCtrl as chooseAreaCtrl',
                        templateUrl: dialogTemplateUrl,
                        clickOutsideToClose: allowClosing,
                        escapeToClose: false,
                        locals: {
                            doNotAllowChooseRetailers: doNotAllowChooseRetailers,
                            areaName: areaName,
                            queryAreaName: queryAreaName,
                            openPickup: openPickup,
                            allowClosing: allowClosing,
                            area: area
                        }
                    });
                }).then(function(response) {
                    Cart.validateDeliveryItemsLimit();
                    DeliveryWithinDaysWarningDialog.show();
                    self.isPresent = false;
                    return response;
                }, function(err){
                    self.isPresent = false;
                });
            }

            function _waitBeforeAreaDialog() {
                return _canShowDialog().then(function(can) {
                    if (can || waitPromise) {
                        return waitPromise;
                    }

                    return waitPromise = new $q(function(resolve) {
                        var listener = $rootScope.$on('$stateChangeSuccess', function() {
                            _canShowDialog().then(function(can) {
                                if (can) {
                                    listener();
                                    resolve();
                                }
                            });
                        });
                    }).then(function() {
                        // wait to allow state change to complete the url change
                        return $timeout(angular.noop, 1000);
                    }).then(function () {
                        waitPromise = null;
                    });
                });
            }

            function _canShowDialog() {
                return $q.all([
                    ExternalCheckout.isInExternalCheckoutFinishPage(),
                    $state.includes('app.user-confirmation')
                ]).then(function(results) {
                    return !results.find(function(value) {
                        return value;
                    });
                });
            }
        }
    ]);

    app.run(['$rootScope', 'ChooseAreaDialog', function($rootScope, ChooseAreaDialog) {
        $rootScope.ChooseAreaDialog = ChooseAreaDialog;
    }]);

    var CHOOSE_AREA_STATES = {
        TYPE_AREA: 1,
        CHOOSE_AREA: 2,
        AREA_NOT_FOUND: 3
    };

    app.controller('ChooseAreaCtrl', [
        '$state', '$location', '$rootScope', '$timeout', 'Config', 'Util', 'User', 'Loading', 'mDesign', 'SpDeliveryAreasService',
        'SpProductTags', 'doNotAllowChooseRetailers', 'areaName', 'queryAreaName', 'openPickup', 'allowClosing', 'area', 'SP_SERVICES',
        'DELIVERY_TIMES_TYPES',
        function ($state, $location, $rootScope, $timeout, Config, Util, User, Loading, mDesign, SpDeliveryAreasService,
            SpProductTags, doNotAllowChooseRetailers, areaName, queryAreaName, openPickup, allowClosing, area, SP_SERVICES,
            DELIVERY_TIMES_TYPES) {
            var chooseAreaCtrl = this,
                searchParams = $location.search(),
                $dialogElement;

            chooseAreaCtrl.CHOOSE_AREA_STATES = CHOOSE_AREA_STATES;
            chooseAreaCtrl.showTypeAreaState = showTypeAreaState;
            chooseAreaCtrl.autoCompleteAreas = autoCompleteAreas;
            chooseAreaCtrl.searchTypedArea = searchTypedArea;
            chooseAreaCtrl.resetErrors = resetErrors;
            chooseAreaCtrl.showPickupAreas = showPickupAreas;
            chooseAreaCtrl.chooseArea = chooseArea;
            chooseAreaCtrl.filterFoundAreas = filterFoundAreas;
            chooseAreaCtrl.getBranchLocation = SpDeliveryAreasService.getAreaAddressText;
            chooseAreaCtrl.areaNotFoundLog = areaNotFoundLog;
            chooseAreaCtrl.cancel = cancel;
            chooseAreaCtrl.allowClosing = allowClosing;
            chooseAreaCtrl.isShowDeliveryWithinDaysWarning = isShowDeliveryWithinDaysWarning;
            chooseAreaCtrl.changeVisibleState = changeVisibleState;
            chooseAreaCtrl.selectTab = selectTab;
            chooseAreaCtrl.addStyle = addStyle;
            chooseAreaCtrl.chooseAreaText = (Config.retailer.chooseAreaText || []).find(function(text) {
                return text.languageId === Config.language.id
            });
            chooseAreaCtrl.isBranchNameHidden = isBranchNameHidden;
            chooseAreaCtrl.isPresent = false;
            chooseAreaCtrl.getDeliveryInputPlaceholder = getDeliveryInputPlaceholder;
            chooseAreaCtrl.goToFilterAreas = goToFilterAreas;
            
            var chooseAreaAdvancedDialogs = {
                PICKUP_AND_DELIVERY: 'views/choose-area-advanced/pickup-and-delivery.html',
                NO_PICKUP: 'views/choose-area-advanced/no-pickup.html',
                NO_STORE_FOUND: 'views/choose-area-advanced/delivery-result/no-store-found.html',
                ONE_STORE_FOUND: 'views/choose-area-advanced/delivery-result/one-store-found.html',
                MULTIPLE_STORE_FOUND: 'views/choose-area-advanced/delivery-result/multiple-store-found.html',
                CHOOSE_PICKUP_TAB: 'views/choose-area-advanced/choose-pickup-tab.html',
                CHOOSE_DELIVERY_TAB: 'views/choose-area-advanced/choose-delivery-tab.html'
            };

            var searchMessageModes = {
                NO_STORE_FOUND: {
                    id: 0,
                    name: 'No Stores Found',
                    property: 'noStore',
                    defaultPopupTitle: 'default_no_store_popup_title',
                    defaultPopupSubTitle: 'default_no_store_popup_sub_title'
                },
                ONE_STORE_FOUND: {
                    id: 1,
                    name: 'One Store Found',
                    property: 'oneStore',
                    defaultPopupTitle: 'default_one_store_popup_title',
                    defaultPopupSubTitle: 'default_one_store_popup_sub_title',
                    defaultContinueButtonText: 'default_one_store_popup_continue_button_text'
                },
                MULTIPLE_STORE_FOUND: {
                    id: 2,
                    name: 'Multiple Stores Found',
                    property: 'multipleStore',
                    defaultPopupTitle: 'default_multiple_store_popup_title',
                    defaultPopupSubTitle: 'default_multiple_store_popup_sub_title'
                }
            };

            _init();
            SpDeliveryAreasService.setPreferredRetailerId(searchParams.retailerId && Number(searchParams.retailerId) || undefined);

            function _init() {
                if ($rootScope.disablePopupOnCurrentRoute) {
                    $rootScope.disablePopupOnCurrentRoute = false;
                    return selectArea(area);
                }

                return Config.waitForInit().then(function() {
                    return SpDeliveryAreasService.getChooseAreaMode(!doNotAllowChooseRetailers);
                }).then(function(mode) {
                    chooseAreaCtrl.isChangeArea = !!Config.branchAreaName;
                    chooseAreaCtrl.chooseAreaMode = mode;

                    if (mode === SP_SERVICES.CHOOSE_AREA_MODE.CHOOSE_RETAILER) {
                        chooseAreaCtrl.firstState = chooseAreaCtrl.visibleState = CHOOSE_AREA_STATES.CHOOSE_AREA;
                        SpDeliveryAreasService.getAreas().then(function (areas) {
                            _setFoundAreas(areas);
                        });
                    } else {
                        SpDeliveryAreasService.getPickupAreas().then(function (pickupAreas) {
                            chooseAreaCtrl.pickupAreas = pickupAreas;

                            if (openPickup || Config.advancedAreaAvailabilityEnabled) {
                                showPickupAreas();
                            }
                        });

                        chooseAreaCtrl.firstState = CHOOSE_AREA_STATES.TYPE_AREA;
                        showTypeAreaState();

                        if (queryAreaName) {
                            chooseAreaCtrl.queryArea = queryAreaName;
                            searchTypedArea(true);
                            return;
                        }

                        // In case areaName is sent in locals use it immediately
                        if (areaName) {
                            chooseAreaCtrl.area = areaName;
                            searchTypedArea(true);
                        }
                    }

                    if (Config.advancedAreaAvailabilityEnabled && Config.advancedAreaAvailabilitySettings) {
                        _initAdvancedAreaAvailability();

                        addStyle();
                    }

                });
            }

            function _setAreaAdvancedTemplate(){
                chooseAreaCtrl.chooseAreaAdvancedTemplate = chooseAreaAdvancedDialogs.PICKUP_AND_DELIVERY;
                chooseAreaCtrl.defaultTabId =  chooseAreaCtrl.currentAdvancedAreaAvailabilitySettings.firstDefaultTab ? 1 : 0;
        
                var retailerSupportedDeliveryMethod = Util.getRetailerSupportedDeliveryMethod();
                if (!retailerSupportedDeliveryMethod.isPickupSupported) {
                    chooseAreaCtrl.defaultTabId = 0;
                    chooseAreaCtrl.chooseAreaAdvancedTemplate = chooseAreaAdvancedDialogs.NO_PICKUP;
                }
            }

             // start advanced area availability setting
            function _initAdvancedAreaAvailability() {
                var scenarioId = localStorage.mobileZuzIsAreaChosen === 'true' ? 1 : 0;  // 0= first time, 1=returning
                chooseAreaCtrl.searchMessageMode = -1;
                chooseAreaCtrl.language = Config.language;
                chooseAreaCtrl.advancedAreaAvailabilitySettings = Config.advancedAreaAvailabilitySettings;
                chooseAreaCtrl.currentAdvancedAreaAvailabilitySettings = Config.advancedAreaAvailabilitySettings[Config.language.id];
                
                _setAreaAdvancedTemplate();
                chooseAreaCtrl.chooseAreaAdvancedCurrentDialog = chooseAreaCtrl.chooseAreaAdvancedTemplate;
                
                chooseAreaCtrl.tabs = {
                    PICKUP: {
                        id: 1,
                        title: chooseAreaCtrl.advancedAreaAvailabilitySettings[Config.language.id].pickupTabName,
                        templateUrl: chooseAreaAdvancedDialogs.CHOOSE_PICKUP_TAB,
                        controller: "ChooseAreaCtrl as chooseAreaCtrl",
                    },
                    DELIVERY: {
                        id: 0,
                        title: chooseAreaCtrl.advancedAreaAvailabilitySettings[Config.language.id].deliveryTabName,
                        templateUrl: chooseAreaAdvancedDialogs.CHOOSE_DELIVERY_TAB,
                        controller: "ChooseAreaCtrl as chooseAreaCtrl",
                    }
                };
                selectTab(chooseAreaCtrl.defaultTabId);

                var scenarios = [
                    {
                        id: 0,
                        name: 'First-Time Visitor (No address or store selected)',
                        property: 'firstTime',
                        defaultPopupTitle: 'default_first_time_popup_title',
                        defaultPopupSubTitle: 'default_first_time_popup_sub_title',
                        defaultPopupSubTitlePickup: 'default_first_time_popup_sub_title_pickup',
                        defaultPopupSubTitleDelivery: 'default_first_time_popup_sub_title_delivery',
                    },
                    {
                        id: 1,
                        name: 'Returning Visitor (Switching store selection)',
                        property: 'returning',
                        defaultPopupTitle: 'default_returning_popup_title',
                        defaultPopupSubTitle: 'default_returning_popup_sub_title',
                        defaultPopupSubTitlePickup: 'default_returning_popup_sub_title_pickup',
                        defaultPopupSubTitleDelivery: 'default_returning_popup_sub_title_delivery',
                    }
                ];
                chooseAreaCtrl.currentScenario = scenarios[scenarioId];

                chooseAreaCtrl.currentSearchMessageMode = null;
                chooseAreaCtrl.defaultStoreIcon = 'https://d226b0iufwcjmj.cloudfront.net/global/advanced-area-availability/default-store-icon.svg';
                chooseAreaCtrl.defaultDeliveryImage = 'https://d226b0iufwcjmj.cloudfront.net/global/advanced-area-availability/default-delivery-image.png';
                chooseAreaCtrl.defaultResultsImage = 'https://d226b0iufwcjmj.cloudfront.net/global/advanced-area-availability/default-delivery-result-image.png';

            }

            function addStyle() {
                var defaultMainColor = (window.tempConfig && window.tempConfig.color) || '#207d3f';
                var defaultTextColor = '#ffffff';
                chooseAreaCtrl.textColor = Config.advancedAreaAvailabilitySettings[Config.language.id].tabsAndButtonsTextColor || defaultTextColor;
                chooseAreaCtrl.bgColor = Config.advancedAreaAvailabilitySettings[Config.language.id].tabsAndButtonsBgColor || defaultMainColor;
            }

            function getDeliveryInputPlaceholder() {
                if (chooseAreaCtrl.chooseAreaText && !!chooseAreaCtrl.chooseAreaText.placeholder.length) {
                    return chooseAreaCtrl.chooseAreaText.placeholder;
                }

                if (chooseAreaCtrl.chooseAreaMode === $rootScope.SP_SERVICES.CHOOSE_AREA_MODE.TYPE_GOOGLE_ADDRESS) {
                    return 'delivery_input_address_placeholder';
                }

                if ($rootScope.config.isZipCodeArea) {
                    return 'delivery_input_zip_code_placeholder'
                }

                return 'Please enter a city';
            }
             // end advanced area availability setting

            function goToFilterAreas() {
                chooseAreaCtrl.visibleState = chooseAreaCtrl.filterAreas;
                chooseAreaCtrl.currentSearchMessageMode = null;
                chooseAreaCtrl.chooseAreaAdvancedCurrentDialog = chooseAreaCtrl.chooseAreaAdvancedTemplate;
                selectTab(chooseAreaCtrl.currentTab.id);
            }

            function selectTab(tabId) {

                if (tabId === chooseAreaCtrl.tabs.DELIVERY.id) {
                    chooseAreaCtrl.currentTab = chooseAreaCtrl.tabs.DELIVERY;
                    return chooseAreaCtrl.changeVisibleState(chooseAreaCtrl.CHOOSE_AREA_STATES.TYPE_AREA);
                }

                if(tabId === chooseAreaCtrl.tabs.PICKUP.id) {
                    chooseAreaCtrl.currentTab = chooseAreaCtrl.tabs.PICKUP;
                    chooseAreaCtrl.showPickupAreas();
                    return chooseAreaCtrl.changeVisibleState(chooseAreaCtrl.CHOOSE_AREA_STATES.CHOOSE_AREA);
                }
            }

            function showTypeAreaState() {
                changeVisibleState(CHOOSE_AREA_STATES.TYPE_AREA);
            }

            function autoCompleteAreas() {
                if (chooseAreaCtrl.addressChoices){
                    return chooseAreaCtrl.filterAreas.addressChoices;
                }
                else{
                    return SpDeliveryAreasService.autoCompleteDeliveryAreasWithFullData(chooseAreaCtrl.chooseAreaMode, chooseAreaCtrl.area).then(function(response){
                        chooseAreaCtrl.fullAutoCompleteData = response.places;
                        return response.names;
                    })
                }
            }

            function searchTypedArea(ignoreMultipleAddresses) {
                delete chooseAreaCtrl.addressChoices;

                if (!(chooseAreaCtrl.area || chooseAreaCtrl.queryArea)) {
                    chooseAreaCtrl.fullAddressError = false;
                    chooseAreaCtrl.wrongAddressError = true;
                    return;
                }

                chooseAreaCtrl.wrongAddressError = false;
                var queryArea = chooseAreaCtrl.area || chooseAreaCtrl.queryArea;
                return SpDeliveryAreasService.filterDeliveryAreas(chooseAreaCtrl.chooseAreaMode,
                    chooseAreaCtrl.area || chooseAreaCtrl.queryArea,
                    ignoreMultipleAddresses,
                    _selectPlaceIdByPlaceDescription(queryArea)).then(function(resp) {
                    // show wrong address error if there if no area has been found from query parameter
                    if (!resp.areas.length && chooseAreaCtrl.queryArea) {
                        chooseAreaCtrl.area = chooseAreaCtrl.queryArea;
                        chooseAreaCtrl.wrongAddressError = true;
                        return;
                    // show "areaNotFoundLog" if no area has been found
                    } else if(!resp.areas.length) {
                        chooseAreaCtrl.currentSearchMessageMode = searchMessageModes.NO_STORE_FOUND;
                        chooseAreaCtrl.chooseAreaAdvancedCurrentDialog = chooseAreaAdvancedDialogs.NO_STORE_FOUND;
                        changeVisibleState(CHOOSE_AREA_STATES.AREA_NOT_FOUND);
                        return;
                    }

                    var branchesIds = [];
                    chooseAreaCtrl.multiBranches = [];

                    angular.forEach(resp.areas, function (area) {
                        if(!branchesIds.includes(area.branch.id)) {
                            branchesIds.push(area.branch.id)
                            chooseAreaCtrl.multiBranches.push(area)
                        }
                    });

                    resp.areas = chooseAreaCtrl.multiBranches;

                    resetErrors();
                    changeVisibleState(CHOOSE_AREA_STATES.CHOOSE_AREA);
                    chooseAreaCtrl.isPickupButton = false;
                    chooseAreaCtrl.isOnlyPickupAreas = !resp.areas.find(function(area) {
                        return area.deliveryTypeId !== SP_SERVICES.DELIVERY_TYPES.PICKUP;
                    });
                    if(resp.areas.length === 1) {
                        chooseAreaCtrl.currentSearchMessageMode = searchMessageModes.ONE_STORE_FOUND;
                        chooseAreaCtrl.chooseAreaAdvancedCurrentDialog = chooseAreaAdvancedDialogs.ONE_STORE_FOUND;
                    } else {
                        chooseAreaCtrl.currentSearchMessageMode = searchMessageModes.MULTIPLE_STORE_FOUND;
                        chooseAreaCtrl.chooseAreaAdvancedCurrentDialog = chooseAreaAdvancedDialogs.MULTIPLE_STORE_FOUND;
                    }
                    _setFoundAreas(resp.areas, resp.addressComponents);

                }).catch(function(err) {
                    if (err.statusCode === 400) {
                        chooseAreaCtrl.fullAddressError = false;
                        chooseAreaCtrl.wrongAddressError = true;
                        return;
                    } else if (err.statusCode === 300) {
                        document.querySelector('#area').focus();
                        chooseAreaCtrl.area = '';
                        chooseAreaCtrl.addressChoices = [];
                        angular.forEach(err.data.addresses, function (address) {
                            chooseAreaCtrl.addressChoices.push(address.formatted_address);
                        });
                        return;
                    }

                    throw err;
                });
            }

            function selectArea(area) {
                var setCookiePromise = SpDeliveryAreasService.setRetailerIdCookie(area.retailer.id);
                var setUserArea = null;

                return Promise.all([setCookiePromise, setUserArea]).then(function () {
                    Config.multiRetailers.hasCookie = true;
                    Config.setLocalStorageItem('mobileZuzBranchAreaId', +area.id);

                    return Config.changeBranch(area.branch.id, area.id, {
                        typedArea: area.name,
                        newRetailerId: area.retailer.id
                    });
                }).then(function(isReload) {
                    mDesign.hide();
                    $state.go('app.home');
                    if (area.path.includes('specials')) {
                        $timeout(function() {
                            $state.go('app.specials');
                        }, 200);
                    }
                });
            }

            function _selectPlaceIdByPlaceDescription(description){
                if (chooseAreaCtrl.fullAutoCompleteData){
                    var place = chooseAreaCtrl.fullAutoCompleteData.find(function(place){ return place.description === description });
                    return place && place.place_id;
                }
                return  null;
            }

            function resetErrors() {
                chooseAreaCtrl.fullAddressError = false;
                chooseAreaCtrl.wrongAddressError = false;
            }

            function showPickupAreas() {
                changeVisibleState(CHOOSE_AREA_STATES.CHOOSE_AREA);
                chooseAreaCtrl.isPickupButton = true;
                chooseAreaCtrl.isOnlyPickupAreas = false;
                _setFoundAreas(chooseAreaCtrl.pickupAreas);
            }

            /**
             * Submit choose area form
             * @public
             *
             * @param {SpDeliveryArea} area
             *
             * @returns {Promise}
             */
            function chooseArea(area) {
                var setCookiePromise = SpDeliveryAreasService.setRetailerIdCookie(area.retailer.id);
                var sessionUserId = User.session && User.session.userId;
                var setUserArea = User.setUserArea(area, sessionUserId);

                return Promise.all([setCookiePromise, setUserArea]).then(function () {
                    Config.multiRetailers.hasCookie = true;

                    if (Config.retailerId !== area.retailer.id) {
                        Util.clearLocalStorage();
                    }

                    Config.setLocalStorageItem('mobileZuzTypedAreaText', chooseAreaCtrl.area);
                    Config.setLocalStorageItem('mobileZuzTypedAreaAddressComponents', JSON.stringify(chooseAreaCtrl.addressComponents));
                    Config.setLocalStorageItem('mobileZuzBranchAreaId', +area.id);

                    return Config.changeBranch(area.branch.id, area.id, {
                        typedArea: chooseAreaCtrl.area,
                        newRetailerId: area.retailer.id
                    });
                }).then(function(isReload) {
                    if (!isReload) {
                        mDesign.hide();
                    } else {
                        $state.go('app.home');
                        Loading.counter(null, 1);
                    }
                });
            }

            function filterFoundAreas(area) {
                return SpDeliveryAreasService.isAreaMatchTerm(area, chooseAreaCtrl.chooseAreaQuery);
            }

            function areaNotFoundLog() {
                // TODO: validation?
                return SpDeliveryAreasService.addAreaNotFoundLog(chooseAreaCtrl.area, chooseAreaCtrl.userEmail).then(function () {
                    cancel();
                });
            }

            /**
             * Set found areas
             * @private
             *
             * @param {Array<Object>} areas
             * @param {Array<Object>} [addressComponents]
             */
            function _setFoundAreas(areas, addressComponents) {
                chooseAreaCtrl.foundAreas = areas;
                chooseAreaCtrl.addressComponents = addressComponents;

                chooseAreaCtrl.areasDeliveryTimeTypes = {};
                SpDeliveryAreasService.getAreasDeliveryTimeTypes(chooseAreaCtrl.foundAreas).then(function(map) {
                    chooseAreaCtrl.areasDeliveryTimeTypes = map;
                    _setDeliveryWithinDaysTag();
                });
            }

            function _setDeliveryWithinDaysTag() {
                if (chooseAreaCtrl.deliveryWithinDaysTag) {
                    return;
                }

                var hasWithinDaysWarning = chooseAreaCtrl.foundAreas.find(function(area) {
                    return _isAreaDeliveryWithinDaysOnly(area);
                });
                if (!hasWithinDaysWarning) {
                    return;
                }

                return SpProductTags.getDeliveryWithinDaysTag().then(function(tag) {
                    chooseAreaCtrl.deliveryWithinDaysTag = tag;
                });
            }

            function isShowDeliveryWithinDaysWarning(area) {
                if (!chooseAreaCtrl.deliveryWithinDaysTag) {
                    return;
                }

                return _isAreaDeliveryWithinDaysOnly(area);
            }

            function _isAreaDeliveryWithinDaysOnly(area) {
                var mapValue = chooseAreaCtrl.areasDeliveryTimeTypes[area.id];
                if (!mapValue) {
                    return false;
                }

                return mapValue.length === 1 && mapValue[0] === DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_DAYS;
            }

            function isBranchNameHidden() {
                if(Config.retailer.settings.isHiddenBranchName === 'true' && chooseAreaCtrl.foundAreas[0].deliveryTypeId !== SP_SERVICES.DELIVERY_TYPES.PICKUP) {
                    return true;
                }

                return false;
            }

            function cancel() {
                mDesign.hide();
            }

            function changeVisibleState(toState) {
                chooseAreaCtrl.visibleState = toState;
                $timeout(function () {
                    $dialogElement = $dialogElement || document.querySelector('body > .md-dialog-container > md-dialog.choose-area-dialog');
                    $dialogElement && $dialogElement.focus()
                });
            }
        }]);
})(angular, angular.module('mobilezuz'));
