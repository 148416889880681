angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.extendedLoyaltyClub', {
        url: '/user-edit/extended-loyalty',
        data: {
            bodyClass: 'extended-loyalty-club',
            metaTags: {
                title: 'Extended Loyalty club'
            }
        },
        resolve: {
            retailerSettings: ['Retailer', function (retailer) {
                return retailer.getRetailerSettings();
            }]
        },
        views: {
            '@': {
                templateUrl: 'views/extended-loyalty-club.html',
                controller: 'ExtendedLoyaltyClubCtrl as extendedLoyaltyCtrl'
            }
        }
    });
}]).controller('ExtendedLoyaltyClubCtrl', [
    '$rootScope','$scope', '$q', '$timeout', 'Api', 'Config', 'User', 'Util', 'Retailer', 'Cart', 'Offers',
    'retailerSettings', 'SpCaptcha', '$state', 'mDesign', '$filter', 'CART_LINE_TYPES', '$window', 'LOYALTY_CLUB_DRIVERS',
    function ($rootScope, $scope, $q, $timeout, Api, Config, User, Util, Retailer, Cart, Offers,
              retailerSettings, SpCaptcha, $state) {
    var extendedLoyaltyCtrl = this;

        extendedLoyaltyCtrl.clientConfig = retailerSettings.loyaltyClubDriver.clientConfig;
        extendedLoyaltyCtrl.retailerContactPhone = retailerSettings.contactPhone;
        extendedLoyaltyCtrl.registerData = {};
        extendedLoyaltyCtrl.showCaptcha = true;
        extendedLoyaltyCtrl.registrationLevels = {
            LOYALTY: 1,
            WEBSITE: 2,
            REGISTER: 3,
            REGISTER_SUCCESS: 4,
            REGISTERED: 5
        };
        extendedLoyaltyCtrl.registrationLevel = extendedLoyaltyCtrl.registrationLevels.LOYALTY;
        extendedLoyaltyCtrl.genderEnum = {male: 1, female: 2, prefer_not_to_say: 3};

        extendedLoyaltyCtrl.next = next;
        extendedLoyaltyCtrl.getCityAutoCompleteOptions = getCityAutoCompleteOptions;
        extendedLoyaltyCtrl.loggedIn = !!User.getUserLoginData();

        _setLoyaltyInfo();

        function _setLoyaltyInfo() {
            if (extendedLoyaltyCtrl.loggedIn) {
                User.getUserSettings().then(function (resp) {
                    extendedLoyaltyCtrl.userData = resp;

                    if (!resp.loyaltyClubCardId) {
                        extendedLoyaltyCtrl.registerData.firstName = resp.firstName;
                        extendedLoyaltyCtrl.registerData.lastName = resp.lastName;
                        extendedLoyaltyCtrl.registerData.email = resp.email;
                        extendedLoyaltyCtrl.registerData.phoneNum = resp.phones[0].phoneNumber;
                        extendedLoyaltyCtrl.registerData.birthDate = resp.dateOfBirth;
                        extendedLoyaltyCtrl.registerData.city = resp.addresses && resp.addresses[0] && resp.addresses[0].city;
                        return;
                    }

                    if (extendedLoyaltyCtrl.registrationLevel !== extendedLoyaltyCtrl.registrationLevels.REGISTER_SUCCESS) {
                        extendedLoyaltyCtrl.registrationLevel = extendedLoyaltyCtrl.registrationLevels.REGISTERED;
                    }
                });
            }
        }

        function next(formName, toLevel) {
            if (formName && !_validate(formName)) {
                return;
            }

            if (toLevel) {
                extendedLoyaltyCtrl.registrationLevel = toLevel;
            }

            if (extendedLoyaltyCtrl.registrationLevel === extendedLoyaltyCtrl.registrationLevels.REGISTER) {
                _register();
            }


        }

        function getCityAutoCompleteOptions() {
            if (!extendedLoyaltyCtrl.registerData.city) {
                return $q.resolve();
            }

            return Api.request({
                method: 'GET',
                url: '/v2/addresses/cities',
                params: {
                    term: extendedLoyaltyCtrl.registerData.city,
                    size: 5
                }
            }).then(function (data) {
                return data.cities.map(function (city) {
                    return city.name;
                });
            });
        }

        function _register() {
            extendedLoyaltyCtrl.registerData.recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();
            return Api.request({
                method: 'POST',
                url: extendedLoyaltyCtrl.loggedIn ? 'retailers/:rid/users/:uid/loyalty/_extended-registration' : 'retailers/:rid/loyalty/_extended-registration',
                data: extendedLoyaltyCtrl.registerData
            }).then(function (response) {
                if (Util.isLoyaltyPremiumPackageEnabled()) {
                    return $state.go('app.premiumLoyaltyClub');
                }
                extendedLoyaltyCtrl.registrationLevel = extendedLoyaltyCtrl.registrationLevels.REGISTER_SUCCESS;

                if (extendedLoyaltyCtrl.loggedIn) {
                    return _setLoyaltyInfo();
                }

                User.login(extendedLoyaltyCtrl.registerData.email, extendedLoyaltyCtrl.registerData.password).then(function () {
                    extendedLoyaltyCtrl.loggedIn = true;
                    _setLoyaltyInfo();
                });
            }).catch(function () {
            });
        }

        function _validate(formName) {
            if (extendedLoyaltyCtrl[formName].$invalid) {
                var errorElem = extendedLoyaltyCtrl[formName].$error[Object.keys(extendedLoyaltyCtrl[formName].$error)[0]][0].$$element[0];
                if(errorElem.type === 'hidden') {
                    _scrollRegisterTabDown(formName);
                } else {
                    errorElem.scrollIntoView();
                    errorElem.focus();
                }
                return false;
            } else if (extendedLoyaltyCtrl.registrationLevel === extendedLoyaltyCtrl.registrationLevels.LOYALTY && !extendedLoyaltyCtrl.registerData.bylaws) {
                return false;
            } else if (extendedLoyaltyCtrl.registrationLevel === extendedLoyaltyCtrl.registrationLevels.WEBSITE && !extendedLoyaltyCtrl.registerData.terms) {
                return false;
            }

            return true;
        }

        function _scrollRegisterTabDown(formName) {
            var formElement = document.getElementsByTagName(formName);
            if(formElement && formElement[0]){
                formElement[0].scrollTop = formElement[0].scrollHeight;
            }
        }
    }
]);