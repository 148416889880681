(function (angular) {
    'use strict';
    angular.module('mobilezuz').directive('spPromotion', [function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                product: '=',
                special: '=?',
                isHomeSpecial: '=?',
                isHomeProduct: '=?',
                isProduct: '=?',
                isSpecial: '=?',
                productSpecial: '=?',
                withoutActions: '@?',
                spTabindex:'=?'
            },
            templateUrl: 'views/templates/sp-promotion.html',
            controller: ['$scope', '$rootScope', '$timeout', 'Config', 'Util', 'Cart', 'SpecialDetailsDialog', function ($scope, $rootScope, $timeout, Config, Util, Cart, SpecialDetailsDialog) {

                _assignSpecials($scope.product);
                $scope.promotionId = 'promotion_tooltip_' + ($scope.product ? $scope.product.id : $scope.special.id);
                $scope.currentSpecialIndex = 0;

                function _assignSpecials(product) {
                    $scope.specials = product ? Util.filterProductSpecials(product.branch): [$scope.special];
                }

                Util.destroyListeners($scope,  $scope.$watch('product', function(newProduct, oldProduct) {
                    if (newProduct !== oldProduct) {
                        _assignSpecials(newProduct);
                    }
                }));


                $scope.previousSpecial = function() {
                    $scope.currentSpecialIndex === 0 ? ($scope.currentSpecialIndex = $scope.specials.length - 1) : $scope.currentSpecialIndex--;
                }

                $scope.nextSpecial = function() {
                    $scope.currentSpecialIndex === $scope.specials.length - 1 ? ($scope.currentSpecialIndex = 0) : $scope.currentSpecialIndex++;
                }

                $scope.toggleTooltip = function(event) {
                    event.stopPropagation();
                    $rootScope.promotionTooltip = $scope.promotionId;
                }

                $scope.closeTooltip = function (event) {
                    event.stopPropagation();
                    $rootScope.promotionTooltip = null;

                    $timeout(function () {
                        var $button = angular.element(document.querySelector('#' + $scope.promotionId + ' .promotion-wrapper .text'));
                        $button && $button.focus();
                    });
                }

                $scope.showSpecialDetails = function(event) {
                    event.stopPropagation();
                    var showProductsFrom = Cart.specialReminders.onProducts &&
                        Cart.specialReminders.onProducts[$scope.specialToShow.id] &&
                        Cart.specialReminders.onProducts[$scope.specialToShow.id].showProductsFrom;
                    return SpecialDetailsDialog.show($scope.specialToShow.id, showProductsFrom);
                } 

                if ($scope.product && $scope.specials && $scope.specials.length) {
                    var highPrioritySpecial;
                    for (var i = 0; i < $scope.specials.length; i++) {
                        var special = $scope.specials[i];
                        if (Config.showCouponsSpecials || !special.isCoupon) {
                            var specialLoyaltyClubsIds = (special.loyaltyClubsIds && special.loyaltyClubsIds.length) || 0,
                                topPrioritySpecialLoyaltyClubsIds = highPrioritySpecial ? (highPrioritySpecial.loyaltyClubsIds && highPrioritySpecial.loyaltyClubsIds.length) || 0 : 0;

                            if (!highPrioritySpecial || specialLoyaltyClubsIds > topPrioritySpecialLoyaltyClubsIds) {
                                highPrioritySpecial = special;
                            } else if (topPrioritySpecialLoyaltyClubsIds === 0) {
                                if (special.firstLevel.type < highPrioritySpecial.firstLevel.type) {
                                    highPrioritySpecial = special;
                                }
                            }
                        }
                    }

                    $scope.specialToShow = highPrioritySpecial;
                }

                $scope.specialToShow = $scope.specialToShow || $scope.productSpecial || $scope.special;
            }]
        };
    }]);
})(angular);