(function (angular, app) {
    'use strict';

    app.service('LoyaltyIFrameDialog', ['mDesign', function (mDesign) {
        this.show = show;
        this.hide = hide;

        function show(url, data) {
            return mDesign.dialog({
                templateUrl: 'views/templates/loyalty-iframe-dialog.html',
                controller: 'LoyaltyIFrameCtrl as loyaltyIframeCtrl',
                multiple: true,
                clickOutsideToClose: false,
                locals: {
                    url: url,
                    data: data
                }
            });
        }

        function hide() {
            mDesign.cancel();
        }
    }]);

    app.directive('iframeOnload', [function() {
        return {
            scope: {
                callBack: '&iframeOnload'
            },
            link: function(scope, element) {
                element.on('load', function(){
                    return scope.callBack();
                })
            }
        }
    }]);

    app.run(['$rootScope', 'LoyaltyIFrameDialog', function ($rootScope, LoyaltyIFrameDialog) {
        $rootScope.LoyaltyIFrameDialog = LoyaltyIFrameDialog;
    }]);

    app.controller('LoyaltyIFrameCtrl', ['$rootScope', '$sce', 'User', 'url', 'data',
        function ($rootScope, $sce, User, iframeUrl, data) {
            var loyaltyIframeCtrl = this;
            angular.extend(loyaltyIframeCtrl, {
                iframeUrl: $sce.trustAsResourceUrl(iframeUrl),
                iframeLoaded: iframeLoaded,
                closeDialog: closeDialog
            });
            window.addEventListener("message", function(e) {
                if(e.data === 'ready'){
                    iframeLoaded()
                }
                else {
                    //if (e.origin == iframeUrl) {
                    if (e.data.isSuccess) {
                        User.getUserSettings(true).then(function(userSettings) {
                            if (userSettings.foreignId) {
                                document.getElementById('loyalty_iframe').src += '';
                                closeDialog();
                            } else {
                                console.log("NO FOREIGN ID");
                            }
                        });
                    }
                    //}
                }
            });

            function iframeLoaded() {
                document.getElementById('loyalty_iframe').contentWindow.postMessage(data, iframeUrl);
            }

            function closeDialog() {
                $rootScope.LoyaltyIFrameDialog.hide();
            }
        }]);
})(angular, angular.module('mobilezuz'));
