(function (angular) {
	angular.module('mobilezuz')
		.filter('trustHtml', ['$sce', function ($sce) {
			return function (value) {
				if (!value) {
					return;
				}

				return $sce.trustAsHtml(value);
			};
		}]);
})(angular);