(function (angular) {
    angular.module('mobilezuz')
        .filter('translate', ['Config', 'EnLanguage', function (Config, EnLanguage) {
            var languagesByCulture = {};
            angular.forEach(Config.languages, function(language) {
                languagesByCulture[language.culture] = language;
            });

            function filter(input) {
                var lowerCase = input && input.toLowerCase(),
                    languages = [Config.language],
                    i;

                // add fallback languages when not in english
                // english has no fallback languages, it should have all the necessary keys
                if (Config.language !== EnLanguage) {
                    // the first fallback is the retailer's language
                    if (Config.language.culture !== Config.retailerCulture && languagesByCulture[Config.retailerCulture]) {
                        languages.push(languagesByCulture[Config.retailerCulture]);
                    }

                    // the second fallback is english
                    languages.push(EnLanguage);
                }

                for (i = 0; i < languages.length; i++) {
                    if (languages[i].countriesTranslations && languages[i].countriesTranslations[Config.retailer.countryIso] &&
                        !angular.isUndefined(languages[i].countriesTranslations[Config.retailer.countryIso][lowerCase])) {
                        return languages[i].countriesTranslations[Config.retailer.countryIso][lowerCase];
                    }
                    if (languages[i].translations && !angular.isUndefined(languages[i].translations[lowerCase])) {
                        return languages[i].translations[lowerCase];
                    }
                }

                return input;
            }

            filter.$stateful = true;
            return filter;
        }]);
})(angular);