angular.module('mobilezuz')
.directive('floatingCart', function () {
  return {
    restrict: 'E',
    templateUrl: 'views/templates/floating-cart.html',
    replace: true,
    link: function (scope, iElement, iAttrs) {

    }
  };
});