(function (angular) {
    "use strict";
    angular.module('mobilezuz').directive('spSpecialsList', ['Retailer',
        function (retailer) {
            return {
                restrict: 'EA',
                templateUrl: 'views/templates/single-product-content.html',
                controllerAs: 'specialListItemCtrl',
                controller: function () {
                    var specialListItemCtrl = this;
                    retailer.getRetailerSettings().then(function (res) {
                        specialListItemCtrl.showSaleExperations = res.showSpecialsValidity;
                    });
                },
                link: function ($scope, element, attrs) {
                    $scope.isSpecialPage = attrs.isSpecialPage === 'true';
                }
            };
        }]);
})(angular);
