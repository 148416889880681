(function(angular) {
	angular.module('mobilezuz').service('EnLanguage', function() {
		this.id = 2;
		this.culture = 'en';
		this.name = 'English';
		this.direction = 'ltr';
		this.translations = {
			'not editable error': 'You cannot edit this field before entering a postcode for your address',
			'not_editable_locked_empty error': 'Please enter your postcode and choose your address from the list',
			'not_editable_locked error': 'To change the address please choose again from postcode list',
			'zipcode error': 'You cannot edit this field',
			'user not found': 'Invalid Username or Password',
			'loyalty user not found': 'Invalid loyalty login details',
			'not all of the products given where found': 'Not all of the products were found',
			'branch not found': 'We\'re  sorry, but the address you provided is not served at this time',
			'this email already exist': 'This email already exists',
			'email_error': 'Please enter a valid email',
			'day0': 'Sun',
			'day1': 'Mon',
			'day2': 'Tue',
			'day3': 'Wed',
			'day4': 'Thu',
			'day5': 'Fri',
			'day6': 'Sat',
			'ticket_status_1': 'NEW',
			'ticket_status_2': 'HANDLING',
			'ticket_status_3': 'DONE',
			'substitute_preference_1_label': 'Replace',
			'substitute_preference_1_description': 'We will replace a missing product without contacting you',
			'substitute_preference_2_label': 'Call Me',
			'substitute_preference_2_description': 'We will call you before we replace a missing product',
			'substitute_preference_3_label': 'Don\'t Replace',
			'substitute_preference_3_description': 'We will not replace a missing product',
			'substitute_preference_4_label': 'Send SMS',
			'substitute_preference_4_description': 'We will send you a text message before we replace a missing product',
			'substitute_preference_5_label': 'Send WhatsApp',
			'substitute_preference_5_description': 'We will send you a text message in WhatsApp before we replace a missing product',
			'substitute_preference__label': 'to prevent error',
			'substitute_preference__description': 'to prevent error',
      'leave_by_the_door': "Please leave the order outside the door if nobody's home",
			'localdelivery': 'Local Delivery',
			'shipping': 'Shipping',
			'callcustomer': 'Call Me',
			'creditcard': 'Credit Card',
			'storecredit': 'Credit On File',
			'percent daily': 'Percent Daily Values are based on a 2,000 calorie diet. Your daily value may be higher or lower depending on your calorie needs.',
			'promotion details': 'Details',
			'delivery_type_1': 'Delivery',
			'delivery_type_2': 'Pickup',
			'delivery_type_3': 'Pick And Go',
			'delivery_type_5': 'Express Delivery',

			'delivery_type_1_times': 'Delivery Times',
			'delivery_type_2_times': 'Pickup Times',
			
			'area_type_1': 'Delivery',
			'area_type_2': 'Delivery',
			'area_type_3': 'Delivery',
			'area_type_4': 'Delivery',
			'area_type_5': 'Pickup',

			'product name': 'Product Name',

			'house_entry_none': 'None',
			'house_entry_a': 'a',
			'house_entry_b': 'b',
			'house_entry_c': 'c',
			'house_entry_d': 'd',
			'house_entry_e': 'e',
			'house_entry_f': 'f',
			'house_entry_g': 'g',
			'house_entry_h': 'h',
			'house_entry_i': 'i',
			'house_entry_j': 'j',
			'entry: spautocompleterequirematch error': 'invalid entrance',
			'1 hours': ' 1 hour',
			'{hours} hours delivery': '{hours} hours',
			'no_deliveries_first_row': 'Sorry, currently no',
			'no_deliveries_second_row': 'deliveries on this day',
			'no_pickup_first_row': 'Sorry, currently no',
			'no_pickup_second_row': 'pickup on this day',
			'callcustomer_description': 'We will call you to confirm the payment',
			'creditcard_description': 'Choose or add a credit card',
			'pay_creditcard_description': 'You will be asked to pay with credit card at the end',
			'storecredit_description': 'Pay with your credit on file ID',
			'paypal_description': 'Pay with PayPal',
			'icreditmasterpass_description': 'Pay with your MasterPass account',
			'bit_description': 'You will be transferred to Bit',
			'ebt_description': 'EBT payment',

			'before_checkout_delivery_title_1': 'Choose the desired',
			'before_checkout_delivery_title_2': 'delivery time',
			'before_checkout_pickup_title_1': 'Pickup your order',
			'before_checkout_pickup_title_2': 'from the store',
			'checkout_title': 'Checkout',
			'pick_and_go_title': 'Pick & Go',
			'defective card.': 'Invalid credit card number. Please try again.',
			'loyalty_mode_1_label': 'Existing Member',
			'loyalty_mode_2_label': 'New Member',
			'connect_loyalty': 'Connect',
			'loyalty_terms_confirm': 'I confirm that i have read the',
			'delivery time': 'Delivery time',
			'pickup time': 'Pickup time',
			'pickandgo time': 'Pick & Go time',
			'special_exp': 'Ends at',
			'special_exp_new': 'Valid',
			'forpackquantity': 'for',
			'verify_1': 'Please verify',
			'verify_2': 'your age',
			'verify_3': 'You must be at least',
			'verify_4': 'years old to purchase',
			'verify_5': 'this item',
			'verify_6': 'I am',
			'verify_7': 'older',
			'verify_8': 'than',
			'verify_9': 'younger',
			'membership_title_register': 'Join',
			'membership_title_identify': 'Identify',
			'membership_title_{clubname}': '{clubname}',
			'membership_{clubname}_title': '{clubname}',
			'menu_stores_info': 'Stores Details',
			'menu_store_info': 'Store Details',
			'policy_end': '',
			'organization_description': 'On credit payment',
			'save_1': 'Save',
			'received_items': 'Received',

			'icreditmasterpass': 'MasterPass',

			'buy_x_for_y': 'Buy {{purchaseTotal}}',

			'add_product': 'Add',
			'nagish_close_component': 'Hide',

			'chametz': 'Chametz',
			'passoverkosher': 'Kosher for Passover',
			'passoverkoshertemporarily': 'Temporarily Kosher for Passover',
			'passoverkitniyotkosher': 'Kosher for Passover (Kitniyot)',
			'passoverkitniyotkoshertemporarily': 'Temporarily Kosher for Passover (Kitniyot)',
			'meat': 'Kosher Fleischig',
			'milky': 'Kosher Milchig',
			'pareve': 'Kosher Pareve',
			'israelmilk': 'Cholov Yisroel',
			'israelcooking': 'Bishul Yisroel',
			'sabbathobservingfactory': 'Sabbath Observing Factory',
			'noshviitconcern': 'No Shviit Concern',
			'noorlaconcern': 'No Orla Concern',
			'notevelconcern': 'No Tevel Concern',
			'noovernightconcern': 'No Overnight Concern',
			'nogeniza': 'No Geniza',
			'at_hour': 'At',
			'the waiting period will not exceed two hours': 'Delivery within 2 hours',
			'special_reminder_bubble_title': 'SAVE!',
			'special_reminder_awarded_bubble_title': 'You Saved!',
			'special_reminder_button_title': 'Tap & SAVE!',
			'coupon_requirements_error': 'You haven\'t met the requirements',
            'product info disclaimer': 'Product information or packaging displayed may not be current or complete. Always refer to the physical product for the most accurate information and warnings. For additional information, contact the retailer or manufacturer.',
			'permanent_filters_legal_text': 'When using the various filtering options offered on the site, the Company does not warrant that there will be no malfunctions and/or errors on the site, including in the performance of the particular filtering. The Company does not undertake that the categorization of products will be accurate and exclude products that do not meet the required criteria nor all presented products meet the criteria. Use of the information obtained is in your sole responsibility, including with respect to any damage caused as a result of the use in the Information or reliance on it. Each product, including its components, must be tested individually. You will be responsible for verifying any information against the manufacturer\'s data for that product and, in the event of any discrepancy between the information presented on the site and the information presented by the manufacturer, the information presented by the manufacturer must be followed. The foregoing shall not create liability, whether for indirect or direct damages, of the Company in connection with the information displayed by the manufacturer of any product.',
			'clear_all_filters_2': 'Clear all filters',
			'required error': '{{name}} is required',

			'custom-phone-validation-range': 'Phone number length must be from {min} to {max} characters',
			'custom-phone-validation-min': 'Phone number minimum length must be {min} characters',
			'custom-phone-validation-max': 'Phone number maximum length must be {max} characters',
			'invalid-phone': 'Phone number is not valid',

			'accessibility_statement_p1': 'In {retailerName}, we believe that our services should be available to anyone, and are committed to providing a website that is accessible to the widest possible audience, regardless of circumstances and ability. We work hard to make and maintain this site accessible by following conformance level AA of WCAG 2.0 which is the Israeli standard 5568 by law.',
			'accessibility_statement_p2': 'This site audited for accessibility by Migdalor, a multi-service center for blind and visually impaired people and we fixed all issues that found in the audit report. Please notice that although we are working to maintain this site accessible there might be parts of it that are not accessible enough.',
			'accessibility_statement_p3': 'Here is the accessibility we know that we are providing in this site:',
			'accessibility_point_1': '* This site can be view and navigate through different screen sizes and platforms without losing any information.',
			'accessibility_point_2': '* You can enlarge the text keyboard shortcut: "CTRL" and "+" (control plus).  It will enlarge it by 10% each time you press the + (plus) key. To reduce it, use the keyboard shortcut: "CTRL" and "-" (control minus).',
			'accessibility_point_3': '* You can navigate and activate all features on site by using the keyboard only.',
			'accessibility_point_4': '* The pages structure is semantic and conforming to the standard.',
			'accessibility_point_5': '* Assistive technology – this site is accessible and available for screen reader users. For optimal navigation, we recommend using NVDA screen reader or iOS voice over.',
			'accessibility_point_6': '* Hierarchical headings',
			'accessibility_point_7': '* Meaningful description for pictures',
			'accessibility_point_8': '* Visible focus',
			'accessibility_point_9': '* Meaningful and simple focus order',
			'accessibility_point_10': '* Error notification in the contact form',

            'after_register_title': 'You\'ve logged in successfully',
            'to_home_page': 'HOME PAGE',
			'EBT_card_is_required': 'EBT card is required',
			'cancel_order_credit_card' : 'Credit Card',
			'cancel_order_credit_card_sum' : 'Credit card sum',
			'cancel_order_ebt_card' : 'EBT card',
			'cancel_order_ebt_snap_refund' : 'EBT SNAP Refund',
			'cancel_order_ebt_cash_refund' : 'EBT Cash Refund',
			'cancel_order_updated_snap_balance' : 'Updated SNAP Balance',
			'cancel_order_updated_cash_balance' : 'Updated Cash Balance',
			'registration attempt has been blocked, please contact support': 'Registration attempt has been blocked, please contact support',
			'account has been suspended, please contact support': 'Account has been suspended, please contact support',

			'cart': '{cart}',
			'add to cart': 'Add to {cart}',
			'select products and click import to cart': 'Select products and click Import to {cart}',
			'import to cart': 'Import to {cart}',
			'you have an open cart with': 'You have an open {cart} with',
			'would you like to merge the two carts or continue using this cart': 'Would you like to merge the two {cart}s or continue using this {cart}',
			'continue with this cart': 'Continue with this {cart}',
			'merge carts': 'Merge {cart}s',
			'the items below will be removed from your cart': 'The items below will be removed from your {cart}',
			'clear cart?': 'Clear {cart}?',
			'are you sure you want to remove all products from your shopping cart?': 'Are you sure you want to remove all products from your shopping {cart}?',
			'back to cart': 'Back to {cart}',
			'yes clear cart': 'Yes, clear {cart}',
			'add cart products': 'Add {cart} products',
			'remove item from cart': 'Remove item from {cart}',
			'in cart': 'In {cart}',
			'my cart': 'My {cart}',
			'the items in your shopping cart will be cleared if you choose to edit your previous order': 'The items in your shopping {cart} will be cleared if you choose to edit your previous order',
			'tavplus': 'Tav Plus+',
			'tavplus_description': '',
			'tav_plus_card_is_required': 'Tav plus card is required',
			'tav_plus_credit_card_disclaimer_short': 'The use of Tav Plus is limited by entering a credit card as a backup payment. The final billing amount will be determined at order collection and may vary due to weighable, missing, or...',
			'tav_plus_credit_card_disclaimer_long': 'The use of vouchers is limited by entering a credit card as a backup payment. The final billing amount will be determined at order collection and may vary due to weighable, missing, or replacement products. In the event of the charge being exceeded from the amount entered to be redeemed or the voucher balance, the payment balance will be charged to the credit card. The voucher must be kept until the order is received and the charge is made. Employee discount will not given when paying with "Tav Plus+"',
			'receipt_option_title_1': 'Digital receipt',
			'receipt_option_title_2': 'Both',
			'receipt_option_title_3': 'Printed receipt',
			'receipt_option_1': 'The receipt will be received via SMS',
			'receipt_option_2': 'The receipt will be received via SMS and a physical copy will be attached to your order',
			'receipt_option_3': 'The receipt will be attached to your order',
			'delivery_type_1_fee': 'Delivery fee',
			'includes_delivery_type_1_fee': 'Includes delivery fee',
			'not_includes_delivery_fee': '(not include the delivery fee)',
			'delivery_type_2_fee': 'Pickup fee',
			'includes_delivery_type_2_fee': 'Includes pickup fee',
			'delivery_type_5_fee': 'Express Delivery fee',
			'includes_delivery_type_5_fee': 'Includes express delivery fee',
			'delivery-fee-explain-default': 'The final delivery fee amount is expected to be updated upon checkout and might be subjected to changes due to the delivery area/time or any additional discounts.',
			'you_can_not_add_more_than': 'You can not add to the shopping cart more than',
			'items_marked_with_symbol': 'items marked with this symbol',
			'we have prepared a list of products you usually purchase': 'We\'ve prepared a list of products you usually purchase',

			'last_minute_recommendations_headline': 'we have recommendations selected especially for you.',
			'last_minute_recommendations_title_coupons': 'Coupons that can save you money',
			'last_minute_recommendations_title_products_usually': 'Maybe you forgot these products?',
			'last_minute_recommendations_title_products_complementary': 'You may also like...',
			'last_minute_recommendations_title_specials_offers': 'Specials you don\'t want to miss',
			'last_minute_recommendations_title_products_offers': 'You don\'t want to miss these products',
			'can be redeemed x times': 'Can be redeemed {X} times',
			'clip for x points': 'Clip for {X} points',
			'coupon expires in x days': 'Coupon expires in {X} days',

			'last_minute_recommendations_title_products_offers': 'You don\'t want to miss these products',

			'account_deletion_common_customer_support_1': 'For support call customer service:',
			'account_deletion_common_customer_support_2': 'Please try again later or call customer service:',
			'account_deletion_account_title': 'Delete Account',
			'account_deletion_init_title': 'Delete your account',
			'account_deletion_recaptcha_description': 'A verification using a few steps is required to delete your account',
			'account_deletion_authentication_description': 'An email with a verification code has been sent to {userEmail}',
			'account_deletion_authentication_help_spam': 'You may need to check you spam folder',
			'account_deletion_authentication_help_code_1': 'Didn\'t receive the code?',
			'account_deletion_authentication_help_code_2': 'send again',
			'account_deletion_authentication_help_error_try_again_1': 'Please try again or',
			'account_deletion_authentication_help_error_try_again_2': 'resend a new code',
			'account_deletion_authentication_help_error_max_tries': 'You have exceeded the number of possible attempts, please try again later',
			'Expired code': 'Expired code',
			'Invalid code': 'Invalid code',
			'account_deletion_confirmation_description_1': 'You\'re about to',
			'account_deletion_confirmation_description_2': 'permanently delete your account',
			'account_deletion_confirmation_description_3': 'all your account data and future orders will be deleted',
			'account_deletion_confirmation_description_4': 'This action cannot be undone',
			'account_deletion_confirmation_help_input_1': 'By typing in the word "CONFIRM", you will approve deleting your account',
			'account_deletion_confirmation_help_input_2': 'Type "CONFIRM" to approve',
			'account_deletion_already_deleted_title': 'Cannot proceed',
			'account_deletion_already_deleted_description': 'This account has been deleted and is being processed',
			'account_deletion_notification_title': 'Your account will be deleted in {numOfDaysUntilDeletion} days',
			'account_deletion_notification_description': 'Any orders placed to be delivered or picked up after this time will not be supplied',
			'account_deletion_error_title': 'System error occurred',
			'account_deletion_error_description': 'Cannot proceed due to a system error',
			'coupon expires today': 'Coupon expires today',
			'coupon expires in {x} days': 'Coupon expires in {x} days',
			'add_units_coupon': 'Add {remaining} more to get the Discount',
			'add_units_coupon_no_remaining': 'Add to get the Discount',
			'add_currency_coupon': 'Add {remaining} more to get the Discount',
			'add_measure_coupon': 'Add {remaining} {unit} more to get the Discount',
			'add_units_cart_coupon': 'Add {remaining} Products',
			'add_unit_cart_coupon': 'Add {remaining} Product',
			'add_currency_cart_coupon': 'Add {remaining}',
			'add_measure_cart_coupon': 'Add {remaining} {unit}',
			'add_units_special_purchase': 'Add {remaining} more',
			'buy_units_total': 'Buy {total} products',
			'buy_unit_total': 'Buy {total} product',
			'buy_currency_total': 'Buy for {total}',
			'buy_measure_total': 'Buy {total} {unit}',
			'please enter valid email': 'Please enter valid email',
			'enter valid phone number (10 digits)': 'Enter valid phone number (10 digits)',

			'suggestions_dialog_header_1': 'Recommendation for Replacements',
			'suggestions_dialog_header_2': 'Please choose a replacement from our variety of similar products',
			'suggestions_dialog_header_3': 'OUT OF STOCK',
			'suggestions_dialog_header_4': 'RECOMMENDATION FOR REPLACEMENTS:',
            'ok, continue' : 'OK, CONTINUE',
            'change chosen time': 'CHANGE CHOSEN TIME',

			'hover description for cvv': 'The CVV Number (“Card Verification Value”) on your credit card or debit card is a digit number on VISA, Mastercard and Discover branded credit card and debit card. on your American Express branded credit or debit card is a 4 digit numeric code.',
			'clipped but not in cart': 'Clipped, items not added',
			'coupon clipped': 'Coupon clipped',
			'add product': 'Add product',
			'product in cart': 'Product in cart',
			'clip coupon': 'Clip coupon',
			'gift_card_error_message': 'We couldn\'t process your GIFT CARD, please try again or try a different card',
			'wrong_gift_card_amount_error': 'Amount exceeds shopping cart value',
			'amount_exceeds_balance': 'Amount exceeds balance',
			'customercredit': 'Customer Credit',
			'replace_2': 'Replace',
			'gg_map_dialog_title': 'Choose an Address',
			'gg_map_dialog_hint': "We're having trouble pinpointing your exact location. Please ensure you position the pin with great precision. This will help us determine your precise address",
			'gg_map_alert_need_location': 'Please turn on location in order to use Google Map',
			'gg_map_alert_error': 'There are some problems when showing Google Map',
			'gg_map_alert_unsupport': 'Geolocation is not supported by this browser',
			'autocomplete_adrs_didnt_find': 'Didn\'t find your address?',
			'autocomplete_adrs_choose_location': 'Choose your location on the map',
			'autocomplete_adrs_city_not_found': 'sorry, your city was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.',
			'autocomplete_adrs_adrs_not_found': 'sorry, your address was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.',
			'home_page_title': 'Home Page',
			'countryInput: required error': 'Please enter a country',
			'cart_line_added_alert': 'was added to the shopping cart',
			'address_warning_enter_house_num': 'Please enter your house number',
			'country_of_origin_title': 'Country of Origin',
			'origin_country_legal_text': 'Please be aware that while the country of origin listed is accurate as of the ordering date, the actual country of origin for the delivered product may vary based on the stock available at the collection point during delivery',
			'powered_by_accessibility': 'powered by',
			'scanner_accessibility_text': 'Scanner. Not accessible to a screen reader, you can make a purchase through the regular process using the text search box',
			'contact_us': 'Contact us',
			'how_can_we_help_you_today': 'How can we help you today?',
			'leave_us_a_message': 'Leave us a message, and we\'ll reach out to you soon',
			'fullname': 'Full name',
			'phone': 'Phone number',
			'email': 'Email',
			'message': 'How can we help you?',
			'send': 'Send',
			'your_message_has_been_sent': 'Your message has been sent',
			'thank_you_for_contact_us': 'Thank you for contacting us',		
			'searchtext_storename_placeholder': '{Store name}',
			'nofshonit': 'Nofshonit',
			'max gift card': 'Max Gift Card',
			'customer support phone number': 'Customer Support Phone Number',
			'loading coupons, please wait...': 'Loading coupons, please wait...',
			'please wait, we are loading the products': 'Please wait, we are loading the products',
			'complete_and_proceed_to_checkout': 'Complete & Proceed to Checkout',

			'home': 'Home',
			'specials': 'Specials',
			'departments': 'Departments',
			'my list': 'My List',
			'coupons': 'Coupons',
			'contact us': 'Contact Us',
			'accessibility statement': 'Accessibility Statement',
			'scanner': 'Scanner',
			'customer service': 'Customer Service',
			'cart': 'Cart',
			'menu': 'Menu',
			'address_form_field_label_city': 'City',
			'address_form_field_label_address': 'Address',
			'address_form_field_label_zip_code': 'Zip Code',
			'address_form_field_label_postal_code': 'Postal Code',
			'address_form_field_label_entrance': 'Entrance',
			'address_form_field_label_apartment_number': 'Apartment Number',
			'address_form_field_label_building_code': 'Building Code',
			'address_form_field_label_state': 'State',
			'address_form_field_label_comments': 'Comments',
			'address_form_field_label_apartment_suite_unit': 'Apartment / Suite / Unit',
			'address_form_field_label_providence': 'Providence',
			'address_form_field_label_more_details': 'More Details',
			'address_form_field_label_floor': 'Floor',
			'address_form_field_label_house_number': 'House number',
			'address_form_field_label_street': 'Street',    

			'address_form_field_error_city': 'Please enter a valid City',
			'address_form_field_error_address': 'Please enter a valid Address',
			'address_form_field_error_zip_code': 'Please enter a valid Zip code',
			'address_form_field_error_postal_code': 'Please enter a valid Postal code',
			'address_form_field_error_entrance': 'Please enter a valid Entrance',
			'address_form_field_error_apartment_number': 'Please enter a valid Apartment Number',
			'address_form_field_error_building_code': 'Please enter a valid Building Code',
			'address_form_field_error_state': 'Please enter a valid State',
			'address_form_field_error_comments': 'Please enter a comment',
			'address_form_field_error_apartment_suite_unit': 'Please enter a valid Apartment/Suite/Unit',
			'address_form_field_error_providence': 'Please enter a valid providence',
			'address_form_field_error_more_details': 'Please enter more details',
			'address_form_field_error_floor': 'Please enter a valid floor',
                
			'address_drop_pin_message_1': 'Location pinned successfully. Edit if needed.',
			'address_drop_pin_message_cancel': 'Cancel pinned address',

			'autocomplete_city_not_found_non_droppin': 'Sorry, Your city was not found. Please select an address from the list or contact support for assistance.',
			'autocomplete_address_not_found_non_droppin': 'Sorry, Your address was not found. Please select an address from the list or contact support for assistance.',
			'autocomplete_zip_code_not_found_non_droppin': 'Sorry, Your zip code was not found. Please select an address from the list or contact support for assistance.',

			'confirm_full_credit_card_charge_title': 'Confirm Full Credit Card Charge',
			'confirm_full_credit_card_charge_content': 'EBT payment amount is set to $0. Are you sure you want to charge the full amount to your credit card?',
			'confirm_full_credit_card_charge_content_btn_confirm': 'Yes, Charge Credit Card',
			'confirm_full_credit_card_charge_content_btn_back': 'Go Back',

			'on_day': 'On',
			'wish_to_change_delivery':'Wish to change the delivery time?',
			'wish_to_change_pickup':'Wish to change the pickup time?',
		
			'checkout_finish_last_update_time_delivery': 'You can update your order delivery time until',
			'checkout_finish_last_update_time_pickup': 'You can update your order pickup time until',
			'forgot_sth_want_to_change_delivery': 'Want to change the delivery time?',
			'forgot_sth_want_to_change_pickup': 'Want to change the pickup date?',

			'update_order_popup_title_def': 'You can update your order items or the {delivery method} time',
			'update_order_popup_order_item_def': 'Update Order Items',
			'update_order_popup_delivery_time_def': 'Update Delivery Time',
			'update_order_popup_pickup_time_def': 'Update Pickup Time',
			'update_order_popup_cancel_def': 'Want to cancel your order? Click here to cancel it {cancel order}',
			'update_order_popup_lastest_time_def': 'You can update your order {delivery method} time until {time calculate}',
			'update_order_popup_err_cant_update_time': 'Updating your {delivery method} time isn\'t available',

			'irre_sales_collect_one': 'This {saleType} will not be valid for this updated time and will be charged at its regular price',
			'irre_sales_collect_many': 'These {count} {saleType} will not be valid for this updated time and will be charged at their regular price',
			'irre_sales_checkout_one': 'By updating this order, the following {saleType} will no longer be valid, and the item will be charged at its regular price',
			'irre_sales_checkout_many': 'By updating this order, the following {count} {saleType} will no longer be valid, and the items will be charged at their regular price',
			'irre_sales_oos_one': 'This product is currently unavailable and will be removed from your cart',
			'irre_sales_oos_many': 'These products are currently unavailable and will be removed from your cart',
			'irre_sales_btn_continue': 'ok, update',
			'irre_sales_btn_change_slot': 'change {delivery method} date',
			'irre_sales_btn_cancel_update': 'cancel update',
			'thank you for your order!': 'Thank you for your order!',
			'pickup order': 'Pickup order',
			'delivery order': 'Delivery order',
			'delivery_disclaimer_default_text':'Delivery windows can only be selected during checkout',
			'api_error_message': 'An error occurred{error_message} (error {error_code}). please try again in a few minutes, if the problem recurs contact customer service',

			'default_first_time_popup_title': 'Welcome!',
			'default_first_time_popup_sub_title': 'How would you like to receive your order?',
			'default_first_time_popup_sub_title_pickup': 'Choose a nearby store for convenient pickup!',
			'default_first_time_popup_sub_title_delivery': 'Enter your address to see delivery options available near you',
			'default_returning_popup_title': 'Change Area',
			'default_returning_popup_sub_title': 'How would you like to receive your order?',
			'default_returning_popup_sub_title_pickup': 'Select new pickup location',
			'default_returning_popup_sub_title_delivery': 'Find new delivery options',
			'default_returning_popup_sub_title_zip_code': '',

			'default_one_store_popup_title': 'Great news!',
			'default_one_store_popup_sub_title': 'We can deliver to your area!',
			'default_one_store_popup_continue_button_text': 'Continue Shopping',

			'default_multiple_store_popup_title': 'You\’re in luck!',
			'default_multiple_store_popup_sub_title': 'We have multiple stores serving your area',

			'default_no_store_popup_title': 'We\'re sorry',
			'default_no_store_popup_sub_title': 'We currently don\’t deliver to your area. Leave your email, and we \’ll let you know when we expand.',

			'delivery_input_zip_code_placeholder': 'Postal code (ex: 75011)',
			'back_btn_text': 'Back',
			'delivery_input_address_placeholder': 'Please enter a delivery address'
		};
	});
})(angular);
