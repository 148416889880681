angular.module('mobilezuz').controller('PaymentIFrameDialogCtrl', [
    '$scope', '$rootScope', '$timeout', '$filter', '$sce', 'Config', 'Util', 'Loading', 'mDesign', 'Retailer', 'SP_PAYMENTS', 'options',
    function ($scope, $rootScope, $timeout, $filter, $sce, Config, Util, Loading, mDesign, Retailer, SP_PAYMENTS, options) {
        var paymentIFrameCtrl = this,
            _iFrameId = 'payment_iframe',
            _iFrameInitiated = false,
            _listeners = [],
            _iframeTimeOut,
            _loadingTimeOut,
            _iframeLoadTimeOut,
            _translate = $filter('translate');

        paymentIFrameCtrl.iFrameUrl = $sce.trustAsResourceUrl(options.url);
        paymentIFrameCtrl.paymentMethodId = options.paymentMethodId;
        paymentIFrameCtrl.isAddCard = options.isAddCard;
        paymentIFrameCtrl.iFrameLoaded = false;
        paymentIFrameCtrl.cancel = mDesign.cancel;
        paymentIFrameCtrl.isHideCancelIconBtn = options.isHideCancelIconBtn;
        paymentIFrameCtrl.bindIFrameLoad = bindIFrameLoad;
        
        paymentIFrameCtrl.creditCardDisplay = Config.retailer.settings.creditCardDisplay ? JSON.parse(Config.retailer.settings.creditCardDisplay) : {}
        if (options && options.isLoyalty && Config.retailer.loyaltyClubDriver) {
            paymentIFrameCtrl.loyaltyClubDriver = Config.retailer.loyaltyClubDriver;
        }

        _initGiftCardDisplay()

        /**
         * Start loading image for iFrame and bind on load event to iFrame
         */
        function bindIFrameLoad() {
            if (_iFrameInitiated) {
                return;
            }

            _iframeTimeOut = $timeout(function() {
                if (_iFrameInitiated) {
                    return;
                }

                var iFrameElement = document.getElementById(_iFrameId);
                if (!iFrameElement) {
                    bindIFrameLoad();
                    return;
                }

                _iFrameInitiated = true;
                Loading.counter(null, 1);
                angular.element(iFrameElement).bind('load', _onIFrameLoad);
                _loadingTimeOut = $timeout(function() {
                    _onIFrameLoad({ target: iFrameElement }, true);
                }, 60000);
                _listeners.push($rootScope.$on('init_cart_iframe_redirect.finished', _setIframeLoaded));
                _listeners.push($rootScope.$on('init_cart_iframe_redirect.loaded', _setIframeLoaded));
                _onIFrameLoad({ target: iFrameElement });
            }, 100);
        }

        /**
         * Stop loading image for iFrame and unbind on load event
         * @private
         *
         * @param {Event} event
         * @param {boolean} [isTimeout]
         */
        function _onIFrameLoad(event, isTimeout) {
            Retailer.getRetailerMainDomain()
                .then(function(appDomain) {
                    if (isTimeout) {
                        throw 'Loading timeout';
                    }

                    var toTryAccessIFrame = event.target.contentWindow.location.origin;
                    if (!toTryAccessIFrame || toTryAccessIFrame.indexOf('http') !== 0) {
                        return;
                    }

                    if (appDomain === '/') {
                        appDomain = window.location.origin;
                    }
                    if (!toTryAccessIFrame.startsWith(appDomain)) {
                        throw 'Set as loaded';
                    }
                })
                .catch(function () {
                    _setIframeLoaded();
                });
        }

        function _setIframeLoaded() {
            if (paymentIFrameCtrl.iFrameLoaded) {
                return;
            }

            if (_loadingTimeOut) {
                $timeout.cancel(_loadingTimeOut);
            }

            _iframeLoadTimeOut = $timeout(function() {
                paymentIFrameCtrl.iFrameLoaded = true;
                Loading.counter(null, -1);

                var iFrameElement = document.getElementById(_iFrameId);
                if (!iFrameElement) {
                    return;
                }

                if (iFrameElement.contentDocument) {
                    var preTags = iFrameElement.contentDocument.querySelectorAll('pre');
                    preTags.forEach(function (preTag) {
                        if (preTag.textContent && preTag.textContent.includes('status')) {
                            var data = JSON.parse(preTag.textContent);
                            if (data.status === 500 && paymentIFrameCtrl.paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT) {
                                mDesign.hide();
                                Util.showPaymentErrorDialog(data);
                                return;
                            }
                        }
                    });
                }

                angular.element(iFrameElement).unbind('load', _onIFrameLoad);
                iFrameElement === document.activeElement && iFrameElement.blur();
                angular.element(iFrameElement).removeClass('hidden');
            }, 0);
        }

        function _initGiftCardDisplay() {
            if(paymentIFrameCtrl.paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD) {
                paymentIFrameCtrl.giftCardDisplay = Config.retailer.settings.giftCardDisplay ? JSON.parse(Config.retailer.settings.giftCardDisplay) : {};
                if(angular.isObject(paymentIFrameCtrl.giftCardDisplay) && paymentIFrameCtrl.giftCardDisplay[Config.language.culture]) {
                    var externalCardTitle = paymentIFrameCtrl.giftCardDisplay[Config.language.culture].externalCardTitle || _translate('{Gift card name}');
                    externalCardTitle = externalCardTitle.replace(/\{Gift card name\}/g, Config.retailer.settings.giftCardNamesSettings[paymentIFrameCtrl.paymentMethodId][$rootScope.config.language.id]);
                    paymentIFrameCtrl.giftCardDisplay[Config.language.culture].externalCardTitle = externalCardTitle;
                }
            }
        }

        _listeners.push(function() {
            // cancel loading when dialog closes
            if (_iFrameInitiated && !paymentIFrameCtrl.iFrameLoaded) {
                Loading.counter(null, -1);
            }

            // cancel timeouts
            _iframeTimeOut && $timeout.cancel(_iframeTimeOut);
            _loadingTimeOut && $timeout.cancel(_loadingTimeOut);
            _iframeLoadTimeOut && $timeout.cancel(_iframeLoadTimeOut);
        });

        Util.destroyListeners($scope, _listeners);
    }]);
