(function (angular) {
    var PRODUCT_SUGGESTIONS_CHUNK_SIZE = 10,
        SELECT_PRODUCTS_FILTERS = {
            must: {
                exists: ['family.id', 'family.categoriesPaths.id', 'branch.regularPrice'],
                term: {
                    'branch.isActive': true,
                    'branch.isVisible': true,
                    isSuggested: true
                }
            },
            mustNot: {
                term: {
                    'branch.regularPrice': 0
                }
            }
        };

    angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
        $stateProvider.state('app.cart', {
            url: '/cart',
            data: {
                bodyClass: 'cart',
                metaTags: {
                    title: 'Cart '
                }
            },
            views: {
                '@': {
                    templateUrl: 'views/cart.html',
                    controller: 'CartCtrl as cartCtrl'
                }
            }
        });
    }]).controller('CartCtrl', [
        '$scope', '$rootScope', '$q', '$filter', '$sce', 'Api', 'Cart', 'User', 'ShoppingList', '$state', 'Util', 'Config',
        'Retailer', 'ShippingTermsPopup', 'Products', 'mDesign', 'CART_LINE_TYPES', 'Orders',
        'ORDER_STATUS_STAGES', '$timeout', 'SpDialogUrlManager', 'SpecialDetailsDialog', 'DataLayer', 'SP_SERVICES', 'PaymentsService', 'SP_PAYMENTS',
        'Loyalty', 'LOYALTY_CLUB_DRIVERS', 'Util', 'UpdateOrderV2Service',
        function ($scope, $rootScope, $q, $filter, $sce, Api, Cart, User, ShoppingList, $state, util, Config,
                  Retailer, ShippingTermsPopup, Products, mDesign, CART_LINE_TYPES, Orders,
                  ORDER_STATUS_STAGES, $timeout, SpDialogUrlManager, SpecialDetailsDialog, DataLayer, SP_SERVICES, PaymentsService, SP_PAYMENTS,
                  Loyalty, LOYALTY_CLUB_DRIVERS, Util, UpdateOrderV2Service) {
            var _listeners = [],
                _editOrderTimeOut,
                cartCtrl = this,
                _productSuggestionsTotal,
                _currentProductSuggestionsRequestPromise,
                _mainContentElement,
                _titleAndDeliveryHeight,
                _productsSuggestionsWrapper,
                _productsSuggestionsDialog,
                _fixedPosterTitle,
                _productsSuggestionsScroller,
                _translate = $filter('translate'),
                _roundCurrencyFilter = $filter('roundCurrency');
            cartCtrl.coupons = {};
            cartCtrl.outOfStockLines = [];
            cartCtrl.order = 'sort';
            cartCtrl.ordersService = Orders;
            cartCtrl.productSuggestions = [];

            cartCtrl.SpecialDetailsDialog = SpecialDetailsDialog;
            cartCtrl.sortedCartByCategories = [];
            cartCtrl.sortedListByTree = [];
            cartCtrl.moneyToPoint = 0;
            
            cartCtrl.clearCart = clearCart;
            cartCtrl.addCouponToCart = addCouponToCart;
            cartCtrl.editOrderCancel = editOrderCancel;
            cartCtrl.goToCheckout = goToCheckout;
            cartCtrl.bindMainContentScroll = bindMainContentScroll;
            cartCtrl.toggleProductsSuggestion = toggleProductsSuggestion;
            cartCtrl.toggleSpecialReminders = toggleSpecialReminders;
            cartCtrl.openDeliveryFeePopup = openDeliveryFeePopup;
            cartCtrl.isEmptyObjectWithDelivery = isEmptyObjectWithDelivery;
            cartCtrl.getRecentLines = getRecentLines;
            cartCtrl.openConfirmCVVPopup = openConfirmCVVPopup;
            cartCtrl.verifyExistedPoint = verifyExistedPoint;
            cartCtrl.checkoutV2 = checkoutV2;
            cartCtrl.editTimeSlot = editTimeSlot;
            cartCtrl.isEnableUpdateTimeSlot = isEnableUpdateTimeSlot;
            cartCtrl.isHideCartPage = false;
            cartCtrl.isAccumulatedPointsDriver = false;

            getRecentLines();
            _initCartList();

            var userLoginData = User.getUserLoginData();

            $scope.trustSrc = function(src) {
                return $sce.trustAsResourceUrl(src);
            }

            if (userLoginData) {
                User.getUserSettings().then(function (resp) {
                    cartCtrl.userData = resp;
                });
            }

            Retailer.getRetailerSettings().then(function (response) {
                cartCtrl.retailer = response;
                cartCtrl.sortByCategories = response.settings.sortCartByCategories === 'true';
                cartCtrl.cvvUpdateConfirm = response.settings.cvvUpdateConfirm === 'true';
            });

            function getRecentLines() {
                Cart.init();
            }

            function initAccumulatedPointsDriver() {
                if(Config.retailer.loyaltyClubDrivers && Array.isArray(Config.retailer.loyaltyClubDrivers) && Config.retailer.loyaltyClubDrivers.length) {
                    var pointsAccumulationDriver = null;
                    for(var i = 0; i < Config.retailer.loyaltyClubDrivers.length && !pointsAccumulationDriver; i++) {
                        if (Config.retailer.loyaltyClubDrivers[i] && Config.retailer.loyaltyClubDrivers[i].loyaltyClubDriverId === LOYALTY_CLUB_DRIVERS.ACCUMULATED_POINTS) {
                            pointsAccumulationDriver = Config.retailer.loyaltyClubDrivers[i];
                        }
                    }

                    if (pointsAccumulationDriver && pointsAccumulationDriver.clientConfig && pointsAccumulationDriver.clientConfig.loyaltyClubs) {
                        var currentLoyaltyClubId = pointsAccumulationDriver.loyaltyClubIds && pointsAccumulationDriver.loyaltyClubIds[0];
                        var pointsAccumulationDriverSettings = currentLoyaltyClubId && pointsAccumulationDriver.clientConfig.loyaltyClubs[currentLoyaltyClubId];
                        if (pointsAccumulationDriverSettings) {
                            cartCtrl.isAccumulatedPointsDriver = pointsAccumulationDriverSettings.loyaltyPages.accumulatedPointElementInCartComponent.isActive;
                            cartCtrl.currentRootCulture = $rootScope.config.language.culture;
                            cartCtrl.accumulatedPointsUnit = pointsAccumulationDriverSettings.loyaltyPages.visibility.tex && pointsAccumulationDriverSettings.loyaltyPages.visibility.tex[cartCtrl.currentRootCulture] || '';
                            cartCtrl.clubPointIcon = pointsAccumulationDriverSettings.loyaltyPages.visibility.clubPointIcon;
                            cartCtrl.moneyToPointRatio = (pointsAccumulationDriverSettings.moneyToPointRatio || 1);
                            cartCtrl.componentPointsText = pointsAccumulationDriverSettings.loyaltyPages.accumulatedPointElementInCartComponent.textNames && pointsAccumulationDriverSettings.loyaltyPages.accumulatedPointElementInCartComponent.textNames[cartCtrl.currentRootCulture] ||  _translate('points');
                            cartCtrl.accumulatedPointsTextColor = pointsAccumulationDriverSettings.loyaltyPages.accumulatedPointElementInCartComponent.textColor;
                            cartCtrl.accumulatedPointsBgColor = pointsAccumulationDriverSettings.loyaltyPages.accumulatedPointElementInCartComponent.backgroundColor;
                        }
                    }
                }
            }
            function verifyExistedPoint() {
                return !!cartCtrl.moneyToPoint && cartCtrl.loyaltyClubClientConfig && cartCtrl.loyaltyClubClientConfig.pointsToCashback && cartCtrl.loyaltyClubClientConfig.loyaltyPages.accumulatedPointElementInCartComponent.isActive
            }

            function clearCart() {
                mDesign.confirm({
                    content: '<h2><strong>' + _translate('clear cart?').replace('{cart}', Config.retailer.settings.setCartNameToBasket ? Config.retailer.settings.setCartNameToBasket.charAt(0).toUpperCase() + Config.retailer.settings.setCartNameToBasket.substring(1) : 'Cart') + '</strong></h2> <br> ' + _translate('are you sure you want to remove all products from your shopping cart?').replace('{cart}', Config.retailer.settings.setCartNameToBasket ? Config.retailer.settings.setCartNameToBasket : 'cart'),
                    ok:  _translate('yes clear cart').replace('{cart}', Config.retailer.settings.setCartNameToBasket ? Config.retailer.settings.setCartNameToBasket : 'cart'),
                    cancel: 'cancel'
                }, function (isTrue) {
                    if (isTrue) {
                        Cart.clear();
                        _initCartList();
                    }
                });
            }

            function isEmptyObjectWithDelivery (data) {
                if (data) {
                    var keys = Object.keys(data);
                    if (keys.length === 1) {
                        return data[keys[0]].type === SP_SERVICES.CART_LINE_TYPES.DELIVERY;
                    }
                    return keys.length === 0;
                }
                return false;
            }

            function openDeliveryFeePopup() {
                mDesign.dialog({
                    controller: 'DeliveryFeeInfoDialog',
                    templateUrl: 'views/templates/delivery-fee-info-dialog.html',
                    styleClass: 'delivery-fee-info-dialog',
                    bypass: true,
                    locals: {
                        text: $rootScope.config.retailer.settings.legalTermsDeliveryText || $filter('translate')('delivery-fee-explain-default'),
                    }
                });
            }

            function addCouponToCart(couponCode, source) {
                Cart.addCoupon(couponCode, source).then(function() {
                    cartCtrl.couponCode = "";
                });
            }

            function editOrderCancel($event) {
                $event && $event.preventDefault();

                if (!Orders.orderInEdit) {
                    return;
                }

                mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: false,
                    templateUrl: 'views/templates/cancel-edit-order-dialog.html',
                    controller: ['$scope', function ($scope) {
                        $scope.cancel = cancel;
                        $scope.editOrderCancel = editOrderCancel;

                        function editOrderCancel() {
                            mDesign.hide();

                            $rootScope.cancelOrder = true;
                            Orders.cancelEdit().then(function () {
                                Cart.clear();
                                _editOrderTimeOut = $timeout(function () {
                                    $rootScope.cancelOrder = false;
                                    $state.go('app.home');
                                }, 2000);
                            });
                        }

                        function cancel() {
                            mDesign.hide();
                        }
                    }]
                });
            }

            /**
             * @param {Event} $event 
             * @param {boolean} lastMinuteRecommendationsChecked 
             * @return {Promise<void>}
             */
            function checkoutV2($event, lastMinuteRecommendationsChecked){
                if(!Util.checkIsPremiumEditOrderToggled() || !Orders.orderInEdit){
                    return goToCheckout($event, lastMinuteRecommendationsChecked)
                }

                var selectedTime = {
                    typeId: Orders.orderInEdit.deliveryTimeTypeId,
                    newFrom: Orders.orderInEdit.shippingTimeFrom,
                    newTo: Orders.orderInEdit.shippingTimeTo,
                    daysRange: null,
                }

                var curOrder = {
                  id: Orders.orderInEdit.id,
                  timePlaced: Orders.orderInEdit.timePlaced,
                };

                return Cart.checkIrrelevantSales(selectedTime, curOrder).then(function (
                  irrelevantSales
                ) {
                  if (!irrelevantSales) {
                    return goToCheckout($event, lastMinuteRecommendationsChecked)
                  }

                  Cart.setIrreSalesData(irrelevantSales);

                  return Util.openEditOrderDialog(
                    "IRRE_SALES",
                    false,
                    Orders.orderInEdit.id
                  );
                });
            }

            function goToCheckout($event, lastMinuteRecommendationsChecked) {
                if ($event){
                    $event && $event.preventDefault();
                }

                if (!Cart.serverCartId || !Cart.total.lines || cartCtrl.disableUpdateOrder) {
                    return;
                }

                if (!lastMinuteRecommendationsChecked) {
                    userLoginData = User.getUserLoginData();
                    if (!userLoginData) {
                    		return util.goToLoginDialog(null, null, "app.cart", JSON.stringify(angular.extend({},  {
                    			loginDialogRetState: undefined,
                    			loginDialogRetStateParams: undefined
                    		})));
                    } else if (Config.retailer.settings.isExternalLoyaltyClubRegistrationEnabled === 'true' && !User.settings.foreignId) {
                        return util.goToLoyaltyIFrameDialog(Config.retailer.settings.externalLoyaltyClubRegistrationUrl, User.settings);
                    } else {
                        return Api.request({
                            method: 'GET',
                            url: '/v2/retailers/:rid/branches/:bid/last-minute-recommendations'
                        }).then(function (lastMinuteRecommendations) {
                            var showLastMinuteRecommendations =
                                lastMinuteRecommendations.settings && lastMinuteRecommendations.settings.config &&
                                lastMinuteRecommendations.settings.config.areLastMinuteRecommendationsEnabled === 'true' &&
                                Object.values(lastMinuteRecommendations.data).some(function(section) {
                                    return section.length > 0;
                                });
                            if (showLastMinuteRecommendations) {
                                SpDialogUrlManager.backClose().then(function() {
                                    $rootScope.LastMinuteRecommendationsDialog.show(lastMinuteRecommendations, goToCheckout);
                                });
                            } else {
                                goToCheckout($event, true);
                            }
                        })
                    }

                }        

                var isLimitPassed = Cart.checkHeavyLimitBeforeAddLines([]);
                if (isLimitPassed === false) {
                    cartCtrl.disableUpdateOrder = true;

                    return Cart.checkUserCreditLimited(false, '').then(function(isLimited) {
                        if(isLimited) {
                            return $q.reject('userCreditLimited');
                        }
                        return $q.resolve();
                    }).then(function() {
                        if (cartCtrl.outOfStockLines && cartCtrl.outOfStockLines.length) {
                            return _outOfStockDialog().then(function(result) {
                                return !result || !result.isConfirmed ? $q.reject('Out of stock not confirmed') : $q.resolve();
                            });
                        }
                        return $q.resolve();
                    }).then(function() {
                        return Retailer.getRetailerSettings();
                    }).then(function(retailerData) {
                        return ShippingTermsPopup.showPopup().then(function(isProceed) {
                            if(!isProceed) {
                                return $q.reject('shippingTermsDeclined');
                            }

                            return retailerData;
                        });
                    })
                    .then(function (retailerData) {
                        if (!cartCtrl.userData) {
                            return User.getUserSettings().then(function (resp) {
                                cartCtrl.userData = resp;
                                return retailerData;
                            });
                        } else {
                            return retailerData;
                        }
                    })
                    .then(function (retailerData) {
                        $rootScope.$emit('firstCheckout');

                        var deliveryMinimumCostByArea = _getAreaById(Config.branchAreaId);
                        deliveryMinimumCostByArea = deliveryMinimumCostByArea && deliveryMinimumCostByArea.deliveryMinimumCost;
                        var minimumOrderPrice = (cartCtrl.userData && (!!cartCtrl.userData.personalMinOrderLimit || cartCtrl.userData.personalMinOrderLimit === 0)) ?
                            cartCtrl.userData.personalMinOrderLimit : (Number(deliveryMinimumCostByArea) || Number(retailerData.settings.minimumOrderPrice));

                        return util.validateMinimumCost(minimumOrderPrice, retailerData.notIncludeSpecials,
                            deliveryMinimumCostByArea && Config.branchAreaName).then(function() {
                            return retailerData;
                        });
                    }).then(function(retailerData) {
                        if (!Orders.orderInEdit) {
                            return $state.go('app.cart.checkout');
                        }

                        return Orders.getOrderDetails(Orders.orderInEdit.id, {
                            fireAndForgot: true
                        }).then(function(order){
                          var irrelevantSalesLines = getIrrelevantSales();
                          function getIrrelevantSales() {
                            var irrelevantSalesLines = [];
                            if (order.shippingTimeTo) {
                              var deliveryDay = new Date(order.shippingTimeTo.split('T')[0]);
                              angular.forEach(Cart.lines, function(line) {

                                if(!Util.isSellOnDate(line,new Date(deliveryDay))){
                                  irrelevantSalesLines.push(line);
                                }
                              });
                            }
                            return irrelevantSalesLines;
                          }
                          if(irrelevantSalesLines.length > 0) {
                            return mDesign.dialog({
                              clickOutsideToClose: false,
                              controller: ['$scope', function ($scope) {
                                $scope.irrelevantSellDates = irrelevantSalesLines;
                                $scope.potentialCartTotal = order.totalAmount;
                                $scope.close = mDesign.hide;
                                $scope.listTemplateUrl = [
                                  "views/templates/irrelevant-sales/sell-date-dialog.html",
                                ];
                                $scope.displayChoosenDateButton = false;
                                $scope.irrelevantSellDates.forEach(function (line){
                                  var finalPrice = line.finalPriceForView || 0
                                  $scope.potentialCartTotal -= Number(finalPrice || 0);
                                });
                              }],
                              templateUrl: 'views/templates/irrelevant-sales/index.html'
                            }).then(function(pass){
                              return $q.resolve({order: order, pass: pass == null, irrelevantSalesLines: irrelevantSalesLines})
                            })
                          }
                          return $q.resolve({order: order, pass: true, irrelevantSalesLines: irrelevantSalesLines})
                        }).then(function(rs){
                          if(rs.pass){
                            if (rs.irrelevantSalesLines.length === 0) {
                              return rs.order;
                            }
                            return Cart.removeLines(rs.irrelevantSalesLines, undefined, true).then(function(){
                              return rs.order;
                            })
                          }
                          return;
                        }).then(function(order) {
                            if (!order) {
                                return;
                            }

                            if (!util.isOrderEditable(order) || order.isCustomerEditBlocked) {
                                return _editOrderDialog(order);
                            }

                            var remainingCharge = _roundCurrencyFilter(Cart.total.finalPriceWithTax +
                                Cart.total.serviceFee.finalPriceWithTax + Cart.total.deliveryCost.finalPriceWithTax) -
                                order.initialCheckoutCharge;
                            var paymentOnUpdateOrderMinSum = !!retailerData.settings.paymentOnUpdateOrderMinSum && Number(retailerData.settings.paymentOnUpdateOrderMinSum);
                            if (retailerData.settings.paymentOnUpdateOrder === 'true' && remainingCharge > (paymentOnUpdateOrderMinSum || 0)) {
                                return _proceedToAdditionalPaymentDialog(remainingCharge);
                            }

                            //var isEBTEligibleAddition = Cart.checkEBTEligibleCartChange(order.lines);
                            if(order.paymentData && order.paymentData.ebtPayment) {
                                return $state.go('app.cart.order-update.ebt-payment');
                            }
                            var  shouldUse3DSOnUpdate =  Config.retailer.settings.creditCard3DSModes && Config.retailer.settings.creditCard3DSModes.map(function(value) {return +value});
                            // return _checkoutUpdate();
                            if(
                              cartCtrl.cvvUpdateConfirm &&
                              !shouldUse3DSOnUpdate &&
                              order.paymentData &&
                              order.paymentData.mainPayment &&
                              order.paymentData.mainPayment.paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD
                            ){

                              openConfirmCVVPopup()
                              .then(function(){
                                var summaryData = cartCtrl.summaryData;
                              
                                if(summaryData){
                                  if(summaryData.proceedToCheckout && !summaryData.popupAgain){
                                    return _checkoutUpdate(summaryData);
                                  }
                                  else if(!summaryData.proceedToCheckout && summaryData.summaryData.popupAgain){
                                    openConfirmCVVPopup()
                                  }
                                }

                              })
                            }
                            else if(shouldUse3DSOnUpdate &&
                                cartCtrl.cvvUpdateConfirm &&
                                order.paymentData &&
                                order.paymentData.mainPayment &&
                                order.paymentData.mainPayment.paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD){
                              openConfirmCVVPopup()
                              .then(function(){
                                var summaryData = cartCtrl.summaryData;
                              
                                if(summaryData){
                                  if(summaryData.proceedToCheckout && !summaryData.popupAgain){
                                    PaymentsService.payWithCreditCard({
                                      creditCardId: (summaryData.creditCard || {}).id,
                                      cvv: summaryData.cvv,
                                      action: 'checkout',
                                    }).then(function(paymentData) {
                                      summaryData.cvvConfirmUpdate = true;
                                      if(!paymentData){
                                        return
                                      }
                                      var payment = {
                                        cvv: summaryData.cvv,
                                        methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD,
                                        typeId: SP_PAYMENTS.PAYMENT_TYPES.CREDIT,
                                        token: paymentData.paymentToken,
                                        authNum: paymentData.authNum,
                                        preAuthWithStaticAmount: paymentData.preAuthWithStaticAmount,
                                        preAuthStaticAmount: paymentData.preAuthStaticAmount,
                                        creditCardId: summaryData.creditCard && summaryData.creditCard.id,
                                        preAuthAmount: !cartCtrl.isEmptyObjectWithDelivery($rootScope.cart.lines) ?
                                          ($rootScope.cart.total.finalPriceForViewWithDeliveryFee || $rootScope.cart.total.finalPriceForView) :
                                          ($rootScope.cart.total.finalPriceForView)
                                        }
                                        cartCtrl.payment = payment;
                                      return _checkoutUpdate(true);
                                    }).catch(function (err) {
                                      if (err.statusCode === 409 && err.response &&
                                        err.response.error === 'Already checked out' && err.response.orderId) {
                                        return { actionResponse: { id: err.response.orderId } };
                                      } else {
                                        throw err;
                                      }
                                    });
                                  }
                                  else if(!summaryData.proceedToCheckout && summaryData.summaryData.popupAgain){
                                    openConfirmCVVPopup()
                                  }
                                }

                              })

                            }else{
                              return _checkoutUpdate();
                            }
                        });
                    }).catch(function(err) {
                        if(err !== 'userCreditLimited'){
                            return $q.reject(err);
                        }
                    }).finally(function () {
                        cartCtrl.disableUpdateOrder = false;
                    });
                }
            }

            function openConfirmCVVPopup() {
              return mDesign.dialog({
                focusOnOpen: false,
                clickOutsideToClose: false,
                templateUrl: 'views/update-cvv-confirm-dialog.html',
                controller: [function () {
                  var cvvConfirmCtrl = this;
                    User.getUserSettings(true).then(function (userData) {
                        if (userData.creditCards && cvvConfirmCtrl.creditCards.length !== userData.creditCards.length) {
                            cvvConfirmCtrl.creditCards = userData.creditCards || [];
                            cvvConfirmCtrl.summaryData.creditCard = _getOrderCreditCard(userData.creditCards, Orders.orderInEdit) || cvvConfirmCtrl.creditCards[0];
                        }
                    });
                  cvvConfirmCtrl.creditCards = cartCtrl.userData.creditCards || [];
                  cvvConfirmCtrl.summaryData = {
                    paymentMethod: null,
                    storeCredit: '',
                    creditCard: {},
                    paymentsNumber: 1
                  }
                  cvvConfirmCtrl.showAddCardDialog = function () {
                    mDesign.hide();
                      cartCtrl.compiledUserAddress = util.compiledUserAddress();
                      cvvConfirmCtrl.summaryData.proceedToCheckout = false;
                      cvvConfirmCtrl.summaryData.popupAgain = true;
                      if (Config.retailer.settings.useDeliveryAddressAsBilling && cartCtrl.compiledUserAddress) {
                          return mDesign.dialog({
                              templateUrl: 'views/templates/use-delivery-address-as-billing.html',
                              controller: ['$scope', function ($dialogScope) {
                                  var dialogCtrl = this,
                                      userAddress = User.settings.addresses && User.settings.addresses.length && User.settings.addresses[0];
                                  dialogCtrl.sendUserAddress = false;
                                  dialogCtrl.compiledUserAddress = cartCtrl.compiledUserAddress;
                                  dialogCtrl.cancel = cancel;
                                  $dialogScope.continue = function () {
                                      mDesign.hide();
                                      return PaymentsService.addCreditCard({
                                          sendUserAddress: dialogCtrl.sendUserAddress,
                                          address: userAddress.text1 ? userAddress.text1 : userAddress.text2 ? userAddress.text2 : '',
                                          city: userAddress.city ? userAddress.city : '',
                                          country: userAddress.country ? userAddress.country : '',
                                          countryCode: userAddress.countryCode ? userAddress.countryCode : '',
                                      }).then(function() {
                                          setTimeout(function() {
                                              User.getUserSettings(true)
                                                  .then(function (user) {
                                                      goToCheckout(false, false)
                                                  })
                                          }, 1000);
                                      });
                                  };
                                  function cancel() {
                                      mDesign.hide();
                                  }
                              }],
                              controllerAs: 'dialogCtrl',
                              styleClass: 'clear-cart-popup'
                          });
                      } else {
                          return PaymentsService.addCreditCard().then(function () {
                              cartCtrl.openConfirmCVVPopup()
                          });
                      }
                  }
                  var setData = function () {
                    cvvConfirmCtrl.summaryData.creditCard = _getOrderCreditCard(cvvConfirmCtrl.creditCards, Orders.orderInEdit) || cvvConfirmCtrl.creditCards[0];
                    cvvConfirmCtrl.hasCard = true;
                  }
                  cvvConfirmCtrl.closeDialog = function () {
                    mDesign.hide();
                    cvvConfirmCtrl.summaryData.proceedToCheckout = false;
                    cvvConfirmCtrl.summaryData.popupAgain = false;
                    return cvvConfirmCtrl.summmaryData
                  }
                  cvvConfirmCtrl.chooseCreditCard = function (creditCard) {
                    cvvConfirmCtrl.summaryData.proceedToCheckout = true;
                    cvvConfirmCtrl.summaryData.popupAgain = false;
                    cvvConfirmCtrl.summaryData.creditCard = creditCard;
                  }
                  cvvConfirmCtrl.confirmUpdate = function () {
                    cvvConfirmCtrl.summaryData.proceedToCheckout = true;
                    cvvConfirmCtrl.summaryData.popupAgain = false;
                    cartCtrl.summaryData = cvvConfirmCtrl.summaryData;
                    if (!cvvConfirmCtrl.summaryData.cvv || cvvConfirmCtrl.summaryData.cvv.length < 3) {
                      cvvConfirmCtrl.errorState = {
                        cvv: 'CVV Should not be empty and should have at least 3 digits'
                      }
                      return
                    }
                    else {
                      return mDesign.hide();
                    }

                  }
                  setData()
                }],
                controllerAs: 'cvvConfirmCtrl',
                styleClass: 'cvv-confirm-popup',
              })
            }

            function _initClubPoint() {
                var userLoyaltyClubs = [];
                var activeLoyaltyClubs = [];
                if(Config.retailer && Config.retailer.loyaltyClubDriver && Config.retailer.loyaltyClubDriver.clientConfig && Config.retailer.loyaltyClubDriver.clientConfig.loyaltyClubs)
                for(var key in Config.retailer.loyaltyClubDriver.clientConfig.loyaltyClubs){
                    if(Config.retailer.loyaltyClubDriver.clientConfig.loyaltyClubs[key].pointsToCashback)
                        activeLoyaltyClubs.push(+key);
                }
                User.getData().then(function(data) {
                    if(data && data.loyaltyClubs && !!data.loyaltyClubs.length){
                        data.loyaltyClubs.forEach(function(item){
                            if(!!activeLoyaltyClubs.length && activeLoyaltyClubs.includes(item.loyaltyClubId)){
                                userLoyaltyClubs.push(item.loyaltyClubId);
                            }
                        })
                    }
                    if(!!userLoyaltyClubs.length){
                        cartCtrl.loyaltyClubClientConfig = userLoyaltyClubs[0] && Loyalty.getRetailerClubConfig(userLoyaltyClubs[0]);
                        if (cartCtrl.loyaltyClubClientConfig && Object.keys(cartCtrl.loyaltyClubClientConfig).length > 0 && cartCtrl.loyaltyClubClientConfig.loyaltyPages.accumulatedPointElementInCartComponent.isActive && cartCtrl.loyaltyClubClientConfig.moneyToPointRatio > 0) {
                            cartCtrl.moneyToPoint = Math.floor(((Cart.total.finalPriceForViewWithDeliveryFee || Cart.total.finalPriceForView) / cartCtrl.loyaltyClubClientConfig.moneyToPointRatio) * 10) / 10;
                        }
                    }
                });
                if (cartCtrl.isAccumulatedPointsDriver && (Cart.total.finalPriceForViewWithDeliveryFee || Cart.total.finalPriceForView)) {
                    cartCtrl.moneyToPoint = Math.floor(((Cart.total.finalPriceForViewWithDeliveryFee || Cart.total.finalPriceForView) / cartCtrl.moneyToPointRatio) * 10) / 10;
                }
            }

            function _outOfStockDialog() {
                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: false,
                    templateUrl: 'views/templates/out-of-stock-dialog.html',
                    controller: ['$scope', function ($scope) {
                        $scope.lines = cartCtrl.outOfStockLines;
                        $scope.hide = mDesign.hide;
                        $scope.isSentData = false;
                        $scope.productsCount = 0;
                        $scope.couponsCount = 0;

                        angular.forEach($scope.lines, function(line) {
                            if (line.isProductOutOfStock) {
                                ++$scope.productsCount;
                            }
                            if (line.isCouponActive === false) {
                                ++$scope.couponsCount;
                            }
                        });


                        $scope.removeLines = function() {
                            var promises = [];
                            $scope.isSentData = true;
                            angular.forEach(cartCtrl.outOfStockLines, function(line) {
                                if (line.isProductOutOfStock || line.isCouponActive === false) {
                                    promises.push(Cart.removeLine(line, true, true));
                                }
                            });
                            $q.all(promises).then(function() {
                                mDesign.hide({isConfirmed: true});
                            });
                        };
                    }]
                });
            }

            function _checkoutUpdate(showSummary) {
                cartCtrl.columns = null;
                return Api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/branches/:bid/users/:uid/_checkoutUpdate',
                    data: {
                        newCartId: Cart.serverCartId,
                        orderId: Orders.orderInEdit.id,
                        skipActiveProductsValidation: true,
                        payment: cartCtrl.payment,
                        isEnableUpdateTimeSlot: Util.checkIsPremiumEditOrderToggled()
                    }
                }, {
                    hideError: true
                }).then(function () {
                    //Google analytics listener.
                    // $rootScope.$emit('checkout', Orders.orderInEdit.id, angular.copy(Cart.total), angular.copy(Cart.lines));
                    cartCtrl.updatedOrderId = Orders.orderInEdit.id;
                    cartCtrl.updateOrder = true;
                    Orders.orderInEdit = null;
                    if(showSummary) {
                        Orders.getOrderDetails(cartCtrl.updatedOrderId).then(function (order)  {
                            cartCtrl.branchDeliveryTypeId = order.branchDeliveryTypeId
                            _prepareSummaryData(order)
                        })
                    }
                    Cart.clear(true);

                    //== refreshing User's Credit data after order update
                    if ($rootScope.isCreditCustomer) {
                        User.getUserSettings(true);
                    }
                }).catch(function(err) {
                    if (err.statusCode !== 400) {
                        Orders.cancelEdit().then(function () {
                            Cart.clear();
                        }).catch(function() {
                            Orders.orderInEdit = null;
                            Cart.clear(true);
                        });
                    }

                    if ((err.statusCode !== 300 && err.statusCode !== 401) && err.response && err.response.error) {
                        //== when Credit customer is limited we need to display a custom popup with remaining sum
                        if (err.response.code === 'creditCustomerLimited') {
                            return Cart.checkUserCreditLimited(true, '');
                        } else {
                            mDesign.alert('{{\'Update order failed\' | translate}}.<br/>{{\'' + err.response.error.replace(/'/g, '\\\'') + '\' | translate}}');
                            return;
                        }
                    }

                    if (err.apiOptions) {
                        err.apiOptions.hideError = false;
                        $rootScope.$emit('spApi.error', err);
                    }
                });
            }

            function _prepareSummaryData(order) {
                var generatedColumns = [];
                var creditCardColumns = [];
                if (order && order.id) {
                    var serviceFee = 0;
                    if(order.lines && order.lines.length) {
                        var findServiceFee = order.lines.find(function (line) {
                            return line.type === SP_SERVICES.CART_LINE_TYPES.SERVICE_FEE;
                        })
                        serviceFee = findServiceFee && findServiceFee.totalPrice;
                    }
                    getOrderDeliveryTime(order);
                    generatedColumns.push(
                        _columnObj('Items', _countCartLinesUnitsItems(order)),
                        _columnObj('Sub total', getOrderSubtotal(order), true, [], false, true),
                        _columnObj('Delivery fee', +order.deliveryFee.toFixed(2), +order.deliveryFee > 0, [], false, true),
                        _columnObj('Service fee', serviceFee ? serviceFee.toFixed(2) : 0, (serviceFee || 0) > 0, [], false, true),
                        _columnObj('Tax',  +order.totalTax.toFixed(2), +order.totalTax > 0, [], false, true),
                        _columnObj('Total (estimated)',  +order.totalAmount.toFixed(2), +order.totalAmount > 0, ['bold', 'colored'])
                    );

                    if (order.paymentData && order.paymentData.mainPayment && order.paymentData.mainPayment.lastFourDigits) {
                        creditCardColumns.push(_columnObj('Credit Card', '****' + order.paymentData.mainPayment.lastFourDigits));
                    }
                    cartCtrl.columns = [generatedColumns, creditCardColumns];
                }
            }

            function _columnObj(text, value, hasCurrency, classes, hideColon, withoutValue) {
                return {
                    text: text,
                    hasCurrency: hasCurrency,
                    value: value,
                    classes: classes,
                    hideColon: hideColon,
                    withoutValue: withoutValue
                }
            }

            function _countCartLinesUnitsItems(order) {
                return order && order.lines ? order.lines.reduce(function(sum, line) {
                    if (line.type === SP_SERVICES.CART_LINE_TYPES.PRODUCT) {
                        sum += line.actualQuantity;
                    }
                    return sum;
                }, 0) : 0;
            }

            function getOrderSubtotal(order) {
                var subTotal = order.totalAmount - (order.deliveryFee + order.totalTax);
                if(subTotal > 0) {
                    return subTotal.toFixed(2);
                }
                return 0;
            }

            function getOrderDeliveryTime (order) {
                var weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
                var dayName = weekday[new Date(order.shippingTimeFrom).getDay()];
                var date = new Date(order.shippingTimeFrom).getDate();
                var month = new Date(order.shippingTimeFrom).getMonth() + 1;
                var timeFrom = new Date(new Date(order.shippingTimeFrom).getTime() + (new Date(order.shippingTimeFrom).getTimezoneOffset() * 60 * 1000));
                var timeTo = new Date(new Date(order.shippingTimeTo).getTime() + (new Date(order.shippingTimeTo).getTimezoneOffset() * 60 * 1000));
                var formattedTimeFrom = timeFrom.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
                var formattedTimeTo = timeTo.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });

                cartCtrl.formattedDeliveryDate = dayName + ' ' + date + '/' + month;
                cartCtrl.formattedDeliveryTime = formattedTimeFrom + ' - ' + formattedTimeTo;
            }

            function _getOrderCreditCard(userCards, order) {
                var userCard = null;
                if (userCards.length && order.paymentData && order.paymentData.mainPayment && order.paymentData.mainPayment.lastFourDigits) {
                    userCard = userCards.find(function (card) {
                        return card.lastFourDigits === order.paymentData.mainPayment.lastFourDigits;
                    })
                }
                return userCard ? userCard : userCards.length && userCards[0];
            }

            function loadProductsChunk() {
                if (angular.isDefined(_productSuggestionsTotal) && _productSuggestionsTotal <= cartCtrl.productSuggestions.length) {
                    return $q.reject('All suggested products already loaded');
                }

                if (!_currentProductSuggestionsRequestPromise) {
                    _currentProductSuggestionsRequestPromise = Products.prepareSearchParams({
                        from: cartCtrl.productSuggestions.length,
                        size: PRODUCT_SUGGESTIONS_CHUNK_SIZE,
                        filters: angular.copy(SELECT_PRODUCTS_FILTERS)
                    }).then(function(params) {
                        return Api.request({
                            method: 'GET',
                            url: '/v2/retailers/:rid/branches/:bid/products',
                            params: params
                        }, {
                            loadingElement: document.querySelector('.products-suggestions .suggestions-dialog .products-content .loading-progress-wrapper')
                        });
                    }).then(function (resp) {
                        _productSuggestionsTotal = resp.total;
                        Array.prototype.push.apply(cartCtrl.productSuggestions, Cart.getProducts(resp.products));
                    }).finally(function () {
                        _currentProductSuggestionsRequestPromise = null;
                    });
                }

                return _currentProductSuggestionsRequestPromise;
            }

            function toggleProductsSuggestion(value) {
                if (angular.isDefined(value)) {
                    cartCtrl.productsSuggesionsShown = !!value;
                } else {
                    cartCtrl.productsSuggesionsShown = !cartCtrl.productsSuggesionsShown;
                }

                if (!_productsSuggestionsScroller) {
                    _productsSuggestionsScroller = document.querySelector('.products-suggestions .suggestions-dialog .products-content');
                }
                if (cartCtrl.productsSuggesionsShown) {
                    angular.element(document.body).addClass('products-suggestions-shown');

                    if (angular.isUndefined(_productSuggestionsTotal)) {
                        loadProductsChunk();
                    }

                    angular.element(_productsSuggestionsScroller).bind('scroll', _onProductsSuggestionsScroll);
                } else {
                    angular.element(document.body).removeClass('products-suggestions-shown');
                    angular.element(_productsSuggestionsScroller).unbind('scroll', _onProductsSuggestionsScroll);
                }
            }

            function toggleSpecialReminders() {
                cartCtrl.isSpecialRemindersExpanded = !cartCtrl.isSpecialRemindersExpanded;

                if (cartCtrl.isSpecialRemindersExpanded) {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Expand Cart Special Reminders'}});
                }
            }

            function _onProductsSuggestionsScroll() {
                var distance = _productsSuggestionsScroller.scrollHeight - _productsSuggestionsScroller.offsetHeight - _productsSuggestionsScroller.scrollTop;
                if (distance < 20) {
                    loadProductsChunk();
                }
            }

            function bindMainContentScroll() {
                _mainContentElement = document.querySelector('body #main > .content');

                $timeout(function () {
                    _mainContentElement.scrollTop = _mainContentElement.scrollHeight;
                }, 0, false);

                angular.element(_mainContentElement).bind('scroll', _onMainContentElementScroll);
                _listeners.push($rootScope.$on('nextDelivery.toggle', function() {
                    Retailer.getRetailerSettings().then(function() {
                        $timeout(_onMainContentElementScroll, 100);
                    });
                }));
            }

            function _proceedToAdditionalPaymentDialog(remainingCharge) {
                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: false,
                    templateUrl: 'views/templates/additional-payment-dialog.html',
                    controller: ['$scope', function ($scope) {
                        $scope.remainingCharge = remainingCharge;
                        $scope.proceedToPayment = function () {
                            mDesign.hide(true);
                        }
                        $scope.backToCart = function () {
                            mDesign.hide(false);
                        }
                    }]
                }).then(function(proceeed) {
                    if (proceeed) {
                        $state.go('app.cart.order-update');
                    }
                });
            }

            function _onMainContentElementScroll() {
                var suggestionsWrapperOffsetTop = 0;
                if (!_titleAndDeliveryHeight) {
                    var poster = _mainContentElement.querySelector(':scope > .cart-tool-bar'),
                        nextDelivery = _mainContentElement.querySelector(':scope > div > .next-delivery-wrapper');

                    _titleAndDeliveryHeight = 0;

                    if (poster) {
                        _titleAndDeliveryHeight += poster.offsetHeight;
                    }
                    if (nextDelivery) {
                        _titleAndDeliveryHeight += nextDelivery.offsetHeight;
                    }
                }

                if (!_productsSuggestionsWrapper) {
                    _productsSuggestionsWrapper = document.querySelector('.products-suggestions');
                }

                if (_productsSuggestionsWrapper) {
                    suggestionsWrapperOffsetTop = _productsSuggestionsWrapper.offsetTop;

                    if (!_productsSuggestionsDialog) {
                        _productsSuggestionsDialog = angular.element(_productsSuggestionsWrapper.querySelector('.suggestions-dialog'));
                    }
                }

                var scrollLimit = _titleAndDeliveryHeight,
                    fixedPosterTitleHeight = 0;
                if (!_fixedPosterTitle) {
                    _fixedPosterTitle = document.querySelector('body #main > h2.fixed-tool-bar');
                }
                if (_fixedPosterTitle) {
                    fixedPosterTitleHeight = _fixedPosterTitle.offsetHeight;

                    if (_mainContentElement.scrollTop + fixedPosterTitleHeight > scrollLimit) {
                        scrollLimit = _mainContentElement.scrollTop + fixedPosterTitleHeight;
                    }
                }

                if (_productsSuggestionsDialog) {
                    if (_mainContentElement.scrollTop > scrollLimit) {
                        _productsSuggestionsDialog.css({'top': fixedPosterTitleHeight - suggestionsWrapperOffsetTop + 'px'});
                    } else {
                        _productsSuggestionsDialog.css({'top': scrollLimit - _mainContentElement.scrollTop - suggestionsWrapperOffsetTop + 'px'});
                    }
                }
            }

            function _editOrderDialog(order) {
                if (!util.isOrderInStatuses(order, ORDER_STATUS_STAGES.RECEIVED) && Orders.orderInEdit) {
                    var contentHeader = '',
                        contentFooter = '',
                        contentTitle = '';
                    if (util.isOrderInStatuses(order, ORDER_STATUS_STAGES.IN_PROCESS) || order.isCustomerEditBlocked) {
                        contentHeader = 'We have started collecting your original order.';
                        contentTitle = 'Your order cannot be changed at this time';
                    } else if (util.isOrderInStatuses(order, ORDER_STATUS_STAGES.READY)) {
                        contentHeader = 'We have finished collecting your original order.';
                    } else if (util.isOrderInStatuses(order, ORDER_STATUS_STAGES.CANCELLED)) {
                        contentTitle = 'Your order can no longer be changed';
                        contentHeader = 'This is a canceled order.';
                    } else {
                        contentHeader = 'We have finished collecting your original order.';
                    }

                    return mDesign.dialog({
                        focusOnOpen: false,
                        clickOutsideToClose: false,
                        templateUrl: 'views/templates/order-status-dialog.html',
                        controller: ['$scope', function ($scope) {
                            $scope.contentHeader = contentHeader;
                            $scope.contentTitle = contentTitle;
                            $scope.contentFooter = contentFooter;
                            $scope.hide = mDesign.hide;
                        }]
                    }).then(function(clearCart) {
                        return Orders.cancelEdit().then(function() {
                            if (clearCart) {
                                Cart.clear();
                            }
                        });
                    });
                }
            }

            function _initCartList() {
                cartCtrl.caseCartLines = [];
                cartCtrl.regularCartLines = [];
                angular.forEach(Cart.lines, function (line) {
                    if (!!line.deliveryProduct) {
                        return;
                    }
                    line.isProductOutOfStock = util.isProductOutOfStock(line);
                    line.isNeedToShowOutOfStockLabel = util.isNeedToShowOutOfStockLabel(line);
                    line.isCase ? cartCtrl.caseCartLines.push(line) : cartCtrl.regularCartLines.push(line);

                    var isExistLine = cartCtrl.outOfStockLines.find(function(l) {
                        return l.id == line.id;
                    });

                    if ((line.isProductOutOfStock || line.isCouponActive === false) && !isExistLine) {
                        cartCtrl.outOfStockLines.push(line);
                    }
                });
                _removeCssFromLis();
                cartCtrl.specialRemindersLength = Object.keys(Cart.specialReminders && Cart.specialReminders.inCart || {}).length;
                setSortedListByCategory(true);
                _checkForDeliveryServiceOrPickupFee();

                if ((Config.retailer.loyaltyClubDrivers || []).map(function(item) {
                    return item.loyaltyClubDriverId;
                }).indexOf(LOYALTY_CLUB_DRIVERS.ACCUMULATED_POINTS) !== -1) {
                    initAccumulatedPointsDriver();
                }

                _initClubPoint();
            }

            function _checkForDeliveryServiceOrPickupFee() {
                util.checkForDeliveryPickupServiceFee(Cart.lines).then(function(deliveryCheckoutOrServiceFee) {
                    cartCtrl.deliveryCheckoutOrServiceFee = deliveryCheckoutOrServiceFee;
                });
            }

            // todo remove this once sure it should be removed (this had logical issues and was creating bugs)
            // $rootScope.$on('cart.update.complete', function () {
            //     setSortedListByCategory(true);
            // })

            function setSortedListByCategory(mergeCarts) {
                if(cartCtrl.sortByCategories) {
                    cartCtrl.sortedCartByCategories = Cart.sortCartByCategories(cartCtrl.regularCartLines, cartCtrl.sortedCartByCategories, mergeCarts);
                    cartCtrl.sortedListByTree = Cart.sortByTree(cartCtrl.sortedCartByCategories)
                }
            }

            function _sendDataLayerEvent() {
                //== Remove flag when $scope.destroy problem will be solved
                if(cartCtrl.dataLayerEventSent) {
                    return;
                }
                cartCtrl.dataLayerEventSent = true;

                var cartLineProducts = [];
                angular.forEach(Cart.lines, function (line) {
                    if(line && line.product && line.product.id) {
                        line.product.quantity = line.quantity;
                        cartLineProducts.push(line.product);
                    }
                });
                DataLayer.push(DataLayer.EVENTS.CHECKOUT, {products: cartLineProducts, data: {step: 1, option: 'cart summary'}});
            }

            function _getAreaById(areaId) {
                var areas = Config.branch.areas;
                for (var i = 0; i < areas.length; i++) {
                    if (areas[i].id == areaId) {
                        return areas[i];
                    }
                }
            }

            function _removeCssFromLis() {
                $timeout(function() {
                    angular.forEach(Cart.lines, function(line, lineKey) {
                        var lineElement = document.querySelector('#cart_line_' + lineKey);
                        if (lineElement) {
                            var li = lineElement.parentNode.parentNode.parentNode.parentNode;
                            li.style.opacity = null;
                            li.style.width = null;
                            li.style.marginLeft = null;
                            li.style.height = null;
                            li.style.marginBottom = null;
                        }
                    });
                });
            }
            
            function editTimeSlot($event) {
                if (!$event) {
                    $event && $event.preventDefault();
                }

                return  util.openEditOrderDialog("TIMESLOT", false, Orders.orderInEdit.id);
            }


            function isEnableUpdateTimeSlot() {
                return Orders.orderInEdit && util.checkCanUpdateTimeSlot(Orders.orderInEdit);
            }

            //================== SCOPE LISTENER  ==================//

            _listeners.push($rootScope.$on('cart.lines.remove', function (event, data) {
                angular.forEach(data.lines, function (removedLine) {
                    var element = document.querySelector('#cart_line_' + Cart.keyByLine(removedLine));
                    if (element) {
                        var li = removedLine.type === CART_LINE_TYPES.COUPON ? element.parentNode.parentNode : element.parentNode.parentNode.parentNode.parentNode;
                        li.style.opacity = 0;
                        li.style.width = (li.offsetWidth * 0.9) + 'px';
                        li.style.marginLeft = (li.offsetWidth * 0.1 / 2) + 'px';
                        li.style.height = 0;
                        li.style.marginBottom = (li.offsetHeight * 0.1 / 2) + 'px';
                    }
                });
                if (cartCtrl.sortByCategories) {
                    cartCtrl.sortedListByTree = Cart.filterCartLineRemoved(cartCtrl.sortedCartByCategories, data)
                }
                _initCartList();
            }));

            _listeners.push($rootScope.$on('cart.update.complete', _initCartList));
            _listeners.push($rootScope.$on('cart.update.complete', _sendDataLayerEvent));

            _listeners.push($rootScope.$on('$stateChangeStart', function (event, toState, toData, fromState, fromData) {
                if (toState.data && toState.data.bodyClass === 'checkout') return;
                cartCtrl.showCheckoutView = false;
                cartCtrl.isHideCartPage = false
            }));

            _listeners.push($rootScope.$on('cart.change', _initClubPoint));

            _listeners.push($rootScope.$on('cart.hideCartPage', function(){
                cartCtrl.isHideCartPage = true
            }))
            
            _listeners.push($rootScope.$on('update-order-v2-summary', function(event, data){
                if(data.order){
                    cartCtrl.updateOrder = true;
                    cartCtrl.branchDeliveryTypeId = data.order.branchDeliveryTypeId;
                    cartCtrl.updatedOrderId = data.order.id;
                 
                    if(data.showSummary) {
                        _prepareSummaryData(data.order);
                    }
                }
            }))

            util.destroyListeners($scope, $rootScope.$on('editOrder.polling', function (event, data) {
                _editOrderDialog(data);
            }));

            util.destroyListeners($scope, _listeners, function () {
                //console.log('Cart destroyListeners')
                _editOrderTimeOut && $timeout.cancel(_editOrderTimeOut);
            });

            Cart.save();
        }
    ]);
})(angular);
