angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.retailer.information.branch', {
        url: '/:id',
        data: {
            stateAccess: PAGE_ACCESS.ALLOW,
            bodyClass: 'branch',
            metaTags: {
                title: 'Branch'
            }
        },
        resolve: {
            branch: ['$stateParams', 'User', function ($stateParams, User) {
                return User.getRetailerData().then(function (data) {
                    for (var i = 0; i < data.branches.length; i++) {
                        if (data.branches[i].id === Number($stateParams.id)) {
                            return data.branches[i];
                        }
                    }
                });
            }]
        },
        views: {
            '@': {
                templateUrl: 'views/branch.html',
                controller: 'BranchCtrl'
            }
        }
    });

}]).controller('BranchCtrl', ['$scope', '$rootScope', 'branch', 'BranchDistance', 'Util',
    function ($scope, $rootScope, branch, BranchDistance, Util) {
        $scope.branch = branch;
        $scope.openNav = function () {
            //   window.open('geo:0,0?q=' + branch.lat + ',' + branch.lon, '_system')
            //       window.open('geo:0,0?q=1600+Amphitheatre+Parkway%2C+CA','_system');
        };
        $rootScope.title = $scope.branch.name;

        $scope.openDialer = function () {
            window.location.href = 'tel:' + $scope.branch.phone;
        };
        $scope.navigateLink = function () {
            if ($scope.branch.lat && $scope.branch.lon) {
                return Util.isIos() ? 'maps://maps.apple.com/?q=' + $scope.branch.lat + ',' + $scope.branch.lon : 'geo:0,0?q=' + $scope.branch.lat + ',' + $scope.branch.lon;
            } else {
                return Util.isIos() ? 'maps://maps.apple.com/?q=' + $scope.branch.location : 'geo:0,0?q=' + $scope.branch.location;
            }
        };

        BranchDistance.calcBranchesDistance([$scope.branch]);

        // BranchDistance.calcDistance($scope.branch).then(function (route) {
        //     if (route) {
        //         var split = route.legs[0].distance.text.split(' ');
        //         branch.distanceText = split[1];
        //         branch.distanceNum = split[0];
        //         $scope.branch.distance = route.legs[0].distance.text;
        //         $scope.branch.distanceValue = route.legs[0].distance.value;
        //     }
        // }, function (err) {});


    }]);