angular.module('mobilezuz')
    .directive('spPointRedemption', function() {
        return {
            restrict: 'E',
            templateUrl: 'views/templates/sp-point-redemption.html',
            replace: true,
            controller: ['$scope', 'User', 'Config', '$rootScope', 'Api', '$timeout', '$state', 'Util', 'Retailer', 'LOYALTY_PAGE_METHODS', 'LOYALTY_CLUB_DRIVERS', 'Loyalty', 'Coupon',
            function($scope, User, Config, $rootScope, Api, $timeout, $state, Util, Retailer, LOYALTY_PAGE_METHODS, LOYALTY_CLUB_DRIVERS, Loyalty, Coupon) {
                var  _timeoutOpenMenuPages;
                var _listeners = [];

                _listeners.push($scope.$watch(function() {
                    return Config.language.culture;
                }, function(newCulture, oldCulture) {
                    $scope.language = newCulture;
                }));

                _init()

                function _init(){
                  setNumberOfCoupons();
                  setUser();
                  _setRetailerData();
                }

                function setUser() {
                    if (User.getUserLoginData()) {
                        User.getUserSettingsMultiLoyalty().then(function(data) {
                            $scope.user = data;
                            setLoyaltyPageConfig($scope.user);
                            User.setIsTriggerMultiLoyalty(false)
                            setNumberOfCoupons()
                        });
                    } else setLoyaltyPageConfig();
                }
                
                function setLoyaltyPageConfig(userData) {
                    Config.waitForInit().then(function () {
                        var loyaltyClubDriver;
                        if (userData && userData.loyaltyClubs && userData.loyaltyClubs.length > 0) {
                            $scope.loyaltyClub = Util.findUserCashbackLoyaltyClub()

                            if ($scope.loyaltyClub) {
                                Util.getUserCashbackPoints($scope.loyaltyClub.id).then(function (response) {
                                    $scope.userPoints = response.points || 0;
                                });
                            }
                            var userCashbackLoyalty = userData.loyaltyClubs && userData.loyaltyClubs.length && userData.loyaltyClubs.find(function(club) {
                                return Loyalty.isCashbackLoyaltyClub(club.loyaltyClubId);
                            });
                            if(userCashbackLoyalty) {
                                loyaltyClubDriver = Loyalty.getLoyaltyDriver(userCashbackLoyalty.loyaltyClubDriverId)
                                $scope.loyaltyClubDriver = loyaltyClubDriver;
                                if (loyaltyClubDriver && loyaltyClubDriver.clientConfig && loyaltyClubDriver.clientConfig.loyaltyClubs) {
                                    Loyalty.getSpecialsLoyalty($scope.loyaltyClubDriver.loyaltyClubIds, 0).then(function(data) {
                                        $scope.numberOfSpecials = data.total
                                    }).then(function() {
                                        var loyaltyClub = loyaltyClubDriver.clientConfig.loyaltyClubs[$scope.loyaltyClub.loyaltyClubId]
                                        $scope.cashbackRatio = loyaltyClub.pointsToCashbackRatio
                                        $scope.loyaltyHomepageConfig = loyaltyClub.loyaltyPages.homepage
                                        $scope.isHomepageActive = loyaltyClub.loyaltyPages.homepage.isActive
                                        $scope.pointsToRedeemCashback = loyaltyClub.minPointsNum;
                                        $scope.pointName = loyaltyClub.loyaltyPages.visibility.tex;
                                    })
                                }
                            }
                        }
                        if ((userData == undefined || userData && userData.loyaltyClubs && userData.loyaltyClubs.length == 0) && Config.retailer && Config.retailer.loyaltyClubDriver && Config.retailer.loyaltyClubDriver.clientConfig && Config.retailer.loyaltyClubDriver.clientConfig.loyaltyClubs !== undefined && Object.values(Config.retailer.loyaltyClubDriver.clientConfig.loyaltyClubs).length > 0) {
                            var loyaltyClubs = Object.values(Config.retailer.loyaltyClubDriver.clientConfig.loyaltyClubs);
                            if (loyaltyClubs.length > 0 && loyaltyClubs[0].loyaltyPages && loyaltyClubs[0].loyaltyPages.homepage) {
                                $scope.loyaltyHomepageConfig = loyaltyClubs[0].loyaltyPages.homepage
                                $scope.isHomepageActive = loyaltyClubs[0].loyaltyPages.homepage.isActive
                            }
                        }
                    })
                }
    
                $rootScope.$on('numberOfCouponsRoot', function(event, data) {
                    $scope.numberOfCoupons = data
                })
                
                function setNumberOfCoupons() {
                    if ($rootScope.isSsoLogin) {
                        return;
                    }

                    if (
                        $scope.user &&
                        $rootScope.config.retailer.settings.enablePersonalizedCoupons
                    ) {
                        Coupon.getNumberOfCoupons()
                            .then(function (couponsData) {
                                var coupons = couponsData.coupons || couponsData;
                                $scope.numberOfCoupons = coupons.length;
                        });
                    } else {
                        delete $scope.numberOfCoupons;
                    }
                }

                $scope.openLoyaltyMembershipPages = function (stateName, stateParams) {
                    if (_timeoutOpenMenuPages) {
                        $timeout.cancel(_timeoutOpenMenuPages);
                    }
                    _timeoutOpenMenuPages = $timeout(function () {
                        $state.go(stateName, stateParams);
                    }, 300);
                };

                function _setRetailerData(withoutCache) {
                    return User.getRetailerData(withoutCache).then(function (retailerData) {
                        $scope.loyaltyClubDrivers = retailerData.loyaltyClubDrivers;
                        $scope.isLoyaltyExtendedRegisterActive = retailerData.loyaltyClubDrivers.length === 1 &&  retailerData.loyaltyClubDrivers[0].clientConfig &&  retailerData.loyaltyClubDrivers[0].clientConfig.extendedLoyaltyClub;
                        $scope.retailerData = retailerData;
                    });
                }

                /**
                 * @param {*} loyaltyHomepageConfig 
                 * @returns {string}
                 */
                function getExternalLinkFromLoyaltyHomeConfig(loyaltyHomepageConfig) {
                  var nonLoyaltyUserType = $rootScope.USER_LOYALTY_TYPES.NON_REGISTERED;

                  if (!loyaltyHomepageConfig || !nonLoyaltyUserType) {
                    return null;
                  }

                  var externalRegistration =
                    loyaltyHomepageConfig.userType[nonLoyaltyUserType].externalRegistration;

                  if (!externalRegistration.isActive) {
                    return null;
                  }

                  return externalRegistration.link;
                }

                $scope.goToSignInMembership = function(event) {
                    event.stopPropagation();

                    if(!$scope.user){
                        Util.goToLoginDialog();
                    }
                    
                    var externalLink = getExternalLinkFromLoyaltyHomeConfig($scope.loyaltyHomepageConfig)
                    // redirect only when login. If not always force login
                    if($scope.user && externalLink){
                        return Util.goToBannerHref(externalLink)
                    }

                    $state.go('app.extendedLoyaltyClub');
                }

                $rootScope.$on('login', function(){
                    setUser()
                })

                $rootScope.$on('logout', function () {
                    delete $scope.isHomepageActive;
                    delete $scope.user;
                    setLoyaltyPageConfig()
                })
                
                Util.destroyListeners($scope, _listeners);
            }]
        };
    });
