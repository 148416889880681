angular.module('mobilezuz')
    .directive('spVerticalSliderPicker', ['$rootScope', '$timeout', 'Config', 'Util', 'DataLayer', function($rootScope, $timeout, Config, Util, DataLayer) {
        //when changing visible options or normal option size need to change at sp-vertical-slider-picker.scss
        var DEFAULT_VISIBLE_OPTIONS = 5,
            CONTINUE_SCROLL_TIME = 200; //milliseconds

        return {
            restrict: 'E',
            replace: true,
            scope: {
                spModel: '=?',
                spDropdownModel: '=?',
                options: '=',
                retailerData: '=?',
                spOnLastOption: '&?',
                dropdownOptionsName: '@?',
                optionTemplateUrl: '@',
                optionTemplateUrlClass: '@?',
                dropdownTemplateUrl: '@?',
                dropdownTitleTemplateUrl: '@?',
                emptyDropdownTemplateUrl: '@?',
                global: '&?',
                visibleOptions: '<?',
                isPresentation: '<?',
                /**
                 * in percentage - default is 95%
                 */
                optionWidth: '<?',
                scrollToActiveOption: '<?',
                dots: '&?',
                autoPlayInterval: '<?',
                onDisabledOption: '&?',
                srSupport: '<?'
            },
            controllerAs: 'pickerCtrl',
            controller: ['$scope', '$element', '$attrs', '$rootScope', 'Config', function($scope, $element, $attrs, $rootScope, Config) {
                var pickerCtrl = this,
                    _sliderOptionsElement = angular.element($element[0].querySelector('.slider-options')),
                    _optionsGrabberElement = angular.element(_sliderOptionsElement[0].querySelector('.slider-options-grabber')),
                    _scrollToActiveOptionTimeout,
                    _currentMargin = 0,
                    _marginName,
                    _optionsWrapperWidth,
                    _normalOptionWidth,
                    _touchesFromStartToEnd = [], //every touch collects all of its points, to calculate which touch happened the animation time before the touch ended
                    _autoPlayTimeout,
                    _listeners = [];

                pickerCtrl.loading = Config && Config.retailer && Config.retailer.disableLazyLoading ? 'eager': 'lazy';
                pickerCtrl.selectOption = selectOption;
                pickerCtrl.onClickDropdownOption = onClickDropdownOption;
                pickerCtrl.getVisibleOptions = getVisibleOptions;
                pickerCtrl.changeFocus = changeFocus;
                pickerCtrl.global = $scope.global && $scope.global();
                pickerCtrl.activeIndex = 0;

                window.addEventListener('resize', _onResize);
                _listeners.push(function() {
                    window.removeEventListener('resize', _onResize);
                });

                _initLanguage();
                _listeners.push($rootScope.$on('config.language.set', function() {
                    _optionsGrabberElement.css(_marginName, '');
                    _initLanguage();
                    _scrollToCurrentItem(true);
                }));

                _listeners.push($rootScope.$on('config.branchAreaId.set', function() {
                    pickerCtrl.global = $scope.global && $scope.global();
                    var currentIndex = pickerCtrl.currentIndex;
                    if(pickerCtrl.currentIndex < 0){
                        currentIndex = 0;
                    } 
                    _setCurrentOption(currentIndex);
                }));

                $scope.$watch('autoPlayInterval', function() {
                    _autoPlayTimeout && $timeout.cancel(_autoPlayTimeout);
                    _autoPlay();
                });
                $scope.$watch(function() {
                    return _sliderOptionsElement && _sliderOptionsElement[0].clientWidth;
                }, function(newVal, oldVal) {
                    if (newVal !== oldVal) {
                        _setOptionsWidth();
                        if (pickerCtrl.currentIndex === undefined) {
                            _setOptions($scope.options);
                        } else {
                            _scrollToCurrentItem(true);
                        }
                    }
                });
                $scope.$watch('options', function(newVal) {
                    _setOptionsWidth();
                    _setOptions(newVal);
                });
                $scope.$watch('options.length', function() {
                    DataLayer.push(DataLayer.EVENTS.VIEW_ITEM_LIST, {products: $scope.options});
                    _scrollToCurrentItem();
                });

                Util.destroyListeners($scope, _listeners);

                function _initLanguage() {
                    _marginName = 'margin-' + (Config.language.direction === 'rtl' ? 'right' : 'left');
                }

                function _setOptionsWidth() {
                    var _visibleOptions = getVisibleOptions(),
                        _normalOptionSize = ($scope.optionWidth || (100 / _visibleOptions)) / 100; // in %
                    _optionsWrapperWidth = _sliderOptionsElement[0].clientWidth;
                    _normalOptionWidth = _optionsWrapperWidth * _normalOptionSize;
                }

                function getVisibleOptions() {
                    return $scope.visibleOptions || DEFAULT_VISIBLE_OPTIONS;
                }

                function changeFocus($index, to) {
                    var newIndex = $index + to;
                    pickerCtrl.activeIndex = newIndex;
                    var _focusElement = angular.element($element[0].querySelector('#picker_option_' + newIndex));
                    $timeout(function () {
                        _focusElement && _focusElement.focus();
                    });
                }

                function _optionsTouchStart(event) {
                    _autoPlayTimeout && $timeout.cancel(_autoPlayTimeout);
                    _touchesFromStartToEnd.splice(0);
                    _touchesFromStartToEnd.push({
                        time: new Date(),
                        point: _getTouchPosition(event),
                        initMargin: _currentMargin
                    });

                    _optionsGrabberElement.addClass('no-animation');
                    // event.preventDefault();
                    _sliderOptionsElement.bind('touchmove', _optionsTouchMove);
                    _sliderOptionsElement.bind('touchend', _optionsTouchEnd);
                    _sliderOptionsElement.unbind('touchstart', _optionsTouchStart);
                }

                function _getOptions() {
                    return $scope.options || [];
                }

                function _setMarginBoundaries(fromMargin) {
                    //can scroll to the very last element when has sp model, otherwise can scroll until all elements are visible
                    var lastScrolledElement = _getOptions().length - ($attrs.spModel ? 1 : getVisibleOptions());

                    var minMargin = lastScrolledElement * _normalOptionWidth * -1,
                        maxMargin = 0;

                    if (fromMargin < minMargin) {
                        fromMargin = minMargin;
                    }
                    if (fromMargin > maxMargin) {
                        fromMargin = maxMargin;
                    }

                    return fromMargin;
                }

                function _getXDistanceFromPoints(pointA, pointB) {
                    return (pointB.x - pointA.x) * (Config.language.direction === 'rtl' ? -1 : 1);
                }

                function _optionsTouchMove(event) {
                    var point = _getTouchPosition(event),
                        prevTouch = _touchesFromStartToEnd[_touchesFromStartToEnd.length - 1],
                        distanceFromPrev = _getXDistanceFromPoints(prevTouch.point, point),
                        yDistanceFromPrev = point.y - prevTouch.point.y,
                        distanceFromStart = _getXDistanceFromPoints(_touchesFromStartToEnd[0].point, point),
                        nextMargin = _currentMargin + distanceFromPrev;

                    _touchesFromStartToEnd.push({
                        time: new Date(),
                        point: point
                    });

                    nextMargin = _setMarginBoundaries(nextMargin);

                    //adds the scroll end effect, keeps scrolling in half of the distance when reached the end
                    nextMargin += ((distanceFromStart + _touchesFromStartToEnd[0].initMargin) - nextMargin) / 2;

                    //prevent scroll if touch went up or down
                    if (Math.abs(yDistanceFromPrev) > Math.abs(distanceFromPrev)) return;

                    _scrollTo(nextMargin);
                    _setCurrentMiddleOption();

                    $scope.$apply();
                    event && event.preventDefault();
                }

                function _getLastAnimationTimeTouch() {
                    var nowTimeMinusAnimationTime = new Date(),
                        touch;
                    nowTimeMinusAnimationTime.setMilliseconds(nowTimeMinusAnimationTime.getMilliseconds() - CONTINUE_SCROLL_TIME);

                    for (var i = _touchesFromStartToEnd.length - 1; i >= 0; i--) {
                        if (_touchesFromStartToEnd[i].time >= nowTimeMinusAnimationTime) {
                            touch = _touchesFromStartToEnd[i];
                        } else {
                            break;
                        }
                    }

                    return touch;
                }

                function _optionsTouchEnd(event) {
                    if (event) {
                        _autoPlay();
                        // event.preventDefault();
                    }
                    _optionsGrabberElement.removeClass('no-animation');
                    _sliderOptionsElement.bind('touchstart', _optionsTouchStart);
                    _sliderOptionsElement.unbind('touchend', _optionsTouchEnd);
                    _sliderOptionsElement.unbind('touchmove', _optionsTouchMove);

                    if (_touchesFromStartToEnd.length > 1) {
                        /**
                         * This logic continues the scroll when it ends.
                         * It takes the distance scrolled in the last animation time (0.5 seconds) and scrolls the same distance multiplied by the 'animation time / touch time' in the next animation time.
                         * It multiplies it to scroll more the fastest the user touched
                         */

                        var lastAnimationTimeTouch = _getLastAnimationTimeTouch(),
                            lastTouch = _touchesFromStartToEnd[_touchesFromStartToEnd.length - 1];

                        if (lastAnimationTimeTouch) {
                            var lastAnimationTimeDistance = _getXDistanceFromPoints(lastAnimationTimeTouch.point, lastTouch.point),
                                yLastAnimationTimeDistance = lastTouch.point.y - lastAnimationTimeTouch.point.y;

                            //to prevent scrolling when touch went up or down
                            if (lastAnimationTimeDistance && Math.abs(yLastAnimationTimeDistance) <= Math.abs(lastAnimationTimeDistance)) {
                                var timeDifferenceMilliseconds = lastTouch.time.getTime() - lastAnimationTimeTouch.time.getTime(),
                                    nextMargin = _setMarginBoundaries(_currentMargin + (lastAnimationTimeDistance * (CONTINUE_SCROLL_TIME / timeDifferenceMilliseconds)));

                                _scrollTo(nextMargin);
                                _setCurrentMiddleOption();
                            }
                        }
                    }

					if ($scope.spOnLastOption && pickerCtrl.currentIndex === (_getOptions().length -1)) {
                        $scope.spOnLastOption({scroll: true});
                    }
                    _scrollToCurrentItem(true);

                    if (event) {
                        $scope.$apply();
                    }
                }

                function _getTouchPosition(event) {
                    event = event.originalEvent || event;
                    return { x: event.touches[0].clientX, y: event.touches[0].clientY };
                }

                function _setCurrentMiddleOption() {
                    var startOfMiddle = _currentMargin * -1,
                        endOfMiddle = startOfMiddle + _normalOptionWidth,
                        currentIndex = Math.round(startOfMiddle / _normalOptionWidth),
                        atEndIndex = Math.round(endOfMiddle / _normalOptionWidth),
                        betweenPoint = atEndIndex * _normalOptionWidth;

                    if (endOfMiddle - betweenPoint > betweenPoint - startOfMiddle) {
                        currentIndex = atEndIndex;
                    }

                    if (currentIndex < 0 || currentIndex > _getOptions().length - 1) {
                        return;
                    }

                    _setCurrentOption(currentIndex);
                }

                function _scrollToCurrentItem(immediately) {
                    function _do() {
                        var _toIndex = pickerCtrl.currentIndex,
                            _visibleOptions = getVisibleOptions(),
                            _allLength = _getOptions().length,
                            _firstInvisible = _toIndex + _visibleOptions;
                        if (_firstInvisible > _allLength && !$scope.scrollToActiveOption) {
                            _toIndex = Math.max(0, _toIndex - (_firstInvisible - _allLength));
                        }

                        var _itemsBeforeWidth = _toIndex * _normalOptionWidth,
                            _inactiveItemsWidth = 0;
                        if (_toIndex && !$scope.scrollToActiveOption) {
                            _inactiveItemsWidth = _optionsWrapperWidth - (_visibleOptions * _normalOptionWidth);
                            if ((pickerCtrl.currentIndex + _visibleOptions) < _allLength) {
                                _inactiveItemsWidth /= 2;
                            }
                        }

                        _scrollTo((_itemsBeforeWidth - _inactiveItemsWidth) * -1);
                    }

                    if (immediately) {
						 _do();
                    } else {
                        _scrollToActiveOptionTimeout && $timeout.cancel(_scrollToActiveOptionTimeout);
                        _scrollToActiveOptionTimeout = $timeout(function() {
                            _scrollToActiveOptionTimeout = null;
                            _do();
                        });
                    }
                }

                function _scrollTo(to) {
                    _currentMargin = to;
                    _optionsGrabberElement.css(_marginName, to + 'px');
                }

                function selectOption(index) {
                    if (!$attrs.spModel) {
                        return;
                    }

                    _setCurrentOption(index);
                    _scrollToCurrentItem();
                }

                function _setCurrentOption(index) {
                    pickerCtrl.currentIndex = index;
                    $scope.spModel = _getOptions()[index];

                    pickerCtrl.dropdownOptions = $scope.$eval($scope.dropdownOptionsName, $scope.spModel);


                    if (!$scope.dropdownOptionsName) return;
                    _selectDropdownOption();
                    if (pickerCtrl.dropdownOptions && pickerCtrl.dropdownOptions.length) {
                        for (var i = 0; i < pickerCtrl.dropdownOptions.length; i++) {
                            _selectDropdownOption(i);
                            if ($scope.spDropdownModel) break;
                        }
                    }
                }

                function onClickDropdownOption(index) {
                    if ($scope.onDisabledOption && index !== undefined && pickerCtrl.dropdownOptions[index] !== undefined && pickerCtrl.dropdownOptions[index].disabled) {
                        $scope.onDisabledOption({$option: pickerCtrl.dropdownOptions[index]});
                    }

                    _selectDropdownOption(index);
                }

                function _selectDropdownOption(index) {
                    if (index === undefined) {
                        pickerCtrl.currentDropdownIndex = null;
                        $scope.spDropdownModel = null;
                        return;
                    }

                    if (pickerCtrl.dropdownOptions[index] === undefined || pickerCtrl.dropdownOptions[index].disabled) {
                        return;
                    }

                    pickerCtrl.currentDropdownIndex = index;
                    $scope.spDropdownModel = pickerCtrl.dropdownOptions[index];
                }

                function _onResize() {
                    if (!pickerCtrl.hasOwnProperty('currentIndex')) {
                        return;
                    }

                    _setOptionsWidth();
                    _scrollToCurrentItem(true);
                }

                function _autoPlay(delay) {
                    delay = delay || $scope.autoPlayInterval;

                    if (!delay) return;

                    _autoPlayTimeout = $timeout(function() {
                        if (pickerCtrl.currentIndex !== undefined) {
                            pickerCtrl.currentIndex++;
                            if (pickerCtrl.currentIndex >= _getOptions().length || 0) {
                                pickerCtrl.currentIndex = 0;
                            }
                            _scrollToCurrentItem(true);
                        }
                        _autoPlay(delay);
                    }, delay);
                }

                function _setOptions(newVal) {
                    if (!newVal || !_normalOptionWidth) return;
                    var modelIndex,
                        i;
                    if ($scope.spModel) {
                        for (i = 0; i < newVal.length; i++) {
                            if (newVal[i] !== $scope.spModel) continue;

                            modelIndex = i;
                            break;
                        }
                    }

                    pickerCtrl.dropdownShownItems = 0;
                    angular.forEach(_getOptions(), function(option) {
                        var dropdownOptions = $scope.$eval($scope.dropdownOptionsName, option);

                        if (!dropdownOptions || pickerCtrl.dropdownShownItems > dropdownOptions.length) return;
                        pickerCtrl.dropdownShownItems = dropdownOptions.length;
                    });
                    pickerCtrl.dropdownShownItems += $scope.dropdownTitleTemplateUrl ? 1 : 0;

                    if (modelIndex === undefined) {
                        _setCurrentMiddleOption();

                        var optionsLength = _getOptions().length;
                        if (pickerCtrl.currentIndex < (optionsLength - 1) && $scope.dropdownTemplateUrl) {
                            while (!$scope.spDropdownModel && pickerCtrl.currentIndex !== (_getOptions().length - 1)) {
                                _setCurrentOption(pickerCtrl.currentIndex + 1);
                            }
                        } else {
                            _setCurrentOption(0);
                        }
                    } else {
                        var dropdownModel = $scope.spDropdownModel;
                        _setCurrentOption(modelIndex);
                        if (pickerCtrl.dropdownOptions && dropdownModel) {
                            for (i = 0; i < pickerCtrl.dropdownOptions.length; i++) {
                                if (pickerCtrl.dropdownOptions[i] !== dropdownModel) continue;
                                _selectDropdownOption(i);
                                break;
                            }
                        }
                    }
                    _optionsTouchEnd();
                }
            }],
            templateUrl: 'views/templates/sp-vertical-slider-picker.html'
        };
    }]);