(function(angular) {
    angular.module('mobilezuz')
        .config(['$stateProvider', function($stateProvider) {
            $stateProvider.state('app.bitCompleteExternalCheckout', {
                url: '/_bit-completed-checkout?{externalPaymentKey},{userId}',
                data: {
                    bodyClass: 'external-checkout-finish'
                },
                views: {
                    '@': {
                        templateUrl: 'views/complete-external-checkout-process.html',
                        controller: 'BitCompleteExternalCheckoutCtrl as bitCompleteCheckoutCtrl'
                    }
                }
            });
        }])
        .controller('BitCompleteExternalCheckoutCtrl', [
            '$scope', '$stateParams', '$state', 'Config', 'Api',
            function($scope, $stateParams, $state, Config, Api) {
                _completeCheckout();

                function _completeCheckout() {
                    return Config.waitForInit().then(function() {
                        return Api.request({
                            method: 'POST',
                            url: '/retailers/:rid/users/' + ($stateParams.userId || ':uid') + '/_bit-completed-checkout',
                            params: {
                                externalPaymentKey: $stateParams.externalPaymentKey
                            }
                        }, {
                            fireAndForgot: true
                        });
                    }).finally(function() {
                        $state.go('app.externalCheckoutFinish', { externalPaymentKey: $stateParams.externalPaymentKey });
                    });
                }
            }
        ]);
})(angular);