(function(angular) {
    function CartService() {}

    angular.module('mobilezuz')
        .config(['SpCartServiceProvider', function (spCartServiceProvider) {
            spCartServiceProvider.initCart = ['cart', function (cart) {
                var serverCartId = localStorage.getItem('serverCartId');
                if (serverCartId) {
                    cart.serverCartId = Number(serverCartId);
                }

                var localLines = localStorage.getItem('myCart');
                if (!localLines) {
                    return;
                }
                localStorage.removeItem('myCart');

                localLines = JSON.parse(localLines);
                var linesToAdd = [];
                angular.forEach(localLines, function (localLine) {
                    var lineToAdd = {
                        product: localLine,
                        isCase: localLine.isCase,
                        isPseudo: localLine.isPseudo,
                        comments: localLine.comments,
                        quantity: localLine.quantity,
                        metaData: localLine.metaData
                    };
                    linesToAdd.push(lineToAdd);
                });
                cart.addLines(linesToAdd);
            }];

            spCartServiceProvider.getLocals = [
                '$injector', '$q', '$state', 'Config', 'Retailer', 'User',
                function ($injector, $q, $state, Config, retailer, user) {
                    return $q.all({
                        retailer: retailer.getRetailerSettings(),
                        user: user.getUserLoginData() ? user.getUserSettings().catch(function() { /* do nothing */ }) : undefined
                    }).then(function(results) {
                        var isCheckoutPage = $state.includes('app.cart.checkout.process'),
                            isEditingOrder = !!$injector.get('Orders').orderInEdit,
                            branchArea;

                        // if there is no area after the config init the getBranchArea will throw an error
                        // in this case the delivery items limit will be validated ones an area will be chosen
                        try {
                            branchArea = Config.getBranchArea();
                        } catch (e) {}

                        return {
                            includeTaxInPrice: angular.isUndefined(results.retailer.includeTaxInPrice) ? null : results.retailer.includeTaxInPrice,
                            isRegularPriceWithTax: angular.isUndefined(results.retailer.isRegularPriceWithTax) ? null : results.retailer.isRegularPriceWithTax,
                            loyaltyClubIds: results.user && results.user.loyaltyClubs && results.user.loyaltyClubs.length && results.user.loyaltyClubs.map(function (loyaltyClub) {
                                return loyaltyClub.loyaltyClubId;
                            }) || null,
                            userId: results.user && results.user.id || null,
                            apiOptions: {
                                hideError: true,
                                loadingElement: isCheckoutPage ? null : document.querySelector('.btn-cart .loading-wrapper')
                            },
                            languageId: Config.language.id,
                            withDeliveryProduct: isCheckoutPage || isEditingOrder,
                            deliveryItemsLimit: branchArea && branchArea.deliveryItemsLimit
                        };
                    });
                }
            ];
        }])
        .service('Cart', ['SpDialogUrlManager', 'SpCartService', 'DeliveryItemsLimitDialog', '$rootScope', 'mDesign', '$state', '$q', '$timeout', '$filter',
            'User', 'Config', 'Util', 'DeliveryWithinDaysWarningDialog', 'PRODUCT_TAG_TYPES', 'DataLayer', '$injector', 'Api', 'SP_SERVICES', 'DELIVERY_TIMES_TYPES', 'CHARGE_SPECIALS_CALCULATION_TIME',
            function (SpDialogUrlManager, spCartService, DeliveryItemsLimitDialog, $rootScope, mDesign, $state, $q, $timeout, $filter,
                      User, config, util, DeliveryWithinDaysWarningDialog, PRODUCT_TAG_TYPES, DataLayer, $injector, Api, SP_SERVICES, DELIVERY_TIMES_TYPES, CHARGE_SPECIALS_CALCULATION_TIME) {
                CartService.prototype = spCartService;
                var cart = new CartService(),
                    promiseChain = $q.resolve(),
                    _deliveryItemsLimitDialog,
                    _isInCartMerge = false,
                    ageValidationListener = $rootScope.$on('cart.update.complete', _handleAgeValidations),
                    _productNameFilter = $filter('productName'),
                    _translateFilter = $filter('translate'),
                    _cartLineQuantityFilter = $filter('cartLineQuantity');

                
                /**
                 * @typedef {Object} IrreSalesDataType
                 * @property {ExpiredInfo} expiredInfo
                 * @property {CartLine[]} irrelevantSalesLines
                 * @property {boolean} skipDialogSpecial
                 * @property {CartLine[]} outOfStockLines
                 * @property {Record<number, boolean>} lineTypes
                 */
                
                /** @type {IrreSalesDataType} */
                cart.irreSalesData = {
                  lineTypes: null,
                  expiredInfo: {
                    outOfStock: null,
                    sellDates: null,
                    special: null,
                    coupon: null
                  },
                  irrelevantSalesLines: [],
                  skipDialogSpecial: false,
                  outOfStockLines: [],
                };
                    
                cart.addProduct = addProduct;
                cart.addCoupon = addCoupon;
                cart.getProductLine = getProductLine;
                cart.quantityChanged = quantityChanged;
                cart.removeLine = removeLine;
                cart.minusQuantity = minusQuantity;
                cart.checkUserCreditLimited = checkUserCreditLimited;
                cart.forceUpdate = forceUpdate;
                cart.checkIsLimitHeavyPackagePassed = checkIsLimitHeavyPackagePassed;
                cart.showDialogHeavyPackage = showDialogHeavyPackage;
                cart.checkHeavyLimitBeforeAddLine = checkHeavyLimitBeforeAddLine;
                cart.checkHeavyLimitBeforeAddLines = checkHeavyLimitBeforeAddLines;
                cart.checkHeavyLimitBeforeAddQuantity = checkHeavyLimitBeforeAddQuantity;
                cart.sortCartByCategories = sortCartByCategories;
                cart.filterCartLineRemoved = filterCartLineRemoved;
                cart.sortByTree = sortByTree;
                cart.addDeliveryFeeLineIfNeeded = addDeliveryFeeLineIfNeeded;
                cart.showSuggestionsDialog = showSuggestionsDialog;
                cart.removeOOS = removeOOS;
                cart.setCartLineReplacements = setCartLineReplacements;
                cart.checkIrrelevantSales = checkIrrelevantSales;
                cart.setIrreSalesData = setIrreSalesData;
                
                /**
                 * Fly image to cart and add a new line with sp cart service
                 * @param {object} line
                 * @public
                 */
                $rootScope.$on('cart.lines.add', function (event, data) {
                    angular.forEach(data.lines, function (line) {
                        var img = angular.element(document.querySelector('#product_line_' + line.product.id)).find('img');
                        if (img.length > 0) {
                            flyToCart(img);
                        }

                        var alertText = _productNameFilter(line.product, line.product.isCaseMode) + ' ' + _translateFilter('cart_line_added_alert') + ' ' + _translateFilter('quantity') + ' ' + _cartLineQuantityFilter(line);
                        util.setLiveAlert(alertText);
                    });
                });

                /**
                 * [flyToCart Animate adding product to cart]
                 * @param {string} $element - original image element
                 */
                function flyToCart($element) {
                    var clientRect = $element[0].getBoundingClientRect(),
                        clone = $element.clone().addClass('fly-cart')[0],
                        myCartClientRect = document.querySelector('#my-cart').getBoundingClientRect();
                    clone.style.top = clientRect.top + 'px';
                    clone.style.left = clientRect.left + 'px';
                    clone.style.width = clientRect.width + 'px';
                    clone.style.height = clientRect.height + 'px';
                    document.body.appendChild(clone);

                    setTimeout(function () {
                        clone.style.top = myCartClientRect.top + 'px';
                        clone.style.left = myCartClientRect.left + 'px';
                        clone.style.width = myCartClientRect.width + 'px';
                        clone.style.height = myCartClientRect.height + 'px';
                        clone.style.opacity = 0;
                    }, 0);

                    setTimeout(function () {
                        document.body.removeChild(clone);
                    }, 500);
                }

                function quantityChanged(line) {
                    spCartService.quantityChanged(line);

                    if (line.removed) {
                        spCartService.removeLine(line);
                    }
                }

                function removeLine(line, isDelete) {
                    spCartService.removeLine(line, isDelete);
                }

                function minusQuantity(line) {
                    spCartService.minusQuantity(line);
                    if (line.removed) {
                        spCartService.removeLine(line);
                    }
                }

                function addDeliveryFeeLineIfNeeded() {
                    // 1 delivery, 2 pickup
                    var deliveryTypeId = $rootScope.config.getBranchArea() ? $rootScope.config.getBranchArea().deliveryTypeId : 1;

                    if (deliveryTypeId) {
                        cart.addLine({
                            quantity: 1,
                            type: SP_SERVICES.CART_LINE_TYPES.DELIVERY,
                            product: {
                                id: -1
                            },
                            areaId: $rootScope.config.getBranchArea() && $rootScope.config.getBranchArea().id ? $rootScope.config.getBranchArea().id : 2157,
                            deliveryTypeId: deliveryTypeId,
                        });
                    }
                }

                $rootScope.$on('cart.lines.inactive', function (event, data) {
                    mDesign.dialog({
                        controller: ['$scope', function ($scope) {
                            $scope.lines = data.lines;
                            $scope.close = mDesign.hide;
                        }],
                        templateUrl: 'views/templates/inactive-lines-alert.html'
                    });
                });

                $rootScope.$on('cart.lines.error', function (event, data) {
                    mDesign.dialog({
                        controller: ['$scope', function ($scope) {
                            $scope.errors = data.errors;
                            $scope.close = mDesign.hide;
                        }],
                        templateUrl: 'views/templates/bad-lines-alert.html'
                    });
                });

                $rootScope.$on('cart.update.complete', function () {
                    if (cart.serverCartId) {
                        localStorage.setItem('serverCartId', cart.serverCartId);
                    } else {
                        localStorage.removeItem('serverCartId');
                    }

                    return User.getUserSettings().then(function(userData){
                        if (!userData.loyaltyClubs || !userData.loyaltyClubs.length && !cart.alertRegisterIfCartAboveFlag &&
                            !!(config.retailer.loyaltyClubDrivers || []).find(function (driver) {
                                return driver.clientConfig && cart.total.simulateClubsGiftsForView && driver.clientConfig.alertRegisterIfCartAbove
                                    && driver.clientConfig.alertRegisterIfCartAbove < cart.total.priceForView
                            })) {
                            cart.alertRegisterIfCartAboveFlag = true;
                            $state.go(config.retailer.loyaltyClubDriver.clientConfig.extendedLoyaltyClub ? 'app.extendedLoyaltyClub' : 'app.loyaltyClub');
                        }
                        setCartLineReplacements(cart.lines);
                    });
                });

                function setCartLineReplacements(lines) {
                    var linesForSuggestions = [];
                    angular.forEach(lines, function(line) {
                       if (util.isEligibleForReplacementSuggestions(line)) {
                           linesForSuggestions.push(line);
                       }
                    });

                    if (linesForSuggestions.length) {
                        setReplacements(linesForSuggestions);
                    }
                }

                function setReplacements(lines) {
                    var allLines = _getCartLinesArray(),
                        productIdMap = {};
                    return User.getReplacementSuggestions(lines.map(function(line) {
                        productIdMap[line.product.id] = line;
                        return line.product.id;
                    }), allLines.map(function(line) {
                        return line.product.id;
                    })).then(function(data) {
                        angular.forEach(data, function(product) {
                            var line = productIdMap[product.id];
                            if (line && line.product && product.suggestions && product.suggestions.length) {
                                line.product._suggestions = product.suggestions;
                            }
                        });

                        angular.forEach(productIdMap, function(line) {
                            if (line && line.product && line.product._suggestions) {
                                if (!line.product._suggestions.length || !line.product._suggestions[0].id) {
                                    delete line.product._suggestions;
                                }
                            }
                        });

                        !$rootScope.$$phase && $rootScope.$apply();
                    }).catch(function(err) {
                        angular.forEach(lines, function(line) {
                            delete line.product._suggestions;
                        });
                    });
                }

                function _getCartLinesArray() {
                    var allLines = [];
                    angular.forEach(cart.lines, function(line) {
                        allLines.push(line);
                    });
                    return allLines;
                }

                /**
                 * Get's current is case mode line from product
                 * @param {object} product
                 * @returns {object}
                 */
                function getProductLine(product) {
                    return product.isCaseMode ? product.caseLine : product.singleLine;
                }

                /**
                 * Add a line for a product by is case mode
                 * @param {object} product
                 */
                function addProduct(product, itemsData) {
                    var line = {product: product, isCase: product.isCaseMode};
                    var result = checkHeavyLimitBeforeAddLine(line);
                    if (result === false) {
                        var deliveryFound = false;
                        var keys = Object.keys(cart.lines)
                        for (var i = 0; i < keys.length; i++ ) {
                            var line = cart.lines[keys[i]];
                            if (line.type === SP_SERVICES.CART_LINE_TYPES.DELIVERY) {
                                deliveryFound = true;
                                break;
                            }
                        }
                        if (!deliveryFound) {
                            cart.addDeliveryFeeLineIfNeeded();
                        }

                        var line = {product: product, isCase: product.isCaseMode};
                        var oldQuantity = product.oosProduct && product.oosProduct.quantity;
                        if (oldQuantity) {
                            line.quantity = oldQuantity;
                            line.product.quantity = oldQuantity;
                        }
                        // if (cart.lines && Object.keys(cart.lines).length === 0 &&
                        //     $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                        //     cart.addDeliveryFeeLineIfNeeded();
                        // }
                        if (itemsData.source) {
                            itemsData.source = util.extractHomeCarouselName(itemsData.source, itemsData.componentType, itemsData.componentPosition);
                        }
                        cart.addLine(line, line.product && line.product.soldBy, itemsData);
                    }
                }

                function _onOtherCartEvent(event, data) {
                    var isEditingOrder = !!$injector.get('Orders').orderInEdit;

                    if (!data.otherCart || $state.includes('app.cart.checkout.process') || isEditingOrder || _isInCartMerge) {
                        return;
                    }

                    _isInCartMerge = true;
                    mDesign.dialog({
                        focusOnOpen: false,
                        clickOutsideToClose: false,
                        templateUrl: 'views/templates/other-cart-dialog.html',
                        controller: ['$scope', function ($scope) {
                            $scope.otherCart = data.otherCart;
                            $scope.mergeCarts = mergeCarts;
                            $scope.thisCart = thisCart;

                            function mergeCarts() {
                                _closeDialog();
                                data.next(data.otherCart.id, true);
                                $rootScope.$emit('mergeCarts');
                            }

                            function thisCart() {
                                _closeDialog();
                                data.next(data.otherCart.id);
                            }

                            function _closeDialog() {
                                _isInCartMerge = false;
                                mDesign.hide();                            }
                        }]
                    });
                }

                function _showQuantityLimitDialog(event, data) {
                    mDesign.dialog({
                        focusOnOpen: false,
                        clickOutsideToClose: false,
                        templateUrl: 'views/templates/quantityLimit-dialog.html',
                        controller: ['$scope', function ($scope) {
                            $scope.line = data.line;
                            $scope.hide = hide;

                            function hide() {
                                mDesign.hide();
                            }
                        }]
                    });
                }

                /**
                 * handles all the validates pertaining to the age limit of every line added
                 * @param {object} listener //TODO I want to get the listener itself here....
                 * @private
                 */
                function _handleAgeValidations() {
                    ageValidationListener();

                    if (!localStorage.getItem('ageRestriction')) {
                        var ageRestriction;
                        angular.forEach(cart.lines, function (line) {
                            if (line.product && line.product.branch && line.product.branch.ageRestriction &&
                                (ageRestriction || 0) < line.product.branch.ageRestriction) {
                                ageRestriction = line.product.branch.ageRestriction;
                            }
                        });
                        if (ageRestriction !== undefined) {
                            localStorage.setItem('ageRestriction', ageRestriction);
                        }
                    }

                    $rootScope.$on('cart.lines.add', function (event, data) {
                        angular.forEach(data.lines, function (line) {
                            promiseChain = promiseChain.then(function () {
                                return _validateAge(line);
                            });
                        });
                    });
                }

                /**
                 * validates the age limit of the line
                 * @param {object} line
                 * @private
                 */
                function _validateAge(line) {
                    if (!line.product || !line.product.branch || !line.product.branch.ageRestriction || line.product.branch.ageRestriction <= (localStorage.getItem('ageRestriction') || 0)) {
                        return $q.resolve(true);
                    }

                    return mDesign.dialog({
                        controller: 'AgeRestrictionDialog',
                        templateUrl: 'views/templates/age-restriction.html',
                        locals: {
                            ageLimit: line.product.branch.ageRestriction
                        }
                    }).then(function() {
                        localStorage.setItem('ageRestriction', line.product.branch.ageRestriction);
                    }).catch(function () {
                        return cart.removeLine(line, true);
                    });
                }

                function showSuggestionsDialog(product, event, skipAction) {
                    if (skipAction || !product || !product._suggestions || !product._suggestions.length || !product._suggestions[0].id) {
                        return;
                    }

                    if (event) {
                        event.stopPropagation();
                    }

                    return mDesign.dialog({
                        clickOutsideToClose: true,
                        multiple: true,
                        bypass: true,
                        controller: 'ProductSuggestionsCtrl as productSuggestionsCtrl',
                        templateUrl: 'views/templates/product-suggestions.html',
                        locals: {
                            product: product
                        }
                    });
                }

                function removeOOS(product) {
                    if (product.oosProduct) {
                        User.updateReplacementSuggestions([{
                            id: product.oosProduct.id,
                            suggestions: [{
                                id: product.id,
                                approved: true
                            }]
                        }]);

                        var line;
                        angular.forEach(cart.getLines(), function(cartLine) {
                            if (cartLine.product.id === product.oosProduct.id) {
                                line = cartLine;
                            }
                        });

                        if (!line) {
                            line = cart.getProductLine(product.oosProduct);
                        }

                        if (!line) {
                            return;
                        }

                        cart.removeLine(line, true);

                        if (product.oosProduct._suggestions && product.oosProduct._suggestions.length) {
                            angular.forEach(product.oosProduct._suggestions, function(product) {
                                delete product.oosProduct;
                            });
                        }
                        delete product.oosProduct;
                        $timeout(function() {
                            !$rootScope.$$phase && $rootScope.$apply();
                        }, 300);
                    }
                }

                function _handleDeliveryItemsLimit(event, data) {
                    if (_deliveryItemsLimitDialog) {
                        return;
                    }

                    return DeliveryItemsLimitDialog.show().finally(function() {
                        _deliveryItemsLimitDialog = null;
                    });
                }

                /**
                 * Alert popup if total exceed the Customer Credit
                 * @public
                 *
                 * @param {Boolean} includeTax
                 * @param {String} goToState
                 *
                 * @returns {Boolean}
                 */
                function checkUserCreditLimited(includeTax, goToState) {

                    if( !$rootScope.isCreditCustomer ){
                        return $q.resolve(false);
                    }

                    var cartTotal = !includeTax ? this.total.finalPriceForView :
                        this.total.finalPriceWithTax + this.total.serviceFee.finalPriceWithTax + this.total.deliveryCost.finalPriceWithTax;

                    return User.getUserSettings(true).then(function(){
                        if( $rootScope.creditCustomerRemainingSum < cartTotal ) {
                            mDesign.dialog({
                                templateUrl: 'views/templates/credit-customer-alert.html',
                                controller: ['$scope', 'mDesign', function ($scope, mDesign) {
                                    $scope.remainingCredit = $rootScope.creditCustomerRemainingSum;
                                    $scope.cartTotal = cartTotal;
                                    $scope.removeSum = Math.abs($rootScope.creditCustomerRemainingSum - cartTotal);
                                    $scope.cancel = function () {
                                        mDesign.hide();
                                    };
                                }]
                            }).then(function() {
                                !!goToState && $state.go(goToState);
                            });
                            return true;
                        }
                        return false;
                    });
                }

                /**
                 * force update all cartlines
                 * @public
                 *
                 * @returns {Promise}
                 */
                function forceUpdate() {
                    var deferred = $q.defer();

                    //in order to save the current prices of the lines into the database
                    $rootScope.forceUpdateCart = true;

                    var cartLines = cart.getLines();
                    angular.forEach(cartLines, function (line) {
                        cart.quantityChanged(line, true);
                    });

                    var listener = $rootScope.$on('cart.update.complete', function () {
                        listener(); //unregister from the listener
                        deferred.resolve();
                    });

                    return deferred.promise;
                }

                function checkHeavyLimitBeforeAddQuantity (cartLineId, productId, quantity) {
                    var tempCartLines = angular.copy( cart.lines);
                    if (tempCartLines[cartLineId]) {
                        tempCartLines[cartLineId].quantity = quantity;
                    } else {
                        tempCartLines[cartLineId] = { quantity: quantity };
                    }
                    var result = checkIsLimitHeavyPackagePassed(tempCartLines, productId, cartLineId);
                    if (result && result.isLimitPassed) {
                        showDialogHeavyPackage(result.maxQuantity, result.limit);
                        return true;
                    } else {
                        return false;
                    }
                }

                function checkHeavyLimitBeforeAddLine (line) {
                    var tempCartLines = angular.copy( cart.lines);
                    var productId = line.product.productId;
                    var cartLineId;
                    if (!line.id) {
                        var isCaseProduct = checkIsCaseProduct(line);
                        if (isCaseProduct) {
                            cartLineId = line.product.id + '1';
                        } else {
                            cartLineId = line.product.id + '0';
                        }
                    } else {
                        cartLineId = line.id;
                    }
                    tempCartLines[cartLineId] = line;
                    if (!tempCartLines[cartLineId].quantity) {
                        tempCartLines[cartLineId].quantity = tempCartLines[cartLineId].product && tempCartLines[cartLineId].product.isWeighable ? tempCartLines[cartLineId].unitResolution : 1;
                    }
                    var result = checkIsLimitHeavyPackagePassed(tempCartLines, productId, cartLineId);
                    if (result && result.isLimitPassed) {
                        showDialogHeavyPackage(result.maxQuantity, result.limit);
                        return true;
                    } else {
                        return false;
                    }
                }


                function checkHeavyLimitBeforeAddLines (newLines) {
                    var tempCartLines = angular.copy(cart.lines);
                    angular.forEach(newLines, function (line) {
                        var lineId;
                        if (line.id) {
                            lineId = line.id;
                        } else {
                            var isCaseProduct = checkIsCaseProduct(line);
                            if (isCaseProduct) {
                                lineId = line.product.id + '1';
                            } else {
                                lineId = line.product.id + '0';
                            }
                        }
                        if (!tempCartLines[lineId]) {
                            tempCartLines[lineId] = line;
                        } else {
                            tempCartLines[lineId].quantity += line.quantity;
                        }
                    });
                    var result = checkIsLimitHeavyPackagePassed(tempCartLines, null, null);
                    if (result && result.isLimitPassed) {
                        showDialogHeavyPackage(result.maxQuantity, result.limit);
                        return true;
                    } else {
                        return false;
                    }
                }

                function checkIsLimitHeavyPackagePassed (cartLines, productId, mainProductId) {
                    var body = {};
                    var keys = Object.keys(cartLines);
                    angular.forEach(keys, function (key) {
                        var isSingleCaseProduct = cartLines[key].product && cartLines[key].product.branch && cartLines[key].product.branch.case && cartLines[key].product.branch.case.price;
                        body[key] = {
                            id: cartLines[key].id,
                            quantity: calculateQuantity(cartLines[key]),
                            product: {
                                id: cartLines[key].product ? cartLines[key].product.productId : productId,
                                mainProductId: cartLines[key].product ? cartLines[key].product.id : mainProductId,
                                isSingleCase: isSingleCaseProduct ? isSingleCaseProduct && cartLines[key].isCase !== true : false,
                            },
                            isActive: cartLines[key].product && cartLines[key].product.branch ? cartLines[key].product.branch.isActive : true,
                        }
                    });
                    return isLimitPassedForHeavyPackages({ body: body, productId: productId, mainProductId: parseInt(mainProductId)});
                }

                function calculateQuantity (line) {
                    var quantity = line.quantity;
                    if (line.product) {
                        if (line.product && line.product.isWeighable) {
                            if (!!line.product.soldBy && line.product.soldBy === $rootScope.PRODUCT_DISPLAY.WEIGHT.name) {
                                quantity = line.quantity;
                            } else {
                                quantity = line.product.weight ? line.quantity * line.product.weight : line.quantity;
                            }
                        }
                    }
                    return quantity;
                }

                function showDialogHeavyPackage (maxQuantity, limit) {
                    var languageContent = limit.languages[config.language.id] ? limit.languages[config.language.id] : limit.languages[0];
                    mDesign.dialog({
                        controller: 'LimitHeavyPackageDialog',
                        templateUrl: 'views/templates/limit-heavy-package.html',
                        styleClass: 'limit-heavy-package-dialog',
                        bypass: true,
                        locals: {
                            maxQuantity: maxQuantity,
                            displayName: languageContent.displayName,
                            iconUrl: languageContent.iconResourceUrl,
                        }
                    });

                }

                function isLimitPassedForHeavyPackages (data) {
                    if (data.productId) {
                        var productContainsHeavyTag = checkIfProductContainsHeavyTag(data);
                        if (productContainsHeavyTag) {
                            return calculateIsLimitPassed(data);
                        } else {
                            return { isLimitPassed: false, maxQuantity: null };
                        }
                    } else {
                        return calculateIsLimitPassed(data);
                    }
                }

                function checkIfProductContainsHeavyTag (data) {
                    var arrayOfProductTagProducts = config.arrayOfProductTagProducts;
                    var arrayOfRetailerProductTagProducts = config.arrayOfRetailerProductTagProducts;

                    if ((data.productId && checkIfItContainedInArray(arrayOfProductTagProducts, data.productId) ) ||
                        (data.mainProductId && checkIfItContainedInArray(arrayOfRetailerProductTagProducts, data.mainProductId))) {
                        return true;
                    } else return false;
                }

                function calculateIsLimitPassed (data) {
                    var heavyPackageLimits = config.retailerHeavyTagLimitations;
                    var mapOfProductTagProducts = config.mapOfProductTagProducts;
                    var mapOfRetailerProductTagProducts = config.mapOfRetailerProductTagProducts;
                    var body = data.body;
                    var i, j;
                    for (i=0; i < heavyPackageLimits.length; i++) {
                        var count = 0;
                        var limit = heavyPackageLimits[i];
                        var bodyKeys = Object.keys(body);
                        for (j=0; j < bodyKeys.length; j++) {
                            var key = bodyKeys[j];
                            var line = body[key];
                            var countSingleProducts = !(limit.excludeSingleProducts && line.product.isSingleCase);
                            if (line.isActive && countSingleProducts) {
                                if ((mapOfProductTagProducts[limit.productTagId] && checkIfItContainedInArray(mapOfProductTagProducts[limit.productTagId],line.product.id))
                                    || (mapOfRetailerProductTagProducts[limit.productTagId] && checkIfItContainedInArray(mapOfRetailerProductTagProducts[limit.productTagId],line.product.mainProductId))) {
                                    count += line.quantity;
                                }
                            }
                        }
                        if (count > limit.maxQuantity) {
                            return { isLimitPassed: true, maxQuantity: limit.maxQuantity, limit: limit };
                        }
                    }
                    return { isLimitPassed: false, maxQuantity: null };
                }

                function checkIfItContainedInArray (array, id) {
                    if (array && id) {
                        var stringId = id.toString();
                        var i;
                        for (i=0; i < array.length; i++) {
                            var item = array[i];
                            if (stringId.includes(item.toString())) return true;
                        }
                        return false;
                    }
                    return false;
                }

                function sortCartByCategories (items, sortedCartByCategories, mergeCarts) {
                    if (!sortedCartByCategories) {
                        sortedCartByCategories = [];
                    }
                    var filtered = []

                    angular.forEach(items, function (item, key) {
                        if (item.type !== SP_SERVICES.CART_LINE_TYPES.DELIVERY) {
                            filtered.push(item);
                            item.$key = key;
                        }
                    });

                    filtered.sort(function (a, b) {
                        if (a.product && a.product.family && a.product.family.categories && a.product.family.categories.length >= 2 && b.product && b.product.family && b.product.family.categories && b.product.family.categories.length >= 2) {
                            return a.id - b.id
                        }
                    });

                    var sortedObjByCategories = sortedCartByCategories;
                    if(sortedCartByCategories && sortedCartByCategories.length < 1 || mergeCarts) {
                        if (filtered.length) {
                            sortedObjByCategories = filtered.reduce(function (acc, item, index, array) {
                                if (item.product && ((item.product.family && item.product.family.categories && item.product.family.categories.length) || item.isPseudo || item.type === SP_SERVICES.CART_LINE_TYPES.COUPON)) {
                                    var mainCategory = getProductMainCategory(item)
                                    item.mainCategoryNames = mainCategory.names;
                                    acc[mainCategory.id] = acc[mainCategory.id] || [];
                                    acc[mainCategory.id].push(item)
                                }
                                return acc;
                            }, Object.create(null))
                        } else {
                            return [];
                        }
                    } else {
                        var product = {};
                        if (sortedObjByCategories && product.product && ((product.product.family && product.product.family.categories && product.product.family.categories.length) || product.isPseudo || product.type === SP_SERVICES.CART_LINE_TYPES.COUPON)) {
                            var mainCategory = getProductMainCategory(product)
                            sortedObjByCategories[mainCategory.id] = sortedObjByCategories[mainCategory.id] || [];
                            product.mainCategoryNames = mainCategory.names;
                            sortedObjByCategories[mainCategory.id].push(product)
                        }
                    }

                    return sortedObjByCategories;
                }

                function sortByTree(sortedObjByCategories) {
                    var categories = config.tree.categories || [];
                    var sortedByCategories = [];
                    var sortedKeys = Object.keys(sortedObjByCategories);
                    categories.forEach(function(category) {
                        if(sortedObjByCategories[category.id] && sortedObjByCategories[category.id].length) {
                            sortedByCategories.push(sortedObjByCategories[category.id])
                            sortedKeys.splice(sortedKeys.indexOf(category.id.toString()), 1);
                        }
                    })
                    if(sortedKeys && sortedKeys.length) {
                        sortedKeys.forEach(function (pseudoCategoryKey) {
                            sortedByCategories.push(sortedObjByCategories[pseudoCategoryKey])
                        })
                    }
                    return sortedByCategories;
                }

                function filterCartLineRemoved(sortedCartByCategories , line) {
                    sortedCartByCategories = angular.forEach(sortedCartByCategories, function (category, key) {
                        var indexOfDelItem = category.findIndex(function (item) {
                            if (item.product && line.lines && line.lines.length) {
                                return item.id === line.lines[0].id
                            }
                            return -1;
                        })
                        if(indexOfDelItem > -1) {
                            category.splice(indexOfDelItem, 1)
                        }
                        sortedCartByCategories[key] = category;
                        return category;
                    })
                    return sortByTree(sortedCartByCategories)
                }

                function getProductMainCategory(product) {
                    var mainCategory = {};
                    if(product.type === SP_SERVICES.CART_LINE_TYPES.COUPON) {
                        mainCategory = {id: 'coupon'}
                    } else {
                        mainCategory = product.isPseudo ? product.product && product.product.family && product.product.family.categories && product.product.family.categories[0] ? product.product.family.categories[0] :  {id: 'unknown'} : product.product.family.categories[0];
                    }
                    return mainCategory;
                }

                function checkIsCaseProduct (line) {
                    return line.product && line.product.branch && line.product.branch.case && line.product.branch.case.price && (line.isCase || line.product.isCaseMode);
                }

                /**
                 * Show delivery within days warning when adding non delivery within days products
                 * @private
                 *
                 * @param {Object} event
                 * @param {Object} data
                 * @param {Object} data.value
                 * @param {Object} data.oldValue
                 *
                 * @returns {Promise|void}
                 */
                function _showDeliveryWithinDaysWarning(event, data) {
                    // when the cart included a non delivery within days product before, do nothing
                    if (data && data.oldValue && data.oldValue.none && !data.oldValue[PRODUCT_TAG_TYPES.DELIVERY_WITHIN_DAYS]) {
                        return;
                    }

                    return DeliveryWithinDaysWarningDialog.show();
                }

                function addCoupon(couponCode, source) {
                    return $q.resolve().then(function () {
                        if (!couponCode) return;
    
                        if (_isPersonalCouponCode(couponCode)) {
                            var userLoginData = User.getUserLoginData();
                            if (!userLoginData || !userLoginData.uid) {
                                var retStateParams = JSON.stringify($state.params);
                                return SpDialogUrlManager.backClose().then(function () {
                                    return util.goToLoginDialog(null, null, null, retStateParams).then(function () {
                                        return _connectCouponToUser(couponCode);
                                    });
                                });
                            }
                            return _connectCouponToUser(couponCode);
                        }
                        return _getCouponProduct(couponCode);
                    }).then(function (products) {
                        if (products && products.length) {
                            var line = {product: products[0], type: SP_SERVICES.CART_LINE_TYPES.COUPON, isCouponPriceZero: $rootScope.config.retailer.isCouponPriceZero};
                            var isLimitPassed = cart.checkHeavyLimitBeforeAddLine(line);
                            if (isLimitPassed === false) {
                                if (cart.lines && Object.keys(cart.lines).length === 0 &&
                                    $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                                    cart.addDeliveryFeeLineIfNeeded();
                                }
                                cart.addLine(line, null, {source: source || 'Coupon'});
                            }
                        }
                    }).catch(function () {
                        return _couponNotValidDialog();
                    });
                }

				function _isPersonalCouponCode(couponCode) {
					var couponCodeParts;
					if (couponCode.indexOf('-') > -1) {
						couponCodeParts = couponCode.split('-');
					} else {
						couponCodeParts = couponCode.split('_');
					}
					return couponCodeParts.length > 1 && couponCodeParts[0].length > 0 && couponCodeParts[1].length === 8;
				}

                function _connectCouponToUser(couponCode) {
                    return Api.request({
                        method: 'PATCH',
                        url: '/v2/retailers/:rid/branches/:bid/users/:uid/coupons/connectCouponToUser',
                        data: {uniqueCode: couponCode}
                    }).then(function (products) {
                        if (!products || !products.length) {
                            return _couponNotValidDialog();
                        }
                        return products;
                    });
                }
    
                function _getCouponProduct(couponCode) {
                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/branches/:bid/products',
                        params: {
                            from: 0,
                            size: 1,
                            filters: {
                                must: {
                                    term: {
                                        isCoupon: true,
                                        'localBarcode.raw': couponCode
                                    }
                                }
                            }
                        }
                    }, {
                        fireAndForgot: true
                    }).then(function (result) {
                        if (!result || !result.products.length || !result.products[0].isCoupon || 
                            !result.products[0].branch || !result.products[0].branch.isActive || 
                            !result.products[0].branch.specials || !result.products[0].branch.specials.length ||
                            (result.products[0].localBarcode !== couponCode && result.products[0].barcode !== couponCode) || 
                            result.products[0].isGeneralCouponBlocked === true) {
                            return _couponNotValidDialog();
                        }
    
                        return result.products;
                    });
                }

                function _couponNotValidDialog() {
                    return mDesign.alert('{{(\'Invalid Coupon Code\' | translate)}}');
                }

                /**
                 * @param {number} deliveryTimeTypeId // delivery.time.typeId
                 * @param {string} fromTime // delivery.time.newFrom
                 * @param {string} toTime // delivery.time.newTo
                 * @param {number} daysRange
                 * @return {Date}
                 */
                function _calculateSaleDate(deliveryTimeTypeId, fromTime, toTime, daysRange) {
                  var MINUTE_IN_MS = 1000 * 60;
                  var DAY_IN_MS = MINUTE_IN_MS * 60 * 24;

                  /** @type {Date} */
                  var saleDate;

                  if (deliveryTimeTypeId === DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_DAYS) {
                    saleDate = new Date();

                    saleDate.setTime(
                      saleDate.getTime() +
                        daysRange * DAY_IN_MS -
                        saleDate.getTimezoneOffset() * MINUTE_IN_MS
                    );
                  } else if (fromTime && toTime) {
                    var date = new Date(fromTime),
                      time = new Date(toTime);

                    saleDate = new Date(
                      date.getUTCFullYear(),
                      date.getUTCMonth(),
                      date.getUTCDate(),
                      time.getUTCHours(),
                      time.getUTCMinutes(),
                      time.getUTCSeconds()
                    );

                    saleDate.setMinutes(saleDate.getMinutes() - saleDate.getTimezoneOffset());
                  }

                  return saleDate;
                }

            /**
             * @param {Object} timeTravel // Cart.timeTravel used for collection time POS
             * @param {number} giftsForView // Cart.total.giftsForView
             * @param {number} clubsGiftsForView // Cart.total.clubsGiftsForView
             * @returns {boolean}
             */
            function _checkIsSkipDialogSpecial(timeTravel, giftsForView, clubsGiftsForView) {
              var skipDialogSpecial = false;

              if (!timeTravel && !giftsForView && !clubsGiftsForView) {
                skipDialogSpecial = true;
              }

              // if the specials will be calculated by the checkout time at the pos, there is no reason to check invalid sales
              if (util.checkIsCalculateCheckoutTime()) {
                skipDialogSpecial = true;
              }

              return skipDialogSpecial;
            }

                /**
                 * Get irrelevant cart lines
                 * @param {*[]} cartLines
                 * @param {string} fromTime
                 * @param {boolean} skipDialogSpecial
                 * @returns {{irrelevantSalesLines: *, lineTypes: Record<number, boolean> }}
                 */
                function _getIrrelevantSales(cartLines, fromTime, skipDialogSpecial) {
                  var irrelevantSalesLines = [],
                    lineTypes = {},
                    deliveryDate = new Date(fromTime);
                  deliveryDate.setUTCHours(0, 0, 0);

                  angular.forEach(cartLines || [], function (line) {
                    var isExpireSpecialCoupon = util.isIrrelevantSale(line, skipDialogSpecial);
                    var isNotSellOnDate = !util.isSellOnDate(line, deliveryDate);

                    if (isExpireSpecialCoupon || isNotSellOnDate) {
                      irrelevantSalesLines.push(line);
                      lineTypes[line.type] = true;
                    }
                  });

                  return { irrelevantSalesLines: irrelevantSalesLines, lineTypes: lineTypes };
                }

                /**
                 * @typedef {Object} ExpiredInfoItem
                 * @property {any[]} items
                 * @property {string} mainTitle
                 * @property {string} subTitle
                 * @property {string} itemTemplateUrl
                 * @property {string=} className
                 */

                /**
                 * @typedef {Object} ExpiredInfo
                 * @property {ExpiredInfoItem} sellDates
                 * @property {ExpiredInfoItem} special
                 * @property {ExpiredInfoItem} coupon
                 * @property {ExpiredInfoItem} outOfStock
                 */

                /**
                 * @param {CartLine[]} irrelevantSalesLines
                 * @param {CartLine[]} cartLines 
                 * @param {string} fromTime 
                 * @return {ExpiredInfo}
                 */
                function _filterIrrelevantSpecialsAndCoupons(
                  irrelevantSalesLines,
                  cartLines,
                  fromTime
                ) {
                  var irrelevantSpecialIndex = {};
                  var deliveryDate = new Date(fromTime);
                  deliveryDate.setUTCHours(0, 0, 0);

                  /** @type {ExpiredInfo} */
                  var expiredInfo = {
                    outOfStock: {
                      type: 'outOfStock',
                      items: [],
                      mainTitle: "",
                      subTitle: "",
                      itemTemplateUrl: "views/templates/sell-date-dialog-content.html"
                    },
                    sellDates: {
                      type: 'sellDates',
                      items: [],
                      mainTitle: "",
                      subTitle: "",
                      itemTemplateUrl: "views/templates/sell-date-dialog-content.html",
                      className: "sell-date-dialog",
                    },
                    special: {
                      type: 'special',
                      items: [],
                      mainTitle: "",
                      subTitle: "",
                      itemTemplateUrl: "views/templates/single-special-content.html"
                    },
                    coupon: {
                      type: 'coupon',
                      items: [],
                      mainTitle: "",
                      subTitle: "",
                      itemTemplateUrl:
                        "template/views/cart-summary/partials/carousel-coupon-item/index.html",
                    },
                  };

                  angular.forEach(irrelevantSalesLines, function (line) {
                    if (line.product) {
                      // for checkout time, specials are already removed -> need to check again
                      // ECOM-10777: need check before checking specials since some special expire but they already have new specials -> check those specials will not match with original gift
                      // TODO check for coupon later. Temporary assume coupon do not expire on checkout time
                      if (
                        util.checkIsCalculateCheckoutTime() &&
                        util.isIrrelevantSale(line, true)
                      ) {
                        expiredInfo.special.items.push(line);
                      }

                      if (line.product.branch && line.product.branch.specials) {
                        var specials = line.product.branch.specials;

                        angular.forEach(line.gifts, function (gift) {
                          var endDate = new Date(gift.promotion.endDate);
                          var giftId = gift.promotion.id;

                          if (endDate < deliveryDate && !irrelevantSpecialIndex[giftId]) {
                            irrelevantSpecialIndex[giftId] = true;

                            if (specials) {
                              // This gift is a special, we can extract its information in the specials
                              var special = _findSepcial(specials, giftId);

                              if (!special) {
                                // In case, a product is applied both special and coupon, because the coupon does not exist in special fields, so we treat it as a coupon
                                expiredInfo.coupon.items.push(gift);
                              } else {
                                special.frontendImageUrl =
                                  line.product && line.product.image && line.product.image.url;
                                expiredInfo.special.items.push(special);
                              }
                            }
                            // Temporarily, treat this gift as a coupon
                            else if (line.type === SP_SERVICES.CART_LINE_TYPES.COUPON) {
                              //== only if Cart Line type is Coupon
                              expiredInfo.coupon.items.push(gift);
                            }
                          }
                        });
                      }

                      if (!util.isSellOnDate(line, deliveryDate)) {
                        expiredInfo.sellDates.items.push(line);
                      }
                    }
                  });

                  expiredInfo.outOfStock.items = _getOutOfStockLines(cartLines);

                  return expiredInfo;
                }

                /**
                 * @param {*[]} specials
                 * @param {number} specialId
                 * @returns {*}
                 */
                function _findSepcial(specials, specialId) {
                  var result = specials.find(function (special) {
                    return special.id === specialId;
                  });
                  return result;
                }

                /**
                 * @param {ExpiredInfo} expiredInfo
                 * @param {*[]} irrelevantSalesLines
                 * @returns
                 */
                function _checkShouldSkipIrrelevantSalesDialog(
                  expiredInfo,
                  irrelevantSalesLines
                ) {
                  var isNewPromotionDesignEnabled =
                    $rootScope.config.retailer.settings.isNewPromotionDesignEnabled === "true";

                  var isEmptyExpiredItem =
                    !expiredInfo.coupon.items.length &&
                    !expiredInfo.special.items.length &&
                    !expiredInfo.sellDates.items.length;

                var isEmptyOutOfStock = expiredInfo.outOfStock.items.length === 0;

                  var isEmptyIrrelevantsSales =
                    (isNewPromotionDesignEnabled && isEmptyExpiredItem) ||
                    !irrelevantSalesLines.length;

                  return isEmptyIrrelevantsSales && isEmptyOutOfStock;
                }

                /**
                 * @param {ExpiredInfo} expiredInfo 
                 * @param {string} orderId
                 * @returns {Promise<{specials: ExpiredSpecialLine[], coupons: Coupon[]}>}
                 */
                function _getMissingExpiredInfo(expiredInfo, orderId) {
                  return $q
                    .all([
                      _getExpiredSpecials(expiredInfo.special, orderId),
                      _getExpiredCoupons(expiredInfo.coupon),
                    ])
                    .then(function (result) {
                      return {
                        specials: result[0],
                        coupons: result[1],
                      };
                    });
                }

                /**
                 * @param {ExpiredInfoItem} expiredInfoSpecial 
                 * @returns {Promise<Special[]>}
                 */
                function _getExpiredSpecials(expiredInfoSpecial, orderId) {
                if (
                    !util.checkIsCalculateCheckoutTime() ||
                    !expiredInfoSpecial ||
                    !expiredInfoSpecial.items ||
                    !expiredInfoSpecial.items.length ||
                    !orderId
                ) {
                    return $q.resolve(null);
                }

                var productIds = expiredInfoSpecial.items.map(function (eis) {
                    return eis.product.id;
                });

                if (!productIds || !productIds.length) {
                    return $q.resolve(null);
                }

                return Api.request({
                    method: "GET",
                    url: "/v2/retailers/:rid/branches/:bid/orders/" + orderId + "/specials",
                    params: {
                    productIds: productIds.join(","),
                    },
                });
                }

                /**
                 * old promotion desgin check on lines, new promotion check on specials
                 *
                 * In case, we have some expired coupons
                 *
                 * @param {ExpiredInfoItem} expiredInfoCoupon
                 * @return {Promise<any[] | null>}
                 */
                function _getExpiredCoupons(expiredInfoCoupon) {
                  if (
                    !expiredInfoCoupon ||
                    !expiredInfoCoupon.items ||
                    !expiredInfoCoupon.items.length
                  ) {
                    return $q.resolve(null);
                  }

                  var couponIds = expiredInfoCoupon.items.map(function (c) {
                    return c.promotion.id;
                  });

                  return _getCoupons(couponIds).then(function (couponDetails) {
                    return couponDetails;
                  });
                }

                /**
                 * @private
                 * @param {number[]} couponIds coupon id list
                 * @returns {Promise<any[]>}
                 */
                function _getCoupons(couponIds) {
                  return Api.request({
                    method: "GET",
                    url:
                      "/v2/retailers/:rid/branches/:bid/users/:uid/coupons/" + couponIds.join(","),
                    params: {
                      extended: true,
                      countonly: false
                    },
                  });
                }

                /**
                 * @param {CartLine[]} cartLines
                 * @returns {CartLine[]}
                 */
                function _getOutOfStockLines(cartLines) {
                    var outOfStockLines = []

                  angular.forEach(cartLines, function (line) {
                    line.isProductOutOfStock = util.isProductOutOfStock(line);
                    line.isNeedToShowOutOfStockLabel = util.isNeedToShowOutOfStockLabel(line);

                    var isExistLine = outOfStockLines.find(function (l) {
                      return l.id == line.id;
                    });

                    if (
                      (line.isProductOutOfStock || line.isCouponActive === false) &&
                      !isExistLine
                    ) {
                      outOfStockLines.push(line);
                    }
                  });

                  return outOfStockLines
                }

                /**
                 * @typedef {Object} SelectedTimeSlot
                 * @property {number} id
                 * @property {number} type
                 * @property {number} dayInWeek
                 * @property {number} day
                 * @property {string} from
                 * @property {string} to
                 * @property {string} fromDisplay
                 * @property {string} hours
                 * @property {string} minutes
                 * @property {string} timeRange
                 * @property {string} daysRange
                 * @property {boolean} isActive
                 * @property {number} deliveryTimeProductId
                 * @property {number} deliveryProductId
                 * @property {number} deliveryTimePrice
                 * @property {number} typeId
                 * @property {string} startDate
                 * @property {string} endDate
                 * @property {Object} branchDeliveryReservation
                 * @property {number} pickingBranchId
                 * @property {number} branchAreaId
                 * @property {number} retailerBranchId
                 * @property {string} newFrom
                 * @property {string} newTo
                 * @property {boolean} isFull
                 * @property {boolean} isInactiveRecurringSlot
                 * @property {Object} deliveryProduct
                 */

                /**
                 * @typedef {Object} IrreSalesDataType
                 * @property {Record<number, boolean>} lineTypes
                 * @property {ExpiredInfo} expiredInfo
                 * @property {boolean} skipDialogSpecial
                 * @property {any[]} irrelevantSalesLines
                 */

                /**
                 * @param {SelectedTimeSlot} selectedTime 
                 * @param {Object} curOrder
                 * @param {string} curOrder.id
                 * @param {string} curOrder.timePlaced
                 * @return {Promise<IrreSalesDataType | null>} // return null if dont need to show popup
                 */
                function checkIrrelevantSales(selectedTime, curOrder) {
                  curOrder = curOrder || {};

                  if (!selectedTime) {
                    $q.resolve(null);
                  }

                  var saleDate = _calculateSaleDate(
                    selectedTime.typeId,
                    selectedTime.newFrom,
                    selectedTime.newTo,
                    selectedTime.daysRange
                  );

                  var skipDialogSpecial = _checkIsSkipDialogSpecial(
                    cart.timeTravel,
                    cart.total.giftsForView,
                    cart.total.clubsGiftsForView
                  );

                  var saveCartPromise = $q.resolve();

                  var shouldTimeTravelCollectionTime = !!(!skipDialogSpecial && saleDate);
                  var shouldTimeTravelCheckoutTime = !!(skipDialogSpecial && curOrder.timePlaced)
    
                  if ( shouldTimeTravelCollectionTime || shouldTimeTravelCheckoutTime) {
                    cart.setTimeTravel({
                      date: skipDialogSpecial ? curOrder.timePlaced : saleDate,
                      override: false,
                    });
                    saveCartPromise = cart.save();
                  }

                  return saveCartPromise.then(function () {
                    var irrelevantSalesObj = _getIrrelevantSales(
                      cart.lines,
                      selectedTime.newFrom,
                      skipDialogSpecial
                    );

                    var irrelevantSales = irrelevantSalesObj.irrelevantSalesLines;
                    var lineTypes = irrelevantSalesObj.lineTypes;

                    /** @type {ExpireInfo} */
                    var expiredInfo = _filterIrrelevantSpecialsAndCoupons(
                      irrelevantSales,
                      cart.lines,
                      selectedTime.newFrom
                    );

                    return _getMissingExpiredInfo(expiredInfo, curOrder.id).then(function (result) {
                      var couponDetails = result.coupons;
                      var cartLineSpecials = result.specials;

                      if (couponDetails) {
                        expiredInfo.coupon.items = couponDetails;
                      }

                      // only checkout time does not have specials since they already expired
                      if (util.checkIsCalculateCheckoutTime()) {
                        if (cartLineSpecials && cartLineSpecials.length > 0) {
                          expiredInfo.special.items = cartLineSpecials.map(function (cartLine) {
                            return cartLine.special;
                          });
                        }

                        // ECOM-10778: fix case user update once, then click update again -> special still expired but CartLineSpecial is empty -> not show empty section
                        else {
                          expiredInfo.special.items = [];
                        }
                      }

                      if (
                        _checkShouldSkipIrrelevantSalesDialog(
                          expiredInfo,
                          irrelevantSales,
                          cart.outOfStockLines
                        )
                      ) {
                        return $q.resolve(null);
                      }

                      return $q.resolve({
                        lineTypes: lineTypes,
                        expiredInfo: expiredInfo,
                        irrelevantSalesLines: irrelevantSales,
                        skipDialogSpecial: skipDialogSpecial,
                        outOfStockLines: expiredInfo.outOfStock.items,
                      });
                    });
                  });
                }
                // END TODO move to sp-service/cart

                /**
                 * @param {IrreSalesDataType} irreSalesData 
                 */
                function setIrreSalesData(irreSalesData){
                    cart.irreSalesData = irreSalesData;
                }                

                $rootScope.$on('cart.otherCart', _onOtherCartEvent);
                $rootScope.$on('cart.crossProductTagTypes.change', _showDeliveryWithinDaysWarning);
                $rootScope.$on('cart.lines.quantityLimit', _showQuantityLimitDialog);
                $rootScope.$on('cart.lines.deliveryItemsLimit', _handleDeliveryItemsLimit);
    
                $rootScope.$on('cart.lines.add.click', function(event, data) {
                    angular.forEach(data.lines, function (line) {
                        if(line && line.product && line.product.productId) {
                            DataLayer.push(DataLayer.EVENTS.ADD_TO_CART, {products: [line.product], data: {quantity: 1}});
                        }
                    });
                });
    
                $rootScope.$on('cart.lines.quantityChanged.click', function(event, data) {
                    angular.forEach(data.lines, function (line) {
                        if(line && line.product && line.product.productId) {
                            if (data.direction === 'increase') {
                                DataLayer.push(DataLayer.EVENTS.ADD_TO_CART, {products: [line.product], data: {line: line, quantity: 1}});
                            } 
                            if (data.direction === 'decrease') {
                                DataLayer.push(DataLayer.EVENTS.REMOVE_FROM_CART, {products: [line.product], data: {line: line, quantity: 1}});
                            }
                            // manual quantity input
                            if (!data.direction) {
                                var count = Math.abs(line.oldQuantity - line.quantity);
                                var event = line.quantity > line.oldQuantity ? DataLayer.EVENTS.ADD_TO_CART : DataLayer.EVENTS.REMOVE_FROM_CART
                                DataLayer.push(event, {products: [line.product], data: {line: line, quantity: count}});
                            }
                        }
                    });
                });

                $rootScope.$on('login', function () {
                    cart.save();
                });

                $rootScope.$on('logout', function () {
                    cart.clear(true);
                });

                return cart;
            }])
        .run(['$rootScope', 'Cart', function ($rootScope, cart) {
            $rootScope.cart = cart;
        }]);
})(angular);
