(function(angular) {
	angular.module('mobilezuz').service('HeLanguage', function() {
		this.id = 1;
		this.culture = 'he';
		this.name = 'עברית';
		this.direction = 'rtl';
		this.translations = {
			'en': 'he',
			'my': 'שלי',
			'me': 'אני',
			'in {name} dep.': 'במחלקת {name}',
			'in specials': 'במבצעים',
			'special products': 'מוצרים במבצע',
			'shopping lists': 'רשימות קניות',
			'shopping list': 'רשימת קניות',
			'shop': 'קנו',
			'shop ': 'מוצרים',
			'by departments': 'עפ\"י מחלקות',
			'email': 'דואר אלקטרוני',
			'email_error': 'אנא הקלידו כתובת דואר אלקטרוני תקינה',
			'password': 'סיסמה',
			'log in': 'הכנס',
			'log in with facebook': 'הכנסו עם פייסבוק',
			'password recovery message was sent to your email': 'הודעת שיחזור סיסמה נשלחה לדואר האלקטרוני שלכם',
			'home': 'בית',
			'departments': 'מחלקות',
			'scanner': 'סריקה',
			'all products': 'כל המוצרים',
			'orders': 'ק. אחרונות',
			'sales': 'מבצעים',
			'specials': 'מבצעים',
			'sale': 'מבצע',
			'edit': 'עריכה',
			'search': 'חיפוש',
			'total': 'סה\"כ',
			'checkout': 'לקופה',
			'forgot your password?': 'שכחתם סיסמה?',
			'my shopping lists': 'רשימות הקניות',
			'add list': 'הוספת רשימה',
			'name': 'שם',
			'creationdate': 'זמן יצירה',
			'itemscount': 'מספר מוצרים',
			'shopping list name': 'שם הרשימה',
			'sorting by': 'מיון לפי',
			'add': 'הוסיפו',
			'add_product': 'הוסיפו',
			'close': 'סגור',
			'clean cart': 'נקה סל',
			'cart': 'עגלת קניות',
			'recipient\'s name': 'שם הנמען',
			'email address': 'כתובת דואר אלקטרוני',
			'the offer is valid from': 'המבצע תקף מ',
			'until': 'עד',
			'terms and conditions': 'תנאי שימוש',
			'this purchase order is an offer by the company identified on the face of this purchase order (the \"buyer\") for the purchase of the goods (the \"goods or services (the “services”) specified\")': 'This purchase order is an offer by the company identified on the face of this purchase order (the \"Buyer\") for the purchase of the goods (the \"Goods or services (the “Services”) specified\")_h',
			'pay with': 'שלמו באמצעות',
			'card number': 'מספר כרטיס',
			'delivery address': 'כתובת משלוח',
			'add card number': 'הוספת מספר כרטיס',
			'country': 'מדינה',
			'zip code': 'מיקוד',
			'zip': 'מיקוד',
			'state': 'מדינה',
			'zipcode': 'מיקוד',
			'entrance': 'כניסה',
			'city': 'עיר',
			'street': 'רחוב',
			'house number': 'מספר בית',
			'floor': 'קומה',
			'apartment': 'דירה',
			'available days': 'ימים זמינים',
			'entry: spautocompleterequirematch error': 'הזן אות (א-י)',
			'house_entry_none': 'ללא',
			'house_entry_a': 'א',
			'house_entry_b': 'ב',
			'house_entry_c': 'ג',
			'house_entry_d': 'ד',
			'house_entry_e': 'ה',
			'house_entry_f': 'ו',
			'house_entry_g': 'ז',
			'house_entry_h': 'ח',
			'house_entry_i': 'ט',
			'house_entry_j': 'י',
			'personal info': 'מידע אישי',
			'first name': 'שם פרטי',
			'last name': 'שם משפחה',
			'full name': 'שם מלא',
			'login with a one time password': 'התחברות עם קוד חד פעמי',
			'continue with sms': 'המשך באמצעות סמס',
			'continue with email': 'המשך באמצעות אימייל',
			'please enter your phone number': 'הקלד את מספר הטלפון שלך',
			'please enter your email': 'הקלד את כתובת המייל שלך',
			'please enter phone number or email': 'אנא הזינו מספר טלפון או כתובת דוא"ל',
			'please enter a valid phone number or email': 'אנא הזינו מספר טלפון או דוא"ל חוקיים',
			'we will send you one time password': 'קוד חד פעמי ישלח למספר הנייד שלך',
			'send password': 'שלח קוד',
			'we\'ve sent a password to the mobile number': 'שלחנו SMS עם קוד חד פעמי למספר',
			'we\'ve sent a password to your email address at': 'שלחנו קוד חד פעמי למייל',
			'not your phone number?': 'לא המספר שלך?',
			'not your email?': 'לא המייל שלך? ',
			'please complete verification': 'הקלד קוד אימות',
			'sorry, the code is invalid or expired.': 'הקוד לא תקין או פג תוקף',
			'sorry, we cannot proceed using this phone number': 'מצטערים, לא הייתה אפשרות להמשיך עם מספר זה',
			'sorry, we cannot proceed using this email': 'מצטערים, לא הייתה אפשרות להמשיך עם מייל זה',
			'please try again or contact customer service for assistance: xxx-xxxxxx': 'אנא נסה שוב או צור קשר עם שירות הלקוחות במספר:XXX-XXXXXX',
			'sorry, you have exceeded the number of attempts allowed for the current user.': 'חרגת ממספר הניסיונות המותר עבור משתמש זה',
			'please try again later or contact customer service for assistance: xxx-xxxxxx': 'אנא נסה שנית מאוחר יותר או צור קשר עם שירות לקוחות לעזרה:XXX-XXXXXX',
			'try again': 'נסה שוב',
			'back to login with email and password': 'התחבר באמצעות מייל וסיסמא',
			'delivery time': 'מועד משלוח',
			'day': 'יום',
			'time': 'זמן',
			'notes': 'הערות',
			'add instructions': 'הוספת הוראות',
			'summary': 'סיכום',
			'products': 'מוצרים',
			'delivery charge': 'עלות משלוח',
			'received_items': 'התקבל',
			'ok': 'אישור',
			'cancel': 'ביטול',
			'recent': 'קניות',
			'purchases': 'אחרונות',
			'please enter street': 'נא להזין רחוב',
			'please enter email': 'אנא הזינו כתובת דוא"ל',
			'please enter password': 'נא להזין סיסמה',
			'please enter first name': 'נא להזין שם פרטי',
			'please enter last name': 'נא להזין שם משפחה',
			'please enter your email address': 'נא להזין דואר אלקטרוני',
			'please enter a text': 'נא להזין טקסט',
			'the shopping list was successfully added to the cart': 'רשימת הקניות נוספה בהצלחה אל העגלה',
			'callcustomer': 'התקשרו אליי',
			'creditcard': 'כרטיס אשראי',
			'storecredit': 'חשבון לקוח',
			'between': 'בין השעות',
			'sunday': 'יום ראשון',
			'monday': 'יום שני',
			'tuesday': 'יום שלישי',
			'wednesday': 'יום רביעי',
			'thursday': 'יום חמישי',
			'friday': 'יום שישי',
			'saturday': 'יום שבת',
			'today': 'היום',
			'your order has been received': 'הזמנתכם התקבלה בהצלחה',
			//'your order is in process and will be sent to you in the next hours. order id': 'הזמנתך בתהליך ותשלח אליך בשעות הקרובות. מזהה הזמנה',
			'register': 'הרשמה',
			'creation date': 'תאריך יצירה',
			'items count': 'כמות פריטים',
			'shop by': 'קניה לפי',
			'my orders history': 'היסטורית ההזמנות שלי',
			'orders history': 'היסטורית הזמנות',
			'previous orders': 'הזמנות קודמות',
			'account': 'פרטי חשבון',
			'retailer details': 'פרטי החנות',
			'more links': 'קישורים נוספים',
			'link': 'קישור',
			'change language': 'החלפת שפה',
			'languages': 'שפות',
			'help': 'עזרה',
			'settings': 'הגדרות',
			'personal details': 'פרטים אישיים',
			'birth date': 'תאריך לידה',
			'birthday': 'תאריך לידה',
			'allow send promotions': 'אני מאשר/ת קבלת חומרים פרסומיים',
			'gender': 'מין',
			'male': 'זכר',
			'female': 'נקבה',
			'prefer not to say': 'אעדיף שלא לציין',
			'save': 'שמור',
			'print': 'הדפס',
			'addresses': 'כתובת',
			'logout': 'התנתקות',
			'info': 'מידע',
			'policy': 'מדיניות',
			'contact details': 'פרטי התקשרות',
			'continue without membership': 'המשיכו ללא מועדון',
			'not a member yet? join now!': 'עדיין לא חברי מועדון? הצטרפו וחסכו!',
			'my cart': 'סל הקניות',
			'quantity': 'כמות',
			'price': 'מחיר',
			'items': 'פריטים',
			'check out': 'סיום קנייה',
			'enter cvv': 'הכנס CVV',
			'add card': 'הוספת כרטיס',
			'remove card': 'הסר/י את הכרטיס',
			'thank you for your purchase': 'תודה שקניתם אצלנו',
			'loyalty club member': 'חברי מועדון',
			'in this purchase': 'כבר בקניה זו',
			'save_1': 'חסכו',
			'continue without loyalty': 'המשיכו ללא מועדון',
			'the delivery time is no longer available': 'חלון זמנים זה לא זמין יותר',
			'finish': 'סיים',
			'gift total': 'שווי הזיכוי',
			'purchase type': 'תנאי המבצע',
			'end date': 'תאריך סיום',
			'gift type': 'סוג מבצע',
			'confirm password': 'אימות סיסמה',
			'please let me know about promotions': 'הנני מאשר/ת קבלת עדכונים והטבות בדוא"ל ובמסרונים (ניתן להפסיק את קבלת העדכונים בכל עת)',
			'more': 'עוד',
			'from my list': 'מהרשימה',
			'import to cart': 'הוספה לסל',
			'import products from cart?': 'ייבוא מוצרים מסל קניות ?',
			'nutrition facts': 'ערכים תזונתיים',
			'details': 'פרטים',
			'add to cart': 'הוספה לסל',
			'view similar items': 'ראו מוצרים דומים',
			'settings was successfuly changed!': 'הפרטים שונו בהצלחה',
			'sorry. we couldn\'t find this product': 'המוצר לא נמצא',
			'two passwords must match': 'הסיסמאות אינן תואמות',
			'sign up completed': 'נרשמתם בהצלחה',
			'product not found': 'מוצר לא נמצא',
			'please enter your password': 'נא להזין סיסמה',
			'my shopping list': 'הרשימה שלי',
			'house no.': 'מספר בית',
			'from my cart': 'מסל הקניות',
			'est.': 'כ-',
			'case': 'מארז',
			'in store location': 'מיקום בחנות',
			'units': 'יחידות',
			'unit': 'יח\'',
			'new shopping list was created': 'רשימת קניות חדשה נוצרה',
			'please enter list name': 'יש לציין שם לרשימה',
			'there are no products in cart': 'אין מוצרים ברשימה',
			'single': 'יחיד',
			'pack': 'חבילה',
			'pck': 'חבילה',
			'cell phone': 'טלפון נייד',
			'home phone': 'טלפון בבית',
			'work phone': 'טלפון בעבודה',
			'error': 'שגיאה',
			'are you sure?': 'אתם בטוחים שברצונכם לנקות את הסל?',
			'phones': 'טלפון',
			'address': 'כתובת',
			'to update details, log in to the yellow app or call *9974 customer service': 'כדי לעדכן פרטים, יש להיכנס לאפליקציית yellow או באמצעות שירות הלקוחות 9974*',
			'opening hours': 'שעות פתיחה',
			'user not found': 'שם משתמש או סיסמה שגוים',
			'the email address doesn\'t have an associated account. please try again': 'כתובת הדואר האלקטרוני אינה משוייכת ללקוח רשום. אנא נסו שנית',
			'please enter your email address to proceed': 'יש להזין את כתובת הדואר האלקטרוני שלכם על מנת להמשיך',
			'loyalty user not found': 'פרטי התחברות למועדון שגויים',
			'branches': 'סניפים',
			'login': 'כניסת משתמש',
			'status': 'מצב',
			'time placed': 'זמן הגעה',
			'i agree to receive promotions': 'אני מאשר/ת לקבל עדכונים',
			'add to list': 'הוספה לרשימה',
			'unauthorized action': 'פעולה לא מורשית',
			'first name is required': 'חובה למלא שם פרטי',
			'last name is required': 'חובה למלא שם משפחה',
			'email is required': 'חובה למלא דואר אלקטרוני',
			'email is not valid': 'דואר אלקטרוני לא חוקי',
			'password is required': 'חובה למלא סיסמה',
			'phone number is required': 'חובה למלא מס\' טלפון',
			'pickup location is required': 'חובה לבחור את מקום האיסוף',
			'pickup day is required': 'חובה לבחור יום איסוף',
			'pickup hour is required': 'חובה לבחור שעת איסוף',
			'payment method is required': 'חובה לבחור את סוג התשלום',
			'credit card is required': 'חובה להוסיף כרטיס אשראי',
			'delivery day is required': 'חובה לבחור יום למשלוח',
			'password should be longer than 6 characters': 'הסיסמה חייבת להיות ארוכה משישה תווים',
			'new order': 'הזמנה חדשה',
			'charged': 'חויבה',
			'delivered': 'אצלך',
			'on the way': 'בדרך',
			'shipment tracking': 'מעקב משלוח',
			'order number': 'הזמנה מס’',
			'package number': 'חבילה מס’ ',
			'tracking number': 'מספר מעקב ',
			'your order contains': 'ההזמנה שלך מכילה ',
			'packages': 'חבילות',
			'play': 'נגן',
			'cancelled': 'בוטלה',
			'on hold': 'מושהית',
			'processing': 'בתהליך',
			'waiting for confirmation': 'מחכה לאישור',
			'collected': 'לוקטה',
			'registered': 'נרשמה לקופה',
			'delete': 'הסר',
			'you must login first': 'עליכם להתחבר לחשבון שלכם',
			'not found shop lists': 'לא נמצאה רשימת קניה',
			'product added to shop list': 'מוצר נוסף לרשימת קניה',
			'yes': 'כן',
			'no': 'לא',
			'brand': 'יצרן',
			'new name': 'שם חדש',
			'invalid name': 'שם לא תקין',
			'could not find your location': 'מיקומכם לא נמצא',
			'new list': 'רשימה חדשה',
			'+add to list': '+הוסף לרשימה',
			'your cart is still empty': 'הסל שלכם עדיין ריק',
			'in all departments': 'בכל המחלקות',
			'this is a pick and go order': 'זה הזמנת שגר ושלח',
			'start shopping': 'התחילו קנייה',
			'your order has been collected': 'הזמנתך נאספה בהצלחה',
			'the order was canceled': 'ההזמנה בוטלה',
			'a message has been sent to the number on your order, kindly confirm the replacements and we will complete your order': 'הודעה נשלחה למספר שמופיע בהזמנה, בבקשה אשר את המוצרים החלופיים כדי שנוכל להשלים את ההזמנה',
			'the order is currently being collected by our trained shoppers': 'ההזמנה הודפסה בחנות ונמצאת בטיפול',
			'your order is currently being collected by our trained shoppers': 'ההזמנה הודפסה בחנות ונמצאת כרגע בטיפול',
			'your order has been received but not charged': 'ההזמנה התקבלה בהצלחה במערכת אך עדיין לא חויבה',
			'your credit card has been charged for your order': 'כרטיס האשראי חוייב בסכום הקנייה',
			'your order has been delivered and is on its way to you': 'ההזמנה נשלחה ונמצאת בדרכה אליכם',
			'the order was cancelled for various reasons and is not being handled': 'ההזמנה בוטלה מסיבות שונות ואינה מטופלת',
			'order could not be completed, customer intervention is required': 'לא ניתן להשלים את ההזמנה, דרושה התערבות הלקוח',
			'the order is currently being processed': 'ההזמנה הודפסה בחנות ונמצאת כרגע בטיפול',
			'the items have been collected but not yet registered in the pos': 'המוצרים נאספו אך עדיין לא נרשמו לקופה',
			'the products were collected and scanned, but the transaction was yet to be completed': 'המוצרים נאספו ונסרקו אך עדיין לא שולמו',
			'product already exists': 'המוצר כבר נמצא ברשימה',
			'approve & register': 'מאשר/ת וסיום הרשמה',
			'not approve': 'לא מאשר/ת',
			'{hubname}\'s privacy policy.': 'למדיניות הפרטיות של {hubname}',
			'i agree to receive promotional content & offers from {company_name} and {hubname}': 'אני מעוניין לקבל תוכן פרסומי והטבות מחברת {company_name} בע"מ המפעילה את האתר הקמעונאי ומ{hubname}',
			'i agree to the': 'הנני מאשר/ת כי קראתי את ',
			'terms & conditions': 'תקנון האתר',
			'loyalty Club terms & conditions': 'תקנון המועדון',
			'policy_end': 'ומסכימ/ה לתנאיהם',
			'privacy policy': 'מדיניות הפרטיות',
			'about us': 'אודותינו',
			//'add comment': 'הוספת הערה לפריט',
			'track my order': 'מעקב הזמנה',
			'order id:': 'מספר הזמנה:',
			'no internet connection': 'אין חיבור אינטרנט',
			'please enable internet connection and retry': 'אנא הפעילו את חיבור האינטרנט ונסו שוב',
			'retry': 'נסה שוב',
			'no branch': 'אין סניף',
			'lists': 'רשימות',
			'street is required': 'חובה למלא רחוב',
			'house number is required': 'חובה למלא מספר בית',
			'country is required': 'חובה למלא מדינה',
			'cvv is required': 'חובה למלא cvv',
			'connect': 'התחברו',
			'connect_loyalty': 'התחברו למועדון',
			'not all of the products given where found': 'לא נמצאו כל המוצרים',
			'not all of the given products were found': 'לא נמצאו כל המוצרים',
			'you have some missing products in your cart': 'יש לכם כמה מוצרים חסרים בעגלה',
			'select method': 'בחר צורת תשלום',
			'call me when there is missing item': 'התקשרו אלי כאשר חסר פריט',
			'this email already exist': 'כתובת דוא"ל זו קיימת במערכת',
			'this email already exists': 'כתובת דוא"ל זו קיימת במערכת',
			'unable to create user': 'לא ניתן ליצור משתמש',
			'km': 'ק\"מ',
			'scanning failed: ': 'הסריקה נכשלה:',
			'scan barcode': 'סרקו מוצר',
			'please point your camera at the barcode.': 'מקדו את המצלמה על הברקוד',
			'i confirm that i have read the': 'אני מאשר/ת שקראתי את',
			'we are not able to add the following products to your current order': 'איננו יכולים להוסיף את המוצרים האלו להזמנה שלכם',
			'terms': 'התקנון',
			'payment by': 'תשלום באמצעות',
			'phone': 'טלפון',
			'payment': 'תשלום',
			'shipping address': 'כתובת למשלוח',
			'payment method': 'אמצעי תשלום',
			'call customer': 'התקשרו ללקוח',
			'you save': 'חסכת',
			'supervised': 'בפיקוח',
			'floor /\ apt': 'קומה /\ דירה',
			'open': 'פתח',
			'per lb': 'לק\"ג',
			'lb': 'ק\"ג',
			'to see if we deliver to your area,': 'לבדיקת אזורי משלוח,',
			'click here': 'לחץ כאן',
			'we\'re available in': 'אנחנו מבצעים משלוחים ל',
			'checking delivery areas': 'בדיקת אזורי משלוח',
			'loyalty club': 'מועדון לקוחות',
			'have a club member?': 'חבר מועדון קיים?',
			'join our members club': 'הצטרפו למועדון הלקוחות שלנו',
			'join the club': 'הצטרפות למועדון',
			'member id': 'מספר ת.ז',
			'club id': 'מספר מועדון',
			'connect to your club account': 'התחברו למועדון שלכם',
			'choose': 'בחרו',
			'choose your delivery area': 'בחרו את אזור המשלוח שלכם',
			'delivery area': 'אזור משלוח',
			'enter zip code': 'הזן מיקוד',
			'mobile phone': 'טלפון נייד',
			'personal id': 'מספר ת.ז',
			'loyalty card id': 'מספר כרטיס מועדון',
			'update club details': 'עדכון פרטי מועדון',
			'how do you want to get your shopping?': 'איך תרצו לקבל את הקניה שלכם?',
			'home delivery': 'משלוח',
			'pickup': 'איסוף עצמי',
			'pick and go': 'שגר וסע',
			'pick & go': 'שגר וסע',
			'shipping details': 'פרטי הקניה',
			'mobile': 'סלולרי',
			'next': 'הבא',
			'choose delivery time': 'בחרו זמן למשלוח',
			'shipping method & time': 'אופן משלוח וזמן',
			'localdelivery': 'משלוח מקומי',
			'send the delivery sooner, if possible': 'שילחו מוקדם יותר, אם אפשרי',
			'sooner if possible': 'בהקדם האפשרי',
			'allow substitutions?': 'החלפת מוצרים חסרים',
			'select card': 'בחרו כרטיס',
			'your order will be delivered': 'הזמנתכם תישלח',
			'you saved': 'חסכתם',
			'subtotal': 'סיכום ביניים',
			'tax': 'מע\"מ',
			'estimated': 'מוערך',
			'only': 'רק',
			'contact details:': 'פרטי התקשרות',
			'place order': 'סיום קניה',
			'place order using masterpass': 'סיום קניה באמצעות MasterPass',
			'sending your order...': 'מעביר את הזמנתכם לחנות...',
			'please check your email for order details. thank you!': 'דואר אלקטרוני עם פרטי ההזמנה נשלח אליכם ברגעים אלו',
			'back to home screen': 'חזרה לדף הבית',
			'back to top': 'חזרה לתחילת העמוד',
			'questions? get in touch': 'שאלות? צרו קשר',
			'current location:': 'מיקום נוכחי:',
			'pickup location': 'נקודת איסוף',
			'click to choose pickup location': 'בחרו נקודת איסוף',
			'pickup time': 'זמן איסוף',
			'contact & payment details': 'פרטי התקשרות ותשלום',
			'your order will be ready': 'הזמנתכם תהיה מוכנה',
			'shipping': 'משלוח',
			'delivery': 'משלוח',
			'call me': 'התקשרו אלי',
			'delivery_type_1': 'משלוח',
			'delivery_type_2': 'איסוף',
			'delivery_type_3': 'שגר וסע',
			'delivery_type_5': 'משלוח מהיר',
			'area_type_1': 'משלוח',
			'area_type_2': 'משלוח',
			'area_type_3': 'משלוח',
			'area_type_4': 'משלוח',
			'area_type_5': 'איסוף',
			'sun': 'א',
			'mon': 'ב',
			'tue': 'ג',
			'wed': 'ד',
			'thu': 'ה',
			'fri': 'ו',
			'sat': 'ז',
			'the address you provided is not served at this time. please enter another address or choose pickup': 'אנחנו עדיין לא מבצעים משלוחים לכתובת שהוזנה, בחרו באפשרות האיסוף העצמי או הזינו כתובת אחרת',
			'we\'re  sorry, but the address you provided is not served at this time': 'אנחנו עדיין לא מבצעים משלוחים לכתובת שהוזנה, עמכם הסליחה',
			'change to pickup': 'עברו לאיסוף עצמי',
			'can\'t find a product ?': 'לא מצאתם ?',
			'can\'t find a product?': 'לא מצאתם את המוצר שחיפשתם?',
			'if we are unfamiliar with the product or don\'t have it in stock, we will give you a call.': 'הכניסו את שם המוצר ואם לא נמצא אותו, ניצור איתכם קשר.',
			'product name': 'שם המוצר',
			'select category': 'בחרו קטגוריה',
			'clear cart': 'מחיקת כל המוצרים',
			'menu': 'תפריט',
			'customer id': 'מספר מועדון',
			'congradulations for joining the customers club': 'ברכות על הצטרפותכם למועדון הלקוחות שלנו',
			'your club member number': 'מספר חבר המועדון שלך',
			'loyalty member': 'חברי מועדון יקרים',
			'you have connected successfully': 'התחברתם בהצלחה',
			'pay attention': 'שימו לב',
			'do you want to continue': 'האם להמשיך',
			'continue without club discounts': 'המשך ללא הטבות מועדון',
			'return and pay with loyalty credit card': 'חזור לבחירת כרטיס אשראי מועדון',
			'paying with different payment method will discard {clubname} club discounts': 'הטבות מועדון {clubname} תקפות רק בתשלום בכרטיס אשראי מועדון. תשלום באמצעי תשלום אחר יביא לביטול הטבות שהתקבלו',
			'join to club': 'הצטרפות למועדון',
			'connect to club': 'התחברות למועדון',
			'join': 'הצטרפו',
			'all the fields is required': 'חובה למלא את כל השדות',
			'did you mean?': 'האם התכוונתם ל?',
			'callcustomer_description': 'אנו נתקשר אליכם כדי לגבות את התשלום',
			'creditcard_description': 'בחרו או הוסיפו כרטיס אשראי',
			'pay_creditcard_description': 'תתבקשו לשלם באמצעות כרטיס אשראי בסיום',
			'storecredit_description': 'שלם באמצעות חשבון לקוח',
			'paypal_description': 'שלם באמצעות PayPal',
			'icreditmasterpass_description': 'שלם באמצעות חשבון MasterPass',
			'bit_description': 'תועברו לאפליקציית Bit בסיום',
			'ebt_description': 'תשלום EBT',
			'select:': 'בחרו:',
			'select': 'בחרו',
			'edit comment': 'עריכת הערה',
			'add comment': 'הוספת הערה',
			'add a comment': 'הוספת הערה',
			'write a comment': 'כתיבת הערה',
			'type your comment here': 'הכניסו הערה פה',
			'address 1': 'רחוב & מספר בית',
			'to join': 'להצטרפות',
			'join now': 'הצטרפו עכשיו',
			'register now to join our membership club': 'הרשמו לאתר והמשיכו להצטרפות למועדון',
			'reset code': 'קוד איפוס',
			'reset password': 'אפסו את הסיסמה',
			'password reset': 'איפוס סיסמה',
			'a password recovery code has been sent to your email': 'קוד שחזור סיסמה נשלח לדוא"ל שלכם',
			'please check your inbox and follow the instructions': 'להמשך התהליך, אנא בדוק את תיבת המייל שלך',
            'if you haven\'t received an email, please check if you mistyped your email address or contact customer service': 'במידה ולא קיבלת מייל, יש לוודא את המייל שהקלדת או צור קשר עם שירות לקוחות במספר',
			'est. weight': 'משקל מוערך כ-',
			'please confirm that you accept the terms and conditions for using this service': 'נא לוודא שהנכם מסכימים לתנאי השימוש באפליקציה',
			'explanation': 'הסבר',
			'invalid cvv': 'cvv שגוי',
			'choose card': 'בחרו כרטיס',
			'invalid phone number': 'מספר טלפון לא תקין',
			'invalid city': 'ישוב לא חוקי',
			'invalid address': 'כתובת לא חוקית',
			'invalid street': 'רחוב לא חוקי',
			'invalid address 1': 'רחוב & מספר בית לא תקינים',
			'invalid zip code': 'מיקוד לא חוקי',
			'products are no longer available': 'מוצרים אינם במלאי',
			'payments number': 'מספר תשלומים',
			'online grocery service': 'קניות מזון באינטרנט',
			'download': 'התחילו בהתקנה',
			'get<br>the app': 'התקן<br>אפליקציה',
			'branch not found': 'אנחנו עדיין לא מבצעים משלוחים לכתובת שהוזנה, עמך הסליחה',
			'missing premise or street number': 'חובה למלא מספר בית',
			'estimated tax': 'מס משוערך',
			'estimated total': 'תשלום משוער',
			'contact us': 'צור קשר',
			'how can we help you?': 'איך אפשר לעזור לכם?',
			'how can we help you today?': 'איך אנחנו יכולים לעזור לכם?',
			'submit': 'שלח',
			'invalid message': 'אנא הכניסו טקסט',
			'invalid email': 'דואר אלקטרוני לא תקין',
			'your message was sent successfully': 'ההודעה נשלחה בהצלחה',
			'we are sorry but this service is no longer available': 'השרות הרצוי אינו זמין',
			'continue with this cart': 'המשיכו עם העגלה הנוכחית',
			'merge carts': 'מיזוג עגלות',
			'would you like to merge the two carts or continue using this cart': 'האם ברצונכם למזג את שתי העגלות או להמשיך עם העגלה הנוכחית',
			'somewhere else': 'במקום אחר',
			'you have an open cart with': 'יש לכם עגלה פתוחה עם',
			'powered by': 'נוצר ע"י',
			'powered_by_accessibility': 'נוצר על ידי',
			'day0': 'ראשון',
			'day1': 'שני',
			'day2': 'שלישי',
			'day3': 'רביעי',
			'day4': 'חמישי',
			'day5': 'שישי',
			'day6': 'שבת',
			'to ': 'ל',
			'your order total {{areaname}}does not meet the {{minimum | currency}} minimum order total': 'ההזמנה שלכם {{areaName}}טרם הגיעה למינימום של {{minimum | currency}}',
			'please add to your cart items worth {{difference | currency}} to proceed to checkout': 'אנא הוסיפו לסל מוצרים בשווי {{difference | currency}} על מנת לסיים את הקנייה',
			'not including items on sale': 'לא כולל מוצרים שבמבצע',
			'please add additional products to the cart to proceed': 'אנא הוסיפו מוצרים לעגלה כדי להמשיך',
			'dear customer': 'לקוח/ה יקר/ה',
			'product properties': 'מאפייני מוצר',
			'product properties need to be selected for': 'יש לבחור מאפייני מוצר ל',

			'accessibility statement': 'הצהרת נגישות',
			'accessibility arrangements': 'הסדרי נגישות',
			'accessibility_statement_p1': 'אנו ב{retailerName} מכירים בחשיבות הנגישות במרחב הדיגיטלי והפיזי לאנשים עם מוגבלות ורואים בכך חלק בלתי נפרד מתפיסת השירות והאחריות החברתית שלנו. אתר זה הונגש בתשומת לב רבה על פי התקן הישראלי 5568 – ברמה AA, הרמה הנדרשת בחוק.',
			'accessibility_statement_p2': 'יש לציין כי למרות מאמצנו להנגיש את כלל הדפים באתר, ייתכן ויתגלו חלקים באתר שטרם נגישים, אנו ממשיכים במאמצים לשפר את נגישות האתר כחלק ממחויבותנו לאפשר שימוש בו עבור כלל האוכלוסייה כולל אנשים עם מוגבלויות.',
			'accessibility_statement_p3': 'ההנגשה של האתר באה לידי ביטוי בנושאים הבאים:',
			'accessibility_point_1': '* האתר מותאם לגלישה במחשב נייח, נייד ובסלולר.',
			'accessibility_point_2': '* ניתן להגדיל את הטקסט ע"י לחיצה בו זמנית על המקשים "Ctrl" ו "+" (קונטרול ופלוס). כל לחיצה על צמד המקשים האלו תגדיל את התצוגה ב-10%. כדי להקטין את התצוגה יש ללחוץ בו זמנית על המקשים "Ctrl" ו "-" (קונטרול ומינוס).',
			'accessibility_point_3': '* גולשים המתקשים בהפעלת עכבר יכולים לגלוש באתר באמצעות מקלדת. לחיצה חוזרת ונשנית על המקש Tab תעביר בין הקישורים, הכפתורים בעמוד והשדות השונים בטופס. לחיצה על Enter תפעיל את הקישור/הכפתור המסומן.',
			'accessibility_point_4': '* מבנה העמודים סמנטי ותקני (חלוקה לכותרות, תיוג איזורים, קישורים ותמונות וכיו"ב).',
			'accessibility_point_5': '* תמיכה בטכנולוגיות מסייעות- אתר זה הינו אתר נגיש המותאם לצפייה גם לאנשים עם מוגבלות ומאפשר תמיכה בתוכנת קריאת מסך. לנגישות מיטבית באתרנו אנו ממליצים להשתמש בקורא מסך NVDA או Voice Over.',
			'accessibility_point_6': '* חלוקה היררכית לכותרות',
			'accessibility_point_7': '* תיוג תמונות ורכיבים שאינם טקסטואליים ואינם קישוט בלבד.',
			'accessibility_point_8': '* פוקוס נראה לעין – פוקוס משמעותי יותר ניתן למצוא ברכיב הנגישות, הפעילו את האפשרות של ניווט מקלדת',
			'accessibility_point_9': '* התראות נגישות על בעיות בשדות בטופס צור קשר',
			'accessibility_point_10': '',

			'substitute_preference_1_label': 'החליפו מוצרים',
			'substitute_preference_1_description': 'אנו נחליף מוצרים חסרים מבלי להתקשר אליך',
			'substitute_preference_2_label': 'התקשרו אליי',
			'substitute_preference_2_description': 'אנו נחליף מוצרים בתיאום טלפוני',
			'substitute_preference_3_label': 'אל תחליפו מוצרים',
			'substitute_preference_3_description': 'אנו לא נחליף מוצרים חסרים',
			'substitute_preference_4_label': 'שלחו לי SMS',
			'substitute_preference_4_description': 'אנו נשלח לך SMS לפני שנחליף מוצרים חסרים',
			'substitute_preference_5_label': 'שלחו לי WhatsApp',
			'substitute_preference_5_description': 'אנו נשלח לך הודעת WhatsApp לפני שנחליף מוצרים חסרים',
			'substitute_preference__label': 'למנוע שגיאה',
			'substitute_preference__description': 'למנוע שגיאה',
			'leave_by_the_door': "נא להשאיר את המשלוח מחוץ לדלת במידה ואין איש בבית",

			'sorry, currently we don\'t deliver to this address.': 'מצטערים, נכון לעכשיו אנחנו לא מבצעים משלוחים לכתובת שהזנתם',
			'sorry, currently we don\'t deliver to this address, for further inquiries call to customer service.': 'מצטערים, נכון לעכשיו אנחנו לא מבצעים משלוחים לכתובת שהזנתם, לבדיקה נוספת התקשרו לשירות לקוחות.',
			'sorry, branch change required, this address could only be delivered from branch': 'מצטערים, דרוש שינוי סניף, לכתובת זו ניתן לשלוח רק מסניף',
			'please enter a more accurate address to continue': 'אנא הקלידו כתובת מדוייקת יותר כדי להמשיך',
			'switch branch': 'החליפו סניף',
			'try another address or choose pickup option': 'נסו כתובת אחרת או בחרו באופציית האיסוף העצמי',
			'delivery fee': 'דמי משלוח',
			'pickup fee': 'דמי איסוף',
			'service fee': 'דמי שירות',
			'add a comment here:': 'הוסיפו הערות כאן:',
			'at': 'ב',
			'next delivery': 'המשלוח הבא',
			'next pickup': 'שעת האיסוף הבאה',
			'get it within': 'יישלח תוך',
			'the following products were not saved correctly due to an error': 'המוצרים הבאים לא נשמרו כראוי עקב שגיאה',
			'hrs': 'שעות',
			'days': 'ימים',
			'business days': 'ימי עסקים',
			'delivery within': 'משלוח תוך',
			'within': 'תוך',
			'pickup within': 'איסוף תוך',
			'coupon number': 'קוד קופון',
			'coupon': 'קופון',
			'please enter quantity': 'אנא הזינו כמות',
			'please enter a name with less than 200 characters': 'שם המוצר לא יכול להיות ארוך מ-200 תווים',
			'we didn\'t recognize the address you entered': 'אנו לא מזהים את הכתובת שהכנסתם',
			'continue anyway': 'להמשיך בכל זאת',
			'more details': 'פרטים נוספים',
			'product description': 'תיאור המוצר',
			'ingredients': 'רכיבים',
			'more information': 'מידע נוסף',
			'directions': 'תיאור',
			'serving size': 'גודל מנה',
			'servings per container': 'תכולה',
			'amount per serving': 'משקל ליחידה',
			'calories': 'קלוריות(אנרגיה)',
			'calories from fat': 'קלוריות משומן',
			'daily value': 'ערך יומי',
			'total fat': 'שומנים',
			'saturated fat': 'מתוכם שומן רווי',
			'trans fat': 'מתוכם שומן טראנס',
			'cholesterol': 'כולסטרול',
			'sodium': 'נתרן',
			'total carbohydrate': 'פחמימות',
			'dietary fiber': 'סיבים תזונתיים',
			'sugars': 'סוכרים',
			'protein': 'חלבונים',
			'vitamin a': 'ויטמין A',
			'vitamin c': 'ויטמין C',
			'calcium': 'קלציום',
			'iron': 'ברזל',
			'g': 'ג',
			'monounsaturated fat': 'חומצות שומן חד בלתי רוויות',
			'polyunsaturated fat': 'חומצות שומן רב בלתי רוויות',
			'percent daily': 'אחוזי הצריכה היומית מבוססים על תזונה יומית בת 2,000 קלוריות. הערך היומי שלך עשוי להיות גבוה או נמוך בהתאם לצריכת הקלוריות שלך.',
			'welcome': 'ברוכים הבאים',
			'let\'s make sure we\'re available in your area': 'בואו נוודא כי אנו זמינים באזורכם',
			'area': 'ישוב',
			'check': 'בדיקה',
			'continue shopping': 'המשיכו בקנייה',
			'change area': 'שינוי אזור',
			'quantity limitation': 'הגבלת כמות',
			'is limited to ': 'מוגבל ל - ',
			'items only': 'פריטים בלבד',
			'similar items': 'מוצרים דומים',
			'nutrition': 'ערכים תזונתיים',
			'description': 'תיאור מוצר',
			'and': 'ו',
			'and the': 'ואת',
			'currently we do not deliver to your area': 'נכון לעכשיו אנחנו לא מבצעים משלוחים לאזור שלכם',
			'ticket_status_1': 'חדש',
			'ticket_status_2': 'בטיפול',
			'ticket_status_3': 'סיום',
			'no previous inquiries': 'לא רשומות פניות',
			'join membership': 'הצטרפו למועדון',
			'join club': 'הצטרפו למועדון',
			'view club member details': 'לצפייה בפרטי חבר מועדון',
			'thank you for signing up to {0} app': 'תודה שהצטרפתם לאפליקציה של {0}',
			'join our loyalty club to benefit from exclusive promotions': 'הצטרפו למועדון הלקוחות שלנו ותהנו ממבצעים בלעדיים',
			'connect to our loyalty club to benefit from exclusive promotions': 'התחברו למועדון הלקוחות שלנו ותהנו ממבצעים בלעדיים',
			'membership number': 'מספר מועדון',
			'membership savings': 'חסכון מועדון',
			'could save': 'יכולתם לחסוך',
			'customer service': 'שירות לקוחות',
			'expiration date': 'תאריך תפוגה',
			'comments': 'הערות',
			'send': 'שלח',
			'credit cards': 'כרטיסי אשראי',
			'drag files or browse...': 'גררו קבצים לכאן או לחצו כדי להעלות...',
			'you are now connected to your membership club account': 'התחברתם בהצלחה למועדון הלקוחות',
			'remove credit card info': 'הסרת מידע על כרטיס אשראי',
			'are you sure you want to remove credit card information': 'האם אתם בטוחים שאתם רוצים למחוק את המידע על כרטיס האשראי',
			'type in new request': 'הכניסו כאן פנייה חדשה',
			'by': 'ע״י',
			'from': 'מקור',
			'read more': 'קראו עוד',
			'read less': 'קראו פחות',
			'recipe': 'מתכון',
			'video': 'סרטון',
			'min': 'דקות',
			'invalid coupon code': 'קוד הקופון אינו קיים במערכת',
			'order status': 'מצב הזמנה',
			'view order': 'צפייה בהזמנה',
			'track order no.': 'מעקב הזמנה מס\'',
			'forgot something': 'שכחת משהו',
			'update order': 'עדכנו קנייה',
			'update order failed': 'עדכון הזמנה נכשל',
			'order received': 'התקבלה הזמנה',
			'order details': 'פרטי הזמנה',
			'payment information': 'פרטי תשלום',
			'total (estimated)': 'סה׳כ (משוער)',
			'last update time': 'זמן עדכון אחרון',
			'replaced with': 'מוצר חלופי',
			'new product': 'מוצר חדש',
			'credit card charge' : 'חיוב בכרטיס אשראי',
			'in process': 'מלקטים',
			'ready': 'מוכן',
			'received': 'קיבלנו',
			'need help': 'זקוקים לעזרה',
			'edit order': 'עריכת הזמנה',
			'edit your order': 'עדכנו את ההזמנה',
			'your order': 'ההזמנה שלכם',
			'has been updated': 'התעדכנה',
			'are you sure you want to cancel your order update and return to your original order': 'האם אתם בטוחים שאתם רוצים לבטל את עריכת ההזמנה ולחזור אל ההזמנה המקורית שלכם',
			'do you want to remove / add items to your existing order': 'האם ברצונכם להסיר / להוסיף מוצרים להזמנה קיימת',
			'note': 'שימו לב',
			'prices and specials will change according to the update date': 'המחירים והמבצעים ישתנו בהתאם לתאריך העדכון',
			'please check your email for order details': 'בבקשה בדקו את הדואר האלקטרוני שלכם כדי לראות את פרטי ההזמנה',
			'thank you': 'תודה רבה',
			'we have started collecting your original order.': 'התחלנו ללקט את הזמנתכם',
			'we have finished collecting your original order.': 'סיימנו כבר ללקט את הזמנתכם',
			'this is a canceled order.': 'הזמנה זו הינה הזמנה שבוטלה',
			'your order cannot be changed at this time': 'לא ניתן עוד לשנות את ההזמנה',
			'your order can no longer be changed': 'לא ניתן עוד לשנות את ההזמנה',
			'cancel update': 'ביטול עדכון',
			'canceling': 'ביטול',
			'update': 'עדכון',
			'replace shopping cart': 'החלפת המוצרים בעגלה',
			'are you sure you want to replace your shopping cart with the order from': 'האם אתם בטוחים שאתם רוצים להחליף את המוצרים בסל הקניות עם המוצרים שהזמנתם בתאריך',
			'replace': 'להחליף',
			'replace_2': 'החלפה',
			'you can still edit your order': 'אתם עדיין יכולים לערוך את הזמנתכם',
			'your original order is': 'חוזר אל',
			'cancel order update': 'ביטול עדכון הזמנה',
			'in process ': 'ההזמנה המקורית שלכם',
			'your cart is empty': 'העגלה שלכם ריקה',
			'continue as new cart': 'יצירת עגלה חדשה',
			'you can move the selected items into a new order': 'אתם יכולים להעביר את המוצרים שבחרתם להזמנה חדשה',
			'my coupons': 'הקופונים שלי',
			'clip & add': 'הוסף',
			'clip': 'הוסף',
			'the site is undergoing maintenance': 'האתר בתהליך תחזוקה',
			'promotion details': 'פרטים',
			'hide details': 'הסתר פרטים',
			'show details': 'הצג פרטים',
			'the following products are not active, and were removed from the cart': 'המוצרים הבאים אינם קיימים במלאי, והוסרו מהעגלה',
			'once we complete picking your order, we adjust your final charge based on weight adjustments, substitutions & out of stock items': 'במהלך ליקוט ההזמנה ייתכנו שינויים בחיוב הסופי כתוצאה משקילת מוצרים, מוצרים חלופיים ומוצרים חסרים במלאי',
			'due to changes in address details': 'כתובת זו אינה מתאימה למועד המשלוח שנבחר',
			'please select your delivery time again': 'אנא בחרו מועד משלוח חדש',
			'order': 'הזמנה',
			'order id': 'מספר הזמנה',
			'pack items': 'מוצרי מארז',
			'how do you want to get': 'איך ברצונכם לקבל את',
			'cost': 'מחיר',
			'express': 'אקספרס',
			'express delivery': 'אקספרס משלוח',
			'no_deliveries_first_row': 'מצטערים, לא נמצאו',
			'no_deliveries_second_row': 'זמני משלוח ליום זה',
			'no_pickup_first_row': 'מצטערים, לא נמצאו',
			'no_pickup_second_row': 'זמני איסוף ליום זה',
			'add your comment here': 'הוספת הערה',
			'substitutions': 'החלפת מוצרים',
			'before_checkout_delivery_title_1': 'בחרו',
			'before_checkout_delivery_title_2': 'זמן משלוח רצוי',
			'before_checkout_pickup_title_1': 'אספו',
			'before_checkout_pickup_title_2': 'את הזמנתכם מהחנות',
			'your credit cards': 'כרטיסי האשראי שלכם',
			'add another card': 'הוסיפו כרטיס אשראי',
			'order update': 'עדכון הזמנה',
			'updated order value': 'ערך הזמנה מעודכן',
			'(estimated)': '(מוערך)',
			'in order to complete the order update, please choose the payment method': 'על מנת להשלים את עדכון ההזמנה, אנא בחר אמצעי תשלום',
			'confirm update': 'אישור עדכון',
			'add another account': 'הוסיפו חשבון',
			'please enter a name': 'נא להזין שם',
			'please enter a mobile phone': 'נא להזין סלולרי',
			'please enter an address': 'נא להזין רחוב & מספר בית',
			'please enter house number': 'נא להזין מספר בית',
			'please enter city': 'נא להזין עיר',
			'please enter a correct phone number': 'נא להזין מספר טלפון תקין',
			'is an anti-fraud security feature to help verify that you are in possession of your credit card': 'קוד CVV - Credit Validation Value, הוא קוד המשמש לאימות שהכרטיס נמצא בחזקתכם',
			'the three-digit cvv number is printed on the signature panel on the back of the card immediately after the card\'s account number': 'שלושת ספרות הCVV מודפסות בגב הכרטיס על גבי פס החתימה',
			'enter your credit on file id': 'הזינו את מספר החשבון שלכם',
			'checkout_title': 'סיום קניה',
			'cart no.': 'מס\' עגלה',
			'pick_and_go_title': 'שגר וסע',
			'no need to wait in line': 'לא צריך לחכות בתור',
			'we will take care of your': 'אנחנו נדאג',
			'cart and deliver': 'לעגלה ולמשלוח שלך',
			'to begin, pick a': 'כדי להתחיל, קח',
			'barcode card': 'כרטיס ברקוד',
			'start pick & go': 'התחל שגר וסע',
			'are you sure you want to stop the': 'אתה בטוח שברצונך להפסיק את',
			'pick & go service': 'שירות \'שגר וסע\'',
			'stop pick & go': 'הפסק שגר וסע',
			'please enter your credit on file id': 'נא הזינו את מספר החשבון שלכם',
			'substitutions choice': 'החלפת מוצרים',
			'home page': 'לדף הבית',
			'thank you for': 'תודה',
			'shopping at {retailername}': 'שביצעת קנייה ב{retailerName}',
            'an order summary email was sent to your inbox': 'הודעת סיכום הזמנה נשלחה אל תיבת הדואר האלקטרוני שלך',
			'forgot': 'שכחת',
			'something': 'משהו',
			'1 hours': 'שעה',
			'2 hours': '2 שעות',
 			'{hours} hours delivery': '{hours} שעות',
			'{minutes} minutes delivery': '{minutes} דקות',
			'1 hour pickup': 'איסוף תוך שעה',
			'2 hour pickup': 'איסוף תוך שעתיים',
			'{hours} hour pickup': 'איסוף תוך {hours} שעות',
			'hours': 'שעות',
			'minutes': 'דקות',
			'within': 'תוך',
			'pickup time and address': 'זמן וכתובת איסוף',
			'pickup details': 'פרטי איסוף',
			'your order will be ready for pickup': 'הזמנתכם תהיה מוכנה לאיסוף',
			'loading': 'טוען',
			'paypal accounts': 'חשבון פייפל',
			'create new order': 'יצירת הזמנה חדשה',
			'create new shopping list': 'יצירת רשימה חדשה',
			'add cart products': 'הוסיפו מוצרים מהעגלה',
			'create': 'צרו',
			'no shopping list created yet': 'טרם נוצרה רשימת קניות',
			'remove format': 'הסר פורמט',
			'since your address was not detected': 'מכיוון שכתובתכם לא זוהתה',
			'please re-select a new delivery time': 'אנא בחרו זמן משלוח חדש',
			'select delivery time': 'בחרו זמן משלוח',
			'please try again': 'אנא נסו שנית',
			'add a credit card': 'הוסיפו כרטיס אשראי',
			'add paypal account': 'הוסיפו חשבון PayPal',
			'it seems you are editing your order on a different device.': 'אתם מבצעים עריכת הזמנה על גבי מכשיר אחר',
			'note! it seems you have finished editing your order on a different device/browser.': 'שימו לב! סיימתם כבר לערוך את ההזמנה במכשיר/דפדפן אחר',
			'it seems you have finished your order on a different device.': 'עריכת ההזמנה שלך כבר הסתיימה',
			'wee are currently updating this device too': 'אנו מעדכנים גם את מכשיר זה',
			'sorry, no available delivery time slots': 'מצטרים, לא נותרו מועדי משלוח פנויים',
			'sorry, no available pickup time slots': 'מצטרים, לא נותרו מועדי איסוף פנויים',
			'today\'s special deals': 'מבצעים מובילים',
			'loyalty membership expired': 'פג תוקף חברותך במועדון',
			'hello': 'שלום',
			'free': 'חינם',
			'your membership has expired': 'פג תוקף חברותך במועדון',
			'your club membership has expired on': 'פג תוקף חברותך במועדון בתאריך',
			'renew you membership and enjoy exclusive benefits and discounts': 'חדשו את חברותכם במועדון ותיהנו מהטבות ומבצעים כבר בקניה זו',
			'enter a valid number': 'הזנת מספר מועדון תקף',
			'no thanks, continue shopping': 'המשך ללא הטבות מועדון',
			'renew membership': 'חידוש חברות',
			'your membership has been renewed successfully': 'חידוש החברות למועדון הסתיים בהצלחה',
			'you can enjoy from the club benefits in this order': 'תוכלו להנות מהטבות המועדון כבר בהזמנה זו',
			'loyalty_mode_1_label': 'חבר מועדון קיים',
			'loyalty_mode_2_label': 'הרשמה למועדון',
			'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/club-icon.png': 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/club-icon-hebrew.png',
			'please fill in membership number': 'אנא הזן מספר חבר מועדון',
			'{retailername} membership offers exclusive': 'חברות במועדון {retailername} מעניקה לכם',
			'promotions in-store and online': 'הטבות, הנחות ומבצעים ייחודיים',
			'hi': 'שלום',
			'the items in your shopping cart will be cleared if you choose to edit your previous order': 'מעבר לעריכת הזמנה ינקה את כל הפריטים שהוספת לסל הקניות',
			'tavplus': 'תו פלוס+',
			'edit order & clear cart': 'ערוך הזמנה ונקה סל',
			'it seems you are editing your order on a different device/browser.': 'אתם מבצעים עריכת הזמנה על גבי מכשיר/דפדפן אחר',
			'it seems you have finished editing your order on a different device/browser.': 'יצאתם מעריכת הזמנה ממכשיר/דפדפן אחר',
			'do you want to add the product to this new order or to the order that is being updated?': 'האם ברצונכם להוסיף את המוצר להזמנה חדשה זו, או להזמנה שנמצאת במצב עריכה',
			'note! you in the middle of editing order': 'שימו לב! הינכם נמצאים באמצע עריכת הזמנה',
			'you in the middle of editing order. changing store is impossible': 'הינכם נמצאים באמצע עריכת הזמנה. לא ניתן להחליף חנות',
			'add to new order': 'הוספה להזמנה חדשה',
			'add to updated order': 'הוספה להזמנה בעריכה',
			'we are not able to register your membership club account': 'לא ניתן להצטרף למועדון',
			'please make sure your details are correct or try again later': 'אנא וודאו כי הפרטים שהזנתם נכונים או נסו שנית מאוחר יותר',
			'https://d226b0iufwcjmj.cloudfront.net/global/mobile/images/backgrounds/loyalty-titles.png': 'https://d226b0iufwcjmj.cloudfront.net/global/mobile/images/backgrounds/loyalty-titles-he.png',
			'special_exp_new': 'בתוקף',
			'ends': 'בתוקף',
			'special_exp': 'בתוקף עד',
			'forpackquantity': 'ב',
			'verify_1': 'איסור מכירה',
			'verify_2': 'לקטינים',
			'verify_3': 'מכירת מוצר זה למי שטרם מלאו לו',
			'verify_4': 'אסורה בהחלט',
			'verify_5': '',
			'verify_6': '',
			'verify_7': 'מלאו',
			'verify_8': 'לי',
			'verify_9': 'טרם מלאו',
			'years': 'שנים',
			'sorry': 'מצטערים',
			'you cannot purchase': 'אינכם יכולים לרכוש',
			'this item': 'מוצר זה',
			'password must include at least 6 characters': 'סיסמה חייבת להכיל 6 תווים לפחות',
			'password should not be longer than 30 characters' : 'סיסמה יכולה להכיל עד 30 תווים',
			'password must contain at least one numeric digit and one character': 'סיסמה חייבת לכלול לפחות ספרה אחת ואות אחת',

			'let\'s start': 'בואו נתחיל',
			'adding some items': 'הוסיפו מוצרים',

			'please notice': 'שימו לב',
			'the expiration of these': 'תוקפם של',
			'sales will not be valid for this chosen time': 'המבצעים המוצגים בהמשך יפוג ביום אספקת ההזמנה',
			'specials will not be valid for this chosen time': 'המבצעים המוצגים בהמשך יפוג ביום אספקת ההזמנה',
			'the special displayed below, will expire on the date you are supposed to receive your order': 'תוקף המבצע יפוג ביום אספקת ההזמנה',
			'these items will be charged at their regular price': 'מוצרים אלו יחויבו במחיר מחירון ללא מבצע',
			'this product will be charged the regular price, promotion will not be applied': 'מוצר זה יחויב במחיר מחירון ללא מבצע',
			'price of updated cart (est.)': 'עלות עגלה מעודכן (מוערך)',

			'general specials': 'מבצעי הרשת',
			'loyalty specials': 'מבצעי מועדון',
			'category': 'קטגוריה',
			'categories': 'קטגוריות',
			'special type': 'סוג מבצע',
			'clear all': 'נקה הכל',
			'filter by': 'סנן לפי',
			'sort by': 'מיין לפי',
			'type': 'סוג',
			'our top picks': 'המומלצים שלנו',
			'membership deals': 'מבצעי מועדון',
			'it\'s easy!': 'זה קל!',
			'please sign in to continue...': 'אנא היכנסו לאתר כדי להמשיך...',
			'e-mail': 'דואר אלקטרוני',
			'sign in': 'כניסה',
			'club member name': 'שם חבר מועדון',
			'{clubname} special deals': 'מבצעי {clubname}',
			'membership_club': 'מועדון',
			'join our membership and enjoy exclusive benefits and promotions': 'הצטרפו למועדון הלקוחות וקבלו הטבות ייחודיות',
			'identify': 'הזדהות',
			'join our membership club': 'הצטרפו למועדון הלקוחות',
			'activate': 'הפעל',
			'please activate credit card {xxxx} in order to continue': 'נא הפעל את כרטיס האשראי שמסתיים בספרות {xxxx} כדי להמשיך',
			'membership_title_register': 'הצטרפו עכשיו',
			'membership_title_identify': 'הזדהו',
			'membership_title_{clubname}': 'למועדון {clubname}',
			'membership_{clubname}_title': 'מועדון {clubname}',
			'to complete your purchase, join/connect to {clubname} membership account': 'להשלמת הרכישה, התחברו/הרשמו למועדון הלקוחות של {clubname}',
			'in order to complete your purchase, you must connect to the loyalty club': 'על מנת להשלים את הרכישה יש להתחבר למועדון הלקוחות',
			'stores info': 'פרטי חנויות',
			'store info': 'פרטי החנות',
			'pickup address': 'כתובת איסוף',
			'select address': 'בחר כתובת',
			'buy': 'קנה',
			'get': 'קבל',
			'please enter e-mail': 'נא להזין דואר אלקטרוני',
			'menu_stores_info': 'רשימת סניפים',
			'menu_store_info': 'פרטי החנות',
			'your order is being edited by another device': 'ההזמנה שלך נערכת במכשיר אחר',
			'organization': 'ארגון',
			'organization_description': 'תשלום בהקפה',
			'enter order confirmation no.': 'אנא הקלידו מספר אישור הזמנה',

			'icreditmasterpass': 'MasterPass',

			'friend\'s email': 'דוא"ל של חבר/ה',
			'or share your promo code': 'או שתף את קוד המבצע',
			'congratulations': 'במיוחד בשבילך',
			'shop now': 'התחילו קנייה',
			'copy': 'העתק',
			'no, thanks': 'לא, תודה',
			'got it': 'תודה, הבנתי',
			'invitation sent successfully': 'ההזמנה נשלחה בהצלחה',
			'promo code unidentified': 'קוד המבצע לא נמצא',
			'enter your promo code': 'הכנס קוד מבצע',
			'there are no coupons currently available': 'אין קופונים זמינים כעת',

			'express delivery is allowed only with products that contain express delivery tag': 'משלוח מהיר אפשרי רק עם מוצרים שמסומנים כמוצרי משלוח מהיר',
			'express delivery products limit exceeded': 'כמות המוצרים חורגת מהגבלת מוצרים למשלוח מהיר',
			'this service is available for express items only. removing non express items from your cart will enable using this option': 'שירות זה זמין למוצרים שמסומנים כמוצרי משלוח מהיר בלבד. הסרה של מוצרים שלא מסומנים תאפשר אפשרות זו',
			'express delivery is limited to': 'משלוח מהיר מוגבל ל',
			'items. to enable this service, remove items from your cart': 'פריטים בלבד. כדי לאפשר שירות זה, הסירו מוצרים מהעגלה',

			'buy_x_for_y': '{{purchaseTotal}} במבצע',

			/*pele card errors*/
			'permitted transaction.': 'עסקה תקינה.',
			'the card is blocked, confiscate it.': 'חסום החרם כרטיס.',
			'the card is stolen, confiscate it.': 'גנוב החרם כרטיס.',
			'contact the credit company.': 'התקשר לחברת האשראי.',
			'refusal by credit company.': 'סירוב מחברת האשראי מספר cvv אינו תקין וכדומה.',
			'the card is forged, confiscate it.': 'מזויף החרם כרטיס.',
			'incorrect cvv/id.': 'מספר תעודת זהות ו/או מספר ה-CVV לא תואם למספר הכרטיס.' /*'חובה להתקשר לחברת האשראי.'*/,
			'an error occurred while building access key for blocked card files.': 'תקלה בבניית מפתח גישה לקובץ חסומים.',
			'no communication. please try again or contact system administration': 'לא הצליח להתקשר.',
			'the program was stopped by user`s command (esc) or com port can\'t be open (windows)': 'תוכנית הופסקה עפ\"י הוראת המפעיל (ESC) או COM PORT לא ניתן לפתיחה (WINDOWS).',
			'the acquirer is not authorized for foreign currency transactions': 'אין אישור סולק למטבע איזו.',
			'this card is not permitted for foreign currency transactions': 'אין אישור למותג למטבע איזו.',
			'this card is not supported.': 'כרטיס לא נתמך.',
			'track 2 (magnetic) does not match the typed data.': 'אין התאמה בין המספר שהוקלד לפס המגנטי.',
			'additional required data was entered/not entered as opposed to terminal settings (z field).': 'נתונים נוספים אינם או ישנם בניגוד להגדרות המסוף.',
			'last 4 digits were not entered (w field).': 'לא הוקלדו 4 ספרות האחרונות.',
			'entry in int_in file is shorter than 16 characters.': 'רשומה בקובץ INT_IN קצרה מ- 16 תווים.',
			'the input file (int_in) does not exist.': 'קובץ קלט (IN_INT) לא קיים.',
			'blocked cards file (neg) does not exist or has not been updated, transmit or request authorization for each transaction.': 'קובץ חסומים (NEG) לא קיים או לא מעודכן - בצע שידור או בקשה לאישור עבור כל עסקה.',
			'one of the parameter files/vectors does not exist.': 'אחד מקבצי פרמטרים או ווקטורים לא קיים.',
			'date file (data) does not exist.': 'קובץ תאריכים (DATA) לא קיים.',
			'format file (start) does not exist.': 'קובץ אתחול (START) לא קיים.',
			'the difference in days in the blocked cards input is too large, transmit or request authorization for each transaction.': 'הפרש בימים בקליטת חסומים גדול מדי - בצע שידור או בקשה לאישור עבור כל עסקה.',
			'the difference in generations in the blocked cards input is too large, transmit or request authorization for each transaction.': 'הפרש דורות בקליטת חסומים גדול מידי - בצע שידור או בקשה לאישור עבור כל עסקה.',
			'when the magnetic strip is not completely entered, define the transaction as a telephone number or signature only.': 'כאשר לא הוכנס פס מגנטי כולו הגדר עסקה כעסקה טלפונית או כעסקת חתימה בלבד.',
			'the central terminal number was not entered into the defined main supplier terminal.': 'מספר מסוף מרכזי לא הוכנס למסוף המוגדר לעבודה כרב ספק.',
			'the beneficiary number was not entered into the defined main beneficiary terminal.': 'מספר מוטב לא הוכנס למסוף המוגדר לעבודה כרב מוטב.',
			'the supplier/beneficiary number was entered, however the terminal was not updated as the main supplier/beneficiary.': 'מסוף שאינו מעודכן כרב ספק/רב מוטב והוקלד מס\' ספק/מס\' מוטב.',
			'the beneficiary number was entered, however the terminal was updated as the main supplier': 'מסוף מעודכן כרב ספק והוקלד גם מס\' מוטב.',
			'old transactions, transmit or request authorization for each transaction.': 'תנועות ישנות בצע שידור או בקשה לאישור עבור כל עסקה.',
			'defective card.': 'טעות בהקלדת כרטיס האשראי, אנא נסה שוב.' /*'כרטיס לא תקין.'*/,
			'this card is not permitted for this terminal or is not authorized for this type of transaction.': 'כרטיס לא רשאי לבצע במסוף זה או אין אישור לעסקה כזאת.',
			'this card is not permitted for this transaction or type of credit.': 'כרטיס לא רשאי לבצע עסקה עם סוג אשראי זה.',
			'expired card.': 'פג תוקף.',
			'expired token': 'קישור פג תוקף',
			'installment error, the amount of transactions needs to be equal to: first installment plus fixed installments times number of installments.': 'שגיאה בתשלומים-סכום עסקה צריך להיות שווה תשלום ראשון + (תשלום קבוע כפול מס\' תשלומים).',
			'unable to execute a debit transaction that is higher than the credit card`s ceiling.': 'לא ניתן לבצע עסקה מעל תקרה לכרטיס לאשראי חיוב מיידי.',
			'incorrect control number.': 'טעות בהקלדת כרטיס האשראי, אנא נסה שוב.' /*'סיפרת בקורת לא תקינה.'*/,
			'the beneficiary and supplier numbers were entered, however the terminal is defined as main.': 'מסוף שמוגדר כרב מוטב הוקלד מס\' ספק.',
			'the transaction`s amount exceeds the ceiling when the input file contains j1, j2 or j3 (contact prohibited).': 'מעל תקרה כאשר קובץ הקלט מכיל 3J או 2J או 1J (אסור להתקשר).',
			'the card is blocked for the supplier where input file contains j1, j2 or j3 (contact prohibited).': 'כרטיס חסום בספק כאשר קובץ הקלט מכיל 3J או 2J או 1J (אסור להתקשר).',
			'random where input file contains j1 (contact prohibited).': 'אקראית כאשר קובץ הקלט מכיל J1 (אסור להתקשר).',
			'the terminal is prohibited from requesting authorization without transaction (j5).': 'מסוף לא רשאי לבקש אישור ללא עסקה (5J).',
			'the terminal is prohibited for supplier-initiated authorization request (j6).': 'מסוף לא רשאי לבקש אישור ביוזמת קמעונאי (6J).',
			'the terminal must request authorization where the input file contains j1, j2 or j3 (contact prohibited).': 'מסוף חייב לבקש אישור כאשר קובץ הקלט מכיל 3J או 2J או 1J (אסור להתקשר).',
			'secret code must be entered where input file contains j1, j2 or j3 (contact prohibited).': 'חייב להקליד מספר סודי, כאשר קובץ הקלט מכיל J3 או 2J או 1J (אסור להתקשר).',
			'incorrect vehicle number.': 'מספר רכב לא תקין.',
			'the number of the distance meter was not entered.': 'מד מרחק לא הוקלד.',
			'the terminal is not defined as gas station (petrol card or incorrect transaction code was used).': 'מסוף לא מוגדר כתחנת דלק (הועבר כרטיס דלק או קוד      עסקה לא מתאים).',
			'an id number is required (for israeli cards only) but was not entered.': 'לא הוקלד מספר תעודת זהות.',
			'cvv is required but was not entered.': 'לא הוקלד CVV2',
			'cvv and id number are required (for israeli cards only) but were not entered.': 'לא הוקלדו מספר תעודת הזהות ו – CVV2 .',
			'abs attachment was not found at the beginning of the input data in memory.': 'צרוף ABS לא נמצא בהתחלת נתוני קלט בזיכרון.',
			'the card number was either not found or found twice.': 'מספר כרטיס לא נמצא או נמצא פעמיים.',
			'incorrect transaction type.': 'סוג עסקה לא תקין.',
			'incorrect transaction code.': 'קוד עסקה לא תקין.',
			'incorrect credit type.': 'סוג אשראי לא תקין.',
			'incorrect currency.': 'מטבע לא תקין.',
			'the first installment and/or fixed payment are for non-installment type of credit.': 'קיים תשלום ראשון ו/או תשלום קבוע לסוג אשראי שונה מתשלומים.',
			'number of installments exist for the type of credit that does not require this.': 'קיים מספר תשלומים לסוג אשראי שאינו דורש זה.',
			'linkage to dollar or index is possible only for installment credit.': 'לא ניתן להצמיד לדולר או למדד לסוג אשראי שונה מתשלומים.',
			'the magnetic strip is too short.': 'אורך הפס המגנטי קצר מידי.',
			'the pin code device is not defined.': 'לא מוגדר מכשיר להקשת מספר סודי.',
			'must enter the pin code number.': 'חובה להקליד מספר סודי.',
			'smart card reader not available - use the magnetic reader.': 'קכ\"ח לא זמין – העבר בקורא מגנטי.',
			'must use the smart card reader.': 'חובה להעביר כרטיס בקכ\"ח.',
			'denied - locked card.': 'דחייה – כרטיס נעול.',
			'denied - smart card reader action didn\'t end in the correct time.': 'דחייה – פעולה עם קכ\"ח לא הסתיימה בזמן הראוי.',
			'denied - data from smart card reader not defined in system.': 'דחייה – נתונים אשר התקבלו מקכ\"ח אינם מוגדרים במערכת.',
			'incorrect pin code.': 'הוקלד מספר סודי שגוי.',
			'currency does not exist in vector 59.': 'מטבע לא קיים בווקטור 59.',
			'the club code entered does not match the credit type.': 'הוכנס \"קוד מועדון\" לסוג אשראי לא מתאים.',
			'cannot cancel charge transaction. please create a credit transaction.': 'עסקת ביטול אסורה בכרטיס יש לבצע עסקת זיכוי.',
			'unable to read/write/open the tran file.': 'לא מצליח לקרוא/ לכתוב/ לפתוח קובץ TRAN.',
			'no authorization from credit company for clearance.': 'הכרטיס שהוקלד אינו נתמך ע\"י בית העסק.' /*'אין אישור מחברת אשראי לעבודה.'*/,
			'the terminal is not permitted to send queries for immediate debit cards.': 'למסוף אין אישור לביצוע שאילתא לאשראי חיוב מיידי.',
			'the transaction amount is too large, divide it into a number of transactions.': 'סכום העסקה גדול מידי - חלק במספר העסקאות.',
			'the terminal is not authorized to execute forced transactions.': 'למסוף אין אישור לבצע עסקאות מאולצות.',
			'the terminal is not authorized for cards with service code 587.': 'למסוף אין אישור לכרטיס עם קוד השרות 587.',
			'the terminal is not authorized for immediate debit cards.': 'למסוף אין אישור לכרטיס חיוב מיידי.',
			'the terminal is not authorized for installment transactions.': 'למסוף אין אישור לעסקה בתשלומים.',
			'the terminal is authorized for installment transactions only, not telephone/signature.': 'למסוף אין אישור לעסקה טלפון/ חתימה בלבד בתשלומים.',
			'the terminal is not authorized for telephone transactions.': 'למסוף אין אישור לעסקה טלפונית.',
			'the terminal is not authorized for signature-only transactions.': 'למסוף אין אישור לעסקה \"חתימה בלבד\".',
			'the terminal is not authorized for foreign currency transactions, or transaction is not authorized.': 'למסוף אין אישור לעסקה בדולרים.',
			'the terminal is not authorized for club transactions.': 'למסוף אין אישור לעסקת מועדון.',
			'the terminal is not authorized for star/point/mile transactions.': 'למסוף אין אישור לעסקת כוכבים/נקודות/מיילים.',
			'the terminal is not authorized for isracredit credit.': 'למסוף אין אישור לאשראי ישראקרדיט.',
			'the terminal is not authorized for amex credit.': 'למסוף אין אישור לאשראי אמקס קרדיט.',
			'the terminal is not authorized for dollar linkage.': 'למסוף אין אישור להצמדה לדולר.',
			'the terminal is not authorized for index linkage.': 'למסוף אין אישור להצמדה למדד.',
			'the terminal is not authorized for index linkage with foreign cards.': 'למסוף אין אישור להצמדה למדד לכרטיסי חו\"ל.',
			'the terminal is not authorized for star/point/mile transactions for this type of credit.': 'למסוף אין אישור לעסקת כוכבים/נקודות/מיילים לסוג אשראי זה.',
			'the terminal is not authorized for isra 36 credit.': 'למסוף אין אישור לאשראי ישרא 36.',
			'the terminal is not authorized for amex 36 credit.': 'למסוף אין אישור לאשראי אמקס 36.',
			'the terminal is not authorized for this club code.': 'למסוף אין אישור לקוד מועדון זה.',
			'the terminal is not authorized for immediate debit transactions (except for immediate debit cards).': 'למסוף אין אישור לעסקת חיוב מיידי פרט לכרטיסי חיוב מיידי.',
			'the terminal is not authorized to accept visa card staring with 3.': 'למסוף אין אישור לקבל כרטיסי ויזה אשר מתחילים ב - 3.',
			'the terminal is not authorized to execute credit transactions above the ceiling.': 'למסוף אין אישור לבצע עסקת זכות מעל תקרה.',
			'the card is not permitted to execute club transactions.': 'כרטיס לא רשאי לבצע עסקת מועדון.',
			'the card is not permitted to execute star/point/mile transactions.': 'כרטיס לא רשאי לבצע עסקת כוכבים/נקודות/מיילים.',
			'the card is not permitted to execute dollar transactions (regular or telephone).': 'כרטיס לא רשאי לבצע עסקאות בדולרים (רגילות או טלפוניות).',
			'the card is not valid according to isracard`s list of valid cards.': 'כרטיס לא תקף על פי רשימת כרטיסים תקפים של ישראכרט.',
			'defective card according to system definitions (isracard vector1), error in the number of figures on the card.': 'כרטיס לא תקין עפ”י הגדרת המערכת (VECTOR1 של ישראכרט)- מס\' הספרות בכרטיס- שגוי.',
			'the card is not permitted to execute dollar transactions according to system definitions (isracard vector1).': 'כרטיס לא רשאי לבצע עסקאות דולריות עפ”י הגדרת המערכת (VECTOR1 של ישראכרט).',
			'the card belongs to a group that is not permitted to execute transactions according to system definitions (visa vector 20).': 'הכרטיס שייך לקבוצת כרטיסים אשר אינה רשאית לבצע עסקאות עפ”י הגדרת המערכת (VECTOR20 של ויזה).',
			'the card`s prefix (7 figures) is invalid according to system definitions (diners vector21).': 'קידומת הכרטיס (7 ספרות) לא תקפה עפ”י הגדרת המערכת (21VECTOR של דיינרס).',
			'the card is not permitted to carry out installment transactions according to isracard`s list of valid cards.': 'כרטיס לא רשאי לבצע עסקאות בתשלומים על פי רשימת כרטיסים תקפים של ישראכרט.',
			'the number of installments is too large according to isracard`s list of valid cards.': 'מספר תשלומים גדול מידי על פי רשימת כרטיסים תקפים של ישראכרט.',
			'visa and diners cards are not permitted for club installment transactions.': 'כרטיסי ויזה ודיינרס לא רשאים לבצע עסקאות מועדון בתשלומים.',
			'series of cards are not valid according to system definition (isracard vector5).': 'סידרת כרטיסים לא תקפה עפ”י הגדרת המערכת (VECTOR5 של ישראכרט).',
			'invalid service code according to system definitions (isracard vector6).': 'קוד שרות לא תקף עפ”י הגדרת המערכת (VECTOR6 של ישראכרט).',
			'the card`s prefix (2 figures) is invalid according to system definitions (isracard vector7).': 'קידומת הכרטיס (2 ספרות) לא תקפה עפ”י הגדרת המערכת (VECTOR7 של ישראכרט).',
			'invalid service code according to system definitions (visa vector12).': 'קוד שרות לא תקף עפ”י הגדרת המערכת (VECTOR12 של ויזה).',
			'invalid service code according to system definitions (visa vector13).': 'קוד שרות לא תקף עפ”י הגדרת המערכת (VECTOR13 של ויזה).',
			'immediate debit card is prohibited for executing credit transaction.': 'לכרטיס חיוב מיידי אסור לבצע עסקת זכות.',
			'the card is not permitted to execute installment transactions according to alpha vector no. 31.': 'כרטיס לא רשאי לבצע עסקאות בתשלומים עפ\"י וקטור 31 של לאומי קארד.',
			'the card is not permitted for telephone and signature-only transactions according to alpha vector no. 31.': 'כרטיס לא רשאי לבצע עסקאות טלפוניות וחתימה בלבד עפ\"י ווקטור 31 של לאומי קארד.',
			'the card is not permitted for telephone transactions according to alpha vector no. 31.': 'כרטיס אינו רשאי לבצע עסקאות טלפוניות עפ\"י וקטור 31 של לאומי קארד.',
			'credit is not approved for immediate debit cards.': 'אשראי לא מאושר לכרטיסי חיוב מיידי.',
			'credit is not approved for foreign cards.': 'אשראי לא מאושר לכרטיסי חו\"ל.',
			'incorrect club code.': 'קוד מועדון לא תקין.',
			'the card is not permitted to execute flexible credit transactions (adif/30+) according to system definitions (diners vector21).': 'כרטיס לא רשאי לבצע עסקאות אשראי גמיש (עדיף +30/) עפ\"י הגדרת המערכת (21VECTOR של דיינרס).',
			'the card is not permitted to execute immediate debit transactions according to system definitions (diners vector21).': 'כרטיס לא רשאי לבצע עסקאות חיוב מיידי עפ\"י הגדרת המערכת (VECTOR21 של דיינרס).',
			'the payment amount is too low for credit transactions.': 'סכום לתשלום בעסקת קרדיט קטן מידי.',
			'incorrect number of installments for credit transaction.': 'מספר תשלומים לעסקת קרדיט לא תקין.',
			'zero ceiling for this type of card for regular credit or credit transaction.': 'תקרה 0 לסוג כרטיס זה בעסקה עם אשראי רגיל או קרדיט.',
			'zero ceiling for this type of card for immediate debit credit transaction.': 'תקרה 0 לסוג כרטיס זה בעסקה עם אשראי חיוב מיידי.',
			'zero ceiling for this type of card for immediate debit in dollars.': 'תקרה 0 לסוג כרטיס זה בעסקת חיוב מיידי בדולרים.',
			'zero ceiling for this type of card for telephone transaction.': 'תקרה 0 לסוג כרטיס זה בעסקה טלפונית.',
			'zero ceiling for this type of card for credit transaction.': 'תקרה 0 לסוג כרטיס זה בעסקת זכות.',
			'zero ceiling for this type of card for installment transaction.': 'תקרה 0 לסוג כרטיס זה בעסקת תשלומים.',
			'american express card issued abroad not permitted for instalments transaction.': 'כרטיס אמריקן אקספרס אשר הונפק בחו\"ל לא רשאי לבצע עסקאות בתשלומים.',
			'jcb cards are only permitted to carry out regular credit transactions.': 'כרטיסי JCB רשאים לבצע עסקאות רק באשראי רגיל.',
			'the amount in stars/points/miles is higher than the transaction amount.': 'סכום בכוכבים/נקודות/מיילים גדול מסכום העסקה.',
			'the club card is not within terminal range.': 'כרטיס מועדון לא בתחום של המסוף.',
			'star/point/mile transactions cannot be executed.': 'לא ניתן לבצע עסקת כוכבים/נקודות/מיילים בדולרים.',
			'dollar transactions cannot be executed for this type of card.': 'למסוף אין אישור לעסקה דולרית עם סוג אשראי זה.',
			'credit transactions cannot be executed with other than regular credit.': 'לא ניתן לבצע עסקת זכות עם אשראי שונה מהרגיל',
			'amount of discount on stars/points/miles is higher than the permitted.': 'סכום הנחה בכוכבים/נקודות/מיילים גדול מהמותר.',
			'forced transactions cannot be executed with credit/immediate debit card.': 'לא ניתן לבצע עסקה מאולצת לכרטיס/אשראי חיוב מיידי.',
			'the previous transaction cannot be cancelled (credit transaction or card number are not identical).': 'לא ניתן לבטל עסקה קודמת (עסקת זכות או מספר כרטיס אינו זהה).',
			'double transaction.': 'עסקה כפולה.',
			'the terminal is not permitted for index linkage of this type of credit.': 'למסוף אין אישור להצמדה למדד לאשראי זה.',
			'the terminal is not permitted for dollar linkage of this type of credit.': 'למסוף אין אישור להצמדה לדולר לאשראי זה.',
			'the card is invalid according to system definitions (isracard vector1).': 'כרטיס אינו תקף עפ”י הגדרת ה מערכת (וקטור 1 של ישראכרט).',
			'unable to execute the self-service transaction if the gas station does not have self service.': 'תחנות דלק לא ניתן לבצע \"שרות עצמי\" אלא \"שרות עצמי בתחנות דלק\".',
			'credit transactions are forbidden with stars/points/miles.': 'אסור לבצע עיסקת זכות בכוכבים/נקודות/מיילים.',
			'dollar credit transactions are forbidden on tourist cards.': 'אסור לבצע עיסקת זכות בדולר בכרטיס תייר.',
			'phone transactions are not permitted on club cards.': 'בכרטיס מועדון לא ניתן לבצע עסקה טלפונית.',
			'application error.': 'שגיאה יישומית.',
			'transaction amount missing or zero.': 'סכום העסקה חסר או אפס.',
			'no communication to pelecard.': 'אין תקשורת לפלא-קארד.',
			'doubled transaction.': 'עסקה כפולה.',
			'terminal number does not exist.': 'מספר מסוף לא קיים.',
			'terminal executes broadcast and/or updating data. please try again later.': 'מסוף מבצע שידור ו/או מעדכן נתונים אנא נסה שנית מאוחר יותר.',
			'user name and/or password not correct. please call support team.': 'שם משתמש ו/או סיסמה לא נכונים אנא פנה למחלקת התמיכה.',
			'user password has expired. please contact support team.': 'פג תוקף סיסמת המשתמש אנא פנה למחלקת התמיכה.',
			'please enter confirm password': 'בבקשה הכנס אימות סיסמה',
			'the passwords must match': 'הסיסמאות צריכות להיות תואמות',
			'please confirm password': 'אנא אשרו את הסיסמה',
			'locked user. please contact support team.': 'משתמש נעול אנא פנה למחלקת התמיכה.',
			'blocked terminal. please contact account team.': 'מסוף חסום אנא פנה להנהלת חשבונות.',
			'token number abnormal.': 'מספר טוקן לא תקין.',
			'user is not authorized in this terminal.': 'משתמש לא רשאי לבצע פעולות במסוף זה.',
			'validity structure invalid. use mmyy structure only.': 'מבנה תוקף לא תקין יש להשתמש במבנה MMYY בלבד.',
			'ssl verifying access is blocked. please contact support team.': 'גישה לאימות תעודת אבטחה חסומה אנא פנה למחלקת התמיכה.',
			'data not exist.': 'לא קיימים נתונים.',
			'general error. please contact support team.': 'שגיאה כללית אנא פנה למחלקת התמיכה.',
			'necessary values are blocked/wrong.': 'ערכים נחוצים חסרים/שגויים.',
			'general error. repeat action.': 'שגיאה כללית אנא פנה למחלקת התמיכה.',
			'necessary values missing to complete installments transaction': 'ערכים נחוצים חסרים לעסקת תשלומים.',
			/*end pele card errors*/

			'removed': 'הוסר',

			'type in zip code': 'הקלידו מיקוד',
			'type in city': 'הקלידו עיר או ישוב המשלוח',
			'type your address': 'הקלידו כתובת',
			'or choose branch for pickup': 'או בחרו חנות לאיסוף עצמי',
			'pickup the order from one of the branches at your time': 'איספו את ההזמנה מאחד הסניפים בשעה שנוחה לכם',
			'we serve your area': 'אנו משרתים את אזורכם',
			'pickup service only': 'שירות איסוף עצמי בלבד',
			'<< back': '<< חזרה',
			'limited to {deliveryitemslimit} products': 'מוגבל ל-{deliveryItemsLimit} מוצרים',
			'limited to products marked with "{tagname}" tag only': 'מוגבל למוצרים המסומנים בתגית "{tagName}" בלבד',
			'has no items limit': 'ללא הגבלת מוצרים',
			'choose the pickup location': 'בחר את נקודת האיסוף',
			'products limit': 'מגבלת מוצרים',
			'this delivery has a limit of {deliveryitemslimit} products': 'במשלוח זה קיימת הגבלה של {deliveryItemsLimit} מוצרים',
			'if you wish to add another product, remove one from the cart first': 'אם ברצונכם להוסיף מוצר נוסף, הסירו תחילה מוצר אחר מהעגלה',
			'continue': 'להמשיך',
			'or change the delivery method': 'או החליפו סוג משלוח',
			'change delivery method': 'החליפו סוג משלוח',
			'are you sure you want to change the delivery type': 'האם ברצונכם להחליף סוג משלוח',
			'changes in delivery times may apply': 'ייתכן שוני בזמני המשלוח',
			'changes in inventory between the stores may apply': 'ייתכן שוני במלאי בין החנויות השונות',
			'yes, change delivery method': 'כן, החליפו סוג משלוח',
			'no, stay in current delivery': 'לא, השארו במשלוח זה',
			'unfortunately we didn\'t find any stores in this area': 'לצערנו לא מצאנו חנויות באזור זה',
			'try a different area name': 'נסה אזור אחר',
			'please type a valid address': 'אנא הקלידו כתובת חוקית',
			'please add street and house number': 'אנא הוסיפו שם רחוב ומספר בית',
			'delivery method in this area': 'סוג משלוח באזור זה',
			'apologies': 'מתנצלים',
			'sorry, we do not deliver to your area': 'נכון לעכשיו אנחנו לא מבצעים משלוחים לאזורכם',
			'please type in your email and we will let you know when we expand to your location': 'השאירו לנו את כתובת הדוא"ל שלכם ונעדכן אתכם כשנגיע לאזורכם',
			'try a different address': 'נסו כתובת אחרת',
			'try a different city': 'נסו עיר אחרת',
			'try a different zip code': 'נסו מיקוד אחר',
			'keep in touch': 'עדכנו אותי',
			'your chosen delivery type is': 'בחרתם לקבל את ההזמנה באמצעות',
			'change to ': 'שנו ל',
			'branch': 'סניף',
			'from branch': 'מסניף',
			'delivery products limit exceeded': 'חריגה מהגבלת מוצרים',
			'see you soon': 'נפגש בסניף',
			'the order will wait for you at the store': 'ההזמנה תמתין לכם ארוזה ומוכנה',
			'nagish_close_component': 'הסתר',
			'chametz': 'חמץ',
			'passoverkosher': 'כשר לפסח כל השנה',
			'passoverkoshertemporarily': 'כשר לפסח זמני',
			'passoverkitniyotkosher': 'כשר לפסח כל השנה לאוכלי קטניות',
			'passoverkitniyotkoshertemporarily': 'כשר לפסח זמני לאוכלי קטניות',
			'meat': 'כשר בשרי',
			'milky': 'כשר חלבי',
			'pareve': 'כשר פרווה',
			'israelmilk': 'כשר חלב ישראל',
			'israelcooking': 'בישול ישראל',
			'sabbathobservingfactory': 'מפעל שומר שבת',
			'noshviitconcern': 'ללא חשש שביעית',
			'noorlaconcern': 'ללא חשש עורלה',
			'notevelconcern': 'ללא חשש טבל',
			'noovernightconcern': 'ללא חשש לינת לילה',
			'nogeniza': 'ללא חשש גניזה',
			'recipes and allergies': 'רכיבים ואלרגנים',
			'list of ingredients': 'רשימת רכיבים',
			'allergies contained': 'אלרגנים כלולים',
			'possible allergies': 'אלרגנים שעשויים להיות כלולים',
			'about the product': 'אודות המוצר',
			'extra info and instructions': 'מידע נוסף והנחיות',
			'supervisions': 'כשרויות',
			'usage warnings': 'אזהרות שימוש ובטיחות',
			'storage instructions': 'הוראות אחסון',
			'usage instructions': 'הוראות הכנה ושימוש במוצר',
			'fat percentage': 'אחוז שומן',
			'cream percentage': 'אחוז שמנת',
			'fruit percentage': 'אחוז פרי',
			'alcohol percentage': 'אחוז אלכהול',
			'ph': 'אחוז הגבה (pH)',
			'country of origin': 'ארץ ייצור',
			'date taken into effect': 'תאריך תחילת תוקף',
			'height': 'גובה',
			'depth': 'עומק',
			'width': 'רוחב',
			'net width': 'משקל נטו',
			'gross width': 'משקל ברוטו',
			'general information': 'פרטים כללים',
			'nutrition values': 'ערכים תזונתיים',
			'the waiting period will not exceed two hours': 'אספקה תוך שעתיים מהמועד הנבחר',
			'at_hour': 'בשעה',
			'special_reminder_bubble_title': 'חסכו!',
			'special_reminder_awarded_bubble_title': 'חסכתם!',
			'special_reminder_button_title': 'לחצו וחסכו!',
			'add products to earn this promotion': 'הוסיפו מוצרים למימוש המבצע',
			'done': 'סיום',
			'select your benefit': 'בחרו את ההטבה',
			'show more specials': 'צפו בעוד מבצעים',
			'show less specials': 'צפו בפחות מבצעים',
			'coupon_requirements_error': 'אינכם עומדים בתנאי הקופון',
			'add products': 'הוסיפו מוצרים',
			'remaining credit': 'יתרת תקציב',
			'budget': 'תקציב',
			'cart amount higher': 'סכום העגלה גבוה',
			'than your credit': 'מיתרת התקציב שנותרה',
			'edit cart': 'עריכת עגלה',
			'cart total': 'סה"כ בעגלה',
			'to complete the purchase, remove products from the cart valued at': 'להשלמת הרכישה הסירו מוצרים מהעגלה בשווי של',
			'this user is not set as credit customer': 'הלקוח אינו מוגדר כלקוח בהקפה',
			'cart amount higher than your credit. to proceed please remove at least': 'סכום ההזמנה חורג מהמסגרת שהוקצתה ללקוח זה. על מנת לסיים יש להוריד מההזמנה מוצרים בשווי לפחות',
			'please verify that you are not a robot': 'אנא סמן/י כי אינך רובוט',
			'all conditions must be marked in order to complete the order': 'יש לסמן את כל התנאים על מנת לסיים את ההזמנה',
			'similar products': 'מוצרים דומים',
            'additional special\'s variety': 'מגוון מוצרי מבצע',
			'select from variety': 'בחרו ממגוון מוצרים',
            'product info disclaimer': 'אין להסתמך על הפירוט המופיע באתר על מרכיבי המוצר, יתכנו טעויות או אי התאמות במידע, הנתונים המדויקים מופיעים על גבי המוצר. יש לבדוק שוב את הנתונים על גבי אריזת המוצר לפני השימוש.',
			'employee discount': 'הנחת עובדים',
			'card id': 'מספר הכרטיס',
			'discount': 'הנחה',
			'remaining obligo': 'יתרת התקציב',
			'monthly obligo': 'תקציב חודשי',
			'club name': 'שם מועדון',
			'obligo type': 'סוג התקציב',
			'monthly discount obligo': 'תקציב הנחה חודשי',
			'cardid: required error': 'אנא הכניסו מספר הכרטיס',
			'pin: required error': 'אנא הכניסו את מספר Pin',
			'employee discount card holder?': 'מגיע לך הנחת עובדים?',
			'employee discount card data': 'פרטי חשבון הנחת עובדים',
			'employee discount account not found': 'לא נמצא חשבון עובדים',
			'estimated employee savings': 'הנחת עובדים משוערת',
			'unlimited': 'ללא הגבלה',
			'getting data...': 'מקבל נתונים...',
			'store is filtered': 'החנות מסוננת',
			'what\'s your lifestyle?': 'מהו אורח חייכם?',
			'filter entire store': 'סננו את כל החנות',
			'filter the entire store according to your preferred lifestyle or diet!': 'סננו את כל החנות לפי אורח החיים או הדיאטה המועדפת עליכם!',
			'filtering store': 'מסנן חנות',
			'the entire store is now filtered by': 'כל החנות מסוננת לפי',
			'clear all filters': 'ניקוי כל המסננים',
			'back to filters': 'בחזרה למסננים',
			'all filters': 'כל המסננים',
			'filters': 'מסננים',
			'show more': 'הצג הכל',
			'show less': 'הצג פחות',
			'permanent_filters_legal_text': 'בעת השימוש באפשרויות הסינון השונות המוצעות באתר, החברה אינה מתחייבת כי לא יהיו תקלות ושגיאות באתר בכלל ובביצוע הסינון בפרט. אין החברה מתחייבת כי סיווג המוצרים לקטגוריות יהיה מדויק ולא יכלול מוצרים אשר אינם עונים על הקריטריונים הנדרשים. השימוש במידע המתקבל, נעשה באחריותך הבלעדית, לרבות בנוגע לכל נזק שייגרם כתוצאה מהשימוש במידע או מהסתמכות עליו. יש לבדוק כל מוצר, לרבות בכל הנוגע למרכיביו, באופן פרטני. אתה תהיה אחראי לאמת כל מידע אל מול נתוני היצרן של אותו מוצר ובמקרה של סתירה בין המידע המוצג באתר למידע המוצג על ידי היצרן, יש להתייחס למידע שמוצג על ידי היצרן. אין באמור לעיל בכדי ליצור אחריות, בין אם לנזקים עקיפים או ישירים, של החברה בקשר עם המידע שמוצג על ידי יצרן של מוצר כזה או אחר.',
			'we couldn\'t find any products': 'לא נמצאו מוצרים',
			'we couldn\'t find any specials': 'לא נמצאו מבצעים',
			'try editing your filters': 'נסו לערוך את המסננים',
			'clear_all_filters_2': 'נקו את כל המסננים',
			'or': 'או',
			'edit global filters': 'עריכת מסננים קבועים',
			'verify account': 'אימות חשבון',
			'you are now registered': 'ההרשמה בוצעה בהצלחה',
			'please verify your account': 'אנא אמתו את חשבונכם',
			'there was a problem verifying your account': 'there was a problem verifying your account',
			'please contact customer service': 'please contact customer service',
			'before your first purchase, a verification code will be sent to your mobile phone': 'לפני הרכישה הראשונה, יישלח קוד אימות למכשיר הנייד שלכם',
			'send sms': 'שליחה',
			'enter the code that was sent to your phone': 'הכניסו את קוד האימות שנשלח למכשיר הנייד שלכם',
			'change number': 'שינוי מספר',
			'verify': 'אימות',
			'i didn\'t receive the code (resend)': 'לא קיבלתי את קוד האימות (שליחה שוב)',
			'the code is invalid or expired': 'קוד האימות שגוי או פג תוקף',
			'your account is verified': 'תהליך האימות הושלם בהצלחה',
			'invalid verification code': 'קוד אימות שגוי',
			'phone number': 'מספר טלפון',
			'please enter valid email': 'הכנס כתובת מייל תקינה',
			'enter valid phone number (10 digits)': 'הכנס מספר טלפון תקין (10 ספרות)',
			'loyalty club details': 'פרטי חשבון מועדון',
			'id': 'תעודת זהות',
			'loyalty terms': 'תקנון המועדון',
			'loyalty_terms_confirm': 'אני מאשר/ת שקראתי את',
			'please enter id': 'נא להזין תעודת זהות',
			'invalid mobile phone': 'מספר טלפון נייד לא תקין',
			'please confirm that you accept the terms': 'נא לוודא שהינכם מסכימים לתנאי השימוש',
			'member number': 'מספר מועדון',
			'you have successfully registered': 'נרשמת בהצלחה',
			'for the customer club': 'למועדון הלקוחות',
			'for the customer club and the website': 'למועדון הלקוחות ולאתר האונליין',
			'website registration': 'הרשמה לאתר',
			'please enter a 9-digit number': 'אנא הקלידו מספר ת.ז בן 9 ספרות',
			'only digits should be typed': 'יש להקליד ספרות בלבד',
			'please let me know about promotions on site and club': 'הנני מאשר/ת קבלת עדכונים והטבות בדוא"ל ובמסרונים מהאתר ומהמועדון',
			'organization management': 'ניהול ארגון',
			'payment by organization credit': 'חיוב מתקציב הארגון',
			'sorry, your organization account is inactive': 'מצטערים, חשבון הארגון אינו פעיל',
			'sorry, there are no branches assigned to this organization': 'מצטערים, לא מחוברים סניפים לארגון זה',
			'please contact your organization admin': 'אנא פנה לאחראי הארגון',
			'credit cards can not be added. you are assigned to an organization': 'לא ניתן להוסיף כרטיס אשראי. הינך משוייך לארגון',

			'cancel order': 'ביטול הזמנה',
			'are you sure you want to cancel this order': 'האם אתם בטוחים שברצונכם לבטל את ההזמנה',
			'order #': 'מספר הזמנה',
			'keep the order': 'השאירו את ההזמנה',
			'your order was cancelled': 'הזמנתכם בוטלה',
			'this order will not be charged': 'הזמנה זו לא תחויב',
			'a confirmation email was sent to {email}': ' אימייל אישור נשלח ל {email}',
			'your payment method will not be charged': 'אמצעי התשלום שלך לא יחויב',
			'no available deliveries in the upcoming days': 'לא נותרו משלוחים פנויים בימים הקרובים',
			'required error': 'אנא הזינו {{name}}',
			'no data on this order': 'אין נתונים על הזמנה זו',
			'payment failed': 'ישנה בעיה בתשלום',
			'back to payment': 'בחזרה לתשלום',
			'remove item from cart': 'הסרת המוצר מהעגלה',
			'to the selected delivery area we only send products marked with "{tagname}" tag': 'לאזור המשלוח שבחרתם אנו שולחים מוצרים המסומנים בתגית "{tagName}" בלבד',
			'you either remove the product from the cart or change the delivery area': 'תוכלו להסיר מוצר זה מהעגלה או להחליף את אזור המשלוח',
			'or change the delivery area': 'או החליפו אזור משלוח',
			'we will contact you in order to schedule the delivery hours': 'אנחנו ניצור איתך קשר בכדי לתאם את שעת המשלוח',
			'delivery price': 'מחיר משלוח',
			'ordered': 'הוזמן',
			'address_warning_enter_house_num': 'אנא הכניסו מספר בית',
			'so we can assign you to the right branch': 'כך שנוכל לשייך אתכם לסניף המתאים',
			'items to cart': 'מוצרים לסל',

			'your products from a website': 'המוצרים שלכם מאתר',
			'inventory availability may vary according to the delivery area': 'ייתכנו שינויים בזמינות המלאי בהתאם לאזור המשלוח והחנות',
			'please note - we could not identify {number}': 'שימו לב - לא הצלחנו לזהות {number}',
			'more products': 'מוצרים נוספים',
			'more product': 'מוצר נוסף',
			'from the {name} website': 'מאתר {name}',
			'out of stock': 'חסר במלאי',
			'added to cart': 'נוסף לעגלה',
			'finish & go to cart': 'סיום ומעבר לעגלה',
			'the site is active only for organization users': 'האתר פעיל למשתמשים ארגוניים בלבד',
			'login / register using the e-mail address ': 'התחברו / הרשמו באמצעות כתובת הדואר האלקטרוני ',
			'approved by the organization': 'אשר אושרה ע"י הארגון',
			'please login': 'אנא התחברו',
			'please login or register': 'אנא התחברו או הירשמו',
			'sorry, this website is for organization users only': 'מצטערים, אתר זה מיועד למשתמשים ארגוניים בלבד',
			'you user is not assigned to an organization': 'המשתמש שלך לא משוייך לארגון',
			'sorry, you can not register with this email address': 'מצטערים, לא ניתן להרשם עם כתובת מייל זו',
			'sorry, you can not register with this email address. use the organization-approved email address': 'מצטערים, לא ניתן להרשם עם כתובת מייל זו. יש להשתמש בכתובת הדוא"ל המאושרת ע"י הארגון',
			'your account has not yet been verified via email - please verify by clicking on the link sent to your email inbox': 'חשבונכם טרם אומת. אנא וודאו כי אישרתם את הדואר האלקטרוני שנשלח לחשבונכם לאחר הרישום',
			'send again': 'שלחו שוב',
			'didn\'t receive an email': 'לא קבלתם דוא"ל',
			'user email address not found': 'כתובת הדוא"ל של המשתמש לא נמצאה',
			'a verification email will be sent to your email box. the link is valid for {link_ttl} minutes': 'מייל אימות נשלח לתיבת הדוא״ל שלכם. הקישור תקף למשך {link_ttl} דקות',
			'your account requires activation, email with activation link have been sent to your email. the link is valid for {link_ttl} minutes': 'על מנת להכנס לאתר נדרש אימות חשבונכם. שלחנו מייל לתיבת הדוא״ל שלכם, ובו קישור לאימות החשבון. הקישור תקף למשך {link_ttl} דקות.',
            'activation code expired': 'קוד האימות פג תוקף',
            'website login': 'כניסה לאתר',
            'send a new link': 'שלח קישור חדש',
            'sorry, this link has expired': 'מצטערים, פג תוקפו של הקישור זה',
            'a new link can be sent': 'ניתן לשלוח קישור חדש',
            'you have successfully registered for the site': 'נרשמתם בהצלחה לאתר',
            'this account has already been verified': 'חשבון זה כבר מאומת במערכת',
			'you may enter the trade site': 'תוכלו להיכנס לאתר הסחר',
            'this account is an organizational account. you may need to verify it similarly from time to time': 'חשבון זה הינו חשבון ארגוני. ייתכן ויהיה עליכם לאמת אותו בצורה דומה מעת לעת',
			'please verify your identity': 'אנא אמתו את זהותכם',
			'to verify your identity, please enter a phone number': 'על מנת לאמת את זהותכם, אנא הזינו מספר טלפון',
            'continue to registration': 'המשיכו להרשמה',
			'phone number does not match': 'מספר הטלפון אינו תואם',
			'user invitation not found': 'ההזמנה של המשתמש לא נמצאה',
			'user already registered': 'המשתמש כבר רשום',
			'phone number hint': 'רמז למספר טלפון',
			'account not verified': 'חשבון זה אינו מאומת',
			'sorry, there was an authentication error': 'מצטערים, חלה שגיאה באימות',
			'please try again or contact the person in charge of the organization': 'אנא נסו שנית או פנו לאחראי הארגון',
			'this account is an organizational account. you account verification has expired': 'חשבון זה הינו חשבון ארגוני. פג תוקפו של אימות של החשבון',
			'account verification succeed': 'חשבון אומת בהצלחה',
			'your account requires activation.': 'להשלמת ההרשמה יש לאמת את החשבון.',
			'email with activation link have been sent to your email.': 'קישור לאימות החשבון נשלח כעת לתיבת הדואר האלקטרוני שלכם.',
			'the link is valid for {link_ttl} minutes': 'הקישור תקף למשך {link_ttl} דקות',
			'apartment, suite, building, floor, etc.': 'קומה, מספר דירה וכו',
			'this user may already be on the system': 'ייתכן ומשתמש זה כבר קיים במערכת',
			'please try to login': 'אנא נסה להכנס לאתר',
			'delivery_type_1_times': 'מועדי משלוח',
            'delivery_type_2_times': 'מועדי איסוף עצמי',
			'wrong captcha token': 'טוקן של קאפצא שגוי',
			'wrong captcha host': 'שם הדומיין של קאפצא שגוי',
			'please type in your zip code': 'נא להקליד את המיקוד שלך',
			'find address': 'מצא כתובת',
			'you cannot edit this field before entering a zip code for your address': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
			'please choose your address': 'יש לבחור את הכתובת שלך',
			'you will be able to edit the address details': 'תהיה אפשרות לערוך את פרטי הכתובת',
			'you cannot edit this field': 'אין אפשרות לערוך שדה זה',
			'not editable error': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
			'not_editable_locked_empty error': 'יש לבחור את הכתובת מתוך רשימת הכתובות לאחר הכנסת המיקוד',
			'not_editable_locked error': 'כדי לשנות את הכתובת יש לחזור לבחירת כתובת משדה המיקוד',
			'zipcode error': 'אין אפשרות לערוך שדה זה',

			'user products list not found': 'רשימת המוצרים של משתמש לא נמצאה',
			'user products list is disabled for this retailer': 'רשימת המוצרים של משתמש מושבתת עבור קמעונאי זה',
			'my lists': 'הרשימות שלי',
			'my products': 'המוצרים שלי',
			'my products list': 'רשימת המוצרים שלי',
			'the products i usually purchase': 'המוצרים שאני בד"כ קונה',
			'products i usually purchase': 'המוצרים שאני בד"כ קונה',
			'future purchases will generate a list of popular items bought by you and update your products list': 'ברכישות הבאות שלך רשימה זו תתעדכן אוטומטית לרשימת המוצרים הפופולריים שלך',
			'in the meantime, you can use the popular items list on the website': 'בינתיים ניתן להיעזר ברשימת הפריטים הפופולריים באתר',
			'no products were selected': 'לא נבחרו מוצרים',
			'your time is precious': 'הזמן שלכם יקר',
			'we have prepared a list of your personal products': 'הכנו עבורך רשימה של המוצרים שלך',
			'we have prepared a list of products you usually purchase': 'הכנו עבורכם רשימה של המוצרים שאתם בד"כ קונים',
			'add products from the list': 'הוספת מוצרים מהרשימה',
			'in cart': 'בסל',

			'delivery precedence for senior citizens!': 'קדימות במשלוחים לאזרחים הותיקים!',
			'we keep delivery slots for senior citizens to allow high availability in this challenging time': 'אנו שומרים זמני משלוח עבור אזרחים ותיקים כדי לאפשר זמינות גבוהה בתקופה מאתגרת זו',
			'enter your id number': 'הזינו מספר תעודת זהות',
			'used for verification only, we arn\'t saving this number': 'לצורך בדיקת זכאות בלבד, אנו לא שומרים מספר זה',
			'another time, continue shopping': 'בפעם אחרת, המשיכו לאתר',
			'subject to delivery slots availability out of senior citizens allocation': 'בכפוף לזמינות זמני אספקה מתוך ההקצאה השמורה לאזרחים הותיקים, אין באמור להוות התחייבות',
			'the verification was successful': 'הבדיקה עברה בהצלחה',
			'your account is now tagged as "senior citizen"': 'חשבונכם מסומן כעת כ"חשבון אזרח ותיק"',
			'please enter your id': 'אנא הזינו את תעודת הזהות שלכם',
			'invalid id': 'תעודת זהות לא חוקית',
			'i am a senior citizen': 'אני אזרח ותיק',
			'may grant you priority delivery slots': 'עשוי להעניק עדיפות בחלונות משלוח',
			'select products and click import to cart': 'סמנו מוצרים ולחצו הוספה לסל',
			'no available slots': 'אין חלונות זמינים',

			'we use cookies to improve your experience': 'אנו עושים שימוש בקבצי cookies כדי לשפר את השירות וחוויית המשתמש',
			'we use cookies or other trackers to facilitate the use of the site, improve the performance and security of the site, personalize the content offered, including personalized advertising content, and compile traffic and navigation statistics. some cookies, other than functional and security cookies, require your consent to be placed.': 'אנו עושים שימוש בקבצי cookies כדי לשפר את השימוש, השירות ואבטחת האתר וכן לצורך שיפור החוויה האישית, התוכן המוצע כולל תוכן שיווקי ומדידת traffic ושימושיות. חלק מקבצי העוגיות דורשים את הסכמתך.',
			'accept': 'קבל',
			'accept all cookies': 'קבל את כל קבצי הcookies',
			'setup my cookies': 'הגדר את קבצי הcookies שלי',
			'managing cookies': 'ניהול קבצי cookies',
			'these cookies allow you to benefit from the services offered by the site, to optimize its use and personalization (in particular personalization of offers and advertisements) depending on your profile.': 'קבצי ה cookies מאפשרים לכם להנות מהשירותים המוצעים על ידי אתר זה, לשפר אותו ואת השירות האישי כולל פרסומות בהתאם להגדרות הפרופיל האישי.',
			'marketing': 'שיווק',
			'marketing cookies are used to improve the relevance of the ads you see and to make sure that you do not see the same ad multiple times.': 'אנו עושים שימוש בקבצי cookies שיווקיים כדי לשפר את דיוק השירותים והמודעות המוצגים ולמנוע הצגה כפולה.',
			'additional features': 'שירותים נוספים',
			'these cookies make it possible to activate additional features on the site, for example to facilitate your purchases.': 'קבצי cookies אלה מאפשרים לנו להפעיל שירותים נוספים באתר כגון כאלה המסייעים בתהליך הרכישה.',
			'audience measurement': 'מדידת קהלים',
			'these cookies allow us to obtain navigation statistics on our site. we use this data to identify possible malfunctions of the site, to improve the site, for marketing purposes, and in order to personalize your experience on the site.': 'קבצי cookies אלה מאפשרים לנו למדוד את הרגלי הגלישה באתר, אנו עושים שימוש במידע זה כדי לזהות תקלות אפשריות ולשפר את האתר כולל חוויה אישית בנוסף לצרכים שיווקיים.',
			'google analytics': 'Google analytics',
			'these cookies allow us to obtain statistics on the number of visits to our site (e.g., number of visits, most viewed pages, etc.). we use this data to understand our users better, check the performance of our content, get insights, and identify possible malfunctions of the site to make improvements.': 'קבצי cookies אלה מאפשרים לנו להשיג נתונים סטטיסטיים על מספר המבקרים וצפיות באתר. אנו עושים שימוש במידע זה כדי לנתח את אופן השימוש באתר לשפר תוכן, ביצועים ולקבל תובנות בנושאים כגון תקלות אפשריות ואיך לתקנן.',
			'functional (not configurable)': 'שימושיות (אין אפשרות הגדרה)',
			'these cookies allow you to remember your choices and preferences on your account and to provide features that facilitate the use of the site. they are essential for the proper functioning of the site and are strictly necessary for the provision of a service that you have expressly requested.': 'קבצי cookies אלה מאפשרים לנו לזכור את ההגדרות האישיות על חשבנוכם ולספק יכולות המשפרות את השימוש באתר והם חיוניים באופן מוחלט לשימוש האתר והספקת שירותים שנתבקשו באופן מפורש.',

			'ebt payment': 'תשלום EBT',
			'credit card': 'כרטיס אשראי',
			'add ebt card': 'הוספת כרטיס EBT',
			'add your ebt card': 'פרטי כרטיס EBT',
			'no card connected': 'כרטיס לא מחובר',
			'step': 'שלב',
			'card': 'כרטיס',
			'remove ebt card': 'הסרת כרטיס EBT',
			'remove': 'הסרה',
			'ebt snap': 'EBT SNAP',
			'ebt cash': 'EBT מזומן',
			'balance': 'יתרה',
			'eligible': 'זכאות',
			'amount to pay': 'סכום לתשלום',
			'includes 10% weight debit': 'כולל 10% פקדון מוצרים שקילים',
			'amount exceeded eligibility': 'הסכום עבר את הזכאות',
			'remaining order balance': 'יתרת הזמנה',
			'pay with credit card': 'שלמו באמצעות כרטיס אשראי',
			'please enter ebt snap or ebt cash amount': 'אנא הכניסו סכום EBT SNAP או EBT מזומן',
			'authorize your': 'אימות',
			'enter your pin on the next screen': 'הכניסו את קוד הPIN במסך הבא',
			'proceed to pin code': 'המשיכו לקוד הPIN',
			'please enter reset code': 'נא להזין קוד איפוס',
			'view all coupons': 'לרשימת הקופונים',
			'time slot unavailable': 'חלון זמן משלוח אינו זמין',
			'we\'re sorry, this time slot is no longer available. please select a different time': 'אנו מצטערים, חלון זמן המשלוח שבחרת כבר לא זמין. אנא בחר זמן משלוח אחר',
			'return to schedule': 'חזרה לזמני משלוח',
			'the coupon is in your cart': 'הקופון נמצא בעגלה שלך',
            'please select': 'אנא בחר',
			'sitemap': 'מפת האתר',
			'pages': 'עמודים',
			'the {product_count} products listed below will be': '{product_count} מוצרים הבאים',
			'removed from your cart': 'יוסרו מהעגלה ולא יסופקו',
			'out of stock products': 'המוצרים הבאים אינם במלאי',
			'confirm': 'אישור',
			'back to cart': 'חזרה לעגלה',
			'the items below will be removed from your cart': 'הפריטים הבאים יוסרו מהעגלה',
			'{product_count} out of stock products will not be supplied': '{product_count} מוצרים חסרים במלאי ולכן לא יסופקו',
			'{coupons_count} expired coupons cannot be redeemed': '{coupons_count} קופונים פגי תוקף ולא ניתן לממשם',
			'expired': 'פג תוקף',
			'products marked with': 'מוצרים המסומנים ב-',
			'limited quantity': 'כמות מוגבלת',
			'tag are limited to': 'תג מוגבל ל',
			'items only.': 'פריטים בלבד.',

			'custom-phone-validation-range': 'מספר הטלפון חייב להכיל {min} עד {max} ספרות',
			'custom-phone-validation-min': 'מספר הטלפון חייב להכיל מינימום {min} 6 ספרות',
			'custom-phone-validation-max': 'מספר הטלפון חייב להכיל מקסימום {max} ספרות',
			'invalid-phone': 'מספר טלפון לא תקין',
			'are you sure you want to remove all products from your shopping cart?': 'האם ברצונך להסיר את כל המוצרים מהעגלה?',
			'yes, clear cart': 'מחק את העגלה',
			'clear cart?': 'מחק את העגלה?',
			'you_can_not_add_more_than': 'לא ניתן להוסיף לעגלה יותר מ-',
			'items_marked_with_symbol': 'פריטים המסומנים בסמל זה',

			'not a club member yet': 'עדיין לא חברי מועדון',
			'join and save': 'הצטרפו וחסכו',
			'club membership gives you unique benefits, discounts and promotions': 'חברות במועדון מעניקה לכם הטבות, הנחות ומבצעים ייחודיים',
			'continue without a club': 'המשיכו בלי מועדון',
			'choose to which club you want to join/sign up': 'בחרו לאיזה מועדון ברצונכם להתחבר/להרשם',
			'logged': 'מחובר',
			'no.': 'מס\'',
			'our clubs specials': 'מבצעי המועדונים שלנו',
			'log in with your club member or sign up and receive unique benefits': 'התחברו עם חבר המועדון שלכם או הרשמו וקבלו הטבות ייחודיות',
			'after_register_title': 'התחברת בהצלחה',
			'you can now enjoy the club specials': 'כעת ביכולתך להנות מהטבות המועדון',
			'to_home_page': 'מעבר לדף הבית',
			'back to loyalty clubs': 'חזרה למועדונים',
			'validity': 'תוקף',
			'items are marked with this symbol': 'פריטים מסומנים בסמל זה',

			'a quick and easy way to login with your former account': 'דרך קלה ומהירה להתחבר לחשבונכם מהאתר הקודם',
			'no account associated with this email was found, please try again': 'לא נמצא חשבון המשוייך לדוא״ל זה, אנא נסו שנית',
			'this email address is used for an account on our previous site': 'לכתובת דוא"ל זו משוייך חשבון באתר הקודם',
			'in order to link your account information, a verification required via email': 'לצורך קישור פרטי החשבון והמועדונים לאתר החדש יש לבצע אימות באמצעות דוא"ל',
			'please enter the email address you previously registered with': 'אנא מלאו את כתובת הדוא"ל עמה נרשמת בעבר',
			'send me a verification code': 'שילחו לי קוד אימות',
			'or go to': 'או עברו',
			'to_login': 'להתחברות',
			'to_register': 'להרשמה',
			'or you can': 'או שתוכלו',
			'register with a different email': 'להרשם עם כתובת דוא"ל אחרת',
			'for support contact customer service:': 'לעזרה צור קשר עם שירות לקוחות:',
			'enter verification code': 'הכנס קוד אימות',
			'an email with verification code has been sent to': 'קוד אימות נשלח באמצעות אימייל לכתובת',
			'didn\'t receive the code?': 'לא קיבלת את הקוד?',
			'you may need to check your spam folder': 'ייתכן שתצטרך לבדוק את תיקיית הספאם שלך',
			'invalid code': 'הקוד שהוכנס אינו תקין',
			'please try again or': 'נסו שוב או',
			'resend a new code': 'שילחו לי קוד חדש',
			'choose a new password': 'יצירת סיסמא חדשה',
			'from now on you will use this password to login': 'סיסמה זו תשמש אותך בכניסה לאתר מעתה והלאה',
			'required fields are marked with *': 'שדות חובה מסומנים ב*',
			'welcome!': 'ברוכים הבאים!',
			'your account and loyalty club information has been successfully updated!': 'פרטי החשבון והמועדונים שלכם עודכנו בהצלחה!',
			'i own the loyalty club credit card': 'בבעלותי כרטיס האשראי של המועדון',
			'loyalty club term & conditions': 'לצפייה בתקנון המועדון',
			'EBT_card_is_required': 'חובה להוסיף כרטיס EBT',
			'register and start shopping!': 'הירשמו והתחילו לקנות! ',
			'add to shopping list': 'שמירה לרשימת קניות',
			'cancel_order_credit_card' : 'כרטיס אשראי',
			'cancel_order_credit_card_sum' : 'סה”כ בכרטיס אשראי',
			'choose shopping list': 'בחרו רשימת קניות',
			'create list': 'צרו רשימה חדשה',
			'choose existing list': 'בחרו רשימה',
			'cancel_order_ebt_card' : 'EBT card',
			'cancel_order_ebt_snap_refund' : 'EBT SNAP Refund',
			'cancel_order_ebt_cash_refund' : 'EBT Cash Refund',
			'cancel_order_updated_snap_balance' : 'Updated SNAP Balance',
			'cancel_order_updated_cash_balance' : 'Updated Cash Balance',
			'registration attempt has been blocked, please contact support': 'ניסיון ההרשמה נחסם, אנא צור קשר עם התמיכה',
			'account has been suspended, please contact support': 'החשבון הושעה, נא צור קשר עם התמיכה',
			'your tav plus': 'התו פלוס שלך',
			'add voucher': 'הוספת תו קניה',
			'amount exceeded order balance': 'הסכום חורג מיתרת ההזמנה',
			'tav_plus_credit_card_disclaimer_short': 'שימוש בתווים באתר אפשרי רק בתנאי שהוזן כרטיס אשראי כגיבוי לתשלום. סכום החיוב הסופי להזמנה ייקבע בזמן איסוף המוצרים ועשוי להשתנות בשל מוצרים שקילים, מוצרים חסרים או...',
			'tav_plus_credit_card_disclaimer_long': 'שימוש בתווים באתר אפשרי רק בתנאי שהוזן כרטיס אשראי כגיבוי לתשלום. סכום החיוב הסופי להזמנה ייקבע בזמן איסוף המוצרים ועשוי להשתנות בשל מוצרים שקילים, מוצרים חסרים או מוצרים תחליפיים. במקרה של חריגת החיוב מהסכום שהוזן למימוש בקניה או מיתרת הסכום בתו/גיפט קארד במועד איסוף המוצרים, יתרת הסכום תחויב בכרטיס האשראי . יש לשמור את התווים/גיפט קארד עד קבלת ההזמנה וביצוע החיוב בהתאם. כמו כן, בעת ביצוע ההזמנה היתרה הצבורה בתו תוותר ללא שינוי וביצוע העסקה יהיה אפשרי אך ורק ביחס ליתרת הצבירה בתו במועד התשלום בפועל. והכל בכפוף למפורט בתקנון. הבהרה ביחס לעובדי קבוצת אלקטרה, קבוצת יינות ביתן ומגה- עובד שיבחר לשלם באמצעות תווי קניה לא יהיה זכאי לקבל בנוסף את הנחת העובד הקבועה שהוא זכאי לקבל.',
			'for additional terms and details': 'לפרטים ותנאים נוספים בנוגע לתו פלוס',
			'go to site': 'לחצו',
			'your voucher details': 'פרטי תו הקניה שלכם',
			'please enter your voucher number': 'אנא הכניסו את מספר התו שלכם',
			'voucher number': 'מספר תו קניה',
			'if your voucher is a plastic card, please keep it in your possession until receiving your order': 'במידה ותוי הקניה שלכם הינם כרטיס פלסטיק, אנא שימרו עליהם ברשותכם לקבלת הזמנתכם',
			'see more': 'הרחב',
			'view more': 'הצג עוד',
			'view less': 'הצג פחות',
			'voucher': 'תו קניה',
			'create shopping list': 'יצירת רשימת קניות',
			'choose list': 'בחרו רשימה',
			'create new list': 'יצירת רשימה חדשה',
			'tav plus': 'תו פלוס',
			'tav_plus_card_is_required': 'חובה להוסיף כרטיס תו פלוס',
			'tav plus charge': 'חיוב בתו פלוס',
			'due multiple unsuccessful attempts ,the process cannot be completed': 'בשל ריבוי נסיונות , לא ניתן להשלים את התהליך',
			'multiple attempts': 'ניסיונות מרובים',
			'receipt': 'חשבונית',
			'receipt_option_title_1': 'חשבונית דיגיטלית',
			'receipt_option_title_2': 'גם וגם',
			'receipt_option_title_3': 'חשבונית מודפסת',
			'receipt_option_1': 'החשבונית תשלח באמצעות SMS',
			'receipt_option_2': 'החשבונית תשלח באמצעות SMS והעתק מודפס יצורף להזמנה שלך',
			'receipt_option_3': 'החשבונית תצורף להזמנה שלך',
			'delivery-fee-explain-default': 'סכום דמי המשלוח הסופי צפוי להתעדכן בעת התשלום ועשוי להיות נתון לשינויים עקב אזור/זמן המשלוח או כל הנחות נוספות.',
			'delivery_type_1_fee': 'דמי משלוח',
			'delivery_type_2_fee': 'איסוף עצמי',
			'includes_delivery_type_1_fee': 'כולל דמי משלוח',
			'includes_delivery_type_2_fee': 'כולל דמי איסוף עצמי',
			'not_includes_delivery_fee': '(לא כולל דמי משלוח)',
			'valid until': 'בתוקף עד',
			'proceed to checkout': 'לסיום קנייה',

			'change store': 'החליפו חנות',
			'change delivery area': 'שנה אזור משלוח',

			'add coupon': 'הוסף קופון',
			'your points': 'הנקודות שלך',
			'new': 'חדש',
			'buy with points': 'לקנות עם נקודות',
			'almost expired': 'פג תוקף בקרוב',
			'not clipped but in cart': 'לא הוסף אך מוצרים בעגלה',
			'clipped but not in cart': 'למימוש, הפריטים לא נוספו',
			'clipped externally': 'למימוש חיצוני',
			'personal coupons': 'קופונים אישיים',
			'may interest you': 'אולי יעניין אתכם',
			'clip for {x} points': 'הוסף עבור {x} נקודות',
			'not enough points': 'לא מספיק נקודות',
			'coupon expires today': 'תוקף הקופון יפוג היום',
			'coupon expires in {x} days': 'תוקף הקופון יפוג בעוד {x} ימים',
			'clip for': 'הוסף עבור',
			'valid': 'תוקף',
			'can be redeemed once': 'ניתן למימוש פעם אחת',
			'can be redeemed {x} times': 'ניתן למימוש {x} פעמים',
			'clip coupon': 'הוסף קופון',
			'product in cart': 'המוצר בעגלה',
			'add product': 'הוסף מוצר',
			'coupon clipped': 'הקופון בעגלה',

			'last_minute_recommendations_headline': 'יש לנו המלצות במיוחד בשבילך',
			'last_minute_recommendations_title_coupons': 'קופונים שיחסכו לכם כסף',
			'last_minute_recommendations_title_products_usually': 'אולי שכחת?',
			'last_minute_recommendations_title_products_complementary': 'אולי יעניין אתכם גם...',
			'last_minute_recommendations_title_specials_offers': 'מבצעים שלא כדאי לפספס',
			'last_minute_recommendations_title_products_offers': 'לא תרצו לפספס את המוצרים האלו',
			'add your credit/debit cards': 'הוספת כרטיס אשראי',
			'use my delivery address as my billing address*': 'השתמשו בכתובת המשלוח שלי ככתובת החיוב*',
			'add your credit/debit card': 'פרטי כרטיס אשראי',
			'*by checking this option you approve transfer of your address data to the payment provider': '*בסימון אפשרות זו אתם מאשרים להעביר את נתוני הכתובת שלכם לספק התשלומים',
			'all results': 'כל התוצאות',
			'there are no available items for this promotion': 'אין מוצרים זמינים למבצע זה כרגע',

			'account_deletion_common_customer_support_1': 'לתמיכה צרו קשר עם שירות הלקוחות:',
			'account_deletion_common_customer_support_2': 'אנא נסו שוב מאוחר יותר או צרו קשר עם שירות לקוחות',
			'account_deletion_account_title': 'מחיקת חשבון',
			'account_deletion_init_title': 'מחיקת חשבונכם',
			'account_deletion_recaptcha_description': 'לצורך מחיקת חשבונכם יש צורך באימות באמצעות מספר שלבים.',
			'account_deletion_authentication_description': 'קוד אימות נשלח באמצעות אימייל לחשבון {userEmail}',
			'account_deletion_authentication_help_spam': 'יכול להיות שתאלצו לבדוק את תיקיית הספאם שלכם',
			'account_deletion_authentication_help_code_1': 'לא קבלתם את הקוד?',
			'account_deletion_authentication_help_code_2': 'שלחו שוב',
			'account_deletion_authentication_help_error_try_again_1': 'אנא נסו שוב או',
			'account_deletion_authentication_help_error_try_again_2': 'שלחו קוד מחדש',
			'account_deletion_authentication_help_error_max_tries': 'חרגתם ממספר הניסיונות האפשריים, אנא נסו שוב מאוחר יותר.',
			'Expired code': 'קוד לא בתוקף',
			'Invalid code': 'קוד לא תקין',
			'account_deletion_confirmation_description_1': 'אתם עומדים',
			'account_deletion_confirmation_description_2': 'למחוק את חשבונכם לצמיתות',
			'account_deletion_confirmation_description_3': 'כל פרטי חשבונכם והזמנות עתידיות ימחקו',
			'account_deletion_confirmation_description_4': 'פעולה זו לא ניתנת לביטול',
			'account_deletion_confirmation_help_input_1': 'בהקלדת המילה "CONFIRM" אתם מאשרים את מחיקת חשבונכם',
			'account_deletion_confirmation_help_input_2': 'הכניסו המילה "CONFIRM"',
			'account_deletion_already_deleted_title': 'לא ניתן להמשיך',
			'account_deletion_already_deleted_description': 'חשבון זה נמצא בתהליך מחיקה',
			'account_deletion_notification_title': 'חשבונכם ימחק תוך {numOfDaysUntilDeletion} ימים',
			'account_deletion_notification_description': 'כל הזמנה שתוזמנה למשלוח או איסוף לאחר חלון זמן זה, לא תסופק',
			'account_deletion_error_title': 'אירעה שגיאת מערכת',
			'account_deletion_error_description': 'לא ניתן להמשיך עקב שגיאת מערכת',
			'add_units_coupon': 'הוסף {remaining} מוצרים כדי לקבל הנחה',
			'add_currency_coupon': 'הוסף {remaining} כדי לקבל הנחה',
			'add_measure_coupon': 'הוסף {remaining} {unit} כדי לקבל הנחה',
			'add_units_cart_coupon': 'הוסיפו {remaining} מוצרים',
			'add_unit_cart_coupon': 'הוסיפו מוצר {remaining}',
			'add_currency_cart_coupon': 'הוסיפו {remaining}',
			'add_measure_cart_coupon': 'הוסיפו {remaining} {unit}',
			'add_units_special_purchase': 'הוסף עוד {remaining}',
			'buy_units_total': 'קנה {total} מוצרים',
			'buy_unit_total': 'קנה מוצר {total}',
			'buy_currency_total': 'קנה ב {total}',
			'buy_measure_total': 'קנה {total} {unit}',
			'suggestions_dialog_header_1': 'מוצרים ממולצים כתחליף',
			'suggestions_dialog_header_2': 'אנא בחר תחליף ממגוון המוצרים הדומים שלנו',
			'suggestions_dialog_header_3': 'לא במלאי',
			'suggestions_dialog_header_4': 'מוצרים ממולצים כתחליף',
            'ok, continue' : 'אוקי, המשך',
            'change chosen time': 'שנה זמן נבחר',

			'hover description for cvv': 'מספר CVV (קוד אימות כרטיס) למרבית כרטיסי ויזה, Mastercard, כרטיסים בנקאיים, וכו \' זהו קוד של שלוש ספרות. בכרטיסי אמריקן אקספרס (AMEX), זה קוד בן ארבע ספרות ',
			'enter cvv number': '',

			'the expiration of these {coupon_number} coupons will not be valid for this chosen time': 'שימו לב, התוקף עבור {coupon_number} קופונים אלו	לא יהיו תקפים עבור הזמן שנבחר',
			'the coupon displayed below, will not be valid for this chosen time': 'הקופון המוצג למטה לא יהיה בתוקף עבור הזמן שנבחר',
			'these {coupon_number} coupons will not be valid for this chosen time': '{coupon_number} קופונים אלו לא יהיו תקפים עבור הזמן שנבחר',

			'the reward you have redeemed will be added automatically at discount': 'הכסף שמימשת מחכה לך בקופה',
			'your accumulated reward points will be added automatically at checkout': 'הנקודות שצברת מחכות לך בקופה',
			'congrats! you have accumulated': 'איזה כייף, צברת במועדון',
			'redeem now': 'רוצה לממש בקנייה זו',
			'points': 'נקודות',
			'congrats! you\'ve redeemed': 'איזה כיף, מימשת',
			'i have changed my mind': 'התחרטתי',
			'enjoy! we have updated your discount': 'תהנו! עדכנו לכם את ההנחה',
			'you have accumulated': 'צברת כ',
			'through {name}': 'במועדון {name}',
			'total after discounts and promotions': 'סה"כ אחרי הנחות ומבצעים',
			'payment with': 'תשלום ב',
			'you haven\'t accumulated any points on {name} yet': 'טרם צברת נקודות במועדון {name}',
			'in this purchase you have accumulated about {xxx points} reward points. the final calculation will be made after your order has been fulfilled and charged': 'בקנייה זו צברת כ {xxx points} נקודות החישוב הסופי יקבע לאחר ליקוט וחיוב ההזמנה',
			'accumulate another {xxx points} to get a discount on your next purchase': 'בצבירה נוספת של כ {xxx points} נקודות, תוכל להנות מהנחה בקנייה הבאה',
			'your personal payment code': 'הקוד שלך לתשלום במסעדה',
			'loyalty members enjoy the variety of promotions': 'חברי מועדון נהנים ממגוון מבצעים',
			'what kind of foodie are you?': 'איזה Foodies אתם?',
			'cooking at home or eating outside?': 'אוכלים בחוץ או מבשלים בבית?',
			'sign in/ sign up': 'התחברו / הצטרפו למועדון',
			'hi {customer name}, you\'ve accumulated {points accumulation} points on foodies program': 'שלום {Customer Name} , צברת {points accumulation} נקודות בתוכנית המועדון',
			'with an additional accumulation of {money value} ,you\'ll be able to redeem cashback.': 'עוד קנייה ב{money value} ותוכלו לממש את הנקודות שצברתם',
			'hi {customer name}, congrats! {cashback amount} is waiting for you on the cart': 'שלום {Customer Name} , תהנו ! {cashback amount} מחכים לך בקופה',
			'unredeemed': 'התחרטתי',
			'find out how much you\'ll earn from your purchases in a month.': 'גלו כמה כסף תרוויחו מהקניות שלכם בחודש',
			'what\'s your monthly shopping spend? (estimated)': 'כמה אתם מוציאים בחודש על קניות? (בערך, לא נתפוס אתכם במילה)',
			'in other words...': 'רק כדי לעורר לכם את התיאבון...',
			'it means you\'ll earn {yearly profit amount} per year': 'זה אומר שבשנה תרוויחו {yearly profit amount}',
			'the code is only valid for the next 30 minutes': 'הקוד תקף ל30 דקות בלבד',
			'price does not include bottle deposit': 'המחיר אינו כולל פיקדון',
			'price includes bottle deposit': 'המחיר כולל פיקדון',
			'questions and answers': 'שאלות ותשובות',
			'you\'ve accumulated {xxx points} points final calculation will be based on picking and payment': 'בקנייה זו צברת כ {xxx points} נקודות החישוב הסופי יקבע לאחר ליקוט וחיוב ההזמנה',
			'with an additional accumulation of {xxx points} points, you will receive a discount on your next purchase': 'בצבירה נוספת של כ {xxx points} נקודות, תוכל להנות מהנחה בקנייה הבאה',
			'you can choose where and how to redeem your rewards!': 'אתם בוחרים איפה ואיך לממש את הנקודות שלכם!',
			'here\'s a list of hundreds of external services to redeem points': 'לרשימת מאות המסעדות בהן ניתן לממש את הכסף שצברתם',
			'login / sign up to continue...it\'s easy!': 'התחברו / הצטרפו למועדון כדי להמשיך...זה פשוט!',
			'the locations in which you can redeem your rewards': 'הסניפים בהם תוכל לממש',
			'loyalty points': 'נקודות מועדון',
			'tenbis': 'תן ביס',
			'rak besmachot': 'רק בשמחות',
			'praxel': 'פרקסל',
			'cibus': 'סיבוס',
			'hitech zone': 'הייטקזון',
			'gold north': 'גולד צפון',
			'dolce vita': 'דולצ\'ה ויטה',
			'istudent': 'איי סטודנט',
			'cal': 'ויזה כאל',
			'direct insurance club': 'מועדון ביטוח ישיר',
			'yours club': 'מועדון שלך',
			'psagot': 'פסגות',
			'yerushalmi': 'ירושלמי עיריית ירושלים',
			'leumi goodies': 'לאומי גודיז',
			'pais plus': 'פיס פלוס מועדון מפעל הפיס',
			'raayonit': 'רעיונית',
			'balance to be paid': 'יתרה לתשלום',
			'please enter the card number to check the balance': 'הקלידו את מספר הכרטיס בכדי לבדוק את היתרה בו',
			'redeem': 'למימוש',
			'please save the voucher or gift card until you receive your order and are charged': 'נא לשמור את השובר או כרטיס המתנה עד שתקבל/י את ההזמנה והחיוב',
			'choose gift card/s': 'בחרו כרטיס/י מתנה',
			'add gift card': 'הוסף גיפט קארד',
			'redeem gift card & discount cards': 'למימוש גיפט קארד וכרטיסי הנחה',
			'for gift card and discount cards': 'למימוש גיפט קארד וכרטיסי הנחה',
			'gift_card_error_message': 'לא הצלחנו לעבד את הגיפט קארד, נא לנסות שנית או לנסות עם גיפט קארד אחר',
			'wrong_gift_card_amount_error': 'הסכום חורג מערך סל הקניות',
			'amount_exceeds_balance': 'הסכום חורג מהיתרה',
			'customercredit': 'לקוח בהקפה',
			"sorry, currently we don't deliver to this address for further inquiries call to the customer service.": 'נכון להיום, שירות המשלוחים עדיין לא מגיע לכתובת הזאת. לעזרה, אפשר להתקשר לשירות הלקוחות שלנו.',
			'gg_map_dialog_title': 'בחרו כתובת',
			'gg_map_dialog_hint': 'לא הצלחנו לאתר את מיקומכם. אנא נעצו את הסיכה בצורה מדויקת כדי שנוכל למצוא את הכתובת שלכם',
			'gg_map_alert_need_location': 'אנא הפעילו מיקום כדי להשתמש בגוגל מפות',
			'gg_map_alert_error': 'ישנן בעיות בהצגת גוגל מפות',
			'gg_map_alert_unsupport': 'מיקום גיאוגרפי אינו נתמך על ידי דפדפן זה',
			'autocomplete_adrs_didnt_find': 'אינכם מוצאים את הכתובת?',
			'autocomplete_adrs_choose_location': 'בחרו את מיקומכם במפה',
			'autocomplete_adrs_city_not_found': "מצטערים, העיר לא נמצאה. ניתן לבחור כתובת מתוך הצעות להשלמה אוטומטית או להצמיד את הכתובת באמצעות האפשרות 'מפות Google.",
			'autocomplete_adrs_adrs_not_found': "מצטערים, הכתובת לא נמצאה. ניתן לבחור כתובת מתוך הצעות להשלמה אוטומטית או להצמיד את הכתובת באמצעות האפשרות 'מפות Google.",
			'side menu': 'תפריט צד',
			'back': 'חזרה',
			'home_page_title': 'דף הבית',
			'login/register popup': 'חלון הרשמה/התחברות',
			'please enter': 'אנא הזינו',
			'please approve the policy': 'בבקשה אשר את תקנון האתר',
			'registration completed successfully': 'ההרשמה הושלמה בהצלחה',
			'you have logged in successfully': 'התחברת בהצלחה',
			'found x results for your search': 'נמצאו X תוצאות לחיפוש שלך',
			'sorry, your city was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'מצטערים, העיר לא נמצאה. אנא בחרו כתובת מתוך הצעות להשלמה אוטומטית או נעצו את הכתובת באמצעות גוגל מפות.',
			'sorry, your address was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'מצטערים, הכתובת לא נמצאה. אנא בחרו כתובת מתוך הצעות להשלמה אוטומטית או נעצו את הכתובת באמצעות גוגל מפות.',
			'sorry, your zip code was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'מצטערים, המיקוד שלך לא נמצא. ניתן לבחור כתובת מתוך הצעות להשלמה אוטומטית או להצמיד את הכתובת באמצעות האפשרות \'מפות Google\'.',
			'country: required error': 'אנא בחרו מדינה',
			'countryInput: required error': 'אנא הכניסו מדינה',
			'previous product': 'מוצר קודם',
			'next product': 'מוצר הבא',
			'cart_line_added_alert': 'נוסף לסל הקניות',
			'increase the amount': 'הגדלת הכמות',
			'reduce the amount': 'הפחתת הכמות',
			'camera opened': 'מצלמה נפתחה',
			'image': 'תמונה',
			'of': 'מתוך',
			'settings was successfully changed!': 'ההגדרות שונו בהצלחה',
			'previous item': 'מוצר קודם',
			'next item': 'מוצר הבא',
			'country_of_origin_title': 'ארץ מוצא',
			'origin_country_legal_text': 'שימו לב- ארץ המקור נכונה למועד ההזמנה. ייתכן וארץ המקור של המוצר שיסופק תהיה שונה, בהתאם למלאי הקיים בנקודת הליקוט במועד האספקה',
			'show password': 'הצג סיסמה',
			'after package deduction': 'בניכוי עלויות אריזה',
			'is this address correct? you can now edit and refine it': 'האם הכתובת נכונה? עכשיו אפשר לערוך ולדייק',
			'edit your address': 'ערכו את כתובתכם',
			'scanner_accessibility_text': 'סריקה. לא זמין לקורא מסך, ניתן לבצע קניה בתהליך הרגיל של חיפוש מוצר באמצעות תיבת החיפוש',
			'sale price': 'מחיר מבצע',
			'regular price': 'מחיר מחירון',
			'normalize price': 'מחיר רגיל',
			'the given phone number already exists in the system': 'מספר הטלפון הנתון כבר קיים במערכת',
			'contact_us': 'יצירת קשר',
			'how_can_we_help_you_today': 'איך נוכל לעזור לכם היום?',
			'leave_us_a_message': 'השאירו לנו הודעה ונחזור אליכם בהקדם',
			'fullname': 'שם מלא',
			'message': 'איך נוכל לעזור לכם?',
			'your_message_has_been_sent': 'ההודעה שלכם נשלחה',
			'thank_you_for_contact_us': 'תודה על שיצרתם איתנו קשר',
			'searchtext_storename_placeholder': '{שם חנות}',
			'reset': 'איפוס',
			'continue with email and password': 'כניסה באמצעות מייל וסיסמה',
			'continue with facebook': 'כניסה באמצעות פייסבוק',
			'continue with a one time password': 'המשך עם סיסמה חד-פעמית',
			'membership approval': 'אישור חברות',
			'state': 'מדינה',
			'zip': 'מיקוד',
			'address 2': 'כניסה + קומה + דירה',
			'invalid username or password': 'שם המשתמש או הסיסמה שגויים',
			'are you sure you want to log out? this action will cancel the login / registration process': 'האם אתם בטוחים שתרצו לצאת? פעולה זאת תבטל את ההתחברות / תהליך ההרשמה',
			'yes, log out': 'כן, לצאת',
			'invalid otp code. please try again': 'קוד OTP לא תקף. אנא נסו שוב.',
			'just one more step to go': 'עוד צעד אחד בלבד',
			'registering to the website automatically registers you to the club without any cost or obligations': 'הרשמה לאתר מחברת אתכם אוטומטית למועדון הלקוחות ללא עלות או התחייבות',
			'please enter the {number of digits} digit code sent to {otp credential} through {type of otp}': 'נא להזין את הקוד בעל {number of digits} הספרות שנשלח {type of otp} למספר {otp credential}',
			"few more details and we're done!": 'עוד כמה פרטים וסיימנו!',
			'i confirm that i have read the Loyalty Terms': 'אני מאשר/ת שקראתי את התנאים',
			'i agree the terms & conditions and the privacy policy': 'אני מסכים/ה לתנאים וההגבלות ולמדיניות הפרטיות',
			'new here? sign up': 'חדשים כאן? הירשמו',
			'do not have an account?': 'אין לכם חשבון?',
			'sign up': 'הירשמו',
			'already have an account?': 'כבר יש לכם חשבון?',
			'send code': 'שלח קוד',
			'phone number or email': 'מספר טלפון או מייל',
			'a one - time identification by sending a code to your mobile or email': 'זיהוי חד פעמי באמצעות שליחת קוד לטלפון או למייל שלכם',
			'log in or sign up': 'התחברות או הרשמה',
			'this number is connected with more than one email. please choose one of the following email addresses to continue with.': 'מספר זה משויך למספר כתובות מייל. בחרו בבקשה בכתובת מייל אחת כדי להמשיך.',
			'are you sure you want to continue with this email?': 'בטוחים שאתם רוצים להמשיך עם המייל הזה?',
			'choose one email to continue': 'בחרו בכתובת מייל אחת כדי להמשיך',
			'nofshonit': 'נופשונית',
			'max gift card': 'מקס גיפט קארד',
			'customer support phone number': 'מספר טלפון שירות לקוחות',
			'sort by ascending series': 'מיון בסדר עולה',
			'sort by descending series': 'מיון בסדר יורד',
			'complete_and_proceed_to_checkout': 'סיים והמשך לתשלום',
			'please notice, the following products are not available on this chosen time.': 'שים לב, המוצרים הבאים אינם מסופקים בזמן שנבחר',
			'the following products are not available on this chosen time.': 'המוצרים הבאים אינם מסופקים בזמן שנבחר',
			'if you continue, they will be removed from your cart.': 'אם תמשיכו, מוצרים אלו יוסרו מהעגלה.',
			'result for “{title}”': 'תוצאות עבור “{title}”',
			'find a product': 'חפשו מוצר',
			'results by brand:': 'תוצאות לפי מותג:',
			'please enter cvv to continue': 'יש להקליד CVV על מנת להמשיך',
			'checkout is opened on another screen':'דף התשלום נפתח במסך אחר',
			'loading coupons, please wait...': 'קופונים בטעינה, אנא המתינו...',
			'please wait, we are loading the products': 'אנא המתינו, אנחנו טוענים את המוצרים',
			'receipt': 'חשבונית',
			'api_error_message': 'ארעה שגיאה{error_message} (שגיאה {error_code}). אנא נסו שוב בעוד מספר דקות, אם הבעיה חוזרת פנו לשירות הלקוחות',
			'payment error': 'שגיאת תשלום',
			'error received': 'התקבלה שגיאה',
			'please check your credit card details or contact your credit card provider': 'אנא בדקו את פרטי כרטיס האשראי שלכם או פנו לספק האשראי שלכם.',
			'home': 'בית',
            'specials': 'מבצעים',
            'departments': 'כל המוצרים',
            'my list': 'הרשימה שלי',
            'coupons': 'קופונים',
            'contact us': 'צרו קשר',
            'accessibility statement': 'הצהרת נגישות',
            'scanner': 'סריקה',
            'customer service': 'שירות לקוחות',
            'cart': 'עגלה',
            'menu': 'תפריט',
            'address_form_field_label_city': 'עיר',
            'address_form_field_label_address': 'כתובת',
            'address_form_field_label_zip_code': 'מיקוד',
            'address_form_field_label_postal_code': 'מיקוד',
            'address_form_field_label_entrance': 'כניסה',
            'address_form_field_label_apartment_number': 'מספר דירה',
            'address_form_field_label_building_code': 'קוד בניין',
            'address_form_field_label_state': 'מדינה',
            'address_form_field_label_comments': 'הערות',
            'address_form_field_label_apartment_suite_unit': 'דירה / סוויטה / יחידה',
            'address_form_field_label_providence': 'מחוז',
            'address_form_field_label_more_details': 'פרטים נוספים',
			'address_form_field_label_floor': 'קומה',
			'address_form_field_label_street': 'רחוב',
			'address_form_field_label_house_number': 'מספר בית',

			'address_form_field_error_city': 'נא למלא עיר תקינה',
			'address_form_field_error_address': 'נא למלא כתובת תקינה',
			'address_form_field_error_zip_code': 'נא למלא מיקוד תקין',
			'address_form_field_error_postal_code': 'נא למלא מיקוד תקין',
			'address_form_field_error_entrance': 'נא למלא כניסה תקינה',
			'address_form_field_error_apartment_number': 'נא למלא מספר דירה תקין',
			'address_form_field_error_building_code': 'נא למלא קוד בניין תקין',
			'address_form_field_error_state': 'נא למלא מדינה תקינה',
			'address_form_field_error_comments': 'נא למלא הערות',
			'address_form_field_error_apartment_suite_unit': 'נא למלא דירה/סוויטה/יחידה תקינה',
			'address_form_field_error_providence': 'נא למלא מחוז תקין',
			'address_form_field_error_more_details': 'נא למלא פרטים נוספים',
			'address_form_field_error_floor': 'נא למלא קומה תקינה',
                
			'address_drop_pin_message_1': 'המיקום נשמר בהצלחה. ערוך במידת הצורך.',
			'address_drop_pin_message_cancel': 'ביטול כתובת נעוצה',

			'autocomplete_city_not_found_non_droppin': 'מצטערים, העיר לא נמצאה. אנא בחר כתובת מהרשימה או פנה לתמיכת לקוחות לקבלת סיוע.',
			'autocomplete_address_not_found_non_droppin': 'מצטערים, הכתובת לא נמצאה. אנא בחר כתובת מהרשימה או פנה לתמיכת לקוחות לקבלת סיוע.',
			'autocomplete_zip_code_not_found_non_droppin': 'מצטערים, המיקוד לא נמצא. אנא בחר כתובת מהרשימה או פנה לתמיכת לקוחות לקבלת סיוע.',

            'confirm_full_credit_card_charge_title': 'אישור חיוב מלא בכרטיס האשראי',
            'confirm_full_credit_card_charge_content': 'סכום התשלום EBT מוגדר ל- $0. האם אתה בטוח שברצונך לגבות את הסכום המלא מכרטיס האשראי שלך?',
            'confirm_full_credit_card_charge_content_btn_confirm': 'כן, גבו מכרטיס האשראי',
            'confirm_full_credit_card_charge_content_btn_back': 'חזרה',

            'pickup time:': 'זמן איסוף:',
            'pickup address:': 'כתובת לאיסוף:',
            'delivery time:': 'זמן קבלת המשלוח:',
            'delivery address:': 'כתובת למשלוח:',
            'on_day': '',
            'wish_to_change_delivery':'מעוניין לשנות את זמן קבלת המשלוח?',
            'wish_to_change_pickup':'רוצה לשנות את זמן האיסוף?',
            'select date & time':'בחירת תאריך ושעה',
            'confirm update':'אישור עדכון',
            'save & change delivery time':'שמירה ושינוי זמן המשלוח',
            'save & change pickup time':'שמירה ושינוי זמן האיסוף',
            'back to home page':'חזרה לדף הבית',
            'your order has been updated':'ההזמנה עודכנה',

            'checkout_finish_last_update_time_delivery': 'אפשר לעדכן את זמן קבלת המשלוח עד',
            'checkout_finish_last_update_time_pickup': 'אפשר לעדכן את זמן האיסוף עד',
            'forgot_sth_want_to_change_delivery': 'רוצה לשנות את זמן המשלוח?',
            'forgot_sth_want_to_change_pickup': 'רוצה לשנות את תאריך האיסוף?',

            'update_order_popup_title_def': 'אפשר לעדכן את פרטי ההזמנה או את זמן ה {delivery method}.',
            'update_order_popup_order_item_def': 'עדכון פריטי הזמנה',
            'update_order_popup_delivery_time_def': 'עדכון זמן המשלוח',
            'update_order_popup_pickup_time_def': 'עדכון זמן האיסוף',
            'update_order_popup_cancel_def': 'רוצים לבטל את ההזמנה? לחצו כאן כדי לבטל אותה {cancel order}',
            'update_order_popup_lastest_time_def': 'ניתן לעדכן את ההזמנה {delivery method} עד {time calculate}',
            'update_order_popup_err_cant_update_time': 'עדכון מועד ה-{delivery method} שלך אינו זמין',

            'irre_sales_collect_one': 'ה{saleType} הזה לא יהיה תקף לפרק הזמן המעודכן ותחויבו לפי המחיר הרגיל',
            'irre_sales_collect_many': 'ה{count} {saleType} האלה לא יהיו תקפים לפרק הזמן המעודכן ותחויבו לפי המחיר הרגיל',
            'irre_sales_checkout_one': 'עם עדכון ההזמנה הזו, ה{saleType} הבא לא יהיה תקף עוד ותחויבו על המוצר לפי מחירו הרגיל',
            'irre_sales_checkout_many': 'עם עדכון ההזמנה הזו, ה{count} {saleType} הבאים לא יהיו תקפים עוד ותחויבו על המוצרים לפי מחירם הרגיל',
            'irre_sales_oos_one': 'המוצרים הבאים אינם זמינים כעת ויוסרו מהסל שלך.',
            'irre_sales_oos_many': 'המוצרים הבאים אינם זמינים כעת ויוסרו מהסל שלך.',
            'irre_sales_btn_continue': 'אוקיי, עדכן',
            'irre_sales_btn_change_slot': 'שינוי תאריך ה{delivery method}',
            'irre_sales_btn_cancel_update': 'ביטול עדכון',
            'thank you for your order!':'תודה על הזמנתך!',
			'delivery_disclaimer_default_text':'ניתן לבחור את חלון המשלוח במהלך התשלום בלבד',

			"add your credit card": "הוספת כרטיס אשראי",
			"payment methods": "אמצעי תשלום",
			"your credit cards": "כרטיסי האשראי שלך",
			"add a credit card": "הוספת כרטיס אשראי",
			"gift cards": "כרטיסי מתנה",
			"add gift cards": "הוספת כרטיסי מתנה",
			"choose gift card/s": "בחרו כרטיס/י מתנה",
			"please enter the card number to check balance": "הקלידו את מספר הכרטיס בכדי לבדוק את היתרה בו",
			"{gift card name} details": "פרטי {Gift card name}",
			"on the next screen, you can select your payment amount": "במסך הבא תוכלו לבחור את הסכום לתשלום",
			"balance:": "יתרה:",
			"please enter amount to pay": "יש להקליד סכום לתשלום",
			"amount exceeds balance": "הסכום חורג מהיתרה",
			"please save the voucher or gift card until you receive your order and are charged.": "נא לשמור את השובר או את כרטיס המתנה עד קבלת ההזמנה והחיוב.",
			"remove card": "הסרת כרטיס",
			"see more": "הצג עוד",
			"show less": "הצג פחות",
			"amount exceeds shopping cart value": "הסכום חורג מערך סל הקניות",
			'due to suspicious activity on your account, we have taken protective measures and blocked the possibility of making purchases on our website. please contact customer support to clarify the issue as soon as possible.':'עקב פעילות חשודה בחשבונך, נקטנו אמצעי הגנה וחסמנו את האפשרות לבצע רכישות באתרנו. אנא צרו קשר עם תמיכת הלקוחות כדי להבהיר את הנושא בהקדם האפשרי',
			'user not connected to organization': 'משתמש לא מחובר לארגון',

			'default_first_time_popup_title': 'ברוכים הבאים!',
			'default_first_time_popup_sub_title': 'איך תרצו לקבל את ההזמנה שלכם?',
			'default_first_time_popup_sub_title_pickup': 'בחרו חנות בקרבתכם לאיסוף נוח!',
			'default_first_time_popup_sub_title_delivery': 'הזינו את הכתובת שלכם כדי לראות אפשרויות משלוח זמינות בקרבתכם',
			'default_returning_popup_title': 'שינוי אזור',
			'default_returning_popup_sub_title': 'איך תרצו לקבל את ההזמנה שלכם?',
			'default_returning_popup_sub_title_pickup': 'בחרו מיקום איסוף חדש',
			'default_returning_popup_sub_title_delivery': 'מצאו אפשרויות משלוח חדשות',

			'default_one_store_popup_title': 'חדשות נהדרות!',
			'default_one_store_popup_sub_title': 'אנחנו יכולים לספק לאזור שלכם!',
			'default_one_store_popup_continue_button_text': 'המשך קניות',

			'default_multiple_store_popup_title': 'יש לכם מזל!',
			'default_multiple_store_popup_sub_title': 'יש לנו מספר חנויות לשירות באזור שלכם',

			'default_no_store_popup_title': 'אנחנו מצטערים',
			'default_no_store_popup_sub_title': 'כרגע אנחנו לא מספקים לאזור שלכם. השאירו את הדוא"ל שלכם , ואנו נודיע לכם כשנרחיב לאזור שלכם.',

			'delivery_input_zip_code_placeholder': 'מיקוד (לדוגמה: 75011)',
			'back_btn_text': 'חזרה',
			'delivery_input_address_placeholder': 'הזינו כתובת למשלוח'
		};
	});
})(angular);
