(function (angular) {
    angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
        $stateProvider.state('app.myShoppingLists.shoppingList', {
            url: '/:listId',
            data: {
                stateAccess: PAGE_ACCESS.LOGGED_IN,
                bodyClass: 'single-shopping-list',
                metaTags: {
                    title: 'Shopping list'
                }
            },
            views: {
                '@': {
                    templateUrl: 'views/single-shopping-list.html',
                    controller: 'SingleShoppingListCtrl as listCtrl'
                }
            }
        });

}]).controller('SingleShoppingListCtrl', ['$scope', '$timeout', 'Api', '$stateParams', 'ShoppingList', 'spUnits', '$q', 'Cart', 'Util', '$state', '$filter', 'mDesign', 'SP_SERVICES', 'Config', '$rootScope',
    function ($scope, $timeout, api, $stateParams, ShoppingList, spUnits, $q, cart, util, $state, $filter, mDesign, SP_SERVICES, Config, $rootScope) {
        var listCtrl = this;

        listCtrl.shoppingListName = '';
        listCtrl.itemsByCategories = {};
        listCtrl.allItems = [];
        listCtrl.total = {quantity: 0, price: 0, lines:0};
        listCtrl.sortByMainCategories = Config.retailer.settings.sortCartByCategories === 'true';
        listCtrl.sortedListByTree = [];


        listCtrl.deleteProduct = deleteProduct;
        listCtrl.removeItem = removeItem;
        listCtrl.importShoppingListToCart = importShoppingListToCart;
        listCtrl.plusQuantity = plusQuantity;
        listCtrl.minusQuantity = minusQuantity;
        listCtrl.quantityChanged = saveProductAfterQuantityChange;
        listCtrl.deleteShopList = deleteShopList;
        listCtrl.comment = showCommentDialog;
        listCtrl.showDisclaimer = showDisclaimer;

        ShoppingList.request('GET', false, $stateParams.listId).then(function (respond) {
            var notActiveProducts = 0,
                categories = {};
            angular.forEach(respond.items, function (line) {
                if (!line.product || (!line.isPseudo && (!line.product.family || !line.product.family.categoriesPaths || !line.product.family.categoriesPaths.length))) {
                    return notActiveProducts++;
                }
                var categoryId = line.product.family.categoriesPaths[0][2].id;
                line.type = SP_SERVICES.CART_LINE_TYPES.PRODUCT;
                line.isProductOutOfStock = util.isProductOutOfStock(line);
                line.isNeedToShowOutOfStockLabel = util.isNeedToShowOutOfStockLabel(line);
                if (!categories[categoryId]) {
                    categories[categoryId] = {
                        names: line.product.family.categoriesPaths[0][2].names,
                        items: []
                    };
                }
                categories[categoryId].items.push(line);
            });
            listCtrl.shoppingListName = respond.name;
            listCtrl.itemsByCategories = categories;
            if (listCtrl.sortByMainCategories) {
                var sortedCartByCategoriesData = cart.sortCartByCategories(respond.items, listCtrl.sortedListByTree);
                listCtrl.sortedListByTree = cart.sortByTree(sortedCartByCategoriesData);
            }

            if (notActiveProducts) {
                mDesign.alert(notActiveProducts + ' ' + $filter('translate')('Products are no longer available'));
            }

            _calculateTotal();
            cart.setCartLineReplacements(listCtrl.allItems);
        });

        function _calculateTotal() {
            listCtrl.total = {quantity: 0, price: 0, lines:0}; //reset values
            listCtrl.allItems = [];

            angular.forEach(listCtrl.itemsByCategories, function (category) {
                angular.forEach(category.items, function (item) {
                    var rowQuantity = item.quantity && !isNaN(item.quantity) ? Number(item.quantity) : 0,
                        rowPrice = rowQuantity * (item.product.branch.regularPrice && !isNaN(item.product.branch.regularPrice) ? item.product.branch.regularPrice : 0);
                    listCtrl.total.quantity += rowQuantity;
                    if (!util.isProductOutOfStock(item)) {
                        listCtrl.total.price += rowPrice;
                        listCtrl.total.lines ++;
                    }
                    listCtrl.allItems.push(item);
                });
            });

            if (listCtrl.total.price > 0) {
                angular.element(document.querySelector('.bottom-actions-btn')).removeAttr('disabled').css({'background': ''});
            } else {
                angular.element(document.querySelector('.bottom-actions-btn')).attr('disabled', 'disabled').css({'background': 'rgba(107, 107, 107, 0.36)'});
            }
        }

        function deleteProduct(categoryId, item) {
            if (!item || !item.productId) return;
            var params = {};
            if(item.isCase){
                params.iscase = item.isCase;
            }
            api.request({
                method: 'DELETE',
                url: 'retailers/:rid/users/:uid/shopLists/' + $stateParams.listId + '/products/' + item.productId,
                params: params
            }).then(function () {
                var itemIndex = -1;
                angular.forEach(listCtrl.itemsByCategories[categoryId].items, function (itemInCategory, index) {
                    itemIndex = item == itemInCategory ? index : itemIndex;
                });
                if (itemIndex != -1) {
                    listCtrl.itemsByCategories[categoryId].items.splice(itemIndex, 1);
                    !listCtrl.itemsByCategories[categoryId].items.length && (delete listCtrl.itemsByCategories[categoryId]);
                }

                if (!Object.keys(listCtrl.itemsByCategories).length) { //Delete empty shoppingList
                    api.request({
                        method: 'DELETE',
                        url: 'retailers/:rid/users/:uid/shopLists/' + $stateParams.listId
                    }).then(function () {
                        $state.go('app.myShoppingLists');
                    });
                } else {
                    _calculateTotal();
                }
            });
        }

        function removeItem(categoryId, item) {
            var transitionTime = 350, //milliseconds
                liElement = angular.element(document.querySelector('#shop_list_item_' + item.productId));
            liElement.css({
                height: liElement[0].offsetHeight + 'px',
                width: liElement[0].offsetWidth + 'px'
            });

            $timeout(function() {
                liElement.css({
                    'transition-duration': transitionTime + 'ms'
                });
            }).then(function() {
                return $timeout(function() {
                    liElement.addClass('remove');
                    liElement.css({
                        width: (liElement[0].offsetWidth * 0.9) + 'px',
                        'margin-left': (liElement[0].offsetWidth * 0.1 / 2) + 'px',
                        'margin-bottom': (liElement[0].offsetHeight * 0.1 / 2) + 'px'
                    });
                });
            }).then(function() {
                $timeout(function() {
                    deleteProduct(categoryId, item);
                }, transitionTime);
            });
        }

        function importShoppingListToCart() {
            var linesToAdd = [];
            var linesWithoutPropertySelected = [];

            angular.forEach(listCtrl.itemsByCategories, function (category) {
                angular.forEach(category.items, function (item) {
                    if(item.product.productProperties && item.product.productProperties.length && !item.productPropertyValueId) {
                        return linesWithoutPropertySelected.push(item.product.names[2].short);
                    }

                    if (item.quantity && !item.isProductOutOfStock && (item.isChoosing || listCtrl.sortByMainCategories)) {
                        linesToAdd.push({
                            isCase: item.isCase,
                            product: item.product,
                            quantity: item.quantity,
                            productPropertyValueId: item.productPropertyValueId || null,
                            comments: item.comments
                        });
                    }
                });
            });

            if(linesWithoutPropertySelected.length) {
                return mDesign.alert('{{\'Product properties need to be selected for\' | translate}}' + ': ' + linesWithoutPropertySelected.join(', '));
            }

            var isLimitPassed = cart.checkHeavyLimitBeforeAddLines(linesToAdd);
            if (isLimitPassed === false) {
                if (cart.lines && Object.keys(cart.lines).length === 0 &&
                    $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                    cart.addDeliveryFeeLineIfNeeded();
                }
                cart.addLines(linesToAdd, SP_SERVICES.SOURCES.SHOP_LIST);
            }
            /*mDesign.alert('The shopping list was successfully added to the cart');*/
        }

        function saveProductAfterQuantityChange(item, $event) {
            $event.preventCart();
            item.quantityChanged = true;
            _calculateTotal();

            return api.request({
                method: 'PATCH',
                data: {
                    products: [{
                        productId: item.product.id,
                        comments: item.comments,
                        quantity: item.quantity,
                        productPropertyValueId: item.productPropertyValueId || null,
                        isCase: item.isCase
                    }]
                },
                url: '/retailers/:rid/users/:uid/shopLists/' + $stateParams.listId + '/products'
            }, {
                fireAndForgot: true
            });
        }

        // function _quantityUnit(item) {
        //     if (item.isWeighable && !item.weight) {
        //         item.quantity = item.product && item.product.unitResolution ? parseFloat(spUnits.convert(item.quantity, item.product && item.product.unitResolution, {
        //             to: $scope.$root.defaultWeighableUnit
        //          }).value.toFixed(2)) : item.quantity;
        //     }
        // }

        function _getQuantityInterval(item) {
            return item.isWeighable && !item.weight ? (item.unitResolution ? item.unitResolution : 0.5) : 1;
        }

        function plusQuantity(item, $event) {
            item.quantity += _getQuantityInterval(item.product);
            // _quantityUnit(item);
            saveProductAfterQuantityChange(item, $event);
        }

        function minusQuantity(item, $event) {
            var intervalQuantity = _getQuantityInterval(item.product);
            item.quantity = item.quantity == 0 ? 0 : item.quantity - intervalQuantity;
            if (item.quantity <= 0 || item.isProductOutOfStock) {
                removeItem(item.product.family.categoriesPaths[0][2].id, item);
            }
            // _quantityUnit(item);
            saveProductAfterQuantityChange(item, $event);
        }

        function deleteShopList() {
            ShoppingList.request('DELETE', false, $stateParams.listId).then(function () {
                $state.go('^');
            });
        }

        function showCommentDialog(item, event) {
            if (event) {
                event.stopPropagation(); // to stop view product page function in father (product-image) event
                event.preventDefault(); // to stop the browser from automatically placing the focus on the md-dialog (on mobile device)
            }

            mDesign.dialog({
                focusOnOpen: false,
                controller: 'ShoppingListComment',
                templateUrl: 'views/templates/cart-line-comment.html',
                resolve: {
                    listId: [function () {
                        return $stateParams.listId;
                    }],
                    item: [function () {
                        return item;
                    }]
                }
            });
        }

        function showDisclaimer(evt, productId) {
            util.showBottleDepositDisclaimer(evt, productId)
        }
    }]);
})(angular);
