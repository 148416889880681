angular.module('mobilezuz').controller('CartLineComment', ['$scope', 'mDesign', 'line', 'Cart',
    function ($scope, mDesign, line, cart) {
        $scope.line = line;
        $scope.comments = line.comments;
        $scope.update = updateComment;
        $scope.cancel = cancel;

        function updateComment() {
            line.comments = $scope.comments;
            cart.commentsChanged(line);
            mDesign.hide(line.comments);
        }

        function cancel() {
            mDesign.cancel();
        }
    }]);