angular.module('mobilezuz')
    .controller('HomePageBubble', [
        '$scope', '$rootScope', '$q', '$interval', '$sce', '$state', 'mDesign', 'Util', 'Config', 'User', 'Orders', 'ORDER_STATUS_STAGES',
        function ($scope, $rootScope, $q, $interval, $sce, $state, mDesign, Util, Config, User, Orders, ORDER_STATUS_STAGES) {
            var homePageBubbleCtrl = this,
                _listeners = [],
                swapInterval = 6000,
                swapTimerObj;

            homePageBubbleCtrl.showBubble = false;
            homePageBubbleCtrl.hasOrder = false;
            homePageBubbleCtrl.hpMessages = {};
            homePageBubbleCtrl.trustedHtml = trustedHtml;
            homePageBubbleCtrl.pagePrev = pagePrev;
            homePageBubbleCtrl.pageNext = pageNext;
            homePageBubbleCtrl.swipe = swipe;
            homePageBubbleCtrl.close = close;
            homePageBubbleCtrl.currentNum = 0;


            _init();

            function _init() {
                _showHomePageBubble();
            }

            //== show HTML from backend's CKEditor
            function trustedHtml(text) {
                return $sce.trustAsHtml(text);
            }

            //== Currently there can be only two messages so Prev and Next makes same action
            function pageNext() {
                swipe();
            }

            //== Should be changed it if there will be more than two pages
            function pagePrev() {
                swipe();
            }

            //== Currently there can be only two messages so Prev, Next and Swap makes same action
            function swipe() {
                homePageBubbleCtrl.currentNum = (homePageBubbleCtrl.currentNum + 1) % 2;
                _setTimeout();
            }

            //== close bubble and save it until page refresh
            function close() {
                $rootScope.homePageBubbleClosed = true;
                homePageBubbleCtrl.showBubble = false;
            }

            //== timeout is for automatic messages switching, applied only when there are both last order and message
            function _setTimeout() {
                //== destroy previous if exists
                swapTimerObj && $interval.cancel(swapTimerObj);

                if(homePageBubbleCtrl.hasOrder && Object.keys(homePageBubbleCtrl.hpMessages)) {
                    swapTimerObj = $interval(pageNext, swapInterval);
                }
            }

            function _cancelTimeout() {
                swapTimerObj && $interval.cancel(swapTimerObj);
            }

            function _showHomePageBubble() {

                return _getLastOrder()
                    .then(function(order) {
                       _getHomePageMessages().then(function (messages) {

                        //== no order and no messages
                        if( !order && !Object.keys(messages).length ) {
                            return;
                        }

                        //== there some messages but the bubble was previously closed
                        if(!order && $rootScope.homePageBubbleClosed) {
                            return;
                        }

                        homePageBubbleCtrl.showBubble = true;
                        homePageBubbleCtrl.hasOrder = !!order;
                        homePageBubbleCtrl.hpMessages = messages;

                        _setTimeout();

                        return true;
                    })

                });
            }

            function _getLastOrder() {
                if (!User.getUserLoginData()) {
                    return $q.resolve(false);
                }

                return Orders.getLastOrder().then(function (order) {
                    if ($scope.$$destroyed || !order) {
                        return;
                    }

                    var shippingTimeTo = new Date(order.shippingTimeTo);
                    shippingTimeTo.setMinutes(shippingTimeTo.getMinutes() + shippingTimeTo.getTimezoneOffset());
                    if (Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.FINISH)  || Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.CANCELLED) || shippingTimeTo < new Date()) {
                        return;
                    }

                    Orders.lastOrder = order;
                    return order;
                });
            }

            function _getHomePageMessages() {
               return Config.waitForInit().then(function(){
                    if( !(Config && Config.retailer && Config.retailer.homePageMessages) ) {
                        return {};
                    }

                    var response = {};

                    //== Run for all messages and fill data for each message's language
                    angular.forEach(Config.retailer.homePageMessages, function(message) {
                        response[message.languageId] = message;
                    });
                    return response;
                })
            }

            //== when logged out there can't be a last order
            _listeners.push($rootScope.$on('login', function () {
                _init();
            }));

            //== when logged out there can't be a last order
            _listeners.push($rootScope.$on('logout', function () {
                homePageBubbleCtrl.hasOrder = false;
                _init();
            }));

            //== remove timer on scope destroy
            $scope.$on('$destroy', function() {
                _cancelTimeout();
            });

            Util.destroyListeners($scope, _listeners);
        }]);