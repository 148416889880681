(function (angular) {
angular.module('mobilezuz')
    .service('ShoppingList', ['$rootScope', '$q', 'Cart', 'Api', 'SP_SERVICES', function ($rootScope, $q, cart, api, SP_SERVICES) {
        var ShoppingList = {};

        ShoppingList.patchShopListItems = function (id, lines) {
            var items = [];
            var arr = [];
            if (lines && angular.isArray(lines)) {
                angular.forEach(lines, function (line) {
                    if (line.isPseudo || line.type !== SP_SERVICES.CART_LINE_TYPES.PRODUCT) return;
                    items.push({
                        productId: line.product.id,
                        comments: line.comments,
                        quantity: line.quantity,
                        productPropertyValueId: line.productPropertyValueId || line.productPropertyValue ? line.productPropertyValue.id : null,
                        isCase: line.isCase
                    });
                    arr.push(line.product.id);
                });
            }
           $rootScope.$emit('cart.lines.added.to.mylist', arr);
           return api.request({
               method: 'PATCH',
               data: {
                   products: items
               },
               url: '/retailers/:rid/users/:uid/shopLists/' + id + '/products'
           });

        }
        //Save user created cart list to "My shopping list"
        ShoppingList.request = function (method, listName, slid, savecart) {
            var url = 'retailers/:rid/users/:uid/shopLists',
                data = {
                    name: listName
                };

            if (method === 'GET' && slid){
                url = '/v2/retailers/:rid/branches/:bid/users/:uid/shopLists';
            } //todo: remove that when moving all to v2
            
            if (slid) {
                url += '/' + slid;
            }

            var defer = $q.defer();

            if (savecart) { //Save cart to shop list
                var preparedProducts = [];

                method = 'POST';

                angular.forEach(cart.lines, function(line) {
                    if (line.isPseudo || line.type !== SP_SERVICES.CART_LINE_TYPES.PRODUCT) return;
                    preparedProducts.push({
                        quantity: line.quantity,
                        productId: line.product.id,
                        comments: line.comments,
                        productPropertyValueId: line.productPropertyValue ? line.productPropertyValue.id : null,
                        isCase: line.isCase
                    });
                });
                data.products = preparedProducts;
            }

            api.request({
                method: method,
                url: url,
                data: data,
                cache: false
            }).then(function (respond) {
                defer.resolve(respond);
            });

            return defer.promise;
        };

        return ShoppingList;
    }]);
})(angular);
