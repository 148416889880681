(function (angular, window) {
    "use strict";

    var app = angular.module('mobilezuz'),
        images = {
            CART_POSTER: 'images/posters/cart.png',
            CHECKOUT_POSTER: 'images/posters/checkout.png',
            MY_COUPONS_POSTER: 'images/posters/my-coupons.png',
            SALES_POSTER: 'images/posters/sales.png',
            SEARCH_POSTER: 'images/posters/search.png',
            SHOPPING_LIST_POSTER: 'images/posters/shopping-list.jpg',

            RECIPE_ICON: 'images/icons/icon-recipe.png',
            VIDEO_ICON: 'images/icons/icon-video.png',
            VIDEO_PLAY: 'images/icons/play-video.png',
            CHEVRON_RIGHT_GREY_ICON: 'images/icons/icon-chevron-right-grey.png',
            CART_WHITE_ICON: 'images/icons/icon-cart-white.png',
            RECIPE_CLOCK_ICON: 'images/icons/recipe-clock.png',
            RECIEVED_ICON: 'images/icons/received.png',
            PACKED_ICON: 'images/icons/packed.png',
            PACKED_ACTIVE_ICON: 'images/icons/Packed_active.png',
            DELIVERY_STATUS_ICON: 'images/icons/delivery-status.png',
            DELIVERY_ACTIVE_ICON: 'images/icons/Delivery_active.png',
            CHEVRON_DOWN_GREY_ICON: 'images/icons/icon-chevron-down-grey.png',
            EMPTY_CART_ICON: 'images/icons/icon-empty-cart.png',
            EXPRESS_ICON: 'images/icons/express.png',
            LOCATION_ICON: 'images/icons/location.png',
            LOCATION2_ICON: 'images/icons/location2.png',
            MY_LOCATION_ICON: 'images/icons/my-location-icon.svg',
            ORDER_SMALL_ICON: 'images/icons/order_small.png',
            CART_VERY_BIG_ICON: 'images/icons/Cart_veryBig.png',
            MALE_BLACK_ICON: 'images/icons/icon-male-black.png',
            FEMALE_BLACK_ICON: 'images/icons/icon-female-black.png',
            COMMENT_GREEN_ICON: 'images/icons/icon-comment-green.png',
            MISSING_ICON: "images/icons/missing-image.png",
            COUPONS_ICON: 'images/icons/coupons-icon.png',
            MY_COUPONS_ICON: 'images/icons/my-coupons.png',
            SP_ICONS_COUPON: 'images/icons/sp-icons-coupon.png',
            COUPONS_SCISSORS: 'images/icons/coupon-scissors.png',
            MISSING_COUPON_ICON: "images/icons/missing-coupon.png",
            DELIVERY_ICON: "images/icons/delivery_ic.png",
            PICKUP_ICON: "images/icons/pickup_ic.png",
            MAGNIFYING_GLASS_ICON: "images/icons/magnifying-glass.png",
            LIST_LARGE_WHITE_ICON: "images/icons/icon-list-large-white.png",
            WHITE_TRASH_ICON: "images/icons/white-trash.png",
            CART_EMPTY_ICON: "images/icons/cart_empty.png",
            MEMBERSHIP_ICON: "images/icons/membership.png",
            ACCUMULATED_POINTS_ICON: "images/icons/accumulated-points.png",
            HISTORY_ICON: "images/icons/history.png",
            RETAILER_DETAILS_ICON: "images/icons/retailers-details.png",
            LINKS_ICON: "images/icons/links.png",
            LOGOUT_ICON: "images/icons/logout.png",
            HOME_WHITE_ICON: "images/icons/icon-home-white.png",
            DEPARTMENTS_WHITE_ICON: "images/icons/icon-departments-white.png",
            BARCODE_WHITE_ICON: "images/icons/icon-barcode-white.png",
            SALES_WHITE_ICON: 'images/icons/icon-sales-white.png',
            LIST_WHITE_ICON: 'images/icons/icon-list-white.png',
            EXIT_ICON: 'images/icons/icon-exit.png',
            ARROW_LEFT: 'images/icons/icon-arrow-left-black.png',
            ARROW_DIAGONAL: 'images/icons/arrow-diagonal.png',
            CLEAR_SEARCH_ICON: 'images/icons/search-clear-button.png',
            BLACK_CART_ICON: 'images/icons/icon-cart-black.png',
            GRAY_SEARCH_ICON: 'images/icons/icon-search-grey.png',
            CHECKOUT_CLOSE_POPUP_ICON: 'images/icons/checkout/close-popup.png',
            CHECKOUT_V_ICON: 'images/icons/checkout/v-icon.png',
            CHECKOUT_EXIT_ICON: 'images/icons/checkout/exit-icon.png',
            CHECKOUT_DELIVERY_ICON: 'images/icons/checkout/delivery-icon.png',
            CHECKOUT_PICKUP_ICON: 'images/icons/checkout/pickup-icon.png',
            CHECKOUT_ADD_CREDIT_CARD_ICON: 'images/icons/checkout/add-credit-card-icon.png',
            CHECKOUT_ADD_CREDIT_CARD_ICON_NEW: 'images/icons/checkout/add-credit-card-icon-new.png',
            CHECKOUT_FORGOT_SOMETHING_ICON: 'images/icons/checkout/forgot-something.png',
            CHECKOUT_BACK_ICON: 'images/icons/checkout/back-icon.png',
            CHECKOUT_SALE_ICON: 'images/icons/checkout/sale-icon.png',
            CHECKOUT_QUESTION_MARK_ICON: 'images/icons/checkout/question-mark.png',
            CHECKOUT_CREDIT_CARD_ICON: 'images/icons/checkout/credit-card-icon.png',
            CHECKOUT_CHOSEN_CREDIT_CARD_ICON: 'images/icons/checkout/chosen-credit-card-icon.png',
            HOME_SCROLL_DOWN_ICON: 'images/icons/home-scroll-down-icon.png',
            HOME_PAGE_CART_ICON: 'images/icons/home-page-cart.png',
            HOME_PAGE_CART_CUT_ICON: 'images/icons/home-page-cart-cut.png',
            HOME_SEARCH_ICON: 'images/icons/home-search-icon.png',
            PAYPAL_ACCOUNT_ICON: 'images/icons/paypal-account.png',
            MENU_WHITE_ICON: 'images/icons/menu-white.png',
            HEADER_BACK_ICON: 'images/icons/header-back-icon.png',
            LOYALTY_COST_ICON: 'images/icons/loyalty-cost-icon.png',
            PICK_AND_GO_BC_ICON: 'images/icons/pick-and-go-bc.png',

            RESTAURANT_MENU_ITEM_ICON: 'images/icons/icon-restaurant-menu-item.png',
            PICK_AND_GO_ICON: 'images/icons/pick-and-go-icon.png',
            FOOTER_HOME_ICON: 'images/icons/footer-home-icon.png',
            FOOTER_LIST_ICON: 'images/icons/footer-list-icon.png',
            FOOTER_SCAN_ICON: 'images/icons/footer-scan-icon.png',
            FOOTER_SHOP_ICON: 'images/icons/footer-shop-icon.png',
            SPECIALS_ICON: 'images/icons/specials-icon.png',
            NEXT_DELIVERY_ICON: 'images/icons/next-delivery-icon.png',
            NEXT_DELIVERY_ARROW_ICON: 'images/icons/next-delivery-arrow-icon.png',
            TRANSPARENT_V_ICON: 'images/icons/checkout/transparent-v-icon.png',

            PICTURE_STACK_ICON: 'images/icons/pic-stack.png',
            MAGNIFY_ICON: 'images/icons/magnify.png',
            THREE_D_ICON: 'images/icons/3d-icon.png',
            CLOSE: 'images/icons/close.png',
            ACCOUNT_DELETION_ICON: 'images/icons/account-deletion.png',
            INFO_ICON_GREEN: 'images/icons/info-icon.png',
            SUCCESS_ICON: 'images/icons/checked.png',
            GOOGLE_MAP_MARKER: 'images/icons/gg-map-marker.svg'
        };

    // incase the app is running as website the images should be loaded from the storage.
    var images_source_prefix;
    if (window.location.hostname !== 'mobilezuz') {
        images_source_prefix = 'https://d226b0iufwcjmj.cloudfront.net/global/mobile/';
    }
    else {
        images_source_prefix = window.location.origin + '/';
    }

    angular.forEach(images, function (value, key) {
            images[key] = images_source_prefix + value;
    });

    app.constant('IMAGES_PATHS', images);

})(angular, window);
