angular.module('mobilezuz')
    .controller('EditOrderCtrl', [
        '$scope', '$rootScope', '$timeout', '$filter', 'mDesign', 'Util', 'Config', 'User', 'Cart', 'Orders',
        'ORDER_STATUS_STAGES', 'DELIVERY_TYPES',
        function ($scope, $rootScope, $timeout, $filter, mDesign, util, Config, User, cart, orders,
                  ORDER_STATUS_STAGES, DELIVERY_TYPES) {
            var editOrderCtrl = this,
                _loaded = false,
                _orderStatusTimer,
                _orderStatusTimeout = 240000,
                _listeners = [];

            editOrderCtrl.editOrder = editOrder;
            editOrderCtrl.ordersService = orders;
            editOrderCtrl.isDeliveryToday = isDeliveryToday;
            editOrderCtrl.openDialer = openDialer;
            editOrderCtrl.loadLastOrder = loadLastOrder;
            editOrderCtrl.onClickUpdateOrder = editOrder;


            init();

            editOrderCtrl.retailerPhoneNumber = $rootScope.retailerPhone || (Config.branch && Config.branch.phone);
            function init() {
                var lastOrderP = _getLastOrder();
                if (!lastOrderP) {
                    lastOrderP = Promise.resolve();
                }
                lastOrderP.then(function() {
                    editOrderCtrl.deliveryTypeText = util.getDeliveryTypeText(orders.lastOrder.branchDeliveryTypeId);
                });
            }

            function editOrder(event) {
                event && event.stopPropagation();

                if (!editOrderCtrl.order) {
                    var listener = $rootScope.$on('order.edit', function () {
                        listener();
                        editOrder();
                    });
                    return;
                }

                orders.checkIfUserCanUpdateOrder(editOrderCtrl.order.id).then(function(response){
                    if(response && response.isCustomerEditBlocked){
                        var  contentHeader = 'We have started collecting your original order.',
                            contentTitle = 'Your order cannot be changed at this time'
                        contentFooter = '';


                        return mDesign.dialog({
                            focusOnOpen: false,
                            clickOutsideToClose: false,
                            templateUrl: 'views/templates/order-status-dialog.html',
                            controller: ['$scope', function ($scope) {
                                $scope.contentHeader = contentHeader;
                                $scope.contentTitle = contentTitle;
                                $scope.contentFooter = contentFooter;
                                $scope.hide = mDesign.hide;
                            }]
                        });
                    }
                    else{
                        if (util.isOrderEditable(editOrderCtrl.order)) {
                            return _editOrder();
                        }

                        var contentHeader = '',
                            contentTitle = "Your order can no longer be changed";
                        if (util.isOrderInStatuses(editOrderCtrl.order, ORDER_STATUS_STAGES.IN_PROCESS)) {
                            contentHeader = "We have started collecting your original order.";
                        } else if (util.isOrderInStatuses(editOrderCtrl.order, ORDER_STATUS_STAGES.READY)) {
                            contentHeader = "We have finished collecting your original order.";
                        } else if (editOrderCtrl.order.branchDeliveryTypeId == DELIVERY_TYPES.PICK_AND_GO) {
                            contentHeader = "This is a pick and go order";
                        } else {
                            contentHeader = "We have finished collecting your original order.";
                        }

                        return mDesign.alert('<div style="font-size: 1em;">' + $filter('translate')(contentHeader) + '<br/>' + $filter('translate')(contentTitle) + '.</div>');
                    }
                })
            }

            function isDeliveryToday(daysToAdd) {
                var shippingDate = new Order(editOrderCtrl.order.shippingTimeTo),
                    todayUTC = _getStartTodayUTC(),
                    tomorrowUTC = _getStartTodayUTC();
                todayUTC.setUTCDate(tomorrowUTC.getUTCDate() + (daysToAdd || 0));
                tomorrowUTC.setUTCDate(tomorrowUTC.getUTCDate() + (daysToAdd || 0) + 1);
                return shippingDate < tomorrowUTC && tomorrowUTC >= todayUTC;
            }

            function openDialer() {
                window.location.href = 'tel:' + editOrderCtrl.retailerPhoneNumber;
            }

            function _getStartTodayUTC() {
                var res = new Date();
                res.setUTCHours(0);
                res.setUTCMinutes(0);
                res.setUTCSeconds(0);
                res.setUTCMilliseconds(0);
                return res;
            }

            function loadLastOrder() {
                if (!_loaded) {
                    _setOrder();
                    _getLastOrder(true);
                }
            }

            function _editOrder() {
                orders.editOrderDialog(editOrderCtrl.order).catch(function() {
                    _getLastOrder(true);
                });
            }

            function _getLastOrder(reload) {
                var userLoginData = User.getUserLoginData();
                if (!userLoginData) {
                    _loaded = false;
                    return _setOrder();
                }

                if (orders.lastOrder && !reload) {
                    // do not touch '_loaded' when the value is orders.lastOrder
                    return _setOrder(orders.lastOrder);
                }

                _loaded = true;

                return orders.getLastOrder().then(function (order) {
                    if(order && order.id) {
                        orders.checkIfUserCanUpdateOrder(order.id).then(function(response){
                            if(response && response.isCustomerEditBlocked){
                                var contentHeader = '',
                                    contentTitle = "Your order can no longer be changed";
                                contentHeader = "We have started collecting your original order.";
                                return mDesign.alert('<div style="font-size: 1em;">' + $filter('translate')(contentHeader) + '<br/>' + $filter('translate')(contentTitle) + '.</div>');
                            }
                            else{
                                orders.lastOrder = order;
                                _setOrder(order);
                                $rootScope.$emit('order.edit');
                            }
                        });
                    }
                });
            }

            function _setOrder(order) {
                editOrderCtrl.order = order;

                if (!order || util.isOrderInStatuses(order, ORDER_STATUS_STAGES.FINISH) || util.isOrderInStatuses(order, ORDER_STATUS_STAGES.CANCELLED)|| new Date(order.shippingTimeTo) < new Date()) {
                    return;
                }

                _cancelStatusTimeout();
                _orderStatusTimer = $timeout(function () {
                    _orderStatusTimer = null;

                    orders.getOrderStatus(editOrderCtrl.order.id).then(function (order) {
                        $rootScope.$emit('editOrder.polling', order);
                        _setOrder(order);
                    });
                }, _orderStatusTimeout);
            }

            function _cancelStatusTimeout() {
                _orderStatusTimer && $timeout.cancel(_orderStatusTimer);
            }

            util.currentScopeListener($scope, $rootScope.$on('order.update.timeslot.refresh', function (event, data) {
                if(data.order){
                    editOrderCtrl.order.shippingTimeTo = data.order.shippingTimeTo;
                    editOrderCtrl.order.shippingTimeFrom = data.order.shippingTimeFrom;
                    editOrderCtrl.order.shippingTimeFromDisplay = data.order.shippingTimeFromDisplay;
                }
            }));

            _listeners.push(_cancelStatusTimeout);

            util.destroyListeners($scope, _listeners);
        }]);
