(function (angular) {
    "use strict";

    angular.module('mobilezuz').directive('spRadioOptions', ['$timeout',
        function () {
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    options: '=',
                    spModel: '=?',
                    label: '@',
                    spChange: '&?',
                    spRoleTab: '=?'
                },
                template: '' +
                '<div class="sp-radio-options">' +
                '   <span id="{{option.id}}" class="sp-radio-option" ng-repeat="option in options" ng-click="setModel(option)" ng-class="{active: spModel == option}" ng-attr-aria-current="{{spModel == option ? \'true\' : undefined}}" ng-attr-role="{{spRoleTab ? \'tab\' : \'radio\'}}">' +
                '       <span class="for-vertical-align"></span><!--' +
                '       --><span>{{$eval(label, {option: option})}}</span>' +
                '   </span>' +
                '</div>',
                controller: ['$scope', function($scope) {
                    $scope.setModel = setModel;
                    $scope.isCurrent = function(option){
                      return $scope.spModel === option
                    };

                    function setModel(option) {
                        $scope.spModel = option;
                    }

                    if ($scope.spChange) {
                        $scope.$watch('spModel', function() {
                            $scope.spChange();
                        });
                    }
                }]
            };
        }]);
})(angular);