angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.cart.checkout.finish', {
        url: '/finish/:orderId?isCreditCardLoyalty',
        views: {
            'checkoutView': {
                templateUrl: 'views/checkout/finish.html',
                controller: 'CheckoutFinishCtrl as finishCtrl'
            }
        }
    });
}]).controller('CheckoutFinishCtrl', ['$rootScope','$timeout', '$scope', '$stateParams', '$state', '$injector', 'Retailer', 'User', 'Util', 'Config', 'mDesign', 'Cart','DELIVERY_TIMES_TYPES', 'CHECKOUT_METHODS',
    function($rootScope, $timeout, $scope, $stateParams, $state, $injector, Retailer, User, Util, Config, mDesign, cart, DELIVERY_TIMES_TYPES, CHECKOUT_METHODS) {
    var finishCtrl = this,
        checkoutCtrl = $scope.checkoutCtrl;

    finishCtrl.orderId = Number($stateParams.orderId);
    finishCtrl.isCreditCardLoyalty = $stateParams.isCreditCardLoyalty;
    finishCtrl.goToHome = goToHome;
    finishCtrl.user = User.settings;
    finishCtrl.dateTimeFormat = $rootScope.config.isUs ? "MM/dd hh:mm a" : "dd/MM HH:mm";
    
    // ECOM-13394 reassign delivery if not available
    checkoutCtrl.isDelivery =
      typeof checkoutCtrl.isDelivery === "boolean"
        ? checkoutCtrl.isDelivery
        : _getDeliveryTypeText(checkoutCtrl.deliveryType);

    finishCtrl.deliveryTypeText = checkoutCtrl.isDelivery ? 'delivery' : 'pickup';
    finishCtrl.canShowUpdateDeliveryTime = false;
    if (finishCtrl.isCreditCardLoyalty === 'true' && Config.retailer.loyaltyClubDriver.clientConfig.text.postCheckout) {
        finishCtrl.postCheckout = Config.retailer.loyaltyClubDriver.clientConfig.text.postCheckout;
    }

    if (finishCtrl.orderId) {
        var ordersService = $injector.get('Orders');
        var totalTransaction = parseFloat((checkoutCtrl.summaryData && checkoutCtrl.summaryData.total || 0).toFixed(2))
        ordersService.getOrderDetails(finishCtrl.orderId, {fireAndForgot: true}).then(function(order) {
            if (!order) return;
            var lines = order.lines || {};
            if(totalTransaction > 0){
                sendFinalCheckOutEvent(finishCtrl.orderId, totalTransaction, lines);
            }
            if(Util.checkIsPremiumEditOrderToggled()) {
                var deliveryTime = checkoutCtrl.timesDetails && checkoutCtrl.timesDetails.deliveryTime && checkoutCtrl.timesDetails && checkoutCtrl.timesDetails.deliveryTime.newFrom 
                || order.shippingTimeFrom;

                if(deliveryTime) {
                    finishCtrl.lastTimeUpdate = Util.calculateLastUpdateOrderDateTime(deliveryTime);
                }
            }
            finishCtrl.canShowUpdateDeliveryTime =  Util.checkIsPremiumEditOrderToggled() && order.deliveryTimeTypeId == DELIVERY_TIMES_TYPES.REGULAR;
        });
    }

    Retailer.getRetailerSettings().then(function (retailer) {
        finishCtrl.retailer = retailer;
        finishCtrl.shopTitle = (retailer.settings.shopTitle && JSON.parse(retailer.settings.shopTitle)) || retailer.title;
        _showAfterOrderFinishedDialog(retailer.settings.afterOrderFinishedText);
    });

    /**
     * @param {'Delivery' | 'Pickup'} deliveryType 
     * @returns {boolean}
     */
    function _getDeliveryTypeText(deliveryType) {
      return deliveryType === "Delivery";
      
    }

    function goToHome(event) {
        event && event.stopPropagation();
        event && event.preventDefault();
        $state.go('app.home');
    }

    function sendFinalCheckOutEvent(orderId, totalPrice, lines){
        var products =[];
        angular.forEach(lines, function(line){
            if(line.product && line.product.branch && line.product.branch.regularPrice) {
                var quantity = (line.product.isWeighable) ? (line.quantity * 1000): line.quantity;
                var price = (line.product.isWeighable) ? (line.product.branch.regularPrice / 1000): line.product.branch.regularPrice;
                products.push({productId:line.product.id, quantity: Math.round(quantity), unitPrice: price});
            }
        })
        var sourceType = "internal";
        var sourceValue = "FinalCheckOut";
        if (cart.cartMetaData && cart.cartMetaData.utm_source){
            sourceType = cart.cartMetaData.utm_source;
            sourceValue = cart.cartMetaData.utm_campaign_id;
        }
        $rootScope.$emit('finalCheckout',orderId, totalPrice, products, sourceType, sourceValue);
    }

    function _showAfterOrderFinishedDialog(afterOrderFinishedText) {
        if(afterOrderFinishedText && afterOrderFinishedText.trim()) {
            $timeout(function() {
                mDesign.dialog({
                    focusOnOpen: false,
                    controller: ['$scope', 'mDesign', 'afterOrderFinishedText',
                            function ($scope, mDesign, afterOrderFinishedText) {
                                $scope.dialog = mDesign;
                                $scope.afterOrderFinishedText = afterOrderFinishedText;
                            }
                        ],
                    templateUrl: 'views/templates/after-order-finished-text.html',
                    resolve: {
                        afterOrderFinishedText: function() {
                            return afterOrderFinishedText;
                        }
                    }
                })
            }, 100);
        }
    }


}]);
