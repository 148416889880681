(function (angular) {
    'use strict';

    angular.module('mobilezuz').filter('productName', ['$filter', function ($filter) {
        var _nameFilter = $filter('name'),
            _translateFilter = $filter('translate'),
            _productWeightFilter = $filter('productWeight');

        return function (product, isCase, type) {
            var itemsInCase = product.branch && product.branch.case && product.branch.case.items;
            if (isCase && product.isWeighable) {
                itemsInCase = _productWeightFilter(product, true);
            }

            return _nameFilter(product.names)[type || 'short'] + (isCase ? ' - ' + _translateFilter('Case' + (!!itemsInCase ? ' of' : '')) + (!!itemsInCase ? ' ' + itemsInCase : '') : '');
        };
    }]);
})(angular);
