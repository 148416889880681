(function (angular) {
    angular.module('mobilezuz')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.cart.order-update.ebt-payment', {
                url: '/ebt-payment',
                views: {
                    updateOrder: {
                        templateUrl: 'views/order/update/ebt-payment.html',
                        controller: 'EBTPaymentCtrl as ebtPaymentCtrl',
                        resolve: {
                            deliveryMethods: ['SP_SERVICES', function (SP_SERVICES) {
                                return {
                                    1: {
                                        image: 'icon_delivery.png',
                                        label: 'Delivery',
                                        text: 'Your Order will be delivered'
                                    },
                                    2: {
                                        image: 'icon-pickup.png',
                                        label: 'PickUp',
                                        text: 'Your Order will be ready to pickup'
                                    }
                                }
                            }],
                            userData: ['User', function (user) {
                                return user.getUserSettings().then(function(data) {
                                    return data;
                                })
                            }],
                            branchSettingsData: ['Config', 'Orders', function (config, orders) {
                                var orderBranchId = orders.orderInEdit && orders.orderInEdit.branchId;

                                return (config.retailer.settings.eBTBranchSettings && orderBranchId)
                                    ? JSON.parse(config.retailer.settings.eBTBranchSettings)[orderBranchId]
                                    : null;
                            }]
                        }
                    }
                }
            });
        }])
        .controller('EBTPaymentCtrl',
            ['$q', '$scope', '$rootScope', '$state', '$location', 'deliveryMethods', 'branchSettingsData', 'PaymentsService', 'Api', 'Util', 'SP_SERVICES', 'SP_PAYMENTS', 'userData', 'Cart', 'Config', 'mDesign', 'Orders', 'FullCreditCardChargeConfirmationDialog',
                function ($q, $scope, $rootScope, $state, $location, deliveryMethods, branchSettingsData, paymentsService, api, util, SP_SERVICES, SP_PAYMENTS, userData, cart, config, mDesign, orders, fullCreditCardChargeConfirmationDialog) {

                    var updateOrderCtrl = $scope.updateOrderCtrl,
                        _listeners = [];
                    updateOrderCtrl.paymentsData = null;
                    updateOrderCtrl.forward = onNext;
                    updateOrderCtrl.back = onBack;

                    if (!updateOrderCtrl.order) return $state.go('app.home');

                    var ebtPaymentCtrl = this;
                    ebtPaymentCtrl.fieldStep = 0.1;


                    ebtPaymentCtrl.ebtValues = {
                        fsAmount: '',
                        cbAmount: ''
                    };

                    ebtPaymentCtrl.prevEBTValues = {
                        fsAmount: '',
                        cbAmount: ''
                    };

                    ebtPaymentCtrl.ebtErrors = {
                        snap: '',
                        cash: ''
                    };

                    ebtPaymentCtrl.ebtInfo = {
                        snap: {
                            id: SP_PAYMENTS.PAYMENT_TYPES.EBT_SNAP,
                            valName: 'fsAmount',
                            shortValName: 'fs',
                            title: 'EBT SNAP',
                            previousPayment: updateOrderCtrl.order.paymentData.ebtSNAPPayment,
                            isEnabled: _setEBTPaymentEnabledByBranch('eBTSnapAllowed', 'ebtSNAPPayment')
                        },
                        cash: {
                            id: SP_PAYMENTS.PAYMENT_TYPES.EBT_CASH,
                            valName: 'cbAmount',
                            shortValName: 'cb',
                            title: 'EBT CASH',
                            previousPayment: updateOrderCtrl.order.paymentData.ebtCashPayment,
                            isEnabled: _setEBTPaymentEnabledByBranch('eBTCashAllowed', 'ebtCashPayment')
                        }
                    }

                    ebtPaymentCtrl.getEBTBalance = getEBTBalance;
                    ebtPaymentCtrl.onChangeEBTChargeType = onChangeEBTChargeType;

                    init();

                    function init() {
                        ebtPaymentCtrl.ebtDividerAmounts = null;
                        updateOrderCtrl.withoutContent = false;
                        ebtPaymentCtrl.substitutePreferences = updateOrderCtrl.order.substitutePreferences;
                        ebtPaymentCtrl.ebtLastDigits = updateOrderCtrl.order.paymentData.ebtPayment && updateOrderCtrl.order.paymentData.ebtPayment.lastFourDigits;
                        ebtPaymentCtrl.mainLastDigits = updateOrderCtrl.order.paymentData.mainPayment && updateOrderCtrl.order.paymentData.mainPayment.lastFourDigits;
                        ebtPaymentCtrl.dividedCart = cart.getEBTEligible();
                        ebtPaymentCtrl.ebtCard = userData.ebtCards && userData.ebtCards.length && userData.ebtCards.find(function (card) {
                            return card.lastFourDigits === ebtPaymentCtrl.ebtLastDigits
                        });
                        ebtPaymentCtrl.ebtSnapTaxSaved = 0;
                        _serializeDeliveryInfo();
                        _initializeSummaryObj();
                        _createCashSnapTaxRatio()

                        if (!(ebtPaymentCtrl.dividedCart.snap.totalWithTax || ebtPaymentCtrl.dividedCart.cash.totalWithTax)) {
                            ebtPaymentCtrl.ebtDividerAmounts = paymentsService.calculateEBTAmounts(ebtPaymentCtrl.ebtValues, ebtPaymentCtrl.dividedCart);
                            onNext(true);

                            return;
                        }

                        ebtPaymentCtrl.isValidToShowScreen = true;
                    }

                    // EXTERNAL VIEW FUNCTIONS
                    function onBack() {
                        $state.go('app.cart');
                    }

                    function onNext(isFinish) {
                        _calculateFinalPayment();

                        updateOrderCtrl.paymentsData = {
                            // ebtBalance: resp.ebtBalance || null, need to add it next screen
                            cartLines: cart.getLines(),
                            summary: ebtPaymentCtrl.summary,
                            deliveryInfo: ebtPaymentCtrl.deliveryInfo,
                            ebtCard: ebtPaymentCtrl.ebtCard,
                            ebtInfo: ebtPaymentCtrl.ebtInfo,
                            mainLastDigits: ebtPaymentCtrl.mainLastDigits,
                            ebtDividerAmounts: ebtPaymentCtrl.ebtDividerAmounts,
                            dividedCart: ebtPaymentCtrl.dividedCart,
                            ebtValues: ebtPaymentCtrl.ebtValues
                        };

                        if (isFinish) {
                            _serializePayments().then(function (payments) {
                                _updateOrder(payments);
                            });

                            return;
                        } else {
                            var totalEbtAmount = 0;
                            angular.forEach(ebtPaymentCtrl.ebtInfo, function (info, type) {
                                var currAmount = ebtPaymentCtrl.ebtValues[info.valName];
                                var updateAmount = !currAmount ? 0 : parseFloat(currAmount);
                                totalEbtAmount += updateAmount;
                            });

                            if (totalEbtAmount === 0) {
                                fullCreditCardChargeConfirmationDialog.show().then(function (data) {
                                    if (data && data.isChargeConfirmed) {
                                        updateOrderCtrl.onConfirm = onNext;
                                        $state.go('app.cart.order-update.ebt-summary');
                                    }
                                });
                            } else {
                                updateOrderCtrl.onConfirm = onNext;
                                $state.go('app.cart.order-update.ebt-summary');
                            }
                        }                        
                    }

                    function onChangeEBTChargeType(type) {
                        var ebtError = '';
                        var ebtValuesParamsObj = {
                            from: 'ebtValues',
                            to: 'prevEBTValues'
                        }

                        if (_isRecalculateEBTValuesSucceed(type)) {
                            ebtPaymentCtrl.ebtDividerAmounts = paymentsService.calculateEBTAmounts(ebtPaymentCtrl.ebtValues, ebtPaymentCtrl.dividedCart);
                            ebtPaymentCtrl.summary.finalTotal = _calcFinalTotalAmount();
                            ebtPaymentCtrl.summary.creditCardReminder = _calcCreditCard();
                            ebtPaymentCtrl.summary.ebtWeightDeposit = ebtPaymentCtrl.ebtDividerAmounts && ebtPaymentCtrl.ebtDividerAmounts.total.deposit;
                        } else {
                            ebtValuesParamsObj = {
                                from: 'prevEBTValues',
                                to: 'ebtValues'
                            }

                            ebtError = 'Invalid exceeding'
                        }

                        _setEBTValues(ebtValuesParamsObj);
                        _setEBTValError(type, ebtError);
                    }

                    function getEBTBalance() {
                        return _getEBTTransaction().then(function (res) {
                            return paymentsService.getEBTBalance(res.paymentToken).then(function (ebtRes) {
                                ebtPaymentCtrl.ebtCard.balance = {
                                    snap: ebtRes.fsBalance,
                                    cash: ebtRes.cbBalance
                                };
                            });
                        });
                    }

                    // INTERNAL FUNCTIONS
                    function _setEBTPaymentEnabledByBranch(type, prevPayment) {
                        return (updateOrderCtrl.order.paymentData[prevPayment] || (branchSettingsData && branchSettingsData[type]));
                    }

                    function _setEBTValError(type, message) {
                        ebtPaymentCtrl.ebtErrors[type] = message;
                    }

                    function _setEBTValues(valsObj) {
                        var from = valsObj.from;
                        var to = valsObj.to;

                        ebtPaymentCtrl[to].fsAmount = ebtPaymentCtrl[from].fsAmount;
                        ebtPaymentCtrl[to].cbAmount = ebtPaymentCtrl[from].cbAmount;
                    }

                    function _isRecalculateEBTValuesSucceed(type) {
                        var currType = type === 'snap' ? 'cash' : 'snap';
                        var typeValHolder = (ebtPaymentCtrl.ebtValues[ebtPaymentCtrl.ebtInfo[type].valName] || 0);
                        if (type === 'snap' && typeValHolder > ebtPaymentCtrl.dividedCart[type].totalWithoutTax) return false
                        if (type === 'cash' && typeValHolder > ebtPaymentCtrl.dividedCart[type].totalWithTax) return false;
                       
                        if (typeValHolder > ebtPaymentCtrl.dividedCart[type].totalWithTax) return false;

                        var onlyCashPaymentHolder = _toFloatNum(ebtPaymentCtrl.dividedCart[type].immutableWithTax - ebtPaymentCtrl.dividedCart[currType].immutableWithTax);
                        var currentPayment = typeValHolder - Math.max(onlyCashPaymentHolder, 0);
                        var paymentHolder;
                        if(type === 'snap'){
                          paymentHolder = _toFloatNum(ebtPaymentCtrl.dividedCart[currType].immutableWithTax - Math.max(currentPayment, 0)* ebtPaymentCtrl.dividedCart.entireCart.immutableTaxRatio);
                          ebtPaymentCtrl.dividedCart[currType].totalWithTax = _toFloatNum(Math.max(paymentHolder, 0));
                          ebtPaymentCtrl.ebtSnapTaxSaved= Math.max(currentPayment, 0) *  ebtPaymentCtrl.dividedCart.entireCart.immutableTaxRatio - Math.max(currentPayment, 0);

                        }
                        if(type === 'cash'){
                          paymentHolder = _toFloatNum(ebtPaymentCtrl.dividedCart[currType].immutableWithoutTax - Math.max(currentPayment, 0));
                          ebtPaymentCtrl.dividedCart[currType].totalWithoutTax = _toFloatNum(Math.max(paymentHolder, 0));
                        }
                        
                        ebtPaymentCtrl.ebtValues[type] = !typeValHolder ? '' : _toFloatNum(typeValHolder);
                        return true;
                    }

                    function _createCashSnapTaxRatio(){
                      var ebt = ebtPaymentCtrl.dividedCart;
                      if(!ebt.entireCart){
                        return
                      }
                      var taxRatio = ebt.snap.immutableWithTaxWithoutDeposit / ebt.snap.immutableWithoutTaxWithoutDeposit;
    
                      if(!taxRatio){
                        taxRatio = 1;
                      }
                      ebtPaymentCtrl.dividedCart.entireCart.immutableTaxRatio = taxRatio;
                      return
                    }

                    function _updateOrder(payments) {
                        var timeSlotData = util.getNewTimeSlotData(updateOrderCtrl.order.id, cart.serverCartId);
                        return api.request({
                            method: 'POST',
                            url: '/v2/retailers/:rid/branches/:bid/users/:uid/_checkoutUpdate',
                            data: {
                                newCartId: cart.serverCartId,
                                orderId: updateOrderCtrl.order.id,
                                skipActiveProductsValidation: true,
                                payments: (payments && payments.length) ? payments : null,
                                totalUpdateOrderAmount: ebtPaymentCtrl.summary.finalTotal,
                                ebtSnapTaxSaved: ebtPaymentCtrl.summary.ebtSnapTaxSaved,
                                ebtWeightDeposit: ebtPaymentCtrl.ebtDividerAmounts && ebtPaymentCtrl.ebtDividerAmounts.total && ebtPaymentCtrl.ebtDividerAmounts.total.deposit,
                                creditCardReminder: ebtPaymentCtrl.summary.creditCardReminder,
                                newAreaDeliveryTimeId: timeSlotData && timeSlotData.newAreaDeliveryTimeId,
                                newDate: timeSlotData && timeSlotData.newDate
                            }
                        }).then(function (resp) {
                            updateOrderCtrl.order = null;
                            updateOrderCtrl.updatedOrderId = resp.id;
                            updateOrderCtrl.paymentsData.ebtBalance = resp.ebtBalance;
                            updateOrderCtrl.paymentsData.summary.creditCardReminder = _toFloatNum(_calcCreditCard());

                            orders.orderInEdit = null;

                            $state.go('app.cart.order-update.ebt-finish');
                        });
                    }

                    function _serializePayments() {
                        return $q.resolve().then(function () {
                            return _getEBTPaymentToken('snap');
                        }).then(function () {
                            return _getEBTPaymentToken('cash');
                        }).then(function () {
                            return _preparePayments();
                        }).catch(function (err) {
                            throw new Error(err);
                        });
                    }

                    function _preparePayments() {
                        if (updateOrderCtrl.finalUpdatePayments) {
                            var payments = [];

                            angular.forEach(updateOrderCtrl.finalUpdatePayments, function (payment) {
                                payments.push(payment);
                            });

                            return payments;
                        }

                        return false;
                    }

                    function _getEBTPaymentToken(type) {
                        // Only Charge type need transaction token (refund not)!
                        if (updateOrderCtrl.finalUpdatePayments && updateOrderCtrl.finalUpdatePayments[type] && updateOrderCtrl.finalUpdatePayments[type].chargeType === 'charge') {
                            return _getEBTCheckoutPaymentTransaction(ebtPaymentCtrl.ebtInfo[type].id)
                                .then(function (data) {
                                    updateOrderCtrl.finalUpdatePayments[type].token = data.paymentToken;
                                }).catch(function () {
                                    throw new Error('getting payment token rejected');
                                });
                        } else {
                            return false;
                        }
                    }

                    function _initializeSummaryObj() {
                        ebtPaymentCtrl.summary = {
                            subTotal: _toFloatNum(cart.total.finalPriceWithoutTax),
                            finalTax: _toFloatNum(cart.total.tax),
                            deliveryFee: _toFloatNum(cart.total.deliveryCost.finalPriceWithTax) || 0,
                            serviceFee: _toFloatNum(cart.total.serviceFee.finalPriceWithTax) || 0,
                            previousCharged: _toFloatNum(updateOrderCtrl.order.totalAmount -  updateOrderCtrl.order.ebtSnapTaxSaved + updateOrderCtrl.order.ebtWeightDeposit) || 0
                        }

                        ebtPaymentCtrl.summary.finalTotal = _calcFinalTotalAmount();
                        ebtPaymentCtrl.summary.creditCardReminder = _calcCreditCard();
                        ebtPaymentCtrl.summary.ebtWeightDeposit = ebtPaymentCtrl.ebtDividerAmounts && ebtPaymentCtrl.ebtDividerAmounts.total.deposit;
                    }

                    function _toFloatNum(v, decimalNum) {
                        return parseFloat(v.toFixed((decimalNum || 2)));
                    }

                    function _calcFinalTotalAmount() {
                      var ebtSnapTaxSaved = ebtPaymentCtrl.ebtDividerAmounts && ebtPaymentCtrl.ebtDividerAmounts.total.taxSaved || 0;
                      ebtPaymentCtrl.summary.ebtSnapTaxSaved = ebtSnapTaxSaved;
                        return _toFloatNum((
                            ebtPaymentCtrl.summary.subTotal +
                            ebtPaymentCtrl.summary.finalTax - 
                            ebtSnapTaxSaved +
                            ebtPaymentCtrl.summary.deliveryFee +
                            ebtPaymentCtrl.summary.serviceFee +
                            (ebtPaymentCtrl.ebtDividerAmounts && ebtPaymentCtrl.ebtDividerAmounts.total.deposit || 0)
                        ))
                    }

                    function _calcCreditCard() {
                        var creditCardReminder = _toFloatNum(ebtPaymentCtrl.summary.finalTotal - _calcEBTTotal());
                        return creditCardReminder < 0 ? _calcFinalTotalAmount() : creditCardReminder;
                    }

                    function _calcEBTTotal() {
                        return (ebtPaymentCtrl.ebtValues.fsAmount || 0) + (ebtPaymentCtrl.ebtValues.cbAmount || 0);
                    }

                    function _serializeDeliveryInfo() {
                        ebtPaymentCtrl.deliveryInfo = {
                            area: config.area,
                            deliveryMethod: updateOrderCtrl.order.branchDeliveryTypeId && deliveryMethods[updateOrderCtrl.order.branchDeliveryTypeId],
                            deliveryTimeRage: {
                                from: updateOrderCtrl.order.shippingTimeFrom,
                                to: updateOrderCtrl.order.shippingTimeTo
                            },
                        };

                        var addressHolder;
                        switch (updateOrderCtrl.order.branchDeliveryTypeId) {
                            case SP_SERVICES.DELIVERY_TYPES.DELIVERY:
                                addressHolder = {
                                    addressText: updateOrderCtrl.order.addressText,
                                    city: updateOrderCtrl.order.city,
                                    zipCode: updateOrderCtrl.order.zipCode
                                };

                                break
                            case SP_SERVICES.DELIVERY_TYPES.PICKUP:
                                addressHolder = {
                                    isPickup: true,
                                    addressText: config.branch.location,
                                    city: config.branch.city,
                                    zipCode: config.branch.zipCode
                                }

                                break
                            default:
                                addressHolder = null;
                        }

                        ebtPaymentCtrl.deliveryInfo.address = addressHolder;
                    }

                    function _calculateFinalPayment() {
                        updateOrderCtrl.finalUpdatePayments = {
                            snap: {chargeType: null, amount: 0},
                            cash: {chargeType: null, amount: 0}
                        };

                        angular.forEach(ebtPaymentCtrl.ebtInfo, function (info, type) {
                            var currAmount = ebtPaymentCtrl.ebtValues[info.valName];
                            var previousAmount = info.previousPayment ? info.previousPayment.preAuthAmount : 0;
                            var updateAmount = !currAmount ? 0 : parseFloat(currAmount);
                            var finalUpdateAmount = _toFloatNum(updateAmount - previousAmount);

                            if (finalUpdateAmount) {
                                updateOrderCtrl.finalUpdatePayments[type] = {
                                    typeId: info.id,
                                    methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT,
                                    chargeType: finalUpdateAmount > 0 ? 'charge' : 'refund',
                                    amount: finalUpdateAmount,
                                    preAuthAmount: finalUpdateAmount,
                                    creditCardId: ebtPaymentCtrl.ebtCard.id,
                                }
                            } else {
                                delete updateOrderCtrl.finalUpdatePayments[type];
                            }
                        });
                    }

                    function _getEBTCheckoutPaymentTransaction(paymentType) {
                        return mDesign.dialog({
                            focusOnOpen: false,
                            clickOutsideToClose: false,
                            templateUrl: 'views/templates/ebt-pin-message-dialog.html',
                            controller: ['$scope', function($scope) {
                                $scope.paymentType = paymentType;
                                $scope.hide = mDesign.hide;
                            }]
                        }).then(function () {
                            return _getEBTTransaction();
                        });
                    }

                    function _getEBTTransaction() {
                        return paymentsService.initEBTPayment({
                            creditCardId: ebtPaymentCtrl.ebtCard.id,
                            address: ebtPaymentCtrl.deliveryInfo.address,
                            isPickup: !!ebtPaymentCtrl.deliveryInfo.address.isPickup
                        })
                    }

                    _listeners.push($rootScope.$on('cart.update.complete', _initializeSummaryObj));

                    util.destroyListeners($scope, _listeners);
                }]);
})(angular);
