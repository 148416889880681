angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.recentPurchases', {
        url: '/recent-purchases',
        data: {
            stateAccess: PAGE_ACCESS.LOGGED_IN,
            bodyClass: 'recent-purchases',
            metaTags: {
                title: 'Recent purchases'
            }
        },
        views: {
            '@': {
                templateUrl: 'views/recent-purchases.html',
                controller: 'RecentPurchasesCtrl'
            }
        }
    });
}]).controller('RecentPurchasesCtrl', ['$rootScope', function ($rootScope) {
    $rootScope.title = 'My lists';
    }]);