(function (angular) {
    angular.module('mobilezuz').config(['$stateProvider', function($stateProvider) {
        $stateProvider.state('app.cart.order-update.finish', {
            url: '/finish/:orderId?{totalAmount},{isPickAndGo}',
            views: {
                updateOrder: {
                    templateUrl: 'views/order/update/finish.html',
                    controller: 'UpdateOrderFinishCtrl as finishCtrl'
                }
            }
        });

        $stateProvider.state('app.user-confirmation.update.finish', {
            url: '/finish?{totalAmount},{isPickAndGo:bool}',
            views: {
                updateOrder: {
                    templateUrl: 'views/order/update/finish.html',
                    controller: 'UpdateOrderFinishCtrl as finishCtrl'
                }
            }
        });
    }]).controller('UpdateOrderFinishCtrl', [
        '$scope', '$stateParams', '$state', '$filter', 'Retailer', 'User', 'Util', 'updateType',
        'UPDATE_ORDER_PAYMENT_TYPE',
        function($scope, $stateParams, $state, $filter, Retailer, User, Util, updateType,
                 UPDATE_ORDER_PAYMENT_TYPE) {
            var finishCtrl = this,
                updateOrderCtrl = $scope.updateOrderCtrl,
                _roundCurrencyFilter = $filter('roundCurrency');

            finishCtrl.orderId = Number($stateParams.orderId);
            finishCtrl.showEditOrder = updateType.type === UPDATE_ORDER_PAYMENT_TYPE.EDIT_ORDER_UPDATE && !$stateParams.isPickAndGo;
            finishCtrl.goToHome = goToHome;
            updateOrderCtrl.back = undefined;
            updateOrderCtrl.forward = undefined;

            Retailer.getRetailerSettings().then(function (retailer) {
                finishCtrl.retailer = retailer;
            });

            User.getUserSettings().then(function (user) {
                finishCtrl.user = user;
            });

            function goToHome(event) {
                event && event.stopPropagation();
                event && event.preventDefault();
                $state.go('app.home');
            }
        }]);
})(angular);
