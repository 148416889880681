angular
  .module("mobilezuz")
  .service("GgMapMobileService", ["GOOGLE_MAP_MOBILE_EVENTS", "GOOGLE_MAP_ADDRESS_TYPES","gMapAbstractService","AddressService", 'Config',
  function(GOOGLE_MAP_MOBILE_EVENTS, GOOGLE_MAP_ADDRESS_TYPES, gMapAbstractService, AddressService, Config){   

      /**
       * @typedef {Object} MGeocodeExtra
       * @property {string} featureName // building/area name
       * @property {string} premises    // similar to featureName but rarely used
       * @property {string[]} lines     // formatted full address
       */

      /**
       * @typedef {Object} MGeocodePosition
       * @property {number} lat
       * @property {number} lng
       */

      /**
       * @typedef {Object} MGeocode         // M presents for mobile
       * @property {string} subThoroughfare // address number
       * @property {string} thoroughfare    // street
       * @property {string} subAdminArea    // district
       * @property {string} adminArea       // city/province/state
       * @property {string} locality        // same level as adminArea
       * @property {string} country
       * @property {string} countryCode
       * @property {string} locale
       * @property {MGeocodeExtra} extra
       * @property {MGeocodePosition} position
       */

    var self = this;
    var events = {
      onSelectAutocompleteOption: "onSelectAutocompleteOption",
    };

    angular.extend(self, gMapAbstractService);

    angular.extend(self, {
      events: events,

      initMap: initMap,
      initMarker: initMarker,
      initInfoWindow: initInfoWindow,

      reverseGeocode: reverseGeocode,
      geocoding: geocoding,
      extractInfoFromAddressComponents: extractInfoFromAddressComponents,

      moveCamera: moveCamera,
      moveMapMoveMarkerListener: moveMapMoveMarkerListener,
      clickMarkerTogglePopupListener: clickMarkerTogglePopupListener,
    });

    /**
     * @param {MGeocodePosition} latLng
     * @returns {MGeocode}
     */
    function reverseGeocode(options) {
      return new Promise(function (resolve) {
        plugin.google.maps.Geocoder.geocode(options,
          function (geocodes) {
            resolve(geocodes[0]);
          }
        );
      });
    }

    function geocoding(address, languageId) {
      return AddressService.geocodeByGgMapApi({ address: address, languageId: languageId }).then(
        function (res) {
          if (!res || !res.results | !res.results.length) {
            return [];
          }

          return _mapGeocodesToMGeocodes(res.results);
        }
      );
    }

    /**
     * @param {Geocode[]} geocodes
     * @returns {MGeocodes[]}
     */
    function _mapGeocodesToMGeocodes(geocodes) {
      var addresses = [];

      angular.forEach(geocodes, function (geocode) {
        var adrsCpnLastIndex = geocode.address_components.length - 1;

        /** @type {MGeocode} */
        var item = {
          position: {
            lat: geocode.geometry.location.lat,
            lng: geocode.geometry.location.lng,
          },

          locality: geocode.address_components[2].long_name,
          country: geocode.address_components[adrsCpnLastIndex].long_name,
          countryCode: geocode.address_components[adrsCpnLastIndex].short_name,
          extra: {
            featureName: geocode.formatted_address.split(", ")[0],
            lines: [geocode.formatted_address],
          },
        };

        addresses.push(item);
      });

      return addresses;
    }

    /**
     * @param {Element} mapRef
     * @param {*} options
     * @returns {Promise<*>} map
     */
    function initMap(mapRef, options) {
      if (!mapRef || !location) {
        return;
      }

      return new Promise(function (resolve) {
        var map = plugin.google.maps.Map.getMap(mapRef, options);

        map.one(GOOGLE_MAP_MOBILE_EVENTS.MAP_READY, function (map) {
          resolve(map);
        });
      });
    }

    function moveCamera(map, location) {
      map.moveCamera({
        target: location,
      });
    }

    /**
     *
     * @param {*} map
     * @param {*} marker
     * @param {Function} callback
     * @returns {void}
     */
    function moveMapMoveMarkerListener(map, marker, callback) {
      if (!map || !marker) {
        return;
      }

      map.on(GOOGLE_MAP_MOBILE_EVENTS.CAMERA_TARGET_CHANGED, function (location) {
        marker.setPosition(location);
      });

      map.on(GOOGLE_MAP_MOBILE_EVENTS.CAMERA_MOVE_END, function (camera) {
        return AddressService.geocodeByGgMapApi({
          lat: camera.target.lat,
          lng: camera.target.lng,
          languageId: Config.language.id
        }).then(function (res) {
          return callback(res.results[0]);
        });
      });
    }

    /**
     *
     * @param {*} map
     * @param {MGeocodePosition} location
     * @returns marker
     */
    function initMarker(map, location) {
      if (!map || !location) {
        return;
      }

      var options = {
        position: location,
      };

      return map.addMarker(options, function (marker) {
        marker.showInfoWindow();
        return marker;
      });
    }

    /**
     * @param {Map} map
     * @param {MGeocodePosition} location
     * @returns
     */
    function moveCamera(map, location) {
      if (!map || !location) {
        return;
      }

      map.moveCamera({
        target: location,
      });
    }

    /**
     *
     * @param {string} html
     * @returns infoWindow
     */
    function initInfoWindow(html) {
      var infoWindow = new plugin.google.maps.HtmlInfoWindow();
      infoWindow.setContent(html);

      return infoWindow;
    }

    /**
     * @param {{
     * marker: any
     * infoWindow: any
     * }} param0
     */
    function clickMarkerTogglePopupListener(param0) {
      var marker = param0.marker;
      var infoWindow = param0.infoWindow;

      if (!marker) {
        return;
      }

      marker.addEventListener(GOOGLE_MAP_MOBILE_EVENTS.MARKER_CLICK, function () {
        infoWindow.open(marker);
      });
    }

    /**
     * @param {AddressComponents[]} addressComponents
     * @returns {{houseNumber: string, route: string, city: string, country: string, countryCode: string, zipCode: string}}
     */
    function extractInfoFromAddressComponents(addressComponents) {
      var _houseNumber = _findFirstComponentByTypes(addressComponents, [GOOGLE_MAP_ADDRESS_TYPES.STREET_NUMBER, GOOGLE_MAP_ADDRESS_TYPES.PREMISE]);
      var _route = _findFirstComponentByTypes(addressComponents, [GOOGLE_MAP_ADDRESS_TYPES.ROUTE, GOOGLE_MAP_ADDRESS_TYPES.ESTABLISHMENT]);
      var _zipCode = _findFirstComponentByTypes(addressComponents, [GOOGLE_MAP_ADDRESS_TYPES.ZIP_CODE]);
      var _country = _findFirstComponentByTypes(addressComponents, [GOOGLE_MAP_ADDRESS_TYPES.COUNTRY]);
      var _city = _findFirstComponentByTypes(addressComponents, [GOOGLE_MAP_ADDRESS_TYPES.LOCALITY, GOOGLE_MAP_ADDRESS_TYPES.SUBLOCALITY_LEVEL_1, GOOGLE_MAP_ADDRESS_TYPES.SUB_LOCALITY, GOOGLE_MAP_ADDRESS_TYPES.ADMIN_AREA_LV2, GOOGLE_MAP_ADDRESS_TYPES.POSTAL_TOWN]);
      var _state = _findFirstComponentByTypes(addressComponents, [GOOGLE_MAP_ADDRESS_TYPES.ADMIN_AREA_LV1]);

      var result = {
        houseNumber: _houseNumber.long_name,
        route: _route.long_name,
        zipCode: _zipCode.long_name,
        country: _country.long_name,
        countryCode: _country.short_name,
        city: _city.long_name,
        state: _state.short_name,
      }

      return result;
    }

    function _findFirstComponentByTypes(addressComponents, types) {
      for (var key in types) {
        var type = types[key];
        var result = addressComponents.find(function (item) { return item.types.includes(type) });
        if (result) return result
      }

      return {
        long_name: '',
        short_name: '',
      }
    }
 }
]);
