(function(angular) {
    /**
     * @typedef {Object} CheckoutRes
     * @property {number} id
     * @property {boolean} isCreditCardLoyalty
     * @property {boolean} [orderExists] // only exist when call checkDuplicateOrder
     */

    /**
     * @typedef {Object} CheckDuplicateOrderBody
     * @property {number} cartId
     * @property {number} areaId
     * @property {number} deliveryTimeId
     * @property {number} total
     * @property {string} [metaData]
     */

    /**
     * @typedef {Object} DuplicatedOrderRes
     * @property {number} orderId
     * @property {number} TotalAmount
     * @property {number} AreaDeliveryTime_Id
     * @property {boolean} orderExists
     */

    var MINUTE_IN_MS = 1000 * 60,
      DAY_IN_MS = MINUTE_IN_MS * 60 * 24;

    angular.module('mobilezuz').config(['$stateProvider', function($stateProvider) {
        $stateProvider.state('app.cart.checkout.process', {
            url: '/:method',
            data: {
                validation: ['$rootScope', '$q', '$stateParams', 'Cart', 'CHECKOUT_METHODS', function($rootScope, $q, $stateParams, Cart, CHECKOUT_METHODS) {
                    var defer = $q.defer(),
                      listener;

                    function listenerCallback() {
                        listener();
                        validate();
                    }

                    function validate() {
                        if (angular.isUndefined($stateParams.method)) {
                            return listener = $rootScope.$on('$stateChangeSuccess', listenerCallback);
                        }

                        if ($stateParams.method === CHECKOUT_METHODS.VALUES.PICK_AND_GO) {
                            return defer.resolve();
                        }

                        if (Cart.total.calculating) {
                            return listener = $rootScope.$on('cart.totalCalculated', listenerCallback);
                        }

                        if (!Cart.sendingSucceeded) {
                            return listener = $rootScope.$on('cart.update.complete', listenerCallback);
                        }

                        if (Cart.serverCartId && Cart.total.actualLines) {
                            return defer.resolve();
                        }

                        return defer.reject('app.cart');
                    }

                    validate();

                    return defer.promise;
                }]
            },
            resolve: {
                branchArea: [
                    '$rootScope', '$stateParams', '$q', 'Config', 'SpDeliveryTimesService', 'Retailer', 'User', 'Cart', 'Orders',
                    'CHECKOUT_METHODS', 'DELIVERY_TYPES','Util', 'SpDeliveryAreasService',
                    function($rootScope, $stateParams, $q, Config, SpDeliveryTimesService, Retailer, User, Cart, Orders,
                             CHECKOUT_METHODS, DELIVERY_TYPES, Util, SpDeliveryAreasService) {
                        return $q.all({
                            deliveryMethods: SpDeliveryTimesService.init(),
                            configInit: Config.waitForInit()
                        }).then(function(results) {
                            if ($stateParams.method === CHECKOUT_METHODS.VALUES.PICKUP) {
                                return SpDeliveryTimesService.getTimes(DELIVERY_TYPES.PICKUP, {
                                    cartId: Cart.serverCartId,
                                    isDeliveryWithinDaysByTag: Config.retailer.isDeliveryWithinDaysByTag
                                });
                            }

                            var deliveryType = DELIVERY_TYPES.DELIVERY;
                            if ($stateParams.method !== CHECKOUT_METHODS.VALUES.DELIVERY) {
                                deliveryType = Object.keys(CHECKOUT_METHODS.BY_DELIVERY_TYPE).find(function(key) {
                                    return CHECKOUT_METHODS.BY_DELIVERY_TYPE[key] === $stateParams.method;
                                });
                            }
                            if (!deliveryType || !results.deliveryMethods[deliveryType]) {
                                return;
                            }

                            return User.getUserSettings().then(function(user) {
                                var address = user && user.addresses && user.addresses.length && user.addresses[0];
                                var typedAreaText = localStorage.getItem('mobileZuzTypedAreaText'),
                                  typedAddressText = localStorage.getItem('mobileZuzTypedAddressText'),
                                  typedCity = typedAreaText;

                                try {
                                    var addressComponents = JSON.parse(localStorage.getItem('mobileZuzTypedAreaAddressComponents'));
                                    if (addressComponents) {
                                        var typedAddress = SpDeliveryAreasService.addressComponentsToSpAddress(addressComponents)
                                        typedCity = typedAddress.city
                                    }
                                } catch (err) {
                                    // In case "mobileZuzTypedAreaAddressComponents" is not array
                                }

                                if(typedCity && address && typedCity !== address.city && !Config.retailer.settings.autocompleteAddressField) {
                                    address.text1 = address.text1 || typedAddressText || '';
                                    address.city = address.city || typedCity || '';
                                }

                                if (!address || !address.text1 || !address.text1.trim() || !address.street || !address.street.trim()) {
                                    return SpDeliveryTimesService.getTimes(deliveryType, {
                                        cartId: Cart.serverCartId,
                                        areaId: Config.branchAreaId,
                                        isDeliveryWithinDaysByTag: Config.retailer.isDeliveryWithinDaysByTag
                                    }).catch(function() { });
                                }

                                return SpDeliveryTimesService.getTimes(deliveryType, {
                                    address: {
                                        text1: address.text1,
                                        street: address.street,
                                        houseNumber: address.houseNumber,
                                        entry: address.entry,
                                        city: address.city,
                                        zipCode: address.zipCode
                                    },
                                    cartId: Cart.serverCartId,
                                    languageId: Config.language.id,
                                    isDeliveryWithinDaysByTag: Config.retailer.isDeliveryWithinDaysByTag
                                }).catch(function() { });
                            });
                        });
                    }
                ],
                resetUserData: ['User', function(User) {
                    return User.getUserSettings(true);
                }]
            },
            views: {
                'checkoutView': {
                    templateUrl: 'views/checkout/process/index.html',
                    controller: 'CheckoutProcessCtrl as checkoutProcessCtrl'
                }
            }
        });

    }]).controller('CheckoutProcessCtrl', [
        '$scope', '$rootScope', '$q', '$timeout', '$window', '$filter', '$state', '$stateParams', 'Config', 'Util', 'Api',
        'Cart', 'Orders', 'Retailer', 'PaymentsService', 'User', 'SpDeliveryTimesService', 'SpDeliveryAreasService',
        'mDesign', 'ChooseAreaDialog', 'ExternalCheckout', 'branchArea', 'DELIVERY_TYPES', 'CREDIT_CARD_MODES',
        'CART_LINE_TYPES', 'DELIVERY_CHOICES', 'SP_SERVICES', 'SP_PAYMENTS', 'CREDIT_CARD_TYPES', 'SUBSTITUTE_PREFERENCES',
        'CHARGE_SPECIALS_CALCULATION_TIME', 'CHECKOUT_METHODS', 'HOUSE_ENTRIES', 'CREDIT_CARD_3DS_MODES',
        'DELIVERY_TIMES_TYPES', 'DataLayer', 'FILTER_ADDRESS_MODE',
        function($scope, $rootScope, $q, $timeout, $window, $filter, $state, $stateParams, Config, Util, Api,
                 Cart, Orders, Retailer, PaymentsService, User, SpDeliveryTimesService, SpDeliveryAreasService,
                 mDesign, ChooseAreaDialog, ExternalCheckout, branchArea, DELIVERY_TYPES, CREDIT_CARD_MODES,
                 CART_LINE_TYPES, DELIVERY_CHOICES, SP_SERVICES, SP_PAYMENTS, CREDIT_CARD_TYPES, SUBSTITUTE_PREFERENCES,
                 CHARGE_SPECIALS_CALCULATION_TIME, CHECKOUT_METHODS, HOUSE_ENTRIES, CREDIT_CARD_3DS_MODES,
                 DELIVERY_TIMES_TYPES, DataLayer, FILTER_ADDRESS_MODE) {
            var checkoutProcessCtrl = this,
              checkoutCtrl = $scope.checkoutCtrl,
              _checkoutContainerElement,
              _checkoutHeaderElement,
              _roundCurrencyFilter = $filter('roundCurrency'),
              _translate = $filter('translate'),
              _defaultGiftCardExclusionMessage = {
                  "he": "לתשומת ליבך: ישנם פריטים ממחלקות מסוימות שאינם ניתנים לרכישה בכרטיס המתנה ויחויבו בכרטיס האשראי שלך.",
                  "en": "Please note: There are items from certain departments that cannot be purchased with the gift card and will be charged to your credit card.",
                  "ar": "يرجى ملاحظة: هناك عناصر من أقسام معينة لا يمكن شراؤها ببطاقة الهدية وسيتم خصمها من بطاقتك الائتمانية.",
                  "ru": "Обратите внимание: некоторые товары из определенных отделов нельзя купить с помощью подарочной карты, и они будут списаны с вашей кредитной карты.",
                  "es": "Ten en cuenta: Hay artículos de ciertos departamentos que no se pueden comprar con la tarjeta de regalo y se cargarán a tu tarjeta de crédito."
              },
              _listeners = [],
              _addressVerificationText = Config.retailer.settings.addressVerificationText ? JSON.parse(Config.retailer.settings.addressVerificationText) : {},
              _addressVerificationTextDroppinOff = JSON.parse(Config.retailer.settings.addressVerificationTextDroppinOff || '{}'),
              _currentLanguage = Config.language.culture,
              _DEFAULT_ERROR_MESSAGE = {
                  city: 'Sorry, Your city was not found. Please select an address from auto-complete suggestions or pin your address using the google maps option.',
                  address: 'Sorry, Your address was not found. Please select an address from auto-complete suggestions or pin your address using the google maps option.',
                  zipCode: 'Sorry, Your zip code was not found. Please select an address from auto-complete suggestions or pin your address using the Google Maps option.'
              },
              _DEFAULT_ERROR_MESSAGE_NON_DROP_PIN = {
                  city: 'autocomplete_city_not_found_non_droppin',
                  address: 'autocomplete_address_not_found_non_droppin',
                  zipCode: 'autocomplete_zip_code_not_found_non_droppin'
              },
              _ADDRESS_TYPE_MAP = {
                  city: 'city',
                  text1: 'address',
                  zipCode: 'zipCode'
              },
              _addressComponents = ['city', 'text1', 'zipCode'];

            checkoutCtrl.isPickAndGo = $stateParams.method === CHECKOUT_METHODS.VALUES.PICK_AND_GO;
            checkoutCtrl.orderService = Orders;
            checkoutProcessCtrl.nextButtonDisabled = false;
            checkoutProcessCtrl.isDelivery = $stateParams.method === CHECKOUT_METHODS.VALUES.DELIVERY || $stateParams.method === CHECKOUT_METHODS.VALUES.PICK_AND_GO;
            checkoutProcessCtrl.phoneRegExp = new RegExp(/^\(?\+?(\d[\s,\-,(,),,,]*){7,15}$/);
            checkoutProcessCtrl.customPhoneRegExp = new RegExp(/^\(?\+?(\d[0-9\)\(-]*)$/);
            checkoutProcessCtrl.customPhoneValidation = Config.retailer.customPhoneValidation && Config.retailer.customPhoneValidation.isActive;
            checkoutProcessCtrl.validCountryCode = true;
            checkoutProcessCtrl.validAreaCode = true;
            checkoutProcessCtrl.validPhoneInput = true;
            checkoutProcessCtrl.editPhoneMode = false;
            checkoutProcessCtrl.leaveByTheDoor = false
            checkoutProcessCtrl.receiptOptions = Object.values($rootScope.RECEIPT_TYPE_ID) || [];
            checkoutProcessCtrl.isReceiptOption = Config.retailer.settings.isReceiptOption === 'true';
            checkoutProcessCtrl.countryNames = Util.getCountryAutocompleteOptions();
            checkoutProcessCtrl.previousFullDeliveryAddress = {};

            if(User.settings.phones && User.settings.phones.length) {
                var mobilePhoneIndex = User.settings.phones.findIndex(function(phone) {
                    return phone.typeVal == 3;
                });

                checkoutProcessCtrl.mobilePhone = User.settings.phones[mobilePhoneIndex] || {};

                if(checkoutProcessCtrl.customPhoneValidation && Config.retailer.customPhoneValidation.areaCodes && Config.retailer.customPhoneValidation.areaCodes.length === 1) {
                    checkoutProcessCtrl.mobilePhone.areaCode = Config.retailer.customPhoneValidation.areaCodes[0]
                }

                if(checkoutProcessCtrl.customPhoneValidation && Config.retailer.customPhoneValidation.countryCodes && Config.retailer.customPhoneValidation.countryCodes.length === 1) {
                    checkoutProcessCtrl.mobilePhone.countryCode = Config.retailer.customPhoneValidation.countryCodes[0]
                }

                var phoneObjCopy = angular.copy(checkoutProcessCtrl.mobilePhone);
                checkoutProcessCtrl.phoneNumber = phoneObjCopy.phoneNumber;
                checkoutProcessCtrl.customPhoneNumber = phoneObjCopy.customPhoneNumber;
                checkoutProcessCtrl.areaCode = phoneObjCopy.areaCode;
                checkoutProcessCtrl.countryCode = phoneObjCopy.countryCode;
            } else {
                checkoutProcessCtrl.mobilePhone = {};
            }

            if (branchArea && branchArea.area && angular.isArray(branchArea.area.areas) && branchArea.area.areas.length > 1) {
                setAreas(branchArea.area.areas);
            }
            //for now the address is always first with this boolean - in the future if the address will stay first need to remove this boolean and the option for times to be first
            checkoutProcessCtrl.isAddressFirst = true;//!branchArea;
            checkoutProcessCtrl.isLastView = isLastView;
            checkoutProcessCtrl.openPaypalWindow = openPaypalWindow;
            checkoutProcessCtrl.openICreditMasterPassWindow = openICreditMasterPassWindow;
            checkoutProcessCtrl.goToState = goToState;
            checkoutProcessCtrl.submitOrder = submitOrder;
            checkoutProcessCtrl.forward = forward;
            checkoutProcessCtrl.back = back;
            checkoutProcessCtrl.setToolTip = setToolTip;
            checkoutProcessCtrl.clearToolTip = clearToolTip;
            checkoutProcessCtrl.addDeliveryProduct = addDeliveryProduct;
            checkoutProcessCtrl.isPaymentLast = isPaymentLast;
            checkoutProcessCtrl.isNonLoyaltyCardWarning = isNonLoyaltyCardWarning;
            checkoutProcessCtrl.setAreas = setAreas;
            checkoutProcessCtrl.getAreaByConfigAreaId = getAreaByConfigAreaId;
            checkoutProcessCtrl.isPaymentSubmitButton = isPaymentSubmitButton;
            checkoutProcessCtrl.getDeliveryTimes = getDeliveryTimes;
            checkoutProcessCtrl.showChooseAreaDialog = showChooseAreaDialog;
            checkoutProcessCtrl.setAreaByConfigAreaId = setAreaByConfigAreaId;
            checkoutProcessCtrl.substitutePreferenceChanged = substitutePreferenceChanged;
            checkoutProcessCtrl.openPayWindow = openPayWindow;
            checkoutProcessCtrl.getEBTTransaction = getEBTTransaction;
            checkoutProcessCtrl.showWeightDebitDialog = showWeightDebitDialog;
            checkoutProcessCtrl.checkFloorInput = checkFloorInput;
            checkoutProcessCtrl.preCheckout = preCheckout;
            checkoutProcessCtrl.addDeliveryProductOnStateChange = addDeliveryProductOnStateChange;
            checkoutProcessCtrl.validateSelectField = validateSelectField;
            checkoutProcessCtrl.openAutocompleteAddress = openAutocompleteAddress;
            checkoutProcessCtrl.checkForAutocompleteAddress = checkForAutocompleteAddress;
            checkoutProcessCtrl.filterCountryNames = filterCountryNames;
            checkoutProcessCtrl.giftCardExclusionMessage = Config.retailer.giftCardExclusion && Config.retailer.giftCardExclusion.message ? Config.retailer.giftCardExclusion.message : _defaultGiftCardExclusionMessage;
            checkoutProcessCtrl.verifyIsSuggestedValue = _verifyIsSuggestedValue;

            Config.checkoutData = Config.checkoutData || {};
            Config.checkoutData.method = $stateParams.method;
            _setReceiptOption();

            angular.forEach(['addressDetails', 'timesDetails', 'paymentDetails'], function(detailsName) {
                if (!Config.checkoutData[detailsName]) {
                    return;
                }

                checkoutProcessCtrl[detailsName] = Config.checkoutData[detailsName];
            });

            var addressState = $state.get('app.cart.checkout.process.address'),
              timesState = $state.get('app.cart.checkout.process.times'),
              summaryState = $state.get('app.cart.checkout.process.summary'),
              paymentState = $state.get('app.cart.checkout.process.payment'),
              mapState = $state.get('app.cart.checkout.process.map');

            if (checkoutProcessCtrl.isAddressFirst) {
                timesState.checkoutViewIndex = timesState.checkoutViewOriginalIndex + 1;
                addressState.checkoutViewIndex = addressState.checkoutViewOriginalIndex - 1;
                mapState.checkoutViewIndex = mapState.checkoutViewOriginalIndex - 1
            } else {
                timesState.checkoutViewIndex = timesState.checkoutViewOriginalIndex;
                addressState.checkoutViewIndex = addressState.checkoutViewOriginalIndex;
            }
            _setCurrentView($state.current.checkoutViewIndex);

            var _getRetailerPromise = Retailer.getRetailerSettings().then(function(response) {
                checkoutProcessCtrl.retailer = response;
                checkoutProcessCtrl.retailerLogo = $rootScope.retailerLogo;

                var branchDeliveryTypeIdMap = {};
                angular.forEach(CHECKOUT_METHODS.BY_DELIVERY_TYPE, function(method, key) {
                    if (method === $stateParams.method) {
                        branchDeliveryTypeIdMap[key] = true;
                    }
                });

                var hasCreditCardOption = (response.checkoutPaymentMethods || []).some(function(paymentMethod) {
                    return paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD && branchDeliveryTypeIdMap[paymentMethod.branchDeliveryTypeId];
                });

                checkoutProcessCtrl.hasPaypalOption = (response.checkoutPaymentMethods || []).some(function(paymentMethod) {
                    return paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.PAYPAL && branchDeliveryTypeIdMap[paymentMethod.branchDeliveryTypeId];
                });

                checkoutProcessCtrl.hasICReditMasterPassOption = (response.checkoutPaymentMethods || []).some(function(paymentMethod) {
                    return paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.I_CREDIT_MASTER_PASS && branchDeliveryTypeIdMap[paymentMethod.branchDeliveryTypeId];
                });

                var settings = Util.getBranchSettings(response);

                checkoutProcessCtrl.isRememberCreditCardsMode = !hasCreditCardOption || settings.creditCardMode === CREDIT_CARD_MODES.REMEMBER_CARDS;

                var creditCard3DSModesObject = {};
                angular.forEach(settings && settings.creditCard3DSModes || [], function(modeId) {
                    creditCard3DSModesObject[modeId] = true;
                });
                checkoutProcessCtrl.isCreditCard3DSActive = checkoutProcessCtrl.isRememberCreditCardsMode && !!creditCard3DSModesObject[CREDIT_CARD_3DS_MODES.ON_PAYMENT];

                if (checkoutProcessCtrl.isPaymentLast()) {
                    _switchSummaryAndPayment();
                }

                _showOrderFinishedDialog(response.settings.orderFinishedText);

                _addICreditMasterPassListener();
            });

            User.getUserSettings().then(function(resp) {
                checkoutProcessCtrl.userData = resp;

                //== when it's Credit Customer there are 3 screens instead of 4
                if (checkoutProcessCtrl.isPaymentLast()) {
                    _switchSummaryAndPayment();
                }

                checkoutProcessCtrl.hasAddressDetails = Util.checkAddressFields(checkoutProcessCtrl.userData.addresses[0]);
                if(checkoutProcessCtrl.userData.addresses && checkoutProcessCtrl.userData.addresses.length && checkoutProcessCtrl.userData.addresses[0].country) {
                    checkoutProcessCtrl.filterCountryNames(checkoutProcessCtrl.userData.addresses[0].country);
                }
            });

            // Init substitute preferences
            var availableSubstitutePreferences = Config.branch.settings &&
              Config.branch.settings.availableSubstitutePreferences ||
              Config.retailer.settings.availableSubstitutePreferences;
            checkoutProcessCtrl.substitutions = angular.filterCollection([
                SUBSTITUTE_PREFERENCES.WHATSAPP,
                SUBSTITUTE_PREFERENCES.SEND_SMS,
                SUBSTITUTE_PREFERENCES.CALL_ME,
                SUBSTITUTE_PREFERENCES.ALLOW,
                SUBSTITUTE_PREFERENCES.DO_NOT_ALLOW
            ], function(preference) {
                return availableSubstitutePreferences[preference];
            });
            checkoutProcessCtrl.substitutePreference = Config.checkoutData.substitutePreference || checkoutProcessCtrl.substitutions[0];
            substitutePreferenceChanged();

            _init();

            function _init() {
                if (Config.retailer.settings.enableDefaultCountry) {
                    _addressComponents.push('country');
                }
            }

            function checkFloorInput (event) {
                if (!((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) && event.keyCode != 8) {
                    event.preventDefault();
                }
            }

            function _switchSummaryAndPayment() {
                if (paymentState.checkoutViewIndex > summaryState.checkoutViewIndex) {
                    return;
                }

                paymentState.checkoutViewIndex = paymentState.checkoutViewOriginalIndex + 1;
                summaryState.checkoutViewIndex = summaryState.checkoutViewOriginalIndex - 1;
                _setCurrentView($state.current.checkoutViewIndex);
            }

            // var _stateChangeListener;
            function goToState(stateName, seconds, focusOnFirstElement) {
                return $q.resolve(function() {
                    var defer = $q.defer();
                    $timeout(function() {
                        defer.resolve();
                    }, seconds);
                    return defer.promise;
                }).then(function() {
                    if ($rootScope.intiDialogClosed) return;

                    var defer = $q.defer(),
                      listener = $rootScope.$on('initDialog.closed', function() {
                          listener();
                          defer.resolve();
                      });
                    return defer.promise;
                }).then(function() {
                    $state.go(stateName, {method: $stateParams.method});

                    if (focusOnFirstElement) {
                        $timeout(function () {
                            var focusElement = $window.document.querySelector('#checkout_views > .checkout-header .header-content .exit');
                            focusElement && focusElement.focus();
                        });
                    }
                });
            }

            function back() {
                if (!checkoutProcessCtrl.currentView) {
                    if (!checkoutCtrl.hasBeforeState) return;

                    if (checkoutCtrl.isPickAndGo) {
                        delete Config.checkoutData;
                        return $state.go('app.pickAngGo');
                    }

                    return checkoutProcessCtrl.goToState('app.cart.checkout.before', undefined, true);
                }

                checkoutProcessCtrl.previousView();
            }

            function checkLoyaltyCreditCard() {
                if (isNonLoyaltyCardWarning()) {
                    return mDesign.dialog({
                        focusOnOpen: false,
                        clickOutsideToClose: true,
                        templateUrl: 'views/templates/loyalty-discounts-warning.html',
                        controller: ['$scope', function($scope) {
                            $scope.hide = mDesign.hide;
                            $scope.loyaltyName = checkoutProcessCtrl.retailer.loyaltyClubDriver.name;
                        }]
                    });
                } else {
                    return $q.resolve(true);
                }
            }

            /**
             * Returns whether a loyalty card exists but not chosen
             * @public
             *
             * @return {boolean}
             */
            function isNonLoyaltyCardWarning() {
                return !!checkoutProcessCtrl.hasLoyaltyCreditCards && (
                  checkoutProcessCtrl.paymentDetails.paymentMethod !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD ||
                  !checkoutProcessCtrl.paymentDetails.creditCard.isLoyalty
                );
            }

            /**
             * Add the array in the format of the checkoutProcessCtrl.timesDetails.branchArea object
             * @param {Array<Object>} areas
             * @param {boolean} [isDefault]
             */
            function setAreas(areas, isDefault) {
                checkoutProcessCtrl.areas = [];
                angular.forEach(areas, function(area) {
                    checkoutProcessCtrl.areas.push({
                        area: area,
                        times: area.times,
                        defaultSelected: isDefault
                    });
                });
            }

            function getAreaByConfigAreaId() {
                var branchArea;
                angular.forEach(checkoutProcessCtrl.areas, function(branchAreaItem) {
                    if (branchAreaItem.area.id === Config.branchAreaId) {
                        branchArea = branchAreaItem;
                    }
                });
                return branchArea;
            }

            function compileCustomValidatedPhone(phone) {
                return [
                    phone.countryCode ? phone.countryCode.toString() : '',
                    phone.areaCode ? phone.areaCode.toString() : '',
                    phone.customPhoneNumber ? phone.customPhoneNumber.toString() : ''
                ].join('');
            }

            function checkCustomPhoneValidity() {
                var phoneNumber = checkoutProcessCtrl.customPhoneValidation && checkoutProcessCtrl.mobilePhone.customPhoneNumber ? checkoutProcessCtrl.mobilePhone.customPhoneNumber : checkoutProcessCtrl.mobilePhone.phoneNumber;
                if(checkoutProcessCtrl.customPhoneValidation && checkoutProcessCtrl.mobilePhone.customPhoneNumber) {
                    validateSelectField('ALL');
                    var customValidation = Config.retailer.customPhoneValidation;
                    checkoutProcessCtrl.validPhoneInput = true;
                    if(!phoneNumber ||
                      checkoutProcessCtrl.customPhoneNumber != checkoutProcessCtrl.mobilePhone.customPhoneNumber ||
                      checkoutProcessCtrl.phoneNumber != checkoutProcessCtrl.mobilePhone.phoneNumber ||
                      checkoutProcessCtrl.countryCode != checkoutProcessCtrl.mobilePhone.countryCode ||
                      checkoutProcessCtrl.areaCode != checkoutProcessCtrl.mobilePhone.areaCode) {
                        var maxLength = customValidation.phoneNumberRange.max;
                        var minLength = customValidation.phoneNumberRange.min;
                        if(maxLength && minLength) {
                            checkoutProcessCtrl.validPhoneInput = (phoneNumber.length >= minLength) && (phoneNumber.length <= maxLength);
                        } else if(minLength) {
                            checkoutProcessCtrl.validPhoneInput = phoneNumber.length >= minLength
                        } else if (maxLength) {
                            checkoutProcessCtrl.validPhoneInput = phoneNumber.length <= maxLength;
                        }
                    }

                    if (!(checkoutProcessCtrl.validCountryCode && checkoutProcessCtrl.validAreaCode && checkoutProcessCtrl.validPhoneInput)) {
                        mDesign.alert('{{(\'Phone number is not valid.\' | translate)}}');
                        return false;
                    } else {
                        checkoutProcessCtrl.addressDetails.phone = checkoutProcessCtrl.mobilePhone.phoneNumber ? checkoutProcessCtrl.mobilePhone.phoneNumber : compileCustomValidatedPhone(checkoutProcessCtrl.mobilePhone);
                        checkoutProcessCtrl.addressDetails.customPhoneNumber = checkoutProcessCtrl.mobilePhone.customPhoneNumber;
                        return true;
                    }
                } else if (!phoneNumber) {
                    mDesign.alert('{{(\'Phone number is not valid.\' | translate)}}');
                    return false;
                } else {
                    checkoutProcessCtrl.addressDetails.phone = checkoutProcessCtrl.mobilePhone.phoneNumber;
                    return true;
                }
            }

            function validateSelectField (type) {
                var customValidation = Config.retailer.customPhoneValidation
                if(customValidation.countryCodes && customValidation.countryCodes.length && (type === 'COUNTRY_CODE' || type === 'ALL')) {
                    checkoutProcessCtrl.validCountryCode = customValidation.countryCodes.some(function (code) {
                        return code == checkoutProcessCtrl.mobilePhone.countryCode;
                    })
                }

                if(customValidation.areaCodes && customValidation.areaCodes.length && (type === 'AREA_CODE' || type === 'ALL')) {
                    checkoutProcessCtrl.validAreaCode = customValidation.areaCodes.some(function (code) {
                        return code == checkoutProcessCtrl.mobilePhone.areaCode;
                    })
                }
            }

            function forward(result) {
                var isNotGgMapPage = checkoutProcessCtrl.currentView !== mapState.checkoutViewIndex

                if (checkoutProcessCtrl.isAddressVerificationEnabled && isNotGgMapPage) {
                    var validationResult = _verifyIsSuggestedValue();
                    for (var key in validationResult) {
                        var value = validationResult[key];
                        if (!value) {
                            var inputSelector = '#' + key + '_input';
                            checkoutProcessCtrl.addressDetails[key] = '';
                            checkoutProcessCtrl.formErrorCtrl[key] = true;
                            $rootScope.$emit('checkout.address.delivery.blurInput', inputSelector);
                            _showAddressWarning(key);
                            return;
                        }
                    }
                }

                var isEnableCountry = Config.retailer.settings.enableDefaultCountry;
                if (isEnableCountry && checkoutProcessCtrl.isDelivery) {
                    var currentCountry =  checkoutProcessCtrl.addressDetails.country;
                    if (!currentCountry || !currentCountry.length) {
                        checkoutProcessCtrl.addressDetails.country = '';
                        $rootScope.$emit('checkout.address.delivery.blurInput', '#country_autocomplete_input');
                        return;
                    } else {
                        var existedCountry = Util.getCountryAutocompleteOptions().find(function (country) {
                            return country.toLowerCase() === currentCountry.toLowerCase();
                        });
                        if (existedCountry) {
                            checkoutProcessCtrl.addressDetails.country = existedCountry;
                        } else {
                            checkoutProcessCtrl.addressDetails.country = '';
                            $rootScope.$emit('checkout.address.delivery.blurInput', '#country_autocomplete_input');
                            return;
                        }
                    }
                }

                if (!Cart.sendingSucceeded) {
                    return;
                }
                $rootScope.selectedDeliveryType = checkoutProcessCtrl.isDelivery ? 1 : 2;

                var isFromGgMapView = checkoutProcessCtrl.currentView === $state.get('app.cart.checkout.process.map').checkoutViewIndex
                var customPhoneValidity = isFromGgMapView ? true : checkCustomPhoneValidity();

                if(!customPhoneValidity) {
                    return
                }

                if (!result && checkoutProcessCtrl.currentView === $state.get('app.cart.checkout.process.payment').checkoutViewIndex) {
                    return checkLoyaltyCreditCard().then(function(result) {
                        if (result) {
                            return forward(result);
                        }
                    });
                }
                if (isLastView()) {
                    if (checkoutProcessCtrl.paymentDetails &&
                      checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.I_CREDIT_MASTER_PASS) {
                        return openICreditMasterPassWindow();
                    }

                    if (checkoutProcessCtrl.paymentDetails &&
                      checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.BIT) {
                        return _openBitWindow();
                    }

                    if ($rootScope.isCreditCustomer) {
                        //== Credit Customer doesn't select a payment method, assigning automatically
                        checkoutProcessCtrl.paymentDetails = {
                            paymentMethod: SP_PAYMENTS.PAYMENT_METHODS.NAMES.CUSTOMER_CREDIT
                        };
                    }

                    return submitOrder();
                }

                checkoutProcessCtrl.nextView();
            }

            function openPaypalWindow() {
                PaymentsService.getPaymentUrl(SP_PAYMENTS.PAYMENT_METHODS.IDS.PAYPAL).then(function(url) {
                    Util.listenForInAppMessage($window.open(url, '_blank'));
                });
            }

            function openPayWindow() {
                PaymentsService.getPaymentUrl(SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD).then(function(url) {
                    Util.listenForInAppMessage($window.open(url, '_blank'));
                });
            }

            /**
             * Add listener for i credit master pass
             * The listener is here and not in the payment ctrl
             * because it should be alive in the last stage of the checkout (can be summary page)
             * @private
             */
            function _addICreditMasterPassListener() {
                if (!checkoutProcessCtrl.hasICReditMasterPassOption) return;

                _listeners.push($rootScope.$on('iCreditMasterPass.finished', function(data, eventData) {
                    if (!eventData || eventData.error || !eventData.paymentToken) {
                        if (eventData && eventData.error) {
                            mDesign.alert(eventData.error.error);
                        }
                        return;
                    }

                    checkoutProcessCtrl.paymentDetails.paymentToken = eventData.paymentToken;
                    checkoutProcessCtrl.submitOrder();
                }));
            }

            function openICreditMasterPassWindow() {
                PaymentsService.getPaymentUrl(SP_PAYMENTS.PAYMENT_METHODS.IDS.I_CREDIT_MASTER_PASS).then(function(url) {
                    Util.listenForInAppMessage($window.open(url, '_blank'));
                });
            }

            function _openBitWindow() {
                var isIos = Util.isIos(),
                  // on safari open the window first, the window cannot be opened without an event called function(like async callbacks)
                  window = undefined;
                if (isIos && !$window.cordova) {
                    window = $window.open('', '_blank');
                    window.document.write('Loading...')
                }

                var checkoutData = {};
                return preCheckout(checkoutData).then(function() {
                    var userLoginData = User.getUserLoginData();

                    return Api.request({
                        method: 'POST',
                        url: '/retailers/' + Config.retailerId + '/users/' + userLoginData.uid + '/_init-bit-checkout',
                        params: {
                            token: userLoginData.token
                        },
                        data: angular.merge({
                            branchId: Config.branch.id,
                            cordovaAppId: Config.appId,
                            returnToApp: isIos
                        }, checkoutData)
                    });
                }).then(function(resp) {
                    // set the checkout data key into the local storage
                    ExternalCheckout.setStorage(resp.externalPaymentKey);
                    // start watching for the checkout to finish
                    ExternalCheckout.watchCheckoutData();

                    _chooseCheckoutArea();

                    var url = isIos ? resp.iosRedirect : resp.androidRedirect;
                    if (window) {
                        window.location.href = url;
                    } else {
                        Util.listenForInAppMessage($window.open(url, $window.cordova ? '_system' : '_blank'));
                    }
                }).catch(function(err) {
                    if (err !== 'userCreditLimited') {
                        return $q.reject(err);
                    }
                });
            }

            function isLastView() {
                //== for Credit Customer we don't show payment screen at all
                if (checkoutProcessCtrl.userData && checkoutProcessCtrl.userData.isCreditCustomer &&
                  checkoutProcessCtrl.currentView === $state.get('app.cart.checkout.process.summary').checkoutViewIndex) {
                    return true;
                }

                return checkoutProcessCtrl.currentView === $state.get('app.cart.checkout.process.' + (isPaymentLast() ? 'payment' : 'summary')).checkoutViewIndex;
            }

            function setToolTip(name) {
                checkoutProcessCtrl.shownToolTip = name || 'default';
            }

            function clearToolTip(event) {
                event && event.preventDefault();
                delete checkoutProcessCtrl.shownToolTip;
            }

            function addDeliveryProduct() {
                // remove old delivery & service fee lines
                angular.forEach(Cart.lines, function(line) {
                    if (line.type == CART_LINE_TYPES.DELIVERY || line.type == CART_LINE_TYPES.SERVICE_FEE) {
                        Cart.removeLine(line, true);
                    }
                });

                // handle service fee
                if (checkoutProcessCtrl.retailer.serviceFeeProduct) {
                    Cart.addLine({
                        quantity: 1,
                        type: CART_LINE_TYPES.SERVICE_FEE,
                        product: {
                            id: checkoutProcessCtrl.retailer.serviceFeeProduct.id
                        }
                    });
                }

                // handle delivery
                var deliveryProductLine = _getDeliveryProductLine();
                if (deliveryProductLine) {
                    Cart.addLine(deliveryProductLine);
                }
            }

            function addDeliveryProductOnStateChange() {
                if ((checkoutProcessCtrl.isAddressFirst && checkoutProcessCtrl.currentView === 0) ||
                  (!checkoutProcessCtrl.isAddressFirst && checkoutProcessCtrl.currentView === 1)) {
                    return;
                }
                addDeliveryProduct();
            }

            function _goToFirstState() {
                if (checkoutProcessCtrl.currentView || Config.checkoutData.state) {
                    var nextStateIndex = checkoutProcessCtrl.currentView !== undefined ?
                      checkoutProcessCtrl.currentView :
                      $state.get(Config.checkoutData.state).checkoutViewIndex;

                    var timesStateIndex = $state.get('app.cart.checkout.process.times').checkoutViewIndex;

                    if (nextStateIndex > timesStateIndex) {
                        _getRetailerPromise.then(addDeliveryProduct);
                    }
                }

                if (checkoutProcessCtrl.currentView !== undefined) {
                    return;
                }

                if (Config.checkoutData.state) {
                    if (checkoutProcessCtrl.paymentDetails && checkoutProcessCtrl.paymentDetails.paymentMethod === $scope.$root.SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT && Config.checkoutData.state === 'app.cart.checkout.process.summary') {
                        checkoutProcessCtrl.goToState('app.cart.checkout.process.payment', undefined, true);
                    } else {
                        checkoutProcessCtrl.goToState(Config.checkoutData.state, undefined, true);
                    }
                } else {
                    checkoutProcessCtrl.goToState('app.cart.checkout.process.' + (checkoutProcessCtrl.isAddressFirst ? 'address' : 'times'), undefined, true);
                }
            }

            /**
             * get the delivery product line based on if delivery time slot has it , else do default
             * @returns {object}
             * @private
             */
            function _getDeliveryProductLine() {
                var deliveryProductId = 0;
                if (checkoutProcessCtrl.timesDetails && checkoutProcessCtrl.timesDetails.deliveryTime && checkoutProcessCtrl.timesDetails.deliveryTime.deliveryTimeProductId) {
                    deliveryProductId = checkoutProcessCtrl.timesDetails.deliveryTime.deliveryTimeProductId;
                }

                if (!deliveryProductId && checkoutProcessCtrl.timesDetails && checkoutProcessCtrl.timesDetails.branchArea) {
                    deliveryProductId = checkoutProcessCtrl.timesDetails.branchArea.area && checkoutProcessCtrl.timesDetails.branchArea.area.deliveryProductId;
                }

                if (!deliveryProductId) return;

                return {
                    quantity: 1,
                    deliveryProduct: true,
                    type: CART_LINE_TYPES.DELIVERY,
                    product: {
                        id: deliveryProductId
                    }
                };
            }

            function submitOrder() {
                if (checkoutProcessCtrl.checkoutInProgress) {
                    return;
                }

                checkoutProcessCtrl.checkoutInProgress = true;

                if (checkoutProcessCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.ZIP_CODE ||
                  checkoutProcessCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.CITY) {
                    checkoutProcessCtrl.addressDetails.text1 = checkoutProcessCtrl.addressDetails.street +
                      (checkoutProcessCtrl.addressDetails.houseNumber ? ' ' + checkoutProcessCtrl.addressDetails.houseNumber : '') +
                      (checkoutProcessCtrl.addressDetails.entry ? ' ' + checkoutProcessCtrl.addressDetails.entry : '');
                }

                checkoutCtrl.summaryData = {
                    total: Cart.total.finalPriceWithTax + Cart.total.serviceFee.finalPriceWithTax + Cart.total.deliveryCost.finalPriceWithTax
                };

                var checkoutData = {};
                checkoutData.receiptOption = Config.checkoutData.receiptOption;

                return preCheckout(checkoutData).then(function(){
                    return checkExclusiveMessage();
                }).then(function (rs){
                    if(!rs) {
                        return $q.resolve(false);
                    }
                    var maxGiftCardCurrentHave = {};
                    checkoutData.payments.forEach(function (payment, index) {
                        if (!payment.isMain) {
                            var amountMinus = _calculateAmountGiftCardExcludeItem(payment.methodId);
                            maxGiftCardCurrentHave[payment.methodId] = _roundCurrencyFilter(checkoutCtrl.summaryData.total - amountMinus);
                        }
                    });
                    var giftCardHaveBeenUsed = 0;
                    checkoutData.payments.forEach(function (payment, index) {
                      if (!payment.isMain) {
                        var maxAmount = maxGiftCardCurrentHave[payment.methodId] - giftCardHaveBeenUsed;
                        if(payment.amount > maxAmount){
                          payment.amount = maxAmount;
                          payment.preAuthAmount = maxAmount;
                          giftCardHaveBeenUsed += maxAmount;
                        } else {
                          giftCardHaveBeenUsed += payment.amount;
                        }
                      }
                    });
                    checkoutData.payments = angular.copy(checkoutData.payments.filter(function (_payment) {
                        return _payment.isMain || _payment.amount > 0;
                    }));
                    return $q.resolve(true);
                }).then(function(rs){
                    if (!rs) {
                        checkoutProcessCtrl.checkoutInProgress = false;
                        return;
                    }
                    return checkDuplicateOrder().then(function(checkDupRes){
                        if (checkDupRes && checkDupRes.orderExists) {
                            return checkDupRes;
                        }

                        if(checkoutProcessCtrl.isDelivery && checkoutProcessCtrl.isUseGMapDeliveryMethod){
                            if(checkoutData.address.lat && checkoutData.address.lng && !checkoutData.address.externalPlaceId){
                                checkoutData.address.isDropPinAddress = true;
                            }
                        }

                        return _sendCheckout(checkoutData);
                    }).then(function(response) {
                            handleAfterCheckout(response, checkoutData);
                    }).catch(function(err) {
                        DataLayer.pushDebug('analytics debug on mobilePurchaseError', true);
                        // try to cancel payments made using regular initpayment flow
                        _cancelTokenPayment(checkoutData);

                        if (err && err.data && err.data.code === 'deliveryTimeNotAvailable') {
                            checkoutProcessCtrl.timesDetails.deliveryDay = null;
                            checkoutProcessCtrl.timesDetails.deliveryTime = null;

                            return mDesign.dialog({
                                focusOnOpen: true,
                                clickOutsideToClose: false,
                                templateUrl: 'views/unavailable-time-slots-alert.html',
                                controller: ['$scope', function($scope) {
                                    $scope.returnToSchedule = function () {
                                        mDesign.hide();
                                        getDeliveryTimes(undefined, {
                                            externalPlaceId: checkoutProcessCtrl.addressDetails.externalPlaceId,
                                            geoCoordinates: {
                                                lat: checkoutProcessCtrl.addressDetails.lat,
                                                lng: checkoutProcessCtrl.addressDetails.lng,
                                            }
                                        }).then(function() {
                                            $state.go('^.times', {}, { reload: true });
                                        }).catch(function() {
                                            $state.go('^.address', {}, { reload: true });
                                        });
                                    };
                                }]
                            });
                        } else if(err && err.data && err.data.xErrorCode && err.data.details){
                            return mDesign.dialog({
                                focusOnOpen: true,
                                clickOutsideToClose: false,
                                templateUrl: 'views/ebt-error.html',
                                controller: ['$scope', function($scope) {
                                    $scope.error = err.data;

                                    $scope.hide = function () {
                                        mDesign.hide();
                                    };
                                }]
                            });
                        }
                        else if (err !== 'userCreditLimited') {
                            return $q.reject(err);
                        }
                    }).finally(function() {
                        checkoutProcessCtrl.checkoutInProgress = false;
                    });
                }).finally(function() {
                    checkoutProcessCtrl.checkoutInProgress = false;
                });
            }

            function checkExclusiveMessage() {
                var checkIsDisplayPopup = false;
                var giftCardExclusionItems = Config.retailer.giftCardExclusion && Config.retailer.giftCardExclusion.items;
                if(checkoutProcessCtrl.paymentDetails && checkoutProcessCtrl.paymentDetails.redeemedGiftCardIds && checkoutProcessCtrl.giftCardExclusionMessage) {
                    checkoutProcessCtrl.paymentDetails.redeemedGiftCardIds.some(function(methodId) {
                        if (giftCardExclusionItems && giftCardExclusionItems[Number(methodId)]) {
                            checkIsDisplayPopup = true;
                            return true;
                        }
                    });
                }


                if(!checkIsDisplayPopup) {
                    return $q.resolve(true);
                }

                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: false,
                    templateUrl: 'views/templates/gift-card-exclusive-message/index.html',
                    controller: ['$scope', function($scope) {
                        $scope.message = checkoutProcessCtrl.giftCardExclusionMessage[$rootScope.config.language.culture] || _defaultGiftCardExclusionMessage[$rootScope.config.language.culture];
                        $scope.hide = function(rs) {
                            mDesign.hide(rs);
                        };
                    }]
                }).then(function(rs) {
                    console.log('test rs', rs)
                    return !!rs;
                });
            }

            function _calculateAmountGiftCardExcludeItem(paymentMethodId) {
                var giftCardExclusionItems = Config.retailer.giftCardExclusion && Config.retailer.giftCardExclusion.items;
                var totalAmount = 0;
                if(giftCardExclusionItems && giftCardExclusionItems[paymentMethodId]) {
                    var giftCardExclusionItemsObj = {};
                    giftCardExclusionItems[paymentMethodId].forEach(function (item) {
                        giftCardExclusionItemsObj[item.id] = item;
                    });
                    Object.keys(Cart.lines).forEach(function (lineId) {
                        var cartLine = Cart.lines[lineId];
                        var productInLine = Cart.lines[lineId].product;
                        var needToExclude = false;
                        if (productInLine && productInLine.family && productInLine.family.categories) {
                            productInLine.family.categories.some(function (category) {
                                var itemGiftCardExclusion = giftCardExclusionItemsObj[category.id];
                                if (category && itemGiftCardExclusion) {
                                    if (itemGiftCardExclusion.familyId) {
                                        if (productInLine.family.id === itemGiftCardExclusion.familyId) {
                                            needToExclude = true;
                                            return true;
                                        }
                                    } else {
                                        needToExclude = true;
                                        return true;
                                    }
                                }
                            });
                            if (needToExclude) {
                                totalAmount = totalAmount += Number(Math.abs(cartLine.finalPriceWithTax));
                            }
                        }
                    });
                }
                return totalAmount;
            }

            /**
             * @returns {Promise<CheckoutRes | null>}
             */
            function checkDuplicateOrder() {
                /** @type {CheckDuplicateOrderBody} */
                var checkDuplicateOrderBody = {
                    cartId: Cart.serverCartId,
                    areaId: checkoutProcessCtrl.timesDetails.branchArea.area.id,

                    deliveryTimeId:
                      checkoutProcessCtrl.timesDetails.deliveryTime &&
                      checkoutProcessCtrl.timesDetails.deliveryTime.id,
                    deliveryTimeDate:
                      checkoutProcessCtrl.timesDetails.deliveryDay &&
                      checkoutProcessCtrl.timesDetails.deliveryDay.date,
                    total:
                      Cart.total.finalPriceWithTax +
                      Cart.total.serviceFee.finalPriceWithTax +
                      Cart.total.deliveryCost.finalPriceWithTax,
                };

                return Api.request({
                    method: "POST",
                    url: "/v2/retailers/:rid/branches/:bid/users/:uid/isDuplicateOrder",
                    data: checkDuplicateOrderBody,
                }).then(_handleAfterCheckDuplicateOrder);
            }

            /**
             * @param {DuplicatedOrderRes} dupOrderRes
             * @returns {CheckoutRes | null}
             */
            function _handleAfterCheckDuplicateOrder(dupOrderRes) {
                if (!dupOrderRes.orderExists) {
                    return null
                }

                /** @type {CheckoutRes} */
                var checkoutResponse = {
                    id: dupOrderRes.orderId,
                    isCreditCardLoyalty: null,
                    orderExists: true,
                };

                return checkoutResponse
            }

            /**
             * @param {CheckoutRes} response
             * @param {checkoutData} checkoutData
             * @returns {void}
             */
            function handleAfterCheckout(response, checkoutData) {
                DataLayer.pushDebug("analytics debug on mobilePurchaseA", true);
                $rootScope.$emit("checkout", response);
                User.getUserSettings(true);
                $window.localStorage.removeItem("mobileZuzTypedAreaAddressComponents");
                Orders.barcode = null;

                if(response.orderExists){
                    Cart.clear();
                    DataLayer.pushDebug("analytics debug on frontendPurchaseC", true);
                }
                // only push these events if not duplicate order to track new purchases
                else {
                    Cart.clear(true);
                    DataLayer.pushDebug("analytics debug on mobilePurchaseB", true);

                    DataLayer.push(
                      DataLayer.EVENTS.PURCHASE,
                      {
                          purchase: {
                              id: response.id,
                              affiliation: checkoutCtrl.deliveryType || "",
                              revenue: checkoutCtrl.grandTotal || "",
                              tax: checkoutCtrl.taxAmount || "",
                              shipping: checkoutCtrl.shippingCost || "",
                              coupon: checkoutCtrl.couponData || "",
                              paymentType: checkoutProcessCtrl.paymentDetails.paymentMethod,
                          },
                          products: checkoutCtrl.dataLayerCartProducts,
                      },
                      true
                    );

                    DataLayer.pushDebug("analytics debug on mobilePurchaseBF", true);
                }

                delete Config.checkoutData;
                $rootScope.$emit("checkoutFinished");

                $state.go("app.cart.checkout.finish", {
                    orderId: response.id,
                    isCreditCardLoyalty: response.isCreditCardLoyalty,
                }, {
                    // ECOM-10973 fix redirect will re init app.cart.checkout
                    reload: 'app.cart.checkout.finish'
                });

                _chooseCheckoutArea();

                if (checkoutData.pickingBranchId) {
                    var pickingBranch = Config.retailer.branches.find(function (branch) {
                        return branch.id === checkoutData.pickingBranchId;
                    });
                    var changes = {
                        originalBranchId: Config.branch.id,
                        branchId: checkoutData.pickingBranchId,
                        branchAreaId: pickingBranch.areas[0].id,
                        pickupBranchId: null,
                    };

                    _updatePickingBranch(response.id, changes);
                }
            }

            function _sendCheckout(checkoutData) {
                return _retry(function(retries) {
                    checkoutData.retries = retries;

                    return Api.request({
                        method: 'POST',
                        url: '/v2/retailers/:rid/branches/:bid/users/:uid/_checkout',
                        data: checkoutData
                    }, {
                        hideError: true
                    });
                }, _hasPaymentToken(checkoutData.payments) ? 5 : 1).catch(function(resp) {
                    resp.apiOptions.hideError = false;
                    if (resp.data && resp.data.paymentError) {
                        Util.showPaymentErrorDialog(resp.data);
                    } else if (!resp.data.xErrorCode) {
                        $rootScope.$emit('spApi.error', resp);
                    }
                    throw resp;
                });
            }

            function preCheckout(checkoutData) {
                var checkoutDataAddress;

                return Cart.checkUserCreditLimited(true, 'app.cart').then(function(isLimited) {
                    if (isLimited) {
                        return $q.reject('userCreditLimited');
                    }

                    // update user before getting payments, payment process might need to use the user data (initpayment for example)
                    var userPhones = {3: {phoneNumber: checkoutProcessCtrl.addressDetails.phone, typeVal: 3}}

                    if(checkoutProcessCtrl.customPhoneValidation && checkoutProcessCtrl.mobilePhone.customPhoneNumber) {
                        userPhones = {3: {
                                phoneNumber: [
                                    checkoutProcessCtrl.mobilePhone.areaCode ? checkoutProcessCtrl.mobilePhone.areaCode.toString() : '',
                                    checkoutProcessCtrl.mobilePhone.customPhoneNumber ? checkoutProcessCtrl.mobilePhone.customPhoneNumber.toString() : ''
                                ].join(''),
                                typeVal: 3,
                                areaCode: checkoutProcessCtrl.mobilePhone.areaCode,
                                countryCode: checkoutProcessCtrl.mobilePhone.countryCode,
                                phoneId: checkoutProcessCtrl.mobilePhone.phoneId,
                                customPhoneNumber: checkoutProcessCtrl.mobilePhone.customPhoneNumber
                            }
                        }
                    }

                    var userPatchData =  {
                        phones: userPhones,
                        storeCreditAccountNumber: checkoutProcessCtrl.paymentDetails.storeCredit,
                        paypalAccount: checkoutProcessCtrl.paymentDetails.paypalAccount,
                        receiptOption: Config.checkoutData.receiptOption
                    };

                    checkoutDataAddress = _getCheckoutDataAddress();
                    if (checkoutDataAddress) {
                        // Override text1
                        // For Israeli Retailer, street will be before house number
                        if (checkoutProcessCtrl.isDelivery && checkoutProcessCtrl.isUseGMapDeliveryMethod && checkoutProcessCtrl.isDropPinAddress) {
                            if(checkoutProcessCtrl.isDropPinAddressNotDetected && checkoutProcessCtrl.isGoogleMapDropPinValidateHouseNumAndStreet){
                                var text1Obj = Util.constructAdrsText1(checkoutDataAddress.countryCode, checkoutDataAddress.houseNumber, checkoutDataAddress.street, checkoutDataAddress.city);
                                checkoutDataAddress.text1 = text1Obj.value;
                            }
                            
                            checkoutDataAddress.street = '';
                            checkoutDataAddress.houseNumber = '';
                            delete checkoutDataAddress.externalPlaceId;
                        }
                        userPatchData.address = checkoutDataAddress;
                    }

                    return Api.request({
                        method: 'PATCH',
                        url: 'retailers/:rid/users/:uid',
                        data: userPatchData
                    }, {
                        hideError: false
                    });
                }).then(function() {
                    localStorage.removeItem("mobileZuzTypedAreaText");
                    localStorage.removeItem("mobileZuzTypedAddressText");
                    localStorage.removeItem("newAddress");

                    return _setCheckoutData(checkoutDataAddress, checkoutData);
                }).then(function () {
                    // update User area

                    if(checkoutData && checkoutData.areaId && checkoutData.areaId != checkoutProcessCtrl.userData.areaId) {
                        var orderArea = checkoutProcessCtrl.timesDetails.branchArea.area;
                        localStorage.setItem('areaId', +orderArea.id);
                        localStorage.setItem('area', orderArea.name);
                        User.setUserArea(orderArea, checkoutProcessCtrl.userData.id);
                    }
                    // getGaParams calls gtag api, which should always execute a callback that resolves - the timeout function is there in case it doesn't
                    return $q.race([
                        DataLayer.getGaParams(),
                        $timeout(function () {return {'err': 'timeout'}}, 3000)
                    ]);
                }).then(function (gaParams) {
                    if (!gaParams) {
                        return;
                    }
                    try {
                        checkoutData.metaData = JSON.stringify({gaParams: gaParams});
                    } catch (e) {
                        checkoutData.metaData = JSON.stringify({gaParams: {'err': 'json'}});
                    }
                    checkoutData.savePreviousMetaData = true;
                    checkoutData.includeGaCookies = true;
                });
            }

            function _hasPaymentToken(payments) {
                return !!(payments || []).find(function(payment) {
                    return payment.token && (
                      // only chargeable payments
                      payment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD ||
                      payment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.PAYPAL ||
                      payment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT ||
                      payment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.I_CREDIT_MASTER_PASS ||
                      payment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.BIT ||
                      payment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.TAV_PLUS ||
                      payment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.TAV_PLUS_LIGHT
                    );
                });
            }

            function _updatePickingBranch(orderId, changes) {
                Api.request({
                    method: 'PATCH',
                    url: '/retailers/:rid/orders/' + orderId + '/updatePickingBranch',
                    data: changes
                }, {
                    withoutToast: true
                }).catch(function () {
                    return;
                });
            }

            function _getCheckoutDataAddress() {
                if ($stateParams.method === CHECKOUT_METHODS.VALUES.PICKUP) {
                    return;
                }
                return {
                    id: checkoutProcessCtrl.addressDetails.addressId,
                    text1: checkoutProcessCtrl.addressDetails.text1,
                    text2: checkoutProcessCtrl.addressDetails.text2,
                    country: checkoutProcessCtrl.addressDetails.country,
                    countryCode: checkoutProcessCtrl.addressDetails.countryCode,
                    street: checkoutProcessCtrl.addressDetails.street,
                    houseNumber: checkoutProcessCtrl.addressDetails.houseNumber,
                    entry: checkoutProcessCtrl.addressDetails.entry,
                    floor: checkoutProcessCtrl.addressDetails.floor,
                    apartment: checkoutProcessCtrl.addressDetails.apartment,
                    city: checkoutProcessCtrl.addressDetails.city,
                    zipCode: checkoutProcessCtrl.addressDetails.zipCode,
                    state: checkoutProcessCtrl.addressDetails.state,
                    buildingCode: checkoutProcessCtrl.addressDetails.buildingCode,
                    friendlyName: checkoutProcessCtrl.addressDetails.friendlyName,
                    lat: checkoutProcessCtrl.addressDetails.lat,
                    lng: checkoutProcessCtrl.addressDetails.lng,
                    externalPlaceId: checkoutProcessCtrl.addressDetails.externalPlaceId || null
                };
            }

            function _setCheckoutData(address, checkoutData) {
                checkoutData.cartId = Cart.serverCartId;

                // this field is used to support mobile app with old versions which don't have the new address version
                // use in selfpointrest only
                if(address){
                    address.isNewAddressVersion = true;
                }

                checkoutData.address = address;
                checkoutData.deliveryMethod = checkoutProcessCtrl.timesDetails.branchArea.area.deliveryTypeId;
                checkoutData.comments = checkoutProcessCtrl.timesDetails.comments;
                checkoutData.areaId = checkoutProcessCtrl.timesDetails.branchArea.area.id;
                checkoutData.friendlyName = checkoutProcessCtrl.addressDetails.friendlyName;
                checkoutData.substitutePreferences = checkoutProcessCtrl.substitutePreference;
                checkoutData.buildingCode = checkoutProcessCtrl.buildingCode;
                checkoutData.state = checkoutProcessCtrl.state;
                checkoutData.phoneNumber = checkoutProcessCtrl.addressDetails.phone;
                checkoutData.languageId = Config.language.id;
                checkoutData.geoCoordinates = {
                    lat: checkoutProcessCtrl.addressDetails.lat,
                    lng: checkoutProcessCtrl.addressDetails.lng,
                };
                checkoutData.externalPlaceId = checkoutProcessCtrl.addressDetails.externalPlaceId;
                checkoutData.customPhoneValidation = Config.retailer.customPhoneValidation && Config.retailer.customPhoneValidation.isActive;
                checkoutData.phoneNumberObject = checkoutProcessCtrl.mobilePhone;
                checkoutData.phoneNumberObject.phoneNumber = checkoutData.phoneNumberObject.mobilePhone;
                delete checkoutData.phoneNumberObject.mobilePhone;
                checkoutData.phoneNumberObject.phoneNumber = checkoutProcessCtrl.addressDetails.phone;
                checkoutData.pickingBranchId = checkoutProcessCtrl.timesDetails.deliveryTime.pickingBranchId;
                checkoutData.leaveByTheDoor = checkoutProcessCtrl.leaveByTheDoor

                if (checkoutProcessCtrl.timesDetails.deliveryChoice === DELIVERY_CHOICES.SHIPPING) {
                    checkoutData.shippingCarrierCode = checkoutProcessCtrl.timesDetails.deliveryDay.carrierCode;
                    checkoutData.shippingServiceCode = checkoutProcessCtrl.timesDetails.deliveryDay.serviceCode;
                } else {
                    checkoutData.deliveryTimeId = checkoutProcessCtrl.timesDetails.deliveryTime && checkoutProcessCtrl.timesDetails.deliveryTime.id;
                    checkoutData.deliveryTimeDate = checkoutProcessCtrl.timesDetails.deliveryTime && checkoutProcessCtrl.timesDetails.deliveryDay.date;
                    checkoutData.asapPreference = checkoutProcessCtrl.timesDetails.asap;
                    checkoutData.shippingCarrierCode = checkoutProcessCtrl.timesDetails.shippingCarrierCode;
                }

                if (checkoutProcessCtrl.paymentDetails.invitePromotion) {
                    checkoutData.invitePromotion = checkoutProcessCtrl.paymentDetails.invitePromotion;
                }

                if (checkoutCtrl.isPickAndGo) {
                    if (Orders.barcode) {
                        checkoutData.barcode = Orders.barcode;
                    } else {
                        mDesign.alert('Your don\'t have cart barcode number, redirecting to cart barcode scanner. scan again.', function () {
                            return $state.go('app.pickAngGo');
                        });
                    }
                }

                if (checkoutProcessCtrl && checkoutProcessCtrl.ebtCalculatedAmounts && checkoutProcessCtrl.ebtCalculatedAmounts.total) {
                    checkoutData.taxSaved = checkoutProcessCtrl.ebtCalculatedAmounts.total.taxSaved || 0;
                    checkoutData.ebtWeightDeposit = checkoutProcessCtrl.ebtCalculatedAmounts.total.deposit || 0;
                }

                var linkedStore = Config.retailer.linkedStores && Config.retailer.linkedStores.find(function (s) { return s.id == Config.branch.id });
                if (linkedStore){
                    var pickingStore = Config.retailer.pickingStores && Config.retailer.pickingStores.find(function (s) { return s.id == linkedStore.pickingBranchId });
                    checkoutData.pickingRetailerId = pickingStore ? pickingStore.retailerId : null;
                    checkoutData.pickingRetailerBranchId = pickingStore ? pickingStore.id : null;
                }

                return _setCheckoutPayments(checkoutData);
            }

            /**
             * Get the checkout data payments
             * @private
             *
             * @returns {Promise<Array<Object>>}
             */
            function _setCheckoutPayments(checkoutData) {
                var mainPayment = {
                    methodId: SP_PAYMENTS.PAYMENT_METHODS.ID_BY_NAME[checkoutProcessCtrl.paymentDetails.paymentMethod],
                    paymentsNumber: checkoutProcessCtrl.paymentDetails.paymentsNumber,
                    isMain: true
                };

                if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT ||
                  mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.TAV_PLUS ||
                  mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.TAV_PLUS_LIGHT) {
                    mainPayment.methodId = SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD;
                }

                if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD &&
                  checkoutProcessCtrl.isRememberCreditCardsMode && checkoutProcessCtrl.paymentDetails.creditCard) {
                    mainPayment.creditCardId = checkoutProcessCtrl.paymentDetails.creditCard.id;
                    mainPayment.cvv = checkoutProcessCtrl.paymentDetails.cvv;
                    mainPayment.isLoyalty = checkoutProcessCtrl.paymentDetails.creditCard.isLoyalty;
                    if (checkoutProcessCtrl.paymentDetails.creditCard.cardType === CREDIT_CARD_TYPES.DIRECT) {
                        mainPayment.paymentsNumber = 1;
                    }
                } else if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.STORE_CREDIT && checkoutProcessCtrl.paymentDetails.storeCredit) {
                    mainPayment.token = checkoutProcessCtrl.paymentDetails.storeCredit;
                } else if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.PAYPAL &&
                  (checkoutProcessCtrl.paymentDetails.paymentToken || checkoutProcessCtrl.paymentDetails.paypalAccount)) {
                    if (checkoutProcessCtrl.paymentDetails.paymentToken && checkoutProcessCtrl.paymentDetails.paypalAccount.payerId === checkoutProcessCtrl.paymentDetails.paypalPayerId) {
                        mainPayment.token = checkoutProcessCtrl.paymentDetails.paymentToken;
                    } else {
                        mainPayment.billingAgreement = checkoutProcessCtrl.paymentDetails.paypalAccount ? checkoutProcessCtrl.paymentDetails.paypalAccount.billingAgreement : null;
                    }
                } else if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.ORGANIZATION) {
                    mainPayment.token = checkoutProcessCtrl.paymentDetails.paymentToken;
                } else if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.I_CREDIT_MASTER_PASS &&
                  checkoutProcessCtrl.paymentDetails.paymentToken) {
                    mainPayment.token = checkoutProcessCtrl.paymentDetails.paymentToken;
                    mainPayment.authNum = checkoutProcessCtrl.paymentDetails.authNum;
                }

                checkoutData.payments = [];
                return $q.resolve().then(function() {
                    if (checkoutProcessCtrl.paymentDetails.paymentMethod !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT || !checkoutProcessCtrl.paymentDetails.ebt.fsAmount) {
                        return;
                    }

                    return _getEBTCheckoutPaymentTransaction(SP_PAYMENTS.PAYMENT_TYPES.EBT_SNAP);
                }).then(function(ebtFSPaymentToken) {
                    if (ebtFSPaymentToken) {
                        checkoutData.payments.push({
                            methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT,
                            typeId: SP_PAYMENTS.PAYMENT_TYPES.EBT_SNAP,
                            amount: checkoutProcessCtrl.paymentDetails.ebt.fsAmount,
                            preAuthAmount: checkoutProcessCtrl.paymentDetails.ebt.fsAmount,
                            creditCardId: checkoutProcessCtrl.paymentDetails.ebtCard.id,
                            token: ebtFSPaymentToken
                        });
                    }

                    if (checkoutProcessCtrl.paymentDetails.paymentMethod !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT || !checkoutProcessCtrl.paymentDetails.ebt.cbAmount) {
                        return;
                    }

                    return _getEBTCheckoutPaymentTransaction(SP_PAYMENTS.PAYMENT_TYPES.EBT_CASH);
                }).then(function(ebtCBPaymentToken) {
                    if (ebtCBPaymentToken) {
                        checkoutData.payments.push({
                            methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT,
                            typeId: SP_PAYMENTS.PAYMENT_TYPES.EBT_CASH,
                            amount: checkoutProcessCtrl.paymentDetails.ebt.cbAmount,
                            preAuthAmount: checkoutProcessCtrl.paymentDetails.ebt.cbAmount,
                            creditCardId: checkoutProcessCtrl.paymentDetails.ebtCard.id,
                            token: ebtCBPaymentToken
                        });
                    }
                    if(!checkoutProcessCtrl.paymentDetails.isStandAlonePaymentMethod && checkoutProcessCtrl.paymentDetails.paymentMethod !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT) {
                        if($rootScope.isUsedCashbackPoints && checkoutProcessCtrl.paymentDetails.userCashbackLoyalty) {
                            var loyaltyPointsPayment = {
                                methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.LOYALTY_POINTS,
                                amount: checkoutProcessCtrl.paymentDetails.userCashbackLoyalty.availableUserCashback,
                                paymentsNumber: 1,
                                token: checkoutProcessCtrl.paymentDetails.userCashbackLoyalty.pointsToken,
                                lastFourDigits: checkoutProcessCtrl.paymentDetails.userCashbackLoyalty.loyaltyCardId && checkoutProcessCtrl.paymentDetails.userCashbackLoyalty.loyaltyCardId.slice(-4),
                                userLoyaltyClubId: checkoutProcessCtrl.paymentDetails.userCashbackLoyalty.id
                            };

                            checkoutData.payments.push(loyaltyPointsPayment);
                        }

                        if (checkoutProcessCtrl.paymentDetails.giftCards) {
                            var giftCardIds = Object.keys(checkoutProcessCtrl.paymentDetails.giftCards);
                            angular.forEach(giftCardIds, function (giftCardId) {
                                var paymentData = {
                                    methodId: Number(giftCardId),
                                    typeId: SP_PAYMENTS.PAYMENT_TYPES.CREDIT,
                                    amount: checkoutProcessCtrl.paymentDetails.giftCards[giftCardId].amount,
                                    preAuthAmount: checkoutProcessCtrl.paymentDetails.giftCards[giftCardId].amount,
                                    token: checkoutProcessCtrl.paymentDetails.giftCards[giftCardId].token,
                                    lastFourDigits: checkoutProcessCtrl.paymentDetails.giftCards[giftCardId].lastFourDigits
                                }

                                if (checkoutProcessCtrl.paymentDetails.giftCards[giftCardId].cvv) {
                                    paymentData['cvv'] = checkoutProcessCtrl.paymentDetails.giftCards[giftCardId].cvv;
                                }
                                if (checkoutProcessCtrl.paymentDetails.giftCards[giftCardId].queryTransId) {
                                    paymentData['queryTransId'] = checkoutProcessCtrl.paymentDetails.giftCards[giftCardId].queryTransId;
                                }

                                checkoutData.payments.push(paymentData);
                            });
                        }
                    }

                    checkoutData.payments.push(mainPayment);

                    var is3DSCard = checkoutProcessCtrl.isCreditCard3DSActive &&
                      checkoutProcessCtrl.paymentDetails.creditCard && checkoutProcessCtrl.paymentDetails.creditCard.is3DS;
                    if (mainPayment.methodId !== SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD ||
                      (checkoutProcessCtrl.isRememberCreditCardsMode && !is3DSCard)) {
                        return;
                    }

                    // TODO send the amount paid with ebt and reduce it from the payment amount,
                    // currently ebt is only supported when remembering credit cards
                    return PaymentsService.payWithCreditCard({
                        creditCardId: (checkoutProcessCtrl.paymentDetails.creditCard || {}).id,
                        cvv: checkoutProcessCtrl.paymentDetails.cvv,
                        deliveryDate: checkoutProcessCtrl.timesDetails && checkoutProcessCtrl.timesDetails.deliveryTime && checkoutProcessCtrl.timesDetails.deliveryTime.newTo,
                        actionData: checkoutData,
                        action: 'checkout'
                    }).catch(function(err) {
                        if (err.statusCode === 409 && err.response &&
                          err.response.orderId && err.response.error === 'Already checked out') {
                            return {actionResponse: {id: err.response.orderId}};
                        } else {
                            throw err;
                        }
                    });
                }).then(function(paymentData) {
                    if (!paymentData) {
                        return;
                    }

                    if (paymentData.actionResponse) {
                        checkoutData.checkoutResponse = paymentData.actionResponse;
                    } else if (paymentData.skip3DS) {
                        mainPayment.skip3DS = paymentData.skip3DS
                    } else {
                        mainPayment.token = paymentData.paymentToken;
                        mainPayment.authNum = paymentData.authNum;
                        mainPayment.isPaymentCompleted = paymentData.isPaymentCompleted;
                        mainPayment.preAuthAmount = paymentData.amount;
                        if (paymentData.paymentsNumber) {
                            mainPayment.paymentsNumber = paymentData.paymentsNumber;
                        }
                    }
                });
            }

            function _getEBTCheckoutPaymentTransaction(paymentType) {
                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: false,
                    templateUrl: 'views/templates/ebt-pin-message-dialog.html',
                    controller: ['$scope', function($scope) {
                        $scope.paymentType = paymentType;
                        $scope.hide = mDesign.hide;
                        $scope.cancel = mDesign.cancel;
                    }]
                }).then(function() {
                    return getEBTTransaction();
                });
            }

            function _cancelTokenPayment(checkoutData) {
                var mainPayment = checkoutData.payments && checkoutData.payments.find(function(payment) {
                    return payment.isMain && payment.token;
                });
                if (!mainPayment) {
                    return;
                }

                mainPayment.amount = checkoutCtrl.summaryData.total;
                return Api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/branches/:bid/users/:uid/_cancelTokenPayment',
                    data: {
                        payments: [mainPayment]
                    }
                }, {
                    withoutToast: true
                }).catch(function() { });
            }

            function getEBTTransaction() {
                if (!checkoutProcessCtrl.paymentDetails.ebtCard) {
                    return $q.reject('no ebt card');
                }

                var address = {};
                if (checkoutProcessCtrl.isDelivery) {
                    address.addressText = checkoutProcessCtrl.addressDetails.text1;
                    address.city = checkoutProcessCtrl.addressDetails.city;
                    address.zipCode = checkoutProcessCtrl.addressDetails.zipCode;
                } else {
                    address.addressText = Config.branch.location;
                    address.city = Config.branch.city;
                    address.zipCode = Config.branch.zipCode;
                }

                return PaymentsService.initEBTPayment({
                    creditCardId: checkoutProcessCtrl.paymentDetails.ebtCard.id,
                    address: address,
                    isPickup: !checkoutProcessCtrl.isDelivery,
                    isHideCancelIconBtn: true
                }).then(function(resp) {
                    return resp.paymentToken;
                });
            }

            function showWeightDebitDialog() {
                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: false,
                    templateUrl: 'views/templates/ebt-weight-debit-disclaimer-dialog.html',
                    controller: ['$scope', function($scope) {
                        $scope.hide = mDesign.hide;
                    }]
                });
            }

            function _retry(action, retries) {
                retries--;
                return action(retries).catch(function(resp) {
                    if (!retries || resp.statusCode >= 300 && resp.statusCode < 500) {
                        throw resp;
                    }

                    return $timeout(function() {
                        return _retry(action, retries);
                    }, 5000);
                });
            }

            function _chooseCheckoutArea() {
                if (Config.isChosenArea && checkoutProcessCtrl.timesDetails.branchArea.area.id === Config.branchAreaId) {
                    return;
                }

                return SpDeliveryAreasService.getChooseAreaMode().then(function(chooseAreMode) {
                    var areaName = checkoutProcessCtrl.timesDetails.branchArea.area.name,
                      typedArea;
                    if (checkoutProcessCtrl.timesDetails.branchArea.area.deliveryTypeId !== SP_SERVICES.DELIVERY_TYPES.PICKUP) {
                        if (chooseAreMode === SP_SERVICES.CHOOSE_AREA_MODE.TYPE_AREA) {
                            areaName = typedArea = ((checkoutProcessCtrl.timesDetails.branchArea.area.names || [])[0] || {}).name ||
                              checkoutProcessCtrl.timesDetails.branchArea.area.name;
                        }
                        if (chooseAreMode === SP_SERVICES.CHOOSE_AREA_MODE.TYPE_GOOGLE_ADDRESS) {
                            typedArea = checkoutProcessCtrl.addressDetails.text1 +
                              (checkoutProcessCtrl.addressDetails.zipCode ? ', ' + checkoutProcessCtrl.addressDetails.zipCode : '') +
                              (checkoutProcessCtrl.addressDetails.city ? ', ' + checkoutProcessCtrl.addressDetails.city : '');
                        }
                    }
                    Config.setBranchArea(checkoutProcessCtrl.timesDetails.branchArea.area.id, areaName);
                    Config.setIsChosenArea(true);
                    Config.setLocalStorageItem('mobileZuzTypedAreaText', typedArea);
                });
            }

            function isPaymentLast() {
                // for Credit Customer we don't show payment screen, so it moved to the end and hides
                return $rootScope.isCreditCustomer;
            }

            /**
             * Returns whether the submit button should redirect to a payment page
             * @public
             *
             * @returns {boolean}
             */
            function isPaymentSubmitButton() {
                if (!checkoutProcessCtrl.paymentDetails && $rootScope.isCreditCustomer) {
                    checkoutProcessCtrl.paymentDetails = {
                        paymentMethod: SP_PAYMENTS.PAYMENT_METHODS.NAMES.CUSTOMER_CREDIT
                    };
                }

                return checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.I_CREDIT_MASTER_PASS ||
                  checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.BIT ||
                  (!checkoutProcessCtrl.isRememberCreditCardsMode &&
                    (checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD ||
                      checkoutProcessCtrl.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT));
            }

            function getDeliveryTimes(defer, options) {
                options = options || {};

                defer = defer || $q.defer();

                var addressDetails = checkoutProcessCtrl.addressDetails,

                  deliveryType = checkoutCtrl.isPickAndGo ? DELIVERY_TYPES.PICK_AND_GO : DELIVERY_TYPES.DELIVERY;

                var params = {
                    address: {
                        text1: addressDetails.text1,
                        street: addressDetails.street,
                        houseNumber: addressDetails.houseNumber,
                        entry: _getEntryValue(addressDetails.entry),
                        city: addressDetails.city,
                        state: addressDetails.state,
                        zipCode: addressDetails.zipCode,

                        // this field is used to support mobile app with old versions which don't have the new address version
                        // use in selfpointrest only
                        isNewAddressVersion: true,
                    },
                    cartId: Cart.serverCartId,
                    languageId: Config.language.id,
                    externalPlaceId: options.externalPlaceId || checkoutProcessCtrl.addressDetails.externalPlaceId,
                    isDeliveryWithinDaysByTag: Config.retailer.isDeliveryWithinDaysByTag
                };

                if (options.scroll && options.startDate) {
                    if (checkoutProcessCtrl.checkingAddressInProgress) {
                        return $q.reject();
                    }
                    params.startDate = options.startDate;
                    params.externalPlaceId = params.externalPlaceId || checkoutProcessCtrl.addressDetails.externalPlaceId;
                }

                if (Config.checkoutData.method === CHECKOUT_METHODS.VALUES.PICKUP) {
                    deliveryType = DELIVERY_TYPES.PICKUP;
                    delete params.address;
                    delete params.externalPlaceId;
                }

                if (checkoutProcessCtrl.isDelivery && checkoutProcessCtrl.isUseGMapDeliveryMethod) {
                    // Add lat lng to params    
                    var address = checkoutProcessCtrl.addressDetails;
                    params.lat = address.lat;
                    params.lng = address.lng;
                }

                checkoutProcessCtrl.checkingAddressInProgress = true;
                SpDeliveryTimesService.getTimes(deliveryType, params).then(function(response) {
                    var area = response.area;
                    return Retailer.getRetailerSettings().then(function(retailer) {
                        return $q.resolve().then(function() {
                            if (area.deliveryMinimumCost) {
                                return Orders.getLastOrder().then(function(order) {
                                    if (!order) return;
                                    return area.deliveryMinimumCost;
                                });
                            }
                            return (!!checkoutProcessCtrl.userData.personalMinOrderLimit || checkoutProcessCtrl.userData.personalMinOrderLimit === 0) ?
                              checkoutProcessCtrl.userData.personalMinOrderLimit : retailer.settings.minimumOrderPrice;
                        }).then(function(minimumPrice) {
                            if (!minimumPrice || checkoutCtrl.isPickAndGo) return;
                            return Util.validateMinimumCost(Number(minimumPrice), retailer.notIncludeSpecials,
                              area.deliveryMinimumCost && area.name);
                        }).then(function() {
                            return response;
                        });
                    });
                }).then(function(response) {
                    if (options.scroll) {
                        defer.resolve(response);
                    } else {
                        _validateNewArea(response, false).then(function ()  {
                            var _isHiddenBranchNameFlow = checkoutProcessCtrl.isEnabledAddressSettings ? !checkoutProcessCtrl.isGMapWithZipCodeProvider : !Config.retailer.settings.autocompleteAddressField;
                            if(Config.retailer.settings.isHiddenBranchName === 'true' && _isHiddenBranchNameFlow) {
                                var typedAreaText = {text1: addressDetails.text1, city: addressDetails.city};
                                _chooseCheckoutArea()
                                return _chooseArea(response.area.areas[0], typedAreaText)
                            }

                            defer.resolve
                        }).catch(defer.reject);
                    }
                }).catch(function(data) {
                    if (!data) {
                        return;
                    }
                    if (data.statusCode == 404) {
                        return $q.reject({msg: 'Sorry, currently we don\'t deliver to this address, for further inquiries call to customer service.'});
                    }
                    if (data.statusCode == 303) {
                        var _isHiddenBranchNameFlow = checkoutProcessCtrl.isEnabledAddressSettings ? !checkoutProcessCtrl.isGMapWithZipCodeProvider : !Config.retailer.settings.autocompleteAddressField;
                        if(Config.retailer.settings.isHiddenBranchName === 'true' && _isHiddenBranchNameFlow) {
                            var typedAreaText = {text1: data.config.params.address.text1, city:data.config.params.address.city};
                            _chooseArea(data.response.areas[0], typedAreaText)
                            return _chooseCheckoutArea()
                        }

                        mDesign.confirm({
                            content: _translate('Sorry, branch change required, this address could only be delivered from branch') + ' ' + data.response.branchName,
                            ok: 'switch branch',
                            cancel: 'cancel'
                        }, function(result) {
                            if (result === true) {
                                showChooseAreaDialog(data.response);
                            }
                        });
                        return $q.reject('Change branch error');
                    }
                    if (data.statusCode == 400) {
                        if (checkoutProcessCtrl.timesDetails && checkoutProcessCtrl.timesDetails.branchArea && checkoutProcessCtrl.timesDetails.branchArea.defaultSelected) {
                            SpDeliveryTimesService.getTimes(deliveryType, {
                                cartId: Cart.serverCartId,
                                isDeliveryWithinDaysByTag: Config.retailer.isDeliveryWithinDaysByTag
                            }).then(function(response) {
                                return _validateNewArea(response, true).then(defer.resolve).catch(defer.reject);
                            });
                        }

                        return $q.reject({msg: 'We didn\'t recognize the address you entered'});
                    }
                    if(data.statusCode == 300){
                        var isIgnored = [
                            checkoutProcessCtrl.addressDetails.isSuggestedAddress,
                            checkoutProcessCtrl.addressDetails.lat && checkoutProcessCtrl.addressDetails.lng,
                            checkoutProcessCtrl.addressDetails.externalPlaceId,
                        ].some(function (value) {
                            return value;
                        });
                        if ((checkoutProcessCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.POLYGON ||
                          checkoutProcessCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.GOOGLE_MAPS) && !isIgnored
                        ) {
                            return mDesign.dialog({
                                templateUrl: 'views/templates/checkout-pick-specific-area.html',
                                controller: 'ChooseFromNumberOfAreas',
                                locals: {
                                    areas: data.response.addresses
                                }
                            }).then(function(result) {
                                if (checkoutProcessCtrl.isAddressVerificationEnabled) {
                                    checkoutProcessCtrl.addressDetails.isSuggestedAddress = true;
                                }
                                angular.extend(checkoutProcessCtrl.addressDetails, result.address);
                                _saveMembershipInfo();
                                return checkoutProcessCtrl.validateView(defer, {
                                    externalPlaceId: result.externalPlaceId
                                });
                            }).catch(function(err) {
                                return $q.reject(err || 'dialog cancelled');
                            });
                        }
                    }
                }).then(function() {
                    defer.resolve();
                }).catch(function(err) {
                    defer.reject(err);
                }).finally(function() {
                    checkoutProcessCtrl.checkingAddressInProgress = false;
                });

                return defer.promise;
            }

            function _chooseArea(area, typedAreaText) {
                var sessionUserId = User.session && User.session.userId;
                var setUserArea = User.setUserArea(area, sessionUserId);

                var fullAddress = typedAreaText.text1 + ' ' + typedAreaText.city;
                return $q.resolve(setUserArea).then(function () {
                    Config.setLocalStorageItem('newAddress', typedAreaText);
                    SpDeliveryAreasService.filterDeliveryAreas(area.deliveryTypeId, fullAddress).then(function (response) {
                        Config.multiRetailers.hasCookie = true;
                        if (Config.retailerId !== area.retailerId) {
                            Util.clearLocalStorage();
                        }
                        Config.setLocalStorageItem('mobileZuzTypedAreaText', typedAreaText.city);
                        Config.setLocalStorageItem('mobileZuzTypedAddressText', typedAreaText.text1);
                        Config.setLocalStorageItem('mobileZuzTypedAreaAddressComponents', response.addressComponents);
                        Config.setLocalStorageItem('mobileZuzBranchAreaId', +area.id);

                        return Config.changeBranch(area.branchId, area.id, {
                            typedArea: fullAddress,
                            newRetailerId: area.retailerId
                        });
                    })
                })
            }

            function _validateNewArea(branchArea, isDefault) {
                var existArea = checkoutProcessCtrl.timesDetails && checkoutProcessCtrl.timesDetails.branchArea && checkoutProcessCtrl.timesDetails.branchArea.area;

                delete checkoutProcessCtrl.areas;
                if (branchArea.area.areas && branchArea.area.areas.length > 1) {
                    checkoutProcessCtrl.setAreas(branchArea.area.areas);
                    branchArea = checkoutProcessCtrl.getAreaByConfigAreaId() || checkoutProcessCtrl.areas[0];
                }

                // We always want to re-set the data to the data from the server
                checkoutProcessCtrl.timesDetails = checkoutProcessCtrl.timesDetails || {};
                checkoutProcessCtrl.timesDetails.branchArea = branchArea;
                checkoutProcessCtrl.timesDetails.branchArea.defaultSelected = isDefault;
                checkoutProcessCtrl.timesDetails.branchArea.area.defaultSelected = isDefault;
                checkoutCtrl.timesDetails = checkoutProcessCtrl.timesDetails;
                checkoutCtrl.isDelivery = checkoutProcessCtrl.isDelivery;

                if (checkoutProcessCtrl.isAddressFirst || (existArea && existArea.id === branchArea.area.id)) {
                    return $q.resolve();
                }

                var alert = {
                    message: '{{\'Due to changes in address details\' | translate}}.<br/>{{\'Please select your delivery time again\' | translate}}.'
                };

                if (isDefault) {
                    alert.message = '{{\'Since your address was not detected\' | translate}}.<br/>{{\'Please re-select a new delivery time\' | translate}}.';
                    alert.buttonText = 'Select delivery time';
                }

                return $q.reject({
                    msg: alert,
                    redirectTo: 'app.cart.checkout.process.times'
                });
            }

            function showChooseAreaDialog(otherAreaResponse) {
                Config.setLocalStorageItem('mobileZuzTypedAreaText', checkoutProcessCtrl.addressDetails.city);
                Config.setLocalStorageItem('mobileZuzTypedAddressText', checkoutProcessCtrl.addressDetails.text1);
                return SpDeliveryAreasService.getChooseAreaMode().then(function(mode) {
                    var areaName = null;
                    if (checkoutProcessCtrl.isDelivery) {
                        if (otherAreaResponse && mode === SP_SERVICES.CHOOSE_AREA_MODE.TYPE_AREA) {
                            areaName = ((otherAreaResponse.names || [])[0] || {}).name || otherAreaResponse.name;
                        }
                        if (mode === SP_SERVICES.CHOOSE_AREA_MODE.TYPE_GOOGLE_ADDRESS) {
                            var _address = checkoutProcessCtrl.addressDetails
                            var _strBuilder = []
                            if (_address.text1) {
                                _strBuilder.push(_address.text1);
                            }
                            if (_address.city) {
                                _strBuilder.push(_address.city);
                            }
                            if (_address.state) {
                                _strBuilder.push(_address.state);
                            }
                            if (_address.zipCode) {
                                _strBuilder.push(_address.zipCode);
                            }
                            areaName = _strBuilder.join(', ');
                        }
                    }
                    return ChooseAreaDialog.show(true, false, areaName, !checkoutProcessCtrl.isDelivery);
                }).then(function() {
                    if (checkoutProcessCtrl.areas && checkoutProcessCtrl.areas.length) {
                        setAreaByConfigAreaId();
                    }
                });
            }

            function setAreaByConfigAreaId() {
                checkoutProcessCtrl.timesDetails = checkoutProcessCtrl.timesDetails || {};

                if (checkoutProcessCtrl.timesDetails.branchArea && checkoutProcessCtrl.timesDetails.branchArea.area &&
                  checkoutProcessCtrl.timesDetails.branchArea.area.deliveryTypeId === SP_SERVICES.DELIVERY_TYPES.PICKUP) {
                    return;
                }

                checkoutProcessCtrl.timesDetails.branchArea = checkoutProcessCtrl.getAreaByConfigAreaId();
            }

            function substitutePreferenceChanged() {
                Config.checkoutData.substitutePreference = checkoutProcessCtrl.substitutePreference;
            }

            function _saveMembershipInfo() {
                return Api.request({
                    method: 'PATCH',
                    url: 'retailers/:rid/users/:uid',
                    data: {
                        addresses: [{
                            id: checkoutProcessCtrl.addressDetails.addressId,
                            text1: checkoutProcessCtrl.addressDetails.text1,
                            text2: checkoutProcessCtrl.addressDetails.text2,
                            city: checkoutProcessCtrl.addressDetails.city,
                            street: checkoutProcessCtrl.addressDetails.street,
                            houseNumber: checkoutProcessCtrl.addressDetails.houseNumber,
                            entry: _getEntryValue(checkoutProcessCtrl.addressDetails.entry),
                            floor: checkoutProcessCtrl.addressDetails.floor,
                            apartment: checkoutProcessCtrl.addressDetails.apartment,
                            zipCode: checkoutProcessCtrl.addressDetails.zipCode
                        }]
                    }
                });
            }

            //set "None" value as null
            function _getEntryValue(entry) {
                if (entry === _translate(HOUSE_ENTRIES[0])) {
                    return null;
                }
                return entry;
            }

            function _handelViewError(err) {
                if (err && err.msg) {
                    mDesign.alert(err.msg, function() {
                        err.selector && angular.element(err.selector).focus();
                        err.redirectTo && goToState(err.redirectTo);
                    });
                } else {
                    err.selector && $timeout(function() {
                        angular.element(err.selector).focus();
                    }, 100);
                    if (err.redirectTo) {
                        goToState(err.redirectTo);
                    }
                }

                return $q.reject(err);
            }

            function _showOrderFinishedDialog(orderFinishedText) {
                if (!orderFinishedText) {
                    return _goToFirstState();
                }

                $timeout(function() {
                    mDesign.dialog({
                        focusOnOpen: true,
                        controller: 'OrderFinishedText',
                        templateUrl: 'views/templates/order-finished-text.html',
                        resolve: {
                            orderFinishedText: function() {
                                return orderFinishedText;
                            }
                        }
                    }).finally(function() {
                        _goToFirstState();
                    });
                }, 100);
            }

            function _setCurrentView(currentView) {
                var oldCurrentView = checkoutProcessCtrl.currentView;
                checkoutProcessCtrl.currentView = currentView;
                if (oldCurrentView <= timesState.checkoutViewIndex && currentView > timesState.checkoutViewIndex) {
                    _checkInvalidSales();
                }
            }

            function _findSepcial(specials, specialId) {
                var result = specials.find(function(special) {
                    return special.id === specialId;
                });
                return result;
            }

            /*
            *  LEGACY CODE. MOVED TO Cart.checkIrrelevantSales(). TODO reuse from service
            */
            function _checkInvalidSales() {
                // for skip check speicals and coupons
                var skipDialogSpecial = false;

                if (!checkoutProcessCtrl.timesDetails.deliveryTime || !checkoutProcessCtrl.timesDetails.deliveryTime.to || !checkoutProcessCtrl.timesDetails.deliveryDay || !checkoutProcessCtrl.timesDetails.deliveryDay.date) {
                    skipDialogSpecial = true;
                }

                return Retailer.getRetailerSettings().then(function(configuration) {

                    // if the specials will be calculated by the checkout time at the pos, there is no reason to check invalid sales
                    if (configuration.settings.specialsCalculationTime === CHARGE_SPECIALS_CALCULATION_TIME.CHECKOUT_POS ||
                      configuration.settings.specialsCalculationTime === CHARGE_SPECIALS_CALCULATION_TIME.CHECKOUT_SP) {
                        skipDialogSpecial = true;
                    }

                    var saleDate;
                    if (checkoutProcessCtrl.timesDetails.deliveryTime.typeId === DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_DAYS) {
                        saleDate = new Date();
                        saleDate.setTime(saleDate.getTime() +
                          checkoutProcessCtrl.timesDetails.deliveryTime.daysRange * DAY_IN_MS -
                          saleDate.getTimezoneOffset() * MINUTE_IN_MS);
                    } else {
                        var date = new Date(checkoutProcessCtrl.timesDetails.deliveryDay.date),
                          time = new Date(checkoutProcessCtrl.timesDetails.deliveryTime.to);

                        saleDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), time.getUTCHours(), time.getUTCMinutes(), time.getUTCSeconds());
                        saleDate.setMinutes(saleDate.getMinutes() - saleDate.getTimezoneOffset());
                    }

                    // check product sell on Date

                    function getIrrelevantSellDates(cartLines){
                        var cartLineSellDate = [];
                        angular.forEach(cartLines, function (line){
                            if(!Util.isSellOnDate(line,new Date(checkoutProcessCtrl.timesDetails.deliveryDay.date))){
                                cartLineSellDate.push(line);
                            }
                        })
                        return cartLineSellDate;
                    }
                    var irrelevantSellDates = getIrrelevantSellDates(Cart.lines),
                      irrelevantSpecials,
                      irrelevantCoupons,
                      saveCartPromise = $q.resolve();

                    if(!irrelevantSellDates.length && skipDialogSpecial){
                        return true;
                    }

                    if(!skipDialogSpecial){
                        Cart.setTimeTravel({
                            date: saleDate,
                            override: false
                        });
                        saveCartPromise = Cart.save();
                    }

                    return saveCartPromise.then(function() {
                        var irrelevantSalesLines = skipDialogSpecial ? [] : getIrrelevantSales();
                        if (!irrelevantSellDates.length && (skipDialogSpecial || Cart.total.finalPriceForView === Cart.total.finalPriceForViewPotential)) {
                            return true;
                        }
                        var irrelevantSpecialsRes = getIrrelevantSpecials(irrelevantSalesLines);
                        irrelevantSpecials = irrelevantSpecialsRes.irrelevantSpeicals ? irrelevantSpecialsRes.irrelevantSpeicals : [];
                        irrelevantCoupons = irrelevantSpecialsRes.irrelevantCoupons;

                        function getIrrelevantSales() {
                            var irrelevantSalesLines = [];
                            angular.forEach(Cart.lines, function(line) {
                                if (!skipDialogSpecial && Util.isIrrelevantSale(line)) {
                                    line.gifts = line.gifts.filter(function(gift) {
                                        return gift.promotion.endDate < checkoutProcessCtrl.timesDetails.deliveryDay.date;
                                    });
                                    irrelevantSalesLines.push(line);
                                }
                            });
                            return irrelevantSalesLines;
                        }

                        function getIrrelevantSpecials(cartLines){
                            var irrelevantSpeicals = [],
                              irrelevantSpeicalIndex = {},
                              irrelevantCoupons = [],
                              deliveryDate = new Date(checkoutProcessCtrl.timesDetails.deliveryDay.date);

                            angular.forEach(cartLines, function(line) {
                                if(line.product && line.product.branch && line.product.branch.specials) {
                                    var specials = line.product.branch.specials;
                                    angular.forEach(line.gifts, function (gift) {
                                        var endDate = new Date(gift.promotion.endDate);
                                        var giftId = gift.promotion.id;
                                        if(endDate < deliveryDate && !irrelevantSpeicalIndex[giftId]) {
                                            irrelevantSpeicalIndex[giftId] = true;
                                            var special = _findSepcial(specials, giftId);
                                            if(special) {
                                                special.frontendImageUrl = line.product.image && line.product.image.url;
                                                irrelevantSpeicals.push(special);
                                            } else {
                                                irrelevantCoupons.push(gift);
                                            }
                                        }
                                    })
                                    // angular.forEach(specials,function (special){
                                    //     var endDate = new Date(special.endDate);
                                    //     if(endDate < deliveryDate && !irrelevantSpeicalIndex[special.id]){
                                    //         irrelevantSpeicalIndex[special.id]= true;
                                    //         special.frontendImageUrl = line.product.image && line.product.image.url;
                                    //         irrelevantSpeicals.push(special);
                                    //     }
                                    // });
                                }
                            });
                            return {irrelevantSpeicals: irrelevantSpeicals, irrelevantCoupons: irrelevantCoupons};
                        }

                        // old promotion desgin check on lines, new promotion check on specials
                        if($rootScope.config.retailer.settings.isNewPromotionDesignEnabled === 'true' && !irrelevantSellDates.length && !irrelevantSpecials.length && !irrelevantCoupons.length)
                        {
                            return true;
                        }
                        return mDesign.dialog({
                            clickOutsideToClose: false,
                            controller: ['$scope', function($scope) {
                                $scope.irrelevantSellDates = irrelevantSellDates;
                                $scope.lines = irrelevantSalesLines;
                                $scope.potentialCartTotal = (skipDialogSpecial &&  Cart.total.finalPriceForView) || (!skipDialogSpecial && Cart.total.finalPriceForViewPotential);
                                $scope.irrelevantSpecials = irrelevantSpecials;
                                $scope.irrelevantCoupons = []; // full coupon details
                                $scope.close = mDesign.hide;
                                $scope.displayChoosenDateButton = true;
                                var listCouponIds = irrelevantCoupons.map(function (coupon) {
                                    return coupon.promotion.id;
                                })
                                getCouponInfo(listCouponIds);
                                $scope.listTemplateUrl = [
                                    "views/templates/irrelevant-sales/sell-date-dialog.html",
                                    "views/templates/irrelevant-sales/new-irrelevant-sales-dialog.html"
                                ]
                                $rootScope.$on('cart.lines.remove', function() {
                                    $scope.lines = getIrrelevantSales();
                                    $scope.irrelevantSellDates = getIrrelevantSellDates(Cart.lines);
                                });
                                $scope.irrelevantSellDates.forEach(function (line){
                                    var finalPrice = skipDialogSpecial ? line.finalPriceForView : line.finalPriceForViewPotential || 0
                                    $scope.potentialCartTotal -= Number(finalPrice || 0);
                                });


                                function getCouponInfo(giftPromotionIds) {
                                    if(!giftPromotionIds || giftPromotionIds.length === 0) return null;

                                    _getCouponInfo(giftPromotionIds).then(function(data) {
                                        $scope.irrelevantCoupons = data
                                    });
                                }
                            }],
                            // templateUrl: $rootScope.config.retailer.settings.isNewPromotionDesignEnabled === 'true' ?'views/templates/irrelevant-sales/index.html': 'views/templates/irrelevant-sales-dialog.html',
                            templateUrl: 'views/templates/irrelevant-sales/index.html'
                            // templateUrl: 'views/templates/irrelevant-sales-dialog.html'
                        });
                    }).then(function(passed) {
                        /**
                         *
                         * passed value
                         * true: all specials is valid nothing have todo
                         * false: have invalid specials, and customer chose "CHANGE CHOSEN TIME"
                         * undefined: have invalid product|specials, customer chose "OK, CONTINUE". He/She dont care specials.
                         *
                         * */
                        if (passed) {
                            return;
                        } else if (passed === false) {
                            checkoutProcessCtrl.back();
                            return;
                        }

                        if(!skipDialogSpecial){
                            Cart.setTimeTravel({
                                override: true
                            });
                        }

                        if(irrelevantSellDates && irrelevantSellDates.length > 0){
                            return Cart.removeLines(irrelevantSellDates)
                        } else if(!skipDialogSpecial){
                            Cart.save();
                        }

                    });
                });
            }

            // ECOM-6569
            function removeGgMapClass(){
                var ggMapClass = '_gmaps_cdv_'

                var elems = document.querySelectorAll("." + ggMapClass);

                Array.from(elems).forEach(function(el) {
                    el.classList.remove(ggMapClass);
                });
            }

            var _passNextStateChange,
              _validatingState;
            _listeners.push($rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState) {
                if(fromState.name === 'app.cart.checkout.process.map'){
                    removeGgMapClass();
                }

                if (toState.name.indexOf('app.cart.checkout.process') == -1 || fromState.name.indexOf('app.cart.checkout.process') == -1) return;

                if (_validatingState) {
                    return event.preventDefault();
                }

                var isPrevView = fromState.checkoutViewIndex > toState.checkoutViewIndex,
                  // extra view from main flow view
                  isChildView = ['app.cart.checkout.process.map'].includes(toState.name)
                validationResult = isPrevView || isChildView || _passNextStateChange || !checkoutProcessCtrl.validateView ? null : checkoutProcessCtrl.validateView();
                _passNextStateChange = false;

                if (!validationResult) {
                    checkoutProcessCtrl.isPrevViewAnimation = isPrevView;
                    return;
                }

                event && event.preventDefault();

                if (!(validationResult instanceof $q)) {
                    return _handelViewError(validationResult);
                }

                _validatingState = true;
                validationResult.then(function(err) {
                    return err;
                }, function(err) {
                    return err;
                }).then(function(err) {
                    _validatingState = false;

                    if (err) {
                        return _handelViewError(err);
                    }

                    _passNextStateChange = true;
                    $state.go(toState, toParams);
                });
            }));

            function _getCouponInfo(giftPromotionIds) {
                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/branches/:bid/users/:uid/coupons/' + giftPromotionIds.join(','),
                    params: {
                        extended: true,
                        countonly: true,
                    }
                });
            }

            function _setReceiptOption() {
                if(Number(Config.retailer.settings.receiptAfterFirstOrder) === $rootScope.DEFAULT_AFTER_FIRST_ORDER.DEFAULT) {
                    Config.checkoutData.receiptOption = Config.retailer.settings.receiptDefault;
                } else {
                    Config.checkoutData.receiptOption = User.settings.receiptTypeId || Config.retailer.settings.receiptDefault;
                }
            }

            function _keyboardShowHandler() {
                setTimeout(function() {
                    var activeElement = $window.document.activeElement,
                      tagName = activeElement && activeElement.tagName.toLowerCase();
                    if (($window.cordova && !$window.cordova.plugins.Keyboard.isVisible) || !activeElement || (tagName != 'input' && tagName != 'textarea')) return;

                    if (!_checkoutContainerElement) {
                        _checkoutContainerElement = $window.document.querySelector('#checkout_views > .checkout-container');
                    }
                    if (!_checkoutHeaderElement) {
                        _checkoutHeaderElement = $window.document.querySelector('#checkout_views > .checkout-header');
                    }

                    var top = activeElement.offsetTop,
                      currentElement = activeElement;
                    while (currentElement.offsetParent && currentElement.offsetParent != _checkoutContainerElement) {
                        currentElement = currentElement.offsetParent;
                        top += currentElement.offsetTop;
                    }

                    angular.element(_checkoutContainerElement).scrollTop(top - _checkoutHeaderElement.offsetHeight, 100);
                }, 100);
            }

            function filterCountryNames(country) {
                var countryName = country || checkoutProcessCtrl.addressDetails.country;
                checkoutProcessCtrl.countryNames = Util.getCountryAutocompleteOptions().filter(function (country) {
                    return country.toLowerCase().indexOf(countryName.toLowerCase()) !== -1;
                });
            }

            function _checkAddressFields() {
                checkoutProcessCtrl.hasAddressDetails = Util.checkAddressFields(checkoutProcessCtrl.addressDetails);
            }

            function checkForAutocompleteAddress(evt) {
                if ((evt.target.disabled || angular.element(evt.target).attr('disabled') === 'disabled' || evt.target.name === 'zip') &&
                  Config.retailer.settings.autocompleteAddressField) {
                    if(evt.target.name === 'zip') {
                        mDesign.alert(_translate('zipcode error'));
                    } else {
                        if(!Config.retailer.settings.activeZipProvider.isLockedAddress) {
                            mDesign.alert(_translate('not editable error'));
                        } else if (Config.retailer.settings.activeZipProvider.isLockedAddress) {
                            if (!checkoutProcessCtrl.addressDetails.city) {
                                mDesign.alert(_translate('not_editable_locked_empty error'));
                            } else {
                                mDesign.alert(_translate('not_editable_locked error'));
                            }
                        }
                    }
                }
            }

            function openAutocompleteAddress(evt) {
                evt.preventDefault();
                return mDesign.dialog({
                    templateUrl: 'views/templates/choose-address.html',
                    controller: ['$scope', function ($dialogScope) {
                        var dialogCtrl = this;
                        Util.getAddressByZipCode(checkoutProcessCtrl.addressDetails.zipCode).then(function (result) {
                            dialogCtrl.addresses = result;
                            dialogCtrl.errorMessage = !result || !result.length;
                        })
                        $dialogScope.continue = function () {
                            if (dialogCtrl.addresses && ((dialogCtrl.addresses.length && !dialogCtrl.address) || dialogCtrl.errorMessage)) {
                                return;
                            }

                            delete dialogCtrl.address.description;
                            var fullAddress = [dialogCtrl.address.text1]
                            if (dialogCtrl.address.locality) {
                                fullAddress.push(dialogCtrl.address.locality);
                            }
                            dialogCtrl.address.text1 = fullAddress.join(', ');
                            checkoutProcessCtrl.addressDetails = Object.assign(checkoutProcessCtrl.addressDetails, dialogCtrl.address);
                            Util.cleanUserAddressObject(checkoutProcessCtrl.addressDetails);

                            _checkAddressFields();
                            mDesign.hide();
                        };
                        $dialogScope.cancel = function () {
                            mDesign.hide();
                        };
                    }],
                    controllerAs: 'dialogCtrl',
                    styleClass: 'choose-address',
                    ariaLabelledby: 'choose_address_dialog_title',
                })
            }

            _listeners.push($rootScope.$on('$stateChangeSuccess', function(event, toState) {
                if (toState.name.indexOf('app.cart.checkout.process') == -1) return;

                _setCurrentView(toState.checkoutViewIndex);
            }));

            _listeners.push($rootScope.$on('googleMap.opening', function(event, toState) {
                checkoutProcessCtrl.isMapOpening = true;
                var _header = document.getElementById('header');
                if(_header) {
                    _header.classList.add('d-none');
                }
            }));

            _listeners.push($rootScope.$on('googleMap.closing', function(event, toState) {
                checkoutProcessCtrl.isMapOpening = false;
                var _header = document.getElementById('header');
                if(_header) {
                    _header.classList.remove('d-none');
                }
            }));

            $window.addEventListener('native.keyboardshow', _keyboardShowHandler);
            _listeners.push(function() {
                $window.removeEventListener('native.keyboardshow', _keyboardShowHandler);

                Cart.disableLoyalty(false);

                // clear the time travel
                if (Cart.timeTravel) {
                    Cart.setTimeTravel();
                    Cart.save();
                }
            });

            Util.destroyListeners($scope, _listeners);

            function _showAddressWarning(errorField) {
                var message
                var addressType = _ADDRESS_TYPE_MAP[errorField];
                var addressErrorMap

                if(checkoutProcessCtrl.isGoogleMapDropPinAllow){
                    message = _DEFAULT_ERROR_MESSAGE[addressType];
                    addressErrorMap = _addressVerificationText && _addressVerificationText[addressType];
                    if (addressErrorMap && addressErrorMap[_currentLanguage]) {
                        message = addressErrorMap[_currentLanguage];
                    }
                } else {
                    message = _DEFAULT_ERROR_MESSAGE_NON_DROP_PIN[addressType]
                    addressErrorMap = _addressVerificationTextDroppinOff && _addressVerificationTextDroppinOff[addressType];
                    if (addressErrorMap && addressErrorMap[_currentLanguage]) {
                        message = addressErrorMap[_currentLanguage];
                    }
                }

                return mDesign.dialog({
                    focusOnOpen: false,
                    clickOutsideToClose: true,
                    templateUrl: 'views/templates/address-warning.html',
                    controller: ['$scope', function ($scope) {
                        $scope.errorMessage = _translate(message);
                        $scope.hide = mDesign.hide;
                    }]
                });
            }

            function _verifyIsSuggestedValue() {
                var city = checkoutProcessCtrl.extractAddressField('city');
                var text1 = checkoutProcessCtrl.extractAddressField('text1');
                var zipCode = checkoutProcessCtrl.extractAddressField('zipCode');

                var address = text1 && city ? text1 + ', ' + city : text1;
                var verifyAddressObj = {}

                if(checkoutProcessCtrl.isEnabledAddressSettings && !checkoutProcessCtrl.isGMapWithZipCodeProvider){
                    if(checkoutProcessCtrl.isDropPinAddress) return {}

                    if(checkoutProcessCtrl.addressFormSettings.address.isEnabled){
                        verifyAddressObj.text1 = checkoutProcessCtrl.isExistedInSuggestions(_ADDRESS_TYPE_MAP.text1, address, checkoutProcessCtrl.suggestedAddresses);
                    }
                    
                    if(checkoutProcessCtrl.addressFormSettings.city.isEnabled){
                        verifyAddressObj.city = checkoutProcessCtrl.isExistedInSuggestions(_ADDRESS_TYPE_MAP.city, city, checkoutProcessCtrl.suggestedCities);
                    }
                    
                    if (checkoutProcessCtrl.enableAddressFiltering && checkoutProcessCtrl.addressFiltering === FILTER_ADDRESS_MODE.ZIP_CODE) {
                        verifyAddressObj.zipCode = checkoutProcessCtrl.isExistedInSuggestions(_ADDRESS_TYPE_MAP.zipCode, zipCode, checkoutProcessCtrl.suggestedZipCodes);
                    }

                } else {
                    verifyAddressObj = {
                        city: checkoutProcessCtrl.isExistedInSuggestions(_ADDRESS_TYPE_MAP.city, city, checkoutProcessCtrl.suggestedCities),
                        text1: checkoutProcessCtrl.isExistedInSuggestions(_ADDRESS_TYPE_MAP.text1, address, checkoutProcessCtrl.suggestedAddresses)
                    }
                }

                return verifyAddressObj;
            }
        }]);
})(angular);
