(function (angular) {
	angular.module('mobilezuz').config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('app.cart.order-update.summary', {
			url: '/summary',
			views: {
				updateOrder: {
					templateUrl: 'views/order/update/summary.html',
					controller: 'UpdateOrderSummaryCtrl as updateOrderSummaryCtrl'
				}
			}
		});

		$stateProvider.state('app.user-confirmation.update.summary', {
			url: '/summary',
			views: {
				updateOrder: {
					templateUrl: 'views/order/update/summary.html',
					controller: 'UpdateOrderSummaryCtrl as updateOrderSummaryCtrl'
				}
			}
		});
	}]).controller('UpdateOrderSummaryCtrl', [
		'$scope', '$rootScope', '$q', '$state', '$stateParams', '$timeout', 'mDesign', 'Config', 'Api', 'Cart', 'User',
		'Retailer', 'Util', 'PaymentsService', 'Orders', 'order', 'updateType', 'CREDIT_CARD_TYPES', 'SP_PAYMENTS',
		'DELIVERY_TYPES', 'UPDATE_ORDER_PAYMENT_TYPE',
		function ($scope, $rootScope, $q, $state, $stateParams, $timeout, mDesign, Config, Api, Cart, User,
				  Retailer, Util, PaymentsService, Orders, order, updateType, CREDIT_CARD_TYPES, SP_PAYMENTS,
				  DELIVERY_TYPES, UPDATE_ORDER_PAYMENT_TYPE) {
			var updateOrderSummaryCtrl = this,
				updateOrderCtrl = $scope.updateOrderCtrl,
				cartCtrl = $scope.cartCtrl;

			updateOrderCtrl.forward = forward;
			updateOrderCtrl.back = back;
			updateOrderSummaryCtrl.showWeightDebitDialog = showWeightDebitDialog;
			updateOrderSummaryCtrl.isNonLoyaltyCardWarning = isNonLoyaltyCardWarning;
			
			if (!updateOrderCtrl.paymentDetails) {
				updateOrderCtrl.goToState(updateType.stateBase + '.payment');
				return;
			}

			updateOrderSummaryCtrl.isPickup = order.branchDeliveryTypeId === DELIVERY_TYPES.PICKUP;

			_initCartTotal();

			User.getUserSettings().then(function (resp) {
				updateOrderSummaryCtrl.userData = resp;
			});

			function back() {
				$state.go(updateType.stateBase + '.payment');
			}

			function forward() {
				_checkoutUpdate();
			}

			function showWeightDebitDialog() {
				return mDesign.dialog({
					focusOnOpen: false,
					clickOutsideToClose: false,
					templateUrl: 'views/templates/ebt-weight-debit-disclaimer-dialog.html',
					controller: ['$scope', function ($scope) {
						$scope.hide = mDesign.hide;
					}]
				});
			}

			/**
			 * Returns whether a loyalty card exists but not chosen
			 * @public
			 *
			 * @return {boolean}
			 */
			function isNonLoyaltyCardWarning() {
				return !!updateOrderCtrl.hasLoyaltyCreditCards && (
					updateOrderCtrl.paymentDetails.paymentMethod !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD ||
					!updateOrderCtrl.paymentDetails.creditCard.isLoyalty
				);
			}

			function _checkoutUpdate() {
				if (updateOrderCtrl.checkoutInProgress) {
					return;
				}

				updateOrderCtrl.checkoutInProgress = true;

				var totalAmount = updateOrderSummaryCtrl.cartTotal.finalPriceWithTax +
					updateOrderSummaryCtrl.cartTotal.serviceFee.finalPriceWithTax +
					updateOrderSummaryCtrl.cartTotal.deliveryCost.finalPriceWithTax;
				if (updateOrderCtrl.ebtCalculatedAmounts && updateOrderCtrl.ebtCalculatedAmounts.total) {
					totalAmount += (updateOrderCtrl.ebtCalculatedAmounts.total.deposit || 0) - (updateOrderCtrl.ebtCalculatedAmounts.total.taxSaved || 0);
				}

				var isPickAndGo = order.branchDeliveryTypeId === DELIVERY_TYPES.PICK_AND_GO;

				return _getUpdateData().then(function(updateData) {
					if (updateData.actionResponse) {
						return updateData.actionResponse;
					}

					return _sendUpdate(updateData);
				}).then(function() {
					if (updateType.type === UPDATE_ORDER_PAYMENT_TYPE.EDIT_ORDER_UPDATE) {
						cartCtrl.updatedOrderId = Orders.orderInEdit.id;
						cartCtrl.updateOrder = true;
						Orders.orderInEdit = null;
						Cart.clear(true);

						//== refreshing User's Credit data after order update
						if ($rootScope.isCreditCustomer) {
							User.getUserSettings(true);
						}
					}

					$state.go(updateType.stateBase + '.finish', {
						orderId: order.id,
						totalAmount: totalAmount,
						isPickAndGo: isPickAndGo
					});
				}).catch(function(err) {
					_handleError(err);
				}).finally(function() {
					updateOrderCtrl.checkoutInProgress = false;
				});
			}

			function _sendUpdate(updateData) {
				if (updateType.type === UPDATE_ORDER_PAYMENT_TYPE.USER_CONFIRMATION_UPDATE) {
					return Api.request({
						method: 'POST',
						url: '/v2/retailers/:rid/orders/' + order.id + '/_finishUserConfirm',
						data: updateData
					}, {
						hideError: true
					});
				}

				return Api.request({
					method: 'POST',
					url: '/v2/retailers/:rid/branches/:bid/users/:uid/_checkoutUpdate',
					data: updateData
				}, {
					hideError: true
				});
			}

			function _handleError(err) {
				return $q.resolve().then(function() {
					if (err.statusCode === 400) {
						return;
					}

					if (updateType.type !== UPDATE_ORDER_PAYMENT_TYPE.EDIT_ORDER_UPDATE) {
						return;
					}

					return Orders.cancelEdit().then(function () {
						Cart.clear();
					}).catch(function() {
						Orders.orderInEdit = null;
						Cart.clear(true);
					}).then(function() {
						return $state.go(updateType.exitState);
					}).then(function() {
						// add timeout to allow the state navigation to finish before popping dialogs
						return $timeout(function() {}, 200);
					});
				}).then(function() {
					if (updateType.type === UPDATE_ORDER_PAYMENT_TYPE.USER_CONFIRMATION_UPDATE) {
						Retailer.getRetailerSettings().then(function(response) {
							return mDesign.dialog({
								focusOnOpen: false,
								clickOutsideToClose: false,
								templateUrl: 'views/order/update/user-confirmation-error-dialog.html',
								controller: ['$scope', function($scope) {
									$scope.closeDialog = mDesign.hide;
									$scope.retailerSupportPhone = response.contactPhone || (Config.branch && Config.branch.phone);
									$scope.errorMessage = err.response && err.response.error;
								}]
							});
						}).then(function(tryAgain) {
							if (tryAgain) {
								_checkoutUpdate();
							}
						});
						return;
					}

					if ((err.statusCode !== 300 && err.statusCode !== 401) && err.response && err.response.error) {
						//== when Credit customer is limited we need to display a custom popup with remaining sum
						if (err.response.code === 'creditCustomerLimited') {
							return Cart.checkUserCreditLimited(true, '');
						} else {
							return mDesign.alert('{{\'Update order failed\' | translate}}.<br/>{{\'' + err.response.error.replace(/'/g, '\\\'') + '\' | translate}}');
						}
					}

					if (err.apiOptions) {
						err.apiOptions.hideError = false;
						$rootScope.$emit('spApi.error', err);
					}
				});
			}

			function _getUpdateData() {
				var data = {};
				if (updateType.type === UPDATE_ORDER_PAYMENT_TYPE.USER_CONFIRMATION_UPDATE) {
					data.userConfirmationToken = $stateParams.userConfirmationToken;
				} else {
					data.newCartId = Cart.serverCartId;
					data.orderId = Orders.orderInEdit.id;
					data.skipActiveProductsValidation = true;
				}
				var timeSlotData = Util.getNewTimeSlotData(Orders.orderInEdit.id, Cart.serverCartId);
				if(timeSlotData && timeSlotData.newAreaDeliveryTimeId && timeSlotData.newDate) {
					data.newAreaDeliveryTimeId = timeSlotData && timeSlotData.newAreaDeliveryTimeId;
					data.newDate = timeSlotData && timeSlotData.newDate;
				}
				return _setCheckoutPayments(data).then(function() {
					return data;
				});
			}

			/**
			 * Get the checkout data payments
			 * @private
			 *
			 * @param {Object} updateData
			 *
			 * @returns {Promise<Array<Object>>}
			 */
			function _setCheckoutPayments(updateData) {
				var mainPayment = {
					methodId: SP_PAYMENTS.PAYMENT_METHODS.ID_BY_NAME[updateOrderCtrl.paymentDetails.paymentMethod],
					paymentsNumber: updateOrderCtrl.paymentDetails.paymentsNumber,
					isMain: false
				};

				if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT) {
					mainPayment.methodId = SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD;
				}

				if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD &&
					updateOrderCtrl.isRememberCreditCardsMode && updateOrderCtrl.paymentDetails.creditCard) {
					mainPayment.creditCardId = updateOrderCtrl.paymentDetails.creditCard.id;
					mainPayment.cvv = updateOrderCtrl.paymentDetails.cvv;
					mainPayment.isLoyalty = updateOrderCtrl.paymentDetails.creditCard.isLoyalty;
					if (updateOrderCtrl.paymentDetails.creditCard.cardType === CREDIT_CARD_TYPES.DIRECT) {
						mainPayment.paymentsNumber = 1;
					}
				} else if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.STORE_CREDIT && updateOrderCtrl.paymentDetails.storeCredit) {
					mainPayment.token = updateOrderCtrl.paymentDetails.storeCredit;
				} else if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.PAYPAL &&
					(updateOrderCtrl.paymentDetails.paymentToken || updateOrderCtrl.paymentDetails.paypalAccount)) {
					if (updateOrderCtrl.paymentDetails.paymentToken && updateOrderCtrl.paymentDetails.paypalAccount.payerId === updateOrderCtrl.paymentDetails.paypalPayerId) {
						mainPayment.token = updateOrderCtrl.paymentDetails.paymentToken;
					} else {
						mainPayment.billingAgreement = updateOrderCtrl.paymentDetails.paypalAccount ? updateOrderCtrl.paymentDetails.paypalAccount.billingAgreement : null;
					}
				} else if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.ORGANIZATION) {
					mainPayment.token = updateOrderCtrl.paymentDetails.paymentToken;
				} else if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.I_CREDIT_MASTER_PASS &&
					updateOrderCtrl.paymentDetails.paymentToken) {
					mainPayment.token = updateOrderCtrl.paymentDetails.paymentToken;
					mainPayment.authNum = updateOrderCtrl.paymentDetails.authNum;
				}

				updateData.payments = [];
				return $q.resolve().then(function() {
					if (updateOrderCtrl.paymentDetails.paymentMethod !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT || !updateOrderCtrl.paymentDetails.ebt.fsAmount) {
						return;
					}

					return _getEBTCheckoutPaymentTransaction(SP_PAYMENTS.PAYMENT_TYPES.EBT_SNAP);
				}).then(function(ebtFSPaymentToken) {
					if (ebtFSPaymentToken) {
						updateData.payments.push({
							methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT,
							typeId: SP_PAYMENTS.PAYMENT_TYPES.EBT_SNAP,
							amount: updateOrderCtrl.ebtCalculatedAmounts.fs.amount,
							preAuthAmount: updateOrderCtrl.paymentDetails.ebt.fsAmount,
							creditCardId: updateOrderCtrl.paymentDetails.ebtCard.id,
							token: ebtFSPaymentToken
						});
					}

					if (updateOrderCtrl.paymentDetails.paymentMethod !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT || !updateOrderCtrl.paymentDetails.ebt.cbAmount) {
						return;
					}

					return _getEBTCheckoutPaymentTransaction(SP_PAYMENTS.PAYMENT_TYPES.EBT_CASH);
				}).then(function(ebtCBPaymentToken) {
					if (ebtCBPaymentToken) {
						updateData.payments.push({
							methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT,
							typeId: SP_PAYMENTS.PAYMENT_TYPES.EBT_CASH,
							amount: updateOrderCtrl.ebtCalculatedAmounts.cb.amount,
							preAuthAmount: updateOrderCtrl.paymentDetails.ebt.cbAmount,
							creditCardId: updateOrderCtrl.paymentDetails.ebtCard.id,
							token: ebtCBPaymentToken
						});
					}

					updateData.payments.push(mainPayment);

					var is3DSCard = updateOrderCtrl.isCreditCard3DSActive &&
						updateOrderCtrl.paymentDetails.creditCard && updateOrderCtrl.paymentDetails.creditCard.is3DS;
					if (mainPayment.methodId !== SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD ||
						(updateOrderCtrl.isRememberCreditCardsMode && !is3DSCard)) {
						return;
					}

					// TODO send the amount paid with ebt and reduce it from the payment amount,
					// currently ebt is only supported when remembering credit cards
					var options = {
						creditCardId: (updateOrderCtrl.paymentDetails.creditCard || {}).id,
						cvv: updateOrderCtrl.paymentDetails.cvv,
						orderId: order.id,
						cartId: updateType.type === UPDATE_ORDER_PAYMENT_TYPE.USER_CONFIRMATION_UPDATE ? order.cartId : updateData.newCartId,
						action: updateType.type === UPDATE_ORDER_PAYMENT_TYPE.USER_CONFIRMATION_UPDATE ? 'finishUserConfirm' : 'checkoutUpdate',
						actionData: updateData
					};
					if (updateType.type === UPDATE_ORDER_PAYMENT_TYPE.USER_CONFIRMATION_UPDATE) {
						options.cartId = order.cartId;
					}
					return PaymentsService.payWithCreditCard(options).catch(function (err) {
						if (err.statusCode === 409 && err.response &&
							err.response.orderId && err.response.error === 'Already checked out') {
							return {actionResponse: {id: err.response.orderId}};
						} else {
							throw err;
						}
					});
				}).then(function(paymentData) {
					if (!paymentData) {
						return;
					}

					if (paymentData.actionResponse) {
						updateData.actionResponse = paymentData.actionResponse;
					} else if (paymentData.skip3DS) {
						mainPayment.skip3DS = paymentData.skip3DS
					} else {
						mainPayment.token = paymentData.paymentToken;
						mainPayment.authNum = paymentData.authNum;
						mainPayment.isPaymentCompleted = paymentData.isPaymentCompleted;
						mainPayment.preAuthAmount = paymentData.amount;
						if (paymentData.paymentsNumber) {
							mainPayment.paymentsNumber = paymentData.paymentsNumber;
						}
					}
				});
			}

			function _getEBTCheckoutPaymentTransaction(paymentType) {
				return mDesign.dialog({
					focusOnOpen: false,
					clickOutsideToClose: false,
					templateUrl: 'views/templates/ebt-pin-message-dialog.html',
					controller: ['$scope', function($scope) {
						$scope.paymentType = paymentType;
						$scope.hide = mDesign.hide;
					}]
				}).then(function() {
					return getEBTTransaction();
				});
			}

			function getEBTTransaction() {
				if (!updateOrderCtrl.paymentDetails.ebtCard) {
					return $q.reject('no ebt card');
				}

				var address = {};
				if (updateOrderSummaryCtrl.isPickup) {
					address.addressText = Config.branch.location;
					address.city = Config.branch.city;
					address.zipCode = Config.branch.zipCode;
				} else {
					// TODO add support in getting edit order address
					/*address.addressText = updateOrderCtrl.addressDetails.text1;
					address.city = updateOrderCtrl.addressDetails.city;
					address.zipCode = updateOrderCtrl.addressDetails.zipCode;*/
				}

				return PaymentsService.initEBTPayment({
					creditCardId: updateOrderCtrl.paymentDetails.ebtCard.id,
					cartId: updateType.type === UPDATE_ORDER_PAYMENT_TYPE.USER_CONFIRMATION_UPDATE && order.cartId,
					orderId: order.id,
					address: address,
					isPickup: updateOrderSummaryCtrl.isPickup
				}).then(function(resp) {
					return resp.paymentToken;
				});
			}

			function _initCartTotal() {
				if (updateType.type === UPDATE_ORDER_PAYMENT_TYPE.EDIT_ORDER_UPDATE) {
					updateOrderSummaryCtrl.cartTotal = Cart.total;

					if (updateOrderCtrl.paymentDetails.ebt && !updateOrderCtrl.ebtCalculatedAmounts &&
						(updateOrderCtrl.paymentDetails.ebt.fsAmount || updateOrderCtrl.paymentDetails.ebt.cbAmount)) {
						updateOrderCtrl.ebtCalculatedAmounts = PaymentsService.calculateEBTAmounts(
							updateOrderCtrl.paymentDetails.ebt, Cart.getEBTEligible());
					}
				} else {
					// when updating user confirmation order, we can't use the cart service as it currently is
					// so we create a 'fake' cart total object made out of the existing order data

					Retailer.getRetailerSettings().then(function(data) {
						var finalPriceWithoutTax = 0;
						angular.forEach(order.lines, function(line) {
							var taxAmount = ((line.taxAmount || 0) + 1);

							if (data.isRegularPriceWithTax) {
								finalPriceWithoutTax += line.totalPrice / taxAmount;
							} else {
								finalPriceWithoutTax += line.totalPrice;
							}
						});
						updateOrderSummaryCtrl.cartTotal = {
							finalPriceWithTax: order.totalAmount,
							finalPriceWithoutTax: finalPriceWithoutTax,
							tax: order.totalAmount - finalPriceWithoutTax,
							serviceFee: { finalPriceWithTax: 0 },
							deliveryCost: { finalPriceWithTax: 0 }
						};
					});
				}
			}
		}]);
})(angular);
