angular.module('mobilezuz')
    .service('Bumpers', [
        '$q', 'Config', '$stateParams', 'DataLayer',
        function ($q, Config, $stateParams, DataLayer) {
            var self = this;

            var BUMPER_TYPES =  {
                recipe: {
                    bumperKey: 'recipe',
                    identificationKey: 'isRecipe',
                    arrayName: 'recipes'
                },
                video: {
                    bumperKey: 'video',
                    identificationKey: 'isVideo',
                    arrayName: 'videos'
                },
                banner: {
                    bumperKey: 'banner',
                    identificationKey: 'isBanner',
                    arrayName: 'promotionBanners'
                }
            };

            angular.extend(self, {
                handleProductsBumpers: handleProductsBumpers
            });


            function handleProductsBumpers(scopeProducts, productAndBumpersArray) {
                var indexToPush = {},
                    productsWithBumpers = [];

                angular.forEach(productAndBumpersArray, function(product, index) {
                    indexToPush[product.id] = index;
                    if (_hasBumper(product)) {
                        productsWithBumpers.push(product);
                    }
                });

                angular.forEach(productsWithBumpers, function () {
                    _pushBumper(scopeProducts, productAndBumpersArray, productsWithBumpers, indexToPush);
                });
            }


            function _hasBumper(product) {
                var hasBumper = false;
                angular.forEach(BUMPER_TYPES, function(type) {
                    if (product[type.arrayName] && product[type.arrayName].length) {
                        angular.forEach(product[type.arrayName], function (bumper) {
                            if (bumper.languageId === Config.language.id || !bumper.languageId && type === BUMPER_TYPES.banner) {
                                product.bumperKey = product.bumperKey && Math.random() < 0.5 ? product.bumperKey : type.bumperKey; //randomly choose between what bumper to push / use
                                hasBumper = true;
                            }
                        });
                    }
                });
                return hasBumper;
            }

            function _pushBumper(scopeProducts, productAndBumpersArray, productsWithBumpers, indexToPush) {
                var randomProductBumperIndex = parseInt(Math.random() * productsWithBumpers.length),
                    randomProductBumperToPush = productsWithBumpers[randomProductBumperIndex],
                    bumperArrayName = BUMPER_TYPES[randomProductBumperToPush.bumperKey].arrayName,
                    bumperIdentificationKey = BUMPER_TYPES[randomProductBumperToPush.bumperKey].identificationKey,
                    randomBumperIndex = parseInt(Math.random() * randomProductBumperToPush[bumperArrayName].length),
                    indexToAdd = indexToPush[randomProductBumperToPush.id] + 1,
                    bumper = randomProductBumperToPush[bumperArrayName][randomBumperIndex];

                if (!_isValidBumper(bumper)) return;

                if (_isNewBumper(bumperIdentificationKey, [productAndBumpersArray, scopeProducts], randomProductBumperToPush[bumperArrayName][randomBumperIndex])) {
                    _pushNewBumper(scopeProducts, bumperIdentificationKey, randomProductBumperToPush[bumperArrayName][randomBumperIndex], productAndBumpersArray, indexToAdd, randomProductBumperToPush.bumperKey);
                } else {
                    var newBumperIndex = _findNewBumper(bumperIdentificationKey, [productAndBumpersArray, scopeProducts], randomProductBumperToPush[bumperArrayName]);
                    if (newBumperIndex) {
                        _pushNewBumper(scopeProducts, bumperIdentificationKey, randomProductBumperToPush[bumperArrayName][newBumperIndex], productAndBumpersArray, indexToAdd, randomProductBumperToPush.bumperKey);
                    }
                }
            }

            function _isNewBumper(bumperIdentificationKey, multipleProductAndBumperArrays, bumper) {
                var isNew = true;
                angular.forEach(multipleProductAndBumperArrays, function (productAndBumperArray) {
                    if (!productAndBumperArray.length) return;
                    isNew = isNew && productAndBumperArray.every(function (existingBumper) {
                        return !existingBumper[bumperIdentificationKey] || existingBumper.id !== bumper.id;
                    });
                });
                return isNew;
            }

            function _isValidBumper(bumper) {
                return !(bumper.retailerId && bumper.retailerId !== Config.retailerId && bumper.retailerId !== (Config.hubRetailer || {}).id);
            }

            function _findNewBumper(bumperIdentificationKey, existingBumpers, newBumpers) {
                var indexOfNewBumper = null;
                angular.forEach(newBumpers, function(newBumper, index) {
                    if (_isNewBumper(bumperIdentificationKey, existingBumpers, newBumper)) {
                        indexOfNewBumper = index;
                    }
                });
                return indexOfNewBumper;
            }

            function _bumperInProximity(products, index) {
                var isInProximity,
                    min = index - 5 >= 0 ? index - 5 : 0,
                    max = index + 5 <= products.length ? index + 5 : products.length;
                for (; min < max; min++ ) {
                    if (products[min] && products[min].isBumper) {
                        isInProximity = true;
                    }
                }
                return isInProximity;
            }

            function _pushNewBumper(scopeProducts, bumperIdentificationKey, bumper, array, index, bumperKey) {
                var indexToAdd = index || array.length;
                index = index + scopeProducts.length;
                var arrayToCheck = [];
                arrayToCheck.push.apply(arrayToCheck, scopeProducts);
                arrayToCheck.push.apply(arrayToCheck, array);
                if (!_bumperInProximity(arrayToCheck, index)) {
                    bumper[bumperIdentificationKey] = true;
                    bumper.isBumper = true;
                    array.splice(indexToAdd, 0, bumper);
                    DataLayer.push(DataLayer.EVENTS.VIEW_PROMOTION, {promotion: bumper, data: {type: bumperKey}});
                }
            }
        }
    ]);
