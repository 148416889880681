(function (angular) {
    /*

    Example:
    http://localhost:3000/?domain={retailer_domain}.com&externalCart=7290006265500,1;7290000042503,1;66556655,1;710069000506,1;7290016363005,1.00;7290003211739,1.55;729000321173966,1.55&externalName=Pricez

    externalCart=barcode,quantity;barcode,quantity

     */


    angular.module('mobilezuz').service('ExternalCartService', ['Api', function(api) {
        this.createProductsList = createProductsList;

        function createProductsList(str) {
            var productsString = str.split(';'),
                productsObject = {};

            //== Create dictionary with localBarcode as a key and quantity as value
            angular.forEach(productsString, function (productString) {
                var productSplit = productString.split(',');

                //== Must be: localBarcode,quantity
                if(productSplit.length === 2) {
                    productsObject[productSplit[0].toString()] = {
                        quantity: productSplit[1],
                        found: false
                    };
                }
            });

            var localBarcodes = Object.keys(productsObject);
            if(!localBarcodes.length) {
                return;
            }

            //== Get Company retailer products by localBarcodes
            return api.request({
                method: 'GET',
                url: '/v2/retailers/:rid/branches/:bid/products',
                params: {
                    filters: {
                        must: {
                            term: {
                                localBarcode: localBarcodes
                            }
                        }
                    }
                }
            }).then(function (response) {
                var cartLinesData = {
                    cartLines: [],
                    cartLinesMissing: [],
                    itemsNotFound: 0,
                    itemsWithQuantity: 0
                };

                //== Create a final products list with provided local barcode and quantity parameter
                angular.forEach(response.products, function (product) {
                    if(product && product.id && productsObject[product.localBarcode] && !productsObject[product.localBarcode].found) {
                        if(_checkProductMissing(product)) {
                            cartLinesData.cartLinesMissing.push({
                                product: product,
                                quantity: 0
                            });
                        } else {
                            cartLinesData.cartLines.push({
                                product: product,
                                quantity: _createLocalQuantity(productsObject[product.localBarcode].quantity, product.isWeighable)
                            });
                            cartLinesData.itemsWithQuantity++;
                        }

                        productsObject[product.localBarcode].found = true;
                    }
                });

                //== Check if we got all products data from server, if not count missing products
                if(cartLinesData.cartLines.length + cartLinesData.cartLinesMissing.length !== localBarcodes.length) {
                    angular.forEach(localBarcodes, function (localBarcode) {
                        if(productsObject[localBarcode] && !productsObject[localBarcode].found) {
                            cartLinesData.itemsNotFound++;
                        }
                    });
                }

                return cartLinesData;
            });

        }

        /**
         * Quantity with maximum 2 decimal places
         * @private
         *
         * @param {String} quantityStr
         * @param {Boolean} isWeighable
         *
         * @returns {Number}
         */
        function _createLocalQuantity(quantityStr, isWeighable) {
            if(!quantityStr) {
                return 1;
            }
            return isWeighable ? Number(parseFloat(quantityStr).toFixed(2)) : parseInt(quantityStr);
        }

        /**
         * Init the data
         * @private
         *
         * @param {Object} product
         *
         * @returns {Boolean}
         */
        function _checkProductMissing(product) {
            return !(product && product.branch && product.branch.isVisible);
        }
    }]);


    angular.module('mobilezuz').config([
        'SpDialogUrlManagerProvider', 'SP_URL_DIALOG_QUERY_PARAMS', 'SP_URL_DIALOG_DATA_QUERY_PARAMS',
        function (SpDialogUrlManagerProvider, SP_URL_DIALOG_QUERY_PARAMS, SP_URL_DIALOG_DATA_QUERY_PARAMS) {
            SpDialogUrlManagerProvider.dialog({
                queryParams: SP_URL_DIALOG_QUERY_PARAMS.EXTERNAL_CART,
                dataQueryParams: SP_URL_DIALOG_DATA_QUERY_PARAMS.EXTERNAL_CART,
                canShow: ['paramValue', function (paramValue) {
                    return !!paramValue;
                }],
                paramResolve: ['paramValue', 'ExternalCartService', function (paramValue, ExternalCartService) {
                   return ExternalCartService.createProductsList(paramValue);
                }],
                show: ['mDesign', 'SpDialogUrlManager', 'value', function (mDesign, SpDialogUrlManager, value) {
                    return mDesign.dialog({
                        multiple: true,
                        fullscreen: true,
                        templateUrl: 'views/external-cart.html',
                        controller: 'ExternalCartCtrl as externalCartCtrl',
                        locals: {
                            externalCartLines: value
                        },
                        dontPreventRouteChange: true
                    });
                }]
            });
        }
    ]);


    angular.module('mobilezuz').controller('ExternalCartCtrl', ['$state', '$timeout', '$stateParams', 'Cart', 'Util',
        'SpRecipeService', 'externalCartLines', 'mDesign', 'SpDialogUrlManager', '$rootScope', 'SP_SERVICES',
        function ($state, $timeout, $stateParams, cart, util, SpRecipeService, externalCartLines, mDesign, SpDialogUrlManager, $rootScope, SP_SERVICES) {
            window.prerenderReady = true;

            var externalCartCtrl = this;

            externalCartCtrl.itemsWithQuantity = externalCartLines.itemsWithQuantity;
            externalCartCtrl.itemsNotFound = externalCartLines.itemsNotFound;
            externalCartCtrl.externalName = $stateParams.externalName || '';
            externalCartCtrl.cartLines = externalCartLines.cartLines;
            externalCartCtrl.cartLinesMissing = externalCartLines.cartLinesMissing;
            externalCartCtrl.addProductToCart = addProductToCart;
            externalCartCtrl.addProductQuantity = addProductQuantity;
            externalCartCtrl.addAllIngredientsToCart = addAllIngredientsToCart;
            externalCartCtrl.closeAndGoToCart = closeAndGoToCart;
            externalCartCtrl.close = close;

            function addProductQuantity(cartLine, toAdd) {
                var nextVal = (cartLine.quantity || 0) + cart.quantityInterval(cartLine) * (toAdd || 0);
                if (nextVal >= 0) {
                    if (nextVal === 0) {
                        externalCartCtrl.itemsWithQuantity--;
                    } else if (cartLine.quantity === 0) {
                        externalCartCtrl.itemsWithQuantity++;
                    }

                    cartLine.quantity = nextVal;
                }

                return true;
            }

            function addProductToCart(cartLine) {
                var isLimitPassed = cart.checkHeavyLimitBeforeAddLine(cartLine);
                if (isLimitPassed === false) {
                    if (cart.lines && Object.keys(cart.lines).length === 0 &&
                        $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                        cart.addDeliveryFeeLineIfNeeded();
                    }
                    SpRecipeService.addIngredientProductToCart(cartLine);
                    cartLine.editState = true;
                }
            }

            function addAllIngredientsToCart() {
                //== Add only cart lines that have quantity
                var cartLines = [];
                angular.forEach(externalCartCtrl.cartLines, function (cartLine) {
                    if (cartLine.quantity) {
                        cartLines.push(cartLine);
                    }
                });
                var isLimitPassed = cart.checkHeavyLimitBeforeAddLines(cartLines);
                if (isLimitPassed === false) {
                    if (cart.lines && Object.keys(cart.lines).length === 0 &&
                        $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                        cart.addDeliveryFeeLineIfNeeded();
                    }
                    cart.addLines(cartLines, SP_SERVICES.SOURCES.RECIPE, true).then(function(){
                        closeAndGoToCart();
                    });
                }
            }

            function closeAndGoToCart() {
                $timeout(function() {
                    $state.go('app.cart', {externalCart: null, externalName: null}, {notify: true});
                }, 500);
            }

            function close() {
                SpDialogUrlManager.backClose();
            }

        }]);
})(angular);
