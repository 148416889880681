(function(angular) {
    'use strict';

    angular.module('mobilezuz')
        .service('FiltersManager', ['$filter', '$state', '$q', 'Config', 'PermanentFilters', 'SEARCH_SORT_BOOST_TYPES', function($filter, $state, $q, Config, PermanentFilters, SEARCH_SORT_BOOST_TYPES) {
            var self = this;
            var _productTagFilter = $filter('productTag');

            self.setFiltersFromUrl = setFiltersFromUrl;
            self.setFiltersToUrl = setFiltersToUrl;

            self.getFamiliesFilter = getFamiliesFilter;
            self.getBrandsFilter = getBrandsFilter;
            self.getCategoriesFilter = getCategoriesFilter;
            self.getCategoriesFilterPlain = getCategoriesFilterPlain;
            self.getProductTagsFilter = getProductTagsFilter;
            self.getSupervisionsFilter = getSupervisionsFilter;

            self.getTypesFilter = getTypesFilter;
            self.getTypesFilterPlain = getTypesFilterPlain;

            self.parsePermanentProductTags = parsePermanentProductTags;

            self.getProductTagsSortBoost = getProductTagsSortBoost;
            self.getProductTagName = getProductTagName;

            /**
             * @typedef {Object} MobileFiltersItem
             *
             * @property {number} value
             * @property {string} [names]
             * @property {string} title
             * @property {string} [icon]
             * @property {number} [count]
             */

            /**
             * @typedef {Object} MobileFilterTool
             *
             * @property {string} title
             * @property {string} name
             * @property {string} orderItemsBy
             * @property {Promise<Array<MobileFiltersItem>>} [itemsPromise]
             * @property {Array<MobileFiltersItem>} items
             * @property {Array<MobileFiltersItem>} selectedItems
             * @property {function} parse
             * @property {function} [resetValues]
             * @property {number} [permanentFiltersType]
             * @property {Promise} [initPromise]
             * @property {boolean} [notInclusive] - the filter related products won't be all of the results
             *          (when a single filter option is available, it will still change the number of products)
             */

            /**
             * @typedef {Object} MobileSortToolValue
             *
             * @property {string} name
             * @property {boolean} isDesc
             */

            /**
             * Returns the selected filters values from the url params (and from local storage when permanent)
             * @public
             *
             * @param {Array<MobileFilterTool>} [filters]
             * @param {Object} stateFilters - key is the filter's name and the value is the array of its values
             *
             * @returns {Promise<{filters: Object, sort: MobileSortToolValue}>} - 'filters' key is the filter's name and the value is the array of its values
             */
            function setFiltersFromUrl(filters, stateFilters) {
                var promises = [];
                angular.forEach(filters, function(filter) {
                    promises.push((filter.initPromise || $q.resolve()).then(function() {
                        var values = stateFilters && stateFilters[filter.name] || undefined;
                        if (filter.permanentFiltersType) {
                            var valuesMap = {};
                            angular.forEach(values, function(value) {
                                valuesMap[value] = true;
                            });
                            values = values || [];
                            angular.forEach(PermanentFilters.values[filter.permanentFiltersType], function(value) {
                                if (!valuesMap[value]) {
                                    values.push(value);
                                }
                            });
                        }

                        if (values) {
                            filter.selectedItems = values.map(function(value) {
                                return {value: value};
                            });

                            if (filter.resetValues) {
                                filter.resetValues();
                            }
                        }
                    }));
                });
                return $q.all(promises);
            }

            /**
             * Sets items pages filters and sort to url (or local storage when permanent)
             * @public
             *
             * @param {Array<MobileFilterTool>} [filters]
             * @param {MobileSortToolValue} [sortValue]
             */
            function setFiltersToUrl(filters, sortValue) {
                var promises = [];
                var params = {
                    filters: undefined,
                    sort: undefined
                };

                if (filters) {
                    angular.forEach(filters, function(filter) {
                        promises.push((filter.initPromise || $q.resolve()).then(function() {
                            /*if (filter.permanentFiltersType) {
                                _setPermanentFilter(filter, filter.selectedItems);
                            } else*/ if (filter.selectedItems && filter.selectedItems.length) {
                                params.filters = params.filters || {};
                                params.filters[filter.name] = _mapToValues(filter.selectedItems);
                            }
                        }));
                    });
                }

                if (sortValue && sortValue.name) {
                    //add to a new object to make sure only these two keys are added
                    params.sort = {
                        name: sortValue.name,
                        isDesc: sortValue.isDesc
                    };
                }

                return $q.all(promises).then(function() {
                    return $state.go('.', params, { location: true, inherit: true, notify: false });
                });
            }

            /**
             * Set the selected filters as permanent filter values
             * @private
             *
             * @param {MobileFilterTool} filter
             * @param {Array<MobileFiltersItem>} selectedItems
             */
            function _setPermanentFilter(filter, selectedItems) {
                $q.resolve(filter.itemsPromise || filter.items).then(function(items) {
                    var valuesMap = {};
                    angular.forEach(selectedItems || [], function(item) {
                        valuesMap[item.value] = true;
                    });

                    var removeValues = [],
                        addValues = [];
                    angular.forEach(items, function(filterItem) {
                        if (valuesMap[filterItem.value]) {
                            addValues.push(filterItem.value);
                        } else {
                            removeValues.push(filterItem.value);
                        }
                    });

                    PermanentFilters.addFilterValues(filter.permanentFiltersType, addValues);
                    PermanentFilters.removeFilterValues(filter.permanentFiltersType, removeValues);
                });
            }

            /**
             * Returns a families filters tool
             * @public
             *
             * @param {Promise} filtersPromise
             *
             * @return {MobileFilterTool}
             */
            function getFamiliesFilter(filtersPromise) {
                return {
                    title: '\'Type\' | translate',
                    name: 'family',
                    orderItemsBy: 'names | name',
                    itemsPromise: filtersPromise.then(function(res) {
                        return res.families.map(function(familyValue) {
                            var names = {};
                            angular.forEach(familyValue.names, function (name, languageId) {
                                names[languageId] = name.name;
                            });
                            return {
                                value: familyValue.id,
                                names: names,
                                title: 'names | name',
                                count: familyValue.count
                            };
                        });
                    }),
                    parse: function(params) {
                        return _defaultParseRequestParams(params, _mapToValues(this.selectedItems), 'family.id');
                    }
                };
            }

            /**
             * Returns a brands filters tool
             * @public
             *
             * @param {Promise} filtersPromise
             * @param {string} [fieldName]
             *
             * @return {MobileFilterTool}
             */
            function getBrandsFilter(filtersPromise, fieldName) {
                return {
                    title: '\'Brand\' | translate',
                    name: 'brand',
                    orderItemsBy: 'names | name',
                    itemsPromise: filtersPromise.then(function(res) {
                        return res.brands.map(function(brandValue) {
                            return {
                                value: brandValue.id,
                                names: brandValue.names,
                                title: 'names | name',
                                count: brandValue.count
                            };
                        });
                    }),
                    parse: function(params) {
                        return _defaultParseRequestParams(params, _mapToValues(this.selectedItems), fieldName || 'brand.id');
                    }
                };
            }

            /**
             * Returns a supervisions filters tool
             * @public
             *
             * @param {Promise} filtersPromise
             * @param {string} [fieldName]
             *
             * @return {MobileFilterTool}
             */
            function getSupervisionsFilter(filtersPromise, fieldName) {
                return {
                    title: '\'Supervisions\' | translate',
                    name: 'supervisions',
                    orderItemsBy: 'names | name',
                    itemsPromise: filtersPromise.then(function(res) {
                        return res.supervisions.map(function(supervisionValue) {
                            return {
                                value: supervisionValue.id,
                                names: supervisionValue.names,
                                title: 'names | name',
                                count: supervisionValue.count
                            };
                        });
                    }),
                    parse: function(params) {
                        return _defaultParseRequestParams(params, _mapToValues(this.selectedItems), fieldName || 'supervisions.id');
                    }
                };
            }

            /**
             * Returns a supervisions filters tool
             * @public
             *
             * @param {Promise} filtersPromise
             * @param {string} [fieldName]
             *
             * @return {MobileFilterTool}
             */
            function getTypesFilter(filtersPromise, fieldName) {
                return {
                    title: '\'Types\' | translate',
                    name: 'types',
                    orderItemsBy: 'names | name',
                    itemsPromise: filtersPromise.then(function(res) {
                        return res.tags.map(function(typesValue) {
                            var names = {};

                            angular.forEach(Object.values(Config.languages), function (language) {
                                names[language.id] = language.translations[typesValue.name.toLowerCase()] || typesValue.name;
                            });

                            return {
                                value: typesValue.id,
                                names: names,
                                title: 'names | name',
                                count: typesValue.count
                            };
                        });
                    }),
                    parse: function(params) {
                        return _defaultParseRequestParams(params, _mapToValues(this.selectedItems), fieldName || 'types.id');
                    }
                };
            }

            function getTypesFilterPlain(data) {
                return data.tags.map(function(typesValue) {
                            var names = {};

                            angular.forEach(Object.values(Config.languages), function (language) {
                                names[language.id] = language.translations[typesValue.name.toLowerCase()] || typesValue.name;
                            });

                            return {
                                value: typesValue.id,
                                names: names,
                                title: 'names | name',
                                count: typesValue.count
                            };
                });
            }

            function getCategoriesFilterPlain(data) {
                return data.categories.map(function (categoryValue) {
                            return {
                                value: categoryValue.id,
                                names: categoryValue.names,
                                title: 'names | name',
                                count: categoryValue.count
                            };
                });
            }

            /**
             * Returns a categories filters tool
             * @public
             *
             * @param {Promise} filtersPromise
             * @param {string} fieldName
             *
             * @return {MobileFilterTool}
             */
            function getCategoriesFilter(filtersPromise, fieldName) {
                return {
                    title: '\'Category\' | translate',
                    name: 'category',
                    orderItemsBy: 'names | name',
                    itemsPromise: filtersPromise.then(function(res) {
                        return res.categories.map(function (categoryValue) {
                            return {
                                value: categoryValue.id,
                                names: categoryValue.names,
                                title: 'names | name',
                                count: categoryValue.count
                            };
                        });
                    }),
                    parse: function(params) {
                        return _defaultParseRequestParams(params, _mapToValues(this.selectedItems), fieldName || 'family.categoriesPaths.id');
                    }
                };
            }

            /**
             * Returns a product tags filters tool
             * @public
             *
             * @param {Promise} filtersPromise
             * @param {Object} $scope
             * @param {boolean} isSpecials
             * @param {boolean} [withCount]
             *
             * @return {MobileFilterTool}
             */
            function getProductTagsFilter(filtersPromise, $scope, isSpecials, withCount) {
                var filter = {
                    title: '\'Diet type\' | translate',
                    name: 'productTag',
                    orderItemsBy: '(value | productTag).languages | name:\'displayName\'',
                    notInclusive: true,
                    itemsPromise: filtersPromise.then(function(res) {
                        return res.productTags.map(function(productTagValue) {
                            return {
                                value: productTagValue.id,
                                title: '(value | productTag).languages | name:\'displayName\'',
                                icon: '(value | productTag).languages | name:\'iconResourceUrl\'',
                                count: withCount ? productTagValue.count : undefined
                            };
                        });
                    }),
                    parse: function(params) {
                        return _parseProductTags(params, _mapToValues(this.selectedItems), isSpecials);
                    }
                };

                filter.initPromise = Config.waitForInit().then(function() {
                    filter.permanentFiltersType = Config.retailer.isPermanentViewFiltersActive ? PermanentFilters.TYPES.PRODUCT_TAGS : undefined;
                    filter.title = '($root.config.retailer.permanentViewFiltersTexts | name:\'sideFiltersTitle\') || (' + filter.title + ')';

                    _listenForPermanentFiltersChanges(filter, $scope);
                });

                return filter;
            }

            /**
             * Map an array of filter items to an array of its values
             * @private
             *
             * @param {Array<MobileFiltersItem>} items
             *
             * @return {void|Array<number>}
             */
            function _mapToValues(items) {
                if (!items) {
                    return;
                }

                return items.map(function(item) {
                    return item.value;
                });
            }

            /**
             * Parse filter values into request params
             * @private
             *
             * @param {Object} params
             * @param {Array<number>} filterValues
             * @param {string} fieldName
             */
            function _defaultParseRequestParams(params, filterValues, fieldName) {
                //if has no values to send, do nothing
                if (!filterValues || !filterValues.length) {
                    return;
                }

                params.filters = params.filters || {};
                params.filters.must = params.filters.must || {};
                params.filters.must.term = params.filters.must.term || {};
                params.filters.must.term[fieldName] = filterValues;
            }

            /**
             * Parse permanent product tags filters into the given request params
             * @public
             *
             * @param {Object} params
             * @param {boolean} isSpecials
             *
             * @returns {Promise}
             */
            function parsePermanentProductTags(params, isSpecials) {
                return Config.waitForInit().then(function() {
                    if (!Config.retailer.isPermanentViewFiltersActive) {
                        return;
                    }

                    _parseProductTags(params, PermanentFilters.values[PermanentFilters.TYPES.PRODUCT_TAGS], isSpecials);
                });
            }

            /**
             * Parse product tags values to request params filters
             * @private
             *
             * @param {Object} params
             * @param {Array<number>} values
             * @param {boolean} isSpecials
             */
            function _parseProductTags(params, values, isSpecials) {
                if (values && values.length) {
                    params.filters = params.filters || {};
                    params.filters.must = params.filters.must || {};

                    params.filters.must.filters = params.filters.must.filters || [];
                    angular.forEach(values, function(value) {
                        var term = {},
                            notTerm = {};
                        if (isSpecials) {
                            term['branch.productsData.productTags.id'] = value;
                            notTerm['branch.productsData.viewFilterProductTags'] = value;
                        } else {
                            term['productTags'] = value;
                            term['globalProductTags'] = value;
                            notTerm['viewFilterProductTags'] = value;
                        }
                        params.filters.must.filters.push({
                            should: {
                                term: term,
                                filters: [{
                                    mustNot: {
                                        term: notTerm
                                    }
                                }]
                            }
                        });
                    });
                }
            }

            /**
             * Will subscribe for permanent filters changes and set them into the given filter tool
             * @private
             *
             * @param {MobileFilterTool} filter
             * @param {Object} $scope
             */
            function _listenForPermanentFiltersChanges(filter, $scope) {
                if (!filter.permanentFiltersType) {
                    return;
                }

                PermanentFilters.subscribe(function() {
                    var oldValue = _mapToValues(filter.selectedItems),
                        permanentValues = PermanentFilters.values[filter.permanentFiltersType];
                    if (!_comparePermanentValues(oldValue, permanentValues)) {
                        filter.selectedItems = permanentValues.map(function(value) {
                            return {value: value};
                        });
                        return filter.resetValues(true);
                    }
                }, $scope);
            }

            /**
             * Compare permanent values array with given filter values array
             * @private
             *
             * @param {Array<number>} filterValues
             * @param {Array<number>} permanentValues
             *
             * @return {boolean}
             */
            function _comparePermanentValues(filterValues, permanentValues) {
                if ((filterValues || []).length !== (permanentValues || []).length) {
                    return false;
                }

                var valuesMap = {};
                angular.forEach(filterValues, function(value) {
                    valuesMap[value] = true;
                });
                for (var i = 0; i < permanentValues.length; i++) {
                    if (!valuesMap[permanentValues[i]]) {
                        return false;
                    }
                }
                return true;
            }

            function getProductTagsSortBoost(productTagsFilter) {
                var values = (productTagsFilter.selectedItems || []).map(function(item) {
                    return { id: item.value };
                });
                if (!values.length) {
                    return;
                }

                return {
                    sortType: SEARCH_SORT_BOOST_TYPES.PRODUCT_TAGS,
                    topPriority: JSON.stringify(values)
                };
            }

            function getProductTagName(id, languageId){
                var name ;
                var tagFilter = _productTagFilter(id);
                if (tagFilter) {
                    name = tagFilter.languages[languageId].displayName;
                }
                return name;
            }
        }]);
})(angular);
