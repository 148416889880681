angular.module('mobilezuz')
    .factory('BarcodeScaner', [ '$q', '$window', 'CordovaService', 'DialogAvailability', 'mDesign', '$rootScope', '$filter', 'Quagga', '$log','Retailer','Config',
        function($q, $window, CordovaService, DialogAvailability, mDesign, $rootScope, $filter, quagga, $log, Retailer, Config) {
            var barcodeScaner = {};

            barcodeScaner.scan = function() {
                if ($window.cordova !== undefined) {
                    var defer = $q.defer();

                    $rootScope.preventHistoryBack = true;
                    CordovaService.ready.then(function () {
                        DialogAvailability.requestDialog(DialogAvailability.PERMISSIONS.CAMERA)
                            .then(function(allow) {
                                $window.cloudSky.zBar.scan({
                                    text_title: ($filter('translate')('Scan barcode')), // Android only
                                    text_instructions: ($filter('translate')('Please point your camera at the barcode.')), // Android only
                                    drawSight: true
                                }, function (barcode) {
                                    $rootScope.preventHistoryBack = false;
                                    defer.resolve(barcode);
                                }, function (error) {
                                    $rootScope.preventHistoryBack = false;
                                    if (error !== 'cancelled') {
                                        if(error == 'notHasPermission'){
                                          return showDenyPermissionDialog();
                                        }
                                        mDesign.alert($filter('translate')("Scanning failed: " )+ error);
                                    }
                                }.bind(this));
                            });
                    });

                    return defer.promise;
                } else {
                    $log.log('Cordova not defined!');
                    /*defer.resolve(window.prompt('enter a barcode'));*/
                    return quagga.scan();
                }
            };

            function showDenyPermissionDialog(){
                var defaulCameraPermissionPopupSettings = {
                    title: {
                        en: 'Camera Access Required',
                        he: 'יש לאפשר גישה למצלמה'
                    },
                    text: {
                        en: 'To Scan products and add them to your cart, please allow camera permission.',
                        he: 'כדי לסרוק מוצרים ולהוסיף אותם לעגלה, יש לתת הרשאת גישה למצלמה.'
                    },
                    allowBtn: {
                        en: 'ALLOW',
                        he: 'לאפשר'
                    },
                    notAllowBtn: {
                        en: 'DON\'T ALLOW',
                        he: 'לא לאפשר'
                    }
                };
                Retailer.getCameraPermissionPopupSettings().then(function(data){
                    $rootScope.CameraPermissionPopup = data.CameraPermissionPopup;
                    return data.CameraPermissionPopup;
                })
                .catch(function(){
                    return defaulCameraPermissionPopupSettings;
                })
                .then(function(CameraPermissionPopupSettings){
                    mDesign.dialog({
                        focusOnOpen: false,
                        clickOutsideToClose: false,
                        templateUrl: 'views/templates/camera-permission-dialog.html',
                        controller: ['$scope', function ($scope) {
                             $scope.allow = allow;
                             $scope.notAllow = notAllow;
                             $scope.title = (CameraPermissionPopupSettings && CameraPermissionPopupSettings.title && CameraPermissionPopupSettings.title[Config.language.culture]) || defaulCameraPermissionPopupSettings.title[Config.language.culture];
                             $scope.text = (CameraPermissionPopupSettings && CameraPermissionPopupSettings.text && CameraPermissionPopupSettings.text[Config.language.culture]) || defaulCameraPermissionPopupSettings.text[Config.language.culture];
                             $scope.allowButtonText = (CameraPermissionPopupSettings && CameraPermissionPopupSettings.allowButtonText && CameraPermissionPopupSettings.allowButtonText[Config.language.culture]) || defaulCameraPermissionPopupSettings.allowBtn[Config.language.culture];
                             $scope.notAllowButtonText = (CameraPermissionPopupSettings && CameraPermissionPopupSettings.notAllowButtonText && CameraPermissionPopupSettings.notAllowButtonText[Config.language.culture]) || defaulCameraPermissionPopupSettings.notAllowBtn[Config.language.culture];

                             function allow() {
                                if(window.cordova && cordova.plugins && cordova.plugins.diagnostic){
                                    cordova.plugins.diagnostic.switchToSettings();
                                }
                                mDesign.hide();
                             }

                             function notAllow() {
                                mDesign.hide();
                             }
                        }]
                    });
                });
            }

            return barcodeScaner;
        }]);
