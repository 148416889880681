(function (angular, app) {
    "use strict";
    angular.module('mobilezuz')
        .directive('ellipsis', ['$timeout', function () {
            return {
                restrict: 'A',
                scope: {
                    text: '@ellipsis',
                    isOff: '<turnEllipsisOff'
                },
                link: function($scope, $element) {
                    function _setTextToElement() {
                        var element = $element[0],
                            text = $scope.text;
                        $element.html(text || '');
                        var startTime = performance.now();

                        if($scope.isOff) return;

                        while (element.clientHeight < (element.scrollHeight - 1)) {
                            if ((text || '').split(' ').length <= 1 || performance.now() - startTime > 500) {
                                break;
                            }
                            text = text.trim().substring(0, text.length - 1);
                            $element.html(text.trim() + '...');
                        }
                    }

                    setTimeout(_setTextToElement, 50);

                    $scope.$watch('text', function (newVal, oldVal) {
                        if (oldVal === newVal) return;
                        _setTextToElement();
                    });

                    $scope.$watch('isOff', function () {
                        if($scope.isOff) {
                            _setTextToElement();//== if an element opened - we remove overflow and updating the text
                        } else {
                            setTimeout(_setTextToElement, 100); //== if element closed we need to wait until style applied and only after it we can calculate the height of element
                        }
                    });
                }
            };
        }]);
})(angular);
