angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
    $stateProvider.state('app.cart.checkout', {
        url: '/checkout',
        data: {
            stateAccess: PAGE_ACCESS.USER_VERIFIED,
            bodyClass: 'checkout',
            metaTags: {
                title: 'Checkout'
            }
        },
        views: {
            'checkout': {
                templateUrl: 'views/checkout/index.html',
                controller: 'CheckoutCtrl as checkoutCtrl',
                resolve: {
                    validateLoyaltyExpirationDate: ['Util', function(Util) {
                        return Util.validateLoyaltyExpirationDate();
                    }]
                }
            }
        }
    });
}]).controller('CheckoutCtrl', [
    '$scope', '$rootScope', '$q', '$state', 'Config', 'SpDeliveryTimesService', 'Util', 'Cart', 'CHECKOUT_METHODS', '$timeout',
    function($scope, $rootScope, $q, $state, Config, SpDeliveryTimesService, Util, Cart, CHECKOUT_METHODS, $timeout) {
        var checkoutCtrl = this,
            cartCtrl = $scope.cartCtrl;

        cartCtrl.showCheckoutView = true;

        checkoutCtrl.exit = exit;

        SpDeliveryTimesService.init({branchId: Config.branch.id}).then(function(deliveryMethods) {
            if ($rootScope.intiDialogClosed) return deliveryMethods;

            var defer = $q.defer(),
                listener = $rootScope.$on('initDialog.closed', function() {
                    listener();
                    defer.resolve(deliveryMethods);
                });
            return defer.promise;
        }).then(function(deliveryMethods) {
            var keys = Object.keys(deliveryMethods);
            checkoutCtrl.hasBeforeState = keys.length !== 1;
            if ($state.current.name === 'app.cart.checkout') {
                
                if (keys.length === 1 || Config.checkoutData && Config.checkoutData.method) {
                    $state.go('app.cart.checkout.process', {
                        method: (Config.checkoutData || {}).method || CHECKOUT_METHODS.BY_DELIVERY_TYPE[keys[0]]
                    });
                } else {
                    $state.go('app.cart.checkout.before');
                }
            }
        });

        $timeout(function(){
            // cheat cause it have child view so ui dont change until load child view
            $rootScope.$emit('dialogUrlManager.shiftEventPromise');
        }, 100);
        

        function exit() {
            if (checkoutCtrl.isPickAndGo){
                checkoutCtrl.orderService = null;
                return $state.go('app.pickAngGo');
            }

            $state.go('app.cart');
        }
    }
]);