(function(angular) {
	angular.module('mobilezuz')
		.config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
			$stateProvider.state('app.retailer.contact-details', {
				url: '/contact-details',
				data: {
					stateAccess: PAGE_ACCESS.ALLOW,
					bodyClass: 'info-text',
					metaTags: {
						title: 'Contact details'
					}
				},
				views: {
					'@': {
						templateUrl: 'views/bind-html.html',
						controller: 'ContactDetailsCtrl'
					}
				}
			});
		}]).controller('ContactDetailsCtrl', ['$rootScope', '$scope', 'Retailer',
		function($rootScope, $scope, Retailer) {
			$rootScope.title = 'Contact details';
			Retailer.getRetailerSettings().then(function(response) {
				$scope.content = response.settings.contactUsText;
			});
		}]);
})(angular);