(function (angular) {
    angular.module('mobilezuz')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.cart.order-update.ebt-summary', {
                url: '/ebt-summary',
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return ($filter('translate')('Summary Details'));
                    }]
                },
                views: {
                    updateOrder: {
                        templateUrl: 'views/order/update/ebt-summary.html',
                        controller: 'EBTSummaryCtrl as ebtSummaryCtrl',
                    }
                }
            });
        }])
        .controller('EBTSummaryCtrl',
            ['$q', '$scope', '$rootScope', '$state',
                function ($q, $scope, $rootScope, $state) {

                    var updateOrderCtrl = $scope.updateOrderCtrl;
                    updateOrderCtrl.back = function () {
                        $state.go('app.cart.order-update.ebt-payment');
                    }
                    updateOrderCtrl.forward = function () {
                        updateOrderCtrl.onConfirm(true);
                    }
                    var ebtSummaryCtrl = this;

                    init();

                    function init() {
                        if (!updateOrderCtrl.paymentsData) return updateOrderCtrl.back();

                        ebtSummaryCtrl.summary = updateOrderCtrl.paymentsData.summary;
                        ebtSummaryCtrl.ebtDividerAmounts = updateOrderCtrl.paymentsData.ebtDividerAmounts;
                        ebtSummaryCtrl.dividedCart = updateOrderCtrl.paymentsData.dividedCart;
                        ebtSummaryCtrl.ebtValues = updateOrderCtrl.paymentsData.ebtValues;
                        ebtSummaryCtrl.ebtInfo = updateOrderCtrl.paymentsData.ebtInfo;

                        updateOrderCtrl.confirmButton = 'CONFIRM UPDATE';
                    }
                }]);
})(angular);