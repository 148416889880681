angular.module('mobilezuz').controller('ProductSuggestionsCtrl', ['$scope', 'mDesign', 'product', '$rootScope',  '$timeout',
    function ($scope, mDesign, product, $rootScope, $timeout) {
        var productSuggestionsCtrl = this;

        _init();
        product.showOutOfStock = true;
        productSuggestionsCtrl.dialog = mDesign;
        productSuggestionsCtrl.product = product;
        productSuggestionsCtrl.products = [product];
        productSuggestionsCtrl.hide = mDesign.hide;
        productSuggestionsCtrl.isFromRecommendationPopup = true

        function _init() {
            angular.forEach(product._suggestions, function(suggestion) {
                suggestion.oosProduct = product;
            });

            if (!$rootScope.$$phase) {
                $scope.$apply();
            } else {
                $timeout(function() {
                    !$rootScope.$$phase && $scope.$apply();
                }, 300);
            }
        }
    }]);