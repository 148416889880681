(function(angular) {
	angular.module('mobilezuz')
		.config(['$stateProvider', 'PAGE_ACCESS', function($stateProvider, PAGE_ACCESS) {
			$stateProvider.state('app.about', {
				url: '/about',
				data: {
					stateAccess: PAGE_ACCESS.ALLOW,
					bodyClass: 'info-text',
					metaTags: {
						title: 'About us'
					}
				},
				views: {
					'@': {
						templateUrl: 'views/bind-html.html',
						controller: 'AboutCtrl'
					}
				}
			});
		}]).controller('AboutCtrl', ['$rootScope', '$scope', 'Retailer',
		function($rootScope, $scope, Retailer) {

			$rootScope.title = 'About us';
			Retailer.getRetailerSettings().then(function(response) {
				$scope.content = response.settings.aboutUsText;
			});
		}]);
})(angular);