(function(angular) {
    angular.module('mobilezuz')
        .config(['$stateProvider', function($stateProvider) {
            $stateProvider.state('app.externalCheckoutFinish', {
                url: '/external-checkout-finish?externalPaymentKey',
                data: {
                    bodyClass: 'external-checkout-finish'
                },
                views: {
                    '@': {
                        templateUrl: 'views/external-checkout-finish.html',
                        controller: 'ExternalCheckoutFinishCtrl as externalFinishCtrl'
                    }
                }
            });
        }])
        .controller('ExternalCheckoutFinishCtrl', [
            '$scope', '$rootScope', '$stateParams', '$state', '$q', '$timeout', '$window', 'ExternalCheckout',
            'Retailer', 'User', 'Config', 'Util', 'Cart', 'SpDeliveryTimesService', 'Loading', 'Orders',
            'DELIVERY_TYPES', 'CHECKOUT_METHODS', 'SP_PAYMENTS', 'DELIVERY_CHOICES', 'DELIVERY_TIMES_TYPES',
            function($scope, $rootScope, $stateParams, $state, $q, $timeout, $window, ExternalCheckout,
                     Retailer, User, Config, Util, Cart, SpDeliveryTimesService, Loading, Orders,
                     DELIVERY_TYPES, CHECKOUT_METHODS, SP_PAYMENTS, DELIVERY_CHOICES, DELIVERY_TIMES_TYPES) {
                var externalFinishCtrl = this,
                    _checkoutDataDeleted = false,
                    _loadingIncreased = true;

                externalFinishCtrl.orderId = $stateParams.orderId;
                externalFinishCtrl.continueCheckout = continueCheckout;
                externalFinishCtrl.deleteCheckoutData = deleteCheckoutData;

                externalFinishCtrl.initiated = false;

                _init();

                $scope.$on('$destroy', function() {
                    if (_loadingIncreased) {
                        Loading.counter(null, -1);
                    }
                });

                function _init() {
                    // timeout to allow the $location.path to change,
                    // to avoid disturbing the Loading every once in a while
                    return $timeout(angular.noop, 100).then(function() {
                        Loading.counter(null, 1);
                        _loadingIncreased = true;
                        return Config.waitForInit();
                    }).then(function() {
                        return $q.all([
                            _initRetailer(),
                            _initUser(),
                            _initCheckoutData()
                        ]);
                    }).then(function() {
                        _compareResponseUser();

                        return _initCanResumeCheckout();
                    }).then(function() {
                        _handleAnalyticsEvents();
                    }).finally(function() {
                        _loadingIncreased = false;
                        Loading.counter(null, -1);
                        externalFinishCtrl.initiated = true;
                    });
                }

                function _initRetailer() {
                    return Retailer.getRetailerSettings().then(function(retailer) {
                        externalFinishCtrl.retailer = retailer;
                    });
                }

                function _initUser() {
                    if (!Config.branch) {
                        return;
                    }

                    // when no checkout key, the transition was locally when detecting checkout response,
                    // that means the user did not get here by a redirect from the server,
                    // so the user data should be refreshed after checkout
                    return User.getUserSettings(!$stateParams.externalPaymentKey).then(function(user) {
                        externalFinishCtrl.user = user;
                    }).catch(function(err) {
                        // no user, do not throw
                    });
                }

                function _initCheckoutData() {
                    return ExternalCheckout.getCheckoutData($stateParams.externalPaymentKey).then(function(data) {
                        externalFinishCtrl.externalPaymentKey = data.externalPaymentKey;
                        externalFinishCtrl.checkoutData = data.checkoutData;

                        if (!data.checkoutData) {
                            ExternalCheckout.exitCheckoutProgress();
                        }
                    });
                }

                function _initCanResumeCheckout() {
                    var canResumeCheckout = !!(Config.branch && Config.branchAreaId &&
                        externalFinishCtrl.user && externalFinishCtrl.user.id && externalFinishCtrl.checkoutData &&
                        externalFinishCtrl.checkoutData.request && externalFinishCtrl.checkoutData.request.options &&
                        externalFinishCtrl.checkoutData.request.options.branchId === Config.branch.id &&
                        (!externalFinishCtrl.checkoutData.response || !externalFinishCtrl.checkoutData.response.orderId));

                    if (!canResumeCheckout) {
                        externalFinishCtrl.canResumeCheckout = canResumeCheckout;
                        return;
                    }

                    return Cart.save().then(function() {
                        externalFinishCtrl.canResumeCheckout = externalFinishCtrl.checkoutData.request.options.cartId === Cart.serverCartId;
                    }).catch(function() {
                        externalFinishCtrl.canResumeCheckout = false;
                    });
                }

                function _compareResponseUser() {
                    if (!externalFinishCtrl.user || !externalFinishCtrl.checkoutData ||
                        !externalFinishCtrl.checkoutData.response || !externalFinishCtrl.checkoutData.response.orderId ||
                        !externalFinishCtrl.checkoutData.response.userId) {
                        return;
                    }

                    // when the logged in user is not the order user, delete it
                    if (externalFinishCtrl.checkoutData.response.userId !== externalFinishCtrl.user.id) {
                        delete externalFinishCtrl.user;
                    }
                }

                function _handleAnalyticsEvents() {
                    if (!externalFinishCtrl.checkoutData || !externalFinishCtrl.checkoutData.response ||
                        !externalFinishCtrl.checkoutData.response.orderId) {
                        return;
                    }

                    return $timeout(angular.noop, 1000).then(function() {
                        if (!externalFinishCtrl.user || !Config.branch) {
                            return;
                        }

                        return Orders.getOrderDetails(externalFinishCtrl.checkoutData.response.orderId);
                    }).then(function(order) {
                        $rootScope.$emit('checkout', order || {
                            id: externalFinishCtrl.checkoutData.response.orderId,
                            lines: [],
                            totalAmount: 0
                        });
                        Cart.save();
                        $window.localStorage.removeItem('mobileZuzTypedAreaAddressComponents');
                        delete Config.checkoutData;
                    });
                }

                function deleteCheckoutData() {
                    if (!externalFinishCtrl.initiated || _checkoutDataDeleted) {
                        return;
                    }

                    _checkoutDataDeleted = true;
                    ExternalCheckout.deleteCheckoutData(externalFinishCtrl.externalPaymentKey);
                }

                function continueCheckout() {
                    var localCheckoutData = _getBaseLocalCheckoutData();

                    return SpDeliveryTimesService.getTimes([DELIVERY_TYPES.DELIVERY, DELIVERY_TYPES.PICKUP, DELIVERY_TYPES.EXPRESS_DELIVERY], {
                        areaId: externalFinishCtrl.checkoutData.request.options.areaId,
                        cartId: Cart.serverCartId,
                        startDate: _getDeliveryTimesStartDate()
                    }).then(function(resp) {
                        localCheckoutData.method = CHECKOUT_METHODS.BY_DELIVERY_TYPE[resp.area.deliveryTypeId];
                        localCheckoutData.timesDetails = _getLocalCheckoutTimesDetails(resp);

                        if (localCheckoutData.timesDetails.deliveryChoice === DELIVERY_CHOICES.SHIPPING && localCheckoutData.timesDetails.deliveryDay ||
                            localCheckoutData.timesDetails.deliveryChoice === DELIVERY_CHOICES.LOCAL_DELIVERY && localCheckoutData.timesDetails.deliveryTime) {
                            localCheckoutData.state = 'app.cart.checkout.process.payment';
                        } else {
                            localCheckoutData.state = 'app.cart.checkout.process.times';
                        }

                        Config.checkoutData = localCheckoutData;
                        $state.go('app.cart.checkout');
                        deleteCheckoutData();
                    });
                }

                function _getDeliveryTimesStartDate() {
                    var date = new Date(externalFinishCtrl.checkoutData.request.options.deliveryTimeDate || (new Date())),
                        today = new Date();
                    date.setUTCHours(0, 0, 0, 0);
                    today.setUTCHours(0, 0, 0, 0);

                    while (date.getUTCDay() !== 0 && date > today) {
                        date.setUTCDate(date.getUTCDate() - 1);
                    }

                    return date
                }

                /**
                 * This is the equivalent of Config.checkoutData (not the cached checkout data - externalFinishCtrl.checkoutData)
                 * @private
                 *
                 * @returns {Object}
                 */
                function _getBaseLocalCheckoutData() {
                    var requestOptions = externalFinishCtrl.checkoutData.request.options;

                    // for now external checkout is only used for bit, which can only be used as a single payment
                    // TODO need to add support for multi payments
                    var payment = requestOptions.payments && requestOptions.payments[0] || requestOptions.payment;

                    var localCheckoutData = {
                        substitutePreference: requestOptions.substitutePreferences,
                        addressDetails: {
                            phone: requestOptions.phoneNumber,
                            friendlyName: requestOptions.friendlyName,
                            geoCoordinates: requestOptions.geoCoordinates,
                            externalPlaceId: requestOptions.externalPlaceId
                        },
                        paymentDetails: {
                            paymentMethod: SP_PAYMENTS.PAYMENT_METHODS.NAME_BY_ID[payment.methodId],
                            paymentsNumber: payment.paymentsNumber,
                            cvv: payment.cvv,
                            authNum: payment.authNum,
                            invitePromotion: requestOptions.invitePromotion
                        }
                    };

                    if (requestOptions.address) {
                        localCheckoutData.addressDetails.addressId = requestOptions.address.id;
                        localCheckoutData.addressDetails.text1 = requestOptions.address.text1;
                        localCheckoutData.addressDetails.text2 = requestOptions.address.text2;
                        localCheckoutData.addressDetails.street = requestOptions.address.street;
                        localCheckoutData.addressDetails.houseNumber = requestOptions.address.houseNumber;
                        localCheckoutData.addressDetails.entry = requestOptions.address.entry;
                        localCheckoutData.addressDetails.floor = requestOptions.address.floor;
                        localCheckoutData.addressDetails.apartment = requestOptions.address.apartment;
                        localCheckoutData.addressDetails.city = requestOptions.address.city;
                        localCheckoutData.addressDetails.zipCode = requestOptions.address.zipCode;
                    }

                    if (payment.creditCardId) {
                        localCheckoutData.paymentDetails.creditCard = {
                            id: payment.creditCardId,
                            isLoyalty: payment.isLoyalty
                        };
                    }

                    if (payment.billingAgreement) {
                        localCheckoutData.paymentDetails.paypalAccount = {
                            billingAgreement: payment.billingAgreement
                        };
                    }

                    if (payment.token) {
                        if (localCheckoutData.paymentDetails.paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.STORE_CREDIT) {
                            localCheckoutData.paymentDetails.storeCredit = payment.token;
                        } else {
                            localCheckoutData.paymentDetails.paymentToken = payment.token;
                        }
                    }

                    return localCheckoutData;
                }

                function _getLocalCheckoutTimesDetails(areaResp) {
                    var timesDetails = {
                        branchArea: areaResp,
                        comments: externalFinishCtrl.checkoutData.request.options.comments
                    };

                    if (externalFinishCtrl.checkoutData.request.options.shippingCarrierCode || externalFinishCtrl.checkoutData.request.options.shippingServiceCode) {
                        timesDetails.deliveryChoice = DELIVERY_CHOICES.SHIPPING;
                        angular.forEach(areaResp.times[DELIVERY_CHOICES.SHIPPING] || [], function(deliveryDay) {
                            if (deliveryDay.carrierCode === externalFinishCtrl.checkoutData.request.options.shippingCarrierCode &&
                                deliveryDay.serviceCode === externalFinishCtrl.checkoutData.request.options.shippingServiceCode) {
                                timesDetails.deliveryDay = deliveryDay;
                            }
                        });
                    } else {
                        timesDetails.deliveryChoice = DELIVERY_CHOICES.LOCAL_DELIVERY;
                        timesDetails.asap = externalFinishCtrl.checkoutData.request.options.asapPreference;
                        var days = [],
                            expressDeliveryTime,
                            withinDaysDeliveryTime;
                        if (areaResp.times[DELIVERY_CHOICES.LOCAL_DELIVERY][DELIVERY_TIMES_TYPES.REGULAR]) {
                            Array.prototype.push.apply(days, areaResp.times[DELIVERY_CHOICES.LOCAL_DELIVERY][DELIVERY_TIMES_TYPES.REGULAR]);
                        }
                        if (areaResp.times[DELIVERY_CHOICES.LOCAL_DELIVERY][DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_HOURS]) {
                            expressDeliveryTime = areaResp.times[DELIVERY_CHOICES.LOCAL_DELIVERY][DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_HOURS];
                        }
                        if (areaResp.times[DELIVERY_CHOICES.LOCAL_DELIVERY][DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_DAYS]) {
                            withinDaysDeliveryTime = areaResp.times[DELIVERY_CHOICES.LOCAL_DELIVERY][DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_DAYS];
                        }
                        angular.forEach(days, function(deliveryDay) {
                            if (deliveryDay.isToday && expressDeliveryTime &&
                                externalFinishCtrl.checkoutData.request.options.deliveryTimeId === expressDeliveryTime.id) {
                                timesDetails.deliveryTime = expressDeliveryTime;
                                timesDetails.deliveryDay = deliveryDay;
                                return;
                            }

                            if (deliveryDay.isToday && withinDaysDeliveryTime &&
                                externalFinishCtrl.checkoutData.request.options.deliveryTimeId === withinDaysDeliveryTime.id) {
                                timesDetails.deliveryTime = withinDaysDeliveryTime;
                                timesDetails.deliveryDay = deliveryDay;
                                return;
                            }

                            angular.forEach(deliveryDay.times, function(deliveryTime) {
                                if (deliveryTime.id === externalFinishCtrl.checkoutData.request.options.deliveryTimeId) {
                                    timesDetails.deliveryTime = deliveryTime;
                                    timesDetails.deliveryDay = deliveryDay;
                                }
                            });
                        });
                    }

                    return timesDetails;
                }
            }
        ]);
})(angular);