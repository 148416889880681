angular.module('mobilezuz').directive('spFocusin', [ function () {
    return {
        restrict: 'A',
        scope: {
            focusin: '=spFocusin'
        },
        link: function ($scope, $element) {
            $element.bind('focusin', onFocusin);
            $element.bind('focusout', onFocusout);

            function onFocusin() {
                $scope.focusin = true;
            }

            function onFocusout() {
                $scope.focusin = false;
            }
        }
    };
}]);