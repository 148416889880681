(function (angular) {
    'use strict';
    angular.module('mobilezuz').directive('spSaleIcon', [
        '$rootScope', '$filter', 'Config', 'Util', 'PROMOTION_TYPES',
        function ($rootScope, $filter, Config, Util, PROMOTION_TYPES) {
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    special: '=?',
                    product: '=?',
                    productSpecial: '=?', // Single special of product
                    withoutActions: '@?'
                },
                templateUrl: 'views/templates/sp-sale-icon.html',
                controller: ['$scope', '$rootScope', '$filter', 'SpecialDetailsDialog', 'Cart', function ($scope, $rootScope, $filter, SpecialDetailsDialog, Cart) {
                    var _translateImageFilter = $filter('translateImage'),
                        nameFilter = $filter('name');

                    $scope.showSpecialDetails = showSpecialDetails;

                    function showSpecialDetails(event) {
                        event.stopPropagation();
                        var showProductsFrom = Cart.specialReminders.onProducts &&
                            Cart.specialReminders.onProducts[$scope.specialToShow.id] &&
                            Cart.specialReminders.onProducts[$scope.specialToShow.id].showProductsFrom;
                        return SpecialDetailsDialog.show($scope.specialToShow.id, showProductsFrom);
                    }

                    $scope.saleIconMethods = {
                        FIXED_SALE: {
                            id: 1,
                            class: 'fixed-sale',
                            image: _translateImageFilter('#sp-icons-sale%translate%', { he: '-he' })
                        },
                        DISCOUNT: {
                            id: 2,
                            class: 'discount-sale',
                            image: '#sp-icons-discount-sale'
                        },
                        BUY_X_FOR_Y: {
                            id: 3,
                            class: 'buy-x-sale',
                            image: '#sp-icons-background-sale'
                        },
                        OVER_AMOUNT: {
                            id: 4,
                            class: 'over-amount-sale',
                            image: '#sp-icons-gift-sale'
                        },
                        BUY_X_GET_ONE: {
                            id: 5,
                            class: 'over-amount-sale',
                            image: '#sp-icons-gift-sale'/*,
                            class: 'buy-x-get-one-sale',
                            image: '#sp-icons-background-sale'*/
                        }
                    };

                    if ($scope.product && $scope.product.branch.specials && $scope.product.branch.specials.length) {

                        var highPrioritySpecial;
                        for (var i = 0; i < $scope.product.branch.specials.length; i++) {
                            var special = $scope.product.branch.specials[i];
                            if (Config.showCouponsSpecials || !special.isCoupon) {
                                var specialLoyaltyClubsIds = (special.loyaltyClubsIds && special.loyaltyClubsIds.length) || 0,
                                    topPrioritySpecialLoyaltyClubsIds = highPrioritySpecial ? (highPrioritySpecial.loyaltyClubsIds && highPrioritySpecial.loyaltyClubsIds.length) || 0 : 0;

                                if (!highPrioritySpecial || specialLoyaltyClubsIds > topPrioritySpecialLoyaltyClubsIds) {
                                    highPrioritySpecial = special;
                                } else if (topPrioritySpecialLoyaltyClubsIds === 0) {
                                    if (special.firstLevel.type < highPrioritySpecial.firstLevel.type) {
                                        highPrioritySpecial = special;
                                    }
                                }
                            }
                        }

                        $scope.specialToShow = highPrioritySpecial;
                    }

                    $scope.specialToShow = $scope.specialToShow || $scope.productSpecial || $scope.special;

                    if ($scope.specialToShow) {
                        var specialFirstLevel = !!$scope.special ? $scope.specialToShow.levels[0] : $scope.specialToShow.firstLevel;
                        var specialType = specialFirstLevel && specialFirstLevel.type;
                        if (specialType) {
                            var firstPurchaseTotal = !!$scope.special ? specialFirstLevel.purchases[0].total : specialFirstLevel.firstPurchaseTotal,
                                firstGift = !!$scope.special ? specialFirstLevel.gifts[0] : specialFirstLevel.firstGift;
                            switch (specialType) {
                                case PROMOTION_TYPES.BUY_X_GET_Y_IN_PROMOTION:
                                    if (firstGift.total === 0) {
                                        $scope.saleIcon = $scope.saleIconMethods.BUY_X_GET_ONE;
                                        $scope.purchaseTotal = firstPurchaseTotal;
                                    } else {
                                        $scope.saleIcon = $scope.saleIconMethods.FIXED_SALE;
                                    }
                                    break;

                                case PROMOTION_TYPES.BUY_X_IN_Y_PROMOTION:
                                    if (!!($scope.product && $scope.product.salePrice) || (!!$scope.special && !!$scope.special.item && !!$scope.special.item.salePrice) || firstPurchaseTotal <= 1) {
                                        $scope.saleIcon = $scope.saleIconMethods.FIXED_SALE;
                                    } else {
                                        $scope.saleIcon = $scope.saleIconMethods.BUY_X_FOR_Y;
                                        $scope.purchaseTotal = firstPurchaseTotal + ($scope.product && $scope.product.isWeighable && $scope.product.weight && $scope.product.unitOfMeasure ? +' ' + (nameFilter)($scope.product.unitOfMeasure.names) : '');
                                    }
                                    break;

                                case PROMOTION_TYPES.TOTAL_GET_Y_IN_PROMOTION:
                                    $scope.saleIcon = $scope.saleIconMethods.OVER_AMOUNT;
                                    break;

                                case PROMOTION_TYPES.BUY_X_GET_Y_DISCOUNT_PROMOTION:
                                    if (firstGift && firstGift.quantity === 1 && firstGift.total === 1) {
                                        $scope.saleIcon = $scope.saleIconMethods.BUY_X_GET_ONE;
                                        $scope.purchaseTotal = firstPurchaseTotal;
                                    }
                                    break;

                                case PROMOTION_TYPES.DISCOUNT_TOTAL_PROMOTION:
                                case PROMOTION_TYPES.BUY_X_GET_DISCOUNT_PROMOTION:
                                    $scope.saleIcon = $scope.saleIconMethods.DISCOUNT;
                                    break;

                                case PROMOTION_TYPES.BUY_X_GET_FIXED_DISCOUNT_PROMOTION:
                                case PROMOTION_TYPES.FIXED_DISCOUNT_TOTAL_PROMOTION:
                                case PROMOTION_TYPES.BUY_X_GET_Y_FIXED_DISCOUNT_PROMOTION:
                                    $scope.saleIcon = $scope.saleIconMethods.FIXED_SALE;
                                    break;
                                default:
                                    $scope.saleIcon = $scope.saleIconMethods.FIXED_SALE;
                                    break;
                            }
                        }

                        if (!specialType || !$scope.saleIcon) {
                            $scope.saleIcon = $scope.saleIconMethods.FIXED_SALE;
                        }

                        if ($scope.specialToShow.loyaltyClubsIds && $scope.specialToShow.loyaltyClubsIds.length) {
                            $scope.saleIconMethods.FIXED_SALE.image = _translateImageFilter('#sp-icons-club%translate%', { he: '-he' });
                        }
                    }
                }],
                controllerAs: 'sale-icon'
            };
        }]);
})(angular);