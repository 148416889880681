var spFiltersApp = angular.module('spFilters', []);
(function(angular, app) {
	'use strict';

	app.filter('currency', ['$filter', 'spFilters', function($filter, spFilters) {
		var roundCurrencyFilter = $filter('roundCurrency');

		var filter = function(input, separator, decimalsLength) {
			if (input === undefined || input === null) {
				return '';
			}

			input = Number(input);
			if (isNaN(input)) {
				return '';
			}

			var fixedInput = (roundCurrencyFilter(input) || 0).toFixed(decimalsLength || spFilters.$currencyInnerData.decimalsLength);

			// add space as separator by default for KWD
			if (!separator && spFilters.retailer.currencySymbol === 'KWD') {
				separator = ' ';
			}

			// on euro the symbol should be on the right side and it should be separated by comma instead of dot
			if (spFilters.retailer.currencySymbol === '€') {
				return fixedInput.replace('.', ',') + (separator || '') + spFilters.retailer.currencySymbol;
			}

			return spFilters.retailer.currencySymbol + (separator || '') + fixedInput;
		};

		filter.$stateful = true;

		return filter;
	}]);
})(angular, spFiltersApp);
(function (angular, app) {
    'use strict';

    app.filter('image', ['$filter', 'spFilters', function ($filter, spFilters) {
        var _interpolateFilter = $filter('interpolate');

        /**
         * Image filter
         * @public
         *
         * @param {String} url
         * @param {Object|String} [option={size: 'medium'}]
         * @param {String} options.size
         * @param {String} [options.extension]
         *
         * @returns {String}
         */
        return function (url, options) {
            if (options === undefined) {
                options = {size: 'medium'};
            } else if (typeof options == 'string') {
                options = {size: options};
            }

            url = _interpolateFilter(url || spFilters.missingImage, options);

            if (url.indexOf('http') == 0 || url.indexOf('mobilezuz') != -1 || url.indexOf('//') == 0) {
                return url;
            } else if (url.indexOf('/') == 0) {
                return 'https://ecom.blob.core.windows.net' + url;
            } else {
                return 'https://ecom.blob.core.windows.net/' + url;
            }
        };
    }]);
})(angular, spFiltersApp);

(function (angular, app) {
    'use strict';

    app.filter('interpolate', ['$interpolate', function ($interpolate) {
        return function (expression, data, options) {
            options = options || {};
            return $interpolate(expression, !!options.mustHaveExpression, options.trustedContext, !!options.allOrNothing)(data);
        };
    }]);

})(angular, spFiltersApp);
(function (angular, app) {
    'use strict';

    var ENGLISH = {
        id: 2,
        culture: 'en'
    };

    app.filter('name', ['spFilters', function (spFilters) {
        function filter (input, innerProperty) {
            if (!input) {
                return '';
            }

            if (typeof input === 'string') {
                return input;
            }

            // first prioritize the current chosen language
            var languages = [spFilters.name.language.id, spFilters.name.language.culture];

            // then prioritize the retailer's default language
            if (spFilters.name.retailerLanguage) {
                // if default language found - push the language id into languages list
                if (spFilters.name.retailerLanguage.id) {
                    languages.push(spFilters.name.retailerLanguage.id)
                }
                // if default language found - push the language culture into languages list
                if (spFilters.name.retailerLanguage.culture) {
                    languages.push(spFilters.name.retailerLanguage.culture)
                }
            }

            // then prioritize english over other languages existing on the input
            languages.push(ENGLISH.id, ENGLISH.culture);

            // and then all other languages on the input
            Array.prototype.push.apply(languages, Object.keys(input));

            var i,
                result;
            for (i = 0; i < languages.length; i++) {
                result = input[languages[i]];
                if (result && innerProperty) {
                    result = result[innerProperty];
                }
                if (result) {
                    return angular.isArray(result) ? result[0] : result;
                }
            }
        }
        filter.$stateful = true;
        return filter;
    }]);

})(angular, spFiltersApp);

(function (angular, app) {
    'use strict';

    /**
     * @typedef {Object} SPProductImage
     *
     * @property {string} url
     * @property {boolean} isSilent
     */

    app.filter('productImage', ['$filter', 'spFilters', function ($filter, spFilters) {
        var _imageFilter = $filter('image');

        /**
         * Product image filter
         * @public
         *
         * @param {Object} product
         * @param {SPProductImage} product.image
         * @param {Object} options
         * @param {boolean} [showSilent=false]
         *
         * @returns {String}
         */
        return function productImageFilter(product, options, showSilent) {
            return _imageFilter(_isShownImage(product && product.image, showSilent) && product.image.url, options);
        };
    }]);

    app.filter('productImages', ['$filter', 'spFilters', function ($filter, spFilters) {
        /**
         * Product image filter
         * @public
         *
         * @param {Object} product
         * @param {SPProductImage} product.image
         * @param {Array<SPProductImage>} product.images
         * @param {boolean} [showSilent=false]
         *
         * @returns {String}
         */
        return function productImageFilter(product, showSilent) {
            var images = [];

            if (!product) {
                return images;
            }

            var runImages = [product.image];
            Array.prototype.push.apply(runImages, product.images || []);

            angular.forEach(runImages, function(image) {
                if (_isShownImage(image, showSilent)) {
                    images.push(image);
                }
            });

            return images;
        };
    }]);

    /**
     * Returns whether the given image should be shown
     * @private
     *
     * @param {SPProductImage} image
     * @param {boolean} showSilent
     *
     * @return {boolean}
     */
    function _isShownImage(image, showSilent) {
        return !!image && !!image.url && (!!showSilent || !image.isSilent);
    }
})(angular, spFiltersApp);

(function (angular, app) {
    'use strict';

    app.filter('productWeight', ['$filter', function ($filter) {
        var _unitNamesFilter = $filter('unitNames'),
            _nameFilter = $filter('name'),
            _spUnitsFilter;

        try {
            _spUnitsFilter = $filter('spUnits');
        } catch (e) { }

        return function (input, isCase, separator, quantity, soldBy) {
            if (!angular.isObject(input)) return input;
            if (quantity === 0) return quantity;

            var unitOfMeasure = _nameFilter(_unitNamesFilter(input));
            var weight;
            if (soldBy === 'Weight') {
                weight = (quantity || 1) * ((quantity && 1) || input.weight || 0);
            } else {
                weight = (quantity || 1) * (input.weight || (quantity && 1) || 0);
            }
            if (isCase && input.branch && input.branch.case && input.branch.case.items && input.isWeighable) {
                weight = input.branch.case.items;
            } else if (isCase && input.branch && input.branch.case && input.branch.case.items) {
                weight *= input.branch.case.items;
            }

            var prefix = (input.numberOfItems && input.numberOfItems > 1 ? input.numberOfItems + '×' : '');

            if (_spUnitsFilter) {
                var options = {
                    separator: separator,
                    convertInGroup: input.isWeighable && ((soldBy === 'Weight') || (!input.weight))
                };
                return prefix + _spUnitsFilter(weight, unitOfMeasure, options);
            }

            return angular.isDefined(weight) ? prefix + weight + (separator || ' ') + unitOfMeasure : '';
        };
    }]);


})(angular, spFiltersApp);
(function (angular, app) {
    'use strict';

    app.filter('regularPrice', ['spFilters', function(spFilters) {
        return function (product, isCase, showPriceWithoutBottleDeposit) {
            if (!product || !product.branch) return 0;
            var regularPrice = 0;
            if (showPriceWithoutBottleDeposit) {
                regularPrice = isCase ? (product.branch.case.price - (product.branch.case.items * product.branch.linkedProductPrice)) : product.branch.regularPrice - product.branch.linkedProductPrice;
            } else {
                regularPrice = isCase ? product.branch.case.price : product.branch.regularPrice;
            }
            if (spFilters.retailer.isRegularPriceWithTax && spFilters.retailer.includeTaxInPrice === false) {
                regularPrice /= 1 + (product.branch.taxAmount || 0);
            } else if (!spFilters.retailer.isRegularPriceWithTax && spFilters.retailer.includeTaxInPrice === true) {
                regularPrice *= 1 + (product.branch.taxAmount || 0);
            }
            return regularPrice;
        };
    }]);
})(angular, spFiltersApp);

(function(angular, app) {
	'use strict';

	var PRICE_ROUND_MODES = {
		ROUND: 1,
		CEILING: 2,
		FLOOR: 3
	};

	// enough precision to not hurt the actual number yet low enough to remove the js bug precision
	var _jsBugMultiplier = 10000000000;

	var _lastRoundModeId,
		_roundModeOperation;

	app.filter('roundCurrency', ['spFilters', function(spFilters) {
		function filter(input) {
			if (input === undefined || input === null) {
				return input;
			}

			var numInput = Number(input);
			if (isNaN(numInput)) {
				return input;
			}

			// set the round operation locally by the price round mode
			_setPriceRoundModeOperation();

			// parse and set data about the currency minor unit for later use and for the currency filter to use
			_setMinorUnitData();

			// first, multiply num by the _jsBugMultiplier and round it
			// all next actions should be made on a rounded integer
			// to avoid mistakes with numbers affected by js float bug (1.16 / 0.005 = 231.99999999999997 for example)
			numInput = Math.round(numInput * _jsBugMultiplier);

			// then round the amount to the minor unit
			numInput = _roundModeOperation(numInput / spFilters.$currencyInnerData.multipliedMinorUnit) *
				spFilters.$currencyInnerData.multipliedMinorUnit;

			// then divide back to the real number
			return numInput / _jsBugMultiplier;
		}

		function _setPriceRoundModeOperation() {
			if (_lastRoundModeId === spFilters.retailer.priceRoundModeId && _roundModeOperation) {
				return;
			}

			_lastRoundModeId = spFilters.retailer.priceRoundModeId;
			switch(_lastRoundModeId) {
				case PRICE_ROUND_MODES.CEILING:
					_roundModeOperation = Math.ceil;
					break;
				case PRICE_ROUND_MODES.FLOOR:
					_roundModeOperation = Math.floor;
					break;
				default:
					_roundModeOperation = Math.round;
			}
		}

		function _setMinorUnitData() {
			var minorUnit = spFilters.retailer.currencyMinorUnit || 0.01;

			if (spFilters.$currencyInnerData && minorUnit === spFilters.$currencyInnerData.minorUnit &&
				spFilters.$currencyInnerData.decimalsLength) {
				return;
			}

			spFilters.$currencyInnerData = {
				minorUnit: minorUnit,
				multipliedMinorUnit: Math.round(minorUnit * _jsBugMultiplier),
				decimalsLength: minorUnit.toString().split('.')[1].length
			};
		}

		filter.$stateful = true;
		return filter;
	}]);
})(angular, spFiltersApp);
(function (angular, app) {
    'use strict';

    app.filter('salePrice', ['spFilters', function(spFilters) {
        return function (product) {
            var salePrice = product.branch && product.branch.salePrice;
            if (spFilters.retailer.isRegularPriceWithTax && spFilters.retailer.includeTaxInPrice === false) {
                salePrice /= 1 + (product.branch && product.branch.taxAmount || 0);
            } else if (!spFilters.retailer.isRegularPriceWithTax && spFilters.retailer.includeTaxInPrice === true) {
                salePrice *= 1 + (product.branch && product.branch.taxAmount || 0);
            }
            return salePrice;
        };
    }]);
})(angular, spFiltersApp);
(function (angular, app) {
    'use strict';

    app.service('spFilters', function () {
        this.name = {};
        this.unitNames = {};
        this.retailer = {};
        this.missingImage = 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/missing-image.png';
    });
})(angular, spFiltersApp);
(function (angular, app) {
    'use strict';

    app.filter('title', ['spFilters', '$filter', 'Config', function (spFilters, $filter, Config) {
        function filter (input) {
            if (!input) {
                return '';
            }

            if (typeof input !== 'string') {
                return input;
            }

            var title;
            if (Config.retailer.metaTitle) {
                title = Config.retailer.metaTitle;
            }
            else if (Config.retailer.shopTitle) {
                title = $filter('name')(Config.retailer.shopTitle);
            }
            else {
                title = Config.retailer.title
            }

            if (input.indexOf('|') !== -1) {
                return input.replace(/\|.*/, '| ' + title);
            }
            else {
                return title;
            }
        }

        filter.$stateful = false;
        return filter;
    }]);

})(angular, spFiltersApp);
(function (angular, app) {
    'use strict';

    app.filter('translateImage', [
        'Config',
        function (config) {
            function filter(input, languages) {
                if (languages && angular.isString(input)) {
                    if (config.language.culture && languages[config.language.culture]) {
                        return input.replace(/%translate%/g, languages[config.language.culture]);
                    }
                }

                return input.replace(/%translate%/g, '');
            }

            filter.$stateful = true;
            return filter;
        }]);
})(angular, spFiltersApp);
(function (angular, app) {
    'use strict';

    app.filter('unitNames', ['spFilters', function (spFilters) {
        return function (input, defaultNames) {
            if (!input) return '';

            if (input.isWeighable) {
                return defaultNames || spFilters.unitNames.defaultNames;
            }

            if (!input.unitOfMeasure || (!input.unitOfMeasure.names && !input.unitOfMeasure.defaultName)) {
                return '';
            }

            return input.unitOfMeasure.names || input.unitOfMeasure.defaultName;
        };
    }]);


})(angular, spFiltersApp);